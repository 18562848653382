import React from 'react'
import { Link } from 'react-router-dom'
import qs from 'qs'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

// utils
import changeInputHandler from 'app/views/utils/changeInputHandler'
import { resetPassword } from 'app/views/utils/auth'
import validate from './validation'
import { COMPLEXITY } from '../../constants/errorMessages'

// components
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

const propTypes = {}
const defaultProps = {}

class ResetPasswordClass extends React.Component {
  constructor(props) {
    super(props)

    this.handleInputChange = changeInputHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      fields: {},
      errors: {},
      error: '',
    }
  }

  async handleSubmit(e) {
    e.preventDefault()
    this.setState({ error: '' })
    const {
      location: { search },
    } = this.props
    const { fields } = this.state
    const errors = validate(fields, this.props.flags.enforceComplexPasswords)

    if (Object.keys(errors).length) {
      return this.setState({
        errors,
      })
    }

    const queryString = search.substr(1)
    const queryParams = qs.parse(queryString)
    const { username } = queryParams

    if (!username) {
      this.setState({ error: 'Username parameter is required' })
      return false
    }
    const payload = {
      verificationCode: fields.verificationCode,
      password: fields.password,
      username: decodeURIComponent(username),
    }

    await resetPassword(payload)
      .then(() => {
        this.props.history.push('/login')
      })
      .catch((err) => {
        console.error(err)
        this.setState({
          error: err.message,
        })
      })
  }

  render() {
    const { errors, error } = this.state

    return (
      <div
        data-test-id='reset-password-component'
        className='uk-background-muted'
        style={{ minHeight: 'calc(100vh - 10px)' }}
      >
        <div className='uk-container' style={{ maxWidth: 550 }}>
          <div className='uk-section uk-margin-large-bottom'>
            <div className='text-center'>
              <Link className='uk-navbar-item uk-logo uk-navbar-toggle' to='/'>
                <img
                  src='/static/images/SJ_HE_Logo2ColorHorizontal22.png'
                  className='w-[450px]'
                  alt='Logo'
                />
              </Link>
              <h3>Set New Password</h3>
            </div>
            <div
              className='uk-card uk-card-default uk-card-body uk-padding-large uk-margin-top'
              style={{ paddingBottom: 30 }}
            >
              <form>
                <div className='uk-margin'>
                  <input
                    name='verificationCode'
                    type='text'
                    placeholder='Verification Code'
                    className='uk-input'
                    onChange={this.handleInputChange}
                  />
                </div>
                <span className='text-danger'>{errors.verificationCode}</span>
                <div className='uk-margin'>
                  <input
                    name='password'
                    type='password'
                    placeholder='New Password'
                    className='uk-input'
                    onChange={this.handleInputChange}
                  />
                </div>
                <span className='text-danger'>{errors.password?.message}</span>
                {errors.password?.missing && (
                  <ul className='text-danger mt-0'>
                    {errors.password.missing.map((name, i) => (
                      <li key={i}>{COMPLEXITY[name]}</li>
                    ))}
                  </ul>
                )}
                <div className='uk-margin'>
                  <input
                    name='confirmPassword'
                    type='password'
                    placeholder='Confirm Password'
                    className='uk-input'
                    onChange={this.handleInputChange}
                  />
                </div>
                <span className='text-danger'>{errors.confirmPassword}</span>
                <div className='my-5'>
                  <PrimaryButton
                    label='Submit'
                    size='full'
                    onClick={this.handleSubmit}
                  />
                </div>
              </form>
              <span className='text-danger'>{error}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ResetPasswordClass.propTypes = propTypes
ResetPasswordClass.defaultProps = defaultProps
const ResetPassword = withLDConsumer()(ResetPasswordClass)
export default ResetPassword
