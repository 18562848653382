import _ from 'lodash'

export default function (event) {
  const { error } = this.state
  const fields = _.cloneDeep(this.state.fields)
  const errors = _.cloneDeep(this.state.errors)
  const { name, value } = event.target

  fields[name] = value
  errors[name] = ''

  const updateStateObj = {
    fields,
    errors,
  }

  if (error) {
    updateStateObj.error = ''
  }

  this.setState(updateStateObj)
}
