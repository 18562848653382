import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { HBox, Box } from 'app/views/core/Box'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'

import StarRatingControl from '../../common/StarRatingControl'
function LessonCompletionForm(props) {
  const { onFinishLesson } = props
  const [numStarsRated, setNumStarsRated] = useState(0)
  const [feedbackText, setFeedbackText] = useState('')
  return (
    <div>
      <p>Rate this lesson:</p>
      <StarRatingControl
        numStarsRated={numStarsRated}
        maxStars={10}
        onNumStarsRatedChange={setNumStarsRated}
      />
      <p>Please share any feedback you have:</p>
      <textarea
        className='uk-textarea'
        rows='3'
        value={feedbackText}
        onChange={(event) => setFeedbackText(event.target.value)}
      />
      <HBox>
        <Box grow />
        <PrimaryButton
          customCss={'mt-5'}
          label='Back to My Plan'
          size='full'
          onClick={() => onFinishLesson(numStarsRated, feedbackText)}
        />
        <Box grow />
      </HBox>
    </div>
  )
}
LessonCompletionForm.propTypes = {
  onFinishLesson: PropTypes.func.isRequired,
}

export default LessonCompletionForm
