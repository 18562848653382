import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import MonthRangePopover from './MonthRangePopover'

import './style.less'

function formatLabelText(startMonth, endMonth) {
  if (!startMonth && !endMonth) {
    return '...'
  }
  const monthToString = (obj) => {
    const monthIndex = obj.month - 1
    const date = new Date(obj.year, monthIndex)
    const monthName = date.toLocaleString(undefined, { month: 'short' })
    return `${monthName} ${date.getFullYear()}`
  }
  if (
    startMonth.year === endMonth.year &&
    startMonth.month === endMonth.month
  ) {
    return monthToString(endMonth)
  }
  if (endMonth === null) {
    return `${monthToString(startMonth)} Onward`
  }
  if (startMonth === null) {
    return `Up to ${monthToString(endMonth)}`
  }
  return `${monthToString(startMonth)} \u2014 ${monthToString(endMonth)}`
}

function MonthRangeDropdown(props) {
  const { mode, onModeChange, startMonth, endMonth, onDateRangeChange } = props
  const dropdownRef = useRef(null)
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <>
      <div
        ref={dropdownRef}
        className={`impact-report-month-range-dropdown${
          isExpanded ? ' impact-report-month-range-dropdown-expanded' : ''
        }`}
      >
        <label htmlFor='impact-report-month-range'>Date Range</label>
        <select
          id='impact-report-month-range'
          defaultValue='_'
          onPointerDown={(event) => {
            event.preventDefault()
            setIsExpanded(true)
          }}
          className='uk-select'
        >
          <option value='_'>{formatLabelText(startMonth, endMonth)}</option>
        </select>
      </div>
      {isExpanded && (
        <MonthRangePopover
          parentElem={dropdownRef.current}
          onDismiss={() => setIsExpanded(false)}
          mode={mode}
          onModeChange={onModeChange}
          startMonth={startMonth}
          endMonth={endMonth}
          onDateRangeChange={onDateRangeChange}
        />
      )}
    </>
  )
}
MonthRangeDropdown.propTypes = {
  mode: PropTypes.oneOf(['all-time', 'one-year', 'custom']).isRequired,
  onModeChange: PropTypes.func.isRequired,
  startMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
  }),
  endMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
  }),
  onDateRangeChange: PropTypes.func.isRequired,
}

export default MonthRangeDropdown
