import React from 'react'
import PropTypes from 'prop-types'

function ArticleHeader(props) {
  const { title } = props
  return (
    <div
      style={{ height: 75 }}
      className='uk-cover-container uk-background-secondary uk-padding-large uk-padding-remove-bottom uk-padding-remove-top uk-light'
    >
      <div className='uk-section uk-padding-remove'>
        <div className='text-center uk-margin-top'>
          <h2>{title}</h2>
        </div>
      </div>
    </div>
  )
}
ArticleHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default ArticleHeader
