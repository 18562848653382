import React from 'react'
import PropTypes from 'prop-types'

function getText(result) {
  if (result === 'pending') {
    return 'We\'re running your code through a series of tests.'
  }
  if (result === 'passed') {
    return 'Your code passed all of our tests. Congratulations on completing the lesson!'
  }
  if (result === 'failed') {
    return 'Your code failed one of our tests. See below for more details on what went wrong.'
  }
  return 'Your code was submitted.'
}

function TestSubmissionExplanation(props) {
  const { result } = props
  return (
    <p className='text-sm' style={{ marginTop: 0 }}>
      {getText(result)}
    </p>
  )
}
TestSubmissionExplanation.propTypes = {
  result: PropTypes.oneOf(['pending', 'passed', 'failed']).isRequired,
}

export default TestSubmissionExplanation
