import React from 'react'

class MultiSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOptions: {},
    }

    this.handlePickUnpick = this.handlePickUnpick.bind(this)
    this.renderSelectedOptionsMsg = this.renderSelectedOptionsMsg.bind(this)
  }

  handlePickUnpick(e) {
    const { checked } = e.target
    if (checked) {
      const { selectedOptions } = this.state
      return this.setState(
        {
          selectedOptions: {
            ...selectedOptions,
            [e.target.dataset.val]: e.target.name,
          },
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.selectedOptions)
          }
        }
      )
    }
    const { selectedOptions } = this.state
    delete selectedOptions[e.target.dataset.val]
    return this.setState(
      {
        selectedOptions,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.selectedOptions)
        }
      }
    )
  }

  renderSelectedOptionsMsg() {
    return Object.values(this.state.selectedOptions).join(', ')
  }

  render() {
    return (
      <div className='uk-inline uk-width-1-1'>
        <button
          style={{
            borderRadius: 0,
            paddingLeft: 10,
          }}
          type='button'
          className='uk-button uk-button-default uk-width-1-1 uk-select text-left'
        >
          {Object.values(this.props.selected || this.state.selectedOptions)
            .length === 0 ? (
            <div className='truncate uk-width-1-1'>
              <span style={{ fontSize: 17, color: '#666' }}>
                {this.props.nullOptionMsg}
              </span>
            </div>
          ) : (
            <div className='truncate uk-width-1-1'>
              <span style={{ fontSize: 17, color: '#666' }}>
                {this.renderSelectedOptionsMsg()}
              </span>
            </div>
          )}
        </button>
        <div
          uk-dropdown='mode: click;pos: center'
          className='uk-small-padding'
          style={{ paddingTop: 10 }}
        >
          {this.props.options.length === 0 ? (
            <p className='text-center'>{this.props.noOptionsMsg}</p>
          ) : (
            <>
              <h5
                style={{ marginBottom: 7.5, marginTop: 5 }}
                className='font-bold'
              >
                {this.props.optionsHeaderMsg}
              </h5>
              {this.props.options.map((option) => (
                <div key={option.value} className='uk-small-padding'>
                  <label>
                    <input
                      className='uk-checkbox'
                      type='checkbox'
                      data-val={option.value}
                      name={option.text}
                      onChange={this.handlePickUnpick}
                      style={{
                        marginRight: 15,
                      }}
                    />
                    {option.text}
                  </label>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    )
  }
}

export default MultiSelect
