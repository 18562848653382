import React from 'react'
import { connect } from 'react-redux'
import Cookie from 'universal-cookie'
import queryString from 'query-string'
import _ from 'lodash'
import settings from 'settings'

// Components
import Loader from 'app/views/components/Loader'
import ButtonWithLoader from 'app/views/components/ButtonWithLoader'
import GenericModal from 'app/views/components/Modals/GenericModal'
import OnboardingEnterpriseModal from 'app/views/components/OnboardingEnterpriseModal'

// Redux
import { getInviteToken, onboardUser } from 'app/state/modules/hacker'

import { getDeepLinkInfo } from 'app/state/modules/deeplinks'
import * as auth from 'app/state/modules/auth'
import {
  getAnonymousId,
  getEmailFromJWT,
  getUsernameFromJWT,
  loginUser,
  resendVerificationCode,
} from 'app/views/utils/auth'

// Utils
import validate from 'app/views/containers/Login/validation'
import { api } from 'app/views/utils/api'

class PartnerDeepLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isButtonLoading: false,
      passwordRequired: false,
      email: '',
      errors: {},
      success: {},
      password: '',
      error: '',
    }
    this.checkEmail = this.checkEmail.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.checkDomain = this.checkDomain.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.updateHeights = this.updateHeights.bind(this)
    this.handleResendCode = this.handleResendCode.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    const queryStringValues = queryString.parse(this.props.location.search)
    if (queryStringValues.domain) {
      this.checkDomain(queryStringValues.domain)
    } else {
      this.props.getDeepLinkInfo(this.props.match.params.link_uuid, () => {
        if (this.mounted) {
          this.setState(
            {
              isLoading: false,
            },
            () => {
              if (this.props.isAuthenticated) {
                this.props.history.push(
                  `/content/${this.props.deepLink.content_uuid}`
                )
              } else {
                this.updateHeights()
              }
            }
          )
        }
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onChangeEmail(e) {
    const { passwordRequired: oldPasswordRequired } = this.state
    this.setState(
      {
        email: e.target.value,
        passwordRequired: false,
        error: '',
      },
      () => {
        if (oldPasswordRequired !== this.state.passwordRequired) {
          this.updateHeights()
        }
      }
    )
  }

  checkDomain(domain) {
    this.setState({ isButtonLoading: true })

    api({
      method: 'post',
      url: settings.saml.checkDomainAssociationUri,
      data: {
        domain,
        redirect_to: settings.cognito.authData.RedirectUriSignIn,
      },
    })
      .then((response) => {
        if (response.data) {
          const url = _.get(response, 'data.url')
          if (url) {
            window.location = url
          } else {
            this.setState({
              passwordRequired: true,
              isButtonLoading: false,
            })
          }
        }
      })
      .catch((error) => {
        console.error(error)
        this.setState({
          error: error.message,
          isButtonLoading: false,
        })
      })
  }

  updateHeights() {
    const rightColumn = document.getElementById('deeplink-right-column')
    const leftColumn = document.getElementById('deeplink-left-column')
    const rightColumnHeight = rightColumn.clientHeight
    const leftColumnHeight = leftColumn.clientHeight
    if (rightColumnHeight >= leftColumnHeight) {
      leftColumn.style.height = `${rightColumnHeight}px`
    } else {
      rightColumn.style.height = `${leftColumnHeight}px`
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const { updateCognitoSession } = this.props
    const errors = validate({
      email: this.state.email,
      password: this.state.password,
    })

    if (Object.keys(errors).length) {
      return this.setState({
        errors,
      })
    }
    this.setState({ isButtonLoading: true })
    loginUser({ email: this.state.email, password: this.state.password })
      .then((result) => {
        this.setState({ isButtonLoading: false })
        updateCognitoSession(result, { clearSessionWhilePending: true })
        const cookie = new Cookie()
        const expires = new Date()
        expires.setFullYear(expires.getFullYear() + 1)
        cookie.set('hackedu.logged.in.app', 'true', {
          path: '/',
          domain: settings.urls.www.replace(
            /(https:\/\/www\.|http:\/\/www\.|:.+|http:\/\/)/g,
            ''
          ),
          expires,
        })
        const email = getEmailFromJWT(result.idToken.jwtToken)
        window.analytics.identify(getUsernameFromJWT(result.idToken.jwtToken), {
          email,
          anonymousId: getAnonymousId(),
        })
        if (this.props.inviteToken && this.props.inviteToken.length > 0) {
          this.props
            .onboardUser(result.idToken.jwtToken, this.props.inviteToken, null)
            .then(() => {
              this.props.history.push(
                `/content/${this.props.deepLink.content_uuid}`
              )
            })
        } else {
          this.props.history.push(
            `/content/${this.props.deepLink.content_uuid}`
          )
        }
      })
      .catch((err) => {
        this.setState({
          error: err.code,
          isButtonLoading: false,
        })
      })
    return undefined
  }

  checkEmail(e) {
    if (e) {
      e.preventDefault()
    }
    const errors = validate({ email: this.state.email })

    if (Object.keys(errors).length) {
      return this.setState({
        errors,
      })
    }
    this.setState({ isButtonLoading: true })

    return api({
      method: 'post',
      url: settings.saml.checkDomainAssociationUri,
      data: {
        email: this.state.email,
        redirect_to: settings.cognito.authData.RedirectUriSignIn,
      },
    })
      .then((response) => {
        if (response.data) {
          const url = _.get(response, 'data.url')

          if (url) {
            window.location = url
          } else {
            this.setState(
              {
                passwordRequired: true,
                isButtonLoading: false,
              },
              () => {
                this.updateHeights()
              }
            )
          }
        }
      })
      .catch((error) => {
        console.error(error)
        this.setState({
          error: error.message,
          isButtonLoading: false,
        })
      })
  }

  handleResendCode() {
    resendVerificationCode({ username: this.state.email })
      .then(() => {
        const { errors } = this.state
        this.setState({
          success: {
            resendEmail:
              'We have just resent the confirmation code to your email',
          },
          errors: {
            ...errors,
            resendEmail: null,
          },
        })
        this.resendModal.show()
      })
      .catch((err) => {
        const { errors, success } = this.state
        this.setState(
          {
            errors: {
              ...errors,
              resendEmail: err.message,
            },
            success: {
              ...success,
              resendEmail: null,
            },
          },
          () => {
            this.resendModal.show()
          }
        )
      })
  }

  renderError() {
    switch (this.state.error) {
      case 'UserNotFoundException':
        return (
          <span className='text-danger text-left'>User does not exist</span>
        )
      case 'NotAuthorizedException':
        return (
          <span className='text-danger'>Incorrect username or password.</span>
        )
      case 'UserNotConfirmedException':
        return (
          <span className='text-danger'>
            You have not confirmed your email address.
            <span
              type='button'
              className='_hover_error'
              onClick={this.handleResendCode}
              style={{
                fontTransform: 'underline',
                borderBottom: '1px solid #f0506e',
                cursor: 'pointer',
              }}
              role='button'
            >
              Resend Verification Email
            </span>
          </span>
        )
      default:
        return null
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loader visible />
    }
    const {
      logo_url: logoUrl,
      learn_more_url: learnMoreUrl,
      title,
      description,
    } = this.props.deepLink
    const submitFn = this.state.passwordRequired
      ? this.handleSubmit
      : this.checkEmail
    return (
      <div className='uk-container'>
        <div className='uk-section  text-center'>
          <div className='inline-flex items-center'>
            <div className=''>
              <img src={logoUrl} alt='deep-link-logo' style={{ height: 120 }} />
            </div>
            <div className='text-2xl'>+</div>
            <div className=''>
              <img
                src='/static/logos/hackedu.png'
                alt='hackedu-logo'
                style={{ height: 120 }}
              />
            </div>
          </div>
        </div>

        <div className='uk-section uk-padding-remove-vertical'>
          <div className='uk-width-1-2 uk-align-center text-center '>
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
        </div>

        <div className='uk-grid'>
          <div
            className='uk-width-1-2 text-center uk-padding'
            style={{ borderRight: 'solid 1px #eeeeee' }}
          >
            <h3>Need an account?</h3>
            <div id='deeplink-left-column'>
              Register to get access to all of HackEDU's lessons and challenges.
            </div>
            <br />
            <OnboardingEnterpriseModal deepLink={this.props.deepLink} />
          </div>
          <div className='uk-width-1-2 text-center uk-padding'>
            <h3>Login</h3>
            <div
              id='deeplink-right-column'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <input
                className='uk-input'
                type='text'
                style={{ maxWidth: 500, minHeight: 40 }}
                placeholder='Email'
                onKeyPress={(e) => {
                  const keyCode = e.keyCode || e.which
                  if (keyCode === 13) {
                    submitFn()
                  }
                }}
                onChange={this.onChangeEmail}
                value={this.state.email}
              />
              {this.state.passwordRequired && (
                <>
                  <div className='uk-margin-top uk-width-1-1'>
                    <input
                      onKeyPress={(e) => {
                        const keyCode = e.keyCode || e.which
                        if (keyCode === 13) {
                          submitFn()
                        }
                      }}
                      name='password'
                      type='password'
                      placeholder='Password'
                      className='uk-input'
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    <div
                      style={{
                        position: 'absolute',
                      }}
                    >
                      {this.state.error && this.renderError()}
                    </div>
                  </div>
                </>
              )}
            </div>
            <br />
            <ButtonWithLoader
              data-test-id='login-submit-btn'
              className='uk-button uk-button-primary tm-button-primary uk-margin-top'
              onClick={submitFn}
              spinnerClassName='uk-margin-top'
              isLoading={this.state.isButtonLoading}
              style={{ width: '100%' }}
            >
              Continue
            </ButtonWithLoader>
          </div>
        </div>
        <br />
        <div className='uk-width-1-2 uk-align-center text-center'>
          <h2>Want to learn more?</h2>
          <p>
            Developers are problem solvers and learn most effectively through
            hands-on real-world scenarios. Video and PowerPoint lessons don't
            cut it.
          </p>
          <a href={learnMoreUrl} className='uk-button uk-button-primary'>
            Learn More
          </a>
        </div>
        <br />
        <br />
        <br />
        <br />
        <GenericModal
          id='modal-resend-code-deep-link'
          buttonType='uk-button-primary'
          ref={(ref) => {
            this.resendModal = ref
          }}
          title={
            this.state.errors.resendEmail ? 'Error' : 'Confirmation code resend'
          }
          body={
            <>
              <p>
                {this.state.errors.resendEmail ||
                  this.state.success.resendEmail}
              </p>
            </>
          }
          closeText='Ok'
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  deepLink: state.deeplinks.info,
  inviteToken: getInviteToken(),
  isAuthenticated: state.auth.status === 'LOGGED_IN',
})

export default connect(mapStateToProps, {
  getDeepLinkInfo,
  onboardUser,
  updateCognitoSession: auth.updateCognitoSession,
})(PartnerDeepLink)
