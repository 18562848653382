import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

function getDirectoryIcon(options) {
  return options.expanded ? 'ri-folder-open-line' : 'ri-folder-line'
}

function getFileIcon(options) {
  const iconType = options.locked ? 'lock' : 'text'
  const iconStyle = options.opened ? 'fill' : 'line'
  return `ri-file-${iconType}-${iconStyle}`
}

function getIconName(type, options) {
  if (type === 'directory') return getDirectoryIcon(options)
  if (type === 'file') return getFileIcon(options)
  return 'ri-question-line'
}

function FileBrowserIcon(props) {
  const { type, options } = props
  return <i className={cx('file-browser-icon', getIconName(type, options))} />
}

FileBrowserIcon.propTypes = {
  type: PropTypes.oneOf(['directory', 'file']).isRequired,
  options: PropTypes.oneOfType([
    PropTypes.shape({
      expanded: PropTypes.bool.isRequired,
    }),
    PropTypes.shape({
      locked: PropTypes.bool.isRequired,
      opened: PropTypes.bool.isRequired,
    }),
  ]).isRequired,
}

export default FileBrowserIcon
