import React from 'react'
import Icon from 'app/views/components/Icon'

class ButtonTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTabIndex: this.props.defaultTab || 0,
    }
    this.renderButtons = this.renderButtons.bind(this)
    this.onClickTab = this.onClickTab.bind(this)
  }

  componentDidMount() {
    this.setState({
      currentTabIndex: this.props.defaultTab,
    })
  }

  onClickTab(currentTabIndex) {
    if (this.props.customClickTab) {
      this.props.customClickTab(currentTabIndex)
    }
    return this.setState({ currentTabIndex })
  }

  renderButtons() {
    const { currentTabIndex } = this.state
    return this.props.tabs.map((item, index) => (
      <button
        key={`tab-${item.name}`}
        onClick={this.onClickTab.bind(this, index)}
        className={`flex items-center justify-center uk-button uk-margin-right ${
          index === currentTabIndex
            ? 'bg-green text-white'
            : 'bg-white text-green'
        }`}
        style={{
          paddingLeft: 10,
        }}
        type='button'
        ratio={0.7}
      >
        <Icon
          className='uk-margin-small-right'
          name={item.icon}
          none={item.iconNone}
        />
        <span>{item.name}</span>
      </button>
    ))
  }

  render() {
    return (
      <>
        <div
          style={{
            marginTop: 50,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {this.renderButtons()}
        </div>
        <br />
        {this.props.tabs[this.state.currentTabIndex].component}
      </>
    )
  }
}

export default ButtonTabs
