/**
 * The relevant data parsed from a svc-code-sub CodeSubmission object: this does not
 * directly mirror the svc-code-sub schema; it's a simplified object that contains the
 * subset of submission data that www-app needs to care about.
 */
export type CodeSubmission = {
  lessonId: string
  lessonVersion: number
  submissionTime: Date
  createdAt: Date
  result: CodeSubmissionResult
  volumeName: string
  replCode: string | null
}

/**
 * Simplified result resolved from the array of test results in the actual API object:
 * a submission can either be passed, failed (with the name of the test that failed), or
 * in an indeterminate error state.
 */
export type CodeSubmissionResult = {
  status: 'error'
} | {
  status: 'passed'
} | {
  status: 'failed'
  testName: string
  errorMessage: string
}

/** Parses and validates a pb.CodeSubmission object returned from svc-code-sub. */
export function parseCodeSubmission(data: unknown): CodeSubmission {
  // Require a JSON object
  if (typeof data !== 'object') {
    throw new Error('CodeSubmission data is not a JSON object')
  }

  // Parse basic destails of the submission
  if (typeof data['lessonId'] !== 'string' || !data['lessonId']) {
    throw new Error("CodeSubmission object does not have a valid lessonId")
  }
  if (typeof data['lessonVersion'] !== 'number' || data['lessonVersion'] <= 0) {
    throw new Error("CodeSubmission object does not have a valid lessonVersion")
  }

  // Parse submission timestamps
  if (typeof data['submissionTime'] !== 'string' || !data['submissionTime']) {
    throw new Error("CodeSubmission object does not have a valid submissionTime")
  }
  const submissionTime = new Date(data['submissionTime'])
  if (isNaN(submissionTime.getTime())) {
    throw new Error("CodeSubmission's submissionTime property is not a valid timestamp")
  }
  if (typeof data['createdAt'] !== 'string' || !data['createdAt']) {
    throw new Error("CodeSubmission object does not have a valid submissionTime")
  }
  const createdAt = new Date(data['createdAt'])
  if (isNaN(createdAt.getTime())) {
    throw new Error("CodeSubmission's createdAt property is not a valid timestamp")
  }

  // Consolidate testResults to a single, simplified result for display
  // Root obj contains {"testResults":{"testResults":[]}} for some reason
  const resultsArray = data['testResults']['testResults']
  if (typeof resultsArray === 'undefined' || !Array.isArray(resultsArray)) {
    throw new Error("CodeSubmission object does not have a valid testResults object with a testResults array")
  }
  const result = parseCodeSubmissionResult(resultsArray)

  // Consolidate volumes to a singular engine name for display, optionally parsing the
  // code contents if the volume is associated with an identifiable repl engine and has
  // a single modified file
  // Root obj contains {"volumes":{"volume":[]}} for some reason
  const volumesArray = data['volumes']['volume']
  if (typeof volumesArray === 'undefined' || !Array.isArray(volumesArray)) {
    throw new Error("CodeSubmission object does not have a valid volumes object with a volume array")
  }
  const { volumeName, replCode } = parseSubmittedCode(volumesArray)

  return {
    lessonId: data['lessonId'],
    lessonVersion: data['lessonVersion'],
    submissionTime,
    createdAt,
    result,
    volumeName,
    replCode,
  }
}

/** Parses an array of pb.TestResult objects from a code submission payload. */
function parseCodeSubmissionResult(resultsArray: unknown[]): CodeSubmissionResult {
  if (resultsArray.length > 0) {
    // If the submission has any test results recorded, just take the last one
    const data = resultsArray[resultsArray.length - 1]
    if (typeof data !== 'object') {
      throw new Error('CodeSubmission test result is not an object')
    }

    // testStatus is carried over from the lesson_activity schema where it must be one
    // of 'passed', 'failed', or 'error'
    const status = data['testStatus']
    if (typeof data['testStatus'] !== 'string') {
      throw new Error('CodeSubmission test result is missing testStatus')
    }
    if (!['passed', 'failed', 'error'].includes(status)) {
      throw new Error(`CodeSubmission test result has invalid testStatus value '${data['testStatus']}'`)
    }
    if (status === 'passed') {
      // If the test status was good, record this submission as passed
      return { status: 'passed' }
    } else if (status === 'failed') {
      // If the test failed, try to ascertain its name: we still correlate these names
      // with 'test' records from the db (which the UI also refers to a 'test stages')
      // to fill in title, description, help URL, etc. This is legacy-HE-specific
      // feature, though: a test name isn't required by the svc-code-sub schema, so if
      // the test name is a sentinel, the UI simply won't identify the stage at which
      // the submission failed.
      let testName = 'unknown-test'
      if (typeof data['testName'] === 'string' && data['testName']) {
        testName = data['testName']
      }
      return {
        status: 'failed',
        testName,
        errorMessage: data['errorMessage'] || '',
      }
    } else {
      // If the submission has results that neither passed nor failed, its status is
      // indeterminate
      return { status: 'error' }
    }
  }
  // If the submission has no testResults recorded, it passed: a passing submission is
  // not required to specify details of specific tests
  return { status: 'passed' }
}

/** Parses an array of pb.Volume objects from a code submission payload. */
function parseSubmittedCode(volumesArray: unknown[]): { volumeName: string, replCode: string | null } {
  if (volumesArray.length > 0) {
    // If the submission includes code for any volumes, just take the last one
    const data = volumesArray[volumesArray.length - 1]
    if (typeof data !== 'object') {
      throw new Error('CodeSubmission volume is not an object')
    }
    if (typeof data['volumeName'] !== 'string' || !data['volumeName']) {
      throw new Error('CodeSubmission volume does not have a valid volumeName')
    }
    // If the volume has a name other than 'app' and its user-modified files consist of
    // a single file, consider it a repl submission and attempt to read the code itself
    const isRepl = data['volumeName'] !== 'app'
    if (isRepl && typeof data['code'] !== 'undefined' && Array.isArray(data["code"]) && data["code"].length > 0) {
      const fileContentsBase64 = data["code"][0]["fileContents"]
      if (typeof fileContentsBase64 !== "string") {
        throw new Error(`CodeSubmission volume with name ${data['volumeName']} and a single-item 'code' array does not list a valid fileContents`)
      }
      const replCode = atob(fileContentsBase64)
      return { volumeName: data['volumeName'], replCode }
    } else {
      return { volumeName: data['volumeName'], replCode: null }
    }
  } else {
    // If the submission has no code, just return a dummy result: the webapp always
    // expects a code submission to have an identifiable engine name, and in our current
    // content offerings 'app' is universal sentinel for "this is not a repl lesson" so
    // we'll use that here to ensure the UI falls back to behavior that doesn't involve
    // trying to identify a repl engine or display code
    return { volumeName: 'app', replCode: null }
  }
}

/** Parses and validates a pb.RetrieveCodeSubmissionResponse from svc-code-sub. */
export function parseFetchSubmissionsResponse(data: unknown): CodeSubmission[] {
  if (typeof data !== 'object') {
    throw new Error('response data is not a JSON object')
  }
  if (typeof data['userCodeSubmissions'] === 'undefined' || !Array.isArray(data['userCodeSubmissions'])) {
    throw new Error("response object does not have a 'userCodeSubmissions' array")
  }
  const submissions = [] as CodeSubmission[]
  for (const obj of data['userCodeSubmissions']) {
    submissions.push(parseCodeSubmission(obj))
  }
  return submissions
}
