import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'
import analytics from 'app/views/utils/analytics'

// Local Components
import Modal from '../../UIkit/Modal'
import Star from './components/Star'

const propTypes = {
  name: PropTypes.string,
  confirmURL: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
}
const defaultProps = {
  name: '',
}

class ModalCompletedLesson extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      feedback: '',
      rating: 0,
      loading: false,
    }

    UIkit.use(Icons)

    this.handleChangeFeedback = this.handleChangeFeedback.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentDidMount() {
    this.modal = UIkit.modal('#modal-completed-lesson')
  }

  hide() {
    this.modal.hide()
  }

  show() {
    this.modal.show()
  }

  handleChangeFeedback(e) {
    this.setState({ feedback: e.target.value })
  }

  handleOnClick() {
    this.modal.hide()
    this.props.history.push(this.props.confirmURL)
  }

  render() {
    let successfulPatch = false
    for (let i = 0; i < this.props.codeSubmissions.length; i++) {
      if (this.props.codeSubmissions[i].test.passed === true) {
        successfulPatch = true
        break
      }
    }

    let body = (
      <div className='text-center'>
        <h2>Congrats!</h2>

        {this.state.loading ? (
          <span data-uk-spinner='' />
        ) : (
          <div>
            <div className='font-bold'>Rate this lesson</div>
            <div>
              <Star
                selected={this.state.rating > 0}
                handleClick={() => {
                  this.setState({ rating: 1 })
                }}
              />
              <Star
                selected={this.state.rating > 1}
                handleClick={() => {
                  this.setState({ rating: 2 })
                }}
              />
              <Star
                selected={this.state.rating > 2}
                handleClick={() => {
                  this.setState({ rating: 3 })
                }}
              />
              <Star
                selected={this.state.rating > 3}
                handleClick={() => {
                  this.setState({ rating: 4 })
                }}
              />
              <Star
                selected={this.state.rating > 4}
                handleClick={() => {
                  this.setState({ rating: 5 })
                }}
              />
              <Star
                selected={this.state.rating > 5}
                handleClick={() => {
                  this.setState({ rating: 6 })
                }}
              />
              <Star
                selected={this.state.rating > 6}
                handleClick={() => {
                  this.setState({ rating: 7 })
                }}
              />
              <Star
                selected={this.state.rating > 7}
                handleClick={() => {
                  this.setState({ rating: 8 })
                }}
              />
              <Star
                selected={this.state.rating > 8}
                handleClick={() => {
                  this.setState({ rating: 9 })
                }}
              />
              <Star
                selected={this.state.rating > 9}
                handleClick={() => {
                  this.setState({ rating: 10 })
                }}
              />
            </div>

            <div className='uk-margin'>
              <p>Please share any feedback you have:</p>
              <textarea
                className='uk-textarea'
                rows='3'
                value={this.state.feedback}
                onChange={this.handleChangeFeedback}
              />
            </div>

            <button
              className='uk-button uk-button-primary'
              onClick={() => {
                if (this.state.rating > 0) {
                  api({
                    method: 'post',
                    url: `${settings.urls.hacker}/feedback`,
                    data: {
                      content_uuid: this.props.contentId,
                      message: this.state.feedback,
                      stars: this.state.rating,
                    },
                    withAuthToken: true,
                  })
                    .then((response) => {
                      if (response.data.success === true) {
                        analytics.track('feedback')
                      }
                      this.setState({ loading: false }, () => {
                        this.handleOnClick()
                        this.props.history.push('/')
                      })
                    })
                    .catch((error) => {
                      console.error(error)
                      this.props.history.push('/')
                    })
                } else {
                  this.handleOnClick()
                }
              }}
              type='button'
            >
              {this.props.confirmText}
            </button>
          </div>
        )}
      </div>
    )

    if (this.props.hasTest === true) {
      if (
        this.props.mustPassCodingExercise === true &&
        successfulPatch === false &&
        this.props.latestCodePatchId === null
      ) {
        body = (
          <div className='text-center'>
            <h2 className='text-danger'>Whoops!</h2>
            <p>You must submit a code patch to pass this lesson.</p>
            <button className='uk-button uk-button-secondary uk-modal-close uk-margin-right'>
              Go Back
            </button>
            <button
              className='uk-button uk-button-primary uk-modal-close'
              onClick={() => {
                this.props.history.push(this.props.confirmURL)
              }}
            >
              Skip Patching
            </button>
          </div>
        )
      } else if (
        this.props.mustPassCodingExercise === true &&
        successfulPatch === false &&
        this.props.latestCodePassed === null
      ) {
        body = (
          <div className='text-center'>
            <div data-uk-spinner='' />
            <p>We are grading your code submission...</p>
            <button className='uk-button uk-button-secondary uk-modal-close uk-margin-right'>
              Go Back
            </button>
            <button
              className='uk-button uk-button-primary uk-modal-close'
              onClick={() => {
                this.props.history.push(this.props.confirmURL)
              }}
            >
              Skip Grading
            </button>
          </div>
        )
      } else if (
        this.props.mustPassCodingExercise === true &&
        successfulPatch === false &&
        this.props.latestCodePassed === false
      ) {
        body = (
          <div className='text-center'>
            <h2 className='text-danger'>Whoops!</h2>
            <p>Your code is not currently passing.</p>
            <button className='uk-button uk-button-secondary uk-modal-close uk-margin-right'>
              Go Back
            </button>
            <button
              className='uk-button uk-button-primary uk-modal-close'
              onClick={() => {
                this.props.history.push(this.props.confirmURL)
              }}
            >
              Skip Grading
            </button>
          </div>
        )
      }
    }

    return <Modal id='modal-completed-lesson' body={body} />
  }
}

ModalCompletedLesson.propTypes = propTypes
ModalCompletedLesson.defaultProps = defaultProps

export default withRouter(ModalCompletedLesson)
