/**
 * Serves as an interface to the instance of api-filesystem that's running on the
 * currently-loaded sandbox, if any. api-filesystem is only used, and hence this module
 * is only initialized, for content with type 'coding_challenge' or 'mobile'. This type
 * of content is also known by its older name, 'CodeReview'.
 */
import { api } from 'app/views/utils/api'

import base64 from '../utils/base64'
import { requestOpts } from 'app/state/modules/sandbox'

const prefix = 'sbcrfilesystem'
const SETUP = `${prefix}/SETUP`
const TEARDOWN = `${prefix}/TEARDOWN`
const QUEUE_DIRECTORY_LISTING_REQUEST = `${prefix}/QUEUE_DIRECTORY_LISTING_REQUEST`
const BEGIN_FETCHING_DIRECTORY_LISTING = `${prefix}/BEGIN_FETCHING_DIRECTORY_LISTING`
const FINISH_FETCHING_DIRECTORY_LISTING = `${prefix}/FINISH_FETCHING_DIRECTORY_LISTING`
const QUEUE_FILE_CONTENTS_REQUEST = `${prefix}/QUEUE_FILE_CONTENTS_REQUEST`
const BEGIN_FETCHING_FILE_CONTENTS = `${prefix}/BEGIN_FETCHING_FILE_CONTENTS`
const FINISH_FETCHING_FILE_CONTENTS = `${prefix}/FINISH_FETCHING_FILE_CONTENTS`
const QUEUE_FILE_COMMIT_REQUEST = `${prefix}/QUEUE_FILE_COMMIT_REQUEST`
const BEGIN_COMMIT_FILE_CONTENTS = `${prefix}/BEGIN_COMMIT_FILE_CONTENTS`
const FINISH_COMMIT_FILE_CONTENTS = `${prefix}/FINISH_COMMIT_FILE_CONTENTS`

const initialState = {
  // A list of paths that we want to fetch: these are queued and we process them in order, one request at a time
  directoryPathsToFetch: [''],
  filePathsToFetch: [],

  // URL of the api-filesystem container that's running in the sandbox: set on init, when the sandbox is first assigned
  apiUrl: null,

  // State related to directory listings fetched from api-filesystem
  fetchingDirectoryListing: false,
  fetchDirectoryListingError: null,
  directoryPathsFetched: [], // Directory listings are static; we only fetch each path once
  rootDirectory: {
    name: '', // '' is a sentinel for root; all other directories have valid name and path
    directories: [], // Recursively nested {name, path, directories, files} objects
    files: [], // Flat list of {name, path} objects
  },

  // State related to file contents fetched from api-filesystem
  fetchingFileContents: false,
  fetchFileContentsError: null,
  fileDetailsByPath: {},

  // State related to saving files, i.e. committing new file contents to api-filesystem
  // NOTE: Unlike fetching functionality above, pendingFileCommits[0] is left in the queue during
  // processing, and is only popped off when we're done saving. Employing a more consistent pattern
  // across the board would probably make this module clearer.
  pendingFileCommits: [],
  committingFileContents: true,
  commitFileContentsError: null,
}

const ENABLE_VERBOSE_LOGGING = false
// eslint-disable-next-line no-console
const _log = ENABLE_VERBOSE_LOGGING ? console.log : () => {}
const _warn = ENABLE_VERBOSE_LOGGING ? console.warn : () => {}

function buildDirectoryListing(newPath, rootDirectory, newData) {
  // Establish a function that will recursively walk down our existing directory
  // listing, copying directory/file objects piecemeal into a new object, but
  // substituting the contents of newData when it gets to that point in the tree
  function merge(depth, dstDir, srcDir) {
    // Iterate over all subdirectories of srcDir, copying them into dstDir
    for (const srcSubdir of srcDir.directories) {
      // Are we at the point in our original directory listing where we need to glue in
      // our newData object, which represents a newer representation of that directory
      // with a deeper list of subdirs/files than we have currently?
      if (srcSubdir.path === newPath) {
        // If so, use the directory/file objects from the api-filesystem response,
        // copying over the name/path values that we already have, since they're not
        // present in the response. Then recurse no further: we're always
        // calling api-filesystem in a way that adds new leaf nodes to our directory
        // listing; we can never go backwards in depth
        _log(
          `${'-'.repeat(depth)} ${srcSubdir.path}/ - accepting newData`,
          newData
        )
        dstDir.directories.push({
          name: srcSubdir.name,
          path: srcSubdir.path,
          directories: newData.directories,
          files: newData.files,
        })
      } else {
        // Otherwise, push a new object that represents the subdirectory, but with
        // uninitialized directory/file arrays, then recurse one level deeper to
        // populate those arrays
        _log(`${'-'.repeat(depth)} ${srcSubdir.path}/`)
        dstDir.directories.push({
          name: srcSubdir.name,
          path: srcSubdir.path,
          directories: [],
          files: [],
        })
        const dstSubdir = dstDir.directories[dstDir.directories.length - 1]
        merge(depth + 1, dstSubdir, srcSubdir)
      }
    }
    // Once all subdirectories have been built at this depth, copy over the list of files
    for (const srcFile of srcDir.files) {
      _log(`${'-'.repeat(depth)} ${srcFile.path}`)
      dstDir.files.push({ ...srcFile })
    }
  }

  // Build a new rootDirectory object, then copy everything from our existing
  // rootDirectory listing, merging in the new directory data we've just received
  let newRootDirectory = { name: '', directories: [], files: [] }
  merge(0, newRootDirectory, rootDirectory)
  return newRootDirectory
}

function reduceDirectoryListing(newPath, rootDirectory, newData) {
  // If we're getting the listing for the root directory, accept it directly
  if (newPath === '') {
    if (
      rootDirectory.directories.length === 0 &&
      rootDirectory.files.length === 0
    ) {
      _log('got initial directory listing', newData)
      return newData
    } else {
      _warn('got redundant root directory listing; ignoring it')
      return rootDirectory
    }
  }

  // If we're getting the listing for a subdirectory, we should already have an object
  // representing that directory in our rootDirectory listing. Our existing object only
  // lists subdirs/files one level deep; whereas the new object (from the
  // api-filesystem request) contains more information. So build a new directory
  // listing, tearing off the old branch for newPath and gluing in a copy filled in
  // with the information from newData.
  return buildDirectoryListing(newPath, rootDirectory, newData)
}

function removeFilePathFromQueue(filePathsToFetch, path) {
  // Pop only the *first* instance, since we might queue multiple requests for the same
  // file at once (not likely; but we don't want to invite weird race conditions)
  const foundIndex = filePathsToFetch.findIndex((x) => x === path)
  if (foundIndex >= 0) {
    return filePathsToFetch
      .slice(0, foundIndex)
      .concat(filePathsToFetch.slice(foundIndex + 1))
  }
  return filePathsToFetch
}

function flagFileLoading(fileDetailsByPath, path) {
  const existingFileDetails = fileDetailsByPath[path]
  if (existingFileDetails) {
    return {
      ...fileDetailsByPath,
      [path]: {
        ...existingFileDetails,
        loading: true,
      },
    }
  }
  return {
    ...fileDetailsByPath,
    [path]: {
      contents: '',
      loading: true,
      recvTime: 0,
    },
  }
}

function flagFileSaving(fileDetailsByPath, path) {
  const existingFileDetails = fileDetailsByPath[path]
  if (existingFileDetails) {
    return {
      ...fileDetailsByPath,
      [path]: {
        ...existingFileDetails,
        saving: true,
      },
    }
  }
  return fileDetailsByPath
}

function updateFileContents(fileDetailsByPath, path, newContents) {
  return {
    ...fileDetailsByPath,
    [path]: {
      contents: newContents,
      loading: false,
      saving: false,
      recvTime: Date.now(),
    },
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SETUP:
      return {
        ...state,
        directoryPathsToFetch: state.directoryPathsToFetch.includes('')
          ? state.directoryPathsToFetch
          : [''].concat(state.directoryPathsFetched),
        apiUrl: action.apiFilesystemUrl,
      }
    case TEARDOWN:
      return {
        ...state,
        apiUrl: null,
        fetchingDirectoryListing: false,
        fetchDirectoryListingError: null,
        directoryPathsFetched: [],
        rootDirectory: {
          name: '',
          directories: [],
          files: [],
        },
        fetchingFileContents: false,
        fetchFileContentsError: null,
        fileDetailsByPath: {},
        pendingFileCommits: [],
        committingFileContents: false,
        commitFileContentsError: null,
      }
    case QUEUE_DIRECTORY_LISTING_REQUEST:
      return {
        ...state,
        directoryPathsToFetch: state.directoryPathsToFetch.concat([
          action.path,
        ]),
      }
    case BEGIN_FETCHING_DIRECTORY_LISTING:
      return {
        ...state,
        fetchingDirectoryListing: true,
        fetchDirectoryListingError: null,
        directoryPathsToFetch: state.directoryPathsToFetch.filter(
          (x) => x !== action.path
        ),
      }
    case FINISH_FETCHING_DIRECTORY_LISTING:
      if (action.error) {
        return {
          ...state,
          fetchingDirectoryListing: false,
          fetchDirectoryListingError: action.error,
        }
      }
      return {
        ...state,
        fetchingDirectoryListing: state.directoryPathsToFetch.length > 0,
        fetchDirectoryListingError: null,
        directoryPathsFetched: [...state.directoryPathsFetched, action.path],
        rootDirectory: reduceDirectoryListing(
          action.path,
          state.rootDirectory,
          action.data
        ),
      }
    case QUEUE_FILE_CONTENTS_REQUEST:
      return {
        ...state,
        filePathsToFetch: state.filePathsToFetch.concat([action.path]),
        fileDetailsByPath: flagFileLoading(
          state.fileDetailsByPath,
          action.path
        ),
      }
    case BEGIN_FETCHING_FILE_CONTENTS:
      return {
        ...state,
        fetchingFileContents: true,
        fetchFileContentsError: null,
        filePathsToFetch: removeFilePathFromQueue(
          state.filePathsToFetch,
          action.path
        ),
      }
    case FINISH_FETCHING_FILE_CONTENTS:
      if (action.error) {
        return {
          ...state,
          fetchingFileContents: false,
          fetchFileContentsError: action.error,
        }
      }
      return {
        ...state,
        fetchingFileContents: state.filePathsToFetch.length > 0,
        fetchFileContentsError: null,
        fileDetailsByPath: updateFileContents(
          state.fileDetailsByPath,
          action.path,
          action.contents
        ),
      }
    case QUEUE_FILE_COMMIT_REQUEST:
      return {
        ...state,
        pendingFileCommits: state.pendingFileCommits.concat([
          { path: action.path, contents: action.contents },
        ]),
        fileDetailsByPath: flagFileSaving(state.fileDetailsByPath, action.path),
      }
    case BEGIN_COMMIT_FILE_CONTENTS:
      return {
        ...state,
        committingFileContents: true,
        commitFileContentsError: null,
      }
    case FINISH_COMMIT_FILE_CONTENTS:
      if (action.error) {
        return {
          ...state,
          committingFileContents: false,
          commitFileContentsError: action.error,
          pendingFileCommits: state.pendingFileCommits.slice(1),
        }
      }
      return {
        ...state,
        committingFileContents: state.pendingFileCommits.length > 1,
        commitFileContentsError: null,
        fileDetailsByPath: updateFileContents(
          state.fileDetailsByPath,
          action.path,
          action.contents
        ),
        pendingFileCommits: state.pendingFileCommits.slice(1),
      }
    default:
      return state
  }
}

async function fetchDirectoryListing(apiUrl, path, opts) {
  const baseUrl = `${apiUrl}/filesystem`
  const url = baseUrl + (path && path.length > 0 ? `?dirname=${path}` : '')
  const response = await api({ method: 'get', url, opts })
  return response.data
}

async function fetchFileContents(apiUrl, path, opts) {
  const url = `${apiUrl}/file`
  const params = { filename: path }
  const response = await api({ method: 'get', url, params, opts })
  return base64.decode(response.data.file)
}

async function commitFileContents(apiUrl, path, contents, opts) {
  const url = `${apiUrl}/file`
  const formData = new FormData()
  formData.append('filename', path)
  formData.append('content', base64.encode(contents))
  await api({ method: 'post', url, data: formData, opts })
}

function processNextDirectoryListingRequest(dispatch, getState) {
  const state = getState()
  const fetchOpts = requestOpts(state)
  const initialState = state.sbcrfilesystem
  if (!initialState.apiUrl) {
    _warn('unable to process directory listing requests: no API url')
    return
  }

  if (initialState.directoryPathsToFetch.length === 0) {
    _log('no directory listing requests to process: queue is empty')
    return
  }

  const path = initialState.directoryPathsToFetch[0]
  _log(`initiating directory listing request for '${path}'...`)
  dispatch({ type: BEGIN_FETCHING_DIRECTORY_LISTING, path })

  fetchDirectoryListing(initialState.apiUrl, path, fetchOpts)
    .then((data) => {
      _log(`got directory listing for '${path}'`, data)
      dispatch({ type: FINISH_FETCHING_DIRECTORY_LISTING, data, path })
      const queuedPaths = getState().sbcrfilesystem.directoryPathsToFetch
      if (queuedPaths.length > 0) {
        _log(
          `directory queue still has ${queuedPaths.length} items; processing next`
        )
        processNextDirectoryListingRequest(dispatch, getState)
      } else {
        _log('done processing directory queue')
      }
    })
    .catch((err) => {
      console.error('failed to fetch directory listing from sandbox', err)
      dispatch({ type: FINISH_FETCHING_DIRECTORY_LISTING, error: err })
    })
}

function processNextFileContentsRequest(dispatch, getState) {
  const state = getState()
  const fetchOpts = requestOpts(state)
  const initialState = state.sbcrfilesystem
  if (!initialState.apiUrl) {
    _warn('unable to process file contents requests: no API url')
    return
  }

  if (initialState.filePathsToFetch.length === 0) {
    _log('no file contents requests to process: queue is empty')
    return
  }

  const path = initialState.filePathsToFetch[0]
  _log(`initiating file contents request for '${path}'...`)
  dispatch({ type: BEGIN_FETCHING_FILE_CONTENTS, path })

  fetchFileContents(initialState.apiUrl, path, fetchOpts)
    .then((contents) => {
      _log(`got file contents for '${path}':\n` + contents)
      dispatch({ type: FINISH_FETCHING_FILE_CONTENTS, contents, path })
      const queuedPaths = getState().sbcrfilesystem.filePathsToFetch
      if (queuedPaths.length > 0) {
        _log(
          `file queue still has ${queuedPaths.length} items; processing next`
        )
        processNextFileContentsRequest(dispatch, getState)
      } else {
        _log('done processing file queue')
      }
    })
    .catch((err) => {
      console.error('failed to fetch file contents from sandbox', err)
      dispatch({ type: FINISH_FETCHING_FILE_CONTENTS, error: err })
    })
}

function processNextFileCommitRequest(dispatch, getState) {
  const state = getState()
  const fetchOpts = requestOpts(state)
  const initialState = state.sbcrfilesystem
  if (!initialState.apiUrl) {
    _warn('unable to process file commit requests: no API url')
    return
  }

  if (initialState.pendingFileCommits.length === 0) {
    _warn('no file commit requests to process: queue is empty')
    return
  }

  const { path, contents } = initialState.pendingFileCommits[0]
  _log(`committing new contents for '${path}'...`)
  dispatch({ type: BEGIN_COMMIT_FILE_CONTENTS, path })

  commitFileContents(initialState.apiUrl, path, contents, fetchOpts)
    .then(() => {
      _log(`finished committing contents for '${path}'`)
      // n.b.: events are dispatched (and reduced into our state object) synchronously,
      // so when we check our new state after this line, the number of pending commits
      // will have been decremented from initialState.pendingFileCommits.length
      dispatch({ type: FINISH_COMMIT_FILE_CONTENTS, path, contents })
      const numPendingCommitsPostDispatch =
        getState().sbcrfilesystem.pendingFileCommits.length
      if (numPendingCommitsPostDispatch > 0) {
        processNextFileCommitRequest(dispatch, getState)
      }
    })
    .catch((err) => {
      console.error('failed to commit file contents to sandbox', err)
      dispatch({ type: FINISH_COMMIT_FILE_CONTENTS, error: err })
    })
}

export function setup(apiFilesystemUrl) {
  return (dispatch, getState) => {
    _log('sbcrfilesystem setup')
    dispatch({
      type: SETUP,
      apiFilesystemUrl,
    })

    // Process any queued directory listing requests we might have been waiting on
    const numDirectoryPathsQueued =
      getState().sbcrfilesystem.directoryPathsToFetch.length
    _log(
      `processing ${numDirectoryPathsQueued} queued directory listing request(s) on init`
    )
    processNextDirectoryListingRequest(dispatch, getState)

    // Process any queued file contents requests as well
    const numFilePathsQueued = getState().sbcrfilesystem.filePathsToFetch.length
    _log(
      `processing ${numFilePathsQueued} queued file contents request(s) on init`
    )
    processNextFileContentsRequest(dispatch, getState)
  }
}

export function teardown() {
  return (dispatch, getState) => {
    _log('sbcrfilesystem teardown')
    dispatch({ type: TEARDOWN })
  }
}

export function requestDirectoryListing(path) {
  return (dispatch, getState) => {
    // Determine whether we've already fetched a listing for this path: if so, just ignore the request
    const initialState = getState().sbcrfilesystem
    if (initialState.directoryPathsFetched.includes(path)) {
      _log(
        `directory listing for '${path}' has already been fetched; ignoring request`
      )
      return
    }

    // If this path is already in the queue to be fetched later, ignore it as well
    if (initialState.directoryPathsToFetch.includes(path)) {
      _log(
        `directory listing for '${path}' is already queued to be fetched; ignoring request`
      )
      return
    }

    // Push the path onto the back of the queue to record that we want to fetch a listing for it
    _log(`queueing request to fetch directory listing for '${path}'`)
    dispatch({ type: QUEUE_DIRECTORY_LISTING_REQUEST, path })

    // If we have a sandbox and we're not currently processing requests, initiate the request right away
    const isInitialized = !!initialState.apiUrl
    const isFetching = initialState.fetchingDirectoryListing
    if (isInitialized && !isFetching) {
      _log('queued directory request will be processed immediately')
      processNextDirectoryListingRequest(dispatch, getState)
    } else {
      const reasonDesc = isInitialized
        ? 'a request is already in progress'
        : 'sandbox is not yet initialized'
      _log(`queued directory request deferred: ${reasonDesc}`)
    }
  }
}

export function requestFileLoad(path) {
  return (dispatch, getState) => {
    // Push the file's path onto the back of the queue to record that we want to fetch its contents
    _log(`queueing request to fetch file contents for '${path}'`)
    dispatch({ type: QUEUE_FILE_CONTENTS_REQUEST, path })

    // If we have a sandbox and we're not currently processing requests, initiate the request right away
    const initialState = getState().sbcrfilesystem
    const isInitialized = !!initialState.apiUrl
    const isFetching = initialState.fetchingFileContents
    if (isInitialized && !isFetching) {
      _log('queued file request will be processed immediately')
      processNextFileContentsRequest(dispatch, getState)
    } else {
      const reasonDesc = isInitialized
        ? 'a request is already in progress'
        : 'sandbox is not yet initialized'
      _log(`queued file request deferred: ${reasonDesc}`)
    }
  }
}

export function requestFileSave(path, newContents) {
  return (dispatch, getState) => {
    // We shouldn't be saving files before we've loaded them from a sandbox, so abort outright if not initialized
    const initialState = getState().sbcrfilesystem
    if (!initialState.apiUrl) {
      _warn(
        `ignoring request to commit new contents for file '${path}': sandbox not initialized`
      )
      return
    }

    // Push the file path and contents onto the back of the queue to record that we want to commit the new version
    _log(
      `queueing request to commit new contents for file '${path}':\n${newContents}`
    )
    dispatch({ type: QUEUE_FILE_COMMIT_REQUEST, path, contents: newContents })

    // If we're not currently processing requests, initiate the request right away
    const isFetching = initialState.committingFileContents
    if (!initialState.committingFileContents) {
      _log('queued file commit will be processed immediately')
      processNextFileCommitRequest(dispatch, getState)
    } else {
      _log('queued file commit deferred: a request is already in progress')
    }
  }
}

export function invalidateAllFileContents() {
  return (dispatch, getState) => {
    const fileDetailsByPath = getState().sbcrfilesystem.fileDetailsByPath
    _log(
      `invalidated contents of ${Object.keys(fileDetailsByPath).length} files`
    )

    for (const path of Object.keys(fileDetailsByPath)) {
      dispatch(requestFileLoad(path))
    }
  }
}
