import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import settings from 'settings'
import { api } from 'app/views/utils/api'

import SubscriptionHelp from 'app/views/components/Subscription/SubscriptionHelp'

import SubmitFlagInstructions from './SubmitFlagInstructions'
import SubmitFlagModal from './SubmitFlagModal'
import CompleteChallengeModal from './CompleteChallengeModal'

function HackingChallengeInstructions(props) {
  const { contentId, description, userId } = props
  const [wantsToSubmitFlag, setWantsToSubmitFlag] = useState(false)
  const [wantsToCompleteChallenge, setWantsToCompleteChallenge] = useState(true)

  const [isSubmittingFlag, setIsSubmittingFlag] = useState(false)
  const [submitFlagResponse, setSubmitFlagResponse] = useState({
    success: false,
    message: null,
  })
  const [submitFlagError, setSubmitFlagError] = useState(null)
  const canSubmitFlag = !isSubmittingFlag && userId && userId.length > 0

  // If submission was unsuccessful (either because the request itself failed,
  // or because the flag wasn't correct), format a message to show the user
  const submitFlagErrorText = submitFlagResponse.success
    ? null
    : submitFlagError
    ? `Unable to submit flag: ${submitFlagError.toString()}`
    : submitFlagResponse.message

  // Clear the error when the dialog is closed and we've finish submitting,
  // to ensure that we don't see an old error when we re-open the dialog
  useEffect(() => {
    if (submitFlagErrorText && !wantsToSubmitFlag && !isSubmittingFlag) {
      setSubmitFlagError(null)
      setSubmitFlagResponse({ success: false, message: null })
    }
  }, [wantsToSubmitFlag, isSubmittingFlag])

  // When the user enters a flag value, submit it to api-hacker via
  // POST /user/<user_uuid>/content/<content_uuid>
  function submitFlag(flagText) {
    if (canSubmitFlag) {
      setIsSubmittingFlag(true)
      const url = `${settings.urls.hacker}/user/${userId}/content/${contentId}`
      const data = { proof: flagText }
      api({ method: 'post', url, withAuthToken: true, data })
        .then((response) => {
          setIsSubmittingFlag(false)
          if (response.data.correct === true) {
            setSubmitFlagResponse({ success: true, message: null })
          } else {
            const message = response.data.error || 'Incorrect flag.'
            setSubmitFlagResponse({ success: false, message })
          }
        })
        .catch((error) => {
          console.error('error posting flag', error)
          setIsSubmittingFlag(false)
          setSubmitFlagError(error)
        })
    }
  }

  // Navigate back to the root route when the user has submitted the correct flag and acknowledged
  // their completion of the challenge
  const { history } = props
  function completeChallenge() {
    history.push('/')
  }

  const hasSucceeded = submitFlagResponse.success
  return (
    <>
      <p style={{ marginTop: 20 }}>{description}</p>
      <SubmitFlagInstructions
        canSubmitFlag={canSubmitFlag}
        hasSucceeded={hasSucceeded}
        onClickSubmitFlag={() => setWantsToSubmitFlag(true)}
        onClickCompleteChallenge={() => setWantsToCompleteChallenge(true)}
      />
      <SubscriptionHelp contentType='hacking_challenge' />
      {wantsToSubmitFlag && !hasSucceeded && (
        <SubmitFlagModal
          canSubmit={canSubmitFlag}
          isSubmitting={isSubmittingFlag}
          errorText={submitFlagErrorText}
          onSubmit={submitFlag}
          onClose={() => setWantsToSubmitFlag(false)}
        />
      )}
      {hasSucceeded && wantsToCompleteChallenge && (
        <CompleteChallengeModal
          onClickDone={completeChallenge}
          onClose={() => setWantsToCompleteChallenge(false)}
        />
      )}
    </>
  )
}
HackingChallengeInstructions.propTypes = {
  history: PropTypes.object.isRequired,
  contentId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  userId: PropTypes.string,
}

export default withRouter(
  connect(
    (state) => ({
      userId: state.hacker.profile.user_id,
    }),
    {}
  )(HackingChallengeInstructions)
)
