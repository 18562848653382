import React from 'react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import NewContentIcon from './NewContentIcon'
import OldContentIcon from './OldContentIcon'

function ContentIcon(props) {
  if (props.flags.hack1297NewContentIcon) {
    return <NewContentIcon {...props} />
  } else {
    return <OldContentIcon {...props} />
  }
}

export default withLDConsumer()(ContentIcon)
