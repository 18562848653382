import React from 'react'

// Components
import Modal from 'app/views/components/UIkit/Modal'

const ModalDefaultPlan = () => (
  <Modal
    id='modal-info-default-plan'
    title='Default Plan'
    body={
      <div>
        <p>
          The default training plan is the plan that gets assigned to users if
          they are not part of a Team.
        </p>
        <p>
          It is also used as the plan that gets automatically assigned to new
          teams created through an SSO integration (if you are not creating
          teams via SSO).
        </p>
        <p>Your organization can only have one default plan.</p>
        <p className='text-right'>
          <button
            className='uk-button uk-button-primary uk-modal-close'
            type='button'
          >
            Ok
          </button>
        </p>
        <button
          className='uk-modal-close-outside'
          data-uk-close
          type='button'
        />
      </div>
    }
  />
)

export default ModalDefaultPlan
