import PropTypes from 'prop-types'
import React from 'react'

import ConfirmButton from 'app/views/components/ConfirmButton'
import Icon from 'app/views/components/Icon'

const propTypes = {
  availableLicenses: PropTypes.number,
  handleClickAddLicenseToUser: PropTypes.func.isRequired,
  handleClickRemoveLicenseFromUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    has_license: PropTypes.bool,
    role: PropTypes.string.isRequired,
    steps_completed: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
}

const defaultProps = {
  availableLicenses: 0,
}

const LicenseMenu = ({
  availableLicenses,
  handleClickAddLicenseToUser,
  handleClickRemoveLicenseFromUser,
  availableLicenseTypes,
  user,
  expired,
  borderRadius,
  display,
}) => {
  return (
    <div
      className='uk-inline uk-width-1-1'
      style={display ? {} : { display: 'none' }}
    >
      <ConfirmButton
        borderRadius={borderRadius}
        isSmall
        disableClick
        text={
          <>
            <Icon
              name='add-circle'
              ratio={0.7}
              style={{ position: 'absolute', left: 10, color: '#666' }}
            />
            <span style={{ color: '#666' }}>Add License</span>
          </>
        }
      />
      <div
        data-uk-dropdown='mode: click; pos: center; animation: uk-animation-slide-left-small; duration: 200;'
        className='text-left'
        id='license-dropdown'
      >
        <ul className='uk-nav uk-dropdown-nav'>
          <li className='uk-nav-header'>Licenses</li>
          {user.has_license && user.steps_completed <= 20 && !expired && (
            <li>
              <a
                onClick={() => {
                  handleClickRemoveLicenseFromUser(
                    user.uuid,
                    user.license_type,
                    user.license_type_uuid
                  )
                }}
              >
                <Icon
                  className='uk-margin-small-right'
                  ratio={0.7}
                  name='subtract-circle'
                />
                {(user.has_license && user.steps_completed > 20) || expired
                  ? 'Cannot Remove License'
                  : 'Remove License'}
              </a>
            </li>
          )}
          {((user.has_license && user.steps_completed > 20) || expired) && (
            <li className='text-muted' style={{ padding: '5px 0' }}>
              <span>
                Cannot remove license
                <Icon
                  as='a'
                  href='#'
                  icon='information'
                  ratio={0.7}
                  data-uk-toggle='target: #modal-cannot-remove-license'
                  style={{ marginLeft: 8 }}
                />
              </span>
            </li>
          )}

          <div id='modal-cannot-remove-license' data-uk-modal>
            <div className='uk-modal-dialog uk-modal-body'>
              <h2>Cannot Remove License</h2>
              <p>
                We do not allow licenses to be removed or reassigned once a user
                has already gone through several lessons.
              </p>
              <p>
                If you have questions about this policy, you may reach out to
                HackEDU at &nbsp;
                <a href='mailto:support@securityjourney.com'>
                  support@securityjourney.com
                </a>
                .
              </p>
              <p className='text-right'>
                <button
                  className='uk-button uk-button-primary uk-modal-close'
                  type='button'
                >
                  Ok
                </button>
              </p>
              <button
                className='uk-modal-close-outside'
                type='button'
                data-uk-close
              />
            </div>
          </div>

          {user.has_license === false && availableLicenses > 0 && (
            <>
              {availableLicenseTypes.map((licenseType) => (
                <li key={`${licenseType.uuid || 'full-license'}`}>
                  <a
                    style={{ display: 'flex' }}
                    onClick={() => {
                      handleClickAddLicenseToUser(
                        user.uuid,
                        licenseType.uuid,
                        licenseType.name
                      )
                    }}
                  >
                    <Icon
                      className='uk-margin-small-right'
                      name='add-circle'
                      ratio={0.7}
                    />
                    Add {licenseType.name}
                  </a>
                </li>
              ))}
            </>
          )}
          {!user.has_license && availableLicenses <= 0 && (
            <li>
              You have no licenses available.
              <br />
              Contact sales@hackedu.io for additional licenses.
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

LicenseMenu.propTypes = propTypes
LicenseMenu.defaultProps = defaultProps

export default LicenseMenu
