import React from 'react'
import PropTypes from 'prop-types'

import ContentModal from '../../../common/ContentModal'
import ContentModalCloseButton from '../../../common/ContentModalCloseButton'

import HighlightHintBody from './HighlightHintBody'
import BasicHintBody from './BasicHintBody'
import HintSelectionList from './HintSelectionList'

import './style.less'

function CodingChallengeHintModal(props) {
  const {
    hints,
    selectedHintIndex,
    onSelectHint,
    onConfirmHighlightHint,
    onClose,
  } = props
  const hint = selectedHintIndex >= 0 ? hints[selectedHintIndex] : null
  return (
    <ContentModal id='coding-challenge-hint-modal' onClose={onClose}>
      <ContentModalCloseButton onClick={onClose} />
      <div style={{ padding: 40 }}>
        {hint ? (
          hint.hintType === 'highlight' ? (
            <HighlightHintBody
              title={hint.title}
              onClickConfirm={() => onConfirmHighlightHint(selectedHintIndex)}
              onClickBack={() => onSelectHint(-1)}
            />
          ) : (
            <BasicHintBody
              title={hint.title}
              markdown={
                hint.state.error
                  ? `ERROR: ${hint.state.error}`
                  : hint.state.isLoading
                  ? '...'
                  : hint.state.data.markdown
              }
              onClickBack={() => onSelectHint(-1)}
            />
          )
        ) : (
          <HintSelectionList hints={hints} onSelectHint={onSelectHint} />
        )}
      </div>
    </ContentModal>
  )
}
CodingChallengeHintModal.propTypes = {
  hints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      markdown: PropTypes.string.isRequired,
      hintType: PropTypes.oneOf(['default', 'basic', 'highlight']).isRequired,
      points: PropTypes.number.isRequired,
      hasBeenUsed: PropTypes.bool.isRequired,
      state: PropTypes.shape({
        isLoading: PropTypes.bool,
        error: PropTypes.any,
        data: PropTypes.object,
      }).isRequired,
    })
  ).isRequired,
  selectedHintIndex: PropTypes.number.isRequired,
  onSelectHint: PropTypes.func.isRequired,
  onConfirmHighlightHint: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CodingChallengeHintModal
