import React from 'react'
import PropTypes from 'prop-types'

import ContentModal from '../../ContentModal'

import { formatUrlErrorHeader, formatUrlErrorMessage } from './util'

function UnsupportedUrlModal(props) {
  const { problem, data, validBaseUrls, onClose } = props
  return (
    <ContentModal id='sandbox-unsupported-url' onClose={onClose}>
      <div style={{ padding: 40 }}>
        <h3>{formatUrlErrorHeader(problem)}</h3>
        <p>{formatUrlErrorMessage(problem, data, validBaseUrls)}</p>
      </div>
    </ContentModal>
  )
}
UnsupportedUrlModal.propTypes = {
  problem: PropTypes.oneOf(['bad-protocol', 'bad-domain', 'malformed-url'])
    .isRequired,
  data: PropTypes.string.isRequired,
  validBaseUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default UnsupportedUrlModal
