import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { HBox } from 'app/views/core/Box'
import { Icon } from 'app/views/components/GustavoIcon'

/**
 * Local layout/styling components.
 */
const Container = ({ id, active, collapsed, onClick, children }) => (
  <HBox
    id={id}
    style={{
      alignItems: 'center',
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      cursor: 'pointer',
      lineHeight: 2,
      whiteSpace: 'nowrap',
      position: 'relative', // Allow absolute positioning of badge
      zIndex: 100, // Ensure that badge is behind div so it doesn't steal mouse input
    }}
    onClick={onClick}
    className={cx({
      'border-b-green text-green': active,
      'border-b-white text-inherit': !active,
      'px-5': collapsed,
      'px-8': !collapsed,
    })}
  >
    {children}
  </HBox>
)
const Label = ({ text }) => (
  <div style={{ fontSize: 12, marginLeft: 10 }}>{text}</div>
)
const Badge = ({ collapsed, count }) => (
  <div
    className='uk-label uk-primary bg-green'
    style={{
      borderRadius: 8,
      position: 'absolute',
      right: collapsed ? 4 : 0,
      bottom: collapsed ? 8 : 20,
      zIndex: 99,
      padding: '0 7px',
    }}
  >
    {count}
  </div>
)

/**
 * A single tab in the bar at the top of the Content UI work panel.
 */
function WorkPanelTab(props) {
  const { id, label, iconName, badgeCount, active, onActivate, collapsed } =
    props
  return (
    <Container
      id={id}
      active={active}
      collapsed={collapsed}
      onClick={onActivate}
    >
      <Icon name={iconName} />
      {!collapsed && <Label text={label} />}
      {(badgeCount || 0) > 0 && (
        <Badge collapsed={collapsed} count={badgeCount} />
      )}
    </Container>
  )
}
WorkPanelTab.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  badgeCount: PropTypes.number,
  active: PropTypes.bool.isRequired,
  onActivate: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
}

export default WorkPanelTab
