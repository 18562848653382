import React from 'react'
import { Link, withRouter } from 'react-router-dom'

// Settings
import settings from 'settings'

// Utils
import analytics from 'app/views/utils/analytics'
import { Button } from 'app/views/components/GustavoButton'

class WwwNav extends React.Component {
  render() {
    return (
      <div
        className='uk-container uk-container-expand uk-sticky uk-sticky-fixed uk-background-secondary uk-light'
        style={{ background: '#161616' }}
      >
        <nav
          className='uk-navbar-container uk-navbar uk-navbar-transparent uk-light'
          data-uk-navbar=''
          data-uk-sticky=''
          style={{ height: 80 }}
        >
          <div className='uk-navbar-left uk-light'>
            <Link className='uk-navbar-item uk-logo uk-navbar-toggle' to='/'>
              <img
                src='/static/images/SJ_HE_LogoWhiteHorizontal22.png'
                className='w-[300px]'
                alt='Logo'
              />
            </Link>
          </div>
          <div className='uk-navbar-right'>
            <ul className='uk-navbar-nav'>
              <li>
                <a href={`${settings.urls.www}/contact`}>Contact</a>
              </li>
              <li>
                <a
                  href={`${settings.urls.www}/secure-development-training`}
                  onClick={() => {
                    analytics.track('click-navi-learn-more')
                  }}
                >
                  <Button
                    content='Learn More'
                    className='uk-button uk-button-default tm-button-default'
                    extraClassName='tm-button-primary'
                  />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}

export default withRouter(WwwNav)
