import 'flatpickr/dist/themes/material_blue.css'

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import settings from 'settings'
import UIkit from 'uikit'

// Components
import Loader from 'app/views/components/Loader'
import Icon from 'app/views/components/Icon'
import PhaseCheckbox from 'app/views/components/PhaseCheckbox'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import SecondaryButton from '../../../components/Buttons/SecondaryButton'

// Redux
import {
  getPlans,
  activateAutomatedPlan,
  updateRequiredContentRecommendationSettings,
  setNoTraining,
} from 'app/state/modules/plan'
import { createTeam } from 'app/state/modules/team'
import { getContent } from 'app/state/modules/content'
// Utils
import analytics from 'app/views/utils/analytics'
import OrganizationLayout from '../components/Layout'

class OrganizationsTeamsAdd extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gracePeriod: 30,
      maxContent: 4,
      vulnerabilityRefreshDays: 45,
      trainingTerm: 'monthly',
      requiredTraining: [],
      name: '',
      planId: null,
      customPlanType: 'scratch',
      contentType: 'lessons',
      planType: null,
      mustPass: null,
      step: 0,
      hasSetInitialPlan: false,
      selectedContentIds: [],
    }

    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangePlan = this.handleChangePlan.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getDisabledNextButton = this.getDisabledNextButton.bind(this)
    this.setSelectedContentIds = this.setSelectedContentIds.bind(this)
  }

  setSelectedContentIds(contentIds) {
    this.setState({
      selectedContentIds: contentIds,
    })
  }

  componentDidMount() {
    this.props.getContent(this.props.match.params.organization_uuid, true)
    this.props.getPlans(this.props.match.params.organization_uuid)
    analytics.page('page-organizations-teams-add')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.plans.length > 0 &&
      nextProps.plans !== this.props.plans &&
      this.state.hasSetInitialPlan === false
    ) {
      this.setState({ hasSetInitialPlan: true })
    }
    if (nextProps.creatingTeamError !== this.props.creatingTeamError) {
      this.setState({ error: nextProps.creatingTeamError })
    }
  }

  getDisabledNextButton() {
    switch (this.state.step) {
      case 1.5:
        if (isNaN(parseInt(this.state.maxContent, 10))) {
          return true
        }
        if (isNaN(parseInt(this.state.vulnerabilityRefreshDays, 10))) {
          return true
        }
        if (isNaN(parseInt(this.state.gracePeriod, 10))) {
          return true
        }
        return false
      case 1:
        if (!this.state.planType) {
          return true
        }
        return false
      case 0:
      default:
        if (this.state.name.trim() === '') {
          return true
        }
        return false
    }
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value })
  }

  handleChangePlan(event) {
    this.setState({ planId: event.target.value })
  }

  handleSubmit() {
    this.props
      .createTeam(
        this.props.match.params.organization_uuid,
        this.state.name,
        this.state.planId,
        this.state.mustPass,
        this.state.planType === 'using_recommendation_engine'
          ? JSON.stringify({
              required_training: Object.keys(this.state.requiredTraining),
              max_content: parseInt(this.state.maxContent, 10),
              vulnerability_refresh_days: parseInt(
                this.state.vulnerabilityRefreshDays,
                10
              ),
              grace_period: parseInt(this.state.gracePeriod, 10),
              training_term: this.state.trainingTerm,
            })
          : null
      )
      .then((teamId) => {
        if (teamId) {
          if (this.state.planType === 'using_recommendation_engine') {
            this.props.activateAutomatedPlan(
              this.props.match.params.organization_uuid,
              teamId,
              () => {
                this.props.history.push(
                  `/admin/${this.props.match.params.organization_uuid}/teams/${teamId}/users`
                )
              }
            )
          } else if (
            this.state.planType === 'custom_plan' &&
            this.state.customPlanType === 'scratch'
          ) {
            this.props.history.push({
              pathname: `/admin/${this.props.match.params.organization_uuid}/plans/add/team`,
              state: {
                defaultTeamUUID: teamId || undefined,
              },
            })
          } else if (this.state.planType === 'no_training') {
            this.props.setNoTraining(
              this.props.match.params.organization_uuid,
              teamId,
              () => {
                this.props.history.push(
                  `/admin/${this.props.match.params.organization_uuid}/teams/${teamId}/users`
                )
              }
            )
          } else {
            this.props.history.push(
              `/admin/${this.props.match.params.organization_uuid}/teams/${teamId}/users`
            )
          }
        }
      })
      .catch(() => {
        UIkit.modal.alert(
          'A team with this name already exists. You can\'t have more than one team with the same name.',
          {
            stack: true,
          }
        )
      })
  }

  renderClonnedPlans() {
    const organizationPlans = this.props.clonePlanList.filter((p) => !p.team)
    const teamPlans = this.props.clonePlanList.filter((p) => p.team)
    return (
      <>
        {organizationPlans.length === 0 && teamPlans.length && (
          <p>No plans in organization</p>
        )}
        {organizationPlans.length > 0 && (
          <i style={{ fontSize: 15, fontWeight: 'bold' }}>Organization plans</i>
        )}
        {organizationPlans.map((plan) => (
          <label style={{ fontSize: 15 }} key={plan.uuid}>
            <input
              checked={this.state.planId === plan.uuid}
              onChange={() =>
                this.setState({
                  planId: plan.uuid,
                })
              }
              className='uk-radio'
              type='radio'
              name='clonned_plan'
            />
            &nbsp; &nbsp;
            {plan.title}
          </label>
        ))}

        {teamPlans.length > 0 && (
          <i style={{ fontSize: 15, fontWeight: 'bold' }}>Teams plans</i>
        )}
        {teamPlans.map((plan) => (
          <label style={{ fontSize: 15 }} key={plan.uuid}>
            <input
              checked={this.state.planId === plan.uuid}
              onChange={() =>
                this.setState({
                  planId: plan.uuid,
                })
              }
              className='uk-radio'
              type='radio'
              name='clonned_plan'
            />
            &nbsp; &nbsp;
            {plan.title} {'- Team '}
            {plan.team.name}
          </label>
        ))}
      </>
    )
  }

  renderTitle(content) {
    if (content.type === 'coding_challenge') {
      return (
        <span>
          {`${settings.apps[content.metadata.app]}: ${content.title} (${
            settings.engines[content.metadata.language.name]
          } - ${settings.frameworks[content.metadata.framework.name]})`}
        </span>
      )
    }
    return content.title
  }

  renderAdaptiveTrainingSetings() {
    return (
      <>
        <div className='uk-margin'>
          <h3>Adaptive Training Plan Settings</h3>
          <label
            className='uk-form-label text-right'
            htmlFor='form-horizontal-text'
          >
            Training Term (Frequency of training plan updates)
          </label>
          <div data-uk-grid>
            <div className='uk-width-1-1'>
              <select
                className='uk-select'
                value={this.state.trainingTerm}
                onChange={(e) => {
                  this.setState({
                    trainingTerm: e.target.value,
                  })
                }}
              >
                <option value='biweekly'>Bi-Weekly</option>
                <option value='monthly'>Monthly</option>
                <option value='quarterly'>Quarterly</option>
              </select>
            </div>
          </div>
        </div>
        <div className='uk-margin'>
          <label
            className='uk-form-label text-right'
            htmlFor='form-horizontal-text'
          >
            Maximum content per training term
            <br />
            (Each lesson takes approximately 30 min)
          </label>
          <div data-uk-grid>
            <div className='uk-width-1-1'>
              <input
                onChange={(e) => {
                  this.setState({
                    maxContent: e.target.value,
                  })
                }}
                className='uk-input'
                type='text'
                value={this.state.maxContent}
              />
            </div>
          </div>
        </div>
        <div className='uk-margin'>
          <label
            className='uk-form-label text-right'
            htmlFor='form-horizontal-text'
          >
            Grace Period (Days)
            <Icon
              className='uk-margin-small-left'
              name='information'
              ratio={0.8}
              onClick={() => {
                UIkit.modal.alert(
                  `
              <h2>Grace Period</h2><p>The grace period is the number of days after a user gets onboarded before their training can be considered "past due."  This way, if you are using a "Monthly" training cycle and a user is onboarded right before the end of the month, they won't immediately be considered behind.</p>
              `,
                  { stack: true }
                )
              }}
            />
          </label>
          <div data-uk-grid>
            <div className='uk-width-1-1'>
              <input
                onChange={(e) => {
                  this.setState({
                    gracePeriod: e.target.value,
                  })
                }}
                className='uk-input'
                type='text'
                value={this.state.gracePeriod}
              />
            </div>
          </div>
        </div>
        <div className='uk-margin'>
          <label
            className='uk-form-label text-right'
            htmlFor='form-horizontal-text'
          >
            Vulnerability Refresh (Days)
            <Icon
              className='uk-margin-small-left'
              name='information'
              ratio={0.8}
              onClick={() => {
                UIkit.modal.alert(
                  `
              <h2>Vulnerability Refresh Time</h2><p style="text-align: justify">If a new vulnerability is found through one of your integrations, the vulnerability refresh time is the number of days that need to have passed before a user is required to do another lesson covering that vulnerability.</p>
              <p style="text-align: justify">For example, if a user does a SQL Injection lesson on January 1, and then a new vulnerability is found in your SAST/DAST tool on January 15, the user will only be assigned another SQL Injection lesson if your "Vulnerability Refresh Time" is less than 15 days.</p>
              <p style="text-align: justify">We typically recommend a 45 day period.</p>
              `,
                  { stack: true }
                )
              }}
            />
          </label>
          <div data-uk-grid>
            <div className='uk-width-1-1'>
              <input
                className='uk-input'
                type='text'
                onChange={(e) => {
                  this.setState({
                    vulnerabilityRefreshDays: e.target.value,
                  })
                }}
                value={this.state.vulnerabilityRefreshDays}
              />
            </div>
          </div>
        </div>
        <div className='uk-margin'>
          <label
            className='uk-form-label text-right'
            htmlFor='form-horizontal-text'
          >
            Additional training the team is required to take
          </label>
          <div className='uk-form-controls uk-form-controls-text'>
            <label className='text-sm'>
              <input
                className='uk-radio uk-margin-small-right'
                type='radio'
                onChange={() =>
                  this.setState({
                    contentType: 'lessons',
                  })
                }
                name='lessons'
                checked={this.state.contentType === 'lessons'}
              />
              Lesson
            </label>
            <br />
            <label className='text-sm'>
              <input
                onChange={() =>
                  this.setState({
                    contentType: 'coding_challenges',
                  })
                }
                checked={this.state.contentType === 'coding_challenges'}
                className='uk-radio uk-margin-small-right'
                type='radio'
                name='coding_challenges'
              />
              Coding Challenges
            </label>
            <br />
          </div>
          <div className='uk-form-controls'>
            <div data-uk-grid>
              <PhaseCheckbox
                selectedContentIds={this.state.selectedContentIds}
                onSelectedContentChange={this.setSelectedContentIds}
                contentType={this.state.contentType}
                content={this.props.content}
              />
            </div>
          </div>
          <div id='modal-choose-lessons' data-uk-modal>
            <div className='uk-modal-dialog uk-modal-body'>
              <h2>Choose Lessons</h2>
              <p>
                Drag lessons from the left column into the "Selected Lessons"
                column to add them to your training plan. Drag the lessons up
                and down to order them.
              </p>
              <p className='text-right'>
                <PrimaryButton label={'Ok'} size={'small'} />
              </p>
              <button
                className='uk-modal-close-outside'
                type='button'
                data-uk-close
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  renderSteps() {
    switch (this.state.step) {
      case 1:
        return (
          <div>
            <h3>Training Settings</h3>
            <div className='uk-margin'>
              <div className='uk-form-label'>
                Which type of training plan do you want to assign to this team?
              </div>
              <div className='uk-form-controls uk-form-controls-text'>
                <label style={{ fontSize: 15 }}>
                  <input
                    onChange={() =>
                      this.setState({
                        planType: 'using_recommendation_engine',
                        selectedContentIds: settings.owasp.map(
                          (content) => content.uuid
                        ),
                      })
                    }
                    className='uk-radio uk-margin-small-right'
                    type='radio'
                    name='plan_types'
                    checked={
                      this.state.planType === 'using_recommendation_engine'
                    }
                  />
                  Adaptive Training Plan
                </label>
                <br />
                <label style={{ fontSize: 15 }}>
                  <input
                    checked={this.state.planType === 'custom_plan'}
                    onChange={() =>
                      this.setState({
                        planType: 'custom_plan',
                      })
                    }
                    className='uk-radio uk-margin-small-right'
                    type='radio'
                    name='plan_types'
                  />
                  Use Custom Plan
                </label>
                <br />
                {this.state.planType === 'custom_plan' && (
                  <div className='uk-form-controls uk-form-controls-text'>
                    <div className='uk-margin-left'>
                      <label style={{ fontSize: 15 }}>
                        <input
                          checked={this.state.customPlanType === 'scratch'}
                          onChange={() =>
                            this.setState({
                              customPlanType: 'scratch',
                              planId: null,
                            })
                          }
                          className='uk-radio uk-margin-small-right'
                          type='radio'
                          name='custom_options'
                        />
                        Start from scratch
                      </label>
                      <br />
                      <label style={{ fontSize: 15 }}>
                        <input
                          checked={this.state.customPlanType === 'clone'}
                          onChange={() =>
                            this.setState({
                              customPlanType: 'clone',
                              planId: null,
                            })
                          }
                          className='uk-radio uk-margin-small-right'
                          type='radio'
                          name='custom_options'
                        />
                        Clone an existing plan
                      </label>
                      {this.state.customPlanType === 'clone' &&
                        this.state.planType === 'custom_plan' && (
                          <div className='flex flex-col uk-margin-left'>
                            {this.renderClonnedPlans()}
                          </div>
                        )}
                    </div>
                  </div>
                )}
                <label style={{ fontSize: 15 }}>
                  <input
                    checked={this.state.planType === 'organization_default'}
                    onChange={() =>
                      this.setState({
                        planType: 'organization_default',
                        planId: null,
                      })
                    }
                    className='uk-radio uk-margin-small-right'
                    type='radio'
                    name='plan_types'
                  />
                  Use Organization Default
                </label>
                <br />
                <label style={{ fontSize: 15 }}>
                  <input
                    checked={this.state.planType === 'no_training'}
                    onChange={() =>
                      this.setState({
                        planType: 'no_training',
                      })
                    }
                    className='uk-radio uk-margin-small-right'
                    type='radio'
                    name='plan_types'
                  />
                  No Training
                </label>
              </div>
            </div>
          </div>
        )
      case 1.5:
        return this.renderAdaptiveTrainingSetings()
      case 2:
        return (
          <div>
            <h3>Coding Test Required</h3>
            <div className='uk-margin'>
              <div className='uk-form-label'>
                Do you want members of this team to be required to pass the
                coding portion of each lesson?
              </div>
              <div className='uk-form-controls uk-form-controls-text'>
                <label style={{ fontSize: 15 }}>
                  <input
                    onChange={() =>
                      this.setState({
                        mustPass: null,
                      })
                    }
                    className='uk-radio uk-margin-small-right'
                    type='radio'
                    name='must_pass_radio'
                    checked={this.state.mustPass === null}
                  />
                  Use Organization Default
                </label>
                <br />
                <label style={{ fontSize: 15 }}>
                  <input
                    checked={this.state.mustPass === true}
                    onChange={() =>
                      this.setState({
                        mustPass: true,
                      })
                    }
                    className='uk-radio uk-margin-small-right'
                    type='radio'
                    name='must_pass_radio'
                  />
                  Users must complete coding section
                </label>
                <br />
                <label style={{ fontSize: 15 }}>
                  <input
                    checked={this.state.mustPass === false}
                    onChange={() =>
                      this.setState({
                        mustPass: false,
                      })
                    }
                    className='uk-radio uk-margin-small-right'
                    type='radio'
                    name='must_pass_radio'
                  />
                  Users can skip the coding section
                </label>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div>
            <h3>Team Name</h3>
            <div className='uk-margin'>
              <label className='uk-form-label'>
                What's the name of the team you want to create?
              </label>
              <div className='uk-form-controls'>
                <input
                  className='uk-input'
                  type='text'
                  data-test-id='input-team-name'
                  placeholder=''
                  value={this.state.name}
                  onChange={this.handleChangeName}
                />
              </div>
            </div>
          </div>
        )
    }
  }

  render() {
    if (this.props.plans.length === 0) {
      return <Loader visible />
    }
    const { step } = this.state
    return (
      <OrganizationLayout
        data-test-id='organizations-teams-component'
        currentOrganizationId={this.props.match.params.organization_uuid}
        {...this.props}
        active='teams'
      >
        <h1>Adding Team</h1>
        <div className='uk-width-xxlarge'>
          {this.renderSteps()}
          <div>
            {step > 0 && (
              <SecondaryButton
                onClick={() => {
                  if (step === 1.5) {
                    return this.setState({
                      step: 1,
                    })
                  }
                  return this.setState({
                    step: step - 1,
                  })
                }}
                customCss={'mr-6'}
                label='Previous'
                size={'small'}
              />
            )}
            <PrimaryButton
              active={!this.getDisabledNextButton()}
              onClick={() => {
                if (this.state.step === 1.5) {
                  const contentIds = {}
                  this.state.selectedContentIds.forEach((contentId) => {
                    contentIds[contentId] = {
                      id: contentId,
                    }
                  })
                  return this.setState({
                    step: 2,
                    requiredTraining: contentIds,
                  })
                }
                if (
                  this.state.step === 1 &&
                  this.state.planType === 'using_recommendation_engine'
                ) {
                  return this.setState({
                    step: 1.5,
                  })
                }
                if (this.state.step !== 2) {
                  return this.setState({
                    step: step + 1,
                  })
                }
                return this.handleSubmit()
              }}
              customCss={'w-24'}
              size={'small'}
              label={this.state.step === 2 ? 'Finish' : 'Next'}
            />
          </div>
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  // hacker
  organizations: state.hacker.organizationsList,

  // plans
  loadingPlans: state.plan.loadingPlans,
  hasLoadedPlans: state.plan.hasLoadedPlans,
  plans: state.plan.plans,
  organizationsHash: state.hacker.organizationsHash,

  content: state.content.content,
  // team
  creatingTeam: state.team.creatingTeam,
  creatingTeamError: state.team.creatingTeamError,
  clonePlanList: state.plan.clonePlanList,
})

export default withRouter(
  connect(mapStateToProps, {
    updateRequiredContentRecommendationSettings,
    createTeam,
    getContent,
    getPlans,
    activateAutomatedPlan,
    setNoTraining,
  })(OrganizationsTeamsAdd)
)
