import React from 'react'

export default function (props) {
  return (
    <div
      className={`uk-card ${
        props.noHover ? '' : 'uk-card-hover'
      }  uk-card-default ${props.isCustom ? 'uk-background-primary' : ''}${
        props.width ? ` ${props.width}` : ''
      }`}
      style={{
        cursor: 'pointer',
        backgroundColor: props.color,
        marginRight: 30,
        marginTop: 30,
        width: 375,
        ...(props.style || {}),
      }}
      onClick={props.onClick || null}
    >
      <div
        className={`uk-padding ${
          props.isCustom ? 'uk-background-primary' : ''
        } text-center`}
        style={{
          backgroundColor: props.color,
        }}
      >
        {!props.isCustom ? (
          <img
            src={props.imageSrc || `/static/images/integrations/${props.logo}`}
            alt={props.keyName}
            style={{
              // maxWidth: !props.small ? '100%' : '60%',
              height: props.imageHeight || 40,
            }}
            className='uk-margin-top uk-margin-bottom'
          />
        ) : (
          <div
            style={{ height: 40, color: 'white' }}
            className='text-2xl uk-margin-top uk-margin-bottom'
          >
            <span>Custom Integration</span>
            <br />
            <small>
              <i>{props.title}</i>
            </small>
          </div>
        )}
      </div>
      <div className='uk-background-default uk-padding-small'>
        {props.children || (
          <>
            <h4 className='uk-margin-remove-top uk-margin-small-bottom'>
              {props.title}
            </h4>
            <h5 className='uk-margin-remove uk-padding-remove'>
              <strong
                className='integration-card-description'
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {props.description}
              </strong>
            </h5>
            <div className='text-right'>
              {props.active ? (
                <label className='uk-label uk-label-success'>
                  {props.integrations}
                  &nbsp; ACTIVE
                </label>
              ) : (
                <label
                  className='uk-label uk-label-danger'
                  style={{ visibility: 'hidden' }}
                >
                  X
                </label>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
