import React from 'react'
import { Icon, IconProps } from '../GustavoIcon';

interface IconButtonProps extends IconProps {
  position?: 'left' | 'right'
}

export interface ButtonProps {
  icon?: IconButtonProps | undefined | null | false,
  children?: React.ReactNode;
  primary?: boolean;
  borderRadius?: number;
  disabled?: boolean;
  default?: boolean;
  secondary?: boolean;
  danger?: boolean;
  text?: boolean;
  link?: boolean;
  small?: boolean;
  large?: boolean;
  className?: string;
  extraClassName?: string;
  fullwidth?: boolean;
  width?: '1-1' | '1-2' | '1-3' | '2-3' | '1-4' | '3-4' | '1-5' | '2-5' | '3-5' | '4-5' | '1-6' | '4-6' | '5-6' | 'auto' | 'expand' | 'small' | 'medium' | 'large' | 'xlarge' | '2xlarge' | null | undefined;
  onClick?: () => void;
  content?: string | React.ReactNode;
  style?: object | null;
  margin?: boolean | 'small' | 'normal' | 'large' | 'none';
  isLoading?: boolean;
  marginRemove?: ('top' | 'bottom' | 'left' | 'right' | 'adjacent' | 'vertical' | 'first-child' | 'last-child')[] | null;
  'uk-tooltip'?: undefined | string
}



export const Button = ({
  children = null,
  style = {},
  'uk-tooltip': ukTooltip = undefined,
  className = undefined,
  primary = false,
  secondary = false,
  default: defaultProp = false,
  extraClassName = undefined,
  danger = false,
  link = false,
  text = false,
  small = false,
  large = false,
  fullwidth = false,
  width = undefined,
  borderRadius = 20,
  disabled = false,
  onClick,
  content = null,
  margin = false,
  marginRemove = null,
  isLoading = false,
  icon
}: ButtonProps): JSX.Element => {
  let classes = ['uk-button', 'uk-position-relative']
  const renderStyleClass = () => {
    if (className) {
      return className
    }
    // color modifiers
    if (primary) {
      classes.push('uk-button-primary')
    } else if (secondary) {
      classes.push('uk-button-secondary')
    } else if (danger) {
      classes.push('uk-button-danger')
    } else if (text) {
      classes.push('uk-button-text')
    } else if (link) {
      classes.push('uk-button-link')
    } else if (defaultProp) {
      classes.push('uk-button-default')
    }
    if (margin) {
      switch (margin) {
        case 'large':
          classes.push('uk-margin-large-left uk-margin-large-top uk-margin-large-right uk-margin-large-bottom')
          break
        case 'small':
          classes.push('uk-margin-small-left uk-margin-small-top uk-margin-small-right uk-margin-small-bottom')
          break
        case 'normal':
          classes.push('uk-margin-left uk-margin-top uk-margin-right uk-margin-bottom')
          break
        case 'none':
        default:
          break
      }
    }

    if (Boolean(icon)) {
      classes.push('uk-flex')
      classes.push('uk-flex-center')
      classes.push('uk-flex-middle')
    }

    if (marginRemove) {
      classes.push(marginRemove.map((css) => (`uk-margin-remove-${css}`)).join(' '))
    }

    // size modifiers
    if (small) {
      classes.push('uk-button-small')
    } else if (large) {
      classes.push('uk-button-large')
    }

    // width modifiers
    if (fullwidth) {
      classes.push('uk-width-1-1')
    } else if (width) {
      classes.push(`uk-width-${width}`)
    }

    if (isLoading) {
      classes.push('uk-flex uk-flex-middle uk-flex-center')
    }

    if (extraClassName) {
      classes.push(extraClassName)
    }

    return classes.join(' ')
  }

  const renderContent = () => {
    const contentItems = [
      <div className={isLoading ? 'uk-invisible' : ''} key="btn-text">
        {(content || children)}
      </div>
    ]
    if (isLoading) {
      contentItems.push(<div uk-spinner="ratio: 0.6" className="uk-position-absolute" key="btn-loader" />)
    } else if (Boolean(icon) && !isLoading) {
      const pos = (icon || {}).position === 'left'
      const iconComponent = (
        <Icon
          key="btn-icon"
          // ratio={0.6}
          {...icon}
          style={{
            ...((icon || {}).style || {}),
          }}
          className={`uk-margin-small-${pos ? 'right' : 'left'}`}
        />
      )
      if (pos) {
        contentItems.unshift(iconComponent)
      } else {
        contentItems.push(iconComponent)
      }
    }
    return contentItems
  }

  return (
    <button
      style={{
        borderRadius,
        ...style,
        ...(isLoading ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}),
        ...(primary && disabled && isLoading ? { backgroundColor: '#1e87f0a8 !important', color: '#fff !important', pointerEvents: 'none' } : {})
      }}
      type="button"
      uk-tooltip={ukTooltip}
      disabled={disabled || isLoading}
      onClick={onClick}
      className={renderStyleClass()}
    >
      {renderContent()}
    </button >
  );
};
