import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  buildRequestText,
  buildResponseText,
} from 'app/state/utils/requestBuilder'

import ContentModal from '../../ContentModal'

/**
 * Style/layout components that are local to this file.
 * TODO: Use styled-components or something- or just revisit styling in general.
 */
const TabBar = ({ children }) => (
  <ul className='uk-tab' style={{ marginBottom: 0 }}>
    {children}
  </ul>
)

const Tab = ({ active, onClick, label }) => (
  <li className={cx({ 'uk-active': active, 'text-[#1e87f0]': active })}>
    <a
      className=''
      href='#'
      onClick={onClick}
      style={{
        lineHeight: 2,
        textTransform: 'initial',
      }}
    >
      <div className='flex flex-row justify-center items-center py-0 pl-5 pr-3.75'>
        <span>{label}</span>
      </div>
    </a>
  </li >
)
const TextBlock = ({ text }) => {
  const ref = useRef(null)
  useEffect(() => {
    // Reset scroll position whenever we're updated with new text
    if (ref.current) {
      ref.current.scrollTop = 0
      ref.current.scrollLeft = 0
    }
  }, [text])
  return (
    <textarea
      ref={ref}
      style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#333',
        color: '#eee',
        padding: 14,
        resize: 'none',
        whiteSpace: 'pre-wrap',
        fontFamily:
          '"Roboto Mono", Consolas, "Andale Mono", "Lucida Console", "DejaVu Sans Mono", Monaco, "Courier New", Courier, monospace',
        border: 0,
        outline: 'none',
      }}
      value={text}
      readOnly
      spellCheck={false}
    />
  )
}

/**
 * Displays the summarized details of an original request, a user-edited
 * version of that request (if applicable), and the response (if received).
 * Allows the user to switch between those modes by clicking on tabs.
 */
function RequestDetail(props) {
  const { requestText, editedRequestText, responseText } = props
  const hasEditedRequest = (editedRequestText || '').length > 0
  const hasResponse = (responseText || '').length > 0
  const [mode, setMode] = useState('request')
  const text =
    mode === 'request'
      ? requestText
      : mode === 'editedRequest'
        ? editedRequestText
        : responseText
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div>
        <TabBar>
          <Tab
            active={mode === 'request'}
            onClick={() => setMode('request')}
            label={hasEditedRequest ? 'Original Request' : 'Request'}
          />
          {hasEditedRequest && (
            <Tab
              active={mode === 'editedRequest'}
              onClick={() => setMode('editedRequest')}
              label='Edited Request'
            />
          )}
          {hasResponse && (
            <Tab
              active={mode === 'response'}
              onClick={() => setMode('response')}
              label='Response'
            />
          )}
        </TabBar>
      </div>
      <div style={{ flexGrow: 1 }}>
        <TextBlock text={text} />
      </div>
    </div>
  )
}
RequestDetail.propTypes = {
  requestText: PropTypes.string.isRequired,
  editedRequestText: PropTypes.string,
  responseText: PropTypes.string,
}

/**
 * Modal dialog that displays the details for a single selected request from
 * the proxy history, along with the accompanying response. Fades in on mount;
 * fades out on unmount.
 */
function RequestDetailModal(props) {
  const { request, onClose } = props
  return (
    <ContentModal
      id='proxy-history-request-detail'
      width='70vw'
      height={600}
      onClose={onClose}
    >
      <RequestDetail
        requestText={buildRequestText(request)}
        editedRequestText={request.editedRequest}
        responseText={request.response ? buildResponseText(request) : null}
      />
    </ContentModal>
  )
}
RequestDetailModal.propTypes = {
  request: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RequestDetailModal
