/**
 * This file implements a shim layer between legacy API calls and mySJ service calls.
 * All requests made via the `api` function are passed to `translateToServiceCall`. If
 * this feature is enabled, and if the API call matches any of the routes defined in
 * SHIMS_BY_ROUTE, then the appropriate shim function will be invoked in order to make
 * an equivalent call to the mySJ backend, taking the place of the original API call.
 */

import { AxiosError, type AxiosPromise, type CancelToken } from 'axios'
import { useEffect } from 'react'

import settings from  'settings'
import { request } from './api'

/** Global state that's internal to the shim */
export const g = {
  /**
   * IDs for the legacy API routes which we should attempt to shim at all; controlled by
   * a feature flag.
   */
  enabledRouteIds: [] as RouteId[],
  /**
   * State of the current lesson 'session', used for calls to svc-lesson. If lesson.key
   * is set, we've loaded up a valid lesson and loadedAt will be valid timestamp.
   */
  lesson: {
    contentUuid: '',
    key: '',
    loadedAt: null as Date,
    totalSeconds: 0,
  }
}

/**
 * Updates the set of routes for which the service-to-API shim will be enabled, given a
 * list of string identifiers that correspond to the id values registered in the
 * SHIMS_BY_ROUTE table. Passing any falsy or non-array value will entirely disable this
 * feature, causing all original API calls to happen unmodified.
 */
export function setServiceShimRoutes(routeIds: unknown) {
  if (routeIds && Array.isArray(routeIds) && routeIds.length > 0 && typeof routeIds[0] === 'string') {
    g.enabledRouteIds = [...routeIds]
  } else {
    g.enabledRouteIds = []
  }
}

/**
 * Reinitializes our lesson state in response to React events. When the component that
 * invokes this hook mounts or unmounts, or when the passed-in lessonKey value changes,
 * we'll update g.lesson to reflect whether the UI is currently rendering a lesson.
 */
export function useServiceShimLessonState(contentUuid: string, lessonKey: string) {
  useEffect(() => {
    const clear = () => {
      g.lesson.contentUuid = ''
      g.lesson.key = ''
      g.lesson.loadedAt = null
      g.lesson.totalSeconds = 0
    }
    if (contentUuid && lessonKey) {
      g.lesson.contentUuid = contentUuid
      g.lesson.key = lessonKey
      g.lesson.loadedAt = new Date()
      g.lesson.totalSeconds = 0
    } else {
      clear()
    }
    return clear
  }, [contentUuid, lessonKey])
}

/**
 * Input parameters describing an API request that we might want to translate into an
 * equivalent call to a mySJ service.
 */
export type RequestInputs = {
  // Method and URL allow us to match against a registered shim to potentially turn this
  // legacy API request into an equivalent call to a new mySJ service
  method: Method
  url: string

  // Additional parameters are passed to that shim function, along with any matching URL
  // parameters, so it can construct the request payload faithfully
  data: any
  params: any
  withAuthToken: boolean
  cancelToken: CancelToken
}

/**
 * Checks to see whether a request should be translated into a call to a mySJ service.
 * If so, uses Axios to make an equivalent HTTP request, and returns the resulting
 * promise. If the given request should not be shimmed, returns null to indicate that
 * the caller should continue with the original request.
 */
export function translateToServiceCall(req: RequestInputs): AxiosPromise<any> | null {
  // Check whether the URL matches an internal API: if so, chop off the relative URL to
  // the route we want to hit, and get a list of all the shim functions registered for
  // that API and request method
  let route = ''
  let candidateShims = [] as Shim[]
  if (req.url.startsWith(settings['urls'].hacker)) {
    route = req.url.slice(settings['urls'].hacker.length)
    candidateShims = SHIMS_BY_ROUTE.hacker[req.method]
  } else if (req.url.startsWith(settings['urls'].controlK8s)) {
    route = req.url.slice(settings['urls'].controlK8s.length)
    candidateShims = SHIMS_BY_ROUTE.controlK8s[req.method]
  }

  // Early-out if we have no match or no registered shims for this API/method
  if (!route || !candidateShims) {
    return null
  }

  //sanitize route
  route = route.replace(/^[a-zA-Z0-9-_.]+$/, "");

  // Compare our desired URL against every registered shim that's currently enabled via
  // feature flag- if we find a matching route, execute its shim function
  for (const shim of candidateShims.filter((x) => g.enabledRouteIds.includes(x.id))) {
    const match = route.match(shim.route)
    if (match) {
      return shim.func(req, match.slice())
    }
  }

  // This route is not shimmed; just let the original API call happen
  return null
}

/**
 * Lookup table for all legacy API calls that should be replaced with mySJ service
 * calls. Shim functions may return null to indicate that a request is not being shimmed
 * and should be sent to the legacy API as-is.
 */
const SHIMS_BY_ROUTE: ShimLookup = {
  hacker: {
    get: [
      {
        /**
         * GET /content/hint?id=<hint_id> requests the full payload of a hint from
         * api-hacker. The 'id' URL parameter is required.
         *
         * The legacy HackEDU platform treats hints differently depending on
         * content_type. For a lesson of type 'lesson', using a hint incurs no cost, and
         * its payload consists simply of a markdown body. www-app gets the full body of
         * the hint when fetching the static content of the lesson, and when a hint is
         * used it's simply displayed client-side, without a call to api-hacker.
         *
         * For a lesson of type 'coding_challenge' or 'mobile', each hint has a cost
         * associated with it, and the app calls GET /content/hint to retrieve the
         * payload for a hint of the given ID, while simultaneously recording that the
         * hint has been used. Hint usage is recorded in the content_to_hacker record's
         * JSONB 'metadata' column. Using a hint with ID 44 that costs 5 points will set
         * content_to_hacker.metadata = {"usedHints":{"44":-5}}. A no-cost hint may be
         * recorded with a value of either 0 or true. e.g. If the same learner uses a
         * hint with ID 60 that has no associated cost, content_to_hacker.metadata may
         * be updated to {"usedHints":{"44":-5,"60":true}}.
         *
         * Zero-cost hints have been recorded in ages past, but in recent years www-app
         * has not called GET /content/hint for zero-cost hints in the lesson UI, so
         * these usages typically aren't recorded. To counteract this special case and
         * ensure that we're recording data for *all* hints, including hints with a cost
         * of 0, we've also updated LessonHintModal to conditionally make a superfluous
         * api-hacker call in order to make sure this function gets called.
         *
         * Legacy hints also have a hint_type value: 'default' or 'basic' hints have a
         * markdown body as their payload, whereas hints of type 'highlight' have no
         * markdown body and instead denote a range of regions in source file(s) to be
         * highlighted in the editor, encoded as a list of {filename, startRow, endRow}
         * objects in the JSONB hint.metdata column.
         *
         * For an ordinary (zero-cost, markdown-only) lesson hint, a 200 response from
         * GET /content/hint carries:
         *
         *  {
         *   "content_uuid": "ffaf1a2c-563c-49ea-94f9-a9be559a21b9",
         *   "id": 18,
         *   "metadata": {},
         *   "markdown": "This is the body of the hint",
         *   "title": "Default Hint",
         *   "sort_order": 1,
         *   "hint_type": "default",
         *   "step_id": 476,
         *   "content_type_id": 1
         * }
         *
         * For a basic (non-zero-cost, markdown-only) coding challenge hint, a 200
         * response carries:
         *
         * {
         *   "content_uuid": "56292ee8-8e48-406d-b6d6-d1950e975a73",
         *   "id": 22,
         *   "metadata": {
         *     "points": -2
         *   },
         *   "markdown": "This is the body of the hint",
         *   "title": "Basic Hint",
         *   "sort_order": 1,
         *   "hint_type": "basic",
         *   "step_id": 595,
         *   "content_type_id": 4
         * }
         *
         * For a coding challenge highlight hint, a 200 response carries:
         *
         * {
         *   "content_uuid": "56292ee8-8e48-406d-b6d6-d1950e975a73",
         *   "id": 34,
         *   "metadata": {
         *     "points": -5,
         *     "highlight": [
         *       {"filename": "routes/auth/login.py", "startRow": 23, "endRow": 24}
         *     ]
         *   },
         *   "markdown": null,
         *   "title": "Highlight Code",
         *   "sort_order": 2,
         *   "hint_type": "highlight",
         *   "step_id": 595,
         *   "content_type_id": 4
         * }
         *
         * The 'sort_order', 'step_id', and 'content_type_id' attributes returned in the
         * api-hacker response are superfluous and aren't actually used by www-app. The
         * only values that need to be present in the response are 'content_uuid', 'id',
         * 'title', 'hint_type', and optionally 'markdown', 'metadata.points', and
         * 'metadata.highlight'.
         *
         * POST /svc/lesson/:lessonKey/events/hint notifies svc-lesson that the user has
         * decided to use a specific hint in their current session. Request payload is
         * {"lessonLoadedAt": "%s", "hintId": "%s"}. In the new platform, hints may be
         * identified with arbitrary string IDs, but for backward-compatibility, all
         * hints from the HackEDU DB are identified with their integer hint ID, in
         * string format.  A 200 response carries:
         *
         * {
         *    "markdown": "This is the body of the hint",
         *    "highlightRegions": [
         *       {"filename": "routes/auth/login.py", "lineNum":23, "numLines":2}
         *    ],
         *    "cost": 5
         * }
         *
         * In the new platform, there is no distinction between lesson vs. challenge
         * hints - all hints have a cost (which may be 0) and are retrieved and recorded
         * in the same way. We also don't discriminate based on 'hint type': a hint can
         * simply have either a markdown body, or a list of highlight regions, or both.
         *
         * We need to:
         *
         * 1. Parse the integer hint ID from the URL parameters and convert it to a
         *    string to get our svc-lesson hintId value
         * 2. Pull the session identifier from context established in the lesson UI
         * 3. Make the request to /events/step to record hint usage and get back the
         *    hint payload
         * 4. Build a response that matches the format of the api-hacker response, with:
         *    - 'content_uuid' pulled from our shim state (i.e. g.lesson.contentUuid),
         *       which is initialized by the UI on mount
         *    - 'id' matching the originally-requested integer id
         *    - 'hint_type' inferred from whether the svc-lesson response has a
         *      non-empty 'highlightRegions' list: 'highlight' if so; else 'default'
         *    - 'markdown' set to null for highlight hints; else the original markdown
         *      body from the svc-lesson response
         *    - 'metadata' set to an object, where:
         *      - 'points' is a negative int representing the cost of the hint if it has
         *        one; else undefined
         *      - 'highlight' matches the list of "highlightRegions" in the svc-lesson
         *        response, updated to use "startRow" and "endRow" instead of "lineNum"
         *        and "numLines"
         */
        id: 'hacker.Hints.get',
        route: makeRouteRegex('/content/hint'),
        func: (req, match) => {
          // Only handle requests if we're in the lesson UI currently
          if (!g.lesson.key) {
            return null
          }

          // Require a valid integer hint ID as a URL parameter
          const hintIdAsInt = parseInt(req.params['id'])
          if (isNaN(hintIdAsInt)) {
            return null
          }
          const hintId = String(hintIdAsInt)

          // Call svc-lesson to record the hint usage and get back the hint payload,
          // then translate that into a compatible respoonse object
          return request({
            method: 'post',
            url: `${settings['urls'].sj}/svc/lesson/${g.lesson.key}/events/hint`,
            data: {
              lessonLoadedAt: g.lesson.loadedAt.toISOString(),
              hintId,
            },
            withAuthToken: req.withAuthToken,
            cancelToken: req.cancelToken,
            params: undefined,
          }).then((response) => {
            const data = {
              content_uuid: g.lesson.contentUuid,
              id: hintIdAsInt,
              hint_type: 'default',
              markdown: response.data['markdown'],
              metadata: {},
            }
            if (response.data['cost'] > 0) {
              data.metadata['points'] = -response.data['cost']
            }
            if (response.data['highlightRegions'].length > 0) {
              data.hint_type = 'highlight'
              data.markdown = null
              data.metadata['highlight'] = []
              for (const region of response.data['highlightRegions']) {
                data.metadata['highlight'].push({
                  filename: region['filename'],
                  startRow: region['lineNum'],
                  endRow: region['lineNum'] + region['numLines'] - 1,
                })
              }
            }
            response.data = data
            return response
          })
        },
      },
    ],
    put: [],
    post: [
      {
        /**
         * POST /content/:uuid/ping notifies api-hacker that the user is still actively
         * engaged with the lesson UI. The request carries no payload. A 200 response
         * carries {"success": true}.
         *
         * POST /svc/lesson/:lessonKey/events/active notifies svc-lesson that the user
         * has accumulated a specific total number of seconds of activity in the current
         * session. Request payload is {"lessonLoadedAt": "%s", "totalSeconds": %d}. A
         * 200 response carries "{}"".
         *
         * Whereas svc-lesson 'active' events track the running total of seconds elapsed
         * in each *session*, api-hacker's 'ping' functionality stores elapsed time
         * bucketed by date. Furthermore, ping requests are stateful - they compare
         * last-update timestamps from the database against the database server's system
         * clock in order to determine how much time has elapsed since the last ping.
         * This makes it effectively impossible for the svc-hacker-patch consumer to
         * properly replicate pings. Instead, we simply call *both* endpoints when this
         * route is shimmed.
         *
         * We need to:
         *
         * 1. Take it on faith that the contentUUID matches our current lessonKey
         * 2. Pull out some context (established in the lesson UI) that identifies the
         *    current session and lets us track how many seconds of activity we've
         *    recorded
         * 3. Roughly convert from a ping (which just represents a fixed 5-second
         *    interval elapsed) to a running total, and mutate our lesson context to
         *    record that new total
         * 4. Call the equivalent svc-lesson RPC to record an 'active' event
         * 5. Also make the original request to api-hacker unmodified, so that it
         *    receives a ping and updates content_to_hacker and content_time_to_hacker
         *    appropriately
         */
        id: 'hacker.Ping.post',
        route: makeRouteRegex('/content/:uuid/ping'),
        func: (req, match) => {
          // Only handle requests if we're in the lesson UI currently
          if (!g.lesson.key) {
            return null
          }

          // See also: CONTENT_PING_INTERVAL_MS in ContentPingBeacon.jsx
          const secondsElapsed = 5
          g.lesson.totalSeconds += secondsElapsed

          // Hit svc-lesson at /events/active to record a new event
          request({
            method: 'post',
            url: `${settings['urls'].sj}/svc/lesson/${g.lesson.key}/events/active`,
            data: {
              lessonLoadedAt: g.lesson.loadedAt.toISOString(),
              totalSeconds: g.lesson.totalSeconds,
            },
            withAuthToken: req.withAuthToken,
            cancelToken: undefined,
            params: undefined,
          })

          // Also make a request to the original /content/:uuid/ping endpoint: these
          // api-hacker 'ping' requests are stateful, and so we can't replicate them
          // properly in the svc-hacker-patch consumer
          return request({
            method: req.method,
            url: req.url,
            data: req.data,
            withAuthToken: req.withAuthToken,
            cancelToken: req.cancelToken,
            params: req.params,
          })
        },
      },
      {
        /**
         * POST /user/:uuid/content/:uuid notifies api-hacker that the user has done one
         * of two things:
         *
         * - If the payload is {"step_number": %d}, then the request indicates that the
         *   user has navigated to the given step (zero-indexed) - e.g. clicking "Next"
         *   on step 0 (to navigate to step 1) will produce {"step_number": 1}. In a
         *   lesson with 9 total steps, clicking "Finish" on the last step will produce
         *   {"step_number": 9}. A 200 response carries "{}".
         *
         * - If the payload is {"proof": "%s"}, then the request indicates that the user
         *   is attempting to submit a flag for a CTF challenge (a.k.a. hacking
         *   challenge). If the flag is incorrect, api-hacker will respond 200 with
         *   {"correct": false, "error": "Flag is incorrect."}. If the flag is correct,
         *   the 200 response will carry {"correct": true}.
         *
         * These two events are handled by separate svc-lesson RPCs:
         *
         * - POST /svc/lesson/:lessonKey/events/step notifies svc-lesson that the user
         *   has now completed a specific number of steps in the context of the current
         *   session. Request carries {"lessonLoadedAt": "%s", "numStepsCompleted": %d}.
         *   A 200 response carries "{}".
         *
         * - POST /svc/lesson/:lessonKey/events/ctf-submission submits the user's flag
         *   for validation. Request carries {"lessonLoadedAt": "%s", "proof": "%s"}.
         *   A 200 response carries {"accepted": true} if the flag was accepted as
         *   correct; {"accepted": false} otherwise.
         *
         * We need to:
         *
         * 1. Take it on faith that the hackerUUID corresponds to the currently
         *    logged-in user identified by the JWT: this should always be the case
         * 2. Take it on faith that the contentUUID matches our current lessonKey
         * 3. Determine whether we're dealing with a step event or a CTF submission
         * 4. Pull the session identifier from context established in the lesson UI
         *
         * For a step event, we then need to:
         *
         * 5. Conceptually map "we are now on step N" to "we have now completed N
         *    steps" - this is 1:1 but we want to avoid sending svc-lesson an event that
         *    records "the user has now completed 0 steps" because that doesn't mean
         *    anything to svc-lesson
         * 6. Make the equivalent svc-lesson RPC
         *
         * For a CTF event, we instead need to:
         *
         * 5. Make the equivalent svc-lesson RPC and fix up the response payload to be
         *    { correct: true } | { correct: false, error: string }
         */
        id: 'hacker.UserContent.post',
        route: makeRouteRegex('/user/:uuid/content/:uuid'),
        func: (req, match) => {
          // Only handle requests if we're in the lesson UI currently
          if (!g.lesson.key) {
            return null
          }

          // Handle step event
          if (typeof req.data['step_number'] !== 'undefined') {
            const stepIndexNavigatedTo = parseInt(req.data['step_number'])
            if (isNaN(stepIndexNavigatedTo)) {
              throw new Error(`unexpected step_number value in api-hacker request: ${stepIndexNavigatedTo}`)
            }
            const numStepsCompleted = stepIndexNavigatedTo
            if (numStepsCompleted === 0) {
              // Don't tell svc-lesson if we go back to step 0; it could error
              return null
            }

            // Hit svc-lesson at /events/step to record a new event
            return request({
              method: 'post',
              url: `${settings['urls'].sj}/svc/lesson/${g.lesson.key}/events/step`,
              data: {
                lessonLoadedAt: g.lesson.loadedAt.toISOString(),
                numStepsCompleted,
              },
              withAuthToken: req.withAuthToken,
              cancelToken: req.cancelToken,
              params: undefined,
            })
          }

          // Handle CTF submission
          if (typeof req.data['proof'] !== 'undefined') {
            const proof = req.data['proof']

            // Hit svc-lesson at /events/ctf-submission to submit the proof for
            // validation, and modify the response to have the same format as the
            // api-hacker call
            return request({
              method: 'post',
              url: `${settings['urls'].sj}/svc/lesson/${g.lesson.key}/events/ctf-submission`,
              data: {
                lessonLoadedAt: g.lesson.loadedAt.toISOString(),
                proof,
              },
              withAuthToken: req.withAuthToken,
              cancelToken: req.cancelToken,
              params: undefined,
            }).then((response) => {
              if (response.data['accepted']) {
                response.data = { correct: true }
              } else {
                response.data = { correct: false, error: 'Flag is incorrect.' }
              }
              return response
            })
          }

          // We don't handle calls to this route with other sets of params
          return null
        }
      },
    ],
    patch: [],
    delete: [],
  },
  controlK8s: {
    get: [
      {
        /**
         * GET /sandbox?sandboxTemplate=<template> requests a sandbox with the given
         * template from api-sandbox. If forceNew=true is supplied, a brand new sandbox
         * will always be provisioned; otherwise an existing, already-assigned sandbox
         * may be used. If the user already has a sandbox of a different template
         * assigned, api-sandbox will automatically discard that sandbox and provision
         * a new one. A 200 response carries a payload with this format:
         *
         * {
         *    "status": "Running",
         *    "apps": {
         *      "app": {"url": "https://app.sb.hackedu.dev"},
         *      "fs-api": {"url": "https://fs-api.sb.hackedu.dev"},
         *      "proxy": {"url": "https://proxy.sb.hackedu.dev"},
         *      "repl": {"url": "https://repl.sb.hackedu.dev"}
         *    }
         * }
         *
         * The response's status is 'Running' once the sandbox is ready to receive
         * client requests. If the sandbox is not yet ready after the initial request,
         * the client may continue to periodically call GET /sandbox (without forceNew)
         * until the sandbox becomes ready.
         *
         * PUT /svc/sandbox/:lessonKey/v/:lessonVersion requests a sandbox that's
         * compatible with the given lesson, behaving the same as api-sandbox's
         * GET /sandbox?forceNew=false, with one exception: if the user already has a
         * sandbox assigned for a different lesson, this endpoint returns a 409 error.
         *
         * POST /svc/sandbox/:lessonKey/v/:lessonVersion requests a brand new sandbox
         * for the given lesson, discarding any already-assigned sandbox: its behavior
         * is identical to GET /sandbox?forceNew=true.
         *
         * We need to:
         *
         * 1. Take it on faith that the sandboxTemplate URL parameter matches the
         *    sandboxTemplate for the currently-loaded lesson: svc-sandbox requests use
         *    the lessonKey instead, so we'll simply disregard the sandboxTemplate arg
         * 2. Check whether forceNew=true is set in the api-sandbox request
         * 3. Use a constant lessonVersion of 1 in all svc-sandbox requests, since the
         *    new mySJ platform doesn't currently implement lesson versions, and the
         *    legacy HE app is not aware of the concept of lesson versions anyway
         * 4. If forceNew is not set, call svc-sandbox's PUT endpoint
         *    a. If we get a 409 error, swallow it and call the POST endpoint instead
         * 5. If forceNew is set, call svc-sandbox's POST endpoint
         * 6. Return the svc-sandbox response directly, since it's a superset of the
         *    api-sandbox response
         *
         * svc-sandbox also has an endpoint that allows clients to open an EventSource
         * and watch for changes in the currently-assigned sandbox's status, without
         * having to periodically poll via repeated requests to GET /sandbox. For this
         * compatibility shim, we ignore that SSE endpoint and simply translate GET
         * sandbox calls directly, since repeated calls to PUT /svc/sandbox/... will
         * behave identically to repeated calls to GET /sandbox.
         */
        id: 'controlK8s.Sandbox.get',
        route: makeRouteRegex('/sandbox'),
        func: (req, match) => {
          // Only handle requests if we're in the lesson UI currently
          if (!g.lesson.key) {
            return null
          }

          // Prepare a helper function that will request a sandbox for the current
          // lesson, either via PUT or POST
          const requestSandbox = (method: 'put' | 'post') => (
            request({
              method,
              url: `${settings['urls'].sj}/svc/sandbox/${g.lesson.key}/v/1`,
              data: undefined,
              withAuthToken: req.withAuthToken,
              cancelToken: req.cancelToken,
              params: undefined,
              opts: {
                withCredentials: true,
              },
            })
          )

          // Check whether forceNew is true
          const canReuseAlreadyAssignedSandbox = req.params['forceNew'] !== true
          if (canReuseAlreadyAssignedSandbox) {
            // If forceNew=false, make a PUT call, but intercept any 409 error and chain
            // it into a POST call so we get the same behavior as api-sandbox (i.e.
            // always discarding an existing sandbox)
            return requestSandbox('put').catch((err) => {
              if (err instanceof AxiosError && err.response.status === 409) {
                return requestSandbox('post')
              } else {
                throw err
              }
            })
          } else {
            // If forceNew=true, just make a POST call so svc-sandbox will assign us a
            // brand new sandbox for the current lesson
            return requestSandbox('post')
          }
        },
      },
    ],
    put: [],
    post: [],
    patch: [],
    delete: [],
  }
}

/** Valid HTTP methods supported by our APIs; always lowercase */
type Method = 'get' | 'put' | 'post' | 'patch' | 'delete'

/** APIs we can redirect requests for; corresponds to settings.urls */
type LegacyAPI = 'hacker' | 'controlK8s'

/** Function that handles a given request by making an equivalent call to a service */
type ShimFunc = (req: RequestInputs, match: string[]) => AxiosPromise<any> | null

/**
 * Globally unique ID used to identify a route. By convention, this should match the
 * Flask-RESTful resource name and method from the API, prefixed with the with the
 * LegacyAPI name, e.g. 'hacker.CodeDetails.get'
 */
type RouteId = string

/** Definition for an API call that we want to transform into a service call */
type Shim = {
  id: RouteId
  route: RegExp
  func: ShimFunc
}

/** Lookup that registers all the routes we want to shim */
type ShimLookup = { [key in LegacyAPI]: { [key in Method]: Shim[] } }

/**
 * Helper function for building a regex that will match a specific API route, given the
 * relative URL with any path parameters replaced with colon-prefixed placeholders
 * identifying their type, e.g. ':uuid' or ':int'.
 *
 * const url = '/organizations/a957cd97-48a1-40f6-b47b-3180d3ac6b58/reports/44'
 * const regex = makeRouteRegex('/organizations/:uuid/reports/:int')
 * const match = url.match(regex)
 * console.log(`we want report ${match[2]} for organization ${match[1]}`)
 */
function makeRouteRegex(route: string): RegExp {
  // Input string must be non-empty and must start with a slash
  if (!route || route[0] !== '/') {
    throw new Error(`Invalid URL format for route: ${route}`)
  }

  // Assemble a list of slash-delimited tokens for our regex pattern
  const patternTokens = [] as string[]
  for (const token of route.split('/').slice(1)) {
    // Sanity-check that we don't have a trailing slash or multiple adjacent slashes
    if (!token) {
      throw new Error(`Empty token in route URL: ${route}`)
    }

    // Handle ':uuid' etc. as a placeholder; otherwise treat the string literally
    if (token[0] === ':') {
      const placeholderType = token.slice(1)
      switch (placeholderType) {
        case 'uuid':
          patternTokens.push('([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})')
          break
        case 'int':
          patternTokens.push('([0-9]+)')
          break
        default:
          throw new Error(`Invalid placeholder type '${placeholderType}' in route URL: ${route}`)
      }
    } else {
      patternTokens.push(token)
    }
  }
  return new RegExp('^/' + patternTokens.join('/') + '$')
}
