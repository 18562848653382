import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { buildRequestText } from 'app/state/utils/requestBuilder'

import { VBox, HBox, Box } from 'app/views/core/Box'

import PrimaryButton from '../../../../../components/Buttons/PrimaryButton'

import ProxyEditor from './ProxyEditor'

/**
 * Appears when a request is made through the proxy with 'Intercept Requests'
 * enabled: allows the user to review the request - and manually edit it if
 * desired - before the request is resumed and forwarded downstream.
 */
function ProxyInterceptor(props) {
  const { request, onSubmit, autoSubmitTimeout } = props

  // Format our request as a plain text, and cache that value for comparison
  const [originalRequestText, setOriginalRequestText] = useState(
    buildRequestText(request)
  )
  const [requestText, setRequestText] = useState(originalRequestText)
  const [hasEditedRequest, setHasEditedRequest] = useState(false)
  const hasSubmitted = useRef(false)

  // If our input request ever changes, reset our state
  const autoSubmitTimerHandle = useRef(null)
  useEffect(() => {
    if (!hasSubmitted.current) {
      const requestText = buildRequestText(request)
      setOriginalRequestText(requestText)
      setRequestText(requestText)
      setHasEditedRequest(false)

      // We'll eventually get a 504 Gateway Timeout if we leave the request hanging
      // for more than a minute, so schedule auto-submit if configured to do so
      if (autoSubmitTimerHandle.current) {
        clearTimeout(autoSubmitTimerHandle.current)
        autoSubmitTimerHandle.current = null
      }
      if (autoSubmitTimeout && autoSubmitTimeout > 0) {
        autoSubmitTimerHandle.current = setTimeout(() => {
          hasSubmitted.current = true
          onSubmit(originalRequestText, false)
        }, autoSubmitTimeout)
      }
    }
  }, [request])

  // Compare new text to the original to determine if it's been edited
  function onRequestTextChange(newText) {
    setRequestText(newText)
    setHasEditedRequest(originalRequestText !== newText)
  }

  // If the user clicks 'revert' to clear any edits, restore the original text
  function onRevertClick() {
    setRequestText(originalRequestText)
    setHasEditedRequest(false)
  }

  return (
    <VBox fillParent style={{ borderTop: '1px solid #eee' }}>
      <HBox style={{ alignItems: 'center', margin: '10px 20px' }}>
        <HBox grow style={{ alignItems: 'baseline' }}>
          <h4 style={{ marginBottom: 0 }}>Pending Request</h4>
          <div className='text-sm' style={{ fontStyle: 'italic' }}>
            &nbsp;(Type to edit)
          </div>
        </HBox>
        <PrimaryButton
          label='Revert'
          size='small'
          visible={hasEditedRequest}
          color={'gray'}
          customCss={'text-black'}
          onClick={onRevertClick}
        />
        <Box fixed={10} />
        <PrimaryButton
          label='Submit'
          size='small'
          onClick={() => onSubmit(requestText, hasEditedRequest)}
        />
      </HBox>
      <Box grow style={{ padding: 10, paddingTop: 0 }}>
        <ProxyEditor
          requestText={requestText}
          onRequestTextChange={onRequestTextChange}
        />
      </Box>
    </VBox>
  )
}
ProxyInterceptor.propTypes = {
  request: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  autoSubmitTimeout: PropTypes.number,
}

export default ProxyInterceptor
