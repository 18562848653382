/**
 * Serves as an interface to the repl container running on a sandbox.
 *
 * The relay container serves an HTTP event stream that will be continually updated
 * with line-by-line TTY output from the sandbox. This is used for multi-file content
 * (i.e. coding challenges / codereviews); for content with user-editable patches (i.e.
 * lessons), output is handled via the repl container instead.
 */
import moment from 'moment'

import * as tty from './tty'
import { requestOpts } from 'app/state/modules/sandbox'

const prefix = 'sbrelay'
const SETUP = `${prefix}/SETUP`
const TEARDOWN = `${prefix}/TEARDOWN`
const ACKNOWLEDGE_LINE = `${prefix}/ACKNOWLEDGE_LINE`

const initialState = {
  eventStreamUrl: null,
  eventSource: null,
  lastAcknowledgedLineId: -1,
}

const ENABLE_VERBOSE_LOGGING = false
// eslint-disable-next-line no-console
const _log = ENABLE_VERBOSE_LOGGING ? console.log : () => {}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SETUP:
      return {
        ...state,
        eventStreamUrl: action.eventStreamUrl,
        eventSource: action.eventSource,
        lastAcknowledgedLineId: -1,
      }
    case TEARDOWN:
      return { ...initialState }
    case ACKNOWLEDGE_LINE:
      return {
        ...state,
        lastAcknowledgedLineId: action.lineId,
      }
    default:
      return state
  }
}

// Called from sandbox.js to initialize this module, if and only if the newly-assigned
// sandbox has a 'relay' host
export function setup(eventStreamUrl) {
  return (dispatch, getState) => {
    let state = getState()
    // We've been initialized with the URL of the relay container: open the event stream
    _log(`sbrelay init; opening event stream ${eventStreamUrl}`)
    const eventStream = new EventSource(eventStreamUrl, requestOpts(state))

    dispatch({
      type: SETUP,
      eventStreamUrl,
      eventStream,
    })

    // For as long as this module is active, handle incoming messages
    eventStream.addEventListener('message', (event) => {
      // relay replays the N most recent lines when a client connection is established:
      // in theory this can happen multiple times within the same page load, in the
      // event we lose our connection and the browser retries and reconnects. Each
      // message is tagged with a serial ID; so just remember the most recently-seen ID
      // and ignore any messages that predate it
      const lineId = parseInt(event.lastEventId)
      if (
        !isNaN(lineId) &&
        lineId > getState().sbrelay.lastAcknowledgedLineId
      ) {
        dispatch({ type: ACKNOWLEDGE_LINE, lineId })
        _log(`sbrelay: acknowledged line ${lineId}: ${event.data}`)

        // Message format is a JSON object where 'timestamp' is an RFC3339-formatted
        // UTC timestamp and 'line' is the UTF-8 string representing a line of output,
        // with any ANSI escape sequences intact
        const obj = JSON.parse(event.data)
        const line = obj.line.trimRight()
        if (line.length > 0) {
          const timestamp = moment.utc(obj.timestamp).toDate().getTime()
          dispatch(tty.pushLine(obj.line, timestamp))
        }
      } else {
        _log(`sbrelay: repeated line ${event.lastEventId} ignored`)
      }
    })
  }
}

// Cleans up any state associated with this module. Idempotent; may be called without a
// preceding setup call.
export function teardown() {
  return (dispatch, getState) => {
    _log('sbrelay teardown')
    const state = getState().sbrelay
    if (state.eventSource) {
      state.eventSource.close()
      _log('sbrelay event stream closed')
    }
    dispatch({ type: TEARDOWN })
  }
}
