import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { HBox, Box } from 'app/views/core/Box'

import { Icon } from 'app/views/components/GustavoIcon'

const ErrorText = ({ text, top }) => (
  <p
    className='text-sm'
    style={{
      color: '#d21919',
      marginBottom: 0,
      marginTop: top,
    }}
  >
    {text}
  </p>
)

function PatchSubmissionErrorNotification(props) {
  const { error } = props
  const [isDismissed, setIsDismissed] = useState(false)
  return (
    !isDismissed && (
      <HBox
        fixed={30}
        style={{
          borderTop: '2px solid #f0506e',
          borderBottom: '1px solid #f0506e',
          backgroundColor: '#ffd1d9',
        }}
      >
        <Box grow style={{ padding: 8 }}>
          <ErrorText text={error} />
          <ErrorText
            top={4}
            text='Please try again. If the problem persists, please let us know via the chat feature in the lower-right.'
          />
        </Box>
        <Box fixed={50} style={{ textAlign: 'right', padding: 6 }}>
          <Icon
            name='close'
            style={{ color: '#f0506e' }}
            ratio={1.5}
            onClick={() => setIsDismissed(true)}
          />
        </Box>
      </HBox>
    )
  )
}
PatchSubmissionErrorNotification.propTypes = {
  error: PropTypes.string.isRequired,
}

export default PatchSubmissionErrorNotification
