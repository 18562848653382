import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import Icon from 'app/views/components/Icon'
import settings from 'settings'

const propTypes = {
  contentType: PropTypes.string.isRequired,
}

const SubscriptionLocked = ({
  contentType,
  licenseOutOfScope = false,
  history,
  isExpired,
}) => {
  contentType = contentType.replace('_', ' ')
  const renderTitle = () => {
    if (licenseOutOfScope) {
      return `Your license don't give you access to this ${contentType}`
    }
    if (isExpired) {
      return 'Your license is expired.'
    }
    return `This ${contentType} requires a subscription.`
  }
  const renderBody = () => {
    if (licenseOutOfScope) {
      return `Contact your manager or contact us for more information about how get access to this ${contentType}`
    }
    if (isExpired) {
      return 'Renew your license or contact your manager for information. Feel free to contact us if you have any questions.'
    }
    return `Upgrade your account to get access this ${contentType} and a lot of other great premium content!`
  }

  const renderButton = () => {
    if (licenseOutOfScope) {
      return (
        <button
          type='button'
          onClick={() => history.goBack()}
          className='uk-button uk-button-primary tm-button-primary'
        >
          Go Back
        </button>
      )
    }
    if (isExpired) {
      return (
        <button
          type='button'
          onClick={() => history.goBack()}
          className='uk-button uk-button-primary tm-button-primary'
        >
          Go Back
        </button>
      )
    }
    return (
      <Link
        to={{ pathname: `${settings.urls.www}/contact` }}
        target='_blank'
        rel='noopener noreferrer'
        className='uk-button uk-button-primary tm-button-primary'
      >
        Upgrade Account
      </Link>
    )
  }
  return (
    <div className='uk-section text-center'>
      <div className='uk-container'>
        <Icon
          className='uk-margin-small-right text-emphasis'
          name='lock'
          ratio={4}
        />
        <h1 className='uk-heading'>{renderTitle()}</h1>
        <p>{renderBody()}</p>
        {renderButton()}
      </div>
    </div>
  )
}

SubscriptionLocked.propTypes = propTypes

export default withRouter(SubscriptionLocked)
