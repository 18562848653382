import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import RecommendationPlan from 'app/views/containers/Organizations/Teams/RecommendationPlan'
import {
  updateRecommendationEngineSettings,
  updateRequiredContentRecommendationSettings,
  updateRequiredTrainingContent,
  getPlans,
  activateAutomatedPlan,
} from 'app/state/modules/plan'
import { getContent } from 'app/state/modules/content'
import OrganizationLayout from '../components/Layout'

class EditOrganizationRecommendation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      settings: (props.location.state || {}).settings || null,
      organization_integrations: (props.location.state || {})
        .organization_integrations_list,
      showNoRecommendationActive: false,
    }
    this.onRefresh = this.onRefresh.bind(this)
  }

  componentDidMount() {
    this.props.getContent(this.props.match.params.organization_uuid, true)
    if (!this.state.settings) {
      this.onRefresh()
    }
  }

  onRefresh() {
    this.props.getPlans(
      this.props.match.params.organization_uuid,
      true,
      (data) => {
        const recommendationPlan = data.organization_plans.find(
          (p) => p.using_recommendation_engine
        )
        if (!recommendationPlan) {
          return this.setState({
            showNoRecommendationActive: true,
          })
        }
        this.setState(
          {
            showNoRecommendationActive: false,
            organization_integrations:
              recommendationPlan.organization_integrations_list,
            settings: recommendationPlan.settings,
          },
          () => {
            this.props.history.push({
              pathname: `/admin/${this.props.match.params.organization_uuid}/plans/recommendation-engine-organization`,
            })
          }
        )
      }
    )
  }

  render() {
    return (
      <OrganizationLayout
        {...this.props}
        active='plans'
        data-test-id='organization-plans-component'
        isLoading={
          !this.state.settings && !this.state.showNoRecommendationActive
        }
      >
        {this.state.showNoRecommendationActive && (
          <>
            <h2 className='flex items-center flex-row justify-between uk-width-1-1'>
              Organization Default Adaptive Training Plan Settings
            </h2>
            <p>
              You neeed to activate the Adaptive plan first. &nbsp;
              <Link
                to={`/admin/${this.props.match.params.organization_uuid}/plans/`}
              >
                Do it here
              </Link>
            </p>
          </>
        )}
        {this.state.settings && (
          <RecommendationPlan
            updateRequiredTrainingContent={
              this.props.updateRequiredTrainingContent
            }
            loadingAutomatedPlan={this.props.loadingAutomatedPlan}
            activateAutomatedPlan={this.props.activateAutomatedPlan}
            updateRequiredContentRecommendationSettings={
              this.props.updateRequiredContentRecommendationSettings
            }
            content={this.props.content}
            onRefresh={this.onRefresh}
            currentTeam={{
              organization_integrations: this.state.organization_integrations,
            }}
            isOrganization
            updateRecommendationEngineSettings={
              this.props.updateRecommendationEngineSettings
            }
            trainingSelected={this.state.settings.required_training.reduce(
              (p, c) => {
                p[c.uuid] = true
                return p
              },
              {}
            )}
            trainedSelected={{}}
            settings={this.state.settings}
          />
        )}
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  // hacker
  organizations: Object.values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,
  content: state.content.content,
})

export default withRouter(
  connect(mapStateToProps, {
    updateRecommendationEngineSettings,
    updateRequiredContentRecommendationSettings,
    updateRequiredTrainingContent,
    getContent,
    getPlans,
    activateAutomatedPlan,
  })(EditOrganizationRecommendation)
)
