import React from 'react'

import Icon from 'app/views/components/Icon'
import ToggleSwitch from 'app/views/components/ToggleSwitch'

const ContentSettings = ({
  handleToggleMustPassCodingExercise,
  mustPassCodingExercise,
  showAllTraining,
  showCodingChallenges,
  showHackingChallenges,
  handleToggleShowAllTraining,
  handleToggleShowHackingChallenges,
  handleToggleShowCodingChallenges,
}) => {
  if (mustPassCodingExercise === undefined) {
    return null
  }

  return (
    <div className='uk-card uk-card-default max-w-3xl'>
      <div className='uk-card-body'>
        <div className='mb-3'>
          <div className='flex mb-1'>
            <ToggleSwitch
              onChange={handleToggleMustPassCodingExercise}
              checked={mustPassCodingExercise}
              label='Coding Test Required'
            />
            <Icon
              as='a'
              href='https://help.securityjourney.com/en/articles/3121295-what-is-must-pass-coding-exercise'
              icon='question'
              ratio={0.8}
              style={{
                marginLeft: 5,
                verticalAlign: 'top',
                display: 'inline-block',
              }}
              target='_blank'
              rel='noopener noreferrer'
            />
          </div>
          <div className='text-sm italic'>
            This is a global setting for coding tests within lessons that can
            also be managed at the team level.
          </div>
        </div>
        <div className='mb-3'>
          <div className='flex mb-1'>
            <ToggleSwitch
              onChange={handleToggleShowAllTraining}
              checked={showAllTraining}
              label='Show All Training'
            />
            <Icon
              as='a'
              href='https://help.securityjourney.com/en/articles/3364093-hide-all-training-in-navigation'
              name='question'
              ratio={0.8}
              style={{
                marginLeft: 5,
                verticalAlign: 'top',
                display: 'inline-block',
              }}
              target='_blank'
              rel='noopener noreferrer'
            />
          </div>
          <div className='text-sm italic'>
            If you'd like learners to take training outside of their assignment,
            enable Show All Training so they can continue their security
            journey. Admins will continue to see this page, even if this setting
            is toggled to off.
          </div>
        </div>
        <div className='mb-3'>
          <div className='flex mb-1'>
            <ToggleSwitch
              disabled={!showAllTraining}
              onChange={handleToggleShowHackingChallenges}
              checked={showHackingChallenges}
              label='Show Hacking Challenges'
            />
          </div>
          <div className='text-sm italic'>
            Admins will continue to see Hacking Challenges even if this is
            toggled off.
          </div>
        </div>
        <div className='mb-3'>
          <div className='flex mb-1'>
            <ToggleSwitch
              disabled={!showAllTraining}
              onChange={handleToggleShowCodingChallenges}
              checked={showCodingChallenges}
              label='Show Coding Challenges'
            />
            <Icon
              as='a'
              href='https://help.securityjourney.com/en/articles/3949030-show-coding-challenges-setting'
              name='question'
              ratio={0.8}
              style={{
                marginLeft: 5,
                verticalAlign: 'top',
                display: 'inline-block',
              }}
              target='_blank'
              rel='noopener noreferrer'
            />
          </div>
          <div className='text-sm italic'>
            Admins will continue to see Coding Challenges even if this is
            toggled off.
          </div>
        </div>
      </div>
    </div>
  )
}

export { ContentSettings }
