import React from 'react'
import UIkit from 'uikit'

import Icon from 'app/views/components/Icon'

class PopUpMenu extends React.Component {
  constructor(props) {
    super(props)
    this.onSelect = this.onSelect.bind(this)
    this.renderTextTrigger = this.renderTextTrigger.bind(this)
  }

  componentDidMount() {
    this.pop = UIkit.dropdown(`#${this.props.id}`)
    UIkit.util.on(`#${this.props.id}`, 'beforeshow', () => {
      if (this.props.beforeShow) {
        this.props.beforeShow()
      }
    })
    UIkit.util.on(`#${this.props.id}`, 'hidden', () => {
      if (this.props.afterHide) {
        this.props.afterHide()
      }
    })
  }

  onSelect(item) {
    if (this.props.onSelect) this.props.onSelect(item, this.props.refObj)
  }

  hide() {
    this.pop.hide()
  }

  renderItems() {
    return (this.props.options || []).map((opt) => {
      return (
        <li key={opt.id}>
          <a
            data-test-id={`${this.props['data-test-id']}-${opt.text
              .toLowerCase()
              .replace(' ', '-')}`}
            className={
              this.props.selected === opt.id
                ? 'font-bold text-secondary'
                : ''
            }
            href='#'
            onClick={() => this.onSelect(opt)}
            opt={opt}
          >
            {opt.text}
          </a>
        </li>
      )
    })
  }

  renderTextTrigger() {
    if (this.props.customTextElement) {
      return this.props.customTextElement
    }
    return <a className='_underline text-green'>{this.props.text}</a>
  }

  render() {
    const {
      isInformative,
      isHover,
      informativeMsg,
      extraOptionText,
      style,
      className,
      leftCenter,
      customRender,
      containerClass,
      customPosition,
    } = this.props
    const position =
      isInformative || leftCenter
        ? 'left-center'
        : customPosition || 'right-top'
    return (
      <div className={`uk-inline${containerClass ? ` ${containerClass}` : ''}`}>
        {this.props.isTextTrigger ? (
          this.renderTextTrigger()
        ) : (
          <button
            style={
              this.props.hidden
                ? {
                    visibility: 'hidden',
                    pointerEvents: 'none',
                  }
                : {
                    background: 'none',
                  }
            }
            data-test-id={this.props['data-test-id']}
            className='uk-button uk-button-small text-green'
            type='button'
          >
            <Icon
              className='uk-margin-small-right'
              name={`${
                isInformative ? 'informative' : this.props.icon || 'edit-2'
              }`}
              ratio={0.8}
            />
          </button>
        )}
        <div
          id={this.props.id}
          data-uk-dropdown={`mode: ${
            isHover ? 'hover' : 'click'
          }; pos: ${position}; animation: uk-animation-slide-left-small; duration: 200;`}
          className={`text-left${className ? ` ${className}` : ''}`}
          style={style || {}}
        >
          {customRender && customRender(this)}
          {customRender ? null : (
            <>
              {isInformative ? (
                <small className='text-center'>{informativeMsg}</small>
              ) : (
                <ul
                  data-test-id='ul-dropdown-options'
                  className='uk-nav uk-dropdown-nav'
                >
                  <li className='uk-nav-header'>
                    {this.props.title || 'Menu'}
                  </li>
                  {this.renderItems()}
                  {this.props.extraItem && (
                    <li>
                      <a
                        data-test-id='extra-option-pop-up'
                        className='uk-link-reset text-green'
                        onClick={this.props.extraItem.onClick}
                      >
                        <Icon
                          className='uk-margin-small-right'
                          name='delete-bin'
                        />
                        {extraOptionText}
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}
export default PopUpMenu
