import React from 'react'
import { connect } from 'react-redux'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import Icon from 'app/views/components/Icon'
import settings from 'settings'

import { getTeams } from 'app/state/modules/team'
import {
  testIntegration,
  addIntegration,
  getIntegrations,
} from 'app/state/modules/integrations'

import IntegrationCard from './IntegrationCard'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

class AddIntegration extends React.Component {
  constructor(props) {
    super(props)
    this.isValidAndNotNative =
      this.props.match.params.integration &&
      settings.integrations[this.props.match.params.integration].is_native_only

    this.state = {
      teams: {},
      loadingTest: false,
      filter: '',
      step: 0,
      error: false,
      optionals:
        this.props.match.params.integration && !this.isValidAndNotNative
          ? Object.keys(
              (
                settings.integrations[this.props.match.params.integration]
                  .settings || {}
              ).fields
            ).reduce((prev, key) => {
              if (
                (
                  settings.integrations[this.props.match.params.integration]
                    .settings || {}
                ).fields[key].optional
              ) {
                prev[key] = true
              }
              return prev
            }, {})
          : {},
      settings:
        this.props.match.params.integration && !this.isValidAndNotNative
          ? Object.keys(
              (
                settings.integrations[this.props.match.params.integration]
                  .settings || {}
              ).fields
            ).reduce((prev, key) => {
              prev[key] = ''
              return prev
            }, {})
          : {},
      settingsLabels:
        this.props.match.params.integration && !this.isValidAndNotNative
          ? Object.keys(
              (
                settings.integrations[this.props.match.params.integration]
                  .settings || {}
              ).fields
            ).reduce((prev, key) => {
              prev[key] = (
                settings.integrations[this.props.match.params.integration]
                  .settings || {}
              ).fields[key].name
              return prev
            }, {})
          : {},
      settingsPlaceHolders:
        this.props.match.params.integration && !this.isValidAndNotNative
          ? Object.keys(
              (
                settings.integrations[this.props.match.params.integration]
                  .settings || {}
              ).fields
            ).reduce((prev, key) => {
              if (
                (
                  settings.integrations[this.props.match.params.integration]
                    .settings || {}
                ).fields[key].placeholder
              ) {
                prev[key] =
                  (
                    settings.integrations[this.props.match.params.integration]
                      .settings || {}
                  ).fields[key].placeholder || undefined
              }
              return prev
            }, {})
          : {},
      title:
        (settings.integrations[this.props.match.params.integration] || {})
          .title || null,
      programs: [],
      selectedPrograms: {},
      savingIntegration: false,
    }
    this.loadTeams = this.loadTeams.bind(this)
    this.renderSteps = this.renderSteps.bind(this)
    this.renderContent = this.renderContent.bind(this)
    this.testIntegration = this.testIntegration.bind(this)
    this.renderNativeIntegration = this.renderNativeIntegration.bind(this)
    this.props.getTeams(this.props.match.params.organization_uuid)
  }

  componentDidMount() {
    this.props.getIntegrations(this.props.match.params.organization_uuid)
  }

  componentDidUpdate(prevProps) {
    if (
      (this.state.title === null &&
        typeof (
          settings.integrations[this.props.match.params.integration] || {}
        ).title === 'string') ||
      (typeof this.props.match.params.integration === 'string' &&
        prevProps.match.params.integration !==
          this.props.match.params.integration)
    ) {
      this.setState({
        title: (
          settings.integrations[this.props.match.params.integration] || {}
        ).title,
        settings: Object.keys(
          (
            settings.integrations[this.props.match.params.integration]
              .settings || {}
          ).fields
        ).reduce((prev, key) => {
          prev[key] = ''
          return prev
        }, {}),
        optionals: this.props.match.params.integration
          ? Object.keys(
              (
                settings.integrations[this.props.match.params.integration]
                  .settings || {}
              ).fields
            ).reduce((prev, key) => {
              if (
                (
                  settings.integrations[this.props.match.params.integration]
                    .settings || {}
                ).fields[key].optional
              ) {
                prev[key] = true
              }
              return prev
            }, {})
          : {},
        settingsLabels: Object.keys(
          (
            settings.integrations[this.props.match.params.integration]
              .settings || {}
          ).fields
        ).reduce((prev, key) => {
          prev[key] = (
            settings.integrations[this.props.match.params.integration]
              .settings || {}
          ).fields[key].name
          return prev
        }, {}),
        settingsPlaceHolders: Object.keys(
          (
            settings.integrations[this.props.match.params.integration]
              .settings || {}
          ).fields
        ).reduce((prev, key) => {
          if (
            (
              settings.integrations[this.props.match.params.integration]
                .settings || {}
            ).fields[key].placeholder
          ) {
            prev[key] =
              (
                settings.integrations[this.props.match.params.integration]
                  .settings || {}
              ).fields[key].placeholder || undefined
          }
          return prev
        }, {}),
      })
    }
  }

  loadTeams() {
    this.setState({
      step: 1,
    })
  }

  testIntegration() {
    const isValid = this.props.testIntegration(
      this.props.match.params.organization_uuid,
      this.state.settings,
      this.props.match.params.integration
    )
    return isValid
  }

  renderForm(disabled = false) {
    const keys = {
      domain: {
        icon: 'links',
      },
      username: {
        icon: 'user',
      },
      secret: {
        icon: 'key-2',
      },
      tenant: {
        icon: 'home-gear',
      },
      organization: {
        icon: 'building',
      },
      key: {
        icon: 'shield-keyhole',
      },
    }
    return Object.keys(this.state.settings)
      .sort((x, y) => {
        if (x === 'domain') {
          return -1
        }
        if (y === 'domain') {
          return 1
        }
        if (x === 'secret') {
          return 1
        }
        if (y === 'secret') {
          return -1
        }
        if (x === 'username') {
          return 1
        }
        return -1
      })
      .map((key) => (
        <div className='uk-margin uk-position-relative' key={key}>
          <label className='uk-form-label'>
            {this.state.settingsLabels[key]}
          </label>
          <div className='uk-inline uk-width-1-1'>
            <input
              disabled={disabled}
              className={`uk-input${!disabled ? ' text-center' : ''}`}
              type='text'
              // placeholder={`i.e: ${this.state.settingsPlaceHolders[key]}`}
              value={this.state.settings[key]}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...this.state.settings,
                    [key]: e.target.value,
                  },
                })
              }}
              data-test-id='input-title'
            />
            <Icon
              as='a'
              role='button'
              style={{
                cursor: 'pointer',
              }}
              className='uk-form-icon uk-form-icon-flip'
              name={keys[key].icon}
            />
          </div>
          {this.state.settingsPlaceHolders[key] ? (
            <small
              className='text-muted'
              style={{
                alignSelf: 'left',
                position: 'absolute',
                bottom: -25,
                left: 0,
              }}
            >{`e.g.: ${this.state.settingsPlaceHolders[key]}`}</small>
          ) : null}
        </div>
      ))
  }

  renderPickOrganizationTeams(name, logo, obj) {
    return (
      <>
        <div>
          <button
            className='uk-button uk-button-small uk-button-secondary flex'
            type='button'
            onClick={() => this.props.history.goBack()}
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Go Back
          </button>
          <div
            className='uk-width-1-4 uk-background-secondary uk-padding uk-align-center text-center'
            style={{ background: obj.settings.color }}
          >
            <img
              src={`/static/images/integrations/${logo}`}
              style={{ maxWidth: '70%' }}
              alt={logo}
            />
          </div>
          <br />
          <div className='text-center uk-width-2-5 uk-align-center '>
            <h4>
              Should data from these {name} reports be applied to everyone in
              your organization, or to specific teams?
            </h4>
            <br />
            <div className='uk-margin-top'>
              <PrimaryButton
                onClick={() => {
                  this.setState(
                    {
                      teams: {},
                      settings: Object.keys(this.state.settings).reduce(
                        (p, k) => {
                          p[k] = this.state.settings[k] || ''
                          return p
                        },
                        {}
                      ),
                      step: 2,
                    },
                    () => {}
                  )
                }}
                size='small'
                customCss={'mr-2 bg-blue'}
                label='Everyone'
              />
              <PrimaryButton
                onClick={this.loadTeams}
                size='small'
                label='Specific Teams'
              />
            </div>

            {settings.activeIntegrations.includes(
              this.props.match.params.integration
            ) && (
              <div className='text-center uk-magin-top uk-padding'>
                <i>
                  <a
                    className='text-green hover:text-green'
                    onClick={() =>
                      this.props.history.push(
                        `${this.props.location.pathname}/data`
                      )
                    }
                  >
                    What data can HackEDU access and what do we store?
                  </a>
                </i>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  renderPickTeams(name, logo, obj) {
    return (
      <>
        <div>
          <button
            className='uk-button uk-button-small uk-button-secondary flex'
            type='button'
            onClick={() =>
              this.setState({
                step: 0,
              })
            }
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Go Back
          </button>
          <div
            className='uk-width-1-4 uk-padding uk-align-center text-center'
            style={{ backgroundColor: obj.settings.color }}
          >
            <img
              src={`/static/images/integrations/${logo}`}
              style={{ maxWidth: '70%' }}
              alt={logo}
            />
          </div>
          <br />
          <div className='text-center uk-width-2-3 uk-align-center '>
            <h4>Choose Teams</h4>
            <br />
            {this.props.teams.length === 0 ? (
              <p>No teams registered</p>
            ) : (
              <div className='flex flex-row justify-center flex-wrap'>
                {this.props.teams.map((team) => (
                  <div
                    key={team.uuid}
                    onClick={() => {
                      let { teams } = this.state
                      if (teams[team.uuid]) {
                        delete teams[team.uuid]
                      } else {
                        teams = {
                          ...teams,
                          [team.uuid]: team,
                        }
                      }
                      this.setState({
                        teams,
                      })
                    }}
                    className={`text-center uk-card uk-card-body uk-padding-small text-sm${
                      this.state.teams[team.uuid]
                        ? ' uk-card-primary'
                        : ' uk-card-default  text-muted'
                    }`}
                    style={{
                      cursor: 'pointer',
                      width: 220,
                      heigth: 70,
                      marginTop: 20,
                      marginLeft: 20,
                      marginRight: 20,
                    }}
                  >
                    <p
                      style={{
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                      className='truncate'
                    >
                      {team.name}
                    </p>
                    <hr
                      style={{
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    />
                    <p
                      style={{
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                      className='text-center'
                    >
                      {team.users}
                      &nbsp; Users
                    </p>
                  </div>
                ))}
              </div>
            )}
            <div className='uk-margin-large-top'>
              <button
                className='uk-button uk-button-primary'
                type='button'
                disabled={Object.keys(this.state.teams).length === 0}
                onClick={() => {
                  const { settings: sett } = this.state
                  this.setState({
                    settings: Object.keys(sett).reduce((p, k) => {
                      p[k] = sett[k] || ''
                      return p
                    }, {}),
                    step: 2,
                  })
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderAddSettings(name, logo, obj) {
    return (
      <>
        <div>
          <button
            className='uk-button uk-button-small uk-button-secondary flex'
            type='button'
            onClick={() => {
              const { teams } = this.state
              this.setState({
                step: Object.keys(teams).length > 0 ? 1 : 0,
              })
            }}
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Go Back
          </button>
          <div
            className='uk-width-1-4 uk-padding uk-align-center text-center'
            style={{ backgroundColor: obj.settings.color }}
          >
            <img
              src={`/static/images/integrations/${logo}`}
              style={{ maxWidth: '70%' }}
              alt={logo}
            />
          </div>
          <br />
          <div className='text-center uk-width-1-2 uk-align-center'>
            <div className='uk-margin'>
              <label className='uk-form-label'>Title</label>
              <div className='uk-inline uk-width-1-1'>
                <input
                  className='uk-input text-center'
                  type='text'
                  placeholder=''
                  value={this.state.title}
                  onChange={(e) => {
                    this.setState({
                      title: e.target.value,
                    })
                  }}
                  data-test-id='input-title'
                />
                <Icon
                  as='a'
                  role='button'
                  style={{
                    cursor: 'pointer',
                  }}
                  className='uk-form-icon uk-form-icon-flip'
                  name='sticky-note'
                />
              </div>
            </div>
            {this.renderForm()}
            <p
              className={`${
                typeof this.state.error === 'string'
                  ? 'text-danger'
                  : 'text-primary'
              }`}
              style={{
                visibility:
                  typeof this.state.error === 'string' || this.state.loadingTest
                    ? null
                    : 'hidden',
              }}
            >
              {typeof this.state.error === 'string'
                ? this.state.error
                : 'Validating credentials...'}
            </p>
            <div className='uk-margin-top'>
              <button
                disabled={
                  this.state.loadingTest ||
                  Object.keys(this.state.settings).some(
                    (item) =>
                      this.state.settings[item].trim() === '' &&
                      !this.state.optionals[item]
                  ) ||
                  typeof this.state.title !== 'string' ||
                  this.state.title.trim() === ''
                }
                onClick={() => {
                  const isReady = settings.activeIntegrations.includes(
                    this.props.match.params.integration
                  )
                  this.setState(
                    {
                      loadingTest: true,
                      error: false,
                    },
                    async () => {
                      if (!isReady) {
                        return this.setState({
                          isReady,
                          step: 4,
                          programs: [],
                          loadingTest: false,
                          selectedPrograms: {},
                        })
                      }
                      const isValid = await this.testIntegration()
                      if (!isValid) {
                        return this.setState({
                          error:
                            'Whoops! It looks like these credentials are incorrect.',
                          loadingTest: false,
                        })
                      }
                      if (
                        this.props.match.params.integration !== '' &&
                        !isReady
                      ) {
                        return this.setState({
                          step: 4,
                          programs: isValid,
                          loadingTest: false,
                          selectedPrograms: {},
                          isReady,
                        })
                      }
                      if (isValid.length === 1 && isReady) {
                        return this.setState({
                          step: 4,
                          programs: isValid,
                          loadingTest: false,
                          selectedPrograms: {
                            [isValid[0].id]: isValid[0],
                          },
                          isReady,
                        })
                      }
                      if (isValid.length > 1 && isReady) {
                        return this.setState({
                          selectedPrograms: {},
                          loadingTest: false,
                          step: 3,
                          programs: isValid,
                          isReady,
                        })
                      }
                      return this.setState({
                        error: `Whoops! It looks like you don't have any ${
                          settings.integrations[
                            this.props.match.params.integration
                          ].settings.choose
                        } to integrate to.`,
                        loadingTest: false,
                      })
                    }
                  )
                }}
                className='uk-button uk-button-primary'
                type='button'
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderPickPrograms(name, logo, obj) {
    return (
      <>
        <div>
          <button
            className='uk-button uk-button-small uk-button-secondary flex'
            type='button'
            onClick={() =>
              this.setState({
                step: 2,
              })
            }
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Go Back
          </button>
          <div
            className='uk-width-1-4 uk-padding uk-align-center text-center'
            style={{ backgroundColor: obj.settings.color }}
          >
            <img
              src={`/static/images/integrations/${logo}`}
              style={{ maxWidth: '70%' }}
              alt={logo}
            />
          </div>
          <br />
          <div className='text-center uk-width-2-3 uk-align-center '>
            <h4>
              {`Which ${
                settings.integrations[this.props.match.params.integration]
                  .settings.choose
              } do you want to apply to this integration ?`}
            </h4>
            <input
              placeholder='Filter'
              className='uk-input'
              value={this.state.filter}
              onChange={(e) =>
                this.setState({
                  filter: e.target.value,
                })
              }
            />
            <table className='uk-table uk-table-divider uk-table'>
              <thead>
                <tr>
                  <th style={{ width: '60%' }}>Name</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.state.programs.map((program) => {
                  if (
                    program.name
                      .toLowerCase()
                      .indexOf(this.state.filter.toLowerCase()) < 0
                  ) {
                    return null
                  }
                  return (
                    <tr key={program.id}>
                      <td className='text-left'>{program.name}</td>
                      <td>
                        <button
                          onClick={() => {
                            let { selectedPrograms } = this.state
                            if (selectedPrograms[program.id]) {
                              delete selectedPrograms[program.id]
                            } else {
                              selectedPrograms = {
                                ...selectedPrograms,
                                [program.id]: program,
                              }
                            }
                            this.setState({
                              selectedPrograms,
                            })
                          }}
                          className={`uk-button uk-button-${
                            this.state.selectedPrograms[program.id]
                              ? 'danger'
                              : 'primary'
                          } uk-button-small`}
                          type='button'
                        >
                          {this.state.selectedPrograms[program.id]
                            ? 'Remove'
                            : 'Add'}
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='text-center uk-width-1-2 uk-align-center'>
            <div className='uk-margin-large-top'>
              <button
                disabled={
                  this.state.loadingTest ||
                  Object.keys(this.state.selectedPrograms).length === 0
                }
                onClick={() => {
                  this.setState({
                    step: 4,
                  })
                }}
                className='uk-button uk-button-primary'
                type='button'
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderSummary(name, logo, obj) {
    let { choose } =
      settings.integrations[this.props.match.params.integration].settings
    choose = choose.charAt(0).toUpperCase() + choose.slice(1)
    return (
      <>
        <div>
          <button
            disabled={this.state.savingIntegration}
            className='uk-button uk-button-small uk-button-secondary flex'
            type='button'
            onClick={() =>
              this.setState({
                step:
                  Object.keys(this.state.programs).length > 1 &&
                  settings.activeIntegrations.includes(
                    this.props.match.params.integration
                  )
                    ? 3
                    : 2,
              })
            }
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Go Back
          </button>
          <div
            className='uk-width-1-4 uk-padding uk-align-center text-center'
            style={{ backgroundColor: obj.settings.color }}
          >
            <img
              src={`/static/images/integrations/${logo}`}
              style={{ maxWidth: '70%' }}
              alt={logo}
            />
          </div>
          <br />
          <div className='text-left uk-width-2-5 uk-align-center'>
            <h4 className='uk-margin-remove flex justify-between items-center'>
              Teams
              <button
                className='uk-button flex uk-button-small uk-button-default'
                type='button'
                onClick={() => {
                  this.setState({
                    step: Object.keys(this.state.teams).length > 0 ? 1 : 0,
                  })
                }}
              >
                <Icon name='edit-box' ratio={0.75} />
                &nbsp; Edit teams
              </button>
            </h4>
            {Object.keys(this.state.teams).length === 0 && (
              <p>This integration will be applied to the whole organization</p>
            )}
            {Object.keys(this.state.teams)
              .map((key) => this.state.teams[key].name)
              .sort()
              .map((team) => (
                <div className='uk-margin-small-top' key={team}>
                  <label className='uk-label uk-label-primary'>{team}</label>
                </div>
              ))}
            <br />

            <h4 className='uk-margin-remove flex justify-between items-center  '>
              Title
              <button
                className='uk-button flex uk-button-small uk-button-default'
                type='button'
                onClick={() => {
                  this.setState({
                    step: 2,
                  })
                }}
              >
                <Icon name='edit-box' ratio={0.75} />
                &nbsp; Edit Title
              </button>
            </h4>
            <p>{this.state.title}</p>
            <br />

            <h4 className='uk-margin-remove flex justify-between items-center  '>
              Settings
              <button
                className='uk-button flex uk-button-small uk-button-default'
                type='button'
                onClick={() => {
                  this.setState({
                    step: 2,
                  })
                }}
              >
                <Icon name='edit-box' ratio={0.75} />
                &nbsp; Edit Settings
              </button>
            </h4>
            {this.renderForm(true)}
            <br />
            {settings.activeIntegrations.includes(
              this.props.match.params.integration
            ) && (
              <>
                <h4 className='uk-margin-remove flex justify-between items-center'>
                  {`Selected ${choose}`}
                  <button
                    className='uk-button flex uk-button-small uk-button-default'
                    type='button'
                    disabled={
                      Object.keys(this.state.selectedPrograms).length < 2
                    }
                    uk-tooltip={
                      Object.keys(this.state.selectedPrograms).length < 2
                        ? 'Only 1 program available'
                        : undefined
                    }
                    onClick={() => {
                      this.setState({
                        step: 3,
                      })
                    }}
                  >
                    <Icon name='edit-box' ratio={0.75} />
                    &nbsp;
                    {`Edit ${choose}`}
                  </button>
                </h4>
                {Object.values(this.state.selectedPrograms).map((program) => (
                  <div className='uk-margin-small-top' key={program.id}>
                    <label className='uk-label uk-label-success'>
                      {program.name}
                    </label>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className='text-center uk-width-1-2 uk-align-center'>
            <div className='uk-margin-large-top'>
              <button
                disabled={this.state.savingIntegration}
                onClick={() => {
                  const s = {
                    ...this.state.settings,
                    programs: Object.keys(this.state.selectedPrograms),
                    programNames: Object.keys(
                      this.state.selectedPrograms
                    ).reduce((p, c) => {
                      p[c] = this.state.selectedPrograms[c]
                      return p
                    }, {}),
                  }
                  if (!this.state.isReady) {
                    s.isNotReady = true
                  }
                  this.props.addIntegration(
                    this.props.match.params.organization_uuid,
                    this.state.title,
                    s,
                    this.props.match.params.integration,
                    Object.values(this.state.teams).length > 0
                      ? Object.values(this.state.teams).map((t) => t.uuid)
                      : undefined,
                    () => {
                      this.props.history.push(
                        this.props.match.url.replace(
                          `/add/${this.props.match.params.integration}`,
                          ''
                        )
                      )
                    }
                  )
                }}
                className='uk-button uk-button-primary'
                type='button'
              >
                Finish
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderSteps() {
    switch (this.state.step) {
      case 1:
        return this.renderPickTeams(...arguments)
      case 2:
        return this.renderAddSettings(...arguments)
      case 3:
        return this.renderPickPrograms(...arguments)
      case 4:
        return this.renderSummary(...arguments)
      default:
        return this.renderPickOrganizationTeams(...arguments)
    }
  }

  renderDefault() {
    const HIDDEN_INTEGRATION_KEYS = [
      'snyk',
      'contrastsecurity',
      'fortify',
      'netsparker',
    ]
    return (
      <>
        <h1>Add Integration</h1>
        <br />
        <div
          className='flex flex-wrap flex-row justify-start'
          uk-height-match='.integration-card-description'
        >
          {Object.values(settings.integrations)
            .filter(
              (integration) =>
                !HIDDEN_INTEGRATION_KEYS.includes(integration.key)
            )
            .map((integration) => (
              <IntegrationCard
                key={integration.key}
                title={settings.integrations[integration.key].title}
                small
                description={integration.description}
                color={integration.settings.color}
                logo={settings.integrationLogos[integration.key]}
                keyName={integration.key}
                active={
                  Object.keys(
                    this.props.integrationsHash[integration.key] || {}
                  ).length > 0
                }
                integrations={
                  Object.keys(
                    this.props.integrationsHash[integration.key] || {}
                  ).length
                }
                onClick={() => {
                  this.props.history.push(
                    `${this.props.match.url}/${integration.key}`
                  )
                  this.setState({ error: false })
                  this.forceUpdate()
                }}
              />
            ))}
        </div>
        <br />
        <hr />
        <p className='text-center italic'>
          Or create your own with the{' '}
          <a
            href='https://developers.hackedu.com/?version=latest'
            target='_blank'
            rel='noopener noreferrer'
            className='text-green'
          >
            HackEDU API
          </a>
        </p>
      </>
    )
  }

  renderNativeIntegration(integration) {
    return (
      <>
        <div
          className='uk-card uk-card-default uk-card-body uk-width-1-2 uk-align-center'
          style={{
            borderRadius: 5,
            padding: 30,
          }}
        >
          <h2>{`${settings.integrations[integration].title} Integration`}</h2>
          <p>
            This integration is run natively in your network and requires using
            the HackEDU CLI. You can find documentation for this here:{' '}
            <a href='https://developers.hackedu.com'>
              https://developers.hackedu.com
            </a>
          </p>
          <p>
            Issues synced with this native integration will show up in your
            Admin Dashboard, just like cloud integrations.
          </p>
        </div>
      </>
    )
  }

  renderContent() {
    const { integration } = (this.props.match || {}).params || {}
    if (integration && this.isValidAndNotNative) {
      return this.renderNativeIntegration(integration)
    }
    if (integration && settings.integrations[integration]) {
      return this.renderSteps(
        settings.integrations[integration].title,
        settings.integrationLogos[integration],
        settings.integrations[integration]
      )
    }
    return this.renderDefault()
  }

  render() {
    return (
      <OrganizationLayout {...this.props} active='integrations'>
        {this.renderContent()}
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = ({ integrations, hacker, team }) => ({
  integrations: integrations.integrations,
  integrationsHash: integrations.integrationsHash,
  loadingIntegrations: integrations.loadingIntegrations,
  organizations: hacker.organizationsList,
  organizationsHash: hacker.organizationsHash,
  teams: team.teams,
})

export const AddIntegrationList = connect(mapStateToProps, {
  getTeams,
  testIntegration,
  addIntegration,
  getIntegrations,
})(AddIntegration)
