import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import cx from 'classnames'

function AllTrainingMenu(props) {
  const [activeIndex, setActiveIndex] = useState(0)

  const renderCoursesSubMenu = () => {
    if (props.match.path === '/all') {
      return props.content.courses.map((course, i) => (
        <li
          className={cx('pl-5', {
            active: i === activeIndex,
          })}
          key={course.uuid}
        >
          <a
            onClick={() => setActiveIndex(i)}
            href={`#scroll-${i}`}
            data-uk-scroll='offset: 100'
          >
            {course.title}
          </a>
        </li>
      ))
    }
    return null
  }

  const renderByVulnerabilityMenu = () => {
    if (props.match.path === '/by-vulnerability') {
      return props.vulnerabilities.map((vulnerabilityCategory, i) => {
        const active =
          (props.activeTab === null && i === 0) ||
          props.activeTab === vulnerabilityCategory.id
        return (
          <React.Fragment key={vulnerabilityCategory.id}>
            <li
              key={vulnerabilityCategory.id}
              className={cx('pl-3.75 mb-0', {
                'mt-1.25': i !== 0,
                active,
              })}
            >
              {vulnerabilityCategory.title}
            </li>
            {vulnerabilityCategory.vulnerabilities.map((vulnerability) => {
              return (
                <li
                  key={vulnerability.id}
                  className={cx('hover-text uk-margin-remove pl-7.5 h-8', {
                    active,
                  })}
                >
                  <input
                    className='uk-checkbox'
                    type='checkbox'
                    checked={Boolean(
                      props.vulnerabilityFilters[vulnerability.id]
                    )}
                    onChange={() =>
                      props.onToggleVulnerability(vulnerability.id)
                    }
                    style={{
                      width: 12,
                      height: 12,
                      backgroundSize: 'contain',
                      borderRadius: 4,
                      marginRight: 7,
                    }}
                  />

                  <a
                    onClick={() =>
                      props.onToggleVulnerability(vulnerability.id)
                    }
                    href={`#scroll-vuln-${vulnerability.id}`}
                    data-uk-scroll='offset: 100'
                    className={cx({
                      'text-primary': Boolean(
                        props.vulnerabilityFilters[vulnerability.id]
                      ),
                    })}
                  >
                    <i>{vulnerability.title}</i>
                  </a>
                </li>
              )
            })}
          </React.Fragment>
        )
      })
    }
    return null
  }

  return (
    <ul className='links dark'>
      <li className={cx({ active: props.match.path === '/all' })}>
        <Link to='/all'>Courses</Link>
      </li>
      {renderCoursesSubMenu()}
      <li className={cx({ active: props.match.path === '/by-vulnerability' })}>
        <Link to='/by-vulnerability'>Vulnerability Types</Link>
      </li>
      {renderByVulnerabilityMenu()}
      {props.showHackingChallenges && (
        <li
          className={cx({
            active: props.match.path === '/challenges',
            'mt-2.5':
              props.match.path === '/by-vulnerability' &&
              !props.showHackingChallenges,
          })}
        >
          <Link to='/challenges'>Hacking Challenges</Link>
        </li>
      )}
      {props.showCodingChallenges && (
        <>
          <li
            className={cx({
              active: props.match.path === '/coding-challenges',
            })}
          >
            <Link to='/coding-challenges'>Coding Challenges</Link>
          </li>
        </>
      )}
    </ul>
  )
}

const mapStateToProps = (state) => ({
  showCodingChallenges: state.hacker.showCodingChallenges,
  showHackingChallenges: state.hacker.showHackingChallenges,
})

export default withRouter(connect(mapStateToProps, {})(AllTrainingMenu))
