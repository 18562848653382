export const isEmailValid = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const isPasswordLengthCorrect = (password) => {
  const re = /^.{6,64}$/
  return re.test(password)
}

const CharacterSets = {
  LENGTH: /^.{10,}$/,
  DIGITS: /\d/,
  UCASE: /[A-Z]/,
  LCASE: /[a-z]/,
  SPECIAL: /[\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/\\,><':;|_~`+=]+/,
}

export const checkPasswordComplexity = (password) => {
  const result = {
    passed: true,
    missing: [],
  }

  result.missing = Object.keys(CharacterSets).reduce((missing, setName) => {
    if (!CharacterSets[setName].test(password)) {
      result.passed = false
      missing.push(setName)
    }
    return missing
  }, [])
  return result
}
