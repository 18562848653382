import { type CodeSubmission } from './types'
import { getNewestSubmission, deduplicateSubmissions } from './util'
import { notifyParentSjApp } from 'app/sjInterop'
import { type TournamentDetails } from 'app/sjTournament'
import * as api from './api'

const INIT = 'codesub/INIT'
const TEARDOWN = 'codesub/TEARDOWN'
const SET_ERROR = 'codesub/SET_ERROR'
const SET_MODE = 'codesub/SET_MODE'
const SET_CANCEL_POLLING_THUNK = 'codesub/SET_CANCEL_POLLING_THUNK'
const CANCEL_POLLING = 'codesub/CANCEL_POLLING'
const LOAD_SUBMISSIONS = 'codesub/LOAD_SUBMISSIONS'
const APPEND_SUBMISSIONS = 'codesub/APPEND_SUBMISSIONS'

type Mode = (
  'uninitialized' // We don't have a lesson loaded or haven't loaded submissions yet
  | 'idle'        // We're initialized and ready to initiate new submissions
  | 'submitting'  // We're currently submitting a request to test code
  | 'waiting'     // We're continually polling for the results of our newest submission
)

type CodesubAction = {
  /** Initializes the module when the lessson UI is loaded. */
  type: typeof INIT
  lessonKey: string
  lowerBound: Date
} | {
  /** Clears state when the lesson UI is unloaded. */
  type: typeof TEARDOWN
} | {
  /** Records an API error that should be displayed to the user. */
  type: typeof SET_ERROR
  error: string
} | {
  /**
   * Changes the state to indicate whether we're currently creating a submission,
   * waiting on results for a submission, or simply sitting idle.
   */
  type: typeof SET_MODE
  mode: Mode
} | {
  /**
   * Records a callback function that will stop periodically making API calls (to poll
   * for the results of our latest submission) when our module state is reinitialized or
   * torn down.
   */
  type: typeof SET_CANCEL_POLLING_THUNK
  thunk: () => void
} | {
  /** Stops polling for the results of our latest submission, if currently doing so. */
  type: typeof CANCEL_POLLING,
} | {
  /**
   * Populates the submissions array with the initial set of submissions received from
   * the API.
   */
  type: typeof LOAD_SUBMISSIONS
  submissions: CodeSubmission[]
} | {
  /**
   * Records one or more newly-created submissions that have been received via polling.
   */
  type: typeof APPEND_SUBMISSIONS
  submissions: CodeSubmission[]
}

const initialState = {
  // Last human-readable error encountered in an API call, cleared on submit
  error: '',
  // Current state of the codesub module: we're either uninitialized, sitting idle and
  // ready to submit, in the process of submitting, or waiting for the results of a
  // submission
  mode: 'uninitialized' as Mode,
  // Identifies the lesson we currently have loaded, if initialized
  lessonKey: '',
  // If nonzero, finite lower bound for submission history: e.g. if running in a
  // tournament, this value is set to the tournament's started-at timestamp so we only
  // fetch submissions created since the start of the tournament
  lowerBound: new Date(0),
  // Records all submissions that the auth'd user has made for that lesson
  submissions: [] as CodeSubmission[],
  // Thunk that will stop polling for results, if we're currently polling
  cancelPolling: null as (() => void) | null,
}

type CodesubState = typeof initialState
type CodesubDispatch = (action: CodesubAction) => void

/**
 * The codesub module handles all user code submission state when using the newer
 * backend services (svc-code-sub and svc-sandbox). Prior to the introduction of this
 * module (and the 'eng_use_svc_code_sub' feature flag), submissions were loaded from
 * api-hacker, either via the hacker module (for repl lessons) or the sbcrsubmission
 * module (for codereview lessons).
 */
export default function reducer(state: CodesubState = initialState, action: CodesubAction | {} = {}): CodesubState {
  if (typeof action['type'] === 'undefined') {
    return state
  }
  const a = action as CodesubAction
  switch (a.type) {
    case INIT:
      if (state.cancelPolling) {
        state.cancelPolling()
      }
      return {
        ...state,
        error: '',
        mode: 'uninitialized', // Still need to load submissions before mode is idle
        lessonKey: a.lessonKey,
        lowerBound: a.lowerBound,
        submissions: [],
        cancelPolling: null,
      }
    case TEARDOWN:
      if (state.cancelPolling) {
        state.cancelPolling()
      }
      return {
        ...state,
        error: '',
        mode: 'uninitialized',
        lessonKey: '',
        lowerBound: new Date(0),
        submissions: [],
        cancelPolling: null,
      }
    case SET_ERROR:
      return {
        ...state,
        error: a.error,
      }
    case SET_MODE:
      return {
        ...state,
        error: a.mode === 'submitting' ? '' : state.error,
        mode: a.mode,
      }
    case SET_CANCEL_POLLING_THUNK:
      return {
        ...state,
        cancelPolling: a.thunk,
      }
    case CANCEL_POLLING:
      if (state.cancelPolling) {
        state.cancelPolling()
      }
      return {
        ...state,
        cancelPolling: null,
      }
    case LOAD_SUBMISSIONS:
      return {
        ...state,
        mode: 'idle',
        submissions: a.submissions,
      }
    case APPEND_SUBMISSIONS:
      return {
        ...state,
        mode: 'idle',
        submissions: deduplicateSubmissions(a.submissions.concat(state.submissions)),
        cancelPolling: null,
      }
    default:
      return state
  }
}

/**
 * Called from the UI to initialize the codesub module whenever a new lesson is loaded,
 * to fetch the user's historical submissions for that lesson.
 */
export function init(lessonKey: string, tournamentDetails?: TournamentDetails) {
  return async (dispatch: CodesubDispatch) => {
    // If we've been initialized for a tournament, then the start time of our tournament
    // becomes the lower bound on submissions we want to know about: we only want to
    // fetch data for submissions that have been created since our tournament started
    let lowerBound = new Date(0)
    if (tournamentDetails) {
      lowerBound = tournamentDetails.startedAt
    }

    // Set up the initial state of the module to reflect the lesson we've loaded
    dispatch({
      type: INIT,
      lessonKey,
      lowerBound,
    })

    // Fetch an initial list of submissions from svc-code-sub
    try {
      const since = lowerBound.getTime() > 0 ? lowerBound : undefined
      const submissions = await api.fetchSubmissions(lessonKey, { since })
      dispatch({ type: LOAD_SUBMISSIONS, submissions })
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        error: `Failed to fetch submissions: ${err['message'] || JSON.stringify(err)}`
      })
    }
  }
}

/**
 * Called when we're unmounting the lesson UI, letting the codesub module know that
 * we no longer need to load or create submissions.
 */
export function teardown() {
  return (dispatch: CodesubDispatch) => {
    dispatch({ type: TEARDOWN })
  }
}

/**
 * Attempts to initiate a new code submission via svc-sandbox
 */
export function createSubmission(lessonKey: string, lessonLoadedAt: Date, tournamentDetails?: TournamentDetails) {
  return async (dispatch: CodesubDispatch, getState: () => { codesub: CodesubState }) => {
    // Record that we're attempting to submit, so we only make one submission at a time
    dispatch({ type: SET_MODE, mode: 'submitting' })

    // Grab the code from our sandbox and submit it for testing, then record the fact
    // that we're now waiting for a submission to be created
    try {
      await api.createSubmission(lessonKey, lessonLoadedAt, tournamentDetails)
      dispatch({ type: SET_MODE, mode: 'waiting' })
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        error: `Failed to create submission: ${err['message'] || JSON.stringify(err)}`
      })
      dispatch({ type: SET_MODE, mode: 'idle' })
      return
    }

    // Begin periodically polling the API for the results of our latest submission
    const controller = new AbortController()
    const newestSubmissionBuffered = getNewestSubmission(getState().codesub.submissions)
    const lowerBound = getState().codesub.lowerBound
    const intervalsMs = [2000, 2000, 2000, 2000, 2000, 3000, 4000, 5000]
    try {
      // Make periodic codesub API requests until we get one or more new submissions
      const submissions = await api.pollForNewSubmissions(controller.signal, lessonKey, newestSubmissionBuffered, lowerBound, intervalsMs)

      // If any of those new submissions is passed and we're embedded in an SJ iframe,
      // notify the SJ app so it can instruct the SJ backend to pull HE lesson status
      for (const submission of submissions) {
        if (submission.result.status === 'passed') {
          notifyParentSjApp({ type: 'lesson-status-changed' })
          break
        }
      }

      // Update our state to concatenate our new submissions into the list and exit the
      // 'waiting' state
      dispatch({ type: APPEND_SUBMISSIONS, submissions })
    } catch (err) {
      const isAbort = err instanceof DOMException && err.name === 'AbortError'
      if (!isAbort) {
        dispatch({
          type: SET_ERROR,
          error: `Failed to create submission: ${err['message'] || JSON.stringify(err)}`
        })
        dispatch({ type: SET_MODE, mode: 'idle' })
        dispatch({ type: CANCEL_POLLING })
      }
    }
  }
}
