import React from 'react'
import AceEditor from 'react-ace'
import Moment from 'react-moment'

import ActionComponent from 'app/views/components/ActionComponent'
import Icon from 'app/views/components/Icon'

// Settings
import settings from 'settings'

// Utils
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

// Local Imports
import FormatedMinutes from '../FormatedMinutes'
import PrimaryButton from '../Buttons/PrimaryButton'

class TrainingTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      userCodeSubmissions: undefined,
      row: undefined,
      showCode: undefined,
    }
    this.renderRows = this.renderRows.bind(this)
    this.renderRow = this.renderRow.bind(this)
    this.resetIndexTab = this.resetIndexTab.bind(this)
  }

  resetCodeSubmissionShow() {
    this.setState({
      userCodeSubmissions: undefined,
      row: undefined,
      showCode: undefined,
    })
  }

  resetIndexTab() {
    if (this.state.activeTab === 'custom') {
      this.setState({ activeTab: 'all' })
    }
  }

  renderRow(row, isCustom = false) {
    const hasTest = row.has_test
    const mustPass = row.must_pass_coding_exercise
    let date = null
    if (hasTest && mustPass) {
      date = row.passed_at
    } else {
      date = row.completed_at
    }
    return (
      <tr key={JSON.stringify(row)}>
        <td
          className='text-sm uk-width-1-4'
          style={{ verticalAlign: 'middle' }}
        >
          {row.title}
        </td>
        {/* {isCustom && (
          <td
            className="text-sm uk-width-1-4"
            style={{ verticalAlign: 'middle' }}
          >
            {row.complete_by ? (
              <Moment format="MMMM D, YYYY">
                {getLocalDateFromUTC(row.complete_by)}
              </Moment>
            )
              : (
                '-'
              )}
          </td>
        )} */}
        <td
          className='text-sm uk-width-1-4'
          style={{ verticalAlign: 'middle' }}
        >
          {date ? (
            <Moment format='MMMM D, YYYY'>{getLocalDateFromUTC(date)}</Moment>
          ) : (
            '-'
          )}
        </td>
        <td
          className='text-sm text-center uk-width-1-4'
          style={{ verticalAlign: 'middle' }}
        >
          <FormatedMinutes minutes={row.total_lesson_time} isText />
        </td>
        <td
          className='text-center uk-width-1-4'
          style={{ verticalAlign: 'middle' }}
        >
          {0 == 0 && (
            <PrimaryButton
              size={'small'}
              onClick={() => {
                this.setState(
                  {
                    userCodeSubmissions: 'loading',
                  },
                  () => {
                    this.props
                      .getHackerCodeSubmissions(
                        this.props.match.params.organization_uuid,
                        this.props.selectedUser.uuid,
                        row.uuid
                      )
                      .then(({ data }) => {
                        this.setState({
                          userCodeSubmissions: data,
                          row,
                        })
                      })
                      .catch((error) => console.error(error))
                  }
                )
              }}
              label='Code Submissions'
            />
          )}
        </td>
      </tr>
    )
  }

  renderTitle() {
    if (this.state.activeTab === 'all') {
      return 'All Training'
    }
    if (this.state.activeTab === 'custom') {
      return 'Custom Plan'
    }
    if (this.props.plans[this.state.activeTab].no_training) {
      return ''
    }
    return this.props.plans[this.state.activeTab].title
  }

  renderDescription() {
    if (this.props.plans[this.state.activeTab].no_training) {
      return 'No training assigned'
    }
    return this.props.plans[this.state.activeTab].description
  }

  renderRows(data) {
    const rows = data.map((row) => {
      return this.renderRow(row)
    })

    return (
      <table className='uk-table uk-table-divider'>
        <thead>
          <tr>
            <th>Content</th>
            <th>Last Completed</th>
            <th className='text-center'>Time Spent</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    )
  }

  render() {
    if (this.props.plans === 'loading') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
          }}
        >
          <div data-uk-spinner='' className='uk-align-center' />
        </div>
      )
    }

    if (this.state.userCodeSubmissions) {
      if (this.state.userCodeSubmissions === 'loading') {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 20,
            }}
          >
            <div data-uk-spinner='' />
          </div>
        )
      }
      return (
        <div
          className='uk-padding uk-padding-remove-top uk-margin-remove'
          data-uk-grid=''
        >
          <button
            className='uk-button uk-button-secondary uk-button-small flex items-center'
            onClick={() =>
              this.setState({ row: null, userCodeSubmissions: null })
            }
            style={{
              paddingLeft: 10,
              height: 40,
            }}
            type='button'
          >
            <Icon name='arrow-left' style={{ paddingRight: 10 }} />
            Back to Code Submissions
          </button>
          <h2 className='uk-margin-remove-top'>{this.state.row.title}</h2>
          <table
            className='uk-table uk-table-divider'
            style={{
              marginTop: 10,
            }}
          >
            <tbody>
              {this.state.userCodeSubmissions.length > 0 &&
                this.state.userCodeSubmissions.map((codeSubmission) => {
                  return (
                    <React.Fragment key={codeSubmission.id}>
                      <tr>
                        <td>
                          <small>
                            {settings.engines[codeSubmission.engine]}
                          </small>
                        </td>
                        <td>
                          <small>
                            <Moment format='MMMM D, YYYY @ HH:mm'>
                              {getLocalDateFromUTC(codeSubmission.submitted_at)}
                            </Moment>
                          </small>
                        </td>
                        <td className='text-right'>
                          {codeSubmission.passed ? (
                            <span className='uk-label uk-label-success'>
                              Passed
                            </span>
                          ) : (
                            <span className='uk-label uk-label-danger'>
                              Failed
                            </span>
                          )}
                        </td>
                        <td className='text-right'>
                          {codeSubmission.content_type !== 'mobile' && (
                            <button
                              type='button'
                              className={`uk-button uk-button-small ${
                                this.state.showCode === codeSubmission.id
                                  ? 'uk-button-danger'
                                  : 'uk-button-primary'
                              }`}
                              onClick={() => {
                                this.setState({
                                  showCode:
                                    codeSubmission.id === this.state.showCode
                                      ? undefined
                                      : codeSubmission.id,
                                })
                              }}
                            >
                              {this.state.showCode === codeSubmission.id
                                ? 'Hide Code'
                                : 'View Code'}
                            </button>
                          )}
                        </td>
                      </tr>
                      {this.state.showCode === codeSubmission.id && (
                        <tr>
                          <td colSpan={4}>
                            <AceEditor
                              showPrintMargin={false}
                              height='400px'
                              width='100%'
                              placeholder=''
                              mode={
                                settings.languages[
                                  codeSubmission.engine.replace(
                                    /[^A-Za-z]/g,
                                    ''
                                  )
                                ]
                              }
                              theme='monokai'
                              name='editor'
                              fontSize={14}
                              showGutter
                              wrapEnabled={false}
                              highlightActiveLine
                              onChange={() => {}}
                              value={codeSubmission.code}
                              setOptions={{
                                showLineNumbers: true,
                                tabSize: 2,
                              }}
                              editorProps={{ $blockScrolling: Infinity }}
                            />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })}
            </tbody>
          </table>
        </div>
      )
    }
    return (
      <>
        <div className='uk-width-1-1 uk-grid'>
          <div className='uk-width-1-5'>
            <div>
              <div className='hackedu-content-menu'>
                <ul className='links dark'>
                  {this.props.plans.map((plan, i) => {
                    if (i === this.props.plans.length - 1) {
                      return null
                    }
                    const className =
                      (this.state.activeTab === null && i === 0) ||
                      this.state.activeTab === i ||
                      (plan.custom && this.state.activeTab === 'custom')
                        ? 'active'
                        : ''
                    return (
                      <li
                        key={`${
                          plan.name ||
                          plan.team_name ||
                          this.props.organization.name
                        }`}
                        className={className}
                      >
                        <a // eslint-disable-line
                          onClick={() => {
                            this.setState({
                              activeTab: plan.custom ? 'custom' : i,
                            })
                          }}
                          href={`#scroll-${i}`}
                          data-uk-scroll='offset: 100'
                        >
                          {plan.name ||
                            plan.team_name ||
                            this.props.organization.name}
                        </a>
                      </li>
                    )
                  })}
                  <li
                    className={this.state.activeTab === 'all' ? 'active' : ''}
                  >
                    <a // eslint-disable-line
                      onClick={() => {
                        this.setState({ activeTab: 'all' })
                      }}
                      data-uk-scroll='offset: 100'
                    >
                      All Training
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className='uk-width-4-5 uk-section-small'
            style={{ paddingTop: 15 }}
          >
            <div>
              <div className='flex justify-between'>
                <h2>{this.renderTitle()}</h2>
                <div>
                  <PrimaryButton
                    onClick={this.props.onAddSingleContent}
                    size='small'
                    label='Assign Lessons'
                  />
                </div>
              </div>
              {this.state.activeTab !== 'all' &&
                this.state.activeTab !== 'custom' && (
                  <small>{this.renderDescription()}</small>
                )}
              {this.state.activeTab !== 'all' &&
                this.state.activeTab !== 'custom' &&
                this.props.plans[this.state.activeTab].groups.map((phase) => {
                  return (
                    <div key={phase.uuid}>
                      <br />
                      <h4 style={{ fontSize: 20 }}>{phase.title}</h4>
                      {this.renderRows(phase.content)}
                    </div>
                  )
                })}
              {this.props.plans !== 'loading' &&
              this.state.activeTab === 'custom' &&
              this.props.plans[this.props.plans.length - 2].content ? (
                <>
                  <table className='uk-table uk-table-divider'>
                    <thead>
                      <tr>
                        <th>Content</th>
                        {/* <th>Complete By</th> */}
                        <th>Last Completed</th>
                        <th className='text-center'>Time Spent</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.plans[
                        this.props.plans.length - 2
                      ].content.map((content) => {
                        return (
                          <React.Fragment key={content.uuid}>
                            {this.renderRow(content, true)}
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <ActionComponent action={this.resetIndexTab} />
              )}
              {this.props.plans !== 'loading' &&
                this.state.activeTab === 'all' && (
                  <>
                    <table className='uk-table uk-table-divider'>
                      <thead>
                        <tr>
                          <th>Content</th>
                          <th>Last Completed</th>
                          <th className='text-center'>Time Spent</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.plans[
                          this.props.plans.length - 1
                        ].content.courses.map((course) => {
                          return (
                            <React.Fragment key={course.uuid}>
                              <tr>
                                <td colSpan={4}>
                                  <h4 style={{ fontSize: 18 }}>
                                    {course.title}
                                  </h4>
                                </td>
                              </tr>
                              {course.items.map((item) => {
                                if (item.item_type === 'content') {
                                  return this.renderRow(item)
                                }
                                if (item.item_type === 'lesson') {
                                  return (
                                    <React.Fragment key={item.uuid}>
                                      <tr>
                                        <td colSpan={4}>
                                          <h4 style={{ fontSize: 14 }}>
                                            {item.title}
                                          </h4>
                                        </td>
                                      </tr>
                                      {item.content.map((row) =>
                                        this.renderRow(row)
                                      )}
                                    </React.Fragment>
                                  )
                                }
                              })}
                            </React.Fragment>
                          )
                        })}
                      </tbody>
                    </table>
                  </>
                )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default TrainingTab
