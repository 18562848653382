/* eslint-disable max-classes-per-file */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

// Components
import FormatedMinutes from 'app/views/components/FormatedMinutes'
import Loader from 'app/views/components/Loader'
import Statistics from 'app/views/components/Statistics'
import ProficiencyChart from 'app/views/containers/Organizations/Dashboard/ProficiencyChart'
import CategoryTimeChart from 'app/views/containers/Organizations/Dashboard/CategoryTimeChart'
import TrainingTimeChart from 'app/views/containers/Organizations/Dashboard/TrainingTimeChart'

// Containers
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'

// Redux
import {
  getOrganizations,
  getTeamDetails,
  getTeamsReportLessons,
} from 'app/state/modules/hacker'

// Utils
import analytics from 'app/views/utils/analytics'

// Local Imports

class OrganizationDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationId: this.props.match.params.organization_uuid,
    }
  }

  componentDidMount() {
    this.debounceTimer = null
    if (!this.props.hasLoadedOrganizations) {
      this.props.getOrganizations()
    }
    this.props.getTeamsReportLessons(this.state.organizationId, '')
    this.props.getTeamDetails(this.state.organizationId)
    analytics.page('organizations-users')
  }

  static getDerivedStateFromProps(props, state) {
    const organizationId = props.match.params.organization_uuid
    if (organizationId !== state.organizationId) {
      props.getTeamDetails(organizationId)
      // if (props.loadingReportLessons) {
      props.getTeamsReportLessons(organizationId, '')
      // }
      return {
        organizationId,
      }
    }
    return null
  }

  render() {
    if (
      !this.props.hasLoadedOrganizations ||
      !this.props.hasLoadedCurrentTeamDetails ||
      this.props.loadingReportLessons
    ) {
      return <Loader visible />
    }
    return (
      <OrganizationLayout
        data-test-id='organization-users-component'
        active='dashboard'
        {...this.props}
      >
        <h2 className='uk-inline'>Assigned Lessons Remaining</h2>
        <ResponsiveContainer
          height={350}
          className='text-sm uk-width-1-1'
        >
          <AreaChart
            data={
              this.props.reportLessons.length === 1
                ? [
                    ...this.props.reportLessons,
                    {
                      lessons: this.props.reportLessons[0].lessons,
                      date: moment().format('YYYY-MM-DD'),
                    },
                  ]
                : this.props.reportLessons
            }
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis
              dataKey='date'
              tickFormatter={(date) => {
                if (date === '') {
                  return ''
                }
                return `  ${moment(date).format('MMM D')}  `
              }}
            />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Area
              type='monotone'
              dataKey='lessons'
              name='Assigned Lessons Remaining'
              fill='#1e87f0'
            />
          </AreaChart>
        </ResponsiveContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: 40,
          }}
        >
          <Statistics
            noMargin
            header={
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 10,
                }}
              >
                {`${(
                  (this.props.completedAssignedLessons /
                    this.props.totalAssignedLessons) *
                    100 || 0
                ).toFixed(0)}`}
                <span style={{ fontSize: 22 }}>%</span>
              </span>
            }
            content={`${this.props.completedAssignedLessons}/${this.props.totalAssignedLessons}`}
            detail='Assigned Lessons Complete'
          />
          <Statistics
            header={<FormatedMinutes minutes={this.props.totalTimeSpent} />}
            content={
              <FormatedMinutes
                minutes={
                  this.props.totalTimeSpent / this.props.totalHackers || 0
                }
                isText
                extraText='per user'
              />
            }
            detail='Time spent'
          />
          <Statistics
            noMargin
            header={this.props.totalCodeSubmissions}
            content={`${(
              this.props.totalCodeSubmissions / this.props.totalHackers || 0
            ).toFixed(0)} per user`}
            detail='Code Submissions'
          />
        </div>
        <hr className='uk-margin-top uk-margin-bottom' />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: 40,
          }}
        >
          <Statistics
            header={this.props.totalHackers}
            content={this.props.totalHackers === 1 ? 'User' : 'Users'}
            detail='registered in organization'
          />
          <Statistics
            header={this.props.totalUsersWithPlanCompleted}
            content={
              this.props.totalUsersWithPlanCompleted === 1 ? 'User' : 'Users'
            }
            detail='with assigned plans completed'
          />
          <Statistics
            noMargin
            header={this.props.totalTeams}
            content={this.props.totalTeams === 1 ? 'Team' : 'Teams'}
            detail='registered in organization'
          />
        </div>
        <hr className='uk-margin-top uk-margin-bottom' />
        <div className='uk-grid'>
          <div className='uk-width-1-2'>
            <ProficiencyChart />
          </div>
          <div className='uk-width-1-2'>
            <CategoryTimeChart />
          </div>
        </div>
        <hr className='uk-margin-top uk-margin-bottom' />
        <TrainingTimeChart />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state, props) => ({
  organizations: state.hacker.organizationsList,
  organizationsHash: state.hacker.organizationsHash,

  reportTotalLessons: state.hacker.reportTotalLessons,
  reportLessons: state.hacker.reportLessons,
  totalAssignedLessons: state.hacker.totalAssignedLessons,
  completedAssignedLessons: state.hacker.completedAssignedLessons,
  totalUsersWithPlanCompleted: state.hacker.totalUsersWithPlanCompleted,
  totalTimeSpent: state.hacker.totalTimeSpent,
  totalTeams: state.hacker.totalTeams,
  totalHackers: state.hacker.totalHackers,

  hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
  hasLoadedCurrentTeamDetails: state.hacker.hasLoadedCurrentTeamDetails,

  hackerUUID: state.hacker.profile.user_id,
  loadingReportLessons: state.hacker.loadingReportLessons,

  totalCodeSubmissions: state.hacker.totalCodeSubmissions,
})

export default withRouter(
  connect(mapStateToProps, {
    getOrganizations,
    getTeamDetails,
    getTeamsReportLessons,
  })(OrganizationDashboard)
)
