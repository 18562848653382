import React from 'react'
import PropTypes from 'prop-types'

const OutputHintTemplate = ({ name, description, code, children }) => (
  <div style={{ marginTop: 20 }}>
    <h4 style={{ marginBottom: 0 }}>{name} Output</h4>
    <p style={{ marginTop: 8 }}>
      {description} to produce output in the <b>Sandbox Output</b> panel. For
      example, you might add some output to a login function:
    </p>
    <pre>{code}</pre>
    <p>
      Once you've clicked <b>Save Code</b> to apply your patch to the sandbox,
      you'll need to interact with the app in order for it to run your modified
      code. In this example, you'd need to try logging in to the app to trigger
      your output.
    </p>
    {children}
  </div>
)

const DotNetOutputHint = () => (
  <OutputHintTemplate
    name='C# / .NET'
    description={
      <>
        You can use functions like <code>Console.WriteLine</code>
      </>
    }
    code={
      'public static bool Login(string username, string password) {\n    Console.WriteLine($"username is {username}");\n    return false;\n}'
    }
  />
)

const ClojureOutputHint = () => (
  <OutputHintTemplate
    name='Clojure'
    description={
      <>
        You can use functions like <code>println</code> or <code>printf</code>
      </>
    }
    code={
      '(defn login\n  [username password]\n  (printf "username is %s%n" username)\n)'
    }
  />
)

const GoOutputHint = () => (
  <OutputHintTemplate
    name='Go'
    description={
      <>
        You can import the <code>fmt</code> package and use functions like{' '}
        <code>fmt.Println</code> or <code>fmt.Printf</code>
      </>
    }
    code={
      'import (\n    "fmt"\n)\n\nfunc login(username string, password string) (bool, error) {\n    fmt.Printf("username is %s\\n", username)\n    return false, nil\n}'
    }
  />
)

const JavaOutputHint = () => (
  <OutputHintTemplate
    name='Java'
    description={
      <>
        You can use functions like <code>System.out.println</code> and{' '}
        <code>System.out.format</code>
      </>
    }
    code={
      'public static boolean login(String username, String password) {\n    System.out.format("username is %s%n", username);\n    return false;\n}'
    }
  />
)

const JavaScriptOutputHint = () => (
  <OutputHintTemplate
    name='JavaScript'
    description={
      <>
        You can call <code>console.log</code> and similar functions
      </>
    }
    code={
      'exports.login = function(username, password) {\n  console.log(`username is ${username}`);\n}'
    }
  />
)

const KotlinOutputHint = () => (
  <OutputHintTemplate
    name='Kotlin'
    description={
      <>
        You can call <code>println</code> to output to the console
      </>
    }
    code={
      'fun login(username: String, password: String) {\n\tprintln("username is $username")\n}'
    }
  />
)

const PerlOutputHint = () => (
  <OutputHintTemplate
    name='Perl'
    description={
      <>
        You can include <code>print</code> statements
      </>
    }
    code={
      'sub login {\n  my $username = $_[0];\n  print "username is $username\\n";\n}'
    }
  />
)

const PHPOutputHint = () => (
  <OutputHintTemplate
    name='PHP'
    description={
      <>
        You can include <code>echo</code> statements
      </>
    }
    code={
      'function login($username, $password) {\n  echo \'username is \' . $username;\n}'
    }
  />
)

const PythonOutputHint = () => (
  <OutputHintTemplate
    name='Python'
    description={
      <>
        You can include <code>print</code> statements
      </>
    }
    code={
      'def login(username, password):\n    print("username is %s" % username)'
    }
  />
)

const RubyOutputHint = () => (
  <OutputHintTemplate
    name='Ruby'
    description={
      <>
        You can call <code>puts</code> or similar functions
      </>
    }
    code={
      'def self.login(username, password):\n  puts "username is %s" % username'
    }
  />
)

const RustOutputHint = () => (
  <OutputHintTemplate
    name='Rust'
    description={
      <>
        You can call <code>println!</code> and similar macros
      </>
    }
    code={
      'fn login(username: &str) {\n    println!("username is {username}");\n}'
    }
  />
)

const ScalaOutputHint = () => (
  <OutputHintTemplate
    name='Scala'
    description={
      <>
        You can use functions like <code>println</code> and <code>printf</code>
      </>
    }
    code={
      'def login(username: String, password: String): Boolean = {\n    printf("username is %s\\n", username)\n    false\n}'
    }
  >
    <p>
      Note that you may need to enclose the function body in a block (
      <code>{'{'}</code> / <code>{'}'}</code>) in order to add print statements
      to a pure function.
    </p>
  </OutputHintTemplate>
)

const TypeScriptOutputHint = () => (
  <OutputHintTemplate
    name='TypeScript'
    description={
      <>
        You can call <code>console.log</code> and similar functions
      </>
    }
    code={
      'exports.login = function(username: string, password: string): void {\n  console.log(`username is ${username}`);\n}'
    }
  />
)
const COutputHint = () => (
  <OutputHintTemplate
    name='C'
    description={
      <>
        You can call <code>printf</code> and similar functions
      </>
    }
    code={
      'int login(char* username, char* password) {\nprintf("Username: %s Password: %s" , username, password);\n}'
    }
  />
)
const CppOutputHint = () => (
  <OutputHintTemplate
    name='C++'
    description={<>You can use std::cout and similar functions</>}
    code={
      'int login(char* username, char* password) {\nstd::cout << "Username:" << username << std::endl;\n}'
    }
  />
)
const CobolOutputHint = () => (
  <OutputHintTemplate
    name='cobol'
    description={<>You can use DISPLAY</>}
    code={'DISPLAY VARIABLE_1, VARIABLE_2.'}
  />
)

function LanguageOutputHint(props) {
  const { engine } = props
  if (engine === 'c') return <COutputHint />
  if (engine === 'cpp') return <CppOutputHint />
  if (engine === 'cobol') return <CobolOutputHint />
  if (engine === 'clojure') return <ClojureOutputHint />
  if (engine === 'dotnet') return <DotNetOutputHint />
  if (engine === 'go') return <GoOutputHint />
  if (engine === 'java') return <JavaOutputHint />
  if (engine === 'kotlin') return <KotlinOutputHint />
  if (engine === 'node') return <JavaScriptOutputHint />
  if (engine === 'perl') return <PerlOutputHint />
  if (engine === 'php') return <PHPOutputHint />
  if (engine === 'python') return <PythonOutputHint />
  if (engine === 'ruby') return <RubyOutputHint />
  if (engine === 'rust') return <RustOutputHint />
  if (engine === 'scala') return <ScalaOutputHint />
  if (engine == 'typescript') return <TypeScriptOutputHint />

  return null
}
LanguageOutputHint.propTypes = {
  engine: PropTypes.string,
}

export default LanguageOutputHint
