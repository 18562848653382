import React from 'react'
import PropTypes from 'prop-types'

function SandboxPageSource(props) {
  const { text } = props
  return (
    <div style={{ flexGrow: 1 }}>
      <textarea
        style={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          backgroundColor: '#333',
          color: '#eee',
          padding: 14,
          resize: 'none',
          whiteSpace: 'pre',
          fontFamily:
            '"Roboto Mono", Consolas, "Andale Mono", "Lucida Console", "DejaVu Sans Mono", Monaco, "Courier New", Courier, monospace',
          border: 0,
          outline: 'none',
        }}
        value={text}
        readOnly
        spellCheck={false}
      />
    </div>
  )
}
SandboxPageSource.propTypes = {
  text: PropTypes.string.isRequired,
}

export default SandboxPageSource
