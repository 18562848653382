import 'flatpickr/dist/themes/material_blue.css'

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { values } from 'lodash'

// Components
import Loader from 'app/views/components/Loader'
import Icon from 'app/views/components/Icon'
import ModalCodingTestRequired from 'app/views/components/Modals/Info/ModalCodingTestRequired'
import ModalOverrideOrganizationDefault from 'app/views/components/Modals/Info/ModalOverrideOrganizationDefault'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import ToggleSwitch from 'app/views/components/ToggleSwitch'

// Redux
import { getPlans } from 'app/state/modules/plan'
import {
  getTeamById,
  updateTeamById,
  deleteTeamById,
} from 'app/state/modules/team'

// Utils
import analytics from 'app/views/utils/analytics'

// Local Imports
import OrganizationLayout from '../components/Layout'

class OrganizationsTeamsEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: '',
      name: '',
      planId: '',
      confirmDelete: false,
      overrideMustPassCode: false,
      teamMustPassCode: false,
    }

    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangePlan = this.handleChangePlan.bind(this)
    this.handleSubmitSave = this.handleSubmitSave.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
    this.handleOverrideEnabled = this.handleOverrideEnabled.bind(this)
    this.handleMustPassEnabled = this.handleMustPassEnabled.bind(this)
  }

  componentDidMount() {
    this.props.getPlans(this.props.match.params.organization_uuid)
    this.props.getTeamById(
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid
    )
    if (this.props.currentTeam.uuid !== null) {
      this.setState({
        uuid: this.props.currentTeam.uuid,
        name: this.props.currentTeam.name,
        planId: this.props.currentTeam.plan.uuid,
        overrideMustPassCode:
          this.props.currentTeam.must_pass_coding_exercise !== null,
        teamMustPassCode:
          this.props.currentTeam.must_pass_coding_exercise || false,
      })
    }

    analytics.page('page-organizations-teams-edit')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentTeam.uuid !== nextProps.currentTeam.uuid) {
      this.setState({
        uuid: nextProps.currentTeam.uuid,
        name: nextProps.currentTeam.name,
        planId: nextProps.currentTeam.plan.uuid,
        overrideMustPassCode:
          this.props.currentTeam.must_pass_coding_exercise !== null,
        teamMustPassCode:
          this.props.currentTeam.must_pass_coding_exercise || false,
      })
    }
  }

  handleOverrideEnabled(active) {
    this.setState({
      overrideMustPassCode: active,
    })
  }

  handleMustPassEnabled(active) {
    this.setState({
      teamMustPassCode: active,
    })
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value })
  }

  handleChangePlan(event) {
    // this.setState({ planId: event.target.value })
  }

  handleSubmitSave(event) {
    event.preventDefault()

    if (this.state.name === '') {
      this.setState({ error: 'Name is required.' })
    } else {
      this.props
        .updateTeamById(
          this.props.match.params.organization_uuid,
          this.props.match.params.team_uuid,
          this.state.name,
          this.state.planId,
          this.state.overrideMustPassCode ? this.state.teamMustPassCode : null
        )
        .then((teamId) => {
          this.props.history.push(
            `/admin/${this.props.match.params.organization_uuid}/teams/${teamId}/plans`
          )
        })
    }
  }

  handleDeleteClick(e) {
    e.preventDefault()

    this.setState({ confirmDelete: true })
  }

  handleDeleteConfirm(e) {
    e.preventDefault()

    this.props
      .deleteTeamById(
        this.props.match.params.organization_uuid,
        this.props.match.params.team_uuid
      )
      .then(() => {
        this.setState({ confirmDelete: false })
        this.props.history.push(
          `/admin/${this.props.match.params.organization_uuid}/teams`
        )
      })
  }

  handleDeleteCancel(e) {
    e.preventDefault()

    this.setState({ confirmDelete: false })
  }

  render() {
    if (this.props.plans.length === 0) {
      return <Loader visible />
    }
    return (
      <OrganizationLayout
        currentOrganizationId={this.props.match.params.organization_uuid}
        {...this.props}
        active='teams'
      >
        <div
          className='uk-padding-remove uk-width-expand@m uk-width-1-1@s'
          style={{ overflow: 'auto', height: 'calc(100vh - 80px)' }}
        >
          {this.state.confirmDelete === false ? (
            <div className='uk-padding' style={{ paddingLeft: 60 }}>
              <h1>Edit Team</h1>
              <div className='uk-card uk-card-default uk-card-body'>
                <div className='uk-container-small'>
                  <form className='uk-form-stacked'>
                    <div className='uk-margin'>
                      <label className='uk-form-label'>Name</label>
                      <div className='uk-form-controls'>
                        <input
                          data-test-id='input-team-name'
                          className='uk-input'
                          type='text'
                          placeholder=''
                          value={this.state.name}
                          onChange={this.handleChangeName}
                        />
                      </div>
                    </div>

                    <div className='uk-margin'>
                      <label className='uk-form-label'>Training Plan</label>
                      <div className='uk-form-controls'>
                        <select
                          disabled
                          className='uk-select'
                          id='form-select-training-plan-editt'
                          value={this.state.planId}
                          onChange={this.handleChangePlan}
                        >
                          {values(this.props.plans).map((plan) => {
                            if (!plan.uuid) return null
                            return (
                              <option key={plan.uuid} value={plan.uuid}>
                                {plan.title}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>

                    <div className='uk-margin'>
                      <label className='uk-form-label'>
                        Coding Test Required
                      </label>
                      <div className='flex'>
                        <ToggleSwitch
                          onChange={this.handleOverrideEnabled}
                          checked={this.state.overrideMustPassCode}
                          label='Override Organization Default'
                        />
                        <Icon
                          href='#'
                          name='information'
                          ratio={0.8}
                          data-uk-toggle='target: #modal-info-override-organization-default'
                          style={{
                            cursor: 'pointer',
                            paddingLeft: 8,
                            verticalAlign: 'top',
                          }}
                        />
                      </div>
                    </div>
                    {this.state.overrideMustPassCode && (
                      <div className='uk-margin'>
                        <div className='flex'>
                          <ToggleSwitch
                            onChange={this.handleMustPassEnabled}
                            checked={this.state.teamMustPassCode}
                            label='Coding Test Required'
                          />
                          <Icon
                            name='information'
                            ratio={0.8}
                            data-uk-toggle='target: #modal-info-coding-test-required'
                            style={{
                              cursor: 'pointer',
                              paddingLeft: 8,
                              verticalAlign: 'top',
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {this.state.error !== '' && (
                      <div className='uk-margin'>
                        <p className='text-danger'>{this.state.error}</p>
                      </div>
                    )}

                    {this.props.updatingTeamByIdError !== '' &&
                      this.state.error === '' && (
                        <div className='uk-margin'>
                          <p className='text-danger'>
                            {this.props.updatingTeamByIdError}
                          </p>
                        </div>
                      )}

                    {this.props.deletingTeamByIdError !== '' &&
                      this.state.error === '' &&
                      this.props.updatingTeamByIdError === '' && (
                        <div className='uk-margin'>
                          <p className='text-danger'>
                            {this.props.deletingTeamByIdError}
                          </p>
                        </div>
                      )}

                    <div className='uk-margin'>
                      <div className='uk-form-controls'>
                        <PrimaryButton
                          size={'medium'}
                          customCss={' bg-dark_gray'}
                          onClick={this.handleDeleteClick}
                          label='Delete Team'
                        />
                        <PrimaryButton
                          size={'medium'}
                          customCss={'ml-2'}
                          onClick={this.handleSubmitSave}
                          label='Save Changes'
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div
              data-test-id='delete-team-confirm'
              className='uk-padding'
              style={{ paddingLeft: 60 }}
            >
              <h1>Delete Team</h1>
              <div className='uk-card uk-card-default uk-card-body'>
                <div className='uk-container-small'>
                  <p>
                    Are you sure you want to delete the team{' '}
                    <strong>"{this.state.name}"</strong>?
                  </p>
                  {this.props.currentTeam.created_by_sso ? (
                    <p>
                      If a member is added to this team via SSO this team will
                      be created again
                    </p>
                  ) : (
                    ''
                  )}
                  <form className='uk-form-stacked'>
                    <div className='uk-margin'>
                      <div className='uk-form-controls'>
                        <PrimaryButton
                          className='uk-button uk-button tm-button-default'
                          onClick={this.handleDeleteCancel}
                          label='Cancel'
                          size={'small'}
                          customCss={' bg-dark_gray'}
                        />
                        <PrimaryButton
                          onClick={this.handleDeleteConfirm}
                          label='Delete'
                          customCss={'ml-2 bg-red'}
                          size={'small'}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
        <ModalOverrideOrganizationDefault />
        <ModalCodingTestRequired />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  // content
  courses: state.content.courses,
  lessonMap: state.content.lessonMap,
  tests: state.content.tests,

  // hacker
  organizations: values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,
  // plans
  plans: state.plan.plans,

  // team
  loadingTeamById: state.team.loadingTeamById,
  loadingTeamByIdError: state.team.loadingTeamByIdError,
  currentTeam: state.team.currentTeam,
  updatingTeamById: state.team.updatingTeamById,
  updatingTeamByIdError: state.team.updatingTeamByIdError,
  deletingTeamById: state.team.deletingTeamById,
})
const mapDispatchToProps = (dispatch) => ({
  getTeamById: (organizationId, teamId) =>
    dispatch(getTeamById(organizationId, teamId)),
  updateTeamById: (organizationId, teamId, name, planId, mustPassCode) =>
    dispatch(
      updateTeamById(organizationId, teamId, name, planId, mustPassCode)
    ),
  deleteTeamById: (organizationId, teamId) =>
    dispatch(deleteTeamById(organizationId, teamId)),
  getPlans: (organizationId) => dispatch(getPlans(organizationId)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsTeamsEdit)
)
