import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import settings from 'settings'
import api from 'app/views/utils/api'

import ContentModal from '../../../common/ContentModal'
import ContentModalCloseButton from '../../../common/ContentModalCloseButton'

import HintBody from './HintBody'
import HintSelectionList from './HintSelectionList'

import './style.less'

function LessonHintModal(props) {
  const { hints, selectedHintIndex, onSelectHint, onClose } = props
  const hint = selectedHintIndex >= 0 ? hints[selectedHintIndex] : null

  // If we're shimming the api-hacker GET /content/hint call, replacing it with a call
  // to POST /svc/lesson/:lessonKey/events/hint, then initiate a throwaway call to
  // api-hacker's hint endpoint - the api-hacker call won't actually occur; instead the
  // shim will kick in and record the event with svc-lesson.
  //
  // Ordinarily, www-app doesn't call GET /content/hint from the Lesson UI - all hints
  // in content of type 'lesson' are free, so they're returned to the client along with
  // the content of the lesson and simply rendered client-side. By contrast, all hints
  // for coding challenges have a cost associated with them, so the Coding Challenge UI
  // calls GET /content/hint when a hint is used, meaning it can be shimmed normally.
  //
  // Basically: all 0-cost hints belong to content of type 'lesson', and if we want to
  // record usage of these hints in Kafka, we need to toss in a extra call to
  // GET /content/hint here in the lesson UI. We don't actually need to wait on the
  // response; we already have the body of the hint and can render it directly.
  //
  // In order to preserve the original behavior in the legacy HackEDU platform, the
  // svc-hacker-patch consumer may refrain from calling api-hacker's GET /content/hint
  // endpoint in response to 0-cost hints, because www-app does not ordinarily make that
  // call for such hints.
  //
  const flags = useFlags()
  useEffect(() => {
    // Have we viewed the details of a new hint, and are GET /content/hint requests
    // being shimmed? If so, make sure we record this hint usage event with svc-lesson
    const shimmedRoutes = flags['engEnableApiToSvcShimForRoutes'] || []
    if (shimmedRoutes.includes('hacker.Hints.get') && hint) {
      api({
        method: 'get',
        url: `${settings.urls.hacker}/content/hint`,
        params: { id: hint.id },
        withAuthToken: true,
      })
    }
  }, [hint, flags])

  return (
    <ContentModal id='lesson-hint-modal' onClose={onClose}>
      <ContentModalCloseButton onClick={onClose} />
      <div style={{ padding: 40 }}>
        {hint ? (
          <HintBody
            title={hint.title}
            markdown={hint.markdown}
            onClickBack={hints.length <= 1 ? null : () => onSelectHint(-1)}
          />
        ) : (
          <HintSelectionList hints={hints} onSelectHint={onSelectHint} />
        )}
      </div>
    </ContentModal>
  )
}
LessonHintModal.propTypes = {
  hints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      markdown: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedHintIndex: PropTypes.number.isRequired,
  onSelectHint: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default LessonHintModal
