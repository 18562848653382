import _ from 'lodash'
import queryString from 'query-string'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import Cookie from 'universal-cookie'
// Components
import Loader from 'app/views/components/Loader'
import GenericModal from 'app/views/components/Modals/GenericModal'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

// Redux
import * as auth from 'app/state/modules/auth'
import { getInviteToken, onboardUser } from 'app/state/modules/hacker'

// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'
import {
  getAnonymousId,
  getEmailFromJWT,
  getUsernameFromJWT,
  loginUser,
  resendVerificationCode,
} from 'app/views/utils/auth'
import {
  storePostLoginRedirectUrl,
  readPostLoginRedirectUrl,
} from 'app/views/utils/authRedirect'
import changeInputHandler from 'app/views/utils/changeInputHandler'

import validate from './validation'

class LoginComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {},
      errors: {},
      success: {},
      error: '',
      passwordRequired: false,
    }

    this.handleInputChange = changeInputHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkDomain = this.checkDomain.bind(this)
    this.checkEmail = this.checkEmail.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleResendCode = this.handleResendCode.bind(this)
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push('/')
    } else {
      const queryStringValues = queryString.parse(this.props.location.search)

      if (queryStringValues.domain) {
        this.checkDomain(queryStringValues.domain)
      }
      if (queryStringValues.redirect_to) {
        storePostLoginRedirectUrl(queryStringValues.redirect_to, null)
      }
    }
  }

  handleChangeEmail(event) {
    changeInputHandler.call(this, event)
    this.setState({
      passwordRequired: false,
    })
  }

  checkEmail(e) {
    if (e) {
      e.preventDefault()
    }
    const errors = validate(this.state.fields)

    if (Object.keys(errors).length) {
      return this.setState({
        errors,
      })
    }
    this.setState((prevState) => ({
      isButtonLoading: true,
      fields: {
        ...prevState.fields,
        email: this.state.fields.email.trim(),
      },
    }))

    api({
      method: 'post',
      url: settings.saml.checkDomainAssociationUri,
      data: {
        email: this.state.fields.email,
        redirect_to: settings.cognito.authData.RedirectUriSignIn,
      },
    })
      .then((response) => {
        if (response.data) {
          const url = _.get(response, 'data.url')

          if (url) {
            window.location = url
          } else {
            this.setState({
              passwordRequired: true,
              isButtonLoading: false,
            })
          }
        }
      })
      .catch((error) => {
        console.error(error)
        this.setState({
          error: error.message,
          isButtonLoading: false,
        })
      })
  }

  checkDomain(domain) {
    this.setState({ isButtonLoading: true })

    api({
      method: 'post',
      url: settings.saml.checkDomainAssociationUri,
      data: {
        domain: domain.toLowerCase(),
        redirect_to: settings.cognito.authData.RedirectUriSignIn,
      },
    })
      .then((response) => {
        if (response.data) {
          const url = _.get(response, 'data.url')

          if (url) {
            window.location = url
          } else {
            this.setState({
              passwordRequired: true,
              isButtonLoading: false,
            })
          }
        }
      })
      .catch((error) => {
        console.error(error)
        this.setState({
          error: error.message,
          isButtonLoading: false,
        })
      })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const { updateCognitoSession } = this.props
    const errors = validate(this.state.fields)

    if (Object.keys(errors).length) {
      return this.setState({
        errors,
      })
    }
    this.setState({ isButtonLoading: true })
    loginUser(this.state.fields)
      .then((result) => {
        this.setState({ isButtonLoading: false })

        localStorage.removeItem('hackedu.coding.challenges')

        updateCognitoSession(result, { clearSessionWhilePending: true })

        const cookie = new Cookie()
        const expires = new Date()
        expires.setFullYear(expires.getFullYear() + 1)

        const url = settings.urls.www
        let safeUrl = url.replace(/^https?:\/\//i, '')

        if (safeUrl.startsWith('www.')) {
          safeUrl = safeUrl.substring(4)
        }

        cookie.set('hackedu.logged.in.app', 'true', {
          path: '/',
          domain: safeUrl,
          expires,
        })

        const email = getEmailFromJWT(result.idToken.jwtToken)
        window.analytics.identify(getUsernameFromJWT(result.idToken.jwtToken), {
          email,
          locale: localStorage.getItem('hackedu.language'),
          anonymousId: getAnonymousId(),
        })
        if (this.props.inviteToken && this.props.inviteToken.length > 0) {
          this.props
            .onboardUser(result.idToken.jwtToken, this.props.inviteToken, null)
            .then(({ data }) => {
              if (
                !data.success &&
                (data.error === 'used-token' || data.error === 'invalid-token')
              ) {
                this.props.history.push(`/invite/${this.props.inviteToken}`)
              } else {
                this.props.history.push(readPostLoginRedirectUrl())
              }
            })
        } else {
          this.props.history.push(readPostLoginRedirectUrl())
        }
      })
      .catch((err) => {
        this.setState({
          error: err.code,
          isButtonLoading: false,
        })
      })
    return undefined
  }

  handleResendCode() {
    resendVerificationCode({ username: this.state.fields.email })
      .then(() => {
        const { errors } = this.state
        this.setState({
          success: {
            resendEmail:
              'We have just resent the confirmation code to your email',
          },
          errors: {
            ...errors,
            resendEmail: null,
          },
        })
        this.resendModal.show()
      })
      .catch((err) => {
        const { errors, success } = this.state
        this.setState(
          {
            errors: {
              ...errors,
              resendEmail: err.message,
            },
            success: {
              ...success,
              resendEmail: null,
            },
          },
          () => {
            this.resendModal.show()
          }
        )
      })
  }

  renderError() {
    switch (this.state.error) {
      case 'UserNotFoundException':
        return <span className='text-danger'>User does not exist</span>
      case 'NotAuthorizedException':
        return (
          <span className='text-danger'>Incorrect username or password.</span>
        )
      case 'UserNotConfirmedException':
        return (
          <span className='text-danger'>
            You have not confirmed your email address.{' '}
            <span
              type='button'
              className='_hover_error'
              onClick={this.handleResendCode}
              style={{
                fontTransform: 'underline',
                borderBottom: '1px solid #f0506e',
                cursor: 'pointer',
              }}
            >
              Resend Verification Email
            </span>
          </span>
        )
      default:
        return null
    }
  }

  render() {
    const { errors, error, isButtonLoading, passwordRequired } = this.state
    const submitFn = passwordRequired ? this.handleSubmit : this.checkEmail

    const queryStringValues = queryString.parse(this.props.location.search)
    if (queryStringValues.domain) {
      return <Loader visible />
    }

    return (
      <div
        data-test-id='login-component'
        className='uk-background-muted'
        style={{ minHeight: 'calc(100vh - 10px)' }}
      >
        <div className='uk-container' style={{ maxWidth: 550 }}>
          <div className='uk-section uk-margin-large-bottom'>
            <div className='text-center'>
              <a
                className='uk-navbar-item uk-logo uk-navbar-toggle'
                href={`${settings.urls.www}`}
              >
                <img
                  src='/static/images/SJ_HE_Logo2ColorHorizontal22.png'
                  className='w-[450px]'
                  alt='Logo'
                />
              </a>
              <h3>Login to your account</h3>
            </div>
            <div
              className='uk-card uk-card-default uk-card-body uk-padding-large uk-margin-top'
              style={{ paddingBottom: 30 }}
            >
              <form onSubmit={submitFn}>
                <div className='uk-margin'>
                  <input
                    data-test-id='login-email-input'
                    name='email'
                    type='text'
                    placeholder='Email'
                    className='uk-input'
                    onChange={this.handleChangeEmail}
                  />
                </div>
                <span className='text-danger'>{errors.email}</span>
                {passwordRequired && (
                  <>
                    <div className='uk-margin'>
                      <input
                        autoFocus
                        data-test-id='login-password-input'
                        name='password'
                        type='password'
                        placeholder='Password'
                        className='uk-input'
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <span className='text-danger'>{errors.password}</span>
                  </>
                )}
                {error && this.renderError()}
                <div className='uk-margin'>
                  <PrimaryButton
                    size='full'
                    label={'Continue'}
                    loading={isButtonLoading}
                    onClick={submitFn}
                  />
                </div>
              </form>
            </div>

            <div className='text-center uk-margin'>
              <Link className='text-green hover:text-green' to='/register'>
                Don't have an account? Register here.
              </Link>
            </div>
            <div className='text-center uk-margin text-green'>
              <Link
                className='text-green hover:text-green'
                to='/forgot-password'
              >
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
        <GenericModal
          id='modal-resend-code'
          buttonType='uk-button-primary'
          ref={(ref) => {
            this.resendModal = ref
          }}
          title={
            this.state.errors.resendEmail ? 'Error' : 'Confirmation code resend'
          }
          body={
            <>
              <p>
                {this.state.errors.resendEmail ||
                  this.state.success.resendEmail}
              </p>
            </>
          }
          closeText='Ok'
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // auth
  isAuthenticated: state.auth.status === 'LOGGED_IN',

  // content
  contentDetails: state.content.contentDetails,

  // hacker
  inviteToken: getInviteToken(),
})

const mapDispatchToProps = (dispatch) => ({
  updateCognitoSession: (session, flags) => {
    dispatch(auth.updateCognitoSession(session, flags))
  },
  onboardUser: (idToken, teamToken, teamId) =>
    dispatch(onboardUser(idToken, teamToken, teamId)),
})

export const Login = compose(connect(mapStateToProps, mapDispatchToProps))(
  LoginComponent
)
