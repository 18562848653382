import React from 'react'
import { connect } from 'react-redux'
import Loader from 'app/views/components/Loader'
import queryStrying from 'query-string'
import settings from 'settings'
import ToggleSwitch from 'app/views/components/ToggleSwitch'

import {
  githubCallback,
  slackCallback,
  microsoftTeamsCallback,
  microsoftTeamsCallbackTeamsInstall,
  microsoftTeamsUninstall,
} from 'app/state/modules/integrations'

import Icon from 'app/views/components/Icon'
import OrganizationLayout from '../components/Layout'

class Callback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPickOrganization: false,
      showNoOrganization: false,
      msTeams: false,
      isLoading: false,
    }
    this.onSubmitOrganization = this.onSubmitOrganization.bind(this)
    this.integrationType = this.onIntegrationLoad()
    this.onActiveTeam = this.onActiveTeam.bind(this)
    this.onSubmitSelectedMSTeams = this.onSubmitSelectedMSTeams.bind(this)
  }

  componentDidMount() {
    const params = queryStrying.parse(this.props.location.search)
    if (params.state && params.state !== 'from_catalogue') {
      this.onCallback()
    } else {
      const adminOrganizations = this.props.organizations.filter(
        (organization) => organization.role === 'admin'
      )
      const totalAdminOrgs = adminOrganizations.length
      if (totalAdminOrgs === 0) {
        this.setState({
          showNoOrganization: true,
        })
      } else if (totalAdminOrgs === 1) {
        this.onCallback(adminOrganizations[0].uuid)
      } else {
        this.setState({
          showPickOrganization: true,
        })
      }
    }
  }

  onActiveTeam(id, active) {
    if (id) {
      this.setState({
        msTeams: (this.state.msTeams || []).map((t) => {
          if (t.id === id) {
            return {
              ...t,
              active,
            }
          } else {
            return t
          }
        }),
      })
    }
  }

  onCallback(organizationUUID) {
    const params = queryStrying.parse(this.props.location.search)
    if (params.error_subcode === 'cancel') {
      return
    }
    switch (this.integrationType) {
      case 'slack':
        this.props.slackCallback(organizationUUID || params.state, params.code)
        break
      case 'github':
        this.props.githubCallback(
          organizationUUID || params.state,
          params.installation_id,
          params.code,
          params.setup_action
        )
        break
      case 'microsoft':
        if (params.tenant && (organizationUUID || params.state)) {
          if (params.state === 'from_catalogue') {
            this.props.microsoftTeamsCallback(
              organizationUUID || params.state,
              params.tenant
            )
          } else {
            this.props.microsoftTeamsUninstall(
              organizationUUID || params.state,
              () => {
                setTimeout(() => {
                  this.props.microsoftTeamsCallback(
                    organizationUUID || params.state,
                    params.tenant,
                    (teams) => {
                      this.setState({
                        msTeams: teams,
                      })
                    }
                  )
                }, 2000)
              }
            )
          }
        } else {
          window.location.replace('/admin')
        }
        break
      default:
        break
    }
  }

  onIntegrationLoad() {
    if (this.props.match.path.indexOf('/slack-callback') > -1) {
      return 'slack'
    }
    if (this.props.match.path.indexOf('/github-callback') > -1) {
      return 'github'
    }
    if (this.props.match.path.indexOf('/microsoft-teams-callback') > -1) {
      return 'microsoft'
    }
    return null
  }

  onSubmitOrganization() {
    const organizationUUID = document.querySelector('#organization-input').value
    this.onCallback(organizationUUID)
  }

  onSubmitSelectedMSTeams() {
    const params = queryStrying.parse(this.props.location.search)
    this.setState({
      isLoading: true,
    })
    this.props.microsoftTeamsCallbackTeamsInstall(
      params.state,
      params.tenant,
      this.state.msTeams.map((t) => t.id),
      () => {
        this.setState({
          isLoading: false,
        })
      }
    )
  }

  renderTitle() {
    switch (this.integrationType) {
      case 'slack':
        return 'Pick the organization you want to connect with HackEDU Slack Bot'
      case 'github':
        return 'Pick the organization you want to link with HackEDU Training Github App'
      case 'microsoft':
        if (this.state.msTeams) {
          return 'Pick the Microsoft Teams you want to add this integration to.'
        }
        return 'Pick the organization you want to connect wit HackEDU Microsoft Teams Bot'
      default:
        return null
    }
  }

  renderDescription() {
    switch (this.integrationType) {
      case 'slack':
        return `You are trying to install HackEDU Slack Bot from an external source.
        Please pick the organization that you want to add this integration.
        `
      case 'github':
        return `You are trying to install the HackEDU Training App from an external source.
        Please pick the organization that you want to add this integration.`
      case 'microsoft':
        if (this.state.msTeams) {
          return `You have more than 1 team registered in Microsoft Teams App. You need to
          select the teams you want to add this integration.`
        }
        return `You are trying to install the HackEDU Microsoft Teams Bot from an external source.
        Please pick the organization that you want to add this integration`
      default:
        return null
    }
  }

  renderTypes() {
    const params = queryStrying.parse(this.props.location.search)
    if (this.state.isLoading) {
      return <Loader visible text='Installing HackEDU Microsoft Teams Bot.' />
    }
    if (parseInt(params.from_catalogue) === 1) {
      return window.location.replace(
        `${settings.urls.microsoftApp}&state=from_catalogue`
      )
    }
    if (this.state.msTeams) {
      // This conditions means that the user has more than 1 team and should pick the ones he wants to install the integration
      return (
        <>
          <div className='uk-width-1-3 uk-align-center uk-margin-top text-center'>
            <br />
            <br />
            <br />
            <h2>{this.renderTitle()}</h2>
            <p>{this.renderDescription()}</p>
            <div className='uk-margin'>
              <ul className='uk-list uk-list-striped text-left'>
                {(this.state.msTeams || []).map((team) => {
                  return (
                    <li key={team.id} className='flex items-center'>
                      <ToggleSwitch
                        onChange={(active) =>
                          this.onActiveTeam(team.id, active)
                        }
                        checked={team.active}
                        label={team.name}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className='uk-margin'>
              <button
                className='uk-button uk-button-primary flex justify-center items-center uk-width-1-1'
                type='button'
                onClick={this.onSubmitSelectedMSTeams}
              >
                <Icon
                  name={this.integrationType}
                  fill
                  className='uk-margin-right'
                />
                Link selected MS Teams
              </button>
            </div>
          </div>
        </>
      )
    }

    if (params.error_subcode === 'cancel') {
      return (
        <div className='uk-width-1-3 uk-align-center uk-margin-top text-center'>
          <br />
          <br />
          <br />

          <div className='uk-alert-danger' uk-alert=''>
            <h4>Error</h4>
            <p>Microsoft Teams install was canceled.</p>
          </div>
        </div>
      )
    }
    if (this.state.showNoOrganization) {
      return (
        <div className='uk-width-1-3 uk-align-center uk-margin-top text-center'>
          <br />
          <br />
          <br />
          <h2>You are not an admin of a HackEDU organization.</h2>
        </div>
      )
    }

    if (this.state.showPickOrganization) {
      return (
        <>
          <div className='uk-width-1-3 uk-align-center uk-margin-top text-center'>
            <br />
            <br />
            <br />
            <h2>{this.renderTitle()}</h2>
            <p>{this.renderDescription()}</p>
            <div className='uk-margin'>
              <label className='uk-form-label'>Pick your organization</label>
              <div className='uk-form-controls'>
                <select id='organization-input' className='uk-select'>
                  {this.props.organizations
                    .filter((organization) => organization.role === 'admin')
                    .map((organization) => (
                      <option key={organization.uuid} value={organization.uuid}>
                        {organization.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className='uk-margin'>
              <button
                className='uk-button uk-button-primary flex justify-center items-center uk-width-1-1'
                type='button'
                onClick={this.onSubmitOrganization}
              >
                <Icon
                  name={this.integrationType}
                  fill
                  className='uk-margin-right'
                />
                Link Selected Organization
              </button>
            </div>
          </div>
        </>
      )
    }

    return <Loader visible text='This could take a moment....' />
  }

  render() {
    return (
      <OrganizationLayout active='apps_integrations' {...this.props}>
        {this.renderTypes()}
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = ({ hacker }) => ({
  organizations: hacker.organizationsList,
  organizationsHash: hacker.organizationsHash,
})

export default connect(mapStateToProps, {
  githubCallback,
  slackCallback,
  microsoftTeamsCallback,
  microsoftTeamsUninstall,
  microsoftTeamsCallbackTeamsInstall,
})(Callback)
