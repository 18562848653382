import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import settings from 'settings'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'

const Group = ({
  title,
  groupId,
  description,
  startDate,
  endDate,
  isOrganizationPlan,
  organizationId,
  planId,
  isActive,
  contentArray,
  isTimeBased,
  duration,
  fetchPhaseDetailModalData,
}) => {
  const renderTitle = (content) => {
    if (content.type === 'coding_challenge') {
      return (
        <li key={content.uuid}>
          <span>
            Coding Challenges: &nbsp;
            {`${settings.apps[content.metadata.app]} ${content.title} (${
              settings.engines[content.metadata.language.name]
            } -  ${settings.frameworks[content.metadata.framework.name]})`}
          </span>
        </li>
      )
    }
    return <li key={content.uuid}>{content.title}</li>
  }
  return (
    <div
      className='uk-card uk-card-default uk-card-body uk-width-1-1@m uk-margin-small'
      onClick={() => {
        fetchPhaseDetailModalData(groupId)
      }}
      style={{ cursor: 'pointer' }}
    >
      <strong style={{ float: 'left' }}>
        {title}
        {isActive || isTimeBased ? (
          <span
            className={`uk-label uk-label-success uk-margin-left${
              isTimeBased ? ' uk-hidden' : ''
            }`}
          >
            Active
          </span>
        ) : (
          <span className='uk-label uk-label-danger uk-margin-left'>
            Inactive
          </span>
        )}
      </strong>
      {isOrganizationPlan && (
        <PrimaryButton
          onClick={(e) => e.stopPropagation}
          link_to={`/admin/${organizationId}/plans/${planId}/phase/${groupId}/edit`}
          size='small'
          customCss='float-right'
          label='Edit'
        />
      )}
      <div style={{ clear: 'both' }} />

      <p>{description}</p>

      <table>
        <tbody>
          {duration && (
            <tr>
              <td>Duration:</td>
              <td className='font-bold'>{`${duration} days`}</td>
            </tr>
          )}
          {startDate && (
            <tr>
              <td>Start Date:</td>
              <td className='font-bold'>
                <Moment format='MMMM D, YYYY'>{startDate}</Moment>
              </td>
            </tr>
          )}
          {endDate && (
            <tr>
              <td>Complete Date:</td>
              <td className='font-bold'>
                <Moment format='MMMM D, YYYY'>{endDate}</Moment>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className='uk-margin-top'>
        <ul className='uk-list uk-list-bullet'>
          {contentArray.map((content) => {
            return renderTitle(content)
          })}
        </ul>
      </div>
    </div>
  )
}
export default Group
