import React from 'react'
import qs from 'qs'
import { verifyEmail,
         resendVerificationCode } from 'app/views/utils/auth'
import ButtonWithLoader from '../../components/ButtonWithLoader'
import GenericModal from 'app/views/components/Modals/GenericModal'

const propTypes = {}
const defaultProps = {}

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props)

    this.handleResendCode = this.handleResendCode.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      errors: {},
      success: {},
      error: null,
      email: '',
    }
  }

  componentDidMount() {
    const queryString = this.props.location.search.substr(1)
    const queryParams = qs.parse(queryString)

    verifyEmail({
      username: decodeURIComponent(queryParams.user_name),
      verificationCode: this.props.match.params.code,
    })
      .then(() => {
        this.props.history.push('/login')
      })
      .catch((err) => {
        if (
          err.message ===
          'User cannot be confirmed. Current status is CONFIRMED'
        ) {
          this.props.history.push('/login')
        } else {
          this.setState({
            error: err.message,
          })
        }
      })
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault()
    }
    this.handleResendCode()
  }

  handleResendCode() {
    const { email } = this.state
    resendVerificationCode({ username: email.toLowerCase() })
      .then(() => {
        const { errors } = this.state
        this.setState({
          success: {
            resendEmail:
            'We have just resent the confirmation code to your email',
          },
          errors: {
            ...errors,
            resendEmail: null,
          },
        })
        this.resendModal.show()
      })
      .catch((err) => {
        const { errors, success } = this.state
        this.setState(
          {
            errors: {
              ...errors,
              resendEmail: err.message,
            },
            success: {
              ...success,
              resendEmail: null,
            },
          },
          () => {
            this.resendModal.show()
          }
        )
      })
  }

  handleChange(event) {
    this.setState({email: event.target.value})
  }

  render() {
    const {
      error,
      email
    } = this.state

    return (
      <div className='uk-container' style={{ maxWidth: 550 }}>
        <div className='uk-section'>
          <div className='uk-container'>
            <div className='text-center'>
              {error ?
               error === 'Invalid code provided, please request a code again.' ?
               <div className="">
                 <p>{error}</p>
                 <form onSubmit={this.handleSubmit}>
                   <div className='uk-margin'>
                     <input
                       name='email'
                       value={email}
                       type='text'
                       placeholder='Email'
                       className='uk-input'
                       onChange={this.handleChange}
                     />
                   </div>
                 </form>
                 <ButtonWithLoader
                   className='uk-button text-emphasis uk-align-center'
                   onClick={this.handleResendCode}
                 >
                   Resend verification email
                 </ButtonWithLoader>
               </div>
               : <p>{error}</p>
               : <div data-uk-spinner='' />}
            </div>
          </div>
        </div>
        <GenericModal
          id='modal-resend-code-register'
          buttonType='uk-button-primary'
          ref={(ref) => {
            this.resendModal = ref
          }}
          title={
            this.state.errors.resendEmail ? 'Error' : 'Confirmation code resend'
          }
          body={
            <>
              <p>
                {this.state.errors.resendEmail ||
                  this.state.success.resendEmail}
              </p>
            </>
          }
          closeText='Ok'
        />
      </div>
    )
  }
}

VerifyEmail.propTypes = propTypes
VerifyEmail.defaultProps = defaultProps

export default VerifyEmail
