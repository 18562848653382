import React from 'react'
import PropTypes from 'prop-types'

import HintSelectionListItem from './HintSelectionListItem'

function HintSelectionList(props) {
  const { hints, onSelectHint } = props
  return (
    <>
      <h3>Hints</h3>
      <div className='coding-challenge-hint-selection-list'>
        {hints.map((hint, index) => (
          <HintSelectionListItem
            key={hint.id}
            title={hint.title}
            hintType={hint.hintType}
            points={hint.points}
            hasBeenUsed={hint.hasBeenUsed}
            onClick={() => onSelectHint(index)}
          />
        ))}
      </div>
    </>
  )
}
HintSelectionList.propTypes = {
  hints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      markdown: PropTypes.string.isRequired,
      hintType: PropTypes.oneOf(['default', 'basic', 'highlight']).isRequired,
      points: PropTypes.number.isRequired,
      hasBeenUsed: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onSelectHint: PropTypes.func.isRequired,
}

export default HintSelectionList
