/**
 * Stores auth-related state, i.e. state required to know who the user is and to
 * authorize API requests on behalf of that user.
 *
 * This module does NOT handle processing login/registration/reset attempts - those
 * auth flows are handled elsewhere, and upon successfully establishing a
 * CognitoUserSession they covey it to this module via auth.updateCognitoSession. From
 * there, the auth module's responsibility is to make tokens and user information
 * available to the rest of the app.
 *
 * The session may be manually refreshed at any time by calling auth.refresh, but
 * refreshes are also handled automatically: the `getTokenString` function returns a
 * promise that resolves to a valid auth token, and if the existing session is expired,
 * it will ensure that the session is refreshed and yield the new token.
 */
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession
} from 'amazon-cognito-identity-js'

import settings from 'settings'

import { SvcAuthToken, getSvcAuthToken, clearCognitoState } from 'app/sjAuth'

const CLEAR_SESSION = 'auth/CLEAR_SESSION'
const UPDATE_SESSION = 'auth/UPDATE_SESSION'
const ADD_REFRESH_CALLBACK = 'auth/INITIATE_REFRESH_REQUEST'
const CLEAR_REFRESH_CALLBACKS = 'auth/FINISH_REFRESH'
const SET_USE_SVC_AUTH_TOKEN = 'auth/SET_USE_SVC_AUTH_TOKEN'

/**
 * Indicates whether the user is logged in. Status is initially PENDING but is resolved
 * to either LOGGED_OUT or LOGGED_IN on app init, depending on whether a cached session
 * could be restored. Status is LOGGED_IN only when the app has a valid set of tokens
 * and a known user.
 */
type AuthStatus = 'PENDING' | 'LOGGED_OUT' | 'LOGGED_IN'

/**
 * Caches some details of the logged-in user: these are accessed in various parts of the
 * UI. idToken is the Cognito ID token and is preserved for compatibility, but most code
 * that needs to make API requests should simply call the `api` function and pass
 * `withAuthToken: true`. For lower-level access to a valid token for authorizing API
 * requests, call getTokenString, passing the root state and dispatcher.
 */
type UserDetails = {
  username: string | null
  email: string | null
  idToken: string | null
}

/**
 * If we get multiple concurrent refresh requests, we only want to process the first,
 * but we want to notify *all* callers once the refresh is complete and we have a new
 * token: so we buffer the callback functions supplied by each refresh request.
 */
type RefreshCallback = (session: CognitoUserSession, svcAuthToken: SvcAuthToken) => void

/**
 * Actions that effect state changes to the auth module.
 */
type AuthAction = {
  // Clears all state and sets status back to PENDING; typically used to indicate that
  // we're in the process of initiating and completing a login attempt so the app should
  // refrain from kicking the user back to the login page
  type: typeof CLEAR_SESSION
} | {
  // With a valid CognitoUserSession, stores the details of the session and updates
  // status to LOGGED_IN; with a null session, clears state and adopts LOGGED_OUT
  type: typeof UPDATE_SESSION
  session: CognitoUserSession | null
  svcAuthToken: SvcAuthToken | null
  isAwatingSSOLogin?: boolean
} | {
  // Registers a callback that will be invoked when the currently-pending session
  // refresh is completed
  type: typeof ADD_REFRESH_CALLBACK
  callback: RefreshCallback
} | {
  // Clears the list of refresh callbacks once they've been invoked
  type: typeof CLEAR_REFRESH_CALLBACKS
}

const initialState = {
  status: 'PENDING' as AuthStatus,
  isAwaitingSSOLogin: false,
  session: null as CognitoUserSession | null,
  svcAuthToken: null as SvcAuthToken | null,
  refreshCallbacks: [] as RefreshCallback[],
  user: {
    username: null,
    email: null,
    idToken: null,
  } as UserDetails,
}

type AuthState = typeof initialState
type AuthDispatch = (action: AuthAction) => void

export default function reducer(state: AuthState = initialState, action: AuthAction | {} = {}): AuthState {
  if (typeof action['type'] === 'undefined') {
    return state
  }
  const a = action as AuthAction
  switch (a.type) {
    case CLEAR_SESSION:
      return {
        ...state,
        status: 'PENDING',
      }
    case UPDATE_SESSION:
      if (!a.session || !a.svcAuthToken) {
        return {
          ...state,
          status: 'LOGGED_OUT',
          session: null,
          svcAuthToken: null,
          user: {
            username: null,
            email: null,
            idToken: null,
          },
          isAwaitingSSOLogin: a.isAwatingSSOLogin || false,
        }
      }
      const idToken = a.session.getIdToken()
      return {
        ...state,
        status: 'LOGGED_IN',
        session: a.session,
        svcAuthToken: a.svcAuthToken,
        user: {
          username: idToken.payload['cognito:username'],
          email: idToken.payload['email'],
          idToken: idToken.getJwtToken(),
        },
        isAwaitingSSOLogin: false,
      }
    case ADD_REFRESH_CALLBACK:
      return {
        ...state,
        refreshCallbacks: state.refreshCallbacks.concat([a.callback]),
      }
    case CLEAR_REFRESH_CALLBACKS:
      return {
        ...state,
        refreshCallbacks: [],
      }
    default:
      return state
  }
}

/**
 * Called by the UI when the app is first loaded, at which point the auth status is
 * still in its initial 'PENDING' state. If a valid Cognito session has been cached to
 * localStorage, init will restore it, use the refresh token to refresh the session and
 * obtain new tokens, and then set status to 'LOGGED_IN'. Otherwise, status will be set
 * to 'LOGGED_OUT'.
 */
export function init() {
  return (dispatch: AuthDispatch) => {
    // Use the cognito-identity SDK to check for an existing user session that's been
    // cached to localStorage
    const userPool = new CognitoUserPool({
      UserPoolId: settings['cognito'].authData.UserPoolId,
      ClientId: settings['cognito'].authData.ClientId,
    })
    const user = userPool.getCurrentUser()

    // If no user is identified in localStorage, we're definitively logged out
    if (!user) {
      dispatch({
        type: UPDATE_SESSION,
        session: null,
        svcAuthToken: null,
      })
      return
    }

    // Otherwise, try and resolve a CognitoUserSession for the last known user, and
    // update our state with that session (or null) when finished
    user.getSession((error: Error | null, session: CognitoUserSession | null) => {
      // If we failed to construct a CognitoUserSession for any reason, set status to
      // logged out
      if (error || !session) {
        dispatch({
          type: UPDATE_SESSION,
          session: null,
          svcAuthToken: null,
        })
        return
      }

      // We have a valid session which includes a long-lived refresh token: go ahead and
      // refresh the session so we can be sure that our access/ID tokens are fresh
      user.refreshSession(session.getRefreshToken(), (newError: Error | null, newSession: CognitoUserSession | null) => {
        // If we failed to refresh the session (e.g. because our cached refresh token
        // has been previously revoked), abort and log out
        if (newError || !newSession) {
          console.error('Failed to refresh cached Cognito session; clearing Cognito state and logging out', newError)
          clearCognitoState()
          dispatch({
            type: UPDATE_SESSION,
            session: null,
            svcAuthToken: null,
          })
        } else {
          // Exchange our Cognito ID token for a svc-auth access token, then store both
          getSvcAuthToken(newSession.getIdToken().getJwtToken())
            .then((svcAuthToken: SvcAuthToken) => {
              dispatch({
                type: UPDATE_SESSION,
                session: newSession,
                svcAuthToken,
              })
            })
            .catch((err) => {
              console.error('Failed to get svc-auth token; clearing Cognito state and logging out', err)
              clearCognitoState()
              dispatch({
                type: UPDATE_SESSION,
                session: null,
                svcAuthToken: null,
              })
            })
        }
      })
    })
  }
}

export function initSSO() {
  return (dispatch: AuthDispatch) => {
    // If we're initiating an SSO login, don't check for an existing session: just
    // update our state to reflect the fact that we're initially LOGGED_OUT, which will
    // allow the Auth route to render and process the SSO code exchange
    dispatch({
      type: UPDATE_SESSION,
      session: null,
      svcAuthToken: null,
      isAwatingSSOLogin: true,
    })
  }
}

/**
 * Allows the state of the auth module to be updated directly, given a new
 * CognitoUserSession. This function is called from the UI layer whenever the app
 * successfully completes a login attempt by any means, including in-app, via SSO, or
 * via credential handoff from a cross-domain login.
 */
type UpdateCognitoSessionFlags = {
  // This option is a bit of a hack to account for how some other, unrelated parts of
  // the app are implemented, so it bears explaining here for context.
  //
  // We use react-router-dom for client-side routing, and routes/index.jsx configures
  // the routes for the app, with routes that require a valid login defined via the
  // <PrivateRoute> component. The overall route table is defined conditionally based on
  // auth status:
  //
  // - If the user is logged out, we define all routes: this means a logged-out user
  //   hitting a private URL will be bounced to /login, with their intended route stored
  //   as the post-login redirect URL so they can be sent to their desired page once
  //   they finish logging in.
  //
  // - If the user is logged in and has loaded organization data from api-hacker (which
  //   the app defines as a prerequisite for rendering any real content), then we also
  //   define all routes: this means a logged-in user hitting a private URL will
  //   successfully load that page.
  //
  // - In any other scenario, we define only a small subset of routes (basically just
  //   /logout), so that a user who's in the process of logging in doesn't get bounced
  //   back to the login page by prematurely accessing a PrivateRoute before being fully
  //   authenticated.
  //
  // In certain situations (e.g. a plain in-app login), we want to set our auth status
  // back to PENDING during the login, so that we refrain from rendering PrivateRoutes
  // until _after_ the auth session is established. In other situations (e.g. a handoff
  // from sj_login that has its own redirect mechanism), we don't want to change the
  // route table at all. This flag controls whether that happens: if true, status will
  // go back to PENDING and the set of registered routes will be restricted until the
  // login finishes. If false, the current auth status will remain unchanged.
  clearSessionWhilePending?: boolean
}
export function updateCognitoSession(session: CognitoUserSession, flags: UpdateCognitoSessionFlags = {}) {
  return (dispatch: AuthDispatch) => {
    // Set the auth status back to 'PENDING' while the token exchange happens, so
    // routing etc. will behave appropriately (e.g. we don't want to render any
    // PrivateRoutes until we've finished initializing our new auth state)
    if (flags.clearSessionWhilePending) {
      dispatch({ type: CLEAR_SESSION })
    }

    // Perform the svc-auth token exchange, then update our state with both the Cognito
    // and svc-auth state
    getSvcAuthToken(session.getIdToken().getJwtToken())
      .then((svcAuthToken: SvcAuthToken) => {
        dispatch({
          type: UPDATE_SESSION,
          session,
          svcAuthToken,
        })
      })
      .catch((err) => {
        console.error('Failed to get svc-auth token; clearing Cognito state and logging out', err)
        clearCognitoState()
        dispatch({
          type: UPDATE_SESSION,
          session: null,
          svcAuthToken: null,
        })
      })
  }
}

/**
 * May only be called when already logged in. Attempts to use the logged-in user's
 * refresh token to refresh the Cognito user session, resulting in fresh access and ID
 * tokens, then exchanges that ID token to get a svc-auth access token. Upon success,
 * invokes the provided callback, passing the new session and svc-auth token.
 */
export function refresh(callback: RefreshCallback) {
  return (dispatch: AuthDispatch, getState: () => { auth: AuthState }) => {
    // If we're already attempting a session refresh, ignore this call, but not before
    // registering the callback: if we have multiple concurrent callers, we only want
    // the first call to initiate a session refresh, but we want to notify *all* callers
    // when that refresh is finished
    const state = getState()
    const isRefreshing = state.auth.refreshCallbacks.length > 0
    dispatch({
      type: ADD_REFRESH_CALLBACK,
      callback,
    })
    if (isRefreshing) {
      return
    }

    // Construct a CognitoUser object for the logged-in user
    const userPool = new CognitoUserPool({
      UserPoolId: settings['cognito'].authData.UserPoolId,
      ClientId: settings['cognito'].authData.ClientId,
    })
    const user = new CognitoUser({
      Username: state.auth.user.username,
      Pool: userPool,
    })

    // Use the stored refresh token to establish a fresh CognitoUserSession. Once we've
    // been handed our new session: store it as the new session, fire all callbacks, and
    // then clear the callbacks array
    user.refreshSession(state.auth.session.getRefreshToken(), (err: Error | null, result: CognitoUserSession | null) => {
      if (err || !result) {
        const suffix = err ? `: ${err.message}` : ''
        throw new Error('Failed to refresh Cognito session' + suffix)
      }
      getSvcAuthToken(result.getIdToken().getJwtToken())
        .then((svcAuthToken: SvcAuthToken) => {
          dispatch({
            type: UPDATE_SESSION,
            session: result,
            svcAuthToken,
          })
          const callbacks = getState().auth.refreshCallbacks
          dispatch({ type: CLEAR_REFRESH_CALLBACKS })
          for (const callback of callbacks) {
            callback(result, svcAuthToken)
          }
        })
        .catch((err) => {
          console.error('Failed to get svc-auth token; clearing Cognito state and logging out', err)
          clearCognitoState()
          dispatch({ type: CLEAR_REFRESH_CALLBACKS })
          dispatch({
            type: UPDATE_SESSION,
            session: null,
            svcAuthToken: null,
          })
        })
      })
  }
}

/**
 * Returns a promise that resolves to a token string that can be supplied as the
 * 'Authorization' header value in any backend API request.
 */
export async function getTokenString(state : {auth: AuthState}, dispatch: (a: any) => any): Promise<string> {
  // We never expect this function to be called prior to successfully initializing the
  // auth module with a valid login
  if (state.auth.status !== 'LOGGED_IN') {
    throw new Error('Unable to get token string: user is not logged in')
  }

  // If the session has a valid token, use it directly, without dispatching any
  // actions or effecting any state changes
  const now = new Date()
  if (state.auth.session && state.auth.session.isValid() && state.auth.svcAuthToken && state.auth.svcAuthToken.expiresAt > now) {
    return state.auth.svcAuthToken.accessToken
  }

  // We have a valid session but its tokens are expired, so we need to refresh the
  // session before we can give the caller a valid token. There could be many
  // concurrent calls to this function: we only want the first of them to trigger a
  // refresh, after which subsequent calls should resolve to the new token value
  // without side effects. The refresh dispatcher is idempotent and will notify us via a
  // callback when the new session is ready
  return new Promise((resolve, _) => {
    dispatch(refresh((session: CognitoUserSession, svcAuthToken: SvcAuthToken) => {
      resolve(svcAuthToken.accessToken)
    }))
  })
}
