import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import PrimaryButton from '../../../../components/Buttons/PrimaryButton'
import ContentModal from '../../ContentModal'
import ContentModalCloseButton from '../../ContentModalCloseButton'

function CodeReviewRestoreModal(props) {
  const { submittedAt, onClose, onConfirm } = props
  return (
    <ContentModal id='code-review-restore-modal' onClose={onClose}>
      <ContentModalCloseButton onClick={onClose} />
      <div style={{ padding: 40 }}>
        <h3>Revert to prior submission</h3>
        <p>
          Would you like to revert your sandbox to the code you submitted{' '}
          {moment.utc(submittedAt).local().calendar()}?
        </p>
        <PrimaryButton
          size='medium'
          onClick={onConfirm}
          label='Yes, restore this code'
          customCss={'ml-36'}
        />
        <p>
          Note that you can always revert the sandbox to its original,
          unmodified state by closing this dialog and clicking the{' '}
          <b>Reset Sandbox</b> button.
        </p>
      </div>
    </ContentModal>
  )
}
CodeReviewRestoreModal.propTypes = {
  submittedAt: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default CodeReviewRestoreModal
