import React from 'react'
import { connect } from 'react-redux'

// Components
import Loader from 'app/views/components/Loader'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'

// Redux
import { getOrganizations, getTeamDetails } from 'app/state/modules/hacker'

class OrganizationReports extends React.Component {
  constructor(props) {
    super(props)
    this.onClickReport = this.onClickReport.bind(this)
  }

  componentDidMount() {
    if (!this.props.hasLoadedOrganizations) {
      this.props.getOrganizations()
    }
    this.props.getTeamDetails(this.props.match.params.organization_uuid)
  }

  onClickReport(reportType) {
    this.props.history.push(
      `/admin/${this.props.match.params.organization_uuid}/reports/${reportType}`
    )
  }

  render() {
    if (!this.props.hasLoadedOrganizations) {
      return <Loader visible />
    }

    return (
      <OrganizationLayout
        data-test-id='organization-users-component'
        active='reports'
        {...this.props}
      >
        <h1>Reports</h1>
        <div className='pt-5'>
          <div className='mb-6'>
            <div className='mb-1'>
              <PrimaryButton
                size='small'
                onClick={() => this.onClickReport('download')}
                label='CSV Download'
              />
            </div>
            <div className='text-sm text-grey ml-4'>
              Export all lesson data for your organization as a single CSV.
            </div>
          </div>
          <div className='mb-6'>
            <div className='mb-1'>
              <PrimaryButton
                size='small'
                onClick={() => this.onClickReport('completed')}
                label='Completed Report'
              />
            </div>
            <div className='text-sm text-grey ml-4'>
              See the training completion status of all users, and see who is
              past due.
            </div>
          </div>
          <div className='mb-6'>
            <div className='mb-1'>
              <PrimaryButton
                size='small'
                onClick={() => this.onClickReport('proficiency')}
                label='Proficiency Report'
              />
            </div>
            <div className='text-sm text-grey ml-4'>
              Break down all of the training that has been completed by
              Proficiency level to see where your team is excelling and where
              they could use some extra attention.
            </div>
          </div>
          <div className='mb-6'>
            <div className='mb-1'>
              <PrimaryButton
                size='small'
                onClick={() => this.onClickReport('challenges')}
                label='Challenge Report'
              />
            </div>
            <div className='text-sm text-grey ml-4'>
              See which challenges your developers have completed.
            </div>
          </div>
          <div className='mb-6'>
            <div className='mb-1'>
              <PrimaryButton
                size='small'
                onClick={() => this.onClickReport('users')}
                label='Users Report'
              />
            </div>
            <div className='text-sm text-grey ml-4'>
              Information about all the users in your organization and their
              progress.
            </div>
          </div>
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
  organizationsHash: state.hacker.organizationsHash,
  organizations: state.hacker.organizationsList,
  email: state.auth.user.email,
})

export default connect(mapStateToProps, {
  getOrganizations,
  getTeamDetails,
})(OrganizationReports)
