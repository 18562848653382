import React from 'react'
import PropTypes from 'prop-types'

import FileBrowserIcon from './FileBrowserIcon'

function FileBrowserDirectoryItem(props) {
  const { depth, name, isExpanded, onClick } = props
  return (
    <div
      className='file-browser-directory-item'
      style={{ marginLeft: depth * 8 }}
      onClick={() => onClick()}
    >
      <FileBrowserIcon
        type='directory'
        options={{
          expanded: isExpanded,
        }}
      />
      <div className='file-browser-label'>{name}</div>
    </div>
  )
}
FileBrowserDirectoryItem.propTypes = {
  depth: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default FileBrowserDirectoryItem
