import PropTypes from 'prop-types'
import React from 'react'
import UIkit from 'uikit'
import Icon from 'app/views/components/Icon'

import './styles.less'
import SecondaryButton from '../../Buttons/SecondaryButton'

const propTypes = {
  canClose: PropTypes.bool,
  modalContainer: PropTypes.bool,
}
const defaultProps = {
  canClose: false,
  modalContainer: false,
}

class GenericModal extends React.Component {
  constructor(props) {
    super(props)
    this.hide = this.hide.bind(this)
    this.handleOnClickOverlay = this.handleOnClickOverlay.bind(this)
  }

  componentDidMount() {
    this.modal = UIkit.modal(`#${this.props.id}`, this.props.options || {})
    if (this.props.showOnStart) {
      this.modal.show()
    }
    if (this.props.activeListeners) {
      UIkit.util.on(`#${this.props.id}`, 'beforeshow', () => {
        if (this.props.beforeShow) {
          this.props.beforeShow()
        }
      })
      UIkit.util.on(`#${this.props.id}`, 'beforehide', () => {
        if (this.props.beforeHide) {
          this.props.beforeHide()
        }
      })
      UIkit.util.on(`#${this.props.id}`, 'hide', () => {
        if (this.props.afterHide) {
          this.props.afterHide()
        }
      })
      UIkit.util.on(`#${this.props.id}`, 'shown', () => {
        if (this.props.afterShown) {
          this.props.afterShown()
        }
      })
    }
  }

  destroy() {
    UIkit.modal(`#${this.props.id}`).$destroy(true)
  }

  show() {
    this.modal.show()
    const elem = document.getElementById(`scrollable-${this.props.id}`)
    if (elem) {
      elem.scrollTop = 0
      elem.scrollLeft = 0
    }
  }

  hide(clickCancel) {
    if (this.props.reloadOnConfim) {
      window.location.reload()
    }
    this.modal.hide()
    if (clickCancel && this.props.onHideCancel) {
      this.props.onHideCancel()
    }
    if (this.props.onHide) {
      this.props.onHide()
    }
  }

  handleOnClickOverlay() {
    if (this.props.handleOnClickOverlay)
      return this.props.handleOnClickOverlay()
    if (this.props.closeOnClickOverlay) {
      return this.hide()
    }
    return null
  }

  render() {
    const { noStack } = this.props
    let className = 'uk-modal'
    if (this.props.modalContainer) {
      className = 'uk-modal uk-modal-container'
    }
    if (this.props.isFullScreen) {
      className = 'uk-modal-full'
    }

    let modalOptions = `bg-close: false; esc-close: false; stack: ${
      noStack ? 'false' : 'true'
    };`
    if (this.props.canClose) {
      modalOptions = `bg-close: true; esc-close: true; stack: ${
        noStack ? 'false' : 'true'
      };`
    }

    return (
      <div
        onClick={this.handleOnClickOverlay}
        data-test-id={this.props['data-test-id']}
        id={this.props.id}
        className={className}
        data-uk-modal={modalOptions}
      >
        <div
          id={`scrollable-${this.props.id}`}
          className={`uk-modal-dialog uk-modal-body${
            this.props.width ? ` ${this.props.width}` : ''
          }${this.props.centered ? ' uk-margin-auto-vertical' : ''}${
            this.props.noPadding ? ' uk-padding-remove' : ''
          }`}
          style={this.props.style || {}}
          uk-overflow-auto={this.props.noOverflow ? null : ''}
          onClick={(e) => e.stopPropagation()}
        >
          {this.props.showHeader && (
            <div className='flex items-center items-end uk-padding header'>
              <div className='orange flex justify-center items-center uk-margin-left'>
                ( ´ ▽ `)ﾉ
              </div>
              <div className='uk-margin-small-left'>
                <span>{this.props.title}</span>
                <Icon
                  className='delete-button'
                  onClick={this.handleOnClickOverlay}
                  name='close'
                  ratio={1.2}
                />
              </div>
            </div>
          )}
          <div className={this.props.extraPadding ? 'uk-padding' : ''}>
            {!this.props.notTitle && (
              <h2 className={this.props.titleClassName}>
                {this.props.title}
                {this.props.titleInfo}
              </h2>
            )}
            <>{this.props.body}</>
            {!this.props.noButtons && (
              <p className='text-right'>
                <SecondaryButton
                  size={'small'}
                  customCss='mr-4'
                  onClick={this.hide.bind(this, true)}
                  label={this.props.closeText || 'Cancel'}
                />

                {this.props.buttons}
              </p>
            )}
          </div>
        </div>
        {this.props.overlayElements}
      </div>
    )
  }
}

GenericModal.propTypes = propTypes
GenericModal.defaultProps = defaultProps

export default GenericModal
