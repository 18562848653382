import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { VBox, HBox, Box } from 'app/views/core/Box.jsx'

// The outage alert is global: we can display a single alert app-wide.
// This flag controls whether we show an alert at all. If there's a new outage:
// - Remain calm.
// - Set this flag to true.
// - Update the alert message in the component below.
// - Proofread the message.
// - Let someone else proofread the message.
// - Deploy.
const SHOW_OUTAGE_ALERT = false

const Container = ({ onDismiss, children }) => (
  <VBox className='outage-alert'>
    <HBox>
      <Box grow>
        <h3>Outage Alert</h3>
      </Box>
      <Box fixed={80}>
        <a href='#' onClick={() => onDismiss()}>
          <em style={{ color: 'gray' }}>Dismiss</em>
        </a>
      </Box>
    </HBox>
    {children}
  </VBox>
)

function OutageAlert(props) {
  const [isDismissed, setIsDismissed] = useState(false)
  if (!SHOW_OUTAGE_ALERT || isDismissed) {
    return null
  }
  return (
    <Container onDismiss={() => setIsDismissed(true)}>
      <p>
        {/* EDIT THIS MESSAGE WHEN RE-ENABLING SHOW_OUTAGE_ALERT
        We are currently affected by a widespread AWS outage that was first reported this morning.
        Some sandboxes may be unavailable: we apologize for the inconvenience.
        For more information, see: <a href="https://status.aws.amazon.com">https://status.aws.amazon.com</a>
        */}
      </p>
    </Container>
  )
}

export default OutageAlert
