import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import PrimaryButton from '../Buttons/PrimaryButton'
import SecondaryButton from '../Buttons/SecondaryButton'
import { Button } from 'app/views/components/GustavoButton'

import './styles.less'

const propTypes = {
  hasCompletedContent: PropTypes.bool,
  lessonInProgress: PropTypes.bool,
  requiresSubscription: PropTypes.bool,
  restartText: PropTypes.string,
  small: PropTypes.bool,
  startText: PropTypes.string,
  url: PropTypes.string.isRequired,
  isActiveForUser: PropTypes.bool.isRequired,
  contentType: PropTypes.string,
}

const defaultProps = {
  hasCompletedContent: false,
  lessonInProgress: false,
  requiresSubscription: null,
  small: false,
  contentType: null,
}

const ContentButton = (props) => {
  const {
    hasCompletedContent,
    isActiveForUser,
    lessonInProgress,
    requiresSubscription,
    small,
    url,
    contentType,
  } = props

  let linkText, status

  if (requiresSubscription && !isActiveForUser) {
    return (
      <Button
        content='Locked'
        disabled
        small={Boolean(small)}
        margin='small'
        style={{
          background: '#f8f8f8',
          display: 'flex',
        }}
        marginRemove={['left', 'top', 'bottom']}
        icon={{
          name: 'lock',
          ratio: 0.8,
        }}
      />
    )
  }

  if (lessonInProgress) {
    linkText = 'Continue'
    status = 'in-progress'
    if (hasCompletedContent) {
      status = 'completed'
      linkText =
        contentType === 'article' ? 'Re-read Article' : 'Restart Lesson'
    }
  } else {
    linkText = contentType === 'article' ? 'Read Article' : 'Start Lesson'
    status = 'not-started'
  }

  return status !== 'completed' ? (
    <PrimaryButton
      status={status}
      size={small ? 'small' : 'medium'}
      link_to={url}
      label={linkText}
    />
  ) : (
    <SecondaryButton
      status={status}
      size={small ? 'small' : 'medium'}
      link_to={url}
      label={linkText}
    />
  )
}

ContentButton.propTypes = propTypes
ContentButton.defaultProps = defaultProps

export default ContentButton
