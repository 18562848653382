import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { VBox } from 'app/views/core/Box'

/**
 * Panel on the left side of the content UI: displays the lesson title and other metadata, along
 * with any content-type-specific component(s) supplied as children.
 */
function InstructionPanel(props) {
  const {
    contentId,
    title,
    children,
    narrow,
    hidden,
    showLessonTitle = true,
  } = props
  const scrollRef = useRef()
  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0
      scrollRef.current.scrollTop = 0
    }
  }

  return (
    <div
      ref={scrollRef}
      className={cx('instruction-panel uk-padding', {
        'instruction-panel-narrow': narrow,
      })}
      style={{ display: hidden ? 'none' : 'block' }}
    >
      <VBox id='instruction-panel-contents'>
        {showLessonTitle && (
          <h1 style={{ marginBottom: 0, borderBottom: '1px solid #eee' }}>
            {title}
          </h1>
        )}
        {typeof children === 'function' ? children(scrollToTop) : children}
      </VBox>
    </div>
  )
}
InstructionPanel.propTypes = {
  contentId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showLessonTitle: PropTypes.bool,
  children: PropTypes.any,
  narrow: PropTypes.bool,
  hidden: PropTypes.bool,
}

export default InstructionPanel
