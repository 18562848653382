// Code used to to facilitate cross-domain logins from the SJ app: the SJ app can send
// the logged-in user's Cognito tokens to api-hacker in exchange for an access code, and
// it can then pass that code to the HE app, which uses the code to fetch the original
// Cognito tokens, establish a CognitoUserSession from those values, and ensure that
// Cognito state is flushed to localStorage.

import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'

import settings from 'settings'

/**
 * Given a temporary access code, attempts to restore a valid CognitoUserSession and
 * flush all Cognito-related state to localStorage.
 */
export async function restoreCognitoSessionFromAccessCode(code: string): Promise<CognitoUserSession> {
  const credentials = await retrieveStoredCredentials(code)
  const session = await restoreCognitoSession(credentials)
  if (!session.isValid()) {
    throw new Error('Cognito session restored from access code is invalid')
  }
  return session
}

/**
 * Cognito token values that are temporarily stored in the HackEDU backend, indexed
 * behind a short-lived access code.
 */
type StoredCredentials = {
  access_token: string
  refresh_token: string
  id_token: string
}

/**
 * Given an access code, retrieves the Cognito tokens that were originally stored in
 * exchange for that code.
 */
async function retrieveStoredCredentials(code: string): Promise<StoredCredentials> {
  // Hit api-hacker to exchange the code for the Cognito tokens that were stored by SJ
  const url = `${settings['urls'].hacker}/init_sj_login?code=${code}`
  const r = await fetch(url)
  if (!r.ok) {
    throw new Error(`init_sj_login failed with status code ${r.status}`)
  }

  // Make sure we're getting the expected set of tokens from api-hacker
  const data = await r.json()
  const keys = ['access_token', 'refresh_token', 'id_token']
  if (!keys.every((key) => typeof data[key] === 'string')) {
    throw new Error('Unexpected JSON data from init_sj_login')
  }
  return data
}

/**
 * Given a set of Cognito tokens, constructs a new CognitoUserSession and ensures that
 * the relevant Cognito state is flushed to localStorage, such that the same session
 * will be reestablished on subsequent page loads.
 */
function restoreCognitoSession(credentials: StoredCredentials): Promise<CognitoUserSession> {
  // Use the Cognito client SDK to parse our JWTs and establish an in-memory
  // CognitoUserSession backed by those tokens
  const session = new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: credentials.id_token }),
    AccessToken: new CognitoAccessToken({ AccessToken: credentials.access_token }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: credentials.refresh_token }),
  })

  // Parse the user's email address from the decoded ID token
  const email = getEmailFromToken(session.getIdToken().payload)
  if (!email) {
    throw new Error('could not resolve email from idToken')
  }

  // Optionally, check for a device_key value in the access token's payload.
  const deviceKey = session.getAccessToken().payload['device_key'] || ''
  const deviceGroupKey = ''

  // Using the decoded email as the Cognito username, flush all relevant Cognito state
  // to localStorage
  flushSessionToLocalStorage(
    email,
    session.getIdToken().getJwtToken(),
    session.getAccessToken().getJwtToken(),
    session.getRefreshToken().getToken(),
    deviceKey,
    deviceGroupKey,
  )

  // Now that our Cognito state has been committed to localStorage, we should be able to
  // load up a new CognitoUserSession from scratch, just by letting the Cognito client
  // SDK happen upon the data we've written to localStorage - this makes certain that
  // our backdoor credential-shuffling has had the intended effect
  const user = new CognitoUser({
    Pool: new CognitoUserPool({
      UserPoolId: settings['cognito'].authData.UserPoolId,
      ClientId: settings['cognito'].authData.ClientId,
    }),
    Username: email,
  })
  return new Promise((resolve, reject) => {
    user.getSession((error: Error, session: CognitoUserSession) => {
      if (error || !session) {
        reject(error || new Error('CognitoUser.getSession failed unexpectedly'))
      } else {
        resolve(session)
      }
    })
  })
}

/**
 * Given the decoded payload of a Cognito access token, attempts to resolve an email
 * address for the user to whom the token was issued. If unable to resolve a valid email
 * address, returns an empty string.
 */
function getEmailFromToken(payload: { [key: string]: any }): string {
  // The JWT may encode the email directly, such as for non-SSO login
  const email = payload['email'] || ''
  if (email) {
    return email
  }
  // For a SAML/SSO login, the JWT may include a list of identities that include the
  // user's email
  for (const identity of payload['identities'] || []) {
    const userId = identity['userId'] || ''
    const atPos = userId.indexOf('@')
    const lastDotPos = userId.lastIndexOf('.')
    if (userId && atPos >= 0 && lastDotPos > atPos) {
      return userId
    }
  }
  // Fall back to cognito:username, which may take the form <uuid>_<email>
  const username = payload['cognito:username'] || ''
  const atPos = username.indexOf('@')
  const lastDotPos = username.lastIndexOf('.')
  if (username && atPos >= 0 && lastDotPos > atPos) {
    // This code expects cognito:username values to always be prefixed with a UUID, e.g.
    // '73fb0daab2f65d95360ed10ceb165bed_barry@gmail.com' would be correctly parsed as
    // 'barry@gmail.com', but 'barry_lyndon@gmail.com' would be erroneously parsed as
    // 'lyndon@gmail.com'. This is a longstanding assumption made in old auth code, so
    // it's probably always the case that the cognito username is prefixed, but this
    // assumption is still a bit brittle and could be validated better.
    const underscorePos = username.indexOf('_')
    return username.slice(underscorePos + 1)
  }
  // We can't resolve a user email from this token
  return ''
}

/**
 * Utility function for caching the state from a CognitoUserSession to localStorage,
 * with the appropriate 'CognitoIdentitySeriviceProvider.'-prefixed keys, so that the
 * Cognito client library can reestablish an equivalent CognitoUserSession on subsequent
 * page loads.
 */
function flushSessionToLocalStorage(username: string, idToken: string, accessToken: string, refreshToken: string, deviceKey: string, deviceGroupKey: string) {
  const set = (prefix: string, key: string, value: string) => {
    localStorage.setItem(`${prefix}.${key}`, value)
  }
  const cognitoClientId = settings['cognito'].authData.ClientId
  const globalPrefix = `CognitoIdentityServiceProvider.${cognitoClientId}`
  const userPrefix = `${globalPrefix}.${username}`

  set(userPrefix, 'idToken', idToken)
  set(userPrefix, 'accessToken', accessToken)
  set(userPrefix, 'refreshToken', refreshToken)
  set(userPrefix, 'clockDrift', '0')
  if (deviceKey) {
    set(userPrefix, 'deviceKey', deviceKey)
  }
  if (deviceGroupKey) {
    set(userPrefix, 'deviceGroupKey', deviceGroupKey)
  }
  set(globalPrefix, 'LastAuthUser', username)
}
