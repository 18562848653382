import PropTypes from 'prop-types'
import React from 'react'

const propTypes = {
  bgClose: PropTypes.bool,
  body: PropTypes.object,
  containerModifier: PropTypes.bool,
  escClose: PropTypes.bool,
  handleComponentDidMount: PropTypes.func,
  id: PropTypes.string.isRequired,
  minHeight: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
}
const defaultProps = {
  bgClose: true,
  body: <div />,
  containerModifier: false,
  escClose: true,
  handleComponentDidMount: () => {},
  minHeight: '0%',
  title: '',
}

class Modal extends React.Component {
  componentDidMount() {
    if (this.props.handleComponentDidMount) {
      this.props.handleComponentDidMount()
    }
  }

  render() {
    const containerClassName = this.props.containerModifier
      ? 'uk-modal-container'
      : ''
    return (
      <div
        className={containerClassName}
        data-test-id={this.props['data-test-id']}
        data-uk-modal={`bg-close: ${this.props.bgClose}; esc-close: ${this.props.escClose}; stack: true;`}
        id={this.props.id}
        style={this.props.style}
      >
        <div
          className={`uk-modal-dialog ${this.props.width || ''}`}
          style={{
            minHeight: this.props.minHeight,
            ...(this.props.dialogStyle || {}),
          }}
        >
          {this.props.title !== '' && (
            <h2
              className='text-center uk-margin-remove'
              data-test-id={this.props.titleTestId}
            >
              {this.props.title}
            </h2>
          )}
          <div className='uk-modal-body'>{this.props.body}</div>
        </div>
      </div>
    )
  }
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
