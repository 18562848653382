import React from 'react'

export const SplashLogo = () => (
  <a href="https://securityjourney.com">
    <img
      src='/static/images/SJ_HE_Logo2ColorHorizontal22.png'
      className='w-[450px] my-4'
      alt='Security Journey logo'
    />
  </a>
)
