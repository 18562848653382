import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import settings from 'settings'
import { api } from 'app/views/utils/api'
import analytics from 'app/views/utils/analytics'

/**
 * Custom hook for tracking which tab we want to display: the user can freely select the different
 * tabs, but if the product tour wants a tab to be visible, that takes precedence.
 */
function useTabState(initialSelectedTabName) {
  const [userSelectedTabName, setUserSelectedTabName] = useState(
    initialSelectedTabName
  )
  const [tourSelectedTabName, setTourSelectedTabName] = useState(null)

  function onActivate(source, tabName) {
    if (source === 'user') {
      setUserSelectedTabName(tabName)
    } else if (source === 'tour') {
      setTourSelectedTabName(tabName)
    }
  }

  return [tourSelectedTabName || userSelectedTabName, onActivate]
}

/**
 * Used within the content UI to keep track of the user's progress in clicking through
 * the steps of a lesson or article: if the user navigates away and then reloads the
 * page within a reasonable timeframe, we want to return them to their last position in
 * the article instead of restarting from the first page of instructions.
 *
 * Note that this client-side mechanism is just a lightweight, transient means of
 * keeping the UI consistent between page reloads; it's separate from the persistent
 * user progress data that we store in the database.
 */
function useCachedStepIndex(contentId, numSteps) {
  // Store a blob of JSON data, serialized to a string in localStorage
  const key = 'hackedu.instructionProgress'

  // Use a helper function to parse the current localStorage value and discard any
  // values for outdated timestamps
  function loadFilteredProgressData() {
    // Parse the existing value as JSON, defaulting to empty object on error
    let objVal
    const stringVal = localStorage.getItem(key)
    try {
      objVal = JSON.parse(stringVal || '{}')
    } catch (err) {
      return {}
    }

    // Ignore progress data that we cached more than 2 hours ago; if the user's been
    // away long enough, we can restart from the top
    const now = new Date().getTime() / 1000.0
    const expireCutoff = now - 2.0 * 60.0 * 60.0

    // Strip out old values: we don't want to continually accumulate more and more
    // useless timestamps in localStorage as the user completes more lessons
    return Object.fromEntries(
      Object.entries(objVal).filter(
        ([_, val]) =>
          typeof val.timestamp === 'number' && val.timestamp > expireCutoff
      )
    )
  }

  // Establish a couple of other helpers to read/write cached stepIndex values, indexed
  // by content ID
  function loadStepIndex() {
    const data = loadFilteredProgressData()
    if (data[contentId]) {
      const loadedStepIndex = data[contentId].stepIndex
      if (loadedStepIndex >= 0 && loadedStepIndex < numSteps) {
        return loadedStepIndex
      }
    }
    return 0
  }

  function saveStepIndex(stepIndex) {
    const data = {
      ...loadFilteredProgressData(),
      [contentId]: {
        stepIndex,
        timestamp: new Date().getTime() / 1000.0,
      },
    }
    localStorage.setItem(key, JSON.stringify(data))
  }

  // Track the current stepIndex value that we've read from or written to localStorage,
  // writing new data to localStorage whenever the caller changes the value
  const [cachedStepIndex, setCachedStepIndex] = useState(loadStepIndex())
  function setStepIndex(val) {
    const newStepIndex = typeof val === 'function' ? val(cachedStepIndex) : val
    if (cachedStepIndex !== newStepIndex) {
      saveStepIndex(newStepIndex)
      setCachedStepIndex(newStepIndex)
    }
  }
  return [cachedStepIndex, setStepIndex]
}

/**
 * Custom hook that resolves a client timestamp indicating the last time we saw any activity from
 * the assigned sandbox, or null if no sandbox is assigned and ready. If the sandbox has a proxy,
 * this is typically the most recent response timestamp; otherwise we fall back on the timestamp
 * when we were assigned a ready sandbox.
 */
function useSandboxLastActivityTimestamp() {
  // Sending a request through the proxy doesn't result in touchSandbox() being called, so we'll
  // ignore last proxy response time for now. TODO: When requests refresh the sandbox, use:
  // Math.max(sandboxReadyTimestamp, sbproxy.lastRecvTimestamp)
  return useSelector((state) => {
    const { sandbox } = state
    return !sandbox.requestError ? sandbox.requestFinishedTimestamp : null
  })
}

/**
 * Custom hook that returns a boolean indicating whether the sandbox has been inactive for longer
 * than the specified interval (in milliseconds).
 */
function useSandboxInactivityTimeout(interval) {
  const [hasTimedOut, setHasTimedOut] = useState(false)
  const lastActivityTimestamp = useSandboxLastActivityTimestamp()

  const timerHandle = useRef(null)
  const checkTimeout = () => {
    const elapsedSinceLastActivity = Date.now() - lastActivityTimestamp
    setHasTimedOut(elapsedSinceLastActivity >= interval)
  }

  useEffect(() => {
    if (timerHandle.current) {
      clearInterval(timerHandle.current)
      timerHandle.current = null
    }
    setHasTimedOut(false)
    if (typeof lastActivityTimestamp === 'number') {
      timerHandle.current = setInterval(checkTimeout, 5000)
    }
  }, [lastActivityTimestamp])

  return hasTimedOut
}

/** Attempts to submit feedback for a specific piece of content. */
async function submitFeedback(contentId, numStarsRated, feedbackText) {
  try {
    const response = await api({
      method: 'post',
      url: `${settings.urls.hacker}/feedback`,
      withAuthToken: true,
      data: {
        content_uuid: contentId,
        stars: numStarsRated,
        message: feedbackText,
      },
    })
    if (response.data.success === true) {
      analytics.track('feedback')
    }
  } catch (err) {
    console.error(err)
  }
}

export {
  useTabState,
  useCachedStepIndex,
  useSandboxLastActivityTimestamp,
  useSandboxInactivityTimeout,
  submitFeedback,
}
