import React from 'react'

// Components
import SelectCountryCode from 'app/views/components/Forms/SelectCountryCode'
import GenericModal from 'app/views/components/Modals/GenericModal'

// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'
import analytics from 'app/views/utils/analytics'

class OnboardingEnterpriseModal extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      phone: '',
      phoneCountryCode: '1',
      name: '',
      company: '',
      loadingButton: false,
      formMessage: '',
      licenseCount: '',
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.handlePhoneCountryChange = this.handlePhoneCountryChange.bind(this)
    this.handleLicenseCountChange = this.handleLicenseCountChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value })
  }

  handleCompanyChange(e) {
    this.setState({ company: e.target.value })
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value })
  }

  handlePhoneCountryChange(countryCode) {
    this.setState({ phoneCountryCode: countryCode })
  }

  handleLicenseCountChange(e) {
    let licenseCount = parseInt(e.target.value, 0)
    if (licenseCount < 0) {
      licenseCount = 0
    }
    if (licenseCount) {
      this.setState({ licenseCount })
    } else {
      this.setState({ licenseCount: null })
    }
  }

  async handleSubmit(e) {
    e.preventDefault()

    this.setState({ loadingButton: true })

    const message = `New Quote from Deeplink (${this.props.deepLink.name})\nOrganization: ${this.state.company}\nPhone Country Code: ${this.state.phoneCountryCode}\nPhone: ${this.state.phone}\nLicenses: ${this.state.licenseCount}`

    api({
      method: 'post',
      url: `${settings.urls.hacker}/contact`,
      data: {
        name: this.state.name,
        email: this.state.email,
        message,
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            formMessage:
              'Thank you for applying for a custom quote! We will get back to you shortly. For questions, please email sales@hackedu.com.',
            loadingButton: false,
          })
          analytics.track('conversion-onboarding-enterprise')
        } else {
          this.setState({
            loadingButton: false,
            formMessage: 'Whoops! Something went wrong.',
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  render() {
    return (
      <>
        <button
          type='button'
          className='uk-button uk-button-primary uk-width-3-4 uk-margin-top'
          onClick={() => this.modal.show()}
        >
          Get a Quote
        </button>
        <GenericModal
          closeOnClickOverlay
          noButtons
          width='uk-width-2-3'
          id='onboarding-enterprise'
          ref={(ref) => {
            this.modal = ref
          }}
          title='Get a Quote'
          body={
            <form className='uk-form-horizontal'>
              <fieldset className='uk-fieldset'>
                <div className='uk-margin'>
                  <label
                    className='uk-form-label text-right'
                    htmlFor='form-horizontal-text'
                  >
                    Name
                  </label>
                  <div data-uk-grid>
                    <div className='uk-width-1-1'>
                      <input
                        className='uk-input'
                        type='text'
                        placeholder='Your name...'
                        onChange={this.handleNameChange}
                        value={this.state.name}
                      />
                    </div>
                  </div>
                </div>

                <div className='uk-margin'>
                  <label
                    className='uk-form-label text-right'
                    htmlFor='form-horizontal-text'
                  >
                    Email
                  </label>
                  <div data-uk-grid>
                    <div className='uk-width-1-1'>
                      <input
                        className='uk-input'
                        type='text'
                        placeholder='Email...'
                        onChange={this.handleEmailChange}
                        value={this.state.email}
                      />
                    </div>
                  </div>
                </div>

                <div className='uk-margin'>
                  <label
                    className='uk-form-label text-right'
                    htmlFor='form-horizontal-text'
                  >
                    Company Name
                  </label>
                  <div data-uk-grid>
                    <div className='uk-width-1-1'>
                      <input
                        className='uk-input'
                        type='text'
                        placeholder='Company Name...'
                        onChange={this.handleCompanyChange}
                        value={this.state.company}
                      />
                    </div>
                  </div>
                </div>

                <div className='uk-margin'>
                  <label
                    className='uk-form-label text-right'
                    htmlFor='form-horizontal-text'
                  >
                    Phone Number
                  </label>
                  <div data-uk-grid>
                    <div className='uk-width-1-3'>
                      <SelectCountryCode
                        onCountryChange={(countryCode) => {
                          this.handlePhoneCountryChange(countryCode)
                        }}
                      />
                    </div>
                    <div className='uk-width-2-3' style={{ paddingLeft: 10 }}>
                      <input
                        className='uk-input'
                        type='text'
                        placeholder='Phone Number...'
                        onChange={this.handlePhoneChange}
                        value={this.state.phone}
                      />
                    </div>
                  </div>
                </div>

                <div className='uk-margin'>
                  <label
                    className='uk-form-label text-right'
                    htmlFor='form-horizontal-text'
                  >
                    Number of Licenses
                  </label>
                  <div data-uk-grid>
                    <div className='uk-width-1-1'>
                      <input
                        className='uk-input'
                        type='text'
                        placeholder='Number of Licenses (1-10,000)'
                        onChange={this.handleLicenseCountChange}
                        value={this.state.licenseCount}
                      />
                    </div>
                  </div>
                </div>

                <div className='uk-margin'>
                  <label
                    className='uk-form-label text-right'
                    htmlFor='form-horizontal-text'
                  ></label>
                  <div data-uk-grid>
                    <div className='uk-width-1-1'>
                      {this.state.loadingButton ? (
                        <span data-uk-spinner='' />
                      ) : (
                        <div>
                          {this.state.formMessage !== '' ? (
                            <div role='alert' className='alert alert-danger'>
                              <strong className='text-primary'>
                                {this.state.formMessage}
                              </strong>
                            </div>
                          ) : (
                            <button
                              type='submit'
                              className='uk-button uk-button-primary tm-button-primary'
                              onClick={this.handleSubmit}
                            >
                              Get Quote Now
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          }
        />
      </>
    )
  }
}

export default OnboardingEnterpriseModal
