import React from 'react'
import PropTypes from 'prop-types'

import PrimaryButton from '../../../../components/Buttons/PrimaryButton'

import EditorTab from './EditorTab'
function EditorTabBar(props) {
  const {
    items,
    activeFilePath,
    activeFileIsLoading,
    activeFileIsSaving,
    activeFileIsReadOnly,
    isLockedDueToSubmission,
    isLockedDueToRevert,
    canSaveActiveFile,
    onClickTab,
    onCloseTab,
    onClickSave,
  } = props
  return (
    <div className='editor-tab-bar'>
      <div className='editor-tab-bar-top'>
        <div className='editor-tab-bar-scroll'>
          {items.map(({ path, hasEdits, hasHints }) => (
            <EditorTab
              key={path}
              name={path.split('/').slice(-1)[0]}
              hasEdits={hasEdits}
              hasHints={hasHints}
              isActive={path === activeFilePath}
              onClick={() => onClickTab(path)}
              onClickClose={() => onCloseTab(path)}
            />
          ))}
        </div>
      </div>
      <div className='editor-tab-bar-bottom'>
        <div className='editor-tab-bar-active-file-label'>
          {activeFilePath}
          {activeFileIsReadOnly
            ? ' [READ-ONLY]'
            : isLockedDueToSubmission
            ? ' [TESTING IN PROGRESS]'
            : isLockedDueToRevert
            ? ' [REVERT IN PROGRESS]'
            : ''}
          {activeFileIsLoading || activeFileIsSaving ? ' [...]' : ''}
        </div>
        <PrimaryButton
          label='Save'
          size='small'
          visible={canSaveActiveFile}
          onClick={onClickSave}
          customCss={'mr-2.5'}
        />
      </div>
    </div>
  )
}
EditorTabBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      hasEdits: PropTypes.bool.isRequired,
      hasHints: PropTypes.bool.isRequired,
    })
  ).isRequired,
  activeFilePath: PropTypes.string,
  activeFileIsLoading: PropTypes.bool.isRequired,
  activeFileIsSaving: PropTypes.bool.isRequired,
  activeFileIsReadOnly: PropTypes.bool.isRequired,
  isLockedDueToSubmission: PropTypes.bool.isRequired,
  isLockedDueToRevert: PropTypes.bool.isRequired,
  canSaveActiveFile: PropTypes.bool.isRequired,
  onClickTab: PropTypes.func.isRequired,
  onCloseTab: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
}

export default EditorTabBar
