import settings from 'settings'

export type SvcAuthToken = {
  accessToken: string
  expiresAt: Date
}

/**
 * POSTs to /svc/auth/token in order to exchange the given Cognito ID token for a
 * svc-auth access token. A successful response from /svc/auth/token will contain the
 * following JSON payload:
 *
 * - access_token:      JWT issued by svc-auth, which can be used to authorize the
 *                      logged-in user in any requests to mySJ backend services, as well
 *                      as to api-hacker
 * - expires_in:        String-formatted integer indicating the number of seconds until
 *                      the token expires
 * - issued_token_type: Always "urn:ietf:params:oauth:token-type:jwt"
 * - token_type:        Always "bearer"
 *
 * Throws an Error if unable to obtain a valid svc-auth token.
 */
export async function getSvcAuthToken(cognitoIdToken: string): Promise<SvcAuthToken> {
  // Make the svc-auth request
  const requestStartedAt = new Date()
  const url = `${settings.urls.sj}/svc/auth/token`
  const r = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      subject_token: cognitoIdToken,
    }),
  })

  // Response body should be JSON: e.g. {"code":13,"message":"exchange failed"} in case
  // of failure; {"access_token":"<jwt>",...} if the response is ok
  let jsonData = null
  if (r.headers.get('Content-Type').startsWith('application/json')) {
    jsonData = await r.json()
  }

  // Handle failure cases: request error or unexpected response format
  if (!r.ok) {
    let message = `HTTP ${r.status}`
    if (jsonData && typeof jsonData['message'] === 'string') {
      message = jsonData['message']
    }
    throw new Error(`Failed to get auth token with status ${r.status}: ${message}`)
  }
  if (!jsonData || typeof jsonData['access_token'] !== 'string') {
    throw new Error(`Failed to get auth token: no 'access_token' in response body (got ${JSON.stringify(jsonData)})`)
  }
  const numSecondsUntilExpiration = parseInt(jsonData['expires_in'])
  if (isNaN(numSecondsUntilExpiration)) {
    throw new Error(`Failed to get auth token: 'expires_in' missing or invalid (got ${JSON.stringify(jsonData)})`)
  }

  // We got a valid access token; return it
  const accessToken: string = jsonData['access_token']
  const expiresAt = new Date()
  expiresAt.setSeconds(requestStartedAt.getSeconds() + numSecondsUntilExpiration - 5)
  return {
    accessToken,
    expiresAt,
  }
}

/**
 * Clears all keys related to our app's Cognito client from localStorage: this helps
 * ensure that we get back to a clean slate in the event we find ourselves in an
 * exceptional state.
 */
export function clearCognitoState() {
  const cognitoClientId = settings['cognito'].authData.ClientId
  const globalPrefix = `CognitoIdentityServiceProvider.${cognitoClientId}`

  const keysToRemove = [] as string[]
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key.startsWith(globalPrefix)) {
      keysToRemove.push(key)
    }
  }

  for (const key of keysToRemove) {
    localStorage.removeItem(key)
  }
}
