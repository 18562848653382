// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'

// get sso details
const GETTING_SSO = 'sso/GETTING_SSO'
const RECEIVED_SSO = 'sso/RECEIVED_SSO'
const RECEIVED_SSO_ERROR = 'sso/RECEIVED_SSO_ERROR'

// update sso
const UPDATING_SSO = 'sso/UPDATING_SSO'
const UPDATED_SSO = 'sso/UPDATED_SSO'
const UPDATED_SSO_ERROR = 'sso/UPDATED_SSO_ERROR'

const initialState = {
  // sso details
  domains: [],
  enabled: false,
  metadata_file: null,
  metadata_url: null,
  loadingSSOError: '',
  loadingSSO: false,
  hasLoadedSSO: false,

  // update sso
  updatingSSO: false,
  updatingSSOError: '',
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // team list
    case GETTING_SSO:
      return {
        ...state,
        loadingSSO: true,
        loadingSSOError: '',
      }
    case RECEIVED_SSO:
      return {
        ...state,
        loadingSSO: false,
        hasLoadedSSO: true,
        domains: action.domains,
        enabled: action.enabled,
        metadata_file: action.metadata_file,
        metadata_url: action.metadata_url,
      }
    case RECEIVED_SSO_ERROR:
      return {
        ...state,
        loadingSSO: false,
        loadingSSOError: action.error,
      }

    // update sso
    case UPDATING_SSO:
      return {
        ...state,
        updatingSSO: true,
        updatingSSOError: '',
      }
    case UPDATED_SSO:
      return {
        ...state,
        updatingSSO: false,
        domains: action.domains,
        enabled: action.enabled,
        metadata_file: action.metadata_file,
        metadata_url: action.metadata_url,
      }
    case UPDATED_SSO_ERROR:
      return {
        ...state,
        updatingSSO: false,
        updatingSSOError: action.error,
      }

    default:
      return state
  }
}

export function getSSO(organizationId) {
  return (dispatch) => {
    dispatch({
      type: GETTING_SSO,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/sso`,
      withAuthToken: true,
    })
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: RECEIVED_SSO_ERROR,
            error: response.data.error,
          })
          return false
        }
        dispatch({
          type: RECEIVED_SSO,
          domains: response.data.domains,
          enabled: response.data.enabled,
          metadata_file: response.data.metadata_file,
          metadata_url: response.data.metadata_url,
        })
        return true
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: RECEIVED_SSO_ERROR,
          error: 'Whoops! There was an error getting sso details.',
        })
      })
  }
}

export function updateSSO(organizationId, enabled, metadataFile, metadataUrl) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_SSO,
    })

    const data = {}
    if (enabled !== null) {
      data.enabled = enabled
    }
    if (metadataFile !== null) {
      data.metadata_file = metadataFile
    }
    if (metadataUrl !== null) {
      data.metadata_url = metadataUrl
    }

    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationId}/sso`,
      withAuthToken: true,
      data,
    })
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: UPDATED_SSO_ERROR,
            error: response.data.error,
          })
        } else {
          dispatch({
            type: UPDATED_SSO,
            domains: response.data.domains,
            enabled: response.data.enabled,
            metadata_file: response.data.metadata_file,
            metadata_url: response.data.metadata_url,
          })
        }
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: UPDATED_SSO_ERROR,
          error: 'Whoops! There was an error updating sso.',
        })
        return false
      })
  }
}
