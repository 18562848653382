import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired, // withRouter
  }).isRequired,
}

class NotFound extends React.Component {
  componentDidMount() {
    this.props.history.push('/')
  }

  render() {
    return <div />
  }
}

NotFound.propTypes = propTypes

export default withRouter(NotFound)
