import React from 'react'

// Components
import Modal from 'app/views/components/UIkit/Modal'

const ModalOverrideOrganizationDefault = () => (
  <Modal
    id='modal-info-override-organization-default'
    title='Override Organization Default'
    body={
      <div>
        <p>
          If "Override Organization Default" is off, this team will use whatever
          the global setting is for requiring the coding test to pass the
          lesson.
        </p>
        <p>
          If you would like this team to have a different setting than the
          default, turn on the override and then set the Coding Test Required
          setting either off or on.
        </p>
      </div>
    }
  />
)

export default ModalOverrideOrganizationDefault
