/**
 * Smoothly interpolates a 1D value over time.
 */
function interpTo(current, target, deltaTime, interpSpeed) {
  const distance = target - current
  if (distance * distance < 0.0001) {
    return target
  }
  const delta = distance * Math.max(0.0, Math.min(1.0, deltaTime * interpSpeed))
  return current + delta
}

/**
 * Given an anchor string, returns an X, Y pair indicating the sign of each axis.
 */
function anchorToSign(anchor) {
  if (anchor === 'top-left') return [-1, -1]
  if (anchor === 'top') return [0, -1]
  if (anchor === 'top-right') return [1, -1]
  if (anchor === 'left') return [-1, 0]
  if (anchor === 'center') return [0, 0]
  if (anchor === 'right') return [1, 0]
  if (anchor === 'bottom-left') return [-1, 1]
  if (anchor === 'bottom') return [0, 1]
  return [1, 1]
}

/**
 * Given an anchor string and a client rect, returns the 2D screen position of the desired anchor.
 */
function anchorToPosition(anchor, rect) {
  let position = { x: 0, y: 0 }
  const [anchorX, anchorY] = anchorToSign(anchor)

  if (anchorX < 0) {
    position.x = rect.x
  } else if (anchorX === 0) {
    position.x = rect.x + rect.width / 2
  } else {
    position.x = rect.x + rect.width
  }

  if (anchorY < 0) {
    position.y = rect.y
  } else if (anchorY === 0) {
    position.y = rect.y + rect.height / 2
  } else {
    position.y = rect.y + rect.height
  }

  return position
}

/**
 * Computes a page-relative position for a tooltip: returns a style object consisting of either
 * a 'left' or 'right' attribute, and either a 'top' or 'bottom' attribute.
 */
function computeTooltipPosition(anchor, rect, tooltipAnchor, pad) {
  const srcPosition = anchorToPosition(anchor, rect)
  const [anchorX, anchorY] = anchorToSign(tooltipAnchor)

  let position = {}
  if (anchorX < 0) {
    position.left = Math.max(srcPosition.x + pad, pad)
  } else {
    position.right = Math.max(
      document.documentElement.clientWidth - srcPosition.x + pad,
      pad
    )
  }

  if (anchorY < 0) {
    position.top = Math.max(srcPosition.y + pad, pad)
  } else {
    position.bottom = Math.max(
      document.documentElement.clientHeight - srcPosition.y + pad,
      pad
    )
  }
  return position
}

export { interpTo, computeTooltipPosition }
