const SET_PLAN = 'SET_PLAN'
const SET_STEP = 'SET_STEP'
const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS'
const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS'

const initialState = {
  plan: '',
  step: 1,
  email: '',
  username: '',
  password: '',
  name: '',
  token: '',
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PLAN:
      return {
        ...state,
        plan: action.plan,
        step: 2,
      }
    case SET_STEP:
      return {
        ...state,
        step: action.step,
      }
    case SET_ACCOUNT_DETAILS:
      return {
        ...state,
        email: action.email,
        username: action.username,
        password: action.password,
        step: 3,
      }
    case SET_PAYMENT_DETAILS:
      return {
        ...state,
        name: action.name,
        email: action.email,
        username: action.username,
        password: action.password,
        token: action.token,
        step: 3,
      }
    default:
      return state
  }
}

export function setPlan(plan) {
  return {
    type: SET_PLAN,
    plan,
  }
}

export function setStep(step) {
  return {
    type: SET_STEP,
    step,
  }
}

export function setAccountDetails(email, username, password) {
  return {
    type: SET_ACCOUNT_DETAILS,
    email,
    username,
    password,
  }
}

export function setPaymentDetails(name, email, username, password, token) {
  return {
    type: SET_PAYMENT_DETAILS,
    name,
    email,
    username,
    password,
    token,
  }
}
