import React from 'react'
import PropTypes from 'prop-types'

const Container = ({ children }) => (
  <div className='impact-report-count-readout'>{children}</div>
)
const Bar = ({ count, maxCount, className }) => (
  <div className='impact-report-count-readout-bar'>
    <div
      className={`impact-report-count-readout-bar-section ${className}`}
      style={{
        width: `${
          maxCount === 0 ? '0' : Math.min(100.0, (count / maxCount) * 100.0)
        }%`,
      }}
    />
  </div>
)
const Label = ({ text, altText, isWide }) => {
  let classes = ['impact-report-count-readout-label']
  if (altText && altText.length > 0) {
    classes.push('impact-report-count-readout-label-hoverable')
  }
  if (isWide) {
    classes.push('impact-report-count-readout-label-wide')
  }
  return (
    <div title={altText} className={classes.join(' ')}>
      <span>{text}</span>
    </div>
  )
}

function CountReadout(props) {
  const { count, maxCount, includeMaxInLabel, className, altText } = props
  const labelText = includeMaxInLabel ? `${count} of ${maxCount}` : count
  return (
    <Container>
      <Label text={labelText} altText={altText} isWide={includeMaxInLabel} />
      <Bar count={count} maxCount={maxCount} className={className} />
    </Container>
  )
}
CountReadout.propTypes = {
  count: PropTypes.number.isRequired,
  maxCount: PropTypes.number.isRequired,
  includeMaxInLabel: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  altText: PropTypes.string,
}

export default CountReadout
