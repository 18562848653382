// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'

// get plans
const GETTING_PLANS = 'plan/GETTING_PLANS'
const RECEIVED_PLANS = 'plan/RECEIVED_PLANS'

// add plan
const ADDING_PLAN = 'plan/ADDING_PLAN'
const ADDED_PLAN = 'plan/ADDED_PLAN'

// get plan by uuid
const GETTING_PLAN_BY_UUID = 'plan/GETTING_PLAN_BY_UUID'
const RECEIVED_PLAN_BY_UUID = 'plan/RECEIVED_PLAN_BY_UUID'

// update plan by uuid
const UPDATING_PLAN_BY_UUID = 'plan/UPDATING_PLAN_BY_UUID'
const UPDATED_PLAN_BY_UUID = 'plan/UPDATED_PLAN_BY_UUID'

// delete plan by uuid
const DELETING_PLAN_BY_UUID = 'plan/DELETING_PLAN_BY_UUID'
const DELETED_PLAN_BY_UUID = 'plan/DELETED_PLAN_BY_UUID'
const DELETED_PLAN_BY_UUID_ERROR = 'plan/DELETED_PLAN_BY_UUID_ERROR'

// add plan group
const ADDING_PLAN_GROUP = 'plan/ADDING_PLAN_GROUP'
const ADDED_PLAN_GROUP = 'plan/ADDED_PLAN_GROUP'

// get plan group by uuid
const GETTING_PLAN_GROUP_BY_UUID = 'plan/GETTING_PLAN_GROUP_BY_UUID'
const RECEIVED_PLAN_GROUP_BY_UUID = 'plan/RECEIVED_PLAN_GROUP_BY_UUID'

// update plan group
const UPDATING_PLAN_GROUP = 'plan/UPDATING_PLAN_GROUP'
const UPDATED_PLAN_GROUP = 'plan/UPDATED_PLAN_GROUP'

// delete plan group
const DELETING_PLAN_GROUP = 'plan/DELETING_PLAN_GROUP'
const DELETED_PLAN_GROUP = 'plan/DELETED_PLAN_GROUP'
const DELETED_PLAN_GROUP_ERROR = 'plan/DELETED_PLAN_GROUP_ERROR'

// users by plan
const SET_LOADING_USERS_PLAN = 'plan/SET_LOADING_USERS_PLAN'
const GETTING_USERS_PLAN = 'plan/GETTING_USERS_PLAN'

const USER_PLAN_UPDATED = 'plan/USER_PLAN_UPDATED'

const SET_SELECTED_PHASE_DETAIL = 'plan/SET_SELECTED_PHASE_DETAIL'
const CLEAR_SELECTED_PHASE_DETAIL = 'plan/CLEAR_SELECTED_PHASE_DETAIL'

const SET_SELECTED_HACKER_CUSTOM_CONTENT =
  'content/SET_SELECTED_HACKER_CUSTOM_CONTENT'
const SET_SELECTED_HACKER_CUSTOM_CONTENT_LOADER =
  'content/SET_SELECTED_HACKER_CUUSTOM_CONTENT_LOADER'

const SET_CUSTOM_CONTENT_HACKERS = 'content/SET_CUSTOM_CONTENT_HACKERS'
const SET_CUSTOM_CONTENT_HACKERS_LOADER =
  'content/SET_CUSTOM_CONTENT_HACKERS_LOADER'

const RECEIVED_CUSTOM_CONTENT = 'content/RECEIVED_CUSTOM_CONTENT'
const SET_LOADING_AUTOMATED_PLAN = 'plan/SET_LOADING_AUTOMATED_PLAN'

const initialState = {
  selectedPhaseDetail: 'loading',
  loadingAutomatedPlan: false,

  // get plans
  loadingPlans: false,
  hasLoadedPlans: false,
  plans: {},

  // add plan
  addingPlan: false,

  // get plan by uuid
  loadingPlanByUUID: false,
  planMap: {},
  customContentDetail: {
    challenges: [],
    courses: [],
    code_reviews: [],
  },
  // update plan by uuid
  updatingPlanByUUID: false,

  // delete plan by uuid
  deletingPlanById: false,
  deletingPlanByIdError: '',

  // add plan group
  addingPlanGroup: false,
  currentPlanGroup: {
    uuid: null,
    title: null,
    description: null,
    start_date: null,
    end_date: null,
    content: [],
  },

  // get plan group by uuid
  gettingPlanGroupByUUID: false,

  // update plan group
  updatingingPlanGroup: false,

  // delete plan group
  deletingPlanGroup: false,
  deletingPlanGroupError: '',

  listOfPlansAndGroups: {},

  // users by plan
  isLoadingUsersPlan: true,
  usersPlan: [],
  totalHackersOnPlan: 0,
  perPage: 10000,
  offsetHackersOnPlan: 0,
  orderHackersOnPlanBy: 'email',
  filterHackersOnPlan: '',
  orderDirectionHackersOnPlan: 'asc',

  customContentHackers: [],
  customContentHackersLoader: true,

  selectedHackerCustomContent: {},
  selectedHackerCustomContentLoader: true,

  clonePlanList: [],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SELECTED_PHASE_DETAIL:
      return {
        ...state,
        selectedPhaseDetail: action.selectedPhaseDetail,
      }
    case CLEAR_SELECTED_PHASE_DETAIL:
      return {
        ...state,
        selectedPhaseDetail: 'loading',
      }
    case RECEIVED_CUSTOM_CONTENT:
      return {
        ...state,
        customContentDetail: action.customContentDetail,
      }
    // get plans
    case GETTING_PLANS:
      return {
        ...state,
        loadingPlans: true,
      }
    case RECEIVED_PLANS:
      return {
        ...state,
        loadingPlans: false,
        hasLoadedPlans: true,
        plans: action.plans,
        clonePlanList: Object.keys(action.plans).reduce((prev, key) => {
          if (key === 'organization_plans') {
            action.plans[key].forEach((p) => {
              if (p.is_custom_plan) {
                prev.push(p)
              }
            })
          }
          if (key === 'teams') {
            action.plans[key].forEach((team) => {
              team.plans.forEach((p) => {
                if (p.is_custom_plan) {
                  prev.push({
                    ...p,
                    team,
                  })
                }
              })
            })
          }
          return prev
        }, []),
      }
    // add plan
    case ADDING_PLAN:
      return {
        ...state,
        addingPlan: true,
      }
    case ADDED_PLAN:
      return {
        ...state,
        addingPlan: false,
        uuid: action.uuid,
        title: action.title,
        description: action.description,
        default_plan: action.default_plan,
        send_reminders: action.send_reminders,
      }
    case SET_LOADING_AUTOMATED_PLAN:
      return {
        ...state,
        loadingAutomatedPlan: action.loadingAutomatedPlan,
      }
    // get plan by uuid
    case GETTING_PLAN_BY_UUID:
      return {
        ...state,
        loadingPlanByUUID: true,
      }
    case RECEIVED_PLAN_BY_UUID:
      return {
        ...state,
        loadingPlanByUUID: false,
        planMap: {
          ...state.planMap,
          [action.uuid]: {
            uuid: action.uuid,
            organization_uuid: action.organization_uuid,
            title: action.title,
            description: action.description,
            default_plan: action.default_plan,
            send_reminders: action.send_reminders,
            groups: action.groups,
            is_time_based: action.is_time_based,
            team_uuid: action.team_uuid,
            team_name: action.team_name,
            mainEngines: action.mainEngines,
          },
        },
      }

    // update plan by uuid
    case UPDATING_PLAN_BY_UUID:
      return {
        ...state,
        updatingPlanByUUID: true,
      }
    case UPDATED_PLAN_BY_UUID:
      return {
        ...state,
        updatingPlanByUUID: false,
        planMap: {
          ...state.planMap,
          [action.uuid]: {
            uuid: action.uuid,
            title: action.title,
            description: action.description,
            default_plan: action.default_plan,
            send_reminders: action.send_reminders,
            groups: action.groups,
          },
        },
      }

    // delete plan by uuid
    case DELETING_PLAN_BY_UUID:
      return {
        ...state,
        deletingPlanById: true,
        deletingPlanByIdError: '',
      }
    case DELETED_PLAN_BY_UUID:
      return {
        ...state,
        deletingPlanById: false,
        planMap: {}, // TODO: only have it delete the current plan from the map
      }
    case DELETED_PLAN_BY_UUID_ERROR:
      return {
        ...state,
        deletingPlanById: false,
        deletingPlanByIdError: action.error,
      }

    // add plan group
    case ADDING_PLAN_GROUP:
      return {
        ...state,
        addingPlanGroup: true,
      }
    case ADDED_PLAN_GROUP:
      return {
        ...state,
        addingPlanGroup: false,
        currentPlanGroup: {
          uuid: action.uuid,
          title: action.title,
          description: action.description,
          start_date: action.start_date,
          end_date: action.end_date,
          content: action.content,
        },
      }

    // get plan group by uuid
    case GETTING_PLAN_GROUP_BY_UUID:
      return {
        ...state,
        gettingPlanGroupByUUID: true,
      }
    case RECEIVED_PLAN_GROUP_BY_UUID:
      return {
        ...state,
        gettingPlanGroupByUUID: false,
        currentPlanGroup: {
          uuid: action.uuid,
          title: action.title,
          description: action.description,
          start_date: action.start_date,
          end_date: action.end_date,
          content: action.content,
          duration: action.duration,
          mainEngines: action.mainEngines,
        },
      }

    // update plan group
    case UPDATING_PLAN_GROUP:
      return {
        ...state,
        addingPlanGroup: true,
      }
    case UPDATED_PLAN_GROUP:
      return {
        ...state,
        addingPlanGroup: false,
        currentPlanGroup: {
          uuid: action.uuid,
          title: action.title,
          description: action.description,
          start_date: action.start_date,
          end_date: action.end_date,
          content: action.content,
          duration: action.duration,
        },
      }

    // delete plan group
    case DELETING_PLAN_GROUP:
      return {
        ...state,
        deletingPlanGroup: true,
        deletingPlanGroupError: '',
      }
    case DELETED_PLAN_GROUP:
      return {
        ...state,
        deletingPlanGroup: false,
      }
    case DELETED_PLAN_GROUP_ERROR:
      return {
        ...state,
        deletingPlanGroupError: action.error,
      }
    // plan user list
    case GETTING_USERS_PLAN:
      return {
        ...state,
        usersPlan: action.usersPlan,
        isLoadingUsersPlan: false,
      }
    case USER_PLAN_UPDATED:
      return {
        ...state,
        usersPlan: state.usersPlan.map((user) => {
          if (user.uuid === action.hackerUUID) {
            return {
              ...user,
              plan_title: action.newPlanTitle,
              plan_uuid: action.newPlanUUID,
              deletedFromPlan: action.deletedFromPlan,
            }
          }
          return user
        }),
      }
    // Custom content plan
    case SET_CUSTOM_CONTENT_HACKERS_LOADER:
      return {
        ...state,
        customContentHackersLoader: action.customContentHackersLoader,
      }
    case SET_SELECTED_HACKER_CUSTOM_CONTENT_LOADER:
      return {
        ...state,
        selectedHackerCustomContentLoader:
          action.selectedHackerCustomContentLoader,
      }
    case SET_CUSTOM_CONTENT_HACKERS:
      return {
        ...state,
        customContentHackers: action.customContentHackers,
        customContentHackersLoader: false,
      }
    case SET_SELECTED_HACKER_CUSTOM_CONTENT:
      return {
        ...state,
        selectedHackerCustomContent: action.selectedHackerCustomContent,
        selectedHackerCustomContentLoader: false,
      }
    default:
      return state
  }
}

export const clearPhaseDetailModal = () => ({
  type: CLEAR_SELECTED_PHASE_DETAIL,
})

export function fetchPhaseDetailModalData(
  organizationId,
  teamId,
  planId,
  phaseId
) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/teams/${teamId}/plan/${planId}/phase/${phaseId}`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_SELECTED_PHASE_DETAIL,
          selectedPhaseDetail: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getPlans(organizationId, noLoader = false, callback = false) {
  return (dispatch) => {
    if (!noLoader) {
      dispatch({
        type: GETTING_PLANS,
      })
    }

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan`,
      withAuthToken: true,
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_PLANS,
          plans: response.data,
        })
        if (typeof callback === 'function') {
          callback(response.data)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function addPlan(
  organizationId,
  title,
  description,
  defaultPlan,
  sendReminders,
  planType,
  teamUUID,
  planUUID,
  teamName = null,
  overrideMustPassCode = false,
  teamMustPassCode = false
) {
  return (dispatch) => {
    dispatch({
      type: ADDING_PLAN,
    })

    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan`,
      withAuthToken: true,
      data: {
        title,
        description,
        default_plan: defaultPlan,
        send_reminders: sendReminders,
        plan_type: planType || 'date',
        team_uuid: teamUUID,
        plan_uuid: planUUID,
        team_name: teamName,
        override_must_pass_code: overrideMustPassCode,
        team_must_pass_code: teamMustPassCode,
      },
    })
      .then((response) => {
        dispatch({
          type: ADDED_PLAN,
          uuid: response.data.uuid,
          title: response.data.title,
          description: response.data.description,
          default_plan: response.data.default_plan,
          send_reminders: response.data.send_reminders,
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error({ error })
      })
  }
}

export function getPlanById(organizationId, planId) {
  return (dispatch) => {
    dispatch({
      type: GETTING_PLAN_BY_UUID,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan/${planId}`,
      withAuthToken: true,
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_PLAN_BY_UUID,
          uuid: response.data.uuid,
          organization_uuid: response.data.organization_uuid,
          title: response.data.title,
          description: response.data.description,
          default_plan: response.data.default_plan,
          send_reminders: response.data.send_reminders,
          groups: response.data.groups,
          is_time_based: response.data.is_time_based,
          team_uuid: response.data.team_uuid,
          team_name: response.data.team_name,
          mainEngines: response.data.main_engines || {},
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function changeUserPhase(
  organizationId,
  hackerId,
  planId,
  planGroupId,
  teamId = null,
  callback = null
) {
  return (dispatch) => {
    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerId}/plan`,
      withAuthToken: true,
      data: {
        plan_uuid: planId,
        plan_group_uuid: planGroupId,
        team_uuid: teamId,
      },
    })
      .then(({ data }) => {
        if (data.success === true) {
          if (callback && typeof callback === 'function') {
            callback()
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function updateRecommendationEngineSettings(
  data,
  organizationUUID,
  teamUUID = null,
  callback = false
) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_AUTOMATED_PLAN,
      loadingAutomatedPlan: true,
    })
    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/organization/${organizationUUID}/enable-recommendation`,
      withAuthToken: true,
      data: {
        ...data,
        team_uuid: teamUUID,
      },
    }).then(() => {
      if (typeof callback === 'function') {
        callback()
      }
    })
  }
}

export function updateRequiredContentRecommendationSettings(
  requiredContent,
  organizationUUID,
  teamUUID = null,
  callback = false
) {
  return (dispatch) => {
    // dispatch({
    //   type: SET_LOADING_AUTOMATED_PLAN,
    //   loadingAutomatedPlan: true,
    // })
    return api({
      method: 'put',
      url: `${settings.urls.hacker}/organization/${organizationUUID}/enable-recommendation`,
      withAuthToken: true,
      data: {
        required_content: requiredContent,
        team_uuid: teamUUID,
      },
    }).then(() => {
      if (typeof callback === 'function') {
        callback()
      }
    })
  }
}
export function activateAutomatedPlan(
  organizationUUID,
  teamUUID = null,
  callback
) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_AUTOMATED_PLAN,
      loadingAutomatedPlan: true,
    })
    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organization/${organizationUUID}/enable-recommendation`,
      withAuthToken: true,
      data: {
        team_uuid: teamUUID,
      },
    }).then((response) => {
      dispatch({
        type: SET_LOADING_AUTOMATED_PLAN,
        loadingAutomatedPlan: false,
      })
      dispatch(getPlans(organizationUUID, true))
      if (typeof callback === 'function') {
        callback()
      }
    })
  }
}

export function updatePlanById(
  organizationId,
  planId,
  title,
  description,
  defaultPlan,
  sendReminders,
  teamUUID,
  succcessCallback,
  errorCallback,
  noTraining = null,
  oldPlanType = null,
  newPlanType = null,
  typeData = {}
) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_PLAN_BY_UUID,
    })

    return api({
      method: 'put',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan/${planId}`,
      withAuthToken: true,
      data: {
        title,
        description,
        default_plan: defaultPlan,
        send_reminders: sendReminders,
        team_uuid: teamUUID,
        no_training: noTraining,
        old_type: oldPlanType,
        new_type: newPlanType,
        type_data: JSON.stringify(typeData),
      },
    })
      .then((response) => {
        if (noTraining !== null) {
          succcessCallback()
        }
        dispatch({
          type: UPDATED_PLAN_BY_UUID,
          uuid: response.data.uuid,
          title: response.data.title,
          description: response.data.description,
          send_reminders: response.data.send_reminders,
          groups: response.data.groups,
          team_uuid: teamUUID,
        })
        if (typeof succcessCallback === 'function') {
          succcessCallback()
        }
      })
      .catch((error) => {
        if (typeof errorCallback === 'function') {
          errorCallback(error.response.data.error)
        }
        console.error(error)
      })
  }
}

export function deletePlanById(organizationId, planId) {
  return (dispatch) => {
    dispatch({
      type: DELETING_PLAN_BY_UUID,
    })

    return api({
      method: 'delete',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan/${planId}`,
      withAuthToken: true,
    })
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: DELETED_PLAN_BY_UUID_ERROR,
            error: response.data.error,
          })
          return false
        }
        dispatch({
          type: DELETED_PLAN_BY_UUID,
          uuid: planId,
        })
        return true
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: DELETED_PLAN_BY_UUID_ERROR,
          error: 'There was an error deleting this plan.',
        })
      })
  }
}

export function addPlanGroup(
  organizationId,
  planId,
  title,
  description,
  startDate,
  endDate,
  contentIds,
  duration
) {
  return (dispatch) => {
    dispatch({
      type: ADDING_PLAN_GROUP,
    })

    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan/${planId}/group`,
      withAuthToken: true,
      data: {
        title,
        description,
        start_date: startDate,
        end_date: endDate,
        content_uuids: contentIds,
        duration: duration || null,
      },
    })
      .then((response) => {
        dispatch({
          type: ADDED_PLAN_GROUP,
          uuid: response.data.uuid,
          title: response.data.title,
          description: response.data.description,
          start_date: response.data.start_date,
          end_date: response.data.end_date,
          content: response.data.content,
          duration: response.data.duration,
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getPlanGroupById(organizationId, planId, phaseId) {
  return (dispatch) => {
    dispatch({
      type: GETTING_PLAN_GROUP_BY_UUID,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan/${planId}/group/${phaseId}`,
      withAuthToken: true,
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_PLAN_GROUP_BY_UUID,
          uuid: response.data.uuid,
          title: response.data.title,
          description: response.data.description,
          start_date: response.data.start_date,
          end_date: response.data.end_date,
          content: response.data.content,
          duration: response.data.duration,
          mainEngines: response.data.main_engines || {},
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function updatePlanGroup(
  organizationId,
  planId,
  groupId,
  title,
  description,
  startDate,
  endDate,
  contentIds,
  duration
) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_PLAN_GROUP,
    })

    return api({
      method: 'put',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan/${planId}/group/${groupId}`,
      withAuthToken: true,
      data: {
        title,
        description,
        start_date: startDate,
        end_date: endDate,
        content_uuids: contentIds,
        duration,
      },
    })
      .then((response) => {
        dispatch({
          type: UPDATED_PLAN_GROUP,
          uuid: response.data.uuid,
          title: response.data.title,
          description: response.data.description,
          start_date: response.data.start_date,
          end_date: response.data.end_date,
          content: response.data.content,
          duration: response.data.duration,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function setCustomPlansNotDefault(organizationId) {
  return (dispatch) => {
    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        if (data.success) {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function deletePlanGroup(organizationId, planId, groupId) {
  return (dispatch) => {
    dispatch({
      type: DELETING_PLAN_GROUP,
    })

    return api({
      method: 'delete',
      url: `${settings.urls.hacker}/organizations/${organizationId}/plan/${planId}/group/${groupId}`,
      withAuthToken: true,
    })
      .then(() => {
        dispatch({
          type: DELETED_PLAN_GROUP,
        })
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: DELETED_PLAN_GROUP_ERROR,
          error: error.data.error,
        })
      })
  }
}

export function getOrganizationCustomAssignContent(
  organizationUUID,
  disableLoader = false
) {
  return (dispatch) => {
    if (!disableLoader) {
      dispatch({
        type: SET_CUSTOM_CONTENT_HACKERS_LOADER,
        customContentHackersLoader: true,
      })
    }
    api({
      method: 'get',
      url: `${settings.urls.hacker}/organization/${organizationUUID}/custom_content`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_CUSTOM_CONTENT_HACKERS,
          customContentHackers: data,
        })
      })
      .catch((e) => {
        console.error({ e })
      })
  }
}

export function getCustomContent(
  organizationUUID,
  hackerUUID,
  disableLoader = false
) {
  return (dispatch) => {
    if (!hackerUUID) return
    if (!disableLoader) {
      dispatch({
        type: SET_SELECTED_HACKER_CUSTOM_CONTENT_LOADER,
        selectedHackerCustomContentLoader: true,
      })
    }
    api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/hackers/${hackerUUID}/custom_content`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_SELECTED_HACKER_CUSTOM_CONTENT,
          selectedHackerCustomContent: data,
        })
      })
      .catch((e) => {
        console.error(e)
      })
  }
}

export function getCustomContentDetail(organizationUUID, hackerUUID, callback) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/content`,
      withAuthToken: true,
      params: {
        include_all_organization_licenses: true,
        organization_uuid: organizationUUID,
        hacker_uuid: hackerUUID,
      },
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_CUSTOM_CONTENT,
          customContentDetail: response.data,
        })
        if (callback) {
          callback()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function saveCustomContent(
  organizationUUID,
  hackerUUID,
  contentUUID,
  completeBy,
  callback = false
) {
  return (dispatch) => {
    api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/hackers/${hackerUUID}/custom_content`,
      withAuthToken: true,
      data: {
        content_uuid: contentUUID,
        complete_by: completeBy === '' ? null : completeBy,
      },
    })
      .then(({ data }) => {
        dispatch(getCustomContent(organizationUUID, hackerUUID, true))
        if (callback) callback()
      })
      .catch((e) => {
        console.error(e)
      })
  }
}

export function deleteCustomContent(
  organizationUUID,
  hackerUUID,
  contentUUID,
  callback = false
) {
  return (dispatch) => {
    api({
      method: 'delete',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/hackers/${hackerUUID}/custom_content`,
      withAuthToken: true,
      data: {
        content_uuid: contentUUID,
      },
    })
      .then(() => {
        dispatch(getCustomContent(organizationUUID, hackerUUID, true))
        if (callback) callback()
      })
      .catch((e) => {
        console.error(e)
      })
  }
}

export function setNoTraining(organizationUUID, teamUUID = null, callback) {
  return (dispatch) => {
    api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/plan`,
      withAuthToken: true,
      data: {
        team_uuid: teamUUID,
        no_training: true,
      },
    })
      .then(() => {
        dispatch(getPlans(organizationUUID, true))
        if (typeof callback === 'function') {
          callback()
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }
}

export function setDefaultPlan(
  planUUID,
  organizationUUID,
  teamUUID = null,
  callback
) {
  return (dispatch) => {
    api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/plan`,
      withAuthToken: true,
      data: {
        team_uuid: teamUUID,
        new_default_plan_uuid: planUUID,
      },
    })
      .then(() => {
        dispatch(getPlans(organizationUUID, true))
        if (typeof callback === 'function') {
          callback()
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }
}

export function setOrganizationDefaultPlan(
  organizationUUID,
  teamUUID = null,
  callback,
  data = false
) {
  return (dispatch) => {
    api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/plan`,
      withAuthToken: true,
      data: data || {
        team_uuid: teamUUID,
        use_organization_default: true,
      },
    })
      .then(() => {
        dispatch(getPlans(organizationUUID, true))
        if (typeof callback === 'function') {
          callback()
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }
}

export function updateRequiredTrainingContent(
  organizationUUID,
  contentUUID,
  teamUUID,
  noLimit,
  days,
  date,
  callback
) {
  return (dispatch) => {
    api({
      method: 'patch',
      url: `${settings.urls.hacker}/organization/${organizationUUID}/content/${contentUUID}/recommendation`,
      withAuthToken: true,
      data: {
        team_uuid: teamUUID,
        no_limit: noLimit,
        days_to_complete: days,
        complete_by: date,
      },
    })
      .then(() => {
        if (typeof callback === 'function') {
          callback()
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export function resetPlan(organizationUUID, planUUID, callback) {
  return (dispatch) => {
    api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/plan/${planUUID}`,
      withAuthToken: true,
    })
      .then(() => {
        if (typeof callback === 'function') {
          callback()
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
}
