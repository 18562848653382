import React from 'react'
import PropTypes from 'prop-types'

import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import { Icon } from 'app/views/components/GustavoIcon'

import { HBox, VBox, Box } from 'app/views/core/Box'

function getStatusHeadline(hasPassed) {
  if (hasPassed) {
    return 'Challenge Complete!'
  }
  return 'Challenge Incomplete'
}

function getStatusDescription(hasPassed, timeString) {
  if (hasPassed) {
    return `Completed ${getLocalDateFromUTC(timeString).format('MMMM D, YYYY')}`
  }
  return 'Save and submit code for testing.'
}

const StatusIcon = ({ hasPassed }) => {
  if (hasPassed)
    return <Icon name='checkbox-circle' className='text-primary' ratio={2} />
  return <Icon name='error-warning' className='text-danger' ratio={2} />
}

function CodingChallengeCompletionStatus(props) {
  const { passedAt } = props
  const hasPassed = !!passedAt
  const className = hasPassed ? 'text-primary' : 'text-danger'
  return (
    <HBox style={{ margin: '0 auto 30px auto', alignItems: 'center' }}>
      <StatusIcon hasPassed={hasPassed} />
      <VBox style={{ marginLeft: 10 }}>
        <Box className={className}>{getStatusHeadline(hasPassed)}</Box>
        <Box className='text-sm'>
          {getStatusDescription(hasPassed, passedAt)}
        </Box>
      </VBox>
    </HBox>
  )
}
CodingChallengeCompletionStatus.propTypes = {
  passedAt: PropTypes.string,
}

export default CodingChallengeCompletionStatus
