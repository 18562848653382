import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

// Components
import ContentCard from 'app/views/components/Cards/ContentCard'

// Redux
import { getLessonById } from 'app/state/modules/content'

// Utils
import analytics from 'app/views/utils/analytics'

class LessonDetail extends React.Component {
  componentDidMount() {
    this.props.getLessonById(this.props.match.params.id)

    analytics.page('page-lesson-detail')
  }

  render() {
    const lesson = this.props.lessonDetails[this.props.match.params.id]
    if (
      !Object.keys(this.props.lessonDetails).includes(
        this.props.match.params.id
      )
    ) {
      return (
        <div className='uk-section text-center'>
          <div className='uk-container'>
            <div data-uk-spinner='' />
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className='uk-section'>
          <div className='uk-container'>
            <h1 className='text-center'>{lesson.title}</h1>

            {lesson.description && (
              <div className='text-2xl text-center'>
                {lesson.description}
              </div>
            )}
          </div>

          {lesson.objectives.length > 0 && (
            <div className='uk-section uk-section-small'>
              <div className='uk-container uk-container-medium'>
                <div className='uk-section uk-section-muted uk-padding'>
                  <h2 className='text-center'>Objectives</h2>
                  <ul className='uk-list uk-list-large uk-list-bullet'>
                    {lesson.objectives.map((objective, i) => (
                      <li key={i}>{objective}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

          <div className='uk-container'>
            <div
              className='uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin-top uk-margin-remove-left'
              data-uk-grid
            >
              {lesson.content
                .sort((a, b) => a.sort_order - b.sort_order)
                .map((content) => (
                  <ContentCard
                    isActive={content.is_active_for_user}
                    key={content.uuid}
                    title={content.title}
                    description={content.description}
                    color='blue'
                    requiresSubscription={content.requires_subscription}
                    license={this.props.license}
                    maxProgress={content.max_progress}
                    mustPassCodingExercise={this.props.mustPassCodingExercise}
                    hasTest={content.has_test}
                    contentId={content.uuid}
                    contentType={content.content_type}
                    completedAt={content.completed_at}
                    passedAt={content.passed_at}
                    metadata={content.metadata}
                    steps={content.step_count}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // content
  lessonDetails: state.content.lessonDetails,

  // hacker
  license: state.hacker.license,
  mustPassCodingExercise: state.hacker.mustPassCodingExercise,
})
const mapDispatchToProps = (dispatch) => ({
  getLessonById: (lessonId) => dispatch(getLessonById(lessonId)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LessonDetail)
)
