const prefix = 'tty'
const CLEAR = `${prefix}/CLEAR`
const PUSH_LINE = `${prefix}/PUSH_LINE`
const PUSH_MULTILINE_OUTPUT = `${prefix}/PUSH_MULTILINE_OUTPUT`
const ACKNOWLEDGE_LINES = `${prefix}/ACKNOWLEDGE_LINES`

const initialState = {
  lines: [],
  numLinesAcknowledged: 0,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR:
      return {
        ...state,
        lines: [],
        numLinesAcknowledged: 0,
      }
    case PUSH_LINE:
      const newLine = {
        timestamp: action.timestamp,
        text: action.text,
      }
      return {
        ...state,
        lines: state.lines.concat([newLine]),
      }
    case PUSH_MULTILINE_OUTPUT:
      const newLines = action.text.split(/\r?\n/).map((lineText) => ({
        timestamp: action.timestamp,
        text: lineText,
      }))
      return {
        ...state,
        lines: state.lines.concat(newLines),
      }
    case ACKNOWLEDGE_LINES:
      return {
        ...state,
        numLinesAcknowledged: state.lines.length,
      }
    default:
      return state
  }
}

export function clear() {
  return {
    type: CLEAR,
  }
}

export function pushLine(text, timestamp) {
  return {
    type: PUSH_LINE,
    timestamp: timestamp || Date.now(),
    text,
  }
}

export function pushMultilineOutput(text) {
  return {
    type: PUSH_MULTILINE_OUTPUT,
    timestamp: Date.now(),
    text,
  }
}

export function acknowledgeLines(text) {
  return {
    type: ACKNOWLEDGE_LINES,
  }
}
