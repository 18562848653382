import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import ContentPingBeacon from '../common/ContentPingBeacon'
import StepDotnav from '../common/StepDotnav'
import StepNavigationButtons from '../common/StepNavigationButtons'
import { useCachedStepIndex } from '../common/util'
import { useServiceShimLessonState } from '../../utils/apiServiceShim'

import ArticleHeader from './ArticleHeader'
import ArticleBody from './ArticleBody'
import LessonCompletionModal from '../LessonUI/LessonCompletionModal'

import { connect } from 'react-redux'
import * as progress from 'app/state/modules/progress'

function ArticleUI(props) {
  // Get basic details about the article and initialize progress tracking
  const { contentId, contentDetails, setupProgress, teardownProgress } = props
  useEffect(() => {
    // Update the state of the progress module so that all trackProgress calls made
    // during the lifetime of this component will refer to this contentId
    setupProgress(contentId)
    return teardownProgress
  }, [])

  // On mount, notify the API-to-service shim that we're now presenting the user with
  // this lesson, and clear that state on unmount
  useServiceShimLessonState(contentId, contentDetails['lesson_key'] || '')

  // Keep track of which step we can display and how the user can navigate
  const steps = contentDetails.steps
  const [stepIndex, setStepIndex] = useCachedStepIndex(contentId, steps.length)
  const canNavigateBack = steps.length > 0 && stepIndex > 0
  const canNavigateForward = steps.length > 0 && stepIndex < steps.length - 1
  const step = steps[stepIndex]

  // Whenever the user browses to a new step, track that change and scroll up
  const scrollRef = useRef()
  const { trackProgress } = props
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0
      scrollRef.current.scrollTop = 0
    }
    trackProgress(stepIndex)
  }, [stepIndex])

  // Use a flag to track whether the user has finished the lesson, in which case we
  // should display a completion modal
  const [hasFinished, setHasFinished] = useState(false)

  return (
    <>
      {hasFinished && (
        <LessonCompletionModal
          contentId={contentId}
          hasTests={false}
          onClose={() => setHasFinished(false)}
          flagLessonCompleted={() => trackProgress(steps.length)}
        />
      )}
      <ContentPingBeacon contentId={contentId} />
      <ArticleHeader title={contentDetails.title} />
      <ArticleBody ref={scrollRef}>
        <div className='flex justify-center items-center uk-padding'>
          <StepDotnav
            currentStepIndex={stepIndex}
            numSteps={steps.length}
            onSelectStep={setStepIndex}
          />
        </div>
        <div id='skill-steps'>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} linkTarget='_blank'>
            {step.markdown}
          </ReactMarkdown>
          <StepNavigationButtons
            canNavigateBack={canNavigateBack}
            canNavigateForward={canNavigateForward}
            canEverFinish
            isReadyToFinish
            onNavigateBack={() => setStepIndex((step) => step - 1)}
            onNavigateForward={() => setStepIndex((step) => step + 1)}
            onAttemptFinish={() => setHasFinished(true)}
          />
        </div>
      </ArticleBody>
    </>
  )
}
ArticleUI.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentDetails: PropTypes.shape({
    title: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  setupProgress: PropTypes.func.isRequired,
  teardownProgress: PropTypes.func.isRequired,
  trackProgress: PropTypes.func.isRequired,
}

export default connect(null, (dispatch) => ({
  setupProgress: (contentId) => dispatch(progress.setup(contentId, null)),
  teardownProgress: () => dispatch(progress.teardown()),
  trackProgress: (stepIndex) => dispatch(progress.track(stepIndex)),
}))(ArticleUI)
