import settings from 'settings'
import { api } from 'app/views/utils/api'

const LOAD_COMPLETED_REPORT = 'reports/LOAD_COMPLETED_REPORT'
const LOAD_INCOMPLETED_REPORT = 'reports/LOAD_INCOMPLETED_REPORT'
const LOAD_PROFICIENCY_REPORT = 'reports/LOAD_PROFICIENCY_REPORT'
const LOAD_CHALLENGES_REPORT = 'reports/LOAD_CHALLENGES_REPORT'
const LOAD_CODE_REVIEW_REPORT = 'reports/LOAD_CODE_REVIEW_REPORT'
const SET_COMPLETED_REPORT = 'reports/SET_COMPLETED_REPORT'
const SET_PAST_DUE_REPORT = 'reports/SET_PAST_DUE_REPORT'
const SET_CHALLENGES_REPORT = 'reports/SET_CHALLENGES_REPORT'
const SET_INCOMPLETED_REPORT = 'reports/SET_INCOMPLETED_REPORT'
const SET_PROFICIENCY_REPORT = 'reports/SET_PROFICIENCY_REPORT'
const SET_CODE_REVIEW_REPORT = 'reports/SET_CODE_REVIEW_REPORT'
const SET_USER_REPORTS = 'reports/SET_USER_REPORTS'
const SET_NOTIFICATIONS_REPORT = 'reports/SET_NOTIFICATIONS_REPORT'
const LOAD_NOTIFICATIONS_REPORT = 'reports/LOAD_NOTIFICATIONS_REPORT'

const initialState = {
  completedReport: 'loading',
  totalCompletedReport: null,
  incompletedReport: 'loading',
  proficiencyReport: 'loading',
  challengesReport: 'loading',
  totalChallengesReport: null,
  codeReviewReport: 'loading',
  pastDueReport: 'loading',
  usersReport: 'loading',
  notificationsReport: 'loading',
  totalUsersReport: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NOTIFICATIONS_REPORT:
      return {
        ...state,
        notificationsReport: action.notificationsReport,
      }
    case SET_USER_REPORTS:
      return {
        ...state,
        usersReport: action.usersReport,
        totalUsersReport: action.totalUsersReport,
      }
    case SET_PAST_DUE_REPORT:
      return {
        ...state,
        pastDueReport: action.pastDueReport,
      }
    case SET_CHALLENGES_REPORT:
      return {
        ...state,
        challengesReport: action.challengesReport,
        totalChallengesReport: action.totalChallengesReport,
      }
    case SET_CODE_REVIEW_REPORT:
      return {
        ...state,
        codeReviewReport: action.codeReviewReport,
      }
    case SET_PROFICIENCY_REPORT:
      return {
        ...state,
        proficiencyReport: action.proficiencyReport,
      }
    case SET_COMPLETED_REPORT:
      return {
        ...state,
        completedReport: action.completedReport,
        totalCompletedReport: action.totalCompletedReport,
      }
    case SET_INCOMPLETED_REPORT:
      return {
        ...state,
        incompletedReport: action.incompletedReport,
      }
    case LOAD_COMPLETED_REPORT:
      return {
        ...state,
        completedReport: 'loading',
      }
    case LOAD_NOTIFICATIONS_REPORT:
      return {
        ...state,
        notificationsReport: 'loading',
      }
    case LOAD_CODE_REVIEW_REPORT:
      return {
        ...state,
        codeReviewReport: 'loading',
      }
    case LOAD_CHALLENGES_REPORT:
      return {
        ...state,
        challengesReport: 'loading',
      }
    case LOAD_INCOMPLETED_REPORT:
      return {
        ...state,
        incompletedReport: 'loading',
      }
    case LOAD_PROFICIENCY_REPORT:
      return {
        ...state,
        proficiencyReport: 'loading',
      }

    default:
      return state
  }
}

export const exportUsersReport =
  (
    disregardTrainingPlans,
    organizationUUID,
    teamUUID = undefined,
    sortBy = 'email',
    asc = true,
    pastDueOnly = false,
    days = null
  ) =>
  async (dispatch, getState) => {
    const state = getState()
    const url = teamUUID
      ? `${settings.urls.hacker}/organizations/${organizationUUID}/teams/${teamUUID}/report/user`
      : `${settings.urls.hacker}/organizations/${organizationUUID}/report/user`
    const { totalUsersReport } = state.reports
    const promises = []
    for (let i = 0; i < totalUsersReport; i += 100) {
      promises.push(
        new Promise((resolve, reject) =>
          api({
            method: 'get',
            url,
            withAuthToken: true,
            params: {
              asc: JSON.stringify(asc),
              sort_by: sortBy,
              offset: i,
              past_due_only: pastDueOnly ? 'true' : 'false',
              days,
              v: disregardTrainingPlans ? 2 : 1,
            },
          })
            .then(({ data }) => {
              resolve(data.users)
            })
            .catch((error) => {
              console.error(error)
              reject(error)
            })
        )
      )
    }
    const responses = await Promise.all(promises)
    return responses.reduce((prev, curr) => {
      const newArr = [...prev, ...curr]
      return newArr
    }, [])
  }

export const getUsersReport =
  (
    disregardTrainingPlans,
    organizationUUID,
    teamUUID = undefined,
    sortBy = 'email',
    asc = true,
    offset = 0,
    pastDueOnly = false,
    days = null,
    callback
  ) =>
  (dispatch) => {
    const url = teamUUID
      ? `${settings.urls.hacker}/organizations/${organizationUUID}/teams/${teamUUID}/report/user`
      : `${settings.urls.hacker}/organizations/${organizationUUID}/report/user`
    return api({
      method: 'get',
      url,
      withAuthToken: true,
      params: {
        asc: JSON.stringify(asc),
        sort_by: sortBy,
        offset,
        past_due_only: pastDueOnly ? 'true' : 'false',
        days,
        v: disregardTrainingPlans ? 2 : 1,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_USER_REPORTS,
          usersReport: data.users,
          totalUsersReport: data.total,
        })
        if (typeof callback === 'function') {
          callback(data)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

export function getTrainingTimeOverTimeChart(organizationUUID) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/report/training_time_chart`,
      withAuthToken: true,
    })
  }
}

export function getProficiencyChartReport(organizationUUID) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/report/mastery_chart`,
      withAuthToken: true,
    })
  }
}

export function getCategoryTimeChartReport(organizationUUID) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/report/category_time_chart`,
      withAuthToken: true,
    })
  }
}

export const exportCompletedReport =
  (
    organizationUUID,
    teamUUID = undefined,
    sortBy = 'email',
    asc = true,
    filter = null
  ) =>
  async (dispatch, getState) => {
    const state = getState()
    const url = teamUUID
      ? `${settings.urls.hacker}/organizations/${organizationUUID}/teams/${teamUUID}/report/user`
      : `${settings.urls.hacker}/organizations/${organizationUUID}/report/completed`
    const { totalCompletedReport } = state.reports
    const responses = []
    for (let i = 0; i < totalCompletedReport; i += 100) {
      // await each network request individually to prevent
      // overloading the database in the case of large customers
      let result = await api({
        method: 'get',
        url,
        withAuthToken: true,
        params: {
          asc: JSON.stringify(asc),
          sort_by: sortBy,
          offset: i,
          filter,
        },
      })
      responses.push(result.data.users)
    }

    return responses.reduce((prev, curr) => {
      const newArr = [...prev, ...curr]
      return newArr
    }, [])
  }

export function getCompletedReport(
  organizationUUID,
  teamUUID = undefined,
  sortBy = 'email',
  asc = true,
  offset = 0,
  filter = null,
  callback
) {
  return (dispatch) => {
    const url = teamUUID
      ? `${settings.urls.hacker}/organizations/${organizationUUID}/teams/${teamUUID}/report/completed`
      : `${settings.urls.hacker}/organizations/${organizationUUID}/report/completed`
    return api({
      method: 'get',
      url,
      withAuthToken: true,
      params: {
        asc: JSON.stringify(asc),
        sort_by: sortBy,
        offset,
        filter,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_COMPLETED_REPORT,
          completedReport: data.users,
          totalCompletedReport: data.total,
        })
        if (typeof callback === 'function') {
          callback(data)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getPastDueReport(organizationId, teamId = null) {
  return (dispatch) => {
    dispatch({
      type: LOAD_COMPLETED_REPORT,
    })
    const url = teamId
      ? `${settings.urls.hacker}/organizations/${organizationId}/teams/${teamId}/report/pastdue`
      : `${settings.urls.hacker}/organizations/${organizationId}/report/pastdue`
    return api({
      method: 'get',
      url,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_PAST_DUE_REPORT,
          pastDueReport: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getIncompletedReport(
  organizationId,
  teamId = null,
  page = null,
  noLoader = false
) {
  return (dispatch) => {
    if (!noLoader) {
      dispatch({
        type: LOAD_INCOMPLETED_REPORT,
      })
    }
    const url = teamId
      ? `${settings.urls.hacker}/organizations/${organizationId}/teams/${teamId}/report/incompleted`
      : `${settings.urls.hacker}/organizations/${organizationId}/report/incompleted`

    return api({
      method: 'get',
      url,
      withAuthToken: true,
      params: {
        page,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_INCOMPLETED_REPORT,
          incompletedReport: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
export function getProficiencyReport(organizationId, teamId = null) {
  return (dispatch) => {
    dispatch({
      type: LOAD_PROFICIENCY_REPORT,
    })
    const url = teamId
      ? `${settings.urls.hacker}/organizations/${organizationId}/teams/${teamId}/report/mastery`
      : `${settings.urls.hacker}/organizations/${organizationId}/report/mastery`

    return api({
      method: 'get',
      url,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_PROFICIENCY_REPORT,
          proficiencyReport: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export const exportChallengeReport =
  (
    organizationUUID,
    teamUUID = undefined,
    sortBy = 'email',
    asc = true,
    days = null
  ) =>
  async (dispatch, getState) => {
    const state = getState()
    const url = teamUUID
      ? `${settings.urls.hacker}/organizations/${organizationUUID}/teams/${teamUUID}/report/challenges`
      : `${settings.urls.hacker}/organizations/${organizationUUID}/report/challenges`
    const { totalChallengesReport } = state.reports
    const promises = []
    for (let i = 0; i < totalChallengesReport; i += 100) {
      promises.push(
        new Promise((resolve, reject) =>
          api({
            method: 'get',
            url,
            withAuthToken: true,
            params: {
              asc: JSON.stringify(asc),
              sort_by: sortBy,
              offset: i,
              days,
            },
          })
            .then(({ data }) => {
              resolve(data.users)
            })
            .catch((error) => {
              console.error(error)
              reject(error)
            })
        )
      )
    }
    const responses = await Promise.all(promises)
    return responses.reduce((prev, curr) => {
      const newArr = [...prev, ...curr]
      return newArr
    }, [])
  }

export function getChallengesReport(
  organizationUUID,
  teamUUID = undefined,
  sortBy = 'email',
  asc = true,
  offset = 0,
  days = null
) {
  return (dispatch) => {
    const url = teamUUID
      ? `${settings.urls.hacker}/organizations/${organizationUUID}/teams/${teamUUID}/report/challenges`
      : `${settings.urls.hacker}/organizations/${organizationUUID}/report/challenges`
    return api({
      method: 'get',
      url,
      withAuthToken: true,
      params: {
        sort_by: sortBy,
        asc: JSON.stringify(asc),
        offset,
        days,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_CHALLENGES_REPORT,
          challengesReport: data.users,
          totalChallengesReport: data.total,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getCodeReviewReport(organizationId, teamId = null) {
  return (dispatch) => {
    dispatch({
      type: LOAD_CODE_REVIEW_REPORT,
    })
    const url = teamId
      ? `${settings.urls.hacker}/organizations/${organizationId}/teams/${teamId}/report/code_review`
      : `${settings.urls.hacker}/organizations/${organizationId}/report/code_review`
    return api({
      method: 'get',
      url,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_CODE_REVIEW_REPORT,
          codeReviewReport: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getNotificationsReport(organizationUUID) {
  return (dispatch) => {
    const url = `${settings.urls.hacker}/organizations/${organizationUUID}/report/notifications`
    return api({
      method: 'get',
      url,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_NOTIFICATIONS_REPORT,
          notificationsReport: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
