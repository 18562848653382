import React from 'react'
import PropTypes from 'prop-types'

import { getEngineDisplayName } from 'app/views/utils/engine'

function buildOptionList(allowNone, engineValues) {
  const engineDisplayNames = engineValues.map(getEngineDisplayName)
  const zipped = engineDisplayNames.map((displayName, i) => ({
    value: engineValues[i],
    displayName,
  }))
  const head = allowNone ? [{ value: 'none', displayName: ' -- ' }] : []
  return head.concat(
    zipped.sort((a, b) => a.displayName.localeCompare(b.displayName))
  )
}

function EngineSelector(props) {
  const { allowNone, currentEngine, supportedEngines, onSelect } = props
  const currentValue = supportedEngines.includes(currentEngine)
    ? currentEngine
    : 'none'
  return (
    <select
      className='uk-select'
      style={{
        borderRadius: 4,
        fontSize: 14,
        maxHeight: 30,
      }}
      value={currentValue}
      onChange={(event) => onSelect(event.target.value)}
    >
      {buildOptionList(allowNone, supportedEngines).map(
        ({ value, displayName }) => (
          <option key={value} value={value}>
            {displayName}
          </option>
        )
      )}
    </select>
  )
}
EngineSelector.propTypes = {
  allowNone: PropTypes.bool,
  currentEngine: PropTypes.string,
  supportedEngines: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default EngineSelector
