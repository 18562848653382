import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getSimpleTeamList } from 'app/state/modules/team'

class TeamPicker extends React.Component {
  componentDidMount() {
    this.props.getSimpleTeamList(this.props.match.params.organization_uuid)
  }

  renderOptions() {
    const { teams } = this.props
    if (teams.length === 0) {
      return <option value='-'>All Teams</option>
    }
    const options = []
    options.push(
      <option value='-' key='all'>
        All Teams
      </option>
    )
    teams.forEach((t) => {
      options.push(
        <option key={t.uuid} value={t.uuid}>
          {t.name}
        </option>
      )
    })
    return options
  }

  render() {
    return (
      <div className={this.props.className}>
        <select
          onChange={this.props.onChange}
          className={this.props.classNameSelect || 'uk-select uk-form-small'}
          disabled={this.props.teams.length === 0}
          style={{
            borderRadius: 500,
            minWidth: 120,
          }}
        >
          {this.renderOptions()}
        </select>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  teams: state.team.list,
})

export default withRouter(
  connect(mapStateToProps, {
    getSimpleTeamList,
  })(TeamPicker)
)
