import { api } from 'app/views/utils/api'
import settings from 'settings'
import UIkit from 'uikit'

const SET_INTEGRATIONS = 'integrations/SET_INTEGRATIONS'
const SET_INTEGRATIONS_LOADING = 'integrations/SET_INTEGRATIONS_LOADING'
const SET_INTEGRATION_DETAIL_LOADING =
  'integrations/SET_INTEGRATION_DETAIL_LOADING'
const SET_INTEGRATION_DETAIL = 'integrations/SET_INTEGRATION_DETAIL'
const ADD_MORE = 'integrations/ADD_MORE'
const SET_LOADING_ADD_MORE = 'integrations/SET_LOADING_ADD_MORE'
const UPDATE_LAST_SCANNED_AT = 'integrations/UPDATE_LAST_SCANNED_AT'
const SET_LOADING_RUN_AGAIN = 'integrations/SET_LOADING_RUN_AGAIN'
const SET_LOADING_DELETE = 'integrations/SET_LOADING_DELETE'
const SET_APP_INTEGRATIONS = 'integrations/SET_APP_INTEGRATIONS'

const initialState = {
  appIntegrations: [],
  integrations: [],
  integrationsHash: {},
  loadingIntegrations: true,
  loadingRunAgain: false,
  loadingAddMore: false,
  loadingDelete: false,
  loadingDetail: true,
  integrationDetail: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_INTEGRATIONS:
      return {
        ...state,
        appIntegrations: action.appIntegrations,
      }
    case SET_LOADING_DELETE:
      return {
        ...state,
        loadingDelete: action.loadingDelete,
      }
    case SET_LOADING_RUN_AGAIN:
      return {
        ...state,
        integrationDetail: {
          ...state.integrationDetail,
          syncing: true,
        },
      }
    case UPDATE_LAST_SCANNED_AT:
      return {
        ...state,
        integrationDetail: {
          ...state.integrationDetail,
          last_scanned_at: action.last_scanned_at,
        },
      }
    case SET_LOADING_ADD_MORE:
      return {
        ...state,
        loadingAddMore: action.loadingAddMore,
      }
    case SET_INTEGRATION_DETAIL_LOADING:
      return {
        ...state,
        loadingDetail: action.loadingDetail,
      }
    case SET_INTEGRATION_DETAIL:
      return {
        ...state,
        loadingDetail: false,
        integrationDetail: action.integrationDetail,
      }
    case SET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.integrations,
        integrationsHash: action.integrations.reduce((p, c) => {
          if (!p[c.type]) {
            p[c.type] = {}
          }
          p[c.type][c.uuid] = c
          return p
        }, {}),
        loadingIntegrations: false,
      }
    case SET_INTEGRATIONS_LOADING:
      return {
        ...state,
        loadingIntegrations: action.loadingIntegrations,
      }
    case ADD_MORE:
      return {
        ...state,
        loadingAddMore: false,
        integrationDetail: {
          ...state.integrationDetail,
          issues: {
            ...state.integrationDetail.issues,
            issues: [
              ...state.integrationDetail.issues.issues,
              ...action.issues,
            ],
          },
        },
      }
    default:
      return state
  }
}

export const getIntegrations =
  (organizationUUID, noLoader = false) =>
  (dispatch) => {
    if (!noLoader) {
      dispatch({
        type: SET_INTEGRATIONS_LOADING,
        loadingIntegrations: true,
      })
    }
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/integrations`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_INTEGRATIONS,
          integrations: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

export const testIntegration =
  (organizationUUID, sett, type) => async (dispatch) => {
    try {
      const { data } = await api({
        method: 'post',
        url: `${settings.urls.hacker}/organizations/${organizationUUID}/integrations/test`,
        withAuthToken: true,
        data: {
          settings: JSON.stringify(sett),
          type,
        },
      })
      if (data.success) {
        return data.response
      }
      return false
    } catch (e) {
      console.error(e)
      return false
    }
  }

export const getIntegrationDetail =
  (
    organizationUUID,
    integrationUUID,
    sortBy,
    asc,
    noLoading = false,
    callback
  ) =>
  (dispatch) => {
    if (!noLoading) {
      dispatch({
        type: SET_INTEGRATION_DETAIL_LOADING,
        loadingDetail: true,
      })
    }
    api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/integrations/${integrationUUID}`,
      withAuthToken: true,
      params: {
        sort_by: sortBy,
        asc,
      },
    })
      .then(({ data }) => {
        if (typeof callback === 'function') {
          callback(data)
        }
        dispatch({
          type: SET_INTEGRATION_DETAIL,
          integrationDetail: data,
          loadingDetail: false,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

export const runIntegration =
  (organizationUUID, integrationUUID, disableLoader = false) =>
  (dispatch) => {
    if (!disableLoader) {
      dispatch({
        type: SET_LOADING_RUN_AGAIN,
        loadingRunAgain: true,
      })
    }
    api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/integrations/${integrationUUID}`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_LAST_SCANNED_AT,
          last_scanned_at: data.last_scanned_at,
        })
        dispatch(
          getIntegrationDetail(
            organizationUUID,
            integrationUUID,
            'timestamp',
            false,
            true
          )
        )
      })
      .catch((error) => {
        console.error(error)
      })
  }

export const addIntegration =
  (organizationUUID, title, sett, type, teams, callback) => (dispatch) => {
    api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/integrations`,
      withAuthToken: true,
      data: {
        settings: JSON.stringify(sett),
        title,
        type,
        teams: teams || undefined,
      },
    })
      .then(({ data }) => {
        if (typeof callback === 'function') callback()

        dispatch(runIntegration(organizationUUID, data.uuid))
      })
      .catch((error) => {
        console.error(error)
      })
  }

export const deleteIntegration =
  (organizationUUID, integrationUUID, callback) => (dispatch) => {
    dispatch({
      type: SET_LOADING_DELETE,
      loadingDelete: true,
    })
    api({
      method: 'delete',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/integrations/${integrationUUID}`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: SET_LOADING_DELETE,
            loadingDelete: false,
          })
          if (typeof callback === 'function') callback()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

export const exportAllIssues =
  (organizationUUID, integrationUUID, sortBy, asc) =>
  async (dispatch, getState) => {
    const state = getState()
    const { total } = state.integrations.integrationDetail.issues
    const { issues: toExport } = state.integrations.integrationDetail.issues
    const promises = []
    for (let i = toExport.length; i < total; i += 100) {
      promises.push(
        new Promise((resolve, reject) =>
          api({
            method: 'get',
            url: `${settings.urls.hacker}/organizations/${organizationUUID}/integrations/${integrationUUID}`,
            withAuthToken: true,
            params: {
              asc: JSON.stringify(asc),
              sort_by: sortBy,
              offset: i,
              limit: 100,
            },
          })
            .then(({ data }) => {
              resolve(data.issues)
            })
            .catch((error) => {
              console.error(error)
              reject(error)
            })
        )
      )
    }
    const responses = await Promise.all(promises)
    return [
      ...toExport,
      ...responses.reduce((prev, curr) => {
        const newArr = [...prev, ...curr]
        return newArr
      }, []),
    ]
  }

export const loadMoreIssues =
  (organizationUUID, integrationUUID, sortBy, asc) => (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: SET_LOADING_ADD_MORE,
      loadingAddMore: true,
    })
    api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/integrations/${integrationUUID}`,
      withAuthToken: true,
      params: {
        offset: state.integrations.integrationDetail.issues.issues.length,
        sort_by: sortBy,
        asc,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: ADD_MORE,
          issues: data.issues,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

export const getAppIntegrations = (organizationUUID) => (dispatch) => {
  api({
    method: 'get',
    url: `${settings.urls.hacker}/organizations/${organizationUUID}/app_integrations`,
    withAuthToken: true,
  })
    .then(({ data }) => {
      dispatch({
        type: SET_APP_INTEGRATIONS,
        appIntegrations: data,
      })
    })
    .catch((error) => {
      console.error(error)
    })
}

export const slackCallback = (organizationUUID, code) => (dispatch) => {
  api({
    method: 'post',
    url: `${settings.urls.hacker}/organizations/${organizationUUID}/slack-callback`,
    withAuthToken: true,
    data: {
      code,
      source: 'slack',
    },
  })
    .then(({ data }) => {
      if (data.success) {
        window.location.assign(
          `${settings.urls.app}/admin/${organizationUUID}/notifications`
        )
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

export const githubCallback =
  (organizationUUID, installationId, code, setupAction) => (dispatch) => {
    api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/github-callback`,
      withAuthToken: true,
      data: {
        installation_id: installationId,
        code,
        setup_action: setupAction,
        source: 'github',
      },
    })
      .then(({ data }) => {
        if (data.success) {
          window.location.assign(
            `${settings.urls.app}/admin/${organizationUUID}/app-integrations`
          )
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

export const microsoftTeamsCallback =
  (organizationUUID, installationId, callback) => (dispatch) => {
    api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/microsoft-teams-callback`,
      withAuthToken: true,
      data: {
        installation_id: installationId,
        source: 'microsoft',
      },
    })
      .then(({ data }) => {
        if (data.success) {
          if (callback && typeof callback === 'function' && !data.only_1_team) {
            callback(data.teams.map((t) => ({ ...t, active: true })))
          } else {
            window.location.assign(
              `${settings.urls.app}/admin/${organizationUUID}/notifications`
            )
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

export const microsoftTeamsCallbackTeamsInstall =
  (organizationUUID, tenantId, teams, callback) => (dispatch) => {
    api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/microsoft-teams-callback`,
      withAuthToken: true,
      data: {
        tenant_id: tenantId,
        teams,
      },
    })
      .then(({ data }) => {
        if (data.success) {
          UIkit.modal
            .alert('The HackEDU Bot has been successfully installed.')
            .then(() => {
              window.location.assign(
                `${settings.urls.app}/admin/${organizationUUID}/notifications`
              )
            })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

export const microsoftTeamsUninstall =
  (organizationUUID, callback = () => {}, errorCallback = () => {}) =>
  (dispatch) => {
    api({
      method: 'delete',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/microsoft-teams-uninstall`,
      withAuthToken: true,
    })
      .then(() => {
        callback()
      })
      .catch((error) => {
        console.error(error)
        if (errorCallback) {
          errorCallback()
        }
      })
  }
