function getEngineDisplayName(value) {
  if (!value) return ''
  if (value === 'dotnet') return 'C# / .NET'
  if (value == 'cpp') return 'C++'
  if (value === 'node') return 'JavaScript'
  if (value === 'php') return 'PHP'
  if (value == 'typescript') return 'TypeScript'
  return value[0].toUpperCase() + value.slice(1)
}

export { getEngineDisplayName }
