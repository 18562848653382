import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { values } from 'lodash'
import settings from 'settings'
// Components
import Loader from 'app/views/components/Loader'

// Redux
import { getOrganizations } from 'app/state/modules/hacker'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired, // withRouter
  }).isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

class Organizations extends React.Component {
  componentDidMount() {
    if (!this.props.hasLoadedOrganizations) {
      this.props.getOrganizations()
    } else if (this.props.organizations.length > 0) {
      const adminOrg = this.getAdmin(this.props.organizations)
      if (adminOrg) {
        this.props.history.push(`/admin/${adminOrg.uuid}/dashboard`)
        return
      }
      const teamAdminCount = this.getTotalTeamsOwned(this.props.organizations)
      const teamAdminOrg = this.getTeamAdmin(this.props.organizations)
      if (teamAdminCount === 1) {
        this.props.history.push(
          `/admin/${teamAdminOrg.uuid}/teams/${teamAdminOrg.team_admin_uuids[0].team_uuid}/users`
        )
      } else {
        this.props.history.push(`/admin/${teamAdminOrg.uuid}/teams`)
      }
    }
  }

  componentDidUpdate() {
    if (this.props.organizations.length > 0) {
      const adminOrg = this.getAdmin(this.props.organizations)
      if (adminOrg) {
        this.props.history.push(`/admin/${adminOrg.uuid}/dashboard`)
        return
      }
      const teamAdminOrg = this.getTeamAdmin(this.props.organizations)
      this.props.history.push(`/admin/${teamAdminOrg.uuid}/teams`)
    }
  }

  getAdmin(arr) {
    return arr.find((item) => item.role === 'admin')
  }

  getTotalTeamsOwned(arr) {
    return arr.reduce((prev, curr) => {
      const newPrev = prev + curr.team_admin_uuids.length
      return newPrev
    }, 0)
  }

  getTeamAdmin(arr) {
    return arr.find((item) => item.team_admin_uuids.length > 0)
  }

  render() {
    const { organizations } = this.props
    return (
      <div>
        <Loader visible={!this.props.hasLoadedOrganizations} />
        {/* Redirect users without organizations to /contact */}
        {organizations.length < 1 && this.props.hasLoadedOrganizations === true
          ? window.location.assign(`${settings.urls.www}/contact`)
          : null}
      </div>
    )
  }
}

Organizations.propTypes = propTypes

const mapStateToProps = (state) => ({
  hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
  organizations: values(state.hacker.organizationsList),
})
const mapDispatchToProps = (dispatch) => ({
  getOrganizations: () => dispatch(getOrganizations()),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Organizations)
)
