import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'
import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'
import { CognitoAuth } from 'amazon-cognito-auth-js'

const AmazonCognitoIdentity = require('amazon-cognito-identity-js')

import settings from 'settings'

export const verifyEmail = (payload) =>
  new Promise((resolve, reject) => {
    const poolData = {
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    }

    const userPool = new CognitoUserPool(poolData)
    const userData = {
      Username: payload.username,
      Pool: userPool,
    }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.confirmRegistration(
      payload.verificationCode,
      true,
      (err, result) => {
        if (err) {
          return reject(err)
        }

        return resolve(result)
      }
    )
  })

export const getConfirmedStatus = (payload) =>
  new Promise((resolve, reject) => {
    const poolData = {
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const userData = {
      Username: payload.email.toLowerCase(),
      Password: payload.password,
    }
    const userPoolData = {
      Username: payload.email.toLowerCase(),
      Pool: userPool,
    }

    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(userData)
    const cognitoUser = new CognitoUser(userPoolData)

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        resolve(result)
      },
      onFailure(err) {
        const confirmed = err.code !== 'UserNotConfirmedException'
        reject(new Error(confirmed ? 'CONFIRMED' : 'NOT_CONFIRMED'))
      },
    })
  })

export const resendVerificationCode = (payload) =>
  new Promise((resolve, reject) => {
    const poolData = {
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const userData = {
      Username: payload.username,
      Pool: userPool,
    }
    const cognitoUser = new CognitoUser(userData)
    const clientMetadata = { signUpFrom: 'HE' }
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err)
      }
      resolve(result)
    }, clientMetadata)
  })

export const resetPassword = (payload) =>
  new Promise((resolve, reject) => {
    const poolData = {
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const userData = {
      Username: payload.username,
      Pool: userPool,
    }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.confirmPassword(payload.verificationCode, payload.password, {
      onSuccess: (result) => {
        resolve(result)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })

export const sendForgotPasswordEmail = (email) =>
  new Promise((resolve, reject) => {
    const poolData = {
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const userData = {
      Username: decodeURIComponent(email.toLowerCase()),
      Pool: userPool,
    }
    const cognitoUser = new CognitoUser(userData)
    const clientMetadata = { signUpFrom: 'HE' }
    cognitoUser.forgotPassword(
      {
        onSuccess: (result) => {
          resolve(result)
        },
        onFailure: (err) => {
          reject(err)
        },
      },
      clientMetadata
    )
  })

export const registerUser = (payload) =>
  new Promise((resolve, reject) => {
    const poolData = {
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const attributeList = []
    const dataEmail = {
      Name: 'email',
      Value: payload.email.toLowerCase(),
    }
    const attributeEmail = new CognitoUserAttribute(dataEmail)

    attributeList.push(attributeEmail)

    const clientMetadata = { signUpFrom: 'HE' }

    userPool.signUp(
      payload.email.toLowerCase(),
      payload.password,
      attributeList,
      null,
      (err, result) => {
        if (err) {
          return reject(err)
        }

        return resolve(result.user)
      },
      clientMetadata
    )
  })

export const loginUser = (payload) =>
  new Promise((resolve, reject) => {
    const poolData = {
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const userData = {
      Username: payload.email.toLowerCase(),
      Password: payload.password,
    }
    const userPoolData = {
      Username: payload.email.toLowerCase(),
      Pool: userPool,
    }
    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(userData)
    const cognitoUser = new CognitoUser(userPoolData)

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        resolve(result)
      },
      onFailure(err) {
        reject(err)
      },
    })
  })

export const getUser = () =>
  new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool({
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    })
    const user = userPool.getCurrentUser()

    if (user != null) {
      user.getSession((err) => {
        if (err) {
          console.error('Error with user.getSession(): ', err)
          return reject(err)
        }
        return resolve(user)
      })
    } else {
      return reject(new Error('user is null'))
    }
  })

export const getUsernameFromJWT = (jwt) => {
  const decoded = jwtDecode(jwt)
  if (decoded['cognito:username'] !== undefined) {
    return decoded['cognito:username']
  }
  return decoded.username
}

export const isJWTExpired = (jwt) => {
  const decoded = jwtDecode(jwt)
  if (Date.now() > decoded['exp'] * 1000) {
    // the token is expired
    return true
  }
  // the token is not expired
  return false
}

export const getEmailFromJWT = (jwt) => {
  const claims = jwtDecode(jwt)

  if ('email' in claims) {
    return claims.email
  } else if (
    'cognito:username' in claims &&
    claims['cognito:username'].includes('@') &&
    claims['cognito:username'].includes('.')
  ) {
    const username = claims['cognito:username']

    const i = username.indexOf('_')
    const emailParts = [username.slice(0, i), username.slice(i + 1)]

    if (emailParts.length > 1) {
      return emailParts[1]
    }
    return emailParts[0]
  }

  return null
}

export const getDeviceKeyFromJWT = (jwt) => {
  const claims = jwtDecode(jwt)
  if ('device_key' in claims) {
    return claims.device_key
  }

  return null
}

export const logout = () => {
  localStorage.removeItem('hackedu.coding.challenges')
  const userPool = new CognitoUserPool({
    UserPoolId: settings.cognito.authData.UserPoolId,
    ClientId: settings.cognito.authData.ClientId,
  })
  const cognitoUser = userPool.getCurrentUser()

  let signedOut = Promise.resolve()
  if (cognitoUser !== null) {
    signedOut = new Promise((resolve, reject) => {
      cognitoUser.signOut((err) => {
        if (err) {
          reject(err)
        } else {
          resolve()
        }
      })
    })
  }

  signedOut
    .catch((err) => {
      console.error('Error with cognitoUser.signOut(): ', err)
    })
    .then(() => {
      const authData = settings.cognito.authData
      const auth = new CognitoAuth(
        Object.assign({}, authData, {
          RedirectUriSignOut: getLogoutUrl(),
        })
      )
      auth.signOut()
    })
}

function getLogoutUrl() {
  const cookies = new Cookies()
  cookies.remove('hackedu.logged.in.app', {
    path: '/',
    domain: settings.urls.www.replace(
      /(https:\/\/www\.|http:\/\/www\.|:.+|http:\/\/)/g,
      ''
    ),
  })

  if (document.location.hostname === 'localhost') {
    return `${document.location.protocol}//${document.location.host}`
  } else {
    return settings.urls.app
  }
}

export const getAnonymousId = () => {
  const cookies = new Cookies()
  const uuidv4 = () =>
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )

  if (cookies.get('ajs_anonymous_id') !== undefined) {
    return cookies.get('ajs_anonymous_id')
  } else if (cookies.get('hackedu_anonymous_id') !== undefined) {
    return cookies.get('hackedu_anonymous_id')
  }
  const uuid = uuidv4()
  cookies.set('hackedu_anonymous_id', uuid, { path: '/' })
  return uuid
}
