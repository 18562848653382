import { api } from 'app/views/utils/api'
import settings from 'settings'

const SET_DEEP_LINK_INFO = 'SET_DEEP_LINK_INFO'

const initialState = {
  info: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DEEP_LINK_INFO:
      return {
        ...state,
        info: action.deepLink,
      }
    default:
      return state
  }
}

export function getDeepLinkInfo(linkUUID, callback) {
  return async (dispatch) => {
    try {
      const { data } = await api({
        method: 'get',
        url: `${settings.urls.hacker}/link/${linkUUID}`,
      })
      dispatch({
        type: SET_DEEP_LINK_INFO,
        deepLink: data || {},
      })
      if (data === null) {
        window.location.assign(settings.urls.www)
        return
      }
      if (callback) callback()
    } catch (error) {
      console.error(error)
    }
  }
}
