import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import SandboxLoader from '../../SandboxLoader'

import SubmissionControls from './SubmissionControls'
import FileBrowser from './FileBrowser'
import EditorTabBar from './EditorTabBar'
import CloseModifiedFileModal from './CloseModifiedFileModal'

import CodingEditor from 'app/views/components/CodingEditor'

import { connect } from 'react-redux'
import * as sbcrfilesystem from 'app/state/modules/sbcrfilesystem'

import './style.less'

function getEngineAndExtension(filePath) {
  const fileName = filePath ? filePath.split('/').slice(-1)[0] : ''
  const dotPos = fileName.lastIndexOf('.')
  const ext =
    dotPos >= 0 && dotPos < fileName.length - 1
      ? fileName.slice(dotPos + 1)
      : ''
  const engine =
    {
      c: 'c',
      h: 'c',
      clojure: 'clojure',
      cs: 'dotnet',
      go: 'go',
      java: 'java',
      js: 'javascript',
      pl: 'perl',
      py: 'python',
      rb: 'ruby',
      scala: 'scala',
      swift: 'swift',
    }[ext] || ''
  return [engine, ext]
}

function flattenDirectoryListing(
  rootDirectory,
  isDirectoryExpanded,
  lockedFilePaths,
  isFileOpened,
  activeFilePath,
  fileHasEdits,
  fileHasHints
) {
  let items = []
  function aux(topDirectory, depth) {
    for (const directory of topDirectory.directories) {
      const isExpanded = isDirectoryExpanded(directory.path)
      items.push({
        type: 'directory',
        depth,
        isExpanded,
        data: directory,
      })
      if (isExpanded) {
        aux(directory, depth + 1)
      }
    }
    for (const file of topDirectory.files) {
      items.push({
        type: 'file',
        depth,
        isLocked: !!file.locked || lockedFilePaths.includes(file.path),
        isOpened: isFileOpened(file.path),
        isActive: file.path === activeFilePath,
        hasEdits: fileHasEdits(file.path),
        hasHints: fileHasHints(file.path),
        data: file,
      })
    }
  }
  aux(rootDirectory, 0)
  return items
}

function findFileListingItem(filePath, rootDirectory) {
  if (!filePath) {
    return null
  }
  const tokens = filePath.split('/')
  let top = rootDirectory
  for (let i = 0; i < tokens.length - 2; i++) {
    top = top.directories.find((x) => x.name === tokens[i])
    if (!top) {
      return null
    }
  }
  return top.files.find((x) => x.name === tokens[tokens.length - 1])
}

function fileIsReadOnly(filePath, rootDirectory, lockedFilePaths) {
  if (filePath) {
    if (lockedFilePaths.includes(filePath)) {
      return true
    }
    const item = findFileListingItem(filePath, rootDirectory)
    if (item && !!item.locked) {
      return true
    }
  }
  return false
}

function MultiFileCodeEditor(props) {
  // If we don't have an active sandbox, render a spinner
  const { isLoading } = props
  if (isLoading) {
    return <SandboxLoader />
  }

  // Get essential state about the files on the sandbox (via sbcrfilesystem) along
  // with file-related metadata from the db's content row (via props)
  const { rootDirectory, fileDetailsByPath, defaultFilePath, lockedFilePaths } =
    props

  // We need to imperatively resize the ace editor in response to certain layout
  // changes, so pass this value through: whenever it increments, we resize
  const { resizeAccumulator } = props

  // Use a flat lookup of directory paths to track which folders the user has expanded
  const { requestDirectoryListing } = props
  const [expandedDirectoryPaths, setExpandedDirectoryPaths] = useState({
    '': true,
  })
  const isDirectoryExpanded = (path) => !!expandedDirectoryPaths[path]
  const expandDirectory = (path) => {
    setExpandedDirectoryPaths({ ...expandedDirectoryPaths, [path]: true })
    requestDirectoryListing(path)
  }
  const collapseDirectory = (path) => {
    setExpandedDirectoryPaths({ ...expandedDirectoryPaths, [path]: false })
  }

  // Keep track of which file (if any) is active, i.e. opened in the editor.
  // Also record the last time we activated each file, so that when we close the active
  // file we can switch to the most-recently-activated file.
  const [activeFilePath, setActiveFilePath] = useState(null)
  const [fileActivationTimes, setFileActivationTimes] = useState({})

  // Keep a buffer for every file we have open and have edited
  const [editStates, setEditStates] = useState({})
  const fileHasEdits = (path) => !!(editStates[path] || {}).editTime
  const anyFileHasEdits = () => {
    for (const [_, editState] of Object.entries(editStates)) {
      if (editState && editState.editTime) {
        return true
      }
    }
    return false
  }
  const clearEdits = (path) => setEditStates({ ...editStates, [path]: {} })
  const acceptEdits = (path, newContents) => {
    // If we're editing a file that has hints, automatically dismiss all hints
    if (fileHasHints(path) && onDismissHighlightHints) {
      onDismissHighlightHints()
    }

    // Update our edit state for this file to reflect the new contents and update time
    const fileDetails = fileDetailsByPath[path]
    const isIdentical =
      fileDetails &&
      fileDetails.contents.length === newContents.length &&
      fileDetails.contents === newContents
    if (isIdentical) {
      clearEdits(path)
    } else {
      setEditStates({
        ...editStates,
        [path]: {
          contents: newContents,
          editTime: Date.now(),
        },
      })
    }
  }

  // Allow files to be saved: this takes our edited file contents and queues a request
  // for sbcrfilesystem to commit those changes to api-filesystem. As soon as that
  // request is queued, state.sbcrfilesystem.fileDetailsByPath[path] is updated to show
  // { saving: true }. Once the changes are successfully committed, the file details
  // are updated to reflect what was saved, and both loading and saving flags are cleared.
  const { requestFileSave } = props
  const saveFile = (path) => {
    const editState = editStates[path]
    if (editState) {
      requestFileSave(path, editState.contents)
    }
  }
  const saveAllFiles = () => {
    for (const [path, editState] of Object.entries(editStates)) {
      if (editState && editState.editTime) {
        requestFileSave(path, editState.contents)
      }
    }
  }

  // We need to detect when our underlying file contents (fileDetailsByPath[*].content)
  // has been updated on save. If we had pending edits to the file and the file has
  // been saved with those contents, we want to clear our edits to reflect that our
  // file is now unmodified from the version saved on the sandbox.
  useEffect(() => {
    for (const [path, fileDetails] of Object.entries(fileDetailsByPath)) {
      // Ignore files that have pending save/load requests
      if (!fileDetails.saving && !fileDetails.loading) {
        // Only process files that we have edit buffers open for
        const editState = editStates[path]
        if (editState && editState.editTime) {
          // If we've received new file contents since our last edits were made, our
          // edits should be cleared
          if (fileDetails.recvTime > editState.editTime) {
            if (fileDetails.contents !== editState.contents) {
              console.warn(
                `mismatch between newly-received and edited file contents on update of '${path}'; clobbering edits!`
              )
            }
            clearEdits(path)
          }
        }
      }
    }
  }, [fileDetailsByPath])

  // To track which files the user has opened, we'll want to use an array that
  // preserves the order in which they were opened
  const { requestFileLoad } = props
  const [openedFilePaths, setOpenedFilePaths] = useState([])
  const isFileOpened = (path) => openedFilePaths.includes(path)
  const activateFile = (path) => {
    if (!isFileOpened(path)) {
      requestFileLoad(path)
      setOpenedFilePaths(openedFilePaths.concat([path]))
    }
    setActiveFilePath(path)
    setFileActivationTimes({ ...fileActivationTimes, [path]: Date.now() })
  }
  const closeFile = (path) => {
    const newOpenedFilePaths = openedFilePaths.filter((x) => x !== path)
    if (activeFilePath === path) {
      let mostRecentlyOpenedFilePath = null
      let mostRecentlyOpenedTime = null
      for (const candidatePath of newOpenedFilePaths) {
        const candidateTime = fileActivationTimes[candidatePath]
        if (
          candidateTime &&
          (mostRecentlyOpenedTime === null ||
            candidateTime > mostRecentlyOpenedTime)
        ) {
          mostRecentlyOpenedTime = candidateTime
          mostRecentlyOpenedFilePath = candidatePath
        }
      }
      if (mostRecentlyOpenedFilePath) {
        activateFile(mostRecentlyOpenedFilePath)
      } else {
        setActiveFilePath(null)
      }
    }
    clearEdits(path)
    setOpenedFilePaths(newOpenedFilePaths)
  }

  // If the user tries to close a file that has pending edits, we want to prompt them
  // with a modal dialog to save or discard their changes (or cancel)
  const [filePendingClose, setFilePendingClose] = useState(null)
  const finishPendingClose = (choice) => {
    if (!['accept', 'discard', 'cancel'].includes(choice)) {
      console.error(`Unexpected operation in finishPendingClose: ${choice}`)
    }
    const shouldCommitEdits = choice === 'accept'
    const shouldCloseFile = choice === 'accept' || choice === 'discard'
    if (shouldCommitEdits) {
      saveFile(filePendingClose)
    }
    if (shouldCloseFile) {
      closeFile(filePendingClose)
    }
    setFilePendingClose(null)
  }
  const tryCloseFile = (path) => {
    if (filePendingClose) {
      return
    }
    if (!fileHasEdits(path)) {
      closeFile(path)
      return
    }
    setFilePendingClose(path)
  }

  // When our defaultFilePath value is initialized, automatically expand each directory
  // leading up to that file, so that it's visible from the start, and automatically
  // open that file for editing
  useEffect(() => {
    if (defaultFilePath && defaultFilePath.length > 0) {
      // Request the listing for each successive directory leading up the file
      const pathTokens = defaultFilePath.split('/')
      let pathsToExpand = {}
      for (let i = 1; i < pathTokens.length; i++) {
        const directoryPath = pathTokens.slice(0, i).join('/')
        requestDirectoryListing(directoryPath)
        pathsToExpand[directoryPath] = true
      }

      // Also queue a request to get the contents of that file
      requestFileLoad(defaultFilePath)

      // Update our UI state to preemptively expand those directories and open the file
      setExpandedDirectoryPaths({
        ...expandedDirectoryPaths,
        ...pathsToExpand,
      })
      activateFile(defaultFilePath)
    }
  }, [defaultFilePath])

  // Get any regions we should highlight due to 'highlight' hints that have been used
  const highlightRegions = props.highlightRegions || []
  const { onDismissHighlightHints } = props
  const fileHasHints = (path) =>
    !!highlightRegions.find((region) => region.filename === path)

  // When our highlight regions are updated due to new hint data, automatically open any
  // files that have hints
  useEffect(() => {
    // Get the set of unique filenames with highlighted regions
    const filenames = highlightRegions.reduce(
      (acc, region) =>
        acc.includes(region.filename) ? acc : acc.concat([region.filename]),
      []
    )
    // Activate each of those files, leaving the last one open as the active file
    for (const filename of filenames) {
      activateFile(filename)
    }
  }, [highlightRegions])

  // Get a list of highlight regions in the active file
  const activeRegions = highlightRegions.filter(
    (region) => region.filename === activeFilePath
  )

  // Determine whether we're currently waiting for any file to save or load
  let hasPendingFileIO = false
  for (const fileDetails of Object.values(fileDetailsByPath)) {
    if (fileDetails.loading || fileDetails.saving) {
      hasPendingFileIO = true
      break
    }
  }

  // Get the details of the active file, i.e. the one we're displaying in the editor
  let activeFileContents = ''
  let activeFileIsLoading = false
  let activeFileIsSaving = false
  let activeFileIsModified = false
  const activeFileIsReadOnly = fileIsReadOnly(
    activeFilePath,
    rootDirectory,
    lockedFilePaths
  )
  const [activeFileEngine, activeFileExtension] =
    getEngineAndExtension(activeFilePath)

  const activeFileDetails = fileDetailsByPath[activeFilePath] || {}
  if (activeFileDetails.recvTime) {
    activeFileIsLoading = activeFileDetails.loading
    activeFileIsSaving = activeFileDetails.saving

    const editState = editStates[activeFilePath] || {}
    if (editState && editState.editTime) {
      activeFileIsModified = true
      activeFileContents = editState.contents
    } else {
      activeFileContents = activeFileDetails.contents
    }
  }

  // Based on that state, determine what operations we can currently allow
  const {
    isSubmittingCode,
    isCodeSubmissionPending,
    isCodeRevertPending,
    onSubmitCode,
    onFocusActiveCodeSubmission,
  } = props
  const canModifyAnything =
    !filePendingClose &&
    !isSubmittingCode &&
    !isCodeSubmissionPending &&
    !isCodeRevertPending
  const canEditActiveFile =
    canModifyAnything &&
    !activeFileIsReadOnly &&
    activeFilePath &&
    !activeFileIsLoading &&
    !activeFileIsSaving
  const canSaveActiveFile =
    canModifyAnything &&
    !activeFileIsReadOnly &&
    activeFileIsModified &&
    !activeFileIsLoading &&
    !activeFileIsSaving
  const canSaveAllFiles =
    canModifyAnything && !hasPendingFileIO && anyFileHasEdits()
  const canSubmit = canModifyAnything && !hasPendingFileIO && !anyFileHasEdits()

  const { sidebarIsCollapsed, onToggleSidebar } = props
  return (
    <div className='multi-file-code-editor'>
      {filePendingClose && (
        <CloseModifiedFileModal
          fileName={filePendingClose.split('/').slice(-1)[0]}
          onAcceptChanges={() => finishPendingClose('accept')}
          onDiscardChanges={() => finishPendingClose('discard')}
          onCancel={() => finishPendingClose('cancel')}
        />
      )}
      <div className='multi-file-code-editor-sidebar'>
        <FileBrowser
          items={flattenDirectoryListing(
            rootDirectory,
            isDirectoryExpanded,
            lockedFilePaths,
            isFileOpened,
            activeFilePath,
            fileHasEdits,
            fileHasHints
          )}
          onClickDirectory={(path) =>
            (isDirectoryExpanded(path) ? collapseDirectory : expandDirectory)(
              path
            )
          }
          onClickFile={activateFile}
        />
        <SubmissionControls
          canSaveAllFiles={canSaveAllFiles}
          canSubmit={canSubmit}
          isSubmitting={!!isSubmittingCode}
          isSubmissionPending={!!isCodeSubmissionPending}
          onSaveAllFiles={saveAllFiles}
          onSubmit={onSubmitCode}
          onFocusSubmission={onFocusActiveCodeSubmission}
          sidebarIsCollapsed={!!sidebarIsCollapsed}
          onToggleSidebar={onToggleSidebar}
        />
      </div>
      {activeFilePath ? (
        <div className='multi-file-code-editor-main'>
          <EditorTabBar
            items={openedFilePaths.map((path) => ({
              path,
              hasEdits: fileHasEdits(path),
              hasHints: fileHasHints(path),
            }))}
            activeFilePath={activeFilePath}
            activeFileIsLoading={activeFileIsLoading}
            activeFileIsSaving={activeFileIsSaving}
            activeFileIsReadOnly={activeFileIsReadOnly}
            isLockedDueToSubmission={isCodeSubmissionPending || false}
            isLockedDueToRevert={isCodeRevertPending || false}
            canSaveActiveFile={canSaveActiveFile}
            onClickTab={activateFile}
            onCloseTab={tryCloseFile}
            onClickSave={() => saveFile(activeFilePath)}
          />
          <CodingEditor
            markers={activeRegions.map((region) => ({
              fileName: region.filename,
              startRow: region.startRow - 1,
              endRow: region.endRow,
              startCol: 0,
              endCol: 0,
              type: 'background',
              className: 'multi-file-code-editor-hint-highlight',
            }))}
            readOnly={!canEditActiveFile}
            code={activeFileContents}
            codeEngine={activeFileEngine}
            engine={activeFileEngine}
            syntaxHighlightingOverrideFileExtension={activeFileExtension}
            onChange={(newContents) => acceptEdits(activeFilePath, newContents)}
            resizeAccumulator={resizeAccumulator}
            height='100%'
            onHotkeySave={() => {
              if (activeFilePath && canSaveActiveFile) {
                saveFile(activeFilePath)
              }
            }}
          />
        </div>
      ) : (
        <div className='multi-file-code-editor-main-empty'>
          <div>
            <p>Choose a file from the list on the left.</p>
          </div>
        </div>
      )}
    </div>
  )
}
MultiFileCodeEditor.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  rootDirectory: PropTypes.shape({
    directories: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
  }).isRequired,
  fileDetailsByPath: PropTypes.object.isRequired,
  requestDirectoryListing: PropTypes.func.isRequired,
  requestFileLoad: PropTypes.func.isRequired,
  requestFileSave: PropTypes.func.isRequired,
  defaultFilePath: PropTypes.string,
  lockedFilePaths: PropTypes.arrayOf(PropTypes.string).isRequired,

  resizeAccumulator: PropTypes.number.isRequired,

  highlightRegions: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string.isRequired,
      startRow: PropTypes.number.isRequired,
      endRow: PropTypes.number.isRequired,
    })
  ),
  onDismissHighlightHints: PropTypes.func,
  sidebarIsCollapsed: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  isSubmittingCode: PropTypes.bool,
  isCodeSubmissionPending: PropTypes.bool,
  isCodeRevertPending: PropTypes.bool,
  onSubmitCode: PropTypes.func,
  onFocusActiveCodeSubmission: PropTypes.func,
}

export default connect(
  (state) => ({
    rootDirectory: state.sbcrfilesystem.rootDirectory,
    fileDetailsByPath: state.sbcrfilesystem.fileDetailsByPath,
  }),
  (dispatch) => ({
    requestDirectoryListing: (path) =>
      dispatch(sbcrfilesystem.requestDirectoryListing(path)),
    requestFileLoad: (path) => dispatch(sbcrfilesystem.requestFileLoad(path)),
    requestFileSave: (path, newContents) =>
      dispatch(sbcrfilesystem.requestFileSave(path, newContents)),
  })
)(MultiFileCodeEditor)
