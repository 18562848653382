import React from 'react'

export default ({ header, content, detail, noMargin }) => (
  <div
    style={{
      marginLeft: noMargin ? 0 : 30,
      marginRight: noMargin ? 0 : 30,
    }}
  >
    <h1
      className='_add_percent'
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      {header}
    </h1>
    <h5
      style={{
        margin: 0,
        padding: 0,
        textAlign: 'center',
        fontSize: 24,
      }}
    >
      {content}
    </h5>
    <h5
      style={{
        margin: 0,
        padding: 0,
        textAlign: 'center',
      }}
    >
      {detail}
    </h5>
  </div>
)
