import React from 'react'
import UIkit from 'uikit'
import { connect } from 'react-redux'

import settings from 'settings'

import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import Icon from 'app/views/components/Icon'
import ModalSendReminders from 'app/views/components/Modals/Info/ModalSendReminders'
import Loader from 'app/views/components/Loader'
import ModalSendNotifications from 'app/views/components/Modals/Info/ModalSendNotifications'
import ToggleSwitch from 'app/views/components/ToggleSwitch'

import {
  getAppIntegrations,
  microsoftTeamsUninstall,
} from 'app/state/modules/integrations'
import {
  getNotificationSettings,
  updateNotificationSettings,
  sendInstantaneousNotifications,
} from 'app/state/modules/notifications'

import CircleButton from 'app/views/components/CircleButton'
import OrganizationLayout from '../components/Layout'

// Simply controls whether the Microsoft Teams integration has an "Install" button available
const ALLOW_INSTALL_TEAMS_INTEGRATION = false

class Notifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deletingIntegration: false,
    }
    this.onDelete = this.onDelete.bind(this)
  }

  componentDidMount() {
    this.props.getAppIntegrations(this.props.match.params.organization_uuid)
    this.props.getNotificationSettings(
      this.props.match.params.organization_uuid
    )
  }

  onDelete(channel) {
    const { active_channels: activeChannels } = this.props.notificationSettings
    UIkit.modal
      .confirm(
        `You are about to delete the ${channel} channel. You users will stop receiving Training Reminders through this channel.`,
        {
          stack: true,
        }
      )
      .then(
        () => {
          const newChannels = activeChannels.filter((chan) => chan !== channel)
          this.props.updateNotificationSettings(
            this.props.match.params.organization_uuid,
            {
              channels: newChannels,
            }
          )
        },
        () => {}
      )
  }

  renderChannels() {
    const titles = {
      slack: 'Slack',
      microsoft: 'Microsoft Teams',
      email: 'Email',
    }
    return Object.keys(this.props.appIntegrations)
      .filter((key) => {
        if (
          (key === 'microsoft' &&
            this.props.appIntegrations.microsoft &&
            this.props.appIntegrations.microsoft.reference_id) ||
          (key === 'slack' &&
            this.props.appIntegrations.slack &&
            this.props.appIntegrations.slack.reference_id) ||
          key === 'email'
        ) {
          return true
        }
        return false
      })
      .map((key) => {
        return (
          <div className='uk-margin flex items-center' key={key}>
            <label className='uk-form-label'>{titles[key]}</label>
            <div className='uk-form-controls'>
              <ToggleSwitch
                onChange={(active) => {
                  if (active) {
                    this.props.updateNotificationSettings(
                      this.props.match.params.organization_uuid,
                      {
                        channels: [
                          ...this.props.notificationSettings.active_channels,
                          key,
                        ],
                      }
                    )
                  } else {
                    const { active_channels: activeChannels } =
                      this.props.notificationSettings
                    const newChannels = activeChannels.filter(
                      (chan) => chan !== key
                    )
                    this.props.updateNotificationSettings(
                      this.props.match.params.organization_uuid,
                      {
                        channels: newChannels,
                      }
                    )
                  }
                }}
                checked={this.props.notificationSettings.active_channels.includes(
                  key
                )}
              />
            </div>
          </div>
        )
      })
  }

  render() {
    return (
      <OrganizationLayout
        active='notifications'
        isLoading={this.props.loadingIntegrations}
        {...this.props}
      >
        {this.props.loadingNotificationSettings ||
        this.state.deletingIntegration ? (
          <Loader visible />
        ) : (
          <>
            <h1>Notification Settings</h1>
            <div className='uk-card uk-card-default uk-card-body uk-width-xxlarge'>
              <div className='uk-form-horizontal uk-width-xxlarge'>
                <h3>Settings</h3>
                <div className='uk-margin flex items-center'>
                  <label className='uk-form-label'>
                    Send Reminders
                    <Icon
                      name='information'
                      ratio={0.8}
                      data-uk-toggle='target: #modal-info-send-reminders'
                      style={{
                        marginLeft: 8,
                      }}
                    />
                  </label>
                  <div className='uk-form-controls'>
                    <ToggleSwitch
                      onChange={(active) => {
                        this.props.updateNotificationSettings(
                          this.props.match.params.organization_uuid,
                          {
                            send_reminders: active,
                          }
                        )
                      }}
                      checked={this.props.notificationSettings.send_reminders}
                    />
                  </div>
                </div>
                <div className='uk-margin flex items-center'>
                  <label className='uk-form-label'>
                    Notifications Schedule
                  </label>
                  <div className='uk-form-controls'>
                    <select
                      value={
                        this.props.notificationSettings.notifications_schedule
                      }
                      className='uk-select uk-form-small'
                      style={{ width: 140 }}
                      onChange={(e) => {
                        this.props.updateNotificationSettings(
                          this.props.match.params.organization_uuid,
                          {
                            notifications_schedule: e.target.value,
                          }
                        )
                      }}
                    >
                      <option value='weekly'>Weekly</option>
                      <option value='biweekly'>Bi-weekly</option>
                      <option value='monthly'>Monthly</option>
                    </select>
                  </div>
                </div>
                <div className='uk-margin flex items-center'>
                  <label className='uk-form-label'>
                    Send notifications to all users with incomplete training.
                  </label>
                  <div className='uk-form-controls'>
                    <button
                      type='button'
                      className='uk-button uk-button-small bg-green text-white'
                      data-uk-toggle='target: #modal-info-send-notifications'
                    >
                      Send Now
                    </button>
                  </div>
                </div>

                <br />
                <hr />
                <h3>Channels</h3>
                {this.renderChannels()}
                <hr />
                <h3>Available Integrations</h3>
                <div className='uk-margin flex items-center'>
                  <label className='uk-form-label'>
                    Slack Integration
                    <Icon
                      name='information'
                      ratio={0.8}
                      onClick={() => {
                        window.open(
                          'https://help.securityjourney.com/en/articles/4790447-slack-integration'
                        )
                      }}
                      style={{
                        marginLeft: 8,
                      }}
                    />
                  </label>
                  <div className='uk-form-controls  flex justify-center items-center flex-row'>
                    {this.props.appIntegrations.slack &&
                    this.props.appIntegrations.slack.reference_id ? (
                      <>
                        <span
                          type='button'
                          className='uk-label uk-label-success'
                        >
                          installed
                        </span>
                        <CircleButton
                          onClick={() => {
                            UIkit.modal
                              .alert(
                                `
                                <h2>Uninstall Slack Integration</h2>
                                <p>To uninstall the Slack integration, go to Slack application and click the <i class="ri-add-box-line"></i> icon in the left menu (next to the 'Applications' section).</p>
                                <p>After you are shown the slack integrations menu, select the HackEDU app and uninstall it.</p>
                                `
                              )
                              .then(() => {})
                          }}
                          icon='uninstall'
                          tooltip='Uninstall'
                        />
                      </>
                    ) : (
                      <button
                        onClick={() => {
                          window.location.assign(
                            `${settings.urls.slackApp}&state=${this.props.match.params.organization_uuid}`
                          )
                        }}
                        type='button'
                        className='uk-button uk-button-small flex text-white bg-green'
                      >
                        Install
                        <Icon
                          name='install'
                          className='uk-margin-small-left'
                          ratio={0.75}
                        />
                      </button>
                    )}
                  </div>
                </div>
                {this.props.flags.msTeamsIntegration && (
                  <div className='uk-margin flex items-center'>
                    <label className='uk-form-label'>
                      Microsoft Teams Integration
                      <Icon
                        name='information'
                        ratio={0.8}
                        onClick={() => {
                          window.open(
                            'https://help.securityjourney.com/en/articles/4790448-microsoft-teams-integration'
                          )
                        }}
                        style={{
                          marginLeft: 8,
                        }}
                      />
                    </label>
                    <div className='uk-form-controls flex justify-center items-center flex-row'>
                      {this.props.appIntegrations.microsoft &&
                      this.props.appIntegrations.microsoft.reference_id ? (
                        <>
                          <span
                            type='button'
                            className='uk-label uk-label-success'
                          >
                            installed
                          </span>
                          <CircleButton
                            onClick={() => {
                              UIkit.modal
                                .confirm(
                                  'Are you sure you want to remove the Microsoft teams integration?'
                                )
                                .then(
                                  () => {
                                    this.setState(
                                      {
                                        deletingIntegration: true,
                                      },
                                      () => {
                                        this.props.microsoftTeamsUninstall(
                                          this.props.match.params
                                            .organization_uuid,
                                          () => {
                                            this.props.getAppIntegrations(
                                              this.props.match.params
                                                .organization_uuid
                                            )
                                            this.props.getNotificationSettings(
                                              this.props.match.params
                                                .organization_uuid
                                            )
                                            this.setState({
                                              deletingIntegration: false,
                                            })
                                          },
                                          () => {
                                            this.props.getAppIntegrations(
                                              this.props.match.params
                                                .organization_uuid
                                            )
                                            this.props.getNotificationSettings(
                                              this.props.match.params
                                                .organization_uuid
                                            )
                                            this.setState({
                                              deletingIntegration: false,
                                            })
                                          }
                                        )
                                      }
                                    )
                                  },
                                  () => {}
                                )
                            }}
                            icon='uninstall'
                            tooltip='Uninstall'
                          />
                        </>
                      ) : ALLOW_INSTALL_TEAMS_INTEGRATION ? (
                        <button
                          onClick={() => {
                            window.location.assign(
                              `${settings.urls.microsoftApp}&state=${this.props.match.params.organization_uuid}`
                            )
                          }}
                          type='button'
                          className='uk-button uk-button-small uk-button-primary flex'
                        >
                          Install
                          <Icon
                            name='install'
                            className='uk-margin-small-left'
                            ratio={0.7}
                          />
                        </button>
                      ) : (
                        <span
                          type='button'
                          className='uk-label uk-label-warning'
                          onClick={() => {
                            window.location.assign(
                              `${settings.urls.microsoftApp}&state=${this.props.match.params.organization_uuid}`
                            )
                          }}
                        >
                          Coming Soon
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <ModalSendReminders />
        <ModalSendNotifications
          onSend={() => {
            this.props.sendInstantaneousNotifications(
              this.props.match.params.organization_uuid
            )
          }}
        />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = ({ hacker, notifications, integrations }) => ({
  organizations: hacker.organizationsList,
  organizationsHash: hacker.organizationsHash,
  notificationSettings: notifications.settings,
  loadingNotificationSettings: notifications.loadingNotificationSettings,
  appIntegrations: (integrations.appIntegrations || [])
    .filter((c) => ['microsoft', 'slack'].includes(c.source))
    .reduce(
      (prev, curr) => {
        prev[curr.source] = curr
        return prev
      },
      {
        email: {
          source: 'email',
        },
      }
    ),
})

export default withLDConsumer()(
  connect(mapStateToProps, {
    getNotificationSettings,
    updateNotificationSettings,
    sendInstantaneousNotifications,
    getAppIntegrations,
    microsoftTeamsUninstall,
  })(Notifications)
)
