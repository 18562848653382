import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import CustomContentModal from 'app/views/components/CustomContentModal'
import GenerateCertificate from 'app/views/components/GenerateCertificate'
import Icon from 'app/views/components/Icon'
import GenericModal from 'app/views/components/Modals/GenericModal'
import FormatedMinutes from 'app/views/components/FormatedMinutes'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import Statistics from 'app/views/components/Statistics'
import Tabs from 'app/views/components/Tabs'

// Redux
import {
  fetchSelectedUserDetail,
  fetchHackerActivity,
  fetchHackerTrainingPlans,
  getHackerCodeSubmissions,
  cleanUserDetailModal,
  fetchHackerCodeReviews,
} from 'app/state/modules/users'
import {
  updateTeamPrivileges,
  setSelectedUser,
  removeHackerFromTeam,
} from 'app/state/modules/team'
import {
  updateHackerRole,
  addLicense,
  removeLicense,
} from 'app/state/modules/hacker'

//  Local Imports
import TeamTab from './TeamTab'
import SettingsTab from './SettingsTab'
import ActivityTab from './ActivityTab'
import TrainingTab from './TrainingTab'
import ProficiencyTab from './ProficiencyTab'
import ChallengesTab from './ChallengesTab'
import CodeReviewTab from './CodeReviewTab'

class UserDetailModal extends React.Component {
  constructor(props) {
    super(props)
    this.refreshUserDetail = this.refreshUserDetail.bind(this)
    this.handleClickAddLicenseToUser =
      this.handleClickAddLicenseToUser.bind(this)
    this.handleClickRemoveLicenseFromUser =
      this.handleClickRemoveLicenseFromUser.bind(this)
    this.getInitialTab = this.getInitialTab.bind(this)
    this.onPrevious = this.onPrevious.bind(this)
    this.handleClickRemove = this.handleClickRemove.bind(this)
    this.onNext = this.onNext.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
    if (this.props.onRef) this.props.onRef(this)
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyUp)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedUserDetail === 'loading' &&
      this.props.selectedUserDetail !== 'loading'
    ) {
      if (this.props.onShow) this.props.onShow()
      this.modal.show()
      if (this.props.initialTab) {
        this.tabsComponent.onChangeTab(this.props.initialTab)
      }
    } else if (
      prevProps.selectedUserDetail !== 'loading' &&
      this.props.selectedUserDetail === 'loading'
    ) {
      this.modal.hide()
    }
    if (prevProps.match.params.tab !== this.props.match.params.tab) {
      if (this.tabsComponent) {
        this.tabsComponent.onChangeTab(this.getInitialTab())
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyUp)
  }

  onPrevious() {
    let prevHacker = null
    if (this.props.localData) {
      if (this.props.localData[this.props.selectedUserDetail.localIndex]) {
        prevHacker =
          this.props.localData[this.props.selectedUserDetail.localIndex]
            .prev_hacker
      }
    } else {
      prevHacker = this.props.selectedUserDetail.prev_hacker
    }
    if (prevHacker) {
      if (this.trainingTab) {
        this.trainingTab.resetCodeSubmissionShow()
      }
      this.props.fetchSelectedUserDetail(
        this.props.match.params.organization_uuid,
        {
          uuid: prevHacker,
          justTeam: this.props.match.params.team_uuid || null,
        },
        true,
        {
          localIndex: this.props.selectedUserDetail.localIndex - 1,
        }
      )
      this.props.fetchHackerActivity(
        this.props.match.params.organization_uuid,
        prevHacker,
        'main'
      )
      this.props.fetchHackerTrainingPlans(
        this.props.match.params.organization_uuid,
        prevHacker
      )
    }
  }

  onNext() {
    let nextHacker = null
    if (this.props.localData) {
      if (this.props.localData[this.props.selectedUserDetail.localIndex]) {
        nextHacker =
          this.props.localData[this.props.selectedUserDetail.localIndex]
            .next_hacker
      }
    } else {
      nextHacker = this.props.selectedUserDetail.next_hacker
    }
    if (nextHacker) {
      if (this.trainingTab) {
        this.trainingTab.resetCodeSubmissionShow()
      }
      this.props.fetchSelectedUserDetail(
        this.props.match.params.organization_uuid,
        {
          uuid: nextHacker,
          justTeam: this.props.match.params.team_uuid || null,
        },
        true,
        {
          localIndex: this.props.selectedUserDetail.localIndex + 1,
        }
      )
      this.props.fetchHackerActivity(
        this.props.match.params.organization_uuid,
        nextHacker,
        'main'
      )
      this.props.fetchHackerTrainingPlans(
        this.props.match.params.organization_uuid,
        nextHacker
      )
    }
  }

  onKeyUp(e) {
    if (e.keyCode === 37) {
      this.onPrevious()
    }
    if (e.keyCode === 39) {
      this.onNext()
    }
  }

  getInitialTab() {
    switch (this.props.match.params.tab) {
      case 'training':
        return 1
      case 'code_reviews':
        return 2
      case 'challenges':
        return 3
      case 'teams':
        return 4
      case 'activity':
        return 5
      case 'settings':
        return 6
      default:
        return 0
    }
  }

  handleClickAddLicenseToUser(hackerId, licenseTypeUUID) {
    this.props
      .addLicense(
        this.props.match.params.organization_uuid,
        hackerId,
        licenseTypeUUID
      )
      .then(() => {
        this.refreshUserDetail()
      })
  }

  handleClickRemoveLicenseFromUser(hackerId, licenseTypename, licenseTypeUUID) {
    this.props
      .removeLicense(
        this.props.match.params.organization_uuid,
        hackerId,
        licenseTypename,
        licenseTypeUUID
      )
      .then(() => {
        this.refreshUserDetail()
      })
  }

  handleClickRemove(teamUUID, hackerUUID) {
    if (this.props.match.params.team_uuid === teamUUID) {
      this.modal.hide()
      this.props.cleanUserDetailModal()
      if (this.props.onHide) {
        this.props.onHide()
      }
      if (this.props.onRefresh) {
        this.props.onRefresh()
      }
    }
    this.props
      .removeHackerFromTeam(
        this.props.match.params.organization_uuid,
        teamUUID,
        hackerUUID
      )
      .then(() => {
        this.refreshUserDetail()
      })
  }

  refreshUserDetail() {
    this.props.fetchSelectedUserDetail(
      this.props.match.params.organization_uuid,
      this.props.selectedUserDetail,
      true
    )
    if (this.props.onRefresh) {
      this.props.onRefresh()
    }
  }

  render() {
    const { selectedUserDetail } = this.props
    return (
      <>
        <GenericModal
          options={this.props.options}
          activeListeners={this.props.activeListeners}
          afterShown={this.props.afterShown}
          noButtons
          handleOnClickOverlay={() => {
            this.modal.hide()
            this.props.cleanUserDetailModal()
            if (this.props.onHide) {
              this.props.onHide()
            }
          }}
          canClose
          id={this.props.id}
          modalContainer
          width='uk-width-3-5'
          ref={(ref) => {
            this.modal = ref
          }}
          body={
            selectedUserDetail !== 'loading' && (
              <div
                className='uk-padding'
                style={{
                  paddingTop: 0,
                }}
              >
                <h2
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  {selectedUserDetail.email}
                  <GenerateCertificate
                    disabled={!selectedUserDetail.has_finished_training}
                    email={selectedUserDetail.email}
                    completionDate={selectedUserDetail.last_completed_at}
                    courseName='OWASP Top 10'
                    className='uk-button uk-button-default capitalize uk-button-small uk-align-right'
                  >
                    {selectedUserDetail.has_finished_training ? (
                      <div className='flex items-center justify-center'>
                        <Icon
                          name='download'
                          ratio={0.8}
                          style={{
                            paddingRight: 10,
                          }}
                        />
                        Download Certificate
                      </div>
                    ) : (
                      'OWASP Top 10 Not Complete'
                    )}
                  </GenerateCertificate>
                </h2>
                <span className='uk-margin-remove text-muted italic'>
                  {selectedUserDetail.last_seen
                    ? `Last seen ${getLocalDateFromUTC(
                        selectedUserDetail.last_seen
                      ).fromNow()}`
                    : 'Never logged in'}
                </span>
                <div className='flex justify-between uk-margin-top'>
                  {!this.props.dontShowArrows && (
                    <>
                      <div>
                        {((!this.props.localData &&
                          this.props.selectedUserDetail.prev_hacker) ||
                          (this.props.localData &&
                            this.props.selectedUserDetail.localIndex > 0)) && (
                          <Icon
                            name='arrow-left'
                            ratio={2}
                            onClick={this.onPrevious}
                            style={{
                              color: '#000',
                              cursor: 'pointer',
                            }}
                          />
                        )}
                      </div>
                      <div>
                        {((!this.props.localData &&
                          this.props.selectedUserDetail.next_hacker) ||
                          (this.props.localData &&
                            this.props.selectedUserDetail.localIndex <
                              this.props.localData - 1)) && (
                          <Icon
                            name='arrow-right'
                            ratio={2}
                            onClick={this.onNext}
                            style={{
                              color: '#000',
                              cursor: 'pointer',
                            }}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div
                  className='uk-margin-top uk-margin-bottom'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Statistics
                    header={
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 10,
                          fontSize: 38,
                        }}
                      >
                        {`${selectedUserDetail.training_progress}`}
                        <span style={{ fontSize: 22 }}>%</span>
                      </span>
                    }
                    content=''
                    detail='Assigned Lessons Completed'
                  />
                  <Statistics
                    header={
                      <FormatedMinutes
                        minutes={selectedUserDetail.total_time}
                      />
                    }
                    content=''
                    detail='Total Training Time'
                  />
                  <Statistics
                    header={selectedUserDetail.code_submission}
                    content=''
                    detail='Code Submissions'
                  />
                </div>
                <Tabs
                  id='_user-modal-tabs'
                  activeTab
                  initialTab={this.getInitialTab()}
                  ref={(ref) => {
                    this.tabsComponent = ref
                  }}
                  tabs={[
                    {
                      key: 'proficiency',
                      title: 'Proficiency',
                      onChangeTab: () => {
                        if (
                          this.props.match.params.tab &&
                          this.props.match.params.tab !== 'proficiency'
                        )
                          this.props.history.push(
                            `${this.props.match.url.substr(
                              0,
                              this.props.match.url.lastIndexOf('/')
                            )}/proficiency`
                          )
                      },
                      component: (
                        <ProficiencyTab
                          selectedUser={this.props.selectedUserDetail}
                        />
                      ),
                    },
                    {
                      key: 'training',
                      title: 'Training',
                      onChangeTab: () => {
                        if (
                          this.props.match.params.tab &&
                          this.props.match.params.tab !== 'training'
                        )
                          this.props.history.push(
                            `${this.props.match.url.substr(
                              0,
                              this.props.match.url.lastIndexOf('/')
                            )}/training`
                          )
                        this.props.fetchHackerTrainingPlans(
                          this.props.organization.uuid,
                          this.props.selectedUserDetail.uuid
                        )
                      },
                      component: (
                        <TrainingTab
                          ref={(ref) => {
                            this.trainingTab = ref
                          }}
                          onAddSingleContent={() =>
                            this.customContentModal.show()
                          }
                          getHackerCodeSubmissions={
                            this.props.getHackerCodeSubmissions
                          }
                          match={this.props.match}
                          organization={this.props.organization}
                          plans={this.props.plans}
                          selectedUser={this.props.selectedUserDetail}
                          email={selectedUserDetail.email}
                        />
                      ),
                    },
                    {
                      key: 'code_reviews',
                      title: 'Coding Challenges',
                      onChangeTab: () => {
                        if (
                          this.props.match.params.tab &&
                          this.props.match.params.tab !== 'code_reviews'
                        )
                          this.props.history.push(
                            `${this.props.match.url.substr(
                              0,
                              this.props.match.url.lastIndexOf('/')
                            )}/code_reviews`
                          )
                        this.props.fetchHackerCodeReviews(
                          this.props.organization.uuid,
                          this.props.selectedUserDetail.uuid
                        )
                      },
                      component: (
                        <CodeReviewTab
                          codeReviews={this.props.codeReviews}
                          selectedUser={this.props.selectedUser}
                          fetchHackerCodeReviews={
                            this.props.fetchHackerCodeReviews
                          }
                        />
                      ),
                    },
                    {
                      key: 'challenges',
                      title: 'Challenges',
                      onChangeTab: () => {
                        if (
                          this.props.match.params.tab &&
                          this.props.match.params.tab !== 'challenges'
                        )
                          this.props.history.push(
                            `${this.props.match.url.substr(
                              0,
                              this.props.match.url.lastIndexOf('/')
                            )}/challenges`
                          )
                      },
                      component: (
                        <ChallengesTab
                          challenges={this.props.selectedUserDetail.challenges}
                        />
                      ),
                    },
                    {
                      key: 'teams',
                      title: 'Teams',
                      onChangeTab: () => {
                        if (
                          this.props.match.params.tab &&
                          this.props.match.params.tab !== 'teams '
                        )
                          this.props.history.push(
                            `${this.props.match.url.substr(
                              0,
                              this.props.match.url.lastIndexOf('/')
                            )}/teams`
                          )
                      },
                      component: (
                        <TeamTab
                          onRemoveFromTeam={this.handleClickRemove}
                          cleanUserDetailModal={this.props.cleanUserDetailModal}
                          setSelectedUser={this.props.setSelectedUser}
                          onEditTeams={this.props.onEditTeams}
                          hackerUUID={this.props.hackerUUID}
                          organization={this.props.organization}
                          updateTeamPrivileges={this.props.updateTeamPrivileges}
                          selectedUser={this.props.selectedUserDetail}
                          refreshUserDetail={this.refreshUserDetail}
                          match={this.props.match}
                        />
                      ),
                    },
                    {
                      key: 'acitivity',
                      title: 'Activity',
                      onChangeTab: () => {
                        if (
                          this.props.match.params.tab &&
                          this.props.match.params.tab !== 'activity'
                        )
                          this.props.history.push(
                            `${this.props.match.url.substr(
                              0,
                              this.props.match.url.lastIndexOf('/')
                            )}/activity`
                          )
                        this.props.fetchHackerActivity(
                          this.props.match.params.organization_uuid,
                          this.props.selectedUserDetail.uuid,
                          'main'
                        )
                      },
                      component: (
                        <ActivityTab
                          selectedUser={this.props.selectedUserDetail}
                          fetchHackerActivity={this.props.fetchHackerActivity}
                          activity={this.props.activity}
                          organization={this.props.organization}
                        />
                      ),
                    },
                    {
                      key: 'settings',
                      title: 'Settings',
                      onChangeTab: () => {
                        if (
                          this.props.match.params.tab &&
                          this.props.match.params.tab !== 'settings'
                        )
                          this.props.history.push(
                            `${this.props.match.url.substr(
                              0,
                              this.props.match.url.lastIndexOf('/')
                            )}/settings`
                          )
                      },
                      component: (
                        <SettingsTab
                          onRemoveFromOrg={this.props.onRemoveFromOrg}
                          organization={this.props.organization}
                          hackerUUID={this.props.hackerUUID}
                          selectedUser={this.props.selectedUserDetail}
                          match={this.props.match}
                          refreshUserDetail={this.refreshUserDetail}
                          getHackers={this.props.getHackers}
                          updateHackerRole={this.props.updateHackerRole}
                          availableLicenses={this.props.availableLicenses}
                          availableLicenseTypes={
                            this.props.availableLicenseTypes
                          }
                          handleClickRemoveLicenseFromUser={
                            this.handleClickRemoveLicenseFromUser
                          }
                          handleClickAddLicenseToUser={
                            this.handleClickAddLicenseToUser
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )
          }
        />
        <CustomContentModal
          id={`${this.props.id}-custom-modal-content-user-detail-modal`}
          onRefresh={() => {
            this.props.fetchHackerTrainingPlans(
              this.props.organization.uuid,
              this.props.selectedUserDetail.uuid,
              true
            )
          }}
          hackerUUID={this.props.selectedUserDetail.uuid}
          onRef={(ref) => {
            this.customContentModal = ref
          }}
          email={selectedUserDetail.email}
        />
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  selectedUserDetail: state.users.selectedUserDetail,
  hackerUUID: state.hacker.profile.user_id,
  availableLicenses: state.hacker.currentTeamDetails.available_licenses,
  availableLicenseTypes:
    state.hacker.currentTeamDetails.available_license_types,
  codeReviews: state.users.codeReviews,
  organization:
    state.hacker.organizationsHash[props.match.params.organization_uuid],
  activity: state.users.activity,
  plans: state.users.plans,
})

export default withRouter(
  connect(mapStateToProps, {
    fetchSelectedUserDetail,
    updateTeamPrivileges,
    updateHackerRole,
    fetchHackerTrainingPlans,
    addLicense,
    removeLicense,
    fetchHackerActivity,
    getHackerCodeSubmissions,
    setSelectedUser,
    cleanUserDetailModal,
    removeHackerFromTeam,
    fetchHackerCodeReviews,
  })(UserDetailModal)
)
