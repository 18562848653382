import React, { useEffect, useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

/**
 * This hook is designed to facilitate rendering a pop-over that appears floating
 * above the rest of the page, affixed next to the dropdown menu that opens it. It's
 * not meant to be portable to other parts of the UI beyond the Impact Report: if we
 * have a consistent need for this sort of component we should come up with a more
 * robust implementation (and/or leverage an existing React-compatible library).
 */
function useCreatePortal(stickToElem, minWidth, onDismiss, className) {
  // Create a function that will keep our popover div glued to its quote-unquote "parent"
  const updateDivStyle = (elem) => {
    if (stickToElem) {
      const rect = stickToElem.getBoundingClientRect()
      elem.style.left = `${rect.x}px`
      elem.style.top = `${rect.y + rect.height + 4}px`

      // Match the width of the "parent", but maintain a hardcoded min width
      // (This is a hack; flexbox layout breaks down when using position: absolute)
      elem.style.minWidth = `${Math.max(rect.width, minWidth)}px`
    }
  }

  // Create a div on init, styled appropriately and stored in a ref
  const containerRef = useRef(null)
  if (!containerRef.current && typeof document !== 'undefined') {
    const div = document.createElement('div')
    div.classList.add(className)
    updateDivStyle(div)
    containerRef.current = div
  }

  // Attach that div to the body of the document on mount, and remove it on unmount
  useLayoutEffect(() => {
    const div = containerRef.current
    if (!div || typeof document === 'undefined') {
      return
    }
    document.body.appendChild(div)
    return () => {
      document.body.removeChild(div)
    }
  }, [])

  // When the window is resized or scrolled (globally), update the element's style
  // to keep it consistently positioned next to the "parent"
  useEffect(() => {
    const onWindowResize = () => updateDivStyle(containerRef.current)
    const onWindowScroll = () => updateDivStyle(containerRef.current)
    window.addEventListener('resize', onWindowResize)
    window.addEventListener('scroll', onWindowScroll, true)
    return () => {
      window.removeEventListener('resize', onWindowResize)
      window.removeEventListener('scroll', onWindowScroll)
    }
  }, [])

  // Dismiss the popover if the user clicks anywhere outside of it
  useEffect(() => {
    const onPointerDown = (event) => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect()
        if (
          event.clientX < rect.left ||
          event.clientX > rect.right ||
          event.clientY < rect.top ||
          event.clientY > rect.bottom
        ) {
          onDismiss()
        }
      }
    }
    window.addEventListener('pointerdown', onPointerDown)
    return () => {
      window.removeEventListener('pointerdown', onPointerDown)
    }
  })

  // Return a function that creates a portal and renders child contents into it
  return (children) =>
    containerRef.current &&
    ReactDOM.createPortal(children, containerRef.current)
}

export { useCreatePortal }
