/**
 * Encapsulates the state related to submitting code for testing/evaluation within the
 * context of a codereview, i.e. content of type 'coding_challenge' or 'mobile'. In a
 * codereview, the user-edited code is contained directly on the filesystem of the
 * sandbox (with the frontend using api-filesystem to read and update files, and the
 * backend using api-filesystem to dump those files to a .zip archive), so submitting
 * that code for testing is dependent on having an active codereview sandbox.
 */
import settings from 'settings'
import { api } from 'app/views/utils/api'
import moment from 'moment'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

import * as sbcrfilesystem from './sbcrfilesystem'
import { requestOpts } from 'app/state/modules/sandbox'
import { notifyParentSjApp } from 'app/sjInterop'

const prefix = 'sbcrsubmission'
const SETUP = `${prefix}/SETUP`
const TEARDOWN = `${prefix}/TEARDOWN`
const BEGIN_FETCHING_SUBMISSIONS = `${prefix}/BEGIN_FETCHING_SUBMISSIONS`
const FINISH_FETCHING_SUBMISSIONS = `${prefix}/FINISH_FETCHING_SUBMISSIONS`
const BEGIN_SUBMITTING_CODE = `${prefix}/BEGIN_SUBMITTING_CODE`
const FINISH_SUBMITTING_CODE = `${prefix}/FINISH_SUBMITTING_CODE`
const BEGIN_POLLING_SUBMISSION = `${prefix}/BEGIN_POLLING_SUBMISSION`
const FINISH_POLLING_SUBMISSION = `${prefix}/FINISH_POLLING_SUBMISSION`
const BEGIN_REVERTING_TO_SUBMISSION = `${prefix}/BEGIN_REVERTING_TO_SUBMISSION`
const FINISH_REVERTING_TO_SUBMISSION = `${prefix}/FINISH_REVERTING_TO_SUBMISSION`

const initialState = {
  apiFilesystemUrl: null, // URL of the api-filesystem service that's running in the assigned sandbox
  contentId: null, // UUID of the content we're running in the current sandbox, if initialized

  isFetching: false, // Whether we have a GET /code request in flight to api-hacker
  fetchError: null, // Error encountered on the last fetch, if any
  submissions: [], // A record of all the user's submissions for this content, newest first
  passingSubmissionAccumulator: 0, // Number of finished submissions that have come back successful since setup was called

  isSubmitting: false, // Whether we have a POST /code request in flight to api-hacker
  submitError: null, // Error encountered on the last submit, if any

  isPollingSubmission: false, // Whether we're polling the most recent submission to await its results
  pollSubmissionError: null, // Error encountered while polling submission via GET /code/:id

  isRevertingToSubmission: false, // Whether we're currently applying a previous submission's .zip file to the sandbox
  revertToSubmissionError: null, // Error encountered while reverting to a previous submission
}

// Our Redux code is a bit messy and has some duplication - the old, monolithic
// hacker.js is where we traditionally track the user's code submissions for a lesson
// or challenge. For multi-file challenges which use api-filesystem, we've factored the
// state related to code submissions into this separate module, sbcrsubmission.js.
//
// Most lessons use the repl to initiate code submissions, and most challenges use a
// separate code path, now implemented in this module, that involves resolving a URL to
// download a .zip from api-filesystem. So traditionally, LessonUI has used
// repl.js/hacker.js while CodingChallengeUI used sbcrsubmission.js, and everything was
// more or less gravy.
//
// However, we've started to create multi-file lessons: this creates a case where
// LessonUI uses sbcrsubmission.js to initiate submissions, but still tracks the state
// of submitted tests through hacker.js. This is pretty asinine, but independent of
// whether content uses repl or api-filesystem, there's also a difference in how tests
// are handled between 'lesson' content type (which pulls test details from the db) and
// 'coding_challenge'/'mobile' content type (which just uses hardcoded error strings),
// and that affects how we display test results in the UI... and that UI code is
// unfortunately somewhat coupled to the underlying Redux state.
//
// This is obviously a very convoluted design that could stand to be improved. These
// arbitrary-seeming constraints are a consequence of the historical differences in how
// lessons (single-file, with repl) and challenges (multi-file, with api-filesystem)
// work on the backend. As we move away from fixed notions like "all lessons use repl"
// or "any content with multiple files is a challenge," it would behove us to refactor
// the frontend in a way that's less coupled to these ideas.
//
// For now, though, we just chuck the submission over to hacker.js so it can add it to
// its list of submissions, despite the fact that we've initiated the submission (and
// are polling it) from this module.
function sendCodeSubmissionToHackerState(dispatch, submission) {
  // HACKER_ADD_CODE_SUBMISSION will add or replace the submission matching the given ID
  dispatch({ type: 'HACKER_ADD_CODE_SUBMISSION', codeSubmission: submission })
}

const ENABLE_VERBOSE_LOGGING = false
// eslint-disable-next-line no-console
const _log = ENABLE_VERBOSE_LOGGING ? console.log : () => {}
const _warn = ENABLE_VERBOSE_LOGGING ? console.warn : () => {}

function prependSubmission(oldSubmissions, newSubmission) {
  const index = oldSubmissions.findIndex((x) => x.id === newSubmission.id)
  if (index >= 0) {
    return oldSubmissions
      .slice(0, index)
      .concat([newSubmission])
      .concat(oldSubmissions.slice(index + 1))
  }
  return [newSubmission].concat(oldSubmissions)
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SETUP:
      return {
        ...initialState,
        apiFilesystemUrl: action.apiFilesystemUrl,
        contentId: action.contentId,
      }
    case TEARDOWN:
      return {
        ...state,
        apiFilesystemUrl: null,
        contentId: null,
      }
    case BEGIN_FETCHING_SUBMISSIONS:
      return {
        ...state,
        isFetching: true,
        fetchError: null,
      }
    case FINISH_FETCHING_SUBMISSIONS:
      if (action.error) {
        return {
          ...state,
          isFetching: false,
          fetchError: action.error,
        }
      }
      return {
        ...state,
        isFetching: false,
        submissions: action.submissions,
      }
    case BEGIN_SUBMITTING_CODE:
      return {
        ...state,
        isSubmitting: true,
        submitError: null,
      }
    case FINISH_SUBMITTING_CODE:
      if (action.error) {
        return {
          ...state,
          isSubmitting: false,
          submitError: action.error,
        }
      }
      return {
        ...state,
        isSubmitting: false,
        submissions: prependSubmission(state.submissions, action.submission),
      }
    case BEGIN_POLLING_SUBMISSION:
      return {
        ...state,
        isPollingSubmission: true,
        pollSubmissionError: null,
      }
    case FINISH_POLLING_SUBMISSION:
      if (action.error) {
        return {
          ...state,
          isPollingSubmission: false,
          pollSubmissionError: action.error,
        }
      }
      return {
        ...state,
        isPollingSubmission: false,
        submissions: prependSubmission(
          state.submissions.filter((x) => x.id !== action.submission.id),
          action.submission
        ),
        passingSubmissionAccumulator:
          state.passingSubmissionAccumulator +
          (action.submission.passed ? 1 : 0),
      }
    case BEGIN_REVERTING_TO_SUBMISSION:
      return {
        ...state,
        isRevertingToSubmission: true,
        revertToSubmissionError: null,
      }
    case FINISH_REVERTING_TO_SUBMISSION:
      if (action.error) {
        return {
          ...state,
          isRevertingToSubmission: false,
          revertToSubmissionError: action.error,
        }
      }
      return {
        ...state,
        isRevertingToSubmission: false,
      }
    default:
      return state
  }
}

async function fetchSubmissions(contentId) {
  // Use an extra 'stale' flag to indicate whether a submission is older than a few minutes, in
  // which case we'll consider it finished regardless of whether it actually has a result
  const submissionIsStale = (submission) =>
    getLocalDateFromUTC(submission.submitted_at).isBefore(
      moment().subtract(5, 'minutes')
    )
  const applyStaleFlag = (submission) => ({
    ...submission,
    stale: submissionIsStale(submission),
  })

  // Hit api-hacker's GET /code endpoint to fetch user's code submissions for this content
  const url = `${settings.urls.hacker}/code`
  const params = { content_uuid: contentId }
  const response = await api({
    method: 'get',
    url,
    withAuthToken: true,
    params,
  })
  return response.data.code_submissions.map(applyStaleFlag)
}

async function createSubmission(apiFilesystemUrl, contentId, languageName) {
  /*
    api-hacker's POST /code endpoint is particularly grotesque: it handles code
    submissions for lessons and challenges, and for K8s as well as non-K8s content,
    despite the significant and inherent differences between the way code is submitted
    and tested in each of those cases.

    But to try and summarize... when we have user-edited code that we're ready to
    submit for testing, we send a POST /code request containing, at a minimum:

    - content_uuid: api-hacker will look up a content row and branch based on type/k8s
    - engine: repl engine that corresponds to the language used; e.g. 'python'
    - code (varies depending on content type):
      - for a lesson: the user-edited repl code (i.e. patch) we want to test
      - for a codereview: a single space (' ') as a sentinel value

    For codereview content, we must also supply the public URL of the api-filesystem
    service that's running on the sandbox:

    - api_url: api-hacker will call GET /filesystem/zip on this URL to get actual code

    When api-hacker receives a POST /code request, it inserts a new code_submission row
    into the db. This row has an integer id that can be used as a handle to check the
    status of the submission, and it carries a submitted_at timestamp as well as our
    hacker_uuid and content_uuid, and the 'code' and 'engine' values we submitted.

    A code_submission row has a nullable 'passed' flag, along with 'test_completed_at',
    'test_name', and 'error_message' fields that are initially null. These fields will
    eventually be updated to reflect the results of the test, but this happens
    asynchronously from the POST /code request.

    Once api-hacker has inserted into the code_submission table, it sets the wheels in
    motion for tests to actually run on that submission (the details of which vary
    depending on content type and K8s status), and then it responds with the details
    of the new code_submission row. From there, it's up to us (the frontend) to poll
    that submission until it has results available.
  */
  const url = `${settings.urls.hacker}/code`
  const data = {
    content_uuid: contentId,
    engine: languageName, // Pulled from content metadata; not necessarily a valid repl engine
    code: ' ', // Just store a sentinel value in the db...
    api_url: apiFilesystemUrl, // ...and get the actual code from api-filesystem
    k8s_override: true, // Old UI does not use sbcrsubmission (TODO: Remove k8s_override)
  }
  const response = await api({ method: 'post', url, withAuthToken: true, data })

  // Add back in the fields that api-hacker leaves out, along with our extra 'stale'
  // flag, so that our submission data has a uniform shape
  return {
    ...response.data,
    test: {
      passed: false,
      name: null,
      error_message: null,
      url: null,
      error_stack_track: null,
      title: null,
      description: null,
    },
    stale: false,
  }
}

async function applySubmissionToSandbox(submissionId, opts) {
  const url = `${settings.urls.controlK8s}/code/${submissionId}`
  const response = await api({ method: 'get', url, withAuthToken: true, opts })
  return response.data
}

async function getSubmission(submissionId) {
  const url = `${settings.urls.hacker}/code/${submissionId}`
  const response = await api({ method: 'get', url, withAuthToken: true })
  return response.data
}

function pollMostRecentSubmission(dispatch, getState) {
  const state = getState()
  const submissionId = (state.sbcrsubmission.submissions[0] || {}).id
  if (!submissionId) {
    _warn('unable to poll most recent submission: no submission ID')
    return
  }

  _log(`polling most recent submission, id ${submissionId}...`)
  getSubmission(submissionId)
    .then((submission) => {
      const test = submission.test || {}
      const isFinished =
        test.passed === true || (test.passed === false && test.error_message)
      if (isFinished) {
        _log(
          `finish polling submission ${submissionId}: tests ${
            test.passed ? 'passed' : 'failed'
          }`,
          test
        )
        dispatch({ type: FINISH_POLLING_SUBMISSION, submission })
        sendCodeSubmissionToHackerState(dispatch, submission)

        // When running in an iframe in the SJ app - notify SJ when we've received
        // confirmation that the user's most recent submission has passed all tests
        if (test.passed) {
          notifyParentSjApp({ type: 'lesson-status-changed' })
        }
      } else {
        _log('test results still pending; will retry in 1 second')
        setTimeout(() => pollMostRecentSubmission(dispatch, getState), 1000)
      }
    })
    .catch((err) => {
      console.error('failed to poll most recent submission', err)
      dispatch({ type: FINISH_POLLING_SUBMISSION, error: err })
    })
}

export function setup(apiFilesystemUrl, contentId) {
  return (dispatch, getState) => {
    _log(`sbcrsubmission setup for ${contentId}`)
    dispatch({
      type: SETUP,
      apiFilesystemUrl,
      contentId,
    })

    dispatch({ type: BEGIN_FETCHING_SUBMISSIONS })
    _log(`fetching the current user's code submissions for '${contentId}'...`)
    fetchSubmissions(contentId)
      .then((submissions) => {
        _log('fetched code submissions', submissions)
        dispatch({ type: FINISH_FETCHING_SUBMISSIONS, submissions })
      })
      .catch((err) => {
        console.error('failed to fetch code submissions', err)
        dispatch({ type: FINISH_FETCHING_SUBMISSIONS, error: err })
      })
  }
}

export function teardown() {
  return (dispatch, getState) => {
    _log('sbcrsubmission teardown')
    dispatch({ type: TEARDOWN })
  }
}

export function initiateSubmission(languageName) {
  return (dispatch, getState) => {
    // Make sure this call is happening between setup and teardown
    const initialState = getState().sbcrsubmission
    if (!initialState.apiFilesystemUrl || !initialState.contentId) {
      _warn('unable to initiate codereview submission: module not initialized')
      return
    }

    // Only allow one submission at a time
    if (initialState.isSubmitting || initialState.isPollingSubmission) {
      const reasonDesc = initialState.isSubmitting
        ? 'another submission is in progress'
        : 'awaiting results from a previous submission'
      _warn(`unable to initiate codereview submission: ${reasonDesc}`)
      return
    }

    _log('initiating codereview submission')
    dispatch({ type: BEGIN_SUBMITTING_CODE })
    createSubmission(
      initialState.apiFilesystemUrl,
      initialState.contentId,
      languageName
    )
      .then((submission) => {
        _log('initiated codereview submission; now awaiting results', {
          submission,
        })
        dispatch({ type: FINISH_SUBMITTING_CODE, submission })
        sendCodeSubmissionToHackerState(dispatch, submission)

        // Start periodically polling our new submission, waiting for tests to pass or fail
        dispatch({ type: BEGIN_POLLING_SUBMISSION })
        pollMostRecentSubmission(dispatch, getState)
      })
      .catch((err) => {
        console.error('failed to initiate codereview submission', err)
        dispatch({ type: FINISH_SUBMITTING_CODE, error: err })
      })
  }
}

export function revertToSubmission(submissionId) {
  return (dispatch, getState) => {
    _log(`attempting to revert to submission ${submissionId}`)
    dispatch({ type: BEGIN_REVERTING_TO_SUBMISSION })
    applySubmissionToSandbox(submissionId, requestOpts(getState()))
      .then((result) => {
        // If successful, clear our 'loading' state
        if (String(result.success).toLowerCase() != 'true') {
          throw new Error(
            `Unable to revert to submission ${submissionId}: ${result}`
          )
        }
        _log(`sandbox successfully reverted to submission ${submissionId}`)
        dispatch({ type: FINISH_REVERTING_TO_SUBMISSION })

        // Notify api-filesystem that its previously-fetched file contents are now dirty
        dispatch(sbcrfilesystem.invalidateAllFileContents())
      })
      .catch((err) => {
        console.error('failed to revert sandbox to prior submission', err)
        dispatch({ type: FINISH_REVERTING_TO_SUBMISSION, error: err })
      })
  }
}
