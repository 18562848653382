import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter, Redirect } from 'react-router-dom'
import Moment from 'react-moment'
import settings from 'settings'
import { Button } from 'app/views/components/GustavoButton'

// Components
import ContentButton from 'app/views/components/ContentButton'
import ContentIcon from 'app/views/components/ContentIcon'
import ModalContentIcon from 'app/views/components/Modals/ModalContentIcon'
import SkeletonLoader from 'app/views/components/Loader/SkeletonLoader'
import Tabs from 'app/views/components/Tabs'
import ScratchpadButton from 'app/views/components/ScratchpadButton'
import GenerateCertificate from 'app/views/components/GenerateCertificate'

// Redux
import { getPlan } from 'app/state/modules/content'
import { getProfile } from 'app/state/modules/hacker'

// Utils
import analytics from 'app/views/utils/analytics'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import Icon from 'app/views/components/Icon'
import * as moment from 'moment'
// import { hasIn } from 'cypress/types/lodash'

const propTypes = {
  getPlan: PropTypes.func.isRequired,
}

class MyPlan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedPhases: [],
      organizationPlanToRender: null,
      organizationUuidFromPlanShown: false,
      completed: false,
    }
    this.renderTitleMenu = this.renderTitleMenu.bind(this)
    this.renderPhases = this.renderPhases.bind(this)
  }

  componentDidMount() {
    this.props.getPlan()
    this.props.getProfile()
    analytics.page('my-plan')
  }

  renderTitleMenu(content) {
    if (content.content_type === 'coding_challenge') {
      return `${settings.apps[content.metadata.app]}: ${content.title} (${
        settings.frameworks[content.metadata.framework.name]
      })`
    }
    return content.title
  }

  renderMenu(phases) {
    Object.size = (obj) => {
      let count = 0
      let key
      for (key in obj) {
        // eslint-disable-line
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          count += obj[key].length
        }
      }
      return count
    }
    const numberOfActivePhases = phases.filter((x) => x.active).length

    return (
      <div data-uk-sticky='offset: 150' className='hackedu-content-menu'>
        <ul className='plans'>
          {phases.map((phase) => {
            // remove duplicate content
            phase.content = phase.content.filter(
              (v1, idx, arr) =>
                arr.findIndex((v2) => v2.uuid === v1.uuid) === idx
            )

            const now = new Date()
            const today = new Date(
              Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
            )
            const startDate = Date.parse(phase.start_date)
            const isFuture = today < startDate
            const hasIncompleted = phase.content.some((c) => !c.completed_at)
            let showIncompleteIcon = false

            let className = 'past'
            let datePrefix = 'Ended on '
            let date = phase.end_date

            if (phase.active) {
              className = 'active'
              datePrefix = 'Started on '
              date = phase.start_date
            } else if (isFuture) {
              className = 'future'
              datePrefix = 'Starts on '
              date = phase.start_date
            }

            let collapsed = !(
              phase.active || this.state.expandedPhases.includes(phase.uuid)
            )
            if (
              hasIncompleted &&
              !isFuture &&
              moment().format('YYYY-MM-DD') !== phase.end_date
            ) {
              showIncompleteIcon = true
              collapsed = false
            }
            if (collapsed) {
              className = `${className} collapsed`
            }

            return (
              <li className={className} key={phase.uuid}>
                <a // eslint-disable-line
                  onClick={() => {
                    let { expandedPhases } = this.state
                    if (expandedPhases.includes(phase.uuid)) {
                      expandedPhases = expandedPhases.filter(
                        (item) => item !== phase.uuid
                      )
                    } else {
                      expandedPhases.push(phase.uuid)
                    }
                    this.setState({ expandedPhases })
                  }}
                >
                  {phase.title}
                </a>
                {!collapsed && (
                  <div>
                    <div className='date'>
                      {datePrefix}
                      <strong>
                        {moment(date || phase.hacker_plan_start_date).format(
                          'MMMM D, YYYY'
                        )}
                      </strong>
                    </div>
                    <div>
                      <ul className='content'>
                        {phase.content.map((content) => {
                          let contentClassName =
                            (content.must_pass_coding_exercise &&
                              content.has_test &&
                              content.passed_at) ||
                            ((!content.has_test ||
                              !content.must_pass_coding_exercise) &&
                              content.completed_at)
                              ? 'complete'
                              : 'incomplete'
                          let completeBy = null
                          let incompletedBy = false
                          if (content.complete_by) {
                            completeBy = Date.parse(content.complete_by)
                          }
                          if (completeBy && today > completeBy) {
                            incompletedBy = true
                          }
                          if (incompletedBy) {
                            contentClassName += ' custom-past'
                          }

                          return (
                            <li
                              className={contentClassName}
                              key={`${phase.uuid}-${content.uuid}`}
                            >
                              <Link
                                to={
                                  content.show_all_training
                                    ? `/content/${content.uuid}`
                                    : '#'
                                }
                                style={{
                                  cursor: content.show_all_training
                                    ? null
                                    : 'default',
                                }}
                                active={content.show_all_training}
                              >
                                <div className='flex'>
                                  <span>{this.renderTitleMenu(content)}</span>
                                  {((!content.completed_at &&
                                    showIncompleteIcon &&
                                    !phase.active) ||
                                    (!content.completed_at &&
                                      content.complete_by &&
                                      getLocalDateFromUTC(
                                        content.complete_by
                                      ).isBefore(
                                        getLocalDateFromUTC(
                                          moment().format('YYYY-MM-DD')
                                        )
                                      ))) && (
                                    <Icon
                                      data-uk-tooltip={
                                        !content.completed_at &&
                                        showIncompleteIcon &&
                                        !phase.active
                                          ? 'Past due'
                                          : null
                                      }
                                      ratio={0.7}
                                      className='text-warning uk-margin-small-left'
                                      name='error-warning'
                                      style={{
                                        opacity: 0.7,
                                      }}
                                    />
                                  )}
                                </div>
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </li>
            )
          })}
        </ul>
        {phases.length > numberOfActivePhases && (
          <ul className='links'>
            <li>
              {this.state.expandedPhases.length > 0 ? (
                <a
                  onClick={() => {
                    this.setState({ expandedPhases: [] })
                  }}
                >
                  Collapse Inactive
                </a>
              ) : (
                <a
                  onClick={() => {
                    const allPhases = []
                    phases.forEach((phase) => {
                      allPhases.push(phase.uuid)
                    })
                    this.setState({ expandedPhases: allPhases })
                  }}
                >
                  Expand All
                </a>
              )}
            </li>
          </ul>
        )}
      </div>
    )
  }

  renderStartText(content) {
    if (
      content.content_type === 'coding_challenge' ||
      content.content_type === 'hacking_challenge'
    ) {
      return 'Start Challenge'
    }
    return undefined
  }

  renderEmptyMessage() {
    if (this.state.currentTabIsTeam) {
      return 'You currently have no training assigned for this team.'
    }
    return 'You currently have no training assigned.'
  }

  renderPhases(phases, isPastDue = false) {
    const pastDueMap = {}
    const numberOfActivePhases = phases.filter((x) => x.active).length
    let hasSeenActive = false
    let hasSetNextPhase = false
    const pastDueTrainingContent = phases.reduce((prev, curr) => {
      const now = new Date()
      const today = new Date(
        Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
      )
      const startDate = Date.parse(curr.start_date)
      const isFuture = today < startDate
      curr.content.forEach((c) => {
        if (
          (!c.completed_at &&
            !isFuture &&
            !curr.active &&
            !pastDueMap[c.uuid]) ||
          (!curr.active &&
            c.completed_at &&
            c.has_test &&
            c.must_pass_coding_exercise &&
            !c.passed_at &&
            !isFuture &&
            !pastDueMap[c.uuid]) ||
          (!isPastDue &&
            c.is_custom &&
            c.complete_by &&
            now > Date.parse(c.complete_by) &&
            ((!c.has_test && !c.passed_at && !c.completed_at) ||
              (c.has_test && c.must_pass_coding_exercise && !c.passed_at)))
        ) {
          prev.push(c)
          pastDueMap[c.uuid] = true
        }
      })
      return prev
    }, [])
    const totalContent = phases.reduce(
      (prev, curr) => prev + curr.content.length,
      0
    )
    if (totalContent === 0) {
      return (
        <div className='uk-width-expand uk-section-small'>
          <div className='uk-section text-center'>
            No training currently assigned
          </div>
        </div>
      )
    }
    return (
      <div className='uk-width-expand uk-section-small'>
        {numberOfActivePhases === 0 && pastDueTrainingContent.length <= 0 && (
          <div className='uk-section text-center'>
            {phases.reduce((prev, curr) => prev + 1, 0) === 0
              ? this.renderEmptyMessage()
              : 'You do not have any assigned training.'}
          </div>
        )}
        {pastDueTrainingContent.length > 0 &&
          this.renderPhases(
            [
              {
                active: true,
                uuid: 'past-due-phase',
                title: 'Past Due Training',
                description:
                  'You haven\'t completed the following lessons, which are past due.',
                content: pastDueTrainingContent,
              },
            ],
            true
          )}
        {phases.map((phase, phaseIndex) => {
          const now = new Date()
          const today = new Date(
            Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
          )
          const startDate = Date.parse(phase.start_date)
          const isFuture = today < startDate
          if (phase.active) {
            hasSeenActive = true
            // remove duplicate content
            phase.content = phase.content.filter(
              (v1, idx, arr) =>
                arr.findIndex((v2) => v2.uuid === v1.uuid) === idx
            )

            return (
              <div key={phase.uuid}>
                <h1>{phase.title}</h1>
                <p className='text-2xl leading-8 text-secondary'>
                  {phase.description}
                </p>
                {phase.end_date && (
                  <div>
                    Complete by{' '}
                    <strong>
                      <Moment format='MMMM D, YYYY'>{phase.end_date}</Moment>
                    </strong>
                  </div>
                )}

                <div className='uk-section-small'>
                  {phase.content.map((content, contentIndex) => {
                    let completeBy = null
                    let incompletedBy = false
                    if (content.complete_by) {
                      completeBy = Date.parse(content.complete_by)
                    }
                    if (completeBy && today > completeBy) {
                      incompletedBy = true
                    }
                    if (
                      incompletedBy &&
                      phase.uuid !== 'custom-content-adapive' &&
                      phase.uuid != 'past-due-phase'
                    ) {
                      return null
                    }
                    const hasCompletedContent = !!content.completed_at
                    const hasPassedContent = !!content.passed_at
                    const hasTest = content.has_test
                    const isActiveForUser =
                      content.is_active_for_user || content.is_custom
                    const lessonInProgress = content.max_progress > 0
                    const hasCodeSubmission = content.code_submission_count > 0

                    let { title } = content
                    if (
                      content.lesson.uuid &&
                      content.lesson.title !== content.title
                    ) {
                      title = `${content.lesson.title} - ${content.title}`
                    }

                    const localLastCompletedTime =
                      content.has_test && content.passed_at
                        ? getLocalDateFromUTC(content.passed_at)
                        : getLocalDateFromUTC(content.completed_at)

                    return (
                      <div key={`${phase.uuid}-${content.uuid}`}>
                        <div
                          className='uk-panel'
                          key={`${phase.uuid}-${content.uuid}`}
                        >
                          <div className='flex flex-col'>
                            <div className='flex items-center'>
                              <ContentIcon
                                hasCodeSubmission={hasCodeSubmission}
                                hasCompletedContent={hasCompletedContent}
                                hasPassedContent={hasPassedContent}
                                hasTest={hasTest}
                                isActiveForUser={isActiveForUser}
                                lessonInProgress={lessonInProgress}
                                license={this.props.license}
                                mustPassCodingExercise={
                                  content.must_pass_coding_exercise
                                }
                                requiresSubscription={
                                  content.requires_subscription
                                }
                                marginTop='20px'
                              />
                              <h3
                                className={`uk-inline${
                                  content.complete_by
                                    ? ' uk-margin-small-bottom'
                                    : ''
                                }`}
                              >
                                <a
                                  href={`/content/${content.uuid}`}
                                  className='uk-link-reset'
                                >
                                  {`${
                                    content.content_type === 'coding_challenge'
                                      ? `Coding Challenge: ${
                                          settings.apps[content.metadata.app]
                                        } `
                                      : ''
                                  }${content.title}${
                                    content.content_type === 'coding_challenge'
                                      ? ` (${
                                          settings.engines[
                                            content.metadata.language.name
                                          ]
                                        } -  ${
                                          settings.frameworks[
                                            content.metadata.framework.name
                                          ]
                                        })`
                                      : ''
                                  }`}
                                </a>
                              </h3>
                            </div>

                            {content.is_custom && (
                              <div>
                                {content.complete_by ? (
                                  <small>
                                    Complete by{' '}
                                    <strong className='uk-margin-small-right'>
                                      <Moment format='MMMM D, YYYY'>
                                        {content.complete_by}
                                      </Moment>
                                    </strong>
                                    (Assigned on{' '}
                                    <strong>
                                      <Moment format='MMMM D, YYYY'>
                                        {content.created_at}
                                      </Moment>
                                    </strong>
                                    )
                                  </small>
                                ) : (
                                  <small>
                                    Assigned on{' '}
                                    <strong>
                                      <Moment format='MMMM D, YYYY'>
                                        {content.created_at}
                                      </Moment>
                                    </strong>
                                  </small>
                                )}
                              </div>
                            )}
                            {!content.is_custom && content.complete_by && (
                              <div className='inline-flex items-center'>
                                <small>
                                  Complete by{' '}
                                  <strong>
                                    <Moment format='MMMM D, YYYY'>
                                      {getLocalDateFromUTC(content.complete_by)}
                                    </Moment>
                                  </strong>
                                </small>
                                {!content.completed_at &&
                                  content.complete_by &&
                                  getLocalDateFromUTC(
                                    content.complete_by
                                  ).isBefore(
                                    getLocalDateFromUTC(
                                      moment().format('YYYY-MM-DD')
                                    )
                                  ) && (
                                    <label className='uk-margin-left uk-label uk-label-danger'>
                                      Past due
                                    </label>
                                  )}
                              </div>
                            )}
                          </div>
                          {content.description && content.is_custom && (
                            <div className='uk-margin-small-top' />
                          )}
                          <div data-uk-grid=''>
                            <div className='uk-width-4-5 uk-padding-small-right'>
                              <p>{content.description}</p>
                              {hasCompletedContent && (
                                <div className='text-sm italic'>
                                  Last completed on{' '}
                                  <Moment format='MMMM D, YYYY'>
                                    {localLastCompletedTime}
                                  </Moment>
                                  {content.content_type ===
                                    'coding_challenge' && (
                                    <>
                                      <br />
                                      <div>
                                        Score: &nbsp;
                                        {`${content.score} / ${content.metadata.points}`}
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className='uk-width-1-5 text-center uk-padding-remove-left'>
                              <ContentButton
                                startText={this.renderStartText(content)}
                                hasCompletedContent={hasCompletedContent}
                                isActiveForUser={isActiveForUser}
                                lessonInProgress={lessonInProgress}
                                requiresSubscription={
                                  content.requires_subscription
                                }
                                url={`/content/${content.uuid}`}
                              />
                            </div>
                          </div>
                        </div>

                        {contentIndex !== phase.content.length - 1 && <hr />}
                      </div>
                    )
                  })}
                </div>
                {phases.length - 1 > phaseIndex &&
                  phases[phaseIndex + 1].start_date && (
                    <div className='uk-section uk-section-muted uk-padding uk-margin-large-top text-center'>
                      Next training phase starts on{' '}
                      <strong>
                        <Moment format='MMMM D, YYYY'>
                          {phases[phaseIndex + 1].start_date}
                        </Moment>
                      </strong>
                    </div>
                  )}
              </div>
            )
          }
          if (
            hasSeenActive === false &&
            isFuture === true &&
            hasSetNextPhase === false
          ) {
            hasSetNextPhase = true
            return (
              <div
                className='uk-section uk-section-muted uk-padding uk-margin-large-top text-center'
                key={phase.uuid}
              >
                Next training phase starts on{' '}
                <strong>
                  <Moment format='MMMM D, YYYY'>{phase.start_date}</Moment>
                </strong>
              </div>
            )
          }
        })}
      </div>
    )
  }

  renderDefaultPlan() {
    return (
      <>
        <div className='uk-padding uk-padding-remove-top uk-padding-remove-bottom'>
          <div className='text-center uk-padding'>
            <h2>{this.props.plan.default.title}</h2>
            <div className='text-sm text-muted'>
              {this.props.plan.default.description}
            </div>
          </div>
          {this.renderPhases(this.props.plan.default[0].groups || [])}
        </div>
      </>
    )
  }

  renderOrganizationPlan() {
    if (!this.state.organizationPlanToRender) {
      return null
    }
    const phases = this.state.organizationPlanToRender.groups
    const totalContent = phases.reduce(
      (prev, curr) => prev + curr.content.length,
      0
    )

    return (
      <div data-uk-grid=''>
        {phases.length > 0 && totalContent > 0 && (
          <div className='uk-width-large'>
            <div>{this.renderMenu(phases)}</div>
          </div>
        )}
        <div className='uk-width-expand'>{this.renderPhases(phases)}</div>
      </div>
    )
  }

  renderPlansByOrganization() {
    const { plan, organizationsHash } = this.props
    const tabs = Object.keys(plan)
      .reduce((arr, organizationUUID) => {
        plan[organizationUUID].forEach((teamPlan) => {
          arr.push({
            organizationUuid: organizationUUID,
            key: `${organizationUUID}-${
              teamPlan.team_uuid || `${teamPlan.uuid}`
            }`,
            title: teamPlan.team_name ? (
              <>
                {organizationsHash[organizationUUID].name}
                <br />
                {teamPlan.team_name}
                &nbsp;Plan
              </>
            ) : (
              <div
                style={{
                  display: 'table-cell',
                  verticalAlign: 'bottom',
                }}
              >
                {`${organizationsHash[organizationUUID].name} Plan`}
              </div>
            ),
            plan: teamPlan,
          })
        })
        return arr
      }, [])
      .map((organizationData) => ({
        ...organizationData,
        onChangeTab: () => {
          this.setState({
            organizationUuidFromPlanShown: organizationData.organizationUuid,
            organizationPlanToRender: organizationData.plan,
            currentTabIsTeam: Boolean(organizationData.plan.team_uuid),
          })
        },
      }))
    return (
      <>
        <Tabs
          hide={tabs.length === 1}
          ref={(ref) => {
            this.tabsComponent = ref
          }}
          tabs={tabs}
        />
        {this.renderOrganizationPlan()}
      </>
    )
  }

  renderPlans(isNotInAnyOrganization) {
    if (isNotInAnyOrganization) return this.renderDefaultPlan()
    return this.renderPlansByOrganization()
  }

  render() {
    let count = 0
    let hasFinishedTraining
    if (this.props.plan.default === true) {
      hasFinishedTraining = this.props.plan
    } else {
      hasFinishedTraining = !Object.keys(this.props.plan).some(
        (organizationUUID) => {
          const plans = this.props.plan[organizationUUID]
          return plans.some((plan) =>
            plan.groups.some((group) =>
              group.content.some((content) => {
                count += 1
                return content.completed_at === null
              })
            )
          )
        }
      )
    }
    const isNotInAnyOrganization = Boolean(this.props.plan.default)
    if (isNotInAnyOrganization) {
      return <Redirect to='/all' />
    }
    // organizationUuidFromPlanShow||
    {
    }
    return (
      <div className='uk-hackedu-container-main'>
        {hasFinishedTraining && this.props.loadingPlan === false && count > 0 && (
          <div
            className='uk-light text-center uk-padding-small uk-inline'
            style={{
              width: '100%',
              backgroundColor: '#32d296',
            }}
          >
            <strong>Congrats! You have finished your assigned training.</strong>
            <br />
            {this.props.profile.has_finished_training && (
              <>
                You can download your OWASP Top 10 certificate &nbsp;
                <GenerateCertificate
                  link
                  style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  email={this.props.email}
                  completionDate={this.props.profile.last_completed_at}
                  courseName={this.props.user.plan_title}
                >
                  here
                </GenerateCertificate>
                .
              </>
            )}
          </div>
        )}
        <div className='uk-section'>
          <div className='uk-container'>
            {this.props.loadingPlan === false &&
            JSON.stringify(this.props.plan) !== '{}' &&
            (JSON.stringify(this.props.organizationsHash) !== '{}' ||
              this.props.plan.default) ? (
              this.renderPlans(isNotInAnyOrganization)
            ) : (
              <SkeletonLoader visible />
            )}
          </div>
        </div>
        <ModalContentIcon />
        <ScratchpadButton id='my-plan' />
      </div>
    )
  }
}

MyPlan.propTypes = propTypes

const mapStateToProps = (state) => ({
  // auth
  email: state.auth.user.email,

  // content
  plan: state.content.plan,
  loadingPlan: state.content.loadingPlan,

  profile: state.hacker.profile,
  user: state.hacker.currentHackerDetails,
  // hacker
  license: state.hacker.license,
  mustPassCodingExercise: state.hacker.mustPassCodingExercise,
  organizationsHash: state.hacker.organizationsHash,
})
const mapDispatchToProps = (dispatch) => ({
  getPlan: () => {
    dispatch(getPlan())
  },
  getProfile: () => {
    dispatch(getProfile())
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPlan))
