import React from 'react'
import { Link } from 'react-router-dom'

export default function PrimaryButton({
  label,
  onClick,
  loading,
  useSpinner,
  size,
  link_to,
  status,
  active,
  target,
  customCss,
  visible,
  gray,
}) {
  let sizeSpecific = ''

  if (!target) {
    target = '_self'
  }

  active = active ?? true
  visible = visible ?? true

  let visibility = visible ? 'visible' : 'invisible'

  switch (size) {
    case 'small':
      sizeSpecific = 'px-2 py-2 text-xs w-32'
      break
    case 'medium':
      sizeSpecific = 'px-4 py-2 text-sm w-48'
      break
    case 'large':
      sizeSpecific = 'px-8 py-2 font-medium'
      break
    case 'full':
      sizeSpecific = 'w-full px-8 py-2 font-medium'
      break
  }

  const loadingIndicator = useSpinner ? (
    <div data-uk-spinner='' style={{ height: '17px', width: '17px' }} />
  ) : (
    'Loading...'
  )

  return (
    <>
      {!link_to && (
        <button
          disabled={!active}
          className={`${sizeSpecific}
          ${visibility}
          text-center
          rounded-full
          ${!active || gray ? 'bg-dark_gray' : 'bg-green'}
          text-white
          cursor-pointer
          whitespace-nowrap
          border-0
          ${customCss}
          `}
          onClick={onClick}
        >
          {loading ? loadingIndicator : label}
        </button>
      )}
      {link_to && (
        <Link
          className={`${sizeSpecific}
          ${customCss}
          text-center
          rounded-full
          ${!active || gray ? 'bg-dark_gray' : 'bg-green'}
          text-white
          hover:text-white
          hover:no-underline
          whitespace-nowrap
          my-4`}
          to={link_to}
          data-status={status}
          target={target}
        >
          {loading ? loadingIndicator : label}
        </Link>
      )}
    </>
  )
}
