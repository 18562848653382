import React from 'react'
import { Button } from 'app/views/components/GustavoButton'
import { Link } from 'react-router-dom'

export default function SecondaryButton({
  label,
  onClick,
  loading,
  size,
  link_to,
  status,
  active,
  target,
  customCss,
  visible,
}) {
  let sizeSpecific = ''

  if (!target) {
    target = '_self'
  }

  active = active ?? true
  visible = visible ?? true

  let visibility = visible ? 'visible' : 'invisible'

  switch (size) {
    case 'small':
      sizeSpecific = 'px-2 py-2 text-xs w-24'
      break
    case 'medium':
      sizeSpecific = 'px-4 py-2 text-sm w-48'
      break
    case 'large':
      sizeSpecific = 'px-8 py-2 font-medium'
      break
    case 'full':
      sizeSpecific = 'w-full px-8 py-2 font-medium'
      break
  }

  return (
    <>
      {!link_to && (
        <Button
          disabled={!active}
          className={`${sizeSpecific}
          ${visibility}
          text-center
          rounded-full
          border-2
          bg-white
          my-4
          cursor-pointer
          border-solid
          whitespace-nowrap
          ${active ? 'border-green text-green' : 'border-gray text-gray'}
          ${customCss}`}
          onClick={onClick}
        >
          {loading ? 'Loading...' : label}
        </Button>
      )}
      {link_to && (
        <Link
          className={`${sizeSpecific}
          text-center
          rounded-full
          border-2
          bg-white
          hover:no-underline
          my-4
          border-solid
          ${
            !active
              ? 'border-gray text-gray hover:text-gray'
              : 'border-green text-green hover:text-green'
          }
          whitespace-nowrap
          ${customCss} `}
          to={link_to}
          data-status={status}
          target={target}
        >
          {loading ? 'Loading...' : label}
        </Link>
      )}
    </>
  )
}
