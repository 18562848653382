import { api } from 'app/views/utils/api'
import settings from 'settings'
import axios from 'axios'
import { setIntercept as updateIntercept } from './proxy'

export const SET_SANDBOX_URL = 'k8s/SET_SANDBOX_URL'
export const RESET_SANDBOX = 'k8s/RESET_SANDBOX'
export const SET_SANDBOX_STATUS = 'k8s/SET_SANDBOX_STATUS'
export const SET_CALLING_INTERCEPT = 'k8s/SET_CALLING_INTERCEPT'

const initialState = {
  sandboxUrl: '',
  sandboxStatus: 'loading',
  callingIntercept: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CALLING_INTERCEPT:
      const { callingIntercept } = action
      return {
        ...state,
        callingIntercept,
      }
    case SET_SANDBOX_URL:
      return {
        ...state,
        sandboxUrl: action.sandboxUrl,
      }
    case RESET_SANDBOX:
      return {
        ...initialState,
      }
    case SET_SANDBOX_STATUS:
      const { sandboxStatus } = action
      return {
        ...state,
        sandboxStatus,
      }
    default:
      return state
  }
}

export function setSandboxStatus(sandboxStatus) {
  return {
    type: SET_SANDBOX_STATUS,
    sandboxStatus,
  }
}

export function resetK8sSandbox() {
  return {
    type: RESET_SANDBOX,
  }
}

export function setSandboxUrl(sandbox) {
  return {
    type: SET_SANDBOX_URL,
    sandbox,
  }
}

export function getEngines() {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.proxy.url) {
      console.error('Sandbox is not loaded?!')
      return
    }
    return api({
      method: 'get',
      url: `${state.proxy.url}/repl/engines`,
    }).then((resp) => resp.data.engines)
  }
}

export function isK8sEnabled(state, content = null) {
  return content
    ? state.content.contentDetails[content].k8s_enabled
    : Object.values(state.content.contentDetails).some((c) => c.k8s_enabled)
}

export function getCode(engine) {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.proxy.url) {
      console.error('Sandbox is not loaded?!')
      return
    }
    return api({
      method: 'get',
      url: `${state.proxy.url}/repl/engines/${engine}/code`,
    }).then((resp) => resp.data.data)
  }
}

export function updateCode(engine, code, callback) {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.proxy.url) {
      console.error('Sandbox is not loaded?!')
      return
    }
    return api({
      method: 'post',
      url: `${state.proxy.url}/repl/engines/${engine}/code`,
      data: { data: code },
    }).then(() => {
      if (typeof callback === 'function') {
        callback()
      }
    })
  }
}

export function resumeRequest(
  method,
  path,
  http_version,
  body_encoded,
  headers
) {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.proxy.url) {
      console.error('Sandbox is not loaded?!')
      return
    }
    return api({
      method: 'post',
      url: `${state.proxy.url}/proxy/resume`,
      data: {
        method,
        path,
        http_version,
        body_encoded,
        headers,
      },
    })
  }
}

let cancelSetIntercept
export function setIntercept(intercept, callback) {
  return (dispatch, getState) => {
    const state = getState()
    if (state.callingIntercept) {
      console.warn('already called')
      return
    }
    if (!state.proxy.url) {
      console.error('Sandbox is not loaded?!')
      return
    }
    dispatch({
      type: SET_CALLING_INTERCEPT,
      callingIntercept: true,
    })
    if (cancelSetIntercept) {
      cancelSetIntercept.cancel('intercept toggled')
    }
    cancelSetIntercept = axios.CancelToken.source()
    return api({
      method: intercept ? 'put' : 'delete',
      url: `${state.proxy.url}/proxy/intercept`,
      withAuthToken: true,
      cancelToken: cancelSetIntercept.token,
    })
      .then(() => {
        dispatch(updateIntercept(intercept))
        dispatch({
          type: SET_CALLING_INTERCEPT,
          callingIntercept: false,
        })
        if (typeof callback === 'function') {
          callback()
        }
      })
      .catch((e) => {
        console.error({ e })
        dispatch({
          type: SET_CALLING_INTERCEPT,
          callingIntercept: false,
        })
      })
  }
}

export function getSandbox(
  sandboxTemplate,
  forceNew = undefined,
  successCallback
) {
  return (dispatch, getState) => {
    return api({
      method: 'get',
      url: `${settings.urls.controlK8s}/sandbox`,
      withAuthToken: true,
      params: {
        forceNew,
        sandboxTemplate,
      },
      opts: {
        withCredentials: true,
      },
    })
      .then(({ data }) => {
        if (data.status !== 'Running') {
          setTimeout(() => {
            dispatch(getSandbox(sandboxTemplate, undefined, successCallback))
          }, 500)
        } else {
          if (typeof successCallback === 'function') {
            successCallback(data)
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
