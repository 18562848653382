import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { compareMonths } from '../util'

function getYears(numYears, endYear) {
  let years = []
  for (let i = 0; i < numYears - 1; i++) {
    const year = endYear - numYears + i + 1
    years.push(year)
  }
  years.push(endYear)
  return years
}

function getMonthName(monthIndex) {
  const date = new Date(1970, monthIndex, 1)
  return date.toLocaleString(undefined, { month: 'short' })
}

function getMonthCellClasses(
  year,
  monthIndex,
  startMonth,
  endMonth,
  startIsActive
) {
  const month = { year, month: monthIndex + 1 }
  let classNames = ['month-range-picker-cell']
  if (!startMonth || !endMonth) {
    return classNames
  }

  const cmpStart = compareMonths(month, startMonth)
  if (cmpStart < 0) return classNames
  if (cmpStart === 0) {
    classNames.push('month-range-picker-cell-start')
    if (startIsActive) {
      classNames.push('month-range-picker-cell-active')
    }
    return classNames
  }

  const cmpEnd = compareMonths(month, endMonth)
  if (cmpEnd > 0) return classNames
  if (cmpEnd === 0) {
    classNames.push('month-range-picker-cell-end')
    if (!startIsActive) {
      classNames.push('month-range-picker-cell-active')
    }
    return classNames
  }

  classNames.push('month-range-picker-cell-on')
  return classNames
}

const MonthCell = ({ monthIndex, classNames, onClick }) => (
  <div className={classNames.join(' ')} onClick={() => onClick()}>
    <div>{getMonthName(monthIndex)}</div>
  </div>
)

const MonthGrid = ({ year, getClassesFunc, onCellClick }) => (
  <div className='month-range-picker-month-grid'>
    {[...Array(4).keys()].map((rowIndex) => (
      <div key={`row-${rowIndex}`} className='month-range-picker-month-row'>
        {[...Array(3).keys()].map((colIndex) => {
          const monthIndex = rowIndex * 3 + colIndex
          return (
            <MonthCell
              key={`month-${monthIndex}`}
              monthIndex={monthIndex}
              classNames={getClassesFunc(year, monthIndex)}
              onClick={() => onCellClick({ year, month: monthIndex + 1 })}
            />
          )
        })}
      </div>
    ))}
  </div>
)

const YearGrid = ({ numYears, endYear, getClassesFunc, onCellClick }) => (
  <div className='month-range-picker-year-grid'>
    {getYears(numYears, endYear).map((year, i) => (
      <React.Fragment key={`year-${i}`}>
        <div className='month-range-picker-year'>
          <div className='month-range-picker-year-label'>{year}</div>
          <MonthGrid
            year={year}
            getClassesFunc={getClassesFunc}
            onCellClick={onCellClick}
          />
        </div>
        {i < numYears - 1 && (
          <div className='month-range-picker-year-separator' />
        )}
      </React.Fragment>
    ))}
  </div>
)

const YearScrollButton = ({ text, active, onClick }) => (
  <button
    className='uk-button uk-button-small uk-button-secondary month-range-picker-year-scroll-button'
    disabled={!active}
    onClick={() => onClick()}
  >
    {text}
  </button>
)

function MonthRangePicker(props) {
  const { numYears, startMonth, endMonth, onDateRangeChange } = props
  const currentYear = new Date().getFullYear()
  const [endYear, setEndYear] = useState(endMonth ? endMonth.year : currentYear)
  const canScrollBack = endYear - numYears >= 2000
  const canScrollForward = endYear < new Date().getFullYear()

  const lastKnownEndMonthYear = useRef(null)
  useEffect(() => {
    if (endMonth !== null) {
      if (
        lastKnownEndMonthYear.current === null ||
        lastKnownEndMonthYear.current !== endMonth.year
      ) {
        if (endMonth.year !== endYear) {
          setEndYear(endMonth.year)
        }
      }
      lastKnownEndMonthYear.current = endMonth.year
    }
  }, [endMonth])

  const [startIsActive, setStartIsActive] = useState(true)
  const handleCellClick = (month) => {
    // If we click to the left of the currently-selected range, move the start of the
    // range out to that point, and "activate" the start month so that subsequent
    // clicks inside the range will move the start month
    const cmpStart = compareMonths(month, startMonth)
    if (cmpStart <= 0) {
      setStartIsActive(true)
      if (cmpStart < 0) {
        onDateRangeChange(month, endMonth)
      }
      return
    }

    // If we click to the right of the currently-selected range, move the end of the
    // range out to that point, and "activate" the end month so that subsequent
    // clicks inside the range will move the end month
    const cmpEnd = compareMonths(month, endMonth)
    if (cmpEnd >= 0) {
      setStartIsActive(false)
      if (cmpEnd > 0) {
        onDateRangeChange(startMonth, month)
      }
      return
    }

    // If we click inside the currently-selected range, move either the start or end
    // month inward, depending on which was moved most recently
    if (startIsActive) {
      // One exception: if the end month is scrolled out of view and we're clicking
      // sufficiently far away from the start month, move (and activate) the end month
      // regardless of whether it's active
      if (endMonth.year > endYear && month.year > startMonth.year) {
        onDateRangeChange(startMonth, month)
        setStartIsActive(false)
      } else {
        onDateRangeChange(month, endMonth)
      }
    } else {
      onDateRangeChange(startMonth, month)
    }
  }

  return (
    <div className='month-range-picker'>
      <YearScrollButton
        text={'\u2190'}
        active={canScrollBack}
        onClick={() => setEndYear((x) => x - 1)}
      />
      <YearGrid
        numYears={numYears}
        endYear={endYear}
        getClassesFunc={(year, monthIndex) =>
          getMonthCellClasses(
            year,
            monthIndex,
            startMonth,
            endMonth,
            startIsActive
          )
        }
        onCellClick={handleCellClick}
      />
      <YearScrollButton
        text={'\u2192'}
        active={canScrollForward}
        onClick={() => setEndYear((x) => x + 1)}
      />
    </div>
  )
}
MonthRangePicker.propTypes = {
  numYears: PropTypes.number.isRequired,
  startMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
  }),
  endMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
  }),
  onDateRangeChange: PropTypes.func.isRequired,
}

export default MonthRangePicker
