import React, { useState, useRef, useEffect } from 'react'

function useFlashOnNewOutput(numOutputLines) {
  const [isFlashing, setIsFlashing] = useState(false)
  const prevNumOutputLines = useRef(0)
  const timerHandle = useRef(null)

  // This is hardcoded from the duration of '#sandbox-output-header .flash', see hackedu.less
  const animationDuration = 500

  // If number of lines has increased and we're not already flashing, start flashing
  useEffect(() => {
    if (numOutputLines > prevNumOutputLines.current) {
      if (!isFlashing) {
        setIsFlashing(true)
        // Stop flashing when the animation is finished
        timerHandle.current = setTimeout(() => {
          setIsFlashing(false)
        }, animationDuration + 16)
      }
    }
    prevNumOutputLines.current = numOutputLines
  }, [numOutputLines])

  // Stop changing state if we're unmounted
  useEffect(() => {
    return () => {
      if (timerHandle.current) {
        clearTimeout(timerHandle.current)
      }
    }
  }, [])

  return isFlashing
}

export { useFlashOnNewOutput }
