import React from 'react'
import AceEditor from 'react-ace'

import Icon from 'app/views/components/Icon'

// Settings
import settings from 'settings'

// Utils
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

class ActivityTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      code: undefined,
      engine: undefined,
      activity: undefined,
    }
    this.renderActivity = this.renderActivity.bind(this)
    this.renderActivityType = this.renderActivityType.bind(this)
  }

  renderActivityType(activity) {
    switch (activity.type) {
      case 'CHALLENGE':
        return (
          <>
            <td style={{ width: '40%' }}>
              <small>{`Submitted a flag on ${activity.title}`}</small>
            </td>
            <td className='text-center' style={{ verticalAlign: 'middle' }}>
              <small
                className={`uk-margin-left uk-label text-center ${
                  activity.completed ? 'uk-label-success' : 'uk-label-danger'
                }`}
              >
                {activity.completed ? 'PASSED' : 'FAILING'}
              </small>
            </td>
            <td className='' />
          </>
        )
      case 'CODE':
        return (
          <>
            <td style={{ width: '40%' }}>
              <small>{`Submitted ${activity.metadata.engine} code on ${activity.title}`}</small>
            </td>
            <td className='text-center' style={{ verticalAlign: 'middle' }}>
              <small
                className={`uk-margin-left uk-label text-center ${
                  activity.completed ? 'uk-label-success' : 'uk-label-danger'
                }`}
              >
                {activity.completed ? 'PASSED' : 'FAILING'}
              </small>
            </td>
            <td className='text-center' style={{ verticalAlign: 'middle' }}>
              <button
                type='button'
                onClick={() =>
                  this.setState({
                    code: activity.metadata.code,
                    engine: activity.metadata.engine,
                    activity,
                  })
                }
                className='uk-button uk-button-primary uk-button-small'
              >
                View Code
              </button>
            </td>
          </>
        )
      default:
        return (
          <>
            <td style={{ width: '40%' }}>
              <small>{`Completed ${activity.title} lesson`}</small>
            </td>
            <td className='text-center' style={{ verticalAlign: 'middle' }}>
              <small
                className={`uk-margin-left uk-label text-center ${
                  activity.completed ? 'uk-label-success' : 'uk-label-danger'
                }`}
              >
                COMPLETED
              </small>
            </td>
            <td className='' />
          </>
        )
    }
  }

  renderActivity() {
    return this.props.activity.map((activity, index) => {
      return (
        <tr key={`_activity-${index}`}>
          <td
            className='text-emphasis '
            style={{ paddingRight: 60, width: '20%', verticalAlign: 'middle' }}
          >
            <small>
              {getLocalDateFromUTC(activity.activity_date).fromNow()}
            </small>
          </td>
          {this.renderActivityType(activity)}
        </tr>
      )
    })
  }

  render() {
    const { activity } = this.props
    if (activity === 'loading') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
          }}
        >
          <div data-uk-spinner='' className='uk-align-center' />
        </div>
      )
    }

    if (this.state.code) {
      return (
        <div className='uk-grid uk-padding uk-padding-remove-top uk-margin-remove'>
          <button
            className='uk-button uk-button-secondary uk-button-small'
            onClick={() => this.setState({ code: null, engine: null })}
            style={{
              paddingLeft: 10,
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            type='button'
          >
            <Icon name='arrow-left' style={{ paddingRight: 10 }} />
            Back to Activity
          </button>

          <h2 style={{ marginTop: 0, marginBottom: 10 }}>
            {this.state.activity.title}
            <br />
            <p className='text-muted text-sm uk-margin-remove-top'>
              {getLocalDateFromUTC(this.state.activity.activity_date).fromNow()}
            </p>
          </h2>
          <AceEditor
            showPrintMargin={false}
            height='400px'
            width='100%'
            placeholder=''
            mode={
              settings.languages[this.state.engine.replace(/[^A-Za-z]/g, '')]
            }
            theme='monokai'
            name='editor'
            fontSize={14}
            showGutter
            wrapEnabled={false}
            highlightActiveLine
            onChange={() => {}}
            value={this.state.code}
            setOptions={{
              showLineNumbers: true,
              tabSize: 2,
            }}
            editorProps={{ $blockScrolling: Infinity }}
          />
        </div>
      )
    }

    if (activity.length === 0) {
      return <p className='text-center'>User has no activity.</p>
    }
    return (
      <>
        <table
          id='uk-modal-detail-user-activity'
          className='uk-table uk-table-expand uk-width-1-1 uk-table-divider'
        >
          <tbody>
            {this.renderActivity()}
            <tr>
              <td colSpan={4}>
                {Array.isArray(activity) && activity.length % 15 === 0 && (
                  <button
                    className='uk-button uk-button-primary uk-button-small uk-align-center'
                    type='button'
                    onClick={() =>
                      this.props.fetchHackerActivity(
                        this.props.organization.uuid,
                        this.props.selectedUser.uuid,
                        'more'
                      )
                    }
                  >
                    Load more
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }
}

export default ActivityTab
