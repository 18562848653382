import React from 'react'
import PropTypes from 'prop-types'

/**
 * Resolve common properties for all divs displayed in a flex layout. This is just a simple bit of
 * shorthand to make layout code more succinct and easier to read. It's not intended to be an
 * exhaustive flexbox wrapper; if you need finer control, either use stylesheets or pass additional
 * inline styles via the 'style' prop; they'll be merged in.
 */
function resolveBoxStyle(fillParent, grow, shrink, fixed) {
  let style = {}
  if (fillParent === true) {
    style.height = '100%'
  }
  if (grow !== undefined) {
    if (grow === false) {
      style.flexGrow = 0
    } else if (grow === true) {
      style.flexGrow = 1
    } else {
      style.flexGrow = grow
    }
  }
  if (shrink !== undefined) {
    if (shrink === false) {
      style.flexShrink = 0
    } else if (shrink === true) {
      style.flexShrink = 1
    } else {
      style.flexShrink = shrink
    }
  }
  if (fixed !== undefined) {
    style.flexBasis = fixed
  }
  return style
}

/**
 * A single item within a flexbox layout; does not apply display: 'flex' itself.
 */
function Box(props) {
  const { fillParent, grow, shrink, fixed, style, ...rest } = props
  const resolvedStyle = {
    ...resolveBoxStyle(fillParent, grow, shrink, fixed),
    ...style,
  }
  return <div style={resolvedStyle} {...rest} />
}
Box.propTypes = {
  fillParent: PropTypes.bool,
  grow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  shrink: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  fixed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

/**
 * A horizontal flexbox container; also accepts all item layout properties.
 */
function HBox(props) {
  const { fillParent, grow, shrink, fixed, style, ...rest } = props
  const resolvedStyle = {
    display: 'flex',
    flexDirection: 'row',
    ...resolveBoxStyle(fillParent, grow, shrink, fixed),
    ...style,
  }
  return <div style={resolvedStyle} {...rest} />
}
HBox.propTypes = {
  ...Box.propTypes,
}

/**
 * A vertical flexbox container; also accepts all item layout properties.
 */
function VBox(props) {
  const { fillParent, grow, shrink, fixed, style, ...rest } = props
  const resolvedStyle = {
    display: 'flex',
    flexDirection: 'column',
    ...resolveBoxStyle(fillParent, grow, shrink, fixed),
    ...style,
  }
  return <div style={resolvedStyle} {...rest} />
}
VBox.propTypes = {
  ...Box.propTypes,
}

export { Box, HBox, VBox }
