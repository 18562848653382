import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { HBox, Box } from 'app/views/core/Box'

import TestStatusIcon from './TestStatusIcon'

const Container = ({ isSelected, onSelect, children }) => (
  <div
    style={{
      borderBottom: '1px solid #eee',
      borderRight: '1px solid #eee',
      cursor: 'pointer',
      backgroundColor: isSelected ? '#f0f0f0' : 'inherit',
      padding: 8,
      paddingLeft: 16,
    }}
    onClick={onSelect}
  >
    {children}
  </div>
)

function TestSubmissionListItem(props) {
  const { isSelected, onSelect, result, submittedAt } = props
  return (
    <Container isSelected={isSelected} onSelect={onSelect}>
      <TestStatusIcon absolute status={result} />
      <span className='text-sm' style={{ marginLeft: 30 }}>
        {moment.utc(submittedAt).local().calendar()}
      </span>
    </Container>
  )
}
TestSubmissionListItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  result: PropTypes.oneOf(['pending', 'passed', 'failed']).isRequired,
  submittedAt: PropTypes.string.isRequired,
}

export default TestSubmissionListItem
