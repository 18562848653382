import React from 'react'
import { PropTypes } from 'prop-types'
import Clipboard from 'react-clipboard.js'
import UIkit from 'uikit'
import settings from 'settings'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'
import Icon from 'app/views/components/Icon'
import Tabs from 'app/views/components/Tabs'

import base64 from 'app/state/utils/base64'

const propTypes = {
  token: PropTypes.string.isRequired,
  totalHackers: PropTypes.number.isRequired,
}

class TeamsUsersInvite extends React.Component {
  constructor(props) {
    super(props)

    this.onCopySuccess = this.onCopySuccess.bind(this)
    this.getInviteLink = this.getInviteLink.bind(this)
    this.handleInviteClick = this.handleInviteClick.bind(this)
  }

  onCopySuccess() {
    UIkit.notification({
      message: 'Copied to clipboard!',
      status: 'primary',
      pos: 'bottom-center',
      timeout: 1200,
    })
  }

  getInviteLink(token) {
    return `${settings.urls.app}/invite/${token.replace('==', '')}`
  }

  handleInviteClick(e) {
    e.target.select()
  }

  renderOnboardingLinks() {
    if (
      this.props.licenseTypes.length === 1 ||
      this.props.licenseTypes.length === 0
    ) {
      return (
        this.props.licenseTypes.length === 0 ? [{}] : this.props.licenseTypes
      ).map((licenseType) => {
        const token = licenseType.uuid
          ? base64.encode(`${this.props.token}|${licenseType.uuid || ''}`)
          : this.props.token
        const inviteLink = this.getInviteLink(token)
        return (
          <div
            key={licenseType.uuid || 'full'}
            className='flex flex-col uk-margin-small-top'
          >
            <div className='uk-inline' style={{ width: 600 }}>
              <Clipboard
                component='span'
                data-clipboard-text={this.getInviteLink(token)}
                onSuccess={this.onCopySuccess}
              >
                <Icon
                  as='button'
                  className='uk-form-icon uk-form-icon-flip uk-icon-button'
                  style={{
                    background: '#fff',
                    border: 'none',
                    cursor: 'pointer',
                    marginTop: 3,
                    marginRight: 5,
                  }}
                  name='file-copy'
                  type='button'
                />
              </Clipboard>
              <input
                type='text'
                className='uk-input text-green'
                value={inviteLink}
                onClick={this.handleInviteClick}
                style={{ paddingRight: '35px' }}
                readOnly
              />
            </div>
          </div>
        )
      })
    }

    if (this.props.licenseTypes.length > 1) {
      const tabs = this.props.licenseTypes.map((licenseType) => {
        const token = licenseType.uuid
          ? base64.encode(`${this.props.token}|${licenseType.uuid || ''}`)
          : this.props.token
        const inviteLink = this.getInviteLink(token)
        return {
          key: licenseType.uuid,
          title: licenseType.name,
          onChangeTab: () => {},
          component: (
            <div
              key={licenseType.uuid}
              className='flex flex-col uk-margin-small-top'
            >
              <p className='uk-margin-vertical text-center'>
                {this.renderLicenseAvailableMessage(licenseType)}
              </p>

              {licenseType.available_licenses > 0 && (
                <div className='uk-inline' style={{ width: 600 }}>
                  <Clipboard
                    component='span'
                    data-clipboard-text={this.getInviteLink(token)}
                    onSuccess={this.onCopySuccess}
                  >
                    <Icon
                      as='button'
                      className='uk-form-icon uk-form-icon-flip uk-icon-button'
                      style={{
                        background: '#fff',
                        border: 'none',
                        cursor: 'pointer',
                        marginTop: 3,
                        marginRight: 5,
                      }}
                      name='file-copy'
                      type='button'
                    />
                  </Clipboard>
                  <input
                    type='text'
                    className='uk-input text-green'
                    value={inviteLink}
                    onClick={this.handleInviteClick}
                    style={{
                      paddingRight: '35px',
                    }}
                    readOnly
                  />
                </div>
              )}
            </div>
          ),
        }
      })
      return (
        <Tabs
          id='_user-modal-tabs'
          activeTab
          ref={(ref) => {
            this.tabsComponent = ref
          }}
          tabs={tabs}
        />
      )
    }
    return null
  }

  renderLicenseAvailableMessage(licenseType) {
    return licenseType.available_licenses > 0 ? (
      <>
        You have &nbsp;
        <b>
          {licenseType.available_licenses}
          &nbsp; "{licenseType.name}"
        </b>
        &nbsp; Licenses available.
      </>
    ) : (
      <>
        There isn't any &nbsp;
        <b>"{licenseType.name}"</b>
        &nbsp; license available.
        <br />
        <PrimaryButton
          link_to={`${settings.urls.www}/contact`}
          target='_blank'
          rel='noopener noreferrer'
          label='Add Licenses'
          size='medium'
        />
      </>
    )
  }

  renderMessage() {
    if ((this.props.licenseTypes || []).length > 1) {
      return 'Share these links to add users to your account based on your license types:'
    }
    return 'Share this link to add users to your account for email/password onboarding:'
  }

  render() {
    const { totalHackers, token } = this.props
    return (
      <div className='uk-card uk-card-default uk-card-body max-w-3xl'>
        <div className='uk-margin'>
          You have &nbsp;
          <span className='text-2xl font-bold'>{totalHackers}</span>
          &nbsp;
          {totalHackers === 1 ? ' user' : ' users'}
          &nbsp; in your organization.
        </div>
        <div className='uk-margin'>
          <div>{this.renderMessage()}</div>
          {token && this.renderOnboardingLinks()}
        </div>
      </div>
    )
  }
}

TeamsUsersInvite.propTypes = propTypes

export default TeamsUsersInvite
