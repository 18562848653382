import React from 'react'
import PaginationItem from './PageItem'

class Pagination extends React.Component {
  onPrev() {
    if (this.props.currentPage > 1) {
      this.props.onChangePage(this.props.currentPage - 1)
    }
  }

  onNext() {
    if (this.props.currentPage !== this.props.totalPages) {
      this.props.onChangePage(this.props.currentPage + 1)
    }
  }

  renderPages() {
    const { totalPages, currentPage } = this.props
    const pages = []
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <PaginationItem
          onChangePage={this.props.onChangePage}
          key={`page_${i}`}
          active={currentPage === i}
          page={i}
        />
      )
    }
    return pages
  }

  render() {
    const { hasArrows, currentPage, totalPages } = this.props
    return (
      <ul
        className='uk-pagination'
        uk-margin='true'
        style={this.props.style || {}}
      >
        {hasArrows && (
          <PaginationItem
            disabled={currentPage === 1}
            isPrev
            onClick={() => {
              this.onPrev()
            }}
          />
        )}
        {this.renderPages()}
        {hasArrows && (
          <PaginationItem
            disabled={currentPage === totalPages}
            isNext
            onClick={() => {
              this.onNext()
            }}
          />
        )}
      </ul>
    )
  }
}

export default Pagination
