import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

/**
 * Dynamically creates and positions a tooltip div that sits above the Spotlight overlay.
 */
function TourTooltip(props) {
  const { position, stageIndex, children } = props
  const lastRenderedStageIndex = useRef(-1)

  // Dynamically create a tooltip div and attach it to the page body on mount
  const div = useRef()
  useEffect(() => {
    div.current = document.createElement('div')
    div.current.style.position = 'absolute'
    div.current.style.zIndex = 2100
    document.body.appendChild(div.current)

    return () => {
      if (div.current) {
        document.body.removeChild(div.current)
        div.current = null
      }
    }
  }, [])

  const timeoutHandle = useRef()
  function hideBriefly() {
    if (timeoutHandle.current) {
      clearTimeout(timeoutHandle.current)
    }
    if (div.current) {
      div.current.style.visibility = 'hidden'
    }
    timeoutHandle.current = setTimeout(() => {
      if (div.current) {
        div.current.style.visibility = 'visible'
      }
      timeoutHandle.current = null
    }, 120)
  }

  // Any time we're given a new position value, apply it imperatively to our div
  useEffect(() => {
    if (div.current) {
      if (position.left !== undefined) {
        div.current.style.left = `${position.left}px`
        div.current.style.right = null
      } else {
        div.current.style.left = null
        div.current.style.right = `${position.right}px`
      }

      if (position.top !== undefined) {
        div.current.style.top = `${position.top}px`
        div.current.style.bottom = null
      } else {
        div.current.style.top = null
        div.current.style.bottom = `${position.bottom}px`
      }
    }
  }, [position])

  // Render statically into the div
  useEffect(() => {
    if (lastRenderedStageIndex.current != stageIndex) {
      hideBriefly()
      if (div.current) {
        ReactDOM.render(children, div.current)
        lastRenderedStageIndex.current = stageIndex
      }
    }
  }, [children])

  return null
}
TourTooltip.propTypes = {
  position: PropTypes.object.isRequired,
  stageIndex: PropTypes.number.isRequired,
  children: PropTypes.any,
}

export default TourTooltip
