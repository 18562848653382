import React from 'react'
import PropTypes from 'prop-types'

const VScroll = React.forwardRef((props, ref) => (
  <div style={{ position: 'relative', height: '100%' }}>
    <div
      ref={ref}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflowY: 'auto',
      }}
    >
      {props.children}
    </div>
  </div>
))
VScroll.propTypes = {
  children: PropTypes.any,
}

export { VScroll }
