import React from 'react'

// Components
import Modal from 'app/views/components/UIkit/Modal'
import PrimaryButton from '../../Buttons/PrimaryButton'

const ModalSendNotifications = ({ onSend }) => (
  <Modal
    id='modal-info-send-notifications'
    title='Send Notifications'
    body={
      <div>
        <p>
          This will send instantaneous notifications to all the users that have
          incompleted training through all the available channels.
        </p>
        <p>Are you sure you want to send a notification to all your users?</p>
        <p className='text-right'>
          <PrimaryButton
            size='small'
            onClick={onSend}
            label='Yes, Send'
            customCss='uk-modal-close'
          />
        </p>
        <button
          className='uk-modal-close-outside'
          data-uk-close
          type='button'
        />
      </div>
    }
  />
)

export default ModalSendNotifications
