import React from 'react'
import UIkit from 'uikit'

import MultiSelect from 'app/views/components/MultiSelect'
import AutoComplete from 'app/views/components/AutoComplete'
import Icon from 'app/views/components/Icon'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import PrimaryCheckBox from 'app/views/components/Inputs/PrimaryCheckBox'
class AddUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: null,
      dataToSend: {},
      sendOnboarding: false,
      newMembersForm: [
        {
          email: '',
          type: 'member',
          teams: null,
        },
      ],
    }
    this.handleAddNew = this.handleAddNew.bind(this)
    this.handleChangeTeams = this.handleChangeTeams.bind(this)
    this.handleDeleteMember = this.handleDeleteMember.bind(this)
    this.handleChangeAddForm = this.handleChangeAddForm.bind(this)
    this.onSubmitNewTeamMembers = this.onSubmitNewTeamMembers.bind(this)
    this.handleChangeMetadataFile = this.handleChangeMetadataFile.bind(this)
    this.props.getTeams(this.props.match.params.organization_uuid)
  }

  onSubmitNewTeamMembers(e) {
    e.preventDefault()
    this.setState(
      {
        newMembersForm: this.state.newMembersForm.filter(
          (member) => member.email !== ''
        ),
      },
      () => {
        const re =
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        const { newMembersForm, dataToSend } = this.state
        const hasFileWithRecords =
          dataToSend && Object.keys(dataToSend).length > 0
        let hasError = false
        this.setState(
          {
            newMembersForm: newMembersForm.map((member, index) => {
              const error = !re.test(member.email)
              if (error) {
                hasError = true
                return {
                  ...member,
                  error:
                    index === 0 &&
                    hasFileWithRecords &&
                    member.email.trim() === ''
                      ? false
                      : true,
                }
              }
              return {
                ...member,
                error: undefined,
              }
            }),
          },
          () => {
            if (hasError && !hasFileWithRecords) {
              return null
            }
            return this.setState(
              {
                uploadingUsers: true,
              },
              () => {
                newMembersForm.forEach((member) => {
                  if (member && member.email) {
                    dataToSend[member.email] = {
                      email: member.email,
                      admin: member.type === 'admin',
                      teams: member.teams ? Object.keys(member.teams) : [],
                      isFile: false,
                    }
                  }
                })
                this.props.importUsers(
                  this.props.match.params.organization_uuid,
                  this.state.sendOnboarding,
                  dataToSend,
                  (data) => {
                    if (data.success) {
                      if (data.only_admins) {
                        UIkit.modal.alert(
                          '<h2>Import Incomplete</h2><p>You have imported all the admin users into your organization but you are out of licenses to add any new member. Please contact your administrator.</p>'
                        )
                      } else {
                        UIkit.modal.alert(
                          '<h2>Import successful</h2><p>Congratulations, you have successfully imported new users.</p>'
                        )
                      }
                      this.props.getHackers(
                        this.props.match.params.organization_uuid,
                        true
                      )
                    } else if (data.msg === 'PRIVILEGES_NEEDED') {
                      UIkit.modal.alert(
                        '<h2>Warning</h2><p>You don\'t have privileges to invite new users.</p>'
                      )
                    } else if (data.msg === 'NOT_ENOUGH_LICENSES') {
                      UIkit.modal.alert(
                        '<h2>Warning</h2><p>Your organization does not have enough licenses to add these users. Please contact your administrator.</p>'
                      )
                    }
                  }
                )
                this.props.hide()
              }
            )
          }
        )
      }
    )
  }

  handleChangeMetadataFile(ev) {
    const { files } = ev.target // FileList object
    const f = files[0]

    const reader = new FileReader()

    this.setState({ fileName: ev.target.value.replace('C:\\fakepath\\', '') })
    const isCSV = ev.target.value.toLowerCase().indexOf('.csv') > 0
    const isExcel =
      ev.target.value.toLowerCase().indexOf('.xlsx') > 0 ||
      ev.target.value.toLowerCase().indexOf('.xls') > 0

    reader.onload = (() => (e) => {
      if (isCSV) {
        this.setState(
          {
            file: e.target.result
              .split('\n')
              .map((row) => row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)),
          },
          () => {
            this.setState(
              {
                confirmUploadMessage: null,
              },
              () => {
                this.preProcessingData()
              }
            )
          }
        )
      } else if (isExcel) {
        import('exceljs').then(({ default: Excel }) => {
          const workbook = new Excel.Workbook()
          workbook.xlsx
            .load(e.target.result)
            .then(() => {
              const { rows } = workbook.model.sheets[0]
              this.setState(
                {
                  file: rows.reduce((prev, { cells }) => {
                    const obj = cells.map(({ value, text }) => {
                      if (value) {
                        return value.toString()
                      }
                      if (text && typeof text === 'string') {
                        // Support cells with style
                        return text.toString()
                      }
                      if (
                        text !== null &&
                        typeof text === 'object' &&
                        text.richText &&
                        text.richText[0]
                      ) {
                        return text.richText[0].text
                      }
                      return ''
                    })
                    if (obj[0] !== '') prev.push(obj)
                    return prev
                  }, []),
                },
                () => {
                  this.setState(
                    {
                      confirmUploadMessage: null,
                    },
                    () => {
                      this.preProcessingData()
                    }
                  )
                }
              )
            })
            .catch((err) => {
              console.error({ err })
            })
        })
      } else {
        document.getElementById('import_team_member_input').value = ''
      }
    })(f)
    if (isCSV) {
      reader.readAsText(f)
    } else {
      reader.readAsArrayBuffer(f)
    }
  }

  preProcessingData() {
    this.setState(
      {
        loadingPreproccesing: true,
      },
      () => {
        const dataToSend = {}
        const invalidEmail = {}
        let repeated = 0
        const re =
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

        const hasHeaders =
          this.state.file &&
          this.state.file[0] &&
          typeof this.state.file[0][0] === 'string' &&
          !re.test(this.state.file[0][0])
        this.state.file.forEach((row, index) => {
          const email = row[0].toLowerCase().trim()
          if (
            ((index > 0 && hasHeaders) || !hasHeaders) &&
            !re.test(email) &&
            row.length >= 3
          ) {
            invalidEmail[email] = true
          } else if (
            ((index > 0 && hasHeaders) || !hasHeaders) &&
            row.length >= 4 &&
            !dataToSend[email]
          ) {
            dataToSend[email] = {
              email,
              admin: Boolean(row[1].match(/TRUE|1/i)),
              teams: row[2]
                ? [
                    ...new Set(
                      row[2]
                        .split(',')
                        .map((team) => team.replace(/("|“|”)/g, '').trim())
                        .filter((team) => team.trim() !== '')
                    ),
                  ]
                : [],
              isFile: true,
            }
          } else if (
            ((index > 0 && hasHeaders) || !hasHeaders) &&
            row.length >= 4
          ) {
            repeated += 1
          }
        })
        const totalToBeImported = Object.keys(dataToSend).length
        setTimeout(() => {
          const totalInvalidEmails = Object.keys(invalidEmail).length
          this.setState({
            dataToSend,
          })
          this.setState({
            confirmUploadMessage: `
            <small class="text-success">- ${totalToBeImported} users will be imported.</small>
            ${
              repeated > 0
                ? `<br/><small class="text-danger">- ${repeated} repeated records were found and will be ignored.</small>`
                : ''
            }
            ${
              totalInvalidEmails > 0
                ? `<br/><small class="text-danger">- ${totalInvalidEmails} records were ignored because email is wrong</small>`
                : ''
            }
          `,
            loadingPreproccesing: false,
          })
        }, 500)
      }
    )
  }

  clearForm() {
    this.setState({
      fileName: null,
      dataToSend: {},
      newMembersForm: [
        {
          email: '',
          type: 'member',
        },
      ],
    })
  }

  handleChangeAddForm(index, key, e) {
    const { newMembersForm } = this.state
    newMembersForm[index][key] = e.target.value
    this.setState({
      newMembersForm,
    })
  }

  handleAddNew() {
    const { newMembersForm } = this.state
    this.setState({
      newMembersForm: [
        ...newMembersForm,
        {
          email: '',
          type: 'member',
        },
      ],
    })
  }

  handleDeleteMember(index) {
    const { newMembersForm, dataToSend } = this.state
    if (dataToSend[newMembersForm[index].email]) {
      delete dataToSend[newMembersForm[index].email]
    }
    this.setState({
      newMembersForm: newMembersForm.filter((_, i) => i !== index),
      dataToSend,
    })
  }

  handleChangeTeams(index, selectedTeams) {
    const { newMembersForm } = this.state
    newMembersForm[index].teams = selectedTeams
    this.setState({
      newMembersForm,
    })
  }

  renderNewMembers() {
    const teams = this.props.teams.map((team) => ({
      value: team.uuid,
      text: team.name,
    }))
    return this.state.newMembersForm.map((member, index) => {
      return (
        <React.Fragment key={`member-${index}`}>
          <div className='uk-width-1-3@s uk-inline'>
            {this.state.newMembersForm.length > 1 && (
              <Icon
                as='a'
                style={{ cursor: 'pointer' }}
                role='button'
                className='uk-form-icon uk-form-icon-flip'
                onClick={this.handleDeleteMember.bind(this, index)}
                name='delete-bin'
              />
            )}
            <div>
              <AutoComplete
                extraAction={this.handleAddNew}
                organizationUUID={this.props.match.params.organization_uuid}
                id={`autocomplete-${index}`}
                className='uk-input'
                type='text'
                placeholder='e.g: jane.smith@acme.com'
                value={member.email}
                index={index}
                onChange={this.handleChangeAddForm}
                style={
                  member.error
                    ? {
                        border: 'solid 1px #f0506e',
                      }
                    : {}
                }
              />
            </div>
          </div>
          <div className='uk-width-1-3@s'>
            <select
              className='uk-select'
              type='text'
              value={member.type}
              onChange={this.handleChangeAddForm.bind(this, index, 'type')}
            >
              <option value='member'>Member</option>
              <option value='admin'>Admin</option>
            </select>
          </div>
          <div className='uk-width-1-3@s'>
            <MultiSelect
              optionsHeaderMsg='SELECT TEAMS'
              options={teams}
              selected={member.teams}
              onChange={(selectedTeams) =>
                this.handleChangeTeams(index, selectedTeams)
              }
              noOptionsMsg='No Teams have been created'
              nullOptionMsg='No Team'
            />
          </div>
        </React.Fragment>
      )
    })
  }

  render() {
    return (
      <>
        <div className='uk-margin'>
          <h4>
            File Upload
            <Icon
              as='a'
              href='https://help.securityjourney.com/en/articles/3143713-import-users-with-user-csv'
              name='question'
              ratio={0.8}
              style={{ verticalAlign: 'top', marginLeft: 10 }}
              target='_blank'
              rel='noopener noreferrer'
            />
          </h4>

          <div>
            <div className='uk-width-1-1' data-uk-form-custom='target: true'>
              <input
                id='import_team_member_input'
                type='file'
                onChange={this.handleChangeMetadataFile}
              />
              <input
                className='uk-placeholder uk-input uk-form-width-medium uk-width-1-1'
                type='text'
                placeholder='Drag a file or click here to upload CSV or XLSX'
                disabled
              />
            </div>
            <span
              className='font-bold'
              dangerouslySetInnerHTML={{
                __html: this.state.confirmUploadMessage,
              }}
            />
          </div>
        </div>
        <hr />
        <form className='uk-grid-small' uk-grid=''>
          <div className='uk-width-1-1@s'>
            <h3>Email</h3>
          </div>
          {this.renderNewMembers()}
          <div className='uk-width-1-1@s'>
            <a
              type='button'
              onClick={this.handleAddNew}
              className='flex items-center text-green'
            >
              <Icon
                className='uk-margin-small-right'
                name='add-circle'
                ratio={0.8}
              />
              Add another
            </a>
          </div>
        </form>
        <br />
        <br />
        <div className='flex justify-between align-top'>
          <div className='flex justify-center items-center'>
            <PrimaryCheckBox
              checked={this.state.sendOnboarding}
              onChange={(e) =>
                this.setState({ sendOnboarding: e.target.checked })
              }
              label='Send onboarding email to imported users'
            />
            <Icon
              className='pl-1'
              as='a'
              href='https://help.securityjourney.com/en/articles/3149755'
              name='question'
              ratio={0.8}
              style={{
                verticalAlign: 'top',
                display: 'inline-block',
              }}
              target='_blank'
              rel='noopener noreferrer'
            />
          </div>
          <div>
            <PrimaryButton
              size={'small'}
              customCss={'w-32'}
              gray={true}
              onClick={this.props.hide}
              label={this.props.closeText || 'Cancel'}
            />

            <PrimaryButton
              size={'small'}
              customCss={'ml-3 w-32'}
              onClick={this.onSubmitNewTeamMembers}
              label='Invite Teammates'
            />
          </div>
        </div>
      </>
    )
  }
}

export default AddUsers
