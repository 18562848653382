import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { interpTo } from './util'

/**
 * Draws a smoothly-animating 2D canvas overlay in order to highlight a single screen-space rectangle.
 */
function Spotlight(props) {
  // Use a canvas element (and 2D drawing context) tied to the lifetime of this component
  const canvas = useRef(null)
  const ctx = useRef(null)

  // Establish internal animation state: this component's only job is to highlight a single
  // rectangular area within the window, by overlaying a canvas element and drawing a rectangular
  // mask into that canvas.
  const lastRenderTimestamp = useRef(null)
  const renderCallbackHandle = useRef(null)

  function clearRenderCallback() {
    if (renderCallbackHandle.current) {
      window.cancelAnimationFrame(renderCallbackHandle.current)
      lastRenderTimestamp.current = null
      renderCallbackHandle.current = null
    }
  }
  function enableRendering() {
    if (!renderCallbackHandle.current) {
      lastRenderTimestamp.current = null
      renderCallbackHandle.current = window.requestAnimationFrame(render)
    }
  }

  // We track the rectangular area of interest with two points: (x1, y1) is the top-left corner;
  // and (x2, y2) is the bottom-right. Render coordinates are rounded to the nearest pixel.
  const renderCoords = useRef({
    x1: 0,
    y1: 0,
    x2: document.documentElement.clientWidth,
    y2: document.documentElement.clientHeight,
  })

  // Our current position is represented with floating-point precision for smooth animation
  const currentCoords = useRef({
    x1: 0.0,
    y1: 0.0,
    x2: document.documentElement.clientWidth,
    y2: document.documentElement.clientHeight,
  })

  // Finally, we have the target positions we're trying to reach: these coordintaes are also
  // rounded to the nearest pixel, same as our current render coordinates
  const targetCoords = useRef({
    x1: 0,
    y1: 0,
    x2: document.documentElement.clientWidth,
    y2: document.documentElement.clientHeight,
  })

  // For our per-frame update logic, we want to gradually move our current positions toward the
  // target positions, and compute new nearest-pixel render positions. When our render positions
  // match the desired target positions, we return true to indicate that we're done rendering.
  function update(deltaTime, interpSpeed) {
    // If we don't have a valid timestep or interpSpeed is zero, instantly jump to the target
    if (!deltaTime || !interpSpeed) {
      renderCoords.current.x1 = currentCoords.current.x1 =
        targetCoords.current.x1
      renderCoords.current.y1 = currentCoords.current.y1 =
        targetCoords.current.y1
      renderCoords.current.x2 = currentCoords.current.x2 =
        targetCoords.current.x2
      renderCoords.current.y2 = currentCoords.current.y2 =
        targetCoords.current.y2
      return true
    }

    // Otherwise, interpolate each coordinate toward its respective target value
    currentCoords.current.x1 = interpTo(
      currentCoords.current.x1,
      targetCoords.current.x1,
      deltaTime,
      interpSpeed
    )
    currentCoords.current.y1 = interpTo(
      currentCoords.current.y1,
      targetCoords.current.y1,
      deltaTime,
      interpSpeed
    )
    currentCoords.current.x2 = interpTo(
      currentCoords.current.x2,
      targetCoords.current.x2,
      deltaTime,
      interpSpeed
    )
    currentCoords.current.y2 = interpTo(
      currentCoords.current.y2,
      targetCoords.current.y2,
      deltaTime,
      interpSpeed
    )

    // Then round each coordinate to the nearest pixel value
    renderCoords.current.x1 = Math.round(currentCoords.current.x1)
    renderCoords.current.y1 = Math.round(currentCoords.current.y1)
    renderCoords.current.x2 = Math.round(currentCoords.current.x2)
    renderCoords.current.y2 = Math.round(currentCoords.current.y2)

    // Return a boolean indicating whether we've reached the target position
    return (
      renderCoords.current.x1 === targetCoords.current.x1 &&
      renderCoords.current.y1 === targetCoords.current.y1 &&
      renderCoords.current.x2 === targetCoords.current.x2 &&
      renderCoords.current.y2 === targetCoords.current.y2
    )
  }

  function render(timestamp) {
    // Compute the elapsed time since the last frame, to scale the speed of our animation
    const deltaTime = lastRenderTimestamp.current
      ? (timestamp - lastRenderTimestamp.current) / 1000.0
      : 1.0 / 60.0
    lastRenderTimestamp.current = timestamp

    // Update our positions, giving us newly-updated render coordinates
    const reachedTarget = update(deltaTime, 10.0)

    // Start rendering to our 2D canvas element: we need to draw the negative space surrounding
    // our target rectangle, which we can accomplish by window-boxing that region with no more
    // than 4 rectangles, e.g.
    // +---+----------+-----+
    // |   |   top    |     |
    // | l +----------+  r  |
    // | e |          |  i  |
    // | f |          |  g  |
    // | t +----------+  h  |
    // |   |  bottom  |  t  |
    // +---+----------+-----+
    const { x1, y1, x2, y2 } = renderCoords.current
    const { width, height } = canvas.current
    ctx.current.clearRect(0, 0, width, height)
    ctx.current.fillStyle = 'rgba(0, 0, 0, 0.3)'

    // Render the left pillarbox rectangle, bringing in the X1 coord for the top/bottom rectangles if needed
    let innerX1 = 0
    if (x1 > 0) {
      innerX1 = x1
      ctx.current.fillRect(0, 0, x1, height)
    }

    // Render the right pillarbox rectangle, bringing in the X2 coord for the top/bottom rectangles if needed
    let innerX2 = width
    if (x2 < width) {
      innerX2 = x2
      ctx.current.fillRect(x2, 0, width, height)
    }

    // Render the top and bottom letterbox rectangles, squeezed inbetween the left and right pillarboxes
    const innerWidth = innerX2 - innerX1
    if (y1 > 0) {
      ctx.current.fillRect(innerX1, 0, innerWidth, y1)
    }
    if (y2 < height) {
      ctx.current.fillRect(innerX1, y2, innerWidth, height)
    }

    // Schedule a new update/render tick next frame, until we reach our target
    if (reachedTarget) {
      clearRenderCallback()
    } else {
      renderCallbackHandle.current = window.requestAnimationFrame(render)
    }
  }

  // When the window is resized, resize the canvas buffer so it stays fullscreen at 1:1
  function onResize() {
    if (canvas.current) {
      canvas.current.width = document.documentElement.clientWidth
      canvas.current.height = document.documentElement.clientHeight

      // A resize can clear the buffer, so we need to render at least one new frame
      enableRendering()
    }
  }

  // The targetRect prop is updated whenever we need to highlight a new area, so we want to pull
  // these values into our internal state whenever they change
  const { targetRect } = props
  useEffect(() => {
    if (targetRect) {
      const { x, y, width, height } = targetRect
      targetCoords.current.x1 = Math.round(x)
      targetCoords.current.y1 = Math.round(y)
      targetCoords.current.x2 = Math.round(x + width)
      targetCoords.current.y2 = Math.round(y + height)
    } else {
      targetCoords.current.x1 = 0
      targetCoords.current.y1 = 0
      targetCoords.current.x2 = document.documentElement.clientWidth
      targetCoords.current.y2 = document.documentElement.clientHeight
    }
    enableRendering()
  }, [targetRect])

  // Finally, on mount we want to create a canvas element, initialize our 2D rendering context,
  // and start rendering; and on unmount we want to clean everything up
  useEffect(() => {
    window.addEventListener('resize', onResize)

    canvas.current = document.createElement('canvas')
    canvas.current.width = document.documentElement.clientWidth
    canvas.current.height = document.documentElement.clientHeight
    canvas.current.style.position = 'absolute'
    canvas.current.style.top = 0
    canvas.current.style.left = 0
    canvas.current.style.zIndex = 2000
    canvas.current.style.pointerEvents = 'none'
    document.body.appendChild(canvas.current)

    ctx.current = canvas.current.getContext('2d')
    ctx.current.fillStyle = 'rgba(0, 0, 0, 0.3)'

    enableRendering()

    return () => {
      clearRenderCallback()
      window.removeEventListener('resize', onResize)
      document.body.removeChild(canvas.current)
      ctx.current = null
      canvas.current = null
    }
  }, [])

  // Render nothing; we manage a canvas element but it's dynamically created and appended elsewhere
  // in the DOM so it can fill the entire window
  return null
}
Spotlight.propTypes = {
  targetRect: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
}

export default Spotlight
