import settings from 'settings'

import { api } from 'app/views/utils/api'
import { renderedInSJApp } from 'app/views/content/LessonUI/util'
import { notifyParentSjApp } from 'app/sjInterop'

const prefix = 'progress'
const SETUP = `${prefix}/SETUP`
const TEARDOWN = `${prefix}/TEARDOWN`
const TRACKED_STEP = `${prefix}/TRACKED_STEP`
const SET_PENDING_STEP_INDEX = `${prefix}/SET_PENDING_STEP_INDEX`

const initialState = {
  contentId: null,
  templateName: null,
  maxStepIndexTracked: -1,
  pendingStepIndex: -1,
}

const ENABLE_VERBOSE_LOGGING = false
// eslint-disable-next-line no-console
const _log = ENABLE_VERBOSE_LOGGING ? console.log : () => { }
const _warn = ENABLE_VERBOSE_LOGGING ? console.warn : () => { }

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SETUP:
      return {
        ...state,
        contentId: action.contentId,
        templateName: action.contentId,
      }
    case TEARDOWN:
      return {
        ...state,
        contentId: null,
        templateName: null,
        maxStepIndexTracked: -1,
        pendingStepIndex: -1,
      }
    case TRACKED_STEP:
      return {
        ...state,
        maxStepIndexTracked: Math.max(
          state.maxStepIndexTracked,
          action.stepIndex
        ),
      }
    case SET_PENDING_STEP_INDEX:
      return {
        ...state,
        pendingStepIndex: action.stepIndex,
      }
    default:
      return state
  }
}

async function postProgress(contentId, userId, stepIndex) {
  // Causes api-hacker to update content_to_hacker; passing numSteps indicates lesson is complete
  const url = `${settings.urls.hacker}/user/${userId}/content/${contentId}`
  const data = { step_number: stepIndex }
  try {
    await api({ method: 'post', url, withAuthToken: true, data })
  } catch (err) {
    console.error('error posting progress', err)
  }
}

function canPostProgress(contentId, state) {
  if (!contentId) {
    _log('ignoring request to track progress: module not initialized')
    return false
  }
  if (state.auth.status !== 'LOGGED_IN') {
    _log('ignoring request to track progress: user is not logged in')
    return false
  }
  if (!state.hacker.profile) {
    _warn('unable to track progress: hacker has no profile')
    return false
  }
  const userId = state.hacker.profile.user_id
  if (!userId) {
    _warn('unable to track progress: hacker profile has no user_id')
    return false
  }
  return true
}

export function setup(contentId, templateName) {
  return (dispatch, getState) => {
    _log(
      `initializing progress module to track user progress for ${contentId}, ${templateName}`
    )
    dispatch({
      type: SETUP,
      contentId,
      templateName,
    })

    // If we received a request to track a step before we were ready, track that step now
    const state = getState()
    if (state.progress.pendingStepIndex >= 0) {
      if (canPostProgress(contentId, state)) {
        postProgress(
          state.progress.contentId,
          state.hacker.profile.user_id,
          state.progress.pendingStepIndex
        ).then(() => {
          _log(
            `tracked progress to step index ${state.progress.pendingStepIndex} (from pending)`
          )
          dispatch({
            type: TRACKED_STEP,
            stepIndex: state.progress.pendingStepIndex,
          })
        })
      }
      dispatch({ type: SET_PENDING_STEP_INDEX, stepIndex: -1 })
    }
  }
}

export function teardown() {
  _log('cleaning up progress module')
  return {
    type: TEARDOWN,
  }
}

export function track(stepIndex) {
  return (dispatch, getState) => {
    const state = getState()

    if (canPostProgress(state.progress.contentId, state)) {
      postProgress(
        state.progress.contentId,
        state.hacker.profile.user_id,
        stepIndex
      ).then(() => {
        _log(`tracked progress to step index ${stepIndex}`)
        dispatch({ type: TRACKED_STEP, stepIndex })

        // When hosted in an iframe in the SJ app, notify the app that it may want to
        // synchronize lesson status, since navigating to a new step may change whether
        // the user has 'completed' the lesson
        if (renderedInSJApp()) notifyParentSjApp({ type: 'lesson-status-changed' });
      })
    } else if (!state.progress.contentId) {
      _log(
        `received request to track progress before module init; setting pending step index to ${stepIndex}`
      )
      dispatch({
        type: SET_PENDING_STEP_INDEX,
        stepIndex,
      })
    }
  }
}
