import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Moment from 'react-moment'
import cx from 'classnames'

// Components
import ContentButton from 'app/views/components/ContentButton'
import ContentIcon from 'app/views/components/ContentIcon'
import ScratchpadButton from 'app/views/components/ScratchpadButton'
import AllTrainingMenu from 'app/views/components/AllTrainingMenu'

// Redux
import { getContent, getVulnerabilityTypes } from 'app/state/modules/content'

// Utils
import analytics from 'app/views/utils/analytics'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import ModalContentIcon from 'app/views/components/Modals/ModalContentIcon'
import SkeletonLoader from 'app/views/components/Loader/SkeletonLoader'

const propTypes = {
  getContent: PropTypes.func.isRequired,
  license: PropTypes.bool,
}
const defaultProps = {
  license: false,
}

class ByVulnerability extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      activeFilters: {},
    }
    this.onToggleVulnerability = this.onToggleVulnerability.bind(this)
    this.props.getVulnerabilityTypes()
  }

  componentDidMount() {
    // if (this.props.vulnerabilities.length > 0) {
    //   this.setState({
    //     activeFilters: this.props.vulnerabilities.reduce((prev, curr) => {
    //       prev[curr.id] = true
    //       return prev
    //     }, {}),
    //   })
    // }

    this.props.getContent()
    analytics.page('all')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.showAllTraining === false &&
      nextProps.organizations.some((item) => item.role === 'admin') !== true
    ) {
      this.props.history.push('/')
    }
    // if (this.props.vulnerabilities.length === 0 && nextProps.vulnerabilities.length > 0) {
    //   this.setState({
    //     activeFilters: nextProps.vulnerabilities.reduce((prev, curr) => {
    //       prev[curr.id] = true
    //       return prev
    //     }, {}),
    //   })
    // }
  }

  onToggleVulnerability(vulnerabilityId) {
    const { activeFilters } = this.state
    if (activeFilters[vulnerabilityId]) {
      delete activeFilters[vulnerabilityId]
    } else {
      activeFilters[vulnerabilityId] = true
    }
    this.setState({
      activeFilters,
    })
  }

  renderItemList() {
    let showAll = false
    if (Object.keys(this.state.activeFilters).length === 0) {
      showAll = true
    }

    return this.props.content.courses.map((course, i) => (
      <div id={`scroll-${i}`} key={course.uuid}>
        {course.items.map((item) => {
          if (item.item_type === 'lesson') {
            let hasVulnerabilitiesActive = false
            const hasVulnerabilities =
              item.content.reduce((prev, curr) => {
                const objects = Object.keys(curr.vulnerabilities || {})
                objects.forEach((vuln) => {
                  if (this.state.activeFilters[vuln]) {
                    hasVulnerabilitiesActive = true
                  }
                })
                return prev + objects.length
              }, 0) > 0
            if (
              (!hasVulnerabilities || !hasVulnerabilitiesActive) &&
              !showAll
            ) {
              return null
            }
            const hasCompletedContentList = []
            const isActiveForUserList = []
            const contentInProgressList = []
            let requiresSubscription = true
            return (
              <div key={item.uuid}>
                <div className='uk-panel'>
                  <div data-uk-grid=''>
                    <div className='uk-width-4-5'>
                      <h4 className='uk-margin-top uk-margin-remove-bottom'>
                        <a
                          href={`/lesson/${item.uuid}`}
                          className='uk-link-reset'
                        >
                          {item.title}
                        </a>
                      </h4>
                      <p className='text-sm uk-margin-small'>
                        {item.description}
                      </p>
                      {item.content.map((content) => {
                        const hasCodeSubmission =
                          content.code_submission_count > 0
                        const hasCompletedContent =
                          content.has_test &&
                            content.must_pass_coding_exercise &&
                            content.content_type === 'lesson'
                            ? !!content.passed_at
                            : !!content.completed_at
                        const hasPassedContent = !!content.passed_at
                        const contentInProgress = content.max_progress > 0
                        const isActiveForUser = content.is_active_for_user
                        isActiveForUserList.push(isActiveForUser)
                        hasCompletedContentList.push(hasCompletedContent)
                        contentInProgressList.push(contentInProgress)
                        if (content.requires_subscription === false) {
                          requiresSubscription = false
                        }
                        let disabledText = false
                        const objects = Object.keys(content.vulnerabilities)
                        const shouldShow = objects.some((vulnerabilityId) =>
                          Boolean(this.state.activeFilters[vulnerabilityId])
                        )
                        if (!shouldShow && !showAll) {
                          disabledText = true
                        }
                        const localLastCompletedTime =
                          content.has_test && content.must_pass_coding_exercise
                            ? getLocalDateFromUTC(content.passed_at)
                            : getLocalDateFromUTC(content.completed_at)
                        return (
                          <div
                            className='uk-margin-left'
                            key={content.uuid}
                            uk-tooltip={
                              disabledText
                                ? 'title: Not a match; pos: left'
                                : null
                            }
                          >
                            <div className='flex items-center uk-margin-small-top'>
                              <ContentIcon
                                disabled={disabledText}
                                hasCodeSubmission={hasCodeSubmission}
                                hasCompletedContent={hasCompletedContent}
                                hasPassedContent={hasPassedContent}
                                hasTest={content.has_test}
                                isActiveForUser={isActiveForUser}
                                lessonInProgress={contentInProgress}
                                license={this.props.license}
                                marginTop={0}
                                mustPassCodingExercise={
                                  content.must_pass_coding_exercise
                                }
                                requiresSubscription={
                                  content.requires_subscription
                                }
                                small
                              />
                              <h5
                                className={cx(
                                  'uk-inline uk-margin-remove',
                                  { 'text-muted': disabledText }
                                )}
                              >
                                <a
                                  href={`/content/${content.uuid}`}
                                  className='uk-link-reset'
                                >
                                  {content.title}
                                </a>
                              </h5>
                              {hasCompletedContent && (
                                <div
                                  className={cx(
                                    'italic text-sm uk-margin-left',
                                    { 'text-muted': disabledText }
                                  )}
                                >
                                  Last completed on{' '}
                                  <Moment format='MMMM D, YYYY'>
                                    {localLastCompletedTime}
                                  </Moment>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className='uk-width-1-5 text-right uk-margin-top'>
                      <ContentButton
                        isActiveForUser={isActiveForUserList.includes(true)}
                        hasCompletedContent={
                          !hasCompletedContentList.includes(false)
                        }
                        lessonInProgress={contentInProgressList.includes(true)}
                        license={this.props.license}
                        requiresSubscription={requiresSubscription}
                        small
                        url={`/lesson/${item.uuid}`}
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            )
          }
          const objects = Object.keys(item.vulnerabilities)
          if (objects.length === 0) {
            return null
          }
          const shouldShow = objects.some((vulnerabilityId) =>
            Boolean(this.state.activeFilters[vulnerabilityId])
          )
          if (!shouldShow && !showAll) {
            return null
          }
          const hasCodeSubmission = item.code_submission_count > 0
          const hasCompletedContent = !!item.completed_at
          const hasPassedContent = !!item.passed_at
          const lessonInProgress = item.max_progress > 0
          const isActiveForUser = item.is_active_for_user
          const localLastCompletedTime =
            item.has_test && item.passed_at
              ? getLocalDateFromUTC(item.passed_at)
              : getLocalDateFromUTC(item.completed_at)
          return (
            <div key={item.uuid}>
              <div className='uk-panel'>
                <div data-uk-grid=''>
                  <div className='uk-width-4-5'>
                    <div className='flex items-center'>
                      <ContentIcon
                        hasCodeSubmission={hasCodeSubmission}
                        hasCompletedContent={hasCompletedContent}
                        hasPassedContent={hasPassedContent}
                        hasTest={item.has_test}
                        isActiveForUser={isActiveForUser}
                        lessonInProgress={lessonInProgress}
                        license={this.props.license}
                        marginTop={0}
                        mustPassCodingExercise={item.must_pass_coding_exercise}
                        requiresSubscription={item.requires_subscription}
                      />
                      <h4 className='uk-inline uk-margin-top'>
                        <a
                          href={`/content/${item.uuid}`}
                          className='uk-link-reset'
                        >
                          {item.title}
                        </a>
                      </h4>
                    </div>
                    <p className='text-sm uk-margin-small uk-margin-remove-top'>
                      {item.description}
                    </p>
                    {hasCompletedContent && (
                      <div className='text-sm italic'>
                        Last completed on{' '}
                        <Moment format='MMMM D, YYYY'>
                          {localLastCompletedTime}
                        </Moment>
                      </div>
                    )}
                  </div>
                  <div className='uk-width-1-5 text-right uk-margin-top'>
                    <ContentButton
                      hasCompletedContent={hasCompletedContent}
                      lessonInProgress={lessonInProgress}
                      isActiveForUser={isActiveForUser}
                      license={this.props.license}
                      requiresSubscription={item.requires_subscription}
                      small
                      url={`/content/${item.uuid}`}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          )
        })}
      </div >
    ))
  }

  render() {
    if (this.props.loadingVulnerabilityTypes) {
      return <SkeletonLoader />
    }
    return (
      <div className='uk-hackedu-container-main'>
        <div className='uk-container'>
          <div className='uk-margin-medium-top'>
            <div data-uk-grid=''>
              <div className='uk-width-large'>
                <div>
                  <div
                    data-uk-sticky='offset: 150'
                    className='hackedu-content-menu'
                  >
                    <AllTrainingMenu
                      onToggleVulnerability={this.onToggleVulnerability}
                      vulnerabilityFilters={this.state.activeFilters}
                      vulnerabilities={this.props.vulnerabilities}
                      activeTab={this.state.activeTab}
                      onActiveTab={(vulnerabilityTypeId) => {
                        this.setState({
                          activeTab: vulnerabilityTypeId,
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='uk-width-expand'>
                <div className='uk-width-expand uk-section-small'>
                  <h1>Vulnerability Types</h1>
                  <p>
                    Pick the vulnerability type to show from the left side menu.
                  </p>
                  {this.renderItemList()}
                  <div style={{ height: '100vh', display: 'block' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalContentIcon />
        <ScratchpadButton id='by-vulnerability' />
      </div>
    )
  }
}

ByVulnerability.propTypes = propTypes
ByVulnerability.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  // auth
  email: state.auth.user.email,

  // content
  content: state.content.content,
  loadingContent: state.content.loadingContent,
  loadingVulnerabilityTypes: state.content.loadingVulnerabilityTypes,
  vulnerabilities: state.content.vulnerabilityTypes,

  // hacker
  organizations: state.hacker.organizationsList,
  license: state.hacker.license,
  mustPassCodingExercise: state.hacker.mustPassCodingExercise,
  showAllTraining: state.hacker.showAllTraining,
  showCodingChallenges: state.hacker.showCodingChallenges,
})
const mapDispatchToProps = (dispatch) => ({
  getContent: () => {
    dispatch(getContent())
  },
  getVulnerabilityTypes: () => {
    dispatch(getVulnerabilityTypes())
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ByVulnerability)
)
