import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory } from 'history'

import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { connectRouter } from 'connected-react-router'

// Modules
import auth from './modules/auth'
import codeReview from './modules/codeReview'
import content, { globalReducer as contentGlobal } from './modules/content'
import deeplinks from './modules/deeplinks'
import hacker from './modules/hacker'
import leaderboard from './modules/leaderboard'
import plan from './modules/plan'
import proxy, { globalReducer as proxyGlobal } from './modules/proxy'
import register from './modules/register'
import repl from './modules/repl'
import reports from './modules/reports'
import impactReport from './modules/impactReport'
import sso from './modules/sso'
import team from './modules/team'
import users from './modules/users'
import linter from './modules/linter'
import integrations from './modules/integrations'
import notifications from './modules/notifications'
import k8s from './modules/k8s'
import tty from './modules/tty'
import sandbox from './modules/sandbox'
import sbproxy from './modules/sbproxy'
import sbrepl from './modules/sbrepl'
import progress from './modules/progress'
import sbcrfilesystem from './modules/sbcrfilesystem'
import sbcrsubmission from './modules/sbcrsubmission'
import sbrelay from './modules/sbrelay'
import hint from './modules/hint'
import codesub from './modules/codesub'
import { IS_DEPLOYMENT_MODE } from '../constants/env'

function reduceReducers(...reducers) {
  return function allReducer(prevState = {}, action = null) {
    return reducers.reduce((state, fn) => fn(state, action), prevState)
  }
}

export const history = createBrowserHistory()
const configureStore = (initialState = {} /* , history */) => {
  const middlewares = [
    thunkMiddleware,
    // routerMiddleware(history),
  ]
  const enhancers = [applyMiddleware(...middlewares)]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    !IS_DEPLOYMENT_MODE &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose
  /* eslint-enable */

  const createGlobalReducer = (h) =>
    combineReducers({
      auth,
      codeReview,
      content,
      deeplinks,
      hacker,
      leaderboard,
      plan,
      proxy,
      register,
      repl,
      reports,
      impactReport,
      sso,
      team,
      users,
      linter,
      integrations,
      k8s,
      notifications,
      tty,
      sandbox,
      sbproxy,
      sbrepl,
      progress,
      sbcrfilesystem,
      sbcrsubmission,
      sbrelay,
      hint,
      codesub,
      router: connectRouter(h),
    })

  return createStore(
    reduceReducers(createGlobalReducer(history), proxyGlobal, contentGlobal),
    initialState,
    composeEnhancers(...enhancers)
  )
}

const initialState = {}

export default configureStore(initialState)
