import { values } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import UIkit from 'uikit'
import Flatpickr from 'react-flatpickr'
import * as moment from 'moment'

// Components
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import ModalActiveTeamPlan from 'app/views/components/Modals/Info/ModalActiveTeamPlan'
import ModalDefaultPlan from 'app/views/components/Modals/Info/ModalDefaultPlan'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import Icon from 'app/views/components/Icon'

// Redux
import {
  deletePlanById,
  getPlans,
  getPlanById,
  updatePlanById,
} from 'app/state/modules/plan'

// Utils
import analytics from 'app/views/utils/analytics'

class OrganizationsPlansEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: '',
      deleteError: '',
      title: '',
      description: '',
      defaultPlan: false,
      sendReminders: false,
      confirmDelete: false,
      planType: 'date',
      dates: {},
      days: {},
    }

    this.handleChangeTitle = this.handleChangeTitle.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
    this.handleChangeDefaultPlan = this.handleChangeDefaultPlan.bind(this)
    this.handleChangeSendReminders = this.handleChangeSendReminders.bind(this)
    this.handleSubmitSave = this.handleSubmitSave.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
    this.handleChangePlanType = this.handleChangePlanType.bind(this)
  }

  componentDidMount() {
    this.props.getPlanById(
      this.props.match.params.organization_uuid,
      this.props.match.params.plan_uuid
    )
    if (
      this.props.match.params.plan_uuid in this.props.planMap &&
      this.props.planMap[this.props.match.params.plan_uuid].uuid !== null
    ) {
      this.setState({
        uuid: this.props.planMap[this.props.match.params.plan_uuid].uuid,
        title: this.props.planMap[this.props.match.params.plan_uuid].title,
        description:
          this.props.planMap[this.props.match.params.plan_uuid].description,
        defaultPlan:
          this.props.planMap[this.props.match.params.plan_uuid].default_plan,
        sendReminders:
          this.props.planMap[this.props.match.params.plan_uuid].send_reminders,
        planType:
          this.props.planMap[this.props.match.params.plan_uuid] &&
          this.props.planMap[this.props.match.params.plan_uuid].is_time_based
            ? 'time'
            : 'date',
        teamUUID:
          this.props.planMap[this.props.match.params.plan_uuid].team_uuid,
        days: this.props.planMap[
          this.props.match.params.plan_uuid
        ].groups.reduce((p, c) => {
          p[c.uuid] = '30'
          return p
        }, {}),
      })
    }

    analytics.page('page-organizations-plans-edit')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (!(this.props.match.params.plan_uuid in this.props.planMap) &&
        this.props.match.params.plan_uuid in nextProps.planMap) ||
      (this.props.match.params.plan_uuid in this.props.planMap &&
        this.props.match.params.plan_uuid in nextProps.planMap &&
        this.props.planMap[this.props.match.params.plan_uuid].uuid !==
          nextProps.planMap[this.props.match.params.plan_uuid].uuid)
    ) {
      this.setState({
        uuid: nextProps.planMap[this.props.match.params.plan_uuid].uuid,
        title: nextProps.planMap[this.props.match.params.plan_uuid].title,
        description:
          nextProps.planMap[this.props.match.params.plan_uuid].description,
        defaultPlan:
          nextProps.planMap[this.props.match.params.plan_uuid].default_plan,
        sendReminders:
          nextProps.planMap[this.props.match.params.plan_uuid].send_reminders,
        planType:
          nextProps.planMap[this.props.match.params.plan_uuid] &&
          nextProps.planMap[this.props.match.params.plan_uuid].is_time_based
            ? 'time'
            : 'date',
        teamUUID:
          nextProps.planMap[this.props.match.params.plan_uuid].team_uuid,
        days: nextProps.planMap[
          this.props.match.params.plan_uuid
        ].groups.reduce((p, c) => {
          p[c.uuid] = '30'
          return p
        }, {}),
      })
    }

    if (nextProps !== this.props.deletingPlanByIdError) {
      this.setState({ deleteError: nextProps.deletingPlanByIdError })
    }
  }

  handleChangeTitle(e) {
    this.setState({ title: e.target.value })
  }

  handleChangeDescription(e) {
    this.setState({ description: e.target.value })
  }

  handleChangeDefaultPlan(e) {
    this.setState({ defaultPlan: e.target.checked })
  }

  handleChangePlanType(e) {
    this.setState({ planType: e.target.name })
  }

  handleChangeSendReminders(e) {
    this.setState({ sendReminders: e.target.checked })
  }

  handleSubmitSave(event) {
    event.preventDefault()
    if (
      this.state.planType === 'time' &&
      this.props.planMap[this.props.match.params.plan_uuid].is_time_based ===
        false
    ) {
      const errorDays = Object.values(this.state.days).some(
        (d) => typeof d !== 'string' || d.length === 0 || d === '0'
      )
      if (errorDays) {
        return this.setState({
          error: 'The duration of the phases must be greater than 0',
        })
      }
    }
    if (
      this.state.planType === 'date' &&
      this.props.planMap[this.props.match.params.plan_uuid].is_time_based ===
        true
    ) {
      const hasInvaidDate = Object.keys(this.state.dates).some((phaseUUID) => {
        if (
          this.state.dates[phaseUUID].endDate &&
          this.state.dates[phaseUUID].startDate &&
          moment(this.state.dates[phaseUUID].endDate) <
            moment(this.state.dates[phaseUUID].startDate)
        ) {
          return true
        }
        return false
      })
      if (hasInvaidDate) {
        return this.setState({
          error: 'End date of phase cannot be before start date',
        })
      }
    }
    if (this.state.title === '') {
      this.setState({ error: 'Title is required.' })
    } else {
      this.props.updatePlanById(
        this.props.match.params.organization_uuid,
        this.props.match.params.plan_uuid,
        this.state.title,
        this.state.description,
        this.state.defaultPlan,
        this.state.sendReminders,
        this.state.teamUUID,
        () => {
          if (this.state.teamUUID) {
            this.props.history.push(
              `/admin/${this.props.match.params.organization_uuid}/teams/${this.state.teamUUID}/plans`
            )
          } else {
            this.props.history.push(
              `/admin/${this.props.match.params.organization_uuid}/plans`
            )
          }
        },
        (error) => {
          if (error.includes('plan with')) {
            UIkit.modal.alert(
              'A plan with this title already exists. You can\'t have more than one plan with the same title.',
              {
                stack: true,
              }
            )
          } else {
            this.setState({
              error,
            })
          }
        },
        null,
        this.props.planMap[this.props.match.params.plan_uuid].is_time_based
          ? 'time'
          : 'date',
        this.state.planType,
        this.state.planType === 'time' ? this.state.days : this.state.dates
      )
    }
    return true
  }

  handleDeleteClick(e) {
    e.preventDefault()

    this.setState({ confirmDelete: true, deleteError: '' })
  }

  handleDeleteConfirm(e) {
    e.preventDefault()

    this.props
      .deletePlanById(
        this.props.match.params.organization_uuid,
        this.props.match.params.plan_uuid
      )
      .then((success) => {
        if (success === true) {
          this.setState({ confirmDelete: false })
          this.props.history.push(
            `/admin/${this.props.match.params.organization_uuid}/plans`
          )
        }
      })
  }

  handleDeleteCancel(e) {
    e.preventDefault()

    this.setState({ confirmDelete: false })
  }

  renderChangePlanType() {
    if (!this.props.planMap[this.props.match.params.plan_uuid]) {
      return null
    }
    const currentType = this.props.planMap[this.props.match.params.plan_uuid]
      .is_time_based
      ? 'time'
      : 'date'
    const { planType } = this.state
    if (currentType === 'date' && planType === 'time') {
      return this.props.planMap[this.props.match.params.plan_uuid].groups.map(
        (phase, index) => {
          const { title, uuid } = phase
          return (
            <React.Fragment key={uuid}>
              {index === 0 && (
                <>
                  <h4 className='uk-margin-remove'>Phases</h4>
                  <small>
                    You must assign a default time in days for each phase if you
                    want to change the plan type to time based.
                  </small>
                </>
              )}
              <div className='flex flex-row uk-margin-small-top items-center'>
                <div
                  style={{
                    width: 200,
                  }}
                >
                  <i>
                    <small>{title}</small>
                  </i>
                </div>
                <input
                  onChange={(e) => {
                    const { days } = this.state
                    this.setState({
                      days: {
                        ...days,
                        [uuid]: e.target.value.replace(/[^0-9]/, ''),
                      },
                    })
                  }}
                  value={this.state.days[uuid]}
                  style={{
                    width: 50,
                  }}
                  className='uk-input uk-form-small uk-margin-small-left text-center'
                />
                <small className='uk-margin-small-left'>days</small>
              </div>
            </React.Fragment>
          )
        }
      )
    }
    if (currentType === 'time' && planType === 'date') {
      return this.props.planMap[this.props.match.params.plan_uuid].groups.map(
        (phase, index) => {
          const { title, uuid } = phase
          return (
            <React.Fragment key={uuid}>
              {index === 0 && (
                <>
                  <h4 className='uk-margin-remove'>Phases</h4>
                  <small>
                    You can assign a start and end date for each phase. You
                    could also leave them blank, in that case all phases will be
                    assigned at once.
                  </small>
                </>
              )}
              <div className='flex flex-row uk-margin-small-top items-center'>
                <div
                  style={{
                    width: 200,
                  }}
                >
                  <i>
                    <small>{title}</small>
                  </i>
                </div>
                <Flatpickr
                  className='uk-input uk-form-small uk-margin-small-left'
                  style={{
                    width: 200,
                  }}
                  // value={this.state.startDate}
                  onChange={(date) => {
                    const { dates } = this.state
                    this.setState({
                      dates: {
                        ...dates,
                        [uuid]: {
                          ...(dates[uuid] || {}),
                          startDate: date[0],
                        },
                      },
                    })
                  }}
                />
                <span className='uk-margin-small-left uk-margin-small-right'>
                  -
                </span>
                <Flatpickr
                  className='uk-input uk-form-small'
                  style={{
                    width: 200,
                  }}
                  onChange={(date) => {
                    const { dates } = this.state
                    this.setState({
                      dates: {
                        ...dates,
                        [uuid]: {
                          ...(dates[uuid] || {}),
                          endDate: date[0],
                        },
                      },
                    })
                  }}
                />
              </div>
            </React.Fragment>
          )
        }
      )
    }
    return null
  }

  render() {
    return (
      <OrganizationLayout {...this.props} active='plans'>
        {this.state.confirmDelete === false ? (
          <>
            <h1>
              {this.state.teamUUID
                ? 'Edit Team Training Plan'
                : 'Edit Training Plan'}
            </h1>
            <div className='uk-card uk-card-default uk-card-body'>
              <div className='uk-container-small'>
                <form className='uk-form-stacked'>
                  <div className='uk-margin'>
                    <label className='uk-form-label'>Title</label>
                    <div className='uk-form-controls'>
                      <input
                        className='uk-input'
                        type='text'
                        placeholder=''
                        value={this.state.title}
                        onChange={this.handleChangeTitle}
                      />
                    </div>
                  </div>

                  <div className='uk-margin'>
                    <label className='uk-form-label'>Description</label>
                    <div className='uk-form-controls'>
                      <input
                        className='uk-input'
                        type='text'
                        placeholder=''
                        value={this.state.description}
                        onChange={this.handleChangeDescription}
                      />
                    </div>
                  </div>
                  <div className='uk-margin uk-grid-small uk-child-width-auto uk-grid'>
                    <label>
                      <input
                        onChange={this.handleChangePlanType}
                        className='uk-radio'
                        type='radio'
                        name='date'
                        checked={this.state.planType === 'date'}
                      />
                      {' Date based'}
                    </label>
                    <label>
                      <input
                        onChange={this.handleChangePlanType}
                        className='uk-radio'
                        type='radio'
                        name='time'
                        checked={this.state.planType === 'time'}
                      />
                      {' Time based'}
                    </label>
                  </div>

                  {this.renderChangePlanType()}

                  <div className='uk-margin'>
                    <div className='uk-form-controls'>
                      {this.state.teamUUID ? (
                        <></>
                      ) : (
                        <>
                          <input
                            className='uk-checkbox'
                            type='checkbox'
                            checked={this.state.defaultPlan}
                            onChange={this.handleChangeDefaultPlan}
                            style={{ marginRight: 10 }}
                          />
                          Default Plan
                          <Icon
                            href='#'
                            name='information'
                            ratio={0.8}
                            data-uk-toggle='target: #modal-info-default-plan'
                            style={{ marginLeft: 8 }}
                          />
                          <ModalDefaultPlan />
                        </>
                      )}
                    </div>
                  </div>
                  <div id='modal-default-plan' data-uk-modal>
                    <div className='uk-modal-dialog uk-modal-body'>
                      {this.state.teamUUID ? (
                        <ModalActiveTeamPlan />
                      ) : (
                        <ModalDefaultPlan />
                      )}
                      <p className='text-right'>
                        <button
                          className='uk-button uk-button-primary uk-modal-close'
                          type='button'
                        >
                          Ok
                        </button>
                      </p>
                      <button
                        className='uk-modal-close-outside'
                        type='button'
                        data-uk-close
                      />
                    </div>
                  </div>

                  {this.state.error !== '' && (
                    <div className='uk-margin'>
                      <p className='text-danger'>{this.state.error}</p>
                    </div>
                  )}

                  <div className='uk-margin'>
                    <div className='uk-form-controls'>
                      <PrimaryButton
                        size={'medium'}
                        onClick={this.handleSubmitSave}
                        label='Save Changes'
                      />

                      <a
                        data-test-id='delete-plan-btn'
                        href='#'
                        style={{ marginLeft: 10 }}
                        onClick={this.handleDeleteClick}
                        className='uk-button uk-button-danger'
                      >
                        Delete Training Plan
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          <>
            <h1>Delete Training Plan</h1>
            <div
              className='uk-card uk-card-default uk-card-body'
              data-test-id='delete-confirm-container'
            >
              <div className='uk-container-small'>
                <p>
                  Are you sure you want to delete the training plan "
                  {this.state.title}
                  "?
                </p>
                <form className='uk-form-stacked'>
                  {this.state.deleteError !== '' && (
                    <div className='uk-margin'>
                      <p className='text-danger'>{this.state.deleteError}</p>
                    </div>
                  )}
                  <div className='uk-margin'>
                    <div className='uk-form-controls'>
                      <button
                        type='button'
                        className='uk-button uk-button-danger tm-button-default'
                        onClick={this.handleDeleteConfirm}
                        data-test-id='confirm-delete-plan-btn mr-2'
                      >
                        Delete
                      </button>
                      <button
                        type='button'
                        className='uk-button uk-button tm-button-default ml-2'
                        onClick={this.handleDeleteCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  // content
  courses: state.content.courses,
  lessonMap: state.content.lessonMap,
  tests: state.content.tests,

  // hacker
  organizations: values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,
  // plans
  planMap: state.plan.planMap,
  deletingPlanByIdError: state.plan.deletingPlanByIdError,

  // user
  email: state.auth.user.email,
})
const mapDispatchToProps = (dispatch) => ({
  updatePlanById: (
    organizationId,
    planId,
    title,
    description,
    defaultPlan,
    sendReminders,
    teamUUID,
    succcessCallback,
    errorCallback,
    noTraining,
    oldType,
    newType,
    typeData
  ) =>
    dispatch(
      updatePlanById(
        organizationId,
        planId,
        title,
        description,
        defaultPlan,
        sendReminders,
        teamUUID,
        succcessCallback,
        errorCallback,
        noTraining,
        oldType,
        newType,
        typeData
      )
    ),
  deletePlanById: (organizationId, planId) =>
    dispatch(deletePlanById(organizationId, planId)),
  getPlanById: (organizationId, planId) =>
    dispatch(getPlanById(organizationId, planId)),
  getPlans: (organizationUUID) => dispatch(getPlans(organizationUUID)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsPlansEdit)
)
