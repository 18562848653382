import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'app/views/components/GustavoIcon'

const getStyle = (size, absolute, color) => ({
  width: size,
  position: absolute ? 'absolute' : undefined,
  color: color,
})

const PendingIcon = ({ size, absolute }) => (
  <Icon
    fill
    ratio={size / 20}
    style={getStyle(size, absolute, '#c8c8c8')}
    name='loader-4'
    className='rotate-360'
  />
)
const PassedIcon = ({ size, absolute }) => (
  <Icon
    fill
    ratio={size / 20}
    style={getStyle(size, absolute, '#1f87f0')}
    name='checkbox-circle'
  />
)
const FailedIcon = ({ size, absolute }) => (
  <Icon
    fill
    ratio={size / 20}
    style={getStyle(size, absolute, '#eb5756')}
    name='close-circle'
  />
)
const SkippedIcon = ({ size, absolute }) => (
  <Icon
    fill
    ratio={size / 20}
    style={getStyle(size, absolute, '#c8c8c8')}
    name='checkbox-blank-circle'
  />
)

function TestStatusIcon(props) {
  const { status, absolute } = props
  const size = props.size || 20
  if (status === 'pending')
    return <PendingIcon size={size} absolute={absolute} />
  if (status === 'passed') return <PassedIcon size={size} absolute={absolute} />
  if (status === 'failed') return <FailedIcon size={size} absolute={absolute} />
  return <SkippedIcon size={size} absolute={absolute} />
}
TestStatusIcon.propTypes = {
  status: PropTypes.oneOf(['pending', 'passed', 'failed', 'skipped'])
    .isRequired,
  absolute: PropTypes.bool,
  size: PropTypes.number,
}

export default TestStatusIcon
