import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ContentUI from '../common/ContentUI'
import ContentPingBeacon from '../common/ContentPingBeacon'
import InactiveSandboxModal from '../common/InactiveSandboxModal'
import InstructionPanel from '../common/InstructionPanel'
import WorkPanel from '../common/WorkPanel'
import { useSandboxInactivityTimeout } from '../common/util'
import { useServiceShimLessonState } from '../../utils/apiServiceShim'
import { TournamentDetailsProps } from 'app/sjTournament'

import CodingChallengeInstructions from './CodingChallengeInstructions'
import CodingChallengeCompletionModal from './CodingChallengeCompletionModal'

import { connect } from 'react-redux'
import * as hint from 'app/state/modules/hint'
import * as sandbox from 'app/state/modules/sandbox'
import * as sbproxy from 'app/state/modules/sbproxy'
import * as sbcrsubmission from 'app/state/modules/sbcrsubmission'
import * as codesub from 'app/state/modules/codesub'
import * as progress from 'app/state/modules/progress'
import { g as SvcShimState } from 'app/views/utils/apiServiceShim'

function CodingChallengeUI(props) {
  // CodingChallengeUI isn't mounted until the content details have been fetched, so
  // these values are immutable for the lifetime of the component
  const {
    contentId,
    contentDetails,
    sandboxTemplateName,
    sandboxIsReady,
    hasPassingTest,
    requirePassingTest,
    teardownProgress,
    trackProgress,
    setupProgress,
  } = props
  const hasSandboxTemplate = sandboxTemplateName.length > 0

  // On mount, notify the API-to-service shim that we're now presenting the user with
  // this lesson, and clear that state on unmount
  const lessonKey = contentDetails['lesson_key'] || ''
  useServiceShimLessonState(contentId, lessonKey)

  // Within the redux state for our current content, our 'hint' objects are duplicated
  // in a couple of places: there's a 'hints' array that contains all objects, but the
  // relevant subset of those objects are copied into a 'hints' array within the
  // relevant item in the 'steps' array. However, only hints[*] contains a 'points'
  // field: steps[*].hints[*] does not include any data parsed from the metadata. This
  // is exceedingly silly, and we should change how we handle this state. In the
  // meantime, though, let's just resolve all the information we need from both places.
  //
  // We also need to merge in a "used" flag by checking the content_to_hacker metadata
  // to see if it indicates that a hint matching the given ID has been used. This data
  // structure is mirrored separately, in the content details object.
  //
  // Finally, we need to merge in the 'state' object from the hint module: this contains
  // the state of our request to get the full details of the hint, including isLoading,
  // error, and data.
  const { hintStatesById, highlightHintDataAccumulator, requestHintData } =
    props
  const sortedSteps = contentDetails.steps.sort(
    (a, b) => a.sort_order - b.sort_order
  )
  const actualSteps = sortedSteps.map((step) => ({
    ...step,
    hints: step.hints.map((hint) => ({
      ...hint,
      ...contentDetails.hints.find((x) => x.id === hint.id),
      hasBeenUsed:
        (contentDetails.hacker_metadata.usedHints || {})[hint.id] !== undefined,
      state: hintStatesById[hint.id],
    })),
  }))

  // Separately, find the data for any hints of type 'highlight', so we can feed those
  // regions into the MultiFileCodeEditor as regions to highlight. Also maintain a flag
  // indicating whether the user has dismissed highlight hints by editing the file etc.
  // after hints have been displayed.
  let codeEditorHighlightRegions = []
  const [hasDismissedHighlightHints, setHasDismissedHighlightHints] =
    useState(false)
  const hasHighlightData = (hintState) => {
    if (hasDismissedHighlightHints) return false
    if (hintState.isLoading || hintState.error || !hintState.data) return false
    return hintState.data.metadata && hintState.data.metadata.highlight
  }
  Object.values(hintStatesById)
    .filter(hasHighlightData)
    .forEach((hintState) => {
      for (const highlightRegion of hintState.data.metadata.highlight) {
        codeEditorHighlightRegions.push(highlightRegion)
      }
    })

  // Whenever we get new hint data for a 'highlight'-type hint, clear the hint
  // dismissal flag, even if the hint data itself is identical
  useEffect(() => {
    setHasDismissedHighlightHints(false)
  }, [highlightHintDataAccumulator])

  const { sendCodeChallengeCompleteEventSj } = useFlags()

  // Request a sandbox (without forceNew) on the initial load, and clean up sandbox
  // state when done
  const { requestNewSandbox, requestSandboxReset, abandonSandbox } = props
  useEffect(() => {
    if (hasSandboxTemplate) {
      requestNewSandbox(contentId, sandboxTemplateName)
    }

    if (sendCodeChallengeCompleteEventSj) {
      // Initialize the progress module to notify api-hacker of the user's progress through the lesson
      setupProgress(contentId, sandboxTemplateName)
    }

    if (sendCodeChallengeCompleteEventSj) {
      return () => {
        abandonSandbox()
        teardownProgress()
      }
    } else {
      return () => {
        abandonSandbox()
      }
    }
  }, [])

  // If the user sits too long without interacting with the sandbox, show a modal
  // forcing them to request a new sandbox
  const sandboxIsInactive = useSandboxInactivityTimeout(120 * 60 * 1000)

  const ideEnabled = contentDetails?.metadata?.ideTabIdx !== undefined

  // Don't try to switch to a non-existent code editor tab if the IDE is enabled
  const defaultActiveTabName = ideEnabled
    ? `browser-${contentDetails.metadata.ideTabIdx}`
    : 'code'

  const [activeTabName, setActiveTabName] = useState(defaultActiveTabName)

  // Allow the user to reclaim screen real estate if they're done reading
  // instructions/hints
  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  // Get proxy-related state to feed into child components
  const { numProxyRequests, interceptedRequest, resumeInterceptedRequest } =
    props

  // For codereviews, the backend needs to pull the user's code directly off the
  // sandbox, so we only allow one submission at a time, we can't submit without an
  // active sandbox, we lock the user out of making edits while their code is being
  // evaluated, and all of this is a matter of global state
  const {
    isSubmittingCodeToHacker,
    isSubmittingCodeToSvc,
    isHackerSubmissionPending,
    isSvcSubmissionPending,
    passingSubmissionAccumulator,
    submissionPassedAt,
    isRevertingCode,
  } = props

  // If using svc-code-sub and svc-sandbox instead of api-hacker for submissions,
  // initiate submissions via the codesub module
  const { engUseSvcCodeSub } = useFlags()
  const isSubmittingCode = engUseSvcCodeSub
    ? isSubmittingCodeToSvc
    : isSubmittingCodeToHacker
  const isSubmissionPending = engUseSvcCodeSub
    ? isSvcSubmissionPending
    : isHackerSubmissionPending

  // Initialize the codesub module to fetch submission history from svc-code-sub (we
  // fetch from svc-code-sub regardless, and conditionally display submission data from
  // one source or the other based on the eng_use_svc_code_sub feature flag)
  const { initSvcCodeSub, teardownSvcCodeSub } = props
  useEffect(() => {
    initSvcCodeSub(lessonKey, props.tournamentDetails)
    return () => {
      teardownSvcCodeSub()
    }
  }, [])

  // When the user submits code for testing and the submission passes, automatically set
  // the wantsToCompleteChallenge flag, which will trigger a dialog congratulating the
  // user and allowing them to submit feedback and/or exit the challenge
  const [wantsToCompleteChallenge, setWantsToCompleteChallenge] =
    useState(false)
  useEffect(() => {
    if (passingSubmissionAccumulator > 0) {
      setWantsToCompleteChallenge(true)
    }
  }, [passingSubmissionAccumulator])

  // When we call api-hacker's POST /code route to submit code for testing, we're
  // required to supply an 'engine' parameter, which will be stored with the new
  // code_submission row. For lessons, this parameter determines which repl engine will
  // be used to test the code; for codereviews it does not appear to be significant to
  // the functioning of the tests. Challenges should have metadata.language defined (as
  // a {name, version} object), so we'll use that name if present, but simply fall back
  // to a placeholder if that value is missing.
  const languageName =
    (contentDetails.metadata.language || {}).name || 'unknown'

  // If metadata.noBrowser is true, the sandbox doesn't serve any user-facing HTML and
  // so we should refrain from rendering a browser tab or proxy controls
  const sandboxServesHTTP = contentDetails.metadata.noBrowser !== true

  const defaultTabs = [
    {
      name: 'App',
      app_name: 'app',
      proxy: true,
      show_url_bar: true,
      clean_url: 'http://app.com',
    },
  ]

  const browserTabs = contentDetails.tabs?.length
    ? contentDetails.tabs.sort((a, b) => a.sort_order - b.sort_order)
    : defaultTabs

  const onSubmitCode = () => {
    if (engUseSvcCodeSub) {
      props.initiateSvcCodeSubmission(
        SvcShimState.lesson.key,
        SvcShimState.lesson.loadedAt,
        props.tournamentDetails
      )
    } else {
      props.initiateHackerCodeSubmission(languageName)
    }
  }

  const onFocusActiveCodeSubmission = () => setActiveTabName('submissions')

  // Once code is submitted and awaiting evaluation, automatically switch to the
  // submissions tab
  useEffect(() => {
    if (isSubmissionPending) {
      setActiveTabName('submissions')
    }
  }, [isSubmissionPending])

  // As soon as we start reverting to a prior submission, automatically switch to the
  // code editor tab
  useEffect(() => {
    if (isRevertingCode && activeTabName == 'submissions') {
      setActiveTabName(defaultActiveTabName)
    }
  }, [isRevertingCode])

  // The IDE has trouble rendering if it doesn't have access to the browser's
  // viewport (i.e. a non-IDE sandbox tab is active) when the sandbox is loaded
  // So we switch to the IDE tab as soon as the sandbox is ready
  useEffect(() => {
    if (ideEnabled && sandboxIsReady) {
      setActiveTabName(defaultActiveTabName)
    }
  }, [sandboxIsReady])

  const toggleTrialModal = useCallback((tab, showVaue) => {
    setActiveTabName(tab)
    setShowUpgradeModal(showVaue)
  }, [])

  return (
    <ContentUI>
      {sandboxIsInactive && (
        <InactiveSandboxModal
          onClose={() => requestNewSandbox(contentId, sandboxTemplateName)}
        />
      )}
      {!sandboxIsInactive && <ContentPingBeacon contentId={contentId} />}
      {wantsToCompleteChallenge && (
        <CodingChallengeCompletionModal
          contentId={contentId}
          onClose={() => setWantsToCompleteChallenge(false)}
        />
      )}
      {sendCodeChallengeCompleteEventSj ? (
        <InstructionPanel
          contentId={contentId}
          title={contentDetails.title}
          narrow
          hidden={sidebarIsCollapsed}
        >
          {(scrollToTop) => (
            <CodingChallengeInstructions
              contentId={contentId}
              steps={actualSteps}
              submissionPassedAt={submissionPassedAt}
              scrollToTop={scrollToTop}
              requestHintData={requestHintData}
              requestSandboxReset={() =>
                requestSandboxReset(contentId, sandboxTemplateName)
              }
              submissionProps={{
                ideEnabled,
                onSubmitCode,
                isSubmittingCode,
                isSubmissionPending,
                onFocusActiveCodeSubmission,
                sandboxIsReady,
              }}
              hasTests={contentDetails.has_tests}
              hasPassingTest={hasPassingTest}
              requirePassingTest={requirePassingTest}
              trackProgress={trackProgress}
              setWantsToCompleteLesson={setWantsToCompleteChallenge}
            />
          )}
        </InstructionPanel>
      ) : (
        <InstructionPanel
          contentId={contentId}
          title={contentDetails.title}
          narrow
          hidden={sidebarIsCollapsed}
        >
          {(scrollToTop) => (
            <CodingChallengeInstructions
              contentId={contentId}
              steps={actualSteps}
              submissionPassedAt={submissionPassedAt}
              scrollToTop={scrollToTop}
              requestHintData={requestHintData}
              requestSandboxReset={() =>
                requestSandboxReset(contentId, sandboxTemplateName)
              }
              submissionProps={{
                ideEnabled,
                onSubmitCode,
                isSubmittingCode,
                isSubmissionPending,
                onFocusActiveCodeSubmission,
                sandboxIsReady,
              }}
            />
          )}
        </InstructionPanel>
      )}
      <WorkPanel
        contentId={contentId}
        contentUsesRealUrls={true}
        sandboxTemplateName={sandboxTemplateName}
        browserTabs={sandboxServesHTTP ? browserTabs : []}
        hasCode={true}
        hasMultiFileCode={true}
        codeEditorProps={{
          isLoading: !sandboxIsReady,
          defaultFilePath: contentDetails.metadata.defaultFile,
          lockedFilePaths: Object.keys(
            contentDetails.metadata.lockedFiles || {}
          ).reduce(
            (acc, x) =>
              contentDetails.metadata.lockedFiles[x] ? acc.concat([x]) : acc,
            []
          ),
          highlightRegions: codeEditorHighlightRegions,
          onDismissHighlightHints: () => setHasDismissedHighlightHints(true),
          sidebarIsCollapsed: sidebarIsCollapsed,
          onToggleSidebar: () => setSidebarIsCollapsed(!sidebarIsCollapsed),
          isSubmittingCode,
          isCodeSubmissionPending: isSubmissionPending,
          isCodeRevertPending: isRevertingCode,
          onSubmitCode,
          onFocusActiveCodeSubmission,
        }}
        hasProxy={sandboxServesHTTP}
        hasTests={true}
        hasMultiFileTests={true}
        hasOutput={true}
        activeTabName={activeTabName}
        onActiveTabNameChange={setActiveTabName}
        numProxyRequests={sandboxServesHTTP ? numProxyRequests : 0}
        interceptedRequest={sandboxServesHTTP ? interceptedRequest : null}
        onSubmitInterceptedRequest={
          sandboxServesHTTP ? resumeInterceptedRequest : () => {}
        }
        ideEnabled={ideEnabled}
        toggleTrialModal={toggleTrialModal}
        showUpgradeModal={showUpgradeModal}
      />
    </ContentUI>
  )
}
CodingChallengeUI.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentDetails: PropTypes.object.isRequired,
  tournamentDetails: TournamentDetailsProps,
  sandboxTemplateName: PropTypes.string.isRequired,

  hintStatesById: PropTypes.object.isRequired,
  highlightHintDataAccumulator: PropTypes.number.isRequired,

  sandboxIsReady: PropTypes.bool.isRequired,

  numProxyRequests: PropTypes.number.isRequired,
  interceptedRequest: PropTypes.object,

  isSubmittingCodeToHacker: PropTypes.bool.isRequired,
  isSubmittingCodeToSvc: PropTypes.bool.isRequired,
  isHackerSubmissionPending: PropTypes.bool.isRequired,
  isSvcSubmissionPending: PropTypes.bool.isRequired,
  initiateHackerCodeSubmission: PropTypes.func.isRequired,
  initiateSvcCodeSubmission: PropTypes.func.isRequired,

  passingSubmissionAccumulator: PropTypes.number.isRequired,
  submissionPassedAt: PropTypes.string,
  isRevertingCode: PropTypes.bool.isRequired,

  requestHintData: PropTypes.func.isRequired,
  requestNewSandbox: PropTypes.func.isRequired,
  requestSandboxReset: PropTypes.func.isRequired,
  abandonSandbox: PropTypes.func.isRequired,
  resumeInterceptedRequest: PropTypes.func.isRequired,
  initSvcCodeSub: PropTypes.func.isRequired,
  teardownSvcCodeSub: PropTypes.func.isRequired,
  requirePassingTest: PropTypes.bool.isRequired,
  hasPassingTest: PropTypes.bool.isRequired,
  trackProgress: PropTypes.func.isRequired,
  setupProgress: PropTypes.func.isRequired,
  teardownProgress: PropTypes.func.isRequired,
}

export default connect(
  (state) => {
    const shouldShowSubmission = (submission) =>
      submission.test.passed || submission.test.name || !submission.stale
    const sandboxIsReady =
      !state.sandbox.requestError && !!state.sandbox.requestFinishedTimestamp
    const {
      concludedHistory,
      hasCompletedInitialPageLoad,
      requests,
      isIntercepting,
    } = state.sbproxy
    const canIntercept =
      hasCompletedInitialPageLoad && isIntercepting && requests.length > 0
    const passingSubmission = sandboxIsReady
      ? state.sbcrsubmission.submissions.find((x) => (x.test || {}).passed)
      : null
    return {
      hintStatesById: state.hint.hintStatesById,
      highlightHintDataAccumulator: state.hint.highlightHintDataAccumulator,
      sandboxIsReady,
      numProxyRequests: concludedHistory ? Object.keys(requests).length : 0,
      interceptedRequest:
        canIntercept && !requests[0].response ? requests[0] : null,
      isSubmittingCodeToHacker:
        sandboxIsReady && state.sbcrsubmission.isSubmitting,
      isSubmittingCodeToSvc: state.codesub.mode === 'submitting',
      isHackerSubmissionPending:
        sandboxIsReady && state.sbcrsubmission.isPollingSubmission,
      isSvcSubmissionPending: state.codesub.mode === 'waiting',
      passingSubmissionAccumulator: sandboxIsReady
        ? state.sbcrsubmission.passingSubmissionAccumulator
        : 0,
      submissionPassedAt: passingSubmission
        ? passingSubmission.submitted_at
        : null,
      isRevertingCode:
        sandboxIsReady && state.sbcrsubmission.isRevertingToSubmission,
      hasPassingTest: state.hacker.currentCodeSubmissions.some(
        (x) => x.test.passed
      ),
      requirePassingTest: state.hacker.mustPassCodingExercise,
    }
  },
  (dispatch) => ({
    requestHintData: (hintId) => dispatch(hint.requestHintData(hintId)),
    requestNewSandbox: (contentId, templateName) =>
      dispatch(sandbox.requestNew(contentId, templateName, false)),
    requestSandboxReset: (contentId, templateName) =>
      dispatch(sandbox.requestNew(contentId, templateName, true)),
    abandonSandbox: () => dispatch(sandbox.abandon()),
    resumeInterceptedRequest: (requestText, isEdited) =>
      dispatch(sbproxy.resumeInterceptedRequest(requestText, isEdited)),
    initSvcCodeSub: (lessonKey, tournamentDetails) =>
      dispatch(codesub.init(lessonKey, tournamentDetails)),
    teardownSvcCodeSub: () => dispatch(codesub.teardown()),
    initiateHackerCodeSubmission: (languageName) =>
      dispatch(sbcrsubmission.initiateSubmission(languageName)),
    initiateSvcCodeSubmission: (lessonKey, lessonLoadedAt, tournamentDetails) =>
      dispatch(
        codesub.createSubmission(lessonKey, lessonLoadedAt, tournamentDetails)
      ),
    trackProgress: (stepIndex) => dispatch(progress.track(stepIndex)),
    setupProgress: (contentId, templateName) =>
      dispatch(progress.setup(contentId, templateName)),
    teardownProgress: () => dispatch(progress.teardown()),
  })
)(CodingChallengeUI)
