import React from 'react'
import UIkit from 'uikit'
import Icon from 'app/views/components/Icon'
import ToggleSwitch from 'app/views/components/ToggleSwitch'

const DeveloperApi = (props) => (
  <div>
    <div className='uk-inline'>
      <h3 className='uk-inline'>
        HackEDU API
        <Icon
          as='a'
          className='uk-margin-small-left'
          name='question'
          style={{
            marginBottom: 8,
          }}
          ratio={0.8}
          href='https://developers.hackedu.com'
          rel='noopener noreferrer'
          target='_blank'
        />
      </h3>
    </div>
    <div className='uk-card uk-card-default uk-card-body max-w-3xl'>
      <div>
        <div className='uk-margin'>
          <div className='uk-inline'>
            <ToggleSwitch
              disabled={props.lock}
              checked={typeof props.token === 'string'}
              onChange={(active) => {
                props.handleChangeApiToken(active || null, true)
              }}
              label='Enable API'
            />
          </div>
          {typeof props.token === 'string' && (
            <div className='uk-margin'>
              <div className='uk-inline uk-width-1-1'>
                <Icon
                  as='a'
                  className='uk-form-icon'
                  href='#'
                  name='file-copy'
                  onClick={() => {
                    navigator.clipboard.writeText(props.token).then(
                      () => {
                        UIkit.notification({
                          message: 'API Token copied!',
                          status: 'success',
                        })
                      },
                      (err) => {
                        console.error('Async: Could not copy text: ', err)
                      }
                    )
                  }}
                />
                <input
                  disabled
                  className='uk-input uk-width-1-1'
                  type='text'
                  placeholder='Search by email'
                  spellCheck={false}
                  autoComplete='off'
                  value={props.token}
                />
                <Icon
                  data-uk-tooltip='Refresh token'
                  as='a'
                  role='button'
                  onClick={() => {
                    props.handleChangeApiToken(true)
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                  className='uk-form-icon uk-form-icon-flip'
                  name='refresh'
                />
              </div>
            </div>
          )}
          {props.lock ? (
            <p>
              Access to the HackEDU API is currently locked. Please contact
              &nbsp;
              <a href='mailto:support@securityjourney.com'>sales@hackedu.com</a>
              &nbsp; to unlock access.
            </p>
          ) : (
            <>
              <p>
                You need an API Key to authenticate with the HackEDU API.
                Include your key in the X-API-Key header on each request.
              </p>
              <p>
                <a
                  href='https://developers.hackedu.com'
                  target='_blank'
                  className='text-green hover:text-green'
                >
                  Developer Documentation
                </a>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default DeveloperApi
