import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import settings from 'settings'
import PrivateRoute from './PrivateRoute'
import SeoRoute from './SeoRoute'
// Containers
import All from '../containers/All'
import Auth from '../containers/Auth'
import ByVulnerability from '../containers/ByVulnerability'
import Challenges from '../containers/Challenges'
import ByCodeReview from '../containers/ByCodeReview'
import ByCodeReviewDetail from '../containers/ByCodeReview/Detail'
import Invite from '../containers/Invite'
import Leaderboard from '../containers/Leaderboard'
import LessonDetail from '../containers/LessonDetail'
import MyPlan from '../containers/MyPlan'
import NotFound from '../containers/NotFound'
import Profile from '../containers/Profile'
import Logout from '../containers/Logout'
import { TryUs } from '../containers/TryUs'

import { SJLogin } from '../auth/pages/SJLogin'

import { Login } from '../containers/Login'
import Register from '../containers/Register'
import ForgotPassword from '../containers/ForgotPassword'
import ResetPassword from '../containers/ResetPassword'
import VerifyEmail from '../containers/VerifyEmail'
import PartnerDeepLink from '../containers/PartnerDeepLink'

// organizations
import Organizations from '../containers/Organizations'

// organizations - dashboard
import OrganizationDashboard from '../containers/Organizations/Dashboard'

// organizations - users
import OrganizationsUsers from '../containers/Organizations/Users'

// organizations - teams
import OrganizationsTeams from '../containers/Organizations/Teams/List'
import OrganizationsTeamsAdd from '../containers/Organizations/Teams/Add'
import OrganizationsTeamsDetail from '../containers/Organizations/Teams/Detail'
import OrganizationsTeamsEdit from '../containers/Organizations/Teams/Edit'

// organizations - plans
import OrganizationsPlans from '../containers/Organizations/Plans/List'
import OrganizationsPlansDetails from '../containers/Organizations/Plans/Details'
import OrganizationsPlansAdd from '../containers/Organizations/Plans/Add'
import OrganizationsPlansEdit from '../containers/Organizations/Plans/Edit'
import OrganizationsPlansPhaseAdd from '../containers/Organizations/Plans/PhaseAdd'
import OrganizationsPlansPhaseEdit from '../containers/Organizations/Plans/PhaseEdit'

// organizations - reports
import DownloadReport from '../containers/Organizations/Reports/DownloadReport'
import OrganizationsReports from '../containers/Organizations/Reports'
import CompletedReports from '../containers/Organizations/Reports/CompletedReports'
import ImpactReport from '../containers/Organizations/Reports/ImpactReport'
import ProficiencyReport from '../containers/Organizations/Reports/ProficiencyReport'
import ChallengeReport from '../containers/Organizations/Reports/ChallengeReport'
import UsersReport from '../containers/Organizations/Reports/UsersReport'
import ComplianceReport from '../containers/Organizations/Reports/ComplianceReport'

// integrations
import Integrations from '../containers/Organizations/Integrations'
import IntegrationsAdd from '../containers/Organizations/Integrations/Add'
import { AddIntegrationList } from '../containers/Organizations/Integrations/AddIntegrationList'
import IntegrationsDetail from '../containers/Organizations/Integrations/Detail'
import IntegrationsDataUsed from '../containers/Organizations/Integrations/DataUsed'
import AppsIntegrations from '../containers/Organizations/AppsIntegrations'
import AppsIntegrationsCallback from '../containers/Organizations/AppsIntegrations/Callback'
import Microsoft from '../containers/Organizations/AppsIntegrations/Microsoft'

// organizations - settings
import OrganizationsSettings from '../containers/Organizations/Settings'

// TODO: Combine these into a single component
import { ContentDetail } from '../containers/ContentDetail'
import EditOrganizationRecommendation from '../containers/Organizations/Plans/EditOrganizationRecommendation'
import Notifications from '../containers/Organizations/Notifications'
import ActivityReport from '../containers/Organizations/Reports/ActivityReport'

// Static tutorials
import TutorialDAST from '../containers/Tutorial/DAST'

const propTypes = {
  onDemoShown: PropTypes.func,
  mountAllRoutes: PropTypes.bool.isRequired,
  isAwaitingSSOLogin: PropTypes.bool.isRequired,
}

const Routes = ({
  onDemoShown,
  organizationsHash,
  mountAllRoutes,
  isAwaitingSSOLogin,
}) => {
  if (isAwaitingSSOLogin) {
    return (
      <Switch>
        <Route exact path='/auth' component={Auth} />
      </Switch>
    )
  }
  if (!mountAllRoutes) {
    return (
      <Switch>
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/link/:link_uuid' component={PartnerDeepLink} />
      </Switch>
    )
  }
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content='Interactive Cybersecurity Training. HackEDU offers comprehensive online Secure Development Training for your developers, engineers, and IT personnel to assist your organization in laying a foundation of security and application vulnerability prevention, assessment, and remediation.'
        />
        <meta
          name='keywords'
          content='cybersecurity, security, secure coding, secure coding training, secure development, secure development training, PCI compliance, training, platform, hacker, school, online, tools'
        />
        <meta
          property='og:title'
          content='Interactive Cybersecurity Training | HackEDU'
        />
        <meta
          property='og:description'
          content='Interactive Cybersecurity Training. HackEDU offers comprehensive online Secure Development Training for your developers, engineers, and IT personnel to assist your organization in laying a foundation of security and application vulnerability prevention, assessment, and remediation.'
        />
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='HackEDU' />
        <meta property='og:url' content='https://www.hackedu.io/' />

        <title>Interactive Cybersecurity Training | HackEDU</title>
      </Helmet>

      <Switch>
        {/* Seo Routes */}

        {/* Redirect needs to be before actual Route that is gonna be used */}
        <Redirect
          from='/capital-one-breach'
          to='/lesson/4f059a0c-ebb5-11e9-852d-0a7816aaa30a'
        />
        <Redirect
          from='/hacktivity/85624'
          to='/hacktivity/679f0662-4149-4585-8542-42dd527e0bdb'
        />
        <Redirect
          from='/hacktivity/highly-wormable-clickjacking-in-twitter-player-card'
          to='/hacktivity/679f0662-4149-4585-8542-42dd527e0bdb'
        />
        <Redirect
          from='/hacktivity/312543'
          to='/hacktivity/ef152bdd-af90-4e68-aac2-5f9f3a7c355f'
        />
        <Redirect
          from='/hacktivity/xxe-in-site-audit-function'
          to='/hacktivity/ef152bdd-af90-4e68-aac2-5f9f3a7c355f'
        />
        <Redirect
          from='/hacktivity/rce-by-command-line-injection-to-gm-convert'
          to='/hacktivity/5f8e247b-98bc-4b7a-aa97-472dcc34a6f8'
        />
        <Redirect
          from='/hacktivity/drivegrab-sql-injection'
          to='/hacktivity/6f2d6933-e632-4e01-8add-2d161bb6b059'
        />
        <Redirect
          from='/hacktivity/stealing-hackerone-form-data-using-marketo-xss'
          to='/hacktivity/46e75af5-9692-4380-845c-ac570c755d7c'
        />
        <Redirect
          from='/vulnerability/39af3508-4e77-482d-9e45-af19ec21524d'
          to='/vulnerabilities/39af3508-4e77-482d-9e45-af19ec21524d'
        />
        <Redirect
          from='/vulnerability/0a61ce9a-2a6a-4235-ace4-cc224ec81079'
          to='/vulnerabilities/0a61ce9a-2a6a-4235-ace4-cc224ec81079'
        />
        <Redirect
          from='/vulnerability/34fd4f1c-f4ba-416f-99ab-f17d426a7f29'
          to='/vulnerabilities/34fd4f1c-f4ba-416f-99ab-f17d426a7f29'
        />
        <SeoRoute
          exact
          path='/demo'
          component={() => <ContentDetail onDemoShown={onDemoShown} />}
          title='SQL Injection Lesson | Hands-On Training'
          description='SQL injection (SQLi) is where an attacker can inject SQL commands into a SQL statement. Try this FREE hands-on, interactive lesson. Languages include Java, .NET, C#, Python, PHP, Ruby, Node, Javascript, Golang'
          keywords='SQL injection, SQLi, vulnerabilities, public vulnerabilities, vulnerability, Secure Coding Training, Secure Development, Secure Development Training, secure coding, Java, .NET, C#, Python, PHP, Ruby, Node, Javascript, Golang'
        />
        <Route path='/tryus/:title' component={TryUs} />
        <Redirect from='/article/:content_uuid' to='/content/:content_uuid' />

        {/* Auth */}
        <Route exact path='/login' component={Login} />
        <Route exact path='/register' component={Register} />
        <Route exact path='/verify/email/:code' component={VerifyEmail} />
        <Route exact path='/auth' component={Auth} />
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/reset-password' component={ResetPassword} />
        <Route exact path='/sj_login' component={SJLogin} />

        <Route exact path='/link/:link_uuid' component={PartnerDeepLink} />
        <Route
          exact
          path='/login/allegiant'
          render={() => {
            window.location = `${settings.urls.app}/login?domain=allegiantair.com`
            return <div />
          }}
        />
        <PrivateRoute exact path='/' component={MyPlan} />
        <Route
          exact
          path='/integrations/microsoft-teams'
          component={Microsoft}
        />

        {/* Private Training Routes */}
        <PrivateRoute exact path='/all' component={All} />
        <PrivateRoute
          exact
          path='/owasp-10'
          component={() => (
            <All courseUUID='8df51c94-0c3f-4f05-bd82-7dc14025f0d5' />
          )}
        />
        <PrivateRoute
          exact
          path='/owasp-api'
          component={() => (
            <All courseUUID='63839ac7-b937-4d1b-91e8-d6452d1b8132' />
          )}
        />
        <PrivateRoute
          exact
          path='/owasp-android'
          component={() => (
            <All courseUUID='a0f5d403-1376-4075-bd1c-4e863e6eea00' />
          )}
        />
        <PrivateRoute
          exact
          path='/owasp-ios'
          component={() => (
            <All courseUUID='fd11168f-50a0-43be-9ff0-978291f645bc' />
          )}
        />
        <PrivateRoute
          exact
          path='/webapp-security'
          component={() => (
            <All courseUUID='4a00cb84-63b6-4235-a2ad-ccd225b5274a' />
          )}
        />
        <PrivateRoute
          exact
          path='/public-vulnerabilities'
          component={() => (
            <All courseUUID='81bc88d8-b7f1-415a-b212-3e86c295e10d' />
          )}
        />
        <PrivateRoute
          exact
          path='/native-apps'
          component={() => (
            <All courseUUID='2d37502f-7a5e-4fb7-876c-22553b8e8a52' />
          )}
        />
        <PrivateRoute
          exact
          path='/devsecops'
          component={() => (
            <All courseUUID='44d39920-aeab-11eb-b5c2-0ab2571ad963' />
          )}
        />
        <PrivateRoute
          exact
          path='/vulnerability-types'
          component={ByVulnerability}
        />
        <PrivateRoute exact path='/hacking-challenges' component={Challenges} />
        <PrivateRoute
          exact
          path='/coding-challenges'
          component={ByCodeReview}
        />
        <PrivateRoute
          exact
          path='/by-vulnerability'
          component={ByVulnerability}
        />
        <PrivateRoute
          exact
          path='/coding-challenges'
          component={ByCodeReview}
        />
        <PrivateRoute
          exact
          path='/coding-challenges/:language/:framework'
          component={ByCodeReviewDetail}
        />
        <PrivateRoute exact path='/challenges' component={Challenges} />
        <PrivateRoute path='/profile' component={Profile} />
        <PrivateRoute path='/leaderboard' component={Leaderboard} />
        <PrivateRoute path='/lesson/:id' component={LessonDetail} />
        <PrivateRoute path='/challenge/:id' component={ContentDetail} />
        <PrivateRoute path='/practice/:id' component={ContentDetail} />
        <PrivateRoute path='/lesson/:id' component={ContentDetail} />
        <PrivateRoute path='/topic/:id' component={ContentDetail} />
        <PrivateRoute path='/content/:id' component={ContentDetail} />

        {/* Static Tutorials */}
        <PrivateRoute path='/tutorial/dast' component={TutorialDAST} />

        {/* Organizations */}
        <Route exact path='/invite' component={Invite} />
        <Route exact path='/invite/:id' component={Invite} />

        {/* Admin Dashboard */}
        <PrivateRoute exact path='/admin' component={Organizations} />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/dashboard'
          component={OrganizationDashboard}
        />
        {/* Admin Users */}
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/users'
          component={OrganizationsUsers}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/users/:hacker_uuid/:tab'
          component={OrganizationsUsers}
        />

        {/* Admin Teams */}
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams'
          component={OrganizationsTeams}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/add'
          component={OrganizationsTeamsAdd}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/users'
          component={OrganizationsTeamsDetail}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/users/:hacker_uuid/:tab'
          component={OrganizationsTeamsDetail}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/plans'
          component={OrganizationsTeamsDetail}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/settings'
          component={OrganizationsTeamsDetail}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/plan/:plan_uuid/phase/:phase_uuid/edit'
          component={OrganizationsTeamsDetail}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/edit'
          component={OrganizationsTeamsEdit}
        />

        {/* Admin Plans */}
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/plans'
          component={OrganizationsPlans}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/plans/add'
          component={OrganizationsPlansAdd}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/plans/add/team'
          component={OrganizationsPlansAdd}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/plans/recommendation-engine-organization'
          component={EditOrganizationRecommendation}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/plans/:plan_uuid'
          component={OrganizationsPlansDetails}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/plans/:plan_uuid/edit'
          component={OrganizationsPlansEdit}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/plans/:plan_uuid/phase/add'
          component={OrganizationsPlansPhaseAdd}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/plans/:plan_uuid/phase/:phase_uuid/edit'
          component={OrganizationsPlansPhaseEdit}
        />

        {/* Admin Reports */}
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/reports'
          component={OrganizationsReports}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/reports/completed'
          component={CompletedReports}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/reports/challenges'
          component={ChallengeReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/reports/proficiency'
          component={ProficiencyReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/reports/download'
          component={DownloadReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/reports/users'
          component={UsersReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/reports/compliance'
          component={ComplianceReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/reports/activity'
          component={ActivityReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/reports/impact'
          component={ImpactReport}
        />

        {/* Team Reports */}
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/reports/completed'
          component={CompletedReports}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/reports/challenges'
          component={ChallengeReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/reports/proficiency'
          component={ProficiencyReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/reports/download'
          component={DownloadReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/reports/users'
          component={UsersReport}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/teams/:team_uuid/reports/compliance'
          component={ComplianceReport}
        />

        {/* Integrations */}
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/integrations'
          component={Integrations}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/app-integrations'
          component={AppsIntegrations}
        />
        <PrivateRoute
          exact
          path='/app-integrations/github-callback'
          component={AppsIntegrationsCallback}
        />
        <PrivateRoute
          exact
          path='/app-integrations/slack-callback'
          component={AppsIntegrationsCallback}
        />
        <PrivateRoute
          redirectPathName='/integrations/microsoft-teams'
          exact
          path='/app-integrations/microsoft-teams-callback'
          component={AppsIntegrationsCallback}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/integrations/add'
          component={AddIntegrationList}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/integrations/add/:integration/data'
          component={IntegrationsDataUsed}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/integrations/add/:integration'
          component={IntegrationsAdd}
        />
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/integrations/:integration_uuid'
          component={IntegrationsDetail}
        />

        {/* Notifications */}
        <PrivateRoute
          exact
          path='/admin/:organization_uuid/notifications'
          component={Notifications}
        />

        <PrivateRoute
          exact
          path='/admin/:organization_uuid/settings'
          component={OrganizationsSettings}
        />

        {/* Global for both authenticated & unauthenticated users */}
        <Route exact path='/404' component={NotFound} />

        {/* Route Redirects */}
        <Route
          exact
          path='/admin/:organization_uuid'
          render={({ match }) => {
            const { role } = organizationsHash[match.params.organization_uuid]
            let redirectTo = ''
            if (role === 'admin') {
              redirectTo = 'users'
            }
            if (
              organizationsHash[match.params.organization_uuid].team_admin_uuids
                .length > 0
            ) {
              redirectTo = 'teams'
            }
            if (redirectTo === '') return <Redirect to='/404' />
            return (
              <Redirect
                to={`/admin/${match.params.organization_uuid}/${redirectTo}`}
              />
            )
          }}
        />

        {/* Redirects */}
        {/* This routes are based on redirects so need to be after the redirects */}
        <PrivateRoute path='/vulnerability/:id' component={ContentDetail} />
        <PrivateRoute exact path='/hacktivity/:id' component={ContentDetail} />
        <PrivateRoute
          exact
          path='/vulnerabilities/:id'
          component={ContentDetail}
        />
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

Routes.propTypes = propTypes

function mapStateToProps(state) {
  return {
    authenticationStatus: state.auth.status,
    organizationsHash: state.hacker.organizationsHash,
    mountAllRoutes:
      state.auth.status === 'LOGGED_OUT' ||
      (state.auth.status === 'LOGGED_IN' &&
        state.hacker.hasLoadedOrganizations),
    isAwaitingSSOLogin: state.auth.isAwaitingSSOLogin,
  }
}
export default withRouter(connect(mapStateToProps)(Routes))
