import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'app/views/components/Icon'

const propTypes = {
  color: PropTypes.string,
  completedAt: PropTypes.string,
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  difficulty: PropTypes.number,
  hideProgress: PropTypes.bool,
  license: PropTypes.bool,
  points: PropTypes.number,
  requiresSubscription: PropTypes.bool.isRequired,
  showContentType: PropTypes.bool,
  steps: PropTypes.number,
  title: PropTypes.string.isRequired,
}

const defaultProps = {
  color: null,
  completedAt: null,
  difficulty: null,
  hideProgress: false,
  license: false,
  points: 0,
  showContentType: false,
  steps: 0,
}

const ContentCard = (props) => {
  let hexColor = '#32D296' // green
  // let checkboxColor = '#333333'
  const checkboxColor = '#1E87F0'

  let colorName = props.color
  if (props.color === null) {
    colorName = 'blue'
    if (props.contentType === 'hacking_challenge') {
      colorName = 'orange'
    } else if (props.contentType === 'vulnerability') {
      colorName = 'green'
    }
  }
  if (colorName === 'green') {
    hexColor = '#32D296'
  } else if (colorName === 'blue') {
    hexColor = '#1E87F0'
  } else if (colorName === 'yellow') {
    hexColor = '#FFE74C'
  } else if (colorName === 'orange') {
    hexColor = '#FF782B'
  } else if (colorName === 'red') {
    hexColor = '#930000'
  }
  if (
    (props.requiresSubscription === true && props.license === false) ||
    (props.requiresSubscription === true && !props.isActive)
  ) {
    hexColor = '#DDDDDD'
  }

  let difficultyDescription = ''
  if (props.contentType === 'hacking_challenge' && props.metadata) {
    if (props.metadata.difficulty === 0) {
      difficultyDescription = 'Easy'
    } else if (props.metadata.difficulty === 1) {
      difficultyDescription = 'Moderate'
    } else if (props.metadata.difficulty === 2) {
      difficultyDescription = 'Hard'
    }
  }
  const maxProgress =
    props.maxProgress !== undefined && props.maxProgress !== null
      ? props.maxProgress
      : 0

  let contentType = props.contentType
  if (contentType === 'lesson') {
    contentType = 'lesson'
  }

  const card = (
    <div
      className='uk-card uk-card-default uk-card-body'
      style={{
        borderTop: `solid 7px ${hexColor}`,
        borderRadius: 5,
        padding: 30,
      }}
    >
      {((props.requiresSubscription === true && props.license === false) ||
        (props.requiresSubscription === true && !props.isActive)) && (
          <div
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
            }}
          >
            <img
              className='mt-5 h-[22px]'
              src='/static/icons/lock.png'
              alt='lock'
            />
          </div>
        )}
      <div>
        {!props.lessonTitle || props.lessonTitle === props.title ? (
          <div>
            {props.showContentType && (
              <div
                className='uk-card-badge uk-label'
                style={{ top: 15, right: 15, background: hexColor }}
              >
                {contentType}
              </div>
            )}
            <h3 className='uk-card-title'>{props.title}</h3>
          </div>
        ) : (
          <div>
            <h4 className='uk-margin-remove'>{props.lessonTitle}</h4>
            <h5 className='uk-margin-remove'>{props.title}</h5>
          </div>
        )}
        {props.description ? (
          <div style={{ paddingBottom: 30 }}>{props.description}</div>
        ) : (
          <div style={{ paddingBottom: 15 }}>&nbsp;</div>
        )}
      </div>

      {props.requiresSubscription === true && !props.isActive ? (
        <div
          className='uk-position-bottom'
          style={{ padding: '0 30px 15px 30px' }}
        >
          <div className='text-sm text-muted'>
            <i>Requires subscription</i>
          </div>
        </div>
      ) : (
        <div>
          {props.hideProgress !== true && (
            <div>
              {(maxProgress === props.steps && maxProgress > 0) ||
                props.completedAt !== null ? (
                <div
                  className='uk-position-bottom'
                  style={{ padding: '0 30px 15px 30px' }}
                >
                  {!props.completedAt ? (
                    <div className='text-sm text-muted align-middle'>
                      <Icon
                        name='close'
                        ratio={0.8}
                        style={{
                          width: 24,
                          height: 24,
                          marginRight: 5,
                          color: '#FFF',
                        }}
                        className='uk-icon-button'
                      />
                      <i className='align-middle'>Test is Failing</i>
                    </div>
                  ) : (
                    <div className='text-sm text-muted align-middle'>
                      <Icon
                        style={{
                          width: 24,
                          height: 24,
                          marginRight: 5,
                          background: checkboxColor,
                          color: '#FFF',
                        }}
                        name='check'
                        ratio={0.8}
                        className='uk-icon-button'
                      />
                      <i className='align-middle'>Completed</i>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {difficultyDescription !== '' && props.metadata.points > 0 ? (
                    <div
                      className='uk-position-bottom'
                      style={{ padding: '0 30px 15px 30px' }}
                    >
                      <div
                        className='text-sm text-muted font-bold'
                        style={{ paddingBottom: 2 }}
                      >
                        Worth {props.metadata.points} Points (
                        {difficultyDescription})
                      </div>
                    </div>
                  ) : (
                    <div
                      className='uk-position-bottom'
                      style={{ padding: '0 30px 15px 30px' }}
                    >
                      {props.mustPassCodingExercise === true &&
                        props.passedAt !== null &&
                        props.hasTest === true ? (
                        <div className='text-sm text-muted align-middle'>
                          <Icon
                            className='uk-icon-button'
                            name='check'
                            ratio={0.8}
                            style={{
                              width: 24,
                              height: 24,
                              marginRight: 5,
                              background: '#1E87F0',
                              color: '#FFF',
                            }}
                          />
                          <i className='align-middle'>Completed</i>
                        </div>
                      ) : (
                        <div>
                          <div
                            className='text-sm text-muted'
                            style={{ paddingBottom: 2 }}
                          >
                            <i>Progress</i>
                          </div>
                          <progress
                            id='js-progressbar'
                            className='uk-progress uk-margin-remove'
                            value={maxProgress}
                            max={props.steps}
                            style={{ height: 10 }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )

  return (
    <a
      data-test-id='lesson-content-card'
      href={`/content/${props.contentId}`}
      className='uk-link-reset uk-padding-small'
    >
      {card}
    </a>
  )
}

ContentCard.propTypes = propTypes
ContentCard.defaultProps = defaultProps

export default ContentCard
