import React from 'react'

export default ({
  disabled,
  page,
  isNext,
  isPrev,
  active,
  onClick,
  onChangePage,
}) => {
  if (isPrev) {
    return (
      <li onClick={onClick} className={`${disabled ? 'uk-disabled' : ''}`}>
        <a>
          <span uk-pagination-previous='true'></span>
        </a>
      </li>
    )
  }
  if (isNext) {
    return (
      <li
        onClick={onClick}
        className={`${disabled ? 'uk-disabled' : ''}${
          active ? 'uk-active' : ''
        }`}
      >
        <a>
          <span uk-pagination-next='true'></span>
        </a>
      </li>
    )
  }
  return (
    <li
      onClick={() => onChangePage(page)}
      className={`${disabled ? 'uk-disabled' : ''}${active ? 'uk-active' : ''}`}
    >
      <a>{page}</a>
    </li>
  )
}
