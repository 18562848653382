import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import StepDotnav from '../../common/StepDotnav'
import StepNavigationButtons from '../../common/StepNavigationButtons'
import { useCachedStepIndex } from '../../common/util'

import PrimaryButton from '../../../components/Buttons/PrimaryButton'

import CodingChallengeHintModal from './CodingChallengeHintModal'
import CodingChallengeCompletionStatus from './CodingChallengeCompletionStatus'
import { notifyParentSjApp } from 'app/sjInterop'
import { renderedInSJApp } from '../../LessonUI/util'
import { useFlags } from 'launchdarkly-react-client-sdk'

function CodingChallengeInstructions(props) {
  const {
    contentId,
    steps,
    submissionPassedAt,
    scrollToTop,
    requestHintData,
    requestSandboxReset,
    submissionProps,
    hasTests,
    hasPassingTest,
    requirePassingTest,
    trackProgress,
    setWantsToCompleteLesson,
  } = props

  const {
    ideEnabled,
    onSubmitCode,
    isSubmissionPending,
    isSubmittingCode,
    onFocusActiveCodeSubmission,
    sandboxIsReady,
  } = submissionProps

  const [stepIndex, setStepIndex] = useCachedStepIndex(contentId, steps.length)
  const canNavigateBack = steps.length > 0 && stepIndex > 0
  const canNavigateForward = steps.length > 0 && stepIndex < steps.length - 1
  const step =
    stepIndex >= 0 && stepIndex < steps.length ? steps[stepIndex] : null

  const { sendCodeChallengeCompleteEventSj } = useFlags()

  const isSJ = renderedInSJApp()

  // If there are any hints associated with the current step, let the user see them
  const hints = step
    ? step.hints.sort((a, b) => a.sort_order - b.sort_order)
    : []
  const [wantsHint, setWantsHint] = useState(false)
  const [selectedHintIndex, setSelectedHintIndex] = useState(-1)
  const onShowHintClick = () => {
    // Never auto-select a hint, since challenge hints can cost points to use
    setSelectedHintIndex(-1)
    setWantsHint(true)
  }
  const onSelectHint = (hintIndex) => {
    if (hintIndex >= 0) {
      // If the hint is a basic/default hint, request its data (and lose points) as soon
      // as it's selected. For 'highlight' hints there's an additional confirmation
      // prompt within the CodingChallengeHintModal itself.
      const hint = hints[hintIndex]
      if (hint.hint_type !== 'highlight') {
        requestHintData(hint.id)
      }
    }
    // Either way, update our selected hint index so the dialog displays the details of
    // this specific hint
    setSelectedHintIndex(hintIndex)
  }
  const onConfirmHighlightHint = (hintIndex) => {
    // When a 'highlight' hint is confirmed, initiate the request for the hint data, and
    // clear the dialog
    const hint = hints[hintIndex]
    requestHintData(hint.id)
    setWantsHint(false)

    // Separately, request a sandbox reset so we can be sure that the hint is accurate
    // (since hints are only stored as filenames and line ranges and we can't easily
    // ascertain whether the file has been modified from its original version)
    requestSandboxReset()
  }

  // Check if user has completed tests before notifying SJ
  const checkCanCompleteSJ = () => {
    if (sendCodeChallengeCompleteEventSj) {
      const needsTest = hasTests && requirePassingTest
      const passingTest = hasTests && hasPassingTest
      const canCompleteLesson = !needsTest || passingTest
      if (canCompleteLesson) trackProgress(steps.length)
      notifyParentSjApp({
        type: canCompleteLesson ? 'lesson-complete' : 'lesson-needs-work',
      })
    }
  }

  // Automatically scroll to the top of the instruction panel whenever the user
  // navigates to a different step
  useEffect(scrollToTop, [stepIndex])

  return (
    <>
      {steps.length > 1 && (
        <StepDotnav
          vertical
          currentStepIndex={stepIndex}
          numSteps={steps.length}
          onSelectStep={setStepIndex}
        />
      )}
      {wantsHint && (
        <CodingChallengeHintModal
          hints={hints.map((hint) => ({
            id: hint.id,
            title: hint.title,
            markdown: hint.markdown || '',
            hintType: hint.hint_type || 'default',
            points: hint.points || 0,
            hasBeenUsed: hint.hasBeenUsed || false,
            state: hint.state || {},
          }))}
          selectedHintIndex={selectedHintIndex}
          onSelectHint={onSelectHint}
          onConfirmHighlightHint={onConfirmHighlightHint}
          onClose={() => setWantsHint(false)}
        />
      )}
      {step && (
        <div style={{ paddingLeft: 10, marginTop: 22 }}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} linkTarget='_blank'>
            {step.markdown}
          </ReactMarkdown>
        </div>
      )}
      {hints.length > 0 && (
        <PrimaryButton
          size={'small'}
          color='gray'
          customCss={'text-black'}
          onClick={onShowHintClick}
          label='Show Hints'
        />
      )}
      {sendCodeChallengeCompleteEventSj
        ? steps.length > 1 && (
            <StepNavigationButtons
              canNavigateBack={canNavigateBack}
              canNavigateForward={canNavigateForward}
              canEverFinish={!!submissionPassedAt}
              isReadyToFinish={false}
              onNavigateBack={() => setStepIndex((step) => step - 1)}
              onNavigateForward={() => setStepIndex((step) => step + 1)}
              onAttemptFinish={() => {
                if (!isSJ) {
                  setWantsToCompleteLesson(true)
                } else {
                  checkCanCompleteSJ()
                }
              }}
            />
          )
        : steps.length > 1 && (
            <StepNavigationButtons
              canNavigateBack={canNavigateBack}
              canNavigateForward={canNavigateForward}
              canEverFinish={false}
              isReadyToFinish={false}
              onNavigateBack={() => setStepIndex((step) => step - 1)}
              onNavigateForward={() => setStepIndex((step) => step + 1)}
              onAttemptFinish={() => {}}
            />
          )}
      {ideEnabled && (steps.length === 0 || stepIndex === steps.length - 1) && (
        <PrimaryButton
          size={'medium'}
          customCss='mt-5'
          label={
            isSubmittingCode
              ? 'Submitting...'
              : isSubmissionPending
              ? 'Awaiting Results...'
              : 'Submit Code'
          }
          active={sandboxIsReady}
          onClick={
            isSubmissionPending ? onFocusActiveCodeSubmission : onSubmitCode
          }
        />
      )}
      {(steps.length === 0 || stepIndex === steps.length - 1) && (
        <CodingChallengeCompletionStatus passedAt={submissionPassedAt} />
      )}
    </>
  )
}
CodingChallengeInstructions.propTypes = {
  contentId: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  submissionPassedAt: PropTypes.string,
  scrollToTop: PropTypes.func.isRequired,
  requestHintData: PropTypes.func.isRequired,
  requestSandboxReset: PropTypes.func.isRequired,
  submissionProps: PropTypes.object,
  requirePassingTest: PropTypes.bool,
  hasPassingTest: PropTypes.bool,
  hasTests: PropTypes.bool,
  trackProgress: PropTypes.func,
  setWantsToCompleteLesson: PropTypes.func,
}

export default CodingChallengeInstructions
