/* eslint-disable no-nested-ternary */
import React from 'react'
import Icon from 'app/views/components/Icon'
import settings from 'settings'

class Users extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: undefined,
      activeTab: 'in-phase',
    }
    this.onClickPlus = this.onClickPlus.bind(this)
    this.renderExtra = this.renderExtra.bind(this)
  }

  onClickPlus(hackerUUID) {
    const { active } = this.state
    this.setState({
      active: hackerUUID === active ? undefined : hackerUUID,
    })
  }

  renderExtra(hacker) {
    const {
      incomplete_content: incompleteContent,
      completed_content: completedContent,
    } = hacker
    const biggerLength =
      completedContent.length > incompleteContent.length
        ? completedContent.length
        : incompleteContent.length
    const rows = []
    rows.push(
      <tr key='header'>
        <td
          colSpan='2'
          className='text-center'
          style={{ background: '#f8f8f8' }}
        >
          <div className='flex items-center justify-center'>
            <Icon
              name='check'
              className='text-success uk-margin-small-right'
            />
            <span>Completed</span>
          </div>
        </td>
        <td
          colSpan='2'
          className='text-center'
          style={{ background: '#f8f8f8' }}
        >
          <div className='flex items-center justify-center'>
            <Icon
              name='close'
              className='text-danger uk-margin-small-right'
            />
            <span>Incomplete</span>
          </div>
        </td>
      </tr>
    )
    for (let i = 0; i < biggerLength; i += 1) {
      rows.push(
        <tr key={`key-row-${i}`}>
          <td colSpan={2} className='text-center'>
            {completedContent[i]
              ? completedContent[i].type === 'coding_challenge'
                ? `${settings.apps[completedContent[i].app]}: ${
                    completedContent[i].content_title
                  } (${settings.engines[completedContent[i].language.name]} - ${
                    settings.frameworks[completedContent[i].framework.name]
                  })`
                : completedContent[i].content_title
              : ' - '}
          </td>
          <td colSpan={2} className='text-center'>
            {incompleteContent[i]
              ? incompleteContent[i].type === 'coding_challenge'
                ? `${settings.apps[incompleteContent[i].app]}: ${
                    incompleteContent[i].content_title
                  } (${
                    settings.engines[incompleteContent[i].language.name]
                  } - ${
                    settings.frameworks[incompleteContent[i].framework.name]
                  })`
                : incompleteContent[i].content_title
              : ' - '}
          </td>
        </tr>
      )
    }
    return rows
  }

  render() {
    const { active } = this.state
    return (
      <>
        <div className='uk-width-1-1 uk-grid'>
          <div className='uk-width-1-5'>
            <div>
              <div className='hackedu-content-menu'>
                <ul className='links dark'>
                  <li
                    className={
                      this.state.activeTab === 'in-phase' ? 'active' : ''
                    }
                  >
                    <a // eslint-disable-line
                      onClick={() => {
                        this.setState({ activeTab: 'in-phase' })
                      }}
                      data-uk-scroll='offset: 100'
                    >
                      Users in phase ({this.props.users.in_phase.total})
                    </a>
                  </li>
                  <li
                    className={
                      this.state.activeTab === 'over-phase' ? 'active' : ''
                    }
                  >
                    <a // eslint-disable-line
                      onClick={() => {
                        this.setState({ activeTab: 'over-phase' })
                      }}
                      data-uk-scroll='offset: 100'
                    >
                      Users out of phase ({this.props.users.over_phase.total})
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className='uk-width-4-5 uk-section-small'
            style={{ paddingTop: 15 }}
          >
            <div>
              {this.state.activeTab === 'in-phase' && (
                <>
                  {this.props.users.in_phase.hackers.length === 0 ? (
                    <p className='text-center'>
                      No users currently in this phase
                    </p>
                  ) : (
                    <table className='uk-table uk-table-divider uk-table-middle'>
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th className='text-center'>Completed Lessons</th>
                          <th className='text-center'>Incomplete Lessons</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody className='text-sm'>
                        {this.props.users.in_phase.hackers.map((hacker) => (
                          <React.Fragment key={hacker.hacker_uuid}>
                            <tr>
                              <td>{hacker.email}</td>
                              <td className='text-center'>
                                {hacker.completed}
                              </td>
                              <td className='text-center'>
                                {hacker.total - hacker.completed}
                              </td>
                              <td>
                                <button
                                  type='button'
                                  onClick={this.onClickPlus.bind(
                                    this,
                                    hacker.hacker_uuid
                                  )}
                                  className={`uk-button ${
                                    active === hacker.hacker_uuid
                                      ? 'uk-button-danger'
                                      : 'uk-button-primary'
                                  } uk-button-small`}
                                  style={{
                                    padding: '0px 10px',
                                  }}
                                >
                                  <Icon
                                    name={`${
                                      active === hacker.hacker_uuid
                                        ? 'subtract'
                                        : 'add'
                                    }`}
                                    ratio={0.7}
                                  />
                                </button>
                              </td>
                            </tr>
                            {this.state.active === hacker.hacker_uuid &&
                              this.renderExtra(hacker)}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              )}
              {this.state.activeTab === 'over-phase' && (
                <>
                  {this.props.users.over_phase.hackers.length === 0 ? (
                    <p className='text-center'>
                      No users have passed this phase
                    </p>
                  ) : (
                    <table className='uk-table uk-table-divider uk-table-middle'>
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th className='text-center'>Completed Lessons</th>
                          <th className='text-center'>Incomplete Lessons</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.users.over_phase.hackers.map((hacker) => (
                          <React.Fragment key={hacker.hacker_uuid}>
                            <tr>
                              <td>{hacker.email}</td>
                              <td className='text-center'>
                                {hacker.completed}
                              </td>
                              <td className='text-center'>
                                {hacker.total - hacker.completed}
                              </td>
                              <td>
                                <button
                                  type='button'
                                  onClick={this.onClickPlus.bind(
                                    this,
                                    hacker.hacker_uuid
                                  )}
                                  className={`uk-button ${
                                    active === hacker.hacker_uuid
                                      ? 'uk-button-danger'
                                      : 'uk-button-primary'
                                  } uk-button-small`}
                                  style={{
                                    padding: '0px 10px',
                                  }}
                                >
                                  <Icon
                                    name={`${
                                      active === hacker.hacker_uuid
                                        ? 'subtract'
                                        : 'add'
                                    }`}
                                    ratio={0.7}
                                  />
                                </button>
                              </td>
                            </tr>
                            {this.state.active === hacker.hacker_uuid &&
                              this.renderExtra(hacker)}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Users
