import React from 'react'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

export default ({ challenges }) => (
  <div className='uk-align-center uk-width-1-2@m'>
    {challenges.length === 0 ? (
      <p className='text-center'>
        User has not completed any hacking challenges.
      </p>
    ) : (
      <table className='uk-table uk-table-divider uk-table-middle text-sm'>
        <thead>
          <tr>
            <th className='uk-width-1-2'>Challenge</th>
            <th>Completed On</th>
            <th className='text-center'>Points</th>
          </tr>
        </thead>
        <tbody>
          {challenges.map((challenge) => (
            <tr key={challenge.challenge_uuid}>
              <td>{challenge.title}</td>
              <td>
                {getLocalDateFromUTC(challenge.completed_at)
                  .format('MMMM  D, YYYY')
                  .replace('_', 'at')}
              </td>
              <td className='text-center'>
                <label className='uk-label uk-label-success'>
                  {challenge.points}
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
)
