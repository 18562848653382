import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Icon } from 'app/views/components/GustavoIcon'

import { VBox } from 'app/views/core/Box'

import { connect } from 'react-redux'
import * as sandbox from 'app/state/modules/sandbox'

function usePeriodicRerender(interval) {
  const [renderTimestamp, setRenderTimestamp] = useState(Date.now())
  const timerHandle = useRef(null)

  useEffect(() => {
    if (!timerHandle.current) {
      timerHandle.current = setInterval(() => {
        setRenderTimestamp(Date.now())
      }, interval)
    }
    return () => {
      if (timerHandle.current) {
        clearInterval(timerHandle.current)
      }
    }
  }, [])

  return renderTimestamp
}

const ApologeticText = ({ children }) => (
  <p className='text-sm' style={{ marginTop: 0, marginBottom: 4 }}>
    {children}
  </p>
)
const ErrorSummary = ({ error, requestNewSandbox }) => {
  const text =
    typeof error === 'string' ? error : `ERROR ${JSON.stringify(error)}`
  return (
    <div style={{ textAlign: 'center', marginTop: 10 }}>
      <p className='text-danger' style={{ marginBottom: 0 }}>
        <b>Failed to load sandbox:</b>
      </p>
      <p style={{ marginTop: 0 }}>
        <em>{text}</em>
      </p>
      <ApologeticText>We've failed to assign you a sandbox.</ApologeticText>
      <ApologeticText>
        Please click the <b>Reset Sandbox</b> button and try again.
      </ApologeticText>
      <button
        className='uk-button uk-button-secondary'
        style={{ display: 'flex', margin: '20px auto' }}
        onClick={requestNewSandbox}
      >
        <Icon name='restart' />
        <div style={{ marginLeft: 8 }}>Reset Sandbox</div>
      </button>
      <ApologeticText>
        If you can't get a sandbox after repeated attempts,
        <br />
        we want to know about it. Please let us know
        <br />
        via the feedback button in the lower-right.
      </ApologeticText>
    </div>
  )
}

const RequestStatusDiv = ({ message, desc }) => (
  <div style={{ textAlign: 'center' }}>
    <p style={{ marginBottom: 0, marginTop: 20 }}>{message}</p>
    {desc && (
      <p className='text-sm' style={{ marginTop: 0 }}>
        {desc}
      </p>
    )}
  </div>
)
const RequestStatusSummary = ({
  initiatedAt,
  acknowledgedAt,
  readyAt,
  now,
}) => {
  if (initiatedAt) {
    if (readyAt) {
      return <RequestStatusDiv message='Sandbox is ready!' />
    }
    if (acknowledgedAt) {
      const desc = `Assigned ${moment(acknowledgedAt).from(
        Math.max(now, acknowledgedAt)
      )}`
      return <RequestStatusDiv message='Loading Sandbox...' desc={desc} />
    }
    const desc = `Requested ${moment(initiatedAt).from(
      Math.max(now, initiatedAt)
    )}`
    return <RequestStatusDiv message='Loading Sandbox...' desc={desc} />
  }
  return <RequestStatusDiv message='Loading Sandbox...' />
}

function SandboxLoader(props) {
  const {
    lastRequestTimestamp,
    requestAcknowledgedTimestamp,
    requestFinishedTimestamp,
    requestError,
    requestNewSandbox,
  } = props
  const renderTimestamp = usePeriodicRerender(3333)
  return (
    <VBox className='uk-section' fillParent style={{ alignItems: 'center' }}>
      {requestError ? (
        <Icon name='error-warning' className='text-danger' ratio={2} />
      ) : (
        <div data-uk-spinner='ratio: 1.5' />
      )}
      {requestError ? (
        <ErrorSummary
          error={requestError}
          requestNewSandbox={requestNewSandbox}
        />
      ) : (
        <RequestStatusSummary
          initiatedAt={lastRequestTimestamp}
          acknowledgedAt={requestAcknowledgedTimestamp}
          readyAt={requestFinishedTimestamp}
          now={renderTimestamp}
        />
      )}
    </VBox>
  )
}
SandboxLoader.propTypes = {
  lastRequestTimestamp: PropTypes.number,
  requestAcknowledgedTimestamp: PropTypes.number,
  requestFinishedTimestamp: PropTypes.number,
  requestError: PropTypes.any,
  requestNewSandbox: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    lastRequestTimestamp: state.sandbox.lastRequest
      ? state.sandbox.lastRequest.timestamp
      : null,
    requestAcknowledgedTimestamp: state.sandbox.requestAcknowledgedTimestamp,
    requestFinishedTimestamp: state.sandbox.requestFinishedTimestamp,
    requestError: state.sandbox.requestError,
  }),
  (dispatch) => ({
    requestNewSandbox: (contentId, templateName) =>
      dispatch(sandbox.requestNew(contentId, templateName, true)),
  })
)(SandboxLoader)
