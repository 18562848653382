import React from 'react'
import PropTypes from 'prop-types'
import { floor, ceil } from 'lodash'

import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Area,
  Label,
  LabelList,
  Legend,
} from 'recharts'

import ChartLegend from './ChartLegend'
import ChartTooltip from './ChartTooltip'

// Primary colors used to plot the vulnerability and lesson completion series
const VULNERABILITY_BASE_COLOR = '#1e87f0'
const COMPLETION_BASE_COLOR = '#1e87f0' // update the values below if this color changes

// For the area chart, recharts modifies the base colors a bit: the stroke is darker,
// and the shaded portion is semitransparent (over a white background)
const COMPLETION_LINE_COLOR = '#3182bd'
const COMPLETION_AREA_COLOR = '#84b8f7'

function Chart(props) {
  const { isLoading, vulnerabilityCategories, excludedCategoryIds, data } =
    props
  const includedCategories = vulnerabilityCategories
    .filter((x) => !excludedCategoryIds.includes(x.id))
    .reverse()
  const singleCategoryTitle =
    includedCategories.length === 1 ? includedCategories[0].title : null

  const getDataWithSums = () => {
    const getVulnerabilitySum = (monthData) =>
      Object.entries(monthData.vulnerabilityCounts).reduce(
        (acc, [categoryIdStr, count]) =>
          excludedCategoryIds.includes(parseInt(categoryIdStr))
            ? acc
            : acc + count,
        0
      )
    const getCompletionSum = (monthData) =>
      Object.entries(monthData.cumulativeCompletionCounts).reduce(
        (acc, [categoryIdStr, count]) =>
          excludedCategoryIds.includes(parseInt(categoryIdStr))
            ? acc
            : acc + count,
        0
      )
    return data.map((monthData) => ({
      ...monthData,
      vulnerabilitySum: getVulnerabilitySum(monthData),
      completionSum: getCompletionSum(monthData),
    }))
  }

  const getRightYAxisDomain = () => {
    let minValue = null
    let maxValue = null
    for (const xPoint of data) {
      const yValue = includedCategories.reduce(
        (acc, x) => acc + (xPoint.cumulativeCompletionCounts[x.id] || 0),
        0
      )
      if (minValue === null || yValue < minValue) {
        minValue = yValue
      }
      if (maxValue === null || yValue > maxValue) {
        maxValue = yValue
      }
    }
    if (minValue === null || maxValue === null) {
      return [0, 'auto']
    }
    const NEAREST_TENS = -1
    return [floor(minValue, NEAREST_TENS), ceil(maxValue, NEAREST_TENS)]
  }

  const displayData = getDataWithSums()
  return (
    <div className='impact-report-chart-wrapper'>
      <ResponsiveContainer height={550} width='100%'>
        <ComposedChart data={displayData}>
          <XAxis
            dataKey='month'
            tick={{ fontSize: 12 }}
            tickFormatter={(value) => {
              const [yearStr, monthStr] = value.split('-')
              const date = new Date(yearStr, parseInt(monthStr) - 1)
              const shortMonthName = date.toLocaleString('default', {
                month: 'short',
              })
              return `${shortMonthName} ${yearStr.slice(yearStr.length - 2)}`
            }}
          />
          <YAxis yAxisId='left' tick={{ fontSize: 12 }}>
            <Label
              value='New Vulnerabilities Identified'
              position='insideLeft'
              offset={20}
              angle={270}
              className='impact-report-chart-axis-label'
            />
          </YAxis>
          <YAxis
            yAxisId='right'
            orientation='right'
            domain={getRightYAxisDomain()}
            allowDataOverflow={true}
            tick={{ fontSize: 12 }}
          >
            <Label
              value='Lessons Completed to Date'
              position='insideRight'
              offset={20}
              angle={90}
              className='impact-report-chart-axis-label'
            />
          </YAxis>
          <Tooltip
            content={
              <ChartTooltip
                data={displayData}
                singleCategoryTitle={singleCategoryTitle}
              />
            }
            isAnimationActive={false}
          />
          <Bar
            name='Vulnerabilities Identified'
            fill={VULNERABILITY_BASE_COLOR}
            yAxisId='left'
            dataKey='vulnerabilitySum'
            isAnimationActive={false}
          >
            <LabelList
              dataKey='vulnerabilitySum'
              position='top'
              fontSize={14}
              formatter={(x) => x || undefined}
            />
          </Bar>
          <Area
            name='Lessons Completed'
            fill={COMPLETION_BASE_COLOR}
            yAxisId='right'
            dataKey='completionSum'
            isAnimationActive={false}
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div className='impact-report-legend-overlay'>
        <ChartLegend
          vulnerabilityColor={VULNERABILITY_BASE_COLOR}
          completionLineColor={COMPLETION_LINE_COLOR}
          completionAreaColor={COMPLETION_AREA_COLOR}
        />
      </div>
      {isLoading && (
        <div className='impact-report-loading-overlay'>Loading...</div>
      )}
    </div>
  )
}
Chart.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  vulnerabilityCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  excludedCategoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string.isRequired,
      vulnerabilityCounts: PropTypes.object.isRequired,
      completionCounts: PropTypes.object.isRequired,
      cumulativeCompletionCounts: PropTypes.object.isRequired,
    })
  ).isRequired,
}

export default Chart
