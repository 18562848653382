/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import Statistics from 'app/views/components/Statistics'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import UIkit from 'uikit'
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Text,
} from 'recharts'
import moment from 'moment'

import {
  getIntegrationDetail,
  loadMoreIssues,
  runIntegration,
  deleteIntegration,
  exportAllIssues,
} from 'app/state/modules/integrations'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import settings from 'settings'
import Icon from 'app/views/components/Icon'
import CustomizedAxisTick from 'app/views/components/CustomizedAxisTick'
import SortableTableHeader from 'app/views/components/SortableTableHeader'

class IntegrationDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 'timestamp',
      asc: false,
    }
    this.onDelete = this.onDelete.bind(this)
    this.initializeListener = this.initializeListener.bind(this)
    this.onExport = this.onExport.bind(this)
    this.onFetcchSorted = this.onFetcchSorted.bind(this)
    this.props.getIntegrationDetail(
      this.props.match.params.organization_uuid,
      this.props.match.params.integration_uuid,
      this.state.active,
      this.state.asc
    )
    this.headers = [
      { key: 'title', name: 'Title' },
      { key: 'app_name', name: 'Application' },
      { key: 'severity', name: 'Severity' },
      { key: 'vuln_str', name: 'Vulnerability' },
      { key: 'timestamp', name: 'Date' },
      { key: 'url', name: '' },
    ]
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.loadingDetail && !this.props.loadingDetail) {
      if (this.props.integrationDetail.syncing) {
        this.initializeListener()
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  onDelete() {
    UIkit.modal
      .confirm(
        'Are you sure you want to remove this integration? All issues from this source will be permanently deleted.',
        {
          stack: true,
        }
      )
      .then(
        () => {
          this.props.deleteIntegration(
            this.props.match.params.organization_uuid,
            this.props.match.params.integration_uuid,
            () => {
              this.props.history.push(
                `/admin/${this.props.match.params.organization_uuid}/integrations`
              )
            }
          )
        },
        () => {}
      )
  }

  onFetcchSorted(sortBy) {
    const { asc, active } = this.state
    this.setState(
      {
        active: sortBy,
        asc: sortBy === active ? !asc : true,
      },
      () => {
        this.props.getIntegrationDetail(
          this.props.match.params.organization_uuid,
          this.props.match.params.integration_uuid,
          this.state.active,
          this.state.asc,
          true
        )
      }
    )
  }

  onExport() {
    this.setState(
      {
        exporting: true,
      },
      async () => {
        const response = await this.props.exportAllIssues(
          this.props.match.params.organization_uuid,
          this.props.match.params.integration_uuid,
          this.state.active,
          this.state.asc
        )
        const rows = []
        rows.push([
          'Title',
          'Application',
          'Severity',
          'Vulnerabilities',
          'Date',
          'Url',
        ])
        response.forEach((issue) => {
          rows.push([
            (issue.title || '').replace('#', ''),
            (issue.app_name || '').replace('#', ''),
            (issue.severity ? `${issue.severity}` : '').replace('#', ''),
            (issue.vuln_str || '').replace('#', ''),
            (issue.timestamp || '').replace('#', ''),
            (issue.url || '').replace('#', ''),
          ])
        })
        const csvContent = `data:text/csv;charset=utf-8,${rows
          .map((e) => e.join(','))
          .join('\n')}`
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute(
          'download',
          `issue_source_data_${moment().format('YYYY-MM-DD')}.csv`
        )
        document.body.appendChild(link)
        link.click()
        this.setState({ exporting: false })
      }
    )
  }

  initializeListener() {
    this.interval = setInterval(() => {
      this.props.getIntegrationDetail(
        this.props.match.params.organization_uuid,
        this.props.match.params.integration_uuid,
        this.state.active,
        this.state.asc,
        true,
        (data) => {
          if (!data.syncing) {
            clearInterval(this.interval)
          }
        }
      )
    }, 10000)
  }

  renderHeaders() {
    return this.headers.map((header, index) => (
      <SortableTableHeader
        onClick={this.onFetcchSorted}
        key={header.key}
        keyName={header.key}
        middle
        notSortable={['vulnerability', 'url'].includes(header.key)}
        center={index > 0}
        name={header.name}
        active={header.key === this.state.active}
        asc={header.key === this.state.active && this.state.asc}
        style={{
          fontSize: 10,
          maxWidth: header.key === 'title' ? '25%' : undefined,
        }}
      />
    ))
  }

  renderMessage() {
    if (this.props.integrationDetail.type === 'custom') {
      return 'Scans are only available on first-party integrations.'
    }
    if (this.props.integrationDetail.is_native_only) {
      return (
        <>
          <span>
            Automated scans are only available for Cloud Integrations.
          </span>
          <br />
          <span>Sync your native integration with the HackEDU CLI</span>
        </>
      )
    }
    if (
      !settings.activeIntegrations.includes(this.props.integrationDetail.type)
    ) {
      return 'This integration is pending sync'
    }
    return 'This integration is queried for new issues automatically every 24 hours.'
  }

  render() {
    const keys = {
      domain: {
        icon: 'links',
      },
      username: {
        icon: 'user',
      },
      secret: {
        icon: 'key-2',
      },
      tenant: {
        icon: 'home-gear',
      },
      organization: {
        icon: 'building',
      },
      key: {
        icon: 'shield-keyhole',
      },
    }
    const { activeIntegrations } = settings
    return (
      <OrganizationLayout active='integrations' {...this.props}>
        {!this.props.loadingDetail && (
          <>
            <h1 className='flex justify-between'>
              {`${
                (this.props.integrationDetail || {}).title || 'Custom'
              } Integration`}
              <button
                type='button'
                onClick={this.onDelete}
                className='uk-button uk-button-default uk-button-small flex items-center'
              >
                <Icon
                  name='delete-bin'
                  ratio={0.8}
                  className='uk-margin-small-right'
                />
                Delete Integration
              </button>
            </h1>
            <div style={{ width: 850 }}>
              <h3>Settings</h3>
              <h5 style={{ marginTop: 0 }}>Teams:</h5>
              {this.props.integrationDetail.issues.teams.length === 0 ? (
                <p>This integration is applied to the whole organization</p>
              ) : (
                <>
                  {this.props.integrationDetail.issues.teams.map((t) => (
                    <label
                      key={t.uuid}
                      className='uk-label uk-label-primary uk-margin-right'
                    >
                      {t.name}
                    </label>
                  ))}
                </>
              )}
              {!this.props.integrationDetail.is_native_only &&
                this.props.integrationDetail.type !== 'custom' &&
                Object.keys(
                  settings.integrations[this.props.integrationDetail.type]
                    .settings.fields
                )
                  .sort((x, y) => {
                    if (x === 'domain') {
                      return -1
                    }
                    if (y === 'domain') {
                      return 1
                    }
                    if (x === 'secret') {
                      return 1
                    }
                    if (y === 'secret') {
                      return -1
                    }
                    if (x === 'username') {
                      return 1
                    }
                    return -1
                  })
                  .map((fieldKey) => {
                    return (
                      <React.Fragment key={fieldKey}>
                        <h5>
                          {
                            settings.integrations[
                              this.props.integrationDetail.type
                            ].settings.fields[fieldKey].name
                          }
                          :
                        </h5>
                        <div
                          className='uk-placeholder text-muted uk-padding-small flex uk-position-relative'
                          style={{ paddingLeft: 60 }}
                        >
                          <Icon
                            className='text-muted'
                            name={keys[fieldKey].icon}
                            style={{
                              position: 'absolute',
                              left: 20,
                            }}
                          />
                          {fieldKey === 'secret' &&
                          this.props.integrationDetail.settings[fieldKey]
                            .length > 64
                            ? this.props.integrationDetail.settings[
                                fieldKey
                              ].slice(0, 64)
                            : this.props.integrationDetail.settings[fieldKey] ||
                              ' - '}
                        </div>
                      </React.Fragment>
                    )
                  })}
              {this.props.integrationDetail.type !== 'custom' &&
                !this.props.integrationDetail.is_native_only && (
                  <>
                    <h5>{`Selected ${this.props.integrationDetail.choose}`}</h5>
                    <div
                      className='uk-placeholder text-muted uk-padding-small flex uk-position-relative'
                      style={{ paddingLeft: 60 }}
                    >
                      <Icon
                        className='text-muted'
                        name='apps'
                        style={{
                          position: 'absolute',
                          left: 20,
                        }}
                      />
                      <div className='flex flex-row flex-wrap'>
                        {Object.values(
                          this.props.integrationDetail.settings.programNames
                        ).map((program) => (
                          <label
                            className='uk-label uk-label-primary uk-margin-small-right'
                            key={program.id}
                            style={{ marginTop: 5, marginBottom: 5 }}
                          >
                            {program.name}
                          </label>
                        ))}
                      </div>
                    </div>
                  </>
                )}

              <h3>Automation Schedule</h3>
              {!this.props.integrationDetail.active && (
                <div className='uk-alert-danger uk-alert'>
                  <span>
                    Integration not syncing. Something may be wrong with your
                    credentials:
                  </span>
                  <br />
                  <code>
                    {(this.props.integrationDetail || {}).error
                      ? this.props.integrationDetail.error.trace
                      : ''}
                  </code>
                </div>
              )}
              {this.props.integrationDetail.active && (
                <div
                  className={`uk-alert-${
                    this.props.integrationDetail.type === 'custom'
                      ? 'warning'
                      : this.props.integrationDetail.last_scanned_at
                      ? 'success'
                      : 'primary'
                  } uk-alert`}
                >
                  <p>{this.renderMessage()}</p>
                  {this.props.integrationDetail.syncing ? (
                    <span className='text-danger'>
                      hackedu issues syncing ...
                    </span>
                  ) : null}
                  {this.props.integrationDetail.type !== 'custom' && (
                    <p>
                      <i>Last scanned:</i>
                      &nbsp;
                      <span className='font-bold'>
                        {this.props.integrationDetail.last_scanned_at
                          ? getLocalDateFromUTC(
                              this.props.integrationDetail.last_scanned_at
                            ).fromNow()
                          : 'Never'}
                      </span>
                    </p>
                  )}
                </div>
              )}
              {activeIntegrations.includes(this.props.integrationDetail.type) &&
                this.props.integrationDetail.active &&
                !this.props.integrationDetail.is_native_only && (
                  <button
                    disabled={
                      this.props.integrationDetail.syncing ||
                      this.props.integrationDetail.type === 'custom' ||
                      !activeIntegrations.includes(
                        this.props.integrationDetail.type
                      )
                    }
                    uk-tooltip={
                      this.props.integrationDetail.type === 'custom'
                        ? 'Scans are only available on first-party integrations.'
                        : null
                    }
                    type='button'
                    className='uk-button uk-button-primary'
                    onClick={() => {
                      this.props.runIntegration(
                        this.props.match.params.organization_uuid,
                        this.props.match.params.integration_uuid,
                        true
                      )
                      this.initializeListener()
                      UIkit.modal.alert(`<h2>Integration Syncing</h2>
                    <p>We are syncing your integration records. This may take several minutes to finish depending on the number of records to process.</p>`)
                    }}
                  >
                    {this.props.integrationDetail.syncing
                      ? 'Syncing...'
                      : 'Run Now'}
                  </button>
                )}
            </div>
            {[...activeIntegrations, 'custom'].includes(
              this.props.integrationDetail.type
            ) && (
              <>
                <h3> Issues</h3>
                <div uk-grid='' className='uk-grid-match'>
                  <div className='uk-width-1-3'>
                    <div>
                      <br />
                      <br />
                      <br />
                      <Statistics
                        header={
                          <span style={{ fontSize: 70 }}>
                            {this.props.integrationDetail.issues.total}
                          </span>
                        }
                        detail={<span>ISSUE COUNT</span>}
                      />
                    </div>
                  </div>
                  <div className='uk-width-2-3'>
                    <ResponsiveContainer
                      height={380}
                      className='text-sm uk-width-1-1'
                      // width={window.innerWidth * 0.5}
                    >
                      <BarChart
                        data={
                          this.props.integrationDetail.issues
                            .issues_per_vulnerability
                        }
                        margin={{
                          top: 0,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <XAxis
                          height={200}
                          interval={0}
                          tick={<CustomizedAxisTick />}
                          dataKey='title'
                        />
                        <YAxis
                          label={
                            <Text
                              x={-20}
                              y={0}
                              dx={50}
                              dy={200}
                              offset={0}
                              fill='rgb(102, 102, 102)'
                              angle={-90}
                            >
                              Vulnerabilities
                            </Text>
                          }
                        />
                        <Tooltip
                          content={({ payload }) => {
                            if (payload && payload[0]) {
                              return (
                                <div className='recharts-tooltip-wrapper'>
                                  <div
                                    className='recharts-default-tooltip'
                                    style={{
                                      border: 'solid 1px rgb(204, 204, 204)',
                                      backgroundColor: '#fff',
                                      padding: 10,
                                    }}
                                  >
                                    <p className='recharts-tooltip-label uk-margin-remove-bottom'>
                                      {payload[0].payload.title}
                                    </p>
                                    <ul className='uk-nav uk-nav-default uk-margin-small-top'>
                                      <li style={{ color: '#1e87f0' }}>
                                        <span>
                                          {`${
                                            payload[0].payload.vulnerabilities
                                          } ${
                                            payload[0].payload.vulnerabilities >
                                            1
                                              ? 'vulnerabilities'
                                              : 'vulnerabilities'
                                          }`}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )
                            }
                            return null
                          }}
                        />
                        <Bar
                          dataKey='vulnerabilities'
                          stackId='bar'
                          fill='#1e87f0'
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                {(this.props.integrationDetail.issues.issues || []).length >
                0 ? (
                  <>
                    <div className='flex justify-end'>
                      <button
                        className='uk-button uk-button-default flex'
                        type='button'
                        onClick={this.onExport}
                        disabled={this.state.exporting}
                      >
                        <Icon
                          className='uk-margin-right'
                          name='download-cloud'
                        />
                        {this.state.exporting
                          ? 'Preparing Data...'
                          : 'Download data'}
                      </button>
                    </div>
                    <table className='uk-table uk-table-divider uk-table-middle text-sm uk-table-small'>
                      <thead>
                        <tr>{this.renderHeaders()}</tr>
                      </thead>
                      <tbody>
                        {this.props.integrationDetail.issues.issues.map(
                          (issue) => (
                            <tr key={`${issue.uuid}`}>
                              <td style={{ width: '27.5%' }}>{issue.title}</td>
                              <td
                                style={{ width: 160 }}
                                className='text-center'
                              >
                                {issue.app_name ? (
                                  <label
                                    className='uk-label uk-label-primary truncate'
                                    style={{ maxWidth: 145 }}
                                    uk-tooltip={issue.app_name}
                                  >
                                    {issue.app_name}
                                  </label>
                                ) : (
                                  ' - '
                                )}
                              </td>
                              <td
                                style={{ width: 70 }}
                                className='text-center'
                              >
                                {issue.severity}
                              </td>
                              <td className='text-center'>
                                {issue.vulnerabilities
                                  .map((v) => v.title)
                                  .join(', ')}
                              </td>
                              <td
                                style={{
                                  width: 120,
                                }}
                              >
                                {issue.timestamp
                                  ? getLocalDateFromUTC(issue.timestamp).format(
                                      'MMM D, YYYY'
                                    )
                                  : ' - '}
                              </td>
                              <td
                                className='text-right'
                                style={{ width: 150 }}
                              >
                                {issue.url && (
                                  <a
                                    className='uk-button uk-button-default uk-button-small flex items-center'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={issue.url}
                                    onClick={(e) => {
                                      if (
                                        this.props.integrationDetail.type ===
                                        'hclappscan'
                                      ) {
                                        e.preventDefault()
                                        navigator.clipboard
                                          .writeText(issue.url)
                                          .then(
                                            () => {
                                              UIkit.notification({
                                                message:
                                                  'Original Issue URL Copied',
                                                status: 'success',
                                                pos: 'top-center',
                                                timeout: 5000,
                                              })
                                              this.modalInvite.current.hide()
                                            },
                                            (err) => {
                                              console.error(
                                                'Async: Could not copy text: ',
                                                err
                                              )
                                            }
                                          )
                                      }
                                    }}
                                  >
                                    <Icon
                                      none
                                      name='link'
                                      ratio={0.8}
                                      className='uk-margin-small-right'
                                    />
                                    {this.props.integrationDetail.type ===
                                    'hclappscan'
                                      ? 'Copy Original URL'
                                      : 'Open Original'}
                                  </a>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p className='text-center'>No Issues.</p>
                )}
                {this.props.integrationDetail.issues.total !==
                  this.props.integrationDetail.issues.issues.length && (
                  <div className='text-center'>
                    <button
                      disabled={this.props.loadingAddMore}
                      type='button'
                      className='uk-button uk-button-primary uk-button-small'
                      onClick={() => {
                        this.props.loadMoreIssues(
                          this.props.match.params.organization_uuid,
                          this.props.match.params.integration_uuid,
                          this.state.active,
                          this.state.asc
                        )
                      }}
                    >
                      {this.props.loadingAddMore
                        ? 'Loading More...'
                        : 'Load More'}
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = ({ integrations, hacker }) => ({
  integrations: integrations.integrations,
  integrationsHash: integrations.integrationsHash,
  integrationDetail: integrations.integrationDetail,
  loadingIntegrations: integrations.loadingIntegrations,
  loadingDetail: integrations.loadingDetail,
  organizations: hacker.organizationsList,
  organizationsHash: hacker.organizationsHash,
  loadingAddMore: integrations.loadingAddMore,
  loadingRunAgain: integrations.loadingRunAgain,
})

export default connect(mapStateToProps, {
  getIntegrationDetail,
  exportAllIssues,
  loadMoreIssues,
  runIntegration,
  deleteIntegration,
})(IntegrationDetail)
