import { values } from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import UIkit from 'uikit'

// Components
import Loader from 'app/views/components/Loader'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import PhaseDetailModal from 'app/views/components/PhaseDetailModal'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import SecondaryButton from '../../../components/Buttons/SecondaryButton'

// Redux
import {
  getPlanById,
  setCustomPlansNotDefault,
  fetchPhaseDetailModalData,
  resetPlan,
} from 'app/state/modules/plan'

// Utils
import analytics from 'app/views/utils/analytics'

// Local Components
import Group from './components/Group'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

class OrganizationsPlans extends React.Component {
  constructor(props) {
    super(props)
    this.fetchPhaseDetailModalData = this.fetchPhaseDetailModalData.bind(this)
    this.onRestartModal = this.onRestartModal.bind(this)
  }

  componentDidMount() {
    this.props.getPlanById(
      this.props.match.params.organization_uuid,
      this.props.match.params.plan_uuid
    )
    analytics.page('page-organizations-plans-details')
  }

  checkIfPhaseIsActive(startDate, endDate) {
    const now = moment(moment().utc().format('YYYY-MM-DD'), 'YYYY-MM-DD').unix()
    const startDateUnix = moment(startDate).unix()
    const endDateUnix = moment(endDate).unix()
    return (
      (now >= startDateUnix && now <= endDateUnix) ||
      (isNaN(startDateUnix) && now <= endDateUnix) ||
      (isNaN(endDateUnix) && now >= startDateUnix) ||
      (isNaN(endDateUnix) && isNaN(startDateUnix))
    )
  }

  fetchPhaseDetailModalData(phaseUUID) {
    const plan = this.props.planMap[this.props.match.params.plan_uuid]
    this.props.fetchPhaseDetailModalData(
      this.props.match.params.organization_uuid,
      plan.team_uuid,
      plan.uuid,
      phaseUUID
    )
  }

  onRestartModal() {
    UIkit.modal
      .confirm(
        'This action will restart the start date for all the users assigned to this plan. Are you sure you want to continue?'
      )
      .then(
        () => {
          this.props.resetPlan(
            this.props.match.params.organization_uuid,
            this.props.match.params.plan_uuid,
            () => {
              UIkit.modal.alert(
                'The plan start date was successfully reset for all users.'
              )
            }
          )
        },
        () => {}
      )
  }

  renderPlansGroups() {
    const plan = this.props.planMap[this.props.match.params.plan_uuid]
    const totalPhases = plan.groups.length

    if (totalPhases === 0) {
      return <p>You have not added a training phase yet.</p>
    }
    return plan.groups.map((group) => (
      <Group
        fetchPhaseDetailModalData={this.fetchPhaseDetailModalData}
        isTimeBased={plan.is_time_based}
        duration={group.duration}
        isActive={this.checkIfPhaseIsActive(group.start_date, group.end_date)}
        key={group.uuid}
        planId={plan.uuid}
        isOrganizationPlan={plan.organization_uuid}
        title={group.title}
        groupId={group.uuid}
        description={group.description}
        startDate={group.start_date}
        endDate={group.end_date}
        contentArray={group.content}
        organizationId={this.props.match.params.organization_uuid}
      />
    ))
  }

  render() {
    const plan = this.props.planMap[this.props.match.params.plan_uuid]
    const organizationId = this.props.match.params.organization_uuid
    if (!(this.props.match.params.plan_uuid in this.props.planMap)) {
      return <Loader visible />
    }
    const isOrganizationPlan = plan.organization_uuid
    const defaultPlan = plan.default_plan
    return (
      <OrganizationLayout {...this.props} active='plans'>
        <h1>Training Plan</h1>
        <div>
          <div className='uk-padding'>
            <h3 style={{ float: 'left' }}>
              {plan.title}
              &nbsp;
              {defaultPlan && (
                <strong
                  style={{
                    backgroundColor: '#1e87f0',
                    color: '#fff',
                    fontSize: '12px',
                    padding: '3px 10px',
                    lineHeight: 1.5,
                    verticalAlign: 'middle',
                    borderRadius: 2,
                    whiteSpace: 'nowrap',
                    textTransform: 'uppercase',
                    marginLeft: 20,
                  }}
                >
                  Default plan
                </strong>
              )}
            </h3>
            <div>
              {plan.organization_uuid && (
                <PrimaryButton
                  link_to={`/admin/${organizationId}/plans/${plan.uuid}/edit`}
                  customCss='float-right'
                  size='small'
                  label='Edit'
                />
              )}
              <SecondaryButton
                size='small'
                customCss='float-right mr-2'
                onClick={this.onRestartModal}
                label='Restart Plan'
              />
            </div>
            <div style={{ clear: 'both' }} />
            <p>{plan.description}</p>
            {/* <p>
              Email Reminders are:
              &nbsp;
              <strong>
                {plan.send_reminders ? 'On' : 'Off'}
              </strong>
            </p> */}
            {!isOrganizationPlan && !defaultPlan && (
              <p>
                <button
                  onClick={() => {
                    this.props.setCustomPlansNotDefault(organizationId)
                  }}
                  className='uk-button uk-button-primary'
                  type='button'
                >
                  Set as default plan
                </button>
              </p>
            )}
          </div>

          <div className='uk-padding'>
            <h3 style={{ float: 'left' }}>Training Phases</h3>
            {plan.organization_uuid && (
              <PrimaryButton
                link_to={`/admin/${organizationId}/plans/${plan.uuid}/phase/add`}
                style={{ float: 'right' }}
                size='medium'
                label='Add Training Phase'
                customCss='float-right'
              />
            )}
            <div style={{ clear: 'both' }} />
            {this.renderPlansGroups()}
          </div>
        </div>
        <PhaseDetailModal />

        {/* Modals */}
        <div id='modal-confirm-create-custom-plan' data-uk-modal>
          <div className='uk-modal-dialog uk-modal-body'>
            <h2>Create Custom Plan</h2>
            <p>
              Please note, this will change what users on your team see when
              they first login (under the My Plan) tab of their dashboard. It
              will also affect the training notices sent out to your team.
            </p>
            <p>
              You can always remove your custom plan to get the default plan
              back.
            </p>
            <p className='text-right'>
              <button
                className='uk-button uk-button-muted uk-modal-close'
                type='button'
              >
                Cancel
              </button>
              <button
                className='uk-button uk-button-primary tm-button-primary uk-modal-close'
                type='button'
                onClick={() => {
                  this.props.history.push(`/admin/${organizationId}/plans/add`)
                }}
              >
                Create Custom Plan
              </button>
            </p>
            <button
              className='uk-modal-close-outside'
              type='button'
              data-uk-close
            />
          </div>
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  // content
  courses: state.content.courses,
  lessonMap: state.content.lessonMap,
  tests: state.content.tests,

  // hacker
  organizations: values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,
  filterHacker: state.hacker.filterHacker,
  filteredHackers: state.hacker.currentTeamHackers,
  isLoadingHackers: state.hacker.isLoadingHackers,
  // plans
  planMap: state.plan.planMap,
  usersPlan: state.plan.usersPlan,
  // user
  email: state.auth.user.email,
})
const mapDispatchToProps = (dispatch) => ({
  getPlanById: (organizationId, planId) =>
    dispatch(getPlanById(organizationId, planId)),
  setCustomPlansNotDefault: (organizationId) =>
    dispatch(setCustomPlansNotDefault(organizationId)),
  fetchPhaseDetailModalData: (
    organizationUUID,
    teamUUID,
    planUUID,
    phaseUUID
  ) =>
    dispatch(
      fetchPhaseDetailModalData(organizationUUID, teamUUID, planUUID, phaseUUID)
    ),
  resetPlan: (organizationUUID, planUUID, callback) =>
    dispatch(resetPlan(organizationUUID, planUUID, callback)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsPlans)
)
