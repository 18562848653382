// Utility code used when the HackEDU webapp (www-app) is loaded into an iframe hosted
// within the SecurityJourney webapp (sj_frontend).

// Keeps global state for this file
const g = {
  useReferrerTargeting: false
}

/**
 * A client-side message sent to the SecurityJourney webapp from an instance of the HE
 * app that's running in an iframe within the SJ app.
 */
type HeEvent = {
  // Indicates to the SJ app that the user who's authenticated in the SJ app has
  // completed some action in the HE app that affects their completion status with
  // respect to whatever HE lesson is currently loaded. This is a non-authoritative hint
  // to the SJ app that it should synchronize lesson status for that user and lesson
  // (i.e. pull state from the HE backend and push it into the SJ backend).
  type:
    | 'lesson-status-changed'
    | 'lesson-complete'
    | 'lesson-needs-work'
    | 'lesson-title'
}

/**
 * Set referrer targeting flag to internal state object
 * @param flagValue 
 */
export function setUseReferrerTargeting(flagValue: boolean) {
  g.useReferrerTargeting = flagValue
}

/**
 * Makes a postMessage call to send a message to the parent window, iff the app is
 * loaded into an iframe within the SecurityJourney app. Has no effect when the app is
 * run in any other context.
 */
export function notifyParentSjApp(message: HeEvent) {
  // If there is no parent window, we're not loaded into an iframe
  if (!window.parent || window.top === window.self) {
    return
  }

  // If we can't figure out which origin it's safe to post messages to, abort
  const targetOrigin = resolveTargetOrigin()
  if (!targetOrigin) {
    // Production builds should always resolve the hardcoded SJ_ORIGIN, so this warning
    // oughta be dev-only
    console.warn('www-app has parent window but no targetOrigin could be resolved; message will not be posted', message)
    return
  }

  // Send the message to the SJ frontend
  window.parent.postMessage(message, targetOrigin)
}

function resolveTargetOrigin(): string {
  // In the default case (e.g. prod), only allow a single hardcoded origin, as we only
  // expect www-app to be embedded via iframe in the canonical mySJ deployment
  if (!g.useReferrerTargeting) {
    return process.env.SJ_ORIGIN
  }

  // When the useReferrerTargeting flag is set (i.e. for dev/local), we want to
  // procedurally resolve the origin of the parent window - we use the referrer origin
  // since browser security restrictions prevent us from reading window.parent.origin
  if (!document.referrer) {
    // If we've been loaded up in an iframe from a parent sj_frontend app,
    // document.referrer should match the URL on which that app is running: but
    // early-out in case we have no referrer
    return ''
  }
  const referrerOrigin = new URL(document.referrer).origin

  // In dev builds, SJ_ORIGIN contains multiple domains, any subdomain of which should
  // be treated as an allowed origin: ensure that our referrer origin matches one of
  // them
  const allowedDomains = process.env.SJ_ORIGIN.split(',')
  if (!allowedDomains.find((domain) => referrerOrigin.endsWith(domain))) {
    return ''
  }
  return referrerOrigin
}
