import React from 'react'

export default function PrimaryCheckBox({
  onChange,
  value,
  name,
  checked,
  label,
  ref,
  customCss,
}) {
  return (
    <label className='flex items-center' htmlFor={name}>
      <input
        className={`${customCss} mr-3 border-solid`}
        ref={ref}
        type='checkbox'
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      {label && <span className='pl-2 text-dark_gray'>{label}</span>}
    </label>
  )
}
