import React from 'react'

import Icon from 'app/views/components/Icon'

// Settings
import settings from 'settings'

import FormatedMinutes from 'app/views/components/FormatedMinutes'

import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

class CodeReviewTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      details: null,
    }
  }

  renderDetails() {
    return (
      <div className='text-sm'>
        <button
          className='uk-button uk-button-secondary uk-button-small flex items-center'
          onClick={() => this.setState({ details: null })}
          style={{
            paddingLeft: 10,
            height: 40,
          }}
          type='button'
        >
          <Icon name='arrow-left' style={{ paddingRight: 10 }} />
          Back
        </button>
        <div uk-grid='' className='uk-width-1-1'>
          <div className='uk-width-1-1 uk-margin' />
          <div className='uk-width-1-3 text-center'>
            <br />
            <br />
            <h4 className='uk-margin-remove'>Hints Used</h4>
            {Object.keys(this.state.details.usedHints || {}).length === 0 &&
              ' - '}
            {Object.keys(this.state.details.usedHints || {}).map((hintKey) => (
              <h5
                key={hintKey}
                className='text-secondary uk-inline uk-margin-remove text-center uk-width-1-1 capitalize'
              >
                <span>{hintKey}</span>
                <span className='uk-label uk-label-danger uk-margin-left'>
                  {this.state.details.usedHints[hintKey]}
                </span>
              </h5>
            ))}
            <br />
            <br />
          </div>
          <div
            className='uk-width-1-3 text-center'
            style={{
              borderLeft: 'solid 1px #e5e5e5',
              borderRight: 'solid 1px #e5e5e5',
            }}
          >
            <br />
            <br />
            <h4 className='uk-margin-remove'>Incorrect Submissions</h4>
            <h5 className='text-secondary uk-inline uk-margin-remove'>
              {`${this.state.details.attempts} Attempts`}
            </h5>
            <span className='uk-label uk-label-success uk-margin-left'>0</span>
            <br />
            <br />
          </div>
          <div className='uk-width-1-3 text-center'>
            <br />
            <br />
            <h4 className='uk-margin-remove'>Time on Question</h4>
            <h5 className='text-secondary uk-inline uk-margin-remove'>
              <FormatedMinutes
                isText
                minutes={this.state.details.time_on_question}
              />
            </h5>
            {/* <span className="uk-label uk-label-success uk-margin-left">+4</span> */}
            <br />
            <br />
          </div>
          <div className='uk-width-1-1 uk-margin' />
        </div>
      </div>
    )
  }

  render() {
    const { codeReviews } = this.props
    if (codeReviews === 'loading') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
          }}
        >
          <div data-uk-spinner='' className='uk-align-center' />
        </div>
      )
    }

    if (this.state.details) {
      return this.renderDetails()
    }
    if (codeReviews.length === 0) {
      return (
        <div className='uk-align-center uk-width-1-2@m'>
          <p className='text-center'>
            User has not completed any coding challenges.
          </p>
        </div>
      )
    }

    if (codeReviews.length > 0) {
      return (
        <div className='uk-width-1-1 uk-grid text-sm'>
          <div className='uk-width-1-6'>
            <div className='hackedu-content-menu' style={{ minHeight: 300 }}>
              <ul className='links dark'>
                {codeReviews.map((language, i) => {
                  const className =
                    (this.state.activeTab === null && i === 0) ||
                    this.state.activeTab === i
                      ? 'active'
                      : ''
                  return (
                    <li key={language.language_name} className={className}>
                      <a // eslint-disable-line
                        onClick={() => {
                          this.setState({ activeTab: i })
                        }}
                      >
                        {settings.engines[language.language_name]}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div
            className='uk-width-5-6 uk-section-small'
            style={{ paddingTop: 15 }}
          >
            <table className='uk-table uk-table-divider'>
              <thead>
                <tr>
                  <th>Framework</th>
                  <th className='text-center'>App</th>
                  <th>Vulnerability</th>
                  <th className='text-center'>Difficulty</th>
                  <th className='text-center'>Points</th>
                  <th className='text-center'>Last Completed</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.props.codeReviews[this.state.activeTab].frameworks.map(
                  (framework) => {
                    const rowSpanFramework = framework.apps.reduce(
                      (p, c) => p + c.content.length,
                      0
                    )
                    return (
                      <React.Fragment key={framework.name}>
                        {framework.apps.map((app, indexApp) => (
                          <React.Fragment key={app.name}>
                            {app.content.map((content, indexContent) => (
                              <React.Fragment key={content.uuid}>
                                <tr>
                                  {indexApp === 0 && indexContent === 0 && (
                                    <td
                                      className='text-secondary'
                                      rowSpan={rowSpanFramework}
                                      style={{ verticalAlign: 'middle' }}
                                    >
                                      {settings.frameworks[framework.name]}
                                    </td>
                                  )}
                                  {indexContent === 0 && (
                                    <td
                                      rowSpan={app.content.length}
                                      style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {settings.apps[app.name]}
                                    </td>
                                  )}
                                  <td>{content.title}</td>
                                  <td className='capitalize text-center'>
                                    {content.difficulty}
                                  </td>
                                  <td className='text-center'>
                                    {`${
                                      typeof content.score === 'number'
                                        ? content.score
                                        : '-'
                                    } / ${content.points}`}
                                  </td>
                                  <td className='text-center'>
                                    {content.completed_at
                                      ? getLocalDateFromUTC(
                                          content.completed_at
                                        ).format('MMMM D, YYYY')
                                      : ' - '}
                                  </td>
                                  <td>
                                    {this.state.details &&
                                    this.state.details.uuid === content.uuid ? (
                                      <button
                                        className='uk-button uk-button-secondary uk-button-small uk-align-right'
                                        type='button'
                                        onClick={() =>
                                          this.setState({ details: null })
                                        }
                                      >
                                        Close
                                      </button>
                                    ) : (
                                      <button
                                        className='uk-button uk-button-primary uk-button-small uk-align-right'
                                        type='button'
                                        onClick={() =>
                                          this.setState({ details: content })
                                        }
                                      >
                                        Details
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    )
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
    return null
  }
}

export default CodeReviewTab
