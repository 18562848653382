/* eslint-disable no-param-reassign */
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

// Components
import GenericModal from 'app/views/components/Modals/GenericModal'
import Icon from 'app/views/components/Icon'

// Redux
import { updateScratchpadContent } from 'app/state/modules/hacker'

class ScratchpadButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: this.props.settings.scratchpad
        ? this.props.settings.scratchpad.notes
        : '',
      status: null,
    }
    this.firstLoad = false
    this.onChangeNotes = this.onChangeNotes.bind(this)
    this.renderStatus = this.renderStatus.bind(this)
    this.exportFile = this.exportFile.bind(this)
  }

  componentDidUpdate() {
    if (!this.firstLoad && !this.props.settings.scratchpad) {
      this.firstLoad = true
      this.initialize('')
    } else if (
      !this.firstLoad &&
      typeof this.props.settings.scratchpad.notes === 'string' &&
      this.state.notes !== this.props.settings.scratchpad.notes
    ) {
      this.firstLoad = true
      this.initialize(this.props.settings.scratchpad.notes)
    }
    return {}
  }

  componentWillUnmount() {
    if (this.modal) {
      this.modal.destroy()
    }
  }

  onChangeNotes(e) {
    this.setState(
      {
        notes: e.target.value,
      },
      () => {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.setState(
            {
              status: 'Saving...',
            },
            () => {
              this.props.updateScratchpadContent(this.state.notes, () => {
                this.setState({
                  status: `Saved at ${moment().fromNow()}`,
                })
              })
            }
          )
        }, 1000)
      }
    )
  }

  setCaretAtEnd(elem) {
    const elemLen = elem.value.length
    if (document.selection) {
      elem.focus()
      const oSel = document.selection.createRange()
      oSel.moveStart('character', -elemLen)
      oSel.moveStart('character', elemLen)
      oSel.moveEnd('character', 0)
      oSel.select()
    } else if (
      elem.selectionStart ||
      elem.selectionStart === '0' ||
      elem.selectionStart === 0
    ) {
      elem.selectionStart = elemLen
      elem.selectionEnd = elemLen
      elem.focus()
    }
  }

  initialize(notes) {
    this.firstLoad = true
    this.setState({
      notes,
    })
  }

  exportFile() {
    let url = null
    const data = new Blob([this.state.notes], {
      type: 'text/plain;charset=utf-8',
    })
    if (url !== null) {
      window.URL.revokeObjectURL(url)
    }

    url = window.URL.createObjectURL(data)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = 'hackNotes.txt'
    a.click()
    window.URL.revokeObjectURL(url)
  }

  renderStatus() {
    if (this.state.status) {
      return this.state.status
    }
    return 'Nothing to save...'
  }

  render() {
    if (
      !this.props.settings.scratchpad ||
      !this.props.settings.scratchpad.enabled
    ) {
      return null
    }
    return (
      <>
        <button
          onClick={() => {
            this.modal.show()
          }}
          type='button'
          className='uk-button uk-button-primary uk-margin-remove uk-padding-remove'
          style={{
            borderRadius: '100%',
            width: 30,
            height: 30,
            position: 'fixed',
            left: 8,
            bottom: 8,
          }}
        >
          <div
            style={{ height: 30 }}
            className='flex items-center justify-center'
          >
            <Icon name='file-edit' ratio={0.8} style={{}} />
          </div>
        </button>
        <GenericModal
          activeListeners
          afterShown={() => {
            const elem = document.querySelector(
              `#scratch-pad-modal${
                this.props.id ? `-${this.props.id}` : ''
              } textarea`
            )
            elem.focus()
            this.setCaretAtEnd(elem)
            elem.scrollTop = elem.scrollHeight
          }}
          closeOnClickOverlay
          width='uk-width-1-2'
          title={
            <>
              Scratchpad
              <button
                className='uk-button uk-button-default uk-button-small uk-align-right uk-padding-remove-right flex justify-center items-center'
                type='button'
                onClick={this.exportFile}
              >
                Export
                <Icon
                  ratio={0.85}
                  name='download-cloud'
                  className='uk-margin-small-left uk-margin-small-right'
                />
              </button>
            </>
          }
          closeText='Close'
          body={
            <>
              <textarea
                value={this.state.notes}
                onChange={this.onChangeNotes}
                className='uk-input'
                style={{
                  resize: 'none',
                  minHeight: window.innerHeight / 1.5,
                  fontFamily:
                    'Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace',
                  lineHeight: 1.5,
                }}
                placeholder='Add notes here...'
              />
              <small>{this.renderStatus()}</small>
            </>
          }
          id={`scratch-pad-modal${this.props.id ? `-${this.props.id}` : ''}`}
          ref={(ref) => {
            this.modal = ref
          }}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  settings: state.hacker.profile.settings,
})

export default connect(mapStateToProps, { updateScratchpadContent })(
  ScratchpadButton
)
