import React from 'react'
import UIkit from 'uikit'
import { connect } from 'react-redux'
import { getAutoCompleteHackers } from 'app/state/modules/hacker'
import Icon from 'app/views/components/Icon'

class AutoComplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emails: [],
      selected: null,
      isFocused: false,
      allowAdd: false,
    }
    this.added = false
    this.onChange = this.onChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onClean = this.onClean.bind(this)
    this.onClickItem = this.onClickItem.bind(this)
  }

  componentDidMount() {
    this.dropdown = UIkit.dropdown(`#${this.props.id}-dropdown`)
    document.addEventListener('keydown', this.onKeyDown)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value === '') {
      this.onClean()
    }
  }

  onClean() {
    this.added = false
    this.setState({ allowAdd: false })
  }

  onKeyDown(e) {
    if (e.keyCode === 13) e.preventDefault()
    if (e.keyCode === 9 || e.keyCode === 40 || e.keyCode === 38) {
      const { selected, emails } = this.state
      e.preventDefault()
      if (e.keyCode === 40) {
        if (selected === emails.length - 1)
          return this.setState({ selected: 0 })
        this.setState({
          selected: selected === null ? 0 : selected + 1,
        })
      }
      if (e.keyCode === 38) {
        if (selected === null || selected === 0)
          return this.setState({ selected: emails.length - 1 })
        this.setState({
          selected: selected - 1,
        })
      }
      if (e.keyCode === 9 && selected !== null && this.state.isFocused) {
        this.onClickItem(emails[selected].email)
      }
    }
  }

  onClickItem(value, isClick) {
    if (this.state.isFocused || isClick) {
      this.dropdown.hide()
      this.onChange(this.props.index, 'email', { target: { value } })
      this.added = true
      setTimeout(() => {
        const newInput = document.getElementById(
          `auto-complete-index-${this.props.index + 1}`
        )
        newInput.click()
        newInput.focus()
      }, 250)
    }
  }

  onChange(index, key, e) {
    const re =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (re.test(e.target.value)) {
      this.setState({
        allowAdd: true,
      })
    } else {
      this.setState({
        allowAdd: false,
      })
    }
    this.dropdown.show()
    this.props.onChange(index, key, e)
    if (e.target.value.length >= 3) {
      this.props
        .getAutoCompleteHackers(this.props.organizationUUID, e.target.value)
        .then(({ data }) => {
          this.setState({
            emails: data.users,
          })
        })
    }
    if (e.target.value === 0) {
      this.setState({
        emails: [],
        selected: null,
      })
    }
  }

  onFocus() {
    this.setState({
      isFocused: true,
    })
  }

  onBlur() {
    this.setState({
      isFocused: false,
    })
    if (!this.added) {
      const re =
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (re.test(this.props.value)) {
        this.added = true
        this.props.extraAction()
      } else {
        this.props.onChange(this.props.index, 'email', {
          target: { value: '' },
        })
      }
    }
  }

  render() {
    const { emails, selected } = this.state
    return (
      <>
        <input
          autoComplete='off'
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          id={`auto-complete-index-${this.props.index}`}
          className={`${this.props.className}`}
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.onChange.bind(this, this.props.index, 'email')}
          style={{
            ...this.props.style,
          }}
        />
        <div id={`${this.props.id}-dropdown`} uk-dropdown='mode: click'>
          {emails.length === 0 && (
            <div className='text-center'>
              {(this.props.value.length < 3 ||
                (this.props.value.length >= 3 &&
                  this.state.allowAdd === false)) && (
                <span>Please enter a valid email address</span>
              )}
              {this.state.allowAdd && (
                <small
                  style={{ color: '#1e87f0', cursor: 'pointer' }}
                  className='flex justify-center items-center flex-wrap'
                  onClick={() => {
                    this.onClickItem(this.props.value, true)
                  }}
                >
                  <Icon
                    name='add-circle'
                    ratio={0.8}
                    style={{ marginRight: 8 }}
                  />
                  Add &nbsp;
                  <b>{this.props.value}</b>
                </small>
              )}
            </div>
          )}
          {emails.length > 0 && <h4>Users found:</h4>}
          {emails.map(({ email }, index) => {
            return (
              <div
                onClick={() => this.onClickItem(email, true)}
                key={email}
                style={{
                  padding: 5,
                  cursor: 'pointer',
                  borderBottom: 'solid 1px #eee',
                  width: '100%',
                  fontSize: 15,
                  letterSpacing: 0.45,
                  margin: '3px 0px',
                  marginTop: 6,
                  ...(index === selected
                    ? {
                        borderBottom: 'solid 1px #1e87f0',
                        boxSizing: 'border-box',
                        color: '#1e87f0',
                        fontWeight: 'bold',
                      }
                    : {}),
                }}
              >
                {email}
              </div>
            )
          })}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

export default connect(
  mapStateToProps,
  {
    getAutoCompleteHackers,
  },
  null,
  {
    forwardRef: true,
  }
)(AutoComplete)
