import React from 'react'
import { connect } from 'react-redux'

import IntegrationCard from 'app/views/containers/Organizations/Integrations/IntegrationCard'
import Icon from 'app/views/components/Icon'

import { getAppIntegrations } from 'app/state/modules/integrations'
import settings from 'settings'

import OrganizationLayout from '../components/Layout'

class AppsIntegrations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.images = {
      microsoft: 'microsoft.jpg',
      slack: 'slack.png',
      github: 'github.png',
    }
  }

  componentDidMount() {
    this.props.getAppIntegrations(this.props.match.params.organization_uuid)
  }

  render() {
    return (
      <OrganizationLayout
        active='apps_integrations'
        isLoading={this.props.loadingIntegrations}
        {...this.props}
      >
        <h1>App Integrations</h1>
        <div
          className='flex flex-row flex-wrap'
          uk-height-match='.integration-card-description'
        >
          {this.props.appIntegrations
            .filter(
              (integration) =>
                !['slack', 'microsoft'].includes(integration.source)
            )
            .map((integration) => {
              return (
                <IntegrationCard
                  key={integration.uuid}
                  small
                  noHover
                  imageHeight={60}
                  style={{
                    width: 480,
                    marginRight: 40,
                  }}
                  onClick={() => {
                    window.location.assign(
                      `${settings.urls[`${integration.source}App`]}&state=${
                        this.props.match.params.organization_uuid
                      }`
                    )
                  }}
                  color={integration.settings.color}
                  logo={this.images[integration.source]}
                  keyName={integration.source}
                >
                  <div className='flex justify-start flex-wrap items-center uk-widh-1-1 integration-card-description'>
                    <h4 className='uk-margin-remove'>{integration.title}</h4>
                    <p className='text-sm'>
                      GitHub Past Due Check offers admins the ability to prevent
                      merging pull requests (PR) from developers who are
                      delinquent on their training. A developer who is behind
                      will be notified by email that they are past due when they
                      submit a PR.
                    </p>
                    <div className='text-right uk-margin-small-top uk-width-1-1 flex justify-end'>
                      {integration.reference_id ? (
                        <label className='uk-label uk-label-success'>
                          ACTIVE
                        </label>
                      ) : (
                        <button
                          className='uk-button bg-green text-white uk-button-small flex'
                          type='button'
                        >
                          <Icon
                            className='uk-margin-small-right'
                            name='install'
                            ratio={0.8}
                          />
                          Install
                        </button>
                      )}
                    </div>
                  </div>
                </IntegrationCard>
              )
            })}
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = ({ hacker, integrations }) => ({
  organizations: hacker.organizationsList,
  organizationsHash: hacker.organizationsHash,
  appIntegrations: integrations.appIntegrations,
})

export default connect(mapStateToProps, {
  getAppIntegrations,
})(AppsIntegrations)
