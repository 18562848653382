import React from 'react'
import PropTypes from 'prop-types'

import { HBox, VBox, Box } from 'app/views/core/Box'
import { Icon } from 'app/views/components/GustavoIcon'

import TestStatusIcon from './TestStatusIcon'

const Line = ({ color }) => (
  <Box grow style={{ width: 2, marginLeft: 9, backgroundColor: color }} />
)
const TestStatusLine = ({ status }) => {
  if (status === 'passed') return <Line color='#1f87f0' />
  if (status === 'failed') return <Line color='#eb5756' />
  return <Line color='#c8c8c8' />
}
const Text = ({ value }) => <span style={{ fontSize: 15 }}>{value}</span>
const Title = ({ text, helpUrl }) => (
  <HBox>
    <Box>
      {helpUrl ? (
        <a href={helpUrl} target='_blank'>
          <Text value={text} />
          <Icon
            fill
            ratio={0.8}
            name='external-link'
            style={{ position: 'absolute', marginLeft: 3 }}
          />
        </a>
      ) : (
        <Text value={text} />
      )}
    </Box>
  </HBox>
)
const Description = ({ text }) => (
  <small style={{ lineHeight: 1.5 }}>{text}</small>
)

function TestStageListItem(props) {
  const { status, isLastStage, title, description, helpUrl } = props
  return (
    <HBox fixed={80}>
      <VBox fixed={40}>
        <TestStatusIcon status={status} />
        {!isLastStage && <TestStatusLine status={status} />}
      </VBox>
      <VBox grow>
        <Title text={title} helpUrl={helpUrl} />
        <Description text={description} />
      </VBox>
    </HBox>
  )
}
TestStageListItem.propTypes = {
  status: PropTypes.oneOf(['pending', 'passed', 'failed', 'skipped'])
    .isRequired,
  isLastStage: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  helpUrl: PropTypes.string,
}

export default TestStageListItem
