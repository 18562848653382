import React from 'react'

import Icon from 'app/views/components/Icon'
import LicenseTag from 'app/views/components/LicenseTag'
import Loader from 'app/views/components/Loader'
import PrimaryButton from '/app/views/components/Buttons/PrimaryButton'

export default function (props) {
  return (
    <div>
      <h2>
        Users
        <PrimaryButton
          onClick={props.showAddUsers}
          size='small'
          customCss='float-right'
          label='Add Users'
        />
        <PrimaryButton
          onClick={() => props.onExport()}
          size='small'
          customCss='float-right mr-2'
          label='Team Reports'
        />
      </h2>
      {props.loadingTeamsUsers ? (
        <p className='text-center'>
          <Loader visible text='Loading...' />
        </p>
      ) : props.currentTeam.users.length === 0 ? (
        <p className='text-center'>There are no users on this team.</p>
      ) : (
        <table className='uk-table uk-table-divider uk-table-responsive uk-table-middle'>
          <thead>
            <tr>
              <th>Team Member</th>
              <th>Role</th>
              <th>Current Phase</th>
              <th>License</th>
              <th>Progress</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {(props.currentTeam.users || []).map((user) => {
              const role =
                user.role === 'team_admin'
                  ? 'Team Admin'
                  : user.role === 'admin'
                  ? 'Admin'
                  : 'Member'
              return (
                <tr key={`row-${user.uuid}`}>
                  <td>
                    <a
                      className='text-green hover:text-green'
                      onClick={() => {
                        props.fetchSelectedUserDetail(
                          props.currentOrganizationId,
                          {
                            ...user,
                            justTeam: props.match.params.team_uuid,
                          }
                        )
                      }}
                    >
                      {user.email}
                    </a>
                  </td>
                  <td>{role}</td>
                  <td>
                    {user.active_phases.map((activePhase) => (
                      <div key={activePhase.phase_uuid}>
                        <span className='uk-label uk-label-secondary'>
                          {activePhase.phase_title}
                        </span>
                      </div>
                    ))}
                    {user.plan_start_date ? (
                      <span className='uk-label uk-label-secondary'>
                        Plan started on {user.plan_start_date}
                      </span>
                    ) : null}
                  </td>
                  <td className='text-center'>
                    <LicenseTag
                      user={{
                        ...user,
                        ...{
                          has_license:
                            (user.license_type || []).length > 0 ? true : false,
                        },
                      }}
                    />
                  </td>
                  <td>
                    <progress
                      className='uk-progress'
                      value={user.progress}
                      max='100'
                    />
                  </td>
                  <td>
                    <PrimaryButton
                      onClick={() => {
                        props.showSideBar()
                        props.setSelectedUser(props.currentOrganizationId, user)
                      }}
                      customCss='float-right'
                      size='small'
                      label='Edit'
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}
