import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import UIkit from 'uikit'

// Components
import ModalActiveTeamPlan from 'app/views/components/Modals/Info/ModalActiveTeamPlan'
import ModalPlanTypes from 'app/views/components/Modals/Info/ModalPlanTypes'
import Icon from 'app/views/components/Icon'

// Redux
import { addPlan, getPlans } from 'app/state/modules/plan'
import { getTeamPlanDetail, getTeamById } from 'app/state/modules/team'

class NewPlan extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: '',
      title: '',
      description: '',
      plan: undefined,
      defaultPlan: true,
      sendReminders: false,
      planType: 'date',
    }

    this.handleChangeTitle = this.handleChangeTitle.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
    this.handleChangeDefaultPlan = this.handleChangeDefaultPlan.bind(this)
    this.handleChangeTeam = this.handleChangeTeam.bind(this)
    this.handleChangeSendReminders = this.handleChangeSendReminders.bind(this)
    this.handleSubmitNext = this.handleSubmitNext.bind(this)
    this.handleChangePlanType = this.handleChangePlanType.bind(this)
    this.handleChangePlan = this.handleChangePlan.bind(this)
  }

  componentDidMount() {
    this.props.getPlans(this.props.match.params.organization_uuid)
  }

  handleChangeTitle(e) {
    this.setState({ title: e.target.value })
  }

  handleChangeDescription(e) {
    this.setState({ description: e.target.value })
  }

  handleChangeTeam(e) {
    this.setState({ team: e.target.value })
  }

  handleChangePlan(e) {
    this.setState({ plan: e.target.value === '' ? undefined : e.target.value })
  }

  handleChangeDefaultPlan(e) {
    this.setState({ defaultPlan: e.target.checked })
  }

  handleChangePlanType(e) {
    this.setState({ planType: e.target.name })
  }

  handleChangeSendReminders(e) {
    this.setState({ sendReminders: e.target.checked })
  }

  handleSubmitNext(event) {
    event.preventDefault()

    if (this.state.title === '') {
      this.setState({ error: 'Title is required.' })
    } else {
      this.setState({ title: event.target.value })
      this.props
        .addPlan(
          this.props.match.params.organization_uuid,
          this.state.title,
          this.state.description,
          this.state.defaultPlan,
          this.state.sendReminders,
          this.state.planType,
          this.props.match.params.team_uuid,
          this.state.plan
        )
        .then((planUUID) => {
          if (typeof planUUID === 'string') {
            this.props.hide()
            this.props.history.push(
              `/admin/${this.props.match.params.organization_uuid}/plans/${planUUID}`
            )
          } else {
            this.setState({
              error: planUUID.data.error,
            })
          }
        })
        .catch((error) => {
          console.error(error)
          UIkit.modal.alert(
            'A plan with this title already exists. You can\'t have more than one plan with the same title.',
            {
              stack: true,
            }
          )
        })
    }
  }

  render() {
    return (
      <div>
        <form className='uk-form-stacked'>
          <div className='uk-margin'>
            <label className='uk-form-label'>Title</label>
            <div className='uk-form-controls'>
              <input
                className='uk-input'
                type='text'
                placeholder=''
                value={this.state.title}
                onChange={this.handleChangeTitle}
                data-test-id='input-title'
              />
            </div>
          </div>
          <div className='uk-margin'>
            <label className='uk-form-label'>Description</label>
            <div className='uk-form-controls'>
              <input
                data-test-id='input-description'
                className='uk-input'
                type='text'
                placeholder=''
                value={this.state.description}
                onChange={this.handleChangeDescription}
              />
            </div>
          </div>

          <div className='uk-margin'>
            <label className='uk-form-label'>Team</label>
            <div className='uk-form-controls'>
              <select
                className='uk-select'
                id='form-select-change-team-new-plan'
                value={this.state.team}
                onChange={this.handleChangeTeam}
              >
                <option
                  key={this.props.currentTeam.uuid}
                  value={this.props.currentTeam.uuid}
                >
                  {this.props.currentTeam.name}
                </option>
              </select>
            </div>
          </div>

          <div className='uk-margin'>
            <label className='uk-form-label'>Training Plan</label>
            <div className='uk-form-controls'>
              <select
                className='uk-select'
                id='form-select-change-plan-new-plan'
                value={this.state.plan}
                onChange={this.handleChangePlan}
              >
                <option value=''>Create a new plan</option>
                {/* {this.props.plans.map((plan) => <option key={plan.uuid} value={plan.uuid}>Clone from {plan.title} {plan.team_name ? `( Team "${plan.team_name}")` : ''}</option>)} */}
              </select>
            </div>
          </div>

          {!this.state.plan && (
            <div className='uk-margin '>
              <div className='uk-form-controls uk-inline'>
                <label
                  className='uk-form-label uk-inline'
                  style={{ display: 'inline' }}
                >
                  Plan Type
                </label>
                <Icon
                  href='#'
                  name='information'
                  ratio={0.8}
                  data-uk-toggle='target: #modal-info-plan-types'
                  style={{ marginLeft: 8 }}
                />
                <ModalPlanTypes />
              </div>

              <div className='uk-form-controls'>
                <input
                  checked={this.state.planType === 'date'}
                  className='uk-radio'
                  name='date'
                  onChange={this.handleChangePlanType}
                  style={{ marginRight: 10 }}
                  type='radio'
                />
                Date Based
              </div>
              <div className='uk-form-controls'>
                <input
                  checked={this.state.planType === 'time'}
                  className='uk-radio'
                  data-test-id='time-based-radio-btn'
                  name='time'
                  onChange={this.handleChangePlanType}
                  style={{ marginRight: 10 }}
                  type='radio'
                />
                Time Based
              </div>
            </div>
          )}
          <div className='uk-margin'>
            <div className='uk-form-controls'>
              <input
                data-test-id='default-plan-checkbox'
                className='uk-checkbox'
                type='checkbox'
                checked={this.state.defaultPlan}
                onChange={this.handleChangeDefaultPlan}
                style={{ marginRight: 10 }}
              />
              Make Active
              <Icon
                as='a'
                href='#'
                name='information'
                ratio={0.8}
                data-uk-toggle='target: #modal-info-active-team-plan'
                style={{ marginLeft: 8 }}
              />
              <ModalActiveTeamPlan />
            </div>
          </div>
          {this.state.error !== '' && (
            <div className='uk-margin'>
              <p className='text-danger'>{this.state.error}</p>
            </div>
          )}
        </form>
        <p className='text-right'>
          <button
            className='uk-button uk-button-default uk-margin-remove-bottom uk-modal-close'
            onClick={this.props.hide}
            type='button'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={this.handleSubmitNext}
            className='uk-button uk-button-primary uk-margin-left'
          >
            Create Plan
          </button>
        </p>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentTeam: state.team.currentTeam,
  plans: state.plan.plans,
})

export default withRouter(
  connect(mapStateToProps, {
    getPlans,
    addPlan,
    getTeamPlanDetail,
    getTeamById,
  })(NewPlan)
)
