import React from 'react'

// Components
import Dot from 'app/views/components/Dot'
import Modal from 'app/views/components/UIkit/Modal'

const ModalAboutProficiency = () => (
  <Modal
    id='modal-info-about-proficiency'
    title='About Proficiency'
    body={
      <div>
        <div className='uk-inline'>
          <Dot expert small className='uk-inline uk-margin-small-right' />
          <span className='uk-inline font-bold'>Expert</span>
        </div>
        <p className='uk-margin-small-top'>
          This user has completed all of the lessons in this vulnerability
          category and patched them in less time with fewer incorrect patches
          than average.
        </p>

        <div className='uk-inline'>
          <Dot average small className='uk-inline uk-margin-small-right' />
          <span className='uk-inline font-bold'>Average</span>
        </div>
        <p className='uk-margin-small-top'>
          This user has completed most of the lessons in this vulnerability
          category with an average number of patch attempts.
        </p>

        <div className='uk-inline'>
          <Dot
            needs_attention
            small
            className='uk-inline uk-margin-small-right'
          />
          <span className='uk-inline font-bold'>Needs Attention</span>
        </div>
        <p className='uk-margin-small-top'>
          This user either hasn't completed many lessons in this vulnerability
          category or takes more patch attempts than average to complete
          lessons.
        </p>

        <div className='uk-inline'>
          <Dot unknown small className='uk-inline uk-margin-small-right' />
          <span className='uk-inline font-bold'>Not Enough Information</span>
        </div>
        <p className='uk-margin-small-top'>
          We don't have enough data yet to know how this user is doing in this
          vulnerability category.
        </p>
        <p className='text-right'>
          <button
            className='uk-button uk-button-primary uk-modal-close'
            type='button'
          >
            Ok
          </button>
        </p>
        <button
          className='uk-modal-close-outside'
          data-uk-close
          type='button'
        />
      </div>
    }
  />
)

export default ModalAboutProficiency
