import React from 'react'
import UIkit from 'uikit'
import PrimaryButton from '../../Buttons/PrimaryButton'
import Modal from '../../UIkit/Modal'

class ModalUserTeamPicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teamsLoaded: false,
      initial: {},
      selectedTeams: {},
    }
    this.onClean = this.onClean.bind(this)
    this.onChangeTeamRole = this.onChangeTeamRole.bind(this)
    this.loadSelectedTeams = this.loadSelectedTeams.bind(this)
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentDidMount() {
    this.modal = UIkit.modal('#modal-user-team-picker')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userEmail === '' && this.props.userEmail !== '') {
      this.onClean(() => {})
    }
    if (
      JSON.stringify(prevProps.userTeams) !==
      JSON.stringify(this.props.userTeams)
    ) {
      this.loadSelectedTeams()
    }
  }

  onClean(callback) {
    this.setState(
      {
        selectedTeams: {},
        initial: {},
        teamsLoaded: false,
      },
      () => callback()
    )
  }

  onChangeTeamRole(e) {
    const { selectedTeams } = this.state
    this.setState({
      selectedTeams: {
        ...selectedTeams,
        [e.target.name]: {
          team_role: e.target.value,
        },
      },
    })
  }

  onChange(team) {
    const { selectedTeams } = this.state
    if (this.state.selectedTeams[team.uuid]) {
      delete selectedTeams[team.uuid]
      this.setState({
        selectedTeams,
      })
    } else {
      const newSelected = {
        ...selectedTeams,
        [team.uuid]: {
          team_role: 'member',
        },
      }
      this.setState({
        selectedTeams: newSelected,
      })
    }
  }

  loadSelectedTeams() {
    this.setState({
      selectedTeams: this.props.userTeams,
      initial: this.props.userTeams,
    })
  }

  show() {
    if (!this.state.teamsLoaded && this.props.onFirstShow) {
      this.props.onFirstShow()
      this.setState({ teamsLoaded: true })
    }
    this.modal.show()
  }

  hide() {
    this.modal.hide()
  }

  renderTeams() {
    if ((this.props.teams || []).length === 0) {
      return (
        <>
          <p>Your organization has no teams.</p>
          <p>Add teams by clicking Teams from the menu on the left.</p>
        </>
      )
    }
    return (
      <div>
        {this.props.teams.map((team) => (
          <React.Fragment key={team.uuid}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 10,
              }}
            >
              <div style={{ flex: 2, display: 'flex' }}>
                <label>
                  <input
                    data-test-id='checkbox-team-option'
                    className='mr-4 border-solid'
                    type='checkbox'
                    name={team.uuid}
                    value={team.uuid}
                    onChange={this.onChange.bind(this, team)}
                    checked={
                      typeof this.state.selectedTeams[team.uuid] === 'object'
                    }
                  />
                  {team.name}
                </label>
              </div>
              <div style={{ flex: 1 }}>
                <select
                  className='uk-select'
                  type='text'
                  name={team.uuid}
                  disabled={!this.state.selectedTeams[team.uuid]}
                  value={
                    this.state.selectedTeams[team.uuid]
                      ? this.state.selectedTeams[team.uuid].team_role
                      : 'member'
                  }
                  onChange={this.onChangeTeamRole}
                >
                  <option value='member'>Member</option>
                  <option value='team_admin'>Team Admin</option>
                </select>
              </div>
            </div>
            <hr />
          </React.Fragment>
        ))}
      </div>
    )
  }

  render() {
    const hasTeams = this.props.teams.length > 0

    return (
      <Modal
        data-test-id='modal-edit-user-teams'
        id='modal-user-team-picker'
        body={
          <div>
            <div
              style={{ position: 'relative' }}
              className='uk-padding-small text-center'
            >
              {this.props.isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    backgroundColor: '#fff',
                    flexDirection: 'column',
                  }}
                >
                  <div data-uk-spinner='' />
                  <p>
                    {this.props.isSaving ? 'Saving changes...' : 'Loading...'}
                  </p>
                </div>
              ) : (
                <>
                  <h3>Available Teams</h3>
                  {hasTeams && (
                    <p>
                      Select teams for
                      <span className='font-bold'>
                        {` ${this.props.userEmail} `}
                      </span>
                      and click Save.
                    </p>
                  )}
                  {this.renderTeams()}
                </>
              )}

              <div className='uk-padding'>
                <PrimaryButton
                  label='Cancel'
                  size={'small'}
                  gray={true}
                  onClick={() => {
                    const { initial } = this.state
                    this.setState({
                      selectedTeams: { ...initial },
                    })
                    this.props.onClickCancel()
                  }}
                />

                {hasTeams && (
                  <PrimaryButton
                    size={'small'}
                    customCss={'ml-6'}
                    active={!this.props.isLoading}
                    onClick={hasTeams ? this.props.onClickConfirm : this.hide}
                    label='Save'
                  />
                )}
              </div>
            </div>
          </div>
        }
      />
    )
  }
}

export default ModalUserTeamPicker
