import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Icon } from 'app/views/components/GustavoIcon'
import * as moment from 'moment'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

function ContentStatus({ contentDetails, match, codeSubmissionsCount }) {
  const hasTests = (contentDetails[match.params.id] || {}).has_test
  const contentCompletedAt = (contentDetails[match.params.id] || {})
    .completed_at
  const contentPassedAt = (contentDetails[match.params.id] || {}).passed_at
  const completed = hasTests
    ? contentPassedAt
    : contentCompletedAt || contentPassedAt
  const renderTitle = () => {
    if (hasTests) {
      if (codeSubmissionsCount === 0) {
        return 'No Code Submissions'
      } else if (completed) {
        return <span className='text-primary'>Tests Passed!</span>
      }
      return <span className='text-danger'>Tests Failed</span>
    }
    if (!hasTests) {
      if (completed) {
        return <span className='text-primary'>Completed!</span>
      }
      return <span>Incomplete</span>
    }
  }
  const renderIcon = () => {
    if (completed) {
      return (
        <Icon name='checkbox-circle' className='text-primary' ratio={1.6} />
      )
    }
    if (codeSubmissionsCount > 0 && !completed) {
      return <Icon name='error-warning' className='text-danger' ratio={1.6} />
    }
    return <Icon name='checkbox-blank-circle' ratio={1.6} />
  }

  const renderSubtitle = () => {
    if (completed) {
      return `Completed on: ${moment(getLocalDateFromUTC(completed)).format(
        'MMMM D, YYYY'
      )}`
    } else {
      if (codeSubmissionsCount > 0) {
        return 'Submit a passing patch to mark lesson complete.'
      } else if (hasTests) {
        return 'Submit a code patch for testing.'
      }
      return 'This lesson has no coding tests.'
    }
  }
  return (
    <div
      className='flex flex-row uk-width-1-1 flex-1-0'
      style={{ color: '#707986' }}
    >
      <div className='flex items-center justify-center uk-padding-small '>
        {renderIcon()}
      </div>
      <div className='flex flex-col justify-start justify-center'>
        <p className='uk-margin-remove uk-padding-remove'>
          Status: {renderTitle()}
        </p>
        <small className='italic'>{renderSubtitle()}</small>
      </div>
    </div>
  )
}

const mapStateToProps = ({ content, hacker }) => ({
  contentDetails: content.contentDetails,
  codeSubmissionsCount: (hacker.currentCodeSubmissions || []).length,
})

export default withRouter(connect(mapStateToProps, {})(ContentStatus))
