import React from 'react'
// Components
import Modal from 'app/views/components/UIkit/Modal'

const PlanTypes = () => (
  <Modal
    id='modal-info-plan-types'
    title='Plan Types'
    body={
      <div>
        <h4>Date Based Plans</h4>
        <p>
          The phases of a "date based" plan have a fixed start and end date
          associated with them.
        </p>
        <p>
          Date based plans are great for organizations who are trying to meet a
          compliance requirement by a specified date, or who just want to make a
          certain set of lessons available for their developers for the entire
          year.
        </p>
        <h4>Time Based Plans</h4>
        <p>
          Time based plans have a duration attached to each phase, so when a new
          user joins your organization, they start from the beggining of the
          plan and work through it in the number of days you specify.
        </p>
        <p>
          These plans are recommended for most organizations, where a compliance
          date doesn't need to be met for the organization.
        </p>
        <p className='text-right'>
          <button
            className='uk-button uk-button-primary uk-modal-close'
            type='button'
          >
            Ok
          </button>
        </p>
        <button
          className='uk-modal-close-outside'
          type='button'
          data-uk-close
        />
      </div>
    }
  />
)

export default PlanTypes
