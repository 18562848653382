import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import SandboxOutputHeader from './SandboxOutputHeader'
import SandboxOutputFeed from './SandboxOutputFeed'

import { connect } from 'react-redux'
import * as tty from 'app/state/modules/tty'

import './style.less'

const Container = ({ isExpanded, children }) => (
  <div
    style={{
      flexBasis: isExpanded ? 300 : 40,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {children}
  </div>
)

function SandboxOutput(props) {
  const {
    hasCode,
    hasMultiFileCode,
    lines,
    numLinesUnacknowledged,
    onClearRequested,
    acknowledgeLines,
    onLayoutChange,
  } = props
  const [isExpanded, setIsExpanded] = useState(false)

  // Automatically acknowledge new lines after a brief delay if we're expanded
  const timerHandle = useRef(null)
  useEffect(() => {
    if (isExpanded) {
      if (timerHandle.current) {
        clearTimeout(timerHandle.current)
      }
      timerHandle.current = setTimeout(acknowledgeLines, 1000)
    }
  }, [lines.length])

  // Clear timers on unmount
  useEffect(() => {
    return () => {
      if (timerHandle.current) {
        clearTimeout(timerHandle.current)
      }
    }
  })

  return (
    <Container isExpanded={isExpanded}>
      <SandboxOutputHeader
        numLines={lines.length}
        numLinesUnacknowledged={numLinesUnacknowledged}
        isExpanded={isExpanded}
        onToggleExpanded={() => {
          if (!isExpanded) {
            acknowledgeLines()
          }
          setIsExpanded((prev) => !prev)
          onLayoutChange()
        }}
        onClickClear={onClearRequested}
      />
      {isExpanded && (
        <SandboxOutputFeed
          hasCode={hasCode}
          hasMultiFileCode={hasMultiFileCode}
          lines={lines}
        />
      )}
    </Container>
  )
}
SandboxOutput.propTypes = {
  hasCode: PropTypes.bool.isRequired,
  hasMultiFileCode: PropTypes.bool.isRequired,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  numLinesUnacknowledged: PropTypes.number.isRequired,
  onClearRequested: PropTypes.func.isRequired,
  acknowledgeLines: PropTypes.func.isRequired,
  onLayoutChange: PropTypes.func.isRequired, // callback fired when the output panel is collapsed or expanded
}

export default connect(
  (state) => ({
    lines: state.tty.lines,
    numLinesUnacknowledged:
      state.tty.lines.length - state.tty.numLinesAcknowledged,
  }),
  (dispatch) => ({
    onClearRequested: () => dispatch(tty.clear()),
    acknowledgeLines: () => dispatch(tty.acknowledgeLines()),
  })
)(SandboxOutput)
