/* eslint-disable no-nested-ternary */
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import UIkit from 'uikit'
import settings from 'settings'
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

// Components
import { ButtonTabs } from 'app/views/components/Tabs'
import FormatedMinutes from 'app/views/components/FormatedMinutes'
import Icon from 'app/views/components/Icon'
import PlanChangeModal from 'app/views/containers/Organizations/Plans/components/PlanChangeModal'
import GenericModal from 'app/views/components/Modals/GenericModal'
import Loader from 'app/views/components/Loader'
import ModalCreatedAutomaticallyViaSSO from 'app/views/components/Modals/Info/ModalCreatedAutomaticallyViaSSO'
import ModalCodingTestRequired from 'app/views/components/Modals/Info/ModalCodingTestRequired'
import ModalUserTeamPicker from 'app/views/components/Modals/ModalUserTeamPicker'
import RightSideBar from 'app/views/components/RightSideBar'
import Statistics from 'app/views/components/Statistics'
import PhaseDetailModal from 'app/views/components/PhaseDetailModal'
import UserDetailModal from 'app/views/components/UserDetailModal'
import CustomContentModal from 'app/views/components/CustomContentModal'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'

// Redux
import {
  getPlans,
  deletePlanGroup,
  changeUserPhase,
  updatePlanById,
  fetchPhaseDetailModalData,
  activateAutomatedPlan,
  updateRecommendationEngineSettings,
  updateRequiredContentRecommendationSettings,
  updateRequiredTrainingContent,
} from 'app/state/modules/plan'
import {
  saveUserTeams,
  fetchSelectedUserDetail,
  cleanUserDetailModal,
} from 'app/state/modules/users'
import { getContent } from 'app/state/modules/content'
import {
  getHackers,
  setFilterHacker,
  deleteHackerFromOrganization,
  updateHackerRole,
} from 'app/state/modules/hacker'
import {
  getTeamById,
  getTeamUsers,
  removeHackerFromTeam,
  getTeamPlanDetail,
  setSelectedUser,
  updateTeamPrivileges,
  updateTeamPlan,
  importTeamUsers,
  getTeams,
} from 'app/state/modules/team'

// Utils
import analytics from 'app/views/utils/analytics'

// Local Imports
import AddUsers from './AddUsers'
import EditTeamUser from './EditTeamUser'
import UsersList from './UsersList'
import OrganizationLayout from '../components/Layout'
import EditSettings from './EditSettings'
import NewPlan from './NewPlan'
import RecommendationPlan from './RecommendationPlan'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired, // withRouter
  }).isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

class OrganizationsTeamsDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editPhase: false,
    }
    this.handleClickRemove = this.handleClickRemove.bind(this)
    this.defaultTab = this.getDefaultTab(this.props.match.path)
    this.handleShowEditBar = this.handleShowEditBar.bind(this)
    this.onShowEditPlanModal = this.onShowEditPlanModal.bind(this)
    this.onDeleteUser = this.onDeleteUser.bind(this)
    this.renderDeleteModalContent = this.renderDeleteModalContent.bind(this)
    this.onConfirmDeletePhase = this.onConfirmDeletePhase.bind(this)
    this.onChangeUserPhase = this.onChangeUserPhase.bind(this)
    this.onClickConfirmTeamSave = this.onClickConfirmTeamSave.bind(this)
    this.handleClickMakeAdmin = this.handleClickMakeAdmin.bind(this)
    this.handleShowPhaseModal = this.handleShowPhaseModal.bind(this)
    this.onClickReport = this.onClickReport.bind(this)
  }

  componentDidMount() {
    this.props.getContent(this.props.match.params.organization_uuid, true)
    this.props.getTeamById(
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid
    )
    this.props.getTeamUsers(
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid
    )
    this.props.getTeamPlanDetail(
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid
    )
    this.defaultTab = this.getDefaultTab(this.props.match.path)
    if (this.props.match.params.hacker_uuid) {
      this.timeoutUserdetailModal = setTimeout(() => {
        this.props.fetchSelectedUserDetail(
          this.props.match.params.organization_uuid,
          {
            uuid: this.props.match.params.hacker_uuid,
            justTeam: this.props.match.params.team_uuid,
          }
        )
      }, 1000)
    }
    analytics.page('page-organizations-teams-detail')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.organization_uuid !==
        this.props.match.params.organization_uuid ||
      nextProps.match.params.team_uuid !== this.props.match.params.team_uuid
    ) {
      this.props.getTeamById(
        this.props.match.params.organization_uuid,
        this.props.match.params.team_uuid
      )
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.hacker_uuid &&
      !this.props.match.params.hacker_uuid &&
      this.userDetailModal
    ) {
      this.userDetailModal.modal.hide()
      this.props.cleanUserDetailModal()
    }
  }

  componentWillUnmount() {
    if (this.timeoutUserdetailModal) {
      clearTimeout(this.timeoutUserdetailModal)
    }
  }

  onShowEditPlanModal() {
    this.props.getPlans(this.props.match.params.organization_uuid)
  }

  onConfirmDeletePhase() {
    this.props
      .deletePlanGroup(
        this.props.match.params.organization_uuid,
        this.props.currentTeam.plan.uuid,
        this.state.editPhase.uuid
      )
      .then(() => {
        this.props.getTeamPlanDetail(
          this.props.match.params.organization_uuid,
          this.props.match.params.team_uuid
        )
        this.deletePhaseModal.hide()
      })
  }

  onChangeUserPhase(item, refObj) {
    this.props.changeUserPhase(
      this.props.match.params.organization_uuid,
      refObj.uuid,
      this.props.currentTeam.plan.uuid,
      item.id,
      this.props.match.params.team_uuid,
      () => {
        this.props.getTeamUsers(
          this.props.match.params.organization_uuid,
          this.props.match.params.team_uuid,
          (users, userId) => {
            users.some((user) => {
              if (user.uuid === userId) {
                this.props.setSelectedUser(
                  this.props.match.params.organization_uuid,
                  user
                )
                return true
              }
              return false
            })
          }
        )
        this.props.getTeamById(
          this.props.match.params.organization_uuid,
          this.props.match.params.team_uuid,
          true
        )
      }
    )
  }

  onClickReport(e) {
    if (e.target.name) {
      if (this.props.match.params.team_uuid) {
        this.props.history.push(
          `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/reports/${e.target.name}`
        )
      } else {
        this.props.history.push(
          `/admin/${this.props.match.params.organization_uuid}/reports/${e.target.name}`
        )
      }
    }
  }

  onDeleteUser() {
    const orgUUID = this.props.match.params.organization_uuid
    const userUUID =
      this.props.selectedUser.uuid || this.props.match.params.hacker_uuid
    const callback = (error = false) => {
      this.modalDeleteUser.hide()
      this.editUserSideBar.triggerHide()
      if (error) {
        return UIkit.modal.alert(
          '<h2 >Error</h2><p>There was an error deleting the user. Please try again or contact support.</p>'
        )
      }
      this.props.getTeamUsers(
        this.props.match.params.organization_uuid,
        this.props.match.params.team_uuid
      )
      this.props.getTeamById(
        this.props.match.params.organization_uuid,
        this.props.match.params.team_uuid,
        true
      )
      return UIkit.modal.alert(
        '<h2 >User removed</h2><p>The user has been removed successfully from the organization<p>'
      )
    }
    this.props.deleteHackerFromOrganization(orgUUID, userUUID, callback)
  }

  onClickConfirmTeamSave() {
    const successCallback = () => {
      this.modalUserTeamPicker.hide()
      this.editUserSideBar.triggerHide()
      this.props.getTeamUsers(
        this.props.match.params.organization_uuid,
        this.props.match.params.team_uuid
      )
    }
    const errorCallback = () => {}
    this.props.saveUserTeams(
      this.props.match.params.organization_uuid,
      this.modalUserTeamPicker.state.selectedTeams,
      this.props.selectedUser.uuid,
      successCallback,
      errorCallback
    )
  }

  getDefaultTab(path) {
    if (path.endsWith('plans')) {
      return 1
    }
    if (path.endsWith('settings')) {
      return 2
    }
    return 0
  }

  handleClickRemove(hacker) {
    this.props
      .removeHackerFromTeam(
        this.props.match.params.organization_uuid,
        this.props.match.params.team_uuid,
        hacker.uuid
      )
      .then(() => {
        this.props.getTeamById(
          this.props.match.params.organization_uuid,
          this.props.match.params.team_uuid
        )
        this.props.getTeamUsers(
          this.props.match.params.organization_uuid,
          this.props.match.params.team_uuid
        )
      })
  }

  handleClickMakeAdmin(hackerId, role) {
    this.props
      .updateHackerRole(
        this.props.match.params.organization_uuid,
        hackerId,
        role
      )
      .then(() => {
        // this.editUserSideBar.hide()
        this.props.getTeamUsers(
          this.props.match.params.organization_uuid,
          this.props.match.params.team_uuid,
          (users, userId) => {
            users.some((user) => {
              if (user.uuid === userId) {
                this.props.setSelectedUser(
                  this.props.match.params.organization_uuid,
                  user
                )
                return true
              }
              return false
            })
          }
        )
      })
  }

  handleShowEditBar() {
    this.editUserSideBar.triggerShow()
  }

  handleShowPhaseModal(phase) {
    this.props.fetchPhaseDetailModalData(
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid,
      this.props.currentTeam.plan.uuid,
      phase.phase_uuid
    )
  }

  renderDeleteModalContent() {
    if (!this.props.selectedUser) return null
    if (this.props.selectedUser.steps_completed > 20) {
      return (
        <>
          <p>
            {`You are about to remove ${this.props.selectedUser.email} from your organization.
            This user's license does not qualify to be released due to the amount of content they
            have already completed.`}
          </p>
          <p>Are you sure you want to continue?</p>
        </>
      )
    }
    return (
      <>
        <p>
          {`You are about to remove ${this.props.selectedUser.email} from your organization. This
          user's license will be available for reuse since they completed a minimal amount of
          the training.`}
        </p>
        <p>Are you sure you want to continue?</p>
      </>
    )
  }

  renderContentTitle(content) {
    if (content.type === 'coding_challenge') {
      return (
        <p>{`${settings.apps[content.app]}: ${content.content_title} (${
          settings.engines[content.language.name]
        } - ${settings.frameworks[content.framework.name]})`}</p>
      )
    }

    return content.content_title
  }

  render() {
    if (this.props.loadingTeamById || !this.props.currentTeam.uuid) {
      return <Loader visible />
    }
    const currentOrganizationId = this.props.match.params.organization_uuid

    let organizationMustPassCodingExercise = null
    if (
      Object.prototype.hasOwnProperty.call(
        this.props.organizationsHash,
        currentOrganizationId
      )
    ) {
      organizationMustPassCodingExercise =
        this.props.organizationsHash[currentOrganizationId]
          .must_pass_coding_exercise
    }
    return (
      <OrganizationLayout
        active='teams'
        data-test-id='organizations-teams-component'
        currentOrganizationId={currentOrganizationId}
        noPaddingRemove={
          this.props.organizationsHash[
            this.props.match.params.organization_uuid
          ].team_admin_uuids.length > 1 &&
          this.props.organizationsHash[
            this.props.match.params.organization_uuid
          ].role !== 'admin'
        }
        style={{ position: 'relative' }}
        {...this.props}
      >
        {this.props.organizationsHash[this.props.match.params.organization_uuid]
          .role !== 'admin' &&
          this.props.organizationsHash[
            this.props.match.params.organization_uuid
          ].team_admin_uuids.length > 1 && (
            <button
              onClick={() =>
                this.props.history.push(
                  `/admin/${this.props.match.params.organization_uuid}`
                )
              }
              className='uk-button uk-button-secondary uk-button-small uk-position-absolute uk-position-top-left uk-margin-top uk-margin-small-left flex'
              type='button'
            >
              <Icon name='arrow-left' className='uk-margin-small-right' />
              Back To Teams
            </button>
          )}
        <h1 className='uk-heading-bullet uk-heading-divider'>
          {this.props.currentTeam.name}
          {this.props.organizationsHash[currentOrganizationId].role ===
            'admin' && (
            <PrimaryButton
              size={'small'}
              customCss='float-right bg-green'
              onClick={() =>
                this.props.history.push(
                  `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/edit`
                )
              }
              label='Edit'
            />
          )}
        </h1>
        <h2>Assigned Lessons Remaining</h2>
        <ResponsiveContainer
          id='teams-chart'
          height={350}
          className='text-sm uk-width-1-1'
        >
          <AreaChart
            data={
              this.props.currentTeam.reportLessons.length === 1
                ? [
                    ...this.props.currentTeam.reportLessons,
                    {
                      lessons: this.props.currentTeam.reportLessons[0].lessons,
                      date: moment().format('YYYY-MM-DD'),
                    },
                  ]
                : this.props.currentTeam.reportLessons
            }
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis
              dataKey='date'
              tickFormatter={(date) => {
                if (date === '') {
                  return ''
                }
                return `  ${moment(date).format('MMM D')}  `
              }}
            />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Area
              type='monotone'
              dataKey='lessons'
              name='Assigned Lessons Remaining'
              fill='#1e87f0'
            />
          </AreaChart>
        </ResponsiveContainer>
        <GenericModal
          ref={(ref) => {
            this.addPlanModal = ref
          }}
          id='new-plan-modal'
          width='uk-width-3-5'
          title='Add New Plan'
          body={
            <NewPlan
              match={this.props.match}
              hide={(this.addPlanModal || {}).hide}
              newPhaseModal={this.addPlanModal}
            />
          }
          noButtons
        />
        <GenericModal
          id='edit-plan-modal'
          ref={(ref) => {
            this.editPlanModal = ref
          }}
          width='uk-width-3-5'
          onShow={this.onShowEditPlanModal}
          title='Choose a plan'
          buttons={
            <button
              type='button'
              className='uk-button uk-button-primary uk-align-left'
              onClick={() => {
                this.editPlanModal.hide()
                this.addPlanModal.show()
              }}
            >
              Create a new plan
            </button>
          }
          body={
            <>
              <hr />
              {this.props.currentTeam.team_plans
                .filter((plan) => plan.team_uuid !== null)
                .map((plan) => (
                  <React.Fragment key={plan.uuid}>
                    <div className='_plan-picker-item'>
                      <label>
                        <input
                          className='uk-checkbox'
                          type='checkbox'
                          onChange={() => {
                            this.editPlanModal.hide()
                            this.props.updateTeamPlan(
                              this.props.match.params.organization_uuid,
                              this.props.match.params.team_uuid,
                              plan.uuid,
                              () =>
                                this.props.getTeamUsers(
                                  this.props.match.params.organization_uuid,
                                  this.props.match.params.team_uuid
                                )
                            )
                          }}
                          checked={
                            this.props.currentTeam.plan.uuid === plan.uuid
                          }
                          style={{
                            marginRight: 15,
                          }}
                          value={plan.uuid}
                        />
                        {plan.title}
                        {plan.team_uuid && (
                          <button
                            onClick={() => {
                              this.editPlanModal.hide()
                              this.props.history.push(
                                `/admin/${this.props.match.params.organization_uuid}/plans/${plan.uuid}`
                              )
                            }}
                            type='button'
                            style={{
                              position: 'absolute',
                              right: 15,
                            }}
                            className='uk-button uk-button-small bg-green text-white'
                          >
                            Edit plan
                          </button>
                        )}
                      </label>
                    </div>
                    <hr />
                  </React.Fragment>
                ))}
              <div className='_plan-picker-item'>
                <label>
                  <input
                    className='uk-checkbox'
                    type='checkbox'
                    onChange={() => {
                      this.editPlanModal.hide()
                      this.props.updateTeamPlan(
                        this.props.match.params.organization_uuid,
                        this.props.match.params.team_uuid,
                        '',
                        () =>
                          this.props.getTeamUsers(
                            this.props.match.params.organization_uuid,
                            this.props.match.params.team_uuid
                          )
                      )
                    }}
                    checked={this.props.currentTeam.is_organization_plan}
                    style={{
                      marginRight: 15,
                    }}
                  />
                  Use default organization plan
                </label>
              </div>
              <hr />
            </>
          }
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 40,
          }}
        >
          <Statistics
            header={
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 10,
                }}
              >
                {`${(
                  (this.props.currentTeam.completed_assigned_lessons /
                    this.props.currentTeam.chart.total_lessons) *
                    100 || 0
                ).toFixed(0)}`}
                <span style={{ fontSize: 22 }}>%</span>
              </span>
            }
            content={`${this.props.currentTeam.completed_assigned_lessons}/${this.props.currentTeam.chart.total_lessons}`}
            detail='Assigned Lessons Complete'
          />
          <Statistics
            header={
              <FormatedMinutes
                minutes={this.props.currentTeam.time_spent_assigned_lessons}
              />
            }
            content={
              <FormatedMinutes
                isText
                minutes={
                  this.props.currentTeam.time_spent_assigned_lessons /
                  this.props.currentTeam.total_users
                }
                extraText='per user'
              />
            }
            detail='Time Spent on Assigned Lessons'
          />
          <Statistics
            header={
              <FormatedMinutes minutes={this.props.currentTeam.time_spent} />
            }
            content={
              <FormatedMinutes
                isText
                minutes={
                  this.props.currentTeam.time_spent /
                  this.props.currentTeam.total_users
                }
                extraText='per user'
              />
            }
            detail='Total Time Spent'
          />
          <Statistics
            header={this.props.currentTeam.total_code_submissions}
            content={`${(
              this.props.currentTeam.total_code_submissions /
                this.props.currentTeam.total_users || 0
            ).toFixed(0)} per user`}
            detail='Code Submissions'
          />
        </div>
        <GenericModal
          noOverflow
          width='uk-width-3-5'
          ref={(ref) => {
            this.teamMemberAddModal = ref
          }}
          noButtons
          body={
            <AddUsers
              hasInvitePrivileges={this.props.currentTeam.has_invite_privileges}
              ref={(ref) => {
                this.addUsersComponent = ref
              }}
              match={this.props.match}
              getTeamUsers={this.props.getTeamUsers}
              getTeamById={this.props.getTeamById}
              importTeamUsers={this.props.importTeamUsers}
              hide={(this.teamMemberAddModal || {}).hide}
            />
          }
          id='add-user-teams'
        />
        <ButtonTabs
          defaultTab={this.defaultTab}
          customClickTab={(index) => {
            switch (index) {
              case 1:
                this.props.getTeamPlanDetail(
                  this.props.match.params.organization_uuid,
                  this.props.match.params.team_uuid
                )
                this.defaultTab = 1
                return this.props.history.push(
                  `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/plans`
                )
              case 2:
                this.defaultTab = 2
                return this.props.history.push(
                  `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/settings`
                )
              default:
                this.defaultTab = 0
                return this.props.history.push(
                  `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/users`
                )
            }
          }}
          tabs={[
            {
              name: 'Users',
              icon: 'team',
              component: (
                <UsersList
                  onExport={() => this.exportSideBar.triggerShow()}
                  fetchSelectedUserDetail={this.props.fetchSelectedUserDetail}
                  isTimeBased={this.props.currentTeam.is_time_based}
                  phases={this.props.teamPlanDetail.map((p) => ({
                    uuid: p.uuid,
                    title: p.phase_title,
                  }))}
                  onChangeUserPhase={this.onChangeUserPhase}
                  showAddUsers={() => {
                    this.addUsersComponent.clearForm()
                    this.teamMemberAddModal.show()
                  }}
                  loadingTeamsUsers={this.props.loadingTeamsUsers}
                  showSideBar={this.handleShowEditBar}
                  currentOrganizationId={currentOrganizationId}
                  currentTeam={this.props.currentTeam}
                  hackerUUID={this.props.hackerUUID}
                  match={this.props.match}
                  setSelectedUser={this.props.setSelectedUser}
                />
              ),
            },
            {
              name: 'Training Plan',
              icon: 'list-check-2',
              iconNone: true,
              component:
                this.props.currentTeam.plan.no_training ||
                this.props.currentTeam.plan.using_recommendation_engine ||
                this.props.currentTeam
                  .using_organization_default_adaptive_training ? (
                  (this.props.currentTeam.plan.using_recommendation_engine ||
                    this.props.currentTeam
                      .using_organization_default_adaptive_training) &&
                  !this.props.currentTeam.plan.no_training ? (
                    <RecommendationPlan
                      isOrganizationDefaultAdaptivePlan={
                        this.props.currentTeam
                          .using_organization_default_adaptive_training
                      }
                      updateRequiredTrainingContent={
                        this.props.updateRequiredTrainingContent
                      }
                      loadingAutomatedPlan={this.props.loadingAutomatedPlan}
                      activateAutomatedPlan={this.props.activateAutomatedPlan}
                      updateRequiredContentRecommendationSettings={
                        this.props.updateRequiredContentRecommendationSettings
                      }
                      content={this.props.content}
                      onRefresh={() => {
                        this.props.getTeamById(
                          this.props.match.params.organization_uuid,
                          this.props.match.params.team_uuid,
                          true
                        )
                        this.props.getTeamPlanDetail(
                          this.props.match.params.organization_uuid,
                          this.props.match.params.team_uuid
                        )
                      }}
                      currentTeam={this.props.currentTeam}
                      updateRecommendationEngineSettings={
                        this.props.updateRecommendationEngineSettings
                      }
                      edit={() => this.changePlanModal.show()}
                      trainingSelected={this.props.currentTeam.settings.required_training.reduce(
                        (p, c) => {
                          p[c.uuid] = true
                          return p
                        },
                        {}
                      )}
                      settings={this.props.currentTeam.settings}
                    />
                  ) : (
                    <div className='uk-padding text-center flex justify-center flex-col items-center'>
                      <p>This team has no assigned training</p>
                      <PrimaryButton
                        onClick={() => this.changePlanModal.show()}
                        label='Change Plan'
                        size='medium'
                      />
                    </div>
                  )
                ) : (
                  <div>
                    <h2 className='flex justify-between'>
                      {this.props.currentTeam.plan.title}
                      <div>
                        <PrimaryButton
                          onClick={() => {
                            this.changePlanModal.show()
                          }}
                          size={'small'}
                          label='Change Plan'
                        />
                        {this.props.currentTeam.plan.is_team_plan && (
                          <PrimaryButton
                            label='Edit Plan'
                            customCss={'ml-2'}
                            size={'small'}
                            onClick={() => {
                              this.props.history.push(
                                `/admin/${this.props.match.params.organization_uuid}/plans/${this.props.currentTeam.plan.uuid}`
                              )
                            }}
                          />
                        )}
                      </div>
                    </h2>
                    <p>{this.props.currentTeam.plan.description}</p>
                    {this.props.teamPlanDetail.map((phase) => (
                      <React.Fragment key={phase.phase_uuid}>
                        <div
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={this.handleShowPhaseModal.bind(this, phase)}
                        >
                          <div className='uk-card uk-card-default uk-card-body uk-width-3-4@l'>
                            <h3 className='uk-card-title'>
                              <span
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                {phase.phase_title}
                              </span>
                            </h3>
                            <hr />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Statistics
                                header={phase.hackers_in_phase}
                                detail={
                                  <span>
                                    Users
                                    <br />
                                    in this Phase
                                  </span>
                                }
                              />
                              <Statistics
                                header={`${(
                                  phase.content.reduce(
                                    (prev, curr) =>
                                      prev + curr.progress_users_in_phase,
                                    0
                                  ) / phase.content.length
                                ).toFixed(0)}%`}
                                detail={
                                  <span>
                                    Content Completed
                                    <br />
                                    by Users in Phase
                                  </span>
                                }
                              />
                              <Statistics
                                header={`${(
                                  phase.content.reduce(
                                    (prev, curr) => prev + curr.progress,
                                    0
                                  ) / phase.content.length
                                ).toFixed(0)}%`}
                                detail={
                                  <span>
                                    Content Completed
                                    <br />
                                    by Entire Team
                                  </span>
                                }
                              />
                              <Statistics
                                header={
                                  <span>
                                    {(phase.total_time_in_phase / 60).toFixed(
                                      0
                                    )}
                                    <span style={{ fontSize: 28 }}>
                                      &nbsp;hrs
                                    </span>{' '}
                                    {(phase.total_time_in_phase % 60).toFixed(
                                      0
                                    )}
                                    <span style={{ fontSize: 28 }}>
                                      &nbsp;min
                                    </span>
                                  </span>
                                }
                                detail={
                                  <span>
                                    Time Spent
                                    <br />
                                    in this Phase
                                  </span>
                                }
                              />
                            </div>
                            <br />
                            {phase.content.map((content) => (
                              <React.Fragment key={content.content_uuid}>
                                {this.renderContentTitle(content)}
                                <progress
                                  className='uk-progress'
                                  value={content.progress}
                                  max='100'
                                />
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                ),
            },
            {
              name: 'Settings',
              icon: 'settings-3',
              component: (
                <div>
                  <h2>
                    Settings
                    <PrimaryButton
                      onClick={() => {
                        this.settingsSideBar.triggerShow()
                      }}
                      size='small'
                      customCss='float-right'
                      label='Edit'
                    />
                  </h2>
                  <div className='uk-child-width-1-1@m' data-uk-grid>
                    <form className='uk-form-horizontal uk-margin-large'>
                      <div className='uk-margin'>
                        <div className='uk-inline'>
                          <label className='text-right'>
                            Coding Test Required
                          </label>
                          <Icon
                            name='information'
                            ratio={0.8}
                            data-uk-toggle='target: #modal-info-coding-test-required'
                            style={{
                              cursor: 'pointer',
                              paddingLeft: 8,
                              paddingTop: 6,
                              verticalAlign: 'top',
                            }}
                          />
                          {this.props.currentTeam.must_pass_coding_exercise ===
                          null ? (
                            <>
                              <span
                                className={`uk-margin-left uk-label ${
                                  organizationMustPassCodingExercise
                                    ? 'uk-label-success'
                                    : 'uk-label-danger'
                                }`}
                                style={{ marginRight: 10 }}
                              >
                                {organizationMustPassCodingExercise
                                  ? 'On'
                                  : 'Off'}
                              </span>
                              <span className='text-sm text-muted'>
                                (Using organization default)
                              </span>
                            </>
                          ) : (
                            <span
                              className={`uk-margin-left uk-label ${
                                this.props.currentTeam.must_pass_coding_exercise
                                  ? 'uk-label-success'
                                  : 'uk-label-danger'
                              }`}
                            >
                              {this.props.currentTeam.must_pass_coding_exercise
                                ? 'On'
                                : 'Off'}
                            </span>
                          )}
                          <ModalCodingTestRequired />
                        </div>
                      </div>

                      <div className='uk-margin'>
                        <div className='uk-inline'>
                          <label className='text-right'>
                            Created Automatically via SSO
                          </label>
                          <Icon
                            name='information'
                            ratio={0.8}
                            data-uk-toggle='target: #modal-info-created-automatically-via-sso'
                            style={{
                              cursor: 'pointer',
                              paddingLeft: 8,
                              paddingTop: 6,
                              verticalAlign: 'top',
                            }}
                          />
                          <span
                            className={`uk-margin-left uk-label ${
                              this.props.currentTeam.created_by_sso
                                ? 'uk-label-success'
                                : 'uk-label-danger'
                            }`}
                          >
                            {this.props.currentTeam.created_by_sso
                              ? 'True'
                              : 'False'}
                          </span>
                          <ModalCreatedAutomaticallyViaSSO />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ),
            },
          ]}
        />
        <GenericModal
          title='Delete user from organization'
          ref={(ref) => {
            this.modalDeleteUser = ref
          }}
          id='delete-user-modal'
          date-test-id='delete-user-modal'
          body={this.renderDeleteModalContent()}
          buttons={
            <button
              data-test-id='delete-user-confirm'
              type='button'
              className='uk-button uk-button-danger uk-margin-left'
              onClick={this.onDeleteUser}
            >
              Delete
            </button>
          }
        />
        <ModalUserTeamPicker
          onClickConfirm={this.onClickConfirmTeamSave}
          userEmail={this.props.selectedUser.email}
          userId={this.props.selectedUser.uuid}
          userTeams={(this.props.selectedUser.teams === 'loading'
            ? []
            : this.props.selectedUser.teams
          ).reduce((prev, curr) => {
            prev[curr.team_uuid] = {
              team_role: curr.team_role,
              team_name: curr.name,
            }
            return prev
          }, {})}
          onClickCancel={() => this.modalUserTeamPicker.hide()}
          selectedUser={this.props.selectedUser}
          onFirstShow={() =>
            this.props.getTeams(this.props.match.params.organization_uuid)
          }
          isLoading={this.props.loadingTeams || this.props.isLoadingSaveTeams}
          isSaving={this.props.isLoadingSaveTeams === true}
          teams={this.props.teams}
          onRef={(ref) => {
            this.modalUserTeamPicker = ref
          }}
        />

        <UserDetailModal
          id='user-detail-teams'
          onEditTeams={() => this.modalUserTeamPicker.show()}
          onRef={(ref) => {
            this.userDetailModal = ref
          }}
          onRemoveFromOrg={() => this.modalDeleteUser.show()}
          onShow={() => {
            if (!this.props.match.params.hacker_uuid) {
              this.props.history.push(
                `${this.props.match.url}/${
                  this.props.selectedUserDetail.uuid
                }/${
                  this.props.match.params.tab
                    ? this.props.match.params.tab
                    : 'proficiency'
                }`
              )
            }
          }}
          onRefresh={() => {
            this.props.getTeamUsers(
              this.props.match.params.organization_uuid,
              this.props.match.params.team_uuid
            )
          }}
          onHide={() => {
            this.props.history.push(
              `${this.props.match.url.substr(
                0,
                this.props.match.url.lastIndexOf('/users')
              )}/users`
            )
            this.props.cleanUserDetailModal()
          }}
        />
        <PhaseDetailModal />
        <RightSideBar
          ref={(ref) => {
            this.settingsSideBar = ref
          }}
          content={<EditSettings match={this.props.match} />}
        />
        <RightSideBar
          ref={(ref) => {
            this.exportSideBar = ref
          }}
          content={
            <>
              <h2>Team Reports</h2>
              <hr />
              <button
                type='button'
                className='uk-button uk-button-default uk-width-1-1 uk-position-relative flex justify-center items-center'
                onClick={this.onClickReport}
                name='download'
              >
                <Icon
                  style={{ position: 'absolute', left: 20 }}
                  name='download-cloud'
                />
                CSV Download
              </button>
              <br />
              <button
                type='button'
                className='uk-button uk-button-default uk-width-1-1 uk-position-relative flex justify-center items-center'
                onClick={this.onClickReport}
                name='compliance'
              >
                <Icon
                  style={{ position: 'absolute', left: 20 }}
                  name='download-cloud'
                />
                Certificate (PDF)
              </button>
              <br />
              <button
                type='button'
                className='uk-button uk-button-default uk-width-1-1 uk-position-relative flex justify-center items-center'
                onClick={this.onClickReport}
                name='completed'
              >
                <Icon
                  style={{ position: 'absolute', left: 20 }}
                  name='link'
                  none
                />
                Completion Report
              </button>
              <br />
              <button
                type='button'
                className='uk-button uk-button-default uk-width-1-1 uk-position-relative flex justify-center items-center'
                onClick={this.onClickReport}
                name='proficiency'
              >
                <Icon
                  style={{ position: 'absolute', left: 20 }}
                  name='link'
                  none
                />
                Proficiency Report
              </button>
              <br />
              <button
                type='button'
                className='uk-button uk-button-default uk-width-1-1 uk-position-relative flex justify-center items-center'
                onClick={this.onClickReport}
                name='challenges'
              >
                <Icon
                  style={{ position: 'absolute', left: 20 }}
                  name='link'
                  none
                />
                Challenge Report
              </button>
              <br />
              <button
                type='button'
                className='uk-button uk-button-default uk-width-1-1 uk-position-relative flex justify-center items-center'
                onClick={this.onClickReport}
                name='users'
              >
                <Icon
                  style={{ position: 'absolute', left: 20 }}
                  name='link'
                  none
                />
                Users Report
              </button>
              {/*
              <br />
              <button
                type="button"
                className="uk-button uk-button-default uk-width-1-1 uk-position-relative flex justify-center items-center"
                onClick={this.onClickReport}
                name="custom"
              >
                <Icon style={{ position: 'absolute', left: 20 }} name="link" none />
                Custom Report
              </button>
              */}
            </>
          }
        />
        <CustomContentModal
          id='custom-modal-content-edit-user-team'
          hackerUUID={this.props.selectedUser.uuid}
          onRef={(ref) => {
            this.customContentModal = ref
          }}
          email={(this.props.selectedUser || {}).email}
        />
        <RightSideBar
          content={
            <EditTeamUser
              onShowCustomModal={() => this.customContentModal.show()}
              hasInvitePrivileges={this.props.currentTeam.has_invite_privileges}
              modalUserTeamPicker={this.modalUserTeamPicker}
              teams={this.props.teams}
              organization={
                this.props.organizationsHash[
                  this.props.match.params.organization_uuid
                ]
              }
              getTeams={this.props.getTeams}
              loadingTeams={this.props.loadingTeams}
              isLoadingSaveTeams={this.props.isLoadingSaveTeams}
              isAdmin={
                this.props.organizationsHash[currentOrganizationId].role ===
                'admin'
              }
              handleClickMakeAdmin={this.handleClickMakeAdmin}
              onChangeUserPhase={this.onChangeUserPhase}
              phases={this.props.teamPlanDetail.map((p) => ({
                uuid: p.phase_uuid,
                title: p.phase_title,
              }))}
              isTimeBased={this.props.currentTeam.is_time_based}
              onRemoveFromOrg={() => this.modalDeleteUser.show()}
              onRemoveFromTeam={() => {
                this.handleClickRemove(this.props.selectedUser)
              }}
              hackerUUID={this.props.hackerUUID}
              selectedUser={this.props.selectedUser}
              updateTeamPrivileges={this.props.updateTeamPrivileges}
              match={this.props.match}
            />
          }
          ref={(ref) => {
            this.editUserSideBar = ref
          }}
        />
        <PlanChangeModal
          history={this.props.history}
          onRefresh={() => {
            this.props.getTeamById(
              this.props.match.params.organization_uuid,
              this.props.match.params.team_uuid,
              true
            )
            this.props.getTeamPlanDetail(
              this.props.match.params.organization_uuid,
              this.props.match.params.team_uuid
            )
          }}
          planToEdit={
            !this.props.currentTeam.plan.using_recommendation_engine &&
            !this.props.currentTeam.plan.no_training
              ? this.props.currentTeam.plan.uuid
              : null
          }
          id='plan-change-team-detail'
          onRef={(ref) => {
            this.changePlanModal = ref
          }}
          teamUUID={this.props.match.params.team_uuid}
          organizationUUID={this.props.match.params.organization_uuid}
        />
      </OrganizationLayout>
    )
  }
}

OrganizationsTeamsDetail.propTypes = propTypes

const mapStateToProps = (state) => ({
  // hacker

  organizations: state.hacker.organizationsList,
  currentTeamHackers: state.hacker.currentTeamHackers,
  loadingCurrentTeamHackers: state.hacker.loadingCurrentTeamHackers,
  organizationsHash: state.hacker.organizationsHash,
  hackerUUID: state.hacker.profile.user_id,
  content: state.content.content,
  // team
  teams: state.team.teams,
  selectedUser: state.team.selectedUser,
  currentTeam: state.team.currentTeam,
  loadingTeamsUsers: state.team.loadingTeamsUsers,
  teamPlanDetail: state.team.teamPlanDetail,
  loadingTeamById: state.team.loadingTeamById,
  // plans: state.plan.plans,
  selectedUserDetail: state.users.selectedUserDetail,

  loadingTeams: state.team.loadingTeams,
  isLoadingSaveTeams: state.users.isLoadingSaveTeams,
})
const mapDispatchToProps = (dispatch) => ({
  getHackers: (organzationId, query) =>
    dispatch(getHackers(organzationId, query)),
  getTeamById: (organzationId, teamId, disableLoader) =>
    dispatch(getTeamById(organzationId, teamId, disableLoader)),
  removeHackerFromTeam: (organizationId, teamId, hackerId) =>
    dispatch(removeHackerFromTeam(organizationId, teamId, hackerId)),
  setFilterHacker: (filter) => dispatch(setFilterHacker(filter)),
  getTeamUsers: (organizationId, teamUUID, callback) =>
    dispatch(getTeamUsers(organizationId, teamUUID, callback)),
  getTeamPlanDetail: (organizationId, teamUUID) =>
    dispatch(getTeamPlanDetail(organizationId, teamUUID)),
  setSelectedUser: (organizationUUID, hacker, localUpdate) =>
    dispatch(setSelectedUser(organizationUUID, hacker, localUpdate)),
  updateTeamPrivileges: (
    organizationId,
    teamId,
    hackerId,
    privileges,
    callback
  ) =>
    dispatch(
      updateTeamPrivileges(
        organizationId,
        teamId,
        hackerId,
        privileges,
        callback
      )
    ),
  getPlans: (organizationId) => dispatch(getPlans(organizationId)),
  updateTeamPlan: (
    organizationUUID,
    teamUUID,
    planUUID,
    callback,
    removeTraining
  ) =>
    dispatch(
      updateTeamPlan(
        organizationUUID,
        teamUUID,
        planUUID,
        callback,
        removeTraining
      )
    ),
  deleteHackerFromOrganization: (organizationId, hackerUUID, callback) =>
    dispatch(
      deleteHackerFromOrganization(organizationId, hackerUUID, callback)
    ),
  importTeamUsers: (
    organizationUUID,
    sendOnboarding,
    teamUUID,
    users,
    callback
  ) =>
    dispatch(
      importTeamUsers(
        organizationUUID,
        sendOnboarding,
        teamUUID,
        users,
        callback
      )
    ),
  deletePlanGroup: (organizationUUID, planUUID, groupUUID) =>
    dispatch(deletePlanGroup(organizationUUID, planUUID, groupUUID)),
  changeUserPhase: (
    organizationUUID,
    hackerUUID,
    planUUID,
    phaseUUID,
    teamUUID,
    callback
  ) =>
    dispatch(
      changeUserPhase(
        organizationUUID,
        hackerUUID,
        planUUID,
        phaseUUID,
        teamUUID,
        callback
      )
    ),
  updateHackerRole: (organizationUUID, hackerUUID, role) =>
    dispatch(updateHackerRole(organizationUUID, hackerUUID, role)),
  getTeams: (organizationUUID) => dispatch(getTeams(organizationUUID)),
  fetchSelectedUserDetail: (organizationUUID, hacker, disableLoader) =>
    dispatch(fetchSelectedUserDetail(organizationUUID, hacker, disableLoader)),
  saveUserTeams: (
    organizationUUID,
    teams,
    hackerUUID,
    successCallback,
    errorCallback
  ) =>
    dispatch(
      saveUserTeams(
        organizationUUID,
        teams,
        hackerUUID,
        successCallback,
        errorCallback
      )
    ),
  cleanUserDetailModal: () => dispatch(cleanUserDetailModal()),
  fetchPhaseDetailModalData: (
    organizationUUID,
    teamUUID,
    planUUID,
    phaseUUID
  ) =>
    dispatch(
      fetchPhaseDetailModalData(organizationUUID, teamUUID, planUUID, phaseUUID)
    ),
  updatePlanById: (
    organizationId,
    planId,
    title,
    description,
    defaultPlan,
    sendReminders,
    teamUUID,
    sc,
    ec,
    noTraining
  ) =>
    dispatch(
      updatePlanById(
        organizationId,
        planId,
        title,
        description,
        defaultPlan,
        sendReminders,
        teamUUID,
        sc,
        ec,
        noTraining
      )
    ),
  activateAutomatedPlan: (org, team, callback) =>
    dispatch(activateAutomatedPlan(org, team, callback)),
  updateRecommendationEngineSettings: (data, org, team, callback) =>
    dispatch(updateRecommendationEngineSettings(data, org, team, callback)),
  getContent: (org, include, callback) =>
    dispatch(getContent(org, include, callback)),
  updateRequiredContentRecommendationSettings: (
    requiredContent,
    org,
    team,
    callback
  ) =>
    dispatch(
      updateRequiredContentRecommendationSettings(
        requiredContent,
        org,
        team,
        callback
      )
    ),
  updateRequiredTrainingContent: (
    organizationUUID,
    contentUUID,
    teamUUID,
    noLimit,
    days,
    date,
    callback
  ) =>
    dispatch(
      updateRequiredTrainingContent(
        organizationUUID,
        contentUUID,
        teamUUID,
        noLimit,
        days,
        date,
        callback
      )
    ),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsTeamsDetail)
)
