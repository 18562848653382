/* eslint-disable no-plusplus */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
import settings from 'settings'
import { api } from 'app/views/utils/api'
import axios, { CancelToken } from 'axios'

const INITIALIZE_SYNTAX_CHECK = 'linter/INITIALIZE_SYNTAX_CHECK'
const SET_SENDING_REQUEST = 'linter/SET_SENDING_REQUEST'
const SET_ANNOTATIONS = 'linter/SET_ANNOTATIONS'
const SET_LINTER_FILEID = 'linter/SET_LINTER_FILEID'

const ADD_OPERATION = 'linter/ADD_OPERATION'
const SET_OPERATIONS = 'linter/SET_OPERATIONS'

const INITIAL_STATE = {
  operations: [],

  isSendingRequest: false,
  annotations: [],
  linterFileId: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INITIALIZE_SYNTAX_CHECK:
      return {
        ...INITIAL_STATE,
      }
    case SET_LINTER_FILEID:
      return {
        ...state,
        linterFileId: action.linterFileId,
      }
    case SET_SENDING_REQUEST:
      return {
        ...state,
        isSendingRequest: action.isSendingRequest,
      }
    case SET_ANNOTATIONS:
      return {
        ...state,
        annotations: action.annotations,
      }
    case ADD_OPERATION:
      return {
        ...state,
        operations: [...state.operations, action.operation],
      }
    case SET_OPERATIONS:
      return {
        ...state,
        operations: action.operations,
      }
    default:
      return state
  }
}

export const addOperation = (operation) => ({
  type: ADD_OPERATION,
  operation,
})

export const resetLinter =
  (del = false) =>
  async (dispatch, getState) => {
    const state = getState()
    if (state.auth.status !== 'LOGGED_IN') {
      return
    }
    dispatch({
      type: INITIALIZE_SYNTAX_CHECK,
    })

    const { linterFileId } = state.linter

    if (del && typeof linterFileId === 'string') {
      api({
        method: 'DELETE',
        url: `${settings.urls.linter}/file/${linterFileId}`,
        withAuthToken: true,
      })
    }
  }

export const initSyntaxCheck = (code, extension) => (dispatch, getState) => {
  dispatch(resetLinter())
  const state = getState()
  if (state.auth.status !== 'LOGGED_IN') {
    return
  }
  dispatch({
    type: SET_SENDING_REQUEST,
    isSendingRequest: true,
  })
  if (window.linterSyntaxCheckInitRequest !== undefined) {
    window.linterSyntaxCheckInitRequest()
  }
  api({
    method: 'POST',
    withAuthToken: true,
    url: `${settings.urls.linter}/file`,
    data: {
      text: code,
      extension,
    },
    cancelToken: new CancelToken((c) => {
      window.linterSyntaxCheckInitRequest = c
    }),
  })
    .then(({ data }) => {
      dispatch({
        type: SET_LINTER_FILEID,
        linterFileId: data.file_id,
      })

      dispatch({
        type: SET_SENDING_REQUEST,
        isSendingRequest: false,
      })

      dispatch({
        type: SET_ANNOTATIONS,
        annotations: data.status !== 'error' ? data.warnings : [],
      })
    })
    .catch((e) => {
      if (!axios.isCancel(e)) {
        console.error('error', e)
      }
      dispatch({
        type: SET_SENDING_REQUEST,
        isSendingRequest: false,
      })
    })
}

export const checkSyntax =
  (operations = false) =>
  async (dispatch, getState) => {
    const state = getState()
    if (state.auth.status !== 'LOGGED_IN') {
      return
    }
    const { linterFileId } = state.linter
    if (!state.linter.isSendingRequest && typeof linterFileId === 'string') {
      dispatch({
        type: SET_SENDING_REQUEST,
        isSendingRequest: true,
      })
      try {
        const { data } = await api({
          method: 'PATCH',
          withAuthToken: true,
          url: `${settings.urls.linter}/file/${linterFileId}`,
          data: {
            operation: JSON.stringify(operations || state.linter.operations),
          },
        })
        dispatch({
          type: SET_ANNOTATIONS,
          annotations: data?.warnings || [],
        })
      } catch {
        dispatch({
          type: SET_ANNOTATIONS,
          annotations: [],
        })
      } finally {
        dispatch({
          type: SET_SENDING_REQUEST,
          isSendingRequest: false,
        })
        if (getState().linter.operations.length) {
          const temparray = [...(getState().linter.operations || [])]
          dispatch({
            type: SET_OPERATIONS,
            operations: [],
          })
          dispatch(checkSyntax(temparray))
        }
      }
    }
  }
