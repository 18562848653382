import PropTypes from 'prop-types'
import React from 'react'

const ButtonWithLoader = ({
  children,
  isLoading,
  spinnerClassName,
  type,
  ...props
}) => {
  let buttonType
  if (isLoading) {
    return <div data-uk-spinner='' className={spinnerClassName} />
  }

  switch (type) {
    case 'submit':
      buttonType = 'submit'
      break
    case 'reset':
      buttonType = 'reset'
      break
    default:
      buttonType = 'button'
  }

  return (
    <button {...props} type={buttonType} disabled={props.disabled || isLoading}>
      {children}
    </button>
  )
}

ButtonWithLoader.propTypes = {
  isLoading: PropTypes.bool,
}
ButtonWithLoader.defaultProps = {
  isLoading: false,
}

export default ButtonWithLoader
