import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import * as content from 'app/state/modules/content'

// See also: secondsElapsed in apiServiceShim.ts, under POST /content/:uuid/ping
const CONTENT_PING_INTERVAL_MS = 5000

/**
 * Hits api-hacker's POST /content/<uuid>/ping endpoint at a fixed interval, so long as the app has
 * focus, in order to update user metrics (e.g. time spent on content) on the backend.
 */
function ContentPingBeacon(props) {
  const { contentId, sendPing } = props
  useEffect(() => {
    // Send a ping POST every so often while this component is mounted
    const timerHandle = setInterval(() => {
      if (document.hasFocus()) {
        sendPing(contentId)
      }
    }, CONTENT_PING_INTERVAL_MS)

    // Stop pinging when this component is unmounted
    return () => {
      clearInterval(timerHandle)
    }
  }, [])
  return null
}
ContentPingBeacon.propTypes = {
  contentId: PropTypes.string.isRequired,
  sendPing: PropTypes.func.isRequired,
}

export default connect(null, (dispatch) => ({
  sendPing: (contentId) => dispatch(content.pingContent(contentId)),
}))(ContentPingBeacon)
