import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { values } from 'lodash'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import cx from 'classnames'

class OrgNav extends React.Component {
  constructor(props) {
    super(props)

    this.handleOrganizationChange = this.handleOrganizationChange.bind(this)
  }

  handleOrganizationChange(event) {
    this.props.history.push(`/admin/${event.target.value}/dashboard`)
  }

  render() {
    const { flags, organizations, currentOrganizationId } = this.props
    const isReportsItem = [
      'reports',
      'certificate',
      'activity',
      'impact-report',
    ].includes(this.props.active)
    const isSettingsItem = [
      'settings',
      'integrations',
      'apps_integrations',
      'notifications',
    ].includes(this.props.active)
    return (
      <div className='uk-padding uk-background-muted'>
        <ul className='uk-nav-default uk-nav-parent-icon' data-uk-nav>
          <div className='uk-margin'>
            <div className='uk-form-controls'>
              <select
                className='uk-select'
                id='form-stacked-select'
                value={currentOrganizationId}
                onChange={this.handleOrganizationChange}
              >
                {Object.keys(organizations).length > 0 &&
                  values(organizations).map((organization) => {
                    if (!organization.uuid) return null
                    return (
                      <option key={organization.uuid} value={organization.uuid}>
                        {organization.name}
                      </option>
                    )
                  })}
              </select>
            </div>
          </div>
          <li
            className={cx({ 'uk-active': this.props.active === 'dashboard' })}
          >
            <Link to={`/admin/${currentOrganizationId}/dashboard`}>
              <i
                className='uk-margin-small-right ri-arrow-down-s-line'
                style={{ visibility: 'hidden' }}
              />
              Dashboard
            </Link>
          </li>
          <li className={cx({ 'uk-active': this.props.active === 'users' })}>
            <Link to={`/admin/${currentOrganizationId}/users`}>
              <i
                className='uk-margin-small-right ri-arrow-down-s-line'
                style={{ visibility: 'hidden' }}
              />
              Users
            </Link>
          </li>
          <li className={cx({ 'uk-active': this.props.active === 'teams' })}>
            <Link
              data-test-id='organization-navi-option-teams'
              to={`/admin/${currentOrganizationId}/teams`}
            >
              <i
                className='uk-margin-small-right ri-arrow-down-s-line'
                style={{ visibility: 'hidden' }}
              />
              Teams
            </Link>
          </li>
          <li className={cx({ 'uk-active': this.props.active === 'plans' })}>
            <Link
              data-test-id='organization-navi-training-plans'
              to={`/admin/${currentOrganizationId}/plans`}
            >
              <i
                className='uk-margin-small-right ri-arrow-down-s-line'
                style={{ visibility: 'hidden' }}
              />
              Training Plans
            </Link>
          </li>
          <li
            data-test-id='organization-navi-option-reports'
            className={cx({ 'uk-active': isReportsItem })}
            style={{ display: 'flex' }}
          >
            {isReportsItem ? (
              <>
                <span className={isReportsItem ? 'text-emphasis' : ''}>
                  <i className='uk-margin-small-right ri-arrow-down-s-line' />
                  Reports & Certificates
                </span>
              </>
            ) : (
              <Link to={`/admin/${currentOrganizationId}/reports`}>
                <i className='uk-margin-small-right ri-arrow-right-s-line' />
                Reports & Certificates
              </Link>
            )}
          </li>
          {isReportsItem && (
            <>
              <li
                className={cx('uk-margin-remove pl-[15px]', {
                  'uk-active': this.props.active === 'reports',
                })}
              >
                <Link
                  className='uk-padding-remove'
                  data-test-id='organization-navi-option-reports-reports'
                  to={`/admin/${currentOrganizationId}/reports`}
                >
                  <i
                    className='uk-margin-small-right ri-arrow-down-s-line'
                    style={{ visibility: 'hidden' }}
                  />
                  Training Reports
                </Link>
              </li>
              <li
                className={cx('uk-margin-remove pl-[15px]', {
                  'uk-active': this.props.active === 'impact-report',
                })}
              >
                <Link
                  className='uk-padding-remove'
                  data-test-id='organization-navi-option-reports-impact'
                  to={`/admin/${currentOrganizationId}/reports/impact`}
                >
                  <i
                    className='uk-margin-small-right ri-arrow-down-s-line'
                    style={{ visibility: 'hidden' }}
                  />
                  Impact Report
                </Link>
              </li>
              <li
                className={cx('uk-margin-remove pl-[15px]', {
                  'uk-active': this.props.active === 'certificate',
                })}
              >
                <Link
                  className='uk-padding-remove'
                  data-test-id='organization-navi-option-reports-certificate'
                  to={`/admin/${currentOrganizationId}/reports/compliance`}
                >
                  <i
                    className='uk-margin-small-right ri-arrow-down-s-line'
                    style={{ visibility: 'hidden' }}
                  />
                  Certificate (PDF)
                </Link>
              </li>
              <li
                className={cx('uk-margin-remove pl-[15px]', {
                  'uk-active': this.props.active === 'activity',
                })}
              >
                <Link
                  className='uk-padding-remove'
                  data-test-id='organization-navi-option-reports-certificate'
                  to={`/admin/${currentOrganizationId}/reports/activity`}
                >
                  <i
                    className='uk-margin-small-right ri-arrow-down-s-line'
                    style={{ visibility: 'hidden' }}
                  />
                  Activity
                </Link>
              </li>
            </>
          )}

          <li
            data-test-id='organization-navi-option-settings'
            className={cx({ 'uk-active': isSettingsItem })}
            style={{ display: 'flex' }}
          >
            {isSettingsItem ? (
              <>
                <span className={isSettingsItem ? 'text-emphasis' : ''}>
                  <i className='uk-margin-small-right ri-arrow-down-s-line' />
                  Settings
                </span>
              </>
            ) : (
              <Link to={`/admin/${currentOrganizationId}/settings`}>
                <i className='uk-margin-small-right ri-arrow-right-s-line' />
                Settings
              </Link>
            )}
          </li>
          {isSettingsItem && (
            <>
              <li
                className={cx('uk-margin-remove pl-[15px]', {
                  'uk-active': this.props.active === 'settings',
                })}
              >
                <Link
                  className='uk-padding-remove'
                  data-test-id='organization-navi-option-reports'
                  to={`/admin/${currentOrganizationId}/settings`}
                >
                  <i
                    className='uk-margin-small-right ri-arrow-down-s-line'
                    style={{ visibility: 'hidden' }}
                  />
                  General
                </Link>
              </li>
              <li
                className={cx('uk-margin-remove pl-[15px]', {
                  'uk-active': this.props.active === 'notifications',
                })}
              >
                <Link
                  className='uk-padding-remove'
                  data-test-id='organization-navi-option-reports'
                  to={`/admin/${currentOrganizationId}/notifications`}
                >
                  <i
                    className='uk-margin-small-right ri-arrow-down-s-line'
                    style={{ visibility: 'hidden' }}
                  />
                  Notifications
                </Link>
              </li>
              <li
                className={cx('uk-margin-remove pl-[15px]', {
                  'uk-active': this.props.active === 'integrations',
                })}
              >
                <Link
                  className='uk-padding-remove'
                  data-test-id='organization-navi-option-reports'
                  to={`/admin/${currentOrganizationId}/integrations`}
                >
                  <i
                    className='uk-margin-small-right ri-arrow-down-s-line'
                    style={{ visibility: 'hidden' }}
                  />
                  Data Integrations
                </Link>
              </li>
              <li
                className={cx('uk-margin-remove pl-[15px]', {
                  'uk-active': this.props.active === 'apps_integrations',
                })}
              >
                <Link
                  className='uk-padding-remove'
                  data-test-id='organization-navi-option-reports'
                  to={`/admin/${currentOrganizationId}/app-integrations`}
                >
                  <i
                    className='uk-margin-small-right ri-arrow-down-s-line'
                    style={{ visibility: 'hidden' }}
                  />
                  App Integrations
                </Link>
              </li>
            </>
          )}
          <li>
            <a
              href='https://help.securityjourney.com/en/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ display: 'flex' }}
            >
              <i
                className='uk-margin-small-right ri-arrow-down-s-line'
                style={{ visibility: 'hidden' }}
              />
              <span>Documentation</span>
              <i className='uk-margin-small-left ri-link' />
            </a>
          </li>
        </ul>
      </div>
    )
  }
}

export const OrganizationsNavi = withLDConsumer()(withRouter(OrgNav))
