import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { HBox, Box } from 'app/views/core/Box'

import StarRatingControl from '../../common/StarRatingControl'

const Button = ({ text, onClick }) => (
  <button
    className='uk-button uk-button-primary'
    style={{ flexBasis: 300, marginTop: 40 }}
    onClick={onClick}
  >
    {text}
  </button>
)

function CodingChallengeCompletionForm(props) {
  const { onFinishChallenge } = props
  const [numStarsRated, setNumStarsRated] = useState(0)
  const [feedbackText, setFeedbackText] = useState('')
  return (
    <div>
      <p>Rate this coding challenge:</p>
      <StarRatingControl
        numStarsRated={numStarsRated}
        maxStars={10}
        onNumStarsRatedChange={setNumStarsRated}
      />
      <p>Please share any feedback you have:</p>
      <textarea
        className='uk-textarea'
        rows='3'
        value={feedbackText}
        onChange={(event) => setFeedbackText(event.target.value)}
      />
      <HBox>
        <Box grow />
        <Button
          text='Finish Challenge'
          onClick={() => onFinishChallenge(numStarsRated, feedbackText)}
        />
        <Box grow />
      </HBox>
    </div>
  )
}
CodingChallengeCompletionForm.propTypes = {
  onFinishChallenge: PropTypes.func.isRequired,
}

export default CodingChallengeCompletionForm
