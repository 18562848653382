import { type CodeSubmission } from './types'

/**
 * Given a list of submissions, returns the submission with the most recent createdAt
 * timestamp, or null if no valid submissions exist.
 */
export function getNewestSubmission(submissions: CodeSubmission[]): CodeSubmission | null {
  let newestSubmission = null as CodeSubmission | null
  let newestTimestamp = new Date(0)
  for (const submission of submissions) {
    if (submission.createdAt > newestTimestamp) {
      newestSubmission = submission
      newestTimestamp = submission.createdAt
    }
  }
  return newestSubmission
}

/**
 * Examines a submission to determine whether it's likely to be a real-time result of a
 * submission that the user has just made in the app - as opposed to a replay of an
 * older Kafka event.
 *
 * When polling for new results, we're waiting specifically for results to a submission
 * that we've just made, so we want to discard any submissions that aren't likely to
 * represent the results we're waiting on.
 */
export function isRealTimeResult(submission: CodeSubmission): boolean {
  const FIFTEEN_MINUTES_IN_MS = 15 * 60 * 1000
  const deltaMs = submission.createdAt.getTime() - submission.submissionTime.getTime()
  return Math.abs(deltaMs) < FIFTEEN_MINUTES_IN_MS
}

/**
 * Given a list of submissions, returns a new list containing all the unique items from
 * the input list, removing any duplicate submissions matching an existing
 * submissionTime.
 */
export function deduplicateSubmissions(submissions: CodeSubmission[]): CodeSubmission[] {
  const results = [] as CodeSubmission[]
  const timestampsSeen = {} as { [key: string]: boolean }
  for (const submission of submissions) {
    const s = String(submission.submissionTime.getTime())
    if (timestampsSeen[s]) {
      continue
    }
    timestampsSeen[s] = true
    results.push(submission)
  }
  return results
}
