const KEY_POST_LOGIN_REDIRECT_PATHNAME = 'hackedu.redirect.onLogin.pathname'
const KEY_POST_LOGIN_REDIRECT_SEARCH = 'hackedu.redirect.onLogin.search'

/**
 * Caches the given URL components (e.g. '/foo/bar', '?n=1&x=42') as the
 * post-login redirect URL: upon their next successful login attempt, the user
 * will be redirected here.
 */
export function storePostLoginRedirectUrl(pathname, search) {
  if (pathname) {
    localStorage.setItem(KEY_POST_LOGIN_REDIRECT_PATHNAME, pathname)
    if (search) {
      localStorage.setItem(KEY_POST_LOGIN_REDIRECT_SEARCH, search || '')
    }
  }
}

/**
 * Reads a post-login redirect URL that was previously stored, which has the
 * side effect of deleting it from the cache. Returns a path with any stored
 * parameters appended; defaults to the root path ('/') if no path is cached.
 */
export function readPostLoginRedirectUrl() {
  const pathname = localStorage.getItem(KEY_POST_LOGIN_REDIRECT_PATHNAME)
  const search = localStorage.getItem(KEY_POST_LOGIN_REDIRECT_SEARCH)
  if (pathname) {
    localStorage.removeItem(KEY_POST_LOGIN_REDIRECT_PATHNAME)
    localStorage.removeItem(KEY_POST_LOGIN_REDIRECT_SEARCH)
    return pathname + (search || '')
  }
  return '/'
}
