import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

function EditorTab(props) {
  const { name, hasEdits, hasHints, isActive, onClick, onClickClose } = props
  return (
    <div
      className={cx('editor-tab', {
        'editor-tab-active': isActive,
        'editor-tab-hints': hasHints
      })}
      onClick={() => onClick()}
      onAuxClick={(event) => {
        const MIDDLE_MOUSE_BUTTON = 1
        if (event.button === MIDDLE_MOUSE_BUTTON) {
          event.preventDefault()
          onClickClose()
        }
      }}
    >
      <div className='editor-tab-label'>
        {name}
        {hasEdits ? ' *' : ''}
      </div>
      <i
        className='editor-tab-close-button ri-close-fill'
        onClick={(event) => {
          event.stopPropagation()
          onClickClose()
        }}
      />
    </div>
  )
}
EditorTab.propTypes = {
  name: PropTypes.string.isRequired,
  hasEdits: PropTypes.bool.isRequired,
  hasHints: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
}

export default EditorTab
