import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'
function HighlightHintBody(props) {
  const { title, onClickConfirm, onClickBack } = props
  return (
    <>
      <h3>{title}</h3>
      <p>
        Using this hint requires us to reset any changes you've made to the app
        and give you a new sandbox. Are you sure you want to proceed?
      </p>
      <PrimaryButton
        onClick={onClickConfirm}
        size='full'
        customCss={'mr-36'}
        label='Reset Sandbox and Activate Hint'
      />

      <a
        href='#'
        onClick={(event) => {
          event.preventDefault()
          onClickBack()
        }}
      >
        &larr; Back to Hints
      </a>
    </>
  )
}
HighlightHintBody.propTypes = {
  title: PropTypes.string.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
}

export default HighlightHintBody
