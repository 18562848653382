import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import ContentUI from '../common/ContentUI'
import ContentPingBeacon from '../common/ContentPingBeacon'
import InactiveSandboxModal from '../common/InactiveSandboxModal'
import InstructionPanel from '../common/InstructionPanel'
import WorkPanel from '../common/WorkPanel'
import { useTabState, useSandboxInactivityTimeout } from '../common/util'
import { useServiceShimLessonState } from '../../utils/apiServiceShim'
import { TournamentDetailsProps } from 'app/sjTournament'

import HackingChallengeInstructions from './HackingChallengeInstructions'

import { connect } from 'react-redux'
import * as sandbox from 'app/state/modules/sandbox'
import * as hacker from 'app/state/modules/hacker'
import * as sbproxy from 'app/state/modules/sbproxy'

function HackingChallengeUI(props) {
  // HackingChallengeUI isn't mounted until the content details have been fetched, so these values
  // are immutable for the lifetime of the component
  const { contentId, contentDetails, sandboxTemplateName } = props
  const hasSandboxTemplate = sandboxTemplateName.length > 0

  // On mount, notify the API-to-service shim that we're now presenting the user with
  // this lesson, and clear that state on unmount
  useServiceShimLessonState(contentId, contentDetails['lesson_key'] || '')

  // We can set our desired intercept state (based on intercept_on_load) before we have a sandbox:
  // sbproxy will cache this as persistent state and act upon it when the proxy is ready to be used
  const { setInitialInterceptState } = props
  const wantsInterceptsEnabledInitially = !!contentDetails.intercept_on_load
  useEffect(() => {
    setInitialInterceptState(wantsInterceptsEnabledInitially)
  }, [wantsInterceptsEnabledInitially])

  // Request a sandbox (without forceNew) on the initial load, and clean up sandbox state when done
  const { requestNewSandbox, abandonSandbox } = props
  useEffect(() => {
    if (hasSandboxTemplate) {
      requestNewSandbox(contentId, sandboxTemplateName)
    }
    return () => {
      abandonSandbox()
    }
  }, [])

  // Pull state from the proxy and contentDetails to pass down to child components
  const { numProxyRequests, interceptedRequest, resumeInterceptedRequest } =
    props
  const browserTabs = contentDetails.tabs.sort(
    (a, b) => a.sort_order - b.sort_order
  )
  const proxiedBrowserTabIndex = browserTabs.findIndex((tab) => tab.proxy)
  const hasProxy = proxiedBrowserTabIndex >= 0

  // Establish state allowing the user to switch between tabs in the WorkPanel
  const [activeTabName, onActivateTab] = useTabState('browser-0')
  const actualActiveTabName =
    proxiedBrowserTabIndex >= 0 && interceptedRequest
      ? `browser-${proxiedBrowserTabIndex}`
      : activeTabName

  // When a new sandbox is requested, automatically switch to the first browser tab
  const { lastSandboxRequestTimestamp } = props
  useEffect(() => {
    if (lastSandboxRequestTimestamp) {
      onActivateTab('user', 'browser-0')
    }
  }, [lastSandboxRequestTimestamp])

  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const toggleTrialModal = useCallback((tab, showVaue) => {
    onActivateTab('user', tab)
    setShowUpgradeModal(showVaue)
  }, [])

  // If the user sits too long without interacting with the sandbox, show a modal forcing them to
  // request a new sandbox
  const sandboxIsInactive = useSandboxInactivityTimeout(120 * 60 * 1000)

  return (
    <ContentUI>
      {sandboxIsInactive && (
        <InactiveSandboxModal
          onClose={() => requestNewSandbox(contentId, sandboxTemplateName)}
        />
      )}
      {!sandboxIsInactive && <ContentPingBeacon contentId={contentId} />}
      <InstructionPanel
        contentId={contentId}
        title={contentDetails.title}
        narrow
      >
        <HackingChallengeInstructions
          contentId={contentId}
          description={contentDetails.description}
        />
      </InstructionPanel>
      <WorkPanel
        contentId={contentId}
        contentUsesRealUrls={true}
        sandboxTemplateName={sandboxTemplateName}
        browserTabs={browserTabs}
        hasCode={false}
        hasMultiFileCode={false}
        codeEditorProps={{
          contentId,
          contentHasTests: false,
        }}
        hasProxy={hasProxy}
        hasTests={false}
        hasMultiFileTests={false}
        hasOutput={false}
        activeTabName={actualActiveTabName}
        onActiveTabNameChange={(tabName) => onActivateTab('user', tabName)}
        numProxyRequests={numProxyRequests}
        interceptedRequest={interceptedRequest}
        onSubmitInterceptedRequest={resumeInterceptedRequest}
        toggleTrialModal={toggleTrialModal}
        showUpgradeModal={showUpgradeModal}
      />
    </ContentUI>
  )
}
HackingChallengeUI.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentDetails: PropTypes.object.isRequired,
  sandboxTemplateName: PropTypes.string.isRequired,
  tournamentDetails: TournamentDetailsProps,
  numProxyRequests: PropTypes.number.isRequired,
  interceptedRequest: PropTypes.object,
  lastSandboxRequestTimestamp: PropTypes.number,

  setInitialInterceptState: PropTypes.func.isRequired,
  requestNewSandbox: PropTypes.func.isRequired,
  abandonSandbox: PropTypes.func.isRequired,
  resumeInterceptedRequest: PropTypes.func.isRequired,
}

export default connect(
  (state) => {
    const {
      concludedHistory,
      hasCompletedInitialPageLoad,
      requests,
      isIntercepting,
    } = state.sbproxy
    const canIntercept =
      hasCompletedInitialPageLoad && isIntercepting && requests.length > 0
    return {
      numProxyRequests: concludedHistory ? Object.keys(requests).length : 0,
      interceptedRequest:
        canIntercept && !requests[0].response ? requests[0] : null,
      lastSandboxRequestTimestamp: state.sandbox.lastRequest
        ? state.sandbox.lastRequest.timestamp
        : null,
    }
  },
  (dispatch) => ({
    setInitialInterceptState: (value) =>
      dispatch(sbproxy.setWantsToIntercept(value)),
    requestNewSandbox: (contentId, templateName) =>
      dispatch(sandbox.requestNew(contentId, templateName, false)),
    abandonSandbox: () => dispatch(sandbox.abandon()),
    resumeInterceptedRequest: (requestText, isEdited) =>
      dispatch(sbproxy.resumeInterceptedRequest(requestText, isEdited)),
  })
)(HackingChallengeUI)
