import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const SeoRoute = ({
  component: Component,
  children,
  title,
  description,
  keywords,
  extraMeta = [],
  ...rest
}) => {
  const getMetas = (...args) => {
    const metas = []
    if (args[0]) {
      metas.push({ name: 'description', content: args[0] })
    }
    if (args[1]) {
      metas.push({ name: 'keywords', content: args[1] })
    }
    return metas.concat(args[2])
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {(title || description || keywords || extraMeta) && (
            <Helmet
              title={title || null}
              meta={getMetas(description, keywords, extraMeta)}
            />
          )}
          <Component {...props} contentUUID={rest.contentUUID} />
        </>
      )}
    />
  )
}

export default SeoRoute
