import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import * as sandbox from 'app/state/modules/sandbox'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

function SandboxResetButton(props) {
  const { contentId, templateName } = props
  const {
    lastRequestTimestamp,
    requestAcknowledgedTimestamp,
    requestFinishedTimestamp,
    requestError,
    requestNewSandbox,
  } = props

  // Disable the button for a few seconds after a request is made
  const [isOnCooldown, setIsOnCooldown] = useState(false)
  const timerHandle = useRef(null)
  useEffect(() => {
    // Clear any existing timeout
    if (timerHandle.current) {
      clearTimeout(timerHandle.current)
    }

    // Deactivate the button, and schedule it to be reactivated shortly
    setIsOnCooldown(true)
    const requestIsPending = !requestFinishedTimestamp
    const requestWasReceived = !!requestAcknowledgedTimestamp
    const interval = requestIsPending && requestWasReceived ? 30000 : 2000
    timerHandle.current = setTimeout(() => setIsOnCooldown(false), interval)

    // Make sure we clear timeouts on unmount
    return () => {
      if (timerHandle.current) {
        clearTimeout(timerHandle.current)
      }
    }
  }, [
    lastRequestTimestamp,
    requestAcknowledgedTimestamp,
    requestFinishedTimestamp,
  ])

  const kind = requestError ? 'danger' : 'secondary'
  return (
    <PrimaryButton
      size='medium'
      customCss={'h-9 w-36 m-2'}
      active={!isOnCooldown}
      onClick={() => {
        if (!isOnCooldown) {
          requestNewSandbox(contentId, templateName)
        }
      }}
      label='Reset Sandbox'
    />
  )
}
SandboxResetButton.propTypes = {
  contentId: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  lastRequestTimestamp: PropTypes.number,
  requestAcknowledgedTimestamp: PropTypes.number,
  requestFinishedTimestamp: PropTypes.number,
  requestError: PropTypes.any,
  requestNewSandbox: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    lastRequestTimestamp: state.sandbox.lastRequest
      ? state.sandbox.lastRequest.timestamp
      : null,
    requestAcknowledgedTimestamp: state.sandbox.requestAcknowledgedTimestamp,
    requestFinishedTimestamp: state.sandbox.requestFinishedTimestamp,
    requestError: state.sandbox.requestError,
  }),
  (dispatch) => ({
    requestNewSandbox: (contentId, templateName) =>
      dispatch(sandbox.requestNew(contentId, templateName, true)),
  })
)(SandboxResetButton)
