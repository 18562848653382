const PRODUCT_TOUR_VERSION = 1
const PRODUCT_TOUR_STAGES = [
  {
    elementId: 'instruction-panel-contents',
    elementAnchor: 'top-right',
    tooltipAnchor: 'top-left',
    maxWidth: 'min(300px, 50vw)',
    title: 'Welcome to HackEDU!',
    text: 'Each lesson starts with written explanation of the vulnerability being explored, and an introduction to the vulnerable application you\'ll be working with.\nClick Next to continue the tour.',
  },
  {
    elementId: 'browser-tab-0',
    elementAnchor: 'top-right',
    tooltipAnchor: 'top-left',
    maxWidth: 'min(300px, 50vw)',
    title: 'Sandbox Browser',
    text: 'This tab shows web pages served by a vulnerable application.\nThis is a real application, running in a sandbox that\'s assigned to you.',
    extra: {
      lockedTabName: 'browser-0',
    },
  },
  {
    elementId: 'browser-tab-0-navbar',
    elementAnchor: 'bottom-left',
    tooltipAnchor: 'top-left',
    maxWidth: 'min(300px, 50vw)',
    title: 'Browser Navigation',
    text: 'As with any browser, you can navigate to different URLs \u2013 the resulting page requests will be sent to the application running in the sandbox.\nYou can also use the View Source button to inspect the page source.',
    extra: {
      lockedTabName: 'browser-0',
    },
  },
  {
    elementId: 'browser-tab-0-proxy-switch',
    elementAnchor: 'bottom-right',
    tooltipAnchor: 'top-right',
    maxWidth: 'min(300px, 50vw)',
    title: 'Intercept Requests',
    text: 'This switch enables the proxy, which holds all requests from the browser to the vulnerable application, giving you a chance to inspect and modify those requests before they\'re sent on their way.\nFeel free to disable the proxy during your initial exploration \u2013 you can turn it back on at any time.',
    extra: {
      lockedTabName: 'browser-0',
    },
  },
  {
    elementId: 'proxy-history-tab',
    elementAnchor: 'bottom-right',
    tooltipAnchor: 'top-right',
    maxWidth: 'min(300px, 50vw)',
    title: 'Proxy History',
    text: 'This tab records all the requests that have been sent to the vulnerable application, regardless of whether \'Intercept Requests\' was enabled at the time.\nYou can select individual requests to see the full details of the request as well as the response sent back from the application.',
    extra: {
      lockedTabName: 'proxy',
    },
  },
  {
    elementId: 'code-editor-tab',
    elementAnchor: 'bottom-right',
    tooltipAnchor: 'top-right',
    maxWidth: 'min(300px, 50vw)',
    title: 'Code Editor',
    text: 'The code editor shows you the vulnerable application code that\'s running in the sandbox, giving you a chance to identify and patch the vulnerability.',
    extra: {
      lockedTabName: 'code',
    },
  },
  {
    elementId: 'code-editor-language-button',
    elementAnchor: 'top-left',
    tooltipAnchor: 'top-right',
    maxWidth: 'min(300px, 50vw)',
    title: 'Language Selection',
    text: 'You can click here to change between supported languages, and to see version information and available libraries for your selected language.',
    extra: {
      lockedTabName: 'code',
    },
  },
  {
    elementId: 'code-editor-save-button',
    elementAnchor: 'bottom-left',
    tooltipAnchor: 'top-left',
    maxWidth: 'min(300px, 50vw)',
    title: 'Save Code',
    text: 'Once you save your code, your changes are applied to the sandbox \u2013 the vulnerable application will be patched with your changes.\nIf you patch a login function, for example, then logging in to the app via the browser tab will cause your code to be executed.',
    extra: {
      lockedTabName: 'code',
    },
  },
  {
    elementId: 'sandbox-output-header-label',
    elementAnchor: 'top-left',
    tooltipAnchor: 'bottom-left',
    maxWidth: 'min(300px, 50vw)',
    title: 'Sandbox Output',
    text: 'If your saved code includes print statements, the resulting output will appear here whenever the application runs your code.',
    extra: {
      lockedTabName: 'code',
    },
  },
  {
    elementId: 'code-editor-test-button',
    elementAnchor: 'bottom-left',
    tooltipAnchor: 'top-left',
    maxWidth: 'min(300px, 50vw)',
    title: 'Run Tests',
    text: 'You can submit your code for testing at any time. We\'ll test your patch and let you know if you\'ve fixed the vulnerability.',
    extra: {
      lockedTabName: 'code',
    },
  },
  {
    elementId: 'tests-tab',
    elementAnchor: 'bottom-right',
    tooltipAnchor: 'top-right',
    maxWidth: 'min(300px, 50vw)',
    title: 'Tests',
    text: 'This tab lists all the patches you\'ve submitted for testing, along with the results of each submission. If your patch doesn\'t fix the vulnerability, the results will explain why.',
    extra: {
      lockedTabName: 'tests',
    },
  },
  {
    elementId: 'reset-sandbox-button',
    elementAnchor: 'bottom-right',
    tooltipAnchor: 'top-right',
    maxWidth: 'min(300px, 50vw)',
    title: 'Reset Sandbox',
    text: 'You can click this button at any time to reset your sandbox. This will discard any local changes and return the sandbox to its clean, initial state.',
  },
  {
    elementId: 'app',
    elementAnchor: 'top-left',
    tooltipAnchor: 'top-left',
    maxWidth: 'min(300px, 50vw)',
    title: 'Good luck!',
    text: 'Thank you for using HackEDU!',
  },
]

export { PRODUCT_TOUR_VERSION, PRODUCT_TOUR_STAGES }
