import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'

import { UPDATE_TEAM_USER } from './hacker'
import { RECEIVED_SELECTED_USER_TEAMS } from './team'

const USER_IMPORT_LOADER = 'users/USER_IMPORT_SET_LOADER'
const SET_USERS_TO_IMPORT = 'users/SET_USERS_TO_IMPORT'
const SET_LOADER_SAVE_TEAMS = 'users/SET_LOADER_SAVE_TEAMS'
const SET_SEND_ONBOARDING = 'users/SET_SEND_ONBOARDING'
const SET_SELECTED_USER_DETAIL = 'users/SET_SELECTED_USER_DETAIL'
const FETCH_SELECTED_USER_DETAIL = 'users/FETCH_SELECTED_USER_DETAIL'
const FETCH_HACKER_ACTIVITY = 'users/FETCH_HACKER_ACTIVITY'
const SET_HACKER_ACTIVITY = 'users/SET_HACKER_ACTIVITY'
const SET_HACKER_ACTIVITY_MORE = 'users/SET_HACKER_ACTIVITY_MORE'
const SET_PLANS = 'users/SET_PLANS'
const FETCH_PLANS = 'users/FETCH_PLANS'
const SET_HACKER_CODES = 'users/SET_HACKER_CODES'
const CLEAN_MODAL_USER_DETAIL = 'users/CLEAN_MODAL_USER_DETAIL'
const SET_CODE_REVIEWS = 'users/SET_CODE_REVIEWS'

const initialState = {
  isLoading: false,
  users: null,
  sendOnboarding: false,
  isLoadingSaveTeams: false,
  selectedUserDetail: 'loading',
  activity: 'loading',
  offset: 0,
  plans: 'loading',
  codes: [],
  codeReviews: 'loading',
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAN_MODAL_USER_DETAIL:
      return {
        ...state,
        isLoading: false,
        users: null,
        sendOnboarding: false,
        isLoadingSaveTeams: false,
        selectedUserDetail: 'loading',
        activity: 'loading',
        codeReviews: 'loading',
        offset: 0,
        plans: 'loading',
        codes: [],
      }
    case SET_CODE_REVIEWS:
      return {
        ...state,
        codeReviews: action.codeReviews,
      }
    case SET_HACKER_CODES:
      return {
        ...state,
        codes: action.codes,
      }
    case FETCH_PLANS:
      return {
        ...state,
        plans: 'loading',
      }
    case SET_PLANS:
      return {
        ...state,
        plans: action.plans,
      }
    case SET_HACKER_ACTIVITY:
      return {
        ...state,
        activity: action.activity,
        offset: state.offset,
      }
    case SET_HACKER_ACTIVITY_MORE:
      return {
        ...state,
        activity: [...state.activity, ...action.activity],
      }
    case FETCH_HACKER_ACTIVITY:
      return {
        ...state,
        activity: action.activity,
        offset: action.offset,
      }
    case FETCH_SELECTED_USER_DETAIL:
      return {
        ...state,
        selectedUserDetail: 'loading',
      }
    case SET_SELECTED_USER_DETAIL:
      return {
        ...state,
        selectedUserDetail: action.selectedUserDetail,
      }
    case SET_SEND_ONBOARDING:
      return {
        ...state,
        sendOnboarding: action.sendOnboarding,
      }
    case SET_USERS_TO_IMPORT:
      return {
        ...state,
        users: action.users,
      }
    case USER_IMPORT_LOADER:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case SET_LOADER_SAVE_TEAMS:
      return {
        ...state,
        isLoadingSaveTeams: action.isLoadingSaveTeams,
      }
    default:
      return state
  }
}

export const cleanUserDetailModal = () => ({
  type: CLEAN_MODAL_USER_DETAIL,
})

export const fetchSelectedUserDetail = (
  organizationUUID,
  user,
  disableLoader = false,
  toOverRide = {}
) => {
  return (dispatch) => {
    if (!disableLoader) {
      dispatch({
        type: FETCH_SELECTED_USER_DETAIL,
      })
    }
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/hackers/${user.uuid}`,
      withAuthToken: true,
      params: {
        team_uuid: user.justTeam || null,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: RECEIVED_SELECTED_USER_TEAMS,
          teams: data.user.teams,
          hacker: data.user,
        })
        dispatch({
          type: SET_SELECTED_USER_DETAIL,
          selectedUserDetail: {
            ...user,
            ...data.user,
            ...toOverRide,
          },
        })
      })
      .catch((error) => {})
  }
}

export function getHackerCodeSubmissions(organizationId, hackerId, contentId) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerId}/code?content_uuid=${contentId}`,
      withAuthToken: true,
    })
  }
}

export function fetchHackerTrainingPlans(
  organizationId,
  hackerId,
  disableLoader = false
) {
  return (dispatch) => {
    if (!disableLoader) {
      dispatch({
        type: FETCH_PLANS,
        plans: 'loading',
      })
    }
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerId}/plan`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_PLANS,
          plans: data[organizationId],
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function fetchHackerActivity(
  organizationId,
  hackerId,
  loaderType = 'main'
) {
  return (dispatch, getState) => {
    const oldState = getState()
    if (loaderType === 'main') {
      dispatch({
        type: FETCH_HACKER_ACTIVITY,
        offset: 0,
        activity: 'loading',
      })
    } else {
      dispatch({
        type: FETCH_HACKER_ACTIVITY,
        offset: oldState.users.offset + 15,
        activity: oldState.users.activity,
      })
    }
    const state = getState()
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerId}/activity`,
      params: {
        offset: state.users.offset,
      },
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type:
            loaderType === 'main'
              ? SET_HACKER_ACTIVITY
              : SET_HACKER_ACTIVITY_MORE,
          activity: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function fetchHackerCodeReviews(organizationUUID, hackerUUID) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/hackers/${hackerUUID}/code_review`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_CODE_REVIEWS,
          codeReviews: data,
        })
      })
      .catch((error) => {
        console.error({ error })
      })
  }
}

export function setSendOnboarding(sendOnboarding = false) {
  return (dispatch) => {
    dispatch({
      type: SET_SEND_ONBOARDING,
      sendOnboarding,
    })
  }
}

export function setUsersToImport(users = null) {
  return (dispatch) => {
    dispatch({
      type: SET_USERS_TO_IMPORT,
      users,
    })
  }
}

export function saveUserTeams(
  organizationId,
  teams,
  userUUID,
  successCallback,
  errorCallback
) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADER_SAVE_TEAMS,
      isLoadingSaveTeams: true,
    })
    return api({
      method: 'put',
      url: `${settings.urls.hacker}/organizations/${organizationId}/user/${userUUID}/teams`,
      withAuthToken: true,
      data: {
        teams,
      },
    })
      .then(() => {
        if (successCallback) {
          dispatch({
            type: UPDATE_TEAM_USER,
            hackerUUID: userUUID,
            teams: Object.keys(teams).reduce((prev, teamId) => {
              prev.push({ uuid: teamId, name: teams[teamId].team_name })
              return prev
            }, []),
          })
          successCallback()
          setTimeout(() => {
            dispatch({
              type: SET_LOADER_SAVE_TEAMS,
              isLoadingSaveTeams: false,
            })
          }, 250)
        }
      })
      .catch((error) => {
        console.error(error)
        if (errorCallback) {
          dispatch({
            type: SET_LOADER_SAVE_TEAMS,
            isLoadingSaveTeams: false,
          })
          errorCallback(
            'An unexpected error occurred. Please reload the page and try again.'
          )
        }
      })
  }
}

export function importUsers(organizationID, sendOnboarding, users, callback) {
  return (dispatch) => {
    dispatch({
      type: USER_IMPORT_LOADER,
      isLoading: true,
    })
    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationID}/users`,
      withAuthToken: true,
      data: {
        send_onboarding: sendOnboarding,
        users,
      },
    })
      .then(({ data }) => {
        callback(data)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
