import React from 'react'
import UIkit from 'uikit'
import { withRouter } from 'react-router-dom'
import Flatpickr from 'react-flatpickr'
import * as moment from 'moment'

// Components
import PhaseCheckbox from 'app/views/components/PhaseCheckbox'
import Icon from 'app/views/components/Icon'
import GenericModal from 'app/views/components/Modals/GenericModal'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'
import PrimaryRadio from 'app/views/components/Inputs/PrimaryRadio'
class RecommendationPlan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      trainingTerm: '',
      maxContent: '',
      gracePeriod: '',
      vulnerabilityRefreshDays: '',
      contentType: 'lessons',
      contentTimingEditing: null,
      timingType: null,
      editingRequiredTraining: false,
      loadingRun: false,
      expirationDate: '',
      daysToComplete: '',
      error: '',
      selectedContentIds: [],
      selectedContentNames: [],
    }
    this.onSaveRecommendation = this.onSaveRecommendation.bind(this)
    this.onSaveTiming = this.onSaveTiming.bind(this)
    this.modalRequiredTraining = React.createRef()
    this.modalTiming = React.createRef()
    this.rerunModal = React.createRef()
    this.modalSettings = React.createRef()
    this.setSelectedContentIds = this.setSelectedContentIds.bind(this)
    this.setSelectedContentNames = this.setSelectedContentNames.bind(this)
    this.handleSubmitSave = this.handleSubmitSave.bind(this)
  }

  setSelectedContentIds(contentIds) {
    this.setState({
      selectedContentIds: contentIds,
    })
  }

  setSelectedContentNames(contentNames) {
    this.setState({
      selectedContentNames: contentNames,
    })
  }

  componentDidMount() {
    const adaptiveSettings = this.props.isOrganizationDefaultAdaptivePlan
      ? this.props.currentTeam.organization_settings
      : this.props.currentTeam.settings || this.props.settings

    this.setState({
      maxContent: (this.props.settings || {}).max_content || '',
      gracePeriod: (this.props.settings || {}).grace_period || '',
      trainingTerm: (this.props.settings || {}).training_term || '',
      vulnerabilityRefreshDays:
        (this.props.settings || {}).vulnerability_refresh_days || '',
      selectedContentIds: adaptiveSettings.required_training.map((x) => x.uuid),
      selectedContentNames: adaptiveSettings.required_training.map(
        (x) => x.title
      ),
    })
  }

  handleSubmitSave() {
    this.props.updateRequiredContentRecommendationSettings(
      this.state.selectedContentIds,
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid,
      () => {
        this.setState(
          {
            editingRequiredTraining: false,
          },
          () => {
            this.props.onRefresh()
          }
        )
      }
    )
  }

  onSaveRecommendation() {
    this.props.updateRecommendationEngineSettings(
      {
        training_term: this.state.trainingTerm,
        max_content: parseInt(this.state.maxContent, 10),
        grace_period: parseInt(this.state.gracePeriod, 10),
        vulnerability_refresh_days: parseInt(
          this.state.vulnerabilityRefreshDays,
          10
        ),
      },
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid,
      () => {
        this.modalSettings.current.hide()
        this.props.onRefresh()
      }
    )
  }

  onSaveTiming() {
    const daysToComplete = parseInt(this.state.daysToComplete, 10)
    if (
      this.state.timingType === 'days' &&
      (isNaN(daysToComplete) || daysToComplete < 1)
    ) {
      return this.setState({
        error: 'Days to complete value must be a number bigger than 0',
      })
    }
    if (
      this.state.timingType === 'date' &&
      (!moment(this.state.expirationDate).isValid() ||
        moment(this.state.expirationDate).isBefore(moment()))
    ) {
      return this.setState({
        error: 'You must enter a valid date',
      })
    }
    this.modalTiming.current.hide()
    this.setState({ error: '' })
    this.props.updateRequiredTrainingContent(
      this.props.match.params.organization_uuid,
      this.state.contentTimingEditing.uuid,
      this.props.match.params.team_uuid,
      !this.state.timingType ? true : undefined,
      this.state.timingType === 'days' ? this.state.daysToComplete : undefined,
      this.state.timingType === 'date' ? this.state.expirationDate : undefined,
      this.props.onRefresh
    )
  }

  renderTerm(maxContent, term) {
    switch (term) {
      case 'biweekly':
        return `${maxContent} lesson${
          maxContent === 0 || maxContent > 1 ? 's' : ''
        } every 2 weeks`
      case 'quarterly':
        return `${maxContent} lesson${
          maxContent === 0 || maxContent > 1 ? 's' : ''
        } every quarter`
      default:
        return `${maxContent} lesson${
          maxContent === 0 || maxContent > 1 ? 's' : ''
        } every month`
    }
  }

  render() {
    const adaptiveSettings = this.props.isOrganizationDefaultAdaptivePlan
      ? this.props.currentTeam.organization_settings
      : this.props.currentTeam.settings || this.props.settings
    return (
      <div id='recommendation-plan-container'>
        <h2 className='flex items-center flex-row justify-between uk-width-1-1'>
          {this.props.isOrganization ||
          this.props.isOrganizationDefaultAdaptivePlan
            ? 'Organization Default Adaptive Training Plan Settings'
            : 'Using Adaptive Training Plan'}
          <div className='inline-flex'>
            {!this.props.isOrganizationDefaultAdaptivePlan && (
              <PrimaryButton
                onClick={() => {
                  this.rerunModal.current.show()
                  this.setState({ loadingRun: true }, () => {
                    this.props.activateAutomatedPlan(
                      this.props.match.params.organization_uuid,
                      this.props.match.params.team_uuid,
                      () =>
                        this.setState({ loadingRun: false }, () => {
                          this.props.onRefresh()
                        })
                    )
                  })
                }}
                size='large'
                customCss={'mr-2'}
                label={
                  this.state.loadingRun
                    ? 'Running Recommendations...'
                    : 'Re-Run Recommendations'
                }
              />
            )}
            {typeof this.props.edit === 'function' && (
              <PrimaryButton
                onClick={() => this.props.edit()}
                size='small'
                label='Change Plan'
              />
            )}
          </div>
        </h2>
        <h4 className='uk-width-1-1 flex justify-between items-center'>
          Settings
          {!this.props.isOrganizationDefaultAdaptivePlan && (
            <PrimaryButton
              size='small'
              onClick={() => {
                this.modalSettings.current.show()
              }}
              label='Edit Settings'
            />
          )}
        </h4>
        <p className='uk-margin-remove'>
          Max Training Frequency:{' '}
          <b>
            {this.renderTerm(
              adaptiveSettings.max_content,
              adaptiveSettings.training_term
            )}
          </b>
        </p>
        <p className='uk-margin-remove'>
          Grace Period : <b>{adaptiveSettings.grace_period} days</b>
          <Icon
            className='uk-margin-small-left'
            name='information'
            ratio={0.8}
            onClick={() => {
              UIkit.modal.alert(
                `
              <h2>Grace Period</h2><p>The grace period is the number of days after a user gets onboarded before their training can be considered "past due."  This way, if you are using a "Monthly" training cycle and a user is onboarded right before the end of the month, they won't immediately be considered behind.</p>
              `,
                { stack: true }
              )
            }}
          />
        </p>
        <p className='uk-margin-remove'>
          Vulnerability Refresh Time:{' '}
          <b>{adaptiveSettings.vulnerability_refresh_days} days</b>
          <Icon
            className='uk-margin-small-left'
            name='information'
            ratio={0.8}
            onClick={() => {
              UIkit.modal.alert(
                `
              <h2>Vulnerability Refresh Time</h2><p style="text-align: justify">If a new vulnerability is found through one of your integrations, the vulnerability refresh time is the number of days that need to have passed before a user is required to do another lesson covering that vulnerability.</p>
              <p style="text-align: justify">For example, if a user does a SQL Injection lesson on January 1, and then a new vulnerability is found in your SAST/DAST tool on January 15, the user will only be assigned another SQL Injection lesson if your "Vulnerability Refresh Time" is less than 15 days.</p>
              <p style="text-align: justify">We typically recommend a 45 day period.</p>
              `,
                { stack: true }
              )
            }}
          />
        </p>
        <h4>Organization Integrations</h4>
        <div>
          {(this.props.currentTeam.organization_integrations || []).length ===
            0 && <p>No integrations linked to this organization</p>}
          {(this.props.currentTeam.organization_integrations || []).map(
            (integration, i) => (
              <label
                className='uk-label uk-label-primary uk-margin-small-right bg-green'
                key={`${integration.uuid}org${i}`}
                onClick={() => {
                  this.props.history.push(
                    `/admin/${this.props.match.params.organization_uuid}/integrations/${integration.uuid}`
                  )
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                {integration.title}
              </label>
            )
          )}
        </div>
        {!this.props.isOrganization && (
          <>
            <h4>Team Integrations</h4>
            <div>
              {(this.props.currentTeam.team_integrations || []).length ===
                0 && <p>No integrations linked to this team</p>}
              {(this.props.currentTeam.team_integrations || []).map(
                (integration) => (
                  <label
                    onClick={() => {
                      this.props.history.push(
                        `/admin/${this.props.match.params.organization_uuid}/integrations/${integration.uuid}`
                      )
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                    className='uk-label uk-label-primary uk-margin-small-right'
                    key={`${integration.uuid}team`}
                  >
                    {integration.title}
                  </label>
                )
              )}
            </div>
          </>
        )}
        <h4 className='uk-width1-1 flex items-center justify-between'>
          Required Training
          {!this.props.isOrganizationDefaultAdaptivePlan && (
            <>
              {!this.state.editingRequiredTraining ? (
                <PrimaryButton
                  onClick={() => {
                    this.setState({
                      editingRequiredTraining: true,
                    })
                  }}
                  label='Edit'
                  size='small'
                />
              ) : (
                <PrimaryButton
                  size='small'
                  onClick={this.handleSubmitSave}
                  label='Save'
                />
              )}
            </>
          )}
        </h4>
        {this.state.editingRequiredTraining ? (
          <>
            <div className='uk-margin'>
              <div className='uk-form-controls'>
                <div>
                  <>
                    <div className='uk-card uk-card-default uk-card-body'>
                      <div className='uk-container-medium'>
                        <form className='uk-form-stacked'>
                          <div className='uk-margin'>
                            <div className='uk-form-controls uk-form-controls-text'>
                              <div className='uk-form-label'>
                                Choose content
                                <Icon
                                  name='information'
                                  ratio={0.85}
                                  uk-tooltip={
                                    'title: A course lesson takes about 15 – 20 minutes to complete. Challenges may take 30 minutes or more.; pos: top'
                                  }
                                  style={{
                                    marginLeft: 8,
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                              <label className='text-sm'>
                                <input
                                  className='uk-radio uk-margin-small-right'
                                  type='radio'
                                  onChange={() =>
                                    this.setState({
                                      contentType: 'lessons',
                                    })
                                  }
                                  name='lessons'
                                  checked={this.state.contentType === 'lessons'}
                                />
                                Lessons
                              </label>
                              <br />
                              <label className='text-sm'>
                                <input
                                  onChange={() =>
                                    this.setState({
                                      contentType: 'coding_challenges',
                                    })
                                  }
                                  checked={
                                    this.state.contentType ===
                                    'coding_challenges'
                                  }
                                  className='uk-radio uk-margin-small-right'
                                  type='radio'
                                  name='coding_challenges'
                                />
                                Coding Challenges
                              </label>
                              <>
                                <br />
                                <label className='text-sm'>
                                  <input
                                    onChange={() =>
                                      this.setState({
                                        contentType: 'hacking_challenges',
                                      })
                                    }
                                    checked={
                                      this.state.contentType ===
                                      'hacking_challenges'
                                    }
                                    className='uk-radio uk-margin-small-right'
                                    type='radio'
                                    name='hacking_challenges'
                                  />
                                  Hacking Challenges
                                </label>
                              </>
                            </div>
                          </div>
                          <div className='uk-margin'>
                            <div className='uk-form-controls'>
                              <div className='uk-form-label'>
                                {
                                  {
                                    lessons: 'Lessons',
                                    coding_challenges: 'Coding challenges',
                                    hacking_challenges: 'Hacking challenges',
                                  }[this.state.contentType]
                                }
                              </div>
                              <div className='uk-container-medium checkbox-container'>
                                <PhaseCheckbox
                                  selectedContentIds={
                                    this.state.selectedContentIds
                                  }
                                  selectedContentNames={
                                    this.state.selectedContentNames
                                  }
                                  onSelectedContentChange={
                                    this.setSelectedContentIds
                                  }
                                  getSelectedContentNames={
                                    this.setSelectedContentNames
                                  }
                                  contentType={this.state.contentType}
                                  content={this.props.content}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {adaptiveSettings.required_training.length === 0 ? (
              <p>No required training</p>
            ) : (
              <table className='uk-table uk-table-divider uk-table middle uk-table-small text-sm'>
                <thead>
                  <tr>
                    <th>Content</th>
                    <th className='text-center'>Timing</th>
                    <th>Decay</th>
                  </tr>
                </thead>
                <tbody>
                  {adaptiveSettings.required_training.map((content) => (
                    <tr key={content.uuid}>
                      <td>{content.title}</td>
                      <td className='flex justify-center uk-middle'>
                        {content.days_to_complete && (
                          <a
                            onClick={() =>
                              this.setState(
                                {
                                  contentTimingEditing: content,
                                },
                                () => {
                                  this.modalTiming.current.show()
                                }
                              )
                            }
                          >
                            {`${content.days_to_complete} day${
                              content.days_to_complete > 1 ? 's' : ''
                            } to complete after assigned.`}
                          </a>
                        )}
                        {content.complete_by && (
                          <a
                            onClick={() =>
                              this.setState(
                                {
                                  contentTimingEditing: content,
                                },
                                () => {
                                  this.modalTiming.current.show()
                                }
                              )
                            }
                          >
                            {`Complete before ${moment(
                              content.complete_by
                            ).format('MMMM Do, YYYY')}`}
                          </a>
                        )}
                        {!content.complete_by && !content.days_to_complete && (
                          <button
                            className='uk-button uk-button-default uk-button-small inline-flex items-center text-sm'
                            type='button'
                            onClick={() => {
                              this.setState(
                                {
                                  contentTimingEditing: content,
                                },
                                () => {
                                  this.modalTiming.current.show()
                                }
                              )
                            }}
                          >
                            <Icon
                              ratio={0.8}
                              name='calendar-event'
                              className='uk-margin-small-right'
                            />
                            Set timing
                          </button>
                        )}
                      </td>
                      <td>{content.max_age} days</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
        <GenericModal
          options={{
            container: 'recommendation-plan-container',
          }}
          buttons={
            <PrimaryButton
              onClick={this.onSaveTiming}
              size='small'
              label='Save'
            />
          }
          id='modal-edit-timing-my-team-plans'
          ref={this.modalTiming}
          title='Edit Timing'
          body={
            <>
              <div className='uk-margin-auto'>
                <h5 className='uk-margin-remove font-bold'>
                  {(this.state.contentTimingEditing || {}).title}
                </h5>
                <p>
                  <small>
                    Set the max date or the numbers of days that users will have
                    to complete this content. All content with timing field will
                    be assigned to your users after the next run of the
                    recommendations.
                  </small>
                </p>
                <div className='uk-form-controls uk-form-controls-text'>
                  <PrimaryRadio
                    onChange={() =>
                      this.setState({
                        timingType: null,
                        error: '',
                      })
                    }
                    checked={!this.state.timingType}
                    name='timing_radio_group'
                    label='No time limit'
                  />
                  <br />
                  <PrimaryRadio
                    onChange={() =>
                      this.setState({
                        timingType: 'date',
                        error: '',
                      })
                    }
                    label='Complete by date'
                    name='timing_radio_date'
                    checked={this.state.timingType === 'date'}
                  />
                  <br />
                  <PrimaryRadio
                    onChange={() =>
                      this.setState({
                        timingType: 'days',
                        error: '',
                      })
                    }
                    checked={this.state.timingType === 'days'}
                    label='Days to complete'
                    name='timing_radio_days'
                  />

                  <br />
                  {this.state.timingType === 'date' && (
                    <div
                      className='uk-width-1-1 flex items-center uk-input uk-margin-small-top uk-padding-remove-left uk-padding-remove-right'
                      style={{ position: 'relative' }}
                    >
                      <Flatpickr
                        options={{ minDate: moment().format('YYYY-MM-DD') }}
                        className='uk-input'
                        placeholder='YYYY-MM-DD'
                        value={this.state.expirationDate}
                        onChange={(expirationDate) => {
                          this.setState({
                            expirationDate: expirationDate[0],
                          })
                        }}
                      />
                      {this.state.expirationDate !== '' && (
                        <Icon
                          onClick={() => this.setState({ expirationDate: '' })}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: 5,
                          }}
                          name='close'
                        />
                      )}
                    </div>
                  )}
                  {this.state.timingType === 'days' && (
                    <input
                      onChange={(e) => {
                        this.setState({
                          daysToComplete: e.target.value.replace(/\D+/g, ''),
                        })
                      }}
                      className='uk-input uk-margin-small-top'
                      type='text'
                      value={this.state.daysToComplete}
                    />
                  )}
                  {this.state.error && (
                    <p className='text-danger uk-margin-remove'>
                      <small>{this.state.error}</small>
                    </p>
                  )}
                </div>
              </div>
            </>
          }
        />

        <GenericModal
          options={{
            container: 'recommendation-plan-container',
          }}
          noButtons
          closeOnClickOverlay={!this.state.loadingRun}
          ref={this.rerunModal}
          id='re-running-recommendations-modal'
          body={
            <div className='text-center uk-padding uk-padding-remove-top'>
              {this.state.loadingRun ? (
                <>
                  <p className='text-emphasis text-2xl leading-8 text-secondary uk-magin-remove'>
                    <b>Re-Running Recommendations</b>
                  </p>
                  <span data-uk-spinner='' />
                </>
              ) : (
                <>
                  <Icon
                    className='text-success'
                    fill
                    name='checkbox-circle'
                    ratio={2}
                  />
                  <br />
                  <span className='text-2xl leading-8 text-secondary'>
                    Success!
                  </span>
                  <p className='text-emphasis uk-padding uk-padding-remove-vertical'>
                    New content assignments have been made based on the latest
                    data.
                  </p>
                  <button
                    type='button'
                    className='uk-button uk-button-primary uk-button-small'
                    onClick={() => {
                      this.rerunModal.current.hide()
                    }}
                  >
                    Continue
                  </button>
                </>
              )}
            </div>
          }
        />

        <GenericModal
          options={{
            container: 'recommendation-plan-container',
          }}
          buttons={
            <PrimaryButton
              onClick={this.onSaveRecommendation}
              size='small'
              label='Save'
            />
          }
          ref={this.modalSettings}
          id='edit-settings-recommendaion-system-team'
          title='Edit Recommendation Engine Settings'
          body={
            <>
              <div className='uk-margin'>
                <label
                  className='uk-form-label text-right'
                  htmlFor='form-horizontal-text'
                >
                  Training Term
                </label>
                <div data-uk-grid>
                  <div className='uk-width-1-1'>
                    <select
                      className='uk-select'
                      value={this.state.trainingTerm}
                      onChange={(e) => {
                        this.setState({
                          trainingTerm: e.target.value,
                        })
                      }}
                    >
                      <option value='biweekly'>Bi-Weekly</option>
                      <option value='monthly'>Monthly</option>
                      <option value='quarterly'>Quarterly</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* END OF INPUT */}
              <div className='uk-margin'>
                <label
                  className='uk-form-label text-right'
                  htmlFor='form-horizontal-text'
                >
                  Max Content
                </label>
                <div data-uk-grid>
                  <div className='uk-width-1-1'>
                    <input
                      onChange={(e) => {
                        this.setState({
                          maxContent: e.target.value,
                        })
                      }}
                      className='uk-input'
                      type='text'
                      value={this.state.maxContent}
                    />
                  </div>
                </div>
              </div>
              {/* END OF INPUT */}
              <div className='uk-margin'>
                <label
                  className='uk-form-label text-right'
                  htmlFor='form-horizontal-text'
                >
                  Grace Period (days)
                </label>
                <div data-uk-grid>
                  <div className='uk-width-1-1'>
                    <input
                      onChange={(e) => {
                        this.setState({
                          gracePeriod: e.target.value,
                        })
                      }}
                      className='uk-input'
                      type='text'
                      value={this.state.gracePeriod}
                    />
                  </div>
                </div>
              </div>
              {/* END OF INPUT */}
              <div className='uk-margin'>
                <label
                  className='uk-form-label text-right'
                  htmlFor='form-horizontal-text'
                >
                  Vulnerability Refresh (Days)
                </label>
                <div data-uk-grid>
                  <div className='uk-width-1-1'>
                    <input
                      className='uk-input'
                      type='text'
                      onChange={(e) => {
                        this.setState({
                          vulnerabilityRefreshDays: e.target.value,
                        })
                      }}
                      value={this.state.vulnerabilityRefreshDays}
                    />
                  </div>
                </div>
              </div>
              {/* END OF INPUT */}
            </>
          }
        />
      </div>
    )
  }
}

export default withRouter(RecommendationPlan)
