import store from './store'
import { getTokenString } from './modules/auth'

/**
 * Global function that will eventually resolve to a valid JWT that can be supplied in
 * API requests, potentially initiating a token refresh if the existing token is
 * expired. The user must be logged in via the auth module. May throw an error if the
 * user is not yet authenticated, or if a refresh is initiated and fails.
 */
export function resolveToken(): Promise<string> {
  return getTokenString(store.getState(), store.dispatch)
}
