import React from 'react'

// Components
import Modal from 'app/views/components/UIkit/Modal'

const ModalCreatedAutomaticallyViaSSO = () => (
  <Modal
    id='modal-info-created-automatically-via-sso'
    title='Created Automatically via SSO'
    body={
      <div>
        <p>
          Teams can be automatically managed through your Single Sign On
          solution by mapping an attribute in your SSO provider to a HackEDU
          Team. When you do this, teams will be automatically created when we
          see users with the appropriate attributes.
        </p>
        <p>
          We don't allow teams that have been created automatically via SSO
          because these teams would just automatically be created again when
          another user is seen from SSO that belongs to that team.
        </p>
        <p>
          <a
            href='https://help.securityjourney.com/en/articles/3117540-automatically-assign-users-to-teams-with-sso'
            target='_blank'
            rel='noopener noreferrer'
          >
            Learn more about managing teams with SSO
          </a>
        </p>
      </div>
    }
  />
)

export default ModalCreatedAutomaticallyViaSSO
