import React from 'react'
import PropTypes from 'prop-types'

import ContentModal from '../../ContentModal'

const ButtonRow = ({ children, style }) => (
  <div style={{ display: 'flex', flexDirection: 'row', ...style }}>
    {children}
  </div>
)
const ButtonCell = ({ children, style }) => (
  <div style={{ flexGrow: 1, flexShrink: 0, flexBasis: 0, ...style }}>
    {children}
  </div>
)
const Button = ({ kind, label, onClick, margin }) => (
  <button
    className={`uk-button uk-button-small uk-button-${kind} close-modified-file-modal-button`}
    onClick={onClick}
    style={{
      borderRadius: 10,
      whiteSpace: 'nowrap',
      width: '100%',
      margin,
    }}
  >
    {label}
  </button>
)

function CloseModifiedFileModal(props) {
  const { fileName, onAcceptChanges, onDiscardChanges, onCancel } = props
  return (
    <ContentModal
      id='close-modified-file-modal'
      width='fit-content'
      height='auto'
      onClose={onCancel}
    >
      <div className='close-modified-file-modal-contents'>
        <p>
          Save <code>{fileName}</code> before closing?
        </p>
        <ButtonRow style={{ marginBottom: 8 }}>
          <ButtonCell style={{ marginRight: 2 }}>
            <Button
              label='Save Changes'
              kind='primary'
              onClick={onAcceptChanges}
            />
          </ButtonCell>
          <ButtonCell style={{ marginLeft: 2 }}>
            <Button
              label='Discard Changes'
              kind='primary'
              onClick={onDiscardChanges}
            />
          </ButtonCell>
        </ButtonRow>
        <ButtonCell>
          <Button label='Cancel' kind='secondary' onClick={onCancel} />
        </ButtonCell>
      </div>
    </ContentModal>
  )
}
CloseModifiedFileModal.propTypes = {
  fileName: PropTypes.string.isRequired,
  onAcceptChanges: PropTypes.func.isRequired,
  onDiscardChanges: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CloseModifiedFileModal
