import React from 'react'
import PropTypes from 'prop-types'

import ContentModal from '../../ContentModal'

const TRIAL_MODAL_TEXT =
  'We\'re sorry, to protect against malicious behavior we don\'t allow code submissions in this trial lesson. Click below to gain acces to an authenticated trail.'
const UPGRADE_TRIAL_LINK =
  'https://www.securityjourney.com/coding-training-solutions/custom-demo-form?hsCtaTracking=aa7e1cb3-ff1c-4658-9a2b-8f80df209841%7C720e6cca-e29a-4696-bea2-f17cbcf356a6'

export function UpgradeTrialModal({ toggleTrialModal }) {
  const openUpgradeTrialLink = () => {
    window.open(UPGRADE_TRIAL_LINK, '_blank')
  }

  return (
    <ContentModal
      id='trial-upgrade-modal'
      onClose={() => toggleTrialModal('code', false)}
      height='250px'
      width='500px'
    >
      <div
        style={{
          padding: 40,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        <p>{TRIAL_MODAL_TEXT}</p>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}
        >
          <button
            className='uk-button uk-button-link'
            onClick={() => toggleTrialModal('code', false)}
          >
            No Thanks
          </button>
          <button
            className='uk-button uk-button-primary uk-margin-medium-left'
            onClick={() => openUpgradeTrialLink()}
          >
            Access Trial
          </button>
        </div>
      </div>
    </ContentModal>
  )
}
UpgradeTrialModal.propTypes = {
  toggleTrialModal: PropTypes.func.isRequired,
}
