import React from 'react'
import PropTypes from 'prop-types'

import { useCreatePortal } from './util'
import MonthRangePicker from './MonthRangePicker'

const ModeButton = ({ label, isSelected, onClick }) => (
  <button
    className={`uk-button uk-button-small${
      isSelected ? ' uk-button-primary' : ''
    }`}
    onClick={onClick}
  >
    {label}
  </button>
)

function MonthRangePopover(props) {
  const {
    parentElem,
    onDismiss,
    mode,
    onModeChange,
    startMonth,
    endMonth,
    onDateRangeChange,
  } = props
  const createPortal = useCreatePortal(
    parentElem,
    280,
    onDismiss,
    'impact-report-month-range-popover'
  )
  return createPortal(
    <div>
      <MonthRangePicker
        numYears={2}
        startMonth={startMonth}
        endMonth={endMonth}
        onDateRangeChange={onDateRangeChange}
      />
      <div className='impact-report-month-range-popover-button-group'>
        <ModeButton
          label='All Time'
          isSelected={mode === 'all-time'}
          onClick={() => onModeChange('all-time')}
        />
        <ModeButton
          label='1 Year'
          isSelected={mode === 'one-year'}
          onClick={() => onModeChange('one-year')}
        />
      </div>
    </div>
  )
}
MonthRangePopover.propTypes = {
  parentElem: PropTypes.any,
  onDismiss: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['all-time', 'one-year', 'custom']).isRequired,
  onModeChange: PropTypes.func.isRequired,
  startMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
  }),
  endMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
  }),
  onDateRangeChange: PropTypes.func.isRequired,
}

export default MonthRangePopover
