import React, { useEffect } from 'react'
import UIkit from 'uikit'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// components
import Modal from '../../UIkit/Modal'

// Redux
import { checkUsername, updateUsername } from 'app/state/modules/hacker'

class ModalSetUsername extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: '',
      available: null,
      username: '',
      success: null,
    }

    this.handleChangeUsername = this.handleChangeUsername.bind(this)
    this.handleCheckUsername = this.handleCheckUsername.bind(this)
    this.handleUpdateUsername = this.handleUpdateUsername.bind(this)
  }

  componentDidMount() {
    UIkit.util.on('#modal-set-username', 'show', () => {
      this.setState({
        error: '',
        available: null,
        username: '',
        success: null,
      })
    })
  }
  handleChangeUsername(event) {
    if (event.target.value === 'please-produce-a-runtime-error') {
      console.warn('producing an intentional error')
      useEffect(useEffect)
    }
    this.setState({
      username: event.target.value,
      available: null,
      error: '',
      success: null,
    })
  }

  handleCheckUsername() {
    if (this.state.username === '') {
      this.setState({
        error: 'Whoops! You haven\'t entered a username to check...',
      })
    } else {
      this.props.checkUsername(this.state.username).then((result) => {
        this.setState({ available: result })
      })
    }
  }

  handleUpdateUsername() {
    this.props
      .updateUsername(this.state.username)
      .then((result) => {
        if (result === true) {
          this.setState({ success: true })
        } else {
          this.setState({
            error: 'Whoops! There was an error updating your username...',
          })
        }
      })
      .catch(() => {
        this.setState({
          error: 'Whoops! There was an error updating your username...',
        })
      })
  }

  render() {
    return (
      <Modal
        id='modal-set-username'
        titleTestId='title-modal-set-username'
        title='Set Username'
        data-test-id='modal-set-username'
        body={
          <div className='text-center'>
            {this.state.success === true ? (
              <div>
                <p>You have successfully updated your username!</p>
                <div className='uk-margin'>
                  <button
                    data-test-id='confirm-btn'
                    className='uk-button uk-button-primary tm-button-primary uk-modal-close'
                    type='button'
                  >
                    Ok
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p>
                  Usernames are used for the public{' '}
                  <Link
                    className='text-green hover:text-green'
                    to='/leaderboard'
                  >
                    Leaderboard
                  </Link>
                  .
                </p>
                <div className='uk-margin text-left'>
                  <label className='uk-form-label'>Username</label>

                  <div>
                    <div data-uk-form-custom style={{ width: 340 }}>
                      <input
                        className='uk-input'
                        type='text'
                        data-test-id='input-username'
                        placeholder=''
                        value={this.state.username}
                        onChange={this.handleChangeUsername}
                      />
                    </div>
                    {this.props.checkingUsername ? (
                      <div
                        className='uk-padding uk-padding-remove-vertical'
                        data-uk-spinner=''
                      />
                    ) : (
                      <button
                        data-test-id='check-availability-btn'
                        className='uk-button uk-button-default'
                        onClick={this.handleCheckUsername}
                      >
                        Check Availability
                      </button>
                    )}
                  </div>
                </div>

                {this.state.available === true && (
                  <div className='uk-margin text-left'>
                    <p className='text-success'>
                      {this.state.username} is available!
                    </p>
                  </div>
                )}

                {this.state.available === false && (
                  <div className='uk-margin text-left'>
                    <p className='text-danger'>
                      {this.state.username} is not available...
                    </p>
                  </div>
                )}
                {this.state.error !== '' && (
                  <div className='uk-margin text-left'>
                    <p className='text-danger'>{this.state.error}</p>
                  </div>
                )}

                <div className='uk-margin'>
                  <button
                    className='uk-button uk-button-muted uk-modal-close'
                    type='button'
                  >
                    Cancel
                  </button>
                  {this.props.updatingUsername ? (
                    <div data-uk-spinner='' />
                  ) : (
                    <button
                      className='uk-button uk-button-primary tm-button-primary'
                      style={{
                        padding: '0 30px',
                      }}
                      onClick={this.handleUpdateUsername}
                      disabled={!this.state.available}
                      data-test-id='confirm-btn'
                    >
                      Set Username
                    </button>
                  )}
                </div>
              </div>
            )}
            <button
              className='uk-modal-close-outside'
              type='button'
              data-uk-close
            />
          </div>
        }
      />
    )
  }
}

const mapStateToProps = (state) => ({
  // hacker
  checkingUsername: state.hacker.checkingUsername,
  updatingUsername: state.hacker.updatingUsername,
})
const mapDispatchToProps = (dispatch) => ({
  checkUsername: (username) => dispatch(checkUsername(username)),
  updateUsername: (username) => dispatch(updateUsername(username)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ModalSetUsername)
