import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import PrimaryButton from '../../../components/Buttons/PrimaryButton'

import StepDotnav from '../../common/StepDotnav'
import StepNavigationButtons from '../../common/StepNavigationButtons'
import { useCachedStepIndex } from '../../common/util'

import LessonHintModal from './LessonHintModal'
import LessonCompletionStatus from './LessonCompletionStatus'

import { connect } from 'react-redux'
import * as progress from 'app/state/modules/progress'

/**
 * Displays the step-by-step text of the lesson, an optional Product Tour button, and a button to
 * view hints, if available for any given step.
 */
function LessonInstructions(props) {
  const {
    contentId,
    hasTests,
    contentCompletedAt,
    contentPassedAt,
    title,
    steps,
  } = props
  const {
    isReadyToFinish,
    shouldOfferProductTour,
    onProductTourRequested,
    onAttemptCompleteLesson,
  } = props

  const [stepIndex, setStepIndex] = useCachedStepIndex(contentId, steps.length)
  const canNavigateBack = steps.length > 0 && stepIndex > 0
  const canNavigateForward = steps.length > 0 && stepIndex < steps.length - 1
  const step = steps[stepIndex]

  const hints = step.hints.sort((a, b) => a.sort_order - b.sort_order)
  const [wantsHint, setWantsHint] = useState(false)
  const [selectedHintIndex, setSelectedHintIndex] = useState(-1)
  const onShowHintClick = () => {
    setSelectedHintIndex(hints.length === 1 ? 0 : -1)
    setWantsHint(true)
  }

  const { scrollToTop, trackProgress } = props
  useEffect(() => {
    scrollToTop()
    trackProgress(stepIndex)
  }, [stepIndex])

  return (
    <>
      <StepDotnav
        vertical
        currentStepIndex={stepIndex}
        numSteps={steps.length}
        onSelectStep={setStepIndex}
      />
      {wantsHint && (
        <LessonHintModal
          hints={hints}
          selectedHintIndex={selectedHintIndex}
          onSelectHint={setSelectedHintIndex}
          onClose={() => setWantsHint(false)}
        />
      )}

      {stepIndex === 0 && shouldOfferProductTour && (
        <>
          <PrimaryButton
            onClick={onProductTourRequested}
            label='Product Tour'
            size='full'
          />
        </>
      )}
      <div style={{ paddingLeft: 10, marginTop: 22 }}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} linkTarget='_blank'>
          {step.markdown}
        </ReactMarkdown>
      </div>
      {hints.length > 0 && (
        <>
          <PrimaryButton
            onClick={onShowHintClick}
            label={`Show ${hints.length > 1 ? 'Hints' : 'Hint'}`}
            size='small'
            customCss={'text-black bg-light_gray'}
          />
        </>
      )}
      {!canNavigateForward && (
        <LessonCompletionStatus
          hasTests={hasTests}
          completedAt={contentCompletedAt}
          passedAt={contentPassedAt}
        />
      )}
      <StepNavigationButtons
        canNavigateBack={canNavigateBack}
        canNavigateForward={canNavigateForward}
        canEverFinish={true}
        isReadyToFinish={isReadyToFinish}
        onNavigateBack={() => setStepIndex((step) => step - 1)}
        onNavigateForward={() => setStepIndex((step) => step + 1)}
        onAttemptFinish={onAttemptCompleteLesson}
      />
    </>
  )
}
LessonInstructions.propTypes = {
  contentId: PropTypes.string.isRequired,
  hasTests: PropTypes.bool.isRequired,
  contentCompletedAt: PropTypes.string,
  contentPassedAt: PropTypes.string,
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  shouldOfferProductTour: PropTypes.bool.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  onProductTourRequested: PropTypes.func.isRequired,
  onAttemptCompleteLesson: PropTypes.func.isRequired,
  isReadyToFinish: PropTypes.bool.isRequired,
  trackProgress: PropTypes.func.isRequired,
}

export default connect(
  (state, ownProps) => ({
    isReadyToFinish:
      !ownProps.hasTests ||
      !state.hacker.mustPassCodingExercise ||
      state.hacker.currentCodeSubmissions.some((x) => x.test.passed),
  }),
  (dispatch) => ({
    trackProgress: (stepIndex) => dispatch(progress.track(stepIndex)),
  })
)(LessonInstructions)
