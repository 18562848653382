import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'app/views/components/GustavoIcon'

function HintSelectionListItem(props) {
  const { title, onClick } = props
  return (
    <div className='lesson-hint-selection-list-item' onClick={onClick}>
      <Icon name='question' />
      <span>{title}</span>
      <div style={{ flex: '1' }} />
      <Icon name='arrow-right-s' />
    </div>
  )
}
HintSelectionListItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default HintSelectionListItem
