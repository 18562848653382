import React from 'react'

class ConfirmButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstClick: false,
      width: null,
    }
    this.onClick = this.onClick.bind(this)
    this.renderClassName = this.renderClassName.bind(this)
    this.onReset = this.onReset.bind(this)
    this.clearTimeouts = this.clearTimeouts.bind(this)
    this.progressRef = React.createRef()
  }

  componentWillUnmount() {
    this.onReset()
    this.clearTimeouts()
  }

  onReset() {
    this.setState({ firstClick: false }, () => {
      this.progressRef.current.style.width = '100%'
    })
  }

  onClick() {
    if (this.props.disableClick) {
      return null
    }
    const { firstClick } = this.state
    if (firstClick) {
      this.onReset()
      this.clearTimeouts()
      this.progressRef.current.style.transition = 'none'
      return false
    }
    return this.setState(
      {
        width: '100%',
      },
      () => {
        this.progressRef.current.style.transition = 'width 2500ms linear'
        this.timeout1 = setTimeout(() => {
          this.setState(
            {
              firstClick: true,
              width: '0%',
            },
            () => {
              this.timeout2 = setTimeout(() => {
                this.props.confirmClick()
                this.onReset()
                this.clearTimeouts()
              }, 2500)
            }
          )
        }, 10)
      }
    )
  }

  clearTimeouts() {
    if (this.timeout1) {
      clearTimeout(this.timeout1)
    }
    if (this.timeout2) {
      clearTimeout(this.timeout2)
    }
  }

  renderClassName() {
    const { isDanger, isSmall, disabled, borderRadius } = this.props
    const { firstClick } = this.state
    let className = `uk-button _confirm-button uk-width-1-1 ${
      borderRadius ? '' : '_no_border_radius'
    }`
    if (firstClick) {
      if (isDanger) {
        className += ' uk-button-danger'
      } else {
        className += ' uk-button-primary'
      }
    } else {
      className += ''
      if (isDanger) {
        className += ' _danger'
      } else {
        className += ' uk-button-default'
      }
    }

    if (disabled) {
      className = `uk-button _confirm-button uk-width-1-1 uk-button-default  ${
        borderRadius ? '' : '_no_border_radius'
      }`
    }
    if (isSmall) {
      className += ' uk-button-small'
    }

    return className
  }

  render() {
    const { text, confirmText, disabled } = this.props
    const { firstClick, width } = this.state
    return (
      <button
        disabled={disabled}
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}
        onClick={this.onClick}
        type='button'
        className={this.renderClassName()}
      >
        {firstClick ? confirmText || 'Click to Undo' : text}
        {width && (
          <div
            ref={this.progressRef}
            style={{
              width,
            }}
            className='_progress-countdown'
          />
        )}
      </button>
    )
  }
}

export default ConfirmButton
