require('core-js/stable')

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable()
  window.Promise = require('promise/lib/es6-extensions.js')
}

// fetch() polyfill for making API calls.
require('whatwg-fetch')

// URLSearchParams() polyfill.
// NOTE: In browsers that support fetch() but not URLSearchParams(), you must
// manually set the appropriate header in your request.
// More info: https://github.com/jerrybendy/url-search-params-polyfill#known-issues
require('url-search-params-polyfill')
