import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import UIkit from 'uikit'

function formatStyle(width, height) {
  const expr = (name, value) =>
    `${name}: ${typeof value === 'string' ? value : `${value}px`};`
  if (width && height)
    return `${expr('width', width)} ${expr('height', height)}`
  if (width) return expr('width', width)
  if (height) return expr('height', height)
  return ''
}

/**
 * React wrapper for UIkit modal, intended for use in the content UI.
 * TODO: Move this elsewhere, if we end up using it in other places.
 */
class ContentModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalDiv: null,
      innerDiv: null,
    }
  }

  componentDidMount() {
    // Create a free-floating uk-modal div for UIkit to control
    const modalDiv = document.createElement('div')
    modalDiv.id = this.props.id
    modalDiv.className = 'uk-modal'
    document.body.appendChild(modalDiv)

    // Add an inner body within the div: we'll render child elements here
    const innerDiv = document.createElement('div')
    innerDiv.className = 'uk-modal-dialog'
    innerDiv.style = formatStyle(this.props.width, this.props.height)
    modalDiv.appendChild(innerDiv)

    // UIkit can fire the 'hide' event multiple times, so only respond to the
    // first callback to make sure we don't call onClose twice
    let hasCalledOnClose = false

    // Invoke UIkit to animate the div in, then set up callbacks:
    // - When the div starts animating out, make sure React state is updated
    // - When the div ultimately finishes animating out, remove it from the DOM
    UIkit.modal(modalDiv).show()
    UIkit.util.on(`#${this.props.id}`, 'hide', () => {
      // Hooray for mutable closures, I guess
      if (!hasCalledOnClose) {
        this.props.onClose()
        hasCalledOnClose = true
      }
    })
    UIkit.util.on(`#${this.props.id}`, 'hidden', () => {
      document.body.removeChild(modalDiv)
    })

    // Cache div references as state: this will trigger a re-render so we can
    // render our child elements into the portal div
    this.setState({ modalDiv, innerDiv })
  }

  componentWillUnmount() {
    // Invoke UIkit to animate the div out: the event handlers we registered on
    // mount will ensure that the state changes are propagated back to React
    // and that our fast-and-loose modifications to the DOM are cleaned up
    UIkit.modal(this.state.modalDiv).hide()
  }

  componentDidUpdate() {
    // On update, statically re-render all of our child elements into the
    // portal div within our externally-controlled modal. This ensures that the
    // contents of the div remain fixed while it fades out, even after this
    // component has been unmounted
    if (this.state.innerDiv) {
      ReactDOM.render(this.props.children, this.state.innerDiv)
    }
  }

  render() {
    // Instead of rendering on update, we could simply use a portal here,
    // e.g.: ReactDOM.createPortal(this.props.children, this.state.innerDiv)
    // But this would clear the contents div as soon as it begins animating
    // out: we just want to freeze everything in place during the fade out.
    return null
  }
}
ContentModal.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default ContentModal
