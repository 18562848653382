/* eslint-disable no-lonely-if */
import { connect } from 'react-redux'
import React from 'react'
import settings from 'settings'
import { Link, withRouter, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import Cookie from 'universal-cookie'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import axios from 'axios'

// Components
import Nav from 'app/views/components/Nav'
import { UserIdle } from 'app/views/components/IdleTimer'

// Containers
import ScrollToTop from 'app/views/containers/ScrollToTop'

// Redux
import store from 'app/state/store'
import * as auth from 'app/state/modules/auth'

// Utils
import { getEmailFromJWT, getUsernameFromJWT } from 'app/views/utils/auth'

import { getOrganizations } from 'app/state/modules/hacker'

// Other
import Routes from 'app/views/routes'
import { api } from 'app/views/utils/api'
import { setServiceShimRoutes } from 'app/views/utils/apiServiceShim'
import { setUseReferrerTargeting } from 'app/sjInterop'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      showCookieNotice: null,
    }

    this.hideCookieBanner = this.hideCookieBanner.bind(this)
  }

  componentDidMount() {
    api({
      method: 'GET',
      url: `${settings.urls.hacker}/params`,
    })
      .then(({ data }) => {
        settings.plans = data.plans
        settings.apps = data.assessment_apps.reduce((prev, curr) => {
          prev[curr.key] = curr.name
          return prev
        }, {})
        settings.appsDescriptions = data.assessment_apps.reduce(
          (prev, curr) => {
            prev[curr.key] = curr.description
            return prev
          },
          {}
        )

        settings.integrations = data.integrations.reduce((prev, curr) => {
          prev[curr.key] = curr
          return prev
        }, {})
        settings.owasp = data.owasp
        settings.availableLanguages = data.languages
        settings.translationLanguages = data.translationLanguages
      })
      .catch((err) => {
        console.error(err)
      })

    if (this.props.location.pathname !== '/auth') {
      this.props.onInitAuth()
    } else {
      this.props.onInitSSO()
      this.setState({ loading: false })
    }

    const hasSeenCookieNotice = localStorage.getItem(
      'hackedu.hasSeenCookieNotice'
    )
    if (hasSeenCookieNotice === 'true') {
      this.setState({ showCookieNotice: false })
    } else {
      this.setState({ showCookieNotice: true })
    }

    // Set ref
    const { ref } = queryString.parse(this.props.location.search)
    if (ref) {
      localStorage.setItem('hackedu.ref.name', ref)
      localStorage.setItem(
        'hackedu.ref.date',
        new Date().toISOString().slice(0, 10)
      )
      window.intercomSettings = {
        ref,
      }
    }

    // Segment Script
    const script = document.createElement('script')
    const scriptText = document.createTextNode(
      `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error('Segment snippet included twice.');else{analytics.invoked=!0;analytics.methods=['trackSubmit','trackClick','trackLink','trackForm','pageview','identify','reset','group','track','ready','alias','debug','page','once','off','on'];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t){var e=document.createElement('script');e.type='text/javascript';e.async=!0;e.src=('https:'===document.location.protocol?'https://':'http://')+'cdn.segment.com/analytics.js/v1/'+t+'/analytics.min.js';var n=document.getElementsByTagName('script')[0];n.parentNode.insertBefore(e,n)};analytics.SNIPPET_VERSION='4.0.0'; analytics.load('${settings.segment.key}');}}();`
    )
    script.appendChild(scriptText)
    document.body.appendChild(script)
  }

  // Subscribe to changes in feature flags
  subscribeToFlagChanges(flagName, callback) {
    callback(this.props?.ldClient.variation(flagName))
    this.props?.ldClient.on(`change:${flagName}`, (value) => {
      callback(value)
    })
  }

  componentDidUpdate(prevProps) {
    // Listen for changes in feature flags and forward them on to some utility functions
    // that rely on them
    if (!prevProps.ldClient && !!this.props.ldClient) {
      // The 'eng_enable_api_to_svc_shim_for_routes' feature flag is used by apiServiceShim.ts,
      // which handles translating legacy API calls into mySJ service calls
      this.subscribeToFlagChanges(
        'eng_enable_api_to_svc_shim_for_routes',
        setServiceShimRoutes
      )

      // The 'eng_use_referrer_targeting' feature flag determines whether a static origin
      // value is used to target postMessages, or whether the referrer URL is parsed
      // and used instead
      this.subscribeToFlagChanges(
        'eng_use_referrer_targeting',
        setUseReferrerTargeting
      )
    }

    // Send user info to LaunchDarkly once hacker.profile is initialized
    if (prevProps.profile.user_id !== this.props.profile.user_id) {
      this.props.ldClient.identify({
        key: this.props.profile.user_id,
        email: this.props.profile.email,
        name: this.props.profile.username,
        privateAttributeNames: ['email', 'name'],
      })
    }

    // Clear the 'loading' flag once auth status is no longer pending
    if (prevProps.isAuthPending && !this.props.isAuthPending) {
      this.setState({ loading: false })
    }
  }

  hideCookieBanner() {
    localStorage.setItem('hackedu.hasSeenCookieNotice', 'true')
    this.setState({ showCookieNotice: false })
  }

  render() {
    if (this.state.loading === true) {
      return (
        <div
          className='uk-section'
          style={{ height: '100vh', paddingTop: '50vh' }}
        >
          <div className='uk-container'>
            <div className='text-center'>
              <div data-uk-spinner='' />
            </div>
          </div>
        </div>
      )
    }

    // If user is logged in and is requesting the login page, send them to application root
    if (
      this.props.isAuthenticated &&
      this.props.location.pathname.indexOf('/login') === 0
    ) {
      return <Redirect to='/' />
    }

    return (
      <div>
        <Nav />
        {this.props.children}
        <div
          style={{ minHeight: 'calc(100vh - 466px)' }}
          className={this.props.isAuthenticated ? 'app' : ''}
        >
          <Routes onDemoShown={this.hideCookieBanner} />
        </div>
        {this.state.showCookieNotice === true && (
          <div
            className='uk-padding-small uk-background-secondary '
            style={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              zIndex: 9999,
            }}
          >
            <div className='uk-container'>
              <div
                data-uk-grid
                className='uk-padding-remove-vertical uk-padding-large'
              >
                <div className='uk-width-2-3@m uk-light'>
                  Our platform uses cookies to customize your learning
                  experience, however we do not sell any user data. To learn
                  more, please see our &nbsp;
                  <a
                    href='https://www.securityjourney.com/privacy'
                    target='_blank'
                    className='underline'
                  >
                    Privacy Policy
                  </a>
                  .
                </div>
                <div className='uk-width-1-3@m'>
                  <button
                    data-test-id='accept-cookies-btn'
                    type='button'
                    className='px-[30px] leading-[38px] rounded-full bg-green text-white border-none'
                    onClick={this.hideCookieBanner}
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <ScrollToTop />
        <UserIdle {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthPending: state.auth.status === 'PENDING',
    isAuthenticated: state.auth.status === 'LOGGED_IN',
    profile: state.hacker.profile,
  }
}
const mapDispatchToProps = (dispatch) => ({
  onInitAuth: () => {
    dispatch(auth.init())
  },
  onInitSSO: () => {
    dispatch(auth.initSSO())
  },
  getOrganizations: () => dispatch(getOrganizations()),
})

export default withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
)
