import _ from 'lodash'
import {
  isEmailValid,
  checkPasswordComplexity,
} from 'app/views/utils/validators'
import {
  CANNOT_BE_BLANK,
  INVALID_EMAIL,
  INSUFFICIENT_PASSWORD_COMPLEXITY,
} from 'app/views/constants/errorMessages'

export default (fields, enforceComplexPasswords) => {
  const { email, password, organization } = fields
  const errors = {}

  const trimmedEmail = _.trim(email)
  if (!trimmedEmail) {
    errors.email = CANNOT_BE_BLANK
  } else if (!isEmailValid(trimmedEmail)) {
    errors.email = INVALID_EMAIL
  }

  const trimmedPassword = _.trim(password)
  if (!trimmedPassword) {
    errors.password = CANNOT_BE_BLANK
  } else if (enforceComplexPasswords) {
    const result = checkPasswordComplexity(trimmedPassword)
    if (!result.passed) {
      errors.password = {
        message: INSUFFICIENT_PASSWORD_COMPLEXITY,
        missing: result.missing,
      }
    }
  }

  return errors
}
