import React from 'react'
import PropTypes from 'prop-types'

const ArticleBody = React.forwardRef((props, ref) => (
  <div
    className='uk-container uk-container-small'
    data-uk-height-viewport='offset-top: true;'
  >
    <div
      className='uk-section uk-position-relative'
      style={{ paddingTop: 20 }}
      ref={ref}
    >
      {props.children}
    </div>
  </div>
))
ArticleBody.propTypes = {
  children: PropTypes.any,
}

export default ArticleBody
