import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

// Components
import SubscriptionLocked from 'app/views/components/Subscription/SubscriptionLocked'
import OutageAlert from 'app/views/components/OutageAlert'

// Containers
import LessonUI from 'app/views/content/LessonUI'
import HackingChallengeUI from 'app/views/content/HackingChallengeUI'
import CodingChallengeUI from 'app/views/content/CodingChallengeUI'
import ArticleUI from 'app/views/content/ArticleUI'

// Redux
import { getContentById } from 'app/state/modules/content'
import { resetProxyState } from 'app/state/modules/proxy'
import { replReset } from 'app/state/modules/repl'

// Settings
import settings from 'settings'

// Utils
import analytics from 'app/views/utils/analytics'
import { api } from 'app/views/utils/api'
import isMobileDevice from 'app/views/utils/mobile'
import ModalPreferLanguages from 'app/views/components/Modals/ModalPreferLanguages'

import { parseTournamentDetails } from 'app/sjTournament'

const propTypes = {
  baseUrl: PropTypes.string, // mapStateToProps
  match: PropTypes.object.isRequired, // withRouter
}
const defaultProps = {
  browserUrl: '',
}

class ContentDetailClass extends React.Component {
  constructor(props) {
    super(props)

    this.isDemo = this.props.match.path === '/demo'
    if (this.isDemo) {
      this.contentId = 'ffaf1a2c-563c-49ea-94f9-a9be559a21b9'
    } else {
      this.contentId = this.props.match.params.id || this.props.trainingUuid
    }

    // Check the location prop supplied by react-router to determine if we were
    // redirected to a lesson within the context of an SJ tournament: if so, we need to
    // supply some tournament-specific parameters to the lesson UI
    /** @type {import('app/sjTournament').TournamentDetails | null} */
    this.tournamentDetails = null
    try {
      this.tournamentDetails = parseTournamentDetails(
        new URLSearchParams(this.props.location.search)
      )
    } catch (err) {
      console.error(err)
    }

    this.trackProgress = this.trackProgress.bind(this)
    this.handleClickBack = this.handleClickBack.bind(this)
    this.handleClickCircle = this.handleClickCircle.bind(this)
    this.handleClickDone = this.handleClickDone.bind(this)
    this.handleClickNext = this.handleClickNext.bind(this)
    this.renderContentType = this.renderContentType.bind(this)
    this.props.resetProxyState()
  }

  componentDidMount() {
    if (this.isDemo) {
      // call the onDemoShown handler to tell the main app to close the cookie notice automatically
      this.props.onDemoShown && this.props.onDemoShown()
    }

    this.props.getContentById(this.contentId)
    analytics.page('page-lesson', {
      id: this.contentId,
      contentType: 'course',
    })
  }

  trackProgress(stepNumber) {
    if (
      this.props.match &&
      this.props.match.params &&
      this.contentId &&
      this.props.profile &&
      this.props.profile.user_id
    ) {
      api({
        method: 'post',
        url: `${settings.urls.hacker}/user/${this.props.profile.user_id}/content/${this.contentId}`,
        withAuthToken: true,
        data: {
          step_number: stepNumber,
        },
      }).catch((error) => {
        console.error('error posting progress: ', error)
      })
    }
  }

  handleClickBack(stepIndex) {
    this.trackProgress(stepIndex)
    analytics.track('click-step-back', {
      lessonId: this.contentId,
      taskId: this.templateId(),
      stepIndex,
    })
  }

  templateId() {
    const searchParams = new URLSearchParams(this.props.location.search)
    return (
      searchParams.get('template_override') ||
      this.props.contentDetails[this.contentId].ecs_task_definition
    )
  }

  handleClickCircle(stepIndex) {
    this.trackProgress(stepIndex)
    analytics.track('click-step-dotnav', {
      lessonId: this.contentId,
      taskId: this.templateId(),
      stepIndex,
    })
  }

  handleClickDone(stepIndex) {
    this.trackProgress(stepIndex)
    analytics.track('click-step-done', {
      lessonId: this.contentId,
      taskId: this.templateId(),
    })
  }

  handleClickNext(stepIndex) {
    this.trackProgress(stepIndex)
    analytics.track('click-step-next', {
      lessonId: this.contentId,
      taskId: this.templateId(),
      stepIndex,
    })
  }

  renderContentType() {
    const content = this.props.contentDetails[this.contentId]
    switch (content.content_type) {
      case 'lesson':
        return (
          <LessonUI
            isDemo={this.isDemo}
            contentId={this.contentId}
            contentDetails={{
              ...content,
              sandboxTemplateName: this.templateId(),
            }}
            tournamentDetails={this.tournamentDetails}
            sandboxTemplateName={this.templateId() || ''}
          />
        )
      case 'hacking_challenge':
        return (
          <HackingChallengeUI
            contentId={this.contentId}
            contentDetails={content}
            tournamentDetails={this.tournamentDetails}
            sandboxTemplateName={this.templateId() || ''}
          />
        )
      case 'coding_challenge':
      case 'mobile':
        return (
          <CodingChallengeUI
            contentId={this.contentId}
            contentDetails={content}
            tournamentDetails={this.tournamentDetails}
            sandboxTemplateName={this.templateId()}
          />
        )
      case 'article':
        return <ArticleUI contentId={this.contentId} contentDetails={content} />
      default:
        return (
          <div>Unknown content_type {JSON.stringify(content.content_type)}</div>
        )
    }
  }

  render() {
    const content = this.props.contentDetails[this.contentId]
    if (
      !Object.keys(this.props.contentDetails).includes(this.contentId) ||
      (!this.isDemo && this.props.license === null && !this.props.trainingUuid)
    ) {
      return (
        <div className='uk-section text-center'>
          <div className='uk-container'>
            <div data-uk-spinner='' />
          </div>
        </div>
      )
    }
    if (
      (content.requires_subscription === true &&
        this.props.license === false) ||
      (content.requires_subscription === true &&
        content.license_out_of_scope) ||
      (content.requires_subscription === true && content.is_expired)
    ) {
      return (
        <SubscriptionLocked
          isExpired={content.is_expired}
          licenseOutOfScope={content.license_out_of_scope}
          contentType={content.content_type}
        />
      )
    }

    if (isMobileDevice()) {
      return (
        <div className='uk-section'>
          <div className='uk-container text-center'>
            <h3 className='font-bold'>Looks like you're on mobile...</h3>
            <p>
              We're glad you're checking out a lesson while on the go, but our
              tools are not available on mobile.
            </p>
            <Link className='uk-button uk-button-primary' to='/'>
              Back To Dashboard
            </Link>
          </div>
        </div>
      )
    }
    return (
      <>
        {this.renderContentType()}
        <OutageAlert />
        {!this.isDemo && <ModalPreferLanguages />}
      </>
    )
  }
}

ContentDetailClass.propTypes = propTypes
ContentDetailClass.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  // content
  contentDetails: state.content.contentDetails,

  // hacker
  license: state.hacker.license,
  profile: state.hacker.profile,
})

const mapDispatchToProps = (dispatch) => ({
  getContentById: (contentId) => dispatch(getContentById(contentId)),
  resetProxyState: () => dispatch(resetProxyState()),
  replReset: () => dispatch(replReset()),
})

const ContentDetail = withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentDetailClass))
)

export { ContentDetail }
