import React, { useState } from 'react'
import settings from 'settings'

import './styles.less'

import SelectionTree from './SelectionTree'

const PhaseCheckbox = (props) => {
  function handleSelectionChange(contentIdOrIds, contentNames, isSelected) {
    if (isSelected) {
      const shouldAddId = contentIdOrIds.filter(
        (id) => !props.selectedContentIds.includes(id)
      )
      const shouldAddNames = contentNames.filter(
        (name) => !props.selectedContentNames.includes(name)
      )
      props.onSelectedContentChange(
        props.selectedContentIds.concat(shouldAddId)
      )
      props.getSelectedContentNames(
        props.selectedContentNames.concat(shouldAddNames)
      )
    } else {
      const shouldKeepId = (x) => !contentIdOrIds.includes(x)
      const shouldKeepName = (x) => !contentNames.includes(x)
      props.onSelectedContentChange(
        props.selectedContentIds.filter(shouldKeepId)
      )
      props.getSelectedContentNames(
        props.selectedContentNames.filter(shouldKeepName)
      )
    }
  }

  const [filters, setFilters] = useState({})

  const onClickFilterButton = (language) => {
    setFilters({ ...filters, [language]: !filters[language] })
  }
  const renderStyle = (isActive) => {
    if (isActive) {
      return {
        fontWeight: 'bold',
        backgroundColor: '#d8eafb',
      }
    }
    return {}
  }

  const renderCodingChallenges = () => {
    let selectedCodingChallenges = []
    if (Object.values(filters).filter((x) => x === true).length === 0) {
      return (
        <li className='uk-disabled text-center uk-margin-top uk-margin-bottom text-emphasis uk-padding'>
          <h5>Please select at least one language above</h5>
        </li>
      )
    }

    for (const language in filters) {
      if (filters[language] === true) {
        let filteredChallenges = props.content.code_reviews.filter(
          (x) => x.language_name === language
        )
        selectedCodingChallenges.push(...filteredChallenges)
      }
    }

    return (
      <SelectionTree
        items={selectedCodingChallenges}
        selectedItemIds={props.selectedContentIds}
        onSelectionChange={handleSelectionChange}
        collapsibleDepth={0}
      />
    )
  }

  return (
    <React.Fragment>
      {props.contentType === 'lessons' && (
        <div>
          <SelectionTree
            items={props.content.courses}
            selectedItemIds={props.selectedContentIds}
            onSelectionChange={handleSelectionChange}
            collapsibleDepth={0}
            initialCollapsedDepth={0}
          />
        </div>
      )}
      {props.contentType === 'coding_challenges' && (
        <div>
          {props.content.code_reviews.map((language) => (
            <button
              key={language.language_name}
              name={language.language_name}
              onClick={() => onClickFilterButton(language.language_name)}
              style={renderStyle(filters[language.language_name])}
              className='uk-button uk-button-default uk-button-small uk-margin-small-right inline-flex items-center uk-margin-small-top'
              type='button'
            >
              <i
                className={`devicon-${
                  settings.engineIcons[language.language_name]
                }-plain colored uk-margin-small-right`}
                style={{ fontSize: 12 }}
                title={settings.engines[language.language_name]}
              />
              {settings.engines[language.language_name]}
            </button>
          ))}
          {renderCodingChallenges()}
        </div>
      )}
      {props.contentType === 'hacking_challenges' && (
        <div>
          <SelectionTree
            items={props.content.challenges}
            selectedItemIds={props.selectedContentIds}
            onSelectionChange={handleSelectionChange}
            collapsibleDepth={0}
            initialCollapsedDepth={0}
          />
        </div>
      )}
    </React.Fragment>
  )
}

export default PhaseCheckbox
