import React from 'react'

import Icon from 'app/views/components/Icon'

function PlanSelectionItem({ text, onClick }) {
  return (
    <div
      className='uk-margin-small-top flex justify-between plan-selection-item'
      onClick={onClick}
    >
      {text}
      <Icon name='arrow-right-s' />
    </div>
  )
}

export default PlanSelectionItem
