import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import changeInputHandler from 'app/views/utils/changeInputHandler'
import {
  sendForgotPasswordEmail,
  resendVerificationCode,
} from 'app/views/utils/auth'
import GenericModal from 'app/views/components/Modals/GenericModal'
import validate from './validation'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

const propTypes = {}
const defaultProps = {}

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)

    this.handleInputChange = changeInputHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleResendCode = this.handleResendCode.bind(this)

    this.state = {
      fields: {},
      success: {},
      errors: {},
      error: null,
    }
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push('/')
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    const { fields } = this.state
    const errors = validate(fields)

    if (Object.keys(errors).length) {
      return this.setState({
        errors,
      })
    }
    this.setState({ isButtonLoading: true })
    sendForgotPasswordEmail(fields.email.toLowerCase())
      .then(() => {
        this.setState({ isButtonLoading: false })
        this.props.history.push(
          `/reset-password?username=${encodeURIComponent(
            fields.email.toLowerCase()
          )}`
        )
      })
      .catch((err) => {
        console.error(err)
        this.setState({
          error: err.code,
          isButtonLoading: false,
        })
      })
  }

  handleResendCode() {
    resendVerificationCode({ username: this.state.fields.email.toLowerCase() })
      .then(() => {
        const { errors } = this.state
        this.setState({
          success: {
            resendEmail:
              'We have just resent the confirmation code to your email',
          },
          errors: {
            ...errors,
            resendEmail: null,
          },
        })
        this.resendModal.show()
      })
      .catch((err) => {
        const { errors, success } = this.state
        this.setState(
          {
            errors: {
              ...errors,
              resendEmail: err.message,
            },
            success: {
              ...success,
              resendEmail: null,
            },
          },
          () => {
            this.resendModal.show()
          }
        )
      })
  }

  renderError() {
    switch (this.state.error) {
      case 'InvalidParameterException':
        return (
          <span className='text-danger'>
            Password cannot be reset for unverified accounts.{' '}
            <span
              className='_hover_error'
              onClick={this.handleResendCode}
              style={{
                fontTransform: 'underline',
                borderBottom: '1px solid #f0506e',
                cursor: 'pointer',
              }}
              type='button'
            >
              Resend Verification Email
            </span>
          </span>
        )
      case 'UserNotFoundException':
        return (
          <span className='text-danger'>
            An account with that email address does not exist.
          </span>
        )
      default:
        return null
    }
  }

  render() {
    const { errors, error, isButtonLoading } = this.state

    return (
      <div
        data-test-id='forgot-password-component'
        className='uk-background-muted'
        style={{ minHeight: 'calc(100vh - 10px)' }}
      >
        <div className='uk-container' style={{ maxWidth: 550 }}>
          <div className='uk-section uk-margin-large-bottom'>
            <div className='text-center'>
              <Link className='uk-navbar-item uk-logo uk-navbar-toggle' to='/'>
                <img
                  src='/static/images/SJ_HE_Logo2ColorHorizontal22.png'
                  className='w-[450px]'
                  alt='Logo'
                />
              </Link>
              <h3>Reset Password</h3>
            </div>
            <div
              className='uk-card uk-card-default uk-card-body uk-padding-large uk-margin-top'
              style={{ paddingBottom: 30 }}
            >
              <form onSubmit={this.handleSubmit}>
                <div className='uk-margin'>
                  <input
                    name='email'
                    type='text'
                    placeholder='Email'
                    className='uk-input'
                    onChange={this.handleInputChange}
                  />
                </div>
                <span className='text-red'>{errors.email}</span>
                <div className='my-5'>
                  <PrimaryButton
                    label='Send Reset Code'
                    loading={isButtonLoading}
                    onClick={this.handleSubmit}
                    size='full'
                  />
                </div>
              </form>
              {error && this.renderError()}
            </div>
            <div className='text-center m-4'>
              <Link className='text-green hover:text-green' to='/login'>
                Login
              </Link>
            </div>
          </div>
        </div>
        <GenericModal
          id='modal-resend-code-recover'
          buttonType='uk-button-primary'
          ref={(ref) => {
            this.resendModal = ref
          }}
          title={
            this.state.errors.resendEmail ? 'Error' : 'Confirmation code resend'
          }
          body={
            <>
              <p>
                {this.state.errors.resendEmail ||
                  this.state.success.resendEmail}
              </p>
            </>
          }
          closeText='Ok'
        />
      </div>
    )
  }
}

ForgotPassword.propTypes = propTypes
ForgotPassword.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  // auth
  isAuthenticated: state.auth.status === 'LOGGED_IN',
})

export default compose(connect(mapStateToProps))(ForgotPassword)
