import React from 'react'
import PropTypes from 'prop-types'
import JsPDF from 'jspdf'
import GenericModal from 'app/views/components/Modals/GenericModal'
import moment from 'moment'
import { Button } from 'app/views/components/GustavoButton'
import cx from 'classnames'

const propTypes = {
  email: PropTypes.string.isRequired,
  // TODO: make this prop required when this is released
  completionDate: PropTypes.any,
  // completionDate: PropTypes.string.isRequired,
  courseName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

const defaultProps = {
  courseName: 'Secure Development Training',
  className: '',
}

class GenerateCertificate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      error: undefined,
      lastname: '',
    }

    this.handleLinkClick = this.handleLinkClick.bind(this)
    this.handleFirstClick = this.handleFirstClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSubmit() {
    this.setState(
      {
        error: undefined,
      },
      () => {
        const { name, lastname } = this.state
        if (name.trim() === '' || lastname.trim() === '') {
          const error = name.trim() === '' ? 'name' : 'lastname'
          return this.setState({
            error,
          })
        }
        this.modalHackerName.hide()
        return this.handleLinkClick()
      }
    )
  }

  handleFirstClick(e) {
    e.preventDefault()
    if (this.props.disabled) {
      return
    }
    this.modalHackerName.show()
  }

  handleLinkClick(e) {
    if (e) {
      e.preventDefault()
    }
    const { email } = this.props
    this.doc = new JsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4',
    })
    const width = this.doc.internal.pageSize.getWidth()
    const height = this.doc.internal.pageSize.getHeight()
    this.doc.setProperties({
      title: `Certificate of Completion | ${this.props.courseName} | HackEDU`,
      author: 'HackEDU',
    })

    const img = new Image()
    img.src = '/static/images/hackedu_certificate_sdt.jpg'
    this.doc.addImage(img, 'JPEG', 0, 0, width, height)
    this.doc
      .setFont(this.doc.getFont().fontName, 'bold')
      .setTextColor(0)
      .setFontSize(24)
      .text(425, 315, `${this.state.name} ${this.state.lastname}`, {
        align: 'center',
      })
    this.doc
      .setFontSize(20)
      .setFont(this.doc.getFont().fontName, 'bold')
      .setTextColor(30, 135, 240)
      .text(425, 400, this.props.courseName, { align: 'center' })
      .text(
        425,
        470,
        moment(this.props.completionDate).format('MMMM Do, YYYY'),
        { align: 'center' }
      )
    this.doc.save(`hackedu_certificate_sdt_${email.split('@')[0]}.pdf`)
  }

  render() {
    return (
      <>
        {this.props.link ? (
          <span onClick={this.handleFirstClick} style={this.props.style || {}}>
            {this.props.children}
          </span>
        ) : (
          <Button
            className={this.props.className}
            onClick={this.handleFirstClick}
            disabled={this.props.disabled}
            style={this.props.style || {}}
          >
            {this.props.children}
          </Button>
        )}
        <GenericModal
          title='Generate Your Certificate'
          body={
            <>
              <p>
                Add your first and last name to be shown in the certificate
                together with your email.
              </p>
              <label
                className='uk-form-label text-right'
                htmlFor='form-horizontal-text'
              >
                First Name
              </label>
              <div data-uk-grid>
                <div className='uk-width-1-1'>
                  <input
                    id='name'
                    name='name'
                    type='text'
                    placeholder='First Name'
                    autoComplete='off'
                    required
                    className='uk-input'
                    onChange={this.onChange}
                    value={this.state.name}
                  />
                  <small
                    className={cx('text-danger', {
                      'uk-invisible': this.state.error !== 'name',
                    })}
                  >
                    You must enter your first name.
                  </small>
                </div>
              </div>
              <label
                className='uk-form-label text-right'
                htmlFor='form-horizontal-text'
              >
                Last Name
              </label>
              <div data-uk-grid>
                <div className='uk-width-1-1'>
                  <input
                    id='lastname'
                    name='lastname'
                    type='text'
                    placeholder='Last Name'
                    autoComplete='off'
                    required
                    className='uk-input'
                    onChange={this.onChange}
                    value={this.state.lastname}
                  />
                  <small
                    className={cx('text-danger', {
                      'uk-invisible': this.state.error !== 'lastname',
                    })}
                  >
                    You must enter your last name.
                  </small>
                </div>
              </div>
              {/* </div> */}
            </>
          }
          buttons={
            <button
              type='button'
              onClick={this.onSubmit}
              className='uk-margin-left uk-button uk-button-primary uk-button-rounded'
            >
              Create certificate
            </button>
          }
          id='nameModal'
          ref={(ref) => {
            this.modalHackerName = ref
          }}
        />
      </>
    )
  }
}

GenerateCertificate.propTypes = propTypes
GenerateCertificate.defaultProps = defaultProps

export default GenerateCertificate
