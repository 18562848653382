import React from 'react'
import PropTypes from 'prop-types'

import { HBox, Box } from 'app/views/core/Box'

import { useWidthBreakpoint } from './util'
import WorkPanelTab from './WorkPanelTab'
import SandboxResetButton from './SandboxResetButton'

const VerticalSeparator = () => (
  <div
    style={{
      width: 1,
      height: 28,
      borderRight: '1px solid #e5e5e5',
      marginTop: 12,
    }}
  />
)

function getWidthBreakpoint(numTabs) {
  if (numTabs >= 4) return 1920
  if (numTabs == 3) return 1600
  if (numTabs == 2) return 1250
  return 1185
}

/**
 * The row of tabs along the top of the Content UI work panel. Allows the user
 * to select between available screens for the lesson. The tab names used for
 * activeTabName and onActiveTabNameChange take the form 'browser-0',
 * 'browser-1', 'browser-2', 'code', 'proxy', 'tests', etc.
 */
function WorkPanelTabBar(props) {
  const { contentId, sandboxTemplateName } = props
  const {
    browserTabs,
    hasCode,
    hasProxy,
    hasTests,
    hasMultiFileTests,
    ideEnabled,
  } = props
  const { activeTabName, onActiveTabNameChange, numProxyRequests } = props
  const collapseNonBrowserTabs = useWidthBreakpoint(
    getWidthBreakpoint(browserTabs.length)
  )
  const hasSandboxTemplate = !!sandboxTemplateName
  return (
    <HBox
      style={{
        height: 60,
        minHeight: 60,
        width: '100%',
        borderBottom: '1px solid #e5e5e5',
      }}
    >
      {browserTabs.map((browserTab, i) => (
        <WorkPanelTab
          id={`browser-tab-${i}`}
          key={`browser-${i}`}
          label={browserTab.name}
          iconName={browserTab.icon || 'global'}
          active={activeTabName === `browser-${i}`}
          onActivate={() => onActiveTabNameChange(`browser-${i}`)}
          collapsed={false}
        />
      ))}
      {collapseNonBrowserTabs && <Box grow />}
      {hasProxy && collapseNonBrowserTabs && <VerticalSeparator />}
      {hasProxy && (
        <WorkPanelTab
          id='proxy-history-tab'
          label='Proxy History'
          iconName='refresh'
          badgeCount={numProxyRequests}
          active={activeTabName === 'proxy'}
          onActivate={() => onActiveTabNameChange('proxy')}
          collapsed={collapseNonBrowserTabs}
        />
      )}
      {hasCode && collapseNonBrowserTabs && <VerticalSeparator />}
      {hasCode && !ideEnabled && (
        <WorkPanelTab
          id='code-editor-tab'
          label='Code Editor'
          iconName='terminal'
          active={activeTabName === 'code'}
          onActivate={() => onActiveTabNameChange('code')}
          collapsed={collapseNonBrowserTabs}
        />
      )}
      {hasTests && collapseNonBrowserTabs && <VerticalSeparator />}
      {hasTests &&
        (hasMultiFileTests ? (
          <WorkPanelTab
            id='submissions-tab'
            label='Submissions'
            iconName='checkbox'
            active={activeTabName === 'submissions'}
            onActivate={() => onActiveTabNameChange('submissions')}
            collapsed={collapseNonBrowserTabs}
          />
        ) : (
          <WorkPanelTab
            id='tests-tab'
            label='Tests'
            iconName='checkbox'
            active={activeTabName === 'tests'}
            onActivate={() => onActiveTabNameChange('tests')}
            collapsed={collapseNonBrowserTabs}
          />
        ))}
      {!collapseNonBrowserTabs && hasSandboxTemplate && <Box grow />}
      {hasSandboxTemplate && <VerticalSeparator />}
      {hasSandboxTemplate && (
        <SandboxResetButton
          contentId={contentId}
          templateName={sandboxTemplateName}
        />
      )}
    </HBox>
  )
}
WorkPanelTabBar.propTypes = {
  contentId: PropTypes.string.isRequired,
  sandboxTemplateName: PropTypes.string,
  browserTabs: PropTypes.arrayOf(PropTypes.object),
  hasCode: PropTypes.bool.isRequired,
  hasProxy: PropTypes.bool.isRequired,
  hasTests: PropTypes.bool.isRequired,
  hasMultiFileTests: PropTypes.bool.isRequired,
  activeTabName: PropTypes.string.isRequired,
  onActiveTabNameChange: PropTypes.func.isRequired,
  numProxyRequests: PropTypes.number.isRequired,
}

export default WorkPanelTabBar
