import React from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ToggleSwitch(props) {
  const { onChange, checked, label, disabled } = props
  return (
    <Switch.Group as='div' className='flex items-center'>
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-secondary_green' : 'bg-dark_gray',
          disabled ? 'opacity-25' : 'opacity-100',
          'relative inline-flex flex-shrink-0 h-6 w-11 rounded-full cursor-pointer transition-colors ease-in-out duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green border-none p-0.5 m-0'
        )}
      >
        <span
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-100'
          )}
        />
      </Switch>
      <Switch.Label className='ml-3' as='span'>
        <span className='text-sm font-medium text-dark_gray'>{label}</span>
      </Switch.Label>
    </Switch.Group>
  )
}
