import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Loader from '../../components/Loader'
import { ContentDetail } from '../ContentDetail'

export function TryUs() {
  // We should have a 'title' slug in the route match that indicates which try-us lesson
  // the user navigated to
  const match = useRouteMatch()
  const title = match.params.title || 'sql-injection-part-1'

  // The 'tryOurTraining' flag contains an array of {title, uuid} objects, e.g.
  // [{title: 'sql-injection-part-1', uuid: 'ffaf1a2c-563c-49ea-94f9-a9be559a21b9'}]
  const flags = useFlags()
  const tryOurTrainingValue = flags['tryOurTraining']
  const data = (tryOurTrainingValue || []) as {title: string, uuid: string}[]

  // Find the entry in that array that matches the title provided as the URL slug
  // e.g. /tryus/sql-injection-1
  const item = data.find((x) => x.title === title)
  if (!item) {
    //@ts-ignore
    return <Loader visible/>
  }

  // Once we have a valid UUID, render the main content UI
  return <ContentDetail trainingUuid={item.uuid} />
}
