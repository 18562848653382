import axios from 'axios'

// Redux
import * as auth from 'app/state/modules/auth'
import store from 'app/state/store'

import { translateToServiceCall } from './apiServiceShim'

export function api({
  method,
  url,
  withAuthToken,
  data,
  cancelToken,
  params,
  headers = {},
  opts = {},
}) {
  // Allow our api-to-service shim layer to intercept this API call, make an equivalent
  // mySJ service call if configured to do so, and return a promise that behaves
  // interchangeably with the original API response. If this API call should not be
  // shimmed, this function will return null. If the relevant feature flag is disabled,
  // this function will *always* return null.
  const promise = translateToServiceCall({
    method: method.toLowerCase(),
    url,
    data,
    params,
    withAuthToken,
    cancelToken,
  })
  if (promise) {
    return promise
  }
  return request({
    method,
    url,
    withAuthToken,
    data,
    cancelToken,
    params,
    headers,
    opts,
  })
}

export function request({
  method,
  url,
  withAuthToken,
  data,
  cancelToken,
  params,
  headers = {},
  opts = {},
}) {
  // Some requests which ordinarily require an auth token can sometimes be made without
  // one, e.g. an anonymous user getting details for demo content
  if (withAuthToken && store.getState().auth.status === 'LOGGED_IN') {
    // Get a valid token to use for the Authorization header value, refreshing the
    // session if necessary, before making the request
    return auth.getTokenString(store.getState(), store.dispatch).then((token) =>
      axios({
        method,
        url,
        headers: {
          ...headers,
          Authorization: token,
        },
        data,
        params,
        cancelToken,
        ...opts,
      })
    )
  } else {
    // If we're not logged in or if the request never requires an auth token, make the
    // request directly without pulling in any auth state
    return axios({
      method,
      url,
      headers,
      data,
      params,
      cancelToken,
      ...opts,
    })
  }
}

export default api
