import React from 'react'
import { connect } from 'react-redux'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import * as moment from 'moment'
import settings from 'settings'
import { Link } from 'react-router-dom'

import { getIntegrations } from 'app/state/modules/integrations'
import IntegrationCard from './IntegrationCard'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

class Integrations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.getIntegrations(this.props.match.params.organization_uuid)
  }

  render() {
    return (
      <OrganizationLayout
        active='integrations'
        isLoading={this.props.loadingIntegrations}
        {...this.props}
      >
        <div className='uk-inline'>
          <h1 className='uk-inline'>Integrations</h1>
        </div>
        <PrimaryButton
          size='medium'
          customCss={'float-right'}
          onClick={() => {
            this.props.history.push(
              `/admin/${this.props.match.params.organization_uuid}/integrations/add`
            )
          }}
          label='Add Integration'
        />

        <br />

        {this.props.integrations.length === 0 && (
          <div className='uk-margin'>
            <p>
              Click{' '}
              <Link
                className='text-green hover:text-green'
                to={`/admin/${this.props.match.params.organization_uuid}/integrations/add`}
              >
                Add Integration
              </Link>{' '}
              to create your first data integration.
            </p>
          </div>
        )}
        <div
          className='flex flex-row flex-wrap'
          uk-height-match='.integration-card-description'
        >
          {this.props.integrations.map((integration) => {
            const isCustom = integration.type === 'custom'
            return (
              <IntegrationCard
                small
                style={{
                  width: 400,
                  marginRight: 40,
                }}
                title={integration.title}
                key={integration.uuid}
                color={
                  isCustom
                    ? '#1f87f0'
                    : settings.integrations[integration.type].settings.color
                }
                logo={
                  isCustom ? null : settings.integrationLogos[integration.type]
                }
                keyName={integration.type}
                isCustom={integration.type === 'custom'}
                onClick={() => {
                  this.props.history.push(
                    `/admin/${this.props.match.params.organization_uuid}/integrations/${integration.uuid}`
                  )
                }}
              >
                <div className='flex justify-start flex-wrap items-center uk-widh-1-1 integration-card-description'>
                  {!integration.teams && (
                    <label className='uk-label  uk-label-success uk-margin-small-rigth uk-margin-small-top'>
                      All Teams
                    </label>
                  )}
                  {(integration.teams || []).map((t) => (
                    <label
                      data-uk-tooltip={t.name}
                      className='uk-label uk-label-primary uk-margin-small-rigth uk-margin-small-top'
                      key={t.uuid}
                      style={{
                        maxWidth: '30%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        marginRight: 10,
                      }}
                    >
                      {t.name}
                    </label>
                  ))}
                </div>
                <div className='flex flex-row justify-between uk-margin-top'>
                  <i>Last scanned at:</i>
                  <span className='text-emphasis'>
                    {integration.last_scanned_at
                      ? moment(integration.last_scanned_at).format(
                          'MMM D, YYYY'
                        )
                      : 'Never'}
                  </span>
                </div>
              </IntegrationCard>
            )
          })}
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = ({ integrations, hacker }) => ({
  integrations: integrations.integrations,
  integrationsHash: integrations.integrationsHash,
  loadingIntegrations: integrations.loadingIntegrations,
  organizations: hacker.organizationsList,
  organizationsHash: hacker.organizationsHash,
})

export default connect(mapStateToProps, {
  getIntegrations,
})(Integrations)
