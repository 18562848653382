/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import UIkit from 'uikit'

// Components
import Dot from 'app/views/components/Dot'
import Icon from 'app/views/components/Icon'
import Loader from 'app/views/components/Loader'
import ModalAboutProficiency from 'app/views/components/Modals/Info/ModalAboutProficiency'
import UserDetailModal from 'app/views/components/UserDetailModal'
import GenericModal from 'app/views/components/Modals/GenericModal'

// Containers
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'

// Redux
import { getProficiencyReport } from 'app/state/modules/reports'
import {
  cleanUserDetailModal,
  fetchSelectedUserDetail,
} from 'app/state/modules/users'
import { deleteHackerFromOrganization } from 'app/state/modules/hacker'

class ProficiencyReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: {
        POOR: true,
        AVERAGE: true,
        STRONG: true,
        UNKNOWN: true,
      },
    }
    this.renderRows = this.renderRows.bind(this)
    this.onExport = this.onExport.bind(this)
    this.onDeleteUser = this.onDeleteUser.bind(this)
    this.onClickFilterButton = this.onClickFilterButton.bind(this)
    this.fetchSelectedUserDetail = this.fetchSelectedUserDetail.bind(this)
    this.props.cleanUserDetailModal()
  }

  componentDidMount() {
    this.props.getProficiencyReport(
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid
    )
  }

  onClickFilterButton(e) {
    const { filters } = this.state
    this.setState({
      filters: {
        ...filters,
        [e.target.name]: !filters[e.target.name],
      },
    })
  }

  onExport() {
    const headers = ['Email', 'CategoryName', 'Proficiency']
    const rows = []
    rows.push(headers)
    Object.values(this.props.proficiencyReport).forEach((hacker) => {
      Object.values(hacker.categories).forEach((categoryItem) => {
        const hackerRow = []
        hackerRow.push(hacker.email)
        if (
          (categoryItem.UNKNOWN && this.state.filters.UNKNOWN) ||
          (categoryItem.STRONG && this.state.filters.STRONG) ||
          (categoryItem.AVERAGE && this.state.filters.AVERAGE) ||
          (categoryItem.POOR && this.state.filters.POOR)
        ) {
          hackerRow.push(categoryItem.title)
          if (categoryItem.UNKNOWN && this.state.filters.UNKNOWN) {
            hackerRow.push('Not enough information')
          }
          if (categoryItem.STRONG && this.state.filters.STRONG) {
            hackerRow.push('Expert')
          }
          if (categoryItem.AVERAGE && this.state.filters.AVERAGE) {
            hackerRow.push('Average')
          }
          if (categoryItem.POOR && this.state.filters.POOR) {
            hackerRow.push('Needs Attention')
          }
          rows.push(hackerRow)
        }
      })
    })
    if (rows.length === 1) {
      UIkit.modal.alert(
        '<h2>Export error</h2><p>There are no users to export in this report</p>'
      )
      return
    }
    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((e) => e.join(','))
      .join('\n')}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `Proficiency_report_${moment().format('YYYY-MM-DD')}.csv`
    )
    document.body.appendChild(link)
    link.click()
  }

  onDeleteUser() {
    const orgUUID = this.props.match.params.organization_uuid
    const userUUID =
      this.props.selectedUserDetail.uuid || this.props.match.params.hacker_uuid
    const callback = (error = false) => {
      this.modalDeleteUser.hide()
      if (error) {
        return UIkit.modal.alert(
          '<h2 >Error</h2><p>There was an error deleting the user. Please try again or contact support.</p>'
        )
      }
      this.props.getProficiencyReport(
        this.props.match.params.organization_uuid,
        this.props.match.params.team_uuid
      )
      return UIkit.modal.alert(
        '<h2 >User removed</h2><p>The user has been removed successfully from the organization<p>'
      )
    }
    this.props.deleteHackerFromOrganization(orgUUID, userUUID, callback)
  }

  fetchSelectedUserDetail(hacker, index) {
    this.props.fetchSelectedUserDetail(
      this.props.match.params.organization_uuid,
      {
        ...hacker,
        uuid: hacker.uuid,
        justTeam: this.props.match.params.team_uuid,
      },
      false,
      {
        localIndex: index,
      }
    )
  }

  renderDeleteModalContent() {
    if (!this.props.selectedUserDetail) return null
    if (this.props.selectedUserDetail.steps_completed > 20) {
      return (
        <>
          <p>
            {`You are about to remove ${this.props.selectedUserDetail.email} from your organization.
            This user's license does not qualify to be released due to the amount of content they
            have already completed.`}
          </p>
          <p>Are you sure you want to continue?</p>
        </>
      )
    }
    return (
      <>
        <p>
          {`You are about to remove ${this.props.selectedUserDetail.email} from your organization. This
          user's license will be available for reuse since they completed a minimal amount of
          the training.`}
        </p>
        <p>Are you sure you want to continue?</p>
      </>
    )
  }

  renderRows() {
    const rows = []
    Object.values(this.props.proficiencyReport).forEach((hacker, index) => {
      const categories = []
      let shouldShowHacker = false
      Object.values(hacker.categories).forEach((categoryItem) => {
        if (
          (categoryItem.UNKNOWN && this.state.filters.UNKNOWN) ||
          (categoryItem.STRONG && this.state.filters.STRONG) ||
          (categoryItem.AVERAGE && this.state.filters.AVERAGE) ||
          (categoryItem.POOR && this.state.filters.POOR)
        ) {
          shouldShowHacker = true
          categories.push(
            <div
              key={`${hacker.uuid}-${categoryItem.title}`}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Dot
                className='uk-margin-small-right'
                small
                unknown={categoryItem.UNKNOWN}
                average={categoryItem.AVERAGE}
                needs_attention={categoryItem.POOR}
                expert={categoryItem.STRONG}
              />
              <span>{categoryItem.title}</span>
            </div>
          )
        }
      })
      if (shouldShowHacker) {
        rows.push(
          <tr key={hacker.uuid}>
            <td>
              <a
                onClick={this.fetchSelectedUserDetail.bind(this, hacker, index)}
              >
                {hacker.email}
              </a>
            </td>
            <td>
              <div>{categories}</div>
            </td>
          </tr>
        )
      }
    })
    if (rows.length === 0) {
      return (
        <tr>
          <td colSpan={2} className='text-center'>
            No users found (with current filters)
          </td>
        </tr>
      )
    }
    return rows
  }

  renderStyle(isActive) {
    if (isActive) {
      return {
        fontWeight: 'bold',
      }
    }
    return {
      background: '#EEE',
      color: '#666',
      textDecoration: 'line-through',
    }
  }

  render() {
    if (
      this.props.proficiencyReport === 'loading' ||
      !this.props.hasLoadedOrganizations
    ) {
      return <Loader visible />
    }

    return (
      <OrganizationLayout
        active='reports'
        data-test-id='organization-users-component'
        noPaddingRemove={this.props.match.params.team_uuid}
        style={{ position: 'relative' }}
        {...this.props}
      >
        {this.props.match.params.team_uuid && (
          <button
            onClick={() =>
              this.props.history.push(
                `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/users`
              )
            }
            className='uk-button uk-button-secondary uk-button-small uk-position-absolute uk-position-top-left uk-margin-top uk-margin-small-left flex'
            type='button'
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Back To Team
          </button>
        )}

        <h2 className='text-center'>Proficiency Report</h2>
        <div className='uk-padding'>
          <button
            onClick={this.onClickFilterButton}
            className='uk-button uk-button-default uk-button-small uk-align-left uk-margin-small-left uk-margin-small-right inline-flex items-center'
            name='STRONG'
            style={this.renderStyle(this.state.filters.STRONG)}
            type='button'
          >
            <Dot expert small className='uk-margin-small-right' />
            Expert
          </button>
          <button
            name='AVERAGE'
            onClick={this.onClickFilterButton}
            className='uk-button uk-button-default uk-button-small uk-align-left uk-margin-small-left uk-margin-small-right inline-flex items-center'
            style={this.renderStyle(this.state.filters.AVERAGE)}
            type='button'
          >
            <Dot average small className='uk-margin-small-right' />
            Average
          </button>
          <button
            name='POOR'
            onClick={this.onClickFilterButton}
            className='uk-button uk-button-default uk-button-small uk-align-left uk-margin-small-left uk-margin-small-right inline-flex items-center'
            style={this.renderStyle(this.state.filters.POOR)}
            type='button'
          >
            <Dot needs_attention small className='uk-margin-small-right' />
            Needs Attention
          </button>
          <button
            name='UNKNOWN'
            onClick={this.onClickFilterButton}
            className='uk-button uk-button-default uk-button-small uk-align-left uk-margin-small-left uk-margin-small-right inline-flex items-center'
            style={this.renderStyle(this.state.filters.UNKNOWN)}
            type='button'
          >
            <Dot unknown small className='uk-margin-small-right' />
            Not Enough Information
          </button>
          <span
            name='information'
            ratio={0.8}
            data-uk-toggle='target: #modal-info-about-Proficiency'
            style={{
              cursor: 'pointer',
              paddingLeft: 8,
              paddingTop: 6,
              verticalAlign: 'top',
            }}
          />

          <button
            onClick={this.onExport}
            className='uk-button uk-button-default uk-button-small uk-align-right uk-margin-small-left uk-margin-small-right flex'
            type='button'
          >
            <Icon className='uk-margin-small-right' name='download-cloud' />
            Export Data
          </button>
          <table className='uk-table uk-table-divider uk-table-middle'>
            <thead>
              <tr>
                <th className='uk-width-1-2'>EmaiL</th>
                <th>Vulnerability categories</th>
              </tr>
            </thead>
            <tbody>{this.renderRows()}</tbody>
          </table>
        </div>
        <UserDetailModal
          id='user-detail-Proficiency-report'
          localData={Object.values(this.props.proficiencyReport)}
          selectedUserDetail={this.props.selectedUserDetail}
          onRemoveFromOrg={() => this.modalDeleteUser.show()}
        />
        <GenericModal
          title='Delete user from organization'
          ref={(ref) => {
            this.modalDeleteUser = ref
          }}
          id='delete-user-modal'
          date-test-id='delete-user-modal'
          body={this.renderDeleteModalContent()}
          buttons={
            <button
              data-test-id='delete-user-confirm'
              type='button'
              className='uk-button uk-button-danger uk-margin-left'
              onClick={this.onDeleteUser}
            >
              Delete
            </button>
          }
        />
        <ModalAboutProficiency />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedUserDetail: state.users.selectedUserDetail,
  proficiencyReport: state.reports.proficiencyReport,
  hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
  organizationsHash: state.hacker.organizationsHash,
  organizations: state.hacker.organizationsList,
})

export default connect(mapStateToProps, {
  getProficiencyReport,
  fetchSelectedUserDetail,
  cleanUserDetailModal,
  deleteHackerFromOrganization,
})(ProficiencyReports)
