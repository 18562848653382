import React from 'react'

// Components
import { OrganizationsNavi } from 'app/views/components/OrganizationsNavi'
import Loader from 'app/views/components/Loader'

const OrganizationLayout = (props) => {
  const showAdminNavBar = props.hideLeftNavi
    ? false
    : (
        (props.organizationsHash || {})[props.match.params.organization_uuid] ||
        {}
      ).role === 'admin'
  return (
    <div
      data-test-id={props['data-test-id']}
      id={props.id}
      className='uk-grid'
      data-uk-grid=''
    >
      {showAdminNavBar && (
        <div
          className='uk-width-medium@m uk-visible@m uk-background-muted'
          style={{
            overflow: 'auto',
            height: 'calc(100vh - 80px)',
            borderRight: '1px solid #EEE',
          }}
        >
          <OrganizationsNavi
            active={props.active}
            organizations={props.organizations}
            currentOrganizationId={props.match.params.organization_uuid}
          />
        </div>
      )}
      <div
        className={`${
          props.noPaddingRemove ? '' : 'uk-padding-remove '
        }uk-width-expand@m uk-width-1-1@s`}
        style={{ overflow: 'auto', height: 'calc(100vh - 80px)' }}
      >
        {props.isLoading ? (
          <Loader visible />
        ) : (
          <div
            className='uk-padding'
            style={
              showAdminNavBar
                ? { ...(props.style || {}) }
                : { ...(props.style || {}), padding: '20px 10vw' }
            }
          >
            {!props.isLoading && props.children}
          </div>
        )}
      </div>
    </div>
  )
}

export default OrganizationLayout
