import React from 'react'
import moment from 'moment'
import UIkit from 'uikit'

import Icon from 'app/views/components/Icon'
import ConfirmButton from 'app/views/components/ConfirmButton'
import LicenseMenu from 'app/views/containers/Organizations/Users/components/LicenseMenu'
import LicenseTag from 'app/views/components/LicenseTag'

const SettingsTab = (props) => {
  const expired =
    props.selectedUser.has_license &&
    props.selectedUser.expiration_date &&
    moment(props.selectedUser.expiration_date, 'YYYY-MM-DD').diff(moment()) < 0

  return (
    <div data-uk-grid>
      <div className='uk-width-1-2 uk-padding' style={{ height: '100%' }}>
        <h4>Organization Role</h4>
        Current Role:
        <span className='font-bold'>
          {props.selectedUser.role === 'admin' ? ' Admin' : ' Member'}
        </span>
        {props.hackerUUID !== props.selectedUser.uuid && (
          <>
            <ConfirmButton
              isSmall
              borderRadius
              text={
                <>
                  Change Role to
                  {props.selectedUser.role === 'admin' ? (
                    <b>&nbsp;Member</b>
                  ) : (
                    <b>&nbsp;Admin</b>
                  )}
                </>
              }
              confirmClick={() => {
                props
                  .updateHackerRole(
                    props.match.params.organization_uuid,
                    props.selectedUser.uuid,
                    props.selectedUser.role === 'admin' ? 'member' : 'admin'
                  )
                  .then(() => {
                    props.refreshUserDetail()
                  })
              }}
            />
          </>
        )}
        {props.hackerUUID !== props.selectedUser.uuid && (
          <button
            type='button'
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
            className='uk-button uk-button-danger uk-width-1-1 uk-button-small'
            onClick={props.onRemoveFromOrg}
          >
            <Icon
              name='delete-bin'
              ratio={0.8}
              style={{
                position: 'absolute',
                left: 10,
              }}
            />
            Remove from Organization
          </button>
        )}
      </div>

      <div
        className='uk-width-1-2 uk-divider-vertical uk-padding'
        style={{ height: '100%' }}
      >
        <h4>License</h4>
        {'Current License: '}
        <LicenseTag
          user={props.selectedUser}
          style={{ padding: 5, border: 'none' }}
        />
        {props.selectedUser.has_license ? (
          <ConfirmButton
            borderRadius
            disabled={
              (props.selectedUser.has_license &&
                props.selectedUser.steps_completed > 20) ||
              expired
            }
            isSmall
            text={
              <>
                {(props.selectedUser.has_license &&
                  props.selectedUser.steps_completed > 20) ||
                expired
                  ? 'Cannot Remove License'
                  : 'Remove License'}

                {((props.selectedUser.has_license &&
                  props.selectedUser.steps_completed > 20) ||
                  expired) && (
                  <Icon
                    type='button'
                    role='button'
                    onClick={() => {
                      UIkit.modal.alert(`
                      <h2 >Cannot Remove License</h2>
                      <p>
                        We do not allow licenses to be removed or reassigned once a
                        user has already gone through several lessons.
                      </p>
                      <p>
                        If you have questions about this policy, you may reach out
                        to HackEDU at <a href="mailto:support@securityjourney.com">support@securityjourney.com</a>.
                      </p>`)
                    }}
                    style={{
                      position: 'absolute',
                      right: 10,
                      cursor: 'pointer',
                    }}
                    name='question'
                    ratio={0.8}
                  />
                )}
              </>
            }
            isDanger
            confirmClick={() => {
              props.handleClickRemoveLicenseFromUser(
                props.selectedUser.uuid,
                props.selectedUser.license_type,
                props.selectedUser.license_type_uuid
              )
            }}
          />
        ) : (
          <LicenseMenu
            borderRadius
            availableLicenseTypes={props.availableLicenseTypes}
            availableLicenses={props.availableLicenses}
            handleClickAddLicenseToUser={props.handleClickAddLicenseToUser}
            handleClickRemoveLicenseFromUser={
              props.handleClickRemoveLicenseFromUser
            }
            user={props.selectedUser}
            expired={expired}
          />
        )}
      </div>
    </div>
  )
}

export default SettingsTab
