//https://app.hackedu.dev
const DEVELOPMENT = 'development'

//https://app.hackedu.com
const PRODUCTION = 'production'

//localhost
const LOCAL = 'local'

const DEPLOYMENT_MODES = [DEVELOPMENT, PRODUCTION]
const DEVELOPMENT_MODES = [DEVELOPMENT, LOCAL]
const LOCAL_MODES = [LOCAL]
const DEPLOYED_DEV_MODES = [DEVELOPMENT]
const PRODUCTION_MODES = [PRODUCTION]

export const IS_DEPLOYMENT_MODE = DEPLOYMENT_MODES.includes(
  process.env.REACT_APP_ENV
)

export const IS_DEV_MODE = DEVELOPMENT_MODES.includes(process.env.REACT_APP_ENV)

export const IS_LOCAL_MODE = LOCAL_MODES.includes(process.env.REACT_APP_ENV)

export const IS_DEPLOYED_DEV_MODE = DEPLOYED_DEV_MODES.includes(
  process.env.REACT_APP_ENV
)

export const IS_PRODUCTION_MODE = PRODUCTION_MODES.includes(
  process.env.REACT_APP_ENV
)
