/* eslint-disable prefer-destructuring */
// Third Party Libraries
import { get } from 'lodash'
import { history } from 'app/state/store'
import axios from 'axios'

// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'
import moment from 'moment'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import { notifyParentSjApp } from 'app/sjInterop'

import { setSelectedUser } from 'app/state/modules/team'
import Base64 from '../utils/base64'
import { REPL_SET_SUBMITTING_PATCH, setRunningTests } from './repl'
// user onboarding
const HACKER_POSTING_ONBOARDING = 'HACKER_POSTING_ONBOARDING'
const HACKER_POSTED_ONBOARDING = 'HACKER_POSTED_ONBOARDING'

// user profile
const HACKER_GETTING_PROFILE = 'HACKER_GETTING_PROFILE'
const HACKER_RECEIVED_PROFILE = 'HACKER_RECEIVED_PROFILE'
const SET_SCRATCHPAD_ENABLED = 'SET_SCRATCHPAD_ENABLED'
const SET_SCRATCHPAD_NOTES = 'SET_SCRATCHPAD_NOTES'
// updating user profile
const HACKER_UPDATING_PROFILE = 'HACKER_UPDATING_PROFILE'
const HACKER_UPDATED_PROFILE = 'HACKER_UPDATED_PROFILE'
const HACKER_UPDATED_PROFILE_ERROR = 'HACKER_UPDATED_PROFILE_ERROR'

// updating user profile
const HACKER_UPDATING_SEND_REMINDERS = 'HACKER_UPDATING_SEND_REMINDERS'
const HACKER_UPDATED_SENT_REMINDERS = 'HACKER_UPDATED_SENT_REMINDERS'
const HACKER_UPDATED_SENT_REMINDERS_ERROR =
  'HACKER_UPDATED_SENT_REMINDERS_ERROR'

// check username
const HACKER_CHECKING_USERNAME = 'HACKER_CHECKING_USERNAME'
const HACKER_CHECKED_USERNAME = 'HACKER_CHECKED_USERNAME'

// code
const HACKER_POSTED_CODE = 'HACKER_POSTED_CODE'
const HACKER_UPDATED_CODE = 'HACKER_UPDATED_CODE'
const HACKER_GETTING_CODE_SUBMISSIONS = 'HACKER_GETTING_CODE_SUBMISSIONS'
const HACKER_RECEIVED_CODE_SUBMISSIONS = 'HACKER_RECEIVED_CODE_SUBMISSIONS'
const HACKER_GETTING_CODE_SUBMISSIONS_ERROR =
  'HACKER_GETTING_CODE_SUBMISSIONS_ERROR'
const HACKER_ADD_CODE_SUBMISSION = 'HACKER_ADD_CODE_SUBMISSION'

// organizations list
const HACKER_GETTING_ORGANIZATIONS = 'HACKER_GETTING_ORGANIZATIONS'
const HACKER_RECEIVED_ORGANIZATIONS = 'HACKER_RECEIVED_ORGANIZATIONS'

// team details
const HACKER_GETTING_TEAMS_DETAILS = 'HACKER_GETTING_TEAMS_DETAILS'
const HACKER_RECEIVED_TEAMS_DETAILS = 'HACKER_RECEIVED_TEAMS_DETAILS'
const HACKER_PATCHING_TEAMS_DETAILS = 'HACKER_PATCHING_TEAMS_DETAILS'
const HACKER_PATCHED_TEAMS_DETAILS = 'HACKER_PATCHED_TEAMS_DETAILS'

// team hackers
const HACKER_GETTING_TEAMS_HACKERS = 'HACKER_GETTING_TEAMS_HACKERS'
const HACKER_RECEIVED_TEAMS_HACKERS = 'HACKER_RECEIVED_TEAMS_HACKERS'
const HACKER_RECEIVED_TEAMS_HACKERS_TOTAL =
  'HACKER_RECEIVED_TEAMS_HACKERS_TOTAL'
const HACKER_SET_IS_LOADING_HACKERS = 'HACKER_SET_IS_LOADING_HACKERS'
const HACKER_SET_FILTER_HACKER = 'HACKER_SET_FILTER_HACKER'
const HACKER_SET_OFFSET_HACKER = 'HACKER_SET_OFFSET_HACKER'
const HACKER_SET_ORDER_DIRECTION = 'HACKER_SET_ORDER_DIRECTION'
const HACKER_SET_ORDER = 'HACKER_SET_ORDER'

// team hacker details
const HACKER_GETTING_TEAMS_HACKERS_DETAILS =
  'HACKER_GETTING_TEAMS_HACKERS_DETAILS'
const HACKER_RECEIVED_TEAMS_HACKERS_DETAILS =
  'HACKER_RECEIVED_TEAMS_HACKERS_DETAILS'

// update team hacker details
const HACKER_UPDATING_TEAMS_HACKERS_DETAILS =
  'HACKER_UPDATING_TEAMS_HACKERS_DETAILS'
const HACKER_UPDATED_TEAMS_HACKERS_DETAILS =
  'HACKER_UPDATED_TEAMS_HACKERS_DETAILS'

// team hacker code
const HACKER_GETTING_TEAMS_HACKERS_CODE = 'HACKER_GETTING_TEAMS_HACKERS_CODE'
const HACKER_RECEIVED_TEAMS_HACKERS_CODE = 'HACKER_RECEIVED_TEAMS_HACKERS_CODE'

// team hacker add license
const HACKER_ADDING_TEAMS_HACKERS_LICENSE =
  'HACKER_ADDING_TEAMS_HACKERS_LICENSE'
const HACKER_ADDED_TEAMS_HACKERS_LICENSE = 'HACKER_ADDED_TEAMS_HACKERS_LICENSE'

// team hacker remove license
const HACKER_REMOVING_TEAMS_HACKERS_LICENSE =
  'HACKER_REMOVING_TEAMS_HACKERS_LICENSE'
const HACKER_REMOVED_TEAMS_HACKERS_LICENSE =
  'HACKER_REMOVED_TEAMS_HACKERS_LICENSE'

// team report lessons
const HACKER_GETTING_TEAMS_REPORT_LESSONS =
  'HACKER_GETTING_TEAMS_REPORT_LESSONS'
const HACKER_RECEIVED_TEAMS_REPORT_LESSONS =
  'HACKER_RECEIVED_TEAMS_REPORT_LESSONS'

// team report users
const HACKER_GETTING_TEAMS_REPORT_USERS = 'HACKER_GETTING_TEAMS_REPORT_USERS'
const HACKER_RECEIVED_TEAMS_REPORT_USERS = 'HACKER_RECEIVED_TEAMS_REPORT_USERS'
const HACKER_RECEIVED_TEAMS_REPORT_USERS_ERRORS =
  'HACKER_RECEIVED_TEAMS_REPORT_USERS_ERRORS'

// team certificates
const HACKER_RECEIVED_TEAM_CERTIFICATES = 'HACKER_RECEIVED_TEAM_CERTIFICATES'

// org invite admin
const HACKER_INVITING_ADMIN = 'HACKER_INVITING_ADMIN'
const HACKER_INVITED_ADMIN = 'HACKER_INVITED_ADMIN'
const HACKER_INVITED_ADMIN_ERROR = 'HACKER_INVITED_ADMIN_ERROR'

// user subscribes
const HACKER_USER_SUBSCRIBED = 'HACKER_USER_SUBSCRIBED'

// user update teams
export const UPDATE_TEAM_USER = 'UPDATE_TEAM_USER'

export const SET_IS_PICKING_PLAN = 'SET_IS_PICKING_PLAN'
export const UPDATE_API_TOKEN = 'UPDATE_API_TOKEN'
export const SET_PULLING_CODE_UNTIL_PASS = 'SET_PULLING_CODE_UNTIL_PASS'

const initialState = {
  // user onboarding
  loadingOnboarding: false,

  isPickingPlan: false,

  // user profile
  loadingProfile: false,
  hasLoadedProfile: false,
  profile: {
    uuid: null,
    email: null,
    username: null,
    points: 0,
    send_reminders: null,
    settings: {},
  },
  license: null,

  // updating user profile
  updatingProfile: false,
  updatingProfileError: false,

  // updating send reminders
  updatingSendReminders: false,
  updatingSendRemindersError: false,

  // check username
  checkingUsername: false,

  // code
  latestCodePatchId: null,
  latestCodePassed: null,
  latestCodeStackTrace: null,
  currentCodeSubmissions: [],
  loadingCodeSubmissions: false,

  // organization list
  loadingOrganizations: false,
  hasLoadedOrganizations: false,
  mustPassCodingExercise: false,
  showAllTraining: true,
  organizationsList: [],
  organizationsHash: {},

  // team details
  loadingCurrentTeamDetails: false,
  hasLoadedCurrentTeamDetails: false,
  currentTeamDetails: {
    token: '',
  },
  // team hackers
  loadingCurrentTeamHackers: false,
  hasLoadedCurrentTeamHackers: false,
  currentTeamHackers: [],
  totalHackers: 0,
  totalTeams: 0,
  completedAssignedLessons: 0,
  hackersPerPage: 50,
  isLoadingHackers: true,
  offsetHackers: 0,
  orderHackersBy: 'email',
  filterHacker: '',
  orderHackersDirection: 'asc',

  // team hacker details
  loadingCurrentHackerDetails: false,
  hasLoadedCurrentHackerDetails: false,
  currentHackerDetails: {
    content_progress: {
      plan: [],
      all: [],
    },
  },

  // update team hacker details
  updatingTeamHackerDetails: false,

  // team hacker code
  loadingCurrentHackerCode: false,
  hasLoadedCurrentHackerCode: false,
  currentHackerCode: [],

  // team hacker add license
  addingTeamHackerLicense: false,

  // team hacker remove license
  removingTeamHackerLicense: false,

  // team report lessons
  loadingReportLessons: false,
  reportTotalLessons: 0,
  errorReportUsers: false,
  reportLessons: [],
  totalAssignedLessons: 0,

  // team report users
  loadingReportUsers: false,
  reportUsers: [],

  // team map
  teamMap: {},

  // org invite admin
  invitingAdmin: false,
  invitingAdminError: false,

  // team certificates
  teamCertificates: [],

  pullingCodeUntilPass: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PULLING_CODE_UNTIL_PASS:
      const { pullingCodeUntilPass } = action
      return {
        ...state,
        pullingCodeUntilPass,
      }
    case HACKER_ADD_CODE_SUBMISSION:
      return {
        ...state,
        currentCodeSubmissions: [
          action.codeSubmission,
          ...state.currentCodeSubmissions.filter(
            (x) => x.id !== action.codeSubmission.id
          ),
        ],
      }
    // user onboarding
    case HACKER_POSTING_ONBOARDING:
      return {
        ...state,
        loadingOnboarding: true,
      }
    case HACKER_POSTED_ONBOARDING:
      return {
        ...state,
        loadingOnboarding: false,
      }

    // user profile
    case HACKER_GETTING_PROFILE:
      return {
        ...state,
        loadingProfile: true,
      }
    case HACKER_RECEIVED_PROFILE:
      return {
        ...state,
        hasLoadedProfile: true,
        loadingProfile: false,
        profile: {
          user_id: action.uuid,
          email: action.email,
          username: action.username,
          points: action.points,
          send_reminders: action.send_reminders,
          has_finished_training: action.has_finished_training,
          last_completed_at: action.last_completed_at,
          settings: action.settings,
          super_admin: action.super_admin,
          languages: action.languages || {},
        },
        license: action.license,
      }
    case UPDATE_API_TOKEN:
      return {
        ...state,
        currentTeamDetails: {
          ...state.currentTeamDetails,
          api_token: action.api_token,
        },
      }
    // updating user profile
    case HACKER_UPDATING_PROFILE:
      return {
        ...state,
        updatingProfile: true,
        updatingProfileError: false,
      }
    case SET_SCRATCHPAD_ENABLED:
      return {
        ...state,
        profile: {
          ...state.profile,
          settings: {
            ...state.profile.settings,
            scratchpad: {
              ...state.profile.settings.scratchpad,
              enabled: action.enabled,
            },
          },
        },
      }
    case SET_SCRATCHPAD_NOTES:
      return {
        ...state,
        profile: {
          ...state.profile,
          settings: {
            ...state.profile.settings,
            scratchpad: {
              ...state.profile.settings.scratchpad,
              notes: action.notes,
            },
          },
        },
      }
    case HACKER_UPDATED_PROFILE:
      return {
        ...state,
        updatingProfile: false,
        updatingProfileError: true,
        profile: {
          ...state.profile,
          username: action.username,
          languages: action.languages || state.profile.languages,
        },
      }
    case HACKER_UPDATED_PROFILE_ERROR:
      return {
        ...state,
        updatingProfile: false,
        updatingProfileError: true,
      }

    // updating send reminders
    case HACKER_UPDATING_SEND_REMINDERS:
      return {
        ...state,
        updatingSendReminders: true,
        updatingSendRemindersError: false,
      }
    case HACKER_UPDATED_SENT_REMINDERS:
      return {
        ...state,
        updatingSendReminders: false,
        updatingSendRemindersError: true,
        profile: {
          ...state.profile,
          send_reminders: action.send_reminders,
        },
      }
    case HACKER_UPDATED_SENT_REMINDERS_ERROR:
      return {
        ...state,
        updatingSendReminders: false,
        updatingSendRemindersError: true,
      }

    // check username
    case HACKER_CHECKING_USERNAME:
      return {
        ...state,
        checkingUsername: true,
      }
    case HACKER_CHECKED_USERNAME:
      return {
        ...state,
        checkingUsername: false,
      }

    // code
    case HACKER_POSTED_CODE:
      return {
        ...state,
        latestCodePatchId: action.latestCodePatchId,
      }
    case HACKER_UPDATED_CODE:
      return {
        ...state,
        latestCodePassed: action.latestCodePassed,
        latestCodeStackTrace: action.latestCodeStackTrace,
      }
    case HACKER_GETTING_CODE_SUBMISSIONS:
      return {
        ...state,
        loadingCodeSubmissions: true,
      }
    case HACKER_RECEIVED_CODE_SUBMISSIONS:
      return {
        ...state,
        loadingCodeSubmissions: false,
        currentCodeSubmissions: action.codeSubmissions,
      }
    case HACKER_GETTING_CODE_SUBMISSIONS_ERROR:
      return {
        ...state,
        loadingCodeSubmissions: false,
        currentCodeSubmissions: [],
      }

    // organization list
    case HACKER_GETTING_ORGANIZATIONS:
      return {
        ...state,
        loadingOrganizations: true,
      }
    case HACKER_RECEIVED_ORGANIZATIONS:
      return {
        ...state,
        hasLoadedOrganizations: true,
        loadingOrganizations: false,
        organizationsList: action.organizations,
        organizationsHash: action.organizations.reduce((prev, curr) => {
          const newPrev = { ...prev }
          newPrev[curr.uuid] = curr
          return newPrev
        }, {}),
        mustPassCodingExercise: action.mustPassCodingExercise,
        showAllTraining: action.showAllTraining,
        showCodingChallenges: action.showCodingChallenges,
        showHackingChallenges: action.showHackingChallenges,
        mySjEnabled: action.mySjEnabled,
      }

    // team details
    case HACKER_GETTING_TEAMS_DETAILS:
      return {
        ...state,
        loadingCurrentTeamDetails: true,
        hasLoadedCurrentTeamDetails: false,
      }
    case HACKER_RECEIVED_TEAMS_DETAILS:
      return {
        ...state,
        loadingCurrentTeamDetails: false,
        hasLoadedCurrentTeamDetails: true,
        currentTeamDetails: action.currentTeamDetails,
      }
    case HACKER_PATCHING_TEAMS_DETAILS:
      return {
        ...state,
        loadingCurrentTeamDetails: true,
      }
    case HACKER_PATCHED_TEAMS_DETAILS:
      return {
        ...state,
        loadingCurrentTeamDetails: false,
        hasLoadedCurrentTeamDetails: true,
        currentTeamDetails: action.currentTeamDetails,
        mustPassCodingExercise: action.mustPassCodingExercise,
        organizationsHash: {
          ...state.organizationsHash,
          [action.organizationId]: {
            ...state.organizationsHash[action.organizationId],
            must_pass_coding_exercise: action.mustPassCodingExercise,
          },
        },
      }

    // team hackers
    case HACKER_GETTING_TEAMS_HACKERS:
      return {
        ...state,
        loadingCurrentTeamHackers: true,
        hasLoadedCurrentTeamHackers: false,
      }
    case HACKER_RECEIVED_TEAMS_HACKERS:
      return {
        ...state,
        loadingCurrentTeamHackers: false,
        hasLoadedCurrentTeamHackers: true,
        currentTeamHackers: action.currentTeamHackers,
      }
    case HACKER_RECEIVED_TEAMS_HACKERS_TOTAL:
      return {
        ...state,
        totalHackers: action.total,
      }
    case HACKER_SET_IS_LOADING_HACKERS:
      return {
        ...state,
        isLoadingHackers: action.isLoadingHackers,
      }
    case HACKER_SET_FILTER_HACKER:
      return {
        ...state,
        filterHacker: action.filterHacker,
      }
    case HACKER_SET_OFFSET_HACKER:
      return {
        ...state,
        offsetHackers: action.offsetHackers,
      }
    case HACKER_SET_ORDER_DIRECTION:
      return {
        ...state,
        orderHackersDirection: action.orderHackersDirection,
      }
    case HACKER_SET_ORDER:
      return {
        ...state,
        orderHackersBy: action.orderHackersBy,
      }
    // team hacker certificates
    case HACKER_RECEIVED_TEAM_CERTIFICATES:
      return {
        ...state,
        teamCertificates: action.teamCertificates,
      }
    // team hacker details
    case HACKER_GETTING_TEAMS_HACKERS_DETAILS:
      return {
        ...state,
        loadingCurrentHackerDetails: true,
        hasLoadedCurrentHackerDetails: false,
      }
    case HACKER_RECEIVED_TEAMS_HACKERS_DETAILS:
      return {
        ...state,
        loadingCurrentHackerDetails: false,
        hasLoadedCurrentHackerDetails: true,
        currentHackerDetails: action.currentHackerDetails,
      }

    // update team hacker details
    case HACKER_UPDATING_TEAMS_HACKERS_DETAILS:
      return {
        ...state,
        updatingTeamHackerDetails: true,
      }
    case HACKER_UPDATED_TEAMS_HACKERS_DETAILS:
      return {
        ...state,
        updatingTeamHackerDetails: false,
      }

    // team hacker code
    case HACKER_GETTING_TEAMS_HACKERS_CODE:
      return {
        ...state,
        loadingCurrentHackerCode: true,
        hasLoadedCurrentHackerCode: false,
      }
    case HACKER_RECEIVED_TEAMS_HACKERS_CODE:
      return {
        ...state,
        loadingCurrentHackerCode: false,
        hasLoadedCurrentHackerCode: true,
        currentHackerCode: action.code,
        teamMap: {
          ...state.teamMap,
          [action.organizationUUID]: {
            ...state.teamMap[action.organizationUUID],
            users: {
              ...get(state.teamMap, `[${action.organizationUUID}]users`, {}),
              [action.hackerUUID]: {
                ...get(
                  state.teamMap,
                  `[${action.organizationUUID}]users[${action.hackerUUID}]`,
                  {}
                ),
                code: {
                  ...get(
                    state.teamMap,
                    `[${action.organizationUUID}]users[${action.hackerUUID}].code`,
                    {}
                  ),
                  [action.contentUUID]: action.code,
                },
              },
            },
          },
        },
      }

    // team hacker add license
    case HACKER_ADDING_TEAMS_HACKERS_LICENSE:
      return {
        ...state,
        addingTeamHackerLicense: true,
      }
    case HACKER_ADDED_TEAMS_HACKERS_LICENSE:
      return {
        ...state,
        addingTeamHackerLicense: false,
        currentTeamDetails: {
          ...state.currentTeamDetails,
          available_licenses: state.currentTeamDetails.available_licenses - 1,
          available_license_types:
            state.currentTeamDetails.available_license_types.reduce(
              (prev, curr) => {
                let currentLicenses = curr.available_licenses
                if (curr.uuid === action.licenseTypeUUID) {
                  currentLicenses -= 1
                }
                if (currentLicenses > 0) {
                  prev.push({
                    ...curr,
                    available_licenses: currentLicenses,
                  })
                }
                return prev
              },
              []
            ),
        },
      }

    // team hacker remove license
    case HACKER_REMOVING_TEAMS_HACKERS_LICENSE:
      return {
        ...state,
        removingTeamHackerLicense: true,
      }
    case HACKER_REMOVED_TEAMS_HACKERS_LICENSE:
      if (
        state.currentTeamDetails.available_license_types.find(
          (item) => item.uuid === action.licenseTypeUUID
        )
      ) {
        return {
          ...state,
          removingTeamHackerLicense: false,
          currentTeamDetails: {
            ...state.currentTeamDetails,
            available_licenses: state.currentTeamDetails.available_licenses + 1,
            available_license_types:
              state.currentTeamDetails.available_license_types.map((item) => {
                if (item.uuid === action.licenseTypeUUID) {
                  return {
                    ...item,
                    available_licenses: item.available_licenses + 1,
                  }
                }
                return item
              }),
          },
        }
      }
      return {
        ...state,
        removingTeamHackerLicense: false,
        currentTeamDetails: {
          ...state.currentTeamDetails,
          available_licenses: state.currentTeamDetails.available_licenses + 1,
          available_license_types: (() => {
            const availableLicenseTypes = [
              ...(state.currentTeamDetails.available_license_types || []),
            ]
            availableLicenseTypes.push({
              name: action.licenseTypeName,
              uuid: action.licenseTypeUUID,
              available_licenses: 1,
            })
            return availableLicenseTypes
          })(),
        },
      }

    // team report lessons
    case HACKER_GETTING_TEAMS_REPORT_LESSONS:
      return {
        ...state,
        loadingReportLessons: true,
      }
    case HACKER_RECEIVED_TEAMS_REPORT_LESSONS:
      return {
        ...state,
        loadingReportLessons: false,
        reportTotalLessons: action.reportTotalLessons,
        reportLessons: action.reportLessons,
        totalAssignedLessons: action.totalAssignedLessons,
        completedAssignedLessons: action.completedAssignedLessons,
        totalTimeSpent: action.totalTimeSpent,
        totalCodeSubmissions: action.totalCodeSubmissions,
        totalUsersWithPlanCompleted: action.totalUsersWithPlanCompleted,
        totalHackers: action.totalHackers,
        totalTeams: action.totalTeams,
      }
    // team report users
    case HACKER_GETTING_TEAMS_REPORT_USERS:
      return {
        ...state,
        loadingReportUsers: true,
        errorReportUsers: false,
        reportUsers: [],
      }
    case HACKER_RECEIVED_TEAMS_REPORT_USERS:
      return {
        ...state,
        loadingReportUsers: false,
        reportUsers: action.reportUsers,
      }
    case HACKER_RECEIVED_TEAMS_REPORT_USERS_ERRORS:
      return {
        ...state,
        loadingReportUsers: false,
        errorReportUsers: true,
      }

    // org invite admin
    case HACKER_INVITING_ADMIN:
      return {
        ...state,
        invitingAdmin: true,
        invitingAdminError: false,
      }
    case HACKER_INVITED_ADMIN:
      return {
        ...state,
        invitingAdmin: false,
        invitingAdminError: false,
      }
    case HACKER_INVITED_ADMIN_ERROR:
      return {
        ...state,
        invitingAdmin: false,
        invitingAdminError: true,
      }

    // user subscribes
    case HACKER_USER_SUBSCRIBED:
      return {
        ...state,
        license: true,
      }

    case UPDATE_TEAM_USER:
      return {
        ...state,
        currentTeamHackers: state.currentTeamHackers.map((hacker) => {
          if (hacker.uuid === action.hackerUUID) {
            return {
              ...hacker,
              teams: action.teams,
            }
          }
          return hacker
        }),
      }
    case SET_IS_PICKING_PLAN:
      return {
        ...state,
        isPickingPlan: action.isPickingPlan,
      }
    default:
      return state
  }
}

export const getAllEmails = (organizationUUID) => (dispatch) => {
  return api({
    method: 'get',
    url: `${settings.urls.hacker}/organizations/${organizationUUID}/users`,
    withAuthToken: true,
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
    })
}

export const addUrlActivity = (contentUUID, url) => (dispatch, getState) => {
  const state = getState()
  if (state.auth.status === 'LOGGED_IN' && contentUUID) {
    api({
      method: 'post',
      url: `${settings.urls.hacker}/user/${state.hacker.profile.user_id}/content/${contentUUID}`,
      withAuthToken: true,
      data: {
        action: 'sandbox_url',
        url,
      },
    }).catch((error) => {
      console.error('error posting progress: ', error)
    })
  }
}

// eslint-disable-next-line no-console
const tempLog = () => {} // console.log

export function onboardUser(cognitoIdToken, onboardingToken, providerName) {
  let token = onboardingToken
  let licenseUUID = null
  let teamUUID = null
  let isTeam = false
  let arr
  if (typeof onboardingToken === 'string' && onboardingToken.length > 15) {
    const decoded = Base64.decode(`${onboardingToken}==`)
    if (decoded.indexOf('@') > -1) {
      arr = decoded.split('@')
      isTeam = true
      teamUUID = arr[1]
    } else {
      arr = decoded.split('|')
      licenseUUID = arr[1]
    }
    token = arr[0]
  }
  tempLog('TEMP: onboardUser', {
    cognitoIdToken,
    onboardingToken,
    providerName,
    licenseUUID,
    teamUUID,
    isTeam,
    token,
  })

  return (dispatch) => {
    tempLog('TEMP: dispatch HACKER_POSTING_ONBOARDING')
    dispatch({
      type: HACKER_POSTING_ONBOARDING,
    })

    let data = {}
    if (onboardingToken !== null) {
      if (isTeam) {
        data = { token, team_uuid: teamUUID }
      } else {
        data = { token, license_uuid: licenseUUID }
      }
    } else if (providerName !== null) {
      data = { provider_name: providerName }
    }
    tempLog('TEMP: calling POST /onboard', { data })

    return new Promise((resolve, reject) => {
      axios(`${settings.urls.hacker}/onboard`, {
        method: 'post',
        url: `${settings.urls.hacker}/onboard`,
        headers: {
          Authorization: cognitoIdToken,
        },
        data,
      })
        .then((response) => {
          tempLog('TEMP: got onboard response', { data: response.data })
          dispatch({
            type: HACKER_POSTED_ONBOARDING,
            teams: response.data,
          })
          window.localStorage.removeItem('hackedu.teams.invite_token')
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  }
}

export function getProfile() {
  return (dispatch) => {
    dispatch({
      type: HACKER_GETTING_PROFILE,
    })
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/profile`,
      withAuthToken: true,
    })
      .then((response) => {
        dispatch({
          type: HACKER_RECEIVED_PROFILE,
          uuid: response.data.uuid,
          email: response.data.email,
          username: response.data.username,
          points: response.data.points,
          send_reminders: response.data.send_reminders,
          license: response.data.license,
          has_finished_training: response.data.has_finished_training,
          last_completed_at: response.data.last_completed_at,
          settings: response.data.settings,
          super_admin: response.data.super_admin || undefined,
          languages: response.data.languages,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function updateLanguages(languages) {
  return (dispatch) => {
    dispatch({
      type: HACKER_UPDATING_PROFILE,
    })

    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/profile`,
      withAuthToken: true,
      data: { languages: Object.keys(languages) },
    })
      .then((response) => {
        if (response.success === false) {
          dispatch({
            type: HACKER_UPDATED_PROFILE_ERROR,
          })
          return false
        }
        dispatch({
          type: HACKER_UPDATED_PROFILE,
          languages,
        })
        return true
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: HACKER_UPDATED_PROFILE_ERROR,
        })
        return false
      })
  }
}

export function updateUsername(username) {
  return (dispatch) => {
    dispatch({
      type: HACKER_UPDATING_PROFILE,
    })

    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/profile`,
      withAuthToken: true,
      data: { username },
    })
      .then((response) => {
        if (response.success === false) {
          dispatch({
            type: HACKER_UPDATED_PROFILE_ERROR,
          })
          return false
        }
        dispatch({
          type: HACKER_UPDATED_PROFILE,
          username,
        })
        return true
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: HACKER_UPDATED_PROFILE_ERROR,
        })
        return false
      })
  }
}

export function checkUsername(username) {
  return (dispatch) => {
    dispatch({
      type: HACKER_CHECKING_USERNAME,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/username`,
      withAuthToken: true,
      params: { username },
    })
      .then((response) => {
        dispatch({
          type: HACKER_CHECKED_USERNAME,
        })
        return response.data.available
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: HACKER_CHECKED_USERNAME,
        })
        return false
      })
  }
}

export function updateSendReminders(sendReminders) {
  return (dispatch) => {
    dispatch({
      type: HACKER_UPDATING_SEND_REMINDERS,
    })

    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/profile`,
      withAuthToken: true,
      data: {
        send_reminders: sendReminders,
      },
    })
      .then((response) => {
        if (response.success === false) {
          dispatch({
            type: HACKER_UPDATED_SENT_REMINDERS_ERROR,
          })
          return false
        }
        dispatch({
          type: HACKER_UPDATED_SENT_REMINDERS,
          send_reminders: sendReminders,
        })
        return true
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: HACKER_UPDATED_SENT_REMINDERS_ERROR,
        })
        return false
      })
  }
}

export function getOrganizations() {
  return (dispatch, getState) => {
    dispatch({
      type: HACKER_GETTING_ORGANIZATIONS,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations`,
      withAuthToken: true,
    })
      .then((response) => {
        let showAllTraining = false
        let mustPassCodingExercise = true
        let showCodingChallenges = false
        let showHackingChallenges = false
        let mySjEnabled = false

        // if the user is not in an organization, show all trainin
        if (response.data.length === 0) {
          showAllTraining = true
        }
        if (response.data.length === 0) {
          showCodingChallenges = true
        }
        for (let team of response.data) {
          // eslint-disable-line
          if (team.must_pass_coding_exercise === false) {
            mustPassCodingExercise = false
          }

          // if any organization has showAllTraining on, set it to true
          if (team.show_all_training === true) {
            showAllTraining = true
          }

          if (team.show_coding_challenges === true) {
            showCodingChallenges = true
          }

          if (team.show_hacking_challenges === true) {
            showHackingChallenges = true
          }

          if (team.mysj_enabled === true) {
            mySjEnabled = true
          }

          if (
            showAllTraining &&
            !mustPassCodingExercise &&
            showCodingChallenges
          ) {
            break
          }
        }

        dispatch({
          type: HACKER_RECEIVED_ORGANIZATIONS,
          organizations: response.data,
          mustPassCodingExercise,
          showAllTraining,
          showCodingChallenges,
          showHackingChallenges,
          mySjEnabled,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getTeamDetails(organizationId) {
  return (dispatch) => {
    dispatch({
      type: HACKER_GETTING_TEAMS_DETAILS,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}`,
      withAuthToken: true,
    })
      .then((response) => {
        dispatch({
          type: HACKER_RECEIVED_TEAMS_DETAILS,
          currentTeamDetails: response.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function patchTeamDetails(organizationId, params = {}) {
  return (dispatch) => {
    dispatch({
      type: HACKER_PATCHING_TEAMS_DETAILS,
    })

    if (params.showAllTraining === false) {
      params.showCodingChallenges = false
      params.showHackingChallenges = false
    } else if (params.showAllTraining === true) {
      params.showCodingChallenges = true
      params.showHackingChallenges = true
    }

    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationId}`,
      withAuthToken: true,
      data: {
        must_pass_coding_exercise: params.mustPassCodingExercise,
        show_all_training: params.showAllTraining,
        show_coding_challenges: params.showCodingChallenges,
        show_hacking_challenges: params.showHackingChallenges,
      },
    })
      .then((response) => {
        dispatch({
          type: HACKER_PATCHED_TEAMS_DETAILS,
          currentTeamDetails: response.data,
          mustPassCodingExercise: response.data.must_pass_coding_exercise,
          organizationId,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function publicApi(organizationUUID, active) {
  return (dispatch) => {
    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/api`,
      withAuthToken: true,
      data: {
        active,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_API_TOKEN,
          api_token: data.token,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function setFilterHacker(filterHacker) {
  return {
    type: HACKER_SET_FILTER_HACKER,
    filterHacker,
  }
}

export function setOffsetHackers(offsetHackers) {
  return {
    type: HACKER_SET_OFFSET_HACKER,
    offsetHackers,
  }
}

export function setOrderHackersDirection(orderHackersDirection) {
  return {
    type: HACKER_SET_ORDER_DIRECTION,
    orderHackersDirection,
  }
}

export function setOrderHackersBy(orderHackersBy) {
  return {
    type: HACKER_SET_ORDER,
    orderHackersBy,
  }
}

export function getAutoCompleteHackers(organizationUUID, filter) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/hackers`,
      withAuthToken: true,
      params: {
        filter,
        limit: 10,
      },
    })
  }
}

export const cleanHackersList = () => ({
  type: HACKER_RECEIVED_TEAMS_HACKERS,
  currentTeamHackers: [],
})

export function getHackers(
  organizationId,
  disableLoader = false,
  hacker = false,
  sortBy = 'email',
  asc = true,
  callback = false
) {
  return (dispatch, getState) => {
    if (!disableLoader) {
      dispatch({
        type: HACKER_GETTING_TEAMS_HACKERS,
      })
    }
    const state = getState()
    dispatch({
      type: HACKER_SET_IS_LOADING_HACKERS,
      isLoadingHackers: true,
    })
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers`,
      withAuthToken: true,
      params: {
        filter: state.hacker.filterHacker,
        order: sortBy,
        offset: state.hacker.offsetHackers,
        limit: state.hacker.hackersPerPage,
        orderDirection: JSON.stringify(asc),
      },
    })
      .then((response) => {
        dispatch({
          type: HACKER_RECEIVED_TEAMS_HACKERS,
          currentTeamHackers: [...response.data.users],
        })
        if (hacker) {
          dispatch(setSelectedUser(organizationId, hacker))
        }
        dispatch({
          type: HACKER_RECEIVED_TEAMS_HACKERS_TOTAL,
          total: response.data.total,
        })
        dispatch({
          type: HACKER_SET_IS_LOADING_HACKERS,
          isLoadingHackers: false,
        })
        if (typeof callback === 'function') {
          callback()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getHackersAndExport(organizationId, callback) {
  return (dispatch, getState) => {
    const state = getState()
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers`,
      withAuthToken: true,
      params: {
        filter: state.hacker.filterHacker,
        order: state.hacker.orderHackersBy,
        offset: 0,
        limit: -1,
        orderDirection: state.hacker.orderHackersDirection,
      },
    })
      .then(({ data }) => {
        const headers = ['Email', 'Role', 'Teams', '% Complete']
        const rows = []
        rows.push(headers)
        data.users.forEach((row) => {
          const hackerRow = []
          hackerRow.push(row.email)
          hackerRow.push(row.role)
          let teams = ''
          if (row.teams.length > 1) {
            teams = `"${row.teams.map(({ name }) => name).join(', ')}"`
          } else {
            teams = row.teams.map(({ name }) => name).join(', ')
          }
          hackerRow.push(teams)
          hackerRow.push(`${row.progress} %`)
          rows.push(hackerRow)
        })
        const encodedData = encodeURIComponent(
          rows
            .map((e) => {
              return e.join(',')
            })
            .join('\n')
        )

        const link = document.createElement('a')
        link.setAttribute('href', `data:text/csv;charset=utf-8,${encodedData}`)
        link.setAttribute(
          'download',
          `users_data_${moment().format('YYYY-MM-DD')}.csv`
        )
        document.body.appendChild(link)
        callback()
        link.click()
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getHackerDetails(organizationId, hackerId) {
  return (dispatch) => {
    dispatch({
      type: HACKER_GETTING_TEAMS_HACKERS_DETAILS,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerId}`,
      withAuthToken: true,
    })
      .then((response) => {
        dispatch({
          type: HACKER_RECEIVED_TEAMS_HACKERS_DETAILS,
          currentHackerDetails: response.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function updateHackerRole(
  organizationId,
  hackerId,
  role,
  callback = false
) {
  return (dispatch, getState) => {
    dispatch({
      type: HACKER_UPDATING_TEAMS_HACKERS_DETAILS,
    })
    const state = getState()

    return api({
      method: 'patch',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerId}`,
      withAuthToken: true,
      data: { role },
    })
      .then((response) => {
        dispatch({
          type: HACKER_UPDATED_TEAMS_HACKERS_DETAILS,
          currentTeamHackers: response.data,
        })
        if (typeof callback === 'function') {
          callback(state.team.currentTeam.users, hackerId)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function addLicense(organizationId, hackerId, licenseTypeUUID) {
  return (dispatch) => {
    dispatch({
      type: HACKER_ADDING_TEAMS_HACKERS_LICENSE,
    })

    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerId}/license`,
      withAuthToken: true,
      data: {
        licenseTypeUUID,
      },
    })
      .then(() => {
        dispatch({
          type: HACKER_ADDED_TEAMS_HACKERS_LICENSE,
          licenseTypeUUID,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function removeLicense(
  organizationId,
  hackerId,
  licenseTypeName,
  licenseTypeUUID
) {
  return (dispatch) => {
    dispatch({
      type: HACKER_REMOVING_TEAMS_HACKERS_LICENSE,
    })

    return api({
      method: 'delete',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerId}/license`,
      withAuthToken: true,
    })
      .then(() => {
        dispatch({
          type: HACKER_REMOVED_TEAMS_HACKERS_LICENSE,
          licenseTypeName,
          licenseTypeUUID,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getCodeSubmissions(contentId, callback) {
  return (dispatch) => {
    dispatch({
      type: HACKER_GETTING_CODE_SUBMISSIONS,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/code`,
      withAuthToken: true,
      params: { content_uuid: contentId },
    })
      .then((response) => {
        dispatch({
          type: HACKER_RECEIVED_CODE_SUBMISSIONS,
          codeSubmissions: response.data.code_submissions,
        })
        if (typeof callback === 'function') {
          callback()
        }
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: HACKER_GETTING_CODE_SUBMISSIONS_ERROR,
        })
      })
  }
}

/**
 * This function does the same thing as getCodeSubmissions, except that it bakes in some additional
 * logic that was previously handled in the lesson UI: namely, it checks timestamps and flags old
 * tests as stale, and it also checks whether the most recent test is still pending and begins
 * polling it for results if so.
 *
 * TODO: Create an entirely separate interface for test submissions; bathe api-hacker in fire.
 */
export function getCodeSubmissionsEx(contentId) {
  return (dispatch, getState) => {
    dispatch({ type: HACKER_GETTING_CODE_SUBMISSIONS })

    // Use an extra 'stale' flag to indicate whether a submission is older than a few minutes, in
    // which case we'll consider it finished regardless of whether it actually has a result
    const submissionIsStale = (submission) =>
      getLocalDateFromUTC(submission.submitted_at).isBefore(
        moment().subtract(5, 'minutes')
      )
    const applyStaleFlag = (submission) => ({
      ...submission,
      stale: submissionIsStale(submission),
    })

    // Hit api-hacker's GET /code endpoint to fetch user's code submissions for this content
    const url = `${settings.urls.hacker}/code`
    const params = { content_uuid: contentId }
    api({ method: 'get', url, withAuthToken: true, params })
      .then((response) => {
        // Apply our extra 'stale' flag to all submissions
        if (response.data.code_submissions.length > 0) {
          const head = applyStaleFlag(response.data.code_submissions[0])
          const tail = response.data.code_submissions
            .slice(1)
            .map(applyStaleFlag)
          dispatch({
            type: HACKER_RECEIVED_CODE_SUBMISSIONS,
            codeSubmissions: [head].concat(tail),
          })

          // If we have a non-stale test in progress, update application state to indicate that we're
          // running tests, and begin polling that test's status until it's finished
          const headIsFinished = head.test.passed || head.test.name
          if (!headIsFinished && !head.stale) {
            dispatch(setRunningTests(true))
            pollCodeUntilPassed(head.id, contentId, dispatch, getState)
          }
        } else {
          dispatch({
            type: HACKER_RECEIVED_CODE_SUBMISSIONS,
            codeSubmissions: [],
          })
        }
      })
      .catch((error) => {
        console.error(error)
        dispatch({ type: HACKER_GETTING_CODE_SUBMISSIONS_ERROR })
      })
  }
}

const pollCodeUntilPassed = (id, contentId, dispatch, getState, callback) => {
  api({
    method: 'get',
    url: `${settings.urls.hacker}/code/${id}`,
    withAuthToken: true,
  })
    .then((response) => {
      // If we're running in an iframe within the SJ app and the submission we've been
      // polling is now complete with all tests passed, notify the SJ app so it can sync
      // lesson status
      if (response.data.passed) {
        notifyParentSjApp({ type: 'lesson-status-changed' })
      }

      if (response.data.passed === null) {
        setTimeout(() => {
          return pollCodeUntilPassed(
            id,
            contentId,
            dispatch,
            getState,
            callback
          )
        }, 1000)
      } else if (callback) {
        callback(response.data)
        dispatch(
          getCodeSubmissions(contentId, () => {
            dispatch(setRunningTests(false))
          })
        )
        dispatch({
          type: REPL_SET_SUBMITTING_PATCH,
          submittingPatch: false,
        })
      } else {
        dispatch({
          type: HACKER_UPDATED_CODE,
          latestCodePassed: response.data.passed,
          latestCodeStackTrace: response.data.test.error_stack_trace,
        })
        dispatch(
          getCodeSubmissions(contentId, () => {
            dispatch(setRunningTests(false))
          })
        )
        dispatch({
          type: REPL_SET_SUBMITTING_PATCH,
          submittingPatch: false,
        })
      }
      dispatch({
        type: SET_PULLING_CODE_UNTIL_PASS,
        pullingCodeUntilPass: false,
      })
    })
    .catch((error) => {
      console.error(error)
    })
}

export const pullCodeIfNotPulling = (id, contentId) => {
  return (dispatch, getState) => {
    return pollCodeUntilPassed(id, contentId, dispatch, getState, () => {
      dispatch({
        type: SET_PULLING_CODE_UNTIL_PASS,
        pullingCodeUntilPass: false,
      })
    })
  }
}

export function addCode(
  contentId,
  engine,
  code,
  callback,
  instantCallback,
  isNative = false,
  errorCallback
) {
  return (dispatch, getState) => {
    const state = getState()

    let k8s_override = state.router.location.query.k8s_override
    return api({
      method: 'post',
      url: `${settings.urls.hacker}/code`,
      withAuthToken: true,
      data: {
        content_uuid: contentId,
        engine,
        code,
        k8s_override,
        api_url: state.codeReview.sandboxApiUrl,
      },
    })
      .then((response) => {
        dispatch({
          type: HACKER_ADD_CODE_SUBMISSION,
          codeSubmission: {
            id: response.data.id,
            content_uuid: contentId,
            engine,
            code,
            submitted_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            test: {
              passed: null,
            },
          },
        })
        if (typeof instantCallback === 'function') {
          instantCallback(response.data)
        }
        if (isNative) {
          callback()
        } else {
          dispatch({
            type: SET_PULLING_CODE_UNTIL_PASS,
            pullingCodeUntilPass: true,
          })
          pollCodeUntilPassed(
            response.data.id,
            contentId,
            dispatch,
            getState,
            callback
          )
        }
        dispatch({
          type: HACKER_POSTED_CODE,
          latestCodePatchId: response.data.id,
        })
      })
      .catch((error) => {
        console.error(error)
        if (errorCallback) {
          errorCallback(error)
        }
      })
  }
}

export function getTeamsReportLessons(organizationId, contentId) {
  return (dispatch) => {
    dispatch({
      type: HACKER_GETTING_TEAMS_REPORT_LESSONS,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/report/content?content_uuid=${contentId}`,
      withAuthToken: true,
    })
      .then((response) => {
        let lessonsRemaining = response.data.burnDownData.total_lessons
        const reportLessons = [
          { date: '', lessons: response.data.burnDownData.total_lessons },
        ]
        response.data.burnDownData.counts.map((day) => {
          lessonsRemaining -= day.lessons
          const d = {
            date: day.date,
            lessons: lessonsRemaining,
          }
          reportLessons.push(d)
        })

        dispatch({
          type: HACKER_RECEIVED_TEAMS_REPORT_LESSONS,
          reportTotalLessons: response.data.burnDownData.total_lessons,
          reportLessons,
          totalAssignedLessons: response.data.total_assigned_lessons,
          completedAssignedLessons: response.data.completed_assigned_lessons,
          totalTimeSpent: response.data.total_time,
          totalCodeSubmissions: response.data.total_code_submissions,
          totalUsersWithPlanCompleted:
            response.data.total_users_with_training_plans_completed,
          totalHackers: response.data.total_hackers,
          totalTeams: response.data.total_teams,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getCertificatesOrganization(
  organizationUUID,
  teamUUID,
  callback,
  days = null
) {
  return (dispatch) => {
    const url = teamUUID
      ? `${settings.urls.hacker}/organizations/${organizationUUID}/teams/${teamUUID}/report/certificates`
      : `${settings.urls.hacker}/organizations/${organizationUUID}/report/certificates`

    api({
      method: 'post',
      url,
      withAuthToken: true,
      data: {
        days,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: HACKER_RECEIVED_TEAM_CERTIFICATES,
          teamCertificates: data,
        })
        callback()
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getCSVReportUsers(
  organizationUUID,
  contentUUIDs,
  team,
  callback,
  days = null
) {
  return (dispatch) => {
    api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/report/download`,
      withAuthToken: true,
      data: {
        content_uuids: contentUUIDs,
        team,
        days,
      },
    })
      .then(({ data }) => {
        const headers = data[0].content.map((content) => {
          let header = ''
          if (content.content_type_id === 2) {
            header += 'Hacking - '
          } else if (content.content_type_id === 4) {
            header += `Coding - ${settings.engines[content.language].replace(
              '#',
              'Sharp'
            )} - ${settings.frameworks[content.framework]} - `
          }
          header += content.name.replace(/,/, '')
          return header
        })
        headers.unshift('Developer \\ Lesson')
        const rows = []
        rows.push(headers)
        data.forEach((student) => {
          const hackerRow = []
          hackerRow.push(student.email)
          student.content.forEach((content) => {
            hackerRow.push(content.completed_at || ' - ')
          })

          rows.push(hackerRow)
        })
        const csvContent = `data:text/csv;charset=utf-8,${rows
          .map((e) => {
            return e.join(',')
          })
          .join('\n')}`
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'HackEdu_exported_data.csv')
        document.body.appendChild(link)
        link.click()

        return callback()
      })
      .catch((error) => {
        return callback()
      })
  }
}

export function getTeamsReportUsers(
  organizationId,
  contentIds,
  teamId,
  completed
) {
  return (dispatch) => {
    dispatch({
      type: HACKER_GETTING_TEAMS_REPORT_USERS,
    })

    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationId}/report/users`,
      withAuthToken: true,
      data: {
        content_uuids: Array.from(contentIds),
        team_uuid: teamId,
        completed,
      },
    })
      .then((response) => {
        dispatch({
          type: HACKER_RECEIVED_TEAMS_REPORT_USERS,
          reportUsers: response.data,
        })
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: HACKER_RECEIVED_TEAMS_REPORT_USERS_ERRORS,
        })
      })
  }
}

export function inviteAdmin(organizationId, email) {
  return (dispatch) => {
    dispatch({
      type: HACKER_INVITING_ADMIN,
    })

    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationId}/admin-invite`,
      withAuthToken: true,
      data: { email },
    })
      .then((response) => {
        if (response.success === false) {
          dispatch({
            type: HACKER_INVITED_ADMIN_ERROR,
          })
          console.error('error inviting admin')
          return false
        }
        dispatch({
          type: HACKER_INVITED_ADMIN,
        })
        return true
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: HACKER_INVITED_ADMIN_ERROR,
        })
      })
  }
}

export function subscribe() {
  return {
    type: HACKER_USER_SUBSCRIBED,
  }
}

export function getInviteToken() {
  return window.localStorage.getItem('hackedu.teams.invite_token')
}

export function setIsPickingPlan(isPickingPlan) {
  return {
    type: SET_IS_PICKING_PLAN,
    isPickingPlan,
  }
}

export function deleteHackerFromOrganization(
  organizationId,
  hackerUUID,
  callback
) {
  return (dispatch) => {
    return api({
      method: 'delete',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerUUID}`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        if (data.success) {
          callback(false)
        } else {
          callback(true)
        }
      })
      .catch((error) => {
        console.error(error)
        callback(true)
      })
  }
}

export function updateScratchpadStatus(enabled) {
  return (dispatch) => {
    return api({
      method: 'post',
      url: `${settings.urls.hacker}/settings`,
      withAuthToken: true,
      data: {
        scratchpad: {
          enabled,
        },
      },
    })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: SET_SCRATCHPAD_ENABLED,
            enabled,
          })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export function updateScratchpadContent(notes, callback) {
  return (dispatch) => {
    return api({
      method: 'post',
      url: `${settings.urls.hacker}/settings`,
      withAuthToken: true,
      data: {
        scratchpad: {
          notes,
        },
      },
    })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: SET_SCRATCHPAD_NOTES,
            notes,
          })
          if (callback) callback()
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
}
