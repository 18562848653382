import React from 'react'

import Icon from 'app/views/components/Icon'

import './styles.less'

function ContentIcon(props) {
  const {
    license,
    requiresSubscription,
    isActiveForUser,
    lessonInProgress,
    mustPassCodingExercise,
    hasTest,
    hasCodeSubmission,
    hasPassedContent,
    hasCompletedContent,
    marginTop,
    small,
    disabled,
  } = props

  // calculate status, ratio
  let status, ratio

  // all content starts out as either locked or unlocked
  if (
    (!license && requiresSubscription) ||
    (requiresSubscription && !isActiveForUser)
  ) {
    status = 'locked'
    ratio = small ? 0.9 : 1
  } else {
    status = 'unlocked'
    ratio = small ? 0.6 : 0.8
  }

  // has user started the content?
  if (lessonInProgress) {
    // should we check code submissions & tests?
    if (mustPassCodingExercise && hasTest) {
      if (!hasCodeSubmission) {
        status = 'started'
      } else if (!hasPassedContent) {
        status = 'not_passed'
      } else {
        status = 'completed'
      }
    }

    // simple pass/fail
    else {
      if (!hasCompletedContent) {
        status = 'started'
      } else {
        status = 'completed'
      }
    }
  }

  const icon = ((s) => {
    switch (s) {
      case 'locked':
        return 'lock'
      case 'not_passed':
        return 'close'
      case 'completed':
        return 'check'
      default:
        return ''
    }
  })(status)

  return (
    <div
      className='content-progress-icon uk-margin-small-right uk-inline'
      style={marginTop ? { marginTop } : {}}
    >
      <div
        className='icon-border inline-flex justify-center items-center'
        data-uk-toggle='target: #modal-content-icon'
        data-status={status}
        data-size={small ? 'small' : 'normal'}
        data-disabled={disabled ? 'true' : 'false'}
      >
        {icon !== '' && <Icon name={icon} ratio={ratio} />}
      </div>
    </div>
  )
}

export default ContentIcon
