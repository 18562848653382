import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import Ansi from 'ansi-to-react'
import { VScroll } from 'app/views/core/Scroll'

import moment from 'moment'

const Line = ({ timestamp, text }) => {
  const TIMESTAMP_COLOR_START = '\u{1b}[38;2;168;168;168m'
  const TIMESTAMP_COLOR_END = '\u{1b}[0m'
  const timestampPrefix =
    TIMESTAMP_COLOR_START +
    moment(timestamp).format('HH:mm:ss') +
    TIMESTAMP_COLOR_END
  return <Ansi>{`${timestampPrefix} ${text}`}</Ansi>
}
const ReplOutputMessage = () => (
  <>
    <code>Output from your code will appear here.</code>
    <code>&nbsp;</code>
    <code>After adding print statements in the Code Editor:</code>
    <code>- Click "Save Code" to apply your changes to the sandbox.</code>
    <code>- Use the browser tab to make the sandbox run your code.</code>
  </>
)
const MultiFileOutputMessage = () => (
  <>
    <code>
      Output from your app, including any print statements you add, will appear
      here.
    </code>
    <code>&nbsp;</code>
    <code>After making the desired changes in Code Editor:</code>
    <code>- Save your changes to apply them to the sandbox.</code>
    <code>- Switch to the browser tab and reload the page.</code>
  </>
)

function SandboxOutputFeed(props) {
  const { hasCode, hasMultiFileCode, lines } = props
  const scrollRef = useRef()
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [lines.length])

  return (
    <div className='sandbox-output-feed'>
      <VScroll ref={scrollRef}>
        <div className='sandbox-output-lines'>
          {lines.length > 0
            ? lines.map(({ timestamp, text }, i) => (
                <Line key={i} timestamp={timestamp} text={text} />
              ))
            : hasCode &&
              (hasMultiFileCode ? (
                <MultiFileOutputMessage />
              ) : (
                <ReplOutputMessage />
              ))}
        </div>
      </VScroll>
    </div>
  )
}
SandboxOutputFeed.propTypes = {
  hasCode: PropTypes.bool.isRequired,
  hasMultiFileCode: PropTypes.bool.isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default SandboxOutputFeed
