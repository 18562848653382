import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { VBox } from 'app/views/core/Box'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import ContentModal from '../../common/ContentModal'

const Container = ({ children }) => (
  <div
    style={{
      height: '100%',
      padding: 40,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff', // somehow this affects layout: remove it and the container will overflow the parent div (wtf css)
    }}
  >
    {children}
  </div>
)
function SubmitFlagModal(props) {
  const { canSubmit, isSubmitting, errorText, onSubmit, onClose } = props
  const [flagText, setFlagText] = useState('')
  return (
    <ContentModal
      id='submit-flag-modal'
      width='70vw'
      height='max(500px, 80vh)'
      onClose={onClose}
    >
      <Container>
        <div>
          <h2>Challenge Flag</h2>
          <p>
            Follow the challenge instructions to find the flag. Once you've
            found it, paste it below and click <b>Submit Flag</b>.
          </p>
          {errorText && errorText.length > 0 && (
            <p className='text-danger'>{errorText}</p>
          )}
        </div>
        <div
          style={{ flexGrow: 1, backgroundColor: '#cff', position: 'relative' }}
        >
          <textarea
            className='uk-textarea'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              resize: 'none',
            }}
            autoComplete='off'
            placeholder='Flag'
            disabled={isSubmitting}
            value={flagText}
            onChange={(event) => setFlagText(event.target.value)}
          />
        </div>
        <PrimaryButton
          active={canSubmit && flagText.length > 0}
          onClick={() => onSubmit(flagText)}
          size='small'
          label='Submit Flag'
          customCss={'whitespace-nowrap my-5'}
        />
      </Container>
    </ContentModal>
  )
}
SubmitFlagModal.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SubmitFlagModal
