import React from 'react'

import Icon from 'app/views/components/Icon'
import settings from 'settings'

import FormatedMinutes from '../FormatedMinutes'

class Content extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: undefined,
    }
    this.onClickPlus = this.onClickPlus.bind(this)
    this.renderExtra = this.renderExtra.bind(this)
  }

  onClickPlus(contentUUID) {
    const { active } = this.state
    this.setState({
      active: contentUUID === active ? undefined : contentUUID,
    })
  }

  renderExtra(content) {
    const {
      hackers_with_incomplete: hackersWithIncomplete,
      hackers_with_completed: hackersWithCompleted,
    } = content
    const biggerLength =
      hackersWithCompleted.length > hackersWithIncomplete.length
        ? hackersWithCompleted.length
        : hackersWithIncomplete.length
    const rows = []
    rows.push(
      <tr key='header'>
        <td
          colSpan='2'
          className='text-center'
          style={{ background: '#f8f8f8' }}
        >
          <div className='flex items-center justify-center'>
            <Icon
              name='check'
              className='text-success uk-margin-small-right'
            />
            <span>Completed</span>
          </div>
        </td>
        <td
          colSpan='3'
          className='text-center'
          style={{ background: '#f8f8f8' }}
        >
          <div className='flex items-center justify-center'>
            <Icon
              name='close'
              className='text-danger uk-margin-small-right'
            />
            <span>Incomplete</span>
          </div>
        </td>
      </tr>
    )
    for (let i = 0; i < biggerLength; i += 1) {
      rows.push(
        <tr key={i}>
          <td colSpan={2} className='text-center'>
            {hackersWithCompleted[i] ? hackersWithCompleted[i].email : ' - '}
          </td>
          <td colSpan={3} className='text-center'>
            {hackersWithIncomplete[i] ? hackersWithIncomplete[i].email : ' - '}
          </td>
        </tr>
      )
    }
    return rows
  }

  renderContentTitle(content) {
    if (content.type === 'coding_challenges') {
      return `${settings.apps[content.app]} ${content.content_title} (${
        settings.engines[content.language.name]
      } - ${settings.frameworks[content.framework.name]})`
    }
    return content.content_title
  }

  render() {
    const { props } = this
    const { active } = this.state

    if (props.content.length === 0) {
      return <p className='text-center'>No users have started this phase</p>
    }
    return (
      <table className='uk-table uk-table-divider uk-table-middle'>
        <thead>
          <tr>
            <th>Content</th>
            <th className='text-center'>Average time spent</th>
            <th className='text-center'>% Completed in phase</th>
            <th className='text-center'>% Completed total</th>
            <th />
          </tr>
        </thead>
        <tbody className='text-sm'>
          {props.content.map((content) => (
            <React.Fragment key={content.content_uuid}>
              <tr>
                <td>{this.renderContentTitle(content)}</td>
                <td className='text-center'>
                  <FormatedMinutes
                    isText
                    minutes={content.total_time_hackers_in_phase / 60}
                  />
                </td>
                <td className='text-center'>
                  {`${(
                    (content.total_hackers_in_phase_completed_content /
                      content.total_hackers_in_phase) *
                      100 || 0
                  ).toFixed(0)} % of users`}
                </td>
                <td className='text-center'>
                  {`${(
                    (content.total_hackers_completed_content /
                      content.total_hackers_available_current_past_content) *
                      100 || 0
                  ).toFixed(0)} % of users`}
                </td>
                <td>
                  <button
                    onClick={this.onClickPlus.bind(this, content.content_uuid)}
                    className={`uk-button ${
                      active === content.content_uuid
                        ? 'uk-button-danger'
                        : 'uk-button-primary'
                    } uk-button-small`}
                    type='button'
                    style={{
                      padding: '0px 10px',
                    }}
                  >
                    <Icon
                      name={`${
                        active === content.content_uuid ? 'subtract' : 'add'
                      }`}
                      ratio={0.7}
                    />
                  </button>
                </td>
              </tr>
              {this.state.active === content.content_uuid &&
                this.renderExtra(content)}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    )
  }
}

export default Content
