import React from 'react'
import { connect } from 'react-redux'
import settings from 'settings'

import PrimaryButton from '../../../components/Buttons/PrimaryButton'

import { updateLanguages } from 'app/state/modules/hacker'

import GenericModal from '../GenericModal'
import Icon from '../../Icon'

class ModalPreferLanguages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: {},
      filteredLanguages: [],
    }
    this.modal = React.createRef()
    this.renderLanguages = this.renderLanguages.bind(this)
    this.onSelectLanguage = this.onSelectLanguage.bind(this)
    this.renderIcon = this.renderIcon.bind(this)
    this.isSpecialIcon = this.isSpecialIcon.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  componentDidMount() {
    if (this.props.languages) {
      if (Object.keys(this.props.languages).length === 0) {
        this.modal.current.show()
      } else {
        this.setState({
          selected: { ...this.props.languages },
        })
      }
    }

    // Due to duplicates in the backend data from api-hacker and it no longer being maintained,
    // this Set is used to filter out duplicates on the frontend.
    const seen = new Set()

    const filteredLanguages = settings.availableLanguages.filter((item) => {
      let languageName = item.language

      if (languageName === 'go') {
        languageName = 'golang'
      }

      const duplicate = seen.has(languageName)
      seen.add(languageName)
      return !duplicate
    })

    this.setState({ filteredLanguages })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.languages && this.props.languages) {
      if (Object.keys(this.props.languages).length === 0) {
        this.modal.current.show()
      } else {
        this.setState({
          selected: {
            ...this.props.languages,
          },
        })
      }
    }
  }

  isSpecialIcon(iconName) {
    const specialIcons = ['golang', 'clojure', 'cobol']
    return specialIcons.includes(iconName)
  }

  onSave() {
    this.props.updateLanguages({ ...this.state.selected })
    this.modal.current.hide()
  }

  onSelectLanguage(language) {
    const { selected } = this.state
    if (language !== 'none' && selected.none) {
      delete selected.none
    }
    if (!selected[language]) {
      selected[language] = true
    } else {
      delete selected[language]
    }
    this.setState({
      selected: language === 'none' ? { none: true } : selected,
    })
  }

  renderSelectedItems() {
    return Object.keys(this.props.languages || [])
      .sort()
      .map((iconName) => {
        if (iconName === 'none') {
          return (
            <label
              className='uk-label uk-label-primary'
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              NO LANGUAGE
            </label>
          )
        }
        return (
          <div
            key={iconName}
            uk-tooltip={settings.engines[iconName]}
            className='uk-margin-right'
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            {this.isSpecialIcon(iconName) ? (
              <img
                src={`/static/images/frameworks/${iconName}.png`}
                alt={iconName}
                style={{ height: 30, marginBottom: 5 }}
              />
            ) : (
              <i
                className={`devicon-${settings.engineIcons[iconName]}-plain colored`}
                style={{ fontSize: 30 }}
                title={settings.engines[iconName]}
              />
            )}
          </div>
        )
      })
  }

  renderIcon(iconName) {
    if (iconName === 'cobol') {
      return (
        <img
          src='/static/images/frameworks/cobol.png'
          alt='cobol'
          style={{ height: '2.3rem' }}
        />
      )
    } else {
      return (
        <i
          className={`devicon-${settings.engineIcons[iconName]}-plain colored`}
          style={{ fontSize: 40 }}
          title={settings.engines[iconName]}
        />
      )
    }
  }

  renderLanguages() {
    const { selected } = this.state
    return this.state.filteredLanguages.map(({ language: iconName }) => (
      <div
        name={iconName}
        onClick={this.onSelectLanguage.bind(this, iconName)}
        className='flex flex-col justify-center items-center uk-card-default uk-padding-small'
        key={iconName}
        style={{
          cursor: 'pointer',
          width: '4rem',
          height: '4rem',
          marginLeft: 15,
          marginTop: 30,
          filter:
            selected[iconName] && !selected.none ? null : 'grayscale(100%)',
        }}
      >
        {this.renderIcon(iconName)}
        <label
          className='uk-label uk-label-success uk-margin-top'
          style={{ width: '100%', textAlign: 'center  ' }}
        >
          {settings.engines[iconName]}
        </label>
      </div>
    ))
  }

  render() {
    return (
      <div id='modal-container-language-picker'>
        {this.props.withButton && (
          <div
            className='flex flex-row items-center uk-placeholder uk-padding-small uk-position-relative flex-wrap'
            style={{ paddingRight: 60, cursor: 'pointer' }}
            onClick={() => this.modal.current.show()}
          >
            {this.renderSelectedItems()}
            <Icon
              name='settings-2'
              className='uk-margin-right'
              style={{ position: 'absolute', right: 0 }}
            />
          </div>
        )}
        <GenericModal
          options={{
            container: '#modal-container-language-picker',
          }}
          title='Pick your preferred languages'
          width='uk-width-1-1'
          ref={this.modal}
          buttons={
            <>
              <PrimaryButton
                label='Save'
                active={Object.keys(this.state.selected).length !== 0}
                onClick={this.onSave}
                size='medium'
              />
            </>
          }
          id={this.props.id || 'pick-language-modal'}
          body={
            <>
              <div className='flex flex-wrap justify-center flex-row uk-width-1-1 uk-padding'>
                {this.renderLanguages()}
              </div>
              <div className='flex justify-center items-center'>
                <button
                  onClick={this.onSelectLanguage.bind(this, 'none')}
                  className={`uk-button  ${
                    this.state.selected.none
                      ? 'uk-button-primary'
                      : 'uk-button-default'
                  } flex  items-center justify-center`}
                  type='button'
                  style={{
                    paddingTop: 7,
                    paddingBottom: 7,
                    paddingLeft: 40,
                    paddingRight: 10,
                  }}
                >
                  I don't know a programming language
                  <Icon
                    name='checkbox-circle'
                    fill
                    ratio={0.9}
                    style={{ color: 'white', marginLeft: 15 }}
                  />
                </button>
              </div>
              <br />
            </>
          }
        />
      </div>
    )
  }
}

const mapStateToProps = ({ hacker }) => ({
  languages: (hacker.profile || {}).languages,
})

export default connect(mapStateToProps, {
  updateLanguages,
})(ModalPreferLanguages)
