import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Spotlight from './Spotlight'
import TourTooltip from './TourTooltip'
import TourTooltipBody from './TourTooltipBody'

import { parseAnchor, computeTooltipPosition } from './util'

/**
 * Full-screen product tour that takes the user through a number of stages, each of which
 * highlights a single page element and displays some descriptive text alongside it in a modal
 * tooltip.
 */
function ProductTour(props) {
  const { stages, onDismiss, onFinish, onStageChange } = props
  const [stageIndex, setStageIndex] = useState(0)
  const [spotlightRect, setSpotlightRect] = useState(null)
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 })

  // When we move to a new stage or when the window resizes, compute new screen-space positions
  // to feed in as inputs for our Spotlight and TourTooltip components
  function updatePositionState() {
    const elementId = stages[stageIndex] ? stages[stageIndex].elementId : null
    const element = elementId ? document.getElementById(elementId) : null
    if (element) {
      const rect = element.getBoundingClientRect()
      const pad = 10
      setSpotlightRect({
        x: rect.x - pad,
        y: rect.y - pad,
        width: rect.width + pad + pad,
        height: rect.height + pad + pad,
      })
      setTooltipPosition(
        computeTooltipPosition(
          stages[stageIndex].elementAnchor,
          rect,
          stages[stageIndex].tooltipAnchor,
          pad + pad
        )
      )
    } else {
      setSpotlightRect(null)
      setTooltipPosition({
        left: document.documentElement.clientWidth / 2,
        top: document.documentElement.clientHeight / 2,
      })
    }
  }

  // Kill the tour if the user presses escape: we don't want them to be stuck on an unusable page
  // if something screwy happens with our non-DOM-constrained rendering
  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      onDismiss()
    }
  }

  useEffect(updatePositionState, [stages, stageIndex])
  useEffect(() => {
    window.addEventListener('resize', updatePositionState)
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('resize', updatePositionState)
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  // If the parent has supplied a callback, notify the parent when the stage changes, passing along
  // any extra data that's defined for that stage
  useEffect(() => {
    if (onStageChange) {
      onStageChange(stageIndex, stages[stageIndex].extra)
    }
  }, [stageIndex])

  const stage = stages[stageIndex]
  return (
    <>
      <Spotlight targetRect={spotlightRect} />
      <TourTooltip position={tooltipPosition} stageIndex={stageIndex}>
        <TourTooltipBody
          maxWidth={stage.maxWidth}
          title={stage.title}
          text={stage.text}
          canNavigateBack={stageIndex > 0}
          canNavigateForward={stageIndex < stages.length - 1}
          onNavigateBack={() => setStageIndex((prev) => prev - 1)}
          onNavigateForward={() => setStageIndex((prev) => prev + 1)}
          onDismiss={onDismiss}
          onFinish={onFinish || onDismiss}
        />
      </TourTooltip>
    </>
  )
}
ProductTour.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      elementId: PropTypes.string.isRequired,
      elementAnchor: PropTypes.oneOf([
        'top-left',
        'top',
        'top-right',
        'left',
        'center',
        'right',
        'bottom-left',
        'bottom',
        'bottom-right',
      ]).isRequired,
      tooltipAnchor: PropTypes.oneOf([
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right',
      ]).isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      extra: PropTypes.any,
    })
  ).isRequired,
  onDismiss: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  onStageChange: PropTypes.func,
}

export default ProductTour
