import React from 'react'
import PropTypes from 'prop-types'

import { VBox, HBox, Box } from 'app/views/core/Box'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'
import TestSubmissionHeading from './TestSubmissionHeading'
import TestSubmissionExplanation from './TestSubmissionExplanation'
import TestStageList from './TestStageList'

function TestSubmissionDetails(props) {
  const {
    submissionId,
    submittedAt,
    engine,
    onRequestCode,
    requestCodeButtonLabel,
    stages,
  } = props
  const { result, failedStage } = props
  return (
    <VBox style={{ padding: 30 }}>
      <HBox>
        <TestSubmissionHeading result={result} submittedAt={submittedAt} />
        {onRequestCode && (
          <Box>
            <PrimaryButton
              size='medium'
              customCss={`${
                result !== 'pending' &&
                `${result === 'passed' ? 'bg-green' : 'bg-red'}`
              }`}
              onClick={onRequestCode}
              label={requestCodeButtonLabel}
            />
          </Box>
        )}
      </HBox>
      <TestSubmissionExplanation result={result} />
      <TestStageList
        submissionResult={result}
        stages={stages}
        failedStageName={failedStage ? failedStage.name : null}
        failedStageHelpUrl={failedStage ? failedStage.helpUrl : null}
      />
    </VBox>
  )
}
TestSubmissionDetails.propTypes = {
  submissionId: PropTypes.number.isRequired,
  submittedAt: PropTypes.string.isRequired,
  engine: PropTypes.string.isRequired,
  onRequestCode: PropTypes.func,
  requestCodeButtonLabel: PropTypes.string,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,

  result: PropTypes.oneOf(['passed', 'failed', 'pending']).isRequired,
  failedStage: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    helpUrl: PropTypes.string,
    errorMessage: PropTypes.string,
    errorStackTrace: PropTypes.string,
  }),
}

export default TestSubmissionDetails
//snippet end
