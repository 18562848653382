import { PropTypes } from 'prop-types'
import React from 'react'

const propTypes = {
  licenseCount: PropTypes.number,
}
const defaultProps = {
  licenseCount: 0,
}

const AvailableLicenses = ({ licenseCount }) => (
  <div className='uk-card uk-card-default uk-card-body max-w-3xl'>
    <div className='my-5'>
      You have &nbsp;
      <span className='text-2xl font-bold'>{licenseCount}</span>
      {licenseCount === 1 ? ' license' : ' licenses'}
      &nbsp;available.
    </div>
    <div className='my-5'>
      To add additional licenses, please reach out to your customer success
      manager. If you're not sure who your CSM is, you can email us at:&nbsp;
      <a
        className='text-green hover:text-green'
        href='mailto:customersuccess@securityjourney.com'
      >
        customersuccess@securityjourney.com
      </a>
      .
    </div>
  </div>
)

AvailableLicenses.propTypes = propTypes
AvailableLicenses.defaultProps = defaultProps

export { AvailableLicenses }
