const stringifyHeaders = (h) => {
  return `${Object.keys(h)
    .map((k) => `${k.toLowerCase()}: ${h[k]}`)
    .join('\n')}\n`
}

const buildRequestText = (req) => {
  if (req.headers === undefined) {
    console.error('unexpected empty headers when building request text.')
    return ''
  }
  const headers = stringifyHeaders(req.headers)
  return `${req.method} ${req.path} ${req.http_version}\n\n${headers}\n${
    req.body || ''
  }`
}

const buildResponseText = (req) => {
  const res = req.response
  const headers = res.headers ? stringifyHeaders(res.headers) : ''
  return `${req.http_version} ${res.status_code}\n\n${headers}\n${
    res.body || ''
  }\n`
}

const parseRequestText = (text) => {
  const req = text.split('\n')

  const location = req.shift().split(' ') // ['POST', '/signin', 'HTTP/1.1']
  const method = location[0]
  const path = location[1]
  const httpVersion = location[2]

  if (req.indexOf('') === 0) req.shift() // strip leading empty string

  // everything from last empty string to end is the body
  const bodyStr = req.splice(req.indexOf('') + 1, req.length - 1).join('\n')
  const replacer = (match, ord) => String.fromCharCode('0x' + ord)
  const body = btoa(
    encodeURIComponent(bodyStr).replace(/%([0-9A-F]{2})/g, replacer)
  )

  const headers = req.reduce((o, i) => {
    i = i.split(': ')
    const key = i[0]
    const val = i[1]
    if (key && val) {
      o[key] = val
    }
    return o
  }, {})

  return { method, path, httpVersion, body, headers }
}

export { buildRequestText, buildResponseText, parseRequestText }
