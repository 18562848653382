import React, { type ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

export const Splash = (props: Props) => (
  <div className="flex justify-center bg-light_gray h-screen">
    <div className="flex flex-col items-center flex-1 max-w-xl mt-16 mb-8 md:px-16">
      {props.children}
    </div>
  </div>
)
