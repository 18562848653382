import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import settings from 'settings'

// Components
import ContentButton from 'app/views/components/ContentButton'
import ContentIcon from 'app/views/components/ContentIcon'
import ScratchpadButton from 'app/views/components/ScratchpadButton'
import AllTrainingMenu from 'app/views/components/AllTrainingMenu'

// Redux
import { getContent, getCodingChallenges } from 'app/state/modules/content'

// Utils
import analytics from 'app/views/utils/analytics'
import ModalContentIcon from 'app/views/components/Modals/ModalContentIcon'
import SkeletonLoader from 'app/views/components/Loader/SkeletonLoader'
import Icon from 'app/views/components/Icon'

const propTypes = {
  getContent: PropTypes.func.isRequired,
  license: PropTypes.bool,
}
const defaultProps = {
  license: false,
}

class ByCodeReview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
    }
    this.container = React.createRef()
  }

  componentDidMount() {
    if (this.props.codeReviews.length === 0) {
      this.props.getContent()
      this.props.getCodingChallenges()
    }
    analytics.page('/coding-challenges')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.showAllTraining === false &&
      nextProps.organizations.some((item) => item.role === 'admin') !== true
    ) {
      this.props.history.push('/')
    }
  }

  componentDidUpdate() {
    if (!this.props.showCodingChallenges) {
      this.props.history.push('/')
    }
  }

  renderIcons(
    languageName,
    frameworkName,
    width = 100,
    height = 100,
    tooltip = null
  ) {
    const style = {
      width: '100%',
    }
    let path = '/static/images/frameworks/'
    switch (frameworkName) {
      case 'react':
        path = `${path}react.png`
        break
      case 'angular':
        path = `${path}angular.png`
        break
      case 'spring_boot':
        path = `${path}spring.png`
        break
      case 'node':
        path = `${path}nodejs.png`
        break
      case 'flask':
        path = `${path}flask.png`
        break
      case 'laravel':
        path = `${path}laravel.png`
        break
      case 'rails':
        path = `${path}rails.png`
        break
      case 'net_http':
        path = `${path}golang.png`
        break
      case 'core':
        if (languageName === 'php') {
          path = `${path}php.svg`
        } else {
          path = `${path}dotnetccore.png`
        }
        break
      case 'restify':
        path = `${path}restify.png`
        break
      default:
        path = null
        break
    }
    return (
      <div
        style={{
          width,
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        uk-tooltip={tooltip ? settings.frameworks[tooltip] : null}
      >
        <img src={path} alt={frameworkName} style={style} />
      </div>
    )
  }

  renderFeaturedCodeReviews() {
    const items = []
    this.props.featuredCodeReviews.map((language) =>
      language.frameworks.map((framework) =>
        framework.apps.map((app) =>
          app.content.map((content) => {
            items.push(
              <tr
                key={`${language.language_name}-${framework.name}-${app.name}-${content.uuid}`}
              >
                <td className='uk-padding-remove-horizontal'>
                  <ContentIcon
                    hasCodeSubmission={content.code_submission_count > 0}
                    hasCompletedContent={!!content.completed_at}
                    hasPassedContent={!!content.passed_at}
                    hasTest={content.has_test}
                    isActiveForUser={content.isActiveForUser}
                    lessonInProgress={content.max_progress > 0}
                    license={this.props.license}
                    requiresSubscription={content.requires_subscription}
                    marginTop={0}
                  />
                </td>
                <td>
                  <a
                    href={`/content/${content.uuid}`}
                    className='uk-link-reset font-normal'
                  >
                    {content.title}
                  </a>
                </td>
                <td className='flex justify-center items-center'>
                  <i
                    uk-tooltip={settings.engines[language.language_name]}
                    className={`devicon-${
                      settings.engineIcons[language.language_name]
                    }-plain colored uk-margin-right`}
                    style={{ fontSize: 30 }}
                    title={language.language_name}
                  />
                  {this.renderIcons(
                    language.language_name,
                    framework.name,
                    40,
                    40,
                    framework.name
                  )}
                </td>
                <td className='text-center'>{content.points}</td>
                <td className='text-center' style={{ width: '25%' }}>
                  <ContentButton
                    restartText='Restart Challenge'
                    startText='Start Challenge'
                    hasCompletedContent={!!content.completed_at}
                    isActiveForUser={content.isActiveForUser}
                    lessonInProgress={content.max_progress > 0}
                    license={this.props.license}
                    requiresSubscription={content.requires_subscription}
                    small
                    url={`/content/${content.uuid}`}
                  />
                </td>
              </tr>
            )
          })
        )
      )
    )
    return items
  }

  renderLanguageCards() {
    return (
      <div
        className={`uk-width-expand uk-section-small${
          this.state.selected ? '' : ' uk-child-width-1-4@m uk-grid-small'
        }`}
        uk-grid=''
      >
        {this.props.codeReviews.map((language) =>
          language.frameworks.map((framework) => {
            const allCompleted = !framework.apps.some((app) =>
              app.content.some((content) => {
                if (!content.completed_at) {
                  return true
                }
                return false
              })
            )
            return (
              <div key={`${language.language_name}-${framework.name}`}>
                <div
                  onClick={() => {
                    this.props.history.push(
                      `/coding-challenges/${language.language_name}/${framework.name}`
                    )
                  }}
                  className='uk-card uk-card-body uk-card-hover uk-card-default flex justify-center flex-col items-center'
                  style={{ cursor: 'pointer' }}
                >
                  {this.renderIcons(language.language_name, framework.name)}
                  <br />
                  <h4 className='uk-margin-remove'>
                    {settings.frameworks[framework.name]}
                  </h4>
                  <h5 className='uk-margin-remove italic'>
                    {settings.engines[language.language_name]}
                  </h5>
                  {allCompleted && (
                    <span
                      className='uk-label uk-label-success flex'
                      style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                      }}
                    >
                      <Icon
                        name='check'
                        ratio={0.6}
                        className='uk-margin-small-right'
                      />
                      Completed
                    </span>
                  )}
                </div>
              </div>
            )
          })
        )}
        <div style={{ height: '50vh', display: 'block' }} />
      </div>
    )
  }

  render() {
    if (this.props.loadingCodeReviews) {
      return <SkeletonLoader visible />
    }
    return (
      <div className='uk-hackedu-container-main'>
        <div className='uk-container'>
          <div className='uk-margin-medium-top' id='lalala'>
            <div data-uk-grid=''>
              <div className='uk-width-large'>
                <div>
                  <div
                    data-uk-sticky='offset: 150'
                    className='hackedu-content-menu'
                  >
                    <AllTrainingMenu content={this.props.codeReviews} />
                  </div>
                </div>
              </div>
              <div className='uk-width-expand'>
                {this.props.featuredCodeReviews.length > 0 &&
                  !this.state.selected && (
                    <>
                      <h2 className=''>Featured Challenges</h2>
                      <table className='uk-table uk-table-middle uk-table-divider text-sm text-emphasis'>
                        <thead>
                          <tr>
                            <th />
                            <th>Title</th>
                            <th className='text-center'>
                              Language / Framework
                            </th>
                            {/* <th>Difficultty</th> */}
                            <th>Points</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>{this.renderFeaturedCodeReviews()}</tbody>
                      </table>
                      <br />
                      <br />
                    </>
                  )}
                {!this.state.selected && (
                  <>
                    <h2 className='uk-margin-bottom '>
                      Languages and Frameworks
                    </h2>
                    <br />
                  </>
                )}
                {this.renderLanguageCards()}
                {/* <div style={{ height: '100vh', display: 'block' }} /> */}
              </div>
            </div>
          </div>
        </div>
        <ModalContentIcon />
        <ScratchpadButton id='by-vulnerability' />
      </div>
    )
  }
}

ByCodeReview.propTypes = propTypes
ByCodeReview.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  // auth
  email: state.auth.user.email,

  // content
  content: state.content.content,
  loadingContent: state.content.loadingContent,
  loadingCodeReviews: state.content.loadingCodeReviews,
  codeReviews: state.content.codeReviews,
  featuredCodeReviews: state.content.featuredCodeReviews,

  // hacker
  organizations: state.hacker.organizationsList,
  license: state.hacker.license,
  mustPassCodingExercise: state.hacker.mustPassCodingExercise,
  showAllTraining: state.hacker.showAllTraining,
  showCodingChallenges: state.hacker.showCodingChallenges,
})
const mapDispatchToProps = (dispatch) => ({
  getContent: () => {
    dispatch(getContent())
  },
  getCodingChallenges: () => {
    dispatch(getCodingChallenges())
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ByCodeReview)
)
