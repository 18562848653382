import React, { useState, useEffect } from 'react'
import { useLocation, Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Splash } from '../components/Splash'
import { SplashLogo } from '../components/SplashLogo'
import { SplashTitle } from '../components/SplashTitle'
import { SplashCard } from '../components/SplashCard'
import { SplashLinks } from '../components/SplashLinks'
import { ErrorBanner } from '../components/ErrorBanner'

import * as auth from 'app/state/modules/auth'
import { restoreCognitoSessionFromAccessCode } from 'app/sjLogin'

export function SJLogin() {
  // Parse query parameters from the URL
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const code = params.get('code') || ''
  const dest = params.get('dest') || ''
  const allowNav = params.get('nav') !== 'none'

  // Establish some state: once finished, we'll redirect the user into the app; if we
  // have an error message we'll display it instead
  const [isFinished, setIsFinished] = useState(false)
  const [error, setError] = useState('')

  // Once the UI layer has ensured that we have a valid CognitoSession, we need to push
  // the relevant auth state into the Redux store so that the state of the application
  // reflects the fact that the user is logged in: also wait for that to happen before
  // initiating a redirect
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state['auth'].status === 'LOGGED_IN')

  // On mount, use the provided access code to retrieve the user's stored Cognito
  // tokens, then reestablish a CognitoUserSession from those tokens and ensure that the
  // required Cognito state is flushed to localStorage, then update application state to
  // reflect that we're logged in
  useEffect(() => {
    const thunk = async () => {
      try {
        const session = await restoreCognitoSessionFromAccessCode(code)
        dispatch(auth.updateCognitoSession(session))
        setIsFinished(true)
      } catch (err) {
        setError(err.message || JSON.stringify(error))
      }
    }
    thunk()
  }, [code])

  // If we encountered an error at any point in the login process, render a message
  if (error) {
    return (
      <Splash>
        <SplashLogo />
        <SplashTitle text="There was an issue logging you in" />
        <SplashCard>
          <ErrorBanner error={error} accumulator={-1} />
        </SplashCard>
        {allowNav && (
          <SplashLinks>
            <Link to='/login'>&larr; Back to Login page</Link>
          </SplashLinks>
        )}
      </Splash>
    )
  }

  // If we finished successfully without an error, *AND* our Redux state update has
  // propagated, send the user to whatever route was indicated by the 'dest' parameter,
  // or the root route by default
  if (isFinished && isLoggedIn) {
    return <Redirect to={getRedirectUrl(dest, allowNav)}/>
  }

  // In the meantime, just display a blank page
  return null
}

/**
 * Ensures that our destination route is properly formatted with a leading slash, and
 * appends query parameters as needed.
 */
function getRedirectUrl(dest: string, allowNav: boolean): string {
  if (!dest.startsWith('/')) {
    dest = '/' + dest
  }
  if (!allowNav && !dest.includes('nav=none')) {
    const delim = dest.indexOf('?') >= 0 ? '&' : '?'
    dest = dest + delim + 'nav=none'
  }
  return dest
}
