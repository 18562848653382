import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { VBox, HBox } from 'app/views/core/Box'

import SandboxLoader from '../../SandboxLoader'

import { validateUrl } from './util'
import useHistoryNav from './useHistoryNav'
import ProxyInterceptor from './ProxyInterceptor'
import SandboxNavButton from './SandboxNavButton'
import SandboxAddressField from './SandboxAddressField'
import SandboxInterceptSwitch from './SandboxInterceptSwitch'
import SandboxPageSource from './SandboxPageSource'
import SandboxBrowserFrame from './SandboxBrowserFrame'
import UnsupportedUrlModal from './UnsupportedUrlModal'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'

import { connect } from 'react-redux'
import * as sbproxy from 'app/state/modules/sbproxy'

const VerticalSeparator = () => (
  <div
    style={{
      width: 1,
      height: 28,
      borderRight: '1px solid #e5e5e5',
      margin: '0 8px',
    }}
  />
)
const GoButton = ({ isActive, onClick }) => (
  <PrimaryButton size='small' active={isActive} onClick={onClick} label='Go' />
)
function SandboxBrowser(props) {
  // Don't render until the sandbox is ready
  const { isLoading } = props
  if (isLoading) {
    return <SandboxLoader />
  }

  // Our history navigation controls are currently a bit limited, since we have no reliable way of
  // accessing the iframe's actual history stack, and calling window.history.back() (even if we did
  // so WITHIN the iframe) can boot us out of the lesson if we go back too far. So the user can go
  // back (if there's a page for them to go back to) only one step, and they can go forward again
  // one step from there. allowedHistoryOp is 'back', 'forward', or null; enactHistoryOp can be
  // called with 'back' or 'forward'; updateHistoryUrl lets the history hook know when the iframe
  // location has changed.
  const [allowedHistoryOp, enactHistoryOp, updateHistoryUrl] = useHistoryNav()

  // cleanUrl:     http://sandbox-hackedu.com
  //                i.e. the user-facing base URL
  // realUrl:      http://some-sandbox-uuid-app.sandbox.hackedu.minikube
  //                i.e. the base URL that the sandbox actually advertises
  // realLocation: http://some-sandbox-uuid-app.sandbox.hackedu.minikube/register
  //                i.e. the URL we want to load in the iframe
  // otherAccessibleUrls: {cleanUrl, realUrl} pairs for any other sandbox hosts that we should be
  //                       allowed to reach from this browser frame
  const { useRealUrl, cleanUrl, realUrl, otherAccessibleUrls } = props
  const [realLocation, setRealLocation] = useState({
    url: 'about:blank',
    index: -1,
  })
  const userFacingUrl = useRealUrl ? realUrl : cleanUrl

  // realLocation has a throwaway counter value attached, so that we we can browse to the same URL
  // we're already visiting and still trigger a React state update
  const realLocationUpdateCount = useRef(0)
  const updateRealLocation = (newUrl) => {
    const url = newUrl || 'about:blank'
    const index = realLocationUpdateCount.current++
    setRealLocation({ url, index })
  }
  useEffect(() => updateRealLocation(realUrl), [realUrl])

  const { appName, isFocused, isProxied, showUrlBar } = props
  const { isInterceptingRequests, setIsInterceptingRequests } = props

  // frameLocation: http://sandbox-hackedu.com/register
  //                 i.e. the URL loaded in the iframe, formatted for user-facing display
  // This value is relayed to us from the iframe via an onload script injected by the proxy, and it
  // also has a throwaway counter value for redundant updates.
  const [frameLocation, setFrameLocation] = useState({
    url: `${userFacingUrl}/`,
    index: -1,
  })
  const [addressFieldText, setAddressFieldText] = useState('')
  useEffect(() => {
    setAddressFieldText(frameLocation.url || '')
    if (frameLocation.url) {
      updateHistoryUrl(frameLocation.url)
    }
  }, [frameLocation])

  // If we click the 'Go' button, 'addressFieldText' is parsed and validated as a URL. If the URL
  // is invalid or unsupported, we'll show a modal error message.
  const [unsupportedUrlInfo, setUnsupportedUrlInfo] = useState({
    problem: null,
    data: null,
  })

  // If we've been supplied with an intercepted request, show the intercept UI and disable most
  // browser functionality until the request is dealt with
  const { interceptedRequest, onSubmitInterceptedRequest } = props
  const hasInterceptedRequest = isProxied && interceptedRequest

  // If we click 'View Source', we'll indicate to SandboxBrowserFrame that it should request page
  // source, from the iframe, and once we receive that source we'll render it over the iframe.
  const [wantsPageSource, setWantsPageSource] = useState(false)
  const [pageSource, setPageSource] = useState('')
  const shouldRenderPageSource =
    wantsPageSource && pageSource.length > 0 && !hasInterceptedRequest

  const allowedOrigins = [{ cleanUrl, realUrl }].concat(otherAccessibleUrls)
  const navigateToUrl = (urlString) => {
    const validated = validateUrl(urlString, allowedOrigins, useRealUrl)
    if (!validated.ok) {
      setUnsupportedUrlInfo(validated.error)
      return
    }
    if (useRealUrl) {
      updateRealLocation(urlString)
    } else {
      const origin = validated.origin
      if (!urlString.startsWith(origin.cleanUrl)) {
        console.error(
          `urlString '${urlString}' passed validation but does not contain cleanUrl '${origin.cleanUrl}'`
        )
        return
      }
      const suffix = urlString.slice(origin.cleanUrl.length)
      updateRealLocation(origin.realUrl + suffix)
    }
    setWantsPageSource(false)
  }

  const { tabIndex } = props
  return (
    <VBox fillParent>
      {showUrlBar && (
        <HBox
          id={`browser-tab-${tabIndex}-navbar`}
          fixed={60}
          style={{ alignItems: 'center', padding: '0 15px', flexShrink: 0 }}
        >
          <SandboxNavButton
            iconName='arrow-left-s'
            isActive={
              isProxied && !interceptedRequest && allowedHistoryOp === 'back'
            }
            onClick={() => enactHistoryOp('back')}
          />
          <SandboxNavButton
            iconName='arrow-right-s'
            isActive={
              isProxied && !interceptedRequest && allowedHistoryOp === 'forward'
            }
            onClick={() => enactHistoryOp('forward')}
          />
          <VerticalSeparator />
          <SandboxNavButton
            iconName='home'
            isActive={!hasInterceptedRequest}
            onClick={() => {
              setAddressFieldText(userFacingUrl)
              navigateToUrl(userFacingUrl)
            }}
          />
          <SandboxAddressField
            text={addressFieldText}
            isEditable={!hasInterceptedRequest}
            onTextChange={setAddressFieldText}
            onEnter={() => navigateToUrl(addressFieldText)}
          />
          <GoButton
            isActive={!hasInterceptedRequest}
            onClick={() => navigateToUrl(addressFieldText)}
          />
          {isProxied && (
            <>
              <PrimaryButton
                active={!hasInterceptedRequest}
                label={
                  wantsPageSource && !hasInterceptedRequest
                    ? 'View Page'
                    : 'View Source'
                }
                size='small'
                customCss={'text-black bg-light_gray'}
                onClick={() => setWantsPageSource((value) => !value)}
              />
              <VerticalSeparator />
              <SandboxInterceptSwitch
                tabIndex={tabIndex}
                isEnabled={isInterceptingRequests}
                onChange={setIsInterceptingRequests}
              />
            </>
          )}
        </HBox>
      )}
      {shouldRenderPageSource && <SandboxPageSource text={pageSource} />}
      {interceptedRequest && (
        <ProxyInterceptor
          request={interceptedRequest}
          onSubmit={onSubmitInterceptedRequest}
        />
      )}
      <SandboxBrowserFrame
        isProxied={isProxied}
        cleanUrl={userFacingUrl}
        useRealUrl={useRealUrl}
        allowedOrigins={allowedOrigins}
        realLocation={realLocation}
        onLocationChange={setFrameLocation}
        isFocused={isFocused}
        isVisible={!shouldRenderPageSource && !hasInterceptedRequest}
        wantsPageSource={wantsPageSource}
        onPageSourceChange={setPageSource}
      />
      {unsupportedUrlInfo.problem && (
        <UnsupportedUrlModal
          problem={unsupportedUrlInfo.problem}
          data={unsupportedUrlInfo.data}
          validBaseUrls={[userFacingUrl].concat(
            useRealUrl
              ? otherAccessibleUrls.map((x) => x.realUrl)
              : otherAccessibleUrls.map((x) => x.cleanUrl)
          )}
          onClose={() => setUnsupportedUrlInfo({ problem: null, data: null })}
        />
      )}
    </VBox>
  )
}
SandboxBrowser.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  accessibleTabs: PropTypes.arrayOf(
    PropTypes.shape({
      app_name: PropTypes.string.isRequired,
      clean_url: PropTypes.string.isRequired,
    })
  ).isRequired,
  appName: PropTypes.string.isRequired,
  useRealUrl: PropTypes.bool.isRequired, // If true, disable cleanUrl string substitution and just present realUrl as the user-facing URL
  isFocused: PropTypes.bool.isRequired,
  isProxied: PropTypes.bool.isRequired,
  showUrlBar: PropTypes.bool.isRequired,
  cleanUrl: PropTypes.string.isRequired,
  realUrl: PropTypes.string,
  otherAccessibleUrls: PropTypes.arrayOf(
    PropTypes.shape({
      cleanUrl: PropTypes.string.isRequired,
      realUrl: PropTypes.string.isRequired,
    })
  ).isRequired,

  interceptedRequest: PropTypes.object,
  onSubmitInterceptedRequest: PropTypes.func.isRequired,

  isLoading: PropTypes.bool.isRequired,
  isInterceptingRequests: PropTypes.bool.isRequired,
  setIsInterceptingRequests: PropTypes.func.isRequired,
}

export default connect(
  (state, ownProps) => {
    const { browserTabUrls, requestError, requestFinishedTimestamp } =
      state.sandbox
    const realUrl = browserTabUrls[ownProps.appName]

    let otherAccessibleUrls = []
    for (const tab of ownProps.accessibleTabs) {
      if (tab.app_name !== ownProps.appName && browserTabUrls[tab.app_name]) {
        otherAccessibleUrls.push({
          cleanUrl: tab.clean_url,
          realUrl: browserTabUrls[tab.app_name],
        })
      }
    }

    const sandboxRequestSucceeded = !requestError && requestFinishedTimestamp
    const proxyIsInitializing =
      ownProps.isProxied &&
      (!state.sbproxy.concludedHistory ||
        !state.sbproxy.hasInitializedInterceptState)
    return {
      realUrl,
      otherAccessibleUrls,
      isLoading: !realUrl || !sandboxRequestSucceeded || proxyIsInitializing,
      isInterceptingRequests:
        ownProps.isProxied && state.sbproxy.isIntercepting,
    }
  },
  {
    setIsInterceptingRequests: sbproxy.setWantsToIntercept,
  }
)(SandboxBrowser)
