import React from 'react'
import { connect } from 'react-redux'
import GenericModal from 'app/views/components/Modals/GenericModal'
import PlanSelectionItem from './PlanSelectionItem'
import PlanChangeBackBtn from './PlanChangeBackBtn'
import settings from 'settings'

import './styles.less'

import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import {
  setNoTraining,
  setOrganizationDefaultPlan,
  activateAutomatedPlan,
  setDefaultPlan,
} from 'app/state/modules/plan'

class PlanChangeModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
    }
    this.modal = React.createRef()
    this.show = this.show.bind(this)
    this.changePage = this.changePage.bind(this)
    this.hide = this.hide.bind(this)
    this.rearrangePlans = this.rearrangePlans.bind(this)
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  show() {
    this.setState(
      {
        page: 0,
      },
      () => {
        this.modal.current.show()
      }
    )
  }

  hide() {
    this.modal.current.hide()
  }

  changePage(number) {
    this.setState({
      page: number,
    })
  }

  // Rearranges plans according to the presetPlanList flag in LaunchDarkly
  // DO NOT REMOVE THIS FLAG WITHOUT UNDERSTANDING THE RAMIFICATIONS
  rearrangePlans() {
    const availablePlans = settings?.plans || []
    const planListFromFlag = this.props.flags.presetPlanList || []

    if (planListFromFlag.length === 0) return availablePlans

    const plansToListInOrder = planListFromFlag.reduce(
      (collector, plan_title) => {
        const plan = availablePlans.find((p) => p.title === plan_title)
        if (plan) {
          return [...collector, plan]
        } else {
          return collector
        }
      },
      []
    )

    if (plansToListInOrder.length < availablePlans.length) {
      console.warn('One or more plans hidden by LD flag')
    }

    return plansToListInOrder
  }

  renderStepOne() {
    const options = [
      {
        key: 'adaptive',
        text: 'Use Adaptive Training plan',
        onClick: () => {
          this.hide()
          this.props.activateAutomatedPlan(
            this.props.organizationUUID,
            this.props.teamUUID,
            this.props.onRefresh
          )
        },
      },
      {
        key: 'custom',
        text: 'Use a custom plan',
        onClick: () => {
          this.changePage(1)
        },
      },
      {
        key: 'none',
        text: 'No training',
        onClick: () => {
          this.hide()
          this.props.setNoTraining(
            this.props.organizationUUID,
            this.props.teamUUID,
            this.props.onRefresh
          )
        },
      },
    ]

    if (this.props.teamUUID) {
      options.splice(2, 0, {
        key: 'org',
        text: 'Use organization default',
        onClick: () => {
          this.hide()
          this.props.setOrganizationDefaultPlan(
            this.props.organizationUUID,
            this.props.teamUUID,
            this.props.onRefresh
          )
        },
      })
    }

    return (
      <>
        <div className='selection-container'>
          {options.map((option) => (
            <PlanSelectionItem
              key={option.key}
              onClick={option.onClick}
              text={option.text}
            />
          ))}

          <p className='change-plan-title uk-margin-large-top'>
            Or build a training plan from a preset template:
          </p>
          {this.rearrangePlans().map((plan, index) => (
            <PlanSelectionItem
              key={plan.uuid}
              text={plan.title}
              onClick={() => {
                this.hide()
                this.props.history.push({
                  pathname: `/admin/${this.props.organizationUUID}/plans/add${
                    this.props.teamUUID ? '/team' : ''
                  }`,
                  state: {
                    planUUIDClone: plan.uuid,
                    defaultTeamUUID: this.props.teamUUID,
                    planTitle: plan.title,
                    planDescription: plan.description,
                  },
                })
              }}
            />
          ))}
        </div>
      </>
    )
  }

  renderCustomizePlan() {
    const options = [
      {
        key: 'scratch',
        text: 'Start from scratch',
        onClick: () => {
          this.hide()
          this.props.history.push({
            pathname: `/admin/${this.props.organizationUUID}/plans/add${
              this.props.teamUUID ? '/team' : ''
            }`,
            state: {
              defaultTeamUUID: this.props.teamUUID
                ? this.props.teamUUID
                : undefined,
            },
          })
        },
      },
      {
        key: 'clone',
        text: 'Clone an existing plan',
        onClick: () => {
          this.changePage(2)
        },
      },
    ]
    if (this.props.planToEdit) {
      options.splice(0, 0, {
        key: 'edit',
        text: 'Edit the current plan',
        onClick: () => {
          this.hide()
          this.props.history.push(
            `/admin/${this.props.organizationUUID}/plans/${this.props.planToEdit}`
          )
        },
      })
    }

    const unUsedPlans =
      this.props.clonePlanList.filter((plan) =>
        this.props.teamUUID
          ? plan.team_uuid === this.props.teamUUID
          : !plan.team_uuid && !plan.is_default && plan.is_custom_plan
      ) || []

    return (
      <>
        <PlanChangeBackBtn onClick={() => this.changePage(0)} />

        <div className='selection-container'>
          {options.map((option) => (
            <PlanSelectionItem
              key={option.key}
              onClick={option.onClick}
              text={option.text}
            />
          ))}

          {unUsedPlans.length > 0 && (
            <>
              <p className='change-plan-title uk-margin-large-top'>
                Or select one of your unused plans:
              </p>
              {unUsedPlans.map((p) => (
                <PlanSelectionItem
                  key={p.uuid}
                  text={p.title}
                  onClick={() => {
                    this.props.setDefaultPlan(
                      p.uuid,
                      this.props.organizationUUID,
                      this.props.teamUUID,
                      () => {
                        this.hide()
                        if (typeof this.props.onRefresh === 'function') {
                          this.props.onRefresh()
                        }
                      }
                    )
                  }}
                />
              ))}
            </>
          )}
        </div>
      </>
    )
  }

  renderPlansToClone() {
    const clonePlan = (plan) => {
      this.hide()
      this.props.history.push({
        pathname: `/admin/${this.props.organizationUUID}/plans/add${
          this.props.teamUUID ? '/team' : ''
        }`,
        state: {
          planUUIDClone: plan.uuid,
          defaultTeamUUID: this.props.teamUUID,
          planTitle: plan.title,
          planDescription: plan.description,
        },
      })
    }

    return (
      <>
        <PlanChangeBackBtn onClick={() => this.changePage(1)} />
        <div className='selection-container'>
          <h3>Organization plans</h3>
          {this.props.clonePlanList
            .filter((p) => !p.team)
            .map((plan) => {
              return (
                <PlanSelectionItem
                  key={plan.uuid}
                  text={plan.title}
                  onClick={() => clonePlan(plan)}
                />
              )
            })}
          <h3>Team plans</h3>
          {this.props.clonePlanList.filter((p) => p.team).length === 0 && (
            <p className='text-left'>No team plans.</p>
          )}
          {this.props.clonePlanList
            .filter((p) => p.team)
            .map((plan) => {
              return (
                <PlanSelectionItem
                  key={plan.uuid}
                  text={`${plan.team.name}: ${plan.title}`}
                  onClick={() => clonePlan(plan)}
                />
              )
            })}
        </div>
      </>
    )
  }

  renderPages() {
    switch (this.state.page) {
      case 1:
        return this.renderCustomizePlan()
      case 2:
        return this.renderPlansToClone()
      default:
        return this.renderStepOne()
    }
  }

  renderTitles() {
    switch (this.state.page) {
      case 1:
        return 'Customize a Plan'
      default:
        return 'Change Plan'
    }
  }

  render() {
    return (
      <GenericModal
        showHeader
        notTitle
        extraPadding
        changePlan
        noButtons
        closeOnClickOverlay
        ref={this.modal}
        id={this.props.id || 'change-plan-modal'}
        title={this.renderTitles()}
        titleClassName='text-left change-plan-title'
        body={this.renderPages()}
      />
    )
  }
}

const mapStateToProps = ({ plan }) => ({
  clonePlanList: plan.clonePlanList,
})

export default withLDConsumer()(
  connect(
    mapStateToProps,
    {
      setNoTraining,
      activateAutomatedPlan,
      setDefaultPlan,
      setOrganizationDefaultPlan,
    },
    null
  )(PlanChangeModal)
)
