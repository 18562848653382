import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import { VBox, HBox, Box } from 'app/views/core/Box'
// TODO: All the inline styles in this file are a hideous example of how not to style a website
const Container = ({ children, maxWidth }) => (
  <VBox
    style={{
      padding: 20,
      backgroundColor: '#fff',
      border: '1px solid #999',
      boxShadow: '0 1px 10px rgb(0, 0, 0, 0.4)',
      maxWidth,
    }}
  >
    {children}
  </VBox>
)
const Heading = ({ title }) => (
  <h3
    style={{
      fontFamily: '"Cabin", "Helvetica", "Corbel", sans-serif',
      fontSize: 24,
      marginBottom: 0,
    }}
  >
    {title}
  </h3>
)
/**
 * Renders the actual contents of a tour tooltip: the title, body text, and buttons for navigating
 * forward and backward between the stages of the tour, and for dismissing the tour outright.
 */
function TourTooltipBody(props) {
  const {
    maxWidth,
    title,
    text,
    canNavigateBack,
    canNavigateForward,
    onNavigateBack,
    onNavigateForward,
    onDismiss,
    onFinish,
  } = props
  return (
    <Container maxWidth={maxWidth}>
      <Heading title={title} />
      <Box
        grow
        className='text-sm'
        style={{ fontSize: 15, marginBottom: 16 }}
      >
        {text.split('\n').map((paragraph, i) => (
          <p key={i} style={{ margin: '10px 0' }}>
            {paragraph}
          </p>
        ))}
      </Box>
      <HBox fixed={20}>
        {canNavigateForward && (
          <PrimaryButton
            color='gray'
            onClick={onDismiss}
            label='Dismiss'
            size='small'
            customCss={'text-black bg-light_gray'}
          />
        )}
        <Box grow />
        {false && canNavigateBack && (
          <PrimaryButton
            onClick={onNavigateBack}
            label='Previous'
            size='small'
          />
        )}
        {canNavigateForward ? (
          <PrimaryButton
            onClick={onNavigateForward}
            label='Next'
            size='small'
          />
        ) : (
          <PrimaryButton onClick={onFinish} label='Finish' size='full' />
        )}
      </HBox>
    </Container>
  )
}
TourTooltipBody.propTypes = {
  maxWidth: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  canNavigateBack: PropTypes.bool.isRequired,
  canNavigateForward: PropTypes.bool.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
  onNavigateForward: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
}

export default TourTooltipBody
