import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
const Container = ({ children }) => (
  <div className='uk-section uk-padding-remove-top'>
    <div className='uk-padding uk-padding-small'>{children}</div>
  </div>
)
function SubmitFlagInstructions(props) {
  const {
    canSubmitFlag,
    hasSucceeded,
    onClickSubmitFlag,
    onClickCompleteChallenge,
  } = props
  return (
    <div style={{ marginBottom: 40 }}>
      <h3>Submit Flag</h3>
      <p>Follow the instructions above and submit your proof of completion.</p>
      <p>
        Hacking Challenges are intentionally vague, so try to explore the app
        and look for clues. Apply what you've learned in HackEDU Lessons and try
        different types of attacks.
      </p>
      <div>
        <PrimaryButton
          active={hasSucceeded || canSubmitFlag}
          onClick={hasSucceeded ? onClickCompleteChallenge : onClickSubmitFlag}
          label={hasSucceeded ? 'Challenge Complete!' : 'Submit Flag'}
          size={'medium'}
        />
      </div>
    </div>
  )
}
SubmitFlagInstructions.propTypes = {
  canSubmitFlag: PropTypes.bool.isRequired,
  hasSucceeded: PropTypes.bool.isRequired,
  onClickSubmitFlag: PropTypes.func.isRequired,
  onClickCompleteChallenge: PropTypes.func.isRequired,
}

export default SubmitFlagInstructions
