import React from 'react'
import settings from 'settings'

class Lessons extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLessons: new Set(),
      totalLessons: 0,
    }
    this.handleClickCheckbox = this.handleClickCheckbox.bind(this)
    this.onChangeSelectAll = this.onChangeSelectAll.bind(this)
    this.unselectAll = this.unselectAll.bind(this)
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  static getDerivedStateFromProps(props) {
    return {
      totalLessons:
        props.content.reduce((prev, course) => {
          const newPrev =
            prev +
            course.items.reduce((p, c) => {
              if (c.item_type === 'content') {
                const newP = p + 1
                return newP
              }
              const newP =
                p +
                c.content.reduce((p2, _) => {
                  const newP2 = p2 + 1
                  return newP2
                }, 0)
              return newP
            }, 0)
          return newPrev
        }, 0) +
        (props.challenges || []).length +
        (props.codingChallenges || []).reduce((prev, curr) => {
          let totalLanguage = 0
          curr.frameworks.forEach((framework) => {
            framework.apps.forEach((app) => {
              totalLanguage += app.content.length
            })
          })
          return prev + totalLanguage
        }, 0),
    }
  }

  onChangeSelectAll(e) {
    if (e.target.checked) {
      if (e.target.value) {
        const { selectedLessons } = this.state
        /** For the moment will show  */
        const checkboxes = document
          .getElementById('lessons-checkbox-component')
          .getElementsByTagName('input')
        for (let i = 0; i < checkboxes.length; i += 1) {
          const cb = checkboxes[i]
          if (cb.value !== 'select_all') {
            cb.checked = true
            if (!selectedLessons.has(cb.value)) {
              selectedLessons.add(cb.value)
            }
          }
        }
        this.setState({
          selectedLessons,
        })
      }
    } else {
      this.unselectAll()
    }
  }

  getLessons() {
    return this.state.selectedLessons
  }

  handleClickCheckbox(e) {
    const { selectedLessons } = this.state
    if (selectedLessons.has(e.target.value)) {
      selectedLessons.delete(e.target.value)
    } else {
      selectedLessons.add(e.target.value)
    }
    this.setState({
      selectedLessons,
    })
  }

  unselectAll(e) {
    if (e) e.preventDefault()
    const { selectedLessons } = this.state
    const checkboxes = document
      .getElementById('lessons-checkbox-component')
      .getElementsByTagName('input')
    for (let i = 0; i < checkboxes.length; i += 1) {
      const cb = checkboxes[i]
      cb.checked = false
      if (selectedLessons.has(cb.value)) {
        selectedLessons.delete(cb.value)
      }
    }
    this.setState({
      selectedLessons,
    })
  }

  renderCheckboxes(
    checkboxes,
    hackingChallenges = false,
    codingChallenges = false
  ) {
    if (codingChallenges) {
      return checkboxes.map((language) => (
        <React.Fragment key={language.language_name}>
          {language.frameworks.map((framework) => (
            <React.Fragment key={framework.name}>
              <div>
                <strong>
                  <i>
                    {settings.engines[language.language_name]}
                    {' - '}
                    {settings.frameworks[framework.name]}
                  </i>
                </strong>
                {framework.apps.map((app) => (
                  <div key={app.name}>
                    <i>{settings.apps[app.name]}</i>
                    <br />
                    {app.content.map((cb) => (
                      <React.Fragment key={`${cb.uuid}`}>
                        <label htmlFor={`cb_${cb.uuid}`}>
                          <input
                            id={`cb_${cb.uuid}`}
                            className='uk-checkbox uk-margin-small-right'
                            type='checkbox'
                            name='lessons'
                            value={cb.uuid}
                            onClick={this.handleClickCheckbox}
                          />
                          {` ${cb.title}`}
                        </label>
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                ))}
                <br />
              </div>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))
    }
    if (hackingChallenges) {
      return checkboxes.map((cb) => (
        <React.Fragment key={`${cb.uuid}`}>
          <label htmlFor={`cb_${cb.uuid}`}>
            <input
              id={`cb_${cb.uuid}`}
              className='uk-checkbox uk-margin-small-right'
              type='checkbox'
              name='lessons'
              value={cb.uuid}
              onClick={this.handleClickCheckbox}
            />
            {` ${cb.title}`}
          </label>
          <br />
        </React.Fragment>
      ))
    }
    return checkboxes.map((cb) => {
      if (cb.item_type === 'content') {
        return (
          <React.Fragment key={`${cb.uuid}`}>
            <label htmlFor={`cb_${cb.uuid}`}>
              <input
                id={`cb_${cb.uuid}`}
                className='uk-checkbox uk-margin-small-right'
                type='checkbox'
                name='lessons'
                value={cb.uuid}
                onClick={this.handleClickCheckbox}
              />
              {` ${cb.title}`}
            </label>
            <br />
          </React.Fragment>
        )
      }
      return cb.content.map((content) => {
        return (
          <React.Fragment key={`${content.uuid}`}>
            <label htmlFor={`cb_${content.uuid}`}>
              <input
                id={`cb_${content.uuid}`}
                className='uk-checkbox uk-margin-small-right'
                type='checkbox'
                name='lessons'
                value={content.uuid}
                onClick={this.handleClickCheckbox}
              />
              {` ${content.title}`}
            </label>
            <br />
          </React.Fragment>
        )
      })
    })
  }

  render() {
    return (
      <div
        id='lessons-checkbox-component'
        data-test-id='lessons-checkbox-component'
        className='uk-width-1-1@s uk-margin-left'
      >
        <strong>Check to select all lessons</strong>
        <div className='uk-padding-small'>
          <label htmlFor='cb_select_all'>
            <input
              id='cb_select_alls'
              className='uk-checkbox uk-margin-small-right'
              type='checkbox'
              onChange={this.onChangeSelectAll}
              name='lessons'
              value='select_all'
              checked={
                this.state.selectedLessons.size === this.state.totalLessons
              }
            />
            Select All
            {this.state.selectedLessons.size > 0 && (
              <a onClick={this.unselectAll}>(Unselect all)</a>
            )}
          </label>
          <br />
        </div>
        {this.props.content.map((course) => {
          return (
            <React.Fragment key={course.uuid}>
              <h4>{course.title}</h4>
              <div className='uk-padding-small'>
                {this.renderCheckboxes(course.items || [], false, false)}
              </div>
            </React.Fragment>
          )
        })}
        <h3>Hacking Challenges</h3>
        <div className='uk-padding-small'>
          {this.renderCheckboxes(this.props.challenges || [], true, false)}
        </div>
        <h3>Coding Challenges</h3>
        <div className='uk-padding-small'>
          {this.renderCheckboxes(
            this.props.codingChallenges || [],
            false,
            true
          )}
        </div>
      </div>
    )
  }
}

export default Lessons
