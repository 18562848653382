import React from 'react'
import { values } from 'lodash'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// Components
import Lessons from 'app/views/components/LessonsCheckboxList'
import GenericModal from 'app/views/components/Modals/GenericModal'
import Loader from 'app/views/components/Loader'
import Icon from 'app/views/components/Icon'
import ModalError from 'app/views/components/Modals/ModalError'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'

// Redux
import { getCSVReportUsers } from 'app/state/modules/hacker'
import { getTeams } from 'app/state/modules/team'
import { getContent } from 'app/state/modules/content'

class DownloadReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorTitle: '',
      errorContent: '',
      days: null,
      selectedTeam: this.props.match.params.team_uuid,
      isLoadingContent: true,
    }
    this.onRefLessons = this.onRefLessons.bind(this)
    this.onRefModalError = this.onRefModalError.bind(this)
    this.redirectTo = this.redirectTo.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChangeTeam = this.handleChangeTeam.bind(this)

    this.props.getTeams(this.props.match.params.organization_uuid)
  }

  componentDidMount() {
    this.props.getContent(this.props.match.params.organization_uuid, true, () =>
      this.setState({ isLoadingContent: false })
    )
  }

  onSubmit() {
    const contentIdsArray = Array.from(this.lessonsCheckbox.getLessons())

    if (contentIdsArray.length === 0) {
      return this.setState(
        {
          errorTitle: 'Error',
          errorContent:
            'You must select at least 1 lesson to download the information about your team.',
        },
        () => {
          this.errorModal.show()
        }
      )
    }
    this.loadingModal.show()
    return setTimeout(
      () =>
        this.props.getCSVReportUsers(
          this.props.match.params.organization_uuid,
          contentIdsArray,
          this.state.selectedTeam === '' ? null : this.state.selectedTeam,
          (state = {}) =>
            this.setState(
              {
                ...state,
              },
              () => {
                this.loadingModal.hide()
              }
            ),
          this.state.days
        ),
      1000
    )
  }

  onRefModalError(ref) {
    this.errorModal = ref
  }

  onRefLessons(ref) {
    this.lessonsCheckbox = ref
  }

  handleChangeTeam(e) {
    this.setState({
      selectedTeam: e.target.value,
    })
  }

  handleClickGoBack(e) {
    e.preventDefault()
    this.props.history.push(this.props.location.pathname.replace(/[^/]*$/, ''))
  }

  redirectTo(route) {
    this.props.history.push(
      `${this.props.location.pathname.replace(/\/$/, '')}${route}`
    )
  }

  render() {
    if (!this.props.hasLoadedOrganizations || this.state.isLoadingContent) {
      return <Loader visible />
    }
    return (
      <OrganizationLayout
        active='reports'
        noPaddingRemove={this.props.match.params.team_uuid}
        style={{ position: 'relative' }}
        {...this.props}
      >
        {this.props.match.params.team_uuid && (
          <button
            onClick={() =>
              this.props.history.push(
                `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/users`
              )
            }
            className='uk-button uk-button-secondary uk-button-small uk-position-absolute uk-position-top-left uk-margin-top uk-margin-small-left flex'
            type='button'
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Back To Team
          </button>
        )}

        <h2 className='text-center'>Download CSV Report</h2>
        <p>
          Pick a team to export or export from all teams and users. Also, you
          can check the lessons you want to include in your report or just pick
          all of them.
        </p>
        {/* <div
          className="flex justify-center uk-padding"
        >
          <TimeRangePicker
            onChange={(days) => {
              this.setState({
                days,
              })
            }}
          />
        </div> */}
        <div className='uk-width-1-1@s uk-margin-left'>
          <div className='uk-margin'>
            <b>Select Team:</b>
            <br />
            <select
              className='uk-select'
              style={{
                maxWidth: 400,
                marginTop: 15,
              }}
              value={this.state.selectedTeam}
              onChange={this.handleChangeTeam}
            >
              {!this.props.match.params.team_uuid && (
                <option value=''>All Teams</option>
              )}
              {(this.props.match.params.team_uuid
                ? this.props.teams.filter(
                    (team) => team.uuid === this.props.match.params.team_uuid
                  )
                : this.props.teams
              ).map((team) => (
                <option key={team.uuid} value={team.uuid}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Lessons
          ref={this.onRefLessons}
          content={this.props.courses}
          challenges={this.props.challenges}
          codingChallenges={this.props.codingChallenges}
        />
        <div className='uk-width-1-1@s'>
          <br />
          <button
            type='button'
            className='uk-button uk-button-primary tm-button-default'
            onClick={this.onSubmit.bind(this)}
          >
            Download report
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type='button'
            className='uk-button uk-button-danger tm-button-default'
            onClick={this.handleClickGoBack.bind(this)}
          >
            Cancel
          </button>
        </div>

        <GenericModal
          ref={(ref) => {
            this.loadingModal = ref
          }}
          id='loading-modal'
          noButtons
          body={
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <br />
                <br />
                <div data-uk-spinner='' />
                <p>{this.props.text || 'Loading...'}</p>
                <br />
                <br />
              </div>
            </>
          }
        />
        <ModalError
          onRef={this.onRefModalError}
          title={this.state.errorTitle}
          errorText={this.state.errorContent}
        />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  organizations: values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,
  teams: state.team.teams,
  courses: state.content.content.courses,
  challenges: state.content.content.challenges,
  codingChallenges: state.content.content.code_reviews,
  hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
})

export default withRouter(
  connect(mapStateToProps, {
    getCSVReportUsers,
    getTeams,
    getContent,
  })(DownloadReport)
)
