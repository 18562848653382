/**
 * Serves as an interface to the repl container running on a sandbox.
 */
import { api } from 'app/views/utils/api'
import { requestOpts } from 'app/state/modules/sandbox'

import * as tty from './tty'
import * as hacker from './hacker'
import * as repl from './repl'

const prefix = 'sbrepl'
const SET_PREFERRED_ENGINE = `${prefix}/SET_PREFERRED_ENGINE`
const SET_SELECTED_ENGINE = `${prefix}/SET_SELECTED_ENGINE`
const NOTE_USER_HAS_OPENED_CODE_EDITOR = `${prefix}/NOTE_USER_HAS_OPENED_CODE_EDITOR`
const SETUP = `${prefix}/SETUP`
const TEARDOWN = `${prefix}/TEARDOWN`
const BEGIN_FETCHING_CODE = `${prefix}/BEGIN_FETCHING_CODE`
const FINISH_FETCHING_CODE = `${prefix}/FINISH_FETCHING_CODE`
const UPDATE_LOCAL_CODE = `${prefix}/UPDATE_LOCAL_CODE`
const BEGIN_PATCHING_SANDBOX = `${prefix}/BEGIN_PATCHING_SANDBOX`
const FINISH_PATCHING_SANDBOX = `${prefix}/FINISH_PATCHING_SANDBOX`
const BEGIN_SUBMITTING_PATCH_FOR_TESTING = `${prefix}/BEGIN_SUBMITTING_PATCH_FOR_TESTING`
const FINISH_SUBMITTING_PATCH_FOR_TESTING = `${prefix}/FINISH_SUBMITTING_PATCH_FOR_TESTING`

const initialState = {
  // Persistent state: not cleared on sandbox reset; can be modfied outside of setup/teardown:
  preferredEngine:
    localStorage.getItem('hackedu.lastLessonLanguage') || 'python',
  selectedEngine: null,

  // Compiled languages (e.g. clojure, Java, C#, etc.) currently take several seconds to handle
  // web requests since they have to frequently compile repl code. As a hack, we default to python
  // so that the sandbox app will be responsive initially, and we only load the user's preferred
  // language once they've actually opened the code editor.
  userHasOpenedCodeEditor: false,

  // Sandbox-dependent state:
  sandboxUrl: null,
  eventStreamUrl: null,
  eventSource: null,
  supportedEngines: [],
  isFetchingCode: false,
  isPatchingSandbox: false,
  isSubmittingPatchForTesting: false,
  patchSubmissionError: null,
  code: null,
  codeEngine: null,
  localCode: null,
  patchedCode: null,
}

function getEngineToSelect(
  userHasOpenedCodeEditor,
  preferredEngine,
  supportedEngines
) {
  // Our default choice is python, since it's widely supported and quick to run.
  // If python isn't supported, just fall back on the first supported engine reported by the repl.
  const defaultEngine = supportedEngines.includes('python')
    ? 'python'
    : supportedEngines[0]

  // If the user prefers a different language that's supported for the lesson, then we need to use
  // that language instead, but only if the user has opened the code editor
  const shouldUsePreferredEngine =
    preferredEngine &&
    preferredEngine !== defaultEngine &&
    supportedEngines.includes(preferredEngine)
  if (shouldUsePreferredEngine && userHasOpenedCodeEditor) {
    return preferredEngine
  }
  return defaultEngine
}

const ENABLE_VERBOSE_LOGGING = false
// eslint-disable-next-line no-console
const _log = ENABLE_VERBOSE_LOGGING ? console.log : () => {}
const _warn = ENABLE_VERBOSE_LOGGING ? console.warn : () => {}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PREFERRED_ENGINE:
      return {
        ...state,
        preferredEngine: action.engine,
      }
    case SET_SELECTED_ENGINE:
      return {
        ...state,
        selectedEngine: action.engine,
      }
    case NOTE_USER_HAS_OPENED_CODE_EDITOR:
      return {
        ...state,
        userHasOpenedCodeEditor: true,
      }
    case SETUP:
      return {
        ...state,
        replUrl: action.replUrl,
        eventStreamUrl: action.eventStreamUrl,
        eventSource: action.eventSource,
        supportedEngines: action.supportedEngines,
      }
    case TEARDOWN:
      return {
        ...state,
        sandboxUrl: null,
        eventStreamUrl: null,
        eventSource: null,
        supportedEngines: [],
        isFetchingCode: false,
        isPatchingSandbox: false,
        code: null,
        codeEngine: null,
      }
    case BEGIN_FETCHING_CODE:
      return {
        ...state,
        isFetchingCode: true,
      }
    case FINISH_FETCHING_CODE:
      return {
        ...state,
        isFetchingCode: false,
        code: action.code,
        codeEngine: action.engine,
        localCode: action.code,
        patchedCode: action.code,
      }
    case UPDATE_LOCAL_CODE:
      return {
        ...state,
        localCode: action.code,
      }
    case BEGIN_PATCHING_SANDBOX:
      return {
        ...state,
        isPatchingSandbox: true,
      }
    case FINISH_PATCHING_SANDBOX:
      return {
        ...state,
        isPatchingSandbox: false,
        patchedCode: action.code,
      }
    case BEGIN_SUBMITTING_PATCH_FOR_TESTING:
      return {
        ...state,
        isSubmittingPatchForTesting: true,
        patchSubmissionError: null,
      }
    case FINISH_SUBMITTING_PATCH_FOR_TESTING:
      return {
        ...state,
        isSubmittingPatchForTesting: false,
        patchSubmissionError: action.error || null,
      }
    default:
      return state
  }
}

async function fetchSupportedEngines(replUrl, opts) {
  const url = `${replUrl}/repl/engines`
  const response = await api({ method: 'get', url, opts })
  return response.data.engines
}

async function fetchCode(sandboxUrl, engine, opts) {
  const url = `${sandboxUrl}/repl/engines/${engine}/code`
  const response = await api({ method: 'get', url, opts })
  return response.data.data
}

async function postCode(sandboxUrl, engine, code, opts) {
  const url = `${sandboxUrl}/repl/engines/${engine}/code`
  const data = { data: code }
  const response = await api({ method: 'post', url, data, opts })
}

function cleanup(state) {
  if (state.sbrepl.eventSource) {
    state.sbrepl.eventSource.close()
  }
}

export function setSelectedEngine(engine) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({ type: SET_SELECTED_ENGINE, engine })

    if (
      state.sbrepl.replUrl &&
      state.sbrepl.supportedEngines.includes(engine)
    ) {
      dispatch({ type: BEGIN_FETCHING_CODE })
      fetchCode(state.sbrepl.replUrl, engine, requestOpts(state))
        .then((code) => {
          _log(
            `loaded ${engine} code (${code.length} bytes) from repl on engine change`
          )
          dispatch({ type: FINISH_FETCHING_CODE, code, engine })
        })
        .catch((err) => {
          _warn(`failed to load ${engine} code from repl on engine change`, err)
          dispatch({ type: FINISH_FETCHING_CODE, code: null, engine })
        })
    }
  }
}

export function saveSelectedEngineAsPreferred() {
  return (dispatch, getState) => {
    const state = getState()
    const engine = state.sbrepl.selectedEngine
    if (engine) {
      dispatch({ type: SET_PREFERRED_ENGINE, engine })
      localStorage.setItem('hackedu.lastLessonLanguage', engine)
    }
  }
}

export function noteUserHasOpenedCodeEditor() {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.sbrepl.userHasOpenedCodeEditor) {
      dispatch({ type: NOTE_USER_HAS_OPENED_CODE_EDITOR })
      const newEngine = getEngineToSelect(
        true,
        state.sbrepl.preferredEngine,
        state.sbrepl.supportedEngines
      )
      if (newEngine !== state.sbrepl.selectedEngine) {
        _log(
          `user has opened code editor; switching from ${state.sbrepl.selectedEngine} to ${newEngine}`
        )
        dispatch(setSelectedEngine(newEngine))
      } else {
        _log(`user has opened code editor; ${newEngine} is already preferred`)
      }
    }
  }
}

export function setup(replUrl, eventStreamUrl) {
  return (dispatch, getState) => {
    // Close any prior connections that we might still have open
    let state = getState()
    cleanup(state)

    // We should have the URL for the sandbox's /repl/eval event stream: open a connection to start
    // receiving a stream of 'repl-errors' events
    _log(`opening repl event stream: ${eventStreamUrl}`)
    let fetchOpts = requestOpts(state)
    const eventSource = new EventSource(eventStreamUrl, fetchOpts)

    // Add an event listener that relays output to the tty module
    eventSource.addEventListener('repl-errors', (event) => {
      const message = JSON.parse(event.data)
      _log('repl message', message)
      if (message.output && message.output !== '') {
        dispatch(tty.pushMultilineOutput(message.output))
      }
    })

    // Fetch the list of repl engines supported by this sandbox
    fetchSupportedEngines(replUrl, fetchOpts).then((supportedEngines) => {
      _log(
        `got supported engines from sandbox repl: ${supportedEngines.join(
          ', '
        )}`
      )

      // Choose an initial language and load the repl code for it. If the user hasn't opened the
      // code editor yet, we're free to fall back on python for the sake of webapp responsiveness.
      if (supportedEngines.length > 0) {
        const state = getState()
        const engine = getEngineToSelect(
          state.sbrepl.userHasOpenedCodeEditor,
          state.sbrepl.preferredEngine,
          supportedEngines
        )
        _log(
          `loading ${engine} code initially${
            state.sbrepl.preferredEngine === engine
              ? ''
              : `, despite preference for ${state.sbrepl.preferredEngine}`
          }`
        )

        dispatch({ type: SET_SELECTED_ENGINE, engine })
        dispatch({ type: BEGIN_FETCHING_CODE })
        fetchCode(replUrl, engine, requestOpts(state))
          .then((code) => {
            _log(
              `loaded ${engine} code (${code.length} bytes) from repl on init`
            )
            dispatch({ type: FINISH_FETCHING_CODE, code, engine })
          })
          .catch((err) => {
            _warn(`failed to load ${engine} code from repl on init`, err)
            dispatch({ type: FINISH_FETCHING_CODE, code: null, engine })
          })
      } else {
        _warn('sandbox has repl but no engines are supported')
      }

      // sbrepl initialization is complete; update our state
      dispatch({
        type: SETUP,
        replUrl: replUrl,
        eventStreamUrl,
        eventSource,
        supportedEngines,
      })
    })
  }
}

export function teardown() {
  return (dispatch, getState) => {
    cleanup(getState())
    dispatch({ type: TEARDOWN })
  }
}

export function updateLocalCode(newLocalCode) {
  return {
    type: UPDATE_LOCAL_CODE,
    code: newLocalCode,
  }
}

export function applyLocalCodeToSandbox() {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.sbrepl.replUrl) {
      _warn('failed to apply local code to sandbox: no sandbox URL')
      return
    }

    dispatch({ type: BEGIN_PATCHING_SANDBOX })

    const savedCode = state.sbrepl.localCode
    return postCode(
      state.sbrepl.replUrl,
      state.sbrepl.codeEngine,
      savedCode,
      requestOpts(state)
    )
      .then(() => {
        _log(`applied local code (${savedCode.length} bytes) to sandbox`)
        dispatch({ type: FINISH_PATCHING_SANDBOX, code: savedCode })
      })
      .catch((err) => {
        _warn('failed to apply local code to sandbox', err)
        dispatch({ type: FINISH_PATCHING_SANDBOX, code: state.sbrepl.code })
      })
  }
}

export function submitPatchForTesting(contentId) {
  return (dispatch, getState) => {
    // hacker.js is responsible for code submissions, so... let's just... uh, copy this from
    // repl.js, I guess? TODO: Define a cleaner interface for test submissions.
    const state = getState()
    if (state.auth.status === 'LOGGED_IN') {
      dispatch({ type: BEGIN_SUBMITTING_PATCH_FOR_TESTING })
      const onFinish = () => {
        _log('finished submitting patch for testing')
        dispatch(repl.setRunningTests(true))
        dispatch({ type: FINISH_SUBMITTING_PATCH_FOR_TESTING })
      }
      const onError = (error) => {
        _warn('error submitting patch', error)
        const errorStr = error.toString()
        dispatch({
          type: FINISH_SUBMITTING_PATCH_FOR_TESTING,
          error: errorStr,
        })
      }
      const engine = state.sbrepl.codeEngine
      const code = state.sbrepl.patchedCode
      dispatch(
        hacker.addCode(contentId, engine, code, null, onFinish, false, onError)
      )
    } else {
      _warn('unable to submit patch for testing: not logged in')
    }
  }
}
