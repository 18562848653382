import React from 'react'
import PropTypes from 'prop-types'

import ToggleSwitch from 'app/views/components/ToggleSwitch'

function SandboxInterceptSwitch(props) {
  const { tabIndex, isEnabled, onChange } = props
  return (
    <div
      id={`browser-tab-${tabIndex}-proxy-switch`}
      className='flex items-center flex-row justify-center'
    >
      <ToggleSwitch
        checked={isEnabled}
        onChange={onChange}
        label='Intercept Requests'
      />
    </div>
  )
}
SandboxInterceptSwitch.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SandboxInterceptSwitch
