import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'app/views/components/GustavoIcon'

import { HBox } from 'app/views/core/Box'

import { getEngineDisplayName } from 'app/views/utils/engine'
import EditorLanguageModal from './EditorLanguageModal'

function EditorLanguageDisplay(props) {
  const {
    preferredEngine,
    codeEngine,
    supportedEngines,
    onSelectEngine,
    onSaveSelectedEngineAsPreferred,
  } = props
  const text = codeEngine ? getEngineDisplayName(codeEngine) : '...'
  const [wantsLanguageDetails, setWantsLanguageDetails] = useState(false)
  return (
    <HBox style={{ alignItems: 'baseline' }}>
      <div className='text-sm' style={{ marginRight: 8, color: '#666' }}>
        Language:
      </div>
      <div
        id='code-editor-language-button'
        className='uk-button uk-button-small'
        style={{
          border: '1px #eee solid',
          boxShadow: '2px 2px 1px rgba(0, 0, 0, 0.2)',
          borderRadius: 8,
          backgroundColor: '#fff',
          color: '#666',
          overflow: 'hidden',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          paddingRight: 8,
        }}
        onClick={() => {
          if (codeEngine) {
            setWantsLanguageDetails(true)
          }
        }}
      >
        {text}
        <Icon fill name='arrow-down-s' style={{ color: '#999' }} />
      </div>
      {wantsLanguageDetails && (
        <EditorLanguageModal
          preferredEngine={preferredEngine}
          currentEngine={codeEngine}
          supportedEngines={supportedEngines}
          onSelectEngine={onSelectEngine}
          onSaveSelectedEngineAsPreferred={onSaveSelectedEngineAsPreferred}
          onClose={() => setWantsLanguageDetails(false)}
        />
      )}
    </HBox>
  )
}
EditorLanguageDisplay.propTypes = {
  preferredEngine: PropTypes.string,
  codeEngine: PropTypes.string,
  supportedEngines: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectEngine: PropTypes.func.isRequired,
  onSaveSelectedEngineAsPreferred: PropTypes.func.isRequired,
}

export default EditorLanguageDisplay
