import React, { useState, useEffect } from 'react'
import { withIdleTimer } from 'react-idle-timer'
import { USER_IDLE_TIMEOUT, USER_PROMPT_TIMEOUT } from 'app/views/constants'

class IdleTimerComponent extends React.Component {
  render() {
    return <>{this.props.children}</>
  }
}

const IdleTimer = withIdleTimer(IdleTimerComponent)

export class UserIdle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeoutPrompt: false,
    }
    this.idleTimer = null
    this.promptTimer = null
    this.onIdle = this.onIdle.bind(this)
    this.onAction = this.onAction.bind(this)
  }

  onIdle() {
    // onIdle will be called after the timeout is reached
    // when onIdle is called, the user will be logged out a short period of time after
    // the prompt modal is displayed
    if (this.props.location.pathname != '/login') {
      this.setState({ timeoutPrompt: true })
      this.promptTimer = setTimeout(() => {
        this.setState({ timeoutPrompt: false })
        console.warn('User logged out due to inactivity')
        this.props.history.push('/logout')
      }, USER_PROMPT_TIMEOUT)
    }
  }

  onAction() {
    // Resets the modal and the timers when the user performs an action
    this.idleTimer.reset()
    this.setState({ timeoutPrompt: false })
    clearTimeout(this.promptTimer)
  }

  render() {
    return (
      <div className='UserIdle'>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref
          }}
          timeout={USER_IDLE_TIMEOUT}
          onIdle={this.onIdle}
          onAction={this.onAction}
          crossTab={{
            type: 'localStorage',
            removeTimeout: 1000 * 60,
            emitOnAllTabs: true,
          }}
        />
        {/*
        The modal is displayed before a user is logged out when
        isIdle has been triggered due to lack of inactivity
        */}
        <div
          className={this.state.timeoutPrompt ? 'uk-modal uk-open' : 'uk-modal'}
          style={{ display: this.state.timeoutPrompt ? 'block' : 'none' }}
          data-uk-modal={'bg-close: true; esc-close: true; stack: true;'}
        >
          <div className={'uk-modal-dialog'} style={this.props.style}>
            <h2
              className='text-center uk-margin-remove'
              id='title-idle-timer-prompt'
            >
              Session Timeout
            </h2>
            <div className='uk-modal-body'>
              <div className='text-center'>
                <p>
                  Your session will expire in{' '}
                  {USER_PROMPT_TIMEOUT / (1000 * 60)} minutes due to inactivity.
                  Please resume activities to stay signed in. Otherwise, you
                  will be logged off automatically.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
