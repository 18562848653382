import React from 'react'
import UIkit from 'uikit'

export default class ModalError extends React.Component {
  constructor(props) {
    super(props)
    this.hide = this.hide.bind(this)
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  show() {
    UIkit.modal(this.props.id ? `#${this.props.id}` : '#_modal_error').show()
  }

  hide() {
    if (this.props.reloadOnConfim) {
      window.location.reload()
    }
    UIkit.modal(this.props.id ? `#${this.props.id}` : '#_modal_error').hide()
  }

  render() {
    return (
      <div
        data-test-id={this.props['data-test-id']}
        id={this.props.id || '_modal_error'}
        className='uk-modal'
        data-uk-modal='bg-close: false; esc-close: false; stack: true'
      >
        <div className='uk-modal-dialog uk-modal-body'>
          <h2>{this.props.title || 'Error'}</h2>
          {this.props.showCustomRender ? (
            <React.Fragment>{this.props.render()}</React.Fragment>
          ) : (
            <p>{this.props.errorText || 'an unexpected error has occurred.'}</p>
          )}
          <p className='text-right'>
            <button
              className='uk-button uk-button-primary'
              type='button'
              onClick={this.hide}
            >
              Ok
            </button>
          </p>
        </div>
      </div>
    )
  }
}
