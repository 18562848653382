import React from 'react'

// Components
import ConfirmButton from 'app/views/components/ConfirmButton'
import PopUpMenu from 'app/views/components/PopUpMenu'
import Icon from 'app/views/components/Icon'

// Utils
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import PrimaryButton from '/app/views/components/Buttons/PrimaryButton'

class EditTeamUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { props } = this

    if (props.selectedUser.teams === 'loading') {
      return (
        <div className='flex justify-center items-center uk-height-1-1'>
          <span data-uk-spinner='' />
        </div>
      )
    }
    return (
      <>
        <div>
          <h2 className='uk-margin-remove-bottom'>
            {props.selectedUser.email}
          </h2>
          {props.selectedUser.last_seen ? (
            <i className='text-sm text-muted'>{`Last seen: ${getLocalDateFromUTC(
              props.selectedUser.last_seen
            ).fromNow()}`}</i>
          ) : (
            <i className='text-sm text-muted'>Last seen: Never</i>
          )}
          <hr />
          <h3
            style={{
              marginBottom: 15,
              marginTop: 10,
            }}
          >
            Organization Role:
            <span>
              {props.selectedUser.role === 'admin' ? ' Admin' : ' Member'}
            </span>
          </h3>
          {props.isAdmin && props.hackerUUID !== props.selectedUser.uuid && (
            <>
              <ConfirmButton
                text={
                  <>
                    Change role to
                    {props.selectedUser.role === 'admin' ? (
                      <b>&nbsp;Member</b>
                    ) : (
                      <b>&nbsp;Admin</b>
                    )}
                  </>
                }
                confirmClick={() =>
                  props.handleClickMakeAdmin(
                    props.selectedUser.uuid,
                    props.selectedUser.role === 'admin' ? 'member' : 'admin'
                  )
                }
              />
              <button
                type='button'
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
                className='uk-button uk-button-danger uk-width-1-1 _no_border_radius'
                onClick={props.onRemoveFromOrg}
              >
                <Icon
                  name='delete-bin'
                  style={{
                    position: 'absolute',
                    left: 10,
                  }}
                />
                Remove from organization
              </button>
            </>
          )}
          <hr />
          {props.isTimeBased &&
            (props.selectedUser.active_phases || []).length > 0 && (
              <>
                <h3 style={{ marginTop: 15 }}>Current Phase</h3>
                <span
                  className='uk-label uk-label-secondary uk-width-1-1 text-center'
                  style={{
                    padding: 5,
                  }}
                >
                  {props.selectedUser.active_phases[0].phase_title}
                </span>
                <PopUpMenu
                  id='pop-up-change-phase'
                  leftCenter
                  containerClass='uk-width-1-1'
                  isTextTrigger
                  beforeShow={() => {
                    document.getElementsByClassName(
                      '_right-side-bar _show'
                    )[0].style.overflowY = 'unset'
                  }}
                  afterHide={() => {
                    const element = document.getElementsByClassName(
                      '_right-side-bar  _show'
                    )[0]
                    if (element) {
                      element.style.overflowY = 'auto'
                    }
                  }}
                  customTextElement={
                    <button
                      type='button'
                      className='uk-button uk-button-small uk-button-default uk-width-1-1'
                      style={{ borderRadius: 2, marginTop: 10 }}
                    >
                      Change user phase
                    </button>
                  }
                  data-test-id='change-phase-menu'
                  refObj={props.selectedUser}
                  title='Change phase'
                  selected={props.selectedUser.active_phases[0].phase_uuid}
                  onSelect={props.onChangeUserPhase}
                  options={(props.phases || []).map(({ uuid, title }) => ({
                    id: uuid,
                    text: title,
                  }))}
                />
                <hr />
              </>
            )}
          <h3
            style={{
              marginBottom: 15,
              marginTop: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            Teams
            {props.isAdmin && (
              <a
                onClick={() => props.modalUserTeamPicker.show()}
                style={{
                  position: 'absolute',
                  right: 20,
                  fontSize: 16,
                  textDecoration: 'underline',
                }}
              >
                Change Teams
              </a>
            )}
          </h3>
          <table className='_teams-hacker-table uk-table uk-table-small uk-table-divider uk-width-1-1'>
            <thead>
              <tr>
                <th className='uk-width-1-4'>TEAM NAME</th>
                <th className='uk-width-1-4'>ROLE</th>
                <th className='uk-width-1-4'>INVITE PRIVILEGES</th>
                <th className='uk-width-1-4' />
              </tr>
            </thead>
            <tbody>
              {props.selectedUser.teams.map((team) => (
                <tr key={`row-${team.team_uuid}`}>
                  <td>{team.name}</td>
                  <td>
                    {props.selectedUser.role === 'admin'
                      ? 'Admin'
                      : team.team_role === 'member'
                      ? 'Member'
                      : 'Team Admin'}
                  </td>
                  <td>
                    <Icon
                      className={`${
                        (team.invite_privileges &&
                          team.team_role === 'team_admin') ||
                        props.selectedUser.role === 'admin'
                          ? 'text-success'
                          : 'text-danger'
                      }`}
                      name={`${
                        (team.invite_privileges &&
                          team.team_role === 'team_admin') ||
                        props.selectedUser.role === 'admin'
                          ? 'check'
                          : 'close'
                      }`}
                    />
                  </td>
                  <td>
                    {(props.selectedUser.role !== 'admin' ||
                      props.organization.role === 'admin') && (
                      <PopUpMenu
                        id='pop-up-edit-team-team'
                        beforeShow={() => {
                          document.getElementsByClassName(
                            '_right-side-bar _show'
                          )[0].style.overflowY = 'unset'
                        }}
                        afterHide={() => {
                          const element = document.getElementsByClassName(
                            '_right-side-bar  _show'
                          )[0]
                          if (element) {
                            element.style.overflowY = 'auto'
                          }
                        }}
                        leftCenter
                        style={{
                          width: 450,
                        }}
                        customRender={() => {
                          return (
                            <>
                              <h4>
                                {`${
                                  props.selectedUser.role === 'admin'
                                    ? 'Role:'
                                    : 'Team Role:'
                                }`}
                                {props.selectedUser.role === 'admin' ||
                                team.team_role === 'admin' ? (
                                  <b> Admin</b>
                                ) : (
                                  <b> Member</b>
                                )}
                              </h4>

                              {props.selectedUser.role === 'admin' && (
                                <p className='italic'>
                                  This user is an Organization Admin so their
                                  role cannot be changed at the team level.
                                </p>
                              )}

                              {props.selectedUser.role !== 'admin' && (
                                <>
                                  <ConfirmButton
                                    isSmall
                                    text={
                                      <>
                                        Change Role to
                                        {team.team_role === 'team_admin'
                                          ? ' Team Member'
                                          : ' Team Admin'}
                                      </>
                                    }
                                    confirmClick={() => {
                                      props.updateTeamPrivileges(
                                        props.match.params.organization_uuid,
                                        team.team_uuid,
                                        props.selectedUser.uuid,
                                        {
                                          team_admin: !(
                                            team.team_role === 'team_admin'
                                          ),
                                        }
                                      )
                                    }}
                                  />
                                  <hr />
                                </>
                              )}
                              {team.team_role === 'team_admin' &&
                                props.hasInvitePrivileges &&
                                props.selectedUser.role !== 'admin' && (
                                  <>
                                    <form className='uk-form-stacked'>
                                      <div className='uk-margin'>
                                        <div className='uk-form-controls'>
                                          <label
                                            style={{
                                              lineHeight: 2,
                                            }}
                                          >
                                            <input
                                              // disabled={props.selectedUser.role === 'admin'}
                                              className='uk-radio'
                                              type='radio'
                                              name='radio1'
                                              checked={
                                                team.invite_privileges === true
                                              }
                                              onChange={() => {
                                                props.updateTeamPrivileges(
                                                  props.match.params
                                                    .organization_uuid,
                                                  team.team_uuid,
                                                  props.selectedUser.uuid,
                                                  {
                                                    can_invite_users: true,
                                                  }
                                                )
                                              }}
                                            />
                                            &nbsp;&nbsp;Can invite users
                                          </label>
                                          <br />
                                          <label
                                            style={{
                                              lineHeight: 2,
                                            }}
                                          >
                                            <input
                                              // disabled={props.selectedUser.role === 'admin'}
                                              className='uk-radio'
                                              type='radio'
                                              name='radio1'
                                              checked={
                                                team.invite_privileges === false
                                              }
                                              onChange={() => {
                                                props.updateTeamPrivileges(
                                                  props.match.params
                                                    .organization_uuid,
                                                  team.team_uuid,
                                                  props.selectedUser.uuid,
                                                  {
                                                    can_invite_users: false,
                                                  }
                                                )
                                              }}
                                            />
                                            &nbsp;&nbsp;Restrict invite
                                            privileges
                                          </label>
                                        </div>
                                      </div>
                                    </form>
                                    <hr />
                                  </>
                                )}
                              <ConfirmButton
                                isSmall
                                isDanger
                                text={
                                  <>
                                    <Icon
                                      name='delete-bin'
                                      style={{
                                        position: 'absolute',
                                        left: 10,
                                      }}
                                    />
                                    Remove from Team
                                  </>
                                }
                                confirmClick={() => props.onRemoveFromTeam()}
                              />
                            </>
                          )
                        }}
                        isTextTrigger
                        text='Edit'
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
          <small
            style={{
              lineHeight: 1,
            }}
          >
            You can assign content to a single user. This content not will be
            shown as part of the user plan but as extra content in the user's
            plan page.
          </small>
          <PrimaryButton
            onClick={this.props.onShowCustomModal}
            size='full'
            label='Assign content'
          />
          <br />
          <br />
          <br />
          <br />
        </div>
      </>
    )
  }
}

export default EditTeamUser
