/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import axios from 'axios'
import UIkit from 'uikit'
import * as moment from 'moment'
import WebSocket from 'isomorphic-ws'
import { api } from 'app/views/utils/api'
import settings from 'settings'
import base64 from 'app/state/utils/base64'

import { addCode } from './hacker'
import { getSandbox } from './k8s'

import { UPDATE_CONTENT_HACKER_METADATA } from './content'

const ADD_CODE_TAB = 'codeReview/ADD_CODE_TAB'
const SET_TOTAL_RESETS = 'codeReview/SET_TOTAL_RESETS'
const SET_TABS = 'codeReview/SET_TABS'
const SAVING_FILES = 'codeReview/SAVING_FILES'
const SET_OUTPUT = 'codeReview/SET_OUTPUT'
const SET_FILE_SYSTEM = 'codeReview/SET_FILE_SYSTEM'
const RESET_FILE_SYSTEM_KEYS = 'codeReview/RESET_FILE_SYSTEM_KEYS'
const ADD_TO_FILE_SYSTEM = 'codeReview/ADD_TO_FILE_SYSTEM'
const SET_LOADING_FILES = 'codeReview/SET_LOADING_FILES'
const UPDATE_TABS = 'codeReview/UPDATE_TABS'
const SET_STACK_TRACE = 'codeReview/SET_STACK_TRACE'
const UPDATE_CODE = 'codeReview/UPDATE_CODE'
const REMOVE_CODE_TAB = 'codeReview/REMOVE_CODE_TAB'

const SET_MODAL = 'codeReview/SET_MODAL'
const SET_SANDBOX_UUID = 'codeReview/SET_SANDBOX_UUID'
const SET_MARKERS = 'codeReview/SET_MARKERS'
const SET_SUCCESS_DETAIL_MODAL = 'codeReview/SET_SUUCCESS_DETAIL_MODAL'

export const SET_INITIAL_FILE = 'codeReview/SET_INITIAL_FILE'
export const SET_INITIAL_PATH = 'codeReview/SET_INITIAL_PATH'

export const SET_VERSIONS = 'codeReview/SET_VERSIONS'
const ADD_NEW_VERSION = 'codeReview/ADD_NEW_VERSION'
const SET_LOADING_VERSION = 'codeReview/SET_LOADING_VERSION'
const SET_SELECTED_VERSION = 'codeReview/SET_SELECTED_VERSION'
const SET_ERROR_MARKDOWN = 'codeReview/SET_ERROR_MARKDOWN'
const SET_ERROR_NAME = 'codeReview/SET_ERROR_NAME'

const RESET_STATE = 'codeReview/RESET_STATE'

const mergeFileSystem = (replacePath, oldFileSystem, newFileSystem) =>
  oldFileSystem.reduce((p, c) => {
    if (c.path === replacePath) {
      p.push({
        ...c,
        directories: newFileSystem.directories,
        files: newFileSystem.files,
      })
    } else if (c.directories.length > 0) {
      p.push({
        ...c,
        directories: mergeFileSystem(replacePath, c.directories, newFileSystem),
      })
    } else {
      p.push(c)
    }
    return p
  }, [])

const INITIAL_STATE = {
  totalResets: 0,
  initialPath: [],
  initialFile: null,
  engine: null,
  filesystem: {},
  fileSystemKeys: {},
  loadingFiles: false,
  tabs: [],
  output: '',
  activeFileTab: null,
  stackTrace: null,
  errorMarkdown: null,
  errorName: null,

  indexEditing: 0,
  code: '',

  modal: 'hide',

  markers: null,
  highlightedFiles: {},

  sandboxUUID: null,
  sandboxAppUrl: null,
  sandboxTerminalUrl: null,
  sandboxApiUrl: null,
  versions: [],
  successDetailModal: {},
  selectedVersion: 'original',
  loadingVersion: false,

  savingFiles: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TOTAL_RESETS:
      return {
        ...state,
        totalResets: action.totalResets,
      }
    case SET_ERROR_MARKDOWN:
      return {
        ...state,
        errorMarkdown: action.errorMarkdown,
      }
    case SET_ERROR_NAME:
      return {
        ...state,
        errorName: action.errorName,
      }
    case SAVING_FILES:
      return {
        ...state,
        savingFiles: action.savingFiles,
      }
    case SET_VERSIONS:
      return {
        ...state,
        versions: action.versions,
      }
    case SET_SUCCESS_DETAIL_MODAL:
      return {
        ...state,
        successDetailModal: action.successDetailModal,
      }
    case SET_INITIAL_PATH:
      return {
        ...state,
        initialPath: action.initialPath,
      }
    case SET_INITIAL_FILE:
      return {
        ...state,
        initialFile: action.initialFile,
        activeFileTab: action.initialFile,
        engine: action.engine,
      }
    case SET_SANDBOX_UUID:
      return {
        ...state,
        sandboxUUID: action.sandboxUUID,
        sandboxApiUrl: action.sandboxApiUrl,
        sandboxAppUrl: action.sandboxAppUrl,
        sandboxTerminalUrl: action.sandboxTerminalUrl,
      }
    case SET_STACK_TRACE:
      return {
        ...state,
        stackTrace: action.stackTrace,
      }
    case SET_MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    case UPDATE_TABS:
      return {
        ...state,
        tabs: [...action.tabs],
      }
    case SET_FILE_SYSTEM:
      return {
        ...state,
        filesystem: action.filesystem,
      }
    case ADD_TO_FILE_SYSTEM:
      return {
        ...state,
        filesystem: {
          ...state.filesystem,
          directories: mergeFileSystem(
            action.path,
            state.filesystem.directories,
            action.filesystem
          ),
        },
        // fileSystemKeys: action.filesystem.directories.reduce((p, c) => {
        //   p[c.path] = true
        //   return p
        // }, { ...state.fileSystemKeys, [action.path]: true }),
        fileSystemKeys: {
          ...state.fileSystemKeys,
          [action.path]: true,
        },
      }
    case RESET_FILE_SYSTEM_KEYS:
      return {
        ...state,
        fileSystemKeys: {},
      }
    case SET_LOADING_FILES:
      return {
        ...state,
        loadingFiles: action.loadingFiles,
        tabs: [],
      }
    case SET_OUTPUT:
      return {
        ...state,
        output: action.output,
      }
    case ADD_CODE_TAB:
      return {
        ...state,
        tabs: action.tabs,
      }
    case SET_TABS:
      return {
        ...state,
        tabs: action.tabs,
      }
    case UPDATE_CODE:
      return {
        ...state,
        code: action.code,
        indexEditing: action.indexEditing,
      }
    case REMOVE_CODE_TAB:
      return {
        ...state,
        tabs: state.tabs.filter((t) => {
          return t.file !== action.fileName
        }),
      }
    case SET_MARKERS:
      return {
        ...state,
        markers: action.markers,
        highlightedFiles: action.highlightedFiles,
      }
    case ADD_NEW_VERSION:
      return {
        ...state,
        versions: [action.version, ...state.versions],
      }
    case SET_SELECTED_VERSION:
      return {
        ...state,
        selectedVersion: action.selectedVersion,
      }
    case SET_LOADING_VERSION:
      return {
        ...state,
        loadingVersion: action.loadingVersion,
      }
    case RESET_STATE:
      return {
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export const setVersions = (versions) => ({
  type: SET_VERSIONS,
  versions,
})

export const setSelectedVersion = (selectedVerion) => ({
  type: SET_SELECTED_VERSION,
  selectedVerion,
})

export const resetTabs = () => ({
  type: SET_TABS,
  tabs: [],
})

export const resetMarkers = () => ({
  type: SET_MARKERS,
  markers: null,
  highlightedFiles: {},
})

export const setInitialFile = (initialFile, engine) => ({
  type: SET_INITIAL_FILE,
  initialFile,
  activeFileTab: initialFile,
  engine,
})

const getInitialRoutesFileSystem = () => async (dispatch, getState) => {
  const { codeReview } = getState()
  Promise.all(
    codeReview.initialPath.map((_, i) => {
      const path = codeReview.initialPath.slice(0, i + 1).join('/')
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: `${codeReview.sandboxApiUrl}/filesystem?dirname=${path}`,
        })
          .then(({ data }) => {
            resolve({ filesystem: data, path })
          })
          .catch((err) => {
            reject(err)
          })
      })
    })
  ).then((responses) => {
    responses.forEach((response) => {
      dispatch({
        type: ADD_TO_FILE_SYSTEM,
        filesystem: response.filesystem,
        path: response.path,
      })
    })
  })
}

export const getFile =
  (file, callback = false) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState()
      const { tabs } = state.codeReview
      let indexEditing = -1
      let code = null
      let tabExists = false
      tabs.forEach((t, i) => {
        if (t.file === file) {
          t.active = true
          tabExists = true
          code = t.code
          indexEditing = i
        } else {
          t.active = false
        }
      })

      if (!tabExists) {
        tabs.push({
          file,
          active: true,
          changed: false,
          loading: true,
        })
        dispatch({
          type: UPDATE_TABS,
          tabs,
        })
        indexEditing = tabs.length - 1
        dispatch({
          type: UPDATE_CODE,
          indexEditing,
        })
        axios({
          method: 'GET',
          url: `${state.codeReview.sandboxApiUrl}/file`,
          params: {
            filename: file,
          },
        })
          .then(({ data }) => {
            code = base64.decode(data.file)
            const { tabs: updatedTabs } = getState().codeReview
            dispatch({
              type: UPDATE_TABS,
              tabs: updatedTabs.map((t) => {
                if (t.file === file) {
                  return {
                    loading: false,
                    changed: false,
                    active: true,
                    file,
                    code: code.trimLeft(),
                  }
                }
                return t
              }),
            })
            dispatch({
              type: UPDATE_CODE,
              code,
              indexEditing,
            })
            if (typeof callback === 'function') {
              callback()
            }
            resolve(true)
          })
          .catch((err) => {
            console.error(err)
            dispatch({
              type: UPDATE_TABS,
              tabs: tabs.filter((t) => t.file !== file),
            })
            reject(err)
          })
      } else {
        dispatch({
          type: UPDATE_TABS,
          tabs,
        })
        dispatch({
          type: UPDATE_CODE,
          code,
          indexEditing,
        })
        if (typeof callback === 'function') {
          callback()
        }
        resolve(true)
      }
    })
  }

export const getFileSystem = (
  path = null,
  cancelInitialFile = false,
  callback = false
) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!path) {
      dispatch({
        type: SET_LOADING_FILES,
        loadingFiles: true,
      })
    }
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${state.codeReview.sandboxApiUrl}/filesystem?${
          path ? `dirname=${path}` : ''
        }`,
      })
      if (Object.keys(state.codeReview.filesystem).length === 0) {
        dispatch({
          type: SET_FILE_SYSTEM,
          filesystem: data,
        })
      } else {
        dispatch({
          type: ADD_TO_FILE_SYSTEM,
          filesystem: data,
          path,
        })
      }
      const arrayPath = (state.codeReview.initialFile || '').split('/')
      if (arrayPath.length > 0) {
        arrayPath.pop()
      }
      dispatch({
        type: SET_INITIAL_PATH,
        initialPath: [...arrayPath],
      })
      if (path === null) {
        dispatch(getInitialRoutesFileSystem())
        dispatch({
          type: SET_LOADING_FILES,
          loadingFiles: false,
        })
        dispatch({
          type: SET_TABS,
          tabs: [],
        })
      }
      if (typeof callback === 'function') {
        callback()
      }
      if (!cancelInitialFile) {
        dispatch(getFile(state.codeReview.initialFile))
      }
    } catch (err) {
      console.error({ err })
      dispatch({
        type: SET_INITIAL_PATH,
        initialPath: {},
      })
      dispatch({
        type: SET_LOADING_FILES,
        loadingFiles: false,
      })
    }
  }
}

export const changeVersion =
  (version, contentUUID) => async (dispatch, getState) => {
    dispatch({
      type: SET_LOADING_VERSION,
      loadingVersion: true,
    })
    dispatch({
      type: SET_SELECTED_VERSION,
      selectedVersion: version,
    })

    const state = getState()
    const lsId = 'hackedu.coding.challenges'
    const localStorageCodingChallengesSandboxes = localStorage.getItem(lsId)
    const savedSandboxes = JSON.parse(
      localStorageCodingChallengesSandboxes || '{}'
    )
    const key = contentUUID
    savedSandboxes[key] = {
      sandboxUUID: state.codeReview.sandboxUUID,
      codeId: version,
    }
    localStorage.setItem(lsId, JSON.stringify(savedSandboxes))

    dispatch({
      type: SET_TABS,
      tabs: [],
    })
    api({
      method: 'GET',
      url: `${settings.urls.controlK8s}/code/${version}`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: SET_LOADING_VERSION,
            loadingVersion: false,
          })
          dispatch(getFileSystem())
        }
      })
      .catch((err) => {
        console.error({ err })
        dispatch({
          type: SET_LOADING_VERSION,
          loadingVersion: false,
        })
      })
  }

export const resetCodeReview = () => ({
  type: RESET_STATE,
})

export const initializeCodeReview = (
  terminal,
  contentUUID,
  isK8s,
  onRef,
  onSandboxCallback = false,
  reset = false,
  cancelInitialFile = false
) => {
  // onRef (socketTerminal ,  socketSandbox)
  return (dispatch, getState) => {
    const state = getState()
    if (reset) {
      dispatch({
        type: RESET_FILE_SYSTEM_KEYS,
      })
    }
    const ecsTaskDefinition =
      state.content.contentDetails[contentUUID].ecs_task_definition

    dispatch(
      getCodingChallengeSandbox(ecsTaskDefinition, reset, (data) => {
        const receivedAt = moment()
        const arr = data.sandboxUrl.replace(/http(s?):\/\//, '').split('.')
        const uuid = arr.shift()
        const apiUrl = data.tabs['api-filesystem'].url
        const terminalURL = `${data.tabs.terminal.url
          .replace('http', 'ws')
          .replace('https', 'wss')}/ws`
        const appUrl = data.tabs.app.url
        const lsId = 'hackedu.coding.challenges'
        const localStorageCodingChallengesSandboxes = localStorage.getItem(lsId)
        const savedSandboxes = JSON.parse(
          localStorageCodingChallengesSandboxes || '{}'
        )
        const key = contentUUID
        const lastContentSandbox = savedSandboxes[key]

        if (lastContentSandbox && lastContentSandbox.sandboxUUID === uuid) {
          dispatch({
            type: SET_SELECTED_VERSION,
            selectedVersion: lastContentSandbox.codeId,
          })
        } else {
          savedSandboxes[key] = {
            sandboxUUID: uuid,
            codeId: 'original',
          }
          localStorage.setItem(lsId, JSON.stringify(savedSandboxes))
          dispatch({
            type: SET_SELECTED_VERSION,
            selectedVersion: 'original',
          })
        }

        dispatch({
          type: SET_SANDBOX_UUID,
          sandboxUUID: uuid,
          sandboxTerminalUrl: terminalURL,
          sandboxApiUrl: apiUrl,
          sandboxAppUrl: appUrl,
        })
        dispatch(
          getFileSystem(null, cancelInitialFile, () => {
            if (typeof onSandboxCallback === 'function') {
              onSandboxCallback()
            }
          })
        )
        const ref = new WebSocket(terminalURL)
        ref.onopen = () => {
          ref.send('{}')
        }

        ref.onclose = () => {
          console.warn('disconnected')
        }
        ref.onmessage = (event) => {
          try {
            if (event.data !== '510') {
              const output = atob(event.data.replace('1', ''))
              const state2 = getState()
              dispatch({
                type: SET_OUTPUT,
                output: state2.codeReview.output + output,
              })
              terminal.write(output)
              if (window.ffterm) {
                window.ffterm.write(output)
              }
            }
          } catch (e) {
            console.error('not base64: ', event.data)
          }
        }
        onRef(ref, null)
      })
    )
  }
}

export const removeTab = (fileName) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_CODE_TAB,
    fileName,
  })
  const state = getState()
  const { tabs } = state.codeReview
  if (tabs.length > 0) {
    dispatch({
      type: UPDATE_CODE,
      code: tabs.length < 0 ? '' : tabs[0].code,
      indexEditing: tabs.length < 0 ? -1 : 0,
    })
  }
}

export const removeCodeTab = (fileName, index) => (dispatch, getState) => {
  const state = getState()
  const tabToRemove = state.codeReview.tabs[index]
  if (tabToRemove.changed === true) {
    UIkit.modal
      .confirm(
        'This file has unsaved changes. Are you sure you want to close it?'
      )
      .then(
        () => {
          dispatch(removeTab(fileName))
          const codingEditorSessions = JSON.parse(
            localStorage.getItem('codingEditorSessions') || '{}'
          )
          delete codingEditorSessions[fileName]
          localStorage.setItem(
            'codingEditorSessions',
            JSON.stringify(codingEditorSessions)
          )
        },
        () => {}
      )
  } else {
    dispatch(removeTab(fileName))
  }
}

export const changeCodeTab = (fileName) => (dispatch, getState) => {
  const state = getState()
  const { tabs } = state.codeReview
  let code = null
  let indexEditing = -1
  tabs.forEach((t, i) => {
    if (t.file === fileName) {
      t.active = true
      code = t.code
      indexEditing = i
    } else {
      t.active = false
    }
  })
  dispatch({
    type: UPDATE_TABS,
    tabs,
  })
  dispatch({
    type: UPDATE_CODE,
    code,
    indexEditing,
  })
}

export const changeCode = (index, code) => (dispatch, getState) => {
  const state = getState()
  const { tabs } = state.codeReview
  tabs[index].code = code
  tabs[index].changed = true
  dispatch({
    type: UPDATE_TABS,
    tabs,
  })
}

export const saveFile =
  (index = null) =>
  async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState()
      const { tabs } = state.codeReview
      const tabToSave =
        tabs[
          typeof index === 'number' && index >= 0
            ? index
            : state.codeReview.indexEditing
        ]
      const content = btoa(tabToSave.code)
      const formData = new FormData()
      formData.append('filename', tabToSave.file)
      formData.append('content', content)
      axios({
        method: 'POST',
        url: `${state.codeReview.sandboxApiUrl}/file`,
        data: formData,
      })
        .then(() => {
          tabs[
            typeof index === 'number' && index >= 0
              ? index
              : state.codeReview.indexEditing
          ].changed = false
          dispatch({
            type: UPDATE_TABS,
            tabs,
          })
          resolve(true)
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }

export const saveAllFiles = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState()
    const { tabs } = state.codeReview
    if (tabs.length > 0) {
      dispatch({
        type: SAVING_FILES,
        savingFiles: true,
      })
      Promise.all(
        tabs.map(async (tab, i) => {
          if (tab.changed) {
            await dispatch(saveFile(i))
          }
          return true
        })
      )
        .then(() => {
          dispatch({
            type: SAVING_FILES,
            savingFiles: false,
          })
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    } else {
      resolve(true)
    }
  })
}

export const showCodeReviewModal = (modal) => ({
  type: SET_MODAL,
  modal,
})

export const onSubmiResults = (contentUUID) => async (dispatch, getState) => {
  const state = getState()
  if (state.codeReview.tabs.length > 0) {
    await dispatch(saveAllFiles())
  }
  dispatch(showCodeReviewModal('loading'))
  dispatch(
    addCode(
      contentUUID,
      state.codeReview.engine,
      ' ',
      (data) => {
        dispatch(showCodeReviewModal(null))
        setTimeout(() => {
          if (data.test.error_stack_trace) {
            dispatch({
              type: SET_STACK_TRACE,
              stackTrace: data.test.error_stack_trace,
            })
            dispatch(showCodeReviewModal('syntax_error'))
          } else if (data.test.passed) {
            dispatch({
              type: SET_STACK_TRACE,
              stackTrace: null,
            })
            dispatch({
              type: SET_SUCCESS_DETAIL_MODAL,
              successDetailModal: {
                attempts: data.attempts,
                total_time: data.total_time,
                hints: data.hints,
              },
            })
            dispatch(showCodeReviewModal('test_success'))
          } else {
            dispatch({
              type: SET_STACK_TRACE,
              stackTrace: null,
            })
            dispatch({
              type: SET_ERROR_MARKDOWN,
              errorMarkdown: data.markdown,
            })
            dispatch({
              type: SET_ERROR_NAME,
              errorName: data.test.error_message,
            })
            dispatch(showCodeReviewModal('test_failed'))
          }
        }, 750)
      },
      (data) => {
        const lsId = 'hackedu.coding.challenges'
        const codingChallengesSandboxes = JSON.parse(
          localStorage.getItem(lsId) || '{}'
        )
        const key = contentUUID
        codingChallengesSandboxes[key] = {
          sandboxUUID: state.codeReview.sandboxUUID,
          codeId: data.id,
        }
        localStorage.setItem(lsId, JSON.stringify(codingChallengesSandboxes))

        dispatch({
          type: ADD_NEW_VERSION,
          version: {
            id: data.id,
            submitted_at: moment(),
          },
        })
        dispatch({
          type: SET_SELECTED_VERSION,
          selectedVersion: data.id.toString(),
        })
      }
    )
  )
}

export const setHintMarkers = (markers, highlightedFiles) => ({
  type: SET_MARKERS,
  markers,
  highlightedFiles,
})

export const getHint = (id, callback) => (dispatch) => {
  api({
    method: 'GET',
    url: `${settings.urls.hacker}/content/hint`,
    params: {
      id,
    },
    withAuthToken: true,
  })
    .then(async ({ data }) => {
      if (callback) callback(data)
      dispatch({
        type: UPDATE_CONTENT_HACKER_METADATA,
        contentUUID: data.content_uuid,
        hintType: id,
        points: data.metadata.points,
      })
      if (data.hint_type === 'highlight') {
        const markers = []
        const highlightedFiles = {}
        for (const [index, highlight] of data.metadata.highlight
          .sort((a, b) => b.startRow - a.startRow)
          .entries()) {
          markers.push({
            startRow: highlight.startRow - 1,
            startCol: 0,
            endRow: highlight.endRow,
            endCol: 0,
            className: 'highlight-marker',
            type: 'background',
            fileName: highlight.filename,
          })
          highlightedFiles[highlight.filename] = true
          if (
            index === data.metadata.highlight.length - 1 &&
            highlight.filename
          ) {
            await dispatch(
              getFile(highlight.filename, () => {
                setTimeout(() => {
                  window.codeReviewEditAreaScroll(
                    (highlight.startRow * window.codeEditorLineHeight < 150
                      ? 150
                      : highlight.startRow * window.codeEditorLineHeight) - 150
                  )
                }, 0)
              })
            )
          } else if (highlight.filename) {
            await dispatch(getFile(highlight.filename))
          }
        }
        dispatch(setHintMarkers(markers, highlightedFiles))
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

export function getCodingChallengeSandbox(
  sandboxTemplate,
  forceNew = undefined,
  successCallback
) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.controlK8s}/sandbox`,
      withAuthToken: true,
      params: {
        forceNew,
        sandboxTemplate,
      },
      opts: {
        withCredentials: true,
      },
    })
      .then(({ data }) => {
        if (!data.status !== 'Running') {
          setTimeout(() => {
            dispatch(getSandbox(sandboxTemplate, undefined, successCallback))
          }, 500)
        } else {
          if (typeof successCallback === 'function') {
            successCallback(data)
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
