/**
 * Encapsulates all the state required to use the Impact Report.
 *
 * From the UI layer (i.e. the ImpactReport component), we call init(organizationUUID)
 * on mount and shutdown() on unmount. This defines the lifetime of the impactReport
 * state, which is tied to a single organization throughout.
 */
import axios from 'axios'

import settings from 'settings'
import { api } from 'app/views/utils/api'

import {
  getCurrentMonth,
  parseMonth,
  monthToString,
  compareMonths,
  resolveAllTimeRange,
  getCombinedAllTimeRange,
  rangeIsSubset,
  reduceDataForDisplay,
} from './util'
import {
  shouldUseMockData,
  mockRequest,
  getMockIssueSources,
  getMockVulnerabilityData,
  getMockCompletionData,
  getMockMaxPossibleCompletions,
} from './data'

// Frontend state related to the display and filtering of the impact report
const initialState = {
  // We make a single up-front request to populate a lookup from vulnerability category ID to name
  categories: {
    isLoading: false, // Whether we have a request pending for vulnerability category data
    error: null, // Any error encountered in the last request
    sortedIds: [], // Integer category IDs, sorted in the desired order for display
    lookup: {}, // Mapping from (stringified) integer ID to human-readable vulnerability category name
  },
  // We make another one-time-only request to get a list of issue sources for the organization
  issueSources: {
    isLoading: false, // Whether we have a request pending for vulnerability category data
    error: null, // Any error encountered in the last request
    data: null, // Mapping of issue source titles to one or more associated issue source UUIDs
  },
  // Another request tells us, very loosely, the maximum possible number of lessons that can be completed within this org
  maxPossibleCompletions: {
    isLoading: false,
    error: null,
    data: null,
  },
  // State describing historical vulnerability data that we've fetched from the issues service
  vulnerability: {
    isLoading: false, // Whether we have a request pending for a new set of data
    error: null, // Any error encountered in the last request
    organizationUUID: null, // Organization UUID to whom this data belongs
    startMonth: null, // Lower bound (inclusive) on the date range of the data, as {year, month}, or null if unbounded
    endMonth: null, // Upper bound (inclusive) on the date range of the data, as {year, month}, or null if unbounded
    data: null, // Vulnerability counts per category, indexed by issue source and month
    allTimeRange: null, // The earliest and latest months for which data exists, if we've fetched data with no time bounds
  },
  // State describing content completion data that we've fetched from (????)
  completion: {
    isLoading: false, // Whether we have a request pending for a new set of data
    error: null, // Any error encountered in the last request
    organizationUUID: null, // Organization UUID to whom this data belongs
    startMonth: null, // Lower bound (inclusive) on the date range of the data, as {year, month}, or null if unbounded
    endMonth: null, // Upper bound (inclusive) on the date range of the data, as {year, month}, or null if unbounded
    data: null, // Total of lessons completed per category, indexed by month
    allTimeRange: null, // The earliest and latest months for which data exists, if we've fetched data with no time bounds
  },
  // State describing what we're actually displaying to the user
  display: {
    organizationUUID: null, // Org within which the data is being shown
    startMonth: null, // Earliest month we want to show data for, as {year, month}, or null for earliest available
    endMonth: null, // Most recent month we want to show data for, as {year, month}, or null this month
    excludedCategoryIds: [], // An array of integer category IDs that we don't want to display data for
    selectedIssueSourceTitles: [],
    data: null,
  },
}

// Functions that operate on our state for the convenience of the UI layer
export function getVulnerabilityCategories(state) {
  let categories = []
  for (const categoryId of state.categories.sortedIds) {
    categories.push({
      id: categoryId,
      title:
        state.categories.lookup[String(categoryId)] ||
        `Vulnerability Category ${categoryId}`,
    })
  }
  return categories
}

export function getIssueSources(state) {
  let sources = []
  const data = state.issueSources.data
  if (!data) {
    return sources
  }

  const titles = state.display.selectedIssueSourceTitles
  for (const issueSourceTitle of Object.keys(data)) {
    const isSelected =
      !titles || titles.length === 0 || titles.includes(issueSourceTitle)
    sources.push({
      title: issueSourceTitle,
      isSelected,
    })
  }
  return sources
}

export function isLoading(state) {
  return (
    state.categories.isLoading ||
    state.issueSources.isLoading ||
    state.maxPossibleCompletions.isLoading ||
    state.vulnerability.isLoading ||
    state.completion.isLoading
  )
}

export function getError(state) {
  if (state.vulnerability.error) {
    return `Error fetching vulnerability data: ${state.vulnerability.error}`
  }
  if (state.completion.error) {
    return `Error fetching completion data: ${state.completion.error}`
  }
  if (state.issueSources.error) {
    return `Error fetching issue sources: ${state.issueSources.error}`
  }
  if (state.maxPossibleCompletions.error) {
    return `Error fetching max possible completions: ${state.maxPossibleCompletions.error}`
  }
  if (state.categories.error) {
    return `Error fetching vulnerability categories: ${state.categories.error}`
  }
  return null
}

export function getDateMode(state, startMonth, endMonth) {
  if (!startMonth && !endMonth) {
    return 'all-time'
  }
  const combinedAllTimeRange = getCombinedAllTimeRange(state)
  if (
    combinedAllTimeRange &&
    compareMonths(startMonth, combinedAllTimeRange.start) === 0 &&
    compareMonths(endMonth, combinedAllTimeRange.end) === 0
  ) {
    return 'all-time'
  }
  if (startMonth && endMonth) {
    const currentMonth = getCurrentMonth()
    if (compareMonths(endMonth, currentMonth) === 0) {
      if (
        startMonth.year === currentMonth.year - 1 &&
        startMonth.month === currentMonth.month
      ) {
        return 'one-year'
      }
    }
  }
  return 'custom'
}

// Actions that modify our state
const prefix = 'impactReport'

const BEGIN_FETCH_VULNERABILITY_CATEGORIES = `${prefix}/BEGIN_FETCH_VULNERABILITY_CATEGORIES`
const ABORT_FETCH_VULNERABILITY_CATEGORIES = `${prefix}/ABORT_FETCH_VULNERABILITY_CATEGORIES`
const RECEIVE_VULNERABILITY_CATEGORIES = `${prefix}/RECEIVE_VULNERABILITY_CATEGORIES`

const BEGIN_FETCH_ISSUE_SOURCES = `${prefix}/BEGIN_FETCH_ISSUE_SOURCES`
const ABORT_FETCH_ISSUE_SOURCES = `${prefix}/ABORT_FETCH_ISSUE_SOURCES`
const RECEIVE_ISSUE_SOURCES = `${prefix}/RECEIVE_ISSUE_SOURCES`

const BEGIN_FETCH_MAX_POSSIBLE_COMPLETIONS = `${prefix}/BEGIN_FETCH_MAX_POSSIBLE_COMPLETIONS`
const ABORT_FETCH_MAX_POSSIBLE_COMPLETIONS = `${prefix}/ABORT_FETCH_MAX_POSSIBLE_COMPLETIONS`
const RECEIVE_MAX_POSSIBLE_COMPLETIONS = `${prefix}/RECEIVE_MAX_POSSIBLE_COMPLETIONS`

// Dispatched when we start, cancel/fail, or finish a request for vulnerability data
const BEGIN_FETCH_VULNERABILITY_DATA = `${prefix}/BEGIN_FETCH_VULNERABILITY_DATA`
const ABORT_FETCH_VULNERABILITY_DATA = `${prefix}/ABORT_FETCH_VULNERABILITY_DATA`
const RECEIVE_VULNERABILITY_DATA = `${prefix}/RECEIVE_VULNERABILITY_DATA`

// Dispatched when we start, cancel/fail, or finish a request for content completion data
const BEGIN_FETCH_COMPLETION_DATA = `${prefix}/BEGIN_FETCH_COMPLETION_DATA`
const ABORT_FETCH_COMPLETION_DATA = `${prefix}/ABORT_FETCH_COMPLETION_DATA`
const RECEIVE_COMPLETION_DATA = `${prefix}/RECEIVE_COMPLETION_DATA`

// Dispatched whenever our display settings change, updating display state and refreshing the display
const UPDATE_DISPLAY = `${prefix}/UPDATE_DISPLAY`

// Dispatched whenever our underlying data changes; to update our display based on our existing display settings
const REFRESH_DISPLAY = `${prefix}/REFRESH_DISPLAY`

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case BEGIN_FETCH_VULNERABILITY_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          isLoading: true,
          error: null,
        },
      }
    case ABORT_FETCH_VULNERABILITY_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          isLoading: false,
          error: action.error || null,
        },
      }
    case RECEIVE_VULNERABILITY_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          isLoading: false,
          error: null,
          sortedIds: action.sortedIds,
          lookup: action.lookup,
        },
        // Default to showing a single category, 'Injection'
        display: {
          ...state.display,
          excludedCategoryIds: action.sortedIds.filter((x) => x !== 1),
        },
      }
    case BEGIN_FETCH_ISSUE_SOURCES:
      return {
        ...state,
        issueSources: {
          ...state.issueSources,
          isLoading: true,
          error: null,
        },
      }
    case ABORT_FETCH_ISSUE_SOURCES:
      return {
        ...state,
        issueSources: {
          ...state.issueSources,
          isLoading: false,
          error: action.error || null,
        },
      }
    case RECEIVE_ISSUE_SOURCES:
      return {
        ...state,
        issueSources: {
          ...state.issueSources,
          isLoading: false,
          data: action.data,
        },
      }
    case BEGIN_FETCH_MAX_POSSIBLE_COMPLETIONS:
      return {
        ...state,
        maxPossibleCompletions: {
          ...state.maxPossibleCompletions,
          isLoading: true,
          error: null,
        },
      }
    case ABORT_FETCH_MAX_POSSIBLE_COMPLETIONS:
      return {
        ...state,
        maxPossibleCompletions: {
          ...state.maxPossibleCompletions,
          isLoading: false,
          error: action.error || null,
        },
      }
    case RECEIVE_MAX_POSSIBLE_COMPLETIONS:
      return {
        ...state,
        maxPossibleCompletions: {
          ...state.maxPossibleCompletions,
          isLoading: false,
          data: action.data,
        },
      }
    case BEGIN_FETCH_VULNERABILITY_DATA:
      return {
        ...state,
        vulnerability: {
          ...state.vulnerability,
          isLoading: true,
          error: null,
        },
      }
    case ABORT_FETCH_VULNERABILITY_DATA:
      return {
        ...state,
        vulnerability: {
          ...state.vulnerability,
          isLoading: false,
          error: action.error || null,
        },
      }
    case RECEIVE_VULNERABILITY_DATA:
      return {
        ...state,
        vulnerability: {
          ...state.vulnerability,
          isLoading: false,
          error: null,
          organizationUUID: action.organizationUUID,
          startMonth: action.startMonth,
          endMonth: action.endMonth,
          data: action.data,
          allTimeRange: resolveAllTimeRange(
            state.vulnerability,
            action.startMonth,
            action.endMonth,
            action.data
          ),
        },
      }
    case BEGIN_FETCH_COMPLETION_DATA:
      return {
        ...state,
        completion: {
          ...state.completion,
          isLoading: true,
          error: null,
        },
      }
    case ABORT_FETCH_COMPLETION_DATA:
      return {
        ...state,
        completion: {
          ...state.completion,
          isLoading: false,
          error: action.error || null,
        },
      }
    case RECEIVE_COMPLETION_DATA:
      return {
        ...state,
        completion: {
          ...state.completion,
          isLoading: false,
          error: null,
          organizationUUID: action.organizationUUID,
          startMonth: action.startMonth,
          endMonth: action.endMonth,
          data: action.data,
          allTimeRange: resolveAllTimeRange(
            state.completion,
            action.startMonth,
            action.endMonth,
            action.data
          ),
        },
      }
    case UPDATE_DISPLAY:
      const desiredOrganizationUUID =
        action.organizationUUID === undefined
          ? state.display.organizationUUID
          : action.organizationUUID
      const desiredStartMonth =
        action.startMonth === undefined
          ? state.display.startMonth
          : action.startMonth
      const desiredEndMonth =
        action.endMonth === undefined ? state.display.endMonth : action.endMonth
      const desiredExcludedCategoryIds =
        action.excludedCategoryIds === undefined
          ? state.display.excludedCategoryIds
          : action.excludedCategoryIds
      const desiredSelectedIssueSourceTitles =
        action.selectedIssueSourceTitles === undefined
          ? state.display.selectedIssueSourceTitles
          : action.selectedIssueSourceTitles
      return {
        ...state,
        display: {
          ...state.display,
          organizationUUID: desiredOrganizationUUID,
          startMonth: desiredStartMonth,
          endMonth: desiredEndMonth,
          excludedCategoryIds: desiredExcludedCategoryIds,
          selectedIssueSourceTitles: desiredSelectedIssueSourceTitles,
          data: reduceDataForDisplay(
            state,
            desiredOrganizationUUID,
            desiredStartMonth,
            desiredEndMonth,
            desiredExcludedCategoryIds,
            desiredSelectedIssueSourceTitles
          ),
        },
      }
    case REFRESH_DISPLAY:
      let newStartMonth = state.display.startMonth
      let newEndMonth = state.display.endMonth
      if (!newStartMonth && !newEndMonth) {
        const combinedAllTimeRange = getCombinedAllTimeRange(state)
        if (combinedAllTimeRange) {
          newStartMonth = combinedAllTimeRange.start
          newEndMonth = combinedAllTimeRange.end
        }
      }
      return {
        ...state,
        display: {
          ...state.display,
          startMonth: newStartMonth,
          endMonth: newEndMonth,
          data: reduceDataForDisplay(
            state,
            state.display.organizationUUID,
            newStartMonth,
            newEndMonth,
            state.display.excludedCategoryIds,
            state.display.selectedIssueSourceTitles
          ),
        },
      }
    default:
      return state
  }
}

const ENABLE_VERBOSE_LOGGING = false
// eslint-disable-next-line no-console
const _log = ENABLE_VERBOSE_LOGGING ? console.log : () => {}
const _warn = ENABLE_VERBOSE_LOGGING ? console.warn : () => {}

async function fetchVulnerabilityCategories(dispatch) {
  try {
    dispatch({ type: BEGIN_FETCH_VULNERABILITY_CATEGORIES })
    const response = await api({
      method: 'get',
      url: `${settings.urls.hacker}/vulnerabilities`,
      withAuthToken: true,
    })
    const data = response.data
    const sortedIds = data
      .sort((a, b) => a.sort_order - b.sort_order)
      .map((x) => x.id)
    const lookup = data.reduce(
      (acc, x) => ({ ...acc, [String(x.id)]: x.title }),
      {}
    )
    dispatch({
      type: RECEIVE_VULNERABILITY_CATEGORIES,
      sortedIds,
      lookup,
    })
  } catch (err) {
    console.error('request for vulnerability categories has failed', err)
    dispatch({ type: ABORT_FETCH_VULNERABILITY_CATEGORIES, error: err })
  }
}

async function fetchIssueSources(organizationUUID, dispatch) {
  if (shouldUseMockData(organizationUUID)) {
    dispatch({ type: BEGIN_FETCH_ISSUE_SOURCES })
    const data = await mockRequest(getMockIssueSources())
    dispatch({ type: RECEIVE_ISSUE_SOURCES, data })
    dispatch({ type: REFRESH_DISPLAY })
    return
  }
  try {
    dispatch({ type: BEGIN_FETCH_ISSUE_SOURCES })
    const response = await api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/report/issue_sources`,
      withAuthToken: true,
    })
    dispatch({
      type: RECEIVE_ISSUE_SOURCES,
      data: response.data,
    })
    dispatch({ type: REFRESH_DISPLAY })
  } catch (err) {
    console.error('request for issue sources has failed', err)
    dispatch({ type: ABORT_FETCH_ISSUE_SOURCES, error: err })
  }
}

async function fetchMaxPossibleCompletions(organizationUUID, dispatch) {
  if (shouldUseMockData(organizationUUID)) {
    dispatch({ type: BEGIN_FETCH_MAX_POSSIBLE_COMPLETIONS })
    const data = await mockRequest(getMockMaxPossibleCompletions())
    dispatch({ type: RECEIVE_MAX_POSSIBLE_COMPLETIONS, data })
    dispatch({ type: REFRESH_DISPLAY })
    return
  }
  try {
    dispatch({ type: BEGIN_FETCH_MAX_POSSIBLE_COMPLETIONS })
    const response = await api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/report/max_possible_completions`,
      withAuthToken: true,
    })
    dispatch({
      type: RECEIVE_MAX_POSSIBLE_COMPLETIONS,
      data: response.data,
    })
    dispatch({ type: REFRESH_DISPLAY })
  } catch (err) {
    console.error('request for max possible completions has failed', err)
    dispatch({ type: ABORT_FETCH_MAX_POSSIBLE_COMPLETIONS, error: err })
  }
}

let g_vulnerabilityCancelToken = null
async function fetchVulnerabilityData(
  organizationUUID,
  startMonth,
  endMonth,
  dispatch
) {
  if (shouldUseMockData(organizationUUID)) {
    dispatch({ type: BEGIN_FETCH_VULNERABILITY_DATA })
    const data = await mockRequest(
      getMockVulnerabilityData(startMonth, endMonth)
    )
    dispatch({
      type: RECEIVE_VULNERABILITY_DATA,
      data: data.data,
      startMonth: data.start ? parseMonth(data.start) : null,
      endMonth: data.end ? parseMonth(data.end) : null,
      organizationUUID,
    })
    dispatch({ type: REFRESH_DISPLAY })
    return
  }
  if (g_vulnerabilityCancelToken !== null) {
    g_vulnerabilityCancelToken.cancel()
  }
  g_vulnerabilityCancelToken = axios.CancelToken.source()
  try {
    dispatch({ type: BEGIN_FETCH_VULNERABILITY_DATA })
    const response = await api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/report/agg_vulnerabilities`,
      params: {
        start: startMonth ? monthToString(startMonth) : null,
        end: endMonth ? monthToString(endMonth) : null,
      },
      withAuthToken: true,
      cancelToken: g_vulnerabilityCancelToken.token,
    })
    g_vulnerabilityCancelToken = null
    const data = response.data
    dispatch({
      type: RECEIVE_VULNERABILITY_DATA,
      data: data.data,
      startMonth: data.start ? parseMonth(data.start) : null,
      endMonth: data.end ? parseMonth(data.end) : null,
      organizationUUID,
    })
    dispatch({ type: REFRESH_DISPLAY })
  } catch (err) {
    if (axios.isCancel(err)) {
      _warn('request for vulnerability data has been canceled')
      dispatch({ type: ABORT_FETCH_VULNERABILITY_DATA })
    } else {
      console.error('request for vulnerability data has failed', err)
      dispatch({ type: ABORT_FETCH_VULNERABILITY_DATA, error: err })
    }
  }
}

let g_completionCancelToken = null
async function fetchCompletionData(
  organizationUUID,
  startMonth,
  endMonth,
  dispatch
) {
  if (shouldUseMockData(organizationUUID)) {
    dispatch({ type: BEGIN_FETCH_COMPLETION_DATA })
    const data = await mockRequest(getMockCompletionData(startMonth, endMonth))
    dispatch({
      type: RECEIVE_COMPLETION_DATA,
      data: data.data,
      startMonth: data.start ? parseMonth(data.start) : null,
      endMonth: data.end ? parseMonth(data.end) : null,
      organizationUUID,
    })
    dispatch({ type: REFRESH_DISPLAY })
    return
  }
  if (g_completionCancelToken !== null) {
    g_completionCancelToken.cancel()
  }
  g_completionCancelToken = axios.CancelToken.source()
  try {
    dispatch({ type: BEGIN_FETCH_COMPLETION_DATA })
    const response = await api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/report/agg_completion`,
      params: {
        start: startMonth ? monthToString(startMonth) : null,
        end: endMonth ? monthToString(endMonth) : null,
      },
      withAuthToken: true,
      cancelToken: g_completionCancelToken.token,
    })
    g_completionCancelToken = null
    const data = response.data
    dispatch({
      type: RECEIVE_COMPLETION_DATA,
      data: data.data,
      startMonth: data.start ? parseMonth(data.start) : null,
      endMonth: data.end ? parseMonth(data.end) : null,
      organizationUUID,
    })
    dispatch({ type: REFRESH_DISPLAY })
  } catch (err) {
    if (axios.isCancel(err)) {
      _warn('request for completion data has been canceled')
      dispatch({ type: ABORT_FETCH_COMPLETION_DATA })
    } else {
      console.error('request for completion data has failed', err)
      dispatch({ type: ABORT_FETCH_COMPLETION_DATA, error: err })
    }
  }
}

function fetchNewDataIfNeeded(
  state,
  displayStartMonth,
  displayEndMonth,
  dispatch
) {
  // Check our cached data to see if it's no longer valid: we're dealing with historical data, so if the new
  // date range is simply a subset of the old one, there's no reason to invalidate our old data and make new requests
  const { vulnerability, completion, display } = state.impactReport
  const needsNewVulnerabilityData = !rangeIsSubset(
    vulnerability.startMonth,
    vulnerability.endMonth,
    displayStartMonth,
    displayEndMonth
  )
  const needsNewCompletionData = !rangeIsSubset(
    completion.startMonth,
    completion.endMonth,
    displayStartMonth,
    displayEndMonth
  )

  // If we need new data, fire off asynchronous requests to fetch it, and update our state when finished
  if (needsNewVulnerabilityData) {
    fetchVulnerabilityData(
      display.organizationUUID,
      displayStartMonth,
      displayEndMonth,
      dispatch
    )
  }
  if (needsNewCompletionData) {
    fetchCompletionData(
      display.organizationUUID,
      displayStartMonth,
      displayEndMonth,
      dispatch
    )
  }
}

export function init(organizationUUID) {
  return (dispatch) => {
    // By default, display data from the past 12 months, up to and including this month
    const endMonth = getCurrentMonth()
    const startMonth = { year: endMonth.year - 1, month: endMonth.month }

    // Initialize our display state
    dispatch({
      type: UPDATE_DISPLAY,
      organizationUUID,
      startMonth,
      endMonth,
    })

    // Make an initial request to get a lookup from vulnerability category ID to name
    fetchVulnerabilityCategories(dispatch)

    // Make another initial request to get a list of issue sources for this org
    fetchIssueSources(organizationUUID, dispatch)

    // Also get the maximum number of content completions this org could theoretically
    // achieve - this also happens only once, on init
    fetchMaxPossibleCompletions(organizationUUID, dispatch)

    // Fire off asynchronous functions to fetch our initial data
    fetchVulnerabilityData(organizationUUID, startMonth, endMonth, dispatch)
    fetchCompletionData(organizationUUID, startMonth, endMonth, dispatch)
  }
}

export function shutdown() {
  return (dispatch, getState) => {
    // Clear our display state on unmount - this is mainly so that if the user navigates away from
    // the Impact Report but then comes back later with a different org/team selected, we don't run the
    // risk of displaying holdover data from the incorrect org or team
    dispatch({
      type: UPDATE_DISPLAY,
      organizationUUID: null,
    })
  }
}

export function setDisplayDateMode(mode) {
  return (dispatch, getState) => {
    const state = getState()
    if (mode === 'all-time') {
      const combinedAllTimeRange = getCombinedAllTimeRange(state.impactReport)
      if (combinedAllTimeRange) {
        dispatch({
          type: UPDATE_DISPLAY,
          startMonth: combinedAllTimeRange.start,
          endMonth: combinedAllTimeRange.end,
        })
      } else {
        const organizationUUID = state.impactReport.display.organizationUUID
        dispatch({
          type: UPDATE_DISPLAY,
          startMonth: null,
          endMonth: null,
        })
        fetchVulnerabilityData(organizationUUID, null, null, dispatch)
        fetchCompletionData(organizationUUID, null, null, dispatch)
      }
    } else if (mode === 'one-year') {
      const currentMonth = getCurrentMonth()
      const oneYearAgo = {
        year: currentMonth.year - 1,
        month: currentMonth.month,
      }
      dispatch({
        type: UPDATE_DISPLAY,
        startMonth: oneYearAgo,
        endMonth: currentMonth,
      })
      fetchNewDataIfNeeded(state, oneYearAgo, currentMonth, dispatch)
    } else if (mode === 'custom') {
      const endMonth = state.impactReport.display.endMonth
        ? state.impactReport.display.endMonth
        : getCurrentMonth()
      let startMonth = state.impactReport.display.startMonth
        ? { ...state.impactReport.display.startMonth }
        : { year: endMonth.year - 1, month: endMonth.month }
      if (startMonth.month === 1) {
        startMonth.year -= 1
        startMonth.month = 12
      } else {
        startMonth.month -= 1
      }
      dispatch({
        type: UPDATE_DISPLAY,
        startMonth,
        endMonth,
      })
      fetchNewDataIfNeeded(state, startMonth, endMonth, dispatch)
    }
  }
}

export function setDisplayDateRange(startMonth, endMonth) {
  return (dispatch, getState) => {
    // Check our {year, month} objects and make sure start <= end
    const shouldSwap = compareMonths(startMonth, endMonth) > 0
    const actualStartMonth = shouldSwap ? endMonth : startMonth
    const actualEndMonth = shouldSwap ? startMonth : endMonth

    // Update our display state with this new range: this will immediately recompute our user-facing data
    // based on the data we previously had cached
    dispatch({
      type: UPDATE_DISPLAY,
      startMonth: actualStartMonth,
      endMonth: actualEndMonth,
    })
    fetchNewDataIfNeeded(getState(), actualStartMonth, actualEndMonth, dispatch)
  }
}

export function setExcludedCategoryIds(excludedCategoryIds) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_DISPLAY,
      excludedCategoryIds: excludedCategoryIds || [],
    })
  }
}

export function setSelectedIssueSourceTitles(selectedIssueSourceTitles) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_DISPLAY,
      selectedIssueSourceTitles: selectedIssueSourceTitles || [],
    })
  }
}
