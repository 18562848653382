import React from 'react'
import { withRouter } from 'react-router-dom'
import UIkit from 'uikit'

// Components
import Modal from 'app/views/components/UIkit/Modal'
import Icon from 'app/views/components/Icon'

// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'
import { Button } from 'app/views/components/GustavoButton'

class ModalChallengeProof extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: '',
      flag: '',
      loading: false,
      success: false,
    }

    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleOnFlagChange = this.handleOnFlagChange.bind(this)
  }

  handleOnClick(e) {
    e.preventDefault()

    this.setState({
      loading: true,
      error: '',
    })
    if (this.props.id) {
      api({
        method: 'post',
        url: `${settings.urls.hacker}/user/${this.props.userId}/content/${this.props.id}`,
        withAuthToken: true,
        data: {
          proof: this.state.flag,
        },
      })
        .then((response) => {
          if (response.data.correct === true) {
            this.setState({
              loading: false,
              success: true,
            })
          } else {
            this.setState({
              error: response.data.error,
              loading: false,
            })
          }
        })
        .catch((error) => {
          console.error('error posting flag: ', error)
        })
    }
  }

  handleOnFlagChange(e) {
    this.setState({ flag: e.target.value })
  }

  render() {
    return (
      <Modal
        id='modal-challenge-proof'
        title='Challenge Flag'
        body={
          <div className='text-center'>
            <p>
              Follow the challenge instructions and copy/paste your flag here.
            </p>
            {this.state.error !== '' && (
              <p className='text-danger'>{this.state.error}</p>
            )}
            {this.state.success ? (
              <div>
                <div>
                  <Icon
                    className='uk-margin-small-right'
                    name='check'
                    ratio={2}
                  />
                </div>
                <h2 className='uk-margin-small-top'>Congrats!</h2>
                <button
                  type='submit'
                  className='uk-button uk-button-primary'
                  onClick={() => {
                    UIkit.modal('#modal-challenge-proof').hide()
                    this.props.history.push('/')
                  }}
                >
                  Done
                </button>
              </div>
            ) : (
              <div>
                <form>
                  <div className='uk-margin'>
                    <textarea
                      className='uk-textarea'
                      required
                      autoComplete='off'
                      rows='5'
                      placeholder='Flag'
                      onChange={this.handleOnFlagChange}
                      value={this.state.flag}
                    />
                  </div>
                  <div className='uk-margin text-right'>
                    {this.state.loading ? (
                      <span data-uk-spinner='' />
                    ) : (
                      <Button
                        primary
                        content='Submit Flag'
                        onClick={this.handleOnClick}
                      />
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        }
        containerModifier
      />
    )
  }
}

export default withRouter(ModalChallengeProof)
