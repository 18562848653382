import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import settings from 'settings'

import SelectionTreeItem from './SelectionTreeItem'
import { _ } from 'core-js'

function getGroupSelectionState(selectedItemIds, itemIds) {
  let numSelected = 0
  for (const itemId of itemIds) {
    if (selectedItemIds.includes(itemId)) {
      numSelected++
    }
  }
  return numSelected === 0
    ? 'none'
    : numSelected === itemIds.length
    ? 'all'
    : 'some'
}

/**
 * Takes a hierarchical/nested list of items as supplied to SelectionTree, and returns a flattened
 * list of objects, creating items for each group (encompassing a subset of items) and injecting
 * depth, descendantIds, and selectionState props.
 */
function flattenItems(
  selectedItemIds,
  items,
  collapsibleDepth = -1,
  initialCollapsedDepth = -1,
  depth = 0,
  hiddenByParent = false
) {
  let flattened = []
  for (const item of items) {
    // figure out what type of object we're working with so we can get a unique key
    let type
    let keyProp = 'uuid'
    if (item.frameworks) {
      type = 'language'
      keyProp = 'language_name'
    } else if (item.apps) {
      type = 'framework'
      keyProp = 'name'
    } else if (item.name && item.content) {
      // lessons have content too, but have titles, not names
      type = 'app'
      keyProp = 'name'
    }

    let isLeaf = Object.values(item).some((item) => Array.isArray(item))
      ? false
      : true

    if (isLeaf) {
      flattened.push({
        isLeaf: true,
        depth,
        name: item.title,
        key: item.uuid,
        itemIds: [item.uuid],
        itemNames: [item.title],
        selectionState: selectedItemIds.includes(item.uuid) ? 'all' : 'none',
        collapsible: false,
        collapsed: false,
        hidden: hiddenByParent,
      })
    } else {
      const isParent = item.items || item.language_name !== undefined

      // handle collapsing/hiding
      const collapsible = depth <= collapsibleDepth
      const collapsed = collapsible && depth <= initialCollapsedDepth // passed into `hiddenByParent` parameter

      const flattenedChildren = flattenItems(
        selectedItemIds,
        item.items || item.content || item.frameworks || item.apps,
        collapsibleDepth,
        initialCollapsedDepth,
        depth + 1,
        collapsed || hiddenByParent
      )
      const descendantIds = flattenedChildren.reduce(
        (acc, x) => (x.isLeaf ? acc.concat([x.key]) : acc),
        []
      )
      const descendantNames = flattenedChildren.reduce(
        (acc, x) => (x.isLeaf ? acc.concat([x.name]) : acc),
        []
      )

      flattened.push({
        isLeaf: false,
        depth,
        name:
          item.title ||
          settings.engines[item.language_name] ||
          settings.apps[item.name] ||
          settings.frameworks[item.name],
        key: item[keyProp],
        itemIds: descendantIds,
        itemNames: descendantNames,
        selectionState: getGroupSelectionState(selectedItemIds, descendantIds),
        isParent,
        collapsible,
        collapsed,
        hidden: hiddenByParent,
      })
      flattened.push(...flattenedChildren)
    }
  }
  return flattened
}

function SelectionTree(props) {
  const {
    items,
    selectedItemIds,
    onSelectionChange,
    collapsibleDepth,
    initialCollapsedDepth,
  } = props

  const flattenedItems = flattenItems(
    selectedItemIds,
    items,
    collapsibleDepth,
    initialCollapsedDepth
  )
  const [collapseStates, setCollapseStates] = useState(
    flattenedItems.map((item) => item.collapsed)
  )
  const [hiddenStates, setHiddenStates] = useState(
    flattenedItems.map((item) => item.hidden)
  )

  useEffect(() => {
    setCollapseStates(flattenedItems.map((item) => item.collapsed))
    setHiddenStates(flattenedItems.map((item) => item.hidden))
  }, [items])

  // Iterate over the hiddenStates of the items, updating only the children
  // based on the collapsed value of the parent
  function updateHiddenStates(index, collapsed) {
    // return if index is oob
    if (index < 0 || index > hiddenStates.length) return

    const parent = flattenedItems[index]
    let inChildren = false
    const newStates = []

    for (let i = 0; i < hiddenStates.length; i++) {
      if (!inChildren) {
        newStates.push(hiddenStates[i])
        inChildren = i == index
      } else {
        if (flattenedItems[i].depth > parent.depth) {
          newStates.push(collapsed)
        } else {
          inChildren = false
          newStates.push(hiddenStates[i])
        }
      }
    }

    setHiddenStates(newStates)
  }

  function updateCollapseStates(index, newState) {
    const newStates = [...collapseStates]
    newStates[index] = newState

    setCollapseStates(newStates)
    updateHiddenStates(index, newState)
  }

  return (
    <div className='hx-selection-tree uk-margin-top'>
      {flattenedItems.map((item, index) => (
        <SelectionTreeItem
          index={index}
          isParent={item.isParent}
          key={item.key}
          depth={item.depth}
          name={item.name}
          selectionState={item.selectionState}
          collapsible={item.collapsible}
          collapsed={collapseStates[index]}
          hidden={hiddenStates[index]}
          onChange={(isSelected) => {
            onSelectionChange(item.itemIds, item.itemNames, isSelected)
            if (item.collapsible && collapseStates[index] && isSelected) {
              updateCollapseStates(index, false)
            }
          }}
          onCollapseClick={updateCollapseStates}
        />
      ))}
    </div>
  )
}

export default SelectionTree
