import { values } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import UIkit from 'uikit'

// Components
import ModalCodingTestRequired from 'app/views/components/Modals/Info/ModalCodingTestRequired'
import Icon from 'app/views/components/Icon'
import ModalActiveTeamPlan from 'app/views/components/Modals/Info/ModalActiveTeamPlan'
import ModalDefaultPlan from 'app/views/components/Modals/Info/ModalDefaultPlan'
import ModalOverrideOrganizationDefault from 'app/views/components/Modals/Info/ModalOverrideOrganizationDefault'
import ModalPlanTypes from 'app/views/components/Modals/Info/ModalPlanTypes'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import ToggleSwitch from 'app/views/components/ToggleSwitch'

// Redux
import { addPlan, getPlans } from 'app/state/modules/plan'
import { getTeams } from 'app/state/modules/team'

// Utils
import analytics from 'app/views/utils/analytics'
import settings from 'settings'

class OrganizationsPlansAdd extends React.Component {
  constructor(props) {
    super(props)

    const { defaultTeamUUID, planUUIDClone, planTitle, planDescription } =
      this.props.location.state || {}
    this.state = {
      error: '',
      title: planTitle || '',
      description: planDescription || '',
      teamName: '',
      team: defaultTeamUUID || undefined,
      plan: planUUIDClone || undefined,
      overrideMustPassCode: false,
      teamMustPassCode: false,
      defaultPlan: true,
      sendReminders: false,
      planType: 'date',
    }
    this.firstRender = false
    this.handleChangeTitle = this.handleChangeTitle.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
    this.handleChangeDefaultPlan = this.handleChangeDefaultPlan.bind(this)
    this.handleChangeTeam = this.handleChangeTeam.bind(this)
    this.handleChangeSendReminders = this.handleChangeSendReminders.bind(this)
    this.handleSubmitNext = this.handleSubmitNext.bind(this)
    this.handleChangePlanType = this.handleChangePlanType.bind(this)
    this.handleChangePlan = this.handleChangePlan.bind(this)
    this.handleChangeTeamName = this.handleChangeTeamName.bind(this)
    this.handleOverrideEnabled = this.handleOverrideEnabled.bind(this)
    this.handleMustPassEnabled = this.handleMustPassEnabled.bind(this)
  }

  componentDidMount() {
    this.props.getTeams(this.props.match.params.organization_uuid)
    this.props.getPlans(this.props.match.params.organization_uuid)

    analytics.page('page-organizations-plans-add')
  }

  componentDidUpdate(prevProps) {
    if (
      ((prevProps.teams.length === 0 && this.props.teams.length > 0) ||
        this.props.teams.length > 0) &&
      !this.firstRender
    ) {
      if (!this.state.team) {
        this.handleChangeTeam({
          target: { value: this.props.teams[0].uuid },
        })
      }
      this.firstRender = true
    }
  }

  handleChangeTitle(e) {
    this.setState({ title: e.target.value })
  }

  handleOverrideEnabled(active) {
    this.setState({
      overrideMustPassCode: active,
    })
  }

  handleMustPassEnabled(active) {
    this.setState({
      teamMustPassCode: active,
    })
  }

  handleChangeTeamName(e) {
    this.setState({ teamName: e.target.value })
  }

  handleChangeDescription(e) {
    this.setState({ description: e.target.value })
  }

  handleChangeTeam(e) {
    this.setState({ team: e.target.value === '' ? undefined : e.target.value })
  }

  handleChangePlan(e) {
    this.setState({ plan: e.target.value === '' ? undefined : e.target.value })
  }

  handleChangeDefaultPlan(e) {
    this.setState({ defaultPlan: e.target.checked })
  }

  handleChangePlanType(e) {
    this.setState({ planType: e.target.name })
  }

  handleChangeSendReminders(e) {
    this.setState({ sendReminders: e.target.checked })
  }

  handleSubmitNext(event) {
    event.preventDefault()
    const isTeamPlan = this.props.match.path.endsWith('/team')
    if (this.state.title === '') {
      this.setState({ error: 'Title is required.' })
    } else {
      this.setState({ title: event.target.value })
      this.props
        .addPlan(
          this.props.match.params.organization_uuid,
          this.state.title,
          this.state.description,
          this.state.defaultPlan,
          this.state.sendReminders,
          this.state.planType,
          isTeamPlan ? this.state.team : null,
          this.state.plan,
          this.state.teamName || null,
          this.state.overrideMustPassCode,
          this.state.teamMustPassCode
        )
        .then((planId) => {
          if (planId) {
            this.props.history.push(
              `/admin/${
                this.props.match.params.organization_uuid
              }/plans/${planId}${this.state.plan ? '' : '/phase/add'}`
            )
          } else {
            UIkit.modal.alert(
              'A plan with this title already exists. You can\'t have more than one plan with the same title.'
            )
          }
        })
    }
  }

  render() {
    const isTeamPlan = this.props.match.path.endsWith('/team')
    return (
      <OrganizationLayout
        {...this.props}
        active='plans'
        data-test-id='add-organization-plan-component'
      >
        <h1>{`Add ${isTeamPlan ? 'Team' : 'Organization'} Training Plan`}</h1>
        <div className='uk-card uk-card-default uk-card-body'>
          <div className='uk-container-small'>
            <form className='uk-form-stacked'>
              <div className='uk-margin'>
                <label className='uk-form-label'>Title</label>
                <div className='uk-form-controls'>
                  <input
                    className='uk-input'
                    type='text'
                    placeholder=''
                    value={this.state.title}
                    onChange={this.handleChangeTitle}
                    data-test-id='input-title'
                  />
                </div>
              </div>
              <div className='uk-margin'>
                <label className='uk-form-label'>Description</label>
                <div className='uk-form-controls'>
                  <input
                    data-test-id='input-description'
                    className='uk-input'
                    type='text'
                    placeholder=''
                    value={this.state.description}
                    onChange={this.handleChangeDescription}
                  />
                </div>
              </div>
              {isTeamPlan && (
                <>
                  <div className='uk-margin'>
                    <label className='uk-form-label'>Team</label>
                    <div className='uk-form-controls'>
                      <select
                        className='uk-select'
                        id='form-select-team-add-plan'
                        value={this.state.team}
                        onChange={this.handleChangeTeam}
                      >
                        <option value=''>Create a new team</option>
                        {this.props.teams.map((team) => {
                          return (
                            <option key={team.uuid} value={team.uuid}>
                              {team.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>

                  {this.state.team === undefined && (
                    <>
                      <hr />
                      <div className='uk-margin'>
                        <label className='uk-form-label'>
                          Name for new team
                        </label>
                        <div className='uk-form-controls'>
                          <input
                            data-test-id='input-description'
                            className='uk-input'
                            type='text'
                            placeholder=''
                            value={this.state.teamName}
                            onChange={this.handleChangeTeamName}
                          />
                        </div>
                      </div>
                      <div className='uk-margin'>
                        <label className='uk-form-label'>
                          Coding Test Required
                        </label>
                        <div className='flex'>
                          <ToggleSwitch
                            onChange={this.handleOverrideEnabled}
                            checked={this.state.overrideMustPassCode}
                            label='Override Organization Default'
                          />
                          <Icon
                            href='#'
                            name='information'
                            ratio={0.8}
                            data-uk-toggle='target: #modal-info-override-organization-default'
                            style={{
                              cursor: 'pointer',
                              paddingLeft: 8,
                              verticalAlign: 'top',
                            }}
                          />
                          <ModalOverrideOrganizationDefault />
                        </div>
                      </div>
                      {this.state.overrideMustPassCode && (
                        <div className='uk-margin'>
                          <div className='flex'>
                            <ToggleSwitch
                              onChange={this.handleMustPassEnabled}
                              checked={this.state.teamMustPassCode}
                              label='Coding Test Required'
                            />
                            <Icon
                              name='information'
                              ratio={0.8}
                              data-uk-toggle='target: #modal-info-coding-test-required'
                              style={{
                                cursor: 'pointer',
                                paddingLeft: 8,
                                verticalAlign: 'top',
                              }}
                            />
                            <ModalCodingTestRequired />
                          </div>
                        </div>
                      )}
                      <hr />
                    </>
                  )}
                </>
              )}

              <div className='uk-margin'>
                <label className='uk-form-label'>Training Plan</label>
                <div className='uk-form-controls'>
                  <select
                    className='uk-select'
                    id='add-plan-select'
                    value={this.state.plan}
                    onChange={this.handleChangePlan}
                  >
                    <optgroup label='Organization Plans'>
                      <option value=''>Create a new plan</option>
                      {this.props.clonePlanList.map((plan) => (
                        <option key={plan.uuid} value={plan.uuid}>
                          Clone from {plan.title}{' '}
                          {plan.team && plan.team.name
                            ? `( Team "${plan.team.name}")`
                            : ''}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label='HackEDU Preset Plans'>
                      {settings.plans.map((p) => (
                        <option value={p.uuid} key={p.uuid}>
                          {`Clone from HackEDU - ${p.title}`}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                </div>
              </div>

              {!this.state.plan && (
                <div className='uk-margin '>
                  <div className='uk-form-controls uk-inline'>
                    <label
                      className='uk-form-label uk-inline'
                      style={{ display: 'inline' }}
                    >
                      Plan Type
                    </label>
                    <Icon
                      name='information'
                      ratio={0.8}
                      data-uk-toggle='target: #modal-info-plan-types'
                      style={{ marginLeft: 8 }}
                    />
                    <ModalPlanTypes />
                  </div>

                  <div className='uk-form-controls'>
                    <input
                      checked={this.state.planType === 'date'}
                      className='uk-radio'
                      name='date'
                      onChange={this.handleChangePlanType}
                      style={{ marginRight: 10 }}
                      type='radio'
                    />
                    Date Based
                  </div>
                  <div className='uk-form-controls'>
                    <input
                      checked={this.state.planType === 'time'}
                      className='uk-radio'
                      data-test-id='time-based-radio-btn'
                      name='time'
                      onChange={this.handleChangePlanType}
                      style={{ marginRight: 10 }}
                      type='radio'
                    />
                    Time Based
                  </div>
                </div>
              )}
              <div className='uk-margin'>
                <div className='uk-form-controls'>
                  {isTeamPlan ? (
                    <></>
                  ) : (
                    <>
                      <input
                        data-test-id='default-plan-checkbox'
                        className='uk-checkbox'
                        type='checkbox'
                        checked={this.state.defaultPlan}
                        onChange={this.handleChangeDefaultPlan}
                        style={{ marginRight: 10 }}
                      />
                      Default Plan
                      <Icon
                        as='a'
                        href='#'
                        name='information'
                        ratio={0.8}
                        data-uk-toggle='target: #modal-info-default-plan'
                        style={{ marginLeft: 8 }}
                      />
                      <ModalDefaultPlan />
                    </>
                  )}
                </div>
              </div>
              {this.state.error !== '' && (
                <div className='uk-margin'>
                  <p className='text-danger'>{this.state.error}</p>
                </div>
              )}

              <div className='uk-margin'>
                <div className='uk-form-controls'>
                  <button
                    data-test-id='next-btn'
                    type='button'
                    className='uk-button uk-button-primary tm-button-default'
                    onClick={this.handleSubmitNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  // content
  courses: state.content.courses,
  lessonMap: state.content.lessonMap,
  tests: state.content.tests,

  // hacker
  organizations: values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,
  teams: state.team.teams,

  // plans
  loadingPlans: state.plan.loadingPlans,
  hasLoadedPlans: state.plan.hasLoadedPlans,
  plans: state.plan.plans,

  // user
  email: state.auth.user.email,
  isPickingPlan: state.hacker.isPickingPlan,
  clonePlanList: state.plan.clonePlanList,
})
const mapDispatchToProps = (dispatch) => ({
  addPlan: (
    organizationId,
    title,
    description,
    defaultPlan,
    sendReminders,
    planType,
    teamUUID,
    planUUID,
    teamName,
    overrideMustPassCode,
    teamMustPassCode
  ) =>
    dispatch(
      addPlan(
        organizationId,
        title,
        description,
        defaultPlan,
        sendReminders,
        planType,
        teamUUID,
        planUUID,
        teamName,
        overrideMustPassCode,
        teamMustPassCode
      )
    ),
  getPlans: (organizationId) => dispatch(getPlans(organizationId)),
  getTeams: (organizationId) => dispatch(getTeams(organizationId)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsPlansAdd)
)
