import React from 'react'
import moment from 'moment'
import UIkit from 'uikit'
import { connect } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

// Components
import UserDetailModal from 'app/views/components/UserDetailModal'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import Loader from 'app/views/components/Loader'
import Icon from 'app/views/components/Icon'
import GenericModal from 'app/views/components/Modals/GenericModal'
import SortableTableHeader from 'app/views/components/SortableTableHeader'
import TeamPicker from 'app/views/components/TeamPicker'

// Redux
import {
  fetchSelectedUserDetail,
  cleanUserDetailModal,
} from 'app/state/modules/users'
import { getUsersReport, exportUsersReport } from 'app/state/modules/reports'
import { deleteHackerFromOrganization } from 'app/state/modules/hacker'
import Pagination from 'app/views/components/Pagination'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import TimeRangePicker from 'app/views/components/TimeRangePicker'

const getHeaders = (usingPlanlessUsersReport) =>
  [
    { key: 'email', name: 'Email' },
    { key: 'teams', name: 'Teams' },
    { key: 'lessons_completed', name: 'Lessons Completed' },
    { key: 'coding_challenges_completed', name: 'Coding Challenges Completed' },
    {
      key: 'hacking_challenges_completed',
      name: 'Hacking Challenges Completed',
    },
    { key: 'challenge_points', name: 'Challenge Points' },
    { key: 'code_submission_count', name: 'Code Submissions' },
    { key: 'time_spent', name: 'Time Spent' },
  ]
    .concat(
      usingPlanlessUsersReport
        ? [
            // Display a different set of fields depending on the planless-user-report flag.
            // If using the planless report, total_assigned represents how many pieces of
            // content a user has ever interacted with.
            { key: 'total_assigned', name: 'Lessons or Challenges Started' },
            { key: 'training_progress', name: 'Percent Completed' },
          ]
        : [
            // For most orgs, we maintain the original behavior, displaying information
            // relevant to the user's assigned training plan(s)
            { key: 'training_progress', name: 'Training Progress' },
            { key: 'is_past_due', name: 'Past Due Status' },
          ]
    )
    .concat([{ key: 'last_seen', name: 'Last seen' }])

class UsersReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      localData: null,
      active: 'email',
      asc: true,
      codeReviewFirst: false,
      days: null,
      currentPage: 1,
      pastDueOnly: false,
      teamUUID: null,
    }
    this.fetchReportData = this.fetchReportData.bind(this)
    this.shouldInvalidateReportData = this.shouldInvalidateReportData.bind(this)
    this.onExport = this.onExport.bind(this)
    this.fetchPerDays = this.fetchPerDays.bind(this)
    this.onDeleteUser = this.onDeleteUser.bind(this)
    this.onChangeTeam = this.onChangeTeam.bind(this)
    this.onShowPastDue = this.onShowPastDue.bind(this)
    this.fetchSelectedUserDetail = this.fetchSelectedUserDetail.bind(this)
    this.fetchSortedReport = this.fetchSortedReport.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.props.cleanUserDetailModal()
    this.hackersPerPage = 100
  }

  fetchReportData() {
    this.props.getUsersReport(
      this.props.flags.planlessUsersReport,
      this.props.match.params.organization_uuid,
      this.state.teamUUID || this.props.match.params.team_uuid,
      this.state.active,
      this.state.asc,
      (this.state.currentPage - 1) * this.hackersPerPage,
      this.state.pastDueOnly,
      this.state.days
    )
  }

  shouldInvalidateReportData(prevProps, prevState) {
    if (
      (this.props.flags || {}).planlessUsersReport !==
      (prevProps.flags || {}).planlessUsersReport
    )
      return true
    if (
      this.props.match.params.organization_uuid !=
      prevProps.match.params.organization_uuid
    )
      return true
    if (this.state.teamUUID !== prevState.teamUUID) return true
    if (this.state.active !== prevState.active) return true
    if (this.state.asc !== prevState.asc) return true
    if (this.state.currentPage !== prevState.currentPage) return true
    if (this.state.pastDueOnly !== prevState.pastDueOnly) return true
    if (this.state.days !== prevState.days) return true
    return false
  }

  componentDidMount() {
    this.fetchReportData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldInvalidateReportData(prevProps, prevState)) {
      this.fetchReportData()
    }
  }

  onShowPastDue() {
    this.setState({
      pastDueOnly: !this.state.pastDueOnly,
    })
  }

  onChangePage(currentPage) {
    this.setState({
      currentPage,
    })
  }

  async onExport() {
    const hackers =
      this.props.totalUsersReport < 100 && this.state.currentPage <= 1
        ? this.props.usersReport
        : await this.props.exportUsersReport(
            this.props.flags.planlessUsersReport,
            this.props.match.params.organization_uuid,
            this.state.teamUUID || this.props.match.params.team_uuid,
            this.state.active,
            this.state.asc,
            this.state.pastDueOnly,
            this.state.days
          )
    const headers = getHeaders(this.props.flags.planlessUsersReport)
    const csvHeaders = headers.map(({ name }) => name)
    const keys = headers.map(({ key }) => key)
    const rows = []
    rows.push(csvHeaders)
    hackers.forEach((hacker) => {
      const hackerRow = keys.map((k) => {
        if (k === 'last_seen') {
          return hacker[k]
            ? getLocalDateFromUTC(hacker[k]).format('YYYY-MM-DD HH:mm')
            : '-'
        }
        if (k == 'teams') {
          return `"${hacker[k].map((team) => team.name).join(',')}"`
        }
        return hacker[k]
      })
      rows.push(hackerRow)
    })
    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((e) => e.join(','))
      .join('\n')}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `users_report_${moment().format('YYYY-MM-DD')}.csv`
    )
    document.body.appendChild(link)
    link.click()
  }

  onDeleteUser() {
    const orgUUID = this.props.match.params.organization_uuid
    const userUUID =
      this.props.selectedUserDetail.uuid || this.props.match.params.hacker_uuid
    const callback = (error = false) => {
      this.modalDeleteUser.hide()
      if (error) {
        return UIkit.modal.alert(
          '<h2 >Error</h2><p>There was an error deleting the user. Please try again or contact support.</p>'
        )
      }
      this.setState({
        active: 'email',
        asc: true,
        codeReviewFirst: false,
        days: null,
        currentPage: 1,
        pastDueOnly: false,
        teamUUID: null,
      })
      this.fetchReportData()
      return UIkit.modal.alert(
        '<h2 >User removed</h2><p>The user has been removed successfully from the organization<p>'
      )
    }
    this.props.deleteHackerFromOrganization(orgUUID, userUUID, callback)
  }

  onChangeTeam(e) {
    const teamUUID = e.target.value === '-' ? null : e.target.value
    this.setState({
      currentPage: 1,
      teamUUID,
    })
  }

  fetchSelectedUserDetail(hacker, index, codeReviewFirst = false) {
    this.setState(
      {
        codeReviewFirst,
      },
      () => {
        this.props.fetchSelectedUserDetail(
          this.props.match.params.organization_uuid,
          {
            ...hacker,
            uuid: hacker.hacker_uuid,
            justTeam: this.props.match.params.team_uuid,
          },
          false,
          {
            localIndex: index,
          }
        )
      }
    )
  }

  fetchPerDays(days) {
    this.setState({
      currentPage: 1,
      days,
    })
  }

  fetchSortedReport(sortBy) {
    const { asc, active } = this.state
    this.setState({
      active: sortBy,
      asc: sortBy === active ? !asc : true,
      currentPage: 1,
    })
  }

  renderHeaders() {
    return getHeaders(this.props.flags.planlessUsersReport).map(
      (header, index) => (
        <SortableTableHeader
          onClick={this.fetchSortedReport}
          key={header.key}
          keyName={header.key}
          middle
          center={index > 0}
          name={header.name}
          active={header.key === this.state.active}
          asc={header.key === this.state.active && this.state.asc}
          style={{
            fontSize: 10,
            minWidth: header.key === 'last_seen' ? 100 : undefined,
          }}
        />
      )
    )
  }

  renderReport() {
    return this.props.usersReport.map((hacker, index) => (
      <tr key={hacker.hacker_uuid}>
        <td>
          <a
            className='text-green hover:text-green'
            onClick={this.fetchSelectedUserDetail.bind(
              this,
              hacker,
              index,
              false
            )}
          >
            {hacker.email}
          </a>
        </td>
        <td className='text-center'>
          {hacker.teams.map(function (team) {
            if (this.state.teamUUID) {
              if (this.state.teamUUID == team.uuid) {
                return <div className='whitespace-nowrap'>{team.name}</div>
              }
            } else {
              return <div className='whitespace-nowrap'>{team.name}</div>
            }
          }, this)}
        </td>
        <td className='text-center'>{hacker.lessons_completed}</td>
        <td className='text-center'>{hacker.coding_challenges_completed}</td>
        <td className='text-center'>{hacker.hacking_challenges_completed}</td>
        <td className='text-center'>{hacker.challenge_points}</td>
        <td className='text-center'>{hacker.code_submission_count}</td>
        <td className='text-center'>
          {hacker.time_spent.split(':').slice(0, 2).join(':')}
        </td>
        {/* If using planless-users-report, display stats for content opened vs. content completed */}
        {this.props.flags.planlessUsersReport && (
          <td className='text-center'>{hacker.total_assigned}</td>
        )}
        {this.props.flags.planlessUsersReport && (
          <td className='text-center'>
            <progress
              uk-tooltip={`${hacker.total_completed} of ${hacker.total_assigned}`}
              className='uk-progress'
              value={hacker.training_progress}
              max='100'
            />
          </td>
        )}
        {/* Otherwise, show plan-related stats: progress percentage and past due status */}
        {!this.props.flags.planlessUsersReport && (
          <td className='text-center'>
            <progress
              uk-tooltip={`${hacker.training_progress}%`}
              className='uk-progress'
              value={hacker.training_progress}
              max='100'
            />
          </td>
        )}
        {!this.props.flags.planlessUsersReport && (
          <td className='text-center'>
            <Icon
              name='checkbox-blank-circle'
              fill
              className={`text-${hacker.is_past_due ? 'danger' : 'success'}`}
            />
          </td>
        )}
        <td className='text-center'>
          {hacker.last_seen
            ? getLocalDateFromUTC(hacker.last_seen).fromNow()
            : 'Never'}
        </td>
      </tr>
    ))
  }

  renderDeleteModalContent() {
    if (!this.props.selectedUserDetail) return null
    if (this.props.selectedUserDetail.steps_completed > 20) {
      return (
        <>
          <p>
            {`You are about to remove ${this.props.selectedUserDetail.email} from your organization.
            This user's license does not qualify to be released due to the amount of content they
            have already completed.`}
          </p>
          <p>Are you sure you want to continue?</p>
        </>
      )
    }
    return (
      <>
        <p>
          {`You are about to remove ${this.props.selectedUserDetail.email} from your organization. This
          user's license will be available for reuse since they completed a minimal amount of
          the training.`}
        </p>
        <p>Are you sure you want to continue?</p>
      </>
    )
  }

  render() {
    if (
      this.props.usersReport === 'loading' ||
      !this.props.hasLoadedOrganizations
    ) {
      return <Loader visible />
    }
    return (
      <OrganizationLayout
        active='reports'
        data-test-id='organization-users-component'
        noPaddingRemove={this.props.match.params.team_uuid}
        style={{ position: 'relative' }}
        {...this.props}
      >
        {this.props.match.params.team_uuid && (
          <button
            onClick={() =>
              this.props.history.push(
                `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/users`
              )
            }
            className='uk-button uk-button-secondary uk-button-small uk-position-absolute uk-position-top-left uk-margin-top uk-margin-small-left flex'
            type='button'
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Back To Team
          </button>
        )}
        <h2 className='text-center'>Users Report</h2>
        <div className='flex justify-end flex-col'>
          <div className='flex justify-end flex-1-0'>
            <TimeRangePicker onChange={this.fetchPerDays} />
            <TeamPicker
              className='uk-margin-small-left'
              onChange={this.onChangeTeam}
            />
            {/* Hide "past due" filter if using planless-users-report */}
            {!this.props.flags.planlessUsersReport && (
              <button
                onClick={this.onShowPastDue}
                className='uk-button uk-button-small uk-button-default flex uk-margin-small-left'
                type='button'
              >
                {this.state.pastDueOnly
                  ? 'Showing Past Due Only'
                  : 'Showing All'}
                <Icon
                  name='checkbox-blank-circle'
                  fill
                  ratio={0.8}
                  className='text-danger uk-margin-small-left'
                />
                {!this.state.pastDueOnly && (
                  <Icon
                    name='checkbox-blank-circle'
                    fill
                    ratio={0.8}
                    className='text-success'
                  />
                )}
              </button>
            )}
            <button
              onClick={this.onExport}
              className='uk-button uk-button-default uk-button-small uk-margin-small-left flex'
              type='button'
            >
              <Icon
                className='uk-margin-small-right'
                name='download-cloud'
                ratio={0.9}
              />
              Full Report
            </button>
          </div>
          <div className='flex justify-end uk-margin-top'>
            <Pagination
              currentPage={this.state.currentPage}
              style={{
                marginBottom: 0,
                visibility: this.props.totalUsersReport === 0 ? 'hidden' : null,
              }}
              onChangePage={this.onChangePage}
              hasArrows
              totalPages={Math.ceil(
                this.props.totalUsersReport / this.hackersPerPage
              )}
            />
          </div>
        </div>
        <table className='uk-table uk-table-divider uk-table-middle uk-table-small text-sm'>
          {this.props.totalUsersReport > 0 && (
            <thead className='uk-text'>
              <tr>{this.renderHeaders()}</tr>
            </thead>
          )}
          <tbody>
            {this.props.totalUsersReport === 0 && (
              <tr>
                <td>No users found</td>
              </tr>
            )}
            {this.renderReport()}
          </tbody>
        </table>

        <UserDetailModal
          initialTab={this.state.codeReviewFirst ? 2 : 0}
          onRef={(ref) => {
            this.userDetailModal = ref
          }}
          id='user-detail-code-review-reports'
          localData={this.state.localData}
          selectedUserDetail={this.props.selectedUserDetail}
          onRemoveFromOrg={() => this.modalDeleteUser.show()}
        />
        <GenericModal
          title='Delete user from organization'
          ref={(ref) => {
            this.modalDeleteUser = ref
          }}
          id='delete-user-modal'
          date-test-id='delete-user-modal'
          body={this.renderDeleteModalContent()}
          buttons={
            <button
              data-test-id='delete-user-confirm'
              type='button'
              className='uk-button uk-button-danger uk-margin-left'
              onClick={this.onDeleteUser}
            >
              Delete
            </button>
          }
        />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedUserDetail: state.users.selectedUserDetail,
  usersReport: state.reports.usersReport,
  totalUsersReport: state.reports.totalUsersReport,
  hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
  organizationsHash: state.hacker.organizationsHash,
  organizations: state.hacker.organizationsList,
})

export default connect(mapStateToProps, {
  getUsersReport,
  fetchSelectedUserDetail,
  cleanUserDetailModal,
  deleteHackerFromOrganization,
  exportUsersReport,
})(withLDConsumer()(UsersReport))
