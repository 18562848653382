import settings from 'settings'
import { getAnonymousId, getUser } from './auth'

const analytics = {
  page: (pageName, properties) => {
    getUser()
      .then((user) => {
        window.analytics.page(pageName, {
          userId: user.username,
          properties: {
            ...properties,
            loggedIn: true,
          },
        })
      })
      .catch(() => {
        window.analytics.page(pageName, {
          anonymousId: getAnonymousId(),
          properties: {
            ...properties,
            loggedIn: false,
          },
        })
      })
  },
  track: (pageName, properties) => {
    getUser()
      .then((user) => {
        window.analytics.track(pageName, {
          userId: user.username,
          properties: {
            ...properties,
          },
        })
      })
      .catch(() => {
        window.analytics.track(pageName, {
          anonymousId: getAnonymousId(),
          properties: {
            ...properties,
          },
        })
      })
  },
}

export default analytics
