import React from 'react'

// Components
import Modal from 'app/views/components/UIkit/Modal'
import { Button } from 'app/views/components/GustavoButton'

const ModalSendReminders = () => (
  <Modal
    id='modal-info-send-reminders'
    title='Send reminders'
    body={
      <div>
        <p>
          If enabled, HackEDU will send email reminders to anyone in the
          organization. Reminders are sent every Monday at 8:00 AM PST (4:00 PM
          UTC) and you can pick from weekly, bi-weekly, or monthly options.
        </p>
        <p>Individuals can opt out of these emails from their accounts.</p>
        <p className='text-right'>
          <Button content='Okay' primary extraClassName='uk-modal-close' />
        </p>
        <button
          className='uk-modal-close-outside'
          data-uk-close
          type='button'
        />
      </div>
    }
  />
)

export default ModalSendReminders
