import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { CognitoAuth } from 'amazon-cognito-auth-js'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import React from 'react'
import qs from 'qs'
import settings from 'settings'
import { withRouter } from 'react-router-dom'

// Redux
import * as auth from 'app/state/modules/auth'
import { getInviteToken, onboardUser } from 'app/state/modules/hacker'

// Utils
import {
  getAnonymousId,
  getEmailFromJWT,
  getUsernameFromJWT,
} from 'app/views/utils/auth'
import {
  storePostLoginRedirectUrl,
  readPostLoginRedirectUrl,
} from 'app/views/utils/authRedirect'
import analytics from 'app/views/utils/analytics'

const propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object, // withRouter
  }).isRequired,
  updateCognitoSession: PropTypes.func.isRequired, // mapDispatchToProps
  onboardUser: PropTypes.func.isRequired,
  inviteToken: PropTypes.string,
}

const defaultProps = {
  inviteToken: '',
}

// eslint-disable-next-line no-console
const tempLog = () => {} // console.log

class Auth extends React.Component {
  componentDidMount() {
    tempLog('TEMP Auth componentDidMount', {
      onboardUser: this.props.onboardUser,
    })
    analytics.page('page-auth')

    const auth = new CognitoAuth(settings.cognito.authData)
    auth.userhandler = {
      onSuccess: (result) => {
        tempLog('TEMP CognitoAuth userhandler onSuccess')
        const decodedAuth = jwtDecode(result.idToken.jwtToken)

        const email = getEmailFromJWT(result.idToken.jwtToken)
        window.analytics.identify(getUsernameFromJWT(result.idToken.jwtToken), {
          email,
          anonymousId: getAnonymousId(),
        })
        tempLog('TEMP decoded email', { email })

        if (this.props.inviteToken && this.props.inviteToken.length > 0) {
          tempLog('TEMP inviteToken present; onboarding user', {
            inviteToken: this.props.inviteToken,
            cognitoIdToken: result.idToken.jwtToken,
          })
          this.props
            .onboardUser(result.idToken.jwtToken, this.props.inviteToken, null)
            .then(({ data }) => {
              if (
                !data.success &&
                (data.error === 'used-token' || data.error === 'invalid-token')
              ) {
                tempLog('TEMP (inviteToken) redirecting to invite', {
                  success: data.success,
                  error: data.error,
                })
                const inviteUrl = `/invite/${this.props.inviteToken}`
                storePostLoginRedirectUrl(inviteUrl, null)
                this.refreshSession(result.refreshToken)
              } else {
                tempLog(
                  'TEMP (inviteToken) no invite redirect; refreshing session',
                  {
                    success: data.success,
                    error: data.error,
                  }
                )
                this.refreshSession(result.refreshToken)
              }
            })
        } else if ('identities' in decodedAuth) {
          const { providerName } = decodedAuth.identities[0]
          tempLog('TEMP identities present; onboarding user', {
            providerName,
            cognitoIdToken: result.idToken.jwtToken,
          })
          this.props
            .onboardUser(result.idToken.jwtToken, null, providerName)
            .then(({ data }) => {
              if (
                !data.success &&
                (data.error === 'used-token' || data.error === 'invalid-token')
              ) {
                tempLog('TEMP (identities) redirecting to invite', {
                  success: data.success,
                  error: data.error,
                })
                const inviteUrl = `/invite/${this.props.inviteToken}`
                storePostLoginRedirectUrl(inviteUrl, null)
                this.refreshSession(result.refreshToken)
              } else {
                tempLog(
                  'TEMP (identities) no invite redirect; refreshing session',
                  {
                    success: data.success,
                    error: data.error,
                  }
                )
                this.refreshSession(result.refreshToken)
              }
            })
        } else {
          tempLog('TEMP no token or identities; calling updateCognitoSession')
          this.props.updateCognitoSession(result)
          this.props.history.push(readPostLoginRedirectUrl())
        }
      },
      onFailure: (err) => {
        console.error('Error!!', err)
        this.props.history.push('/')
      },
    }
    tempLog('TEMP Cognito Auth initiate code grant flow', {
      href: window.location.href,
    })
    auth.useCodeGrantFlow()
    auth.parseCognitoWebResponse(window.location.href)
  }

  refreshSession(refreshToken) {
    tempLog('TEMP Auth refreshSession', { refreshToken })
    const userPool = new CognitoUserPool({
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    })
    const user = userPool.getCurrentUser()

    user.refreshSession(refreshToken, (err, session) => {
      if (err) {
        console.error('error with refresh session: ', err)
      } else {
        tempLog('TEMP refresh OK; calling updateCognitoSession')
        this.props.updateCognitoSession(session)
        this.props.history.push(readPostLoginRedirectUrl())
      }
    })
  }

  render() {
    const {
      location: { search },
    } = this.props
    const queryString = search.substr(1)
    const queryParams = qs.parse(queryString)

    tempLog('TEMP Auth render', { queryString })

    return (
      <div className='uk-container'>
        <div className='uk-section'>
          <div className='uk-container'>
            <div className='text-center'>
              {queryParams.error ? (
                <h1>{queryParams.error_description}</h1>
              ) : (
                <div data-uk-spinner='' />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Auth.propTypes = propTypes
Auth.defaultProps = defaultProps

const mapStateToProps = () => ({
  inviteToken: getInviteToken(),
})

const mapDispatchToProps = (dispatch) => ({
  updateCognitoSession: (session, flags) => {
    dispatch(auth.updateCognitoSession(session, flags))
  },
  onboardUser: (idToken, teamToken, teamId) =>
    dispatch(onboardUser(idToken, teamToken, teamId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth))
