/* eslint-disable no-nested-ternary */
import React from 'react'
import moment from 'moment'

import Pagination from 'app/views/components/Pagination'
import LicenseTag from 'app/views/components/LicenseTag'
import Icon from 'app/views/components/Icon'

import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import SecondaryButton from '../../../components/Buttons/SecondaryButton'
import SortableTableHeader from 'app/views/components/SortableTableHeader'
import Loader from 'app/views/components/Loader'

class UsersList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onSelectUser = this.onSelectUser.bind(this)
    this.printRole = this.printRole.bind(this)
    this.onClearFilter = this.onClearFilter.bind(this)
    this.headers = [
      { key: 'email', name: 'Email' },
      { key: 'role', name: 'Role' },
      { key: 'teams', name: 'Teams' },
      { key: 'license_type', name: 'License' },
      { key: 'progress', name: 'Progress' },
    ]
  }

  onSelectUser(user) {
    this.props.onSelectUser(user)
  }

  onClearFilter() {
    this.props.onChangeFilter({ target: { value: '' } })
  }

  printRole(role) {
    switch (role) {
      case 'admin':
        return 'Admin'
      case 'team_admin':
        return 'Team Admin'
      default:
        return 'Member'
    }
  }

  renderHeaders() {
    return this.headers.map((header, index) => (
      <SortableTableHeader
        disabled={this.props.isLoadingHackers}
        onClick={this.props.fetchSorted}
        key={header.key}
        keyName={header.key}
        middle
        notSortable={header.key === 'teams'}
        center={index > 0}
        name={header.name}
        active={header.key === this.props.active}
        asc={header.key === this.props.active && this.props.asc}
        style={{
          fontSize: 10,
          minWidth: header.key === 'last_seen' ? 100 : undefined,
          width:
            header.key === 'email'
              ? '30%'
              : header.key === 'progress'
              ? null
              : '12%',
        }}
      />
    ))
  }

  render() {
    return (
      <>
        {!this.props.simpleList && <h2>Users</h2>}

        <div className='uk-width-1-1 flex items-center justify-between'>
          <div
            className={`uk-inline${
              this.props.simpleList ? ' uk-width-1-1' : ''
            }`}
          >
            <Icon as='a' className='uk-form-icon' href='#' name='search' />
            <input
              className={`uk-input ${
                this.props.simpleList ? 'uk-width-1-1' : 'uk-form-width-large'
              }`}
              type='text'
              placeholder='Search by email'
              spellCheck={false}
              autoComplete='off'
              value={this.props.filterHacker}
              onChange={this.props.onChangeFilter}
            />
            {this.props.filterHacker !== '' && (
              <Icon
                as='a'
                role='button'
                onClick={this.onClearFilter}
                style={{
                  cursor: 'pointer',
                }}
                className='uk-form-icon uk-form-icon-flip'
                name='close'
              />
            )}
          </div>
          <div className='flex items-center'>
            <PrimaryButton
              onClick={this.props.showAddUsers}
              size='small'
              label='Add Users'
              customCss={'mr-2'}
            />
            <SecondaryButton
              size='small'
              onClick={() => this.props.onExport()}
              label='Export Data'
            />
          </div>
        </div>
        {!this.props.simpleList && (
          <div className='flex flex-1-0 justify-end uk-margin-top'>
            <Pagination
              onChangePage={this.props.onChangePage}
              hasArrows
              totalPages={this.props.totalPages}
              currentPage={
                this.props.offsetHackers === 0
                  ? 1
                  : this.props.offsetHackers / this.props.hackersPerPage + 1
              }
            />
          </div>
        )}
        <table className='uk-table uk-table-divider uk-table-middle text-sm uk-table-small uk-margin-remove'>
          <thead>
            <tr>
              {this.renderHeaders()}
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.users.length === 0 && (
              <tr>
                <td colSpan='6' className='text-center'>
                  {this.props.filterHacker !== '' || this.props.simpleList
                    ? 'There are no users that coincide with your search criteria'
                    : 'No users in the organization'}
                </td>
              </tr>
            )}
            {this.props.isLoadingHackers && (
              <tr>
                <td colSpan='6'>
                  <Loader visible text='Loading...' />
                </td>
              </tr>
            )}
            {!this.props.isLoadingHackers &&
              this.props.users.map((user) => (
                <tr key={user.uuid}>
                  <td>
                    {typeof this.props.fetchSelectedUserDetail ===
                    'function' ? (
                      <a
                        role='button'
                        className='text-green hover:text-green'
                        onClick={() => {
                          this.props.fetchSelectedUserDetail(
                            this.props.match.params.organization_uuid,
                            user
                          )
                        }}
                      >
                        {user.email}
                      </a>
                    ) : (
                      user.email
                    )}
                  </td>
                  {!this.props.simpleList && (
                    <>
                      <td className='text-center'>
                        {this.printRole(user.role)}
                      </td>
                      <td className='text-center'>
                        {user.teams.length === 0
                          ? ' - '
                          : user.teams
                              .sort((a, b) => {
                                if (a.name > b.name) {
                                  return 1
                                }
                                if (a.name < b.name) {
                                  return -1
                                }
                                return 0
                              })
                              .map((team) => (
                                <div key={team.uuid}>
                                  <span className='uk-label uk-label-secondary'>
                                    {team.name}
                                    {team.plan_start_date ? ' - ' : ''}
                                    {team.plan_start_date
                                      ? `${team.plan_start_date}`
                                      : null}
                                  </span>
                                </div>
                              ))}
                      </td>
                      <td className='text-center'>
                        <LicenseTag user={user} />
                      </td>
                      <td>
                        <progress
                          className='uk-progress'
                          value={user.progress}
                          max='100'
                        />
                      </td>
                    </>
                  )}
                  <td
                    className={
                      this.props.actionsContainerClass || 'text-center'
                    }
                    style={{ width: 140 }}
                  >
                    {this.props.renderActions ? (
                      this.props.renderActions(user)
                    ) : (
                      <SecondaryButton
                        onClick={this.onSelectUser.bind(this, user)}
                        size='small'
                        label={'Edit'}
                        customCss={'mb-4'}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {this.props.extraContent}
      </>
    )
  }
}

export default UsersList
