import React from 'react'
import PropTypes from 'prop-types'

import ContentModal from './ContentModal'

function InactiveSandboxModal(props) {
  const { onClose } = props
  return (
    <ContentModal id='inactive-sandbox-timeout' width='70vw' onClose={onClose}>
      <div style={{ padding: 40 }}>
        <h3>Sandbox Timed Out</h3>
        <p>You have been inactive for more than two hours.</p>
        <p>
          If you'd like to continue the lesson with a new sandbox, please close
          this dialog.
        </p>
      </div>
    </ContentModal>
  )
}
InactiveSandboxModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default InactiveSandboxModal
