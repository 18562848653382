import settings from 'settings'
import { api } from 'app/views/utils/api'

import { UPDATE_CONTENT_HACKER_METADATA } from './content'

const prefix = 'hint'
const BEGIN_FETCHING_HINT = `${prefix}/BEGIN_FETCHING_HINT`
const FINISH_FETCHING_HINT = `${prefix}/FINISH_FETCHING_HINT`

// hint state caches the hints we've received from the API, indexed by serial hint ID,
// with each object consisting of { isLoading, error, data }. Also accumulate the number
// of times we've received 'highlight' hint data, so we can easily detect when there's
// new highlight data to display.
const initialState = {
  hintStatesById: {},
  highlightHintDataAccumulator: 0,
}

// Helper function for immutably mutating a single hint state object
const updateHintState = (state, hintId, attrs) => ({
  ...state,
  hintStatesById: {
    ...state.hintStatesById,
    [hintId]: {
      ...state.hintStatesById[hintId],
      ...attrs,
    },
  },
})

const ENABLE_VERBOSE_LOGGING = false
// eslint-disable-next-line no-console
const _log = ENABLE_VERBOSE_LOGGING ? console.log : () => {}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case BEGIN_FETCHING_HINT:
      return updateHintState(state, action.hintId, {
        isLoading: true,
        error: null,
        data: null,
      })
    case FINISH_FETCHING_HINT:
      if (action.error) {
        return updateHintState(state, action.hintId, {
          isLoading: false,
          error: action.error,
          data: null,
        })
      }
      const withNewHintData = updateHintState(state, action.hintId, {
        isLoading: false,
        data: action.data,
      })
      if (action.data.hint_type === 'highlight') {
        return {
          ...withNewHintData,
          highlightHintDataAccumulator: state.highlightHintDataAccumulator + 1,
        }
      }
      return withNewHintData
    default:
      return state
  }
}

export function requestHintData(hintId) {
  return (dispatch) => {
    _log(`fetching data for hint ${hintId}...`)
    dispatch({ type: BEGIN_FETCHING_HINT, hintId })
    api({
      method: 'GET',
      url: `${settings.urls.hacker}/content/hint`,
      params: { id: hintId },
      withAuthToken: true,
    })
      .then((response) => {
        _log(`got data for hint ${hintId}`, response.data)
        dispatch({ type: FINISH_FETCHING_HINT, hintId, data: response.data })

        // We need to notify the content module that this hint has been used, since it
        // doesn't get any updates on content_to_hacker.metadata.usedHints after the
        // initial API request it makes on load
        const contentUUID = response.data.content_uuid
        const points = response.data.metadata.points || 0
        _log(
          `notifying content module that hint ${hintId} was used (${points} points on content ${contentUUID})`
        )
        dispatch({
          type: UPDATE_CONTENT_HACKER_METADATA,
          hintType: hintId, // for some reason this parameter is called 'hintType', but it's meant to be the ID of the hint
          contentUUID,
          points,
        })
      })
      .catch((error) => {
        console.error(`failed to get data for hint ${hintId}`, error)
        dispatch({ type: FINISH_FETCHING_HINT, hintId, error })
      })
  }
}
