import React from 'react'
import moment from 'moment'
import UIkit from 'uikit'
import { connect } from 'react-redux'

// Components
import Pagination from 'app/views/components/Pagination'
import GenericModal from 'app/views/components/Modals/GenericModal'
import Icon from 'app/views/components/Icon'
import Loader from 'app/views/components/Loader'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import UserDetailModal from 'app/views/components/UserDetailModal'
import TeamPicker from 'app/views/components/TeamPicker'

// Redux
import {
  exportCompletedReport,
  getCompletedReport,
} from 'app/state/modules/reports'
import {
  fetchSelectedUserDetail,
  cleanUserDetailModal,
} from 'app/state/modules/users'
import { deleteHackerFromOrganization } from 'app/state/modules/hacker'
import SortableTableHeader from 'app/views/components/SortableTableHeader'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

class CompletedReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 'email',
      asc: true,
      currentPage: 1,
      filter: null,
      teamUUID: null,
      runningReport: false,
    }
    this.onExport = this.onExport.bind(this)
    this.onPickFilter = this.onPickFilter.bind(this)
    this.fetchSortedReport = this.fetchSortedReport.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.fetchSelectedUserDetail = this.fetchSelectedUserDetail.bind(this)
    this.onChangeTeam = this.onChangeTeam.bind(this)
    this.onDeleteUser = this.onDeleteUser.bind(this)
    this.props.cleanUserDetailModal()
    this.hackersPerPage = 100
    this.headers = [
      { key: 'email', name: 'Email' },
      { key: 'teams', name: 'Teams' },
      { key: 'time_spent', name: 'Time Spent' },
      { key: 'training_progress', name: 'Progress' },
      { key: 'is_past_due', name: 'Past Due' },
    ]
  }

  componentDidMount() {
    this.props.getCompletedReport(
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid
    )
  }

  async onExport() {
    this.setState({
      runningReport: true,
    })
    try {
      const headers = [
        'Email',
        'Lesson',
        'Completion Date',
        'Code Submissions',
        'Time Spent',
      ]
      const hackers =
        this.props.totalCompletedReport < 100 && this.state.currentPage <= 1
          ? this.props.completedReport
          : await this.props.exportCompletedReport(
              this.props.match.params.organization_uuid,
              this.state.teamUUID || this.props.match.params.team_uuid,
              this.state.active,
              this.state.asc,
              this.state.filter
            )
      const rows = []
      rows.push(headers)
      hackers.forEach((hacker) => {
        hacker.content.forEach((c) => {
          const hackerRow = []
          hackerRow.push(hacker.email)
          hackerRow.push(c.content_title)
          hackerRow.push(
            c.finished_on
              ? getLocalDateFromUTC(c.finished_on).format('YYYY-MM-DD HH:mm')
              : ' - '
          )
          hackerRow.push(c.code_submission)
          hackerRow.push(c.time_spent)
          rows.push(hackerRow)
        })
      })
      const csvContent = `data:text/csv;charset=utf-8,${rows
        .map((e) => e.join(','))
        .join('\n')}`
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute(
        'download',
        `completion_report_${moment().format('YYYY-MM-DD')}.csv`
      )
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({
        runningReport: false,
      })
    }
  }

  onPickFilter(filter) {
    const { filter: currentFilter } = this.state
    this.setState(
      {
        filter: currentFilter === filter ? null : filter,
        currentPage: 1,
      },
      () => {
        this.props.getCompletedReport(
          this.props.match.params.organization_uuid,
          this.state.teamUUID || this.props.match.params.team_uuid,
          this.state.active,
          this.state.asc,
          (this.state.currentPage - 1) * this.hackersPerPage,
          this.state.filter
        )
      }
    )
  }

  onChangeTeam(e) {
    const teamUUID = e.target.value === '-' ? null : e.target.value
    this.setState(
      {
        currentPage: 1,
        teamUUID,
      },
      () => {
        this.props.getCompletedReport(
          this.props.match.params.organization_uuid,
          this.state.teamUUID,
          this.state.active,
          this.state.asc,
          (this.state.currentPage - 1) * this.hackersPerPage,
          this.state.filter
        )
      }
    )
  }

  onChangePage(currentPage) {
    this.setState({
      currentPage,
    })
    this.props.getCompletedReport(
      this.props.match.params.organization_uuid,
      this.state.teamUUID || this.props.match.params.team_uuid,
      this.state.active,
      this.state.asc,
      (currentPage - 1) * this.hackersPerPage,
      this.state.filter
    )
  }

  onDeleteUser() {
    const orgUUID = this.props.match.params.organization_uuid
    const userUUID =
      this.props.selectedUserDetail.uuid || this.props.match.params.hacker_uuid
    const callback = (error = false) => {
      this.modalDeleteUser.hide()
      if (error) {
        return UIkit.modal.alert(
          '<h2 >Error</h2><p>There was an error deleting the user. Please try again or contact support.</p>'
        )
      }
      this.props.getCompletedReport(
        this.props.match.params.organization_uuid,
        this.props.match.params.team_uuid
      )
      return UIkit.modal.alert(
        '<h2 >User removed</h2><p>The user has been removed successfully from the organization<p>'
      )
    }
    this.props.deleteHackerFromOrganization(orgUUID, userUUID, callback)
  }

  fetchSelectedUserDetail(row, index) {
    this.props.fetchSelectedUserDetail(
      this.props.match.params.organization_uuid,
      {
        ...row,
        uuid: row.hacker_uuid,
        justTeam: this.props.match.params.team_uuid,
      },
      false,
      {
        localIndex: index,
      }
    )
  }

  fetchSortedReport(sortBy) {
    const { asc, active } = this.state
    this.setState(
      {
        active: sortBy,
        asc: sortBy === active ? !asc : true,
        currentPage: 1,
      },
      () => {
        this.props.getCompletedReport(
          this.props.match.params.organization_uuid,
          this.state.teamUUID || this.props.match.params.team_uuid,
          this.state.active,
          this.state.asc,
          0,
          this.state.filter
        )
      }
    )
  }

  renderDeleteModalContent() {
    if (!this.props.selectedUserDetail) return null
    if (this.props.selectedUserDetail.steps_completed > 20) {
      return (
        <>
          <p>
            {`You are about to remove ${this.props.selectedUserDetail.email} from your organization.
            This user's license does not qualify to be released due to the amount of content they
            have already completed.`}
          </p>
          <p>Are you sure you want to continue?</p>
        </>
      )
    }
    return (
      <>
        <p>
          {`You are about to remove ${this.props.selectedUserDetail.email} from your organization. This
          user's license will be available for reuse since they completed a minimal amount of
          the training.`}
        </p>
        <p>Are you sure you want to continue?</p>
      </>
    )
  }

  renderHeaders() {
    return this.headers.map((header, index) => (
      <SortableTableHeader
        onClick={this.fetchSortedReport}
        key={header.key}
        keyName={header.key}
        middle
        notSortable={header.key === 'teams'}
        center={index > 0}
        name={header.name}
        active={header.key === this.state.active}
        asc={header.key === this.state.active && this.state.asc}
        style={{
          fontSize: 10,
          minWidth: header.key === 'last_seen' ? 100 : undefined,
        }}
      />
    ))
  }

  renderReport() {
    return this.props.completedReport.map((hacker, index) => (
      <React.Fragment key={hacker.hacker_uuid}>
        <tr>
          <td>
            <a
              className='text-green hover:text-green'
              onClick={this.fetchSelectedUserDetail.bind(
                this,
                hacker,
                index,
                false
              )}
            >
              {hacker.email}
            </a>
          </td>
          <td className='text-center'>
            {hacker.teams.map((team) => (
              <React.Fragment key={team.uuid}>
                <label className='uk-label uk.-label-primary'>
                  {team.name}
                </label>
                <br />
              </React.Fragment>
            ))}
          </td>
          <td className='text-center'>
            {hacker.time_spent.split(':').slice(0, 2).join(':')}
          </td>
          <td className='text-center'>
            <progress
              className='uk-progress'
              value={hacker.training_progress}
              max='100'
            />
          </td>
          <td className='text-center'>
            <Icon
              name='checkbox-blank-circle'
              fill
              className={`text-${hacker.is_past_due ? 'danger' : 'success'}`}
            />
          </td>
        </tr>
      </React.Fragment>
    ))
  }

  render() {
    if (
      this.props.completedReport === 'loading' ||
      !this.props.hasLoadedOrganizations
    ) {
      return <Loader visible />
    }

    return (
      <>
        <OrganizationLayout
          active='reports'
          data-test-id='organization-users-component'
          noPaddingRemove={this.props.match.params.team_uuid}
          style={{ position: 'relative' }}
          {...this.props}
        >
          {this.props.completedReport === 'loading' ||
          !this.props.hasLoadedOrganizations ? (
            <Loader visible />
          ) : (
            <>
              {this.props.match.params.team_uuid && (
                <button
                  onClick={() =>
                    this.props.history.push(
                      `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/users`
                    )
                  }
                  className='uk-button uk-button-secondary uk-button-small uk-position-absolute uk-position-top-left uk-margin-top uk-margin-small-left flex'
                  type='button'
                >
                  <Icon name='arrow-left' className='uk-margin-small-right' />
                  Back To Team
                </button>
              )}

              <h2 className='text-center'>Completion Report</h2>
              <div className='flex justify-end flex-col'>
                <div className='flex justify-end flex-1-0'>
                  {!this.props.match.params.team_uuid && (
                    <TeamPicker onChange={this.onChangeTeam} />
                  )}
                  <div className='uk-margin-small-left'>
                    <button
                      onClick={this.onPickFilter.bind(this, 'complete')}
                      className={`uk-button uk-button-${
                        this.state.filter === 'complete'
                          ? 'secondary'
                          : 'default'
                      } uk-button-small`}
                      type='button'
                      style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      Complete
                    </button>
                    <button
                      onClick={this.onPickFilter.bind(this, 'incomplete')}
                      className={`uk-button uk-button-${
                        this.state.filter === 'incomplete'
                          ? 'secondary'
                          : 'default'
                      } uk-button-small`}
                      type='button'
                      style={{
                        borderRadius: 0,
                      }}
                    >
                      Incomplete
                    </button>
                    <button
                      onClick={this.onPickFilter.bind(this, 'past_due')}
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      className={`uk-button uk-button-${
                        this.state.filter === 'past_due'
                          ? 'secondary'
                          : 'default'
                      } uk-button-small`}
                      type='button'
                    >
                      Past Due
                    </button>
                  </div>
                  <button
                    onClick={this.onExport}
                    className='uk-button uk-button-default uk-button-small uk-margin-small-left flex'
                    type='button'
                    disabled={this.state.runningReport ? true : false}
                  >
                    <Icon
                      className='uk-margin-small-right'
                      name='download-cloud'
                      ratio={0.9}
                    />
                    Full Report
                  </button>
                </div>
                <div className='flex justify-end flex-1-0 uk-margin-top'>
                  <Pagination
                    currentPage={this.state.currentPage}
                    style={{
                      marginBottom: 0,
                      visibility:
                        this.props.totalCompletedReport === 0 ? 'hidden' : null,
                    }}
                    onChangePage={this.onChangePage}
                    hasArrows
                    totalPages={Math.ceil(
                      this.props.totalCompletedReport / this.hackersPerPage
                    )}
                  />
                </div>
              </div>
            </>
          )}
          <table className='uk-table uk-table-divider uk-table-middle uk-table-small text-sm'>
            {this.props.totalCompletedReport > 0 && (
              <thead className='uk-text'>
                <tr>{this.renderHeaders()}</tr>
              </thead>
            )}
            <tbody>
              {this.props.totalCompletedReport === 0 && (
                <tr>
                  <td>No users for the selected filters</td>
                </tr>
              )}
              {this.renderReport()}
            </tbody>
          </table>
          <UserDetailModal
            options={{ container: 'body' }}
            id='user-detail-complete-reports'
            localData={this.props.completedReport}
            selectedUserDetail={this.props.selectedUserDetail}
            onRemoveFromOrg={() => this.modalDeleteUser.show()}
          />
          <GenericModal
            title='Delete user from organization'
            ref={(ref) => {
              this.modalDeleteUser = ref
            }}
            id='delete-user-modal'
            date-test-id='delete-user-modal'
            body={this.renderDeleteModalContent()}
            buttons={
              <button
                data-test-id='delete-user-confirm'
                type='button'
                className='uk-button uk-button-danger uk-margin-left'
                onClick={this.onDeleteUser}
              >
                Delete
              </button>
            }
          />
        </OrganizationLayout>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedUserDetail: state.users.selectedUserDetail,
  completedReport: state.reports.completedReport,
  totalCompletedReport: state.reports.totalCompletedReport,
  hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
  organizationsHash: state.hacker.organizationsHash,
  organizations: state.hacker.organizationsList,
})

export default connect(mapStateToProps, {
  getCompletedReport,
  fetchSelectedUserDetail,
  cleanUserDetailModal,
  deleteHackerFromOrganization,
  exportCompletedReport,
})(CompletedReports)
