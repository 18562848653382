// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'
import { SET_INITIAL_FILE, SET_VERSIONS, resetCodeReview } from './codeReview'
import { SET_SANDBOX_URL } from './k8s'

const GETTING_CONTENT = 'content/GETTING_CONTENT'
const RECEIVED_CONTENT = 'content/RECEIVED_CONTENT'

const GETTING_CONTENT_BY_ID = 'content/GETTING_CONTENT_BY_ID'
export const RECEIVED_CONTENT_BY_ID = 'content/RECEIVED_CONTENT_BY_ID'
const RECEIVED_CONTENT_BY_ID_ERROR = 'content/RECEIVED_CONTENT_BY_ID_ERROR'

const GETTING_LESSON_BY_ID = 'content/GETTING_LESSON_BY_ID'
const RECEIVED_LESSON_BY_ID = 'content/RECEIVED_LESSON_BY_ID'
const RECEIVED_LESSON_BY_ID_ERROR = 'content/RECEIVED_LESSON_BY_ID_ERROR'

const GETTING_PLAN = 'content/GETTING_PLAN'
const RECEIVED_PLAN = 'content/RECEIVED_PLAN'

const GETTING_VULNERABILITY_TYPES = 'content/GETTING_VULNERABILITY_TYPES'
const RECEIVED_VULNERABILITY_TYPES = 'content/RECEIVED_VULNERABILITY_TYPES'

const GETTING_CODE_REVIEWS = 'content/GETTING_CODE_REVIEWS'
const RECEIVED_CODE_REVIEWS = 'content/RECEIVED_CODE_REVIEWS'

export const UPDATE_CONTENT_HACKER_METADATA =
  'content/UPDATE_CONTENT_HACKER_METADATA'

const initialState = {
  loadingContent: false,
  content: {
    challenges: [],
    courses: [],
    code_reviews: [],
  },

  loadingCodeReviews: false,
  loadingContentById: false,
  loadingContentByIdError: '',
  contentDetails: {},

  loadingLessonById: false,
  loadingLessonByIdError: '',
  lessonDetails: {},
  loadingPlan: false,
  plan: {
    // uuid: null,
    // title: null,
    // description: null,
    // groups: [],
  },

  loadingVulnerabilityTypes: false,
  vulnerabilityTypes: [],
  codeReviews: [],
  featuredCodeReviews: [],
}

function mapValues(obj, fn) {
  return (
    obj &&
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, fn(value)])
    )
  )
}

/**
 * Updates the realUrl of the content tabs with the URL provided by the sandbox.
 *
 * @param contentDetails
 * @param sandbox
 * @returns the content detail with the tab urls updated
 */
function updateTabs(contentDetails, sandbox) {
  const detail = sandbox && contentDetails[sandbox.contentUUID]
  if (detail && !detail.tabs) {
    detail.tabs = []
  }
  return detail
    ? {
        ...contentDetails,
        [sandbox.contentUUID]: {
          ...detail,
          tabs: detail.tabs.map((tab) => ({
            ...tab,
            realUrl: (sandbox.tabs[tab.app_name] || {}).url || tab.realUrl,
          })),
        },
      }
    : contentDetails
}

export function globalReducer(globalState = initialState, action = {}) {
  const state = globalState.content
  switch (action.type) {
    case RECEIVED_CONTENT_BY_ID:
      return {
        ...globalState,
        content: {
          ...state,
          // if we have data from the sandbox, update the tab URLs
          contentDetails: updateTabs(
            action.lessons,
            (globalState.proxy || {}).sandbox
          ),
          loadingContentById: false,
        },
      }
  }
  return globalState
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GETTING_CONTENT:
      return {
        ...state,
        loadingContent: true,
      }
    case RECEIVED_CONTENT:
      return {
        ...state,
        content: action.content,
        loadingContent: false,
      }
    case GETTING_CONTENT_BY_ID:
      return {
        ...state,
        loadingContentById: true,
        loadingContentByIdError: '',
      }
    case RECEIVED_CONTENT_BY_ID_ERROR:
      return {
        ...state,
        loadingContentByIdError: action.error,
      }
    case GETTING_LESSON_BY_ID:
      return {
        ...state,
        loadingLessonById: true,
        loadingLessonByIdError: '',
      }
    case RECEIVED_LESSON_BY_ID:
      return {
        ...state,
        lessonDetails: action.lessons,
        loadingLessonById: false,
      }
    case RECEIVED_LESSON_BY_ID_ERROR:
      return {
        ...state,
        loadingLessonByIdError: action.error,
      }
    case GETTING_PLAN:
      return {
        ...state,
        loadingPlan: true,
      }
    case RECEIVED_PLAN:
      return {
        ...state,
        plan: action.plan,
        loadingPlan: false,
      }
    case GETTING_VULNERABILITY_TYPES:
      return {
        ...state,
        loadingVulnerabilityTypes: true,
      }
    case GETTING_CODE_REVIEWS:
      return {
        ...state,
        loadingCodeReviews: true,
      }
    case RECEIVED_VULNERABILITY_TYPES:
      return {
        ...state,
        vulnerabilityTypes: action.vulnerabilityTypes,
        loadingVulnerabilityTypes: false,
      }
    case RECEIVED_CODE_REVIEWS:
      return {
        ...state,
        codeReviews: action.codeReviews.content,
        featuredCodeReviews: action.codeReviews.featured,
        loadingCodeReviews: false,
      }
    case SET_SANDBOX_URL:
      return {
        ...state,
        contentDetails: updateTabs(state.contentDetails, action.sandbox),
      }
    case UPDATE_CONTENT_HACKER_METADATA:
      return {
        ...state,
        contentDetails: {
          [action.contentUUID]: {
            ...state.contentDetails[action.contentUUID],
            hacker_metadata: {
              ...state.contentDetails[action.contentUUID].hacker_metadata,
              usedHints: {
                ...state.contentDetails[action.contentUUID].hacker_metadata
                  .usedHints,
                [action.hintType]: action.points,
              },
            },
          },
        },
      }
    default:
      return state
  }
}

export function getLicenseContent(organizationUUID, licenseUUID) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/content`,
      withAuthToken: true,
      params: {
        license_type_uuid: licenseUUID,
        organization_uuid: organizationUUID,
      },
    })
  }
}

export function getContent(
  organizationUUID = null,
  includeAllOrganizationLicenses = null,
  callback = null
) {
  return (dispatch) => {
    dispatch({
      type: GETTING_CONTENT,
    })
    const language =
      localStorage.getItem('hackedu.language') === null
        ? 'en'
        : localStorage.getItem('hackedu.language')

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/content`,
      withAuthToken: true,
      params: {
        include_all_organization_licenses: includeAllOrganizationLicenses,
        organization_uuid: organizationUUID,
        language,
      },
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_CONTENT,
          content: response.data,
        })
        if (callback) {
          callback()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function pingContent(contentId) {
  return (dispatch) => {
    if (contentId) {
      return api({
        method: 'post',
        url: `${settings.urls.hacker}/content/${contentId}/ping`,
        withAuthToken: true,
      }).catch((error) => {
        console.error({ error })
      })
    }
    return false
  }
}

export function getContentById(contentId) {
  return (dispatch, getState) => {
    dispatch({
      type: GETTING_CONTENT_BY_ID,
    })
    const state = getState()

    const language =
      localStorage.getItem('hackedu.language') === null
        ? 'en'
        : localStorage.getItem('hackedu.language')

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/content/${contentId}`,
      withAuthToken: true,
      params: {
        language,
      },
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_CONTENT_BY_ID,
          lessons: {
            ...state.lessons,
            [contentId]: response.data,
          },
        })
        if (
          response.data.content_type === 'coding_challenge' ||
          response.data.content_type === 'mobile'
        ) {
          dispatch(resetCodeReview())
          dispatch({
            type: SET_VERSIONS,
            versions: response.data.versions,
          })
          dispatch({
            type: SET_INITIAL_FILE,
            initialFile: response.data.metadata.defaultFile,
            engine: response.data.metadata.language.name,
          })
        }
      })
      .catch((error) => {
        if (
          error.response !== undefined &&
          error.response.data.error === 'unauthorized'
        ) {
          dispatch({
            type: RECEIVED_CONTENT_BY_ID_ERROR,
            error: 'unauthorized',
          })
          return false
        }
        dispatch({
          type: RECEIVED_CONTENT_BY_ID_ERROR,
          error: 'error',
        })
        return false
      })
  }
}

export function getLessonById(lessonId) {
  return (dispatch, getState) => {
    dispatch({
      type: GETTING_LESSON_BY_ID,
    })
    const state = getState()

    const language =
      localStorage.getItem('hackedu.language') === null
        ? 'en'
        : localStorage.getItem('hackedu.language')

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/lesson/${lessonId}`,
      withAuthToken: true,
      params: {
        language,
      },
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_LESSON_BY_ID,
          lessons: {
            ...state.lessons,
            [lessonId]: response.data,
          },
        })
      })
      .catch((error) => {
        console.error(error)
        if (
          error.response !== undefined &&
          error.response.data.error === 'unauthorized'
        ) {
          dispatch({
            type: RECEIVED_LESSON_BY_ID_ERROR,
            error: 'unauthorized',
          })
          return false
        }
        dispatch({
          type: RECEIVED_LESSON_BY_ID_ERROR,
          error: 'error',
        })
        return false
      })
  }
}

export function getPlan() {
  return (dispatch) => {
    dispatch({
      type: GETTING_PLAN,
    })
    const language =
      localStorage.getItem('hackedu.language') === null
        ? 'en'
        : localStorage.getItem('hackedu.language')
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/plan`,
      withAuthToken: true,
      params: {
        language,
      },
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_PLAN,
          plan: response.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getVulnerabilityTypes() {
  return (dispatch) => {
    dispatch({
      type: GETTING_VULNERABILITY_TYPES,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/vulnerabilities`,
      withAuthToken: true,
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_VULNERABILITY_TYPES,
          vulnerabilityTypes: response.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getCodingChallenges() {
  return (dispatch) => {
    dispatch({
      type: GETTING_CODE_REVIEWS,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/code-review`,
      withAuthToken: true,
    })
      .then((response) => {
        dispatch({
          type: RECEIVED_CODE_REVIEWS,
          codeReviews: response.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
