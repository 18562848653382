import React from 'react'

// Components
import ContentIcon from 'app/views/components/ContentIcon'

const ModalContentIcon = () => (
  <div id='modal-content-icon' data-uk-modal=''>
    <div className='uk-modal-dialog uk-modal-body' style={{ width: 800 }}>
      <button
        className='uk-modal-close-default'
        type='button'
        data-uk-close=''
      />
      <h2>Content Status</h2>

      <div className='uk-padding-small uk-padding-remove-horizontal flex items-center'>
        <ContentIcon license={false} requiresSubscription marginTop={0} />
        You do not have access to this lesson.
      </div>

      <div className='uk-padding-small uk-padding-remove-horizontal flex items-center'>
        <ContentIcon
          hasCompletedContent={false}
          lessonInProgress={false}
          marginTop={0}
        />
        You have not started this lesson yet.
      </div>

      <div className='uk-padding-small uk-padding-remove-horizontal flex items-center'>
        <ContentIcon
          hasCompletedContent={false}
          lessonInProgress
          marginTop={0}
        />
        You have started the lesson, but have not finished.
      </div>

      <div className='uk-padding-small uk-padding-remove-horizontal flex items-center'>
        <ContentIcon
          hasCodeSubmission={true}
          hasCompletedContent={false}
          hasPassedContent={false}
          hasTest
          lessonInProgress
          marginTop={0}
          mustPassCodingExercise
        />
        You have started the lesson, but have not passed the coding exercise.
      </div>

      <div className='uk-padding-small uk-padding-remove-horizontal flex items-center'>
        <ContentIcon lessonInProgress hasCompletedContent marginTop={0} />
        <span>
          Congratulations, you have successfully completed the lesson!
        </span>
      </div>

      <p className='text-right'>
        <button
          className='uk-button uk-button-primary uk-modal-close'
          type='button'
        >
          Ok
        </button>
      </p>
    </div>
  </div>
)

export default ModalContentIcon
