import 'flatpickr/dist/themes/material_blue.css'

import React from 'react'
import Flatpickr from 'react-flatpickr'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { values } from 'lodash'
import UIkit from 'uikit'
import { Link } from 'react-router-dom'

// Components
import Loader from 'app/views/components/Loader'
import Icon from 'app/views/components/Icon'
import PhaseCheckbox from 'app/views/components/PhaseCheckbox'
import LessonSorter from 'app/views/components/LessonSorter'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'
import SecondaryButton from 'app/views/components/Buttons/SecondaryButton'
import PrimaryRadio from 'app/views/components/Inputs/PrimaryRadio'

// Redux
import { getContent } from 'app/state/modules/content'
import {
  updatePlanGroup,
  deletePlanGroup,
  getPlanGroupById,
} from 'app/state/modules/plan'

// Utils
import analytics from 'app/views/utils/analytics'
import settings from 'settings'
import OrganizationLayout from '../components/Layout'

class OrganizationsPlansPhaseEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: {},
      title: '',
      description: '',
      startDate: null,
      endDate: null,
      confirmDelete: false,
      updating: false,
      duration: '',
      isLoading: true,
      contentType: 'lessons',
      selectedContentIds: [],
      selectedContentNames: [],
      step: 1,
    }

    this.handleChangeTitle = this.handleChangeTitle.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this)
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this)
    this.handleSubmitSave = this.handleSubmitSave.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
    this.handleChangeDuration = this.handleChangeDuration.bind(this)
    this.initializeComponent = this.initializeComponent.bind(this)
    this.activateDragListeners = this.activateDragListeners.bind(this)
    this.setSelectedContentIds = this.setSelectedContentIds.bind(this)
    this.setSelectedContentNames = this.setSelectedContentNames.bind(this)
    this.handleClickNext = this.handleClickNext.bind(this)
    this.addError = this.addError.bind(this)
    this.removeError = this.removeError.bind(this)
    this.hasErrors = this.hasErrors.bind(this)
    this.checkDates = this.checkDates.bind(this)

    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  setSelectedContentIds(contentIds) {
    this.setState({
      selectedContentIds: contentIds,
    })
  }

  setSelectedContentNames(contentNames) {
    this.setState({
      selectedContentNames: contentNames,
    })
  }

  componentDidMount() {
    if (!this.props.isModal) {
      this.initializeComponent()
    }
    if (this.props.content.courses.length > 0) {
      setTimeout(() => {
        this.activateDragListeners()
      }, 500)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentPlanGroup !== this.props.currentPlanGroup) {
      let startDate = null
      if (nextProps.currentPlanGroup.start_date) {
        startDate = new Date(
          `${nextProps.currentPlanGroup.start_date}T00:00:00`
        )
      }

      let endDate = null
      if (nextProps.currentPlanGroup.end_date) {
        endDate = new Date(`${nextProps.currentPlanGroup.end_date}T00:00:00`)
      }

      this.setState(
        {
          title: nextProps.currentPlanGroup.title,
          description: nextProps.currentPlanGroup.description,
          startDate,
          endDate,
          duration: nextProps.currentPlanGroup.duration || null,
          selectedContentIds: nextProps.currentPlanGroup.content.map(
            (x) => x.uuid
          ),
          selectedContentNames: nextProps.currentPlanGroup.content.map(
            (x) => x.title
          ),
        },
        () => {
          setTimeout(() => {
            this.activateDragListeners()
          }, 500)
        }
      )
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  activateDragListeners() {
    UIkit.util.on('#content-list', 'added', (x) => {
      x.target.children.forEach((child) => {
        if (child.dataset.type === 'coding_challenge' && child.children[0]) {
          child.children[0].innerHTML = child.dataset.selectedName
        }
        if (child.dataset.type === 'lesson' || child.dataset.type === 'app') {
          JSON.parse(child.dataset.contentUuids).forEach((contentUUID) => {
            const element = document.getElementById(`${contentUUID}`)
            let existsElement = false
            for (let el of document
              .getElementById('content-list')
              .getElementsByTagName('li')) {
              if (el.id === contentUUID) {
                existsElement = true
              }
            }
            if (!existsElement) {
              element.parentNode.removeChild(element)
              document
                .getElementById('content-list')
                .insertAdjacentElement('beforeend', element)
              if (element.dataset.selectedName) {
                element.innerHTML = element.dataset.selectedName
              }
            }
          })
        }
      })
    })
  }

  initializeComponent() {
    this.props.getPlanGroupById(
      this.props.match.params.organization_uuid,
      this.props.match.params.plan_uuid,
      this.props.match.params.phase_uuid
    )
    this.props.getContent(
      this.props.match.params.organization_uuid,
      true,
      () => {
        this.setState({ isLoading: false })
      }
    )

    analytics.page('page-organizations-plans-phase-edit')
  }

  addError(input, message) {
    const newErrs = { ...this.state.errors }
    newErrs[input] = message
    this.setState({ errors: newErrs })
  }

  removeError(input) {
    const newErrs = { ...this.state.errors }
    delete newErrs[input]
    this.setState({ errors: newErrs })
  }

  hasErrors() {
    return Object.keys(this.state.errors).length > 0
  }

  handleChangeTitle(event) {
    const titleVal = event.target.value

    this.setState({ title: titleVal })
    if (!titleVal) {
      this.addError('title', 'Title is required.')
    } else {
      this.removeError('title')
    }
  }

  handleChangeDescription(event) {
    this.setState({ description: event.target.value })
  }

  handleChangeDuration(event) {
    const durationVal = event.target.value.replace(/[^0-9]+/g, '')
    this.setState({ duration: durationVal })
    if (
      durationVal === '' ||
      isNaN(parseInt(durationVal, 10)) ||
      parseInt(durationVal, 10) < 1
    ) {
      this.addError(
        'duration',
        'Duration is required and can\'t be less than 1.'
      )
    } else {
      this.removeError('duration')
    }
  }

  handleChangeStartDate(date) {
    this.setState({ startDate: date[0] })
    this.checkDates()
  }

  handleChangeEndDate(date) {
    this.setState({ endDate: date[0] })
    this.checkDates()
  }

  convertDateStringToDate(dateStr) {
    return dateStr.toISOString().slice(0, 10)
  }

  checkDates() {
    if (
      !this.props.currentPlanGroup.duration &&
      this.state.startDate &&
      this.state.endDate
    ) {
      if (
        this.convertDateStringToDate(this.state.endDate) <
        this.convertDateStringToDate(this.state.startDate)
      ) {
        this.addError(
          'endDate',
          'End date of phase cannot be before start date'
        )
      } else {
        this.removeError('endDate')
      }
    }
  }

  handleClickNext() {
    if (!this.hasErrors()) {
      this.setState({ step: 2 })
    }
  }

  handleSubmitSave(event) {
    event.preventDefault()
    if (this.hasErrors()) {
      return
    }

    this.setState({ updating: true })

    let startDate = null
    if (this.state.startDate !== undefined && this.state.startDate !== null) {
      startDate = this.convertDateStringToDate(this.state.startDate)
    }

    let endDate = null
    if (this.state.endDate !== undefined && this.state.endDate !== null) {
      endDate = this.convertDateStringToDate(this.state.endDate)
    }

    const contentIds = {}
    let sortOrder = 1

    this.state.selectedContentIds.forEach((contentId) => {
      contentIds[contentId] = {
        id: contentId,
        sort_order: sortOrder,
      }
      sortOrder += 1
    })

    this.props
      .updatePlanGroup(
        this.props.match.params.organization_uuid,
        this.props.match.params.plan_uuid,
        this.props.match.params.phase_uuid,
        this.state.title,
        this.state.description,
        startDate,
        endDate,
        JSON.stringify(Object.values(contentIds)),
        this.state.duration
      )
      .then(() => {
        this.props.history.push(
          `/admin/${this.props.match.params.organization_uuid}/plans/${this.props.match.params.plan_uuid}`
        )
      })
  }

  handleDeleteClick(e) {
    e.preventDefault()

    this.setState({ confirmDelete: true })
  }

  handleDeleteConfirm(e) {
    e.preventDefault()

    this.props
      .deletePlanGroup(
        this.props.match.params.organization_uuid,
        this.props.match.params.plan_uuid,
        this.props.match.params.phase_uuid
      )
      .then(() => {
        this.setState({ confirmDelete: false })
        this.props.history.push(
          `/admin/${this.props.match.params.organization_uuid}/plans/${this.props.match.params.plan_uuid}`
        )
      })
  }

  handleDeleteCancel(e) {
    e.preventDefault()

    this.setState({ confirmDelete: false })
  }

  renderTitle(content) {
    if (content.type === 'coding_challenge') {
      return (
        <span>
          {`${settings.apps[content.metadata.app]}: ${content.title} (${
            settings.engines[content.metadata.language.name]
          } - ${settings.frameworks[content.metadata.framework.name]})`}
        </span>
      )
    }
    return content.title
  }

  render() {
    if (this.state.isLoading)
      return (
        <div className='text-center uk-padding-large'>
          <div data-uk-spinner='' />
        </div>
      )

    // render a loader if we are "updating" (i.e. saving the updated values)
    if (this.state.updating) {
      return (
        <OrganizationLayout
          {...this.props}
          active='plans'
          data-test-id='organization-plans-component'
        >
          <Loader visible />
        </OrganizationLayout>
      )
    }

    // render "delete a training phase" card if we are in that state
    if (this.state.confirmDelete) {
      return (
        <OrganizationLayout
          {...this.props}
          active='plans'
          data-test-id='organization-plans-component'
        >
          <div className='uk-container-medium'>
            <h1>Delete Training Phase</h1>
            <div className='uk-card uk-card-default uk-card-body'>
              <div className='uk-container-small'>
                <p>
                  Are you sure you want to delete the training phase "
                  {this.state.title}"?
                </p>
                <form className='uk-form-stacked'>
                  <div className='uk-margin'>
                    <div className='uk-form-controls'>
                      <button
                        type='button'
                        className='uk-button uk-button-danger tm-button-default uk-margin-right'
                        onClick={this.handleDeleteConfirm}
                      >
                        Delete
                      </button>
                      <button
                        type='button'
                        className='uk-button uk-button tm-button-default'
                        onClick={this.handleDeleteCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </OrganizationLayout>
      )
    }

    // render main screen if we aren't in any of the other special states
    return (
      <OrganizationLayout
        {...this.props}
        active='plans'
        data-test-id='organization-plans-component'
      >
        <div className='uk-container-medium'>
          <div>
            <div className='flex justify-between'>
              <h1>Edit Training Phase</h1>
              <a
                className='text-danger uk-margin-small-top'
                href='#'
                onClick={this.handleDeleteClick}
              >
                Delete Training Phase
              </a>
            </div>
            <p className=''>
              You can break up your training into different "phases". Choose
              which lessons you would like for the phase, and optionally set a
              timeframe for each phase.
            </p>
            {
              // render steps with an inline object
              {
                1: (
                  <>
                    <div className='uk-card uk-card-default uk-card-body'>
                      <div className='uk-container-medium'>
                        <form className='uk-form-stacked'>
                          <div className='uk-margin phase-input'>
                            <label className='uk-form-label'>
                              Phase title
                              <Icon
                                as='a'
                                href='#'
                                type='button'
                                name='information'
                                ratio={0.8}
                                data-uk-toggle='target: #modal-training-phase'
                                style={{ marginLeft: 8 }}
                              />
                            </label>
                            <div className='uk-form-controls'>
                              <input
                                className={`uk-input ${
                                  this.state.errors.title
                                    ? 'uk-form-danger'
                                    : ''
                                }`}
                                name='title'
                                type='text'
                                placeholder={this.state.errors.title}
                                value={this.state.title}
                                onChange={this.handleChangeTitle}
                              />
                            </div>
                            <div id='modal-training-phase' data-uk-modal>
                              <div className='uk-modal-dialog uk-modal-body'>
                                <h2>What is a Training Phase?</h2>
                                <p>
                                  You can break up your training into different
                                  "phases". Choose which lessons you would like
                                  for the phase and optionally set a timeframe
                                  for that phase.
                                </p>
                                <p className='text-right'>
                                  <button
                                    className='uk-button uk-button-primary uk-modal-close'
                                    type='button'
                                  >
                                    Ok
                                  </button>
                                </p>
                                <button
                                  className='uk-modal-close-outside'
                                  type='button'
                                  data-uk-close
                                />
                              </div>
                            </div>
                          </div>

                          <div className='uk-margin phase-input'>
                            <label className='uk-form-label'>
                              Phase description
                            </label>
                            <div className='uk-form-controls'>
                              <input
                                className='uk-input'
                                type='text'
                                placeholder=''
                                value={this.state.description}
                                onChange={this.handleChangeDescription}
                              />
                            </div>
                            <span className='text-sm text-muted'>
                              The description shows up on the learner's My Plan
                              dashboard
                            </span>
                          </div>

                          {!this.props.currentPlanGroup.duration ? (
                            <>
                              <div className='uk-margin phase-input'>
                                <label className='uk-form-label'>
                                  Phase start date
                                </label>
                                <div className='uk-form-controls'>
                                  <Flatpickr
                                    className='uk-input'
                                    value={this.state.startDate}
                                    onChange={this.handleChangeStartDate}
                                  />
                                </div>
                                <span className='text-sm text-muted'>
                                  The start date is used for training reminders
                                </span>
                              </div>

                              <div className='uk-margin phase-input'>
                                <label className='uk-form-label'>
                                  Phase end date
                                </label>
                                <div className='uk-form-controls'>
                                  <Flatpickr
                                    className={`uk-input ${
                                      this.state.errors.endDate
                                        ? 'uk-form-danger'
                                        : ''
                                    }`}
                                    value={this.state.endDate}
                                    onChange={this.handleChangeEndDate}
                                  />
                                </div>
                                <span className='text-sm text-muted'>
                                  {this.state.errors.endDate ||
                                    'The end date is used for training reminders'}
                                </span>
                              </div>
                            </>
                          ) : (
                            <div className='uk-margin phase-input'>
                              <label className='uk-form-label'>
                                Duration in days
                              </label>
                              <div className='uk-form-controls'>
                                <input
                                  data-test-id='input-phase-description'
                                  className={`uk-input ${
                                    this.state.errors.duration
                                      ? 'uk-form-danger'
                                      : ''
                                  }`}
                                  type='text'
                                  placeholder={this.state.errors.duration || ''}
                                  value={this.state.duration}
                                  onChange={this.handleChangeDuration}
                                />
                              </div>
                              <span className='text-sm text-muted'>
                                The number of days to complete this phase after
                                the learner is added to the training plan
                              </span>
                            </div>
                          )}
                          <div className='uk-margin'>
                            <div className='uk-form-controls uk-form-controls-text'>
                              <div className='uk-form-label'>
                                Choose content
                                <Icon
                                  name='information'
                                  ratio={0.85}
                                  uk-tooltip={
                                    'title: A course lesson takes about 15 – 20 minutes to complete. Challenges may take 30 minutes or more.; pos: top'
                                  }
                                  style={{ marginLeft: 8, cursor: 'pointer' }}
                                />
                              </div>
                              <PrimaryRadio
                                label='Lessons'
                                name='lessons'
                                checked={this.state.contentType === 'lessons'}
                                onChange={() =>
                                  this.setState({
                                    contentType: 'lessons',
                                  })
                                }
                              />
                              <PrimaryRadio
                                label='Coding Challenges'
                                name='coding_challenges'
                                checked={
                                  this.state.contentType === 'coding_challenges'
                                }
                                onChange={() =>
                                  this.setState({
                                    contentType: 'coding_challenges',
                                  })
                                }
                              />
                              <PrimaryRadio
                                label='Hacking Challenges'
                                name='hacking_challenges'
                                checked={
                                  this.state.contentType ===
                                  'hacking_challenges'
                                }
                                onChange={() =>
                                  this.setState({
                                    contentType: 'hacking_challenges',
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className='uk-margin'>
                            <div className='uk-form-controls'>
                              <div className='uk-form-label'>
                                {
                                  {
                                    lessons: 'Lessons',
                                    coding_challenges: 'Coding challenges',
                                    hacking_challenges: 'Hacking challenges',
                                  }[this.state.contentType]
                                }
                              </div>
                              <div className='uk-container-medium checkbox-container'>
                                <PhaseCheckbox
                                  selectedContentIds={
                                    this.state.selectedContentIds
                                  }
                                  selectedContentNames={
                                    this.state.selectedContentNames
                                  }
                                  onSelectedContentChange={
                                    this.setSelectedContentIds
                                  }
                                  getSelectedContentNames={
                                    this.setSelectedContentNames
                                  }
                                  contentType={this.state.contentType}
                                  content={this.props.content}
                                  defaultFilters={(
                                    this.props.currentPlanGroup.mainEngines ||
                                    []
                                  ).reduce((prev, { language }) => {
                                    prev[language] = true
                                    return prev
                                  }, {})}
                                />
                              </div>
                            </div>
                          </div>
                          {this.hasErrors() && (
                            <div className='uk-margin'>
                              <p className='text-danger'>
                                You must fix any errors before proceeding:
                              </p>
                              <ul className='text-danger'>
                                {Object.keys(this.state.errors).map((key) => (
                                  <li key={key}>{this.state.errors[key]}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                    <div className='flex justify-end uk-container-medium'>
                      <PrimaryButton
                        size='medium'
                        onClick={this.handleClickNext}
                        active={!this.hasErrors()}
                        label='Next: Organize Content'
                      />
                    </div>
                  </>
                ),
                2: (
                  <>
                    <div className='flex-col justify-center uk-margin-large uk-width-2xlarge'>
                      <div className='font-bold uk-margin-bottom'>
                        Organize Content
                      </div>
                      <LessonSorter
                        selectedContentNames={this.state.selectedContentNames}
                        selectedContentIds={this.state.selectedContentIds}
                        contentIdReorder={this.setSelectedContentIds}
                        contentNameReorder={this.setSelectedContentNames}
                      />
                    </div>
                    <div className='flex justify-between uk-width-2xlarge'>
                      <SecondaryButton
                        size='medium'
                        onClick={() => this.setState({ step: 1 })}
                        label='Previous'
                      />
                      <div>
                        <PrimaryButton
                          label='Cancel'
                          gray={true}
                          customCss={'mr-2'}
                          size='medium'
                          link_to={`/admin/${this.props.match.params.organization_uuid}/plans/${this.props.match.params.plan_uuid}`}
                        />

                        <PrimaryButton
                          size='medium'
                          onClick={this.handleSubmitSave}
                          label='Update Training Phase'
                        />
                      </div>
                    </div>
                  </>
                ),
              }[this.state.step]
            }
          </div>
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  // content
  content: state.content.content,

  // hacker
  organizations: values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,

  // plans
  currentPlanGroup: state.plan.currentPlanGroup,

  // user
  email: state.auth.user.email,
})

const mapDispatchToProps = (dispatch) => ({
  updatePlanGroup: (
    organizationId,
    planId,
    groupId,
    title,
    description,
    startDate,
    endDate,
    contentIds,
    duration
  ) =>
    dispatch(
      updatePlanGroup(
        organizationId,
        planId,
        groupId,
        title,
        description,
        startDate,
        endDate,
        contentIds,
        duration
      )
    ),
  getContent: (organizationUUID, includeAllOrganizationContent, callback) =>
    dispatch(
      getContent(organizationUUID, includeAllOrganizationContent, callback)
    ),
  deletePlanGroup: (organizationId, planId, groupId) =>
    dispatch(deletePlanGroup(organizationId, planId, groupId)),
  getPlanGroupById: (organizationId, planId, phaseId) =>
    dispatch(getPlanGroupById(organizationId, planId, phaseId)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsPlansPhaseEdit)
)
