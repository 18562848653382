import UIkit from 'uikit'
// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'

// team list
const GETTING_TEAMS = 'team/GETTING_TEAMS'
const RECEIVED_TEAMS = 'team/RECEIVED_TEAMS'
const RECEIVED_TEAMS_ERROR = 'team/RECEIVED_TEAMS_ERROR'

// create team
const CREATING_TEAM = 'team/CREATING_TEAM'
const CREATED_TEAM = 'team/CREATED_TEAM'
const CREATED_TEAM_ERROR = 'team/CREATED_TEAM_ERROR'

// get team by id
const GETTING_TEAM_BY_ID = 'team/GETTING_TEAM_BY_ID'
const RECEIVED_TEAM_BY_ID = 'team/RECEIVED_TEAM_BY_ID'
const RECEIVED_TEAM_BY_ID_ERROR = 'team/RECEIVED_TEAM_BY_ID_ERROR'
const GETTING_TEAM_USERS = 'team/GETTING_TEAM_USERS'
const RECEIVED_TEAM_USERS = 'team/RECEIVED_TEAM_USERS'
const RECEIVED_TEAM_PLAN_DETAIL = 'team/RECEIVED_TEAM_PLAN_DETAIL'

// update team by id
const UPDATING_TEAM_BY_ID = 'team/UPDATING_TEAM_BY_ID'
const UPDATED_TEAM_BY_ID = 'team/UPDATED_TEAM_BY_ID'
const UPDATED_TEAM_BY_ID_ERROR = 'team/UPDATED_TEAM_BY_ID_ERROR'
const UPDATE_MUST_PASS_CODE_TEAM = 'team/UPDATE_MUST_PASS_CODE_TEAM'

// delete team by id
const DELETING_TEAM_BY_ID = 'team/DELETING_TEAM_BY_ID'
const DELETED_TEAM_BY_ID = 'team/DELETED_TEAM_BY_ID'
const DELETED_TEAM_BY_ID_ERROR = 'team/DELETED_TEAM_BY_ID_ERROR'

// add hacker to team
const ADDING_HACKER_TO_TEAM = 'team/ADDING_HACKER_TO_TEAM'
const ADDED_HACKER_TO_TEAM = 'team/ADDED_HACKER_TO_TEAM'
const ADDED_HACKER_TO_TEAM_ERROR = 'team/ADDED_HACKER_TO_TEAM_ERROR'

// remove hacker from team
const DELETING_HACKER_FROM_TEAM = 'team/DELETING_HACKER_FROM_TEAM'
const DELETED_HACKER_FROM_TEAM = 'team/DELETED_HACKER_FROM_TEAM'
const DELETED_HACKER_FROM_TEAM_ERROR = 'team/DELETED_HACKER_FROM_TEAM_ERROR'

const LOAD_SELECTED_USER = 'team/LOAD_SELECTED_USER'
export const RECEIVED_SELECTED_USER_TEAMS = 'team/RECEIVED_SELECTED_USER_TEAMS'
const UPDATE_SELECTED_USER = 'team/UPDATE_SELECTED_USER'

const SET_TEAM_LIST = 'team/SET_TEAM_LIST'

const initialState = {
  // team list
  loadingTeams: false,
  loadingTeamsError: '',
  hasLoadedTeams: false,
  teams: [],

  // create team
  creatingTeam: false,
  creatingTeamError: '',

  // get team by id
  loadingTeamById: false,
  loadingTeamByIdError: '',
  loadingTeamsUsers: false,
  currentTeam: {
    uuid: null,
    name: null,
    organization: {
      uuid: null,
    },
    plan: {
      uuid: null,
      name: null,
    },
    users: [],
    created_at: null,
    updated_at: null,
  },
  teamPlanDetail: [],
  list: [],

  selectedUser: {
    email: '',
    username: '',
    last_seen: null,
    teams: 'loading',
  },

  // update team by id
  updatingTeamById: false,
  updatingTeamByIdError: '',

  // delete team by id
  deletingTeamById: false,
  deletingTeamByIdError: '',

  // add hacker to team
  addingHackerToTeam: false,
  addingHackerToTeamError: '',

  // remove hacker from team
  deletingHackerFromTeam: false,
  deletingHackerFromTeamError: '',
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TEAM_LIST:
      return {
        ...state,
        list: action.list,
      }
    case UPDATE_MUST_PASS_CODE_TEAM:
      return {
        ...state,
        currentTeam: {
          ...state.currentTeam,
          must_pass_coding_exercise: action.must_pass_coding_exercise,
        },
      }
    // team list
    case UPDATE_SELECTED_USER:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          teams: state.selectedUser.teams.map((item) => {
            if (item.team_uuid === action.teamId) {
              return {
                ...item,
                invite_privileges: action.invite_privileges,
              }
            }
            return item
          }),
        },
      }
    case RECEIVED_SELECTED_USER_TEAMS:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          ...action.hacker,
          teams: action.teams,
        },
      }
    case LOAD_SELECTED_USER:
      return {
        ...state,
        selectedUser: {
          email: '',
          username: '',
          last_seen: null,
          teams: 'loading',
        },
      }
    case GETTING_TEAMS:
      return {
        ...state,
        loadingTeams: true,
        loadingTeamsError: '',
      }
    case RECEIVED_TEAMS:
      return {
        ...state,
        loadingTeams: false,
        hasLoadedTeams: true,
        teams: action.teams,
      }
    case RECEIVED_TEAMS_ERROR:
      return {
        ...state,
        loadingTeams: false,
        loadingTeamsError: action.error,
      }

    // create team
    case CREATING_TEAM:
      return {
        ...state,
        creatingTeam: true,
        creatingTeamError: '',
      }
    case CREATED_TEAM:
      return {
        ...state,
        creatingTeam: false,
      }
    case CREATED_TEAM_ERROR:
      return {
        ...state,
        creatingTeam: false,
        creatingTeamError: action.error,
      }

    // get team by id
    case GETTING_TEAM_BY_ID:
      return {
        ...state,
        loadingTeamById: true,
        loadingTeamByIdError: '',
      }

    case RECEIVED_TEAM_PLAN_DETAIL:
      return {
        ...state,
        teamPlanDetail: action.teamPlanDetail,
      }

    case GETTING_TEAM_USERS:
      return {
        ...state,
        loadingTeamsUsers: true,
      }
    case RECEIVED_TEAM_USERS:
      return {
        ...state,
        loadingTeamsUsers: false,
        currentTeam: {
          ...state.currentTeam,
          users: action.users,
        },
      }
    case RECEIVED_TEAM_BY_ID:
      return {
        ...state,
        loadingTeamById: false,
        currentTeam: {
          ...state.currentTeam,
          ...action.team,
        },
      }
    case RECEIVED_TEAM_BY_ID_ERROR:
      return {
        ...state,
        loadingTeamById: false,
        loadingTeamByIdError: action.error,
      }

    // update team by id
    case UPDATING_TEAM_BY_ID:
      return {
        ...state,
        updatingTeamById: true,
        updatingTeamByIdError: '',
      }
    case UPDATED_TEAM_BY_ID:
      return {
        ...state,
        updatingTeamById: false,
      }
    case UPDATED_TEAM_BY_ID_ERROR:
      return {
        ...state,
        updatingTeamById: false,
        updatingTeamByIdError: action.error,
      }

    // add hacker to team
    case ADDING_HACKER_TO_TEAM:
      return {
        ...state,
        addingHackerToTeam: true,
        addingHackerToTeamError: '',
      }
    case ADDED_HACKER_TO_TEAM:
      return {
        ...state,
        addingHackerToTeam: false,
      }
    case ADDED_HACKER_TO_TEAM_ERROR:
      return {
        ...state,
        addingHackerToTeam: false,
        addingHackerToTeamError: action.error,
      }

    // remove hacker from team
    case DELETING_HACKER_FROM_TEAM:
      return {
        ...state,
        deletingHackerFromTeam: true,
        deletingHackerFromTeamError: '',
      }
    case DELETED_HACKER_FROM_TEAM:
      return {
        ...state,
        deletingHackerFromTeam: false,
      }
    case DELETED_HACKER_FROM_TEAM_ERROR:
      return {
        ...state,
        deletingHackerFromTeam: false,
        deletingHackerFromTeamError: action.error,
      }

    default:
      return state
  }
}

export function getTeams(organizationId) {
  return (dispatch) => {
    dispatch({
      type: GETTING_TEAMS,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team`,
      withAuthToken: true,
    })
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: RECEIVED_TEAMS_ERROR,
            error: response.data.error,
          })
          return false
        }
        dispatch({
          type: RECEIVED_TEAMS,
          teams: response.data,
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: RECEIVED_TEAMS_ERROR,
          error: 'Whoops! There was an error getting teams.',
        })
      })
  }
}

export function createTeam(
  organizationId,
  name,
  planId,
  mustPassCode,
  setingsOverride
) {
  return (dispatch) => {
    dispatch({
      type: CREATING_TEAM,
    })

    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team`,
      withAuthToken: true,
      data: {
        name,
        plan_uuid: planId,
        must_pass_code: mustPassCode,
        settings_override: setingsOverride,
      },
    })
      .then((response) => {
        if (response.data.error) {
          UIkit.notification({
            message:
              '<span data-test-id="test-failed-notification"></span> Team name already exists!<br /><div class="text-sm">You need to use a different team name.</div>',
            status: 'danger',
            pos: 'top-center',
          })
          return false
        }
        dispatch({
          type: CREATED_TEAM,
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: CREATED_TEAM_ERROR,
          error: 'Whoops! There was an error creating this team.',
        })
        return false
      })
  }
}

export function getTeamById(organizationId, teamId, disableLoader = false) {
  return (dispatch) => {
    if (!disableLoader) {
      dispatch({
        type: GETTING_TEAM_BY_ID,
      })
    }

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}`,
      withAuthToken: true,
    })
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: RECEIVED_TEAM_BY_ID_ERROR,
            error: response.data.error,
          })
          return false
        }

        let lessonsRemaining = response.data.chart.total_lessons
        const reportLessons = [
          { date: '', lessons: response.data.chart.total_lessons },
        ]
        response.data.chart.counts.map((day) => {
          lessonsRemaining -= day.lessons
          const d = {
            date: day.date,
            lessons: lessonsRemaining,
          }
          reportLessons.push(d)
        })
        dispatch({
          type: RECEIVED_TEAM_BY_ID,
          team: {
            ...response.data,
            reportLessons,
          },
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: RECEIVED_TEAM_BY_ID_ERROR,
          error: 'Whoops! There was an error getting this team.',
        })
        return false
      })
  }
}

export function getTeamUsers(organizationId, teamId, callback = null) {
  return (dispatch, getState) => {
    dispatch({
      type: GETTING_TEAM_USERS,
    })
    const state = getState()
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}/hackers`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: RECEIVED_TEAM_USERS,
          users: data.users,
        })
        if (callback && typeof callback === 'function') {
          callback(data.users, state.team.selectedUser.uuid)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function importTeamUsers(
  organizationId,
  sendOnboarding,
  teamId,
  users,
  callback
) {
  return (dispatch, getState) => {
    dispatch({
      type: GETTING_TEAM_USERS,
    })
    const state = getState()
    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}/hackers`,
      withAuthToken: true,
      data: {
        users,
        send_onboarding: state.team.currentTeam.has_invite_privileges
          ? sendOnboarding
          : false,
      },
    })
      .then(({ data }) => {
        callback(data)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getTeamPlanDetail(organizationId, teamId) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}/plan`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: RECEIVED_TEAM_PLAN_DETAIL,
          teamPlanDetail: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function updateTeamPlan(
  organizationId,
  teamId,
  planId,
  callback,
  removeTraining = null
) {
  return (dispatch) => {
    const data =
      removeTraining !== null
        ? {
            no_training: removeTraining,
          }
        : {
            plan_uuid: planId,
          }
    api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}`,
      withAuthToken: true,
      data,
    })
      .then(() => {
        dispatch(getTeamById(organizationId, teamId))
        dispatch(getTeamPlanDetail(organizationId, teamId))
        if (typeof callback === 'function') callback()
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function updateTeamMustPassCode(organizationId, teamId, mustPassCode) {
  return (dispatch) => {
    api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}`,
      withAuthToken: true,
      data: {
        must_pass_code: mustPassCode,
      },
    })
      .then(() => {
        dispatch({
          type: UPDATE_MUST_PASS_CODE_TEAM,
          must_pass_coding_exercise: mustPassCode === '' ? null : mustPassCode,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function updateTeamById(
  organizationId,
  teamId,
  name,
  planId,
  mustPassCode
) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_TEAM_BY_ID,
    })

    return api({
      method: 'put',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}`,
      withAuthToken: true,
      data: {
        name,
        plan_uuid: planId,
        must_pass_code: mustPassCode,
      },
    })
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: UPDATED_TEAM_BY_ID_ERROR,
            error: response.data.error,
          })
          return false
        }
        dispatch({
          type: UPDATED_TEAM_BY_ID,
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: UPDATED_TEAM_BY_ID_ERROR,
          error: 'Whoops! There was an error updating this team.',
        })
        return false
      })
  }
}

export function deleteTeamById(organizationId, teamId) {
  return (dispatch) => {
    dispatch({
      type: DELETING_TEAM_BY_ID,
    })

    return api({
      method: 'delete',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}`,
      withAuthToken: true,
    })
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: DELETED_TEAM_BY_ID_ERROR,
            error: response.data.error,
          })
          return false
        }
        dispatch({
          type: DELETED_TEAM_BY_ID,
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: DELETED_TEAM_BY_ID_ERROR,
          error: 'Whoops! There was an error deleting this team.',
        })
        return false
      })
  }
}

export function setSelectedUser(organizationId, hacker, localUpdate = false) {
  return (dispatch, getState) => {
    const state = getState()
    if (localUpdate) {
      return dispatch({
        type: RECEIVED_SELECTED_USER_TEAMS,
        teams: state.team.selectedUser.teams,
        hacker: {
          ...state.team.selectedUser,
          ...localUpdate,
        },
      })
    }
    dispatch({
      type: LOAD_SELECTED_USER,
    })
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hacker.uuid}/teams`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: RECEIVED_SELECTED_USER_TEAMS,
          teams: data,
          hacker,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getTeamsHacker(organizationId, teamId, hackerId) {
  return (dispatch) => {
    dispatch({
      type: ADDING_HACKER_TO_TEAM,
    })

    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationId}/hackers/${hackerId}/teams`,
      withAuthToken: true,
    })
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: ADDED_HACKER_TO_TEAM_ERROR,
            error: response.data.error,
          })
          return false
        }
        dispatch({
          type: ADDED_HACKER_TO_TEAM,
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: ADDED_HACKER_TO_TEAM_ERROR,
          error: 'Whoops! There was an error adding hacker to this team.',
        })
        return false
      })
  }
}

export function updateTeamPrivileges(
  organizationId,
  teamId,
  hackerId,
  privileges,
  isUserTab = false,
  callback = false
) {
  return (dispatch, getState) => {
    dispatch({
      type: ADDING_HACKER_TO_TEAM,
    })
    const state = getState()
    return api({
      method: 'put',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}/hacker/${hackerId}`,
      withAuthToken: true,
      data: privileges,
    })
      .then(({ data }) => {
        if (typeof callback === 'function') {
          callback()
        } else {
          if (
            privileges.can_invite_users === true ||
            privileges.can_invite_users === false
          ) {
            dispatch({
              type: UPDATE_SELECTED_USER,
              teamId,
              invite_privileges: privileges.can_invite_users,
            })
          }
          if (
            privileges.team_admin === true ||
            privileges.team_admin === false
          ) {
            dispatch({
              type: UPDATE_SELECTED_USER,
              teamId,
              team_role: privileges.team_admin ? 'team_admin' : 'member',
            })
            if (typeof callback === 'function') {
              callback()
            }
            if (!isUserTab) {
              dispatch(
                getTeamUsers(
                  organizationId,
                  state.team.currentTeam.uuid || teamId,
                  (users, userId) => {
                    users.some((user) => {
                      if (user.uuid === userId) {
                        dispatch(setSelectedUser(organizationId, user))
                        return true
                      }
                      return false
                    })
                  }
                )
              )
            }
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function removeHackerFromTeam(organizationId, teamId, hackerId) {
  return (dispatch) => {
    dispatch({
      type: DELETING_HACKER_FROM_TEAM,
    })

    return api({
      method: 'delete',
      url: `${settings.urls.hacker}/organizations/${organizationId}/team/${teamId}/hacker/${hackerId}`,
      withAuthToken: true,
    })
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: DELETED_HACKER_FROM_TEAM_ERROR,
            error: response.data.error,
          })
          return false
        }
        dispatch({
          type: DELETED_HACKER_FROM_TEAM,
        })
        return response.data.uuid
      })
      .catch((error) => {
        console.error(error)
        dispatch({
          type: DELETED_HACKER_FROM_TEAM_ERROR,
          error: 'Whoops! There was an error removing hacker from this team.',
        })
        return false
      })
  }
}

export function getSimpleTeamList(organizationUUID) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/team_list`,
      withAuthToken: true,
    })
      .then(({ data }) => {
        dispatch({
          type: SET_TEAM_LIST,
          list: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
