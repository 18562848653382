import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'
import UIKit from 'uikit'
import { getLicenseContent } from 'app/state/modules/content'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import settings from 'settings'

const propTypes = {
  style: PropTypes.object,
  user: PropTypes.object,
  className: PropTypes.string,
}

const defaultProps = {
  style: {},
  user: {},
  className: '',
}

class LicenseTag extends React.Component {
  constructor(props) {
    super(props)
    this.licenses = this.licenses.bind(this)
  }

  generateModal(user, licenses) {
    const hasMultipleLicenses = licenses.length > 1
    return (
      <div className='uk-padding'>
        <h2 className='text-center'>{`${user.license_type} License`}</h2>
        {hasMultipleLicenses && (
          <ul uk-tab='' className='justify-center'>
            {user.licenses.map((license, i) => (
              <li key={`${license.uuid}-${i}`}>
                <a href='#'>{license.license_type}</a>
              </li>
            ))}
          </ul>
        )}
        <ul
          className={`${
            hasMultipleLicenses
              ? 'uk-switcher '
              : 'uk-nav uk-nav-default uk-width-medium '
          }uk-margin`}
        >
          {licenses.map((data, ind) => {
            return (
              <li key={ind}>
                {Object.keys(data).length > 0 &&
                  Object.keys(data).map((contentKey) => {
                    if (contentKey === 'courses') {
                      return (
                        <div key={contentKey}>
                          <span className='capitalize text-emphasis'>
                            {contentKey}
                          </span>
                          {data[contentKey].map((course) => {
                            return (
                              <React.Fragment key={course.uuid}>
                                <ul className='uk-nav uk-nav-default'>
                                  <li
                                    className='uk-disabled uk-active text-emphasis'
                                    style={{ marginLeft: 10 }}
                                  >
                                    {course.title}
                                  </li>
                                  {course.items.map((item) => {
                                    if (item.item_type === 'lesson') {
                                      return (
                                        <React.Fragment key={item.uuid}>
                                          <li
                                            className='uk-disabled'
                                            key={item.uuid}
                                          >
                                            <a style={{ paddingLeft: 20 }}>
                                              <i>
                                                <u>{`${item.title} Lesson`}</u>
                                              </i>
                                            </a>
                                          </li>
                                          {item.content.map((itm) => (
                                            <li
                                              className='uk-margin-small-left uk-disabled'
                                              key={itm.uuid}
                                            >
                                              <a style={{ paddingLeft: 30 }}>
                                                {itm.title}
                                              </a>
                                            </li>
                                          ))}
                                        </React.Fragment>
                                      )
                                    }
                                    return (
                                      <li
                                        className='uk-disabled'
                                        key={item.uuid}
                                      >
                                        <a
                                          id={item.uuid}
                                          data-course-uuid={course.uuid}
                                          style={{ paddingLeft: 20 }}
                                        >
                                          {item.title}
                                        </a>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      )
                    }
                    if (contentKey === 'code_reviews') {
                      return (
                        <div key={contentKey}>
                          <span className='capitalize text-emphasis'>
                            Coding Challenges
                          </span>
                          <ul className='uk-nav uk-nav-default uk-width-medium'>
                            {data[contentKey].map((item) => (
                              <React.Fragment key={item.language_name}>
                                <li className='uk-disabled'>
                                  <a
                                    style={{
                                      paddingLeft: 20,
                                    }}
                                  >
                                    {settings.engines[item.language_name]}
                                  </a>
                                </li>
                                {item.frameworks.map((framework) => (
                                  <React.Fragment key={framework.name}>
                                    <li className='uk-disabled uk-margin-small-left'>
                                      <a
                                        style={{
                                          paddingLeft: 20,
                                        }}
                                      >
                                        {settings.frameworks[framework.name]}
                                      </a>
                                    </li>
                                    {framework.apps.map((app) => (
                                      <React.Fragment
                                        key={`${item.language_name}-${framework.name}-${app.name}`}
                                      >
                                        <li className='uk-disabled uk-margin-medium-left'>
                                          <a>{settings.apps[app.name]}</a>
                                        </li>
                                        {app.content.map((content) => (
                                          <li
                                            className='uk-disabled uk-margin-large-left'
                                            key={`${item.language_name}-${framework.name}-${app.name}-${content.uuid}`}
                                          >
                                            <a>{content.title}</a>
                                          </li>
                                        ))}
                                      </React.Fragment>
                                    ))}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ))}
                          </ul>
                        </div>
                      )
                    }
                    return (
                      <div key={contentKey}>
                        <span className='capitalize text-emphasis'>
                          {contentKey}
                        </span>
                        <ul className='uk-nav uk-nav-default uk-width-medium'>
                          {data[contentKey].map((item) => (
                            <li className='uk-disabled' key={item.uuid}>
                              <a id={item.uuid} style={{ paddingLeft: 20 }}>
                                {item.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                  })}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  async licenses(user) {
    const promises = user.licenses.map(
      (license) =>
        new Promise((resolve, reject) => {
          this.props
            .getLicenseContent(
              this.props.match.params.organization_uuid,
              license.uuid || ''
            )
            .then(({ data }) => {
              resolve(data)
            })
            .catch((e) => {
              console.error(e)
              reject(e)
            })
        })
    )

    const responses = await Promise.all(promises)
    const modalContent = this.generateModal(user, responses)
    UIKit.modal.dialog(renderToStaticMarkup(modalContent), { stack: true })
  }

  renderLicense(user) {
    if (user.license_type === 'EXPIRED') {
      return (
        <span
          onClick={() => {}}
          style={{ ...this.props.style, cursor: 'pointer' }}
          className={`uk-label uk-label-danger ${this.props.className}`}
        >
          Expired
        </span>
      )
    }
    if (user.has_license) {
      return (
        <span
          onClick={() => this.licenses(user)}
          style={{ ...this.props.style, cursor: 'pointer' }}
          className={`uk-label uk-label-success ${this.props.className}`}
        >
          {user.license_type}
        </span>
      )
    }
    return (
      <span
        className={`uk-label uk-label-danger ${this.props.className}`}
        style={{ ...this.props.style }}
      >
        No license
      </span>
    )
  }

  render() {
    return <>{this.renderLicense(this.props.user)}</>
  }
}

LicenseTag.propTypes = propTypes
LicenseTag.defaultProps = defaultProps

export default withRouter(
  connect(null, {
    getLicenseContent,
  })(LicenseTag)
)
