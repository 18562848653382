import React, { type ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

export const SplashCard = (props: Props) => (
  <div className="flex flex-col w-full p-8 bg-white shadow-md box-border">
    {props.children}
  </div>
)
