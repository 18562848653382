/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @ts-nocheck
import React from 'react'
import UIkit from 'uikit'


export interface ModalProps {
  id: string
}


class Modal extends React.Component<ModalProps> {
  constructor(props: ModalProps) {
    super(props)
    this.hide = this.hide.bind(this)
    this.handleOnClickOverlay = this.handleOnClickOverlay.bind(this)
  }

  componentDidMount() {
    this.modal = UIkit.modal(`#${this.props.id}`, this.props.options || {})
    if (this.props.showOnStart) {
      this.modal.show()
    }
    if (this.props.activeListeners) {
      UIkit.util.on(`#${this.props.id}`, 'beforeshow', () => {
        if (this.props.beforeShow) {
          this.props.beforeShow()
        }
      })
      UIkit.util.on(`#${this.props.id}`, 'beforehide', () => {
        if (this.props.beforeHide) {
          this.props.beforeHide()
        }
      })
      UIkit.util.on(`#${this.props.id}`, 'hide', () => {
        if (this.props.afterHide) {
          this.props.afterHide()
        }
      })
      UIkit.util.on(`#${this.props.id}`, 'shown', () => {
        if (this.props.afterShown) {
          this.props.afterShown()
        }
      })
    }
  }

  destroy() {
    UIkit.modal(`#${this.props.id}`).$destroy(true)
  }

  show() {
    this.modal.show()
    const elem = document.getElementById(`scrollable-${this.props.id}`)
    if (elem) {
      elem.scrollTop = 0
      elem.scrollLeft = 0
    }
  }

  hide(clickCancel) {
    if (this.props.reloadOnConfim) {
      window.location.reload()
    }
    this.modal.hide()
    if (clickCancel && this.props.onHideCancel) {
      this.props.onHideCancel()
    }
    if (this.props.onHide) {
      this.props.onHide()
    }
  }

  handleOnClickOverlay() {
    if (this.props.handleOnClickOverlay) return this.props.handleOnClickOverlay()
    if (this.props.closeOnClickOverlay) {
      return this.hide()
    }
    return null
  }

  render() {
    const { noStack } = this.props
    let className = 'uk-modal'
    if (this.props.modalContainer) {
      className = 'uk-modal uk-modal-container'
    }
    if (this.props.isFullScreen) {
      className = 'uk-modal-full'
    }

    const canClose = this.props.canClose || this.props.closeOnClickOverlay
    const closeValue = canClose ? 'true' : 'false'
    const stackValue = noStack ? 'false' : 'true'
    const modalOptions = `bg-close: ${closeValue}; esc-close: ${closeValue}; stack: ${stackValue};`

    return (
      <div
        onClick={this.handleOnClickOverlay}
        data-test-id={this.props['data-test-id']}
        id={this.props.id}
        className={className}
        data-uk-modal={modalOptions}
        style={{
          // borderRadius: 20
        }}
      >
        <div
          id={`scrollable-${this.props.id}`}
          className={`uk-modal-dialog uk-modal-body${this.props.width ? ` ${this.props.width}` : ''}${this.props.centered ? ' uk-margin-auto-vertical' : ''}${this.props.noPadding ? ' uk-padding-remove' : ''}`}
          style={{
            borderRadius: 20,
            ...this.props.style || {}
          }}
          uk-overflow-auto={this.props.noOverflow ? null : ''}
          onClick={(e) => e.stopPropagation()}
        >
          {(!this.props.notTitle || (this.props.title)) && (
            <div className={this.props.titleContainerClass}>
              <h3 className="uk-padding-remove uk-margin-remove">
                {this.props.title}
              </h3>
              {Boolean(this.props.titleInfo) && (<span>{this.props.titleInfo}</span>)}
            </div>
          )}
          <>
            {this.props.body}
          </>
          {!this.props.noButtons && (
            <p className="uk-text-right">
              <button
                className={`uk-button ${this.props.buttonType ? this.props.buttonType : 'uk-button-default'} uk-margin-remove-bottom uk-modal-close${this.props.cancelLeft ? ' uk-align-left' : ''}`}
                onClick={this.hide.bind(this, true)}
                type="button"
              >
                {this.props.closeText || 'Cancel'}
              </button>
              {this.props.buttons}
            </p>
          )}
        </div>
        {this.props.overlayElements}
      </div>
    )
  }
}


export { Modal }
