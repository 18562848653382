import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { HBox, Box } from 'app/views/core/Box'
import { VScroll } from 'app/views/core/Scroll'

import { parseSubmissionDetails } from '../TestOverview/util'
import TestSubmissionList from '../TestOverview/TestSubmissionList'
import TestSubmissionDetails from '../TestOverview/TestSubmissionDetails'

import CodeReviewRestoreModal from './CodeReviewRestoreModal'

import { connect } from 'react-redux'
import * as sbcrsubmission from 'app/state/modules/sbcrsubmission'

function parseCodeReviewSubmissionDetails(submission) {
  // Ordinary lessons have a set of tests defined in the db's 'test' table, so we build
  // the list of test stages procedurally and identify a failed by its name (e.g.
  // '3-sqli-login-sqli-chars') to know which stage the failure occurred at. Coding
  // Challenges don't have data-driven tests; their tests just resolve to one of three
  // magic strings: syntax_error, functionality_broken, or vulnerability_not_fixed.
  // Since this may very well be temporary, we'll just hardcode these three stages and
  // then modify our submission details to use those error strings in place of the
  // actual test names, so that we can reuse the existing (newer) UI code as-is.
  let details = parseSubmissionDetails(submission)
  if (details.failedStage) {
    if (
      [
        'syntax_error',
        'functionality_broken',
        'vulnerability_not_fixed',
      ].includes(details.failedStage.errorMessage)
    ) {
      details.failedStage.name = details.failedStage.errorMessage
    }
  }
  return details
}

function CodeReviewSubmissionOverview(props) {
  const {
    hackerSubmissions,
    svcSubmissions,
    svcHasPendingSubmission,
    revertToSubmission,
  } = props
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(-1)

  // If using submission data from svc-code-sub, conform our CodeSubmission objects to
  // the format used by these UI components
  const { engUseSvcCodeSub } = useFlags()
  let submissions = hackerSubmissions
  if (engUseSvcCodeSub) {
    submissions = []
    if (svcHasPendingSubmission) {
      submissions.push({
        id: 0,
        submitted_at: new Date().toISOString(),
        engine: 'app',
        code: '',
        test: {},
      })
    }
    for (const s of svcSubmissions.sort(
      (a, b) => b.submissionTime.getTime() - a.submissionTime.getTime()
    )) {
      submissions.push({
        id: -s.submissionTime.getTime(),
        submitted_at: s.submissionTime.toISOString(),
        engine: s.volumeName,
        code: s.replCode || '',
        test: {
          passed: s.result.status === 'passed',
          name:
            s.result.status === 'failed' ? s.result.errorMessage : undefined,
        },
      })
    }
  }

  // When we get a new code submission, automatically select it
  useEffect(() => {
    if (submissions.length > 0) {
      setSelectedSubmissionId(submissions[0].id)
    }
  }, [JSON.stringify(submissions.map((x) => x.id))])

  // Allow the user to click a button in the detail view to restore the selected code
  // submission to the sandbox, with a confirmation prompt
  const [wantsToRestore, setWantsToRestore] = useState(false)

  const selectedSubmission =
    selectedSubmissionId === -1
      ? null
      : submissions.find((x) => x.id === selectedSubmissionId)
  const details = selectedSubmission
    ? parseCodeReviewSubmissionDetails(selectedSubmission)
    : null

  // If using svc-code-sub and we encountered an error in the codesub module, surface
  // it here
  const { svcError } = props
  if (engUseSvcCodeSub && svcError) {
    return <div className='text-danger p-4'>{svcError}</div>
  }

  return submissions.length === 0 ? (
    <Box fillParent style={{ padding: 20 }}>
      <h3 className='uk-margin-remove-bottom'>Test Submissions</h3>
      <span className='text-sm'>No tests have been submitted.</span>
    </Box>
  ) : (
    <HBox fillParent>
      <Box fixed={250}>
        <VScroll>
          <TestSubmissionList
            submissions={submissions}
            selectedSubmissionId={selectedSubmissionId}
            onSelect={setSelectedSubmissionId}
          />
        </VScroll>
      </Box>
      <Box grow>
        <VScroll>
          {details && (
            <TestSubmissionDetails
              stages={[
                {
                  name: 'syntax_error',
                  title: 'Syntax Check / 500 Errors',
                  description:
                    'Does your code build and run without errors? Note that syntax errors will not affect your score.',
                },
                {
                  name: 'functionality_broken',
                  title: 'Functionality Check',
                  description:
                    'Did your changes break the existing functionality of the app?',
                },
                {
                  name: 'vulnerability_not_fixed',
                  title: 'Vulnerability Check',
                  description: 'Does your code fix the vulnerability?',
                },
              ]}
              {...details}
            />
          )}
        </VScroll>
        {details && wantsToRestore && (
          <CodeReviewRestoreModal
            submittedAt={details.submittedAt}
            onClose={() => setWantsToRestore(false)}
            onConfirm={() => {
              setWantsToRestore(false)
              revertToSubmission(details.submissionId)
            }}
          />
        )}
      </Box>
    </HBox>
  )
}
CodeReviewSubmissionOverview.propTypes = {
  hackerSubmissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  svcSubmissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  svcError: PropTypes.string.isRequired,
  revertToSubmission: PropTypes.func.isRequired,
}

export default connect(
  (state) => {
    const shouldShowSubmission = (submission) =>
      submission.test.passed || submission.test.name || !submission.stale
    return {
      hackerSubmissions:
        state.sbcrsubmission.submissions.filter(shouldShowSubmission),
      svcSubmissions: state.codesub.submissions,
      svcError: state.codesub.error,
      svcHasPendingSubmission: state.codesub.mode === 'waiting',
    }
  },
  (dispatch) => ({
    revertToSubmission: (submissionId) =>
      dispatch(sbcrsubmission.revertToSubmission(submissionId)),
  })
)(CodeReviewSubmissionOverview)
