import 'flatpickr/dist/themes/material_blue.css'

import React from 'react'
import Flatpickr from 'react-flatpickr'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { values } from 'lodash'
import UIkit from 'uikit'
import { Link } from 'react-router-dom'

// Components
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import Icon from 'app/views/components/Icon'
import PhaseCheckbox from 'app/views/components/PhaseCheckbox'
import LessonSorter from 'app/views/components/LessonSorter'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'
import SecondaryButton from 'app/views/components/Buttons/SecondaryButton'

// Redux
import { getContent } from 'app/state/modules/content'
import { addPlanGroup, getPlanById } from 'app/state/modules/plan'

// Utils
import analytics from 'app/views/utils/analytics'

class OrganizationsPlansPhaseAdd extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: { title: 'Title is required' },
      title: '',
      description: '',
      startDate: null,
      endDate: null,
      duration: '',
      isLoading: true,
      contentType: 'lessons',
      selectedContentIds: [],
      selectedContentNames: [],
      step: 1,
    }

    this.handleChangeTitle = this.handleChangeTitle.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this)
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this)
    this.handleSubmitSave = this.handleSubmitSave.bind(this)
    this.handleChangeDuration = this.handleChangeDuration.bind(this)
    this.setSelectedContentIds = this.setSelectedContentIds.bind(this)
    this.setSelectedContentNames = this.setSelectedContentNames.bind(this)
    this.activateDragListeners = this.activateDragListeners.bind(this)
    this.handleClickNext = this.handleClickNext.bind(this)
    this.addError = this.addError.bind(this)
    this.removeError = this.removeError.bind(this)
    this.hasErrors = this.hasErrors.bind(this)
    this.checkDates = this.checkDates.bind(this)
    this.getCurrentPlan = this.getCurrentPlan.bind(this)

    const currentPlan = this.getCurrentPlan()
    if (currentPlan && currentPlan.is_time_based) {
      this.state.errors.duration =
        'Duration is required and can\'t be less than 1.'
    }
  }

  setSelectedContentIds(contentIds) {
    this.setState({
      selectedContentIds: contentIds,
    })
  }

  setSelectedContentNames(contentNames) {
    this.setState({
      selectedContentNames: contentNames,
    })
  }

  componentDidMount() {
    this.props.getPlanById(
      this.props.match.params.organization_uuid,
      this.props.match.params.plan_uuid
    )
    this.props.getContent(this.props.match.params.organization_uuid, true, () =>
      this.setState({ isLoading: false })
    )

    analytics.page('page-organizations-plans-phase-add')
    if (this.props.content.courses.length > 0) {
      setTimeout(() => {
        this.activateDragListeners()
      }, 500)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.content.courses.length === 0 &&
      this.props.content.courses.length > 0
    ) {
      setTimeout(() => {
        this.activateDragListeners()
      }, 500)
    }
  }

  activateDragListeners() {
    UIkit.util.on('#content-list', 'added', (x) => {
      x.target.children.forEach((child) => {
        if (child.dataset.type === 'coding_challenge' && child.children[0]) {
          child.children[0].innerHTML = child.dataset.selectedName
        }
        if (child.dataset.type === 'lesson' || child.dataset.type === 'app') {
          JSON.parse(child.dataset.contentUuids).forEach((contentUUID) => {
            const element = document.getElementById(`${contentUUID}`)
            let existsElement = false
            for (let el of document
              .getElementById('content-list')
              .getElementsByTagName('li')) {
              if (el.id === contentUUID) {
                existsElement = true
              }
            }
            if (!existsElement) {
              element.parentNode.removeChild(element)
              document
                .getElementById('content-list')
                .insertAdjacentElement('beforeend', element)
              if (element.dataset.selectedName) {
                element.innerHTML = element.dataset.selectedName
              }
            }
          })
        }
      })
    })
  }

  getCurrentPlan() {
    return this.props.planMap[this.props.match.params.plan_uuid]
  }

  addError(input, message) {
    const newErrs = { ...this.state.errors }
    newErrs[input] = message
    this.setState({ errors: newErrs })
  }

  removeError(input) {
    const newErrs = { ...this.state.errors }
    delete newErrs[input]
    this.setState({ errors: newErrs })
  }

  hasErrors() {
    return Object.keys(this.state.errors).length > 0
  }

  handleChangeTitle(event) {
    const titleVal = event.target.value

    this.setState({ title: titleVal })
    if (!titleVal) {
      this.addError('title', 'Title is required.')
    } else {
      this.removeError('title')
    }
  }

  handleChangeDescription(event) {
    this.setState({ description: event.target.value })
  }

  handleChangeDuration(event) {
    const durationVal = event.target.value.replace(/[^0-9]+/g, '')
    this.setState({ duration: durationVal })
    if (
      durationVal === '' ||
      isNaN(parseInt(durationVal, 10)) ||
      parseInt(durationVal, 10) < 1
    ) {
      this.addError(
        'duration',
        'Duration is required and can\'t be less than 1.'
      )
    } else {
      this.removeError('duration')
    }
  }

  handleChangeStartDate(date) {
    this.setState({ startDate: date[0] })
    this.checkDates()
  }

  handleChangeEndDate(date) {
    this.setState({ endDate: date[0] })
    this.checkDates()
  }

  convertDateStringToDate(dateStr) {
    return dateStr.toISOString().slice(0, 10)
  }

  checkDates() {
    if (
      !this.getCurrentPlan().is_time_based &&
      this.state.startDate &&
      this.state.endDate
    ) {
      if (
        this.convertDateStringToDate(this.state.endDate) <
        this.convertDateStringToDate(this.state.startDate)
      ) {
        this.addError(
          'endDate',
          'End date of phase cannot be before start date'
        )
      } else {
        this.removeError('endDate')
      }
    }
  }

  handleClickNext() {
    if (!this.hasErrors()) {
      this.setState({ step: 2 })
    }
  }

  handleSubmitSave(event) {
    event.preventDefault()
    if (this.hasErrors()) {
      return
    }

    if (this.getCurrentPlan().groups.length === 0) {
      UIkit.modal('#modal-plan-created').show()
    }

    let startDate = null
    if (this.state.startDate !== undefined && this.state.startDate !== null) {
      startDate = this.convertDateStringToDate(this.state.startDate)
    }

    let endDate = null
    if (this.state.endDate !== undefined && this.state.endDate !== null) {
      endDate = this.convertDateStringToDate(this.state.endDate)
    }

    const contentIds = {}
    let sortOrder = 1

    this.state.selectedContentIds.forEach((contentId) => {
      contentIds[contentId] = {
        id: contentId,
        sort_order: sortOrder,
      }
      sortOrder += 1
    })

    this.props
      .addPlanGroup(
        this.props.match.params.organization_uuid,
        this.props.match.params.plan_uuid,
        this.state.title,
        this.state.description,
        startDate,
        endDate,
        JSON.stringify(Object.values(contentIds)),
        this.state.duration
      )
      .then(() => {
        this.props.history.push(
          `/admin/${this.props.match.params.organization_uuid}/plans/${this.props.match.params.plan_uuid}`
        )
      })
  }

  render() {
    if (!this.props.planMap || !this.getCurrentPlan() || this.state.isLoading)
      return (
        <div className='text-center uk-padding-large'>
          <div data-uk-spinner='' />
        </div>
      )
    const currentPlan = this.getCurrentPlan()

    return (
      <OrganizationLayout
        {...this.props}
        active='plans'
        data-test-id='organization-plans-component'
      >
        <div className='uk-container-medium'>
          <h1>Add Training Phase</h1>
          <p className=''>
            You can break up your training into different "phases". Choose which
            lessons you would like for the phase, and optionally set a timeframe
            for each phase.
          </p>
          {
            // render steps with an inline object
            {
              1: (
                <>
                  <div className='uk-card uk-card-default uk-card-body'>
                    <div className='uk-container-medium'>
                      <form className='uk-form-stacked'>
                        <div className='uk-margin phase-input'>
                          <label className='uk-form-label'>
                            Phase title
                            <Icon
                              as='a'
                              href='#'
                              type='button'
                              name='information'
                              ratio={0.8}
                              data-uk-toggle='target: #modal-training-phase'
                              style={{ marginLeft: 8 }}
                            />
                          </label>
                          <div className='uk-form-controls'>
                            <input
                              data-test-id='input-phase-title'
                              className={`uk-input ${
                                this.state.errors.title ? 'uk-form-danger' : ''
                              }`}
                              name='title'
                              type='text'
                              placeholder={this.state.errors.title}
                              value={this.state.title}
                              onChange={this.handleChangeTitle}
                            />
                          </div>
                          <div id='modal-training-phase' data-uk-modal>
                            <div className='uk-modal-dialog uk-modal-body'>
                              <h2>What is a Training Phase?</h2>
                              <p>
                                You can break up your training into different
                                "phases". Choose which lessons you would like
                                for the phase and optionally set a timeframe for
                                that phase.
                              </p>
                              <p className='text-right'>
                                <button
                                  className='uk-button uk-button-primary uk-modal-close'
                                  type='button'
                                >
                                  Okay
                                </button>
                              </p>
                              <button
                                className='uk-modal-close-outside'
                                type='button'
                                data-uk-close
                              />
                            </div>
                          </div>
                        </div>
                        <div className='uk-margin phase-input'>
                          <label className='uk-form-label'>
                            Phase description
                          </label>
                          <div className='uk-form-controls'>
                            <input
                              data-test-id='input-phase-description'
                              className='uk-input'
                              type='text'
                              placeholder=''
                              value={this.state.description}
                              onChange={this.handleChangeDescription}
                            />
                          </div>
                          <span className='text-sm text-muted'>
                            The description shows up on the learner's My Plan
                            dashboard
                          </span>
                        </div>
                        {currentPlan.is_time_based === false ? (
                          <>
                            <div className='uk-margin phase-input'>
                              <label className='uk-form-label'>
                                Phase start date
                              </label>
                              <div className='uk-form-controls'>
                                <Flatpickr
                                  className='uk-input'
                                  value={this.state.startDate}
                                  onChange={this.handleChangeStartDate}
                                />
                              </div>
                              <span className='text-sm text-muted'>
                                The start date is used for training reminders
                              </span>
                            </div>

                            <div className='uk-margin phase-input'>
                              <label className='uk-form-label'>
                                Phase end date
                              </label>
                              <div className='uk-form-controls'>
                                <Flatpickr
                                  className={`uk-input ${
                                    this.state.errors.endDate
                                      ? 'uk-form-danger'
                                      : ''
                                  }`}
                                  value={this.state.endDate}
                                  onChange={this.handleChangeEndDate}
                                />
                              </div>
                              <span className='text-sm text-muted'>
                                {this.state.errors.endDate ||
                                  'The end date is used for training reminders'}
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className='uk-margin phase-input'>
                            <label className='uk-form-label'>
                              Duration in days
                            </label>
                            <div className='uk-form-controls'>
                              <input
                                data-test-id='input-phase-description'
                                className={`uk-input ${
                                  this.state.errors.duration
                                    ? 'uk-form-danger'
                                    : ''
                                }`}
                                type='text'
                                placeholder={this.state.errors.duration || ''}
                                value={this.state.duration}
                                onChange={this.handleChangeDuration}
                              />
                            </div>
                            <span className='text-sm text-muted'>
                              The number of days to complete this phase after
                              the learner is added to the training plan
                            </span>
                          </div>
                        )}
                        <div className='uk-margin'>
                          <div className='uk-form-controls uk-form-controls-text'>
                            <div className='uk-form-label'>
                              Choose content
                              <Icon
                                name='information'
                                ratio={0.85}
                                uk-tooltip={
                                  'title: A course lesson takes about 15 – 20 minutes to complete. Challenges may take 30 minutes or more.; pos: top'
                                }
                                style={{ marginLeft: 8, cursor: 'pointer' }}
                              />
                            </div>
                            <label className='text-sm'>
                              <input
                                className='uk-radio uk-margin-small-right'
                                type='radio'
                                onChange={() =>
                                  this.setState({
                                    contentType: 'lessons',
                                  })
                                }
                                name='lessons'
                                checked={this.state.contentType === 'lessons'}
                              />
                              Lessons
                            </label>
                            <br />
                            <label className='text-sm'>
                              <input
                                onChange={() =>
                                  this.setState({
                                    contentType: 'coding_challenges',
                                  })
                                }
                                checked={
                                  this.state.contentType === 'coding_challenges'
                                }
                                className='uk-radio uk-margin-small-right'
                                type='radio'
                                name='coding_challenges'
                              />
                              Coding Challenges
                            </label>
                            <br />
                            <label className='text-sm'>
                              <input
                                onChange={() =>
                                  this.setState({
                                    contentType: 'hacking_challenges',
                                  })
                                }
                                checked={
                                  this.state.contentType ===
                                  'hacking_challenges'
                                }
                                className='uk-radio uk-margin-small-right'
                                type='radio'
                                name='hacking_challenges'
                              />
                              Hacking Challenges
                            </label>
                          </div>
                        </div>

                        <div className='uk-margin'>
                          <div className='uk-form-controls'>
                            <div className='uk-form-label'>
                              {
                                {
                                  lessons: 'Lessons',
                                  coding_challenges: 'Coding challenges',
                                  hacking_challenges: 'Hacking challenges',
                                }[this.state.contentType]
                              }
                            </div>
                            <div className='uk-container-medium checkbox-container'>
                              <PhaseCheckbox
                                selectedContentIds={
                                  this.state.selectedContentIds
                                }
                                selectedContentNames={
                                  this.state.selectedContentNames
                                }
                                onSelectedContentChange={
                                  this.setSelectedContentIds
                                }
                                getSelectedContentNames={
                                  this.setSelectedContentNames
                                }
                                contentType={this.state.contentType}
                                content={this.props.content}
                                defaultFilters={currentPlan.mainEngines.reduce(
                                  (prev, { language }) => {
                                    prev[language] = true
                                    return prev
                                  },
                                  {}
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        {this.hasErrors() && (
                          <div className='uk-margin'>
                            <p className='text-danger'>
                              You must fix any errors before proceeding:
                            </p>
                            <ul className='text-danger'>
                              {Object.keys(this.state.errors).map((key) => (
                                <li key={key}>{this.state.errors[key]}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                  <div className='flex justify-end uk-container-medium'>
                    <PrimaryButton
                      onClick={this.handleClickNext}
                      active={!this.hasErrors()}
                      size='medium'
                      label='Next: Organize Content'
                    />
                  </div>
                </>
              ),
              2: (
                <>
                  <div className='flex-col justify-center uk-margin-large'>
                    <div className='font-bold uk-margin-bottom'>
                      Organize Content
                    </div>
                    <LessonSorter
                      selectedContentNames={this.state.selectedContentNames}
                      selectedContentIds={this.state.selectedContentIds}
                      contentIdReorder={this.setSelectedContentIds}
                      contentNameReorder={this.setSelectedContentNames}
                    />
                  </div>
                  <div className='flex justify-between'>
                    <SecondaryButton
                      onClick={() => this.setState({ step: 1 })}
                      size='medium'
                      label='Previous'
                    />
                    <div>
                      <Link
                        data-test-id='organization-navi-training-plans'
                        to={`/admin/${this.props.match.params.organization_uuid}/plans/${this.props.match.params.plan_uuid}`}
                      >
                        <PrimaryButton
                          gray={true}
                          size='medium'
                          label='Cancel'
                        />
                      </Link>
                      <PrimaryButton
                        onClick={this.handleSubmitSave}
                        size='medium'
                        customCss={'ml-3'}
                        label='Create Training Phase'
                      />
                    </div>
                  </div>
                </>
              ),
            }[this.state.step]
          }
          <div
            id='modal-plan-created'
            data-uk-modal
            data-test-id='modal-plan-creation-confirm'
          >
            <div className='uk-modal-dialog uk-modal-body'>
              <h2>Success!</h2>
              <p>You've successfully created a custom training plan!</p>
              <p>
                You can add phases by clicking the blue{' '}
                <strong>Add Training Phase</strong> button.
              </p>
              <p className='text-right'>
                <button
                  data-test-id='ok-btn'
                  className='uk-button uk-button-primary tm-button-primary uk-modal-close'
                  type='button'
                >
                  Ok
                </button>
              </p>
              <button
                className='uk-modal-close-outside'
                type='button'
                data-uk-close
              />
            </div>
          </div>
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  // content
  content: state.content.content,
  courses: state.content.courses,
  lessonMap: state.content.lessonMap,
  tests: state.content.tests,

  // hacker
  organizations: values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,

  // plans
  loadingPlans: state.plan.loadingPlans,
  hasLoadedPlans: state.plan.hasLoadedPlans,
  planMap: state.plan.planMap,

  // user
  email: state.auth.user.email,
})
const mapDispatchToProps = (dispatch) => ({
  addPlanGroup: (
    organizationId,
    planId,
    title,
    description,
    startDate,
    endDate,
    contentIds,
    duration
  ) =>
    dispatch(
      addPlanGroup(
        organizationId,
        planId,
        title,
        description,
        startDate,
        endDate,
        contentIds,
        duration
      )
    ),
  getPlanById: (organizationId, planId) =>
    dispatch(getPlanById(organizationId, planId)),
  getContent: (organizationUUID, includeAllOrganizationContent, callback) =>
    dispatch(
      getContent(organizationUUID, includeAllOrganizationContent, callback)
    ),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsPlansPhaseAdd)
)
