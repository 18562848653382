import React from 'react'

const ProficiencyTab = (props) => {
  if (props.selectedUser === 'loading') {
    return <div data-uk-spinner />
  }
  const renderLabel = (label) => {
    if (label === 'UNKNOWN') {
      return (
        <div
          className='uk-label uk-label-small'
          style={{ backgroundColor: '#666666' }}
        >
          Not Enough Data
        </div>
      )
    }
    if (label === 'POOR') {
      return (
        <div className='uk-label uk-label-small uk-label-danger'>
          Needs Attention
        </div>
      )
    }
    if (label === 'STRONG') {
      return (
        <div className='uk-label uk-label-small uk-label-success'>Expert</div>
      )
    }
    return (
      <div className='uk-label uk-label-small uk-label-warning'>Average</div>
    )
  }

  return (
    <div className='uk-align-center uk-width-1-2@m'>
      <table className='uk-table uk-table-divider'>
        <tbody>
          {props.selectedUser.mastery.map((category) => (
            <tr key={`category-${category.category_id}`}>
              <td style={{ verticalAlign: 'middle' }}>
                <small>{category.title}</small>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                {renderLabel(category.mastery)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ProficiencyTab
