/* eslint-disable no-nested-ternary */
import React from 'react'

export default (props) => {
  const {
    as = 'i',
    name,
    fill,
    className,
    ratio = 1,
    style = {},
    onClick,
    none,
    disabled,
  } = props
  let f = false
  if (fill) {
    f = true
  }

  // make a copy of the props and delete ones we don't want to pass to the created element
  const propsCopy = { ...props }
  delete propsCopy.fill
  delete propsCopy.none

  if (props.as === 'a') {
    style.textDecoration = 'none'
    style.color = 'inherit'
  }
  return React.createElement(as, {
    ...propsCopy,
    onClick: disabled ? undefined : onClick,
    className: `ri-${name}${none ? '' : f ? '-fill' : '-line'}${
      className ? ` ${className}` : ''
    }`,
    style: {
      fontSize: 20 * ratio,
      ...style,
      cursor: onClick && !disabled ? 'pointer' : null,
    },
  })
}
