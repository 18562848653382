/**
 * Manages the state associated with requesting a sandbox from the sandbox API. This module knows
 * when we last requested a sandbox, whether that request is still pending, any errors that occur
 * in the process, etc.
 */
import settings from 'settings'

import { api } from 'app/views/utils/api'

import * as sbproxy from './sbproxy'
import * as sbrepl from './sbrepl'

import * as sbcrfilesystem from './sbcrfilesystem'
import * as sbcrsubmission from './sbcrsubmission'
import * as sbrelay from './sbrelay'

class SandboxRequestPoller {
  constructor(
    startTimestamp,
    templateName,
    interval,
    onRequestSuccess,
    onRequestError
  ) {
    this.startTimestamp = startTimestamp
    this.templateName = templateName
    this.request = {
      method: 'get',
      url: `${settings.urls.controlK8s}/sandbox`,
      withAuthToken: true,
      params: {
        sandboxTemplate: this.templateName,
      },
      opts: {
        withCredentials: true,
      },
    }
    this.interval = interval
    this.onRequestSuccess = onRequestSuccess
    this.onRequestError = onRequestError
    this.timerHandle = null
    this.canceled = false
  }

  start() {
    _log('beginning periodic polling of sandbox requests')
    this.timerHandle = setTimeout(() => this._poll(), this.interval)
  }

  cancel() {
    if (this.timerHandle) {
      _log('canceling periodic polling of sandbox requests')
      clearTimeout(this.timerHandle)
      this.timerHandle = null
    }
    this.canceled = true
  }

  _poll() {
    _log(
      `...polling pending request for '${this.templateName}' sandbox (${
        (Date.now() - this.startTimestamp) / 1000
      }s elapsed)`
    )
    api(this.request)
      .then(({ data }) => {
        if (!this.canceled) {
          if (data.status === 'Running') {
            _log(
              `pending '${
                this.templateName
              }' sandbox is now ready! request succeeded after ${
                (Date.now() - this.startTimestamp) / 1000
              } seconds`
            )
            this.onRequestSuccess(data)
          } else {
            this.timerHandle = setTimeout(() => this._poll(), this.interval)
          }
        } else {
          _log(
            'pending sandbox request was canceled while HTTP request was in flight; ignoring response'
          )
        }
      })
      .catch((error) => {
        console.error('sandbox request failed after acknowledgement', error)
        this.onRequestError(error)
      })
  }
}

const prefix = 'sandbox'
const REQUEST_INITIATED = `${prefix}/REQUEST_INITIATED`
const REQUEST_ACKNOWLEDGED = `${prefix}/REQUEST_ACKNOWLEDGED`
const REQUEST_SUCCEEDED = `${prefix}/REQUEST_SUCCEEDED`
const REQUEST_FAILED = `${prefix}/REQUEST_FAILED`
const SANDBOX_ABANDONED = `${prefix}/SANDBOX_ABANDONED`

const initialState = {
  lastRequest: null,
  requestAcknowledgedTimestamp: null,
  requestPoller: null,
  requestFinishedTimestamp: null,
  requestError: null,
  browserTabUrls: {},
  url: null,
}

const ENABLE_VERBOSE_LOGGING = false
// eslint-disable-next-line no-console
const _log = ENABLE_VERBOSE_LOGGING ? console.log : () => {}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_INITIATED:
      return {
        ...state,
        lastRequest: {
          timestamp: action.timestamp,
          contentId: action.contentId,
          templateName: action.templateName,
        },
        requestAcknowledgedTimestamp: null,
        requestFinishedTimestamp: null,
        requestError: null,
        browserTabUrls: {},
        url: null,
      }
    case REQUEST_ACKNOWLEDGED:
      if (action.requestPoller) {
        action.requestPoller.start()
      }
      return {
        ...state,
        requestAcknowledgedTimestamp: action.timestamp,
        requestPoller: action.requestPoller,
      }
    case REQUEST_SUCCEEDED:
      return {
        ...state,
        apisRequireCredentials: action.apisRequireCredentials,
        requestPoller: null,
        requestFinishedTimestamp: action.timestamp,
        requestError: null,
        browserTabUrls: action.browserTabUrls,
        url: action.url,
      }
    case REQUEST_FAILED:
      return {
        ...state,
        requestPoller: null,
        requestFinishedTimestamp: action.timestamp,
        requestError: action.error,
      }
    case SANDBOX_ABANDONED:
      if (state.requestPoller) {
        state.requestPoller.cancel()
      }
      return {
        ...initialState,
      }
    default:
      return state
  }
}

function handleRequestSuccess(lastRequest, data, dispatch) {
  // Update our state to record the fact that we now have a ready sandbox
  const succeededAt = Date.now()
  let appData = data.apps || data.tabs
  dispatch({
    type: REQUEST_SUCCEEDED,
    timestamp: succeededAt,
    browserTabUrls: Object.keys(appData).reduce((acc, appName) => {
      acc[appName] = appData[appName].url
      return acc
    }, {}),
    // apps signals new sandbox URLs are in use, which requires credentials for CORS
    apisRequireCredentials: !!data.apps,
  })

  if (data.apps) {
    const appInits = {
      proxy: [(url) => sbproxy.setup(url, `${url}/proxy/intercept`)],
      repl: [(url) => sbrepl.setup(url, `${url}/repl/eval`)],
      relay: [sbrelay.setup],
      'api-filesystem': [
        sbcrfilesystem.setup,
        (url) => sbcrsubmission.setup(url, lastRequest.contentId),
      ],
    }

    Object.keys(appInits).forEach((appName) => {
      if (!data.apps[appName]) {
        return
      }
      const appUrl = data.apps[appName].url
      appInits[appName].forEach((init) => dispatch(init(appUrl)))
    })
    return
  }

  // TODO below setup is deprecated, can be removed when nice-urls are permanently on

  // If the sandbox has a proxy container, initialize sandbox-dependent proxy state
  if (data.proxyEvents) {
    dispatch(sbproxy.setup(data.sandboxUrl, data.proxyEvents))
  }

  // If the sandbox has a repl container, initialize sandbox-dependent repl state
  if (data.replEvents) {
    dispatch(sbrepl.setup(data.sandboxUrl, data.replEvents))
  }

  // CodeReview (i.e. coding_challenge/mobile) sandboxes are a special breed: they're
  // expected to have a predefined set of publicly-accessible hosts:
  // - 'app': Persistent host process that can build/run/test the user-editable source
  // - 'api-filesystem': Allows the UI to view and edit that source, keeping changes in
  //                     sync with app.
  // - 'relay': Serves stdout/stderr from the app over a text/event-stream response.

  // If the sandbox has an api-filesytem host, initialize the client-side module that
  // interacts with api-filesystem, and also set up state for the codereview-specific
  // module that handles code submissions
  const hasApiFilesystem = !!data.tabs['api-filesystem']
  if (hasApiFilesystem) {
    const apiFilesystemUrl = data.tabs['api-filesystem'].url
    dispatch(sbcrfilesystem.setup(apiFilesystemUrl))
    dispatch(sbcrsubmission.setup(apiFilesystemUrl, lastRequest.contentId))
  }

  // If the sandbox has a 'relay' host, initialize the client-side relay module to
  // receive output via an EventSource, whereupon it'll be send to the tty module
  // (relay.js is responsible for receiving and parsing messages; tty.js is responsible
  // for accumulating them for display, allowing the user to clear them, etc.)
  const hasRelay = !!data.tabs['relay']
  if (hasRelay) {
    const relayUrl = data.tabs['relay'].url
    dispatch(sbrelay.setup(relayUrl))
  }
}

function handleRequestError(error, dispatch) {
  const failedAt = Date.now()
  dispatch({
    type: REQUEST_FAILED,
    timestamp: failedAt,
    error: error.toString(),
  })
}

/**
 * Computes the options needed by fetch based on the state of the sandbox.
 *
 * @param state the global state
 * @returns options to pass to fetch
 */
export function requestOpts(state) {
  return {
    withCredentials: !!state[prefix].apisRequireCredentials,
  }
}

export function requestNew(contentId, templateName, force) {
  return (dispatch, getState) => {
    // Check whether we already have a sandbox request in flight
    const state = getState()
    if (state.sandbox.requestPoller) {
      // If we don't need to force a new sandbox, ignore the request
      if (!force && state.sandbox.requestPoller.templateName === templateName) {
        _log(
          `ignoring request for new '${templateName}' sandbox; a request is already pending`
        )
        return
      }

      // Otherwise, cancel polling for the pending request and carry on
      _log(
        `request for forced-new '${templateName}' sandbox pre-empts already pending request (initiated at ${
          state.sandbox.lastRequest
            ? state.sandbox.lastRequest.timestamp
            : '???'
        }}); stopping all polling for currently pending sandbox`
      )
      state.sandbox.requestPoller.cancel()
    }

    // Notify other modules that depend on the sandbox: shut 'er down, the sandbox is going away
    dispatch(sbproxy.teardown())
    dispatch(sbrepl.teardown())

    dispatch(sbcrfilesystem.teardown())
    dispatch(sbcrsubmission.teardown())
    dispatch(sbrelay.teardown())

    // Update our state to reflect that we have a new request in progress
    _log(`initiating request for new '${templateName}' sandbox`)
    const initiatedAt = Date.now()
    dispatch({
      type: REQUEST_INITIATED,
      timestamp: initiatedAt,
      contentId,
      templateName,
    })

    // Request a sandbox from api-sandbox, via GET /sandbox?sandboxTemplate=<name>
    return api({
      method: 'get',
      url: `${settings.urls.controlK8s}/sandbox`,
      withAuthToken: true,
      params: {
        forceNew: force ? true : undefined,
        sandboxTemplate: templateName,
      },
      opts: {
        withCredentials: true,
      },
    })
      .then(({ data }) => {
        // A response from the API means the request was acknowledged; data.status indicates readiness
        const acknowledgedAt = Date.now()
        _log('sandbox request acknowledged', data)

        // If our sandbox is ready right away, note that the request has succeeded - otherwise, start
        // polling in the background by repeatedly hitting GET /sandbox until it's ready
        let requestPoller = null
        if (data.status === 'Running') {
          _log('sandbox is ready immediately upon request!')
          handleRequestSuccess(getState().sandbox.lastRequest, data, dispatch)
        } else {
          _log(
            'sandbox is not yet ready; we will poll the request periodically...'
          )
          // eslint-disable-next-line no-console
          console.assert(!requestPoller)
          const interval = 500
          const onRequestSuccess = (data) =>
            handleRequestSuccess(getState().sandbox.lastRequest, data, dispatch)
          const onRequestError = (error) => handleRequestError(error, dispatch)
          requestPoller = new SandboxRequestPoller(
            initiatedAt,
            templateName,
            interval,
            onRequestSuccess,
            onRequestError
          )
        }
        dispatch({
          type: REQUEST_ACKNOWLEDGED,
          timestamp: acknowledgedAt,
          requestPoller,
        })
      })
      .catch((error) => {
        // If a sandbox request fails for any reason, note the error so we can inform the user
        console.error('sandbox request failed upon initial API call', error)
        handleRequestError(error, dispatch)
      })
  }
}

export function abandon() {
  return (dispatch, getState) => {
    // We *could* notify api-sandbox that we no longer need the sandbox so it can cull it faster,
    // but for right now this just tears down sandbox state and cancels any in-progress requests
    _log('sandbox is abandoned; cleaning up sandbox state')

    // Notify sandbox-dependent modules that they no longer have a sandbox
    dispatch(sbproxy.teardown())
    dispatch(sbrepl.teardown())

    dispatch(sbcrfilesystem.teardown())
    dispatch(sbcrsubmission.teardown())
    dispatch(sbrelay.teardown())

    // Pack it in, boys: reset this module to its initial state
    dispatch({ type: SANDBOX_ABANDONED })
  }
}
