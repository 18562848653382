import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'

import { inviteAdmin } from 'app/state/modules/hacker'

const propTypes = {
  totalAdmins: PropTypes.number.isRequired,
  organizationId: PropTypes.string.isRequired,
}

class InviteAdmins extends React.Component {
  constructor() {
    super()

    this.state = {
      email: '',
      error: '',
      success: null,
    }

    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleInviteAdmin = this.handleInviteAdmin.bind(this)
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value })
  }

  handleInviteAdmin() {
    this.props
      .inviteAdmin(this.props.organizationId, this.state.email)
      .then((result) => {
        if (result === true) {
          this.setState({ success: true })
        } else {
          this.setState({
            error: 'Whoops! There was an error inviting your admin...',
          })
        }
      })
      .catch(() => {
        this.setState({
          error: 'Whoops! There was an error inviting your admin...',
        })
      })
  }

  validateEmail(email) {
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i

    return expression.test(String(email).toLowerCase())
  }

  render() {
    const { totalAdmins } = this.props
    return (
      <div className='uk-card uk-card-default uk-card-body max-w-3xl'>
        <div className='uk-margin'>
          You have &nbsp;
          <span className='text-2xl font-bold'>{totalAdmins}</span>
          {totalAdmins === 1 ? ' admin' : ' admins'}
          &nbsp;in your organization.
        </div>
        <div className='uk-margin'>
          <div>Invite an admin to your account:</div>
          <input
            type='text'
            className='uk-input'
            value={this.state.email}
            onChange={this.handleChangeEmail}
            placeholder='admin@example.com'
            style={{
              color: '#1e87f0',
              paddingRight: '35px',
              width: 400,
            }}
          />
          {this.state.error && (
            <div className='uk-margin'>
              <p>{this.state.error}</p>
            </div>
          )}
          <div className='uk-margin'>
            <div className='uk-inline uk-width-1-1'>
              <button
                className='uk-button uk-button-primary tm-button-primary'
                disabled={!this.validateEmail(this.state.email)}
                data-uk-toggle='target: #modal-confirm-invite-admin'
                onClick={() => {
                  this.setState({ error: '', success: null })
                }}
                type='button'
              >
                Invite Admin
              </button>
            </div>
          </div>
        </div>
        <div id='modal-confirm-invite-admin' data-uk-modal>
          {this.state.success === null ? (
            <div className='uk-modal-dialog uk-modal-body'>
              {this.props.invitingAdmin ? (
                <div className='uk-padding text-center'>
                  <div data-uk-spinner='' />
                </div>
              ) : (
                <div>
                  <h2>Are you sure?</h2>
                  <p>
                    Are you sure you want to invite{' '}
                    <strong>{this.state.email}</strong> as an admin?
                  </p>
                  <button
                    className='uk-modal-close uk-button uk-button-muted'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    className='uk-button uk-button-primary tm-button-primary'
                    type='button'
                    onClick={this.handleInviteAdmin}
                  >
                    Send Invite
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className='uk-modal-dialog uk-modal-body'>
              <h2>Success!</h2>
              <p>
                We have sent <strong>{this.state.email}</strong> an admin invite
                link.
              </p>
              <button
                className='uk-modal-close uk-button uk-button-secondary'
                type='button'
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

InviteAdmins.propTypes = propTypes

const mapStateToProps = (state) => ({
  invitingAdmin: state.hacker.invitingAdmin,
})
const mapDispatchToProps = (dispatch) => ({
  inviteAdmin: (organizationId, email) =>
    dispatch(inviteAdmin(organizationId, email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InviteAdmins)
