import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { HBox, Box } from 'app/views/core/Box'
import { VScroll } from 'app/views/core/Scroll'
import { getEngineDisplayName } from 'app/views/utils/engine'

import { parseSubmissionDetails } from './util'
import TestSubmissionList from './TestSubmissionList'
import TestSubmissionDetails from './TestSubmissionDetails'
import TestSubmissionCodeModal from './TestSubmissionCodeModal'

import { connect } from 'react-redux'
import { UpgradeTrialModal } from './UpgradeTrialModal'

function TestOverview(props) {
  const {
    stages,
    isRunningTests,
    isSubmittingPatch,
    hackerSubmissions,
    svcSubmissions,
    svcError,
    svcHasPendingSubmission,
    toggleTrialModal,
    showUpgradeModal,
    isTrialMode,
  } = props
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(-1)

  // If using submission data from svc-code-sub, conform our CodeSubmission objects to
  // the format used by these UI components
  const { engUseSvcCodeSub } = useFlags()
  let submissions = hackerSubmissions
  if (engUseSvcCodeSub) {
    submissions = []
    if (svcHasPendingSubmission) {
      submissions.push({
        id: 0,
        submitted_at: new Date().toISOString(),
        engine: 'app',
        code: '',
        test: {},
      })
    }
    for (const s of svcSubmissions.sort(
      (a, b) => b.submissionTime.getTime() - a.submissionTime.getTime()
    )) {
      submissions.push({
        id: -s.submissionTime.getTime(),
        submitted_at: s.submissionTime.toISOString(),
        engine: s.volumeName,
        code: s.replCode || '',
        test: {
          passed: s.result.status === 'passed',
          name: s.result.status === 'failed' ? s.result.testName : undefined,
        },
      })
    }
  }

  // When we get a new code submission, or when the eng_use_svc_code_sub feature flag
  // changes, automatically select the newest submission
  useEffect(() => {
    if (submissions.length > 0) {
      setSelectedSubmissionId(submissions[0].id)
    }
  }, [JSON.stringify(submissions.map((x) => x.id))])

  // Use a flag to store whether we want to see a modal with our currently-selected
  // submission's code
  const [wantsCode, setWantsCode] = useState(false)

  // If using svc-code-sub and we encountered an error in the codesub module, surface
  // it here
  if (engUseSvcCodeSub && svcError && !isTrialMode) {
    return <div className='text-danger p-4'>{svcError}</div>
  }

  const selectedSubmission =
    selectedSubmissionId === -1
      ? null
      : submissions.find((x) => x.id === selectedSubmissionId)
  const details = selectedSubmission
    ? parseSubmissionDetails(selectedSubmission)
    : null
  return submissions.length === 0 ? (
    <Box fillParent style={{ padding: 20 }}>
      <h3 className='uk-margin-remove-bottom'>Test Submissions</h3>
      <span className='text-sm'>No tests have been submitted.</span>
      {showUpgradeModal && (
        <UpgradeTrialModal toggleTrialModal={toggleTrialModal} />
      )}
    </Box>
  ) : (
    <HBox fillParent>
      <Box fixed={250}>
        <VScroll>
          <TestSubmissionList
            submissions={submissions}
            selectedSubmissionId={selectedSubmissionId}
            onSelect={setSelectedSubmissionId}
          />
        </VScroll>
      </Box>
      <Box grow>
        <VScroll>
          {details && (
            <TestSubmissionDetails
              onRequestCode={
                details.code.length > 1 ? () => setWantsCode(true) : null
              }
              requestCodeButtonLabel={`View ${getEngineDisplayName(
                details.engine
              )} Code`}
              stages={stages}
              {...details}
            />
          )}
        </VScroll>
      </Box>
      {details && wantsCode && (
        <TestSubmissionCodeModal
          engine={details.engine}
          code={details.code}
          onClose={() => setWantsCode(false)}
        />
      )}
    </HBox>
  )
}
TestOverview.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRunningTests: PropTypes.bool.isRequired,
  hackerSubmissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  svcSubmissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  svcError: PropTypes.string.isRequired,
  toggleTrialModal: PropTypes.func.isRequired,
  showUpgradeModal: PropTypes.bool.isRequired,
}

export default connect((state) => {
  const shouldShowSubmission = (submission) =>
    submission.test.passed || submission.test.name || !submission.stale
  return {
    isRunningTests: state.repl.runningTests,
    hackerSubmissions:
      state.hacker.currentCodeSubmissions.filter(shouldShowSubmission),
    svcSubmissions: state.codesub.submissions,
    svcError: state.codesub.error,
    svcHasPendingSubmission: state.codesub.mode === 'waiting',
  }
}, {})(TestOverview)
