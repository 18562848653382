import React, { useState, useEffect } from 'react'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import './styles.less'
import Icon from 'app/views/components/Icon'

const LessonSorter = ({
  selectedContentIds,
  selectedContentNames,
  contentIdReorder,
  contentNameReorder,
}) => {
  const [contentIdOrder, setContentIdOrder] = useState(selectedContentIds)
  const [contentNameOrder, setContentNameOrder] = useState(selectedContentNames)
  const [deletedContent, setDeletedContent] = useState({})

  const handleOnDragEnd = (result) => {
    if (!result.destination) return

    const itemIds = Array.from(contentIdOrder)
    const [reOrderedIds] = itemIds.splice(result.source.index, 1)
    itemIds.splice(result.destination.index, 0, reOrderedIds)

    setContentIdOrder(itemIds)

    const itemNames = Array.from(contentNameOrder)
    const [reOrderedNames] = itemNames.splice(result.source.index, 1)
    itemNames.splice(result.destination.index, 0, reOrderedNames)

    setContentNameOrder(itemNames)
  }

  const deleteLesson = () => {
    setContentIdOrder((prevList) =>
      prevList.filter((contentId, i) => i !== deletedContent.index)
    )
    setContentNameOrder((prevList) =>
      prevList.filter((contentName, i) => i !== deletedContent.index)
    )
    contentIdReorder(contentIdOrder)
  }

  useEffect(() => {
    contentIdReorder(contentIdOrder)
  }, [contentIdOrder])

  useEffect(() => {
    contentNameReorder(contentNameOrder)
  }, [contentNameOrder])

  return (
    <div className='uk-card uk-card-default'>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId='selectedContentIds'>
          {(provided) => (
            <div
              className='uk-padding'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {contentNameOrder.map((content, index) => (
                <Draggable key={content} draggableId={content} index={index}>
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      className='flex justify-between content-sorter'
                    >
                      <div>
                        <Icon
                          uk-toggle='target: #delete-lesson'
                          onClick={() => setDeletedContent({ index, content })}
                          className='delete-lesson-button'
                          name='close-circle'
                          ratio='.7'
                          as='span'
                        />
                        <span className='text-sm'>{content}</span>
                      </div>
                      <div className='drag-icon'>
                        <svg
                          width='14'
                          height='20'
                          viewBox='0 0 14 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          uk-tooltip='title: Drag to reorder; pos: right'
                        >
                          <path
                            d='M5.40304 13.3088C5.12179 13.3088 4.88031 13.2094 4.67861 13.0105C4.47974 12.8088 4.38031 12.5673 4.38031 12.2861C4.38031 12.0077 4.47974 11.769 4.67861 11.5702C4.88031 11.3713 5.12179 11.2719 5.40304 11.2719C5.67576 11.2719 5.9144 11.3713 6.11895 11.5702C6.32349 11.769 6.42576 12.0077 6.42576 12.2861C6.42576 12.4736 6.37747 12.6455 6.28088 12.8017C6.18713 12.9551 6.06355 13.0787 5.91014 13.1725C5.75673 13.2634 5.5877 13.3088 5.40304 13.3088ZM5.40304 8.72359C5.12179 8.72359 4.88031 8.62416 4.67861 8.42529C4.47974 8.22643 4.38031 7.98495 4.38031 7.70086C4.38031 7.42245 4.47974 7.18524 4.67861 6.98921C4.88031 6.79035 5.12179 6.69092 5.40304 6.69092C5.67576 6.69092 5.9144 6.79035 6.11895 6.98921C6.32349 7.18524 6.42576 7.42245 6.42576 7.70086C6.42576 7.8912 6.37747 8.0645 6.28088 8.22075C6.18713 8.37416 6.06355 8.49632 5.91014 8.58723C5.75673 8.67813 5.5877 8.72359 5.40304 8.72359Z'
                            fill='#6F6F6F'
                          />
                          <path
                            d='M8.97726 13.3088C8.69601 13.3088 8.45453 13.2094 8.25282 13.0105C8.05396 12.8088 7.95453 12.5673 7.95453 12.2861C7.95453 12.0077 8.05396 11.769 8.25282 11.5702C8.45453 11.3713 8.69601 11.2719 8.97726 11.2719C9.24998 11.2719 9.48862 11.3713 9.69317 11.5702C9.89771 11.769 9.99998 12.0077 9.99998 12.2861C9.99998 12.4736 9.95169 12.6455 9.8551 12.8017C9.76135 12.9551 9.63777 13.0787 9.48436 13.1725C9.33095 13.2634 9.16192 13.3088 8.97726 13.3088ZM8.97726 8.72359C8.69601 8.72359 8.45453 8.62416 8.25282 8.42529C8.05396 8.22643 7.95453 7.98495 7.95453 7.70086C7.95453 7.42245 8.05396 7.18524 8.25282 6.98921C8.45453 6.79035 8.69601 6.69092 8.97726 6.69092C9.24998 6.69092 9.48862 6.79035 9.69317 6.98921C9.89771 7.18524 9.99998 7.42245 9.99998 7.70086C9.99998 7.8912 9.95169 8.0645 9.8551 8.22075C9.76135 8.37416 9.63777 8.49632 9.48436 8.58723C9.33095 8.67813 9.16192 8.72359 8.97726 8.72359Z'
                            fill='#6F6F6F'
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div id='delete-lesson' uk-modal='true'>
        <div className='uk-modal-dialog uk-modal-body'>
          <h2 className='uk-modal-title'>
            Remove the lesson "{deletedContent.content}" from this phase?
          </h2>
          <p className='text-right'>
            <button
              className='uk-button uk-button-default uk-modal-close'
              type='button'
            >
              Cancel
            </button>
            <button
              onClick={() => deleteLesson()}
              className='uk-button uk-button-primary uk-modal-close'
              type='button'
            >
              Delete Lesson
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}

export default LessonSorter
