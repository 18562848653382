import React from 'react'
import { connect } from 'react-redux'
import UIkit from 'uikit'

// Components
import Icon from 'app/views/components/Icon'
import ToggleSwitch from 'app/views/components/ToggleSwitch'

// Redux
import { updateTeamMustPassCode } from 'app/state/modules/team'

class EditSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mustPassCode: this.props.currentTeam.must_pass_coding_exercise,
    }
    this.handleOverRide = this.handleOverRide.bind(this)
    this.handleUpdateMustPassCode = this.handleUpdateMustPassCode.bind(this)
  }

  handleOverRide(value) {
    this.setState(
      {
        mustPassCode: value === true ? false : null,
      },
      () => {
        this.props.updateTeamMustPassCode(
          this.props.match.params.organization_uuid,
          this.props.match.params.team_uuid,
          value === true ? false : ''
        )
      }
    )
  }

  handleUpdateMustPassCode(value) {
    this.setState(
      {
        mustPassCode: value,
      },
      () => {
        this.props.updateTeamMustPassCode(
          this.props.match.params.organization_uuid,
          this.props.match.params.team_uuid,
          value
        )
      }
    )
  }

  render() {
    return (
      <>
        <h2>Settings</h2>
        <div className='uk-margin'>
          <label className='uk-form-label'>Coding Test Required</label>
          <div className='flex'>
            <ToggleSwitch
              onChange={this.handleOverRide}
              checked={this.state.mustPassCode !== null}
              label='Override Organization Default'
            />
            <Icon
              name='information'
              ratio={0.8}
              onClick={() => {
                UIkit.modal.alert(
                  `<h2>Override Organization Default</h2>
                <div>
                <p>
                  If "Override Organization Default" is off, this team will use whatever
                  the global setting is for requiring the coding test to pass the
                  lesson.
                </p>
                <p>
                  If you would like this team to have a different setting than the
                  default, turn on the override and then set the Coding Test Required
                  setting either off or on.
                </p>
              </div>
              `,
                  {
                    bgClose: true,
                  }
                )
              }}
              style={{
                cursor: 'pointer',
                paddingLeft: 8,
                verticalAlign: 'top',
              }}
            />
          </div>
        </div>
        {this.state.mustPassCode !== null && (
          <div className='uk-margin'>
            <div className='flex'>
              <ToggleSwitch
                onChange={this.handleUpdateMustPassCode}
                checked={this.state.mustPassCode}
                label='Coding Test Required'
              />
              <Icon
                name='information'
                ratio={0.8}
                onClick={() => {
                  UIkit.modal.alert(
                    `<h2>Coding Test Required</h2>
                  <div>
                  <p>
                    The "Coding Test Required" setting determines whether users have to
                    submit a successful patch to a lesson with a coding exercise before
                    it is considered completed.
                  </p>
                  <p>
                    By default, this setting is set at the Organization level, but you can
                    override this global setting at the Team level as well if you wish.
                  </p>
                </div>`,
                    {
                      bgClose: true,
                    }
                  )
                }}
                style={{
                  cursor: 'pointer',
                  paddingLeft: 8,
                  verticalAlign: 'top',
                }}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  currentTeam: state.team.currentTeam,
})

export default connect(mapStateToProps, {
  updateTeamMustPassCode,
})(EditSettings)
