import React from 'react'
import Icon from '../Icon'
import cx from 'classnames'

const SortableTableHeader = (props) => {
  const onClick = () => {
    props.onClick(props.keyName, props.asc)
  }

  return (
    <th
      className={cx({
        'text-center': Boolean(props.center),
        'align-middle': Boolean(props.middle),
      })}
      style={props.style || {}}
    >
      <div
        className={cx('flex items-center', {
          'justify-center': Boolean(props.center),
        })}
      >
        <span>{props.name}</span>
        {!props.notSortable && (
          <Icon
            className={cx('uk-margin-small-left', {
              'text-green': props.active,
            })}
            name={props.asc || !props.active ? 'arrow-down' : 'arrow-up'}
            ratio={0.8}
            onClick={onClick}
          />
        )}
      </div>
    </th>
  )
}

export default SortableTableHeader
