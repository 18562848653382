import React from 'react'
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Text,
  Legend,
} from 'recharts'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getCategoryTimeChartReport } from 'app/state/modules/reports'
import CustomizedAxisTick from 'app/views/components/CustomizedAxisTick'

class CategoryTimeChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      categoryTimeChart: null,
    }
    this.mounted = true
  }

  componentDidMount() {
    this.debounce = setTimeout(() => {
      this.props
        .getCategoryTimeChartReport(this.props.match.params.organization_uuid)
        .then(({ data }) => {
          if (this.mounted) {
            this.setState({
              categoryTimeChart: data,
              isLoading: false,
            })
          }
        })
        .catch((error) => console.error(error))
    }, 250)
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <>
          <h2 className='text-center'>Time per Category</h2>
          <div
            style={{ height: 550 }}
            className='uk-width-1-1 items-center justify-center flex flex-col'
          >
            <div data-uk-spinner='' />
            <span className='uk-small-margin-top'>Loading...</span>
          </div>
        </>
      )
    }
    const { categoryTimeChart } = this.state
    return (
      <>
        <h2 className='text-center' style={{ marginBottom: 30 }}>
          Time per Category
        </h2>
        <ResponsiveContainer
          height={550}
          className='text-sm uk-width-1-1'
        >
          <BarChart
            data={categoryTimeChart}
            margin={{
              top: 0,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              height={200}
              interval={0}
              tick={<CustomizedAxisTick />}
              dataKey='title'
            />
            <YAxis
              label={
                <Text
                  x={-20}
                  y={0}
                  dx={50}
                  dy={200}
                  offset={0}
                  fill='rgb(102, 102, 102)'
                  angle={-90}
                >
                  Hours
                </Text>
              }
            />
            <Tooltip
              content={({ payload }) => {
                if (payload && payload[0]) {
                  return (
                    <div className='recharts-tooltip-wrapper'>
                      <div
                        className='recharts-default-tooltip'
                        style={{
                          border: 'solid 1px rgb(204, 204, 204)',
                          backgroundColor: '#fff',
                          padding: 10,
                        }}
                      >
                        <p className='recharts-tooltip-label uk-margin-remove-bottom'>
                          {payload[0].payload.title}
                        </p>
                        <ul className='uk-nav uk-nav-default uk-margin-small-top'>
                          <li style={{ color: '#1e87f0' }}>
                            <span>
                              {`${payload[0].payload.Hours} ${
                                payload[0].payload.Hours > 1 ? 'hours' : 'hour'
                              }`}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )
                }
                return null
              }}
            />
            <Bar dataKey='Hours' stackId='bar' fill='#1e87f0' />
          </BarChart>
        </ResponsiveContainer>
      </>
    )
  }
}

export default withRouter(
  connect(null, {
    getCategoryTimeChartReport,
  })(CategoryTimeChart)
)
