// Settings
import settings from 'settings'

// Utils
import { api } from 'app/views/utils/api'

const RECEIVE_LEADERBOARD = 'RECEIVE_LEADERBOARD'
const SET_LEADERBOARD_OFSET = 'SET_LEADERBOARD_OFSET'
const SET_LEADERBOARD_LOADING = 'SET_LEADERBOARD_LOADING'

const initialState = {
  loading: true,
  leaderboard: [],
  order: 'points',
  offset: 0,
  perPage: 50,
  orderDirection: 'desc',
  totalHackers: 0,
  isOrganizationLeaderboard: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_LEADERBOARD:
      return {
        ...state,
        leaderboard: action.leaderboard,
        loading: false,
        totalHackers: action.total,
        isOrganizationLeaderboard: action.isOrganizationLeaderboard,
      }
    case SET_LEADERBOARD_LOADING:
      return {
        ...state,
        loading: true,
      }
    case SET_LEADERBOARD_OFSET:
      return {
        ...state,
        offset: action.offset,
      }
    default:
      return state
  }
}

export function setLeaderboardOffset(offset) {
  return {
    type: SET_LEADERBOARD_OFSET,
    offset,
  }
}

export function getLeaderboard(organizationUUID = null) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: SET_LEADERBOARD_LOADING,
      loading: true,
    })
    return api({
      method: 'get',
      url: `${settings.urls.hacker}${
        organizationUUID ? `/organizations/${organizationUUID}` : ''
      }/leaderboard`,
      withAuthToken: true,
      params: {
        order: state.leaderboard.order,
        offset: state.leaderboard.offset,
        limit: state.leaderboard.perPage,
        orderDirection: state.leaderboard.orderDirection,
      },
    })
      .then((response) => {
        dispatch({
          type: RECEIVE_LEADERBOARD,
          leaderboard: response.data.users,
          total: response.data.total,
          isOrganizationLeaderboard:
            typeof organizationUUID === 'string' &&
            organizationUUID.length > 10,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
