import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

function FilterDropdown(props) {
  const {
    labelText,
    inputId,
    isLoading,
    items,
    selectedId,
    onSelectedIdChange,
  } = props
  return (
    <div className='impact-report-filter-dropdown'>
      <label htmlFor={inputId}>{labelText}</label>
      <select
        id={inputId}
        value={isLoading ? '' : selectedId}
        onChange={(event) => onSelectedIdChange(event.target.value)}
        disabled={isLoading}
        className='uk-select'
      >
        {!isLoading &&
          items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.title}
            </option>
          ))}
      </select>
    </div>
  )
}
FilterDropdown.propTypes = {
  labelText: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedId: PropTypes.string.isRequired,
  onSelectedIdChange: PropTypes.func.isRequired,
}

export default FilterDropdown
