import _ from 'lodash'
import { checkPasswordComplexity } from 'app/views/utils/validators'
import {
  CANNOT_BE_BLANK,
  INSUFFICIENT_PASSWORD_COMPLEXITY,
  INCORRECT_CONFIRM_PASSWORD_DOES_NOT_MATCH,
} from 'app/views/constants/errorMessages'

export default (fields, enforceComplexPasswords) => {
  const { verificationCode, password, confirmPassword } = fields
  const errors = {}

  const trimmedVerificationCode = _.trim(verificationCode)
  if (!trimmedVerificationCode) {
    errors.verificationCode = CANNOT_BE_BLANK
  }

  const trimmedPassword = _.trim(password)
  if (!trimmedPassword) {
    errors.password = CANNOT_BE_BLANK
  } else if (enforceComplexPasswords) {
    const result = checkPasswordComplexity(trimmedPassword)
    if (!result.passed) {
      errors.password = {
        message: INSUFFICIENT_PASSWORD_COMPLEXITY,
        missing: result.missing,
      }
    }
  }

  const trimmedConfirmPassword = _.trim(confirmPassword)
  if (!trimmedConfirmPassword) {
    errors.confirmPassword = CANNOT_BE_BLANK
  } else if (trimmedConfirmPassword !== trimmedPassword) {
    errors.confirmPassword = INCORRECT_CONFIRM_PASSWORD_DOES_NOT_MATCH
  }

  return errors
}
