import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

/**
 * Strips out the script tag that's injected into the document by the proxy, so
 * we're not presenting sandbox implementation details to the user alongside
 * the page source.
 */
function stripInjectedScript(source) {
  const startRegex =
    /\n<!-- The following script tag is a HackEDU sandbox script and not part of the application -->\s+<script>/m
  const match = source.match(startRegex)
  if (match) {
    const scriptStartPos = match.index + match[0].length
    const endTag = '</script>\n'
    const endTagPos = source.indexOf(endTag, scriptStartPos)
    if (endTagPos > -1) {
      return (
        source.slice(0, match.index) + source.slice(endTagPos + endTag.length)
      )
    }
  }
  return source
}

/**
 * The location reported to us by the sandbox iframe will be the actual public
 * DNS name of the app - i.e. the "real" URL. Map that base URL back to the
 * user-facing "clean" value, given the list of all known sandbox app URLs.
 */
function getCleanBaseUrl(protocol, host, allowedOrigins, fallback) {
  if (!protocol || !host || protocol.length === 0 || host.length === 0) {
    return fallback
  }
  const baseUrl = `${protocol}//${host}`
  for (const { cleanUrl, realUrl } of allowedOrigins) {
    if (baseUrl === realUrl) {
      return cleanUrl
    }
  }
  return baseUrl
}

function SandboxBrowserFrame(props) {
  const {
    isProxied,
    cleanUrl,
    useRealUrl,
    allowedOrigins,
    realLocation,
    onLocationChange,
    isFocused,
    isVisible,
  } = props
  const { wantsPageSource, onPageSourceChange } = props
  const iframeRef = useRef()
  const numLocationChanges = useRef(0)

  // We can't directly access the state of the iframe due to browser-level
  // cross-origin security restrictions: instead, the page running in the
  // iframe posts messages to the parent window with the help of an onload
  // script that's injected by the proxy.
  const onMessage = (event) => {
    // Ignore messages from iframes not belonging to this browser tab
    if (
      !iframeRef.current ||
      event.source.window !== iframeRef.current.contentWindow
    ) {
      return
    }
    // The iframe sends 'location' when it's loaded a new URL; 'source' in response to a
    // 'View Source' request
    if (event.data.action === 'location') {
      // Update the URL shown in the address bar
      const { protocol, host, pathname, search } = event.data
      if (useRealUrl) {
        onLocationChange({
          url: `${protocol}//${host}${pathname}${search}`,
          index: numLocationChanges.current++,
        })
      } else {
        const baseUrl = getCleanBaseUrl(
          protocol,
          host,
          allowedOrigins,
          cleanUrl
        )
        onLocationChange({
          url: `${baseUrl}${pathname}${search}`,
          index: numLocationChanges.current++,
        })
      }
    } else if (event.data.action === 'source') {
      // Send the page source up to the UI for display
      const rawSource = event.data.data
      onPageSourceChange(stripInjectedScript(rawSource))
    }
  }

  // While the tab is actively being displayed, listen for 'message' events
  // sent up to the parent window from the iframe
  useEffect(() => {
    if (isProxied && isFocused) {
      window.addEventListener('message', onMessage)
    } else {
      window.removeEventListener('message', onMessage)
    }
  }, [isProxied, isFocused])

  // Ensure that the event listener is always cleaned up on unmount
  useEffect(() => {
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])

  // If page source has been requested, post a message to the iframe requesting
  // that it send its source to the parent window (handled above in onMessage)
  useEffect(() => {
    if (wantsPageSource && isProxied) {
      if (iframeRef.current) {
        iframeRef.current.contentWindow.postMessage({ action: 'source' }, '*')
      }
    } else {
      onPageSourceChange('')
    }
  }, [wantsPageSource])

  // If realLocation has changed but the URL is unchanged, explicitly force
  // the iframe to reload the same page, since no React update will occur
  const lastKnownSrc = useRef(null)
  useEffect(() => {
    if (iframeRef.current) {
      if (lastKnownSrc.current === realLocation.url) {
        iframeRef.current.src = realLocation.url
      }
      lastKnownSrc.current = realLocation.url
    }
  }, [realLocation])

  return (
    <div style={{ flexGrow: 1, display: isVisible ? 'block' : 'none' }}>
      <iframe
        ref={iframeRef}
        style={{ width: '100%', height: '100%' }}
        src={realLocation.url}
      />
    </div>
  )
}
SandboxBrowserFrame.propTypes = {
  isProxied: PropTypes.bool.isRequired,
  cleanUrl: PropTypes.string.isRequired,
  useRealUrl: PropTypes.bool.isRequired,
  allowedOrigins: PropTypes.arrayOf(
    PropTypes.shape({
      cleanUrl: PropTypes.string.isRequired,
      realUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  realLocation: PropTypes.shape({
    url: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  onLocationChange: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  wantsPageSource: PropTypes.bool.isRequired,
  onPageSourceChange: PropTypes.func.isRequired,
}

export default SandboxBrowserFrame
