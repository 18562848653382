import React, { type ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

export const SplashLinks = (props: Props) => (
  <div className="flex flex-col items-center mt-8 space-y-2">
    {props.children}
  </div>
)
