import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { getNotificationsReport } from 'app/state/modules/reports'

import { ButtonTabs } from 'app/views/components/Tabs'
import Loader from 'app/views/components/Loader'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import GenericModal from 'app/views/components/Modals/GenericModal'
import Icon from 'app/views/components/Icon'
import OrganizationLayout from '../components/Layout'

class ActivityReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDetails: null,
    }
    this.onShowModal = this.onShowModal.bind(this)
    this.modal = React.createRef()
  }

  componentDidMount() {
    this.props.getNotificationsReport(this.props.match.params.organization_uuid)
  }

  onShowModal(row) {
    this.setState(
      {
        showDetails: row,
      },
      () => {
        this.modal.current.show()
      }
    )
  }

  renderDate(dateStr, forceFull = false) {
    if (forceFull) {
      return getLocalDateFromUTC(dateStr).format('MMMM D, YYYY @ h:mm a')
    }
    const date = getLocalDateFromUTC(dateStr).diff(moment.utc(), 'hours')
    if (Math.abs(date) < 25) {
      return getLocalDateFromUTC(dateStr).fromNow()
    }
    return getLocalDateFromUTC(dateStr).format('MMMM D, YYYY @ h a')
  }

  renderTitle(key, row, isRow) {
    const totalHackers = row.hackers.length
    switch (key) {
      case 'onboarding':
        return (
          <p>
            Onboarding reminders sent to{' '}
            <span
              className='text-secondary underline-offset-auto'
              onClick={() => this.onShowModal(row)}
              type='button'
              style={
                isRow ? { cursor: 'pointer', textDecoration: 'underline' } : {}
              }
            >
              {`${totalHackers} user${totalHackers === 1 ? '' : 's'}.`}
            </span>
          </p>
        )
      case 'reminder':
        if (row.channel === 'slack') {
          return (
            <p>
              Slack reminders sent to{' '}
              <span
                className='text-secondary underline-offset-auto'
                onClick={() => this.onShowModal(row)}
                type='button'
                style={
                  isRow
                    ? { cursor: 'pointer', textDecoration: 'underline' }
                    : {}
                }
              >
                {`${totalHackers} user${totalHackers === 1 ? '' : 's'}.`}
              </span>
            </p>
          )
        }
        if (row.channel === 'email') {
          return (
            <p>
              Email reminders sent to{' '}
              <span
                className='text-secondary underline-offset-auto'
                onClick={() => this.onShowModal(row)}
                type='button'
                style={
                  isRow
                    ? { cursor: 'pointer', textDecoration: 'underline' }
                    : {}
                }
              >
                {`${totalHackers} user${totalHackers === 1 ? '' : 's'}.`}
              </span>
            </p>
          )
        }
        if (row.channel === 'microsoft') {
          return (
            <p>
              Microsoft Teams reminders sent to{' '}
              <span
                className='text-secondary underline-offset-auto'
                onClick={() => this.onShowModal(row)}
                type='button'
                style={
                  isRow
                    ? { cursor: 'pointer', textDecoration: 'underline' }
                    : {}
                }
              >
                {`${totalHackers} user${totalHackers === 1 ? '' : 's'}.`}
              </span>
            </p>
          )
        }
        break
      case 'training_assigned':
        return (
          <p>
            Slack new training notifications sent to{' '}
            <span
              className='text-secondary underline-offset-auto'
              onClick={() => this.onShowModal(row)}
              type='button'
              style={
                isRow ? { cursor: 'pointer', textDecoration: 'underline' } : {}
              }
            >
              {`${totalHackers} user${totalHackers === 1 ? '' : 's'}.`}
            </span>
          </p>
        )
      default:
        return ''
    }
    return null
  }

  renderIcon(channel) {
    switch (channel) {
      case 'slack':
        return 'slack'
      case 'email':
        return 'mail-send'
      case 'microsoft':
        return 'microsoft'
      default:
        return null
    }
  }

  render() {
    return (
      <OrganizationLayout
        active='activity'
        data-test-id='organization-users-component'
        noPaddingRemove={this.props.match.params.team_uuid}
        style={{ position: 'relative' }}
        {...this.props}
      >
        <h1>Activity</h1>
        {this.props.notificationsReport === 'loading' ? (
          <Loader visible />
        ) : (
          <>
            {this.props.notificationsReport.length === 0 && (
              <p>No notification activity found.</p>
            )}
            <table className='uk-table uk-table-divider uk-table-small text-sm uk-table-middle'>
              {this.props.notificationsReport.length > 0 && (
                <thead>
                  <tr>
                    <th>Notification</th>
                    <th>Timestamp</th>
                    <th className='text-center'>Details</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {this.props.notificationsReport.map((row) => {
                  return (
                    <tr key={`${row.type}-${row.channel}-${row.sent_at}`}>
                      <td>
                        <div className='flex items-center justify-start flex-row uk-width-1-1'>
                          <Icon
                            name={this.renderIcon(row.channel)}
                            className='uk-margin-right'
                            fill
                          />
                          {this.renderTitle(row.type, row, true)}
                        </div>
                      </td>
                      <td>{this.renderDate(row.sent_at)}</td>
                      <td className='text-center'>
                        <PrimaryButton
                          onClick={() => this.onShowModal(row)}
                          size={'small'}
                          label='See Details'
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}

        <GenericModal
          ref={this.modal}
          width='uk-width-2-5 uk-padding-remove-top'
          id='users-sent-notifications'
          body={
            <>
              <h3>
                {this.renderTitle(
                  (this.state.showDetails || {}).type,
                  this.state.showDetails || { hackers: [] }
                )}
              </h3>
              <table className='uk-table uk-table-divider uk-table-middle uk-table-small text-sm'>
                <thead>
                  <tr>
                    <th>Sent To</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {((this.state.showDetails || {}).hackers || []).map(
                    (hacker) => {
                      return (
                        <tr key={`${hacker.hacker_uuid}-${hacker.timestamp}`}>
                          <td>{hacker.email}</td>
                          <td>{this.renderDate(hacker.timestamp, true)}</td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </table>
            </>
          }
        />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  notificationsReport: state.reports.notificationsReport,
  organizationsHash: state.hacker.organizationsHash,
  organizations: state.hacker.organizationsList,
})

export default connect(mapStateToProps, {
  getNotificationsReport,
})(ActivityReport)
