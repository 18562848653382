import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import settings from 'settings'

// Components
import ContentButton from 'app/views/components/ContentButton'
import ContentIcon from 'app/views/components/ContentIcon'
import ScratchpadButton from 'app/views/components/ScratchpadButton'
import AllTrainingMenu from 'app/views/components/AllTrainingMenu'

// Redux
import { getContent, getCodingChallenges } from 'app/state/modules/content'

// Utils
import analytics from 'app/views/utils/analytics'
import ModalContentIcon from 'app/views/components/Modals/ModalContentIcon'
import SkeletonLoader from 'app/views/components/Loader/SkeletonLoader'
import Icon from 'app/views/components/Icon'

const propTypes = {
  getContent: PropTypes.func.isRequired,
  license: PropTypes.bool,
}
const defaultProps = {
  license: false,
}

class ByCodeReview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
    }
    this.container = React.createRef()
    this.onSelected = this.onSelected.bind(this)
    this.frameworkURL = this.props.match.params.framework
    this.languageURL = this.props.match.params.language
  }

  componentDidMount() {
    if (this.props.codeReviews.length === 0) {
      this.props.getContent()
      this.props.getCodingChallenges()
    }
    this.onSelected()
    analytics.page('/coding-challenges/')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.showAllTraining === false &&
      nextProps.organizations.some((item) => item.role === 'admin') !== true
    ) {
      this.props.history.push('/')
    }
  }

  componentDidUpdate() {
    this.onSelected()
    if (!this.props.showCodingChallenges) {
      this.props.history.push('/')
    }
  }

  onSelected() {
    let exists = false
    if (
      this.props.codeReviews.length > 0 &&
      !this.state.selected &&
      this.frameworkURL &&
      this.languageURL
    ) {
      this.props.codeReviews.forEach((language) => {
        language.frameworks.forEach((framework) => {
          if (
            framework.name === this.frameworkURL &&
            language.language_name === this.languageURL
          ) {
            exists = true
            this.setState(
              {
                selected: {
                  ...framework,
                  languageName: language.language_name,
                },
              },
              () => {}
            )
          }
        })
      })
      if (!exists) {
        this.props.history.push('/coding-challenges')
      }
    }
  }

  renderSelected() {
    return (
      <div
        className='uk-width-expand uk-section-small'
        style={{ position: 'relative' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          className='uk-margin-large-bottom'
        >
          <button
            type='button'
            onClick={() => {
              this.props.history.push('/coding-challenges')
              this.frameworkURL = null
              this.languageURL = null
              this.setState(
                {
                  selected: null,
                },
                () => {
                  this.forceUpdate()
                }
              )
            }}
            className='uk-button uk-button-small uk-button-secondary flex'
            style={{
              position: 'absolute',
              left: 10,
              top: 10,
            }}
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Framework List
          </button>
          {this.renderIcons(
            this.state.selected.languageName,
            this.state.selected.name,
            150,
            150
          )}
          <br />
          <h3 className='uk-margin-remove'>
            {settings.frameworks[this.state.selected.name]}
          </h3>
          <h5 className='uk-margin-remove  italic'>
            {settings.engines[this.state.selected.languageName]}
          </h5>
        </div>
        <hr />
        {this.state.selected.apps.map((app) => (
          <div key={app.name}>
            <div className='flex flex-row items-center uk-margin-left'>
              <table className='uk-table uk-table-middle'>
                <thead>
                  <tr>
                    <th colSpan='2' className='uk-padding-remove-horizontal'>
                      <h4 className='uk-margin-remove'>
                        <u>{`${settings.apps[app.name]} App`}</u>
                      </h4>
                    </th>
                    <th className='text-center'>
                      <h4 className='uk-margin-remove'>
                        <u style={{ fontSize: 17 }}>Difficulty</u>
                      </h4>
                    </th>
                    <th className='text-center'>
                      <h4 className='uk-margin-remove'>
                        <u style={{ fontSize: 17 }}>Points</u>
                      </h4>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {app.content.map((content) => (
                    <tr key={content.uuid}>
                      <td className='uk-padding-remove-horizontal'>
                        <ContentIcon
                          hasCodeSubmission={content.code_submission_count > 0}
                          hasCompletedContent={!!content.completed_at}
                          hasPassedContent={!!content.passed_at}
                          hasTest={content.has_test}
                          isActiveForUser={content.isActiveForUser}
                          lessonInProgress={content.max_progress > 0}
                          license={this.props.license}
                          requiresSubscription={content.requires_subscription}
                          marginTop={0}
                        />
                      </td>
                      <td>
                        <h4 className='uk-margin-remove'>
                          <a
                            href={`/content/${content.uuid}`}
                            className='uk-link-reset'
                          >
                            {content.title}
                          </a>
                        </h4>
                      </td>
                      <td
                        className='text-center capitalize'
                        style={{ width: '15%' }}
                      >
                        {content.difficulty}
                      </td>
                      <td className='text-center' style={{ width: '15%' }}>
                        {`${
                          typeof content.score === 'number'
                            ? `${content.score} / `
                            : ''
                        }${content.points}`}
                      </td>
                      <td className='text-center' style={{ width: '25%' }}>
                        <ContentButton
                          restartText='Restart Challenge'
                          startText='Start Challenge'
                          hasCompletedContent={!!content.completed_at}
                          isActiveForUser={content.isActiveForUser}
                          lessonInProgress={content.max_progress > 0}
                          license={this.props.license}
                          requiresSubscription={content.requires_subscription}
                          small
                          url={`/content/${content.uuid}`}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
        <div style={{ height: '50vh', display: 'block' }} />
      </div>
    )
  }

  renderIcons(
    languageName,
    frameworkName,
    width = 100,
    height = 100,
    tooltip = null
  ) {
    const style = {
      width: '100%',
    }
    let path = '/static/images/frameworks/'
    switch (frameworkName) {
      case 'react':
        path = `${path}react.png`
        break
      case 'angular':
        path = `${path}angular.png`
        break
      case 'spring_boot':
        path = `${path}spring.png`
        break
      case 'node':
        path = `${path}nodejs.png`
        break
      case 'flask':
        path = `${path}flask.png`
        break
      case 'laravel':
        path = `${path}laravel.png`
        break
      case 'rails':
        path = `${path}rails.png`
        break
      case 'net_http':
        path = `${path}golang.png`
        break
      case 'core':
        if (languageName === 'php') {
          path = `${path}php.svg`
        } else {
          path = `${path}dotnetccore.png`
        }
        break
      case 'restify':
        path = `${path}restify.png`
        break
      default:
        path = null
        break
    }
    return (
      <div
        style={{
          width,
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        uk-tooltip={tooltip ? settings.frameworks[tooltip] : null}
      >
        <img src={path} alt={frameworkName} style={style} />
      </div>
    )
  }

  render() {
    if (this.props.loadingCodeReviews || !this.state.selected) {
      return <SkeletonLoader visible />
    }
    return (
      <div className='uk-hackedu-container-main'>
        <div className='uk-container'>
          <div className='uk-margin-medium-top' id='lalala'>
            <div data-uk-grid=''>
              <div className='uk-width-large'>
                <div>
                  <div
                    data-uk-sticky='offset: 150'
                    className='hackedu-content-menu'
                  >
                    <AllTrainingMenu content={this.props.codeReviews} />
                  </div>
                </div>
              </div>
              <div className='uk-width-expand'>{this.renderSelected()}</div>
            </div>
          </div>
        </div>
        <ModalContentIcon />
        <ScratchpadButton id='by-vulnerability' />
      </div>
    )
  }
}

ByCodeReview.propTypes = propTypes
ByCodeReview.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  // auth
  email: state.auth.user.email,

  // content
  content: state.content.content,
  loadingContent: state.content.loadingContent,
  loadingCodeReviews: state.content.loadingCodeReviews,
  codeReviews: state.content.codeReviews,
  featuredCodeReviews: state.content.featuredCodeReviews,

  // hacker
  organizations: state.hacker.organizationsList,
  license: state.hacker.license,
  mustPassCodingExercise: state.hacker.mustPassCodingExercise,
  showAllTraining: state.hacker.showAllTraining,
  showCodingChallenges: state.hacker.showCodingChallenges,
})
const mapDispatchToProps = (dispatch) => ({
  getContent: () => {
    dispatch(getContent())
  },
  getCodingChallenges: () => {
    dispatch(getCodingChallenges())
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ByCodeReview)
)
