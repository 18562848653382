import * as React from 'react'

export interface IconProps {
  as?: string;
  name?: string | null;
  fill?: boolean;
  className?: string | null;
  style?: object;
  disabled?: boolean;
  none?: boolean;
  ratio?: number
  onClick?: (() => void) | null;
}

export const Icon = ({
  as = 'i',
  name = null,
  fill = false,
  className = null,
  ratio = 1,
  style = {},
  onClick = null,
  none = false,
  disabled = false
}: IconProps): JSX.Element => {
  let f = false
  if (fill) {
    f = true
  }
  return React.createElement(
    as,
    {
      disabled,
      onClick: disabled ? undefined : onClick,
      className: `ri-${name}${none ? '' : (f ? '-fill' : '-line')}${className ? ` ${className}` : ''}`,
      style: {
        fontSize: 20 * ratio,
        color: as === 'a' ? 'inherit' : null,
        ...style,
        cursor: onClick && !disabled ? 'pointer' : null,
        textDecoration: as === 'a' ? 'none' : null,
      },
    },
  )
}
