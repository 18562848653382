import React from 'react'
import PropTypes from 'prop-types'

import { VBox } from 'app/views/core/Box'

import TestStageListItem from './TestStageListItem'

function resolveStageStatuses(submissionResult, stages, failedStageName) {
  if (submissionResult === 'pending' || submissionResult === 'passed') {
    return stages.map(() => submissionResult)
  }
  const failedStageIndex = stages.findIndex((x) => x.name === failedStageName)
  return stages.map((_, i) => {
    if (i < failedStageIndex) return 'passed'
    if (i == failedStageIndex) return 'failed'
    return 'skipped'
  })
}

function TestStageList(props) {
  const { submissionResult, stages, failedStageName, failedStageHelpUrl } =
    props
  const stageStatuses = resolveStageStatuses(
    submissionResult,
    stages,
    failedStageName
  )
  return (
    <VBox style={{ marginLeft: 8 }}>
      {stages.map((stage, i) => (
        <TestStageListItem
          key={i}
          status={stageStatuses[i]}
          isLastStage={i === stages.length - 1}
          title={stage.title || stage.name}
          description={stage.description || 'n/a'}
          helpUrl={stage.name === failedStageName ? failedStageHelpUrl : null}
        />
      ))}
    </VBox>
  )
}
TestStageList.propTypes = {
  submissionResult: PropTypes.oneOf(['pending', 'passed', 'failed']).isRequired,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  failedStageName: PropTypes.string,
  failedStageHelpUrl: PropTypes.string,
}

export default TestStageList
