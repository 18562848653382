import React from 'react'
import JsPDF from 'jspdf'
import UIkit from 'uikit'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import {
  getCertificatesOrganization,
  getTeamDetails,
} from 'app/state/modules/hacker'

const propTypes = {
  courseName: PropTypes.string,
  className: PropTypes.string,
}

const defaultProps = {
  courseName: 'Secure Development Training',
  className: '',
}

const NAMES_PAGE = 20
const INITIAL_Y_POS = 45

class GenerateOrganizationCertificates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
    this.modal = null
    this.handleLinkClick = this.handleLinkClick.bind(this)
    this.generateRecursivePages = this.generateRecursivePages.bind(this)
    this.generateListOfCertificates = this.generateListOfCertificates.bind(this)
    this.onRefModal = this.onRefModal.bind(this)
  }

  componentDidMount() {
    if (!this.props.currentTeamDetails || !this.props.currentTeamDetails.name) {
      this.props.getTeamDetails(this.props.organizationUUID)
    }
  }

  onRefModal(ref) {
    this.modalError = ref
  }

  handleLinkClick(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    if (!this.state.isLoading) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.props.getCertificatesOrganization(
            this.props.organizationUUID,
            this.props.teamUUID,
            () => {
              if (this.props.teamCertificates.length === 0) {
                this.setState({
                  isLoading: false,
                })
                return UIkit.modal.alert(
                  '<h2>No Certificate Available</h2><p>No one with the given filters has completed the OWASP Top 10 lessons.</p>'
                )
              }
              this.generateListOfCertificates()
            },
            this.props.days
          )
        }
      )
    }
  }

  generateListOfCertificates() {
    this.num = 1
    const totalPages = Math.ceil(
      this.props.teamCertificates.length / NAMES_PAGE
    )
    this.certificates = [...this.props.teamCertificates]
    this.doc = new JsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4',
    })
    this.doc.setProperties({
      title: `Certificate of Completion | ${this.props.courseName} | HackEDU`,
      author: 'HackEDU',
    })
    const width = this.doc.internal.pageSize.getWidth()
    const height = this.doc.internal.pageSize.getHeight()
    const Cover = new Image()
    Cover.src = '/static/images/hackedu_certificate_cover.jpg'
    this.doc.addImage(Cover, 'JPEG', 0, 0, width, height)
    this.doc.setFont(this.doc.getFont().fontName, 'bold').setFontSize(26)
    
    
    this.doc
      .setFontSize(20)
      .setTextColor(30, 135, 240)
      .text(420, 460, moment().format('MMMM Do, YYYY'), {
        align: 'center',
      })
    this.generateRecursivePages(totalPages, this.certificates, 1)
  }

  printCertificates(certificates = []) {
    const certificatesLength =
      certificates.length < NAMES_PAGE ? certificates.length : NAMES_PAGE
    let colorCounter = 1
    for (let i = 1; i <= certificatesLength; i += 1) {
      let isEven = i % 2
      let textColor
      isEven = isEven === 0

      const x = isEven ? 420 : 70

      let y = INITIAL_Y_POS
      y += i * 22.5 - (isEven ? 22.5 : 0)

      const userEmail = certificates[0].email
      const userFinishedDate = moment(
        certificates[0].last_completed_at,
        'YYYY-MM-DD HH:mm:ss'
      ).format('MMMM Do, YYYY')
      certificates.shift()
      if (colorCounter === 5) {
        colorCounter = 1
      }
      this.doc.setDrawColor(1, 59, 108)
      if ([1, 4].includes(colorCounter)) {
        this.doc.setFillColor(1, 59, 108)
        textColor = 0
        textColor = (30, 135, 240)
      } else {
        this.doc.setFillColor(248, 248, 248)
        textColor = (30, 135, 240)
      }
      colorCounter += 1
      this.doc.line(x - 10, y - 25 + 45, 750, y - 25 + 45)
      this.doc
        .setFont(this.doc.getFont().fontName, 'bold')
        .setTextColor(1, 59, 108)
        .setFontSize(11)
        .text(x, y, ` ${this.num}. ${userEmail} (${userFinishedDate})`)
      this.num = this.num + 1
    }
  }

  generateRecursivePages(totalPages = 5, certificates, page = 1) {
    this.doc.addPage('a4', 'landscape')

    const width = this.doc.internal.pageSize.getWidth()
    const height = this.doc.internal.pageSize.getHeight()
    const Page = new Image()
    Page.src = '/static/images/hackedu_certificate_page.jpg'
    this.doc.addImage(Page, 'JPEG', 0, 0, width, height)
    if (totalPages > page) {
      if (certificates.length > 0) {
        this.printCertificates(certificates)
        return this.generateRecursivePages(totalPages, certificates, page + 1)
      }
    }

    this.printCertificates(certificates)
    return this.drawPage()
  }

  drawPage() {
    this.setState({
      isLoading: false,
    })
    this.doc.save(
      `${this.props.currentTeamDetails.name || 'no_name'}.progress_report.pdf`
    )
  }

  render() {
    return (
      <>
        <div
          data-test-id={this.props['data-test-id']}
          className={this.props.className}
          style={this.props.style || {}}
          onClick={this.handleLinkClick}
          onKeyDown={this.handleLinkClick}
          role='presentation'
        >
          {this.props.children}
        </div>
        {/* <GenericModal
          ref={(ref) => { this.modal = ref }}
          id="show-date-picker"
          title="Users that Completed the OWASP plan in:"
          body={(
            <>
              <div className="flex flex-col justify-center uk-margin-top uk-padding-top items-center">
                <br />
                {this.ranges.map((r) => (
                  <button
                    onClick={() => {
                      this.setState({
                        days: r.key,
                      }, () => {
                        this.modal.hide()
                        this.handleLinkClick()
                      })
                    }}
                    key={r.key}
                    className="uk-button uk-button-secondary uk-position-relative uk-margin-small flex items-center justify-center"
                    style={{
                      width: '75%',
                    }}
                    type="button"
                  >
                    {r.name}
                    <Icon
                      style={{
                        position: 'absolute',
                        right: 10,
                      }}
                      name="history"
                    />
                  </button>

                ))}
              </div>
            </>
          )}
        /> */}
      </>
    )
  }
}

GenerateOrganizationCertificates.defaultProps = defaultProps
GenerateOrganizationCertificates.propTypes = propTypes

const mapStateToProps = (state) => ({
  // hacker
  teamCertificates: state.hacker.teamCertificates,
  currentTeamDetails: state.hacker.currentTeamDetails,
})
export default connect(mapStateToProps, {
  getCertificatesOrganization,
  getTeamDetails,
})(GenerateOrganizationCertificates)
