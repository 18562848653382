import React, { useState } from 'react'
import PropTypes from 'prop-types'

import WorkPanelTabBar from './WorkPanelTabBar'
import SandboxBrowser from './SandboxBrowser'
import CodeEditor from './CodeEditor'
import MultiFileCodeEditor from './MultiFileCodeEditor'
import ProxyHistory from './ProxyHistory'
import TestOverview from './TestOverview'
import CodeReviewSubmissionOverview from './CodeReviewSubmissionOverview'
import SandboxOutput from './SandboxOutput'

/**
 * Local layout components.
 * We only display the contents of a single tab at a time, but we keep all
 * relevant views for the lesson in memory rather than unmounting/remounting
 * and re-rendering when the active tab changes.
 */
const Container = ({ children }) => <div className='work-panel'>{children}</div>
const TabView = ({ show, children }) => (
  <div
    style={{
      display: show ? 'block' : 'none',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 0,
    }}
  >
    {children}
  </div>
)

/**
 * Panel on the right side of the content UI: contains a row of tabs along the
 * top edge, allowing the user to switch between different central views, as
 * well as a terminal window for output. Depending on the lesson, tabs can
 * include: one or more browser tabs, for rendering HTML from sandbox apps; a
 * code editor for editing and submitting repl patches; a record of all
 * requests made through the proxy; and a summary of tests run against
 * submitted repl patches.
 */
function WorkPanel(props) {
  const {
    contentId,
    contentUsesRealUrls,
    sandboxTemplateName,
    browserTabs,
    hasCode,
    hasMultiFileCode,
    codeEditorProps,
    hasProxy,
    hasTests,
    hasMultiFileTests,
    hasOutput,
    testStages,
    ideEnabled,
    toggleTrialModal,
    showUpgradeModal,
  } = props
  const { activeTabName, onActiveTabNameChange, numProxyRequests } = props
  const { interceptedRequest, onSubmitInterceptedRequest } = props
  const tabShouldBeVisible = (tab) => tab.name !== 'hidden'
  const tabShouldBeAccessible = (tab) =>
    tab.show_url_bar && tab.clean_url.startsWith('http')
  const visibleTabs = browserTabs.filter(tabShouldBeVisible)
  const accessibleTabs = browserTabs.filter(tabShouldBeAccessible)
  const activateTestsTab = () => onActiveTabNameChange('tests')
  const isTrialMode = window.location?.pathname?.includes('tryus')

  // The Ace editor only responds to window resize events, so when we expand or collapse
  // the "Sandbox Output" window, its layout gets screwed up. It's kind of a hack, but
  // we need to notify the code editor when this happens so we can call editor.resize()
  // on the Ace instance, which will force it to update its layout.
  const [codeEditorResizeAccumulator, setCodeEditorResizeAccumulator] =
    useState(0)
  const onSandboxOutputLayoutChange = () =>
    setCodeEditorResizeAccumulator((x) => x + 1)

  return (
    <Container>
      <WorkPanelTabBar
        contentId={contentId}
        sandboxTemplateName={sandboxTemplateName}
        browserTabs={visibleTabs}
        hasCode={hasCode}
        hasProxy={hasProxy}
        hasTests={hasTests}
        hasMultiFileTests={hasMultiFileTests}
        activeTabName={activeTabName}
        onActiveTabNameChange={onActiveTabNameChange}
        numProxyRequests={numProxyRequests}
        ideEnabled={ideEnabled}
      />
      {visibleTabs.map((browserTab, i) => (
        <TabView key={`browser-${i}`} show={activeTabName === `browser-${i}`}>
          <SandboxBrowser
            tabIndex={i}
            accessibleTabs={accessibleTabs}
            appName={browserTab.app_name}
            useRealUrl={contentUsesRealUrls}
            isFocused={activeTabName === `browser-${i}`}
            isProxied={browserTab.proxy}
            showUrlBar={browserTab.show_url_bar}
            cleanUrl={browserTab.clean_url}
            interceptedRequest={browserTab.proxy ? interceptedRequest : null}
            onSubmitInterceptedRequest={onSubmitInterceptedRequest}
          />
        </TabView>
      ))}
      {/*
        TODO: Having WorkPanel know all about single-file vs. multi-file editors etc.
        is kind of a smell; ideally WorkPanel should just be a layout-only component
        into which we slot whatever child components we want to mount as tabs.
      */}
      {hasCode && !ideEnabled && (
        <TabView show={activeTabName === 'code'}>
          {hasMultiFileCode ? (
            <MultiFileCodeEditor
              resizeAccumulator={codeEditorResizeAccumulator}
              {...codeEditorProps}
            />
          ) : (
            <CodeEditor
              resizeAccumulator={codeEditorResizeAccumulator}
              activateTestsTab={activateTestsTab}
              isTrialMode={isTrialMode}
              toggleTrialModal={toggleTrialModal}
              {...codeEditorProps}
            />
          )}
        </TabView>
      )}
      {hasProxy && (
        <TabView show={activeTabName === 'proxy'}>
          <ProxyHistory />
        </TabView>
      )}
      {hasTests &&
        (hasMultiFileTests ? (
          <TabView show={activeTabName === 'submissions'}>
            <CodeReviewSubmissionOverview />
          </TabView>
        ) : (
          <TabView show={activeTabName === 'tests'}>
            <TestOverview
              stages={testStages}
              toggleTrialModal={toggleTrialModal}
              showUpgradeModal={showUpgradeModal}
              isTrialMode={isTrialMode}
            />
          </TabView>
        ))}
      {/* We have to put a div at the bottom of ths container, regardless of whether we actually
       * need to render an output window, in order to keep the bottom edge of the VScroll
       * components (used in the proxy history and tests tabs) from extending past the bottom of
       * the window. Without this div, those scroll areas would need to be positioned with
       * 'bottom: 60px' instead of 'bottom: 0'. TODO: clean up webapp page layout; embrace flexbox
       * from the top down for full-screen views; render kludgy CSS hacks unnecessary; delete this
       * comment.
       */}
      {hasOutput ? (
        <SandboxOutput
          hasCode={hasCode}
          hasMultiFileCode={hasMultiFileCode}
          onLayoutChange={onSandboxOutputLayoutChange}
        />
      ) : (
        <div style={{ flexBasis: '0px' }} />
      )}
    </Container>
  )
}
WorkPanel.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentUsesRealUrls: PropTypes.bool.isRequired,
  sandboxTemplateName: PropTypes.string,
  browserTabs: PropTypes.arrayOf(PropTypes.object),
  hasCode: PropTypes.bool.isRequired,
  hasMultiFileCode: PropTypes.bool.isRequired,
  codeEditorProps: PropTypes.object.isRequired,
  hasProxy: PropTypes.bool.isRequired,
  hasTests: PropTypes.bool.isRequired,
  hasMultiFileTests: PropTypes.bool.isRequired,
  hasOutput: PropTypes.bool.isRequired,

  activeTabName: PropTypes.string.isRequired,
  onActiveTabNameChange: PropTypes.func.isRequired,

  numProxyRequests: PropTypes.number.isRequired,
  testStages: PropTypes.arrayOf(PropTypes.object),
  interceptedRequest: PropTypes.object,
  onSubmitInterceptedRequest: PropTypes.func.isRequired,
  ideEnabled: PropTypes.bool,
}

export default WorkPanel
