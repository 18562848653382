import {
  getCurrentMonth,
  compareMonths,
  parseMonth,
  monthToString,
} from './util'

// Organizations that will receive hardcoded mock data instead of hitting the API
const ORG_UUIDS_WITH_MOCK_DATA = [
  'cece66f1-b10e-4c01-af45-6a389007a67b',
  '62be9d9c-794f-4bd2-b326-86326adcbf9e', // Acme Corp
]

// BEGIN GENERATED DATA (impact_report_mock_data.py)
const MOCK_ISSUE_SOURCES = {
  GitHub: ['ef2d0a77-d90c-4c50-bc5a-539665fdde31'],
  HackerOne: ['c328e0e5-b9ec-41a9-8588-460f04ade696'],
}
const MOCK_MAX_POSSIBLE_COMPLETIONS = {
  1: 624,
  2: 520,
  3: 156,
  4: 520,
  5: 416,
  6: 208,
  7: 312,
  8: 208,
}
const MOCK_COMPLETION_DATA = {
  '2020-01': { 1: 3, 2: 4, 3: 3, 4: 3, 5: 2, 6: 1, 7: 2, 8: 1 },
  '2020-02': { 1: 5, 2: 8, 3: 5, 4: 5, 5: 4, 6: 2, 7: 6, 8: 3 },
  '2020-03': { 1: 11, 2: 10, 3: 10, 4: 11, 5: 5, 6: 4, 7: 6, 8: 3 },
  '2020-04': { 1: 23, 2: 22, 3: 20, 4: 10, 5: 18, 6: 6, 7: 10, 8: 6 },
  '2020-05': { 1: 34, 2: 14, 3: 8, 4: 8, 5: 6, 6: 5, 7: 10, 8: 4 },
  '2020-06': { 1: 20, 2: 15, 3: 12, 4: 8, 5: 8, 6: 4, 7: 6, 8: 3 },
  '2020-07': { 1: 37, 2: 11, 3: 21, 4: 11, 5: 11, 6: 4, 7: 8, 8: 5 },
  '2020-08': { 1: 51, 2: 18, 3: 37, 4: 9, 5: 10, 6: 7, 7: 9, 8: 7 },
  '2020-09': { 1: 37, 2: 16, 3: 37, 4: 14, 5: 16, 6: 5, 7: 11, 8: 6 },
  '2020-10': { 1: 24, 2: 9, 3: 17, 4: 10, 5: 9, 6: 3, 7: 6, 8: 4 },
  '2020-11': { 1: 12, 2: 8, 3: 10, 4: 6, 5: 8, 6: 4, 7: 10, 8: 3 },
  '2020-12': { 1: 9, 2: 7, 3: 7, 4: 7, 5: 4, 6: 2, 7: 4, 8: 2 },
}
const MOCK_VULNERABILITY_DATA = {
  '2020-02': {
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': { 1: 3, 2: 1, 3: 3, 4: 3, 5: 2 },
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      1: 1,
      2: 1,
      3: 5,
      4: 1,
      5: 5,
      6: 3,
    },
  },
  '2020-03': {
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      1: 2,
      2: 5,
      3: 10,
      4: 5,
      5: 3,
      6: 2,
    },
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': {
      1: 7,
      2: 6,
      3: 11,
      4: 4,
      5: 9,
      6: 2,
    },
  },
  '2020-04': {
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      1: 18,
      2: 3,
      3: 16,
      4: 6,
      5: 9,
      6: 8,
      7: 4,
    },
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': {
      1: 17,
      2: 6,
      3: 9,
      4: 5,
      5: 12,
      6: 5,
      7: 3,
    },
  },
  '2020-05': {
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': {
      1: 12,
      2: 6,
      3: 13,
      4: 8,
      5: 13,
      6: 5,
    },
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      1: 15,
      2: 3,
      3: 14,
      4: 1,
      5: 13,
      6: 5,
    },
  },
  '2020-06': {
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': {
      1: 10,
      2: 4,
      3: 10,
      4: 7,
      5: 8,
      6: 2,
    },
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      1: 6,
      2: 8,
      3: 9,
      4: 3,
      5: 6,
      6: 1,
    },
  },
  '2020-07': {
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': { 1: 1, 2: 2, 3: 2, 5: 3, 6: 1 },
    'c328e0e5-b9ec-41a9-8588-460f04ade696': { 1: 1, 3: 2, 4: 1 },
  },
  '2020-08': {
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      1: 5,
      2: 7,
      3: 6,
      4: 6,
      5: 6,
      6: 2,
      7: 3,
    },
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': {
      1: 4,
      2: 7,
      3: 3,
      4: 2,
      5: 4,
      6: 4,
      7: 3,
    },
  },
  '2020-09': {
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': {
      1: 3,
      2: 3,
      3: 4,
      4: 2,
      5: 6,
      6: 2,
      7: 2,
    },
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      2: 4,
      3: 4,
      4: 5,
      5: 4,
      6: 2,
      7: 1,
    },
  },
  '2020-10': {
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      1: 2,
      2: 3,
      3: 2,
      4: 4,
      5: 7,
      6: 4,
    },
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': {
      1: 2,
      2: 7,
      3: 3,
      4: 7,
      5: 10,
      6: 2,
    },
  },
  '2020-11': {
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': { 1: 2, 2: 1, 3: 2, 4: 3, 5: 2 },
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      2: 3,
      4: 1,
      5: 2,
      6: 2,
      7: 2,
      8: 2,
    },
  },
  '2020-12': {
    'ef2d0a77-d90c-4c50-bc5a-539665fdde31': { 1: 1, 3: 1, 4: 1, 5: 3, 6: 1 },
    'c328e0e5-b9ec-41a9-8588-460f04ade696': {
      1: 1,
      2: 2,
      3: 1,
      4: 3,
      5: 1,
      6: 1,
    },
  },
}
// END GENERATED DATA

function findEarliestMonth(data) {
  let earliestMonth = null
  for (const monthKey of Object.keys(data)) {
    const month = parseMonth(monthKey)
    if (earliestMonth === null || compareMonths(month, earliestMonth) < 0) {
      earliestMonth = month
    }
  }
  return earliestMonth
}

function findLatestMonth(data) {
  let latestMonth = null
  for (const monthKey of Object.keys(data)) {
    const month = parseMonth(monthKey)
    if (latestMonth === null || compareMonths(month, latestMonth) > 0) {
      latestMonth = month
    }
  }
  return latestMonth
}

/**
 * Shifts our mock data forward in time so that it always appears up to date.
 */
function shiftToThisMonth(data) {
  // Find the latest month for which we have data in our mock dataset
  const latestMonthInVulnerabilityData = findLatestMonth(
    MOCK_VULNERABILITY_DATA
  )
  const latestMonthInCompletionData = findLatestMonth(MOCK_COMPLETION_DATA)
  const latestMonth =
    compareMonths(latestMonthInVulnerabilityData, latestMonthInCompletionData) >
    0
      ? latestMonthInVulnerabilityData
      : latestMonthInCompletionData

  // If our mock data is empty or newer than this month, do nothing
  const cur = getCurrentMonth()
  if (latestMonth === null || compareMonths(latestMonth, cur) >= 0) {
    return data
  }

  // Figure out how many months out-of-date our mock data is
  const deltaMonths =
    (cur.year - latestMonth.year) * 12 + (cur.month - latestMonth.month)

  // For every data point, slide the 'YYYY-MM' month key forward by that fixed amount
  const shiftMonth = (monthKey) => {
    const month = parseMonth(monthKey)
    let newMonth = {
      year: month.year,
      month: month.month + deltaMonths,
    }
    while (newMonth.month > 12) {
      newMonth.month -= 12
      newMonth.year++
    }
    return monthToString(newMonth)
  }
  let newData = {}
  for (const monthKey of Object.keys(data)) {
    newData[shiftMonth(monthKey)] = data[monthKey]
  }
  return newData
}

export function shouldUseMockData(organizationUUID) {
  return ORG_UUIDS_WITH_MOCK_DATA.includes(organizationUUID)
}

export async function mockRequest(data) {
  const delay = 50 + Math.random() * 100
  await new Promise((resolve) => setTimeout(resolve, delay))
  return data
}

export function getMockIssueSources() {
  return MOCK_ISSUE_SOURCES
}

export function getMockMaxPossibleCompletions() {
  return MOCK_MAX_POSSIBLE_COMPLETIONS
}

export function getMockVulnerabilityData(startMonth, endMonth) {
  const mockData = shiftToThisMonth(MOCK_VULNERABILITY_DATA)
  const actualStartMonth = startMonth ? startMonth : findEarliestMonth(mockData)
  const actualEndMonth = endMonth ? endMonth : findLatestMonth(mockData)
  let result = {
    start: startMonth ? monthToString(startMonth) : null,
    end: endMonth ? monthToString(endMonth) : null,
    data: {},
  }
  for (const monthKey of Object.keys(mockData)) {
    const month = parseMonth(monthKey)
    if (
      compareMonths(month, actualStartMonth) >= 0 &&
      compareMonths(month, actualEndMonth) <= 0
    ) {
      result.data[monthKey] = mockData[monthKey]
    }
  }
  return result
}

export function getMockCompletionData(startMonth, endMonth) {
  const mockData = shiftToThisMonth(MOCK_COMPLETION_DATA)
  const actualStartMonth = startMonth ? startMonth : findEarliestMonth(mockData)
  const actualEndMonth = endMonth ? endMonth : findLatestMonth(mockData)
  let result = {
    start: startMonth ? monthToString(startMonth) : null,
    end: endMonth ? monthToString(endMonth) : null,
    data: { '.initial': {} },
  }
  for (const [monthKey, monthData] of Object.entries(mockData)) {
    const month = parseMonth(monthKey)
    if (compareMonths(month, actualStartMonth) >= 0) {
      if (compareMonths(month, actualEndMonth) <= 0) {
        result.data[monthKey] = monthData
      }
    } else {
      for (const [categoryIdStr, numCompletions] of Object.entries(monthData)) {
        result.data['.initial'][categoryIdStr] =
          (result.data['.initial'][categoryIdStr] || 0) + numCompletions
      }
    }
  }
  return result
}
