import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'app/views/components/GustavoIcon'

import { HBox } from 'app/views/core/Box'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import ContentModal from '../../common/ContentModal'

const CheckIcon = () => (
  <Icon name='checkbox-circle' ratio={2} style={{ color: '#1e87f0' }} />
)
function CompleteChallengeModal(props) {
  const { onClickDone, onClose } = props
  return (
    <ContentModal id='complete-hacking-challenge-modal' onClose={onClose}>
      <div style={{ padding: 40 }}>
        <h2 style={{ marginBottom: 0 }}>Challenge Complete!</h2>
        <HBox style={{ margin: 10, alignItems: 'center' }}>
          <CheckIcon />
          <p style={{ marginLeft: 10 }}>
            Congratulations! You've submitted the correct flag.
          </p>
        </HBox>
        <PrimaryButton onClick={onClickDone} />
      </div>
    </ContentModal>
  )
}
CompleteChallengeModal.propTypes = {
  onClickDone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CompleteChallengeModal
