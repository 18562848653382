import React from 'react'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Link, withRouter } from 'react-router-dom'
import { Button } from 'app/views/components/GustavoButton'
import UIkit from 'uikit'
import settings from 'settings'
import cx from 'classnames'

// Components
import ChallengeProof from 'app/views/components/ChallengeProof'
import ModalCompletedLesson from 'app/views/components/Modals/ModalCompletedLesson'
import ModalHint from 'app/views/components/Modals/ModalHint'
import SubscriptionHelp from 'app/views/components/Subscription/SubscriptionHelp'

import {
  replGetDefaultEngineLS,
  replSetDefaultEngineLS,
  replSetEngine,
} from 'app/state/modules/repl'

// Utils
import analytics from 'app/views/utils/analytics'
import ContentStatus from '../ContentStatus'
import EngineDropdown from '../EngineDropdown'

const NavBar = ({
  contentType,
  switcherTopPosition,
  steps,
  currentProgress,
  handleClickCircle,
  k8sEnabled,
}) => {
  return (
    <ul
      className={cx(
        'uk-dotnav',
        `top-[${switcherTopPosition + (k8sEnabled ? 0 : 80)}]px`,
        {
          'uk-dotnav-vertical uk-margin-small-left': contentType !== 'article',
          absolute: contentType !== 'article',
          'left-0': contentType !== 'article',
        }
      )}
      data-uk-switcher='connect: #switcher-content'
    >
      {steps.map((step, index) => (
        <li
          key={step.id}
          className={cx(
            'flex align-center justify-start text-sm text-emphasis',
            {
              'uk-active':
                currentProgress === index ||
                (currentProgress === undefined && index === 0),
            }
          )}
        >
          <button
            className='p-0 mr-2.5 cursor-pointer'
            type='button'
            onClick={() => {
              handleClickCircle(index)
            }}
          />
        </li>
      ))}
    </ul>
  )
}
class LessonSteps extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: null, // only use this for filtering hints and showing product tour
      hide: false,
    }
    this.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]'
      })(
        !window['safari'] ||
          (typeof safari !== 'undefined' && safari.pushNotification)
      )
    this.handleClickBack = this.handleClickBack.bind(this)
    this.handleClickCircle = this.handleClickCircle.bind(this)
    this.handleClickDeveloperTraining =
      this.handleClickDeveloperTraining.bind(this)
    this.handleClickDone = this.handleClickDone.bind(this)
    this.handleClickNext = this.handleClickNext.bind(this)
    this.handleOnReset = this.handleOnReset.bind(this)
    this.handleEngineChange = this.handleEngineChange.bind(this)
    this.getTopPositionForNavBar = this.getTopPositionForNavBar.bind(this)
    this.switcherTopPosition = null
  }

  componentDidMount() {
    if (this.props.contentType === 'lesson') {
      while (!this.switcherTopPosition) {
        this.getTopPositionForNavBar()
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentProgress !== null &&
      nextProps.currentProgress !== (this.props.steps || []).length &&
      this.state.currentStep === null
    ) {
      this.setState({
        currentStep: nextProps.currentProgress + 1,
      })
    }
  }

  getTopPositionForNavBar() {
    this.lessonTitle = document.getElementById('lesson-title')
    if (this.lessonTitle) {
      const clientRect = this.lessonTitle.getBoundingClientRect()
      this.switcherTopPosition = clientRect.height + 40 + 20
      this.forceUpdate()
    }
  }

  handleOnReset() {
    this.props.onSandboxReset()
  }

  handleClickBack(index) {
    if (this.props.onClickBack !== undefined) {
      this.props.onClickBack(index - 1)
    }
    // update currentStep state for hint filtering
    this.setState({ currentStep: this.state.currentStep - 1 }, () => {
      if (this.isSafari) {
        document.getElementsByTagName('html')[0].style.width = '99.5%'
        setTimeout(() => {
          document.getElementsByTagName('html')[0].style.width = '100%'
        }, 10)
      }
    })
    if (this.props.lessonStepContainerRef) {
      this.props.lessonStepContainerRef.scrollTop = 0
      this.props.lessonStepContainerRef.scrollLeft = 0
    }
    if (this.props.contentType === 'article') {
      document.getElementsByTagName('html')[0].scrollTop = 0
      document.getElementsByTagName('html')[0].scrollLeft = 0
    }
  }

  handleClickCircle(index) {
    if (this.props.onClickBack !== undefined) {
      this.props.onClickCircle(index)
    }

    // update currentStep state for hint filtering
    this.setState({ currentStep: index + 1 }, () => {
      if (this.isSafari) {
        document.getElementsByTagName('html')[0].style.width = '99.5%'
        setTimeout(() => {
          document.getElementsByTagName('html')[0].style.width = '100%'
        }, 10)
      }
    })
  }

  handleClickDeveloperTraining() {
    analytics.track('Learn More (CTA)')
    window.location.assign(`${settings.urls.www}/secure-development-training`)
  }

  handleClickDone(index) {
    this.modalCompleted.show()
    if (this.props.onClickDone !== undefined) {
      this.props.onClickDone(index + 1)
    }
  }

  handleEngineChange(e) {
    const engine = e.target.value
    analytics.track('click-change-engine', {
      lessonId: this.props.match.params.id,
      taskId: this.props.ecsTaskDefinition,
      engine,
    })
    this.setState(
      {
        patchedCode: '',
      },
      () => {
        this.props.replSetEngine(engine)
      }
    )
  }

  handleClickNext(index) {
    if (this.props.onClickNext !== undefined) {
      this.props.onClickNext(index + 1)
    }

    this.setState({ currentStep: this.state.currentStep + 1 }, () => {
      if (this.isSafari) {
        document.getElementsByTagName('html')[0].style.width = '99.5%'
        setTimeout(() => {
          document.getElementsByTagName('html')[0].style.width = '100%'
        }, 10)
      }
    })
    if (this.props.lessonStepContainerRef) {
      this.props.lessonStepContainerRef.scrollTop = 0
      this.props.lessonStepContainerRef.scrollLeft = 0
    }
    if (this.props.contentType === 'article') {
      document.getElementsByTagName('html')[0].scrollTop = 0
      document.getElementsByTagName('html')[0].scrollLeft = 0
    }
  }

  render() {
    const stepKey = this.state.currentStep - 1
    let barCss = {}
    let dropdownCss = {}
    if (this.props.displayBar === true) {
      barCss = { background: '#EEEEEE', margin: '10px 0px' }
      dropdownCss = { backgroundColor: '#EEEEEE' }
    }
    const { contentDetails } = this.props
    const { k8s_enabled: k8sEnabled } =
      contentDetails[this.props.match.params.id] || {}
    const defaultEngine = replGetDefaultEngineLS()
    return (
      <>
        {Object.keys(this.props).includes('steps') &&
          (this.props.steps || []).length > 0 &&
          (this.props.contentType === 'article' ? (
            <div className='flex justify-center items-center uk-padding'>
              <NavBar
                contentType='article'
                currentProgress={this.props.currentProgress}
                steps={this.props.steps}
                handleClickCircle={this.handleClickCircle}
                switcherTopPosition={this.switcherTopPosition}
              />
            </div>
          ) : (
            <NavBar
              k8sEnabled={k8sEnabled}
              currentProgress={this.props.currentProgress}
              steps={this.props.steps}
              handleClickCircle={this.handleClickCircle}
              switcherTopPosition={this.switcherTopPosition}
            />
          ))}
        <div
          className={cx({
            'uk-container uk-padding-small': this.props.noPadding,
          })}
          id='skill-steps'
        >
          {!this.props.noTitle && (
            <h1
              id='lesson-title'
              style={{
                fontSize: k8sEnabled ? 27 : undefined,
              }}
            >
              {this.props.title}
            </h1>
          )}
          {k8sEnabled && (this.props.engines || []).length > 0 && (
            <EngineDropdown
              onClickDefault={() => {
                replSetDefaultEngineLS(this.props.engine)
                this.forceUpdate()
              }}
              defaultEngine={defaultEngine}
              onChangeEngine={this.handleEngineChange}
              engine={this.props.engine}
              engines={this.props.engines}
            />
          )}
          <div className='uk-margin-small-bottom' id='steps-container'>
            {this.props.description !== '' &&
              (this.props.contentType === 'practice' ||
                this.props.contentType === 'hacking_challenge') && (
                <p>{this.props.description}</p>
              )}
            {Object.keys(this.props).includes('steps') &&
              (this.props.steps || []).length > 0 && (
                <div id='skill-steps' className='uk-position-relative'>
                  {this.state.currentStep === 1 &&
                    this.props.ecsTaskDefinition === 'was1_sqli_1' && (
                      <button
                        className='uk-button uk-button-primary uk-margin-top'
                        onClick={() => {
                          if (this.props.onProductTourRequested) {
                            this.props.onProductTourRequested()
                          } else {
                            UIkit.modal('#modal-lesson-tour').show()
                          }
                        }}
                        type='button'
                      >
                        Product Tour
                      </button>
                    )}
                  <ul id='switcher-content' className='uk-switcher uk-margin'>
                    {this.props.steps.map((step, index) => (
                      <li key={step.id}>
                        <div>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            linkTarget='_blank'
                            children={step.markdown}
                          />
                        </div>
                        {(step.hints || []).length > 0 &&
                          (this.props.hasHint || this.props.skillArea) &&
                          (step.hints.length > 0 ||
                            (this.props.hints || []).length > 0) && (
                            <div>
                              <button
                                className={cx('uk-button', {
                                  'uk-margin-top': this.props.hasHint,
                                })}
                                data-uk-toggle={
                                  this.props.hasHint
                                    ? '#modal-code-review'
                                    : `#modal-hint-${step.id}`
                                }
                                id='hint-button'
                                onClick={this.props.onHintClick}
                                style={{
                                  width: '100%',
                                  backgroundColor: '#e9ecef',
                                  color: '#333',
                                }}
                                type='button'
                              >
                                Hint
                              </button>
                              {!this.props.hasHint && (
                                <ModalHint
                                  hints={this.props.hints || step.hints}
                                  stepId={step.id}
                                />
                              )}
                            </div>
                          )}
                        {!this.props.noButtons && (
                          <>
                            <div className='uk-margin'>
                              {index > 0 && (
                                <button
                                  className='uk-button uk-button-muted uk-button-small'
                                  data-uk-switcher-item={index - 1}
                                  onClick={() => {
                                    this.handleClickBack(index)
                                  }}
                                  data-test-id='lesson-steps-back-button'
                                  type='button'
                                >
                                  Back
                                </button>
                              )}
                              {index < (this.props.steps || []).length - 1 && (
                                <button
                                  data-test-id='lesson-steps-next-button'
                                  className='uk-button uk-button-secondary uk-button-small uk-align-right'
                                  data-uk-switcher-item='next'
                                  onClick={() => {
                                    this.handleClickNext(index)
                                  }}
                                  type='button'
                                >
                                  Next
                                </button>
                              )}
                              {index ===
                                (this.props.steps || []).length - 1 && (
                                <button
                                  type='button'
                                  className='uk-button uk-button-primary uk-button-small uk-align-right'
                                  onClick={() => {
                                    this.handleClickDone(index)
                                  }}
                                  data-uk-toggle='target: #modal-completed-lesson'
                                >
                                  Done
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>

                  {!this.props.noModal && (
                    <ModalCompletedLesson
                      onRef={(ref) => {
                        this.modalCompleted = ref
                      }}
                      title={this.props.title}
                      confirmURL={this.props.lessonCompletionURL}
                      confirmText={this.props.lessonCompletionText}
                      latestCodePassed={this.props.latestCodePassed}
                      latestCodePatchId={this.props.latestCodePatchId}
                      mustPassCodingExercise={this.props.mustPassCodingExercise}
                      hasTest={this.props.hasTest}
                      ecsTaskDefinition={this.props.ecsTaskDefinition}
                      contentId={this.props.contentId}
                      codeSubmissions={this.props.codeSubmissions}
                    />
                  )}
                </div>
              )}
          </div>
        </div>
        <br />
        <br />
        {this.props.contentType !== 'article' && k8sEnabled && (
          <>
            <ContentStatus />
            {!this.props.hideRestart && (
              <div className='uk-flek justify-center items-center uk-margin-top'>
                <Button
                  fullwidth
                  content='Reset Sandbox'
                  onClick={this.props.handleSandboxReset}
                />
              </div>
            )}
          </>
        )}
        {this.props.contentType === 'vulnerability' && (
          <div className='uk-card uk-card-default uk-card-body text-center uk-margin-medium-top'>
            <h3 className='uk-margin-small-top'>Like what you see?</h3>
            <p>
              Learn more about the lessons in our Secure Development Training.
            </p>
            <button
              type='button'
              className='uk-button uk-button-primary'
              onClick={this.handleClickDeveloperTraining}
            >
              Learn More
            </button>
          </div>
        )}

        {this.props.contentType === 'hacking_challenge' && (
          <div>
            <ChallengeProof
              id={this.props.contentId}
              userId={this.props.userId}
            />
            <SubscriptionHelp contentType={this.props.contentType} />
          </div>
        )}

        {this.props.contentType === 'practice' && (
          <SubscriptionHelp contentType={this.props.contentType} />
        )}
      </>
    )
  }
}

const mapStateToProps = ({ content, repl }) => ({
  contentDetails: content.contentDetails,
  engine: repl.engine,
})

export default withRouter(
  connect(mapStateToProps, {
    replSetEngine,
  })(LessonSteps)
)
