import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import UIkit from 'uikit'

// Components
import GenerateCertificate from 'app/views/components/GenerateCertificate'
import Icon from 'app/views/components/Icon'
import ModalSetUsername from 'app/views/components/Modals/ModalSetUsername'
import ToggleSwitch from 'app/views/components/ToggleSwitch'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'
import PrimaryCheckBox from 'app/views/components/Inputs/PrimaryCheckBox'

// Redux
import {
  updateSendReminders,
  getHackerDetails,
  updateScratchpadStatus,
} from 'app/state/modules/hacker'
// Utils
import analytics from 'app/views/utils/analytics'
import ModalPreferLanguages from 'app/views/components/Modals/ModalPreferLanguages'
import settings from 'settings'

const propTypes = {
  license: PropTypes.bool,
}
const defaultProps = {
  license: false,
}

class Profile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sendReminders:
        typeof this.props.profile.send_reminders === 'boolean'
          ? this.props.profile.send_reminders
          : false,
      scratchpad:
        this.props.profile.settings.scratchpad &&
        typeof this.props.profile.settings.scratchpad.enabled === 'boolean'
          ? this.props.profile.settings.scratchpad.enabled
          : false,
    }

    this.handleSendRemindersChange = this.handleSendRemindersChange.bind(this)
    this.handleChangeScratchpadStatus =
      this.handleChangeScratchpadStatus.bind(this)
  }

  componentDidMount() {
    analytics.page('page-profile')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile.send_reminders !== this.state.sendReminders) {
      this.setState({ sendReminders: nextProps.profile.send_reminders })
    }
    if (
      nextProps.profile.settings.scratchpad &&
      typeof nextProps.profile.settings.scratchpad.enabled === 'boolean' &&
      nextProps.profile.settings.scratchpad.enabled !== this.state.scratchpad
    ) {
      this.setState({
        scratchpad: nextProps.profile.settings.scratchpad.enabled,
      })
    }
  }

  getLastCompletedDate(courses) {
    let lastCompletedDate = null
    courses.forEach((course) => {
      if (course.completed_at) {
        if (lastCompletedDate) {
          if (moment(course.completed_at).isAfter(lastCompletedDate)) {
            lastCompletedDate = course.completed_at
          }
        } else {
          lastCompletedDate = course.completed_at
        }
      }
    })
    return lastCompletedDate
  }

  handleChangeScratchpadStatus() {
    const enabled =
      this.props.profile.settings.scratchpad &&
      typeof this.props.profile.settings.scratchpad.enabled === 'boolean'
        ? this.props.profile.settings.scratchpad.enabled
        : false
    this.props.updateScratchpadStatus(!enabled)
  }

  handleSendRemindersChange() {
    this.props.updateSendReminders(!this.props.profile.send_reminders)
  }

  render() {
    return (
      <div
        data-test-id='profile-component'
        className='uk-background-muted'
        style={{ minHeight: 'calc(100vh - 80px)' }}
      >
        <div className='uk-section'>
          <div className='uk-container'>
            <div className='uk-child-width-expand@s' data-uk-grid>
              <div className='uk-width-1-3@m'>
                <div
                  data-test-id='username-manage-container'
                  className='uk-card uk-card-default uk-card-body'
                >
                  <div className='text-center'>
                    <Icon name='user' ratio={3.5} />
                  </div>
                  <h4>{this.props.profile.email}</h4>
                  {this.props.profile.username ? (
                    <div>
                      <p>
                        {this.props.profile.username}
                        &nbsp; (
                        <Link
                          data-test-id='change-username-btn'
                          to='#'
                          data-uk-toggle='#modal-set-username'
                          className='text-green hover:text-green'
                        >
                          Change Username
                        </Link>
                        )
                      </p>
                    </div>
                  ) : (
                    <button
                      data-test-id='set-username-btn'
                      type='button'
                      className='font-medium rounded-full text-white py-2 px-4 bg-green hover:bg-green border-none cursor-pointer'
                      data-uk-toggle='#modal-set-username'
                    >
                      Set a Username
                    </button>
                  )}
                </div>

                <div className='uk-card uk-card-default uk-card-body uk-margin'>
                  <h4>Communication Preferences</h4>
                  <div>
                    <PrimaryCheckBox
                      checked={this.state.sendReminders}
                      label='Receive Reminder Emails'
                      onChange={this.handleSendRemindersChange}
                    />
                  </div>
                </div>

                <div className='uk-card uk-card-default uk-card-body uk-margin'>
                  <h4>User Settings</h4>
                  <div>
                    <div className='flex'>
                      <ToggleSwitch
                        onChange={this.handleChangeScratchpadStatus}
                        checked={this.state.scratchpad}
                        label='Scratchpad'
                      />
                      <Icon
                        href='#'
                        type='button'
                        role='button'
                        name='information'
                        ratio={0.8}
                        onClick={(e) => {
                          e.preventDefault()
                          UIkit.modal.alert(`
                          <h2>Scratchpad</h2>
                          <p>
                            You can use the Scratchpad to take notes during lessons.
                            When enabled, the Scratchpad can be opened with the blue button on the bottom left of the lesson area.
                          </p>
                          `)
                        }}
                        style={{
                          cursor: 'pointer',
                          paddingLeft: 8,
                          verticalAlign: 'top',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='uk-card uk-card-default uk-card-body uk-margin'>
                  <h4>Coding language preferences</h4>
                  <ModalPreferLanguages
                    withButton
                    id='profile-edit-prefered-language'
                  />
                </div>
              </div>

              <div className='uk-width-2-3@m'>
                <div className='uk-card uk-card-default uk-card-body'>
                  {this.props.license === true ? (
                    <div>
                      <h4>Subscription</h4>
                      <p>
                        If you would like to cancel your subscription, email us
                        at{' '}
                        <a
                          className='text-green hover:text-green'
                          href='mailto:support@securityjourney.com'
                        >
                          support@securityjourney.com
                        </a>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <h4>Subscription</h4>
                      <p>You do not have a HackEDU Subscription</p>
                      <Link
                        to={{ pathname: `${settings.urls.www}/contact` }}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='uk-button uk-button-primary tm-button-primary'
                      >
                        Upgrade Now
                      </Link>
                    </div>
                  )}
                </div>

                <div className='uk-card uk-card-default uk-card-body uk-margin'>
                  <h4>Training</h4>
                  <div>
                    {this.props.profile.has_finished_training &&
                    typeof this.props.profile.email === 'string' ? (
                      <GenerateCertificate
                        style={{ display: 'flex' }}
                        email={this.props.profile.email}
                        completionDate={this.props.profile.last_completed_at}
                        courseName={this.props.user.plan_title}
                        className='uk-button uk-button-default'
                      >
                        <Icon
                          name='download-cloud'
                          className='uk-margin-small-right'
                        />
                        Generate Certificate
                      </GenerateCertificate>
                    ) : (
                      <p>
                        You have not completed the OWASP Top 10 training plan
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalSetUsername />
      </div>
    )
  }
}

Profile.propTypes = propTypes
Profile.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  // hacker
  license: state.hacker.license,
  profile: state.hacker.profile,
  user: state.hacker.currentHackerDetails,
})
const mapDispatchToProps = (dispatch) => ({
  updateSendReminders: (sendReminders) =>
    dispatch(updateSendReminders(sendReminders)),
  dispatchGetHackerDetails: (teamId, hackerId) =>
    dispatch(getHackerDetails(teamId, hackerId)),
  updateScratchpadStatus: (enabled) =>
    dispatch(updateScratchpadStatus(enabled)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
