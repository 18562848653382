import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Tabs from 'app/views/components/Tabs'

// Redux
import {
  getLeaderboard,
  setLeaderboardOffset,
} from 'app/state/modules/leaderboard'

// Utils
import analytics from 'app/views/utils/analytics'
import Pagination from 'app/views/components/Pagination'

class Leaderboard extends React.Component {
  constructor(props) {
    super(props)
    this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.props.getLeaderboard()

    analytics.page('page-leaderboard')
  }

  onChangePage(pageNumber) {
    this.props.setLeaderboardOffset((pageNumber - 1) * this.props.perPage)
    this.props.getLeaderboard(this.tabsComponent.getCurrentData().uuid)
  }

  renderLeaderBoard() {
    const { isOrganizationLeaderboard } = this.props
    if (this.props.loading) return null
    return (this.props.leaderboard || []).map((item) => {
      return (
        <tr
          key={`k_${item.username || item.email}`}
          style={
            item.username === this.props.username ||
            item.email === this.props.email
              ? { backgroundColor: '#edfbf6' }
              : {}
          }
        >
          <td
            style={{
              verticalAlign: 'middle',
              paddingLeft: '12.5%',
            }}
            className={`uk-width-1-${isOrganizationLeaderboard ? '3' : '2'}`}
          >
            {item.username || item.email}
          </td>
          {isOrganizationLeaderboard ? (
            <td
              style={{ verticalAlign: 'middle' }}
              className={`uk-width-1-${isOrganizationLeaderboard ? '3' : '2'}`}
            >
              <div className='text-center'>
                {(item.teams || []).length === 0 ? ' - ' : null}
                {(item.teams || [])
                  .sort((a, b) => {
                    if (a.name > b.name) {
                      return 1
                    }
                    if (a.name < b.name) {
                      return -1
                    }
                    return 0
                  })
                  .map((team) => (
                    <div key={team.uuid}>
                      <span className='uk-label uk-label-secondary'>
                        {team.name}
                      </span>
                    </div>
                  ))}
              </div>
            </td>
          ) : null}
          <td
            className={`text-center uk-width-1-${
              isOrganizationLeaderboard ? '3' : '2'
            }`}
            style={{
              verticalAlign: 'middle',
            }}
          >
            {item.points}
          </td>
        </tr>
      )
    })
  }

  render() {
    const {
      isOrganizationLeaderboard,
      organizationsList,
      offset,
      perPage,
      total,
    } = this.props
    const totalPages = Math.ceil(total / perPage)
    return (
      <div className='uk-section'>
        <div className='uk-container'>
          <div>
            <h2 className='text-center'>
              {`Leaderboard${organizationsList.length > 0 ? 's' : ''}`}
            </h2>
            <div className='text-sm text-muted text-center'>
              Score points by completing challenges.
            </div>
            <Tabs
              ref={(ref) => {
                this.tabsComponent = ref
              }}
              tabs={[
                { title: 'Global Leaderboard' },
                ...(organizationsList || []).map((organization) => ({
                  title: `${organization.name} Leaderboard`,
                  uuid: organization.uuid,
                  name: organization.name,
                })),
              ].map((leaderBoardData) => ({
                ...leaderBoardData,
                onChangeTab: () => {
                  this.props.setLeaderboardOffset(0)
                  this.props.getLeaderboard(leaderBoardData.uuid || null)
                },
              }))}
            />

            <table
              data-test-id='leaderboard-table'
              className='uk-table uk-table-striped'
              style={{ marginTop: 0 }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      paddingLeft: '12.5%',
                    }}
                  >
                    {isOrganizationLeaderboard ? 'Email' : 'Username'}
                  </th>
                  {isOrganizationLeaderboard ? (
                    <th className='text-center'>Teams</th>
                  ) : null}
                  <th className='text-center'>Points</th>
                </tr>
              </thead>
              <tbody>
                {this.props.loading ? (
                  <tr>
                    <td
                      colSpan={isOrganizationLeaderboard ? '3' : '2'}
                      style={{
                        padding: '2.5%',
                        textAlign: 'center',
                        backgroundColor: '#FFF',
                      }}
                    >
                      <div data-uk-spinner='' />
                    </td>
                  </tr>
                ) : null}
                {!this.props.loading && this.props.leaderboard.length === 0 ? (
                  <tr>
                    <td
                      className='text-center'
                      colSpan={isOrganizationLeaderboard ? '3' : '2'}
                    >
                      This page is empty
                    </td>
                  </tr>
                ) : null}
                {this.renderLeaderBoard()}
              </tbody>

              {!this.props.loading ? (
                <tfoot
                  style={{
                    backgroundColor: '#f8f8f8',
                  }}
                >
                  <tr>
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                      colSpan={isOrganizationLeaderboard ? '3' : '2'}
                    >
                      <Pagination
                        onChangePage={this.onChangePage}
                        style={{
                          display: 'inline-flex',
                        }}
                        hasArrows
                        totalPages={totalPages}
                        currentPage={offset === 0 ? 1 : offset / perPage + 1}
                      />
                    </td>
                  </tr>
                </tfoot>
              ) : null}
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // leaderboard
  leaderboard: state.leaderboard.leaderboard,
  loading: state.leaderboard.loading,

  total: state.leaderboard.totalHackers,
  perPage: state.leaderboard.perPage,
  isOrganizationLeaderboard: state.leaderboard.isOrganizationLeaderboard,
  offset: state.leaderboard.offset,

  organizationsList: state.hacker.organizationsList,

  username: state.hacker.profile.username,
  email: state.hacker.profile.email,
})
const mapDispatchToProps = (dispatch) => ({
  getLeaderboard: (organizationUUID) => {
    dispatch(getLeaderboard(organizationUUID))
  },
  setLeaderboardOffset: (offset) => {
    dispatch(setLeaderboardOffset(offset))
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Leaderboard)
)
