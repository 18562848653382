import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../.././../../components/Buttons/PrimaryButton'

function SubmissionControls(props) {
  const {
    canSaveAllFiles,
    canSubmit,
    isSubmitting,
    isSubmissionPending,
    onSaveAllFiles,
    onSubmit,
    onFocusSubmission,
    sidebarIsCollapsed,
    onToggleSidebar,
  } = props
  const submitLabel = isSubmitting
    ? 'Submitting...'
    : isSubmissionPending
    ? 'Awaiting Results...'
    : 'Submit Code'
  return (
    <div className='submission-controls'>
      {/* Only render the 'toggle sidebar' button if we have a valid callback */}
      {onToggleSidebar && (
        <div className='submission-controls-show-sidebar-button'>
          <PrimaryButton
            label={sidebarIsCollapsed ? '\u2192' : '\u2190'}
            enabled={true}
            onClick={onToggleSidebar}
          />
        </div>
      )}
      <div className='submission-controls-button-group'>
        <PrimaryButton
          label='Save All Files'
          size={'medium'}
          active={canSaveAllFiles}
          onClick={onSaveAllFiles}
        />
        {/* Only render the 'submit' button if we're meant to do something on submit */}
        {onSubmit && (
          <PrimaryButton
            size={'medium'}
            customCss='mt-5'
            label={submitLabel}
            kind={isSubmissionPending ? 'secondary' : 'primary'}
            enabled={isSubmissionPending || canSubmit}
            onClick={isSubmissionPending ? onFocusSubmission : onSubmit}
          />
        )}
      </div>
    </div>
  )
}
SubmissionControls.propTypes = {
  canSaveAllFiles: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSubmissionPending: PropTypes.bool.isRequired,
  onSaveAllFiles: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onFocusSubmission: PropTypes.func,
  sidebarIsCollapsed: PropTypes.bool.isRequired,
  onToggleSidebar: PropTypes.func,
}

export default SubmissionControls
