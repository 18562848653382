import React from 'react'
import PropTypes from 'prop-types'

function SandboxAddressField(props) {
  const { text, isEditable, onTextChange, onEnter } = props
  return (
    <div style={{ flexGrow: 1, padding: '0 10px' }}>
      <input
        type='text'
        className='uk-input uk-form-small'
        style={{ borderRadius: 8 }}
        value={text}
        disabled={!isEditable}
        onChange={(event) => onTextChange(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onEnter()
          }
        }}
      />
    </div>
  )
}
SandboxAddressField.propTypes = {
  text: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
}

export default SandboxAddressField
