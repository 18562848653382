import React from 'react'

class RightSideBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
    this.triggerHide = this.triggerHide.bind(this)
    this.triggerShow = this.triggerShow.bind(this)
  }

  triggerShow() {
    this.setState({
      show: true,
    })
  }

  triggerHide() {
    this.setState({
      show: false,
    })
  }

  render() {
    const { show } = this.state
    return (
      <>
        <div
          onClick={this.triggerHide}
          className={`_right-side-bar-overlay${show ? ' _show' : ''}`}
        />
        <div className={`_right-side-bar${show ? ' _show' : ''}`}>
          {this.props.content || this.props.children}
        </div>
      </>
    )
  }
}

export default RightSideBar
