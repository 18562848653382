import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Icon } from 'app/views/components/GustavoIcon'

function SandboxNavButton(props) {
  const { iconName, isActive, onClick } = props
  return (
    <div
      style={{
        flexBasis: 30,
        cursor: isActive ? 'pointer' : 'inherit',
        textAlign: 'center',
      }}
      onClick={() => {
        if (isActive) {
          onClick()
        }
      }}
    >
      <Icon
        name={iconName}
        className={cx({
          'text-primary': isActive,
          'text-muted': !isActive,
        })}
      />
    </div>
  )
}

SandboxNavButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default SandboxNavButton
