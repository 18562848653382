import React from 'react'
import PropTypes from 'prop-types'

const VulnerabilityIcon = ({ color }) => (
  <svg width='20' height='20'>
    <rect x='4' y='4' width='12' height='12' fill={color} />
  </svg>
)
const CompletionIcon = ({ lineColor, areaColor }) => (
  <svg width='20' height='20'>
    <rect x='0' y='9' width='20' height='2' fill={lineColor} />
    <circle
      cx='10'
      cy='10'
      r='5'
      fill={areaColor}
      stroke={lineColor}
      strokeWidth={2}
    />
  </svg>
)

/**
 * Indicates to the user which series represents vulnerabilties and which represents
 * lesson completion data. Note that we don't render this is an an actual recharts
 * <Legend />, because recharts will inconsistently screw up the positioning of the
 * legend when used in conjunction with ResponsiveContainer - sometimes the legend is
 * rendered directly on top of (and intersecting with) the chart; other times the chart
 * will shrink to accommodate the legend. Since we can't rely on Legend, we instead
 * render this custom component into a div that's overlaid on top of the entire chart.
 */
function ChartLegend(props) {
  const { vulnerabilityColor, completionLineColor, completionAreaColor } = props
  return (
    <div className='impact-report-legend'>
      <div className='impact-report-legend-row'>
        <div className='impact-report-legend-icon'>
          <VulnerabilityIcon color={vulnerabilityColor} />
        </div>
        <div className='impact-report-legend-label'>
          Vulnerabilities Identified
        </div>
      </div>
      <div className='impact-report-legend-row'>
        <div className='impact-report-legend-icon'>
          <CompletionIcon
            lineColor={completionLineColor}
            areaColor={completionAreaColor}
          />
        </div>
        <div className='impact-report-legend-label'>Lessons Completed</div>
      </div>
    </div>
  )
}
ChartLegend.propTypes = {
  vulnerabilityColor: PropTypes.string.isRequired,
  completionLineColor: PropTypes.string.isRequired,
  completionAreaColor: PropTypes.string.isRequired,
}

export default ChartLegend
