import React from 'react'
import PropTypes from 'prop-types'

import FileBrowserDirectoryItem from './FileBrowserDirectoryItem'
import FileBrowserFileItem from './FileBrowserFileItem'

function FileBrowser(props) {
  const { items, onClickDirectory, onClickFile } = props
  return (
    <div className='file-browser'>
      <div className='file-browser-scroll'>
        {items.map((item) =>
          item.type === 'directory' ? (
            <FileBrowserDirectoryItem
              key={item.data.path}
              depth={item.depth}
              name={item.data.name}
              isExpanded={item.isExpanded}
              onClick={() => onClickDirectory(item.data.path)}
            />
          ) : (
            <FileBrowserFileItem
              key={item.data.path}
              depth={item.depth}
              name={item.data.name}
              isLocked={item.isLocked}
              isOpened={item.isOpened}
              isActive={item.isActive}
              hasEdits={item.hasEdits}
              hasHints={item.hasHints}
              onClick={() => onClickFile(item.data.path)}
            />
          )
        )}
      </div>
    </div>
  )
}
FileBrowser.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['directory', 'file']),
      depth: PropTypes.number.isRequired,
      isExpanded: PropTypes.bool,
      isLocked: PropTypes.bool,
      isOpened: PropTypes.bool,
      isActive: PropTypes.bool,
      hasEdits: PropTypes.bool,
      hasHints: PropTypes.bool,
      data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  onClickDirectory: PropTypes.func.isRequired,
  onClickFile: PropTypes.func.isRequired,
}

export default FileBrowser
