/**
 * Returns a single object with 'vulnerability' and 'completion' datasets, if and only if the data
 * belongs to the organization we're configured to display data for.
 */
function getData(state, desiredOrganizationUUID) {
  return {
    vulnerability:
      state.vulnerability.organizationUUID === desiredOrganizationUUID
        ? state.vulnerability.data
        : null,
    completion:
      state.completion.organizationUUID === desiredOrganizationUUID
        ? state.completion.data
        : null,
  }
}

/**
 * Given a valid set of completion data and the first month whose data we're preparing to display,
 * returns an object containing the per-category completion counts for all lessons completed prior
 * to the start month. Every category ID that's represented in the dataset should be present in the
 * resulting object.
 */
function accumulateInitialCompletionCounts(completionData, startMonth) {
  const initialCounts = { ...completionData['.initial'] }
  for (const [monthStr, completionCountsByCategory] of Object.entries(
    completionData
  )) {
    if (monthStr === '.initial') continue
    if (compareMonths(parseMonth(monthStr), startMonth) < 0) {
      for (const [categoryIdStr, numCompletions] of Object.entries(
        completionCountsByCategory
      )) {
        const existingCount = initialCounts[categoryIdStr] || 0
        initialCounts[categoryIdStr] = existingCount + numCompletions
      }
    }
  }
  return initialCounts
}

/**
 * Given the entire dataset (vulnerability and completion), returns a {year, month} object
 * indicating the earliest data point to appear in that set. If there's no data, returns null.
 */
function getEarliestMonth(data) {
  let earliestMonth = null
  const keys = Object.keys(data.vulnerability || {}).concat(
    Object.keys(data.completion || {})
  )
  for (const month of keys.filter((x) => x !== '.initial').map(parseMonth)) {
    if (!earliestMonth || compareMonths(month, earliestMonth) < -1) {
      earliestMonth = month
    }
  }
  return earliestMonth
}

/**
 * Returns a {year, month} object reflecting the current month as of today's date.
 */
export function getCurrentMonth() {
  const today = new Date()
  return {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
  }
}

/**
 * Parses a 'YYYY-MM' string into a {year, month} object.
 */
export function parseMonth(monthString) {
  const match = monthString.match(/(\d{4})-(\d{2})/)
  if (!match) {
    throw new Error(`Unexpected month format: ${monthString}`)
  }
  return {
    year: parseInt(match[1]),
    month: parseInt(match[2]),
  }
}

/**
 * Formats a {year, month} object into a 'YYYY-MM' string.
 */
export function monthToString({ year, month }) {
  return `${year}-${String(month).padStart(2, '0')}`
}

/**
 * Compares {year, month} objects and returns -1 if lhs < rhs; 0 if lhs == rhs; or 1 if lhs > rhs.
 */
export function compareMonths(lhs, rhs) {
  if (lhs.year === rhs.year) {
    if (lhs.month === rhs.month) return 0
    return lhs.month < rhs.month ? -1 : 1
  }
  return lhs.year < rhs.year ? -1 : 1
}

/**
 * Given the details of a successful response, determines whether that response represents _all_
 * known data, with an unbounded time range. If so, creates a new object denoting the start and end
 * months for that dataset, or an 'empty' flag if there is no data at all. If the response contains
 * data bounded to a specific time range, does nothing, and simply passes through the existing
 * allTimeRange value.
 */
export function resolveAllTimeRange(state, startMonth, endMonth, data) {
  if (startMonth === null && endMonth === null) {
    let earliestMonth = null
    let latestMonth = null
    for (const monthKey of Object.keys(data)) {
      if (monthKey === '.initial') continue
      const month = parseMonth(monthKey)
      if (!earliestMonth || compareMonths(month, earliestMonth) < 0) {
        earliestMonth = month
      }
      if (!latestMonth || compareMonths(month, latestMonth) > 0) {
        latestMonth = month
      }
    }
    return {
      empty: !earliestMonth || !latestMonth,
      earliestMonth,
      latestMonth,
    }
  }
  return state.allTimeRange
}

export function getCombinedAllTimeRange(impactReportState) {
  const { vulnerability, completion } = impactReportState
  // If we haven't fetched all required data with an unbounded range, we don't know the all-time range
  if (!vulnerability.allTimeRange || !completion.allTimeRange) {
    return null
  }

  // If we've determined that there's no data whatsoever, just default to the past 12 months
  const vulnerabilityDataExists = !vulnerability.allTimeRange.empty
  const completionDataExists = !completion.allTimeRange.empty
  if (!vulnerabilityDataExists && !completionDataExists) {
    const currentMonth = getCurrentMonth()
    return {
      start: { year: currentMonth.year - 1, month: currentMonth.month },
      end: currentMonth,
    }
  }

  // If there's no completion data, but we have vulnerability data, use its time range directly
  if (!completionDataExists) {
    return {
      start: vulnerability.allTimeRange.earliestMonth,
      end: vulnerability.allTimeRange.earliestMonth,
    }
  }

  // Include the month before the start of the completion data, so we can see a nice rise from 0 lessons at the origin of the graph
  const completionEndMonth = { ...completion.allTimeRange.latestMonth }
  let completionStartMonth = { ...completion.allTimeRange.earliestMonth }
  if (completionStartMonth.month == 1) {
    completionStartMonth.year -= 1
    completionStartMonth.month = 12
  } else {
    completionStartMonth.month -= 1
  }

  // If there's no vulnerability data, use the range of the completion data directly
  if (!vulnerabilityDataExists) {
    return {
      start: completionStartMonth,
      end: completionEndMonth,
    }
  }

  // Otherwise, the combined all-time range of the data is the union of the range for each dataset
  return {
    start:
      compareMonths(
        vulnerability.allTimeRange.earliestMonth,
        completionStartMonth
      ) < 0
        ? vulnerability.allTimeRange.earliestMonth
        : completionStartMonth,
    end:
      compareMonths(
        vulnerability.allTimeRange.earliestMonth,
        completionEndMonth
      ) > 0
        ? vulnerability.allTimeRange.earliestMonth
        : completionEndMonth,
  }
}

/**
 * Returns true if [startMonth .. endMonth] is a subset of [outerSetStartMonth .. outerSetEndMonth].
 * Each month value is a {year, month} object if bounded; or null if unbounded. This checks is
 * meant for determining whether we need to fetch new data: if we already have data cached and our
 * desired date range hasn't expanded beyond the range of our original data, there's no need to
 * re-fetch a simple subset of the data we already have.
 */
export function rangeIsSubset(
  outerSetStartMonth,
  outerSetEndMonth,
  startMonth,
  endMonth
) {
  function lowerBoundHasExpanded() {
    if (outerSetStartMonth === null) return false
    if (startMonth === null) return true
    return compareMonths(startMonth, outerSetStartMonth) < 0
  }
  function uppperBoundHasExpanded() {
    if (outerSetEndMonth === null) return false
    if (endMonth === null) return true
    return compareMonths(endMonth, outerSetEndMonth) > 0
  }
  return !lowerBoundHasExpanded() && !uppperBoundHasExpanded()
}

/**
 * Given our current state and the latest information indicating what we want to display, filters
 * our underlying dataset down to a simple series for display. Returns an array of objects, each
 * with {month: 'YYYY-MM', numVulnerabilities: 0, numLessonsCompletedToDate: 0}, ordered by month.
 */
export function reduceDataForDisplay(
  state,
  desiredOrganizationUUID,
  desiredStartMonth,
  desiredEndMonth,
  desiredExcludedCategoryIds,
  desiredSelectedIssueSourceTitles
) {
  // Examine our underlying data and validate that it matches our desired org
  const data = getData(state, desiredOrganizationUUID)

  // If we have no lower bound on our time range, start from the first data point available
  let startMonth = desiredStartMonth
  if (!startMonth) {
    startMonth = getEarliestMonth(data) // may still be null if our dataset is empty
  }

  // If we have no upper bound on our time range, include all data up to the end of the current month
  let endMonth = desiredEndMonth
  if (!endMonth) {
    endMonth = getCurrentMonth()
  }

  // If we don't have a valid start month, just use a single-month range; otherwise make sure start < end
  if (!startMonth) {
    startMonth = endMonth
  } else if (compareMonths(startMonth, endMonth) > 0) {
    const tmp = startMonth
    startMonth = endMonth
    endMonth = tmp
  }

  // Prepare a fast lookup of string category IDs we want to display data for
  const excludedCategoryIdStrs = (desiredExcludedCategoryIds || []).reduce(
    (acc, x) => ({ ...acc, [String(x)]: true }),
    {}
  )
  const shouldDisplayCategory = (categoryIdStr) =>
    !excludedCategoryIdStrs[categoryIdStr]

  let allowedIssueSourceUUIDs = {}
  for (const [issueSourceTitle, uuids] of Object.entries(
    state.issueSources.data || {}
  )) {
    if (
      !desiredSelectedIssueSourceTitles ||
      desiredSelectedIssueSourceTitles.length === 0 ||
      desiredSelectedIssueSourceTitles.includes(issueSourceTitle)
    ) {
      for (const uuid of uuids) {
        allowedIssueSourceUUIDs[uuid] = true
      }
    }
  }
  const shouldIncludeIssueSource = (issueSourceUUID) =>
    !!allowedIssueSourceUUIDs[issueSourceUUID]

  // If we want cumulative completion data, accumulate total lesson completion counts per category as we iterate
  let completionCountsToDate = {}
  if (data.completion) {
    // As a starting point, collect the total completion counts for each category prior to the start month
    const initialCompletionCounts = accumulateInitialCompletionCounts(
      data.completion,
      startMonth
    )
    for (const [categoryIdStr, numLessons] of Object.entries(
      initialCompletionCounts
    )) {
      if (shouldDisplayCategory(categoryIdStr)) {
        completionCountsToDate[categoryIdStr] =
          (completionCountsToDate[categoryIdStr] || 0) + numLessons
      }
    }
  }

  // Get a list of all category IDs represented in our completion data
  const allCategoryIdStrs = data.completion
    ? [...Object.keys(data.completion['.initial'])]
    : []

  // Iterate up to and including endMonth, giving us a month-by-month series of summarized data points that we can plot
  let series = []
  let month = { ...startMonth }
  while (compareMonths(month, endMonth) <= 0) {
    // Look up the underlying data recorded for this month: data is indexed by 'YYYY-MM' key
    const monthKey = monthToString(month)
    const vulnerabilityData = (data.vulnerability || {})[monthKey]
    const completionData = (data.completion || {})[monthKey]

    // If we have any data, filter it as desired to arrive at total counts for this month
    let vulnerabilityCounts = {}
    for (const [issueSourceUUID, numVulnsByCategoryId] of Object.entries(
      vulnerabilityData || {}
    )) {
      if (shouldIncludeIssueSource(issueSourceUUID)) {
        for (const [categoryIdStr, numVulns] of Object.entries(
          numVulnsByCategoryId
        )) {
          if (shouldDisplayCategory(categoryIdStr)) {
            vulnerabilityCounts[categoryIdStr] =
              (vulnerabilityCounts[categoryIdStr] || 0) + numVulns
          }
        }
      }
    }

    // Collect completion data for this month, optionally adding to the running total if cumulative display is desired
    let completionCounts = {}
    let cumulativeCompletionCounts = { ...completionCountsToDate }
    if (completionData) {
      for (const [categoryIdStr, numLessons] of Object.entries(
        completionData
      )) {
        if (shouldDisplayCategory(categoryIdStr)) {
          completionCounts[categoryIdStr] = numLessons
          cumulativeCompletionCounts[categoryIdStr] =
            (cumulativeCompletionCounts[categoryIdStr] || 0) + numLessons
        }
      }
    } else {
      // If there's no data for this month, insert all zeroes
      for (const categoryIdStr of allCategoryIdStrs) {
        completionCounts[categoryIdStr] = 0
      }
    }
    completionCountsToDate = cumulativeCompletionCounts

    // Make sure that our data isn't sparse: i.e. insert 0's where category IDs are not represented
    if (series.length > 0) {
      for (const categoryIdStr of Object.keys(
        series[series.length - 1].completionCounts
      )) {
        if (completionCounts[categoryIdStr] === undefined) {
          completionCounts[categoryIdStr] = 0
        }
      }
    }

    series.push({
      month: monthKey,
      vulnerabilityCounts,
      completionCounts,
      cumulativeCompletionCounts,
    })

    // Increment to the next month
    if (month.month == 12) {
      month.year += 1
      month.month = 1
    } else {
      month.month += 1
    }
  }
  return series
}
