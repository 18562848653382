import React from 'react'

export default function PrimaryRadio({
  onChange,
  value,
  name,
  checked,
  label,
}) {
  return (
    <label className='flex text-sm my-1' htmlFor={name}>
      <input
        type='radio'
        className='cursor-pointer border-solid'
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className='pl-2'>{label}</span>
    </label>
  )
}
