import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import FileBrowserIcon from './FileBrowserIcon'

function FileBrowserFileItem(props) {
  const {
    depth,
    name,
    isLocked,
    isOpened,
    isActive,
    hasEdits,
    hasHints,
    onClick,
  } = props
  return (
    <div
      className={cx('file-browser-file-item', {
        'file-browser-file-item-active': isActive,
        'file-browser-file-item-hints': hasHints,
      })}
      style={{ marginLeft: depth * 8 }}
      onClick={onClick}
    >
      <FileBrowserIcon
        type='file'
        options={{
          locked: isLocked,
          opened: isOpened,
        }}
      />
      <div className='file-browser-label'>
        {name}
        {hasEdits ? ' *' : ''}
      </div>
    </div>
  )
}

FileBrowserFileItem.propTypes = {
  depth: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  hasEdits: PropTypes.bool.isRequired,
  hasHints: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default FileBrowserFileItem
