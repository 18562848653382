import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import { Icon } from 'app/views/components/GustavoIcon'
import { HBox, VBox, Box } from 'app/views/core/Box'

import { connect } from 'react-redux'

function resolveStatus(requirePassingTest, completedAt, passedAt) {
  if (requirePassingTest) {
    if (passedAt) {
      return { status: 'complete', timeString: passedAt }
    }
    return { status: 'incomplete' }
  }
  if (passedAt || completedAt) {
    return { status: 'complete', timeString: passedAt || completedAt }
  }

  // Our progress.js POST requests update content_to_hacker to reflect how far the user has clicked
  // through the lesson steps: when they reach the end, completed_at is set. For a lesson that
  // doesn't require passing tests, this is all the user has to do to complete the lesson. However,
  // we don't get a redux state update when completed_at is updated on the backend. Since we only
  // display this component at the end of the lesson anyway, if the lesson doesn't require tests
  // we'll just display the lesson status as complete.
  const momentWillInterpretThisAsCurrentTime = undefined
  return {
    status: 'complete',
    timeString: momentWillInterpretThisAsCurrentTime,
  }
}

function getStatusHeadline(requirePassingTest, isComplete, hasSubmittedTests) {
  if (isComplete) {
    return requirePassingTest ? 'Tests Passed!' : 'Lesson Complete!'
  }
  if (requirePassingTest) {
    return hasSubmittedTests ? 'Tests Failed' : 'No Code Submissions'
  }
  return 'Lesson Incomplete'
}

function getStatusDescription(requirePassingTest, isComplete, timeString) {
  if (isComplete) {
    return `Completed ${getLocalDateFromUTC(timeString).format('MMMM D, YYYY')}`
  }
  if (requirePassingTest) {
    return 'Submit a code patch for testing.'
  }
  return ''
}

const StatusIcon = ({ requirePassingTest, isComplete }) => {
  if (isComplete)
    return <Icon name='checkbox-circle' className='text-primary' ratio={2} />
  if (requirePassingTest)
    return <Icon name='error-warning' className='text-danger' ratio={2} />
  return <Icon name='checkbox-blank-circle' ratio={2} />
}

function LessonCompletionStatus(props) {
  const {
    completedAt,
    passedAt,
    requirePassingTest,
    hackerHasSubmittedTests,
    hackerSubmittedTestPassedAt,
    svcHasSubmittedTests,
    svcSubmittedTestPassedAt,
  } = props

  const { engUseSvcCodeSub } = useFlags()
  const hasSubmittedTests = engUseSvcCodeSub
    ? svcHasSubmittedTests
    : hackerHasSubmittedTests
  const submittedTestPassedAt = engUseSvcCodeSub
    ? svcSubmittedTestPassedAt
    : hackerSubmittedTestPassedAt

  const { status, timeString } = resolveStatus(
    requirePassingTest,
    completedAt,
    passedAt || submittedTestPassedAt
  )
  const isComplete = status === 'complete'
  return (
    <HBox style={{ margin: '0 auto 30px auto', alignItems: 'center' }}>
      <StatusIcon
        requirePassingTest={requirePassingTest}
        isComplete={isComplete}
      />
      <VBox className='ml-2.5'>
        <Box
          className={cx({
            'text-primary': isComplete,
            'text-danger': !isComplete,
          })}
        >
          {getStatusHeadline(requirePassingTest, isComplete, hasSubmittedTests)}
        </Box>
        <Box className='text-sm'>
          {getStatusDescription(requirePassingTest, isComplete, timeString)}
        </Box>
      </VBox>
    </HBox>
  )
}
LessonCompletionStatus.propTypes = {
  hasTests: PropTypes.bool.isRequired,
  completedAt: PropTypes.string,
  passedAt: PropTypes.string,
  requirePassingTest: PropTypes.bool.isRequired,
  hackerHasSubmittedTests: PropTypes.bool.isRequired,
  hackerSubmittedTestPassedAt: PropTypes.string,
  svcHasSubmittedTests: PropTypes.bool.isRequired,
  svcSubmittedTestPassedAt: PropTypes.string,
}

export default connect((state, ownProps) => {
  // content.passed_at isn't updated until page refresh, so we fall back on codeSubmissions
  // and assume that the test has just passed as of when it was submitted.
  const submissionHasFinished = (x) => x.test.passed || x.test.name
  const submissionHasPassed = (x) => x.test.passed
  return {
    requirePassingTest:
      ownProps.hasTests && state.hacker.mustPassCodingExercise,
    hackerHasSubmittedTests:
      ownProps.hasTests &&
      (state.hacker.currentCodeSubmissions || []).some(submissionHasFinished),
    hackerSubmittedTestPassedAt: ownProps.hasTests
      ? (
          (state.hacker.currentCodeSubmissions || []).find(
            submissionHasPassed
          ) || {}
        ).submitted_at
      : undefined,
    svcHasSubmittedTests: state.codesub.submissions.length > 0,
    svcSubmittedTestPassedAt: findPassingCodeSubmissionTime(
      state.codesub.submissions
    ),
  }
}, {})(LessonCompletionStatus)

function findPassingCodeSubmissionTime(submissions) {
  for (let i = submissions.length - 1; i >= 0; i--) {
    const submission = submissions[i]
    if (submission.result.status === 'passed') {
      return submission.submissionTime.toISOString()
    }
  }
  return ''
}
