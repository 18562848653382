import React from 'react'

function validateUrl(urlString, allowedOrigins, useRealUrl) {
  // If we can't parse the string to a URL, it's invalid
  let url = null
  try {
    url = new URL(urlString)
  } catch (err) {
    return { ok: false, error: { problem: 'malformed-url', data: urlString } }
  }

  // Don't allow non-HTTP protocols
  if (url.protocol !== 'http:' && url.protocol !== 'https:') {
    return {
      ok: false,
      error: { problem: 'bad-protocol', data: `${url.protocol}//` },
    }
  }

  // Don't allow hosts/domains from origins that aren't explicitly allowed
  const originIndex = allowedOrigins.findIndex((x) => {
    const userFacingUrl = useRealUrl ? x.realUrl : x.cleanUrl
    return userFacingUrl === url.origin
  })
  if (originIndex === -1) {
    return { ok: false, error: { problem: 'bad-domain', data: url.hostname } }
  }

  // No error; urlString can be used as-is
  return { ok: true, origin: allowedOrigins[originIndex] }
}

function formatUrlErrorHeader(problem) {
  if (problem === 'bad-protocol') {
    return 'Unsupported Protocol'
  }
  if (problem === 'bad-domain') {
    return 'Invalid Domain'
  }
  if (problem === 'malformed-url') {
    return 'Malformed URL'
  }
  return 'Unsupported URL'
}

function formatUrlErrorMessage(problem, data, validBaseUrls) {
  if (problem === 'bad-protocol') {
    const protocol = data
    return (
      <>
        The Sandbox Browser does not support the <code>{protocol}</code>{' '}
        protocol. Our lessons will only instruct you to use <code>http://</code>{' '}
        and <code>https://</code>, so please take a closer look at the
        instructions to see where things may have gone wrong.
      </>
    )
  }
  if (problem === 'bad-domain') {
    const domainName = data
    return (
      <>
        Requests to <code>{domainName}</code> can not be made from this tab.
        {validBaseUrls.length > 0 &&
          (validBaseUrls.length === 1 ? (
            <>
              &nbsp;URL must begin with <code>{validBaseUrls[0]}</code>.
            </>
          ) : (
            <>
              &nbsp;URL must begin with one of the following:
              <ul>
                {validBaseUrls.map((x) => (
                  <li key={x}>
                    <code>{x}</code>
                  </li>
                ))}
              </ul>
            </>
          ))}
      </>
    )
  }
  if (problem === 'malformed-url') {
    const urlString = data
    return (
      <>
        <code>{urlString}</code> is not a valid URL!
      </>
    )
  }
  return (
    <>
      The requested URL is not supported. Try browsing to{' '}
      <code>{cleanUrl}</code>.
    </>
  )
}

export { validateUrl, formatUrlErrorHeader, formatUrlErrorMessage }
