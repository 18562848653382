import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import Icon from 'app/views/components/Icon'
// Utils
import analytics from 'app/views/utils/analytics'

const propTypes = {
  contentType: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired, // withRouter
  }).isRequired,
  license: PropTypes.bool.isRequired, // mapStateToProps
}

class SubscriptionHelp extends React.Component {
  constructor(props) {
    super(props)

    this.handleEmailClick = this.handleEmailClick.bind(this)
    this.handleUpgradeClick = this.handleUpgradeClick.bind(this)
  }

  handleEmailClick() {
    analytics.track('Click - Need Help (Send us an email)')
    window.open('mailto:support@securityjourney.com')
  }

  handleUpgradeClick() {
    analytics.track('Click - Need Help (Upgrade Account)')
    this.props.history.push('/contact')
  }

  render() {
    return (
      <div>
        {this.props.license ? (
          <div className='uk-card uk-card-default uk-card-body text-center'>
            <div className='text-center'>
              <Icon name='lifebuoy' fill ratio={3} />
            </div>
            <h3 className='uk-margin-small-top'>Need Help?</h3>
            <p>
              You can email us at &nbsp;
              <Link to='mailto:support@securityjourney.com' target='_blank'>
                support@securityjourney.com
              </Link>
              &nbsp; or use the Chat button in the bottom right to get help.
            </p>
            <button
              type='button'
              className='uk-button uk-button-primary'
              onClick={this.handleEmailClick}
            >
              Send us an email
            </button>
          </div>
        ) : (
          <div className='uk-card uk-card-default uk-card-body text-center'>
            <Icon className='uk-margin-small-right' name='lifebuoy' ratio={2} />
            <h3 className='uk-card-title uk-margin-small-top'>
              Need Help? Upgrade Now!!!
            </h3>
            <p>
              {`Upgrading your account gives you direct access to our team
              of security researchers so you can get help with this
              ${this.props.contentType}.`}
            </p>
            <button
              type='button'
              className='uk-button uk-button-primary'
              onClick={this.handleUpgradeClick}
            >
              Upgrade Account
            </button>
          </div>
        )}
      </div>
    )
  }
}

SubscriptionHelp.propTypes = propTypes

const mapStateToProps = (state) => ({
  license: state.hacker.license,
})

export default withRouter(connect(mapStateToProps)(SubscriptionHelp))
