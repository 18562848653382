/* eslint-disable no-nested-ternary */
import React from 'react'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

import PrimaryButton from '../Buttons/PrimaryButton'
import Icon from 'app/views/components/Icon'
export default ({
  item,
  showAdd,
  onAdd,
  onDelete,
  onEdit,
  renderLabel,
  contentCompleted,
  hideCompleted,
}) => {
  if (hideCompleted && contentCompleted) {
    return null
  }
  return (
    <tr>
      <td>{item.title}</td>
      <td className='text-center text-sm'>
        {!showAdd
          ? renderLabel(item)
          : contentCompleted
          ? `Completed on ${getLocalDateFromUTC(contentCompleted).format(
              'MMM D, YYYY'
            )}`
          : ' - '}
      </td>
      <td className='text-right'>
        {showAdd ? (
          <PrimaryButton onClick={onAdd} size='small' label='Add' />
        ) : (
          <div>
            <Icon
              onClick={onEdit}
              className='uk-margin-small-right'
              name='settings-3'
              style={{ cursor: 'pointer' }}
            />
            <Icon
              style={{ cursor: 'pointer' }}
              onClick={onDelete}
              name='delete-bin'
            />
          </div>
        )}
      </td>
    </tr>
  )
}
