import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

// Redux
import { pingContent } from 'app/state/modules/content'

// Utils
import analytics from 'app/views/utils/analytics'
import LessonSteps from 'app/views/components/LessonSteps'

class TutorialDAST extends React.Component {
  componentDidMount() {
    analytics.page('tutorial-dast')
  }

  render() {
    return (
      <>
        <div>
          <div className='uk-background-secondary uk-padding uk-light'>
            <div className='text-center uk-margin-top'>
              <h2>DAST Lesson Walkthrough</h2>
              <h4>
                A step-by-step overview of our DAST Lesson in the DevSecOps
                Course.
              </h4>
            </div>
          </div>

          <div className='uk-container uk-container-small'>
            <div className='uk-section'>
              <h2>Introduction to DAST</h2>
              <p>
                The lesson starts out with an introduction to DAST, how it is
                different from SAST, and why it is important to integrate it
                into your build process.
              </p>

              <h2>Manually verify XSS vulnerability</h2>
              <p>
                The first step in the lesson is to verify an XSS vulnerability
                that was found in our app by a pentest our fictitious company
                performed.
              </p>
              <img src='https://cdn.hackedu.com/lessons/dast/1_verify_xss_vuln.png' />

              <h2>Try (and fail) to find XSS with SAST</h2>
              <p>
                Next, the developer will run the same SAST tool they used in the
                SAST lesson, which will <strong>not</strong> find the XSS
                vulnerability. This shows the importance of using both types of
                scanners in the build process.
              </p>
              <img src='https://cdn.hackedu.com/lessons/dast/2_run_sast.png' />

              <h2>Review DAST (OWASP ZAP) Report in CI</h2>
              <p>
                Then the developer will look at the DAST report where they will
                see XSS found and flagged as a "High" severity issue.
              </p>
              <img src='https://cdn.hackedu.com/lessons/dast/3_build_is_green.png' />
              <img src='https://cdn.hackedu.com/lessons/dast/4_review_zap_report.png' />

              <h2>Alter ZAP script to fail build</h2>
              <p>
                As we saw in the ZAP Report, two "High" severity issues were
                found, but the build still succeeds. The developer will now
                alter the <code>run-zap.py</code> script to fail the build when
                a High severity issue is found.
              </p>
              <img src='https://cdn.hackedu.com/lessons/dast/5_edit_zap_script.png' />

              <h2>Commit script and see build fail</h2>
              <p>
                The developer then commits these script changes and verifies
                that the build actually fails when it finds XSS.
              </p>
              <img src='https://cdn.hackedu.com/lessons/dast/6_commit_script_updates.png' />
              <img src='https://cdn.hackedu.com/lessons/dast/7_build_starts.png' />
              <img src='https://cdn.hackedu.com/lessons/dast/8_build_fails.png' />

              <h2>Fix the XSS issue</h2>
              <p>
                Now that the build is failing when XSS is found, the developer
                fixes the issue in the app.
              </p>
              <img src='https://cdn.hackedu.com/lessons/dast/9_fix_xss.png' />

              <h2>CI builds and deploys app</h2>
              <p>
                Once XSS is fixed, the developer commits these changes and the
                CI/CD process runs again. The build should succeed and the fixed
                app should be automatically deployed.
              </p>
              <img src='https://cdn.hackedu.com/lessons/dast/10_build_again.png' />
              <img src='https://cdn.hackedu.com/lessons/dast/11_pass_and_deploy_app.png' />
              <img src='https://cdn.hackedu.com/lessons/dast/12_deploy_done.png' />

              <h2>Verify XSS is fixed</h2>
              <p>
                Once the app is deployed, we can try to re-exploit the app and
                see that it is no longer vulnerable.
              </p>
              <img src='https://cdn.hackedu.com/lessons/dast/13_xss_fixed.png' />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(TutorialDAST)
