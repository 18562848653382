import React from 'react'

const Dot = (props) => {
  const getColor = () => {
    if (props.unknown) return '#666'
    if (props.needs_attention) return '#f0506e'
    if (props.expert) return '#32d296'
    return '#faa05a'
  }
  return (
    <div
      className={props.className ? props.className : 'uk-align-center'}
      style={{
        width: props.small ? 15 : 35,
        height: props.small ? 15 : 35,
        color: '#fff',
        background: getColor(),
        borderRadius: props.small ? 15 : 35,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 'small',
        display: 'flex',
      }}
    >
      {(typeof props.text === 'string' || typeof props.text === 'number') && (
        <span style={{ marginBottom: 3 }}>{props.text}</span>
      )}
    </div>
  )
}

export default Dot
