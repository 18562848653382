import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { VScroll } from 'app/views/core/Scroll'

import ProxyHistoryTable from './ProxyHistoryTable'
import RequestDetailModal from './RequestDetailModal'

import { connect } from 'react-redux'

/**
 * Style/layout components that are local to this file.
 * TODO: Factor out common styling/layout details entirely.
 */
const ProxyContainer = ({ children }) => (
  <div className='uk-padding'>{children}</div>
)
const SmallText = ({ value }) => <p className='text-sm'>{value}</p>

/**
 * Displays a table showing all requests made through the proxy; also tracks
 * selection state and displays the details of a single request if the user has
 * selected one.
 */
function ProxyHistory(props) {
  const requests = props.requests || []
  const [selectedRequestId, setSelectedRequestId] = useState(null)
  const selectedRequest = selectedRequestId
    ? requests.find((x) => x.request_id === selectedRequestId)
    : null
  return (
    <VScroll>
      <ProxyContainer>
        {selectedRequest && (
          <RequestDetailModal
            request={selectedRequest}
            onClose={() => setSelectedRequestId(null)}
          />
        )}
        <h3>Proxy History</h3>
        {requests.length === 0 ? (
          <SmallText value='No requests have been made.' />
        ) : (
          <>
            <SmallText value='Click on a request to see additional details.' />
            <ProxyHistoryTable
              requests={requests}
              onSelectRequest={setSelectedRequestId}
            />
          </>
        )}
      </ProxyContainer>
    </VScroll>
  )
}
ProxyHistory.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.object),
}

export default connect(
  (state) => ({
    requests: state.sbproxy.concludedHistory ? state.sbproxy.requests : [],
  }),
  {}
)(ProxyHistory)
