import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Moment from 'react-moment'

// Components
import ContentButton from 'app/views/components/ContentButton'
import ContentIcon from 'app/views/components/ContentIcon'
import ModalContentIcon from 'app/views/components/Modals/ModalContentIcon'
import ScratchpadButton from 'app/views/components/ScratchpadButton'
import SkeletonLoader from 'app/views/components/Loader/SkeletonLoader'
import AllTrainingMenu from 'app/views/components/AllTrainingMenu'

// Redux
import { getContent } from 'app/state/modules/content'

// Utils
import analytics from 'app/views/utils/analytics'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

const propTypes = {
  getContent: PropTypes.func.isRequired,
  license: PropTypes.bool,
}
const defaultProps = {
  license: false,
}

class All extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
    }

    this.onScroll = this.onScroll.bind(this)
    this.onCheckScroll = this.onCheckScroll.bind(this)
  }

  componentDidMount() {
    // Dashboard tour
    /*
    if (localStorage.getItem('hackedu.tour.dashboard') !== 'true') {
      localStorage.setItem('hackedu.tour.dashboard', 'true');
      UIkit.modal('#modal-dashboard-tour').show();
    }
    */

    /*
    if (localStorage.getItem('hackedu.hasSignedTermsOfService') !== 'true') {
      UIkit.modal('#modal-terms-of-service').show()
    }
    */
    window.addEventListener('scroll', this.onScroll)

    this.props.getContent()
    analytics.page('all')
    this.onCheckScroll()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.showAllTraining === false &&
      nextProps.organizations.some((item) => item.role === 'admin') !== true
    ) {
      this.props.history.push('/')
    }
    if (
      this.props.content.courses.length === 0 &&
      nextProps.content.courses.length > 0
    ) {
      this.setState(
        {
          activeTab: nextProps.content.courses[0].uuid,
        },
        () => {
          this.onCheckScroll()
        }
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true)
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  onScroll() {
    if (this.timer !== null) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      this.onCheckScroll()
    }, 100)
  }

  onCheckScroll() {
    for (let i = this.props.content.courses.length - 1; i >= 0; i -= 1) {
      const elem = document.getElementById(`scroll-${i}`)
      if (!elem) break
      const scrollIoffset = elem.offsetTop
      const windowScrollY = window.scrollY
      if (windowScrollY === 0) {
        this.setState({ activeTab: this.props.content.courses[0].uuid })
        break
      }
      if (scrollIoffset - 150 <= windowScrollY) {
        this.setState({ activeTab: this.props.content.courses[i].uuid })
        break
      }
    }
  }

  render() {
    if (this.props.loadingContent || this.props.loadingVulnerabilityTypes) {
      return <SkeletonLoader />
    }
    const courses = this.props.courseUUID
      ? this.props.content.courses.filter(
          (x) => x.uuid === this.props.courseUUID
        )
      : this.props.content.courses
    return (
      <div className='uk-hackedu-container-main'>
        <div className='uk-container'>
          <div className='uk-margin-medium-top'>
            <div data-uk-grid=''>
              <div className='uk-width-large'>
                <div>
                  <div
                    data-uk-sticky='offset: 150'
                    className='hackedu-content-menu'
                  >
                    <AllTrainingMenu
                      content={this.props.content}
                      activeTab={this.state.activeTab}
                      onActiveTab={(courseUUID) => {
                        this.setState({
                          activeTab: courseUUID,
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='uk-width-expand'>
                <div className='uk-width-expand uk-section-small'>
                  {courses.map((course, i) => {
                    return (
                      <div id={`scroll-${i}`} key={course.uuid}>
                        <h2 className={i > 0 ? 'uk-margin-large-top' : ''}>
                          {course.title}
                        </h2>
                        <p>{course.description}</p>
                        {course.items.map((item) => {
                          if (item.item_type === 'lesson') {
                            const hasCompletedContentList = []
                            const isActiveForUserList = []
                            const contentInProgressList = []
                            let requiresSubscription = true
                            return (
                              <div key={item.uuid}>
                                <div className='uk-panel'>
                                  <div data-uk-grid=''>
                                    <div className='uk-width-4-5'>
                                      <h4 className='uk-margin-top uk-margin-remove-bottom'>
                                        <a
                                          href={`/lesson/${item.uuid}`}
                                          className='uk-link-reset'
                                        >
                                          {item.title}
                                        </a>
                                      </h4>
                                      <p className='text-sm uk-margin-small'>
                                        {item.description}
                                      </p>
                                      {item.content.map((content) => {
                                        const hasCodeSubmission =
                                          content.code_submission_count > 0
                                        const hasCompletedContent =
                                          !!content.completed_at
                                        const hasPassedContent =
                                          !!content.passed_at
                                        const contentInProgress =
                                          content.max_progress > 0
                                        const isActiveForUser =
                                          content.is_active_for_user
                                        isActiveForUserList.push(
                                          isActiveForUser
                                        )
                                        hasCompletedContentList.push(
                                          hasCompletedContent
                                        )
                                        contentInProgressList.push(
                                          contentInProgress
                                        )
                                        if (
                                          content.requires_subscription ===
                                          false
                                        ) {
                                          requiresSubscription = false
                                        }

                                        const localLastCompletedTime =
                                          content.has_test && content.passed_at
                                            ? getLocalDateFromUTC(
                                                content.passed_at
                                              )
                                            : getLocalDateFromUTC(
                                                content.completed_at
                                              )
                                        return (
                                          <div
                                            className='uk-margin-left'
                                            key={content.uuid}
                                          >
                                            <div className='flex items-center uk-margin-small-top'>
                                              <ContentIcon
                                                hasCodeSubmission={
                                                  hasCodeSubmission
                                                }
                                                hasCompletedContent={
                                                  hasCompletedContent
                                                }
                                                hasPassedContent={
                                                  hasPassedContent
                                                }
                                                hasTest={content.has_test}
                                                isActiveForUser={
                                                  isActiveForUser
                                                }
                                                lessonInProgress={
                                                  contentInProgress
                                                }
                                                license={this.props.license}
                                                marginTop={0}
                                                mustPassCodingExercise={
                                                  content.must_pass_coding_exercise
                                                }
                                                requiresSubscription={
                                                  content.requires_subscription
                                                }
                                                small
                                              />
                                              <h5 className='uk-inline uk-margin-remove'>
                                                <a
                                                  href={`/content/${content.uuid}`}
                                                  className='uk-link-reset'
                                                >
                                                  {content.title}
                                                </a>
                                              </h5>
                                              {hasCompletedContent && (
                                                <div className='italic text-sm uk-margin-left'>
                                                  Last completed on{' '}
                                                  <Moment format='MMMM D, YYYY'>
                                                    {localLastCompletedTime}
                                                  </Moment>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                    <div className='uk-width-1-5 text-right uk-margin-top'>
                                      <ContentButton
                                        contentType={item.content_type}
                                        isActiveForUser={isActiveForUserList.includes(
                                          true
                                        )}
                                        hasCompletedContent={
                                          !hasCompletedContentList.includes(
                                            false
                                          )
                                        }
                                        lessonInProgress={contentInProgressList.includes(
                                          true
                                        )}
                                        license={this.props.license}
                                        requiresSubscription={
                                          requiresSubscription
                                        }
                                        small
                                        url={`/lesson/${item.uuid}`}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            )
                          }
                          const hasCodeSubmission =
                            item.code_submission_count > 0
                          const hasCompletedContent = !!item.completed_at
                          const hasPassedContent = !!item.passed_at
                          const lessonInProgress = item.max_progress > 0
                          const isActiveForUser = item.is_active_for_user
                          const localLastCompletedTime =
                            item.has_test && item.passed_at
                              ? getLocalDateFromUTC(item.passed_at)
                              : getLocalDateFromUTC(item.completed_at)
                          return (
                            <div key={item.uuid}>
                              <div className='uk-panel'>
                                <div data-uk-grid=''>
                                  <div className='uk-width-4-5'>
                                    <div className='flex items-center'>
                                      <ContentIcon
                                        hasCodeSubmission={hasCodeSubmission}
                                        hasCompletedContent={
                                          hasCompletedContent
                                        }
                                        hasPassedContent={hasPassedContent}
                                        hasTest={item.has_test}
                                        isActiveForUser={isActiveForUser}
                                        lessonInProgress={lessonInProgress}
                                        license={this.props.license}
                                        marginTop={0}
                                        mustPassCodingExercise={
                                          item.must_pass_coding_exercise
                                        }
                                        requiresSubscription={
                                          item.requires_subscription
                                        }
                                      />
                                      <h4 className='uk-inline uk-margin-top'>
                                        <a
                                          href={`/content/${item.uuid}`}
                                          className='uk-link-reset'
                                        >
                                          {item.title}
                                        </a>
                                      </h4>
                                    </div>
                                    <p className='text-sm uk-margin-small uk-margin-remove-top'>
                                      {item.description}
                                    </p>
                                    {hasCompletedContent && (
                                      <div className='text-sm italic'>
                                        Last completed on &nbsp;
                                        <Moment format='MMMM D, YYYY'>
                                          {localLastCompletedTime}
                                        </Moment>
                                      </div>
                                    )}
                                  </div>
                                  <div className='uk-width-1-5 text-right uk-margin-top'>
                                    <ContentButton
                                      contentType={item.content_type}
                                      hasCompletedContent={hasCompletedContent}
                                      lessonInProgress={lessonInProgress}
                                      isActiveForUser={isActiveForUser}
                                      license={this.props.license}
                                      requiresSubscription={
                                        item.requires_subscription
                                      }
                                      small
                                      url={`/content/${item.uuid}`}
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                  <ModalContentIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScratchpadButton id='all' />
      </div>
    )
  }
}

All.propTypes = propTypes
All.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  // auth
  email: state.auth.user.email,

  // content
  content: state.content.content,
  loadingContent: state.content.loadingContent,
  loadingVulnerabilityTypes: state.content.loadingVulnerabilityTypes,
  vulnerabilityTypes: state.content.vulnerabilityTypes,

  // hacker
  organizations: state.hacker.organizationsList,
  license: state.hacker.license,
  mustPassCodingExercise: state.hacker.mustPassCodingExercise,
  showAllTraining: state.hacker.showAllTraining,
  showCodingChallenges: state.hacker.showCodingChallenges,
})
const mapDispatchToProps = (dispatch) => ({
  getContent: () => {
    dispatch(getContent())
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(All))
