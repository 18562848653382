import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { getEngineDisplayName } from 'app/views/utils/engine'
import { renderedInSJApp } from '../../../LessonUI/util'

import ContentModal from '../../ContentModal'

import EngineSelector from './EngineSelector'
import LanguageEnvironmentDetails from './LanguageEnvironmentDetails'
import LanguageOutputHint from './LanguageOutputHint'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

function ExistingPreferredEngine(props) {
  const { engine, isSelected, isSupported } = props
  const displayName = getEngineDisplayName(engine)
  if (isSelected || isSupported) {
    return (
      <>
        <b>{displayName}</b> is your default language.
      </>
    )
  }
  return (
    <>
      Your default language is <b>{displayName}</b>, which is not supported for
      this lesson.
    </>
  )
}
ExistingPreferredEngine.propTypes = {
  engine: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isSupported: PropTypes.bool.isRequired,
}

function PreferredEngineLine(props) {
  const {
    preferredEngine,
    selectedEngine,
    supportedEngines,
    onSaveSelectedEngineAsPreferred,
  } = props
  const showExisting = !!preferredEngine
  const showNew = !preferredEngine || preferredEngine !== selectedEngine
  const SAVE_ENGINE_TEXT = `Save ${getEngineDisplayName(
    selectedEngine
  ).toUpperCase()} as your default language?`

  return (
    <p style={{ marginTop: 4 }}>
      {showExisting && (
        <ExistingPreferredEngine
          engine={preferredEngine}
          isSelected={preferredEngine === selectedEngine}
          isSupported={supportedEngines.includes(preferredEngine)}
        />
      )}
      {showExisting && showNew && <span>&nbsp;</span>}
      {showNew && (
        <div className={cx('mt-2', { 'sj-btn': renderedInSJApp() })}>
          <PrimaryButton
            customCss={'font-bold sj-bg-blue'}
            size={'large'}
            label={SAVE_ENGINE_TEXT}
            onClick={onSaveSelectedEngineAsPreferred}
          />
        </div>
      )}
    </p>
  )
}
PreferredEngineLine.propTypes = {
  preferredEngine: PropTypes.string,
  selectedEngine: PropTypes.string.isRequired,
  supportedEngines: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSaveSelectedEngineAsPreferred: PropTypes.func.isRequired,
}

function EditorLanguageModal(props) {
  const {
    preferredEngine,
    currentEngine,
    supportedEngines,
    onSelectEngine,
    onSaveSelectedEngineAsPreferred,
    onClose,
  } = props
  return (
    <ContentModal
      id='code-editor-language-details'
      width='max(70vw, 700px)'
      onClose={onClose}
    >
      <div style={{ padding: 40 }}>
        <h3>Language Selection</h3>
        <span className='text-sm'>
          <p style={{ marginBottom: 4 }}>Select a language for this lesson:</p>
          <EngineSelector
            currentEngine={currentEngine}
            supportedEngines={supportedEngines}
            onSelect={onSelectEngine}
          />
          <PreferredEngineLine
            preferredEngine={preferredEngine}
            selectedEngine={currentEngine}
            supportedEngines={supportedEngines}
            onSaveSelectedEngineAsPreferred={onSaveSelectedEngineAsPreferred}
          />
          <LanguageEnvironmentDetails engine={currentEngine} />
          <LanguageOutputHint engine={currentEngine} />
        </span>
      </div>
    </ContentModal>
  )
}
EditorLanguageModal.propTypes = {
  preferredEngine: PropTypes.string,
  currentEngine: PropTypes.string.isRequired,
  supportedEngines: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectEngine: PropTypes.func.isRequired,
  onSaveSelectedEngineAsPreferred: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditorLanguageModal
