import React from 'react'

export default function (props) {
  if (!props.minutes && props.minutes !== 0) return null
  const minutes = parseFloat(props.minutes.toFixed(0))
  if (props.isText) {
    if (minutes === 1) {
      return `${minutes} minute ${props.extraText || ''}`
    }
    if (minutes < 60) {
      return `${minutes} minutes ${props.extraText || ''}`
    }

    const hourNumber = parseInt(minutes / 60, 10)
    const minuteNumber = parseInt(minutes % 60, 10)
    let hourText = 'hrs'
    if (hourNumber === 1) {
      hourText = 'hr'
    }
    return `${hourNumber} ${hourText} ${minuteNumber} min ${
      props.extraText || ''
    }`
  }
  if (minutes < 60) {
    return (
      <span>
        {parseInt(minutes, 10)}{' '}
        <span style={{ fontSize: 28 }}>
          {minutes === 1 ? 'minute' : 'minutes'}
        </span>
      </span>
    )
  }
  return (
    <span>
      {parseInt(minutes / 60, 10)}
      <span style={{ fontSize: 28 }}>
        &nbsp;
        {parseInt(minutes / 60, 10) === 1 ? 'hr' : 'hrs'}
      </span>{' '}
      {parseInt(minutes % 60, 10)}
      <span style={{ fontSize: 28 }}>
        &nbsp;min
        {props.extraText || ''}
      </span>
    </span>
  )
}
