import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { renderedInSJApp } from '../LessonUI/util'

/** Main container for a full-screen app UI that runs any kind of content. */
function ContentUI({ children, useSjTheme = false }) {
  return (
    <div
      className={cx('flex content-area', {
        'clear-nav-height': renderedInSJApp(),
        'sj-theme': useSjTheme,
      })}
    >
      {children}
    </div>
  )
}
ContentUI.propTypes = {
  children: PropTypes.any,
}

export default ContentUI
