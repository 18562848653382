import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

// Components
import Loader from 'app/views/components/Loader'
import Icon from 'app/views/components/Icon'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'

// Redux
import { getTeams } from 'app/state/modules/team'

// Utils
import analytics from 'app/views/utils/analytics'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired, // withRouter
  }).isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

class OrganizationsTeams extends React.Component {
  componentDidMount() {
    this.props.getTeams(this.props.match.params.organization_uuid)
    analytics.page('page-organizations-teams')
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.organization_uuid !==
      prevProps.match.params.organization_uuid
    ) {
      this.props.getTeams(this.props.match.params.organization_uuid)
    }
  }

  handleSelectTeam(uuid) {
    const currentOrganizationId = this.props.match.params.organization_uuid
    this.props.history.push(
      `/admin/${currentOrganizationId}/teams/${uuid}/users`
    )
  }

  render() {
    if (!this.props.hasLoadedTeams) {
      return <Loader visible />
    }
    // if (this.props.teams.length === 1) {
    //   return <Redirect to={`/admin/${this.props.match.params.organization_uuid}/teams/${this.props.teams[0].uuid}`} />
    // }
    const currentOrganizationId = this.props.match.params.organization_uuid
    return (
      <OrganizationLayout
        data-test-id='organizations-teams-component'
        {...this.props}
        active='teams'
      >
        <div className='uk-inline'>
          <h1 className='uk-inline'>Teams</h1>
          <Icon
            as='a'
            href='https://help.securityjourney.com/en/articles/3117523-set-up-teams'
            ratio={0.8}
            name='question'
            style={{ padding: 10, verticalAlign: 'top' }}
            target='_blank'
            rel='noopener noreferrer'
          />
        </div>
        {this.props.organizationsHash[this.props.match.params.organization_uuid]
          .role === 'admin' && (
          <PrimaryButton
            link_to={`/admin/${currentOrganizationId}/teams/add`}
            label={'Create Team'}
            size='medium'
            customCss='float-right'
          />
        )}

        <div style={{ clear: 'both' }} />

        {this.props.teams.length > 0 ? (
          <div className='uk-overflow-auto'>
            <table className='uk-table uk-table-large uk-table-middle uk-table-divider uk-table-striped'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th className='text-center'># of Users</th>
                  <th className='text-center'>Assigned Lessons Completed</th>
                  <th className='text-center'>Total Training Time</th>
                </tr>
              </thead>
              <tbody>
                {this.props.teams.map((team) => (
                  <tr
                    key={team.uuid}
                    onClick={this.handleSelectTeam.bind(this, team.uuid)}
                    name={team.uuid}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <td>{team.name}</td>
                    <td className='text-center'>{team.users}</td>
                    <td className='text-center'>
                      {team.content_completed}/{team.content_assigned}
                    </td>
                    <td className='text-center'>
                      {`${parseInt(team.time_spent / 60, 10)} hrs ${parseInt(
                        team.time_spent % 60,
                        10
                      )} min`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>You have not added any teams yet.</p>
        )}
      </OrganizationLayout>
    )
  }
}

OrganizationsTeams.propTypes = propTypes

const mapStateToProps = (state) => ({
  // hacker
  organizations: state.hacker.organizationsList,
  organizationsHash: state.hacker.organizationsHash,
  // team
  teams: state.team.teams,
  hasLoadedTeams: state.team.hasLoadedTeams,
})
const mapDispatchToProps = (dispatch) => ({
  getTeams: (organzationId) => dispatch(getTeams(organzationId)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationsTeams)
)
