import React from 'react'

import Icon from 'app/views/components/Icon'

function PlanChangeBackBtn({ onClick }) {
  return (
    <button
      onClick={onClick}
      type='button'
      className='uk-button uk-button-small uk-button-secondary flex items-center'
      style={{
        position: 'absolute',
        left: '30px',
      }}
    >
      <Icon name='arrow-left' className='uk-margin-small-right' />
      Back
    </button>
  )
}

export default PlanChangeBackBtn
