import React, { useState, useEffect } from 'react'

function useWidthBreakpoint(width) {
  const [isLessThanWidth, setIsLessThanWidth] = useState(
    document.documentElement.clientWidth < width
  )
  const onResize = () =>
    setIsLessThanWidth(document.documentElement.clientWidth < width)
  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })
  return isLessThanWidth
}

export { useWidthBreakpoint }
