import React, { useState } from 'react'

import { PRODUCT_TOUR_VERSION } from './ProductTour/data'

/**
 * Custom hook that tells us whether we should offer the product tour to the user (assuming it's
 * supported by the lesson). We offer the product tour if the user hasn't previously completed it,
 * with a simple version number that allows us to re-offer the product tour if it changes.
 */
function useProductTourState() {
  const key = 'hackedu.productTourVersionCompleted'
  const versionCompleted = parseInt(localStorage.getItem(key)) || 0
  const [shouldOfferProductTour, setShouldOfferProductTour] = useState(
    versionCompleted < PRODUCT_TOUR_VERSION
  )

  const flagProductTourCompleted = () => {
    localStorage.setItem(key, PRODUCT_TOUR_VERSION)
    setShouldOfferProductTour(false)
  }

  return [shouldOfferProductTour, flagProductTourCompleted]
}

/** Checks if HackEDU content displayed in SecurityJourney iframe  */
function renderedInSJApp() {
  const params = new URLSearchParams(window.location.search)
  const isNavHidden = params.get('nav') === 'none'
  return window.parent !== window.self && isNavHidden
}

export { useProductTourState, renderedInSJApp }
