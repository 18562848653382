import React from 'react'
import moment from 'moment'
import UIkit from 'uikit'
import { connect } from 'react-redux'

// Components
import UserDetailModal from 'app/views/components/UserDetailModal'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import Loader from 'app/views/components/Loader'
import Icon from 'app/views/components/Icon'
import GenericModal from 'app/views/components/Modals/GenericModal'
import Pagination from 'app/views/components/Pagination'

// Redux
import {
  fetchSelectedUserDetail,
  cleanUserDetailModal,
} from 'app/state/modules/users'
import {
  getChallengesReport,
  exportChallengeReport,
} from 'app/state/modules/reports'
import { deleteHackerFromOrganization } from 'app/state/modules/hacker'

// Utils
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import SortableTableHeader from 'app/views/components/SortableTableHeader'
import settings from 'settings'
import TimeRangePicker from 'app/views/components/TimeRangePicker'
import TeamPicker from 'app/views/components/TeamPicker'

class ChallengeReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      localData: null,
      active: 'email',
      asc: true,
      days: null,
      teamUUID: null,
      currentPage: 1,
      showHackerDetail: null,
    }
    this.onExport = this.onExport.bind(this)
    this.onDeleteUser = this.onDeleteUser.bind(this)
    this.onChangeTeam = this.onChangeTeam.bind(this)
    this.fetchPerDays = this.fetchPerDays.bind(this)
    this.fetchSortedReport = this.fetchSortedReport.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.fetchSelectedUserDetail = this.fetchSelectedUserDetail.bind(this)
    this.props.cleanUserDetailModal()
    this.hackersPerPage = 100
    this.headers = [
      { key: 'email', name: 'Email' },
      { key: 'teams', name: 'Teams' },
      {
        key: 'hacking_challenges_completed',
        name: 'Hacking Challenges Completed',
      },
      {
        key: 'coding_challenges_completed',
        name: 'Coding Challenges Completed',
      },
      { key: 'points', name: 'Points' },
    ]
  }

  componentDidMount() {
    this.props.getChallengesReport(
      this.props.match.params.organization_uuid,
      this.props.match.params.team_uuid
    )
  }

  async onExport() {
    const headers = [
      'Email',
      'Challenge name',
      'Challenge Type',
      'Completion date',
      'App',
      'Language',
      'Framework',
      'Score',
      'Possible Score',
    ]
    const hackers =
      this.props.totalChallengesReport < 100 && this.state.currentPage <= 1
        ? this.props.challengesReport
        : await this.props.exportChallengeReport(
            this.props.match.params.organization_uuid,
            this.state.teamUUID || this.props.match.params.team_uuid,
            this.state.active,
            this.state.asc,
            this.state.days
          )
    const rows = []
    rows.push(headers)
    hackers.forEach((hacker) => {
      hacker.challenges.forEach((chall) => {
        const hackerRow = []
        hackerRow.push(hacker.email)
        hackerRow.push(chall.content_title)
        hackerRow.push(
          chall.type === 'coding_challenge'
            ? 'Coding Challenge'
            : 'Hacking Challenge'
        )
        hackerRow.push(
          getLocalDateFromUTC(chall.finished_on).format('YYYY-MM-DD HH:mm')
        )
        hackerRow.push(settings.apps[chall.app] || '-')
        hackerRow.push(
          (settings.engines[chall.language] || '-').replace('#', 'Sharp')
        )
        hackerRow.push(settings.frameworks[chall.framework] || '-')
        hackerRow.push(chall.points)
        hackerRow.push(chall.max_points)
        rows.push(hackerRow)
      })
    })
    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((e) => e.join(','))
      .join('\n')}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `challlnge_report${moment().format('YYYY-MM-DD')}.csv`
    )
    document.body.appendChild(link)
    link.click()
  }

  onChangeTeam(e) {
    const teamUUID = e.target.value === '-' ? null : e.target.value
    this.setState(
      {
        currentPage: 1,
        teamUUID,
      },
      () => {
        this.props.getChallengesReport(
          this.props.match.params.organization_uuid,
          this.state.teamUUID || null,
          this.state.active,
          this.state.asc,
          (this.state.currentPage - 1) * this.hackersPerPage,
          this.state.pastDueOnly,
          this.state.days
        )
      }
    )
  }

  onChangePage(currentPage) {
    this.setState(
      {
        currentPage,
      },
      () => {
        this.props.getChallengesReport(
          this.props.match.params.organization_uuid,
          this.state.teamUUID || null,
          this.state.active,
          this.state.asc,
          (this.state.currentPage - 1) * this.hackersPerPage,
          this.state.pastDueOnly,
          this.state.days
        )
      }
    )
  }

  onDeleteUser() {
    const orgUUID = this.props.match.params.organization_uuid
    const userUUID =
      this.props.selectedUserDetail.uuid || this.props.match.params.hacker_uuid
    const callback = (error = false) => {
      this.modalDeleteUser.hide()
      if (error) {
        return UIkit.modal.alert(
          '<h2 >Error</h2><p>There was an error deleting the user. Please try again or contact support.</p>'
        )
      }
      this.props.getChallengesReport(
        this.props.match.params.organization_uuid,
        this.props.match.params.team_uuid
      )
      return UIkit.modal.alert(
        '<h2 >User removed</h2><p>The user has been removed successfully from the organization<p>'
      )
    }
    this.props.deleteHackerFromOrganization(orgUUID, userUUID, callback)
  }

  fetchSelectedUserDetail(hacker, index) {
    this.props.fetchSelectedUserDetail(
      this.props.match.params.organization_uuid,
      {
        ...hacker,
        uuid: hacker.hacker_uuid,
        justTeam: this.props.match.params.team_uuid,
      },
      false,
      {
        localIndex: index,
      }
    )
  }

  fetchSortedReport(sortBy) {
    const { asc, active } = this.state
    this.setState(
      {
        active: sortBy,
        asc: sortBy === active ? !asc : true,
        currentPage: 1,
      },
      () => {
        this.props.getChallengesReport(
          this.props.match.params.organization_uuid,
          this.state.teamUUID || this.props.match.params.team_uuid,
          this.state.active,
          this.state.asc,
          0,
          this.state.days
        )
      }
    )
  }

  fetchPerDays(days) {
    this.setState(
      {
        currentPage: 1,
        days,
      },
      () => {
        this.props.getChallengesReport(
          this.props.match.params.organization_uuid,
          this.state.teamUUID || this.props.match.params.team_uuid,
          this.state.active,
          this.state.asc,
          0,
          this.state.days
        )
      }
    )
  }

  renderHeaders() {
    return this.headers.map((header, index) => (
      <SortableTableHeader
        onClick={this.fetchSortedReport}
        key={header.key}
        keyName={header.key}
        middle
        notSortable={header.key === 'teams'}
        center={index > 0}
        name={header.name}
        active={header.key === this.state.active}
        asc={header.key === this.state.active && this.state.asc}
        style={{
          fontSize: 10,
          minWidth: header.key === 'last_seen' ? 100 : undefined,
        }}
      />
    ))
  }

  renderHackerDetail(challenges) {
    return (
      <tr>
        <td className='text-sm uk-padding-remove' colSpan='6'>
          <div className='uk-width-1-1 uk-grid uk-margin-remove'>
            <table className='uk-table uk-table-small text-sm uk-table-divider'>
              <thead>
                <tr>
                  <th style={{ width: 350 }}>Challenge</th>
                  <th className='text-center'>Type</th>
                  <th className='text-center'>Language / Framework</th>
                  <th className='text-center'>Points</th>
                  <th className='text-center'>Completed On</th>
                </tr>
              </thead>
              <tbody>
                {challenges.map((challenge) => (
                  <tr key={challenge.content_uuid}>
                    <td>
                      {`${
                        challenge.type === 'coding_challenge'
                          ? `${settings.apps[challenge.app]} - `
                          : ''
                      }${challenge.content_title}`}
                    </td>
                    <td className='text-center'>
                      <label
                        className={`uk-label ${
                          challenge.type === 'hacking_challenge'
                            ? 'uk-label-secondary'
                            : 'uk-label-primary'
                        }`}
                      >
                        {challenge.type === 'hacking_challenge'
                          ? 'Hacking Challenge'
                          : 'Coding Challenge'}
                      </label>
                    </td>
                    <td className='text-center'>
                      {challenge.type === 'coding_challenge'
                        ? `${settings.engines[challenge.language]} - ${
                            settings.frameworks[challenge.framework]
                          }`
                        : '-'}
                    </td>
                    <td className='text-center'>
                      {challenge.points}
                      {challenge.type === 'coding_challenge' && '/'}
                      {challenge.type === 'coding_challenge' &&
                        challenge.max_points}
                    </td>
                    <td className='text-center'>
                      {getLocalDateFromUTC(challenge.finished_on).format(
                        'MMMM Do, YYYY'
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    )
  }

  renderReport() {
    return this.props.challengesReport.map((hacker, index) => (
      <React.Fragment key={hacker.hacker_uuid}>
        <tr>
          <td>
            <a
              onClick={this.fetchSelectedUserDetail.bind(
                this,
                hacker,
                index,
                false
              )}
            >
              {hacker.email}
            </a>
          </td>
          <td className='text-center'>
            {hacker.teams.map((t) => (
              <div key={t.uuid}>
                <label className='uk-label uk-label-primary'>{t.name}</label>
              </div>
            ))}
          </td>
          <td className='text-center'>
            {hacker.hacking_challenges_completed}
          </td>
          <td className='text-center'>
            {hacker.coding_challenges_completed}
          </td>
          <td className='text-center'>{hacker.points}</td>
          <td className='text-center'>
            <button
              type='button'
              onClick={() => {
                const { showHackerDetail } = this.state
                this.setState({
                  showHackerDetail:
                    showHackerDetail === hacker.hacker_uuid
                      ? null
                      : hacker.hacker_uuid,
                })
              }}
              className={`uk-button uk-button-${
                this.state.showHackerDetail === hacker.hacker_uuid
                  ? 'secondary'
                  : 'primary'
              } uk-button-small`}
            >
              {this.state.showHackerDetail === hacker.hacker_uuid
                ? 'Close'
                : 'Details'}
            </button>
          </td>
        </tr>
        {this.state.showHackerDetail === hacker.hacker_uuid &&
          this.renderHackerDetail(hacker.challenges)}
      </React.Fragment>
    ))
  }

  renderDeleteModalContent() {
    if (!this.props.selectedUserDetail) return null
    if (this.props.selectedUserDetail.steps_completed > 20) {
      return (
        <>
          <p>
            {`You are about to remove ${this.props.selectedUserDetail.email} from your organization.
            This user's license does not qualify to be released due to the amount of content they
            have already completed.`}
          </p>
          <p>Are you sure you want to continue?</p>
        </>
      )
    }
    return (
      <>
        <p>
          {`You are about to remove ${this.props.selectedUserDetail.email} from your organization. This
          user's license will be available for reuse since they completed a minimal amount of
          the training.`}
        </p>
        <p>Are you sure you want to continue?</p>
      </>
    )
  }

  render() {
    if (
      this.props.challengesReport === 'loading' ||
      !this.props.hasLoadedOrganizations
    ) {
      return <Loader visible />
    }

    return (
      <OrganizationLayout
        active='reports'
        data-test-id='organization-users-component'
        noPaddingRemove={this.props.match.params.team_uuid}
        style={{ position: 'relative' }}
        {...this.props}
      >
        {this.props.match.params.team_uuid && (
          <button
            onClick={() =>
              this.props.history.push(
                `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/users`
              )
            }
            className='uk-button uk-button-secondary uk-button-small uk-position-absolute uk-position-top-left uk-margin-top uk-margin-small-left flex'
            type='button'
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Back To Team
          </button>
        )}

        <h2 className='text-center'>Challenge Report</h2>
        {this.props.challengesReport !== 'loading' && (
          <div className='flex justify-end flex-col'>
            <div className='flex flex-1-0 justify-end'>
              <TeamPicker onChange={this.onChangeTeam} />
              <TimeRangePicker
                className='uk-margin-small-left'
                onChange={this.fetchPerDays}
              />
              <button
                onClick={this.onExport}
                disabled={this.props.challengesReport.length === 0}
                className='uk-button uk-button-default uk-button-small uk-margin-small-left flex'
                type='button'
              >
                <Icon className='uk-margin-small-right' name='download-cloud' />
                Full Report
              </button>
            </div>
            {this.props.totalChallengesReport > 0 && (
              <div className='flex flex-1-0 justify-end uk-margin-top'>
                <Pagination
                  currentPage={this.state.currentPage}
                  style={{
                    marginBottom: 0,
                  }}
                  onChangePage={this.onChangePage}
                  hasArrows
                  totalPages={Math.ceil(
                    this.props.totalChallengesReport / this.hackersPerPage
                  )}
                />
              </div>
            )}
          </div>
        )}
        <table className='uk-table uk-table-divider uk-table-middle uk-table-small text-sm'>
          {this.props.totalChallengesReport > 0 && (
            <thead className='uk-text'>
              <tr>{this.renderHeaders()}</tr>
            </thead>
          )}
          <tbody>
            {this.props.totalChallengesReport === 0 && (
              <tr>
                <td>No users found</td>
              </tr>
            )}
            {this.renderReport()}
          </tbody>
        </table>

        <UserDetailModal
          id='user-detail-chall-reports'
          localData={this.state.localData}
          selectedUserDetail={this.props.selectedUserDetail}
          onRemoveFromOrg={() => this.modalDeleteUser.show()}
        />
        <GenericModal
          title='Delete user from organization'
          ref={(ref) => {
            this.modalDeleteUser = ref
          }}
          id='delete-user-modal'
          date-test-id='delete-user-modal'
          body={this.renderDeleteModalContent()}
          buttons={
            <button
              data-test-id='delete-user-confirm'
              type='button'
              className='uk-button uk-button-danger uk-margin-left'
              onClick={this.onDeleteUser}
            >
              Delete
            </button>
          }
        />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedUserDetail: state.users.selectedUserDetail,
  challengesReport: state.reports.challengesReport,
  totalChallengesReport: state.reports.totalChallengesReport,
  hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
  organizationsHash: state.hacker.organizationsHash,
  organizations: state.hacker.organizationsList,
})

export default connect(mapStateToProps, {
  getChallengesReport,
  fetchSelectedUserDetail,
  cleanUserDetailModal,
  deleteHackerFromOrganization,
  exportChallengeReport,
})(ChallengeReport)
