import React from 'react'
import UIkit from 'uikit'

import Icon from 'app/views/components/Icon'
import AutoComplete from 'app/views/components/AutoComplete'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'

class AddUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: null,
      dataToSend: {},
      sendOnboarding: false,
      newMembersForm: [
        {
          email: '',
          type: 'member',
        },
      ],
    }
    this.handleAddNew = this.handleAddNew.bind(this)
    this.handleDeleteMember = this.handleDeleteMember.bind(this)
    this.handleChangeAddForm = this.handleChangeAddForm.bind(this)
    this.onSubmitNewTeamMembers = this.onSubmitNewTeamMembers.bind(this)
    this.handleChangeMetadataFile = this.handleChangeMetadataFile.bind(this)
  }

  onSubmitNewTeamMembers(e) {
    e.preventDefault()
    this.setState(
      {
        newMembersForm: this.state.newMembersForm.filter(
          (member) => member.email !== ''
        ),
      },
      () => {
        const re =
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        const { newMembersForm, dataToSend } = this.state
        const hasFileWithRecords =
          dataToSend && Object.keys(dataToSend).length > 0
        let hasError = false
        this.setState(
          {
            newMembersForm: newMembersForm.map((member, index) => {
              const error = !re.test(member.email)
              if (error) {
                hasError = true
                return {
                  ...member,
                  error:
                    index === 0 &&
                    hasFileWithRecords &&
                    member.email.trim() === ''
                      ? false
                      : true,
                }
              }
              return {
                ...member,
                error: undefined,
              }
            }),
          },
          () => {
            if (hasError && !hasFileWithRecords) {
              return null
            }
            return this.setState(
              {
                uploadingUsers: true,
              },
              () => {
                newMembersForm.forEach((member) => {
                  if (member && member.email) {
                    dataToSend[member.email] = {
                      email: member.email,
                      teamAdmin: member.type === 'team_admin',
                      isFile: false,
                    }
                  }
                })
                this.props.importTeamUsers(
                  this.props.match.params.organization_uuid,
                  this.state.sendOnboarding,
                  this.props.match.params.team_uuid,
                  dataToSend,
                  (data) => {
                    if (data.success) {
                      UIkit.modal.alert(
                        '<h2>Import Successful</h2><p>Congratulations! You have successfully imported new team members.</p>'
                      )
                    } else if (data.msg === 'PRIVILEGES_NEEDED') {
                      UIkit.modal.alert(
                        '<h2>Warning</h2><p>You don\'t have privileges to invite new users.</p>'
                      )
                    } else if (data.msg === 'NOT_ENOUGH_LICENSES') {
                      UIkit.modal.alert(
                        '<h2>Warning</h2><p>Your organization does not have enough licenses to add these users. Please contact your administrator.</p>'
                      )
                    }
                    this.props.getTeamUsers(
                      this.props.match.params.organization_uuid,
                      this.props.match.params.team_uuid
                    )
                    this.props.getTeamById(
                      this.props.match.params.organization_uuid,
                      this.props.match.params.team_uuid,
                      true
                    )
                    this.props.hide()
                  }
                )
              }
            )
          }
        )
      }
    )
  }

  handleChangeMetadataFile(ev) {
    const { files } = ev.target // FileList object
    const f = files[0]

    const reader = new FileReader()

    this.setState({ fileName: ev.target.value.replace('C:\\fakepath\\', '') })
    const isCSV = ev.target.value.toLowerCase().indexOf('.csv') > 0
    const isExcel =
      ev.target.value.toLowerCase().indexOf('.xlsx') > 0 ||
      ev.target.value.toLowerCase().indexOf('.xls') > 0

    reader.onload = (() => (e) => {
      if (isCSV) {
        this.setState(
          {
            file: e.target.result
              .split('\n')
              .map((row) => row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)),
          },
          () => {
            this.setState(
              {
                confirmUploadMessage: null,
              },
              () => {
                this.preProcessingData()
              }
            )
          }
        )
      } else if (isExcel) {
        import('exceljs').then(({ default: Excel }) => {
          const workbook = new Excel.Workbook()
          workbook.xlsx
            .load(e.target.result)
            .then(() => {
              const { rows } = workbook.model.sheets[0]
              this.setState(
                {
                  file: rows.reduce((prev, { cells }) => {
                    const obj = cells.map(({ value, text }) => {
                      if (value) {
                        return value.toString()
                      }
                      if (text && typeof text === 'string') {
                        // Support cells with style
                        return text.toString()
                      }
                      if (
                        text !== null &&
                        typeof text === 'object' &&
                        text.richText &&
                        text.richText[0]
                      ) {
                        return text.richText[0].text
                      }
                      return ''
                    })
                    if (obj[0] !== '') prev.push(obj)
                    return prev
                  }, []),
                },
                () => {
                  this.setState(
                    {
                      confirmUploadMessage: null,
                    },
                    () => {
                      this.preProcessingData()
                    }
                  )
                }
              )
            })
            .catch((err) => {
              console.error({ err })
            })
        })
      } else {
        document.getElementById('import_team_member_input').value = ''
      }
    })(f)
    if (isCSV) {
      reader.readAsText(f)
    } else {
      reader.readAsArrayBuffer(f)
    }
  }

  preProcessingData() {
    this.setState(
      {
        loadingPreproccesing: true,
      },
      () => {
        const dataToSend = {}
        const invalidEmail = {}
        let repeated = 0
        const re =
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

        const hasHeaders =
          this.state.file &&
          this.state.file[0] &&
          typeof this.state.file[0][0] === 'string' &&
          !re.test(this.state.file[0][0])
        this.state.file.forEach((row, index) => {
          const email = row[0].toLowerCase()
          if (
            ((index > 0 && hasHeaders) || !hasHeaders) &&
            !re.test(email) &&
            row.length >= 2
          ) {
            invalidEmail[email] = true
          } else if (
            ((index > 0 && hasHeaders) || !hasHeaders) &&
            row.length >= 4 &&
            !dataToSend[email]
          ) {
            dataToSend[email] = {
              email,
              teamAdmin: Boolean(row[1].match(/TRUE|1/i)),
              isFile: true,
            }
          } else if (
            ((index > 0 && hasHeaders) || !hasHeaders) &&
            row.length >= 4
          ) {
            repeated += 1
          }
        })
        const totalToBeImported = Object.keys(dataToSend).length
        setTimeout(() => {
          const totalInvalidEmails = Object.keys(invalidEmail).length
          // this.props.setUsersToImport(dataToSend)
          this.setState({
            dataToSend,
            confirmUploadMessage: `
            <small class="text-success">- ${totalToBeImported} new team members will be imported to team.</small>
            ${
              repeated > 0
                ? `<br/><small class="text-danger">- ${repeated} repeated records were found and will be ignored.</small>`
                : ''
            }
            ${
              totalInvalidEmails > 0
                ? `<br/><small class="text-danger">- ${totalInvalidEmails} records were ignored because email is wrong</small>`
                : ''
            }
          `,
            loadingPreproccesing: false,
          })
        }, 500)
      }
    )
  }

  clearForm() {
    this.setState({
      fileName: null,
      dataToSend: {},
      newMembersForm: [
        {
          email: '',
          type: 'member',
        },
      ],
    })
  }

  handleChangeAddForm(index, key, e) {
    const { newMembersForm } = this.state
    newMembersForm[index][key] = e.target.value
    this.setState({
      newMembersForm,
    })
  }

  handleAddNew() {
    const { newMembersForm } = this.state
    this.setState({
      newMembersForm: [
        ...newMembersForm,
        {
          email: '',
          type: 'member',
        },
      ],
    })
  }

  handleDeleteMember(index) {
    const { newMembersForm, dataToSend } = this.state
    if (dataToSend[newMembersForm[index].email]) {
      delete dataToSend[newMembersForm[index].email]
    }
    this.setState({
      newMembersForm: newMembersForm.filter((_, i) => i !== index),
      dataToSend,
    })
  }

  renderNewMembers() {
    return this.state.newMembersForm.map((member, index) => (
      <React.Fragment key={`member-${index}`}>
        <div className='uk-width-1-2@s uk-inline'>
          {this.state.newMembersForm.length > 1 && (
            <Icon
              as='a'
              role='button'
              className='uk-form-icon uk-form-icon-flip'
              onClick={this.handleDeleteMember.bind(this, index)}
              name='delete-bin'
            />
          )}
          <div>
            <AutoComplete
              extraAction={this.handleAddNew}
              organizationUUID={this.props.match.params.organization_uuid}
              id={`autocomplete-${index}`}
              className='uk-input'
              type='text'
              placeholder='e.g: jane.smith@acme.com'
              value={member.email}
              index={index}
              onChange={this.handleChangeAddForm}
              style={
                member.error
                  ? {
                      border: 'solid 1px #f0506e',
                    }
                  : {}
              }
            />
          </div>
        </div>
        <div className='uk-width-1-2@s'>
          <select
            className='uk-select'
            type='text'
            value={member.type}
            onChange={this.handleChangeAddForm.bind(this, index, 'type')}
          >
            <option value='member'>Team Member</option>
            <option value='team_admin'>Team Admin</option>
          </select>
        </div>
      </React.Fragment>
    ))
  }

  render() {
    return (
      <>
        <div className='uk-margin'>
          <h4>
            File Upload
            <Icon
              as='a'
              href='https://help.securityjourney.com/en/articles/3143713-import-users-with-user-csv'
              name='question'
              ratio={0.8}
              style={{ verticalAlign: 'top', marginLeft: 10 }}
              target='_blank'
              rel='noopener noreferrer'
            />
          </h4>
          <div>
            <div className='uk-width-1-1' data-uk-form-custom='target: true'>
              <input
                id='import_team_member_input'
                type='file'
                onChange={this.handleChangeMetadataFile}
              />
              <input
                className='uk-placeholder uk-input uk-form-width-medium uk-width-1-1'
                type='text'
                placeholder='Drag a file or click here to upload CSV or XLSX'
                disabled
              />
            </div>
            <span
              className='font-bold'
              dangerouslySetInnerHTML={{
                __html: this.state.confirmUploadMessage,
              }}
            />
          </div>
        </div>
        <hr />
        <form className='uk-grid-small' uk-grid=''>
          <div className='uk-width-1-1@s'>
            <h3>Email</h3>
          </div>
          {this.renderNewMembers()}
          <div className='uk-width-1-1@s'>
            <a
              type='button'
              role='button'
              onClick={this.handleAddNew}
              className='flex text-green'
              style={{ textDecoration: 'none' }}
            >
              <Icon className='uk-margin-small-right' name='add-circle' />
              Add another
            </a>
          </div>
        </form>
        <br />
        <br />
        <div className='text-right'>
          <div className='uk-inline uk-align-left'>
            <input
              className='uk-checkbox'
              type='checkbox'
              disabled={!this.props.hasInvitePrivileges}
              checked={this.state.sendOnboarding}
              onChange={(e) =>
                this.setState({ sendOnboarding: e.target.checked })
              }
              style={{
                marginTop: 10,
                padding: '5px 0 0 10px',
                verticalAlign: 'top',
                display: 'inline-block',
              }}
            />
            <span
              style={{
                padding: '5px 0 0 10px',
                verticalAlign: 'top',
                display: 'inline-block',
                color: !this.props.hasInvitePrivileges ? '#bdbdbd' : undefined,
              }}
            >
              Send onboarding email to imported users
            </span>
            <Icon
              as='a'
              className='pl-1'
              href='https://help.securityjourney.com/en/articles/3149755'
              name='question'
              ratio={0.8}
              style={{ verticalAlign: 'top', display: 'inline-block' }}
              target='_blank'
              rel='noopener noreferrer'
            />
          </div>
          <PrimaryButton
            size={'small'}
            onClick={this.props.hide}
            customCss={'w-32'}
            gray={true}
            label={this.props.closeText || 'Cancel'}
          />

          <PrimaryButton
            size='small'
            customCss={'ml-2 w-32'}
            label='Invite Teammates'
            onClick={this.onSubmitNewTeamMembers}
          />
        </div>
      </>
    )
  }
}

export default AddUsers
