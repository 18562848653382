import React from 'react'
import PropTypes from 'prop-types'

import { VBox, HBox, Box } from 'app/views/core/Box'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'

import TestStatusIcon from './TestStatusIcon'

function getText(result) {
  if (result === 'pending') return 'Testing in progress...'
  if (result === 'passed') return 'All tests passed!'
  if (result === 'failed') return 'Submission failed.'
  return 'Test Submission'
}

function TestSubmissionHeading(props) {
  const { result, submittedAt } = props
  return (
    <HBox grow>
      <Box fixed={50} style={{ paddingTop: 12 }}>
        <TestStatusIcon absolute size={40} status={result} />
      </Box>
      <VBox grow>
        <h3 className='uk-margin-remove-bottom'>{getText(result)}</h3>
        <p className='text-sm' style={{ marginTop: 0, marginLeft: 10 }}>
          Submitted {getLocalDateFromUTC(submittedAt).fromNow()}
        </p>
      </VBox>
    </HBox>
  )
}
TestSubmissionHeading.propTypes = {
  result: PropTypes.oneOf(['pending', 'passed', 'failed']).isRequired,
  submittedAt: PropTypes.string.isRequired,
}

export default TestSubmissionHeading
