import React from 'react'
import PropTypes from 'prop-types'

/**
 * Displays the text of a request and allows the user to edit it. Deals only
 * in plain text; does not handle serializing/parsing to/from request objects.
 */
function ProxyEditor(props) {
  const { requestText, onRequestTextChange } = props
  return (
    <textarea
      style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#333',
        color: '#eee',
        padding: 14,
        resize: 'none',
        whiteSpace: 'pre-wrap',
        fontFamily:
          '"Roboto Mono", Consolas, "Andale Mono", "Lucida Console", "DejaVu Sans Mono", Monaco, "Courier New", Courier, monospace',
        border: 0,
        outline: 'none',
      }}
      value={requestText}
      onChange={(event) => {
        event.preventDefault()
        onRequestTextChange(event.target.value)
      }}
      spellCheck={false}
    />
  )
}
ProxyEditor.propTypes = {
  requestText: PropTypes.string,
  onRequestTextChange: PropTypes.func.isRequired,
}

export default ProxyEditor
