import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import queryString from 'query-string'

import Icon from 'app/views/components/Icon'

// Redux
import { onboardUser } from 'app/state/modules/hacker'

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired, // withRouter
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired, // withRouter
  isAuthenticated: PropTypes.bool.isRequired,
  onboardUser: PropTypes.func.isRequired,
}

class Invite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
    this.renderError = this.renderError.bind(this)
  }

  componentDidMount() {
    let inviteToken = queryString.parse(this.props.location.search).h || null
    if (inviteToken === null) {
      inviteToken = this.props.match.params.id || null
    }
    if (inviteToken) {
      // setting invite token...
      window.localStorage.setItem('hackedu.teams.invite_token', inviteToken)

      if (!this.props.isAuthenticated) {
        // edirecting to register page...
        this.props.history.push('/register')
      } else {
        // logged in and onboarding user...
        this.props
          .onboardUser(this.props.idToken, inviteToken, null)
          .then(({ data }) => {
            if (
              !data.success &&
              (data.error === 'used-token' || data.error === 'invalid-token')
            ) {
              this.setState({
                error: data.error,
              })
            } else {
              window.location.replace('/')
            }
          })
      }
    } else {
      // redirecting to home...
      this.props.history.push('/')
    }
  }

  renderError() {
    switch (this.state.error) {
      case 'used-token':
        return (
          <div className='uk-card uk-card-body uk-card-default uk-width-1-2 text-center italic'>
            This link is a single-use admin onboarding link, which has already
            been used. Please let the person who sent this to you know that a
            standard invite link is needed. Thanks!
          </div>
        )
      default:
        return (
          <div className='uk-card uk-card-body uk-card-default uk-width-1-2 text-center italic'>
            Whoops! This onboarding link is invalid. Please let the person who
            sent this to you know that a new link is needed.
          </div>
        )
    }
  }

  renderMessage() {
    if (this.state.error) {
      return (
        <>
          <h3 className='flex flex-row items-center justify-center'>
            <Icon
              name='close'
              ratio={1.6}
              className='text-danger uk-margin-small-right'
            />
            Onboarding Failed
          </h3>
          {this.renderError()}
          <buton
            className='uk-button uk-button-primary uk-margin-top'
            onClick={() => window.location.assign('/')}
          >
            Go to Home
          </buton>
        </>
      )
    }
    return null
  }

  render() {
    return (
      <div
        className='flex flex-1-0 items-start items-center flex-col uk-margin uk-padding  text-emphasis'
        style={{ height: 'calc(100vh - 80px)' }}
      >
        {this.renderMessage()}
      </div>
    )
  }
}

Invite.propTypes = propTypes

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.status === 'LOGGED_IN',
  idToken: state.auth.user.idToken,
})

const mapDispatchToProps = (dispatch) => ({
  onboardUser: (idToken, onboardingToken) =>
    dispatch(onboardUser(idToken, onboardingToken)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invite))
