import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'app/views/components/Icon'

const propTypes = {
  hasCompletedContent: PropTypes.bool,
  hasPassedContent: PropTypes.bool,
  hasTest: PropTypes.bool,
  lessonInProgress: PropTypes.bool,
  license: PropTypes.bool,
  requiresSubscription: PropTypes.bool,
  marginTop: PropTypes.number,
  mustPassCodingExercise: PropTypes.bool,
  small: PropTypes.bool,
  isActiveForUser: PropTypes.bool,
  disabled: PropTypes.bool,
}

const defaultProps = {
  hasCompletedContent: false,
  hasPassedContent: false,
  hasTest: false,
  lessonInProgress: false,
  license: null,
  requiresSubscription: null,
  marginTop: 20,
  mustPassCodingExercise: false,
  small: false,
  isActiveForUser: undefined,
  disabled: false,
}

const ContentIcon = ({
  hasCompletedContent,
  hasPassedContent,
  hasTest,
  lessonInProgress,
  license,
  requiresSubscription,
  marginTop,
  mustPassCodingExercise,
  isActiveForUser,
  small,
  disabled,
}) => {
  if (
    (license === false && requiresSubscription === true) ||
    (requiresSubscription && !isActiveForUser)
  ) {
    let icon = 'lock'
    if (small) {
      icon = 'lock'
    }
    return (
      <Icon
        style={{
          cursor: 'pointer',
          marginRight: 10,
          marginTop,
        }}
        ratio={small ? 0.9 : 1}
        className='uk-inline'
        name={icon}
        data-uk-toggle='target: #modal-content-icon'
      />
    )
  }

  let background = 'none'
  let border = '3px solid #1e87f0'
  let icon = ''
  let width = 15
  let height = 15
  let marginRight = 10
  let ratio = 0.8
  if (small) {
    width = 10
    height = 10
    marginRight = 5
    ratio = 0.6
  }

  if (
    mustPassCodingExercise === true &&
    hasTest === true &&
    lessonInProgress === true &&
    hasPassedContent === false
  ) {
    background = '#f0506e'
    border = '3px solid #f0506e'
    // color = 'red'
    icon = 'close'
  } else if (hasCompletedContent === true) {
    background = '#1e87f0'
    icon = 'check'
  } else if (lessonInProgress === true) {
    background = '-webkit-linear-gradient(90deg, #1e87f0 50%, #ffffff 50%)'
  }
  if (icon === '') {
    return (
      <div
        className='uk-inline'
        style={{
          background: `${background}`,
          border,
          width,
          height,
          borderRadius: '50%',
          marginRight,
          marginTop,
          color: '#FFFFFF',
          cursor: 'pointer',
          opacity: disabled ? 0.6 : 1,
        }}
        data-uk-toggle='target: #modal-content-icon'
      />
    )
  }
  return (
    <div
      className='uk-inline'
      style={{
        background: `${background}`,
        border,
        width,
        height,
        borderRadius: '50%',
        marginRight,
        marginTop,
        color: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: disabled ? 0.6 : 1,
      }}
      data-uk-toggle='target: #modal-content-icon'
    >
      <Icon name={icon} ratio={ratio} />
    </div>
  )
}

ContentIcon.propTypes = propTypes
ContentIcon.defaultProps = defaultProps

export default ContentIcon
