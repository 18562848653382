import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import UIkit from 'uikit'
import Icon from 'app/views/components/Icon'
import PrimaryButton from '../../Buttons/PrimaryButton'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import axios from 'axios'
import { CognitoUserPool } from 'amazon-cognito-identity-js'

// Redux
import { getProfile, getOrganizations } from 'app/state/modules/hacker'

// Utils
import analytics from 'app/views/utils/analytics'
import { logout } from 'app/views/utils/auth'
import settings from 'settings'

const propTypes = {
  license: PropTypes.bool,
}
const defaultProps = {
  license: false,
}

class DashboardNavInner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayMaintenance: false,
      hasClickedUsernameNotice: false,
      language: 'en',
      hideNav: false,
    }

    this.handleClickMyPlan = this.handleClickMyPlan.bind(this)
    this.handleClickAllTraining = this.handleClickAllTraining.bind(this)
    this.handleClickLeaderboard = this.handleClickLeaderboard.bind(this)
    this.handleClickQuiz = this.handleClickQuiz.bind(this)
    this.handleClickContact = this.handleClickContact.bind(this)
    this.handleClickProfile = this.handleClickProfile.bind(this)
    this.handleClickLogout = this.handleClickLogout.bind(this)
    this.handleSJClick = this.handleSJClick.bind(this)
    this.isTeamAdmin = this.isTeamAdmin.bind(this)
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search)
    const hideNav = params.get('nav') === 'none'

    if (this.props.isAuthenticated) {
      this.initiatePostAuthInit()
    }
    const language =
      localStorage.getItem('hackedu.language') === null
        ? 'en'
        : localStorage.getItem('hackedu.language')
    const hasClickedUsernameNotice =
      localStorage.getItem('hasClickedUsernameNotice') === 'true'

    this.setState({
      hasClickedUsernameNotice,
      language,
      hideNav,
    })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.initiatePostAuthInit()
    }
  }

  initiatePostAuthInit() {
    this.props.getOrganizations()
    this.props.getProfile()
  }

  handleClickMyPlan() {
    UIkit.offcanvas('#nav-dashboard').hide()
    analytics.track('click-navi-my-plan')
  }

  handleClickAllTraining() {
    UIkit.offcanvas('#nav-dashboard').hide()
    analytics.track('click-navi-all-training')
  }

  handleClickLeaderboard() {
    UIkit.offcanvas('#nav-dashboard').hide()
    analytics.track('click-navi-leaderboard')
  }

  handleClickQuiz() {
    UIkit.offcanvas('#nav-dashboard').hide()
    analytics.track('click-navi-quiz')
  }

  handleClickContact() {
    UIkit.offcanvas('#nav-dashboard').hide()
    analytics.track('click-navi-click')
  }

  handleClickProfile() {
    UIkit.offcanvas('#nav-dashboard').hide()
    analytics.track('click-navi-profile')
  }

  handleClickLogout() {
    UIkit.offcanvas('#nav-dashboard').hide()
    analytics.track('click-navi-logout')
    logout()
  }

  handleSJClick(e) {
    e.preventDefault()
    const poolData = {
      UserPoolId: settings.cognito.authData.UserPoolId,
      ClientId: settings.cognito.authData.ClientId,
    }
    const userPool = new CognitoUserPool(poolData)
    const user = userPool.getCurrentUser()
    if (user !== null) {
      user.getSession((err, session) => {
        if (err) {
          console.error('Error with user.getSession(): ', err)
          return
        } else {
          // get the cognito data
          const requestData = {
            access_token: session.getAccessToken().getJwtToken(),
            refresh_token: session.getRefreshToken().getToken(),
            id_token: session.getIdToken().getJwtToken(),
          }

          // do the handshake w/ cognito values
          axios({
            method: 'POST',
            url: `${settings.urls.sj}${this.props.flags.sjAuthHandshakeRoute}`,
            data: requestData,
          })
            .then(({ data }) => {
              // do the handoff via opening in a new window
              window.open(
                `${settings.urls.sjAuth.handoff}?code=${data.code}`,
                '_self'
              )
            })
            .catch((err) => {
              console.error('Error with handshake', err)
            })
        }
      })
    } else {
      console.error('Error retrieving current user', err)
      return
    }
  }

  isTeamAdmin() {
    const { organizations } = this.props
    for (const organizationUUID in organizations) {
      // eslint-disable-line
      if (
        organizations[organizationUUID].role === 'admin' ||
        organizations[organizationUUID].team_admin_uuids.length > 0
      ) {
        return true
      }
    }
    return false
  }

  renderLogo() {
    const orgWithLogo = this.props.organizations.find((item) => item.logo_url)
    if (
      orgWithLogo &&
      this.props.profile &&
      this.props.profile.email &&
      !this.props.profile.super_admin
    ) {
      return (
        <>
          <img
            src={orgWithLogo.logo_url}
            className='h-[50px] m-0.5'
            alt='Organization Logo'
          />
          <div className='flex flex-col items-center'>
            <span className='text-sm text-white font-bold'>Powered by</span>
            <img
              className='h-6'
              src='/static/images/SJ_HE_LogoWhiteHorizontal22.png'
              alt='sublogo'
            />
          </div>
        </>
      )
    }
    return (
      <img
        src='/static/images/SJ_HE_LogoWhiteHorizontal22.png'
        className='w-[300px]'
        alt='Logo'
      />
    )
  }

  render() {
    if (this.state.hideNav) {
      return null
    }

    this.renderLogo()
    const path = this.props.location.pathname
    return (
      <div>
        <div
          className='uk-container uk-container-expand uk-sticky uk-sticky-fixed uk-background-primary uk-lightx'
          style={{
            position: 'fixed',
            top: 0,
            width: '100%',
            background: '#161616',
          }}
        >
          <nav
            className='uk-navbar uk-light'
            data-uk-navbar=''
            data-uk-sticky=''
            style={{ height: 80 }}
          >
            <div className='uk-navbar-left uk-light'>
              <Link className='uk-navbar-item uk-logo uk-navbar-toggle' to='/'>
                {this.renderLogo()}
              </Link>
            </div>
            <div className='uk-navbar-right'>
              <ul className='uk-navbar-nav  uk-visible@m'>
                {this.props.organizations.length > 0 && (
                  <li>
                    <Link
                      onClick={() => {
                        analytics.track('click-navi-my-plan')
                      }}
                      to='/'
                    >
                      My Plan
                    </Link>
                  </li>
                )}

                {(this.props.showAllTraining || this.isTeamAdmin()) && (
                  <li>
                    <Link
                      data-test-id='nav-training-btn'
                      onClick={() => {
                        analytics.track('click-navi-all-training')
                      }}
                      to='/all'
                    >
                      All Training
                    </Link>
                  </li>
                )}

                {this.props.profile.email !== null &&
                  this.props.profile.email.endsWith('@allegiantair.com') !==
                    true && (
                    <li>
                      <Link
                        onClick={() => {
                          analytics.track('click-navi-leaderboard')
                        }}
                        to='/leaderboard'
                      >
                        Leaderboard
                      </Link>
                    </li>
                  )}

                {!!this.props.mySjEnabled && (
                  <li>
                    <a onClick={this.handleSJClick} href='#'>
                      Security Journey
                    </a>
                  </li>
                )}

                {this.isTeamAdmin() && (
                  <li>
                    <Link
                      onClick={() => {
                        analytics.track('click-navi-admin')
                      }}
                      to='/admin'
                    >
                      Admin
                    </Link>
                  </li>
                )}
              </ul>
              {this.props.license === false && (
                <div className='uk-navbar-item uk-visible@m'>
                  <Link
                    className='uk-button uk-button-default tm-button-default'
                    to={{ pathname: `${settings.urls.www}/contact` }}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => {
                      analytics.track('click-upgrade')
                    }}
                  >
                    Upgrade
                  </Link>
                </div>
              )}

              <div className='uk-navbar-item uk-visible@m'>
                <button
                  className='uk-button uk-button-default tm-button-default'
                  data-uk-toggle='target: #modal-language'
                  style={{
                    marginRight: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                  type='button'
                >
                  <a
                    href='#'
                    className='text-center uk-link-reset uppercase flex items-center'
                  >
                    <span>{this.state.language}</span>
                    <Icon name='arrow-down-s' fill />
                  </a>
                </button>

                <div
                  id='modal-language'
                  data-uk-modal=''
                  className='uk-padding-large'
                >
                  <div className='uk-modal-dialog uk-modal-body'>
                    <h2>Select Language</h2>
                    <div className='uk-margin'>
                      {settings.translationLanguages && (
                        <div className='uk-form-controls'>
                          {settings.translationLanguages.map((item) => {
                            return (
                              <PrimaryButton
                                label={item.name}
                                customCss={'mb-4'}
                                size='full'
                                key={item.language}
                                onClick={() => {
                                  this.setState({ language: item.language })
                                  analytics.track('click-select-language', {
                                    selectedLanguage: item.language,
                                  })
                                  localStorage.setItem(
                                    'hackedu.language',
                                    item.language
                                  )
                                  setTimeout(() => {
                                    window.location.reload()
                                  }, 200)
                                }}
                              />
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='uk-inline uk-visible@s'>
                  <Icon
                    type='button'
                    name='user'
                    style={{ cursor: 'pointer' }}
                    className='uk-inline uk-margin-left'
                  />
                  <div data-uk-dropdown='mode: click' id='dropdown-profile'>
                    <ul className='uk-nav uk-navbar-dropdown-nav uk-visible@s'>
                      <li>
                        <Link
                          to='/profile'
                          style={{ color: '#666666 !important' }}
                          onClick={() => {
                            UIkit.dropdown('#dropdown-profile').hide()
                          }}
                        >
                          Profile
                        </Link>
                      </li>
                      <li>
                        <a
                          href={`${settings.urls.www}/contact`}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ color: '#666666 !important' }}
                          onClick={() => {
                            UIkit.dropdown('#dropdown-profile').hide()
                            analytics.track('Navi Click (Contact Us)')
                          }}
                        >
                          Contact
                        </a>
                      </li>
                      <li>
                        <Link to='#' onClick={this.handleClickLogout}>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <button
                className='uk-navbar-toggle uk-hidden@m uk-navbar-toggle-icon uk-icon uk-navbar-right'
                data-uk-navbar-toggle-icon=''
                data-uk-toggle='target: #nav-dashboard'
                type='button'
              />
            </div>
          </nav>
        </div>
        <div
          id='nav-dashboard'
          data-uk-offcanvas='mode: push overlay: true'
          className='uk-offcanvas'
        >
          <div className='uk-offcanvas-bar' style={{ maxWidth: 250 }}>
            <button
              className='uk-offcanvas-close'
              type='button'
              data-uk-close
            />
            <div className='uk-panel'>
              <ul className='uk-nav uk-nav-default tm-nav uk-margin-bottom'>
                <li className='uk-nav-header'>HackEDU</li>
                {this.props.organizations.length > 0 && (
                  <li>
                    <Link to='/' onClick={this.handleClickMyPlan}>
                      My Plan
                    </Link>
                  </li>
                )}

                {(this.props.showAllTraining || this.isTeamAdmin()) && (
                  <li>
                    <Link to='/all' onClick={this.handleClickAllTraining}>
                      All Training
                    </Link>
                  </li>
                )}

                <li>
                  <Link to='/leaderboard' onClick={this.handleClickLeaderboard}>
                    Leaderboard
                  </Link>
                </li>

                {this.isTeamAdmin() && (
                  <li>
                    <Link to='/admin'>Admin</Link>
                  </li>
                )}

                <li className='uk-nav-header'>User</li>
                <li>
                  <Link
                    to='/profile'
                    onClick={this.handleClickProfile}
                    style={{ color: '#666666 !important' }}
                  >
                    Profile
                  </Link>
                </li>
                <li>
                  <Link to='#' onClick={this.handleClickLogout}>
                    Logout
                  </Link>
                </li>
                <hr />
                <li>
                  <a
                    href={`${settings.urls.www}/contact`}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={this.handleClickContact}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          style={{
            width: '100%',
            height: 80,
          }}
        />

        {(this.props.profile.username === '' ||
          this.props.profile.username === null) &&
          this.props.profile.points > 0 &&
          this.state.hasClickedUsernameNotice === false && (
            <div
              className='uk-light text-center uk-padding-small uk-inline'
              style={{
                width: '100%',
                backgroundColor: '#999',
              }}
            >
              <strong>Set a Username</strong>
              <br />
              You have scored &nbsp;
              <strong>{`${this.props.profile.points} points`}</strong>
              &nbsp; in our challenges. To show up on the leaderboard, &nbsp;
              <Link to='/profile' style={{ textDecoration: 'underline' }}>
                set a username
              </Link>
              .
              <Icon
                name='close'
                style={{
                  position: 'absolute',
                  top: 30,
                  right: 50,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.setState({ hasClickedUsernameNotice: true })
                  localStorage.setItem('hasClickedUsernameNotice', 'true')
                }}
                type='button'
              />
            </div>
          )}

        {this.state.displayMaintenance && (
          <div
            className='uk-light text-center uk-padding-small uk-inline'
            style={{
              width: '100%',
              backgroundColor: '#f0506e',
            }}
          >
            <strong>Maintenance</strong>
            <br />
            We are currently experiencing a service interuption with some of our
            lesson sandboxes. We're working to resolve this as soon as possible.
            <br />
            Thank you for your patience.
            <Icon
              name='close'
              onClick={() => {
                this.setState({ displayMaintentance: false })
              }}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: 50,
                top: 10,
              }}
              type='button'
            />
          </div>
        )}
      </div>
    )
  }
}

DashboardNavInner.propTypes = propTypes
DashboardNavInner.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  // hacker
  mySjEnabled: state.hacker.mySjEnabled,
  license: state.hacker.license,
  profile: state.hacker.profile,
  organizations: state.hacker.organizationsList,
  showAllTraining: state.hacker.showAllTraining,
  isAuthenticated: state.auth.status === 'LOGGED_IN',
})
const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
  getOrganizations: () => dispatch(getOrganizations()),
})

const DashboardNav = withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardNavInner))
)

export { DashboardNav }
