import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { storePostLoginRedirectUrl } from 'app/views/utils/authRedirect'

const PrivateRoute = ({
  component: Component,
  isPending,
  isAuthenticated,
  redirectPathName = null,
  title,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isPending) {
        return null
      }

      if (isAuthenticated) {
        return (
          <>
            {title ? <Helmet title={title} /> : null}
            <Component {...props} />
          </>
        )
      }

      storePostLoginRedirectUrl(rest.location.pathname, rest.location.search)
      return (
        <Redirect
          to={{
            pathname: redirectPathName || '/login',
            state: { from: props.location },
          }}
        />
      )
    }}
  />
)

export default connect((state) => {
  return {
    isPending: false,
    isAuthenticated: state.auth.status === 'LOGGED_IN',
  }
})(PrivateRoute)
