import React from 'react'
import { connect } from 'react-redux'
import settings from 'settings'

import Icon from 'app/views/components/Icon'

import { getIntegrations } from 'app/state/modules/integrations'

import OrganizationLayout from '../components/Layout'

class DataUsed extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderGithub() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              First we make a request to{' '}
              <code>https://api.github.com/user/repos</code>
              &nbsp;to get a list of repositories your token has access to. We
              only save the <code>name</code> of the repos you select in the
              integration process.
            </p>
            <pre>
              {`[
  {
    "id": "[REDACTED]",
    "node_id": "[REDACTED]",
    "name": "atka-app",
    ...
]`}
            </pre>
            <p>
              Each time the sync process is run we make an api call to this
              endpoint:{' '}
              <code>
                https://api.github.com/repos/:username/:repository_name/code-scanning/alerts
              </code>
              , requesting data only from the repositories that you selected in
              the integration process. The full response from this request is
              saved.
            </p>
            <pre>
              {`{
    "number": 4,
    "created_at": "2020-09-12T01:55:22Z",
    "url": "https://api.github.com/repos/:repository_name/vulnerable-app/code-scanning/alerts/4",
    "html_url": "https://github.com/:repository_name/vulnerable-app/security/code-scanning/4",
    "state": "open",
    "dismissed_by": null,
    "dismissed_at": null,
    "dismissed_reason": null,
    "rule": {
      "id": "js/reflected-xss",
      "severity": "error",
      "description": "Reflected cross-site scripting"
    },
    "tool": {
      "name": "CodeQL command-line toolchain",
      "version": null
    }
}`}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderAcunetix() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              First we make a request to <code>/api/v1/targets</code>
              &nbsp;to get a list of targets your token has access to. We only
              save the <code>description</code> and <code>address</code> of the
              targets you select in the integration process.
            </p>
            <pre>
              {`{
  "targets": [
    {
      "address": "11.111.111.111",
      "continuous_mode": "[REDACTED]",
      "criticality": "[REDACTED]",
      "default_scanning_profile_id": "[REDACTED]",
      "deleted_at": "[REDACTED]",
      "description": "Name Of Target",
      "fqdn": "[REDACTED]",
      "fqdn_hash": "[REDACTED]",
      "last_scan_date": "[REDACTED]",
      "last_scan_id": "[REDACTED]",
      "last_scan_session_id": "[REDACTED]",
      "last_scan_session_status": "[REDACTED]",
      "manual_intervention": "[REDACTED]",
      "scan_authorization": "[REDACTED]",
      "severity_counts": "[REDACTED]",
      "target_id": "[REDACTED]",
      "threat": "[REDACTED]",
      "type": "[REDACTED]",
      "verification": "[REDACTED]"
    },
    {`}
            </pre>
            <p>
              Each time the sync process is run we make an api call to this
              endpoint: <code>api/v1/vulnerabilities</code>, requesting data
              only from the targets that you selected in the integration
              process. The full response from this request is saved.
            </p>
            <pre>
              {`{
  "vulnerabilities": [
    {
      "affects_detail": "",
      "affects_url": "111.111.111.11:general/tcp",
      "app": "ovas",
      "archived": false,
      "confidence": 0,
      "continuous": true,
      "criticality": 10,
      "issue_id": null,
      "last_seen": "2020-11-05T00:22:55.263518+00:00",
      "severity": 0,
      "status": "open",
      "tags": [
        "confidence.0"
      ],
      "target_description": "Some TargetName",
      "target_id": "Target ID",
      "vt_created": null,
      "vt_id": "ID",
      "vt_name": "SSL/TLS: Hostname discovery from server certificate",
      "vt_updated": null,
      "vuln_id": "1293812932197381"
    },`}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderBugCrowd() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              First we make a request to{' '}
              <code>https://api.bugcrowd.com/programs</code>
              &nbsp;to get a list of programs your token has access to. We only
              save the <code>name</code>, <code>id</code> and <code>code</code>{' '}
              of the targets you select in the integration process.
            </p>
            <pre>
              {`{
  "id": "4427330a-bebc-41c0-b01d-5f7c8d339a2",
  "type": "program",
  "attributes": {
    "code": "hackedutest-ay1rx",
    "name": "HackEDU"
  }
},`}
            </pre>
            <p>
              Each time the sync process is run we make an api call to this
              endpoint: <code>https://api.bugcrowd.com/submissions</code>,
              requesting data only from the programs that you selected in the
              integration process. The full response from this request is saved.
            </p>
            <pre>
              {`{
  "id": "59798d32-edc3-477b-9603-d407bb084245",
  "type": "submission",
  "attributes": {
  "bug_url": null,
  "custom_fields": {},
  "description": "SQL Injection in login form from home page.",
  "duplicate": false,
  "extra_info": null,
  "http_request": null,
  "remediation_advice": null,
  "researcher_email": null,
  "source": "api",
  "submitted_at": "2018-11-13T20:20:39.000Z",
  "title": "SQL Injection",
  "vrt_id": "other",
  "vrt_version": "1.9",
  "vulnerability_references": null,
  "severity": 1,
  "state": "unresolved"
},`}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderWhiteHat() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              We make a request to
              <code>https://sentinel.whitehatsec.com/api/asset</code>
              and get the list of the assets you have registered in your
              WhiteHat account. From this api call we just save the &nbsp;
              <code>id</code>
              of the assets that you choose in the last integration step.
            </p>
            <pre>
              {`[
   {
    "collection": [
      {
        "asset": {
          "id": 80103,
          "label": "[REDACTED]",
          "client_id": "[REDACTED]"
        },
        "href": "[REDACTED]",
        "asset_type": "[REDACTED]"
      },
     ...
    ]
  }
`}
            </pre>
            <p>
              Each time the sync process is run we make an api call to 1
              endpoint: &nbsp;
              <code>https://sentinel.whitehatsec.com/api/vuln</code>
              &nbsp; requesting for the information of only the assets that you
              have previously registered in the integration process. All the
              response from this request is saved except for the field &nbsp;
              <code>url</code>
              &nbsp; that may contain sensible information
            </p>
            <pre>
              {`{
      "href": "/api/vuln/52449585",
      "threat": "0",
      "out_of_scope_reasons": "",
      "is_manual": 1,
      "opened": "2019-05-14T10:52:45Z",
      "found": "2019-05-14T10:52:45Z",
      "unreachable": null,
      "modified": "2019-11-20T23:13:09Z",
      "score": "0",
      "site_name": "OWASP JuiceShop DAST",
      "id": "52449585",
      "status": "closed",
      "service_level_abbr": "PE",
      "severity": "0",
      "mitigated_by": null,
      "url": "[REDACTED]",
      "site": "80103",
      "closed": "2019-11-20T23:13:09Z",
      "retest_state": "manual_retest_available",
      "mitigated": 1,
      "class": "Brute Force"
    }
              `}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;
              <a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderContrastSecurity() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              We make a request to{' '}
              <code>/Contrast/api/ng/:orgUUID/applications</code>
              &nbsp;and get the list of the applications you have registered in
              your Contrast Security account. From the response, we only save
              the <code>app_id</code> of the applications you choose in the last
              integration step.
            </p>
            <pre>
              {`[
    {
      "name": "[REDACTED]",
      "path": "[REDACTED]",
      "language": "[REDACTED]",
      "created": "[REDACTED]",
      "status": "[REDACTED]",
      "importance": "[REDACTED]",
      "archived": "[REDACTED]",
      "assess": "[REDACTED]",
      "assessPending": "[REDACTED]",
      "master": "[REDACTED]",
      "notes": "[REDACTED]",
      "defend": "[REDACTED]",
      "defendPending": "[REDACTED]",
      "roles": "[REDACTED]",
      "missingRequiredFields": "[REDACTED]",
      "links": "[REDACTED]",
      "app_id": "3c938a12-1d43-470b-955d-9d9727cd1db9",
      "last_seen": "[REDACTED]",
      "last_reset": "[REDACTED]",
      "size_shorthand": "[REDACTED]",
      ...
    }
]
              `}
            </pre>
            <p>
              Each time the sync process is run we make an api call to the
              filter endpoint:{' '}
              <code>/Contrast/api/ng/:orgUUID/traces/:appUUID/filter</code>,
              requesting information only for the applications you had
              registered in the integration process. The full response from this
              request is saved except for the <code>evidence</code> field, which
              may contain sensible information.
            </p>
            <pre>
              {`{
      "app_version_tags": [],
      "bugtracker_tickets": [],
      "category": "Caching",
      "closed_time": null,
      "confidence": "Low",
      "default_severity": "NOTE",
      "discovered": 1598461440000,
      "evidence": "[REDACTED]",
      "first_time_seen": 1598461440000,
      "hasParentApp": false,
      "impact": "Low",
      "instance_uuid": "2BQR-MWI8-NI2O-6AM6",
      "language": "Java",
      "last_time_seen": 1598461440000,
      "last_vuln_time_seen": 1598461440000,
      "license": "Licensed",
      "likelihood": "Low",
      "organization_name": "HackEDU",
      "reported_to_bug_tracker": false,
      "reported_to_bug_tracker_time": null,
      "rule_name": "cache-controls-missing",
      "rule_title": "Anti-Caching Controls Missing",
      "severity": "Note",
      "status": "Reported",
      "sub_status": "",
      "sub_title": "Anti-Caching Controls Missing detected",
      "substatus_keycode": null,
      "tags": [],
      "title": "Anti-Caching Controls Missing detected",
      "total_traces_received": 10,
      "uuid": "2BQR-MWI8-NI2O-6AM6",
      "visible": true
    }
              `}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderFortify() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              We make a request to <code>/api/v3/applications</code> and get the
              list of the applications you have registered in Fortify. We save
              the <code>applicationId</code> from this response for the
              applications you choose in the last integration step.
            </p>
            <pre>
              {`{
  "items": [
    {
      "applicationId": 106413,
      "applicationName": [REDACTED],
      "applicationDescription": [REDACTED],
      "applicationCreatedDate": [REDACTED],
      "businessCriticalityTypeId":[REDACTED],
      "businessCriticalityType": [REDACTED],
      "emailList": [REDACTED],
      "applicationTypeId": [REDACTED],
      "applicationType":[REDACTED],
      "hasMicroservices": [REDACTED],
      "attributes": [REDACTED]
    },
    ....
  ],
  ...
}`}
            </pre>
            <p>
              Each time the sync process is run we make an api call to two
              endpoints. The first one is <code>/api/v3/releases</code>. We
              don't save any data from this response, but we use the{' '}
              <code>releaseId</code>
              (from the apps you choose in the integration process) for the
              second request.
            </p>
            <p>
              The second endpoint we call is{' '}
              <code>/api/v3/releases/:releaseId/vulnerabilities</code>. We save
              the full response with the exception to the following fields which
              may contain sensible information we do not need:
            </p>

            <ul>
              <li>
                <code>primaryLocationFull</code>
              </li>
              <li>
                <code>primaryLocation</code>
              </li>
              <li>
                <code>lineNumber</code>
              </li>
              <li>
                <code>source</code>
              </li>
              <li>
                <code>sink</code>
              </li>
            </ul>

            <pre>
              {` {
      "id": 18009375,
      "releaseId": 109132,
      "fisma": "(Not Set)",
      "severityString": "Low",
      "severity": 1,
      "category": "Insecure Storage: Shared Keychain",
      "kingdom": "Encapsulation",
      "owasp2004": "(Not Set)",
      "owasp2007": "(Not Set)",
      "owasp2010": "(Not Set)",
      "owasp2013": "(Not Set)",
      "owasp2017": "(Not Set)",
      "cwe": "(Not Set)",
      "package": "Application Utilizes Shared Keychain",
      "primaryLocation": "[REDACTED]",
      "vulnId": "c96cb8db-61ad-4bfa-a3f1-8452f74e6037",
      "analysisType": "(Not Set)",
      "lineNumber": "[REDACTED]",
      "hasComments": false,
      "assignedUser": "(Not Set)",
      "scantype": "Dynamic",
      "subtype": "",
      "primaryLocationFull": "[REDACTED]",
      "hasAttachments": false,
      "pci1_1": null,
      "pci1_2": null,
      "pci2": "(Not Set)",
      "sans2009": "(Not Set)",
      "sans2010": "(Not Set)",
      "sans2011": "(Not Set)",
      "wasc24_2": "(Not Set)",
      "isSuppressed": false,
      "scanId": 151272,
      "pci3": "(Not Set)",
      "instanceId": "f7b8e9df-e537-42a9-96fb-3b30836783ec",
      "auditPendingAuditorStatus": "(No Change)",
      "auditorStatus": "Pending Review",
      "checkId": "M240",
      "closedDate": null,
      "closedStatus": false,
      "developerStatus": "Open",
      "falsePositiveChallenge": "(Not Set)",
      "introducedDate": "2020-08-05",
      "scanStartedDate": "2020-08-05T00:00:00",
      "scanCompletedDate": "2020-08-05T00:00:00",
      "status": "New",
      "bugSubmitted": false,
      "bugLink": "",
      "auditPendingSuppression": null,
      "source": "[REDACTED]",
      "sink": "[REDACTED]",
      "timeToFixDays": null
    }`}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderHackerone() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              For this integration, we make a request to
              <code>https://api.hackerone.com/v1/me/programs</code>
              &nbsp;and get the list of programs you have registered in your
              HackerOne account. We only save the <code>id</code> of the
              programs you choose in the integration process from this response.
            </p>
            <pre>
              {`{
  "data": [
    {
      "id": "49409",
      "type": "program",
      "attributes": "[REDACTED]"
    },
  ....
  ],
  "links": {}
}`}
            </pre>
            <p>
              Each time the sync process is run we make an api call to &nbsp;
              <code>https://api.hackerone.com/v1/programs/:programId</code>
              &nbsp;and get the handle code of your program. None of the data
              from this endpoint is saved.
            </p>
            <p>
              Next, we make a call to{' '}
              <code>https://api.hackerone.com/v1/reports</code>
              &nbsp;where we just fetch the information of the programs you
              selected in the integration process. We save the full response
              except for the <code>attributes.vulnerability_information</code>
              &nbsp;field (the report body), which may contain sensitive data we
              do not need.
            </p>
            <pre>
              {`{
  "id": "908194",
  "type": "report",
  "attributes": {
    "title": "Demo report: XSS in HackEDU home page",
    "state": "new",
    "created_at": "2020-06-25T18:08:39.104Z",
    "vulnerability_information": "[REDACTED]",
    "triaged_at": null,
    "closed_at": null,
    "last_reporter_activity_at": "2020-06-28T18:08:40.161Z",
    "first_program_activity_at": null,
    "last_program_activity_at": null,
    "bounty_awarded_at": null,
    "swag_awarded_at": null,
    "disclosed_at": null,
    "reporter_agreed_on_going_public_at": null,
    "last_public_activity_at": "2020-06-28T18:08:40.161Z",
    "last_activity_at": "2020-06-28T18:08:40.161Z",
    "source": null,
    "timer_bounty_awarded_elapsed_time": null,
    "timer_bounty_awarded_miss_at": null,
    "timer_first_program_response_miss_at": null,
    "timer_first_program_response_elapsed_time": null,
    "timer_report_resolved_miss_at": null,
    "timer_report_resolved_elapsed_time": null,
    "timer_report_triage_miss_at": null,
    "timer_report_triage_elapsed_time": null
  }`}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderJira() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              We make a request to <code>/rest/api/3/search</code> to retrieve
              the <code>id</code> for the projects you choose in the integration
              process.
            </p>
            <pre>
              {`{
  "self": "https://hackedu.atlassian.net/rest/api/3/project/search?maxResults=50&startAt=0",
  "maxResults": 50,
  "startAt": 0,
  "total": 2,
  "isLast": true,
  "values": [
    {
      "expand": "description,lead,issueTypes,url,projectKeys,permissions,insight",
      "self": "https://hackedu.atlassian.net/rest/api/3/project/10002",
      "id": "10002",
      "key": "HACK",
      "name": "HackEDU",
      "projectTypeKey": "software",
      "simplified": false,
      "style": "classic",
      "isPrivate": false,
      "properties": {}
    },
    ....
  ]
}`}
            </pre>
            <p>
              Each time the sync process is run we make a request to &nbsp;
              <code>/rest/api/3/search</code> and save the summary, description,
              project, and created_at date for all the issues for that project.
              We only store the issues that match with a vulnerability name in
              the title or description of the ticket.
            </p>
            <pre>
              {`{
  "expand": "operations,versionedRepresentations,editmeta,changelog,renderedFields",
  "id": "10126",
  "self": "https://hackedu.atlassian.net/rest/api/3/issue/10126",
  "key": "WWW-80",
  "fields": {
    "summary": "SQL Injection in login",
    "project": {
      "self": "https://hackedu.atlassian.net/rest/api/3/project/10000",
      "id": "10000",
      "key": "WWW",
      "name": "Web App",
      "projectTypeKey": "software",
      "simplified": false,
      "avatarUrls": {
        "48x48": "https://hackedu.atlassian.net/secure/projectavatar?pid=10000&avatarId=10325",
        "24x24": "https://hackedu.atlassian.net/secure/projectavatar?size=small&s=small&pid=10000&avatarId=10325",
        "16x16": "https://hackedu.atlassian.net/secure/projectavatar?size=xsmall&s=xsmall&pid=10000&avatarId=10325",
        "32x32": "https://hackedu.atlassian.net/secure/projectavatar?size=medium&s=medium&pid=10000&avatarId=10325"
      }
    },
    "description": {
      "version": 1,
      "type": "doc",
      "content": [
        {
          "type": "paragraph",
          "content": [
            {
              "type": "text",
              "text": "There is an sql injection in the login page. To replicate ..."
            }
          ]
        },
      ]
    },
    "created": "2019-11-26T09:51:54.302-0800"
  }
}`}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderHCLAppScan() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              We make a request to{' '}
              <code>https://cloud.appscan.com/api/v2/Apps</code>
              &nbsp;and get the list of applications you have registered in your
              HCL AppScan account. From this response, we only save the &nbsp;
              <code>Id</code> from the applications you choose in the
              integration process.
            </p>
            <pre>
              {`{
  "Id": "0d7c4851-ceb8-4fd9-a03b-5388c0bc0cfe",
  "AssetGroupName": "[REDACTED]",
  "DateCreated": "[REDACTED]",
  "LastUpdated": "[REDACTED]",
  ....
}`}
            </pre>
            <p>
              Each time the sync process is run, we make a request to &nbsp;
              <code>
                https://cloud.appscan.com/api/v2/Issues/Application/:ApplicationId
              </code>
              . We save all the issues from this response, but redact the
              following fields which may containe sensitive information we do
              not need:
            </p>
            <ul>
              <li>
                <code>Api</code>
              </li>
              <li>
                <code>Location</code>
              </li>
              <li>
                <code>SourceFile</code>
              </li>
            </ul>
            <pre>
              {`{
  "Id": "a5a7d25d-4cde-ea11-9b05-00155d550e89",
  "AsmHash": "-1881026083",
  "HashVersion": 2,
  "ApplicationId": "8a10c37e-5722-42fc-bf66-45b9fcf471d9",
  "FixGroupId": "99a7d25d-4cde-ea11-9b05-00155d550e89",
  "Api": "[REDACTED]",
  "Source": null,
  "Context": null,
  "AppscanVulnId": null,
  "CallingLine": null,
  "CallingMethod": null,
  "Class": null,
  "Cve": "https://vuln.whitesourcesoftware.com/vulnerability/CVE-2019-8331",
  "CvePublishDate": null,
  "DetailsUrl": "https://vuln.whitesourcesoftware.com/vulnerability/CVE-2019-8331",
  "Cvss": null,
  "DateCreated": "2020-08-14T16:37:03.34Z",
  "DiscoveryMethod": "SAST",
  "Domain": null,
  "Element": null,
  "ExternalId": null,
  "Host": null,
  "IssueTypeId": "",
  "IssueType": "OpenSource",
  "IssueXml": null,
  "LastUpdated": "2020-08-14T16:37:03.527Z",
  "Line": "",
  "Location": "[REDACTED]",
  "Package": null,
  "Path": null,
  "Port": 0,
  "Scheme": null,
  "SeverityValue": 3,
  "Severity": "Medium",
  "SourceFile": "[REDACTED]",
  "Status": "New",
  "LastComment": null,
  "Scanner": "AppScan Static Analyzer",
  "ScanName": "static my_scan.irx 20200814_11:34:11",
  "Cwe": null,
  "ThreatClassId": null,
  "DiffResult": "NoChange",
  "AvailabilityImpact": "Partial",
  "Classification": "Definitive",
  "ConfidentialityImpact": "Partial",
  "Authentication": null,
  "AccessComplexity": null,
  "AccessVector": null,
  "ProjectName": null,
  "Protocol": null,
  "RemediationLevel": null,
  "ReportConfidence": null,
  "NessusPluginId": null,
  "FixRecommendation": null,
  "IntegrityImpact": "Partial",
  "Summary": null,
  "WhiteHatSecVulnId": null,
  "StepsToReproduce": null,
  "Description": null,
  "Exploitability": null,
  "ApplicationName": "",
  "FriendlyId": null,
  "ApiVulnName": null
}`}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderGitlab() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              We make a request to <code>/api/v4/projects</code> and get the
              list of projects you have registered in your GitLab account. From
              this response we only save the <code>id</code> from the projects
              you select in the integration process. All other fields are
              redacted:
            </p>
            <pre>
              {`{
  "id": 20475068,
  "description": "[REDACTED]",
  "name": "[REDACTED]",
  "name_with_namespace": "[REDACTED]",
  "path": "[REDACTED]",
  "path_with_namespace": "[REDACTED]",
  "created_at": "[REDACTED]",
  "default_branch": "[REDACTED]",
  "tag_list": "[REDACTED]",
  "ssh_url_to_repo": "[REDACTED]",
  "http_url_to_repo": "[REDACTED]",
  "web_url": "[REDACTED]",
  "readme_url": "[REDACTED]",
  "avatar_url": "[REDACTED]",
  "forks_count": "[REDACTED]",
  "star_count": "[REDACTED]",
  "last_activity_at": "[REDACTED]",
  "namespace": "[REDACTED]",
}`}
            </pre>
            <p>
              Each time the sync process is run we make an api call to &nbsp;
              <code>/api/v4/projects/:projectId/vulnerability_findings</code>
              &nbsp;and we save all the information regarding the
              vulnerabilities found except for the following fields which may
              require sensitive information we do not need:
            </p>
            <ul>
              <li>location</li>
              <li>solution</li>
              <li>links</li>
              <li>blob_path</li>
              <li>create_vulnerability_feedback_issue_path</li>
              <li>create_vulnerability_feedback_merge_request_pathrr</li>
              <li>create_vulnerability_feedback_dismissal_path</li>
            </ul>
            <pre>
              {`{
  "id": null,
  "report_type": "dependency_scanning",
  "name": "Authentication bypass via incorrect DOM traversal and canonicalization in saml2-js",
  "severity": "unknown",
  "confidence": "undefined",
  "scanner": {
    "external_id": "gemnasium",
    "name": "Gemnasium"
  },
  "identifiers": [
    {
      "external_type": "gemnasium",
      "external_id": "9952e574-7b5b-46fa-a270-aeb694198a98",
      "name": "Gemnasium-9952e574-7b5b-46fa-a270-aeb694198a98",
      "url": "https://deps.sec.gitlab.com/packages/npm/saml2-js/versions/1.5.0/advisories"
    },
    {
      "external_type": "cve",
      "external_id": "CVE-2017-11429",
      "name": "CVE-2017-11429",
      "url": "https://cve.mitre.org/cgi-bin/cvename.cgi?name=CVE-2017-11429"
    }
  ],
  "project_fingerprint": "fa6f5b6c5d240b834ac5e901dc69f9484cef89ec",
  "create_vulnerability_feedback_issue_path": "[REDACTED]",
  "create_vulnerability_feedback_merge_request_path": "[REDACTED]",
  "create_vulnerability_feedback_dismissal_path": "[REDACTED]",
  "project": {
    "id": 31,
    "name": "yarn-remediation-test",
    "full_path": "/tests/yarn-remediation-test",
    "full_name": "tests / yarn-remediation-test"
  },
  "dismissal_feedback": null,
  "issue_feedback": null,
  "merge_request_feedback": null,
  "description": "[REDACTED]",
  "links": "[REDACTED]",
  "location": "[REDACTED]",
  "solution": "[REDACTED]",
  "blob_path": "[REDACTED]"
}`}
            </pre>
            <p>
              If you have any other questions feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderVeracode() {
    return (
      <>
        <div uk-grid='' className='justify-center'>
          <div className='uk-width-1-2'>
            <p>
              We make requests to the Veracode API at
              <code>https://api.veracode.com/appsec</code>, using the API key ID
              and secret value that you provide. All requests are signed using
              the
              <code>veracode_api_signing</code> Python package, as described{' '}
              <a href='https://docs.veracode.com/r/c_enabling_hmac'>here</a>.
            </p>
            <p>
              There are a total of two Veracode API endpoints that we use.
              First, we make requests to <code>/v1/applications</code> to get a
              list of applications associated with your Veracode account. When
              you choose to enable issue syncing for one of those applications,
              we store only the
              <code>guid</code> and <code>profile.name</code> values from that
              response:
            </p>
            <pre>
              {`{
  "guid": "84576933-126f-46e2-966d-e993f119a4e6",
  "profile": {
      "name": "ExampleApplication1.0.5",
      "...": "(all other fields are ignored)"
  },
  "...": "(all other fields are ignored)"
}`}
            </pre>
            <p>
              When you've enabled issue syncing for an application, we'll sync
              issue data for that application nightly. As part of this process,
              we make requests to <code>/v2/applications/:guid/findings</code>,
              where
              <code>guid</code> is the aforementioned application ID, to
              retrieve a list of vulnerability findings from the Veracode API.
            </p>
            <p>
              We store the data for each vulnerability that we find, but we
              first redact the <code>description</code> field, since it may
              contain arbitrary and potentially sensitive information:
            </p>
            <pre>
              {`{
  "build_id": 8495112,
  "context_guid": "84576933-126f-46e2-966d-e993f119a4e6",
  "context_type": "APPLICATION",
  "count": 1,
  "description": "[REDACTED]",
  "finding_details": {
    "attack_vector": "Cross-Site Request Forgery (CSRF)",
    "cwe": {
      "href": "https://api.veracode.com/appsec/v1/cwes/352",
      "id": 352,
      "name": "Cross-Site Request Forgery (CSRF)"
    },
    "discovered_by_vsa": 0,
    "finding_category": {
      "href": "https://api.veracode.com/appsec/v1/categories/11",
      "id": 11,
      "name": "Authentication Issues"
    },
    "hostname": "subdomain.example.com",
    "path": "/somepath/",
    "plugin": "Authentication Issues",
    "port": "8080",
    "severity": 3,
    "url": "http://subdomain.example.com:8080/somepath/",
    "vulnerable_parameter": "JSESSIONID"
  },
  "finding_status": {
    "first_found_date": "2020-09-13T20:56:17.380Z",
    "last_seen_date": "2020-09-13T21:00:17.769Z",
    "mitigation_review_status": "NONE",
    "new": true,
    "resolution": "UNRESOLVED",
    "resolution_status": "NONE",
    "status": "OPEN"
  },
  "issue_id": 7,
  "scan_type": "DYNAMIC",
  "violates_policy": false
}`}
            </pre>
            <p>
              If you have any other questions, feel free to send us a message
              &nbsp;<a href={`${settings.urls.www}/contact`}>here</a>.
            </p>
          </div>
        </div>
      </>
    )
  }

  renderIntegrationInformation() {
    switch (this.props.match.params.integration) {
      case 'fortify':
        return this.renderFortify()
      case 'jira':
        return this.renderJira()
      case 'hackerone':
        return this.renderHackerone()
      case 'hclappscan':
        return this.renderHCLAppScan()
      case 'whitehat':
        return this.renderWhiteHat()
      case 'contrastsecurity':
        return this.renderContrastSecurity()
      case 'gitlab':
        return this.renderGitlab()
      case 'github':
        return this.renderGithub()
      case 'acunetix':
        return this.renderAcunetix()
      case 'bugcrowd':
        return this.renderBugCrowd()
      case 'veracode':
        return this.renderVeracode()
      default:
        return 'Not found'
    }
  }

  render() {
    return (
      <OrganizationLayout {...this.props} active='integrations'>
        <button
          className='uk-button uk-button-secondary uk-button-small flex'
          type='button'
          onClick={() => {
            this.props.history.goBack()
          }}
        >
          <Icon name='arrow-left' className='uk-margin-small-right' />
          Go Back
        </button>
        <div
          className='text-center uk-padding uk-width-large uk-align-center'
          style={{
            background:
              settings.integrations[this.props.match.params.integration]
                .settings.color,
          }}
        >
          <img
            style={{
              width: 140,
            }}
            src={`/static/images/integrations/${
              settings.integrationLogos[this.props.match.params.integration]
            }`}
            alt={this.props.match.params.integration}
          />
        </div>
        <h4 className='text-center'>
          Data from integration saved in HackEDU
        </h4>
        {this.renderIntegrationInformation()}
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = ({ integrations, hacker, team }) => ({
  integrations: integrations.integrations,
  integrationsHash: integrations.integrationsHash,
  loadingIntegrations: integrations.loadingIntegrations,
  organizations: hacker.organizationsList,
  organizationsHash: hacker.organizationsHash,
  teams: team.teams,
})

export default connect(mapStateToProps, {
  getIntegrations,
})(DataUsed)
