export const CANNOT_BE_BLANK = 'Cannot be blank'
export const INVALID_EMAIL = 'Invalid email address'
export const INSUFFICIENT_PASSWORD_COMPLEXITY =
  'Password is of insufficient complexity. It must have the following:'
export const COMPLEXITY = {
  LENGTH: '10 or more characters',
  DIGITS: '1 digit',
  LCASE: '1 lowercase letter',
  UCASE: '1 uppercase letter',
  SPECIAL: '1 special character',
}

export const INCORRECT_CONFIRM_PASSWORD_DOES_NOT_MATCH =
  'Confirmation password does not match'
