import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

function BasicHintBody(props) {
  const { title, markdown, onClickBack } = props
  return (
    <>
      <h3>{title}</h3>
      {markdown.length > 0 && (
        <ReactMarkdown rehypePlugins={[rehypeRaw]} linkTarget='_blank'>
          {markdown}
        </ReactMarkdown>
      )}
      <a
        href='#'
        onClick={(event) => {
          event.preventDefault()
          onClickBack()
        }}
      >
        &larr; Back to Hints
      </a>
    </>
  )
}
BasicHintBody.propTypes = {
  title: PropTypes.string.isRequired,
  markdown: PropTypes.string.isRequired,
  onClickBack: PropTypes.func.isRequired,
}

export default BasicHintBody
