import React from 'react'
import UIkit from 'uikit'
import ButtonTabs from './ButtonTabs'

class Tabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = { activeTab: -1 }
    this.onChangeTab = this.onChangeTab.bind(this)
    this.shouldAddBottomSpace = this.props.noSpaceBottom
      ? false
      : this.props.tabs.some((item) => typeof item !== 'string')
  }

  componentDidMount() {
    this.tabs = UIkit.tab(this.props.id ? `#${this.props.id}` : '#_tabs')
    this.onChangeTab(this.props.initialTab || 0)
  }

  onChangeTab(activeTab) {
    if (activeTab === this.state.activeTab) return

    this.setState({ activeTab }, () => {
      if (!this.props.hide) {
        this.tabs.show(activeTab)
      }
      if (this.props.tabs[activeTab].onChangeTab) {
        this.props.tabs[activeTab].onChangeTab()
      }
    })
  }

  getCurrentIndex() {
    return this.state.activeTab
  }

  getCurrentData() {
    return {
      name: this.props.tabs[this.state.activeTab].name || 'Global',
      uuid: this.props.tabs[this.state.activeTab].uuid,
    }
  }

  renderTabs() {
    return this.props.tabs.map((tab, index) => {
      return (
        <li
          onClick={() => this.onChangeTab(index)}
          key={`tab_${tab.key || tab.title}`}
          data-test-id='tab-item'
          style={{
            height: '100%',
          }}
        >
          <a
            href='#'
            style={{
              display: 'table-cell',
              height: '100%',
              borderColor:
                index === this.state.activeTab ? 'green' : 'transparent',
            }}
          >
            {tab.title}
            {this.shouldAddBottomSpace ? <br /> : null}
          </a>
        </li>
      )
    })
  }

  renderContent() {
    return <p>{this.props.tabs[this.state.activeTab].title}</p>
  }

  render() {
    const { activeTab } = this.state
    if (this.props.hide) {
      return null
    }
    return (
      <div className={this.props.noMargin ? '' : 'uk-margin-medium-top'}>
        <ul
          id={this.props.id || '_tabs'}
          className='justify-center uk-width-1-1'
          style={{ alignItems: 'flex-end' }}
        >
          {this.renderTabs()}
        </ul>
        {(this.props.tabs[activeTab] || {}).component
          ? this.props.tabs[activeTab].component
          : null}
      </div>
    )
  }
}

export default Tabs
export { ButtonTabs }
