import React from 'react'
import PropTypes from 'prop-types'

import ContentModal from '../../common/ContentModal'
import { submitFeedback } from '../../common/util'

import CodingChallengeCompletionForm from './CodingChallengeCompletionForm'

function CodingChallengeCompletionModal(props) {
  const { contentId, onClose } = props

  function handleSubmit(numStarsRated, feedbackText) {
    if (numStarsRated && numStarsRated > 0 && numStarsRated <= 10) {
      submitFeedback(contentId, numStarsRated, feedbackText).then(() => {
        window.location.href = '/'
      })
    } else {
      window.location.href = '/'
    }
  }

  return (
    <ContentModal
      id='coding-challenge-completion'
      width='min(80vw, 700px)'
      onClose={onClose}
    >
      <div style={{ padding: 40 }}>
        <h3>Congratulations!</h3>
        <CodingChallengeCompletionForm onFinishChallenge={handleSubmit} />
      </div>
    </ContentModal>
  )
}
CodingChallengeCompletionModal.propTypes = {
  contentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CodingChallengeCompletionModal
