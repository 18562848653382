import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'app/views/core/Box'
import { Icon } from 'app/views/components/GustavoIcon'
import cx from 'classnames'

import { useFlashOnNewOutput } from './util'

const Container = ({ id, className, onClick, children }) => (
  <div
    id={id}
    className={className}
    onClick={onClick}
    style={{
      borderTop: '1px solid #eee',
      cursor: 'pointer',
      height: 40,
      display: 'flex',
      padding: '0 20px',
      alignItems: 'center',
    }}
  >
    {children}
  </div>
)
const Label = ({ id, text }) => (
  <span id={id} className='text-sm font-bold'>
    {text}
  </span>
)
const Badge = ({ count }) => (
  <div
    className='uk-label uk-primary'
    style={{
      borderRadius: 8,
      marginLeft: 10,
      marginBottom: 2,
    }}
  >
    {count}
  </div>
)
const ClearButton = ({ active, onClick }) => (
  <button
    className='uk-button uk-button-small'
    style={{
      flexBasis: 80,
      borderRadius: 10,
      marginLeft: 10,
      maxHeight: 28,
      overflow: 'hidden',
    }}
    disabled={!active}
    onClick={onClick}
  >
    Clear
  </button>
)

function SandboxOutputHeader(props) {
  const {
    numLines,
    numLinesUnacknowledged,
    isExpanded,
    onToggleExpanded,
    onClickClear,
  } = props
  const isFlashing = useFlashOnNewOutput(numLines)

  // In prod, there's a big blue Intercom button floating in the lower-right corner of the
  // viewport, so when the output window is collapsed, our "Clear" button is partially covered.
  // Just shove the button over to the left when the output panel is collapsed: this will look
  // goofy in dev/minikube but it'll be fine in prod.
  const clearButtonOffset = isExpanded ? 0 : 60

  return (
    <Container
      id='sandbox-output-header'
      className={cx({ flash: isFlashing })}
      onClick={onToggleExpanded}
    >
      <Icon name={isExpanded ? 'arrow-down-s' : 'arrow-up-s'} />
      <div style={{ marginLeft: 10, flexBasis: 200 }}>
        <Label id='sandbox-output-header-label' text='Sandbox Output' />
        {numLinesUnacknowledged > 0 && <Badge count={numLinesUnacknowledged} />}
      </div>
      <Box grow />
      <ClearButton
        active={numLines > 0}
        onClick={(event) => {
          event.stopPropagation()
          onClickClear()
        }}
      />
      {clearButtonOffset > 0 && <Box fixed={60} />}
    </Container>
  )
}
SandboxOutputHeader.propTypes = {
  numLines: PropTypes.number.isRequired,
  numLinesUnacknowledged: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onToggleExpanded: PropTypes.func.isRequired,
  onClickClear: PropTypes.func.isRequired,
}

export default SandboxOutputHeader
