import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ContentUI from '../common/ContentUI'
import ContentPingBeacon from '../common/ContentPingBeacon'
import InactiveSandboxModal from '../common/InactiveSandboxModal'
import InstructionPanel from '../common/InstructionPanel'
import WorkPanel from '../common/WorkPanel'
import { useSandboxInactivityTimeout, useTabState } from '../common/util'

import LessonInstructions from './LessonInstructions'
import LessonCompletionModal from './LessonCompletionModal'
import ProductTour from './ProductTour'
import { PRODUCT_TOUR_STAGES } from './ProductTour/data'
import { useProductTourState, renderedInSJApp } from './util'
import { notifyParentSjApp } from 'app/sjInterop'
import { useServiceShimLessonState } from '../../utils/apiServiceShim'
import { TournamentDetailsProps } from 'app/sjTournament'

import { connect } from 'react-redux'
import * as sandbox from 'app/state/modules/sandbox'
import * as hacker from 'app/state/modules/hacker'
import * as sbproxy from 'app/state/modules/sbproxy'
import * as sbrepl from 'app/state/modules/sbrepl'
import * as sbcrsubmission from 'app/state/modules/sbcrsubmission'
import * as progress from 'app/state/modules/progress'
import * as codesub from 'app/state/modules/codesub'
import { g as SvcShimState } from 'app/views/utils/apiServiceShim'

const params = new URLSearchParams(window.location.search)
const useSjTheme = window.parent !== window.self && params.get('theme') === 'sj'

function LessonUI(props) {
  // LessonUI isn't mounted until the content details have been fetched, so these values are
  // immutable for the lifetime of the component
  const { isDemo, contentId, contentDetails, sandboxTemplateName } = props
  const hasSandboxTemplate = sandboxTemplateName.length > 0
  const hasTests = contentDetails.has_test
  const isSJ = renderedInSJApp()

  // On mount, notify the API-to-service shim that we're now presenting the user with
  // this lesson, and clear that state on unmount
  const lessonKey = contentDetails['lesson_key'] || ''
  useServiceShimLessonState(contentId, lessonKey)

  // If 'eng_use_svc_code_sub' is enabled, we want to use svc-code-sub to fetch
  // submissions and watch submission status, and we want initiate code submissions via
  // svc-sandbox (rather than going through api-hacker)
  const { engUseSvcCodeSub } = useFlags()

  // We can set our desired intercept state (based on intercept_on_load) before we have a sandbox:
  // sbproxy will cache this as persistent state and act upon it when the proxy is ready to be used
  const { setInitialInterceptState } = props
  const wantsInterceptsEnabledInitially = !!contentDetails.intercept_on_load
  useEffect(() => {
    setInitialInterceptState(wantsInterceptsEnabledInitially)
  }, [wantsInterceptsEnabledInitially])

  // Figure out if the user hasn't yet completed the latest version of the product tour.
  // Currently, we only offer the product tour on SQL Injection: Part 1.
  const canOfferProductTour =
    contentId === 'ffaf1a2c-563c-49ea-94f9-a9be559a21b9'
  const [shouldOfferProductTour, flagProductTourCompleted] =
    useProductTourState()

  // Automatically start the product tour if this is the demo
  const [wantsProductTour, setWantsProductTour] = useState(
    isDemo ? true : false
  )

  // Kick off some state initialization now that we're ready to start this content
  const {
    sandboxIsReady,
    requestNewSandbox,
    abandonSandbox,
    fetchTestSubmissions,
    setupProgress,
    teardownProgress,
    initSvcCodeSub,
    teardownSvcCodeSub,
  } = props
  useEffect(() => {
    // Request a sandbox (without supplying forceNew, so we'll retain existing sandbox on reload)
    if (hasSandboxTemplate) {
      requestNewSandbox(contentId, sandboxTemplateName)
    }

    // If the lesson has tests, fetch the user's code submissions for this lesson
    if (!isDemo && hasTests) {
      // Fetch submissions from both api-hacker and svc-code-sub regardless of whether
      // engUseSvcCodeSub is set; so we have the data from both sources in-memory and
      // can seamlessly switch between them when the feature flag changes
      fetchTestSubmissions(contentId)
      initSvcCodeSub(lessonKey, props.tournamentDetails)
    }

    // Initialize the progress module to notify api-hacker of the user's progress through the lesson
    setupProgress(contentId, sandboxTemplateName)

    // Clean up if we leave this lesson
    return () => {
      abandonSandbox()
      teardownProgress()
      teardownSvcCodeSub()
    }
  }, [])

  // Pull some state from other modules, and format data to feed down to child components
  const {
    hasRepl,
    numProxyRequests,
    interceptedRequest,
    resumeInterceptedRequest,
  } = props

  // Lessons with repl code should present a single-file code editor. Lessons without
  // repl may set {"hasMultiFileCode": true} in the content metadata to enable a
  // multi-file editor that uses api-filesystem
  const hasMultiFileCode =
    !hasRepl && !!contentDetails.metadata.hasMultiFileCode
  const hasCode = hasRepl || hasMultiFileCode

  const browserTabs = contentDetails.tabs.sort(
    (a, b) => a.sort_order - b.sort_order
  )
  const testStages = (contentDetails.tests || []).map((obj) => ({
    name: obj.test_name,
    title: obj.title,
    description: obj.description,
  }))

  // Track a bit of state that affects the UI as a whole
  const proxiedBrowserTabIndex = browserTabs.findIndex((tab) => tab.proxy)
  const hasProxy = proxiedBrowserTabIndex >= 0
  const [activeTabName, onActivateTab] = useTabState('browser-0')
  const actualActiveTabName =
    proxiedBrowserTabIndex >= 0 && interceptedRequest
      ? `browser-${proxiedBrowserTabIndex}`
      : activeTabName

  // We use CodeEditor for single-file repl content and MultiFileCodeEditor for lessons
  // that use api-filesystem
  let codeEditorProps = { lessonKey }
  if (hasMultiFileCode) {
    codeEditorProps.isLoading = !sandboxIsReady
    codeEditorProps.defaultFilePath = contentDetails.metadata.defaultFile
    codeEditorProps.lockedFilePaths = contentDetails.metadata.lockedFiles || []
    if (hasTests) {
      const isSubmittingCode = engUseSvcCodeSub
        ? props.isSvcSubmittingCode
        : !!props.isSubmittingMultiFileCode
      const isSubmissionPending = engUseSvcCodeSub
        ? isSvcRunningTests
        : !!props.isMultiFileCodeSubmissionPending
      codeEditorProps.isSubmittingCode = isSubmittingCode
      codeEditorProps.isCodeSubmissionPending = isSubmissionPending
      codeEditorProps.onSubmitCode = () => {
        const metadata = contentDetails.metadata
        const languageName = (metadata.language || {}).name || 'unknown'
        if (engUseSvcCodeSub) {
          props.createCodeSubmission(
            lessonKey,
            SvcShimState.lesson.loadedAt,
            props.tournamentDetails
          )
        } else {
          props.initiateMultiFileCodeSubmission(languageName)
        }
      }
      codeEditorProps.onFocusActiveCodeSubmission = () => {
        onActivateTab('user', 'tests')
      }
    }
  } else {
    codeEditorProps.contentId = contentId
    codeEditorProps.contentHasTests = hasTests
    codeEditorProps.tournamentDetails = props.tournamentDetails
  }

  // When we start running tests, switch to the tests tab as if the user had clicked on it
  const { isHackerRunningTests, isSvcRunningTests } = props
  const isRunningTests = engUseSvcCodeSub
    ? isSvcRunningTests
    : isHackerRunningTests
  useEffect(() => {
    if (isRunningTests || props.isMultiFileCodeSubmissionPending) {
      onActivateTab('user', 'tests')
    }
  }, [isRunningTests, props.isMultiFileCodeSubmissionPending])

  // Notify the sbrepl module when the user opens the Code Editor tab: so that it knows it has to
  // respect the user's preferred language choice instead of falling back on Python.
  // (This is a workaround for the fact that compiled languages are slow to evaluate with repl.)
  const { noteUserHasOpenedCodeEditor } = props
  useEffect(() => {
    if (activeTabName === 'code') {
      noteUserHasOpenedCodeEditor()
    }
  }, [activeTabName])

  // When a new sandbox is requested, automatically switch to the first browser tab
  const { lastSandboxRequestTimestamp } = props
  useEffect(() => {
    if (lastSandboxRequestTimestamp) {
      onActivateTab('user', 'browser-0')
    }
  }, [lastSandboxRequestTimestamp])

  useEffect(() => {
    if (isSJ) {
      const message = {
        type: 'lesson-title',
        value: contentDetails.title,
      }
      notifyParentSjApp(message)
    }
  }, [isSJ, contentDetails])

  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const toggleTrialModal = useCallback((tab, showVaue) => {
    onActivateTab('user', tab)
    setShowUpgradeModal(showVaue)
  }, [])

  // Allow the user to try completing the lesson once they've reached the last step: the
  // LessonCompletionDialog will decide whether they still need to pass tests etc.
  const [wantsToCompleteLesson, setWantsToCompleteLesson] = useState(false)

  // If the user sits too long without interacting with the sandbox, show a modal forcing them to
  // request a new sandbox
  const { trackProgress } = props
  const sandboxIsInactive = useSandboxInactivityTimeout(120 * 60 * 1000)

  // Check if user has completed tests before notifying SJ
  const { hasPassingTest, requirePassingTest } = props
  const checkCanCompleteSJ = () => {
    const needsTest = hasTests && requirePassingTest
    const passingTest = hasTests && hasPassingTest
    const canCompleteLesson = !needsTest || passingTest
    if (canCompleteLesson) trackProgress(contentDetails.steps.length)
    notifyParentSjApp({
      type: canCompleteLesson ? 'lesson-complete' : 'lesson-needs-work',
    })
  }
  return (
    <ContentUI useSjTheme={useSjTheme}>
      {wantsToCompleteLesson && (
        <LessonCompletionModal
          contentId={contentId}
          hasTests={hasTests}
          hasPassingTest={hasPassingTest}
          onClose={() => setWantsToCompleteLesson(false)}
          flagLessonCompleted={() => trackProgress(contentDetails.steps.length)}
        />
      )}
      {sandboxIsInactive && (
        <InactiveSandboxModal
          onClose={() => requestNewSandbox(contentId, sandboxTemplateName)}
        />
      )}
      {!sandboxIsInactive && !isDemo && (
        <ContentPingBeacon contentId={contentId} />
      )}
      <InstructionPanel
        contentId={contentId}
        title={contentDetails.title}
        showLessonTitle={!isSJ}
      >
        {(scrollToTop) => (
          <LessonInstructions
            contentId={contentId}
            hasTests={hasTests}
            contentCompletedAt={contentDetails.completed_at}
            contentPassedAt={contentDetails.passed_at}
            title={contentDetails.title}
            steps={contentDetails.steps.sort(
              (a, b) => a.sort_order - b.sort_order
            )}
            shouldOfferProductTour={
              canOfferProductTour && shouldOfferProductTour
            }
            scrollToTop={scrollToTop}
            onProductTourRequested={() => setWantsProductTour(true)}
            onAttemptCompleteLesson={() => {
              if (!isSJ) {
                setWantsToCompleteLesson(true)
              } else {
                checkCanCompleteSJ()
              }
            }}
          />
        )}
      </InstructionPanel>
      <WorkPanel
        contentId={contentId}
        contentUsesRealUrls={contentDetails.metadata.useRealUrls || false}
        sandboxTemplateName={sandboxTemplateName}
        browserTabs={browserTabs}
        hasCode={hasCode}
        hasMultiFileCode={hasMultiFileCode}
        codeEditorProps={codeEditorProps}
        hasProxy={hasProxy}
        hasTests={hasTests}
        hasMultiFileTests={false}
        hasOutput={!contentDetails.hide_terminal}
        activeTabName={actualActiveTabName}
        onActiveTabNameChange={(tabName) => onActivateTab('user', tabName)}
        numProxyRequests={numProxyRequests}
        testStages={testStages}
        interceptedRequest={interceptedRequest}
        onSubmitInterceptedRequest={resumeInterceptedRequest}
        toggleTrialModal={toggleTrialModal}
        showUpgradeModal={showUpgradeModal}
      />
      {wantsProductTour && (
        <ProductTour
          stages={PRODUCT_TOUR_STAGES}
          onStageChange={(stageIndex, extra) => {
            onActivateTab('tour', extra?.lockedTabName || null)
          }}
          onDismiss={() => {
            setWantsProductTour(false)
            onActivateTab('tour', null)
          }}
          onFinish={() => {
            setWantsProductTour(false)
            onActivateTab('tour', null)
            flagProductTourCompleted()
          }}
        />
      )}
    </ContentUI>
  )
}
LessonUI.propTypes = {
  isDemo: PropTypes.bool.isRequired,
  contentId: PropTypes.string.isRequired,
  contentDetails: PropTypes.object.isRequired,
  tournamentDetails: TournamentDetailsProps,
  sandboxTemplateName: PropTypes.string.isRequired,
  hasRepl: PropTypes.bool.isRequired,
  isHackerRunningTests: PropTypes.bool.isRequired,
  isSvcSubmittingCode: PropTypes.bool.isRequired,
  isSvcRunningTests: PropTypes.bool.isRequired,
  numProxyRequests: PropTypes.number.isRequired,
  interceptedRequest: PropTypes.object,
  lastSandboxRequestTimestamp: PropTypes.number,
  isSubmittingMultiFileCode: PropTypes.bool,
  isMultiFileCodeSubmissionPending: PropTypes.bool,

  setInitialInterceptState: PropTypes.func.isRequired,
  requestNewSandbox: PropTypes.func.isRequired,
  abandonSandbox: PropTypes.func.isRequired,
  fetchTestSubmissions: PropTypes.func.isRequired,
  initSvcCodeSub: PropTypes.func.isRequired,
  teardownSvcCodeSub: PropTypes.func.isRequired,
  setupProgress: PropTypes.func.isRequired,
  teardownProgress: PropTypes.func.isRequired,
  trackProgress: PropTypes.func.isRequired,
  resumeInterceptedRequest: PropTypes.func.isRequired,
  noteUserHasOpenedCodeEditor: PropTypes.func.isRequired,
  initiateMultiFileCodeSubmission: PropTypes.func.isRequired,
  requirePassingTest: PropTypes.bool.isRequired,
  hasPassingTest: PropTypes.bool.isRequired,
}

export default connect(
  (state) => {
    const sandboxIsReady =
      !state.sandbox.requestError && !!state.sandbox.requestFinishedTimestamp
    const {
      concludedHistory,
      hasCompletedInitialPageLoad,
      requests,
      isIntercepting,
    } = state.sbproxy
    const canIntercept =
      hasCompletedInitialPageLoad && isIntercepting && requests.length > 0

    return {
      sandboxIsReady: sandboxIsReady,
      hasRepl: state.sbrepl.supportedEngines.length > 0,
      isHackerRunningTests: state.repl.runningTests,
      isSvcSubmittingCode: state.codesub.mode === 'submitting',
      isSvcRunningTests: state.codesub.mode === 'waiting',
      numProxyRequests: concludedHistory ? Object.keys(requests).length : 0,
      interceptedRequest:
        canIntercept && !requests[0].response ? requests[0] : null,
      lastSandboxRequestTimestamp: state.sandbox.lastRequest
        ? state.sandbox.lastRequest.timestamp
        : null,
      isSubmittingMultiFileCode:
        sandboxIsReady && state.sbcrsubmission.isSubmitting,
      isMultiFileCodeSubmissionPending:
        sandboxIsReady && state.sbcrsubmission.isPollingSubmission,
      requirePassingTest: state.hacker.mustPassCodingExercise,
      hasPassingTest:
        state.hacker.currentCodeSubmissions.some((x) => x.test.passed) ||
        state.codesub.submissions.some(
          (x) => (x.result.status || '') === 'passed'
        ),
    }
  },
  (dispatch) => ({
    setInitialInterceptState: (value) =>
      dispatch(sbproxy.setWantsToIntercept(value)),
    requestNewSandbox: (contentId, templateName) =>
      dispatch(sandbox.requestNew(contentId, templateName, false)),
    abandonSandbox: () => dispatch(sandbox.abandon()),
    fetchTestSubmissions: (contentId) =>
      dispatch(hacker.getCodeSubmissionsEx(contentId)),
    initSvcCodeSub: (lessonKey, tournamentDetails) =>
      dispatch(codesub.init(lessonKey, tournamentDetails)),
    teardownSvcCodeSub: () => dispatch(codesub.teardown()),
    setupProgress: (contentId, templateName) =>
      dispatch(progress.setup(contentId, templateName)),
    teardownProgress: () => dispatch(progress.teardown()),
    trackProgress: (stepIndex) => dispatch(progress.track(stepIndex)),
    resumeInterceptedRequest: (requestText, isEdited) =>
      dispatch(sbproxy.resumeInterceptedRequest(requestText, isEdited)),
    noteUserHasOpenedCodeEditor: () =>
      dispatch(sbrepl.noteUserHasOpenedCodeEditor()),
    initiateMultiFileCodeSubmission: (languageName) =>
      dispatch(sbcrsubmission.initiateSubmission(languageName)),
    createCodeSubmission: (lessonKey, lessonLoadedAt, tournamentDetails) =>
      dispatch(
        codesub.createSubmission(lessonKey, lessonLoadedAt, tournamentDetails)
      ),
  })
)(LessonUI)
