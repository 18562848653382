import React from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
} from 'recharts'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import { getTrainingTimeOverTimeChart } from 'app/state/modules/reports'

class TrainingTimeChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      trainingTimeChart: null,
    }
    this.mounted = true
  }

  componentDidMount() {
    this.debounce = setTimeout(() => {
      this.props
        .getTrainingTimeOverTimeChart(this.props.match.params.organization_uuid)
        .then(({ data }) => {
          if (this.mounted) {
            this.setState({
              trainingTimeChart: data,
              isLoading: false,
            })
          }
        })
        .catch((error) => console.error(error))
    }, 250)
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
  }

  renderContent() {
    if (this.state.isLoading) {
      return (
        <>
          <h2 className='text-center'>Proficiency Summary</h2>
          <div
            style={{ height: 450 }}
            className='uk-width-1-1 items-center justify-center flex flex-col'
          >
            <div data-uk-spinner='' />
            <span className='uk-small-margin-top'>Loading...</span>
          </div>
        </>
      )
    }
    const { trainingTimeChart } = this.state
    return (
      <>
        <h2 className='text-center'>Training Time over Time</h2>
        <ResponsiveContainer
          height={350}
          className='text-sm uk-width-1-1'
        >
          <AreaChart
            data={trainingTimeChart}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis
              dataKey='date'
              tickFormatter={(date) => {
                if (date === '') {
                  return ''
                }
                return `${moment(date, 'YYYY-MM-DD').format('MMM D')}  `
              }}
            />
            <YAxis allowDecimals />
            <Tooltip
              content={({ payload }) => {
                if (payload && payload[0]) {
                  return (
                    <div className='recharts-tooltip-wrapper'>
                      <div
                        className='recharts-default-tooltip'
                        style={{
                          border: 'solid 1px rgb(204, 204, 204)',
                          backgroundColor: '#fff',
                          padding: 10,
                        }}
                      >
                        <p className='recharts-tooltip-label uk-margin-remove-bottom'>
                          {payload[0].payload.date}
                        </p>
                        <ul className='uk-nav uk-nav-default uk-margin-small-top'>
                          <li style={{ color: '#1e87f0' }}>
                            <span>
                              {`${payload[0].payload.hours} ${
                                payload[0].payload.hours > 1 ? 'hours' : 'hour'
                              }`}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )
                }
                return null
              }}
            />
            <Area type='monotone' dataKey='hours' name='Hours' fill='#1e87f0' />
          </AreaChart>
        </ResponsiveContainer>
      </>
    )
  }

  render() {
    return <>{this.renderContent()}</>
  }
}

export default withRouter(
  connect(() => ({}), {
    getTrainingTimeOverTimeChart,
  })(TrainingTimeChart)
)
