import PropTypes from 'prop-types'
import React from 'react'

const propTypes = {
  visible: PropTypes.bool.isRequired,
}

const Loader = ({
  visible,
  text,
  ratio,
  textClassName = '',
  onlySpinner = false,
  size = 60,
}) => {
  if (onlySpinner) {
    return (
      <img
        src='/static/spinners/simple.svg'
        style={{
          width: size,
          height: size,
        }}
      />
    )
  }
  return visible ? (
    <div className='uk-section'>
      <div className='uk-container'>
        <div
          className={`${
            text ? 'flex flex-col  justify-center items-center' : ''
          } text-center`}
        >
          <div data-uk-spinner={ratio ? `ratio: ${ratio}` : ''} />
          {text && typeof text === 'string' ? (
            <p className={textClassName}>{text}</p>
          ) : (
            text || null
          )}
        </div>
      </div>
    </div>
  ) : (
    <div />
  )
}

Loader.propTypes = propTypes

export default Loader
