import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const containerStyles = {
  vertical: {
    position: 'fixed',
    left: 10,
    top: 170,
  },
  horizontal: {},
}

function StepDotnav(props) {
  const { currentStepIndex, numSteps, onSelectStep, vertical } = props
  const containerStyle = vertical
    ? containerStyles.vertical
    : containerStyles.horizontal

  return (
    <div style={containerStyle}>
      <ul className={cx('uk-dotnav', { 'uk-dotnav-vertical': vertical })}>
        {[...Array(numSteps).keys()].map((_, index) => (
          <li
            key={index}
            className={index === currentStepIndex ? 'uk-active' : ''}
          >
            <a href='#' onClick={() => onSelectStep(index)}>
              {currentStepIndex + 1}
            </a>
          </li>
        ))}
      </ul>
    </div >
  )
}
StepDotnav.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
  numSteps: PropTypes.number.isRequired,
  onSelectStep: PropTypes.func.isRequired,
  vertical: PropTypes.bool,
}

export default StepDotnav
