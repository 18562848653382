import _ from 'lodash'
import { isEmailValid } from 'app/views/utils/validators'
import {
  CANNOT_BE_BLANK,
  INVALID_EMAIL,
} from 'app/views/constants/errorMessages'

export default (fields) => {
  const { email } = fields
  const errors = {}

  const trimmedEmail = _.trim(email)
  if (!trimmedEmail) {
    errors.email = CANNOT_BE_BLANK
  } else if (!isEmailValid(trimmedEmail)) {
    errors.email = INVALID_EMAIL
  }

  return errors
}
