import React from 'react'
import PropTypes from 'prop-types'

function formatMonthHeading(label) {
  const [yearStr, monthStr] = label.split('-')
  const monthName = new Date(yearStr, parseInt(monthStr) - 1).toLocaleString(
    'default',
    { month: 'long' }
  )
  return `${monthName} ${yearStr}`
}

function formatCompletionSummary(
  numLessonsCompleted,
  numCategoriesWithCompletion,
  singleCategoryTitle
) {
  const NO_COMPLETION_SINGLE_CATEGORY =
    'No $CATEGORY lessons completed to date.'
  const NO_COMPLETION_MANY_CATEGORIES =
    'No lessons completed to date in any category.'
  const SINGLE_COMPLETION_SINGLE_CATEGORY =
    '1 $CATEGORY lesson completed to date.'
  const SINGLE_COMPLETION_MANY_CATEGORIES =
    '1 lesson completed to date in $NUM_CATEGORIES categories.'
  const MANY_COMPLETIONS_SINGLE_CATEGORY =
    '$NUM_COMPLETIONS $CATEGORY lessons completed to date.'
  const MANY_COMPLETIONS_MANY_CATEGORIES =
    '$NUM_COMPLETIONS lessons completed to date in $NUM_CATEGORIES categories.'
  const chooseString = () => {
    if (numLessonsCompleted === 0)
      return singleCategoryTitle
        ? NO_COMPLETION_SINGLE_CATEGORY
        : NO_COMPLETION_MANY_CATEGORIES
    if (numLessonsCompleted === 1)
      return singleCategoryTitle
        ? SINGLE_COMPLETION_SINGLE_CATEGORY
        : SINGLE_COMPLETION_MANY_CATEGORIES
    return singleCategoryTitle
      ? MANY_COMPLETIONS_SINGLE_CATEGORY
      : MANY_COMPLETIONS_MANY_CATEGORIES
  }
  return chooseString()
    .replace('$CATEGORY', singleCategoryTitle || '')
    .replace('$NUM_CATEGORIES', numCategoriesWithCompletion)
    .replace('$NUM_COMPLETIONS', numLessonsCompleted)
}

function formatVulnerabilitySummary(
  numVulnerabilitiesFound,
  numCategoriesWithVulnerabilities,
  singleCategoryTitle
) {
  const NO_VULNS_SINGLE_CATEGORY =
    'No new $CATEGORY vulnerabilities identified.'
  const NO_VULNS_MANY_CATEGORIES = 'No new vulnerabilities identified.'
  const SINGLE_VULN_SINGLE_CATEGORY =
    '1 new $CATEGORY vulnerability identified.'
  const SINGLE_VULN_MANY_CATEGORIES =
    '1 new vulnerability identified in $NUM_CATEGORIES categories.'
  const MANY_VULNS_SINGLE_CATEGORY =
    '$NUM_VULNS new $CATEGORY vulnerabilities identified.'
  const MANY_VULNS_MANY_CATEGORIES =
    '$NUM_VULNS new vulnerabilities identified in $NUM_CATEGORIES categories.'
  const chooseString = () => {
    if (numVulnerabilitiesFound === 0)
      return singleCategoryTitle
        ? NO_VULNS_SINGLE_CATEGORY
        : NO_VULNS_MANY_CATEGORIES
    if (numVulnerabilitiesFound === 1)
      return singleCategoryTitle
        ? SINGLE_VULN_SINGLE_CATEGORY
        : SINGLE_VULN_MANY_CATEGORIES
    return singleCategoryTitle
      ? MANY_VULNS_SINGLE_CATEGORY
      : MANY_VULNS_MANY_CATEGORIES
  }
  return chooseString()
    .replace('$CATEGORY', singleCategoryTitle || '')
    .replace('$NUM_CATEGORIES', numCategoriesWithVulnerabilities)
    .replace('$NUM_VULNS', numVulnerabilitiesFound)
}

function ChartTooltip(props) {
  const { label, payload, data, singleCategoryTitle } = props
  if (!label || !payload) {
    return null
  }

  const actualPayload = data.find((x) => x.month === label)
  if (!actualPayload) {
    return null
  }

  const numLessonsCompleted = actualPayload.completionSum
  const numCategoriesWithCompletion = Object.entries(
    actualPayload.completionCounts
  ).reduce((acc, x) => acc + (x[1] ? 1 : 0), 0)
  const completionSummary = formatCompletionSummary(
    numLessonsCompleted,
    numCategoriesWithCompletion,
    singleCategoryTitle
  )

  const numVulnerabilitiesFound = actualPayload.vulnerabilitySum
  const numCategoriesWithVulnerabilities = Object.entries(
    actualPayload.vulnerabilityCounts
  ).reduce((acc, x) => acc + (x[1] ? 1 : 0), 0)
  const vulnerabilitySummary = formatVulnerabilitySummary(
    numVulnerabilitiesFound,
    numCategoriesWithVulnerabilities,
    singleCategoryTitle
  )

  return (
    <div className='impact-report-tooltip'>
      <div className='impact-report-tooltip-header'>
        {formatMonthHeading(label)}
      </div>
      <div className='impact-report-tooltip-body'>
        <div className='impact-report-tooltip-row'>{completionSummary}</div>
        <div className='impact-report-tooltip-row'>{vulnerabilitySummary}</div>
      </div>
    </div>
  )
}
ChartTooltip.propTypes = {
  label: PropTypes.string,
  payload: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  singleCategoryTitle: PropTypes.string,
}

export default ChartTooltip
