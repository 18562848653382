import * as moment from 'moment'
import React from 'react'

class TimeRangePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
    }
    this.ranges = [
      {
        key: 'all',
        name: 'All Time',
      },
      {
        key: 'w',
        name: 'This Week',
      },
      {
        key: 7,
        name: 'Last 7 Days',
      },
      {
        key: 'm',
        name: 'This Month',
      },
      {
        key: 30,
        name: 'Last 30 Days',
      },
      {
        key: 'y',
        name: 'This Year',
      },
      {
        key: 365,
        name: 'Last 365 Days',
      },
    ]
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.setState(
      {
        selected: e.target.value,
      },
      () => {
        const today = moment().startOf('day')
        let days = null
        if (typeof this.props.onChange === 'function') {
          const { selected } = this.state
          if (selected === 'w') {
            const start = moment().startOf('week')
            days = today.diff(start, 'days')
          } else if (selected === 'm') {
            const start = moment().startOf('month')
            days = today.diff(start, 'days')
          } else if (selected === 'y') {
            const start = moment().startOf('year')
            days = today.diff(start, 'days')
          } else if (selected === 'all') {
            days = null
          } else {
            days = parseInt(selected, 10)
          }
          this.props.onChange(days)
        }
      }
    )
  }

  getValue() {
    return this.state.selected
  }

  render() {
    if (this.props.horizontal) {
      return (
        <div>
          {this.ranges.map((r, i) => (
            <button
              key={r.name}
              style={{
                borderBottomLeftRadius: i === 0 ? null : 0,
                borderTopLeftRadius: i === 0 ? null : 0,
                borderBottomRightRadius:
                  i === this.ranges.length - 1 ? null : 0,
                borderTopRightRadius: i === this.ranges.length - 1 ? null : 0,
              }}
              className={`uk-button uk-button-${
                this.state.selected === r.key ? 'secondary' : 'default'
              } uk-button-small`}
              type='button'
              onClick={this.onChange.bind(this, r.key)}
            >
              {r.name}
            </button>
          ))}
        </div>
      )
    }
    return (
      <div className={this.props.className}>
        <select
          onChange={this.onChange}
          className={this.props.classNameSelect || 'uk-select uk-form-small'}
          style={{
            borderRadius: 500,
            minWidth: 120,
          }}
        >
          {this.ranges.map((r, i) => (
            <option key={r.name} value={r.key}>
              {r.name}
            </option>
          ))}
        </select>
      </div>
    )
  }
}

export default TimeRangePicker
