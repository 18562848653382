import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components
import { DashboardNav } from 'app/views/components/Nav/DashboardNav'
import WwwNav from 'app/views/components/Nav/WwwNav'

const propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

class Nav extends React.PureComponent {
  render() {
    const {
      isAuthenticated,
      location: { pathname },
    } = this.props
    if (
      pathname === '/auth' ||
      pathname === '/login' ||
      pathname === '/forgot-password' ||
      pathname === '/reset-password' ||
      pathname === '/logout' ||
      (pathname === '/sj_login' && !isAuthenticated) ||
      pathname.startsWith('/link/')
    ) {
      return <div />
    }

    if (isAuthenticated) {
      return <DashboardNav />
    }

    return <WwwNav />
  }
}

Nav.propTypes = propTypes

const mapStateToProps = (state) => ({
  // auth
  isAuthenticated: state.auth.status === 'LOGGED_IN',
})
const mapDispatchToProps = () => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav))
