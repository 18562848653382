/* eslint-disable no-nested-ternary */
import React from 'react'
import moment from 'moment'
import UIkit from 'uikit'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import ConfirmButton from 'app/views/components/ConfirmButton'
import CustomContentModal from 'app/views/components/CustomContentModal'
import getLocalDateFromUTC from 'app/views/utils/getLocalDateFromUTC'
import LicenseMenu from 'app/views/containers/Organizations/Users/components/LicenseMenu'
import LicenseTag from 'app/views/components/LicenseTag'
import PopUpMenu from 'app/views/components/PopUpMenu'
import Icon from 'app/views/components/Icon'

class EditUser extends React.Component {
  render() {
    const { props } = this
    const expired =
      props.selectedUser.has_license &&
      props.selectedUser.expiration_date &&
      moment(props.selectedUser.expiration_date, 'YYYY-MM-DD').diff(moment()) <
        0

    return (
      <>
        {props.selectedUser.teams === 'loading' ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              flex: '1 1',
              height: '100%',
            }}
          >
            <span data-uk-spinner='' />
            <span>Loading...</span>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        ) : (
          <div>
            <h2 className='uk-margin-remove-bottom'>
              {props.selectedUser.email}
            </h2>
            {props.selectedUser.last_seen ? (
              <i className='text-sm text-muted'>{`Last seen: ${getLocalDateFromUTC(
                props.selectedUser.last_seen
              ).fromNow()}`}</i>
            ) : (
              <i className='text-sm text-muted'>Last seen: Never</i>
            )}
            <hr />
            <h3
              style={{
                marginBottom: 15,
                marginTop: 10,
              }}
            >
              Organization Role:
              <span>
                {props.selectedUser.role === 'admin' ? ' Admin' : ' Member'}
              </span>
            </h3>
            {props.hackerUUID !== props.selectedUser.uuid && (
              <>
                <ConfirmButton
                  text={
                    <>
                      Change Role to &nbsp;
                      <strong>
                        {props.selectedUser.role === 'admin'
                          ? 'Member'
                          : 'Admin'}
                      </strong>
                    </>
                  }
                  confirmClick={() => {
                    props
                      .updateHackerRole(
                        props.match.params.organization_uuid,
                        props.selectedUser.uuid,
                        props.selectedUser.role === 'admin' ? 'member' : 'admin'
                      )
                      .then(() => {
                        props.getHackers(props.match.params.organization_uuid)
                      })
                  }}
                />
                <button
                  type='button'
                  style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 20,
                  }}
                  className='uk-button uk-button-danger uk-width-1-1 _no_border_radius'
                  onClick={props.onRemoveFromOrg}
                >
                  <Icon
                    name='delete-bin'
                    style={{
                      position: 'absolute',
                      left: 10,
                    }}
                  />
                  Remove from Organization
                </button>
              </>
            )}
            <hr />
            <h3
              style={{
                marginBottom: 15,
                marginTop: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              Teams
              {props.isAdmin && (
                <a
                  onClick={() => props.modalUserTeamPicker.show()}
                  className='text-green'
                  style={{
                    position: 'absolute',
                    right: 20,
                    fontSize: 16,
                    textDecoration: 'underline',
                  }}
                >
                  Edit Teams
                </a>
              )}
            </h3>
            {props.selectedUser.teams.length === 0 ? (
              <p className='text-sm'>This user has no teams.</p>
            ) : (
              <table className='_teams-hacker-table uk-table uk-table-small uk-table-divider uk-width-1-1'>
                <thead>
                  <tr>
                    <th width='30%'>TEAM NAME</th>
                    <th width='30%'>ROLE</th>
                    <th width='30%'>INVITE PRIVILEGES</th>
                    <th width='30%' />
                  </tr>
                </thead>
                <tbody>
                  {props.selectedUser.teams.map((team) => (
                    <tr key={`row-${team.team_uuid}`}>
                      <td>{team.name}</td>
                      <td>
                        {props.selectedUser.role === 'admin'
                          ? 'Admin'
                          : team.team_role === 'member'
                          ? 'Member'
                          : 'Team Admin'}
                      </td>
                      <td>
                        <Icon
                          className={`${
                            (team.invite_privileges &&
                              team.team_role === 'team_admin') ||
                            props.selectedUser.role === 'admin'
                              ? 'text-success'
                              : 'text-danger'
                          }`}
                          name={`${
                            (team.invite_privileges &&
                              team.team_role === 'team_admin') ||
                            props.selectedUser.role === 'admin'
                              ? 'check'
                              : 'close'
                          }`}
                        />
                      </td>
                      <td>
                        {(props.selectedUser.role !== 'admin' ||
                          props.organization.role === 'admin') && (
                          <PopUpMenu
                            id='change-role'
                            leftCenter
                            beforeShow={() => {
                              document.getElementsByClassName(
                                '_right-side-bar _show'
                              )[0].style.overflowY = 'unset'
                            }}
                            afterHide={() => {
                              const element = document.getElementsByClassName(
                                '_right-side-bar  _show'
                              )[0]
                              if (element) {
                                element.style.overflowY = 'auto'
                              }
                            }}
                            style={{
                              width: 450,
                            }}
                            customRender={() => {
                              return (
                                <>
                                  <h4>
                                    {`${
                                      props.selectedUser.role === 'admin'
                                        ? 'Role: '
                                        : 'Team Role: '
                                    }`}
                                    {props.selectedUser.role === 'admin' ||
                                    team.team_role === 'admin' ? (
                                      <b>Admin</b>
                                    ) : (
                                      <b>Member</b>
                                    )}
                                  </h4>

                                  {props.selectedUser.role === 'admin' && (
                                    <p className='italic'>
                                      This user is an Organization Admin so
                                      their role cannot be changed at the team
                                      level.
                                    </p>
                                  )}
                                  {props.selectedUser.role !== 'admin' && (
                                    <>
                                      <ConfirmButton
                                        isSmall
                                        text={
                                          <>
                                            Change Role to
                                            {team.team_role === 'team_admin'
                                              ? ' Team Member'
                                              : ' Team Admin'}
                                          </>
                                        }
                                        confirmClick={() => {
                                          props.updateTeamPrivileges(
                                            props.match.params
                                              .organization_uuid,
                                            team.team_uuid,
                                            props.selectedUser.uuid,
                                            {
                                              team_admin: !(
                                                team.team_role === 'team_admin'
                                              ),
                                            },
                                            false,
                                            () => {
                                              this.props.onRefresh(
                                                props.selectedUser
                                              )
                                            }
                                          )
                                        }}
                                      />
                                      <hr />
                                    </>
                                  )}
                                  {team.team_role === 'team_admin' &&
                                    props.selectedUser.role !== 'admin' && (
                                      <>
                                        <form className='uk-form-stacked'>
                                          <div className='uk-margin'>
                                            <div className='uk-form-controls'>
                                              <label
                                                style={{
                                                  lineHeight: 2,
                                                }}
                                              >
                                                <input
                                                  className='uk-radio uk-margin-small-right'
                                                  type='radio'
                                                  name='radio1'
                                                  checked={
                                                    team.invite_privileges ===
                                                    true
                                                  }
                                                  onChange={() => {
                                                    props.updateTeamPrivileges(
                                                      props.match.params
                                                        .organization_uuid,
                                                      team.team_uuid,
                                                      props.selectedUser.uuid,
                                                      {
                                                        can_invite_users: true,
                                                      }
                                                    )
                                                  }}
                                                />
                                                Can invite users
                                              </label>
                                              <br />
                                              <label
                                                style={{
                                                  lineHeight: 2,
                                                }}
                                              >
                                                <input
                                                  // disabled={props.selectedUser.role === 'admin'}
                                                  className='uk-radio uk-margin-small-right'
                                                  type='radio'
                                                  name='radio1'
                                                  checked={
                                                    team.invite_privileges ===
                                                    false
                                                  }
                                                  onChange={() => {
                                                    props.updateTeamPrivileges(
                                                      props.match.params
                                                        .organization_uuid,
                                                      team.team_uuid,
                                                      props.selectedUser.uuid,
                                                      {
                                                        can_invite_users: false,
                                                      }
                                                    )
                                                  }}
                                                />
                                                Restrict invite privileges
                                              </label>
                                            </div>
                                          </div>
                                        </form>
                                        <hr />
                                      </>
                                    )}
                                  <ConfirmButton
                                    isSmall
                                    isDanger
                                    text={
                                      <>
                                        <Icon
                                          name='delete-bin'
                                          style={{
                                            position: 'absolute',
                                            left: 10,
                                          }}
                                        />
                                        Remove from Team
                                      </>
                                    }
                                    confirmClick={() => {
                                      props.onRemoveFromTeam(
                                        props.selectedUser,
                                        team.team_uuid
                                      )
                                    }}
                                  />
                                </>
                              )
                            }}
                            isTextTrigger
                            text='Edit'
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <h3
              style={{
                marginBottom: 15,
                marginTop: 10,
              }}
            >
              License
            </h3>
            <LicenseTag
              user={props.selectedUser}
              style={{ padding: 5, border: 'none' }}
              className='uk-width-1-1 text-center'
            />
            {props.selectedUser.has_license && (
              <ConfirmButton
                disabled={
                  (props.selectedUser.has_license &&
                    props.selectedUser.steps_completed > 20) ||
                  expired
                }
                ref={(ref) => {
                  this.confirmBtn = ref
                }}
                isSmall
                text={
                  <>
                    {(props.selectedUser.has_license &&
                      props.selectedUser.steps_completed > 20) ||
                    expired
                      ? 'Cannot Remove License'
                      : 'Remove License'}
                    {((props.selectedUser.has_license &&
                      props.selectedUser.steps_completed > 20) ||
                      expired) && (
                      <Icon
                        type='button'
                        role='button'
                        onClick={() => {
                          UIkit.modal.alert(`
                        <h2 >Cannot Remove License</h2>
                        <p>
                          We do not allow licenses to be removed or reassigned once a
                          user has already gone through several lessons.
                        </p>
                        <p>
                          If you have questions about this policy, you may reach out
                          to HackEDU at <a href="mailto:support@securityjourney.com">support@securityjourney.com</a>.
                        </p>`)
                        }}
                        style={{
                          position: 'absolute',
                          right: 10,
                          cursor: 'pointer',
                        }}
                        name='question'
                        ratio={0.8}
                      />
                    )}
                  </>
                }
                isDanger
                confirmClick={() => {
                  props.handleClickRemoveLicenseFromUser(
                    props.selectedUser.uuid,
                    props.selectedUser.license_type,
                    props.selectedUser.license_type_uuid
                  )
                  this.confirmBtn.onReset()
                }}
              />
            )}
            <LicenseMenu
              display={!props.selectedUser.has_license}
              availableLicenseTypes={props.availableLicenseTypes}
              availableLicenses={props.availableLicenses}
              handleClickAddLicenseToUser={props.handleClickAddLicenseToUser}
              handleClickRemoveLicenseFromUser={
                props.handleClickRemoveLicenseFromUser
              }
              user={props.selectedUser}
              expired={expired}
            />

            <hr />
            <h3 className='uk-margin-top uk-margin-small-bottom'>
              Assign Lessons
            </h3>
            <small>
              You can assign lessons directly to a single user. These lessons
              will show up as a "Custom Plan" in your users' dashboard (under
              "My Plan").
            </small>
            <PrimaryButton
              onClick={() => this.customContentModal.show()}
              size='full'
              label='Assign Lessons'
            />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
        <CustomContentModal
          id='custom-modal-content-edit-user'
          hackerUUID={props.selectedUser.uuid}
          onRef={(ref) => {
            this.customContentModal = ref
          }}
          email={props.selectedUser.email}
        />
      </>
    )
  }
}

export default EditUser
