import React from 'react'
import Icon from '../Icon'

export default function CircleButton({ tooltip, icon, onClick }) {
  return (
    <div
      onClick={onClick}
      data-uk-tooltip={tooltip}
      style={{
        borderRadius: '100%',
        background: '#f0516d',
        width: 25,
        height: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 10,
        cursor: 'pointer',
        color: 'white',
      }}
    >
      <Icon name={icon} ratio={0.7} />
    </div>
  )
}
