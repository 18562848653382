import React from 'react'

// Components
import Modal from 'app/views/components/UIkit/Modal'

const ModalActiveTeamPlan = () => (
  <Modal
    id='modal-info-active-team-plan'
    title='Active Team Plan'
    body={
      <div>
        <p>
          If you select the "Make Active" option, the created plan will be
          assigned to all users on this team.
        </p>
        <p>
          You can leave this off if you want to build the plan out before
          assigning it.
        </p>
        <p>Your team can only have one active plan.</p>
        <p className='text-right'>
          <button
            className='uk-button uk-button-primary uk-modal-close'
            type='button'
          >
            Ok
          </button>
        </p>
        <button
          className='uk-modal-close-outside'
          data-uk-close
          type='button'
        />
      </div>
    }
  />
)

export default ModalActiveTeamPlan
