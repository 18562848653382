import React, { useState, useEffect } from 'react'

type Props = {
  error?: Error | string | object
  accumulator: number
}

export const ErrorBanner = (props: Props) => {
  // Whenenver we get a valid error value, latch on to the current value of the
  // accumulator, so we can automatically stop rendering on the next increment
  const [initialAccumulatorValue, setInitialAccumulatorValue] = useState(-1)
  useEffect(() => {
    if (props.error) {
      setInitialAccumulatorValue(props.accumulator)
    } else {
      setInitialAccumulatorValue(-1)
    }
  }, [props.error])

  // If there's no error, or if the accumulator has been updated since we first saw the
  // error, render nothing
  if (!props.error || initialAccumulatorValue !== props.accumulator) {
    return null
  }

  // Otherwise, reduce the loosey-goosey input error to a human-readable message and
  // render it in a flashy red div
  let message = typeof props.error === 'string' ? props.error : (
    props.error['message'] || JSON.stringify(props.error)
  )
  return (
    <div className="p-1 mb-4 border-solid border-1 border-l-4 border-danger bg-danger_faded text-danger italic font-semibold">
      {message}
    </div>
  )
}
