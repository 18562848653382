import React from 'react'

// Components
import Modal from 'app/views/components/UIkit/Modal'

const ModalCodingTestRequired = () => (
  <Modal
    id='modal-info-coding-test-required'
    title='Coding Test Required'
    body={
      <div>
        <p>
          The "Coding Test Required" setting determines whether users have to
          submit a successful patch to a lesson with a coding exercise before it
          is considered completed.
        </p>
        <p>
          By default, this setting is set at the Organization level, but you can
          override this global setting at the Team level as well if you wish.
        </p>
      </div>
    }
  />
)

export default ModalCodingTestRequired
