import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../components/Buttons/PrimaryButton'
import cx from 'classnames'

import { HBox, Box } from 'app/views/core/Box'

const NavButton = ({ label, kind, active, onClick }) => (
  <button
    className={cx('uk-button uk-button-small', {
      [`uk-button-${kind}`]: Boolean(kind),
    })}
    disabled={!active}
    style={{
      width: '100%',
      height: '100%',
      borderRadius: 10,
    }}
    onClick={onClick}
  >
    {label}
  </button>
)

function StepNavigationButtons(props) {
  const {
    canNavigateBack,
    canNavigateForward,
    canEverFinish,
    isReadyToFinish,
  } = props
  const { onNavigateBack, onNavigateForward, onAttemptFinish } = props

  return (
    <HBox>
      <Box>
        <PrimaryButton
          label='Previous'
          visible={canNavigateBack}
          size='small'
          customCss={'text-black bg-light_gray'}
          onClick={() => onNavigateBack()}
        />
      </Box>
      <Box grow />
      <Box>
        {canEverFinish && !canNavigateForward ? (
          <PrimaryButton
            label='Finish'
            active={true}
            size='small'
            onClick={() => onAttemptFinish()}
          />
        ) : (
          <PrimaryButton
            label='Next'
            active={canNavigateForward}
            size='small'
            onClick={() => onNavigateForward()}
          />
        )}
      </Box>
    </HBox>
  )
}
StepNavigationButtons.propTypes = {
  canNavigateBack: PropTypes.bool.isRequired,
  canNavigateForward: PropTypes.bool.isRequired,
  canEverFinish: PropTypes.bool.isRequired,
  isReadyToFinish: PropTypes.bool.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
  onNavigateForward: PropTypes.func.isRequired,
  onAttemptFinish: PropTypes.func.isRequired,
}

export default StepNavigationButtons
