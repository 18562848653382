import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'

import MonthRangeDropdown from './MonthRangeDropdown'
import FilterDropdown from './FilterDropdown'
import Chart from './Chart'
import Table from './Table'
import { useIssueSourceFilter, useCategoryFilter } from './util'

import { connect } from 'react-redux'
import * as impactReport from 'app/state/modules/impactReport'

import './style.less'

function ImpactReport(props) {
  // Get the organization UUID from the URL, and tie our underlying state to this component's lifetime
  const organizationUUID = props.match.params.organization_uuid
  const { initImpactReport, shutdownImpactReport } = props
  useEffect(() => {
    initImpactReport(organizationUUID)
    return () => {
      shutdownImpactReport()
    }
  }, [])

  // Pull in some state related to vulnerability categories and which ones we've filtered down to; and also track which one we're hovering over
  const {
    vulnerabilityCategories,
    categoriesAreLoading,
    excludedCategoryIds,
    setExcludedCategoryIds,
  } = props
  const [getSelectedCategoryId, setSelectedCategoryId] = useCategoryFilter(
    vulnerabilityCategories,
    excludedCategoryIds,
    setExcludedCategoryIds
  )
  const listCategoryDropdownItems = () => {
    const head = { id: 'all', title: '(all categories)' }
    const formatItem = (category) => ({
      id: category.id.toString(),
      title: category.title,
    })
    return [head].concat(vulnerabilityCategories.map(formatItem))
  }

  // Pull in some state related to issue sources, and track which ones are selected
  const { issueSourcesAreLoading, issueSources, setSelectedIssueSourceTitles } =
    props
  const [getSelectedIssueSourceTitle, setSelectedIssueSourceTitle] =
    useIssueSourceFilter(issueSources, setSelectedIssueSourceTitles)
  const listIssueSourceDropdownItems = () => {
    const formatItem = (source) => ({ id: source.title, title: source.title })
    if (issueSources.length === 1) {
      return [formatItem(issueSources[0])]
    } else {
      const head = { id: 'all', title: '(all sources)' }
      return [head].concat(issueSources.map(formatItem))
    }
  }

  // Load some general display-related state from the impactReport module
  const {
    displayIsLoading,
    displayError,
    displayDateMode,
    displayStartMonth,
    displayEndMonth,
    displayData,
    maxCompletionsByCategoryId,
  } = props
  const { setDisplayDateMode, setDisplayDateRange } = props

  return (
    <OrganizationLayout
      active='impact-report'
      data-test-id='organization-users-component'
      style={{ position: 'relative' }}
      {...props}
    >
      <div className='impact-report'>
        <div className='impact-report-header'>
          <h2>Impact Report</h2>
          <p className='impact-report-description'>
            The Impact Report breaks down your organization's completed training
            by vulnerability category. For any category, you can see how many
            lessons have been completed over time, compared with how many
            vulnerabilities of that type were identified via Data Integrations
            in each month.
          </p>
          <div className='impact-report-controls'>
            <MonthRangeDropdown
              mode={displayDateMode}
              onModeChange={setDisplayDateMode}
              startMonth={displayStartMonth}
              endMonth={displayEndMonth}
              onDateRangeChange={setDisplayDateRange}
            />
            <FilterDropdown
              labelText='Category'
              inputId='impact-report-vulnerability-category-select'
              isLoading={categoriesAreLoading}
              items={listCategoryDropdownItems()}
              selectedId={getSelectedCategoryId()}
              onSelectedIdChange={setSelectedCategoryId}
            />
            <FilterDropdown
              labelText='Issue Source'
              inputId='impact-report-issue-source-select'
              isLoading={issueSourcesAreLoading}
              items={listIssueSourceDropdownItems()}
              selectedId={getSelectedIssueSourceTitle()}
              onSelectedIdChange={setSelectedIssueSourceTitle}
            />
          </div>
          {!issueSourcesAreLoading && issueSources.length === 0 && (
            <div className='impact-report-data-integration-alert'>
              <i className='ri-information-line' />
              <p>
                No issue sources configured. To start syncing vulnerability data
                from third-party tools, see{' '}
                <Link to={`/admin/${organizationUUID}/integrations`}>
                  Data Integrations
                </Link>
                .
              </p>
            </div>
          )}
        </div>
        <div className='impact-report-chart-container'>
          <Chart
            isLoading={displayIsLoading}
            vulnerabilityCategories={vulnerabilityCategories}
            excludedCategoryIds={excludedCategoryIds}
            data={displayData || []}
          />
        </div>
      </div>
      <Table
        isLoading={displayIsLoading}
        maxCompletionsByCategoryId={maxCompletionsByCategoryId}
        vulnerabilityCategories={vulnerabilityCategories}
        excludedCategoryIds={excludedCategoryIds}
        data={displayData || []}
      />
    </OrganizationLayout>
  )
}
ImpactReport.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      organization_uuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  hasLoadedOrganizations: PropTypes.bool.isRequired,
  organizationsHash: PropTypes.object,
  organizations: PropTypes.array,

  vulnerabilityCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  categoriesAreLoading: PropTypes.bool.isRequired,

  issueSources: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  issueSourcesAreLoading: PropTypes.bool.isRequired,

  maxCompletionsByCategoryId: PropTypes.object.isRequired,

  displayIsLoading: PropTypes.bool.isRequired,
  displayError: PropTypes.string,
  displayDateMode: PropTypes.oneOf(['all-time', 'one-year', 'custom'])
    .isRequired,
  displayStartMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
  }),
  displayEndMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
  }),
  excludedCategoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  displayData: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string.isRequired,
      vulnerabilityCounts: PropTypes.object.isRequired,
      completionCounts: PropTypes.object.isRequired,
      cumulativeCompletionCounts: PropTypes.object.isRequired,
    })
  ),

  initImpactReport: PropTypes.func.isRequired,
  shutdownImpactReport: PropTypes.func.isRequired,
  setDisplayDateMode: PropTypes.func.isRequired,
  setDisplayDateRange: PropTypes.func.isRequired,
  setExcludedCategoryIds: PropTypes.func.isRequired,
  setSelectedIssueSourceTitles: PropTypes.func.isRequired,
}

export default connect(
  (state) => {
    const display = state.impactReport.display
    return {
      hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
      organizationsHash: state.hacker.organizationsHash,
      organizations: state.hacker.organizationsList,
      vulnerabilityCategories: impactReport.getVulnerabilityCategories(
        state.impactReport
      ),
      categoriesAreLoading: state.impactReport.categories.isLoading,
      issueSources: impactReport.getIssueSources(state.impactReport),
      issueSourcesAreLoading: state.impactReport.issueSources.isLoading,
      maxCompletionsByCategoryId:
        state.impactReport.maxPossibleCompletions.data || {},
      displayIsLoading: impactReport.isLoading(state.impactReport),
      displayError: impactReport.getError(state.impactReport),
      displayDateMode: impactReport.getDateMode(
        state.impactReport,
        display.startMonth,
        display.endMonth
      ),
      displayStartMonth: display.startMonth,
      displayEndMonth: display.endMonth,
      excludedCategoryIds: display.excludedCategoryIds,
      displayData: display.data,
    }
  },
  (dispatch) => ({
    initImpactReport: (organizationUUID) =>
      dispatch(impactReport.init(organizationUUID)),
    shutdownImpactReport: () => dispatch(impactReport.shutdown()),
    setDisplayDateMode: (mode) =>
      dispatch(impactReport.setDisplayDateMode(mode)),
    setDisplayDateRange: (startMonth, endMonth) =>
      dispatch(impactReport.setDisplayDateRange(startMonth, endMonth)),
    setExcludedCategoryIds: (excludedCategoryIds) =>
      dispatch(impactReport.setExcludedCategoryIds(excludedCategoryIds)),
    setSelectedIssueSourceTitles: (selectedIssueSourceTitles) =>
      dispatch(
        impactReport.setSelectedIssueSourceTitles(selectedIssueSourceTitles)
      ),
  })
)(ImpactReport)
