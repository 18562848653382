import React from 'react'
import PropTypes from 'prop-types'

import { VBox } from 'app/views/core/Box'

import { getSubmissionResult } from './util'
import TestSubmissionListItem from './TestSubmissionListItem'

function TestSubmissionList(props) {
  const { submissions, selectedSubmissionId, onSelect } = props
  return (
    <VBox>
      {submissions.map((submission) => (
        <TestSubmissionListItem
          key={submission.id}
          isSelected={selectedSubmissionId === submission.id}
          onSelect={() => onSelect(submission.id)}
          result={getSubmissionResult(submission)}
          submittedAt={submission.submitted_at}
        />
      ))}
    </VBox>
  )
}
TestSubmissionList.propTypes = {
  submissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSubmissionId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default TestSubmissionList
