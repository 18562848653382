import React from 'react'
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Text,
  Legend,
} from 'recharts'
import { withRouter } from 'react-router-dom'
import Icon from 'app/views/components/Icon'
import { connect } from 'react-redux'
import UIKit from 'uikit'

import { getProficiencyChartReport } from 'app/state/modules/reports'
import CustomizedAxisTick from 'app/views/components/CustomizedAxisTick'

class ProficiencyChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      proficiencyChartReport: null,
    }
    this.mounted = true
    this.showHelp = this.showHelp.bind(this)
  }

  componentDidMount() {
    this.debounce = setTimeout(() => {
      this.props
        .getProficiencyChartReport(this.props.match.params.organization_uuid)
        .then(({ data }) => {
          if (this.mounted) {
            this.setState({
              proficiencyChartReport: data,
              isLoading: false,
            })
          }
        })
        .catch((error) => console.error(error))
    }, 250)
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
  }

  showHelp() {
    UIKit.modal.alert(
      `
    <h2>Proficiency Summary</h2>
    <p>
    The Proficiency Summary chart shows the number of users in each proficiency level for each vulnerability category. Users who do not have enough information for a category are not depicted in the chart.
    </p>`,
      { bgClose: true }
    )
  }

  render() {
    if (this.state.isLoading) {
      return (
        <>
          <h2 className='text-center flex'>
            Proficiency Summary
            <Icon
              type='button'
              role='button'
              onClick={this.showHelp}
              style={{
                marginLeft: 10,
                marginTop: 0,
                cursor: 'pointer',
                verticalAlign: 'top',
                display: 'inline-block',
              }}
              ratio={0.8}
              name='question'
            />
          </h2>
          <div
            style={{ height: 550 }}
            className='uk-width-1-1 items-center justify-center flex flex-col'
          >
            <div data-uk-spinner='' />
            <span className='uk-small-margin-top'>Loading...</span>
          </div>
        </>
      )
    }
    const { proficiencyChartReport } = this.state
    return (
      <>
        <h2 className='text-center flex' style={{ marginBottom: 30 }}>
          Proficiency Summary
          <Icon
            type='button'
            role='button'
            onClick={this.showHelp}
            style={{
              marginLeft: 10,
              marginTop: 0,
              cursor: 'pointer',
              verticalAlign: 'top',
              display: 'inline-block',
            }}
            ratio={0.8}
            name='question'
          />
        </h2>
        <ResponsiveContainer
          height={550}
          className='text-sm uk-width-1-1'
        >
          <BarChart
            data={proficiencyChartReport}
            margin={{
              top: 0,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Legend
              iconType='circle'
              wrapperStyle={{ top: -12 }}
              verticalAlign='top'
              align='center'
            />
            <XAxis
              dataKey='name'
              height={200}
              interval={0}
              tick={<CustomizedAxisTick />}
            />
            <YAxis
              label={
                <Text
                  x={-20}
                  y={0}
                  dx={50}
                  dy={200}
                  offset={0}
                  fill='rgb(102, 102, 102)'
                  angle={-90}
                >
                  # of Users
                </Text>
              }
            />
            <Tooltip />
            <Bar
              dataKey='Mastered'
              name='Expert'
              stackId='bar'
              fill='#32d296'
            />
            <Bar dataKey='Average' stackId='bar' fill='#ffd558' />
            <Bar dataKey='Needs Attention' stackId='bar' fill='#f0506e' />
          </BarChart>
        </ResponsiveContainer>
      </>
    )
  }
}

export default withRouter(
  connect(null, {
    getProficiencyChartReport,
  })(ProficiencyChart)
)
