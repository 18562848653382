/**
 * Serves as an interface to the proxy container running on a sandbox. This functionality depends
 * on having a sandbox assigned and running; therefore this module is invoked by sandbox.js as
 * needed. When the initial sandbox is ready, we get a setup() call. If the user clicks 'Reset
 * Sandbox', we get a teardown() call, followed by setup() when the next sandbox is ready.
 */
import moment from 'moment'
import axios from 'axios'

import { api } from 'app/views/utils/api'
import { parseRequestText } from '../utils/requestBuilder'
import Base64 from '../utils/base64'
import { requestOpts } from 'app/state/modules/sandbox'

const prefix = 'sbproxy'
const SET_WANTS_TO_INTERCEPT = `${prefix}/SET_WANTS_TO_INTERCEPT`
const SETUP = `${prefix}/SETUP`
const TEARDOWN = `${prefix}/TEARDOWN`
const SCHEDULE_CONCLUDE_HISTORY = `${prefix}/SCHEDULE_CONCLUDE_HISTORY`
const CONCLUDE_HISTORY = `${prefix}/CONCLUDE_HISTORY`
const COMPLETE_INITIAL_PAGE_LOAD = `${prefix}/COMPLETE_INITIAL_PAGE_LOAD`
const PUSH_REQUEST = `${prefix}/PUSH_REQUEST`
const PUSH_RESPONSE = `${prefix}/PUSH_RESPONSE`
const ATTACH_EDITED_REQUEST_TEXT = `${prefix}/ATTACH_EDITED_REQUEST_TEXT`
const SET_IS_INTERCEPTING = `${prefix}/SET_IS_INTERCEPTING`

const initialState = {
  // Persistent state: not cleared on sandbox reset; can be modfied outside of setup/teardown:
  wantsToIntercept: false,

  // Sandbox-dependent state:
  sandboxUrl: null,
  eventStreamUrl: null,
  eventSource: null,
  concludedHistory: false,
  concludeHistoryTimerHandle: null,
  concludeHistoryTimestamp: null,
  hasCompletedInitialPageLoad: false,
  requests: [],
  hasInitializedInterceptState: false,
  isIntercepting: false,
  lastRecvTimestamp: null,
}

const ENABLE_VERBOSE_LOGGING = false
// eslint-disable-next-line no-console
const _log = ENABLE_VERBOSE_LOGGING ? console.log : () => {}
const _warn = ENABLE_VERBOSE_LOGGING ? console.warn : () => {}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_WANTS_TO_INTERCEPT:
      return {
        ...state,
        wantsToIntercept: action.value,
      }
    case SETUP:
      return {
        ...state,
        proxyUrl: action.proxyUrl,
        eventStreamUrl: action.eventStreamUrl,
        eventSource: action.eventSource,
        concludeHistoryTimerHandle: action.concludeHistoryTimerHandle,
        hasInitializedInterceptState: true,
      }
    case TEARDOWN:
      return {
        ...state,
        proxyUrl: null,
        eventStreamUrl: null,
        eventSource: null,
        concludedHistory: false,
        concludeHistoryTimerHandle: null,
        concludeHistoryTimestamp: null,
        hasCompletedInitialPageLoad: false,
        requests: [],
        hasInitializedInterceptState: false,
        isIntercepting: false,
        lastRecvTimestamp: null,
      }
    case SCHEDULE_CONCLUDE_HISTORY:
      return {
        ...state,
        concludeHistoryTimestamp: action.timestamp,
      }
    case CONCLUDE_HISTORY:
      _log(
        'Received CONCLUDE_HISTORY; assuming all proxy history has now been received'
      )
      if (state.concludeHistoryTimerHandle) {
        _log('Clearing concludeHistoryTimerHandle')
        clearInterval(state.concludeHistoryTimerHandle)
      }
      return {
        ...state,
        concludedHistory: true,
        concludeHistoryTimerHandle: null,
        concludeHistoryTimestamp: null,
      }
    case COMPLETE_INITIAL_PAGE_LOAD:
      return {
        ...state,
        hasCompletedInitialPageLoad: true,
      }
    case PUSH_REQUEST:
      if (
        state.requests.some((x) => x.request_id === action.request.request_id)
      ) {
        _warn(
          `Ignoring proxy request '${action.request.request_id}' - we already have a request with that ID`
        )
        return state
      }
      const newRequest = {
        ...action.request,
        timestamp: moment(),
      }
      return {
        ...state,
        requests: [newRequest].concat(state.requests),
      }
    case PUSH_RESPONSE:
      const requestIndex = state.requests.findIndex(
        (x) => x.request_id === action.response.request_id
      )
      if (requestIndex < 0) {
        _warn(
          `Ignoring proxy response for request '${action.response.request_id}' - we have no such request`
        )
        return state
      }
      return {
        ...state,
        requests: state.requests
          .slice(0, requestIndex)
          .concat([
            appendResponseToRequest(
              state.requests[requestIndex],
              action.response
            ),
          ])
          .concat(state.requests.slice(requestIndex + 1)),
        lastRecvTimestamp: Date.now(),
      }
    case ATTACH_EDITED_REQUEST_TEXT:
      const index = state.requests.findIndex(
        (x) => x.request_id === action.requestId
      )
      if (index < 0) {
        _warn(
          `Ignoring edited request text for request ${action.requestId} - we have no such request`
        )
        return state
      }
      const modified = {
        ...state.requests[index],
        editedRequest: action.text,
      }
      return {
        ...state,
        requests: state.requests
          .slice(0, index)
          .concat([modified])
          .concat(state.requests.slice(index + 1)),
      }
    case SET_IS_INTERCEPTING:
      return {
        ...state,
        isIntercepting: action.value,
      }
    default:
      return state
  }
}

let g_cancelToken = null
async function requestInterceptStateChange(shouldIntercept, proxyUrl, opts) {
  if (g_cancelToken) {
    g_cancelToken.cancel('intercept toggled')
  }
  g_cancelToken = axios.CancelToken.source()

  const method = shouldIntercept ? 'put' : 'delete'
  const url = `${proxyUrl}/proxy/intercept`
  try {
    await api({
      method,
      url,
      withAuthToken: true,
      cancelToken: g_cancelToken.token,
      opts,
    })
  } catch (err) {
    if (axios.isCancel(err)) {
      _warn(
        `request to ${
          shouldIntercept ? 'enable' : 'disable'
        } proxy intercepts has been canceled`
      )
    } else {
      console.error('failed to change proxy intercept state', err)
      throw err
    }
  }
}

function cleanup(state) {
  if (state.sbproxy.eventSource) {
    state.sbproxy.eventSource.close()
  }
  if (state.sbproxy.concludeHistoryTimerHandle) {
    clearTimeout(state.sbproxy.concludeHistoryTimerHandle)
  }
}

function scheduleConcludeHistory(delay) {
  return {
    type: SCHEDULE_CONCLUDE_HISTORY,
    timestamp: Date.now() + delay,
  }
}

function completeInitialPageLoad() {
  return (dispatch, getState) => {
    const state = getState()
    _log(
      `initial page load completed; setting intercepts ${
        state.sbproxy.wantsToIntercept ? 'on' : 'off'
      }`
    )
    requestInterceptStateChange(
      state.sbproxy.wantsToIntercept,
      state.sbproxy.proxyUrl,
      requestOpts(state)
    )
      .then(() => {
        _log(
          `intercepts successfully ${
            state.sbproxy.wantsToIntercept ? 'enabled' : 'disabled'
          }`
        )
        dispatch({
          type: SET_IS_INTERCEPTING,
          value: state.sbproxy.wantsToIntercept,
        })
        dispatch({ type: COMPLETE_INITIAL_PAGE_LOAD })
      })
      .catch((err) => {
        _warn('failed to set initial intercept state', err)
        dispatch({ type: COMPLETE_INITIAL_PAGE_LOAD })
      })
  }
}

export function setWantsToIntercept(value) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({ type: SET_WANTS_TO_INTERCEPT, value })
    if (
      state.sbproxy.proxyUrl &&
      state.sbproxy.hasCompletedInitialPageLoad &&
      state.sbproxy.isIntercepting !== value
    ) {
      requestInterceptStateChange(
        value,
        state.sbproxy.proxyUrl,
        requestOpts(state)
      ).then(() => {
        dispatch({ type: SET_IS_INTERCEPTING, value })
      })
    }
  }
}

export function setup(proxyUrl, eventStreamUrl) {
  return (dispatch, getState) => {
    // Close any prior connections that we might still have open
    const state = getState()
    cleanup(state)

    // Open a connection to the sandbox's /proxy/intercept event stream, to start receiving a
    // stream of 'request' and 'response' messages
    _log(`opening proxy event stream: ${eventStreamUrl}`)
    const eventSource = new EventSource(eventStreamUrl, requestOpts(state))

    // Upon first connection, we'll need to wait until we've received the full request history
    // from the proxy container: until we've "concluded" the history, we don't want to allow any
    // new requests. The proxy doesn't tell us where the history ends, so we'll just wait until
    // it's been a brief moment with no new events. For simplicity re: propagation of state,
    // we'll just use a single repeating timer that checks a timestamp ~30 times per second.
    const concludeHistoryTimerHandle = setInterval(() => {
      const { concludedHistory, concludeHistoryTimestamp } = getState().sbproxy
      if (
        !concludedHistory &&
        typeof concludeHistoryTimestamp === 'number' &&
        Date.now() >= concludeHistoryTimestamp
      ) {
        dispatch({ type: CONCLUDE_HISTORY })
      }
    }, 33)

    // Wait a brief-but-perceptible moment by default, to give events time to start arriving.
    // If the proxy has no request history to speak of, this is the worst-case wait time for the
    // proxy UI to finish initializing itself.
    dispatch(scheduleConcludeHistory(500))

    // Each time we get a new event, we'll push that timestamp out just a bit further, so that
    // once the line goes quiet our repeating timer function will conclude that the full replay of
    // proxy request history has been received
    function onProxyRequest(request) {
      const state = getState()
      _log('proxy request', request)
      const decodedBody = Base64.decode(window.decodeURI(request.body || ''))
      dispatch({
        type: PUSH_REQUEST,
        request: { ...request, body: decodedBody },
      })
      if (!state.sbproxy.concludedHistory) {
        dispatch(scheduleConcludeHistory(200))
      }
    }
    function onProxyResponse(response) {
      const state = getState()
      _log('proxy response', response)
      const decodedBody = Base64.decode(window.decodeURI(response.body || ''))
      dispatch({
        type: PUSH_RESPONSE,
        response: { ...response, body: decodedBody },
      })
      if (!state.sbproxy.concludedHistory) {
        dispatch(scheduleConcludeHistory(200))
      } else if (!state.sbproxy.hasCompletedInitialPageLoad) {
        dispatch(completeInitialPageLoad())
      }
    }

    // Set up event handlers to receive request/response data from the proxy container
    eventSource.addEventListener('request', (event) => {
      onProxyRequest(JSON.parse(event.data))
    })
    eventSource.addEventListener('response', (event) => {
      onProxyResponse(JSON.parse(event.data))
    })

    // The proxy's initial intercept state isn't guaranteed, and we never want it to intercept
    // requests until after we've finished replaying our history and completed the initial page
    // load, so make sure intercepts are explicitly disabled from the start
    requestInterceptStateChange(false, proxyUrl, requestOpts(state))
      .then(() => {
        _log('disabled intercepts on init')
        // Our basic initialization is done - once we finish accumulating our request history
        // (concludedHistory), we'll permit the app to start making page requests, and after the
        // firsst request is completed (hasCompletedInitialPageLoad), we'll finally set our initial
        // intercept state (if configured to automatically enable intercepts)
        dispatch({
          type: SETUP,
          proxyUrl: proxyUrl,
          eventStreamUrl,
          eventSource,
          concludeHistoryTimerHandle,
        })
      })
      .catch((err) => {
        console.error('failed to disable proxy intercepts on init', err)
      })
  }
}

export function teardown() {
  return (dispatch, getState) => {
    cleanup(getState())
    dispatch({ type: TEARDOWN })
  }
}

export function resumeInterceptedRequest(requestText, isEdited) {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.sbproxy.proxyUrl) {
      _warn('Unable to resume request: no sandbox URL')
      return
    }

    if (isEdited) {
      dispatch({
        type: ATTACH_EDITED_REQUEST_TEXT,
        requestId: state.sbproxy.requests[0].request_id,
        text: requestText,
      })
    }

    const { method, path, httpVersion, body, headers } =
      parseRequestText(requestText)
    api({
      method: 'post',
      url: `${state.sbproxy.proxyUrl}/proxy/resume`,
      data: {
        method,
        path,
        http_version: httpVersion,
        body_encoded: body,
        headers,
      },
      opts: requestOpts(state),
    })
      .then(() => {
        _log('resumed pending proxy request')
      })
      .catch((err) => {
        _warn('failed to resume pending proxy request', err)
      })
  }
}

function appendResponseToRequest(request, response) {
  let timestamp = request.timestamp
  if (response.headers && response.headers.Date) {
    timestamp = moment(response.headers.Date)
  }
  return {
    ...request,
    timestamp,
    response: {
      ...response,
    },
  }
}
