import React from 'react'

class ActionComponent extends React.Component {
  componentDidMount() {
    this.props.action()
  }

  render() {
    return <></>
  }
}

export default ActionComponent
