import React from 'react'

import Loader from 'app/views/components/Loader'

import { logout } from 'app/views/utils/auth'

class Logout extends React.Component {
  componentDidMount() {
    logout()
  }

  render() {
    return <Loader visible />
  }
}

export default Logout
