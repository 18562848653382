import React from 'react'
import PropTypes from 'prop-types'

import { VBox, Box } from 'app/views/core/Box'

import ContentModal from '../../ContentModal'

function TestSubmissionCodeModal(props) {
  const { engine, code, onClose } = props
  return (
    <ContentModal
      id='test-submission-code'
      width='70vw'
      height='70vh'
      onClose={onClose}
    >
      <VBox fillParent>
        <h3 style={{ margin: 10 }}>Code Submission</h3>
        <Box grow>
          <div style={{ position: 'relative', height: '100%' }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: 10,
                borderTop: '1px solid #eee',
                backgroundColor: '#333',
                color: '#eee',
                whiteSpace: 'pre',
                fontFamily:
                  '"Roboto Mono", Consolas, "Andale Mono", "Lucida Console", "DejaVu Sans Mono", Monaco, "Courier New", Courier, monospace',
                fontSize: 14,
                lineHeight: 1.25,
                overflow: 'auto',
              }}
            >
              {code}
            </div>
          </div>
        </Box>
      </VBox>
    </ContentModal>
  )
}
TestSubmissionCodeModal.propTypes = {
  engine: PropTypes.string,
  code: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TestSubmissionCodeModal
