import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ContentModal from '../../common/ContentModal'
import { submitFeedback } from '../../common/util'

import LessonCompletionForm from './LessonCompletionForm'
import LessonNagMessage from './LessonNagMessage'

function LessonCompletionModal(props) {
  const {
    onClose,
    contentId,
    requirePassingTest,
    hasPassingTest,
    flagLessonCompleted,
  } = props
  const canCompleteLesson = !requirePassingTest || hasPassingTest

  // Once the conditions for completing the lesson have been met and the completion dialog appears,
  // we can flag the lesson completed on the backend
  useEffect(() => {
    if (canCompleteLesson) {
      flagLessonCompleted()
    }
  }, [canCompleteLesson])

  function handleSubmit(numStarsRated, feedbackText) {
    if (numStarsRated && numStarsRated > 0 && numStarsRated <= 10) {
      submitFeedback(contentId, numStarsRated, feedbackText).then(() => {
        window.location.href = '/'
      })
    } else {
      window.location.href = '/'
    }
  }

  return (
    <ContentModal
      id='lesson-completion'
      width='min(80vw, 700px)'
      onClose={onClose}
    >
      <div style={{ padding: 40 }}>
        <h3>{canCompleteLesson ? 'Congratulations!' : 'Whoops!'}</h3>
        {canCompleteLesson ? (
          <LessonCompletionForm onFinishLesson={handleSubmit} />
        ) : (
          <LessonNagMessage
            onReturnToLesson={onClose}
            onLeaveAnyway={() => handleSubmit(null, null)}
          />
        )}
      </div>
    </ContentModal>
  )
}
LessonCompletionModal.propTypes = {
  contentId: PropTypes.string.isRequired,
  hasTests: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  requirePassingTest: PropTypes.bool,
  hasPassingTest: PropTypes.bool,
  flagLessonCompleted: PropTypes.func.isRequired,
}

export default connect(
  (state, ownProps) => ({
    requirePassingTest:
      ownProps.hasTests && state.hacker.mustPassCodingExercise,
    hasPassingTest:
      ownProps.hasTests &&
      (state.hacker.currentCodeSubmissions.some((x) => x.test.passed) || ownProps.hasPassingTest),
  }),
  {}
)(LessonCompletionModal)
