import React from 'react'

type Props = {
  text: string
}

export const SplashTitle = (props: Props) => (
  <h3 className="mb-6">
    {props.text}
  </h3>
)
