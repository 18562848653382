import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import settings from 'settings'

// Components
import GenericModal from 'app/views/components/Modals/GenericModal'
import Loader from 'app/views/components/Loader'
import Tabs from 'app/views/components/Tabs'
import Icon from 'app/views/components/Icon'
import PrimaryButton from '../../components/Buttons/PrimaryButton'
import ToggleSwitch from 'app/views/components/ToggleSwitch'

// Redux
import {
  getCustomContent,
  getCustomContentDetail,
  saveCustomContent,
  deleteCustomContent,
} from 'app/state/modules/plan'

// Local components
import ContentRow from './ContentRow'

class CustomContentModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      expirationDate: '',
      selectedContent: {},
      filters: {},
      hideCompleted: false,
    }
    this.show = this.show.bind(this)
    // this.renderContent = this.renderContent.bind(this)
    this.renderContentTable = this.renderContentTable.bind(this)
    this.handleChangeExpirationDate = this.handleChangeExpirationDate.bind(this)
    this.renderChallenges = this.renderChallenges.bind(this)
    this.setCodeReviewsFilters = this.setCodeReviewsFilters.bind(this)
    this.onClickFilterButton = this.onClickFilterButton.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.renderLabel = this.renderLabel.bind(this)
    this.onSave = this.onSave.bind(this)
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.codeReviews || prevProps.codeReviews.length === 0) &&
      this.props.codeReviews.length > 0
    ) {
      this.setCodeReviewsFilters()
    }
  }

  onClickFilterButton(e) {
    const { filters } = this.state
    this.setState({
      filters: {
        ...filters,
        [e.target.name]: !filters[e.target.name],
      },
    })
  }

  setCodeReviewsFilters() {
    this.setState({
      filters: this.props.codeReviews.reduce((prev, language) => {
        prev[language.language_name] = true
        return prev
      }, {}),
    })
  }

  show() {
    this.modal.show()
  }

  hide() {
    this.modal.hide()
  }

  handleChangeExpirationDate(date) {
    this.setState({ expirationDate: date[0] })
  }

  renderLabel(content) {
    const { selectedHackerCustomContent } = this.props
    let label = null
    if (selectedHackerCustomContent[content.uuid] !== undefined) {
      if (selectedHackerCustomContent[content.uuid].completed_at !== null) {
        const tooltip = `Completed on ${
          selectedHackerCustomContent[content.uuid].completed_at
        }`
        label = (
          <label
            className='uk-label uk-label-success flex items-center inline-flex'
            data-uk-tooltip={tooltip}
          >
            COMPLETED
            <Icon
              className='uk-margin-small-left'
              name='information'
              ratio={0.6}
            />
          </label>
        )
      } else if (
        selectedHackerCustomContent[content.uuid].completed_at === null &&
        (selectedHackerCustomContent[content.uuid].complete_by === null ||
          moment(
            selectedHackerCustomContent[content.uuid].complete_by,
            'YYYY-MM-DD'
          ).isSameOrAfter(moment().format('YYYY-MM-DD')))
      ) {
        const tooltip =
          selectedHackerCustomContent[content.uuid].complete_by === null
            ? 'No due date'
            : `Complete by ${
                selectedHackerCustomContent[content.uuid].complete_by
              }`
        label = (
          <label
            className='uk-label uk-label-secondary flex items-center inline-flex'
            data-uk-tooltip={tooltip}
          >
            PENDING
            <Icon
              className='uk-margin-small-left'
              name='information'
              ratio={0.6}
            />
          </label>
        )
      } else if (
        selectedHackerCustomContent[content.uuid].completed_at === null &&
        (selectedHackerCustomContent[content.uuid].complete_by === null ||
          moment(
            selectedHackerCustomContent[content.uuid].complete_by,
            'YYYY-MM-DD'
          ).isBefore(moment().format('YYYY-MM-DD')))
      ) {
        const tooltip = `Not completed by ${
          selectedHackerCustomContent[content.uuid].complete_by
        }`
        label = (
          <label
            className='uk-label uk-label-danger flex items-center inline-flex'
            data-uk-tooltip={tooltip}
          >
            EXPIRED
            <Icon
              className='uk-margin-small-left'
              name='information'
              ratio={0.6}
            />
          </label>
        )
      }
    }
    return label
  }

  renderContentTable() {
    const { selectedHackerCustomContent } = this.props
    return this.props.courses.map((course) => {
      let searchResults = 0
      return (
        <React.Fragment key={course.uuid}>
          <tr key={course.uuid}>
            <td colSpan='3' className='text-center'>
              <strong>{course.title}</strong>
            </td>
          </tr>
          {course.items.map((item) => {
            if (item.item_type === 'lesson') {
              return item.content.map((content) => {
                const contentCompleted =
                  content.has_test && content.must_pass_coding_exercise
                    ? content.passed_at
                    : content.completed_at
                if (
                  this.state.search === '' ||
                  content.title.search(new RegExp(this.state.search, 'i')) > -1
                ) {
                  searchResults += 1
                  return (
                    <ContentRow
                      hideCompleted={this.state.hideCompleted}
                      onEdit={() => {
                        this.setState(
                          {
                            selectedContent: content,
                            expirationDate: selectedHackerCustomContent[
                              content.uuid
                            ]
                              ? new Date(
                                  `${
                                    selectedHackerCustomContent[content.uuid]
                                      .complete_by
                                  }T00:00:00`
                                )
                              : '',
                          },
                          () => {
                            this.modalAssign.show()
                          }
                        )
                      }}
                      contentCompleted={contentCompleted}
                      onDelete={() => {
                        this.setState(
                          {
                            selectedContent: content,
                          },
                          () => {
                            this.modalAssignDelete.show()
                          }
                        )
                      }}
                      renderLabel={this.renderLabel}
                      key={content.uuid}
                      item={content}
                      onAdd={() => {
                        this.setState({
                          selectedContent: content,
                        })
                        this.modalAssign.show()
                      }}
                      showAdd={
                        selectedHackerCustomContent[content.uuid] === undefined
                      }
                    />
                  )
                }
                return null
              })
            }
            const contentCompleted =
              item.has_test && item.must_pass_coding_exercise
                ? item.passed_at
                : item.completed_at
            if (
              this.state.search === '' ||
              item.title.search(new RegExp(this.state.search, 'i')) > -1
            ) {
              searchResults += 1
              return (
                <ContentRow
                  hideCompleted={this.state.hideCompleted}
                  contentCompleted={contentCompleted}
                  onEdit={() => {
                    this.setState(
                      {
                        selectedContent: item,
                        expirationDate: selectedHackerCustomContent[item.uuid]
                          ? new Date(
                              `${
                                selectedHackerCustomContent[item.uuid]
                                  .complete_by
                              }T00:00:00`
                            )
                          : '',
                      },
                      () => {
                        this.modalAssign.show()
                      }
                    )
                  }}
                  renderLabel={this.renderLabel}
                  onDelete={() => {
                    this.setState(
                      {
                        selectedContent: item,
                      },
                      () => {
                        this.modalAssignDelete.show()
                      }
                    )
                  }}
                  key={item.uuid}
                  item={item}
                  onAdd={() => {
                    this.setState({
                      selectedContent: item,
                    })
                    this.modalAssign.show()
                  }}
                  showAdd={selectedHackerCustomContent[item.uuid] === undefined}
                />
              )
            }
            return null
          })}
          {searchResults === 0 && (
            <tr>
              <td colSpan='3' className='text-center'>
                <i>No results found</i>
              </td>
            </tr>
          )}
        </React.Fragment>
      )
    })
  }

  renderChallenges() {
    const { selectedHackerCustomContent } = this.props
    let searchResults = 0
    return (
      <>
        <tr key='challenges'>
          <td colSpan='3' className='text-center'>
            <strong>Challenges</strong>
          </td>
        </tr>
        {this.props.challenges.map((challenge) => {
          if (
            this.state.search === '' ||
            challenge.title.search(new RegExp(this.state.search, 'i')) > -1
          ) {
            searchResults += 1
            const contentCompleted = challenge.completed_at
            return (
              <ContentRow
                hideCompleted={this.state.hideCompleted}
                contentCompleted={contentCompleted}
                onEdit={() => {
                  this.setState(
                    {
                      selectedContent: challenge,
                      expirationDate: selectedHackerCustomContent[
                        challenge.uuid
                      ]
                        ? new Date(
                            `${
                              selectedHackerCustomContent[challenge.uuid]
                                .complete_by
                            }T00:00:00`
                          )
                        : '',
                    },
                    () => {
                      this.modalAssign.show()
                    }
                  )
                }}
                renderLabel={this.renderLabel}
                onDelete={() => {
                  this.setState(
                    {
                      selectedContent: challenge,
                    },
                    () => {
                      this.modalAssignDelete.show()
                    }
                  )
                }}
                key={challenge.uuid}
                item={challenge}
                onAdd={() => {
                  this.setState({
                    selectedContent: challenge,
                  })
                  this.modalAssign.show()
                }}
                showAdd={
                  selectedHackerCustomContent[challenge.uuid] === undefined
                }
              />
            )
          }
          return null
        })}
        {searchResults === 0 && (
          <tr>
            <td colSpan='3' className='text-center'>
              <i>No results found</i>
            </td>
          </tr>
        )}
      </>
    )
  }

  renderCodeReviews() {
    const { selectedHackerCustomContent } = this.props
    let searchResults = 0
    return (
      <>
        <tr key='code-reviews'>
          <td colSpan='3' className='text-center'>
            <strong>Coding Challenges</strong>
          </td>
        </tr>
        {this.props.codeReviews.map((language) =>
          language.frameworks.map((framework) =>
            framework.apps.map((app) =>
              app.content.map((content) => {
                if (!this.state.filters[language.language_name]) {
                  return false
                }
                const title = `${settings.apps[app.name]}: ${content.title} (${
                  settings.engines[language.language_name]
                } - ${settings.frameworks[framework.name]})`
                if (
                  this.state.search === '' ||
                  title.search(new RegExp(this.state.search, 'i')) > -1
                ) {
                  searchResults += 1
                  const contentCompleted = content.completed_at
                  return (
                    <ContentRow
                      hideCompleted={this.state.hideCompleted}
                      contentCompleted={contentCompleted}
                      onEdit={() => {
                        this.setState(
                          {
                            selectedContent: content,
                            expirationDate: selectedHackerCustomContent[
                              content.uuid
                            ]
                              ? new Date(
                                  `${
                                    selectedHackerCustomContent[content.uuid]
                                      .complete_by
                                  }T00:00:00`
                                )
                              : '',
                          },
                          () => {
                            this.modalAssign.show()
                          }
                        )
                      }}
                      renderLabel={this.renderLabel}
                      onDelete={() => {
                        this.setState(
                          {
                            selectedContent: content,
                          },
                          () => {
                            this.modalAssignDelete.show()
                          }
                        )
                      }}
                      key={content.uuid}
                      item={{
                        ...content,
                        title,
                      }}
                      onAdd={() => {
                        this.setState({
                          selectedContent: content,
                        })
                        this.modalAssign.show()
                      }}
                      showAdd={
                        selectedHackerCustomContent[content.uuid] === undefined
                      }
                    />
                  )
                }
                return null
              })
            )
          )
        )}
        {searchResults === 0 && (
          <tr>
            <td colSpan='3' className='text-center'>
              <i>No results found</i>
            </td>
          </tr>
        )}
      </>
    )
  }

  renderStyle(isActive) {
    if (isActive) {
      return {
        fontWeight: 'bold',
      }
    }
    return {
      background: '#EEE',
      color: '#666',
      textDecoration: 'line-through',
    }
  }

  onSave() {
    this.props.saveCustomContent(
      this.props.match.params.organization_uuid,
      this.props.hackerUUID,
      this.state.selectedContent.uuid,
      this.state.expirationDate,
      () => {
        if (this.props.onRefresh) {
          this.props.onRefresh()
        }
      }
    )
    this.modalAssign.hide()
  }

  confirmDelete() {
    this.props.deleteCustomContent(
      this.props.match.params.organization_uuid,
      this.props.hackerUUID,
      this.state.selectedContent.uuid,
      () => {
        if (this.props.onRefresh) {
          this.props.onRefresh()
        }
      }
    )
    this.modalAssignDelete.hide()
  }

  render() {
    return (
      <>
        <GenericModal
          closeOnClickOverlay
          noButtons
          activeListeners
          beforeShow={() => {
            this.props.getCustomContentDetail(
              this.props.match.params.organization_uuid,
              this.props.hackerUUID
            )
            this.props.getCustomContent(
              this.props.match.params.organization_uuid,
              this.props.hackerUUID
            )
          }}
          width='uk-width-3-5'
          title={`Assign lessons to ${this.props.email}`}
          ref={(ref) => {
            this.modal = ref
          }}
          id={this.props.id}
          body={
            this.props.selectedHackerCustomContentLoader ? (
              <Loader visible />
            ) : (
              <>
                <div className='flex justify-between'>
                  <div className='uk-inline uk-width-1-2'>
                    <Icon
                      className='uk-form-icon'
                      role='button'
                      href='#'
                      name='search'
                    />
                    <input
                      className='uk-input'
                      type='text'
                      placeholder='Search by title'
                      spellCheck={false}
                      value={this.state.search}
                      onChange={(e) =>
                        this.setState({ search: e.target.value })
                      }
                    />
                    {this.props.filterHacker !== '' && (
                      <Icon
                        className='uk-form-icon uk-form-icon-flip'
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'none',
                        }}
                        role='button'
                        onClick={() => {
                          this.setState({ search: '' })
                        }}
                        as='a'
                        name='close'
                      />
                    )}
                  </div>
                  <div>
                    <ToggleSwitch
                      onChange={() =>
                        this.setState({
                          hideCompleted: !this.state.hideCompleted,
                        })
                      }
                      checked={this.state.hideCompleted}
                      label='Filter Completed Lessons'
                    />
                  </div>
                </div>

                <Tabs
                  id='custom-content-tabs'
                  tabs={[
                    {
                      key: 'custom-content-courses-tab',
                      title: 'Courses',
                      component: (
                        <table className='uk-table uk-table-divider'>
                          <tbody>{this.renderContentTable()}</tbody>
                        </table>
                      ),
                    },
                    {
                      key: 'custom-content-challenges-tab',
                      title: 'Challenges',
                      component: (
                        <table className='uk-table uk-table-divider'>
                          <tbody>{this.renderChallenges()}</tbody>
                        </table>
                      ),
                    },
                    {
                      key: 'custom-content-codereviews-tab',
                      title: 'Coding Challenges',
                      component: (
                        <>
                          <div className='flex justify-center items-center flex-wrap uk-width-1-1'>
                            {this.props.codeReviews.map((language) => (
                              <button
                                key={language.language_name}
                                name={language.language_name}
                                onClick={this.onClickFilterButton}
                                className='uk-button uk-button-default uk-button-small uk-margin-small-right inline-flex items-center uk-margin-small-top'
                                style={this.renderStyle(
                                  this.state.filters[language.language_name]
                                )}
                                type='button'
                              >
                                <i
                                  className={`devicon-${
                                    settings.engineIcons[language.language_name]
                                  }-plain colored uk-margin-small-right`}
                                  style={{ fontSize: 12 }}
                                  title={
                                    settings.engines[language.language_name]
                                  }
                                />
                                {settings.engines[language.language_name]}
                              </button>
                            ))}
                          </div>
                          <table className='uk-table uk-table-divider'>
                            <tbody>{this.renderCodeReviews()}</tbody>
                          </table>
                        </>
                      ),
                    },
                  ]}
                />
              </>
            )
          }
        />
        <GenericModal
          id={`${this.props.id}-custom-content-delete-modal`}
          ref={(ref) => {
            this.modalAssignDelete = ref
          }}
          title='Delete assigned content'
          body={
            <>
              Are you sure you want to delete the assigned content
              <i> {this.state.selectedContent.title}</i>?
            </>
          }
          buttons={
            <PrimaryButton
              onClick={this.confirmDelete}
              size='small'
              customCss='bg-red'
              label='Yes, delete'
            />
          }
        />
        <GenericModal
          activeListeners
          afterHide={() => {
            this.setState({
              expirationDate: '',
            })
          }}
          title={
            <span>
              Assign <i>{this.state.selectedContent.title}</i>
            </span>
          }
          ref={(ref) => {
            this.modalAssign = ref
          }}
          id={`${this.props.id}-custom-content-assign-modal`}
          body={
            <>
              <h4 className='uk-margin-remove-bottom'>Due Date</h4>
              <p className='text-sm uk-margin-small-top'>
                When should this lesson be completed by? Leave this empty to
                assign without a due date.
              </p>
              <div data-uk-grid>
                <div
                  className='uk-width-1-1 flex items-center'
                  style={{ position: 'relative' }}
                >
                  <Flatpickr
                    options={{ minDate: moment().format('YYYY-MM-DD') }}
                    className='uk-input'
                    placeholder='YYYY-MM-DD'
                    value={this.state.expirationDate}
                    onChange={this.handleChangeExpirationDate}
                  />
                  {this.state.expirationDate !== '' && (
                    <Icon
                      onClick={() => this.setState({ expirationDate: '' })}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: 5,
                      }}
                      name='close'
                    />
                  )}
                </div>
              </div>
            </>
          }
          buttons={
            <>
              <PrimaryButton
                size={'small'}
                onClick={this.onSave}
                label='Save'
              />
            </>
          }
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  courses: state.plan.customContentDetail.courses,
  challenges: state.plan.customContentDetail.challenges,
  codeReviews: state.plan.customContentDetail.code_reviews,
  selectedHackerCustomContentLoader:
    state.plan.selectedHackerCustomContentLoader,
  selectedHackerCustomContent: state.plan.selectedHackerCustomContent,
  selectedUser: state.team.selectedUser,
})

export default withRouter(
  connect(mapStateToProps, {
    getCustomContentDetail,
    getCustomContent,
    saveCustomContent,
    deleteCustomContent,
  })(CustomContentModal)
)
