import PropTypes from 'prop-types'
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const propTypes = {
  visible: PropTypes.bool,
}
const defaultProps = {
  visible: true,
}

const SkeletonLoader = ({ visible }) =>
  visible ? (
    <SkeletonTheme color='#f8f8f8' highlightColor='#f0f0f0'>
      <div className='uk-hackedu-container-main'>
        <div className='uk-container'>
          <div className='uk-margin-medium-top'>
            <div data-uk-grid='' style={{ fontSize: 72, lineHeight: 1.2 }}>
              <div className='uk-width-large'>
                <Skeleton count={8} />
              </div>
              <div className='uk-width-expand'>
                <Skeleton count={8} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  ) : (
    <div />
  )

SkeletonLoader.propTypes = propTypes
SkeletonLoader.defaultProps = defaultProps

export default SkeletonLoader
