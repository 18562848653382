const env = process.env.CONFIG_FILE
let urls = {}
let cognito = {}

// support individual dev environments. If one is detected, replace URLs according to the convention.
if (
  env === 'development' &&
  (window.location.hostname.endsWith('.dev-env.hackedu.dev') ||
    window.location.hostname.endsWith('-www-app.env.hackedu.dev'))
) {
  let name = window.location.hostname.split('.')[0].replace('-www-app', '')
  const host = window.location.host
  urls = {
    app: 'https://{host}',
    controlK8s: `https://${name}-api-sandbox-ingress.env.hackedu.dev`,
    hacker: `https://${name}-api-hacker.env.hackedu.dev/hacker/v2`,
    linter: `https://${name}-api-linter.env.hackedu.dev/linter/v2`,
  }
  // NOTE: this only works if you temporarily add your
  // DevEnv to the App Redirect URLs in Cognito. Should not be necessary most of the time.
  cognito = {
    authData: {
      RedirectUriSignIn: `https://${host}/auth`,
      RedirectUriSignOut: `https://${host}`,
    },
  }
}

module.exports = { urls, cognito }
