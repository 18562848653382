module.exports = {
  urls: {
    app: 'https://app.hackedu.com',
    control: 'https://control.hackedu.com',
    controlK8s: 'https://api.sandbox-k8s.hackedu.com',
    hacker: 'https://platform.hackedu.com/hacker/v2',
    samy: 'https://samy.hackedu.com',
    www: 'https://www.hackedu.com',
    linter: 'https://platform.hackedu.com/linter/v2',
    githubApp: 'https://github.com/apps/hack-edu/installations/new?',
    slackApp:
      'https://slack.com/oauth/v2/authorize?client_id=203999096960.1356400665046&scope=chat:write,chat:write.customize,im:write,users:read,users:read.email,groups:write,mpim:write&user_scope=',
    microsoftApp:
      'https://login.microsoftonline.com/common/adminconsent?client_id=9a29e77c-154f-4dee-b957-a894a7d665bf&redirect_uri=https%3A%2F%2Fapp.hackedu.com%2Fapp-integrations%2Fmicrosoft-teams-callback',
    sj: 'https://my.securityjourney.com',
    sjAuth: {
      handoff: 'https://my.securityjourney.com/he_login',
    },
  },
  aws: {
    region: 'us-east-1',
    identityPoolId: 'us-east-1:894aeb70-77ed-466b-bb83-88751ac73e55',
  },
  cognito: {
    authData: {
      ClientId: 'bg6jqljbdakuts5tu3jqhuph0',
      AppWebDomain: 'auth.hackedu.com',
      TokenScopesArray: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      RedirectUriSignIn: 'https://app.hackedu.com/auth',
      RedirectUriSignOut: 'https://app.hackedu.com',
      UserPoolId: 'us-east-1_CHi5tsM8X',
      AdvancedSecurityDataCollectionFlag: false,
    },
  },
  saml: {
    checkDomainAssociationUri: 'https://platform.hackedu.com/auth/v2/domain',
  },
  segment: {
    key: 'Sy6SN194H3OATRfapSYABAcSBGFdIqyv',
  },
  launchDarkly: {
    clientSideId: '61e1b06b0e7a8916040690d2',
  },
}
