import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { withRouter } from 'react-router-dom'
import UIkit from 'uikit'
import { connect } from 'react-redux'
import { getHint } from 'app/state/modules/codeReview'
import cx from 'classnames'
// Components
import { Modal } from 'app/views/components/GustavoModal'
import { Icon } from 'app/views/components/GustavoIcon'

function HintOption({ icon, title, subtitle, onClick, hint }) {
  return (
    <div
      className='flex flex-row uk-padding-small with-hover'
      style={{ cursor: 'pointer' }}
      onClick={() => onClick(hint)}
    >
      <div className='flex align-top justify-end uk-padding-small uk-padding-remove-top'>
        <Icon name={icon} className='text-primary' />
      </div>
      <div className='flex flex-col uk-position-relative uk-width-1-1 text-emphasis'>
        <strong>{title}</strong>
        <small>{subtitle}</small>
        <Icon
          name='arrow-right-s'
          className='uk-position-absolute'
          style={{
            top: 0,
            right: 0,
          }}
        />
      </div>
    </div>
  )
}

class ModalHint extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      helpArticles: false,
      highlightCode: false,
      payload: false,
      showPayloadHint: false,
    }
    this.renderMenu = this.renderMenu.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleClickHighlight = this.handleClickHighlight.bind(this)
    this.handleClickPayload = this.handleClickPayload.bind(this)
    this.handleClickHelpArticle = this.handleClickHelpArticle.bind(this)
    this.modalRef = React.createRef()
  }

  handleOnClick() {
    UIkit.modal('#modal-hint').hide()
  }

  handleClickPayload(hint = false) {
    if (hint) {
      this.props.getHint(hint.id)
    }
    this.setState({
      showPayloadHint: hint,
    })
  }

  handleClickHighlight(hint) {
    this.modalRef.current.hide()
    this.props.getHint(hint[0].id)
    document.querySelector('#tab-code a').click()
  }

  handleClickHelpArticle(hint) {
    this.props.getHint(hint[0].id)
    window.open(hint[0].url)
  }

  renderMenu() {
    return (
      <>
        <hr style={{ marginBottom: 5, marginTop: 5 }} />
        {this.props.hints.map((hint) => {
          const hintType = hint.hint_type
          return (
            <HintOption
              key={hint.id}
              hint={hint}
              onClick={
                ['default', 'basic'].includes(hintType)
                  ? this.handleClickPayload
                  : hintType === 'highlight'
                  ? this.handleClickHighlight
                  : this.handleClickHelpArticle
              }
              icon={
                ['default', 'basic'].includes(hintType)
                  ? 'question'
                  : hintType === 'highlight'
                  ? 'terminal'
                  : 'lifebuoy'
              }
              title={hint.title}
              subtitle={hint.description}
            />
          )
        })}
      </>
    )
  }

  renderPayloadHint() {
    const hints = this.state.showPayloadHint
    return (
      <>
        <hr style={{ marginTop: 5, marginBottom: 5 }} />
        <div>
          {hints.length > 1 && (
            <div className='flex justify-center items-center uk-margin-top'>
              <ul
                className='uk-dotnav text-center'
                data-uk-switcher='animation: uk-admination-slide-right-medium, uk-animation-slide-left-medium'
              >
                {hints.map((hint, index) => {
                  return (
                    <li
                      key={hint.id}
                      className={cx({
                        'uk-active': hint.sort_order === this.state.step,
                      })}
                    >
                      <a
                        href='#'
                        onClick={() => {
                          this.setState({ step: hint.sort_order })
                        }}
                      >
                        {index}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          <div className='uk-padding-small'>
            <ul className='uk-switcher uk-margin'>
              {(Array.isArray(hints) ? hints : [hints]).map((hint, index) => {
                return (
                  <li
                    key={hint.id}
                    className={cx({
                      'uk-active':
                        !Object.keys(hint).includes('sort_order') ||
                        hint.sort_order === this.state.step,
                    })}
                  >
                    <div>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        linkTarget='_blank'
                        children={hint.markdown}
                      />
                    </div>
                    <div className='uk-fle flex-row items-center'>
                      {this.state.step > 1 && (
                        <button
                          className='uk-button uk-button-secondary uk-button-small uk-align-left'
                          data-uk-switcher-item={index - 1}
                          onClick={() => {
                            this.setState({ step: this.state.step - 1 })
                          }}
                          type='button'
                        >
                          Back
                        </button>
                      )}
                      {this.state.step < hints.length && (
                        <button
                          className='uk-button uk-button-secondary uk-button-small uk-align-right'
                          data-uk-switcher-item={index + 1}
                          onClick={() => {
                            this.setState({ step: this.state.step + 1 })
                          }}
                          type='button'
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </>
    )
  }

  renderTitle(hint) {
    if (this.state.showPayloadHint) {
      return (
        <span className='flex flex-row justify-start items-center'>
          <Icon
            onClick={() => this.handleClickPayload(false)}
            ratio={1.4}
            name='arrow-left-s'
            className='uk-margin-small-right'
          />
          {hint.title}
        </span>
      )
    }
    return 'Choose a hint'
  }

  render() {
    return (
      <Modal
        ref={this.modalRef}
        noPadding
        style={{
          width: 400,
        }}
        title={this.renderTitle(this.state.showPayloadHint || false)}
        titleContainerClass='uk-padding-small'
        noButtons
        closeOnClickOverlay
        id={`modal-hint-${this.props.stepId}`}
        body={
          this.state.showPayloadHint
            ? this.renderPayloadHint()
            : this.renderMenu()
        }
      />
    )
  }
}

const mapStateToProps = (state) => ({})

export default withRouter(
  connect(mapStateToProps, {
    getHint,
  })(ModalHint)
)
