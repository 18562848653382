import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { VBox, HBox, Box } from 'app/views/core/Box'

import EditorLanguageDisplay from './EditorLanguageDisplay'
import PatchSubmissionErrorNotification from './PatchSubmissionErrorNotification'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

import CodingEditor from 'app/views/components/CodingEditor'

import { connect } from 'react-redux'
import * as sbrepl from 'app/state/modules/sbrepl'
import * as codesub from 'app/state/modules/codesub'
import { g as SvcShimState } from 'app/views/utils/apiServiceShim'
import { TournamentDetailsProps } from 'app/sjTournament'

import { withLDConsumer } from 'launchdarkly-react-client-sdk'

/**
 * Local styling/layout components.
 */

function useButtonCooldown(interval) {
  // Track cooldown state with a boolean flag
  const [isOnCooldown, setIsOnCooldown] = useState(false)

  // Use a timeout to flip the flag back to false, and clear the timer on unmount
  const timerHandle = useRef(null)
  const clearTimer = () => {
    if (timerHandle.current) {
      clearTimeout(timerHandle.current)
      timerHandle.current = null
    }
  }
  useEffect(() => {
    return clearTimer
  }, [])

  // Supply a function that sets the flag while also scheduling it to be cleared
  const triggerCooldown = () => {
    clearTimer()
    setIsOnCooldown(true)
    timerHandle.current = setTimeout(() => {
      setIsOnCooldown(false)
    }, interval)
  }
  return [isOnCooldown, triggerCooldown]
}

/**
 * Single-file text editor for lessons: allows the user to edit the repl code for the lesson, save
 * the code to patch the sandbox, and submit the code for testing once patched. Wraps CodingEditor,
 * which wraps an AceEditor.
 */
function CodeEditor(props) {
  // - localCode is the buffer that we directly modify via the text editor
  // - Changes made via the text editor get propagated to state via updateLocalCode
  // - Clicking 'Save Code' calls applyLocalCodeToSandbox, which patches the sandbox
  // - savedCode is the most recent patch that we applied to the sandbox
  // - i.e. whenever we click 'Save Code' and the patch is applied, savedCode === localCode
  const { contentId, contentHasTests, lessonKey } = props
  const {
    selectedEngine,
    localCode,
    savedCode,
    setSelectedEngine,
    saveSelectedEngineAsPreferred,
    updateLocalCode,
    applyLocalCodeToSandbox,
    submitPatchForTesting,
    createCodeSubmission,
    activateTestsTab,
    isTrialMode,
    toggleTrialModal,
  } = props

  // Some additional state from the sbrepl module tells us what state we're in
  const {
    isFetchingCode,
    isSavingCode,
    isSubmittingPatchForTesting,
    isCreatingCodeSubmission,
    replSubmissionError,
    codeSubmissionError,
    preferredEngine,
    codeEngine,
    supportedEngines,
  } = props

  // Determine whether we initiate submissions via svc-code-sub instead of api-hacker,
  // in which case we'll also get data re: whether we have a submission in progress from
  // the codesub module instead of the hacker module
  const { engUseSvcCodeSub } = useFlags()
  const patchSubmissionError = engUseSvcCodeSub
    ? codeSubmissionError
    : replSubmissionError
  const isSubmittingCode = engUseSvcCodeSub
    ? isCreatingCodeSubmission
    : isSubmittingPatchForTesting

  const canSaveCode =
    !isFetchingCode && !isSavingCode && localCode !== savedCode

  const showLoading = isFetchingCode || isSavingCode || isSubmittingCode

  // We need to imperatively resize the ace editor in response to certain layout
  // changes, so pass this value through: whenever it increments, we resize
  const { resizeAccumulator } = props

  // Disable the "Run Tests" button if there's no code to submit, or while tests are being
  // submitted, and for 5 seconds thereafter (api-hacker rate-limits requests to 1 per 5s.)
  const [runTestsIsOnCooldown, triggerRunTestsCooldown] =
    useButtonCooldown(5000)

  const canRunTests =
    !runTestsIsOnCooldown &&
    !isFetchingCode &&
    !isSavingCode &&
    !isSubmittingCode &&
    savedCode.length > 0

  // The links on the right side of the toolbar bring up modal dialogs with more info for the user
  const [requestedModalName, setRequestedModalName] = useState(null)

  const runTests = () => {
    if (isTrialMode) {
      toggleTrialModal('tests', true)
      return
    }
    setTimeout(() => activateTestsTab(), 500)
    applyLocalCodeToSandbox().then(() => {
      triggerRunTestsCooldown()
      if (engUseSvcCodeSub) {
        if (SvcShimState.lesson.loadedAt === null) {
          throw new Error(
            'Unable to submit code: no lessonLoadedAt timestamp in svc shim state'
          )
        }
        createCodeSubmission(
          lessonKey,
          SvcShimState.lesson.loadedAt,
          props.tournamentDetails
        )
      } else {
        submitPatchForTesting(contentId)
      }
    })
  }

  return (
    <VBox fillParent>
      <VBox>
        <HBox fixed={60} style={{ alignItems: 'center', padding: '0 15px' }}>
          <PrimaryButton
            label='Save Code'
            active={canSaveCode}
            size='small'
            onClick={applyLocalCodeToSandbox}
            customCss={canSaveCode && 'bg-blue'}
            loading={showLoading}
            useSpinner={true}
          />
          {contentHasTests && (
            <PrimaryButton
              active={canRunTests}
              onClick={() => runTests()}
              customCss='ml-2'
              size='small'
              label='Save &amp; Run Tests'
              loading={showLoading}
              useSpinner={true}
            />
          )}
          <Box grow />
          <EditorLanguageDisplay
            preferredEngine={preferredEngine}
            codeEngine={codeEngine}
            supportedEngines={supportedEngines}
            onSelectEngine={setSelectedEngine}
            onSaveSelectedEngineAsPreferred={saveSelectedEngineAsPreferred}
          />
        </HBox>
        {patchSubmissionError && (
          <PatchSubmissionErrorNotification error={patchSubmissionError} />
        )}
      </VBox>
      <Box grow>
        <CodingEditor
          code={localCode}
          codeEngine={codeEngine}
          isLoadingNewCode={isFetchingCode}
          engine={selectedEngine}
          onChange={updateLocalCode}
          resizeAccumulator={resizeAccumulator}
          height='100%'
        />
      </Box>
    </VBox>
  )
}
CodeEditor.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentHasTests: PropTypes.bool.isRequired,
  lessonKey: PropTypes.string.isRequired,
  tournamentDetails: TournamentDetailsProps,

  isRunningTests: PropTypes.bool.isRequired,
  isFetchingCode: PropTypes.bool.isRequired,
  isSavingCode: PropTypes.bool.isRequired,
  isSubmittingPatchForTesting: PropTypes.bool.isRequired,
  isCreatingCodeSubmission: PropTypes.bool.isRequired,
  replSubmissionError: PropTypes.string,
  codeSubmissionError: PropTypes.string,
  localCode: PropTypes.string.isRequired,
  savedCode: PropTypes.string.isRequired,
  preferredEngine: PropTypes.string,
  selectedEngine: PropTypes.string,
  codeEngine: PropTypes.string,

  resizeAccumulator: PropTypes.number.isRequired,

  setSelectedEngine: PropTypes.func.isRequired,
  saveSelectedEngineAsPreferred: PropTypes.func.isRequired,
  updateLocalCode: PropTypes.func.isRequired,
  applyLocalCodeToSandbox: PropTypes.func.isRequired,
  submitPatchForTesting: PropTypes.func.isRequired,
  createCodeSubmission: PropTypes.func.isRequired,
  isTrialMode: PropTypes.bool,
  toggleTrialModal: PropTypes.func,
}

export default withLDConsumer()(
  connect(
    (state, ownProps) => ({
      isRunningTests:
        (ownProps.contentHasTests && state.repl.runningTests) || false,
      isFetchingCode: state.sbrepl.isFetchingCode,
      isSavingCode: state.sbrepl.isPatchingSandbox,
      isSubmittingPatchForTesting: state.sbrepl.isSubmittingPatchForTesting,
      isCreatingCodeSubmission: state.codesub.mode === 'submitting',
      replSubmissionError: state.sbrepl.patchSubmissionError,
      codeSubmissionError: state.codesub.error,
      localCode: state.sbrepl.localCode || '',
      savedCode: state.sbrepl.patchedCode || '',
      preferredEngine: state.sbrepl.preferredEngine,
      selectedEngine: state.sbrepl.selectedEngine,
      codeEngine: state.sbrepl.codeEngine,
      supportedEngines: state.sbrepl.supportedEngines,
    }),
    (dispatch) => ({
      setSelectedEngine: (engine) => dispatch(sbrepl.setSelectedEngine(engine)),
      saveSelectedEngineAsPreferred: () =>
        dispatch(sbrepl.saveSelectedEngineAsPreferred()),
      updateLocalCode: (value) => dispatch(sbrepl.updateLocalCode(value)),
      applyLocalCodeToSandbox: () => dispatch(sbrepl.applyLocalCodeToSandbox()),
      submitPatchForTesting: (contentId, isNative) =>
        dispatch(sbrepl.submitPatchForTesting(contentId, isNative)),
      createCodeSubmission: (lessonKey, lessonLoadedAt, tournamentDetails) =>
        dispatch(
          codesub.createSubmission(lessonKey, lessonLoadedAt, tournamentDetails)
        ),
    })
  )(CodeEditor)
)
