import { values } from 'lodash'
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

// Components
import Loader from 'app/views/components/Loader'
import Icon from 'app/views/components/Icon'
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import PlanChangeModal from 'app/views/containers/Organizations/Plans/components/PlanChangeModal'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

// Redux
import {
  getPlans,
  updatePlanById,
  activateAutomatedPlan,
  setOrganizationDefaultPlan,
  setDefaultPlan,
} from 'app/state/modules/plan'
import { updateTeamPlan } from 'app/state/modules/team'

// Utils
import analytics from 'app/views/utils/analytics'
import GenericModal from 'app/views/components/Modals/GenericModal'

class OrganizationsPlans extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teamUUID: null,
      planToEdit: null,
      showInactive: false,
    }
    this.onShowModal = this.onShowModal.bind(this)
    this.toogleShowUnusedPlans = this.toogleShowUnusedPlans.bind(this)
    this.renderPlanCard = this.renderPlanCard.bind(this)
    this.changePlanModal = null
  }

  componentDidMount() {
    this.props.getPlans(this.props.match.params.organization_uuid)

    analytics.page('page-organizations-plans')
  }

  onShowModal() {
    this.newPlanModal.show()
  }

  onHideModal(team = false) {
    this.newPlanModal.hide()
    this.props.history.push(
      `/admin/${this.props.match.params.organization_uuid}/plans/add${
        team ? '/team' : ''
      }`
    )
  }

  toogleShowUnusedPlans() {
    const { showInactive } = this.state
    this.setState({
      showInactive: !showInactive,
    })
  }

  renderTerm(term) {
    switch (term) {
      case 'quarterly':
        return 'quarter'
      case 'biweekly':
        return '2 weeks'
      default:
        return 'month'
    }
  }

  renderBodyPlanCard(plan) {
    const className =
      plan.is_default && !plan.no_training && !plan.using_recommendation_engine
        ? 'text-emphasis'
        : 'text-muted'
    if (plan.is_time_based) {
      const totalTime = plan.groups.reduce((prev, curr) => {
        const sum = prev + curr.duration
        return sum
      }, 0)
      const phasesLength = (plan.groups || []).length
      return (
        <p>
          <b className={className}>{plan.lessonCount}</b>
          {' lessons/challenges over '}
          <b className={className}>{phasesLength}</b>
          {` phase${phasesLength > 1 ? 's' : ''}. Users have `}
          <b className={className}>{totalTime}</b>
          {' days to complete.'}
        </p>
      )
    }
    if (plan.using_recommendation_engine) {
      return (
        <div className='uk-width-1-1'>
          <p className='uk-margin-small'>
            <b className='text-emphasis'>
              {plan.settings.required_training.length}
            </b>
            {' required lessons/challenges'}
          </p>
          <p className='uk-margin-small'>
            <b className='text-emphasis'>{plan.settings.max_content}</b>
            {` lessons every ${this.renderTerm(plan.settings.training_term)}`}
          </p>
          {!!plan.team_uuid && (
            <>
              <p className='uk-margin-small'>
                <b className='text-emphasis'>{plan.team_integrations}</b>
                {' team data integrations'}
              </p>
            </>
          )}
          <p className='uk-margin-small'>
            <b className='text-emphasis'>{plan.organization_integrations}</b>
            {' organization data integrations'}
          </p>
        </div>
      )
    }
    let maxEndDate = null
    plan.groups.forEach((planGroup) => {
      if (
        planGroup.end_date &&
        (!maxEndDate || moment(planGroup.end_date).isAfter(moment(maxEndDate)))
      ) {
        maxEndDate = moment(planGroup.end_date)
      }
    })
    const phasesLength = (plan.groups || []).length
    return (
      <p>
        <b className={className}>{plan.lessonCount}</b>
        {' lessons/challenges over '}
        <b className={className}>{phasesLength}</b>
        {` phase${phasesLength > 1 ? 's' : ''}. `}
        {maxEndDate ? (
          <>
            {' Users must finish before '}
            <b className={className}>{maxEndDate.format('MMMM D, YYYY')}</b>
          </>
        ) : (
          'Plan does not have a date limit.'
        )}
      </p>
    )
  }

  renderPlanCard(plan) {
    let lessonCount = 0
    plan.groups.forEach((g) => {
      lessonCount += g.content.length
    })
    if (plan.is_organization_default && !this.state.showInactive) {
      return <p key={plan.uuid}>Using default training plan.</p>
    }
    if (plan.no_training) {
      return <p key={plan.uuid}>No training plan assigned.</p>
    }
    if ((plan.is_default || this.state.showInactive) && !plan.no_training) {
      if (plan.is_organization_default) {
        return <p key={plan.uuid}>Using default training plan.</p>
      }
      return (
        <div
          key={plan.uuid}
          data-test-id='plan-card'
          className='uk-width-xlarge uk-margin-small'
        >
          <div className='uk-link-reset'>
            <div
              className={
                (plan.is_default &&
                  !plan.no_training &&
                  !plan.using_recommendation_engine) ||
                plan.using_recommendation_engine
                  ? 'uk-card uk-card-default'
                  : 'text-muted uk-placeholder uk-padding-remove'
              }
              data-test-id='plan-type-card'
            >
              <div
                className='uk-card-body uk-padding-small'
                style={{ paddingTop: 20 }}
              >
                <h4 className='flex justify-between items-center'>
                  <span
                    className={
                      (plan.is_default &&
                        !plan.no_training &&
                        !plan.using_recommendation_engine) ||
                      plan.using_recommendation_engine
                        ? 'text-emphasis'
                        : 'text-muted'
                    }
                  >
                    {plan.using_recommendation_engine
                      ? 'Automated Plan'
                      : plan.title}
                  </span>
                  {!plan.no_training && (
                    <Icon
                      uk-tooltip='Edit / Details'
                      className='text-green'
                      name='arrow-right-circle'
                      ratio={1.5}
                      onClick={() => {
                        if (
                          plan.using_recommendation_engine &&
                          plan.team_uuid
                        ) {
                          this.props.history.push(
                            `/admin/${this.props.match.params.organization_uuid}/teams/${plan.team_uuid}/plans`
                          )
                        } else {
                          this.props.history.push({
                            pathname: `/admin/${this.props.match.params.organization_uuid}/plans/${plan.uuid}`,
                            state: {
                              settings: plan.settings,
                              organization_integrations_list:
                                plan.organization_integrations_list,
                            },
                          })
                        }
                      }}
                    />
                  )}
                </h4>
                <hr className='uk-margin-remove-top' />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                  }}
                >
                  {this.renderBodyPlanCard({ ...plan, lessonCount })}
                </div>
                {!plan.is_default && (
                  <PrimaryButton
                    onClick={(e) => {
                      if (!plan.team_uuid) {
                        return this.props.setDefaultPlan(
                          plan.uuid,
                          this.props.match.params.organization_uuid,
                          null,
                          () => {
                            this.props.getPlans(
                              this.props.match.params.organization_uuid,
                              true,
                              () => {
                                this.setState(
                                  {
                                    showInactive: false,
                                  },
                                  () => {
                                    this.forceUpdate()
                                  }
                                )
                              }
                            )
                          }
                        )
                      }
                      this.props.updatePlanById(
                        this.props.match.params.organization_uuid,
                        plan.uuid,
                        plan.title,
                        plan.description,
                        true,
                        plan.send_reminders,
                        plan.team_uuid,
                        () => {
                          this.props.getPlans(
                            this.props.match.params.organization_uuid,
                            true,
                            () => {
                              this.setState(
                                {
                                  showInactive: false,
                                },
                                () => {
                                  this.forceUpdate()
                                }
                              )
                            }
                          )
                        },
                        () => {},
                        plan.no_training ? false : null
                      )
                      e.preventDefault()
                    }}
                    size='small'
                    label='Use this plan'
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  renderPlans(plans) {
    return (plans || []).map((plan) => {
      return this.renderPlanCard(plan)
    })
  }

  renderTeams(teams) {
    const ChangePlanBtn = ({ teamUUID, planToEdit }) => (
      <button
        onClick={() => {
          this.setState(
            {
              teamUUID,
              planToEdit,
            },
            () => {
              this.changePlanModal.show()
            }
          )
        }}
        className='uk-button uk-button-default uk-button-small flex items-center uk-margin-left'
        type='button'
      >
        <Icon className='uk-margin-small-right' name='exchange' />
        <span>Change Plan Type</span>
      </button>
    )
    return (teams || []).map((team) => (
      <React.Fragment key={team.uuid}>
        <h3 className='flex flex-row uk-width-xlarge justify-between'>
          <Link
            uk-tooltip={team.name}
            to={`/admin/${this.props.match.params.organization_uuid}/teams/${team.uuid}/users`}
            className='uk-link-reset'
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              maxWidth: '65%',
            }}
          >
            {`${team.name} Team`}
          </Link>
          <ChangePlanBtn
            teamUUID={team.uuid}
            planToEdit={
              (team.plans.find((p) => p.is_default && p.is_custom_plan) || {})
                .uuid
            }
          />
        </h3>
        <div>{this.renderPlans(team.plans)}</div>
      </React.Fragment>
    ))
  }

  render() {
    if (this.props.loadingPlans) {
      return <Loader visible />
    }
    const ChangePlanBtn = () => (
      <button
        onClick={() => {
          this.setState(
            {
              teamUUID: null,
              planToEdit: (
                (this.props.plans.organization_plans || []).find(
                  (p) => p.is_default && p.is_custom_plan
                ) || {}
              ).uuid,
            },
            () => {
              this.changePlanModal.show()
            }
          )
        }}
        className='uk-button uk-button-default uk-button-small flex items-center uk-margin-left'
        type='button'
      >
        <Icon className='uk-margin-small-right' name='exchange' />
        <span>Change Plan Type</span>
      </button>
    )
    return (
      <OrganizationLayout
        {...this.props}
        active='plans'
        data-test-id='organization-plans-component'
      >
        <div className='uk-inline'>
          <h1 className='uk-inline'>Training Plans</h1>
          <Icon
            as='a'
            href='https://help.securityjourney.com/en/articles/5027372-how-to-setup-training-plans'
            name='question'
            ratio={0.8}
            style={{ padding: 10, verticalAlign: 'top' }}
            target='_blank'
            rel='noopener noreferrer'
          />
        </div>
        <button
          type='button'
          className='uk-button uk-button-default uk-button-small flex items-center'
          style={{ float: 'right' }}
          onClick={this.toogleShowUnusedPlans}
        >
          <Icon
            className='text-sm uk-margin-small-right'
            name={!this.state.showInactive ? 'eye' : 'eye-off'}
          />
          {this.state.showInactive ? 'Hide Unused Plans' : 'See Unused Plans'}
        </button>
        <div style={{ clear: 'both' }} />
        <GenericModal
          ref={(ref) => {
            this.newPlanModal = ref
          }}
          id='create-plan'
          cancelLeft
          buttons={
            <>
              <button
                type='button'
                onClick={() => this.onHideModal()}
                className='uk-button uk-button-primary uk-margin-right'
              >
                Organization Plan
              </button>
              <button
                onClick={() => this.onHideModal(true)}
                type='button'
                className='uk-button uk-button-secondary'
              >
                Team Plan
              </button>
            </>
          }
          title='Create Plan'
          body={
            <>
              <p className='text-justify'>
                You are about to create a new plan. A plan can be owned by an
                organization or by a team. Team plans are not shared between
                teams and if a team does not have an active plan then the
                default organization plan is used.
              </p>
              <p className='text-justify'>
                Please note, this will change what users on your team see when
                they first login (under the My Plan) tab of their dashboard. It
                will also affect the training notices sent out to your team.
              </p>
            </>
          }
        />

        <h3 className='flex flex-row uk-width-xlarge justify-between'>
          <span className='uk-link-reset'>Default Plan</span>
          <ChangePlanBtn />
        </h3>
        <p>
          <i>For users without teams or teams set to use the default plan</i>
        </p>
        <div>{this.renderPlans(this.props.plans.organization_plans || [])}</div>

        {(this.props.plans.teams || []).length > 0 && (
          <>
            <h2>Team Plans</h2>
            <hr />
          </>
        )}
        {this.renderTeams(this.props.plans.teams)}
        <PlanChangeModal
          planToEdit={this.state.planToEdit}
          history={this.props.history}
          onRef={(ref) => {
            this.changePlanModal = ref
          }}
          teamUUID={this.state.teamUUID}
          organizationUUID={this.props.match.params.organization_uuid}
        />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  // content
  courses: state.content.courses,
  lessonMap: state.content.lessonMap,
  tests: state.content.tests,

  // hacker
  organizations: values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,
  // plans
  loadingPlans: state.plan.loadingPlans,
  hasLoadedPlans: state.plan.hasLoadedPlans,
  plans: state.plan.plans,

  // user
  email: state.auth.user.email,
})

export default withRouter(
  connect(mapStateToProps, {
    getPlans,
    updatePlanById,
    updateTeamPlan,
    activateAutomatedPlan,
    setOrganizationDefaultPlan,
    setDefaultPlan,
  })(OrganizationsPlans)
)
