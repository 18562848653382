import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components
import GenericModal from 'app/views/components/Modals/GenericModal'

// Redux
import { clearPhaseDetailModal } from 'app/state/modules/plan'

// Local Imports
import Content from './Content'
import Users from './Users'
import Tabs from '../Tabs'

class PhaseDetailModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.props.clearPhaseDetailModal()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedPhaseDetail === 'loading' &&
      this.props.selectedPhaseDetail !== 'loading'
    ) {
      if (this.props.onShow) this.props.onShow()
      this.modal.show()
    } else if (
      prevProps.selectedPhaseDetail !== 'loading' &&
      this.props.selectedPhaseDetail === 'loading'
    ) {
      this.modal.hide()
    }
  }

  render() {
    const { selectedPhaseDetail } = this.props
    return (
      <>
        <GenericModal
          handleOnClickOverlay={() => {
            this.modal.hide()
            this.props.clearPhaseDetailModal()
          }}
          noButtons
          canClose
          id='phase-detail-modal'
          modalContainer
          width='uk-width-3-5'
          ref={(ref) => {
            this.modal = ref
          }}
          body={
            selectedPhaseDetail !== 'loading' && (
              <div
                className='uk-padding'
                style={{
                  paddingTop: 0,
                }}
              >
                <h2
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  {selectedPhaseDetail.phase.title}
                </h2>
                <span className='uk-margin-remove text-muted italic'>
                  {selectedPhaseDetail.phase.description
                    ? selectedPhaseDetail.phase.description
                    : ''}
                </span>

                <Tabs
                  id='phase-detail-modal-tabs'
                  tabs={[
                    {
                      key: 'phase-detail-modal-tab-content',
                      title: 'Content',
                      component: (
                        <Content
                          content={this.props.selectedPhaseDetail.content}
                        />
                      ),
                    },
                    {
                      key: 'phase-detail-modal-tab-users',
                      title: 'Users',
                      component: (
                        <Users users={this.props.selectedPhaseDetail.users} />
                      ),
                    },
                  ]}
                />
              </div>
            )
          }
        />
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  selectedPhaseDetail: state.plan.selectedPhaseDetail,
})

export default withRouter(
  connect(mapStateToProps, {
    clearPhaseDetailModal,
  })(PhaseDetailModal)
)
