import PropTypes from 'prop-types'
import React from 'react'

import Icon from 'app/views/components/Icon'

const propTypes = {
  selected: PropTypes.bool,
}
const defaultProps = {
  selected: false,
}

const Star = (props) => {
  if (props.selected) {
    return (
      <a
        href='#'
        className='uk-icon-button uk-margin-small-right'
        onClick={() => props.handleClick()}
      >
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
          data-svg='star'
        >
          <polygon
            fill='#999'
            stroke='none'
            strokeWidth='1.01'
            points='10 2 12.63 7.27 18.5 8.12 14.25 12.22 15.25 18 10 15.27 4.75 18 5.75 12.22 1.5 8.12 7.37 7.27'
          />
        </svg>
      </a>
    )
  }

  return (
    <Icon
      href='#'
      className='uk-icon-button uk-margin-small-right'
      name='star'
      onClick={() => props.handleClick()}
    />
  )
}

Star.propTypes = propTypes
Star.defaultProps = defaultProps

export default Star
