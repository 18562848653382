import * as moment from 'moment'
import Base64 from '../utils/base64'
import { RECEIVED_CONTENT_BY_ID } from './content'
import { isK8sEnabled, SET_SANDBOX_URL } from './k8s'

const prefix = 'proxy'
export const SET_INTERCEPT = `${prefix}/SET_INTERCEPT`
export const SET_STATUS = `${prefix}/SET_STATUS`
export const SET_URL = `${prefix}/SET_URL`
export const SANDBOX_RESET = `${prefix}/SANDBOX_RESET`
export const SANDBOX_INIT = `${prefix}/SANDBOX_INIT`
export const SANDBOX_SET_PATH = `${prefix}/SANDBOX_SET_PATH`
export const ON_REQUEST = `${prefix}/ON_REQUEST`
export const ON_REQUEST_K8S = `${prefix}/ON_REQUEST_K8S`
export const ON_RESPONSE = `${prefix}/ON_RESPONSE`
export const ON_RESPONSE_K8S = `${prefix}/ON_RESPONSE_K8S`
export const SET_PROXY_TAB = `${prefix}/SET_PROXY_TAB`
export const GENERATE_TABS_REAL_URLS = `${prefix}/GENERATE_TABS_REAL_URLS`
export const ADD_EDITED_REQUEST = `${prefix}/ADD_EDITED_REQUEST`
export const ADD_EDITED_REQUEST_K8S = `${prefix}/ADD_EDITED_REQUEST_K8S`
export const RESET_PROXY_STATE = `${prefix}/RESET_PROXY_STATE`

const initialState = {
  url: '',
  path: '',
  search: '',
  status: 'loading',
  requests: [],
  intercept: false,
  request: '',
  xApps: {},
  currentTab: 0,
  tabs: {},
  realUrlTabsGenerated: false,
  sandboxResetByUser: false,
}

// some actions need to look beyond the proxy state
export function globalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GENERATE_TABS_REAL_URLS:
      return {
        ...state,
        proxy: {
          ...state.proxy,
          reseted: false,
          realUrlTabsGenerated: true,
          sandboxResetByUser: false,

          tabs: Object.keys(state.proxy.tabs).reduce((prev, key) => {
            const indexOfUnderline = action.xAppHost.indexOf('_')
            const indexOfPoint = action.xAppHost.indexOf('.', indexOfUnderline)
            const realUrl =
              action.xAppHost.substring(0, indexOfUnderline + 1) +
              state.proxy.tabs[key].app_name +
              action.xAppHost.substring(indexOfPoint)
            prev[key] = {
              ...state.proxy.tabs[key],
              realUrl,
            }
            return prev
          }, {}),
        },
      }
  }
  return state
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_PROXY_STATE:
      return {
        ...initialState,
        reseted: true,
      }
    case RECEIVED_CONTENT_BY_ID:
      const { tabs } = action.lessons[Object.keys(action.lessons)[0]]
      if (!tabs) {
        return {
          ...state,
          tabs: [],
        }
      }
      return {
        ...state,
        tabs: action.lessons[Object.keys(action.lessons)[0]].tabs.reduce(
          (prev, curr) => {
            prev[`${curr.app_name}`] = {
              app_name: curr.app_name,
              clean_url: curr.clean_url,
              realUrl: null,
            }
            prev[curr.clean_url.replace(/(http:\/\/|https:\/\/)/g, '')] = {
              app_name: curr.app_name,
              clean_url: curr.clean_url,
              realUrl: null,
            }
            return prev
          },
          {}
        ),
      }
    case SET_PROXY_TAB:
      return {
        ...state,
        currentTab: action.currentTab,
      }
    case SET_INTERCEPT:
      return {
        ...state,
        intercept: action.intercept,
      }
    case SET_STATUS:
      return {
        ...state,
        status: action.status,
      }
    case SET_URL:
      return {
        ...state,
        url: action.url,
      }
    case SANDBOX_RESET:
      return {
        ...state,
        url: '',
        path: '',
        search: '',
        status: 'reset',
        requests: [],
        sandboxResetByUser: action.clickedByUser,
      }
    case SANDBOX_INIT:
      return {
        ...state,
        status: 'init',
      }
    case SET_SANDBOX_URL:
      return {
        ...state,
        sandbox: action.sandbox,
      }
    case SANDBOX_SET_PATH:
      return {
        ...state,
        path: action.path,
        search: action.search,
      }
    case ON_REQUEST:
      return {
        ...state,
        requests: [
          ...state.requests,
          {
            ...action.request,
            timestamp: moment(),
            response: null,
          },
        ],
        status: state.intercept ? 'waiting' : 'disabled',
        xApps: {
          ...action.xApps,
        },
      }
    case ON_REQUEST_K8S:
      const existsRequest = state.requests.some((req) => {
        return action.request.request_id === req.request_id
      })
      if (existsRequest) {
        return state
      }
      return {
        ...state,
        requests: [
          ...state.requests,
          {
            ...action.request,
            timestamp: moment(),
          },
        ],
      }
    case ON_RESPONSE:
      return {
        ...state,
        requests: state.requests.map((req, i) => {
          return i === state.requests.length - 1 ? action.item : req
        }),
        status: state.intercept ? 'listening' : 'disabled',
      }
    case ON_RESPONSE_K8S:
      return {
        ...state,
        requests: state.requests.map((req) => {
          if (req.request_id === action.response.request_id && !req.response) {
            return {
              ...req,
              response: { ...action.response },
            }
          }
          return req
        }),
      }
    case ADD_EDITED_REQUEST:
      return {
        ...state,
        requests: state.requests.map((req, i) => {
          return i === state.requests.length - 1
            ? {
                ...req,
                editedRequest: action.editedRequest,
              }
            : req
        }),
      }
    case ADD_EDITED_REQUEST_K8S:
      return {
        ...state,
        requests: state.requests.map((req) => {
          return req.request_id === action.editedRequest.request_id
            ? {
                ...req,
                editedRequest: action.editedRequest,
              }
            : req
        }),
      }
    default:
      return state
  }
}

export const setIntercept = (intercept) => ({
  type: SET_INTERCEPT,
  intercept,
})

export const setStatus = (status) => ({
  type: SET_STATUS,
  status,
})

export const setUrl = (url) => ({
  type: SET_URL,
  url,
})

export const sandboxReset = (clickedByUser = false) => ({
  type: SANDBOX_RESET,
  clickedByUser,
})

export const sandboxInit = () => ({
  type: SANDBOX_INIT,
})

export const sandboxSetPath = (path, search) => ({
  type: SANDBOX_SET_PATH,
  path,
  search,
})

export const setCurrentProxyTab = (currentTab) => ({
  type: SET_PROXY_TAB,
  currentTab,
})

export const getPendingRequest = (state) => {
  const req = state.proxy.requests[Object.keys(state.proxy.requests).length - 1]
  if (req && !req.response) {
    return req
  }
  return null
}

// Thunks, Sagas, etc
// ...

export const addEditedRequest = (editedRequest, isK8s = false) => ({
  type: isK8s ? ADD_EDITED_REQUEST_K8S : ADD_EDITED_REQUEST,
  editedRequest,
})

export function onRequest(request, isK8s = false) {
  return (dispatch, getState) => {
    if (isK8s) {
      // new setup for requests (k8s)
      return dispatch({
        type: ON_REQUEST_K8S,
        request: {
          ...request,
          body: Base64.decode(window.decodeURI(request.body)),
        },
      })
    } else {
      // Old setup for request
      const isSocketSecondCall = request.path === '/ws'
      const { proxy } = getState()
      const { xApps } = proxy
      let newXApps = { ...xApps }
      if (
        typeof request.headers['X-App-Name'] === 'string' &&
        request.headers['X-App-Name'].length > 0 &&
        !xApps[request.headers['X-App-Name']]
      ) {
        if (!proxy.realUrlTabsGenerated || proxy.sandboxResetByUser) {
          dispatch({
            type: GENERATE_TABS_REAL_URLS,
            xAppHost: request.headers['X-App-Host'],
          })
        }

        newXApps = {
          ...newXApps,
          [`tab${proxy.currentTab}`]: isSocketSecondCall
            ? newXApps[`tab${proxy.currentTab}`]
            : {
                xAppName: request.headers['X-App-Name'],
                xAppHost: request.headers['X-App-Host'],
              },
        }
      }
      return dispatch({
        type: ON_REQUEST,
        request: {
          ...request,
          body: Base64.decode(window.decodeURI(request.body)),
        },
        xApps: newXApps,
      })
    }
  }
}

export function onResponse(item, isK8s) {
  if (isK8s) {
    return (dispatch) =>
      dispatch({
        type: ON_RESPONSE_K8S,
        response: {
          ...item,
          body: Base64.decode(window.decodeURI(item.body)),
        },
      })
  } else {
    return (dispatch) =>
      dispatch({
        type: ON_RESPONSE,
        item: {
          ...item,
          response: {
            ...item.response,
            body: Base64.decode(window.decodeURI(item.response.body)),
          },
        },
      })
  }
}

export function resetProxyState() {
  return {
    type: RESET_PROXY_STATE,
  }
}
