import React from 'react'
import { connect } from 'react-redux'
import UIkit from 'uikit'
import { values } from 'lodash'
import parseHtml from 'html-react-parser'

// Components
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'

// Redux
import {
  getHackers,
  getTeamDetails,
  patchTeamDetails,
  publicApi,
  getAllEmails,
} from 'app/state/modules/hacker'
import { getSSO, updateSSO } from 'app/state/modules/sso'
import {
  getCompletedReport,
  getUsersReport,
  exportCompletedReport,
} from 'app/state/modules/reports'

// Utils
import analytics from 'app/views/utils/analytics'

// Local Components
import { AvailableLicenses } from './components/AvailableLicenses'
import { ContentSettings } from './components/ContentSettings'
import DeveloperApi from './components/DeveloperApi'
import InviteAdmins from './components/InviteAdmins'
import InviteUsers from './components/InviteUsers'
import SSOSettings from './components/SSOSettings'
import SendBCCCEmails from './components/SendBCCCEmails'

class OrganizationsSettings extends React.Component {
  constructor() {
    super()
    this.state = {
      totalCompleted: 0,
      totalIncomplete: 0,
    }
    this.handleToggleMustPassCodingExercise =
      this.handleToggleMustPassCodingExercise.bind(this)
    this.handleOnEnableSSO = this.handleOnEnableSSO.bind(this)
    this.handleOnUpdateMetadata = this.handleOnUpdateMetadata.bind(this)
    this.handleToggleShowAllTraining =
      this.handleToggleShowAllTraining.bind(this)
    this.handleToggleShowCodingChallenges =
      this.handleToggleShowCodingChallenges.bind(this)
    this.handleToggleShowHackingChallenges =
      this.handleToggleShowHackingChallenges.bind(this)
    this.handleChangeApiToken = this.handleChangeApiToken.bind(this)
    this.onEmail = this.onEmail.bind(this)
  }

  async componentDidMount() {
    this.props.getTeamDetails(this.props.match.params.organization_uuid)
    this.props.getHackers(this.props.match.params.organization_uuid)
    this.props.getSSO(this.props.match.params.organization_uuid)

    this.props.getCompletedReport(
      this.props.match.params.organization_uuid,
      null,
      'email',
      true,
      0,
      'complete',
      (data) => {
        this.setState({
          totalCompleted: data.total,
        })
      }
    )
    this.props.getCompletedReport(
      this.props.match.params.organization_uuid,
      null,
      'email',
      true,
      0,
      'incomplete',
      (data) => {
        this.setState({
          totalIncomplete: data.total,
        })
      }
    )
    analytics.page('organizations-settings')
  }

  async onEmail(e) {
    let hackers
    if (e.target.name === 'all') {
      hackers = await this.props.getAllEmails(
        this.props.match.params.organization_uuid
      )
    } else {
      hackers = await this.props.exportCompletedReport(
        this.props.match.params.organization_uuid,
        null,
        'email',
        true,
        e.target.name === 'incomplete' ? 'incomplete' : 'complete'
      )
    }
    hackers = hackers.map((h) => h.email).join(',')
    window.location.href = `mailto:?bcc=${hackers}`
  }

  handleToggleMustPassCodingExercise(mustPassCodingExercise) {
    const organizationId = this.props.match.params.organization_uuid
    this.props.patchTeamDetails(organizationId, { mustPassCodingExercise })
  }

  handleToggleShowAllTraining(showAllTraining) {
    const organizationId = this.props.match.params.organization_uuid
    this.props.patchTeamDetails(organizationId, { showAllTraining })
  }

  handleToggleShowCodingChallenges(showCodingChallenges) {
    const organizationId = this.props.match.params.organization_uuid
    this.props.patchTeamDetails(organizationId, { showCodingChallenges })
  }

  handleToggleShowHackingChallenges(showHackingChallenges) {
    const organizationId = this.props.match.params.organization_uuid
    this.props.patchTeamDetails(organizationId, { showHackingChallenges })
  }

  handleOnEnableSSO(enabled) {
    const organizationId = this.props.match.params.organization_uuid
    this.props.updateSSO(organizationId, enabled, null, null)
  }

  handleOnUpdateMetadata(metadataFile, metadataUrl) {
    const organizationId = this.props.match.params.organization_uuid

    let file = metadataFile
    let url = metadataUrl
    if (metadataFile === '') {
      file = null
    }
    if (metadataUrl === '') {
      url = null
    }
    this.props.updateSSO(organizationId, null, file, url)
  }

  handleChangeApiToken(active, force) {
    if (force) {
      this.props.publicApi(this.props.match.params.organization_uuid, active)
      return
    }
    UIkit.modal
      .confirm(
        'The previous token will be revoked. Are you sure you want to continue?',
        {
          stack: true,
        }
      )
      .then(
        () => {
          this.props.publicApi(
            this.props.match.params.organization_uuid,
            active
          )
        },
        () => {}
      )
  }

  render() {
    const organizationId = this.props.match.params.organization_uuid
    return (
      <OrganizationLayout
        data-test-id='admin-settings-component'
        {...this.props}
        active='settings'
      >
        <h2>Settings</h2>
        <h3>Users</h3>
        <InviteUsers
          token={this.props.team.token}
          totalHackers={this.props.totalHackers}
          licenseTypes={this.props.team.available_license_types}
        />
        <div className='uk-margin-top'>
          <AvailableLicenses
            licenseCount={this.props.team.available_licenses}
          />
        </div>
        <SendBCCCEmails
          totalHackers={this.props.totalHackers}
          onEmail={this.onEmail}
          totalCompleted={this.state.totalCompleted}
          totalIncomplete={this.state.totalIncomplete}
        />
        <hr className='uk-margin-large-top' />

        <h3>Admins</h3>
        <InviteAdmins
          organizationId={organizationId}
          totalAdmins={this.props.totalAdmins || 0}
        />
        <hr className='uk-margin-large-top' />

        <h3>Content</h3>
        <ContentSettings
          handleToggleShowAllTraining={this.handleToggleShowAllTraining}
          showAllTraining={this.props.team.show_all_training}
          handleToggleMustPassCodingExercise={
            this.handleToggleMustPassCodingExercise
          }
          mustPassCodingExercise={this.props.team.must_pass_coding_exercise}
          showCodingChallenges={this.props.team.show_coding_challenges}
          showHackingChallenges={this.props.team.show_hacking_challenges}
          handleToggleShowHackingChallenges={
            this.handleToggleShowHackingChallenges
          }
          handleToggleShowCodingChallenges={
            this.handleToggleShowCodingChallenges
          }
        />
        <hr className='uk-margin-large-top' />

        <SSOSettings
          domains={this.props.domains}
          enabled={this.props.enabled}
          metadata_file={this.props.metadata_file}
          metadata_url={this.props.metadata_url}
          onEnable={this.handleOnEnableSSO}
          onUpdateMetadata={this.handleOnUpdateMetadata}
          updatingSSO={this.props.updatingSSO}
          updatingSSOError={this.props.updatingSSOError}
        />
        <hr className='uk-margin-large-top' />

        <DeveloperApi
          handleChangeApiToken={this.handleChangeApiToken}
          token={this.props.team.api_token}
          lock={this.props.team.lock_api}
        />
        <hr />

        <h3>Onboarding</h3>
        <div className='uk-card uk-card-default uk-card-body max-w-3xl'>
          <div className='uk-margin'>
            <h3 className='uk-margin-remove'>
              Customize the onboarding message for your users
            </h3>
            <hr />
            {parseHtml(this.props.onboardingEmail || '')}
            <hr />
            <p>
              <i>
                Send an email to &nbsp;
                <a
                  className='text-green hover:text-green'
                  href='mailto:support@securityjourney.com'
                >
                  support@securityjourney.com
                </a>
                &nbsp; to change this onboarding message.
              </i>
            </p>
          </div>
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.user.email,
  team: state.hacker.currentTeamDetails,
  organizations: values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,
  users: values(state.hacker.currentTeamHackers),
  totalHackers: state.hacker.totalHackers,
  totalAdmins: state.hacker.currentTeamDetails.total_admins,

  onboardingEmail: state.hacker.currentTeamDetails.onboarding_message,
  // sso
  domains: state.sso.domains,
  enabled: state.sso.enabled,
  metadata_file: state.sso.metadata_file,
  metadata_url: state.sso.metadata_url,
  updatingSSO: state.sso.updatingSSO,
  updatingSSOError: state.sso.updatingSSOError,
})

export default connect(mapStateToProps, {
  getCompletedReport,
  getTeamDetails,
  patchTeamDetails,
  getSSO,
  updateSSO,
  getAllEmails,
  publicApi,
  getHackers,
  exportCompletedReport,
  getUsersReport,
})(OrganizationsSettings)
