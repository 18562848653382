import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Icon } from 'app/views/components/GustavoIcon'

function HintSelectionListItem(props) {
  const { title, hintType, points, hasBeenUsed, onClick } = props
  return (
    <div
      className={cx('coding-challenge-hint-selection-list-item', {
        'coding-challenge-hint-selection-list-item-used': hasBeenUsed,
      })}
      onClick={onClick}
    >
      <Icon
        className='hint-icon'
        name={hintType === 'highlight' ? 'terminal' : 'question'}
      />
      <span className='hint-title'>{title}</span>
      {points !== 0 &&
        (hasBeenUsed ? (
          <span className='uk-label hint-used-badge'>Used</span>
        ) : (
          <span className='uk-label hint-points-badge'>{points} Points</span>
        ))}
      <div style={{ flex: '1' }} />
      <Icon name='arrow-right-s' />
    </div>
  )
}
HintSelectionListItem.propTypes = {
  title: PropTypes.string.isRequired,
  hintType: PropTypes.oneOf(['default', 'basic', 'highlight']).isRequired,
  points: PropTypes.number.isRequired,
  hasBeenUsed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default HintSelectionListItem
