import React, { useState, useRef } from 'react'

function useHistoryNav() {
  const [allowedOp, setAllowedOp] = useState(null)
  const pendingOp = useRef(null)
  const lastUrl = useRef(null)

  function enactOp(op) {
    if (op === 'forward' || op === 'back') {
      if (allowedOp === op) {
        pendingOp.current = op
        setAllowedOp(null)
        if (op === 'forward') {
          window.history.forward()
        } else {
          window.history.back()
        }
      }
    }
  }

  function updateUrl(newUrl) {
    if (pendingOp.current) {
      const justWentBack = pendingOp.current === 'back'
      setAllowedOp(justWentBack ? 'forward' : 'back')
    } else {
      const canGoBack = lastUrl.current && lastUrl.current !== newUrl
      setAllowedOp(canGoBack ? 'back' : null)
    }
    pendingOp.current = null
    lastUrl.current = newUrl
  }

  return [allowedOp, enactOp, updateUrl]
}

export default useHistoryNav
