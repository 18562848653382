import React from 'react'
import Icon from 'app/views/components/Icon'

export default (props) => (
  <div className='uk-margin-top'>
    <div className='uk-card uk-card-default uk-card-body max-w-3xl'>
      <p>Send an email to users:</p>
      <div
        className='uk-grid-collapse uk-child-width-expand@s text-center text-sm'
        uk-grid=''
      >
        <div>
          <div className='uk-background-muted uk-padding-small flex justify-center flex-col items-center'>
            Users with Completed Training
            <br />({props.totalCompleted} Users)
            <br />
            <button
              disabled={props.totalCompleted === 0}
              className='uk-button uk-button-default uk-button-small uk-margin-top flex items-center'
              type='button'
              onClick={props.onEmail}
              name='complete'
            >
              <Icon
                name='mail-send'
                className='uk-margin-right'
                ratio={0.7}
                fill
              />
              Send Email
            </button>
          </div>
        </div>
        <div>
          <div className='uk-background-primary uk-padding-small uk-light  flex justify-center flex-col items-center'>
            Users with Incomplete Training
            <br />({props.totalIncomplete} Users)
            <br />
            <button
              disabled={props.totalIncomplete === 0}
              className='uk-button uk-button-default uk-button-small uk-margin-top flex items-center'
              type='button'
              name='incomplete'
              onClick={props.onEmail}
            >
              <Icon
                name='mail-send'
                className='uk-margin-right'
                ratio={0.7}
                fill
              />
              Send Email
            </button>
          </div>
        </div>
        <div>
          <div className='uk-background-secondary uk-padding-small uk-light  flex justify-center flex-col items-center'>
            All Users
            <br />({props.totalHackers} Users)
            <br />
            <button
              onClick={props.onEmail}
              disabled={props.totalHackers === 0}
              className='uk-button uk-button-default uk-button-small uk-margin-top flex items-center'
              type='button'
              name='all'
            >
              <Icon
                name='mail-send'
                className='uk-margin-right'
                ratio={0.7}
                fill
              />
              Send Email
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
)
