import React from 'react'
import PropTypes from 'prop-types'

import { HBox, Box } from 'app/views/core/Box'

import Star from 'app/views/components/Modals/ModalCompletedLesson/components/Star'

function StarRatingControl(props) {
  const { numStarsRated, maxStars, onNumStarsRatedChange } = props
  return (
    <HBox>
      <Box grow />
      {[...Array(maxStars).keys()].map((_, i) => (
        <Star
          key={i}
          selected={numStarsRated > i}
          handleClick={() => onNumStarsRatedChange(i + 1)}
        />
      ))}
      <Box grow />
    </HBox>
  )
}
StarRatingControl.propTypes = {
  numStarsRated: PropTypes.number.isRequired,
  maxStars: PropTypes.number.isRequired,
  onNumStarsRatedChange: PropTypes.func.isRequired,
}

export default StarRatingControl
