import { api } from 'app/views/utils/api'
import settings from 'settings'

const SET_LOADING_NOTIFICATIONS = 'notifications/SET_LOADING_NOTIFICATIONS'
const SET_SETTINGS = ''

const initialState = {
  settings: {},
  loadingNotificationSettings: true,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        loadingNotificationSettings: false,
        settings: {
          ...action.settings,
        },
      }
    case SET_LOADING_NOTIFICATIONS:
      return {
        ...state,
        loadingNotificationSettings: action.loadingNotificationSettings,
      }
    default:
      return state
  }
}

export function getNotificationSettings(organizationUUID) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_NOTIFICATIONS,
      loadingNotificationSettings: true,
    })
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/notifications`,
      withAuthToken: true,
    })
      .then((response) => {
        dispatch({
          type: SET_SETTINGS,
          settings: response.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function updateNotificationSettings(
  organizationUUID,
  notificationSettings = {}
) {
  return (dispatch) => {
    return api({
      method: 'post',
      url: `${settings.urls.hacker}/organizations/${organizationUUID}/notifications`,
      withAuthToken: true,
      data: {
        send_reminders:
          typeof notificationSettings.send_reminders === 'boolean'
            ? notificationSettings.send_reminders
            : undefined,
        channels: notificationSettings.channels || null,
        notifications_schedule:
          notificationSettings.notifications_schedule || undefined,
      },
    })
      .then((response) => {
        dispatch({
          type: SET_SETTINGS,
          settings: response.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function sendInstantaneousNotifications(organizationUUID) {
  return (dispatch) => {
    return api({
      method: 'get',
      url: `${settings.urls.hacker}/organization/${organizationUUID}/send_notifications`,
      withAuthToken: true,
    })
  }
}
