import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import { HBox, Box } from 'app/views/core/Box'
function LessonNagMessage(props) {
  const { onReturnToLesson, onLeaveAnyway } = props
  return (
    <div>
      <p>
        In order to complete this lesson, you must submit a patch that passes
        all tests.
      </p>
      <HBox>
        <Box grow={1} />
        <PrimaryButton
          label='Back to Lesson'
          onClick={onReturnToLesson}
          size='medium'
          customCss={'text-black mr-3 bg-light_gray'}
        />
        <PrimaryButton
          label='Leave Anyway'
          onClick={onLeaveAnyway}
          customCss='bg-red'
          size='medium'
        />
        <Box grow={1} />
      </HBox>
    </div>
  )
}
LessonNagMessage.propTypes = {
  onReturnToLesson: PropTypes.func.isRequired,
  onLeaveAnyway: PropTypes.func.isRequired,
}

export default LessonNagMessage
