exports.engines = {
  c: 'C',
  clojure: 'Clojure',
  cobol: 'Cobol',
  cpp: 'C++',
  csharp: 'C#',
  dotnet: 'C# / .NET',
  go: 'Go',
  golang: 'Go',
  java: 'Java',
  kotlin: 'Kotlin',
  node: 'Node',
  perl: 'Perl',
  php: 'PHP',
  python: 'Python',
  ruby: 'Ruby',
  rust: 'Rust',
  scala: 'Scala',
  javascript: 'JavaScript',
  typescript: 'TypeScript',
}

exports.activeIntegrations = [
  'hackerone',
  'github',
  'gitlab',
  'jira',
  'fortify',
  'hclappscan',
  'acunetix',
  'contrastsecurity',
  'whitehat',
  'bugcrowd',
  'sonarqube',
  'synopsys',
  'veracode',
  'sonarcloud',
  'rapid7',
]

exports.integrationsLms = {
  exeed: 'Exceed / Intellum',
}

exports.integrationLogos = {
  acunetix: 'acunetix.svg',
  hackerone: 'hackerone.png',
  veracode: 'veracode.svg',
  gitlab: 'gitlab.png',
  github: 'github.png',
  jira: 'jira.png',
  bugcrowd: 'bugcrowd.svg',
  checkmarx: 'checkmarx.png',
  rapid7: 'rapid7.svg',
  contrastsecurity: 'contrastsecurity.svg',
  netsparker: 'netsparker.svg',
  hclappscan: 'hclappscan.svg',
  whitehat: 'whitehat.svg',
  synopsys: 'synopsys.jpeg',
  fortify: 'fortify.png',
  sonarqube: 'sonarqube.png',
  sonarcloud: 'sonarcloud.png',
}

exports.engineIcons = {
  c: 'c',
  clojure: 'clojure',
  cobol: 'cobol',
  cpp: 'cplusplus',
  csharp: 'csharp',
  dotnet: 'dot-net',
  go: 'go',
  golang: 'go',
  java: 'java',
  kotlin: 'kotlin',
  node: 'nodejs',
  perl: 'perl',
  php: 'php',
  python: 'python',
  ruby: 'ruby',
  rust: 'rust',
  scala: 'scala',
  javascript: 'javascript',
  typescript: 'typescript',
}

exports.frameworks = {
  django: 'DJango',
  flask: 'Flask',
  rails: 'Rails',
  core: 'Core',
  spring_boot: 'Spring',
  gin: 'Gin',
  react: 'React',
  angular: 'Angular',
  laravel: 'Laravel',
  net_http: 'Net / Http',
  nodejs: 'NodeJS',
  node: 'NodeJS',
  restify: 'Restify',
}

exports.apps = {}

exports.languages = {
  c: 'c_cpp',
  cpp: 'cpp',
  csharp: 'csharp',
  dotnet: 'csharp',
  go: 'golang',
  golang: 'golang',
  java: 'java',
  javascript: 'javascript',
  node: 'javascript',
  nodejs: 'javascript',
  perl: 'perl',
  php: 'php',
  python: 'python',
  ruby: 'ruby',
  scala: 'scala',
}

exports.themes = {
  colors: {
    blue: '#2697c0',
    brightBlue: '#1e87f0',
    grey: '#323232',
  },
}

exports.integrations = {
  hackerone: 'HackerOne',
  fortify: 'Fortify',
  gitlab: 'Gitlab',
  jira: 'Jira',
  hclappscan: 'HCL AppScan',
}
