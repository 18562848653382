import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import UIkit from 'uikit'

// Components
import GenericModal from 'app/views/components/Modals/GenericModal'
import Loader from 'app/views/components/Loader'
import ModalUserTeamPicker from 'app/views/components/Modals/ModalUserTeamPicker'
import RightSideBar from 'app/views/components/RightSideBar'
import UserDetailModal from 'app/views/components/UserDetailModal'
import Icon from 'app/views/components/Icon'

// Containers
import OrganizationLayout from 'app/views/containers/Organizations/components/Layout'
import UsersList from 'app/views/containers/Organizations/Users/UsersList'

// Redux
import {
  getOrganizations,
  getTeamDetails,
  getHackers,
  getHackersAndExport,
  updateHackerRole,
  addLicense,
  removeLicense,
  setFilterHacker,
  setOffsetHackers,
  deleteHackerFromOrganization,
} from 'app/state/modules/hacker'
import {
  getTeams,
  setSelectedUser,
  updateTeamPrivileges,
  removeHackerFromTeam,
} from 'app/state/modules/team'
import {
  importUsers,
  saveUserTeams,
  fetchSelectedUserDetail,
  cleanUserDetailModal,
} from 'app/state/modules/users'

// Utils
import analytics from 'app/views/utils/analytics'

// Local Imports
import EditUser from './EditUser'
import AddUsers from './AddUsers'

class OrganizationUsers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      organizationId: null,
      active: 'email',
      asc: true,
    }

    this.handleClickRemove = this.handleClickRemove.bind(this)
    this.onDeleteUser = this.onDeleteUser.bind(this)
    this.fetchSorted = this.fetchSorted.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeFilter = this.onChangeFilter.bind(this)
    this.renderDeleteModalContent = this.renderDeleteModalContent.bind(this)
    this.handleClickRemoveLicenseFromUser =
      this.handleClickRemoveLicenseFromUser.bind(this)
    this.handleClickAddLicenseToUser =
      this.handleClickAddLicenseToUser.bind(this)
    this.onClickConfirmTeamSave = this.onClickConfirmTeamSave.bind(this)
    this.onExport = this.onExport.bind(this)
    this.refreshHackers = this.refreshHackers.bind(this)
  }

  componentDidMount() {
    this.debounceTimer = null
    if (!this.props.hasLoadedOrganizations) {
      this.props.getOrganizations()
    }
    this.props.getTeamDetails(this.props.match.params.organization_uuid)
    this.props.getHackers(
      this.props.match.params.organization_uuid,
      false,
      false,
      this.state.active,
      this.state.asc,
      () => {
        if (this.props.match.params.hacker_uuid) {
          this.timeoutUserdetailModal = setTimeout(() => {
            this.props.fetchSelectedUserDetail(
              this.props.match.params.organization_uuid,
              { uuid: this.props.match.params.hacker_uuid }
            )
          }, 300)
        }
      }
    )
    analytics.page('organizations-users')
  }

  static getDerivedStateFromProps(props, state) {
    const organizationId = props.match.params.organization_uuid
    if (organizationId !== state.organizationId) {
      props.getTeamDetails(organizationId)
      return {
        organizationId,
      }
    }
    return null
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.hacker_uuid &&
      !this.props.match.params.hacker_uuid &&
      this.userDetailModal
    ) {
      this.userDetailModal.modal.hide()
      this.props.cleanUserDetailModal()
    }
  }

  componentWillUnmount() {
    if (this.timeoutUserdetailModal) {
      clearTimeout(this.timeoutUserdetailModal)
    }
  }

  onDeleteUser() {
    const orgUUID = this.props.match.params.organization_uuid
    const userUUID =
      this.props.selectedUser.uuid || this.props.match.params.hacker_uuid
    const callback = (error = false) => {
      this.modalDeleteUser.hide()
      this.editUserRightBar.triggerHide()
      if (error) {
        return UIkit.modal.alert(
          '<h2 >Error</h2><p>There was an error deleting the user. Please try again or contact support.</p>'
        )
      }
      return UIkit.modal
        .alert(
          '<h2 >User removed</h2><p>The user has been removed successfully from the organization<p>'
        )
        .then(() =>
          this.props.getHackers(this.props.match.params.organization_uuid)
        )
    }
    this.props.deleteHackerFromOrganization(orgUUID, userUUID, callback)
  }

  onChangePage(pageNumber) {
    this.props.setOffsetHackers((pageNumber - 1) * this.props.hackersPerPage)
    this.props.getHackers(
      this.props.match.params.organization_uuid,
      true,
      false,
      this.state.active,
      this.state.asc
    )
  }

  onChangeFilter(e) {
    clearTimeout(this.debounceTimer)
    this.props.setFilterHacker(e.target.value)
    this.debounceTimer = setTimeout(() => {
      if (
        this.props.filterHacker.trim().length >= 2 ||
        this.props.filterHacker === ''
      ) {
        this.props.setOffsetHackers(0)
        this.props.getHackers(
          this.props.match.params.organization_uuid,
          true,
          false,
          this.state.active,
          this.state.asc
        )
      }
    }, 300)
  }

  onClickConfirmTeamSave() {
    const successCallback = () => {
      this.modalUserTeamPicker.hide()
      this.editUserRightBar.triggerHide()
      this.props.getHackers(this.props.match.params.organization_uuid, true)
    }
    const errorCallback = () => {}
    this.props.saveUserTeams(
      this.props.match.params.organization_uuid,
      this.modalUserTeamPicker.state.selectedTeams,
      this.props.selectedUser.uuid,
      successCallback,
      errorCallback
    )
  }

  onExport() {
    this.exportingUsersLoaderModal.show()
    this.props.getHackersAndExport(
      this.props.match.params.organization_uuid,
      () => {
        this.exportingUsersLoaderModal.hide()
      }
    )
  }

  fetchSorted(sortBy) {
    if (this.props.isLoadingHackers) {
      return
    }
    const { asc, active } = this.state
    this.props.setOffsetHackers(0)
    this.setState(
      {
        active: sortBy,
        asc: sortBy === active ? !asc : true,
      },
      () => {
        this.props.getHackers(
          this.props.match.params.organization_uuid,
          true,
          false,
          this.state.active,
          this.state.asc
        )
      }
    )
  }

  handleClickAddLicenseToUser(hackerId, licenseTypeUUID, licenseTypeName) {
    this.props
      .addLicense(
        this.props.match.params.organization_uuid,
        hackerId,
        licenseTypeUUID
      )
      .then(() => {
        this.props.setSelectedUser(null, null, {
          has_license: true,
          license_type_uuid: licenseTypeUUID,
          license_type: licenseTypeName,
        })
        this.props.getHackers(this.props.match.params.organization_uuid, true)
      })
  }

  handleClickRemoveLicenseFromUser(hackerId, licenseTypename, licenseTypeUUID) {
    this.props
      .removeLicense(
        this.props.match.params.organization_uuid,
        hackerId,
        licenseTypename,
        licenseTypeUUID
      )
      .then(() => {
        this.props.getHackers(this.props.match.params.organization_uuid)
      })
  }

  handleClickRemove(hacker, teamUUID) {
    this.props
      .removeHackerFromTeam(
        this.props.match.params.organization_uuid,
        teamUUID,
        hacker.uuid
      )
      .then(() => {
        this.props.getHackers(this.props.match.params.organization_uuid)
      })
  }

  refreshHackers(hackerReloadUUID = false) {
    this.props.getHackers(
      this.props.match.params.organization_uuid,
      true,
      hackerReloadUUID
    )
  }

  renderDeleteModalContent() {
    if (!this.props.selectedUser) return null
    if (this.props.selectedUser.steps_completed > 20) {
      return (
        <>
          <p>
            {`You are about to remove ${this.props.selectedUser.email} from your organization.
            This user's license does not qualify to be released due to the amount of content they
            have already completed.`}
          </p>
          <p>Are you sure you want to continue?</p>
        </>
      )
    }
    return (
      <>
        <p>
          {`You are about to remove ${this.props.selectedUser.email} from your organization. This
          user's license will be available for reuse since they completed a minimal amount of
          the training.`}
        </p>
        <p>Are you sure you want to continue?</p>
      </>
    )
  }

  render() {
    if (
      !this.props.hasLoadedOrganizations ||
      !this.props.hasLoadedCurrentTeamDetails ||
      !this.props.hasLoadedCurrentTeamHackers ||
      !this.props.currentOrganization
    ) {
      return <Loader visible />
    }
    const totalPages = Math.ceil(
      this.props.totalHackers / this.props.hackersPerPage
    )
    const currentOrganizationId = this.props.match.params.organization_uuid

    return (
      <OrganizationLayout
        data-test-id='organization-users-component'
        active='users'
        {...this.props}
      >
        <GenericModal
          width='uk-margin-auto-vertical text-center uk-padding-large'
          ref={(ref) => {
            this.exportingUsersLoaderModal = ref
          }}
          id='exporting-users-loader-modal'
          body={
            <>
              <div data-uk-spinner='' />
              <p className='text-center'>Exporting users</p>
            </>
          }
          noButtons
          buttonType='uk-button-primary'
        />
        <UsersList
          asc={this.state.asc}
          active={this.state.active}
          isLoadingHackers={this.props.isLoadingHackers}
          fetchSorted={this.fetchSorted}
          hackersPerPage={this.props.hackersPerPage}
          onExport={this.onExport}
          fetchSelectedUserDetail={this.props.fetchSelectedUserDetail}
          match={this.props.match}
          onChangePage={this.onChangePage}
          filterHacker={this.props.filterHacker}
          totalPages={totalPages}
          offsetHackers={this.props.offsetHackers}
          hackerUUID={this.props.hackerUUID}
          users={this.props.users || []}
          onSelectUser={(user) => {
            this.props.setSelectedUser(
              this.props.match.params.organization_uuid,
              user
            )
            this.editUserRightBar.triggerShow()
          }}
          onChangeFilter={this.onChangeFilter}
          showAddUsers={() => {
            this.addUsersComponent.clearForm()
            this.teamMemberAddModal.show()
          }}
        />
        <GenericModal
          width='uk-width-3-5'
          ref={(ref) => {
            this.teamMemberAddModal = ref
          }}
          noButtons
          noOverflow
          body={
            <AddUsers
              ref={(ref) => {
                this.addUsersComponent = ref
              }}
              match={this.props.match}
              getTeams={this.props.getTeams}
              getHackers={this.props.getHackers}
              teams={this.props.teams || []}
              // getTeamById={this.props.getTeamById}
              importUsers={this.props.importUsers}
              hide={(this.teamMemberAddModal || {}).hide}
            />
          }
          id='add-user-teams'
        />

        <GenericModal
          title='Delete user from organization'
          ref={(ref) => {
            this.modalDeleteUser = ref
          }}
          id='delete-user-modal'
          date-test-id='delete-user-modal'
          body={this.renderDeleteModalContent()}
          buttons={
            <button
              data-test-id='delete-user-confirm'
              type='button'
              className='uk-button uk-button-danger uk-margin-left'
              onClick={this.onDeleteUser}
            >
              Delete
            </button>
          }
        />

        <ModalUserTeamPicker
          onClickConfirm={this.onClickConfirmTeamSave}
          userEmail={this.props.selectedUser.email}
          userId={this.props.selectedUser.uuid}
          userTeams={(this.props.selectedUser.teams === 'loading'
            ? []
            : this.props.selectedUser.teams
          ).reduce((prev, curr) => {
            prev[curr.team_uuid] = {
              team_role: curr.team_role,
              team_name: curr.name,
            }
            return prev
          }, {})}
          onClickCancel={() => this.modalUserTeamPicker.hide()}
          selectedUser={this.props.selectedUser}
          onFirstShow={() =>
            this.props.getTeams(this.props.match.params.organization_uuid)
          }
          isLoading={this.props.loadingTeams || this.props.isLoadingSaveTeams}
          isSaving={this.props.isLoadingSaveTeams === true}
          teams={this.props.teams}
          onRef={(ref) => {
            this.modalUserTeamPicker = ref
          }}
        />

        <UserDetailModal
          id='user-details-users-view'
          onEditTeams={() => {
            this.modalUserTeamPicker.show()
          }}
          onRef={(ref) => {
            this.userDetailModal = ref
          }}
          onRefresh={this.refreshHackers}
          onShow={() => {
            if (!this.props.match.params.hacker_uuid) {
              this.props.history.push(
                `${this.props.match.url}/${
                  this.props.selectedUserDetail.uuid
                }/${
                  this.props.match.params.tab
                    ? this.props.match.params.tab
                    : 'proficiency'
                }`
              )
            }
          }}
          onHide={() => {
            this.props.history.push(
              `${this.props.match.url.substr(
                0,
                this.props.match.url.lastIndexOf('/users')
              )}/users`
            )
            this.props.cleanUserDetailModal()
          }}
          onRemoveFromOrg={() => this.modalDeleteUser.show()}
        />

        <RightSideBar
          ref={(ref) => {
            this.editUserRightBar = ref
          }}
          content={
            <EditUser
              isAdmin={
                this.props.organizationsHash[currentOrganizationId].role ===
                'admin'
              }
              modalUserTeamPicker={this.modalUserTeamPicker}
              availableLicenses={this.props.availableLicenses}
              availableLicenseTypes={this.props.availableLicenseTypes}
              updateHackerRole={this.props.updateHackerRole}
              onRefresh={this.refreshHackers}
              handleClickMakeAdmin={this.handleClickMakeAdmin}
              handleClickRemoveLicenseFromUser={
                this.handleClickRemoveLicenseFromUser
              }
              handleClickAddLicenseToUser={this.handleClickAddLicenseToUser}
              onRemoveFromOrg={() => this.modalDeleteUser.show()}
              onRemoveFromTeam={this.handleClickRemove}
              selectedUser={this.props.selectedUser}
              updateTeamPrivileges={this.props.updateTeamPrivileges}
              match={this.props.match}
              getHackers={this.props.getHackers}
              hackerUUID={this.props.hackerUUID}
              organization={
                this.props.organizationsHash[
                  this.props.match.params.organization_uuid
                ]
              }
            />
          }
        />
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state, props) => ({
  organizations: state.hacker.organizationsList,
  organizationsHash: state.hacker.organizationsHash,
  isLoadingHackers: state.hacker.isLoadingHackers,

  selectedUser: state.team.selectedUser,

  offsetHackers: state.hacker.offsetHackers,

  hasLoadedOrganizations: state.hacker.hasLoadedOrganizations,
  hasLoadedCurrentTeamDetails: state.hacker.hasLoadedCurrentTeamDetails,
  filterHacker: state.hacker.filterHacker,

  hasLoadedCurrentTeamHackers: state.hacker.hasLoadedCurrentTeamHackers,
  users: state.hacker.currentTeamHackers,
  hackerUUID: state.hacker.profile.user_id,

  teams: state.team.teams,

  currentOrganization:
    state.hacker.organizationsHash[props.match.params.organization_uuid],
  totalHackers: state.hacker.totalHackers,
  hackersPerPage: state.hacker.hackersPerPage,

  availableLicenses: state.hacker.currentTeamDetails.available_licenses,
  availableLicenseTypes:
    state.hacker.currentTeamDetails.available_license_types,

  loadingTeams: state.team.loadingTeams,
  isLoadingSaveTeams: state.users.isLoadingSaveTeams,

  selectedUserDetail: state.users.selectedUserDetail,
})

export default withRouter(
  connect(mapStateToProps, {
    getOrganizations,
    getTeamDetails,
    getHackers,
    getHackersAndExport,
    getTeams,
    setSelectedUser,
    addLicense,
    removeLicense,
    updateHackerRole,
    updateTeamPrivileges,
    removeHackerFromTeam,
    setFilterHacker,
    setOffsetHackers,
    deleteHackerFromOrganization,
    importUsers,
    saveUserTeams,
    fetchSelectedUserDetail,
    cleanUserDetailModal,
  })(OrganizationUsers)
)
