import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import PrimaryCheckBox from '../Inputs/PrimaryCheckBox'
/**
 * Single item displayed in a SelectionTree: may represent a group with 1 or more children, or a
 * leaf item. Displays a checkbox and a label, indented to a certain depth. Clicking anywhere on
 * the item toggles selection of the associated item(s).
 */
function SelectionTreeItem(props) {
  const {
    depth,
    name,
    selectionState,
    onChange,
    isParent,
    index,
    collapsible,
    collapsed,
    onCollapseClick,
    hidden,
  } = props
  const isSelected = selectionState !== 'none'

  const handleChange = () => {
    const shouldBecomeSelected = selectionState !== 'all'
    onChange(shouldBecomeSelected)
  }

  const handleClick = (e) => {
    if (collapsible && e.target.tagName !== 'INPUT') {
      onCollapseClick(index, !collapsed)
    }
  }

  const checkboxRef = useRef()
  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = selectionState === 'some'
    }
  }, [selectionState])

  const classes = ['selection-item']
  if (isParent) {
    classes.push('parent')
  } else if (hidden) {
    classes.push('selection-child', 'accordion-hide')
  } else {
    classes.push('selection-child', 'accordion-show')
  }

  const margin = 20 * depth

  return collapsible ? (
    <div
      id={index}
      className={classes.concat('selection-collapsible').join(' ')}
      style={{ marginLeft: margin }}
      onClick={handleClick}
    >
      <input
        ref={checkboxRef}
        type='checkbox'
        className='border-solid mr-2'
        checked={isSelected}
        onChange={handleChange}
        name={name}
      />
      <span className='name text-sm'>{name}</span>
      <i
        className={collapsed ? 'ri-arrow-down-s-line' : 'ri-arrow-up-s-line'}
      ></i>
    </div>
  ) : (
    <div
      id={index}
      className={classes.join(' ')}
      style={{ marginLeft: margin }}
      onClick={handleClick}
    >
      <PrimaryCheckBox
        checked={isSelected}
        onChange={handleChange}
        name={name}
        label={name}
        ref={checkboxRef}
      />
    </div>
  )
}

SelectionTreeItem.propTypes = {
  depth: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  selectionState: PropTypes.oneOf(['none', 'some', 'all']).isRequired,
  onChange: PropTypes.func.isRequired,
  collapsible: PropTypes.bool,
  onCollapseClick: PropTypes.func,
}

export default SelectionTreeItem
