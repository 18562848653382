import React from 'react'

export default function Microsoft() {
  return (
    <div className='flex justify-center items-center flex-col'>
      <div className='text-center'>
        <a className='uk-navbar-item uk-logo uk-navbar-toggle'>
          <img
            src='/static/images/logo.png'
            style={{ maxWidth: 129 }}
            alt='Logo'
          />
        </a>
        <h3>HackEDU Microsoft Teams Bot</h3>
      </div>
      <div className='uk-card uk-card-body uk-card-default uk-width-1-2'>
        <p>Thanks for installing the HackEDU Microsoft Teams bot!</p>
        <p>
          In order to use this integration you must be a HackEDU customer. If
          you already have an Admin account, login <a href='/login'>here</a>.
          <br />
          If you would like to create an account, please email{' '}
          <a href='mailto:sales@hackedu.com'>sales@hackedu.com</a>. To learn
          more about HackEDU, visit{' '}
          <a href='https://www.hackedu.com/secure-coding-training'>
            https://www.hackedu.com/secure-coding-training
          </a>
        </p>
      </div>
    </div>
  )
}
