import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'

// Components
import ButtonWithLoader from 'app/views/components/ButtonWithLoader'
import Icon from 'app/views/components/Icon'
import ToggleSwitch from 'app/views/components/ToggleSwitch'

const propTypes = {
  domains: PropTypes.array.isRequired,
  enabled: PropTypes.bool.isRequired,
  onEnable: PropTypes.func.isRequired,
  onUpdateMetadata: PropTypes.func.isRequired,
  updatingSSO: PropTypes.bool.isRequired,
  updatingSSOError: PropTypes.string.isRequired,
}

class SSOSettings extends React.Component {
  constructor() {
    super()

    this.state = {
      metadataUrl: '',
      metadataFile: '',
      metadataFileName: '',
    }

    this.handleChangeMetadataFile = this.handleChangeMetadataFile.bind(this)
    this.handleChangeMetadataURL = this.handleChangeMetadataURL.bind(this)
    this.handleOnEnable = this.handleOnEnable.bind(this)
    this.handleUpdateMetadata = this.handleUpdateMetadata.bind(this)
  }

  handleChangeMetadataFile(e) {
    const files = e.target.files // FileList object

    // use the 1st file from the list
    const f = files[0]

    const reader = new FileReader()

    this.setState({
      metadataFileName: e.target.value.replace('C:\\fakepath\\', ''),
    })

    // Closure to capture the file information.
    reader.onload = (() => {
      return (result) => {
        this.setState({
          metadataFile: result.target.result,
          metadataUrl: '',
        })
      }
    })(f)

    // Read in the image file as a data URL.
    reader.readAsText(f)
  }

  handleChangeMetadataURL(e) {
    this.setState({
      metadataUrl: e.target.value,
      metadataFile: '',
      metadataFileName: '',
    })
  }

  handleOnEnable(enabled) {
    this.props.onEnable(enabled)
  }

  handleUpdateMetadata() {
    this.props.onUpdateMetadata(this.state.metadataFile, this.state.metadataUrl)
    this.setState({
      metadataUrl: '',
      metadataFile: '',
      metadataFileName: '',
    })
  }

  render() {
    return (
      <div>
        <h3>Single sign-on (SSO)</h3>
        <div className='uk-card uk-card-default uk-card-body max-w-3xl'>
          {this.props.updatingSSO ? (
            <div className='uk-margin'>
              <div data-uk-spinner='' />
            </div>
          ) : (
            <div>
              <div className='uk-margin-large'>
                {!this.props.metadata_file && !this.props.metadata_url ? (
                  <div className='uk-margin'>
                    <Link
                      to='#modal-edit-metadata'
                      data-uk-toggle='target: #modal-edit-metadata'
                      className='flex items-center text-green'
                    >
                      <Icon
                        className='uk-margin-small-right uk-icon'
                        name='add-circle'
                        ratio={0.8}
                        as='span'
                      />
                      Add a metadata file or URL
                    </Link>
                  </div>
                ) : (
                  <div className='uk-inline'>
                    <ToggleSwitch
                      label={
                        this.props.enabled
                          ? 'Disable SSO Integration'
                          : 'Enable SSO Integration'
                      }
                      onChange={this.handleOnEnable}
                      checked={this.props.enabled}
                    />
                  </div>
                )}
              </div>
              <div className='uk-margin'>
                <span className='font-bold uk-margin-small-bottom'>
                  Domains
                </span>
                {this.props.domains.length > 0 ? (
                  <div>
                    {this.props.domains.map((domain) => (
                      <span
                        className='uk-label uk-label-secondary uk-margin-small-right'
                        key={domain}
                      >
                        @{domain}
                      </span>
                    ))}
                    <div className='text-sm text-muted'>
                      If you need to change your SSO domains, email{' '}
                      <a
                        className='text-green hover:text-green'
                        href='mailto:support@securityjourney.com'
                      >
                        support@securityjourney.com
                      </a>
                    </div>
                  </div>
                ) : (
                  <div>
                    Email{' '}
                    <a
                      className='text-green hover:text-green'
                      href='mailto:support@securityjourney.com'
                    >
                      support@securityjourney.com
                    </a>{' '}
                    to associate a domain to your account.
                  </div>
                )}
              </div>
              <div className='uk-margin'>
                {this.props.metadata_file && (
                  <div>
                    <span className='font-bold uk-margin-small-bottom uk-margin-small-right'>
                      Metadata File
                    </span>
                    (
                    <Link
                      to='#modal-edit-metadata'
                      data-uk-toggle='target: #modal-edit-metadata'
                    >
                      Edit
                    </Link>
                    )
                    <pre style={{ maxHeight: 200 }}>
                      {this.props.metadata_file}
                    </pre>
                  </div>
                )}
                {this.props.metadata_url && (
                  <div>
                    <span className='font-bold uk-margin-small-bottom uk-margin-small-right'>
                      Metadata URL
                    </span>
                    (
                    <Link
                      to='#modal-edit-metadata'
                      data-uk-toggle='target: #modal-edit-metadata'
                      className='text-green hover:text-green'
                    >
                      Edit
                    </Link>
                    )<div>{this.props.metadata_url}</div>
                  </div>
                )}
              </div>

              <div className='uk-margin'>
                <p className='text-danger'>{this.props.updatingSSOError}</p>
              </div>
            </div>
          )}

          <div id='modal-edit-metadata' data-uk-modal>
            <div className='uk-modal-dialog uk-modal-body'>
              <h2>Edit Metadata</h2>
              <div className='uk-margin'>
                <strong>URL</strong>
                <input
                  name='metadata_url'
                  type='text'
                  placeholder='New metadata URL'
                  className='uk-input'
                  onChange={this.handleChangeMetadataURL}
                  value={this.state.metadataUrl}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  height: 11,
                  borderBottom: '1px solid #e5e5e5',
                  textAlign: 'center',
                }}
              >
                <span style={{ backgroundColor: '#FFFFFF', padding: '0 10px' }}>
                  or
                </span>
              </div>
              <div className='uk-margin'>
                <strong>File Upload</strong>
                <div>
                  <div
                    className='uk-width-1-1'
                    data-uk-form-custom='target: true'
                  >
                    <input
                      type='file'
                      onChange={this.handleChangeMetadataFile}
                    />
                    <input
                      className='uk-placeholder uk-input uk-form-width-medium uk-width-1-1'
                      type='text'
                      placeholder='Drag a file or click here to upload metadata document'
                      disabled
                      value={this.state.metadataFileName}
                    />
                  </div>
                </div>
              </div>
              <div className='uk-margin'>
                <div className='uk-form-controls'>
                  <ButtonWithLoader
                    type='submit'
                    className='uk-button uk-button-primary tm-button-primary uk-modal-close'
                    onClick={this.handleUpdateMetadata}
                    disabled={
                      this.state.metadataUrl === '' &&
                      this.state.metadataFile === ''
                    }
                    isLoading={this.props.updatingSSO}
                  >
                    Continue
                  </ButtonWithLoader>
                  <button
                    type='button'
                    className='uk-button uk-button uk-modal-close'
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <button
                className='uk-modal-close-outside'
                type='button'
                data-uk-close
              />
            </div>
          </div>

          <div className='uk-margin'>
            <strong>SSO support</strong>
            <div>
              HackEDU integrates with any SAML 2.0 Identity Provider (IdP). View
              the{' '}
              <a
                href='https://help.securityjourney.com/en/collections/3484978-hackedu-features#sso'
                target='_blank'
                className='text-green hover:text-green'
              >
                {' '}
                SSO help guides
              </a>{' '}
              for setup instructions and help with managing single sign-on.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SSOSettings.propTypes = propTypes

export default SSOSettings
