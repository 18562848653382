import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/**
 * Style/layout components that are local to this file.
 * TODO: Factor out common styling/layout details entirely.
 */
const Table = ({ children }) => (
  <table className='uk-table gray uk-table-small uk-table-divider text-sm uk-margin-small-top uk-width-1-2'>
    {children}
  </table>
)
const TableHeader = ({ labels }) => (
  <thead>
    <tr>
      {labels.map((label, i) => (
        <th
          key={i}
          className='text-emphasis uppercase text-sm'
        >
          {label}
        </th>
      ))}
    </tr>
  </thead>
)
const TableRow = (props) => (
  <tr className='text-sm cursor-pointer' {...props} />
)
const EditedLabel = () => (
  <span className='uk-margin-small-left text-primary text-sm'>
    (Edited)
  </span>
)

/**
 * A single row in the proxy history table, representing a single request and
 * its corresponding response.
 */
function ProxyHistoryRow(props) {
  const { request, onClick } = props
  const requestDesc = `${request.method} ${request.path}`
  const responseDesc = request.response ? request.response.status_code : ' - '
  const timestampDesc = request.timestamp ? request.timestamp.fromNow() : null
  const isEdited = !!request.editedRequest
  const hasErrorResponse =
    request.response && request.response.status_code >= 400
  return (
    <TableRow onClick={onClick}>
      <td>
        <span>{requestDesc}</span> {isEdited && <EditedLabel />}
      </td>
      <td className={cx({ 'text-danger': hasErrorResponse })}>
        {responseDesc}
      </td>
      <td>{timestampDesc}</td>
    </TableRow>
  )
}
ProxyHistoryRow.propTypes = {
  request: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

/**
 * Table listing all the requests that have been made through the proxy.
 */
function ProxyHistoryTable(props) {
  const { requests, onSelectRequest } = props
  return (
    <Table>
      <TableHeader labels={['Request', 'Response Code', 'Timestamp']} />
      <tbody>
        {requests.map((request) => (
          <ProxyHistoryRow
            key={request.request_id}
            onClick={() => onSelectRequest(request.request_id)}
            request={request}
          />
        ))}
      </tbody>
    </Table>
  )
}
ProxyHistoryTable.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectRequest: PropTypes.func.isRequired,
}

export default ProxyHistoryTable
