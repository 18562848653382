/**
 * Helper functions to get our test data into a more sensible format for display.
 */
function getSubmissionResult(submission) {
  // It'd be nice if the API just gave us a completed_at timestamp - as it is, the API indicates:
  // - pending: 'passed' == false && 'test' has null values
  // -  failed: 'passed' == false && 'test' has non-null values from the stage that failed
  // -  passed: 'passed' == true
  return submission.test.passed
    ? 'passed'
    : submission.test.name
    ? 'failed'
    : 'pending'
}

function parseSubmissionDetails(submission) {
  const result = getSubmissionResult(submission)
  const stage = submission.test
  return {
    submissionId: submission.id,
    submittedAt: submission.submitted_at,
    engine: submission.engine,
    code: submission.code,
    result: result,
    failedStage:
      result !== 'failed'
        ? null
        : {
            name: stage.name,
            title: stage.title,
            description: stage.description,
            helpUrl: stage.url,
            errorMessage: stage.error_message,
            errorStackTrace: stage.error_stack_trace,
          },
  }
}

export { getSubmissionResult, parseSubmissionDetails }
