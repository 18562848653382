import PropTypes from 'prop-types'
import React from 'react'

// Components
import ModalChallengeProof from 'app/views/components/Modals/ModalChallengeProof'

const propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
}

const ChallengeProof = (props) => (
  <div className='uk-section uk-padding-remove-top'>
    <div className='uk-padding uk-padding-small'>
      <h3>Submit Flag</h3>
      <p>Follow the instructions above and submit your proof of completion.</p>
      <p>
        Hacking Challenges are intentionally vague, so try exploring the app and
        look for clues. Apply what you have learned in HackEDU Lessons and try
        different types of attacks.
      </p>
      <div>
        <button
          className='uk-button uk-button-primary uk-width-1-1'
          data-uk-toggle='#modal-challenge-proof'
          type='button'
        >
          Submit Flag
        </button>
      </div>
      <ModalChallengeProof id={props.id} userId={props.userId} />
    </div>
  </div>
)

ChallengeProof.propTypes = propTypes

export default ChallengeProof
