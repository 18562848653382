import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'app/views/components/GustavoIcon'

function ContentModalCloseButton(props) {
  const { onClick } = props
  return (
    <div
      style={{
        float: 'right',
        paddingTop: 16,
        paddingRight: 16,
        cursor: 'pointer',
      }}
    >
      <Icon name='close' ratio={1.5} onClick={onClick} />
    </div>
  )
}
ContentModalCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ContentModalCloseButton
