import PropTypes from 'prop-types'

const UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/

/**
 * Data passed into the www-app lesson UI from SJ in order to indicate that a lesson is
 * being taken in the context of an SJ tournament.
 */
export type TournamentDetails = {
  /** UUID identifying the tournament */
  uuid: string
  /** Time at which the tournament started */
  startedAt: Date
  /** SJ tenant in which the tournament is taking place */
  tenant: string
}

export const TournamentDetailsProps = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  startedAt: PropTypes.instanceOf(Date).isRequired,
  tenant: PropTypes.string.isRequired,
})

/**
 * Examines a set of URL parameters to determine if www-app is being instructed to load
 * a lesson within the context of an SJ tournament. If a 'tournament' URL parameter is
 * present, parses and returns a valid TournamentDetails object, or throws an Error if
 * the required parameters are not present or are malformed. If no 'tournament'
 * parameter exists, returns null to indicate that we're not running in a tournament.
 */
export function parseTournamentDetails(params: URLSearchParams): TournamentDetails | null {
  // Require '?tournament=...&tournamentStart=...&tenant=...'
  const tournamentStr = params.get('tournament')
  const tournamentStartStr = params.get('tournamentStart')
  const tenantStr = params.get('tenant')

  // If 'tournament' is not specified, return null to indicate that we're not meant to
  // run in tournament mode
  if (tournamentStr === null) {
    return null
  }

  // Otherwise, parse the remaining values strictly: if 'tournament' is supplied, then
  // we *must* have a valid start timestamp and tenant
  const uuid = tournamentStr
  const startedAt = new Date(tournamentStartStr)
  const tenant = tenantStr

  if (!uuid || !uuid.match(UUID_REGEX)) {
    throw new Error(`Invalid tournament configuration: '${uuid}' is not a valid tournament UUID`)
  }
  if (!tournamentStartStr || isNaN(startedAt.getTime())) {
    throw new Error(`Invalid tournament configuration: 'tournamentStart' must be a valid timestamp (got '${tournamentStartStr}')`)
  }
  if (!tenant) {
    throw new Error("Invalid tournament configuration: 'tenant' must be supplied")
  }
  return { uuid, startedAt, tenant }
}
