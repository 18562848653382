import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

function HintBody(props) {
  const { title, markdown, onClickBack } = props
  return (
    <>
      <h3>{title}</h3>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} linkTarget='_blank'>
        {markdown}
      </ReactMarkdown>
      {onClickBack && (
        <a
          href='#'
          onClick={(event) => {
            event.preventDefault()
            onClickBack()
          }}
        >
          &larr; Back to Hints
        </a>
      )}
    </>
  )
}
HintBody.propTypes = {
  title: PropTypes.string.isRequired,
  markdown: PropTypes.string.isRequired,
  onClickBack: PropTypes.func,
}

export default HintBody
