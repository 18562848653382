import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Icon from 'app/views/components/Icon'
import TimeRangePicker from 'app/views/components/TimeRangePicker'
import TeamPicker from 'app/views/components/TeamPicker'
import GenerateOrganizationCertificates from 'app/views/components/GenerateOrganizationCertificates'

import OrganizationLayout from '../components/Layout'

class ComplianceReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      days: null,
      teamUUID: null,
    }
  }

  render() {
    return (
      <OrganizationLayout
        active='certificate'
        data-test-id='standard-report-component'
        noPaddingRemove={this.props.match.params.team_uuid}
        style={{ position: 'relative' }}
        {...this.props}
      >
        {this.props.match.params.team_uuid && (
          <button
            onClick={() =>
              this.props.history.push(
                `/admin/${this.props.match.params.organization_uuid}/teams/${this.props.match.params.team_uuid}/users`
              )
            }
            className='uk-button uk-button-secondary uk-button-small uk-position-absolute uk-position-top-left uk-margin-top uk-margin-small-left flex'
            type='button'
          >
            <Icon name='arrow-left' className='uk-margin-small-right' />
            Back To Team
          </button>
        )}

        <h2 className='text-center'>Certificate (PDF)</h2>
        <div className='uk-card uk-card-default uk-card-body'>
          <p className='text-center'>
            An official HackEDU Certificate for all users who have completed the
            OWASP Top 10 training plan.
          </p>
          <div className='flex justify-center flex-col uk-width-1-1 text-center'>
            <TimeRangePicker
              onChange={(days) =>
                this.setState({
                  days,
                })
              }
              className='text-center'
              classNameSelect='uk-select uk-width-large text-center'
            />
            {!this.props.match.params.team_uuid && (
              <TeamPicker
                onChange={(e) =>
                  this.setState({
                    teamUUID: e.target.value === '-' ? null : e.target.value,
                  })
                }
                className='text-center'
                classNameSelect='uk-select uk-width-large text-center uk-margin-top'
              />
            )}
            <div className='text-center uk-width-1-1 flex justify-center'>
              <GenerateOrganizationCertificates
                days={this.state.days}
                isTeamReport={
                  Boolean(this.props.match.params.team_uuid) ||
                  Boolean(this.state.teamUUID)
                }
                teamUUID={
                  this.props.match.params.team_uuid || this.state.teamUUID
                }
                style={{
                  cursor: 'pointer',
                }}
                className='uk-button uk-button-secondary uk-margin-top uk-position-relative flex justify-center items-center uk-width-large'
                organizationUUID={this.props.match.params.organization_uuid}
              >
                <Icon
                  style={{ position: 'absolute', left: 20 }}
                  name='download-cloud'
                />
                Download Certificate (PDF)
              </GenerateOrganizationCertificates>
            </div>
          </div>
        </div>
      </OrganizationLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  organizations: Object.values(state.hacker.organizationsList),
  organizationsHash: state.hacker.organizationsHash,
})
export default withRouter(connect(mapStateToProps, {})(ComplianceReport))
