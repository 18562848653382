import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'
import queryString from 'query-string'
import changeInputHandler from 'app/views/utils/changeInputHandler'
import GenericModal from 'app/views/components/Modals/GenericModal'

import {
  registerUser,
  getConfirmedStatus,
  resendVerificationCode,
  logout,
} from 'app/views/utils/auth'
import { storePostLoginRedirectUrl } from 'app/views/utils/authRedirect'

import settings from 'settings'
import validate from './validation'
import ButtonWithLoader from '../../components/ButtonWithLoader'
import { COMPLEXITY } from '../../constants/errorMessages'

import { withLDConsumer } from 'launchdarkly-react-client-sdk'

const propTypes = {}
const defaultProps = {}

class Register extends React.Component {
  constructor(props) {
    super(props)

    this.handleInputChange = changeInputHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleResendVerificationEmail =
      this.handleResendVerificationEmail.bind(this)
    this.handleResendCode = this.handleResendCode.bind(this)
    this.state = {
      fields: {},
      errors: {},
      error: '',
      registered: false,
      success: {},
      showResendVerificationEmailLink: false,
      disableSubmitButton: false,
      showLinkToTroubleshootingVerificationIssues: false,
      user: null,
    }
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.logout()
    }

    const queryStringValues = queryString.parse(this.props.location.search)
    if (queryStringValues.redirect_to) {
      storePostLoginRedirectUrl(queryStringValues.redirect_to)
    }
    if (queryStringValues.email) {
      this.setState({ fields: { email: queryStringValues.email } })
    }
  }

  handleResendVerificationEmail() {
    const { user } = this.state
    const clientMetadata = { signUpFrom: 'HE' }
    this.setState({ isResendButtonLoading: true })
    user.resendConfirmationCode(() => {
      this.setState({
        showResendVerificationEmailLink: false,
        isResendButtonLoading: false,
        showLinkToTroubleshootingVerificationIssues: true,
      })
    }, clientMetadata)
  }

  handleSubmit(e) {
    e.preventDefault()
    const { fields } = this.state
    const errors = validate(fields, this.props.flags.enforceComplexPasswords)
    if (Object.keys(errors).length) {
      return this.setState({
        errors,
      })
    }
    this.setState({ isButtonLoading: true })

    registerUser(fields)
      .then((user) => {
        this.setState({
          showResendVerificationEmailLink: true,
          disableSubmitButton: true,
          isButtonLoading: false,
          user,
          registered: true,
        })
      })
      .catch((err) => {
        if (err.code === 'UsernameExistsException') {
          getConfirmedStatus({
            email: this.state.fields.email.toLowerCase(),
            password: fields.password,
          })
            .then(() => {
              this.setState({
                isButtonLoading: false,
                error: err.message,
              })
            })
            .catch((e) => {
              if (e.message === 'CONFIRMED') {
                this.setState({
                  isButtonLoading: false,
                  error: 'CONFIRMED',
                })
              } else {
                this.setState({
                  isButtonLoading: false,
                  error: 'NOT_CONFIRMED',
                })
              }
            })
        } else {
          this.setState({
            isButtonLoading: false,
            error: err.message,
          })
        }
      })
  }

  handleResendCode() {
    resendVerificationCode({ username: this.state.fields.email.toLowerCase() })
      .then(() => {
        const { errors } = this.state
        this.setState({
          success: {
            resendEmail:
              'We have just resent the confirmation code to your email',
          },
          errors: {
            ...errors,
            resendEmail: null,
          },
        })
        this.resendModal.show()
      })
      .catch((err) => {
        const { errors, success } = this.state
        this.setState(
          {
            errors: {
              ...errors,
              resendEmail: err.message,
            },
            success: {
              ...success,
              resendEmail: null,
            },
          },
          () => {
            this.resendModal.show()
          }
        )
      })
  }

  renderError() {
    switch (this.state.error) {
      case 'NOT_CONFIRMED':
        return (
          <div className='uk-margin'>
            <span className='text-danger'>
              An account using that email address already exists, but has not
              been verified.{' '}
              <span
                className='_hover_error'
                onClick={this.handleResendCode}
                style={{
                  fontTransform: 'underline',
                  borderBottom: '1px solid #f0506e',
                  cursor: 'pointer',
                }}
                type='button'
              >
                Resend Verification Email
              </span>
            </span>
          </div>
        )
      case 'CONFIRMED':
        return (
          <div className='uk-margin'>
            <span className='text-danger'>
              An account using that email address already exists
            </span>
          </div>
        )
      case 'EMAIL_INVALID':
        return (
          <div className='uk-margin'>
            <span className='text-danger'>
              The email is not valid as a company email. Please use an email
              that has your company domain.
            </span>
          </div>
        )
      case '500':
        return (
          <div className='uk-margin'>
            <span className='text-danger'>Something went wrong.</span>
          </div>
        )
      case null:
      case '':
        return null
      default:
        return (
          <div className='uk-margin'>
            <span className='text-danger'>{this.state.error}</span>
          </div>
        )
    }
  }

  render() {
    const {
      errors,
      error,
      showResendVerificationEmailLink,
      disableSubmitButton,
      isButtonLoading,
      isResendButtonLoading,
      showLinkToTroubleshootingVerificationIssues,
    } = this.state

    return (
      <div
        data-test-id='register-component'
        className='uk-background-muted'
        style={{ minHeight: 'calc(100vh - 10px)' }}
      >
        <div className='uk-container' style={{ maxWidth: 600 }}>
          <div className='uk-section uk-margin-large-bottom'>
            <div className='text-center'>
              <Link className='uk-navbar-item uk-logo uk-navbar-toggle' to='/'>
                <img
                  src='/static/images/SJ_HE_Logo2ColorHorizontal22.png'
                  className='w-[450px]'
                  alt='Logo'
                />
              </Link>
              <h3>Create an account</h3>
            </div>
            <div
              className='uk-card uk-card-default uk-card-body uk-padding-large uk-margin-top'
              style={{ paddingBottom: 30 }}
            >
              {!this.state.registered ? (
                <form onSubmit={this.handleSubmit}>
                  <div className='uk-margin'>
                    <input
                      name='email'
                      value={this.state.fields.email || ''}
                      type='text'
                      placeholder='Your company email'
                      className='uk-input'
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <span className='text-danger'>{errors.email}</span>
                  <div className='uk-margin'>
                    <input
                      name='password'
                      type='password'
                      placeholder='Password'
                      className='uk-input'
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <span className='text-danger'>
                    {errors.password?.message}
                  </span>
                  {errors.password?.missing && (
                    <ul className='text-danger mt-0'>
                      {errors.password.missing.map((name, i) => (
                        <li key={i}>{COMPLEXITY[name]}</li>
                      ))}
                    </ul>
                  )}
                  {error && this.renderError()}
                  <div className='uk-margin'>
                    <p className='text-sm text-muted'>
                      By registering, I agree to HackEDU's{' '}
                      <a
                        href={`${settings.urls.www}/privacy`}
                        target='_blank'
                        className='text-green hover:text-green underline'
                      >
                        Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a
                        href={`${settings.urls.www}/terms-of-service`}
                        target='_blank'
                        className='text-green hover:text-green underline'
                      >
                        Terms of Service
                      </a>
                      .
                    </p>
                  </div>
                  <PrimaryButton
                    label='Register'
                    onClick={this.handleSubmit}
                    loading={isButtonLoading}
                    size='full'
                  />
                </form>
              ) : (
                <p className='text-success text-center font-bold'>
                  We have sent you an email to verify your account
                </p>
              )}
              {showResendVerificationEmailLink && (
                <ButtonWithLoader
                  className='uk-button uk-button-link text-emphasis uk-align-center'
                  onClick={this.handleResendVerificationEmail}
                  isLoading={isResendButtonLoading}
                >
                  Resend verification email
                </ButtonWithLoader>
              )}
              {showLinkToTroubleshootingVerificationIssues && (
                <a
                  href={`${settings.urls.www}/contact`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='uk-link-text'
                >
                  Troubleshooting Verification Issues
                </a>
              )}
            </div>
            <div className='m-4 text-center'>
              <Link
                data-test-id='have-account-btn'
                to='/login'
                className='text-green hover:text-green'
              >
                Already have account?
              </Link>
            </div>
          </div>
        </div>
        <GenericModal
          id='modal-resend-code-register'
          buttonType='uk-button-primary'
          ref={(ref) => {
            this.resendModal = ref
          }}
          title={
            this.state.errors.resendEmail ? 'Error' : 'Confirmation code resend'
          }
          body={
            <>
              <p>
                {this.state.errors.resendEmail ||
                  this.state.success.resendEmail}
              </p>
            </>
          }
          closeText='Ok'
        />
      </div>
    )
  }
}

Register.propTypes = propTypes
Register.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  // auth
  isAuthenticated: state.auth.status === 'LOGGED_IN',
})

export default withLDConsumer()(
  compose(
    connect(mapStateToProps, {
      logout,
    })
  )(Register)
)
