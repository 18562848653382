// This module will provide a configuration object after following these steps:
//
// 1) Load the properties in .env to the `process.env` object. See
//    https://www.npmjs.com/package/dotenv for more info.
// 2) Load configuration from file based on the NODE_ENV environment variable.
//
// You will have to specify what configuration properties to overwrite from the
// environment in the configuration files. When this is all done the module will
// return a configuration object.

import { IS_DEV_MODE } from '/app/constants/env'

const _ = require('lodash')

if (IS_DEV_MODE) {
  // eslint-disable-next-line no-console
  console.log('START DEV WITH ENV', {
    NODE_ENV: process.env.NODE_ENV,
    REACT_APP_ENV: process.env.REACT_APP_ENV,
    CONFIG_FILE: process.env.CONFIG_FILE,
  })
}
// load configuration from files
const env = process.env.CONFIG_FILE || 'local'
const config = require(`../config/${env}`)

// Contants used throughout application
const {
  engines,
  languages,
  frameworks,
  themes,
  apps,
  engineIcons,
  integrations,
  integrationLogos,
  activeIntegrations,
  integrationsLms,
} = require('./constants')

const devEnv = require('./dev-env')

// General settings that apply to all environments (but can be overridden).
const generalSettings = {
  env,
  engines,
  engineIcons,
  frameworks,
  languages,
  themes,
  integrations,
  integrationLogos,
  apps,
  activeIntegrations,
  integrationsLms,
}

const settings = _.merge(
  generalSettings,
  _.omitBy(config, _.isFunction),
  devEnv
)

// export the configuration object
export default settings
