import React from 'react'
import ConfirmButton from 'app/views/components/ConfirmButton'
import PopUpMenu from 'app/views/components/PopUpMenu'
import Icon from 'app/views/components/Icon'
import PrimaryButton from 'app/views/components/Buttons/PrimaryButton'

const TeamTab = (props) => (
  <div className='uk-align-center uk-width-1-2@m'>
    {props.selectedUser.teams.length === 0 ? (
      <div className='text-center'>
        <p>User does not belong to any teams.</p>
        <PrimaryButton
          size='small'
          label='Change Teams'
          onClick={() => {
            if (typeof props.setSelectedUser === 'function') {
              props.setSelectedUser(props.organization.uuid, props.selectedUser)
            }
            if (typeof props.cleanUserDetailModal === 'function') {
              props.cleanUserDetailModal()
            }
            if (typeof props.onEditTeams === 'function') {
              props.onEditTeams()
            }
          }}
        />
      </div>
    ) : (
      <>
        {props.onEditTeams && (
          <PrimaryButton
            size='small'
            label='Change Teams'
            onClick={() => {
              if (typeof props.setSelectedUser === 'function') {
                props.setSelectedUser(
                  props.organization.uuid,
                  props.selectedUser
                )
              }
              if (typeof props.cleanUserDetailModal === 'function') {
                props.cleanUserDetailModal()
              }
              if (typeof props.onEditTeams === 'function') {
                props.onEditTeams()
              }
            }}
          />
        )}
        <table className='_teams-hacker-table uk-table uk-table-small uk-table-divider'>
          <thead>
            <tr>
              <th className='uk-width-1-4'>TEAM NAME</th>
              <th className='uk-width-1-4'>ROLE</th>
              <th className='uk-width-1-4'>INVITE PRIVILEGES</th>
              <th className='uk-width-1-4' />
            </tr>
          </thead>
          <tbody>
            {props.selectedUser.teams.map((team) => (
              <tr key={`row-${team.team_uuid}`}>
                <td>{team.name}</td>
                <td>
                  {props.selectedUser.role === 'admin'
                    ? 'Admin'
                    : team.team_role === 'member'
                    ? 'Member'
                    : 'Team Admin'}
                </td>
                <td>
                  <Icon
                    className={`${
                      (team.invite_privileges &&
                        team.team_role === 'team_admin') ||
                      props.selectedUser.role === 'admin'
                        ? 'text-success'
                        : 'text-danger'
                    }`}
                    name={`${
                      (team.invite_privileges &&
                        team.team_role === 'team_admin') ||
                      props.selectedUser.role === 'admin'
                        ? 'check'
                        : 'close'
                    }`}
                  />
                </td>
                <td>
                  {(props.selectedUser.role !== 'admin' ||
                    props.organization.role === 'admin') && (
                    <PopUpMenu
                      id='pop-up-change-role-team-tab'
                      customPosition='left-bottom'
                      style={{
                        width: 450,
                      }}
                      customRender={() => {
                        return (
                          <>
                            <h4>
                              {`${
                                props.selectedUser.role === 'admin'
                                  ? 'Role:'
                                  : 'Team Role:'
                              }`}
                              {props.selectedUser.role === 'admin' ||
                              team.team_role === 'admin' ? (
                                <b> Admin</b>
                              ) : (
                                <b> Member</b>
                              )}
                            </h4>

                            {props.selectedUser.role === 'admin' && (
                              <p className='italic'>
                                This user is an Organization Admin so their role
                                cannot be changed at the team level.
                              </p>
                            )}
                            {props.selectedUser.role !== 'admin' && (
                              <>
                                <ConfirmButton
                                  isSmall
                                  text={
                                    <>
                                      Change Role to
                                      {team.team_role === 'team_admin'
                                        ? ' Team Member'
                                        : ' Team Admin'}
                                    </>
                                  }
                                  confirmClick={() => {
                                    props.updateTeamPrivileges(
                                      props.match.params.organization_uuid,
                                      team.team_uuid,
                                      props.selectedUser.uuid,
                                      {
                                        team_admin: !(
                                          team.team_role === 'team_admin'
                                        ),
                                      },
                                      true,
                                      () => props.refreshUserDetail()
                                    )
                                  }}
                                />
                                <hr />
                              </>
                            )}
                            {team.team_role === 'team_admin' &&
                              props.selectedUser.role !== 'admin' && (
                                <>
                                  <form className='uk-form-stacked'>
                                    <div className='uk-margin'>
                                      <div className='uk-form-controls'>
                                        <label
                                          style={{
                                            lineHeight: 2,
                                          }}
                                        >
                                          <input
                                            disabled={
                                              props.selectedUser.role ===
                                                'admin' &&
                                              props.organization.role !==
                                                'admin'
                                            }
                                            className='uk-radio'
                                            type='radio'
                                            name='radio1'
                                            checked={
                                              team.invite_privileges === true
                                            }
                                            onChange={() => {
                                              props.updateTeamPrivileges(
                                                props.match.params
                                                  .organization_uuid,
                                                team.team_uuid,
                                                props.selectedUser.uuid,
                                                {
                                                  can_invite_users: true,
                                                },
                                                true,
                                                () => props.refreshUserDetail()
                                              )
                                            }}
                                          />
                                          &nbsp;&nbsp;Can invite users
                                        </label>
                                        <br />
                                        <label
                                          style={{
                                            lineHeight: 2,
                                          }}
                                        >
                                          <input
                                            disabled={
                                              props.selectedUser.role ===
                                                'admin' &&
                                              props.organization.role !==
                                                'admin'
                                            }
                                            className='uk-radio'
                                            type='radio'
                                            name='radio1'
                                            checked={
                                              team.invite_privileges === false
                                            }
                                            onChange={() => {
                                              props.updateTeamPrivileges(
                                                props.match.params
                                                  .organization_uuid,
                                                team.team_uuid,
                                                props.selectedUser.uuid,
                                                {
                                                  can_invite_users: false,
                                                },
                                                true,
                                                () => props.refreshUserDetail()
                                              )
                                            }}
                                          />
                                          &nbsp;&nbsp;Restrict invite privileges
                                        </label>
                                      </div>
                                    </div>
                                  </form>
                                  <hr />
                                </>
                              )}
                            <ConfirmButton
                              isSmall
                              isDanger
                              text={
                                <>
                                  <Icon
                                    name='delete-bin'
                                    style={{
                                      position: 'absolute',
                                      left: 10,
                                    }}
                                    ratio={0.8}
                                  />
                                  Remove from Team
                                </>
                              }
                              confirmClick={() => {
                                props.onRemoveFromTeam(
                                  team.team_uuid,
                                  props.selectedUser.uuid
                                )
                              }}
                            />
                          </>
                        )
                      }}
                      isTextTrigger
                      text='Edit'
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )}
    {props.selectedUser.teams.length >= 1 && (
      <>
        <br />
        <br />
      </>
    )}
  </div>
)

export default TeamTab
