import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Moment from 'react-moment'

// Components
import ContentButton from 'app/views/components/ContentButton'
import ContentIcon from 'app/views/components/ContentIcon'
import ScratchpadButton from 'app/views/components/ScratchpadButton'
import AllTrainingMenu from 'app/views/components/AllTrainingMenu'
import ModalContentIcon from 'app/views/components/Modals/ModalContentIcon'

// Redux
import { getContent } from 'app/state/modules/content'

// Utils
import analytics from 'app/views/utils/analytics'

const propTypes = {
  getContent: PropTypes.func.isRequired,
  license: PropTypes.bool,
}
const defaultTypes = {
  license: false,
}

class Challenges extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
    }
  }

  componentDidMount() {
    // Dashboard tour
    /*
    if (localStorage.getItem('hackedu.tour.dashboard') !== 'true') {
      localStorage.setItem('hackedu.tour.dashboard', 'true');
      UIkit.modal('#modal-dashboard-tour').show();
    }
    */

    /*
    if (localStorage.getItem('hackedu.hasSignedTermsOfService') !== 'true') {
      UIkit.modal('#modal-terms-of-service').show()
    }
    */

    this.props.getContent()

    analytics.page('all')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.showAllTraining === false &&
      nextProps.organizations.some((item) => item.role === 'admin') !== true
    ) {
      this.props.history.push('/')
    }
  }

  render() {
    return (
      <div className='uk-hackedu-container-main'>
        <div className='uk-container'>
          <div className='uk-margin-medium-top'>
            <div data-uk-grid=''>
              <div className='uk-width-large'>
                <div>
                  <div
                    data-uk-sticky='offset: 150'
                    className='hackedu-content-menu'
                  >
                    <AllTrainingMenu />
                  </div>
                </div>
              </div>
              <div className='uk-width-expand'>
                <div className='uk-width-expand uk-section-small'>
                  <h1>Challenges</h1>
                  <hr />
                  {this.props.content.challenges.map((challenge, i) => {
                    const hasCompletedContent = !!challenge.completed_at
                    return (
                      <div key={challenge.uuid}>
                        <div className='uk-panel'>
                          <div data-uk-grid=''>
                            <div className='uk-width-3-4'>
                              <div className='uk-inline uk-margin-small-top'>
                                <div className='flex items-center'>
                                  <ContentIcon
                                    isActiveForUser
                                    hasCompletedContent={hasCompletedContent}
                                    lessonInProgress={false}
                                    marginTop={0}
                                  />
                                  <h4 className='uk-inline uk-margin-remove'>
                                    <a
                                      href={`/challenge/${challenge.uuid}`}
                                      className='uk-link-reset'
                                    >
                                      {challenge.title}
                                    </a>
                                    <span className='uk-label uk-margin-left'>
                                      {challenge.points} points
                                    </span>
                                  </h4>
                                </div>
                                <p>{challenge.description}</p>
                                {hasCompletedContent && (
                                  <div className='italic text-sm'>
                                    Last completed on{' '}
                                    <Moment format='MMMM D, YYYY'>
                                      {challenge.completed_at}
                                    </Moment>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='uk-width-1-4 text-right uk-margin-top'>
                              <ContentButton
                                isActiveForUser
                                hasCompletedContent={hasCompletedContent}
                                lessonInProgress={false}
                                startText='Try Challenge'
                                restartText='Try Again'
                                url={`/challenge/${challenge.uuid}`}
                              />
                            </div>
                          </div>
                        </div>
                        {i !== this.props.content.challenges.length - 1 && (
                          <hr />
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalContentIcon />
        <ScratchpadButton id='challenges' />
      </div>
    )
  }
}

Challenges.propTypes = propTypes
Challenges.defaultTypes = defaultTypes

const mapStateToProps = (state) => ({
  // auth
  email: state.auth.user.email,

  // content
  content: state.content.content,
  loadingContent: state.content.loadingContent,
  loadingVulnerabilityTypes: state.content.loadingVulnerabilityTypes,
  vulnerabilityTypes: state.content.vulnerabilityTypes,

  // hacker
  organizations: state.hacker.organizationsList,
  license: state.hacker.license,
  mustPassCodingExercise: state.hacker.mustPassCodingExercise,
  showAllTraining: state.hacker.showAllTraining,
  showCodingChallenges: state.hacker.showCodingChallenges,
})
const mapDispatchToProps = (dispatch) => ({
  getContent: () => {
    dispatch(getContent())
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Challenges)
)
