import settings from 'settings'
import { resolveToken } from '../../api'

import { type TournamentDetails } from 'app/sjTournament'
import { type CodeSubmission, parseFetchSubmissionsResponse } from './types'
import { isRealTimeResult } from './util'

/**
 * Hits svc-code-sub to fetch a list of the authenticated user's N most recent code
 * submissions for the given lesson. opts.limit may be supplied to explicitly bound N.
 * opts.since may be supplied to restrict the search to submissions whose createdAt
 * timestamp is greater than the given Date.
 */
export async function fetchSubmissions(lessonKey: string, opts?: { limit?: number, since?: Date }): Promise<CodeSubmission[]> {
  const token = await resolveToken()
  const url = new URL(`${settings['urls'].sj}/svc/code-sub/submissions`)
  url.searchParams.set('lessonId', lessonKey)
  if (opts && opts.limit) {
    url.searchParams.set('limit', String(opts.limit))
  }
  if (opts && opts.since) {
    url.searchParams.set('since', opts.since.toISOString())
  }
  const response = await fetch(url, { headers: { 'Authorization': token } })
  if (!response.ok) {
    throw parseError(response, 'submissions request')
  }
  const data = await response.json()
  return parseFetchSubmissionsResponse(data)
}

/**
 * Makes periodic API calls to svc-code-sub until at least one submission exists with a
 * createdAt timestamp newer than the provided submission, then returns that array of
 * submissions. If the abort signal is fired, halts and throws an AbortError.
 */
export async function pollForNewSubmissions(signal: AbortSignal, lessonKey: string, newerThanSubmission: CodeSubmission | null, lowerBound: Date, intervalsMs: number[]): Promise<CodeSubmission[]> {
  // Allow the caller to supply a series of intervals for each successive API call, to
  // give us a rudimentary backoff in case of longer-running submissions
  let intervalIndex = 0
  if (intervalsMs.length < 1) {
    throw new Error("caller must supply at least one interval")
  }

  // We'll continue to fetch submissions until we see one whose createdAt timestamp is
  // more recent than this value
  let newerThanTimestamp = lowerBound
  if (newerThanSubmission) {
    // The API's 'since' param is inclusive (i.e. it's gte, not gt); so add a
    // millisecond to ensure that we don't get back a previously-buffered submission
    newerThanTimestamp = new Date(newerThanSubmission.createdAt.getTime() + 1)
  }

  // Make periodic API calls until we have our desired submission results, or until the
  // caller fires the abort signal
  for (;;) {
    // Determine how long to wait on this iteration
    signal.throwIfAborted()
    const intervalMs = intervalsMs[intervalIndex]
    if (intervalIndex < intervalsMs.length - 1) {
      intervalIndex++
    }

    // Wait for the desired interval before making an API call
    await new Promise((resolve) => setTimeout(resolve, intervalMs));
    signal.throwIfAborted()

    // Hit the API to get the latest list of submissions created since our last-known
    // creation timestamp
    const since = newerThanTimestamp.getTime() > 0 ? newerThanTimestamp : undefined
    const rawSubmissions = await fetchSubmissions(lessonKey, { since })
    signal.throwIfAborted()

    // Ignore any submissions that are likely to be replays of old Kafka events etc; we
    // only want to consider submissions that are newly created and that have made it
    // through the Kafka -> svc-code-sub pipeline successfully from the get-go
    const submissions = rawSubmissions.filter(isRealTimeResult)
    if (submissions.length > 0) {
      return submissions
    }
  }
}

/**
 * Hits svc-sandbox to take a snapshot of the user's code, then submits that code for
 * testing. If everything works as intended, this will eventually result in a code
 * submission being published via svc-code-sub.
 *
 * In the meantime, the frontend must keep track of the fact that it's initiated a new
 * submission and is waiting for the results. The backend does not consider the code
 * submission to exist until tests are completed, and there's no way for the frontend to
 * check whether tests are pending.
 */
export async function createSubmission(lessonKey: string, lessonLoadedAt?: Date, tournamentDetails?: TournamentDetails): Promise<void> {
  const code = await fetchSandboxSnapshot()
  await submitSandboxSnapshot(code, lessonKey, lessonLoadedAt, tournamentDetails)
}

/**
 * Hits svc-sandbox to get a snapshot of the user-modified code on the user's assigned
 * sandbox.
 */
async function fetchSandboxSnapshot(): Promise<SandboxSnapshot> {
  // Hit GET /svc/sandbox/fs/snapshot to get a full code snapshot
  const token = await resolveToken()
  const url = `${settings['urls'].sj}/svc/sandbox/fs/snapshot`
  const response = await fetch(url, { headers: { 'Authorization': token } })
  if (!response.ok) {
    throw await parseError(response, 'sandbox snapshot request')
  }

  // We should get a response object with a 'code' array containing per-volume info
  const volumes = [] as VolumeSnapshot[]
  const data = await response.json()
  if (typeof data !== 'object' || !Array.isArray(data['volumes'])) {
    throw new Error("unexpected response format for fs snapshot: expected an object with a 'volumes' array")
  }
  for (const volumeData of data['volumes']) {
    volumes.push(parseCodeSnapshotVolume(volumeData))
  }
  return { volumes }
}

/**
 * Hits svc-sandbox to submit the given code snapshot for testing.
 */
async function submitSandboxSnapshot(code: SandboxSnapshot, lessonKey: string, lessonLoadedAt: Date, tournamentDetails?: TournamentDetails): Promise<void> {
  const token = await resolveToken()
  const url = `${settings['urls'].sj}/svc/sandbox/test`
  const req: TestRequest = {
    code,
    lesson: {
      id: {
        key: lessonKey,
        version: 1,
      },
      context: {
        session_start: lessonLoadedAt.toISOString(),
        tournament_uuid: tournamentDetails?.uuid,
        tenant_name: tournamentDetails?.tenant,
      },
    },
  }
  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(req),
  })
  if (!response.ok) {
    throw await parseError(response, 'sandbox test request')
  }
}

/** Validates a VolumeSnapshot received via /svc/sandbox/fs/snapshot. */
function parseCodeSnapshotVolume(data: unknown): VolumeSnapshot {
  if (typeof data !== 'object') {
    throw new Error('volume snapshot is not an object')
  }
  if (typeof data['volume'] !== 'string' || !data['volume']) {
    throw new Error("invalid volume snapshot: a non-empty 'volume' string is required")
  }
  if (typeof data['checksum'] !== 'string' || !data['checksum']) {
    throw new Error("invalid volume snapshot: a non-empty 'checksum' string is required")
  }
  if (typeof data['checksum_original'] !== 'string' || !data['checksum_original']) {
    throw new Error("invalid volume snapshot: a non-empty 'checksum_original' string is required")
  }
  if (typeof data['modified_files'] !== 'object') {
    throw new Error("invalid volume snapshot: a 'modified_files' object is required")
  }
  for (const [k, v] of Object.entries(data['modified_files'])) {
    if (typeof v !== 'string') {
      throw new Error(`invalid volume snapshot: expected string value for 'modified_files' entry with key '${k}'`)
    }
  }
  return data as VolumeSnapshot
}

/**
 * Result from /svc/sandbox/fs/snapshot, representing the user-modified code on a
 * sandbox.
 */
type SandboxSnapshot = {
  volumes: VolumeSnapshot[]
}

/**
 * Describes a single 'volume' within a snapshot. In practice a snapshot will only
 * contain a single volume - the active engine for repl (e.g. 'python', with a modified
 * 'external.py' file unless unchanged) or 'app' for sandboxes that use filetree.
 */
type VolumeSnapshot = {
  volume: string
  checksum: string
  checksum_original: string
  modified_files: { [key: string]: string }
}

/**
 * Request payload sent to /svc/sandbox/test in order to kick off testing of
 * user-submitted code.
 */
type TestRequest = {
  lesson: {
    id: {
      key: string
      version: number
    }
    context: {
      session_start: string
      tournament_uuid?: string
      tenant_name?: string
    }
  }
  code: SandboxSnapshot
}

/**
 * Given a non-OK HTTP response, builds a normalized Error object with a human-readable
 * message that describes the response, optionally including any JSON-encoded error
 * message in the response body.
 */
async function parseError(r: Response, desc: string): Promise<Error> {
  let errorMessage = ''
  try {
    const data = await r.json()
    errorMessage = data['message'] || ''
  } catch (_) {
  }
  const suffix = errorMessage ? `: ${errorMessage}` : ''
  const message = `got status ${r.status} from ${desc}${suffix}`
  return new Error(message)
}
