import React from 'react'
import { connect } from 'react-redux'
import AceEditor from 'react-ace'
import {
  addOperation,
  checkSyntax,
  resetLinter,
  initSyntaxCheck,
} from 'app/state/modules/linter'

import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-clojure'
import 'ace-builds/src-noconflict/mode-cobol'
import 'ace-builds/src-noconflict/mode-csharp'
import 'ace-builds/src-noconflict/mode-golang'
import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/mode-kotlin'
import 'ace-builds/src-noconflict/mode-perl'
import 'ace-builds/src-noconflict/mode-php_laravel_blade'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-ruby'
import 'ace-builds/src-noconflict/mode-html_ruby'
import 'ace-builds/src-noconflict/mode-jsx'
import 'ace-builds/src-noconflict/mode-tsx'
import 'ace-builds/src-noconflict/mode-razor'
import 'ace-builds/src-noconflict/mode-scala'
import 'ace-builds/src-noconflict/mode-swift'
import 'ace-builds/src-noconflict/mode-typescript'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-xml'
import 'ace-builds/src-noconflict/mode-css'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/mode-smarty'
import 'ace-builds/src-noconflict/mode-sh'
import 'ace-builds/src-noconflict/mode-markdown'
import 'ace-builds/src-noconflict/mode-scss'
import 'ace-builds/src-noconflict/mode-sass'
import ace from 'ace-builds/src-noconflict/ace'

ace.config.setModuleUrl(
  'ace/mode/javascript_worker',
  '/static/workers/worker-javascript.js'
)
ace.config.setModuleUrl('ace/mode/css_worker', '/static/workers/worker-css.js')
ace.config.setModuleUrl(
  'ace/mode/html_worker',
  '/static/workers/worker-html.js'
)
ace.config.setModuleUrl(
  'ace/mode/json_worker',
  '/static/workers/worker-json.js'
)
ace.config.setModuleUrl('ace/mode/php_worker', '/static/workers/worker-php.js')

/** Values for the AceEditor 'mode', indexed by file extension. */
const ACE_MODES = {
  bat: 'sh',
  c: 'c_cpp',
  clj: 'clojure',
  clojure: 'clojure',
  cob: 'cobol',
  cpp: 'c_cpp',
  cs: 'csharp',
  cshtml: 'razor',
  csproj: 'csharp',
  css: 'css',
  erb: 'html_ruby',
  gemfile: 'ruby',
  go: 'golang',
  gradle: 'java',
  gradlew: 'sh',
  html: 'html',
  java: 'java',
  kt: 'kotlin',
  js: 'javascript',
  json: 'json',
  jsx: 'jsx',
  map: 'javascript',
  md: 'markdown',
  mod: 'golang',
  php: 'php',
  pl: 'perl',
  plist: 'xml',
  props: 'csharp',
  py: 'python',
  rb: 'ruby',
  rs: 'rust',
  sass: 'sass',
  scala: 'scala',
  scss: 'scss',
  swift: 'swift',
  tmpl: 'smarty',
  ts: 'typescript',
  tsx: 'tsx',
  vue: 'javascript',
  xml: 'xml',
}

/** File extensions associated with every possible value for this component's 'engine' prop. */
const EXTENSIONS_BY_ENGINE = {
  c: 'c',
  clojure: 'clojure',
  cobol: 'cob',
  cpp: 'cpp',
  csharp: 'cs',
  dotnet: 'cs',
  go: 'go',
  golang: 'go',
  java: 'java',
  javascript: 'js',
  kotlin: 'kt',
  node: 'js',
  perl: 'pl',
  php: 'php',
  python: 'py',
  ruby: 'rb',
  rust: 'rs',
  scala: 'scala',
  swift: 'swift',
  typescript: 'ts',
}

/** File extensions for which we should use api-linter; should generally mirror api-linter/src/common/extension_config.py. */
const LINTER_SUPPORTED_EXTENSIONS = [
  'c',
  'cpp',
  'cs',
  'go',
  'java',
  'py',
  'rb',
  'scala',
]

const TEXTS_BACKGROUNDS = {
  selected: '#009844',
}

/** Resolves a (dotless) file extension given an engine name or filename. */
function getFileExtension(engine, fileName) {
  if (engine) {
    return EXTENSIONS_BY_ENGINE[engine]
  }
  if (typeof fileName === 'string') {
    const parts = fileName.split('.')
    return parts[parts.length - 1].toLowerCase()
  }
  return null
}

class CodingEditor extends React.Component {
  constructor(props) {
    super(props)
    const fontSize = window.innerWidth <= 1400 ? 12 : 13
    window.codeEditorLineHeight = fontSize === 12 ? 16 : 17
    this.state = {
      localCode: '',
      lastFileReceived: '',
      linterExtension: null, // File extension for which we last called initSyntaxCheck, or null
      fontSize,
    }
    this.ace = React.createRef()
    window.aceEditorReference = this.ace

    this.onChange = this.onChange.bind(this)
    this.setScrollTop = this.setScrollTop.bind(this)
    this.onChangeLinter = this.onChangeLinter.bind(this)
    this.onRefreshSessions = this.onRefreshSessions.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.sessionToJSON = this.sessionToJSON.bind(this)
    this.onWindowListener = this.onWindowListener.bind(this)
    this.loadSession = this.loadSession.bind(this)
    this.checkSavedSession = this.checkSavedSession.bind(this)
    this.onHighlightSelection = this.onHighlightSelection.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    const newState = state
    if (props.code !== state.lastFileReceived) {
      newState.localCode = props.code
      newState.lastFileReceived = props.code
    }
    return newState
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onWindowListener)
    document.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('mouseup', this.onHighlightSelection)
    window.codeReviewEditAreaScroll = this.setScrollTop

    this.reinitLinter()

    this.ace.current.editor.keyBinding.addKeyboardHandler({
      handleKeyboard: (data, hash, keyString, keyCode) => {
        if (hash === -1 || (keyCode <= 40 && keyCode >= 37)) {
          return false
        }
      },
    })
    if (!this.props.engine) {
      this.checkSavedSession()
    }
  }

  componentDidUpdate(prevProps) {
    const hasNewFilename =
      prevProps.fileName !== this.props.fileName &&
      typeof this.props.fileName === 'string'
    const hasFinishedPullingCode =
      prevProps.isPullingNewCode === true &&
      this.props.isPullingNewCode === false
    const forceReinit = hasNewFilename || hasFinishedPullingCode

    if (forceReinit && !this.props.engine) {
      this.checkSavedSession()
    }

    if (prevProps.annotations !== this.props.annotations) {
      this.ace.current.editor
        .getSession()
        .setAnnotations(this.props.annotations)
    }

    if (this.ace.current) {
      // If the layout of the app has changed, force a resize
      if (this.props.resizeAccumulator !== prevProps.resizeAccumulator) {
        const forceResize = true
        this.ace.current.editor.resize(forceResize)
      }

      // Lazily initialize the Ctrl-S hotkey binding
      const commands = this.ace.current.editor.commands
      const hasSaveCommand = !!commands.commands['hotkeySave']
      if (this.props.onHotkeySave && !hasSaveCommand) {
        commands.addCommand({
          name: 'hotkeySave',
          bindKey: { win: 'Ctrl-S', mac: 'Command-S' },
          exec: () => this.props.onHotkeySave(),
          readOnly: false,
        })
      } else if (!this.props.onHotkeySave && hasSaveCommand) {
        commands.removeCommand('hotkeySave')
      }
    }

    this.reinitLinter(forceReinit)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keydown', this.onWindowListener)
    window.removeEventListener('mouseup', this.onHighlightSelection)
  }

  reinitLinter(force) {
    const desiredLinterExtension = this.getDesiredLinterExtension()
    if (force || desiredLinterExtension != this.state.linterExtension) {
      if (desiredLinterExtension) {
        this.props.initSyntaxCheck(
          this.props.code,
          `.${desiredLinterExtension}`
        )
      } else {
        this.props.resetLinter()
      }
      this.setState({ linterExtension: desiredLinterExtension })
    }
  }

  /** Returns the file extension we should use for linting code via the linter, or null if external linting is not supported/required. */
  getDesiredLinterExtension() {
    // Only use api-linter if we actually have code loaded
    if (this.props.code !== '') {
      // ...and only if we're currently configured to use the language associated with that code
      const codeExtension = getFileExtension(this.props.codeEngine)
      if (
        codeExtension ===
        getFileExtension(this.props.engine, this.props.fileName)
      ) {
        // ...and only if that language is supported for backend linting
        if (LINTER_SUPPORTED_EXTENSIONS.indexOf(codeExtension) > -1) {
          return codeExtension
        }
      }
    }
    return null
  }

  onChangeLinter(event) {
    if (this.state.linterExtension) {
      const { doc } = this.ace.current.editor.getSession()
      const operation = {}
      operation.action = event.action
      operation.start = doc.positionToIndex(event.start, 0)
      operation.end = doc.positionToIndex(event.end, 0)
      operation.text = event.lines.join('\n')
      if (!this.props.isSendingRequest) {
        this.props.checkSyntax([operation])
      } else {
        this.props.addOperation(operation)
      }
    }
  }

  onRefreshSessions() {
    if (
      this.ace &&
      typeof this.props.fileName === 'string' &&
      !this.props.isLesson
    ) {
      const codingEditorSessions = JSON.parse(
        localStorage.getItem('codingEditorSessions') || '{}'
      )
      codingEditorSessions[this.props.fileName] = JSON.stringify(
        this.sessionToJSON(this.ace.current.editor.getSession())
      )
      localStorage.setItem(
        'codingEditorSessions',
        JSON.stringify(codingEditorSessions)
      )
    }
  }

  onWindowListener(e) {
    // console.log({
    //   path: e.path,
    //   code: e.code,
    //   key: e.key,
    // })
  }

  onKeyDown(e) {
    if (e.key === 'Escape') {
      if (typeof this.props.onCloseFullScreen === 'function') {
        this.props.onCloseFullScreen()
      }
    }

    this.onHighlightSelection()
  }

  onChange(code, event) {
    this.setState(
      {
        localCode: code,
      },
      () => {
        this.props.onChange(code)
        if (event) {
          this.onChangeLinter(event)
        }
        this.onRefreshSessions()
      }
    )
  }

  onScroll(editor) {
    if (!this.props.engine) {
      this.onRefreshSessions()
    }
    if (typeof this.props.onScroll === 'function') {
      this.props.onScroll(editor)
    }
  }

  onHighlightSelection() {
    setTimeout(() => {
      const element = document.querySelector(
        '.ace_selection.ace_start.ace_br15'
      )
      if (element) {
        element.style.background = TEXTS_BACKGROUNDS.selected
      }
    }, 150)
  }

  /** Returns the AceEditor mode that should be used for the configured engine and/or fileName. */
  getMode() {
    // Regardless of what we're doing with api-linter etc., allow the syntax highlighting mode of
    // the ace editor to be overridden by passing in a file extension
    let fileExtension = this.props.syntaxHighlightingOverrideFileExtension
    if (!fileExtension) {
      fileExtension = getFileExtension(this.props.engine, this.props.fileName)
    }
    return ACE_MODES[fileExtension] || 'text'
  }

  shouldEnableLinting() {
    // Most of the time our .html files are jinja templates; just ignore them
    return this.props.syntaxHighlightingOverrideFileExtension !== 'html'
  }

  setScrollTop(top) {
    this.ace.current.editor.session.setScrollTop(top)
  }

  checkSavedSession() {
    if (!this.props.isLesson) {
      const codingEditorSessions = JSON.parse(
        localStorage.getItem('codingEditorSessions') || '{}'
      )
      const sessionFile = codingEditorSessions[this.props.fileName]
      if (sessionFile) {
        this.loadSession(JSON.parse(sessionFile))
      }
    }
  }

  loadSession(session) {
    const currentSession = this.ace.current.editor.getSession()
    currentSession.setAnnotations(session.annotations)
    this.setState(
      {
        localCode: session.value,
      },
      () => {
        this.props.onChange(session.value)
      }
    )
    currentSession.setBreakpoints(session.breakpoints)
    currentSession.getUndoManager().reset()
    currentSession.getUndoManager().$undoStack = session.history.undo
    currentSession.getUndoManager().$redoStack = session.history.redo
    currentSession.setMode(session.mode)
    this.ace.current.editor.session.setScrollLeft(session.scrollLeft)
    this.ace.current.editor.session.setScrollTop(session.scrollTop)
    currentSession.selection.fromJSON(session.selection)
  }

  sessionToJSON(session) {
    return {
      annotations: session.getAnnotations(),
      breakpoints: session.getBreakpoints(),
      folds: session.getAllFolds().map((fold) => fold.range),
      history: {
        undo: session.getUndoManager().$undoStack,
        redo: session.getUndoManager().$redoStack,
      },
      mode: session.getMode().$id,
      scrollLeft: session.getScrollLeft(),
      scrollTop: session.getScrollTop(),
      selection: session.getSelection().toJSON(),
      value: session.getValue(),
    }
  }

  render() {
    return (
      <AceEditor
        markers={this.props.markers || this.props.markersDefault}
        onScroll={this.onScroll}
        ref={this.ace}
        showPrintMargin={false}
        height={this.props.height}
        width='100%'
        placeholder=''
        mode={this.getMode()}
        theme='monokai'
        name={this.props.id || 'editor'}
        onLoad={this.props.onLoad}
        onChange={this.onChange}
        fontSize={this.state.fontSize}
        showGutter
        wrapEnabled
        highlightActiveLine
        enableBasicAutocompletion
        enableLiveAutocompletion
        readOnly={this.props.readOnly}
        value={this.state.localCode}
        setOptions={{
          showLineNumbers: true,
          tabSize: 4,
          useWorker: this.shouldEnableLinting(),
        }}
        editorProps={{ $blockScrolling: Infinity }}
      />
    )
  }
}

const mapStateToProps = ({ linter, codeReview, repl }) => ({
  annotations: linter.annotations,
  isSendingRequest: linter.isSendingRequest,
  sandboxUUID: codeReview.sandboxUUID,
  isPullingNewCode: repl.isPullingNewCode,
  markersDefault: codeReview.markers,
})

export default connect(
  mapStateToProps,
  {
    addOperation,
    checkSyntax,
    initSyntaxCheck,
    resetLinter,
  },
  null,
  {
    forwardRef: true,
  }
)(CodingEditor)
