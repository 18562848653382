import React from 'react'
import PropTypes from 'prop-types'

import CountReadout from './CountReadout'

import './style.less'

export function formatMonthKey(monthKey) {
  const [yearStr, monthStr] = monthKey.split('-')
  const monthIndex = parseInt(monthStr) - 1
  const date = new Date(parseInt(yearStr), monthIndex)
  const monthName = date.toLocaleString(undefined, { month: 'long' })
  return `${monthName} ${date.getFullYear()}`
}

const HeaderRow = () => (
  <tr>
    <th>Month</th>
    <th>Vulnerabilities Found</th>
    <th>Lessons Completed</th>
  </tr>
)

const TableRow = ({
  month,
  vulnerabilityCount,
  vulnerabilityMax,
  completionCount,
  completionMax,
}) => (
  <tr>
    <th>{formatMonthKey(month)}</th>
    <td>
      <CountReadout
        count={vulnerabilityCount}
        maxCount={vulnerabilityMax}
        includeMaxInLabel={false}
        className='vulnerability-bar'
      />
    </td>
    <td>
      <CountReadout
        count={completionCount}
        maxCount={completionMax}
        includeMaxInLabel={true}
        className='completion-bar'
        altText={`The maximum of ${completionMax} possible lesson completions represents the total number of lessons offered in the selected category, multiplied by the number of licensed users in your organization.`}
      />
    </td>
  </tr>
)

function Table(props) {
  const {
    isLoading,
    vulnerabilityCategories,
    excludedCategoryIds,
    maxCompletionsByCategoryId,
    data,
  } = props

  const getSumOfIncludedCategories = (countsByCategoryId) =>
    Object.entries(countsByCategoryId).reduce(
      (acc, [categoryIdStr, count]) =>
        acc +
        (excludedCategoryIds.includes(parseInt(categoryIdStr)) ? 0 : count),
      0
    )

  // To get the max value for number of vulnerabilities in any given month, simply use
  // the value from whichever month in this dataset had the most vulnerabilities
  const vulnerabilityMax = data.reduce(
    (acc, x) =>
      Math.max(acc, getSumOfIncludedCategories(x.vulnerabilityCounts)),
    0
  )

  // To put an upper bound on number of lessons completed: get the total number of
  // lesson completions (for the currently-selected categories) that could ever be
  // registered for this org, then divide by the number of months included in our
  // selected date range
  const maxPossibleCompletions = getSumOfIncludedCategories(
    maxCompletionsByCategoryId
  )
  return (
    <table className='uk-table uk-table-small impact-report-table'>
      <thead>
        <HeaderRow />
      </thead>
      <tbody>
        {data.map((monthData, monthIndex) => (
          <TableRow
            key={monthData.month}
            month={monthData.month}
            vulnerabilityCount={getSumOfIncludedCategories(
              monthData.vulnerabilityCounts
            )}
            vulnerabilityMax={vulnerabilityMax}
            completionCount={getSumOfIncludedCategories(
              monthData.cumulativeCompletionCounts
            )}
            completionMax={maxPossibleCompletions}
          />
        ))}
      </tbody>
    </table>
  )
}
Table.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  vulnerabilityCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  excludedCategoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  maxCompletionsByCategoryId: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string.isRequired,
      vulnerabilityCounts: PropTypes.object.isRequired,
      cumulativeCompletionCounts: PropTypes.object.isRequired,
    })
  ).isRequired,
}

export default Table
