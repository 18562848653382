import 'core-js'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import Icon from 'app/views/components/Icon'

import settings from 'settings'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

// Core UIKIT  - Includes css and javascript
import 'uikit'

// Style Overrides
import 'static/less/theme.less'
import 'static/less/hackedu.less'
import 'static/less/hackedu-uikit.less'
import 'static/less/new_theme.less'
import 'remixicon/fonts/remixicon.css'

import './tailwind.css'

// Config
import store, { history } from 'app/state/store'

// Containers
import App from 'app/views/containers/App'

function browserSupportsCookies() {
  // If cookies are disabled at the browser level, abort
  if (!navigator.cookieEnabled) {
    return false
  }

  try {
    // Try setting a cookie and verifying that it took effect
    const testCookieName = 'cookietest'
    const testCookieValue = `${testCookieName}=1; SameSite=None; Secure;`
    document.cookie = testCookieValue
    const cookieWasWritten = document.cookie.indexOf(`${testCookieName}=`) >= 0

    // Remove the cookie by updating it with an expiration date that's already passed
    document.cookie = `${testCookieValue} expires=Thu, 01-Jan-1970 00:00:01 GMT`
    return cookieWasWritten
  } catch (err) {
    console.error('error testing cookie support', err)
    return false
  }
}

function browserSupportsLocalStorage() {
  try {
    window.localStorage.setItem('hackedu.check.ls', 'true')
    window.localStorage.removeItem('hackedu.check.ls')
    return true
  } catch (err) {
    console.error('error testing localStorage support', err)
    return false
  }
}

function Application() {
  let missingFeatures = []
  if (!browserSupportsCookies()) {
    missingFeatures.push('Cookies')
  }
  if (!browserSupportsLocalStorage()) {
    missingFeatures.push('Local Storage')
  }

  if (missingFeatures.length > 0) {
    const desc = missingFeatures.join(' and ')
    const noun = missingFeatures.length > 1 ? 'these features' : 'this feature'
    return (
      <div
        className='uk-background-primary uk-width-1-1 uk-position-absolute uk-height-1-1 flex items-center justify-center flex-col'
        style={{
          opacity: 0.9,
          color: '#FFFFFF',
        }}
      >
        <Icon name='error-warning' ratio={3.5} />
        <br />
        <p className='text-2xl uk-width-xlarge text-center'>
          It looks like you have {desc} disabled. Please configure your browser
          to allow {noun} for app.hackedu.com and refresh the page.
        </p>
      </div>
    )
  }

  var ops_env = 'local'
  if (process.env.GIT_BRANCH) {
    if (process.env.GIT_BRANCH === 'master') {
      ops_env = 'production'
    }
    if (process.env.GIT_BRANCH === 'development') {
      ops_env = 'development'
    }
  }

  datadogRum.init({
    applicationId: '148070df-6cee-4287-966c-a2a9f4a01c66', // not a secret
    clientToken: 'pub7ca333ea8fe9908876398f1eee39e644', // not a secret
    site: 'datadoghq.com',
    service: 'he_frontend',
    env: ops_env,
    version: process.env.RELEASE_NAME || 'local',
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [
      /https:\/\/my\.securityjourney\.(?:com|dev)/,
      // enable once plaform operator allows DD headers for cors
      // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum#how-are-rum-resources-linked-to-traces
      //  /https:\/\/app\.hackedu\.(?:com|dev)/,
    ],
  })

  datadogRum.startSessionReplayRecording()

  datadogLogs.init({
    clientToken: 'pub7ca333ea8fe9908876398f1eee39e644', // not a secret as it must be comiled into the app
    site: 'datadoghq.com',
    service: 'he_frontend',
    env: ops_env,
    version: process.env.RELEASE_NAME || 'local',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
  })

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  )
}

const AppWithLDFLags = withLDProvider({
  clientSideID: settings.launchDarkly.clientSideId,
  options: {
    bootstrap: 'localStorage',
  },
})(Application)

ReactDOM.render(<AppWithLDFLags />, document.getElementById('app'))
