import UIKit from 'uikit'
import { addCode } from './hacker'
import { isK8sEnabled } from './k8s'

const REPL_RESET = 'REPL_RESET'
const REPL_SET_CODE = 'REPL_SET_CODE'
const REPL_SET_LOCAL_CODE = 'REPL_SET_LOCAL_CODE'
const REPL_SET_ENGINE = 'REPL_SET_ENGINE'
const REPL_SET_ENGINES = 'REPL_SET_ENGINES'
const REPL_SET_OUTPUT = 'REPL_SET_OUTPUT'
const REPL_ON_ERROR = 'REPL_ON_ERROR'
const REPL_ON_PATCH = 'REPL_ON_PATCH'
const REPL_ON_VIEW_ERROR = 'REPL_ON_VIEW_ERROR'
const REPL_SET_IS_PULLING_NEW_CODE = 'REPL_SET_IS_PULLING_NEW_CODE'
const REPL_SET_FIRST_TIME_PULL = 'REPL_SET_FIRST_TIME_PULL'
const REPL_SET_RUNNING_TESTS = 'REPL_SET_RUNNING_TESTS'
export const REPL_SET_SUBMITTING_PATCH = 'REPL_SET_SUBMITTING_PATCH'

const initialState = {
  code: null,
  codeEngine: null,
  localCode: null,
  engine: '',
  engines: [],
  output: '',
  patchSubmitted: false,
  errorViewed: true,
  isPullingNewCode: false,
  firstTimePull: false,
  runningTests: false,
  submittingPatch: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REPL_SET_RUNNING_TESTS:
      return {
        ...state,
        runningTests: action.runningTests,
      }
    case REPL_SET_FIRST_TIME_PULL:
      return {
        ...state,
        firstTimePull: action.firstTimePull,
      }
    case REPL_SET_IS_PULLING_NEW_CODE:
      return {
        ...state,
        isPullingNewCode: action.isPullingNewCode,
      }
    case REPL_RESET:
      return {
        ...state,
        code: null,
        localCode: null,
        engine: '',
        engines: [],
        output: '',
        patchSubmitted: false,
        errorViewed: true,
        firstTimePull: false,
      }
    case REPL_SET_LOCAL_CODE:
      return {
        ...state,
        localCode: action.localCode,
      }
    case REPL_SET_CODE:
      return {
        ...state,
        code: action.code,
        codeEngine:
          action.codeEngine !== undefined ? action.codeEngine : state.engine,
        localCode: action.code,
      }
    case REPL_SET_ENGINE:
      return {
        ...state,
        engine: action.engine,
        // output: '',
      }
    case REPL_SET_ENGINES:
      return {
        ...state,
        engine: action.engine,
        engines: action.engines,
        // code: null,
        // localCode: null,
        // output: '',
      }
    case REPL_SET_OUTPUT:
      return {
        ...state,
        output: action.output,
      }
    case REPL_ON_ERROR:
      return {
        ...state,
        errorViewed: false,
      }
    case REPL_ON_PATCH:
      return {
        ...state,
        patchSubmitted: true,
      }
    case REPL_ON_VIEW_ERROR:
      return {
        ...state,
        errorViewed: true,
      }
    case REPL_SET_SUBMITTING_PATCH:
      const { submittingPatch } = action
      return {
        ...state,
        submittingPatch,
      }
    default:
      return state
  }
}

export function setReplIsPullingCode(isPullingNewCode) {
  return {
    type: REPL_SET_IS_PULLING_NEW_CODE,
    isPullingNewCode,
  }
}

export function replSetCode(code, codeEngine) {
  return {
    type: REPL_SET_CODE,
    code,
    codeEngine,
  }
}

export function setFirstTimePull(firstTimePull) {
  return {
    type: REPL_SET_FIRST_TIME_PULL,
    firstTimePull,
  }
}

export function replSetLocalCode(localCode) {
  return {
    type: REPL_SET_LOCAL_CODE,
    localCode,
  }
}

export const replSetDefaultEngineLS = (engine) => {
  localStorage.setItem('hackedu.lastLessonLanguage', engine)
}

export const replGetDefaultEngineLS = () => {
  const defaultEngine = localStorage.getItem('hackedu.lastLessonLanguage')
  return defaultEngine
}

export const replSetEngine = (engine) => (dispatch, getState) => {
  const state = getState()
  const isK8s = isK8sEnabled(state)
  if (!isK8s) {
    replSetDefaultEngineLS(engine)
  }
  dispatch({
    type: REPL_SET_ENGINE,
    engine,
  })
}

export function replSetDefaultEngine() {
  return (dispatch, getState) => {
    const state = getState()
    const { engines } = state.repl
    const defaultEngine = replGetDefaultEngineLS()
    if (engines.includes(defaultEngine)) {
      dispatch(replSetEngine(defaultEngine))
    } else if (engines.includes('python')) {
      dispatch(replSetEngine('python'))
    } else {
      dispatch(replSetEngine(engines[0]))
    }
  }
}

export function replSetEngines(engines) {
  engines.sort()
  const engine = 'python'
  return {
    type: REPL_SET_ENGINES,
    engine,
    engines,
  }
}

export function replReset() {
  return {
    type: REPL_RESET,
  }
}

export function replSetOutput(output) {
  let originalOutput = output
  if (originalOutput == null) {
    originalOutput = ''
  }
  // eslint-disable-next-line no-console
  console.log(originalOutput) // print full code output

  let cleanOutput = output
  const ignoredOutputs = [
    'Note: /opt/repl/engines/java/src/main/java/demo/Adapter.java uses unchecked or unsafe operations.',
    'Note: /opt/repl/engines/java/src/main/java/demo/External.java uses or overrides a deprecated API.',
    'msbuild /p:Configuration=Release',
    'Note: Some input files use unchecked or unsafe operations.',
    'OpenJDK 64-Bit Server VM warning: Options -Xverify:none and -noverify were deprecated in JDK 13 and will likely be removed in a future release.',
    'Compiling demo.adapter',
    'Compiling demo.external',
    'Compiling index',
    'Created /opt/repl/engines/clojure/target/uberjar/demo-0.1.0-SNAPSHOT.jar',
    'Created /opt/repl/engines/clojure/target/uberjar/demo-0.1.0-SNAPSHOT-standalone.jar',
  ]
  ignoredOutputs.forEach((ignoredOutput) => {
    if (
      originalOutput.startsWith(ignoredOutput) &&
      !originalOutput.includes('Build FAILED.')
    ) {
      cleanOutput = ''
    }
  })
  return {
    type: REPL_SET_OUTPUT,
    output: cleanOutput,
  }
}

export function replOnError() {
  return {
    type: REPL_ON_ERROR,
  }
}

export function replOnPatch(
  contentId,
  engine,
  code,
  hasTest,
  isNative = false
) {
  return async (dispatch, getState) => {
    dispatch({
      type: REPL_SET_SUBMITTING_PATCH,
      submittingPatch: true,
    })
    const state = getState()
    if (hasTest && state.auth.status === 'LOGGED_IN' && contentId !== null) {
      await dispatch(
        addCode(
          contentId,
          engine,
          code,
          typeof isNative === 'function' ? isNative : false,
          () => {
            dispatch({
              type: REPL_SET_SUBMITTING_PATCH,
              submittingPatch: false,
            })
          }
        )
      )
    } else if (!hasTest && isNative) {
      await dispatch(
        addCode(
          contentId,
          engine,
          code,
          isNative,
          () => {
            dispatch({
              type: REPL_SET_SUBMITTING_PATCH,
              submittingPatch: false,
            })
          },
          true
        )
      )
    }
    return dispatch({
      type: REPL_ON_PATCH,
    })
  }
}

export function replOnViewError() {
  return {
    type: REPL_ON_VIEW_ERROR,
  }
}

export function setRunningTests(runningTests) {
  return {
    type: REPL_SET_RUNNING_TESTS,
    runningTests,
  }
}
