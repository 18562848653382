import React from 'react'
import settings from 'settings'

import { Icon } from 'app/views/components/GustavoIcon'

function EngineDropdown({
  onClickDefault,
  defaultEngine,
  onChangeEngine,
  engine,
  engines,
  noDefaultEngine,
}) {
  return (
    <div id='tour-language-selector'>
      <div
        data-uk-form-custom='target: > * > span:first-child'
        style={{ width: '100%' }}
      >
        <select
          name='select'
          value={engine}
          onChange={onChangeEngine}
          data-test-id='tour-language-selector'
          className='uk-width-1-1'
          style={{
            borderRadius: 10,
          }}
        >
          {engines.map((engine) => (
            <option key={engine} value={engine}>
              {settings.engines[engine]}
            </option>
          ))}
        </select>
        <button
          className='uk-button uk-button-default uk-button-small uk-padding-small uk-padding-remove-vertical flex items-center uk-width-1-1 uk-position-relative'
          type='button'
          tabIndex='-1'
          style={{
            borderRadius: 8,
          }}
        >
          <span />
          <Icon
            style={{
              position: 'absolute',
              right: 10,
            }}
            name='arrow-down-s'
          />
        </button>
      </div>
      {!noDefaultEngine && engine !== defaultEngine && (
        <span
          className='text-emphasis text-sm'
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            onClickDefault()
          }}
        >
          Set as default?
        </span>
      )}
    </div>
  )
}
export default EngineDropdown
