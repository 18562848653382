export function compareMonths(lhs, rhs) {
  if (lhs.year === rhs.year) {
    if (lhs.month === rhs.month) return 0
    return lhs.month < rhs.month ? -1 : 1
  }
  return lhs.year < rhs.year ? -1 : 1
}

/**
 * Given a list of issue sources and a function that updates the list of selected
 * titles, returns two functions that establish an interface for a single-select filter.
 * getSelectedIssueSourceTitle returns the title of the currently selected issue source,
 * if one is selected; or 'all' if no filter is active. setSelectedIssueSourceTitle
 * accepts either 'all' or a single title and updates the underlying state accordingly.
 */
export function useIssueSourceFilter(
  issueSources,
  onSelectedIssueSourceTitlesChange
) {
  const getSelectedIssueSourceTitle = () => {
    // Underlying redux state allows filtering on arbitrary combinations of issue
    // sources, but the UI condenses that down to either 1 source or all of them
    const numSources = issueSources.length
    const numSelectedSources = issueSources.reduce(
      (acc, x) => (x.isSelected ? acc + 1 : acc),
      0
    )
    if (numSources === 1) {
      return issueSources[0].title
    }
    if (numSelectedSources === numSources) {
      return 'all'
    }
    for (const issueSource of issueSources) {
      if (issueSource.isSelected) {
        return issueSource.title
      }
    }
    return null
  }
  const setSelectedIssueSourceTitle = (newTitle) => {
    // Select no individual issue sources if we want to see them all; otherwise flag
    // only our single desired source as selected
    if (newTitle === 'all') {
      onSelectedIssueSourceTitlesChange([])
    } else {
      onSelectedIssueSourceTitlesChange([newTitle])
    }
  }
  return [getSelectedIssueSourceTitle, setSelectedIssueSourceTitle]
}

/**
 * Given vulnerability category metadata, the list of category IDs excluded from view,
 * and a function that updates that list, returns two functions that establish an
 * interface for a single-select filter. getSelectedCategoryId returns either 'all' or
 * the string category ID, depending on whether the view is filtered to a single
 * category. setSelectedCategoryId accepts 'all' or a category ID and updates the
 * underlying state accordingly.
 */
export function useCategoryFilter(
  vulnerabilityCategories,
  excludedCategoryIds,
  onExcludedCategoryIdsChange
) {
  const getSelectedCategoryId = () => {
    // Underlying redux state allows filtering on arbitrary combinations of categories,
    // but the UI condenses that down to either 1 category or all of them. If no
    // categories are excluded from view, all are visible.
    if (excludedCategoryIds.length === 0) {
      return 'all'
    }
    // Otherwise, since we assume only one category can be selected at a time, the
    // first category that's *not* excluded must be it
    for (const category of vulnerabilityCategories) {
      if (!excludedCategoryIds.includes(category.id)) {
        return category.id.toString()
      }
    }
    return null
  }
  const setSelectedCategoryId = (newId) => {
    // Exclude no categories if we want to see them all; otherwise exclude *every*
    // category except the one we want to see
    if (newId === 'all') {
      onExcludedCategoryIdsChange(null)
    } else {
      const otherCategoryIds = vulnerabilityCategories
        .filter((x) => x.id !== parseInt(newId))
        .map((x) => x.id)
      onExcludedCategoryIdsChange(otherCategoryIds)
    }
  }
  return [getSelectedCategoryId, setSelectedCategoryId]
}
